import {
  DebtorReferenceFormValues,
  EditDebtorReferenceFormProps,
} from '@containers/Recovery/Wip/_modules/Debtors/types/debtor-associate-detail.type'
import { FC } from 'react'
import { toast } from 'react-toastify'
import DebtorReferenceForm from './debtor-reference-form.component'
import { useUpdateDebtorReferenceMutation } from '@containers/Recovery/Wip/_modules/Debtors/hooks/mutations/crud-debtor-reference.hook'

const EditDebtorReferenceForm: FC<EditDebtorReferenceFormProps> = ({
  debtorReferenceId,
  onSuccessSubmit,
  onCancelClick,
  initialValues,
}) => {
  const { mutateAsync, isLoading } = useUpdateDebtorReferenceMutation()

  const handleSubmit = async (data: DebtorReferenceFormValues) => {
    try {
      const updatedDebtorReference = await mutateAsync({
        id: debtorReferenceId,
        data: {
          ...data,
          call_result: data.call_result.value,
        },
      })
      onSuccessSubmit(updatedDebtorReference)
      toast.success('Updated')
    } catch (e) {
      toast.error('Something went wrong')
      console.log(e)
    }
  }

  return (
    <DebtorReferenceForm
      onSubmit={handleSubmit}
      onCancelClick={onCancelClick}
      loading={isLoading}
      initialValues={initialValues}
    />
  )
}

export default EditDebtorReferenceForm
