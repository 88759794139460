import {
  MatchHeader,
  MatchOption,
  MatchColumnsStepProps,
} from '@containers/Sales/Properties/types/property.type'
import { FC, useEffect, useMemo, useState } from 'react'
import styles from '../upload-multiple-properties-form.module.scss'
import cls from 'classnames'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { Header, SubHeader, Text } from '@containers/Recovery/Wip/components/UI/text/text.component'
import SecondaryTable from '../../../tables/secondary-table/secondary-table.component'
import TableRow from '@components/table/table-row.component'
import TableCell from '@components/table/table-cell-component'
import secondaryTableStyles from '../../../tables/secondary-table/secondary-table.module.scss'
import CloseIcon from '@src/icons/close'
import Select from 'react-select'
import { Checkbox, checkboxClasses, styled, svgIconClasses } from '@mui/material'

import CircleCheckedFilled from '@material-ui/icons/CheckCircle'
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import { SelectOption } from '@src/types/component-types'
import ColumnMatch from '../../../column-match/column-match.component'
import { useFormContext } from 'react-hook-form'
import ResetIcon from '@src/icons/reset'

const CustomCheckbox = styled(Checkbox)(() => ({
  [`&.${checkboxClasses.root}`]: {
    width: 24,
    height: 24,
  },
}))

const CustomCircleUnchecked = styled(CircleUnchecked)(() => ({
  [`&.${svgIconClasses.root}`]: {
    fill: '#D59E2E',
  },
}))

const CustomCircleCheckedFilled = styled(CircleCheckedFilled)(() => ({
  [`&.${svgIconClasses.root}`]: {
    fill: '#37A169',
  },
}))

const MatchColumnsStep: FC<MatchColumnsStepProps> = ({
                                                       rows,
                                                       header,
                                                       matchOptions,
                                                       onNextStepClick,
                                                     }) => {
  const [innerRows, setInnerRows] = useState<string[][]>(rows)
  const methods = useFormContext()
  const [columnHeader, setColumnHeader] = useState<MatchHeader>(
    header.map((column) => {
      return {
        name: column,
        match: null,
      }
    }),
  )
  const [ignoredHeaderIndexes, setIgnoredHeaderIndexes] = useState<number[]>([])

  const getColumnDataByIndex = (index: number) => {
    const column = columnHeader[index]
    if (!column) {
      throw new Error(`Cannot get column data. Column with index ${index} not found`)
    }
    return innerRows.map((row) => {
      return row[index]
    })
  }

  const getColumnDataByMatch = (match: string) => {
    const columnIndex = columnHeader.findIndex(
      (headerColumn) => headerColumn.match?.value === match,
    )
    if (columnIndex === -1) {
      throw new Error(`Cannot get column data. Column with match ${match} not found`)
    }
    return innerRows.map((row) => {
      return row[columnIndex]
    })
  }

  const setColumnHeaderMatch = (
    index: number,
    matchOption: MatchOption,
    newColumnData: unknown[] | null = null,
  ) => {
    methods.setValue(matchOption.value, newColumnData)
    const newHeader = [...columnHeader]
    newHeader[index].match = matchOption
    setColumnHeader(newHeader)
  }

  const resetColumnHeaderMatch = (index: number) => {
    const header = columnHeader[index]

    if (!header.match) {
      return
    }

    methods.unregister(header.match.value)

    const newHeader = [...columnHeader]
    newHeader[index].match = null
    setColumnHeader(newHeader)
  }

  const handleMatchUnselect = (columnHeaderIndex: number) => {
    const header = columnHeader[columnHeaderIndex]
    const prevMatch = header.match

    if (!prevMatch) {
      return
    }

    const matchOption = matchOptions.find((matchOption) => matchOption.value === prevMatch.value)

    if (!matchOption) {
      return
    }

    const rowsChangeAction = matchOption.matchDropdown?.rowsChangeAction

    if (rowsChangeAction) {
      resetColumnHeaderMatch(columnHeaderIndex)
      setInnerRows(rows)
      return
    }

    resetColumnHeaderMatch(columnHeaderIndex)
  }

  const handleMatchSelect = (matchOption: MatchOption, columnHeaderIndex: number) => {
    const rowsChangeAction = matchOption.matchDropdown?.rowsChangeAction
    const columnData = getColumnDataByIndex(columnHeaderIndex)
    const header = columnHeader[columnHeaderIndex]
    if (header.match) {
      handleMatchUnselect(columnHeaderIndex)
    }
    if (rowsChangeAction) {
      setColumnHeaderMatch(columnHeaderIndex, matchOption)
      setInnerRows(rowsChangeAction(rows, columnHeaderIndex))
      return
    }
    if (matchOption.matchDropdown) {
      const jsonResolver = matchOption.matchDropdown.jsonResolver
      const transformedColumnData = columnData.map((cell) => {
        return jsonResolver(cell)
      })
      setColumnHeaderMatch(columnHeaderIndex, matchOption, transformedColumnData)
    } else {
      setColumnHeaderMatch(columnHeaderIndex, matchOption, columnData)
    }
  }

  const handleIgnoreColumn = (index: number) => {
    if (ignoredHeaderIndexes.includes(index)) {
      const newIgnoredHeaderIndexes = ignoredHeaderIndexes.filter(
        (ignoredIndex) => ignoredIndex !== index,
      )
      setIgnoredHeaderIndexes(newIgnoredHeaderIndexes)
      return
    }

    handleMatchUnselect(index)
    setIgnoredHeaderIndexes([...ignoredHeaderIndexes, index])
  }

  const handleMatchSelectChange = (index: number, option: SelectOption | null) => {
    const matchOption = matchOptions.find((matchOption) => matchOption.value === option?.value)
    if (matchOption) {
      handleMatchSelect(matchOption, index)
    } else {
      handleMatchUnselect(index)
    }
  }

  useEffect(() => {
    const selectedColumns = methods.getValues()
    for (const selectedColumnName in selectedColumns) {
      const foundMatchOption = matchOptions.find(
        (matchOption) => matchOption.value === selectedColumnName,
      )

      if (!foundMatchOption) {
        continue
      }

      if (foundMatchOption.matchDropdown) {
        const jsonResolver = foundMatchOption.matchDropdown.jsonResolver
        const cellData = getColumnDataByMatch(selectedColumnName).map((cell) => {
            return jsonResolver(cell)
          },
        )
        methods.setValue(selectedColumnName, cellData)
      } else {
        methods.setValue(selectedColumnName, getColumnDataByMatch(selectedColumnName))
      }
    }
  }, [innerRows])

  const shownRows = rows.slice(0, 2)

  const filteredOptions = useMemo(
    () =>
      matchOptions
        .filter(
          (option) =>
            !columnHeader.some(
              (column, index) =>
                column.match?.value === option.value && !ignoredHeaderIndexes.includes(index),
            ),
        )
        .map((option) => ({
          value: option.value,
          label: option.label,
        })),
    [columnHeader, ignoredHeaderIndexes],
  )
  return (
    <div className={cls(styles.stepWrapper, styles.withoutPadding)}>
      <div className={styles.matchColumnsWrapper}>
        <Header mb={4}>Match Columns</Header>
        <SubHeader mb={2} fontSize="large" fontWeight="bold">
          Your Table
        </SubHeader>
        <div className={styles.exampleTableWrapper}>
          <SecondaryTable>
            <TableRow
              className={cls(secondaryTableStyles.secondaryTableRow, secondaryTableStyles.header)}
            >
              {columnHeader.map((column, index) => (
                <TableCell
                  key={`column-${index}`}
                  position="eclipsed"
                  className={cls(styles.columnCell, {
                    [styles.ignored]: ignoredHeaderIndexes.includes(index),
                  })}
                >
                  {column.name}
                  <SubmitButton
                    className={cls(styles.ignoreColumnButton, {
                      [styles.ignored]: ignoredHeaderIndexes.includes(index),
                    })}
                    buttonStyle="secondary"
                    size="small"
                    onClick={() => handleIgnoreColumn(index)}
                  >
                    {ignoredHeaderIndexes.includes(index) ? <ResetIcon /> : <CloseIcon />}
                  </SubmitButton>
                </TableCell>
              ))}
            </TableRow>
            {shownRows.map((row, index) => (
              <TableRow key={`row-${index}`} className={secondaryTableStyles.secondaryTableRow}>
                {row.map((cell, cellIndex) => (
                  <TableCell
                    className={cls({
                      [styles.ignored]: ignoredHeaderIndexes.includes(cellIndex),
                    })}
                    key={`cell-${cellIndex}`}
                    position="eclipsed"
                  >
                    {cell}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </SecondaryTable>
        </div>
        <SubHeader mb={2} fontSize="large" fontWeight="bold">
          Will Become
        </SubHeader>
        <div className={styles.willBecomeWrapper}>
          {columnHeader.map((column, index) => (
            <div className={styles.headerMatchCellWrapper} key={`column-match-${index}`}>
              {ignoredHeaderIndexes.includes(index) ? (
                <Text color="lightGrey">Column ignored</Text>
              ) : (
                <>
                  <div className={styles.columnMatchWrapper}>
                    <Select
                      styles={{
                        container: (baseStyles) => ({
                          ...baseStyles,
                          width: '100%',
                        }),
                      }}
                      value={
                        column.match
                          ? { value: column.match.value, label: column.match.label }
                          : null
                      }
                      onChange={(value) => handleMatchSelectChange(index, value)}
                      options={filteredOptions}
                      isClearable
                      placeholder="Select column..."
                    />
                    <CustomCheckbox
                      disabled
                      checked={column.match !== null}
                      icon={<CustomCircleUnchecked />}
                      checkedIcon={<CustomCircleCheckedFilled />}
                    />
                  </div>
                  {!column.match || !column.match.matchDropdown ? null : (
                    <ColumnMatch
                      columnName={column.match.value}
                      columnData={getColumnDataByIndex(index)}
                      fields={column.match.matchDropdown.fields}
                    />
                  )}
                </>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.formFooter}>
        <SubmitButton
          onClick={methods.handleSubmit(onNextStepClick)}
          className={styles.nextStepButton}
          buttonStyle="highlighted"
          size="large"
        >
          Next Step
        </SubmitButton>
      </div>
    </div>
  )
}

export default MatchColumnsStep
