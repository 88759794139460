import ClientSpeedDial from '@containers/Communication/components/speed-dial/client-speed-dial.component'
import { Client } from '../../types/client.type'
import styles from './client-page.module.scss'
import { FC } from 'react'
import { useClientPageActions } from './client-page-actions-context.page'

interface ClientPageProps {
  client: Client
}

const ClientSpeedDialWrapper: FC<ClientPageProps> = ({ client }) => {
  const { actions } = useClientPageActions()
  return (
    <div className={styles.speedDialWrapper}>
      {actions}
      <ClientSpeedDial client={client} />
    </div>
  )
}

export default ClientSpeedDialWrapper
