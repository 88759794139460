import FormDatePicker from '@components/inputs/form-date-picker/form-date-picker.component'
import CurrencyFormInput from '@components/inputs/form-input/currency-form-input.component'
import FormSelect from '@components/inputs/form-select/form-select.component'
import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import styles from './payment-tracking-form.module.scss'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { TRACKING_PERIOD_OPTIONS, ACCOUNTS_OPTIONS } from '../../../const/tracking.const'
import { paymentTrackingSchema } from '../../../schemas/tracking.schemas'
import { joiResolver } from '@hookform/resolvers/joi'
import { PaymentTrackingFormProps, PaymentTrackingFormValues } from '../../../types/payment-tracking.type'

const PaymentTrackingForm: FC<PaymentTrackingFormProps> = ({
  loading = false,
  onSubmit,
  initialValues,
}) => {
  const { control, handleSubmit, reset } = useForm<PaymentTrackingFormValues>({
    defaultValues: initialValues,
    resolver: joiResolver(paymentTrackingSchema),
  })

  useEffect(() => {
    if (!initialValues) return
    reset(initialValues)
  }, [initialValues])

  const innerSubmit = (data: PaymentTrackingFormValues) => {
    onSubmit(data)
  }

  return (
    <form className={styles.formWrapper} onSubmit={handleSubmit(innerSubmit)}>
      <div className={styles.row}>
        <FormSelect
          label="With a Period of "
          control={control}
          name="trackDayPeriod"
          options={TRACKING_PERIOD_OPTIONS}
        />
        <CurrencyFormInput
          label="For Amount Of"
          control={control}
          name="amount"
          placeholder="$00.00"
        />
      </div>
      <div className={styles.row}>
        <FormSelect label="From Account" control={control} name="from" options={ACCOUNTS_OPTIONS} />
        <FormDatePicker label="First Payment Due" control={control} name="firstPaymentDue" />
      </div>
      <div className={styles.actionWrapper}>
        <SubmitButton type="link" url="./payments">
          View Payments
        </SubmitButton>
        <SubmitButton buttonType="submit" loading={loading}>
          Save
        </SubmitButton>
      </div>
    </form>
  )
}

export default PaymentTrackingForm
