import React from 'react'

interface IProps extends React.SVGAttributes<SVGElement> {
  onClick: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void
}
const AddUser = ({ onClick, ...rest }: IProps) => {
  return (
    <svg
      onClick={(e) => onClick(e)}
      style={{ cursor: 'pointer' }}
      width="27"
      height="27"
      fill="none"
      viewBox="0 0 27 27"
      {...rest}
    >
      <g opacity="0.5">
        <circle cx="13.5" cy="13.5" r="13" stroke="#9898AE" strokeDasharray="2 2"></circle>
        <path
          fill="#9898AE"
          fillRule="evenodd"
          d="M18.576 14.076h-4.5v4.5H12.6v-4.5H8.1V12.6h4.5V8.1h1.476v4.5h4.5v1.476z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  )
}

export default AddUser
