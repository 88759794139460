import { NotificationOption } from "./notification-modals.type";

export const handleRecipientsChange = (
  value: { label: string; value: string }[],
  cb: (value: NotificationOption[]) => void,
) => {
  try {
    const parsedValue = value.map((option) => ({
      label: option.label,
      value: JSON.parse(option.value),
    }))
    cb(parsedValue)
  } catch {
    cb([])
  }
}

export const transformRecipientsOptions = (options: NotificationOption[]) =>
  (options || []).map((option) => ({ label: option.label, value: JSON.stringify(option.value) }))


const replacePlaceholders = (template: string, values: NonNullable<unknown>) => {
  let result = template
  Object.keys(values).forEach((key) => {
    const placeholder = new RegExp(`%${key}%`, 'g')
    result = result.replace(placeholder, values[key])
  })
  return result
}

export const formatHtmlText = (template: string, values: NonNullable<unknown>) => {
  const htmlText = replacePlaceholders(template, values)
  return htmlText
}