import RefreshIcon from '@src/icons/refresh'
import styles from './refresh-button.module.scss'
import cls from 'classnames'
import { getMilliseconds } from 'date-fns'
import { FC, useState } from 'react'

interface RefreshButtonProps {
  onClick?: () => Promise<void> | void
}

const RefreshButton: FC<RefreshButtonProps> = ({ onClick }) => {
  const [isLoading, setIsLoading] = useState(false)
  const handleClick = async () => {
    if (isLoading) return
    const startDate = new Date()
    setIsLoading(true)

    onClick && await onClick()

    const timeTakenInMs = getMilliseconds(new Date().getTime() - startDate.getTime())
    const restAnimationTime = timeTakenInMs  % 1000

    setTimeout(() => {
      setIsLoading(false)
    }, restAnimationTime)
  }

  return (
    <button
      className={cls(styles.refreshButton, { [styles.loading]: isLoading })}
      onClick={handleClick}
    >
      <RefreshIcon />
    </button>
  )
}
export default RefreshButton
