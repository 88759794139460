import { editInvoiceTemplateActionTypes } from '../actionTypes';
import { editInvoiceTemplateService } from '../service';

export const editInvoiceTemplate = (id, data) => async (dispatch) => {
  dispatch({ type: editInvoiceTemplateActionTypes.EDIT_INVOICE_TEMPLATE_LOADING });
  try {
    const res = await editInvoiceTemplateService(id, data);

    dispatch({
      type: editInvoiceTemplateActionTypes.EDIT_INVOICE_TEMPLATE_SUCCESS,
      payload: res,
    });

    return res;
  } catch (error) {
    dispatch({
      type: editInvoiceTemplateActionTypes.EDIT_INVOICE_TEMPLATE_LOADING,
      payload: error,
    });

    throw error;
  }
};
