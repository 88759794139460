export const styles = {
  tableRow: {
    height: 'auto',
    padding: '0',
    border: 'solid 1px rgba(224, 224, 224, 1)',
    borderRight: 'none',
    borderLeft: 'none',
  },
  tableCell: {
    display: 'flex',
    gap: '5px',
    border: 'none',
    margin: 'auto',
  },
};
