import { DeepPartial } from "react-hook-form";
import { CreateWipCostListItemData, WipCostListItem } from "../types/wip-cost.type";
import { Params } from "@src/types/service.type";
import axios from "axios";
import { REACT_APP_API_URL } from "@src/lib/global-variables";

export class WipCostListItemService {

  async createWipCostListItem(data: CreateWipCostListItemData) {
    const res = await axios.post<WipCostListItem>(`${REACT_APP_API_URL}/wip-cost-list-items`, data)
    return res.data
  }

  async updateWipCostListItem(id: number, data: DeepPartial<WipCostListItem>, params?: Params) {
    const res = await axios.patch<WipCostListItem>(`${REACT_APP_API_URL}/wip-cost-list-items/${id}`, data, { params })
    return res.data
  }

  async deleteWipCostList(id: number, params?: Params) {
    const res = await axios.delete(`${REACT_APP_API_URL}/wip-cost-list-items/${id}`, { params })
    return res.data
  }
}

