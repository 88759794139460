import FormInput from '@components/inputs/form-input/form-input.component'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import SearchIcon from '@src/icons/search'
import { FC } from 'react'
import styles from './client-properties-table.module.scss'

interface ClientPropertiesFilter {
  search: string
}

interface ClientPropertiesTableFilterProps {
  onFilterChange: (filter: ClientPropertiesFilter) => void
}

const ClientPropertiesTableFilter: FC<ClientPropertiesTableFilterProps> = () => {
  return (
    <div className={styles.filterWrapper}>
      <Header>Properties</Header>
      <div className={styles.inputWrapper}>
        <FormInput size="small" placeholder="Search" icon={SearchIcon} />
      </div>
    </div>
  )
}

export default ClientPropertiesTableFilter
