import React, { FC } from 'react'
import { ContactListModeSwitchProps, ListModeEnum } from '../types/contact-list.type'
import { useUserContext } from '@src/context/user-context'
import Tabs from '@components/tabs'

const contactModeOptions = [
  { value: ListModeEnum.ALL, label: 'All' },
  { value: ListModeEnum.FAVORITE, label: 'Favorite' },
]

const ContactListModeSwitch: FC<ContactListModeSwitchProps> = ({ onChange }) => {
  const { user } = useUserContext()

  const handleChange = (newValue: string) => {
    if (newValue !== null) {
      onChange(
        newValue === ListModeEnum.ALL ? null : { 'assigned_to_favorite.id': { $eq: user!.id } },
      )
    }
  }
  return (
    <Tabs
      options={contactModeOptions}
      onChange={handleChange}
      defaultValue={contactModeOptions[0]}
    />
  )
}

export default ContactListModeSwitch
