import TableRow from '@components/table/table-row.component'
import { BaseListItemProps } from '@src/types/component-types'
import { FC } from 'react'
import { ProtectAndCollectForm } from '../../../types/protect-and-collect-form.type'
import TableCell from '@components/table/table-cell-component'
import { format } from 'date-fns'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import styles from './protect-and-collect-table.module.scss'
import { useQueryClient } from '@tanstack/react-query'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import { toast } from 'react-toastify'
import {
  useCreateWipMutation,
  useDeleteProtectAndCollectFormMutation,
} from '../../../hooks/crud-protect-and-collect-forms.hook'
import { useUserContext } from '@src/context/user-context'
import { getPropertyName } from '@containers/Sales/Properties/utils/property.utils'

const ProtectAndCollectTableRow: FC<BaseListItemProps<ProtectAndCollectForm>> = ({
  entity: protectAndCollectForm,
}) => {
  const { user } = useUserContext()
  const { mutateAsync: deleteProtectAndCollectForm, isLoading: isProtectAndCollectFormDeleting } =
    useDeleteProtectAndCollectFormMutation()
  const { mutateAsync: createWip, isLoading: isWipCreating } = useCreateWipMutation()
  const queryClient = useQueryClient()
  const tenants =
    protectAndCollectForm?.tenants_details?.map((item) => item.first_name).join(', ') || '-'

  const handleDelete = async () => {
    try {
      await deleteProtectAndCollectForm(protectAndCollectForm.id)
      queryClient.invalidateQueries({ queryKey: [GLOBAL_QUERIES.PROTECT_AND_COLLECT.GET] })
      toast.success('Protect & Collect Form deleted successfully')
    } catch (e) {
      toast.error('Error deleting Protect & Collect Form')
      console.error(e)
    }
  }

  const handleCreateWip = async () => {
    try {
      const wip = await createWip({ form_id: protectAndCollectForm.id, staff_created_id: user!.id })
      queryClient.invalidateQueries({ queryKey: [GLOBAL_QUERIES.PROTECT_AND_COLLECT.GET] })
      toast.success(`WIP File #${wip?.id} Created Successfully`)
    } catch (e) {
      toast.error('Error creating WIP')
      console.error(e)
    }
  }

  return (
    <TableRow>
      {/* DATE */}
      <TableCell position="eclipsed" width={120}>
        {format(protectAndCollectForm.created_at, 'MMM dd yyyy')}
      </TableCell>
      {/* TIME */}
      <TableCell position="eclipsed" width={120}>
        {format(protectAndCollectForm.created_at, 'HH:mm aa')}
      </TableCell>
      {/* RECOVERY TYPE */}
      <TableCell position="center" width={120}>
        {protectAndCollectForm?.recovery_details?.carpet_cleaning || '-'}
      </TableCell>
      {/* DEBTORS */}
      <TableCell position="eclipsed">{tenants}</TableCell>
      {/* PROPERTY */}
      <TableCell position='eclipsed'>
        {getPropertyName(protectAndCollectForm?.property?.address_details)}
      </TableCell>
      {/* <CLIENT></CLIENT> */}
      <TableCell position="eclipsed">{protectAndCollectForm.owner_details?.name}</TableCell>
      {/* STATUS */}
      <TableCell position="eclipsed" width={100}>
        Submitted
      </TableCell>
      {/* ACTIONS */}
      <TableCell position="eclipsed" width={270} className={styles.actionCell}>
        <SubmitButton size="small" onClick={handleDelete} loading={isProtectAndCollectFormDeleting}>
          Cancel
        </SubmitButton>
        <SubmitButton
          type="link"
          size="small"
          url={`/recoveries/edit-protect_&_collect/${protectAndCollectForm?.id}`}
        >
          Edit
        </SubmitButton>
        <SubmitButton size="small" onClick={handleCreateWip} loading={isWipCreating}>
          Create WIP
        </SubmitButton>
      </TableCell>
    </TableRow>
  )
}

export default ProtectAndCollectTableRow
