import Section from '@components/section/section.component'
import SubSection from '@containers/Recovery/Wip/components/UI/sub-section/sub-section.component'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import { useModal } from '@src/hooks/useModal'
import { FC } from 'react'
import styles from './debtor-legal-judgment-date-section.module.scss'
import DebtorLegalJudgmentDateList from '../../lists/debtor-legal-judgment-date-list/debtor-legal-judgment-date-list.component'
import { JudgmentDateSectionProps } from '../../../types/debtor-legal-judgment-date.type'
import CreateDebtorLegalJudgmentDateModal from '../../modals/debtor-judgment-date-modal/create-debtor-judgment-date-modal.component'

const JudgmentDateSection: FC<JudgmentDateSectionProps> = ({ debtorLegal }) => {
  const [isOpen, handleOpen, handleClose] = useModal(false)
  return (
    <Section>
      <SubSection>
        <Header mb={4}>Judgment Date/s</Header>
      </SubSection>

      <DebtorLegalJudgmentDateList entities={debtorLegal.judgmentDates} />

      <div className={styles.buttonWrapper}>
        <SubmitButton onClick={handleOpen}>Add Judgment Date</SubmitButton>
      </div>

      <CreateDebtorLegalJudgmentDateModal
        isOpen={isOpen}
        onClose={handleClose}
        debtorLegalId={debtorLegal.id}
      />
    </Section>
  )
}

export default JudgmentDateSection
