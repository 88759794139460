import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';

import { TableRow, TableCell } from '@material-ui/core';

import { createURFWip } from './serviceCorporateRecoveryForm';
import { getURFInfo } from './serviceCorporateRecoveryForm';
import { useUserContext } from '../../../context/user-context';
const CorporateRecoveryListRow = ({
  labelId,
  index,
  row,
  tenantName,
  getRemoveItem,
}) => {
  const [loader, setLoader] = useState(false);
  const {user} = useUserContext()

  const getCreateWIP = (id) => async () => {
    setLoader(true);
    try {
      const params = {
        join: 'company||id',
      };
      await getURFInfo(id, params);
      const currentStaff = user;

      const createWIPDto = {
        form_id: row.id,
        staff_created_id: currentStaff.id,
      };

      await createURFWip(createWIPDto)
        .then((res) => {
          toast.success(`WIP File #${res?.id} Created Successfully`);
        })
        .catch((error) => {
          toast.error('Something went wrong!');
        });

      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        {moment(row.time_created).format('MM-DD-YYYY HH:mm')}
      </TableCell>
      <TableCell align="right">
        {row?.recovery_details?.arrears || '-'}
      </TableCell>
      <TableCell align="right">{tenantName(row?.tenants) || '-'}</TableCell>
      <TableCell align="right">{row?.property_type || '-'}</TableCell>
      <TableCell align="right">{row?.owner_name || '-'}</TableCell>
      <TableCell align="right">Submitted</TableCell>
      <TableCell align="right">
        <button
          onClick={getCreateWIP(row?.id)}
          className="btn btn-success-wip py-1 px-2 m-0 mr-2 w-250px"
        >
          {loader ? 'loading...' : 'Create WIP'}
        </button>
        <Link
          to={`/recoveries/edit-recovery-form/${row?.id}`}
          className="btn btn-info py-1 px-2 m-0"
        >
          Edit
        </Link>
        <button
          onClick={getRemoveItem(row?.id)}
          className="btn btn-danger py-1 px-2 m-0 ml-2"
        >
          Cancel
        </button>
      </TableCell>
    </TableRow>
  );
};

export default CorporateRecoveryListRow;
