import LoaderWrapper from '@components/loader/loader-wrapper.component'
import SubNavBar from '@components/nav-bar/sub-nav-bar.component'
import { Client } from '@containers/Sales/Client/types/client.type'
import { usePropertyById } from '@containers/Sales/Properties/hooks/crud-property.hook'
import { FC, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import EditClientPropertyDetails from './edit-client-property-details.component'
import EditClientPropertyOwners from './edit-client-property-owners.component'
import EditClientPropertyHistory from './edit-client-property-history.component'
import { GET_CLIENT_PROPERTIES_PARAMS } from '@containers/Sales/Client/const/client.const'
import styles from './edit-client-property-page.module.scss'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import { useClientPageActions } from '@containers/Sales/Client/view/client-page/client-page-actions-context.page'
import EditClientPropertyActions from './edit-client-property-actions.component'

interface EditClientPropertyPageProps {
  client: Client
}

enum EditPropertyNavOption {
  DETAILS = 'DETAILS',
  OWNERS = 'OWNERS',
  HISTORY = 'HISTORY',
}

const editPropertyNavOptions = [
  {
    label: 'Details',
    url: EditPropertyNavOption.DETAILS,
  },
  {
    label: 'Owners',
    url: EditPropertyNavOption.OWNERS,
  },
  {
    label: 'History',
    url: EditPropertyNavOption.HISTORY,
  },
]

const EditClientPropertyPage: FC<EditClientPropertyPageProps> = ({ client }) => {
  const [activeUrl, setActiveUrl] = useState<EditPropertyNavOption>(EditPropertyNavOption.DETAILS)
  const { propertyId } = useParams<{ propertyId: string }>()
  const { setActions } = useClientPageActions()
  const history = useHistory()
  const {
    data: property,
    isError,
    isLoading,
    isRefetching,
  } = usePropertyById(Number(propertyId), GET_CLIENT_PROPERTIES_PARAMS)

  useEffect(() => {
    if (!isError) {
      return
    }
    history.push(`/client/${client.id}/properties`)
  }, [client, isError])

  const isPropertyLoading = isLoading || isRefetching

  useEffect(() => {
    if (!property) return
    setActions(<EditClientPropertyActions property={property} />)

    return () => {
      setActions(null)
    }
  }, [property])

  return (
    <>
      {isPropertyLoading || !property ? (
        <LoaderWrapper />
      ) : (
        <div>
          <div className={styles.navbarWrapper}>
            <SubNavBar
              options={editPropertyNavOptions}
              activeUrl={activeUrl}
              isLink={false}
              className={styles.navbar}
              onChange={(url) => setActiveUrl(url as EditPropertyNavOption)}
            />
            <Header>{property.fullAddress || 'Invalid Address'}</Header>
          </div>
          {activeUrl === EditPropertyNavOption.DETAILS ? (
            <EditClientPropertyDetails property={property} />
          ) : null}
          {activeUrl === EditPropertyNavOption.OWNERS ? (
            <EditClientPropertyOwners property={property} />
          ) : null}
          {activeUrl === EditPropertyNavOption.HISTORY ? <EditClientPropertyHistory /> : null}
        </div>
      )}
    </>
  )
}

export default EditClientPropertyPage
