import * as React from 'react'
const SmsIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
    <path
      fill="#1F2A37"
      d="M7.263 21a1.05 1.05 0 0 1-.744-.31 1.062 1.062 0 0 1-.308-.749v-3.176H4.105a2.1 2.1 0 0 1-1.488-.62A2.124 2.124 0 0 1 2 14.647v-9.53c0-.561.222-1.1.617-1.497.394-.397.93-.62 1.488-.62h15.79a2.1 2.1 0 0 1 1.488.62c.395.397.617.936.617 1.498v9.53c0 .56-.222 1.1-.617 1.497a2.1 2.1 0 0 1-1.488.62h-7l-4.974 4.003c-.187.15-.419.232-.658.232ZM4.105 5.118v9.53h3.158c.28 0 .547.11.744.31.198.198.309.467.309.748v2.032l3.552-2.86c.187-.15.42-.231.658-.23h7.369v-9.53H4.105Z"
    />
    <path
      fill="#1F2A37"
      d="M7.79 10.941c.58 0 1.052-.474 1.052-1.059 0-.584-.471-1.058-1.053-1.058-.58 0-1.052.474-1.052 1.058 0 .585.471 1.06 1.052 1.06ZM12 10.941c.581 0 1.053-.474 1.053-1.059 0-.584-.472-1.058-1.053-1.058s-1.053.474-1.053 1.058c0 .585.472 1.06 1.053 1.06ZM16.21 10.941c.582 0 1.053-.474 1.053-1.059 0-.584-.471-1.058-1.053-1.058-.58 0-1.052.474-1.052 1.058 0 .585.471 1.06 1.053 1.06Z"
    />
  </svg>
)
export default SmsIcon
