export const invoiceTemplatesSharedStyles = {
  container: {
    width: '100%',
    maxWidth: '1630px',
    position: 'relative',
    padding: '0 15px 15px 15px',
    margin: '0 auto',
  },
  card: {
    borderRadius: '5px',
    boxShadow: '0 2px 10px 0 #ddd, 0 2px 20px 0 rgb(0 0 0 / 10%)',
    padding: '30px',
    backgroundColor: 'white',
  },
  header: {
    marginBottom: '48px',
  },
  title: {
    color: 'black',
    fontSize: '21px',
    fontFamily: 'Source Sans Pro',
    fontWeight: '700',
  },
};

export const invoiceTemplatesFormStyles = {
  buttonsWrapper: {
    marginTop: '40px',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '20px',
  },
};

export const invoiceTemplatesTableStyles = {
  tableRow: {
    height: 'auto',
    padding: '0',
    border: 'solid 1px rgba(224, 224, 224, 1)',
    borderRight: 'none',
    borderLeft: 'none',
  },
  tableCell: {
    display: 'flex',
    gap: '5px',
    border: 'none',
    margin: 'auto',
  },
  buttonsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    gap: '20px',
  },
  tableContainer: {
    overflowX: 'auto',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  paginationWrapper: {
    margin: "20px 20px 20px 0px"
  },
  loadingWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    textAlign: "center"
  }
};
