const Assignee = () => {
  return (
    <svg style={{ cursor: 'pointer' }} width="27" height="27" fill="none" viewBox="0 0 27 27">
      <circle cx="13.5" cy="13.5" r="13.5" fill="#9B9B9B"></circle>
      <mask
        id="mask0_41_2383"
        style={{ maskType: 'luminance' }}
        width="27"
        height="27"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <circle cx="13.5" cy="13.5" r="13.5" fill="#fff"></circle>
      </mask>
      <g mask="url(#mask0_41_2383)">
        <path fill="#D8D8E4" d="M0 0H27V27H0z"></path>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M13.5 14.918c.697 0 1.463.1 2.295.303.945.236 1.71.552 2.295.945.698.462 1.046.979 1.046 1.553v1.417H7.864V17.72c0-.574.349-1.091 1.046-1.553.585-.393 1.35-.709 2.295-.945.832-.202 1.598-.303 2.295-.303zm0-1.418c-.506 0-.973-.127-1.4-.38a2.863 2.863 0 01-1.021-1.02 2.699 2.699 0 01-.38-1.401c0-.506.126-.979.38-1.418a2.823 2.823 0 011.02-1.038 2.7 2.7 0 011.401-.38 2.7 2.7 0 011.4.38c.428.254.768.6 1.022 1.038.253.439.38.912.38 1.418a2.7 2.7 0 01-.38 1.4A2.864 2.864 0 0114.9 13.12c-.428.254-.895.38-1.401.38z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  )
}

export default Assignee
