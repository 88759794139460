import React, { Component, Fragment } from 'react';
import { Button, Card, CardBody, Col, Container, Row, Spinner, UncontrolledTooltip } from 'reactstrap';
import Select from 'react-select';
import Dropzone from 'react-dropzone';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import ReactFileReader from 'react-file-reader';
import Modal from 'react-bootstrap/Modal';
import Services from '../../../../shared/Services';
import moment from 'moment';
import TablePagination from '@material-ui/core/TablePagination';
import SimpleReactValidator from 'simple-react-validator';
import { fileLoacationOption } from '../../../../shared/Common/Constant';
import WIPService from '../../../../services/WIPService';
import DebtorService from '../../../../services/DebtorService';
import TransactionsService from '../../../../services/TransactionsService';
import BankRecordsService from '../../../../services/BankRecordsService';
import PayTrackingService from '../../../../services/PayTracking';
import { UserContext } from '../../../../context/user-context';

const csv = require('csvtojson');

const Reconciliations = [
  { value: '0', label: 'Bank of Queensland' },
  { value: '1', label: 'Bankwest' },
  //{ value: '2', label: 'CBA CC' },
];

let dataTosend = [];
let dataTosend1 = [];
let dataTosend2 = [];
let errorElements = [];
let errorElements1 = [];
const errorElements2 = [];
let file;

export default class ImportBankCSV extends Component {
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.state = {
      profilePic: '',
      profilePhotoView: '',
      profileLoder: false,
      showPropertieListError: false,
      errorElements: errorElements,
      errorElements1: errorElements1,
      errorElements2: errorElements2,
      showPropertieList: false,
      showCBC: false,
      showBankwest: false,
      csvfilename: '',
      staffData: [],
      rowsPerPage: 5,
      page: 0,
      showBankwestError: false,
      showCBCError: false,
      total: '',
      OverviewdropDown: true,
      wipdata: [],
      wip_ID: localStorage.getItem('wipID'),
      filedata: '',
      wipstaffData: [],
      searchWIp: '',
      recordID: '',
      match: [],
      staffDataMatch: [],
      confirm: [],
      showPropertieListclose: false,
      showBankwestclose: false,
      showCBCclose: false,
      error: false,
      debtorsOption: [],
      debtor: null,
      debtorId: null,
      nameDebtor: '',
      unassignPayment: {},
      fileCSVOption: [],
      allFileCSV: [],
      csvFile: null,
      csvId: null,
      allDataCSV: [],
      showConfirm: false,
      wipNumberArray: [],
      payTrack: {},
      amountCompare: false,
      accountCompare: false,
      nameCompare: false,
      wipIdCompare: false,
      wipRegArray: [],
      wipArrayCompare: [],
      comparingSuccesses: [],
    };

    this.bankRec = new BankRecordsService();
    this.payTrack = new PayTrackingService();
    this.debtor = new DebtorService();
    this.transaction = new TransactionsService();
    this.services = new Services();
    this.validator = new SimpleReactValidator();
    this.wip = new WIPService();
  };

  componentDidMount = () => {
    this.getDate();
    this.getDebtors();
    this.getAllWIPs();
  };

  async getAllWIPs() {
    const params = {
      limit: 10000,
      join: 'debtors',
    };
    const list = await this.wip.getWips(params);
    const option = list.data.map((val) => ({
      number: val.id,
    }));
    this.setState({
      wipNumberArray: option,
      wipArrayCompare: list.data,
    });

  }

  getDebtorId(id) {
    const params = {
      id: id,
      join: ['wips', 'wips.debtors'],
    };
    this.debtor.getDebtorId(params).then((res) => {
      if (res.status === 200) {

        const description = `${this.state.recordID.description}`;
        const regexpWip = new RegExp(res.data.wips[0].id, 'gi');
        const regexpDebtor = new RegExp(res.data?.name_last, 'gi');

        if (regexpWip && description) {
          let resultWip = description.match(regexpWip);
          if (resultWip?.length) {
            this.setState({ wipIdCompare: true });
          } else {
            this.setState({ wipIdCompare: false });
          }
        }
        if (regexpDebtor && description) {
          let resultDebtor = description.match(regexpDebtor);
          if (resultDebtor?.length) {
            this.setState({ nameCompare: true });
          } else {
            this.setState({ nameCompare: false });
          }
        }

        this.getPaymentTracking(res.data.wips[0].id);
        const getData = res.data;
        this.setState({
          wipdata: getData.wips,
        });
        this.setState({
          nameDebtor: `${getData.name_first} ${getData.name_last}`,
        });
      } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
        this.setState({ accessErrorModal: true });
      }
    });
  }

  getPaymentTracking(id) {
    const params = {
      join: ['wip||id', 'debtor||id'],
      filter: `wip.id||$eq||${id}`,
    };
    this.payTrack.getPayTrack(params).then((res) => {
      if (res.status === 200) {
        if (res.data.data.length) {
          const comparePayTrack = res.data.data[0];
          const amount = comparePayTrack.amount === +this.state.recordID.debit;
          const account = comparePayTrack.bank_account === this.state.recordID.account;

          this.setState({
            payTrack: res.data.data[0],
            amountCompare: amount,
            accountCompare: account,
          });
        }
      }
    });
  }

  async getDebtors() {
    try {
      const params = {
        limit: 1000,
      };
      const debtors = await this.debtor.getDebtor(params);
      const option = debtors.data.map((e) => ({
        value: e.id, label: `${e.name_first} ${e.name_last}`,
      }));
      this.setState({ debtorsOption: option });
    } catch {
      this.setState({ accessErrorModal: true });
    }
  }

  profilePictureUpload = imgOption => {
    this.setState({ profileLoder: true });
    this.services.uploadImage(imgOption[0], (res) => {
      if (res.status === 200) {
        this.setState({ profilePhotoView: URL.createObjectURL(imgOption[0]), profilePic: res.data.data.profile });
        this.services.successToast('Profile photo upload successfully');
      } else {
        this.services.errorToast(res.message);
      }
      this.setState({ profileLoder: false });
    });
  };

  removeProfile = (e) => {
    e.preventDefault();
    this.setState({ profilePic: '' });
    // call api for remove image
  };

  getWIPStaff = () => {
    const data = {};
    this.services.getWIPforClientList(data, (res) => {
      if (res.status === 200) {
        this.setState({
          wipstaffData: res.data.data.list,
          wipstaffDataCopy: res.data.data.list,
        });

      } else if (
        res.statusCode === 401 &&
        res.responseType === 'UNAUTHORIZED'
      ) {
        this.setState({ accessErrorModal: true });
      }
    });
  };

  getDate = () => {
    const params = {
      join: 'transactions',
      limit: 1000,
    };
    this.bankRec.getBankRecords(params).then((res) => {
      if (res.status === 200) {
        if (res.data.data.length > 0) {
          const option = res.data.data && res.data.data.filter(item => item.isDone === true);
          if (option.length > 0) {
            const fileCSVOption = option.map((el) => ({
              value: el.id, label: moment(el.createdAt).format('DD-MM-YYYY HH:MM'),
            }));

            const sort = fileCSVOption.sort((a, b) => a.value - b.value);

            const select = sort.length - 1;
            this.setState({
              allFileCSV: res.data.data || [],
              fileCSVOption: fileCSVOption || [],
            });
            this.handleChangeCSV(fileCSVOption[select], 'csvFile');
          }
        }
      }
    });
  };

  getDataWip(id) {
    const params = {
      wip_id: id,
      join: ['debtors'],
    };
    this.wip.getWipsId(params).then((res) => {
      if (res.status === 200) {

        const description = `${this.state.recordID.description}`;
        const regexpWip = new RegExp(res.data.id, 'gi');
        const regexpDebtor = new RegExp(res.data?.debtors[0]?.name_last, 'gi');

        if (regexpWip && description) {
          let resultWip = description.match(regexpWip);
          if (resultWip?.length > 0) {
            this.setState({ wipIdCompare: true });
          } else {
            this.setState({ wipIdCompare: false });
          }
        }
        if (regexpDebtor && description) {
          let resultDebtor = description.match(regexpDebtor);
          if (resultDebtor?.length) {
            this.setState({ nameCompare: true });
          } else {
            this.setState({ nameCompare: false });
          }
        }

        this.setState({
          wipdata: [res.data],
        });

      } else if (
        res.statusCode === 401 &&
        res.responseType === 'UNAUTHORIZED'
      ) {
        this.setState({ accessErrorModal: true });
        this.setState({ wipdata: [] });
      }
    });
  }

  applypayment = (e, i,name) => {
    this.setState({
      confirm: [i],
      showConfirm: true,
    });
    if(name){
      this.setState({ wipIdCompare: true });
    }
  };

  ConfirmforWIPList = (e, i) => {

    const data = {
      date: e.date,
      debtor: { id: i.debtors ? i.debtors[0].id : this.state.debtorId },
      wip: { id: i.id },
      deposit: +e.credit,
      withdrawal: 55,
      type: 'string',
      reversed_on: '2022-05-26T13:53:37.539Z',
      reason_for: 'string',
      payee: 'string',
      depositor_name: 'string',
      depositor_address: 'string',
    };
    this.transaction.createTransaction(data).then((res) => {

      if (res.status === 201) {
        const dataWithOutPay = this.state.allDataCSV.filter((val) => val.id !== e.id);
        const withDebtor = {
          ...this.state.unassignPayment,
          debtor: { id: i.debtors ? i.debtors[0].id : this.state.debtorId },
          assign: true,
        };
        const allData = [...dataWithOutPay, withDebtor];
        const isDone = allData.filter(item => item.assign !== true);
        // const params = {
        //   id: this.state.csvId,
        //   staff: { id: user.id },
        //   transactions: allData,
        //   data: allData,
        //   isDone: isDone.length !== 0,
        // };

        this.bankRec.changeBankRecordsId(withDebtor).then((res) => {
          if (res.status === 200) {
            this.setState({
              showConfirm: false,
            });
            this.setState({ confirm: [], wipdata: [] });
            if (isDone.length === 0) {
              this.setState({ staffData: [] });
              this.getDate();
            }
            this.getDate();
            this.services.successToast('Payment for WIP successfully');
          }
        });


      }
    }, () => this.getDate());

  };

  handleChangePage = (event, page) => {
    this.setState({ page: page });
    if (this.state.csvfilename.label) {
      this.setState({ csvfilename: '' });
    }
    // const data = {
    //   limit: this.state.rowsPerPage,
    //   skip: page * this.state.rowsPerPage,
    // };
    // this.services.getBankRecords(data, (res) => {
    //   if (res.status === 200) {
    //     this.setState({
    //       csvfilename: '',
    //       staffData: [],
    //       total: res.data.data.total,
    //     }, () => {
    //       this.setState({ staffData: res.data.data.list, csvfilename: '' });
    //     });
    //   }
    // });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value) });
    // const data = {
    //   bank_file_type: this.state.csvfilename.label,
    //   limit: event.target.value,
    //   skip: this.state.page * this.state.rowsPerPage,
    // };
  };

  csvRead = (fileToUpload) => {
    this.onChangeCsv(fileToUpload);
    let reader = new FileReader();
    reader.onload = function() {
      file = fileToUpload[0];
    };
    reader.readAsText(fileToUpload[0]);
  };

  closePopup = () => {
    this.setState({
      showPropertieListError: false,
      showCBCError: false,
      showBankwestError: false,
      showConfirm: false,
    });
  };

  onChangeCsv = (files) => {
    if (this.validator.allValid()) {
      const self = this;
      this.setState({ file: files[0] });
      const csvfile = files[0];
      const render = new FileReader();

      render.readAsText(csvfile);
      if (this.state.csvfilename.value === '0') {
        dataTosend = [];
        errorElements = [];
        render.onload = function() {
          csv({
            noheader: true,
            output: 'json',
          })
            .fromString(render?.result)
            .then((csvRows) => {
              const toJson = [];
              csvRows.forEach((aCsvRow, i) => {
                if (i !== 0) {
                  const builtObject = {};
                  Object.keys(aCsvRow).forEach((aKey) => {
                    const valueToAddInBuiltObject = aCsvRow[aKey];
                    const keyToAddInBuiltObject = csvRows[0][aKey];
                    if (keyToAddInBuiltObject === 'Date' ||
                      keyToAddInBuiltObject === 'Description' ||
                      keyToAddInBuiltObject === 'Account' ||
                      keyToAddInBuiltObject === 'Debit' ||
                      keyToAddInBuiltObject === 'Credit') {
                      builtObject[keyToAddInBuiltObject] = valueToAddInBuiltObject;
                    }
                    builtObject['selected'] = true;
                  });
                  toJson.push(builtObject);

                }
              });

              toJson.forEach((element, i) => {
                if (element['Date'] && element['Date'] !== 'Error' && element['Description'] && element['Description'] !== 'Error'
                  && element['Account'] && element[' Account'] !== 'Error' && element['Debit'] && element['Debit'] !== 'Error' && element['Credit'] !== 'Error' && element['Credit']) {

                  dataTosend.push({
                    'date': moment(element.Date, 'DD-MM-YYYY').format(),
                    'description': element['Description'],
                    account: element.Account,
                    debit: element.Debit ? element.Debit : '',
                    credit: element['Credit'] ? element['Credit'] : '',
                    selected: true,
                  });
                } else {
                  element.RowNumber = i;
                  if (element['Date'] === '' || element['Date'] === undefined || element['Date'] === 'Error') {
                    element['Date'] = 'Error';
                  }
                  if (element['Description'] === '' || element['Description'] === undefined || element['Description'] === 'Error') {
                    element['Description'] = 'Error';
                  }
                  if (element['Account'] === '' || element['Account'] === undefined || element['Account'] === 'Error') {
                    element['Account'] = 'Error';
                  }
                  if (element['Debit'] === '' || element['Debit'] === undefined || element['Debit'] === 'Error') {
                    element['Debit'] = 'Error';
                  }
                  if (element['Credit'] === '' || element['Credit'] === undefined || element['Credit'] === 'Error') {
                    element['Credit'] = 'Error';
                  }
                  errorElements.push(element);
                }
              });
              if (errorElements.length > 0) {
                self.setState({
                  showPropertieListError: true,
                  errorElements,
                });
              } else {
                self.setState({
                  'toJson': dataTosend,
                  showPropertieList: true,
                  showPropertieListclose: true,
                });
              }
            });
        };
      }
      if (this.state.csvfilename.value === '1') {
        dataTosend1 = [];
        errorElements1 = [];
        render.onload = function() {
          csv({
            noheader: true,
            output: 'json',
          })
            .fromString(render?.result)
            .then((csvRows) => {
              const toJson = [];
              csvRows.forEach((aCsvRow, i) => {
                if (i !== 0) {
                  const builtObject = {};

                  Object.keys(aCsvRow).forEach((aKey) => {
                    const valueToAddInBuiltObject = aCsvRow[aKey];
                    const keyToAddInBuiltObject = csvRows[0][aKey];
                    if (keyToAddInBuiltObject === 'BSB Number' ||
                      keyToAddInBuiltObject === 'Account Number' ||
                      keyToAddInBuiltObject === 'Transaction Date' ||
                      keyToAddInBuiltObject === 'Narration' ||
                      keyToAddInBuiltObject === 'Cheque' ||
                      keyToAddInBuiltObject === 'Debit' ||
                      keyToAddInBuiltObject === 'Credit' ||
                      keyToAddInBuiltObject === 'Transaction Type') {
                      builtObject[keyToAddInBuiltObject] = valueToAddInBuiltObject;
                    }
                    builtObject['selected'] = true;
                  });
                  toJson.push(builtObject);
                }
              });
              toJson.forEach((element, i) => {
                if (element['BSB Number'] && element['BSB Number'] !== 'Error' && element['Account Number'] && element['Account Number'] !== 'Error'
                  && element['Transaction Date'] && element[' Transaction Date'] !== 'Error' && element['Narration'] && element['Narration'] !== 'Error' && element['Cheque'] !== 'Error' && element['Cheque'] && element['Debit'] !== 'Error' && element['Debit'] && element['Credit'] !== 'Error' && element['Credit'] && element['Transaction Type'] !== 'Error' && element['Transaction Type']) {
                  dataTosend1.push({
                    'BSB_Number': element['BSB Number'],
                    account: element['Account Number'],
                    date: element['Transaction Date'],
                    description: element.Narration,
                    cheque: element.Cheque,
                    debit: element.Debit,
                    credit: element.Credit,
                    transaction_Type: element['Transaction Type'],
                    selected: true,
                  });
                } else {
                  element.RowNumber = i;
                  if (element['BSB Number'] === '' || element['BSB Number'] === undefined || element['BSB Number'] === 'Error') {
                    element['BSB Number'] = 'Error';
                  }
                  if (element['Account Number'] === '' || element['Account Number'] === undefined || element['Account Number'] === 'Error') {
                    element['Account Number'] = 'Error';
                  }
                  if (element['Transaction Date'] === '' || element['Transaction Date'] === undefined || element['Transaction Date'] === 'Error') {
                    element['Transaction Date'] = 'Error';
                  }
                  if (element['Narration'] === '' || element['Narration'] === undefined || element['Narration'] === 'Error') {
                    element['Narration'] = 'Error';
                  }
                  if (element['Cheque'] === '' || element['Cheque'] === undefined || element['Cheque'] === 'Error') {
                    element['Cheque'] = 'Error';
                  }
                  if (element['Debit'] === '' || element['Debit'] === undefined || element['Debit'] === 'Error') {
                    element['Debit'] = 'Error';
                  }
                  if (element['Credit'] === '' || element['Credit'] === undefined || element['Credit'] === 'Error') {
                    element['Credit'] = 'Error';
                  }
                  if (element['Transaction Type'] === '' || element['Transaction Type'] === undefined || element['Transaction Type'] === 'Error') {
                    element['Transaction Type'] = 'Error';
                  }
                  errorElements1.push(element);
                }
              });
              if (errorElements1.length > 0) {
                self.setState({
                  showBankwestError: true,
                  errorElements1: errorElements1,
                });
              } else {
                self.setState({
                  'toJson': dataTosend1,
                  showPropertieListError: false,
                  showBankwest: true,
                  showBankwestclose: true,
                });
              }
            });
        };
      }
      if (this.state.csvfilename.value === '2') {
        dataTosend2 = [];
        errorElements = [];
        render.onload = function() {
          csv({
            noheader: true,
            output: 'json',
          })
            .fromString(render?.result)
            .then((csvRows) => {
              const toJson = [];
              csvRows.forEach((aCsvRow, i) => {
                if (i !== 0) {
                  const builtObject = {};

                  Object.keys(aCsvRow).forEach((aKey) => {
                    const valueToAddInBuiltObject = aCsvRow[aKey];
                    const keyToAddInBuiltObject = csvRows[0][aKey];
                    if (keyToAddInBuiltObject === 'HEADER' ||
                      keyToAddInBuiltObject === 'CustomerRefNo1' ||
                      keyToAddInBuiltObject === 'CustomerRefNo2' ||
                      keyToAddInBuiltObject === 'AmountPaid') {
                      builtObject[keyToAddInBuiltObject] = valueToAddInBuiltObject;
                    }
                    builtObject['selected'] = true;
                  });
                  toJson.push(builtObject);
                }
              });
              toJson.forEach((element, i) => {
                if (element['HEADER'] && element['HEADER'] !== 'Error' && element['CustomerRefNo1'] && element['CustomerRefNo1'] !== 'Error'
                  && element['CustomerRefNo2'] && element[' CustomerRefNo2'] !== 'Error' && element['AmountPaid'] && element['AmountPaid'] !== 'Error') {
                  dataTosend2.push({
                    'header': element['HEADER'],
                    'account': element['CustomerRefNo1'],
                    customerRefNo2: element.CustomerRefNo2,
                    credit: element.AmountPaid,
                    selected: true,
                  });
                } else {
                  element.RowNumber = i;
                  if (element['HEADER'] === '' || element['HEADER'] === undefined || element['HEADER'] === 'Error') {
                    element['HEADER'] = 'Error';
                  }
                  if (element['CustomerRefNo1'] === '' || element['CustomerRefNo1'] === undefined || element['CustomerRefNo1'] === 'Error') {
                    element['CustomerRefNo1'] = 'Error';
                  }
                  if (element['CustomerRefNo2'] === '' || element['CustomerRefNo2'] === undefined || element['CustomerRefNo2'] === 'Error') {
                    element['CustomerRefNo2'] = 'Error';
                  }
                  if (element['AmountPaid'] === '' || element['AmountPaid'] === undefined || element['AmountPaid'] === 'Error') {
                    element['AmountPaid'] = 'Error';
                  }
                  errorElements2.push(element);
                }
              });
              if (errorElements2.length > 0) {
                self.setState({
                  showCBCError: true,
                  errorElements2: errorElements2,
                });
              } else {
                self.setState({
                  'toJson': dataTosend2,
                  showCBC: true,
                  showCBCclose: true,
                });
              }
            });
        };
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  saveData = () => {
    if (this.state.errorElements.find(item => item.Debit === 'Error')) {
      alert('not valid');
    } else {
      if (this.state.csvfilename.value === '0') {
        const leed = this.state.errorElements.map(item => {
          return {
            account: item.Account,
            credit: item.Credit,
            debit: item.Debit,
            description: item.Description,
            date: moment(item.Date, 'DD-MM-YYYY').format(),
            selected: true,
          };
        });

        const {user} = this.context;
        const formData = {
          staff: { id: user.id },
          data: dataTosend.length === 0 ? leed : dataTosend,
        };

        this.bankRec.createBankRecords(formData).then((res) => {
          if (res.status === 201) {
            this.setState({
              showPropertieList: false,
              showPropertieListError: false,
              showCBCError: false,
              showBankwestError: false,
            });
            this.services.successToast('ImportBankCSV Added successfully');
            dataTosend = [];
            this.getDate();
          } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
            this.setState({ showPropertieList: false, accessErrorModal: true });
          } else {
            this.services.errorToast(res.message);
          }
        });
      }
      if (this.state.csvfilename.value === '1') {
        const base = this.state.errorElements1.map(item => {
          return {
            account: item['Account Number'],
            description: item.Narration,
            debit: item.Debit,
            credit: item.Credit,
            date: item['Transaction Date'],
          };
        });
        const {user} = this.context;
        const formData = {
          staff: { id: user.id },
          data: dataTosend1.length === 0 ? base : dataTosend1,
        };
        this.bankRec.createBankRecords(formData).then((res) => {
          if (res.status === 201) {
            this.setState({
              showBankwest: false,
            });
            this.services.successToast('ImportBankCSV Added successfully');
            dataTosend1 = [];
            this.getDate();
          } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
            this.setState({ showBankwest: false, accessErrorModal: true });
          } else {
            this.services.errorToast(res.message);
          }
        });
      }
      if (this.state.csvfilename.value === '2') {
        const {user} = this.context;
        const formData = {
          staff: { id: user.id },
          data: dataTosend2 || errorElements2,
        };
        this.bankRec.createBankRecords(formData).then((res) => {
          if (res.status === 201) {
            this.setState({
              showCBC: false,
            });
            this.services.successToast('ImportBankCSV Added successfully');
          } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
            this.setState({ showCBC: false, accessErrorModal: true });
            dataTosend2 = [];
            this.getDate();
          } else {
            this.services.errorToast(res.message);
          }
        });
      }
    }
  };

  handleChange = (selectedOption, name) => {
    this.setState({ [name]: selectedOption });
  };

  handleChangeCSV = (selectedOption, name) => {
    this.setState({ [name]: selectedOption });
    const csv = this.state.allFileCSV.find((el) => el.id === selectedOption.value);

    if(!csv) return;

    const addFildCSV = csv.transactions.map((el) => {
      const o = Object.assign({}, el);
      o.searchbutton = false;
      o.id = o.id ? o.id : Math.floor(Math.random() * (1 + 100)) + 1;
      return o;
    });

    const unassigenPay = addFildCSV.filter(function(item) {
      return item.assign !== true;
    });

    this.setState({
      allDataCSV: addFildCSV,
      staffData: unassigenPay,
      total: unassigenPay.length,
      csvId: selectedOption.value,
    });
  };

  BOQDownloadErrorCSV = () => {
    if (this.state.errorElements) {

    }
    let rows = this.state.errorElements.map((element) => {
        return `${[element['RowNumber'], element['Date'], element['Description'], element['Account'], element['Debit'], element['Credit']]}`;
      },
    ).join('\n');
    rows = 'RowNumber,Date, Description, Account, Debit, Credit' + '\n' + rows;
    let csvContent = 'data:text/csv;charset=utf-8,'
      + rows;

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'Bank of QueenslandError.csv');
    document.body.appendChild(link);
    link.click();
  };

  BankwestDownloadErrorCSV = () => {
    let rows = this.state.errorElements1.map((element) => {
        return `${[element['RowNumber'], element['BSB Number'], element['Account Number'], element['Transaction Date'], element['Narration'], element['Cheque'], element['Debit'], element['Credit'], element['Transaction Type']]}`;
      },
    ).join('\n');

    rows = 'RowNumber, BSB_Number, Account_Number, Transaction_Date, Narration, Cheque, Debit, Credit, Transaction Type' + '\n' + rows;
    let csvContent = 'data:text/csv;charset=utf-8,'
      + rows;

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'BankwestError.csv');
    document.body.appendChild(link);
    link.click();
  };

  CBADownloadErrorCSV = () => {
    let rows = this.state.errorElements2.map((element) => {
        return `${[element['RowNumber'], element['HEADER'], element['CustomerRefNo1'], element['CustomerRefNo2'], element['AmountPaid']]}`;
      },
    ).join('\n');

    rows = 'RowNumber, HEADER, CustomerRefNo1, CustomerRefNo2, AmountPaid' + '\n' + rows;
    let csvContent = 'data:text/csv;charset=utf-8,'
      + rows;


    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'CBA CCError.csv');
    document.body.appendChild(link);

    link.click();
  };

  Search = (e, i) => {
    this.state.staffData.map(element => {
        if (element.id === i.id) {
          element.searchbutton = !element.searchbutton;
        } else if (element.searchbutton === true) {
          element.searchbutton = !element.searchbutton;
        }
      },
    );
    this.setState({ recordID: i, unassignPayment: i });
    if (this.state.recordID) {
      this.setState({ wipdata: [] });
    }

    const description = `${i.description}`;

    const compare = this.state.wipArrayCompare.map((el) => {
      try {
        const regexpWip = new RegExp(el.id, 'gi');
      const regexpDebtor = new RegExp(el?.debtors[0]?.name_last, 'gi');

      if (regexpWip && description) {
        let resultWip = description.match(regexpWip);
        if (resultWip?.length > 0) {
          return el;
        }
      }
      if (regexpDebtor && description) {
        let resultDebtor = description.match(regexpDebtor);
        if (resultDebtor?.length > 0 && resultDebtor[0] !== '') {
          return el;
        }
      }
      }catch(e) { 
        console.error(e)
        return undefined
      }
    });

    const clean = compare.filter((val) => val !== undefined);
    this.setState({ comparingSuccesses: clean });
  };

  searchWipS = () => {
    this.getDataWip(this.state.searchWIp);
    this.getPaymentTracking(this.state.searchWIp);
    this.setState({ debtor: null });
  };

  searchWip = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  successClose = () => {
    this.setState({
      showPropertieListclose: false,
      showBankwestclose: false,
      showCBCclose: false,
    });
  };

  handleInput = (e, i) => {
    const { name, value } = e.target;
    errorElements.map((item) => {
      // if(item.Account === 'Error'){
      if (i === item.RowNumber) {
        item[name] = value;
      }
      // }
    });
    this.setState({});
  };

  handleIn = (e, i) => {
    const { name, value } = e.target;
    this.state.errorElements.map((item) => {
      if (i === item.RowNumber) {
        item[name] = value;
      }
    });

    this.setState({ error: true });
  };

  handleInSide = (e, i) => {
    const { name, value } = e.target;
    errorElements1.map((item) => {
      if (item.RowNumber === i) {
        item[name] = value;
      }
    });
    this.setState({});
  };

  handleChangeDebtor = (selectedOption, name) => {
    this.setState({ debtorId: selectedOption.value });
    this.setState({ [name]: selectedOption });
  };

  searchDebtorId = () => {
    this.getDebtorId(this.state.debtorId);
    this.setState({ searchWIp: '' });
  };

  render() {
    const {
      debtorsOption,
      debtor,
      searchWIp,
      nameDebtor,
      unassignPayment,
      fileCSVOption,
      wipNumberArray,
      payTrack,
      amountCompare,
      wipIdCompare,
      nameCompare,
    } = this.state;
    return (
      <Container style={{ height: '100vh' }}>
        <Row>
          <Col md={12} lg={12}>
            <div className='mb-2 d-flex align-content-center  justify-content-between'>
              <h3 className='page-title'>ImportBankCSV</h3>
            </div>

            <Card className='form' style={{ height: 'auto' }}>
              <CardBody>
                <div className='row  align-content-center  justify-content-between'>
                  <div className='col-12 col-md-6 col-lg-3'>
                    <div className='form-group'>
                      <label className='form__form-group-label' htmlFor=''>Bank Format:</label>
                      <Select
                        name='csvfilename'
                        type='text'
                        options={Reconciliations}
                        classNamePrefix='react-select'
                        className='react-select'
                        value={this.state.csvfilename}
                        onChange={(e) => this.handleChange(e, 'csvfilename')}
                      />
                      <span
                        className='form__form-group-error'>{this.validator.message('Bank Format', this.state.csvfilename, 'required')}</span>
                    </div>
                  </div>
                  <div className='col-12 col-md-1 col-lg-1'>
                    <ReactFileReader handleFiles={this.onChangeCsv} fileTypes={'.csv'} className='btn btn-secondary'
                                     style={{ width: '100%' }}>
                      <button className='btn btn-secondary'> Upload</button>
                    </ReactFileReader>
                  </div>
                  <div className='col-12 col-md-12 col-lg-12'>
                    <div className={`dropzone dropzone--single drop-here `}>
                      {this.state.fileLoder &&
                      <Spinner animation='border' variant='warning' className='dropzone__drop-here' />}
                      <Dropzone
                        accept='.csv'
                        name={''}
                        multiple={false}
                        onDrop={(fileToUpload) => {
                          this.csvRead(fileToUpload);
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()} className='dropzone__input'>

                            <div className='dropzone__drop-here dropzon-custom'>
                              <div className='drop-box'>
                                <span className='uploder-img' />
                                <span>Drag and Drop files into the box below to add attachments </span>
                              </div>
                            </div>
                            <input {...getInputProps()} disabled={this.state.fileLoder} />
                          </div>
                        )}
                      </Dropzone>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
            <Modal
              show={this.state.showBankwestError}
              onHide={this.closePopup}
              aria-labelledby='contained-modal-title-vcenter'
              centered
              className='tool_modal noheader mediam_modal'
            >
              <Modal.Header closeButton>
                <Modal.Title id='contained-modal-title-vcenter'>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className='p-0 py-4'>
                <Table className='material-table transactions_table' id='notesTable'>
                  <TableRow>
                    <th className='material-table__cell'>BSB Number</th>
                    <th className='material-table__cell'> Account Number</th>
                    <th className='material-table__cell'> Transaction Date</th>
                    <th className='material-table__cell'> Narration</th>
                    <th className='material-table__cell'> Cheque</th>
                    <th className='material-table__cell'> Debit</th>
                    <th className='material-table__cell'> Credit</th>
                    <th className='material-table__cell'> Transaction_Type</th>
                  </TableRow>
                  <TableBody>
                    {errorElements1.map((item, i) => {
                      return (
                        <>
                          <TableRow key={i} role='checkbox'>
                            <TableCell
                              className='material-table__cell material-table__cell-right'
                              component=''
                              scope='row'
                            >
                              <input type='text' className={item['BSB Number'] === 'Error' ? '_input_error' : '_input'}
                                     name='BSB Number' placeholder={item['BSB Number']}
                                     onChange={(e) => this.handleInSide(e, item.RowNumber)} />
                            </TableCell>
                            <TableCell
                              className='material-table__cell material-table__cell-right'
                              component=''
                              scope='row'
                            >
                              <input type='text'
                                     className={item['Account Number'] === 'Error' ? '_input_error' : '_input'}
                                     name='Account Number' placeholder={item['Account Number']}
                                     onChange={(e) => this.handleInSide(e, item.RowNumber)} />
                            </TableCell>
                            <TableCell
                              className='material-table__cell material-table__cell-right'
                              component=''
                              scope='row'
                            >
                              <input type='text'
                                     className={item['Transaction Date'] === 'Error' ? '_input_error' : '_input'}
                                     name='Transaction Date' placeholder={item['Transaction Date']}
                                     onChange={(e) => this.handleInSide(e, item.RowNumber)} />
                            </TableCell>
                            <TableCell
                              className='material-table__cell material-table__cell-right'
                              component=''
                              scope='row'
                            >
                              <input type='text' className={item['Narration'] === 'Error' ? '_input_error' : '_input'}
                                     name='Narration' placeholder={item['Narration']}
                                     onChange={(e) => this.handleInSide(e, item.RowNumber)} />
                            </TableCell>
                            <TableCell
                              className='material-table__cell material-table__cell-right'
                              component=''
                              scope='row'
                            >
                              <input type='text' className={item['Cheque'] === 'Error' ? '_input_error' : '_input'}
                                     name='Cheque' placeholder={item['Cheque']}
                                     onChange={(e) => this.handleInSide(e, item.RowNumber)} />
                            </TableCell>
                            <TableCell
                              className='material-table__cell material-table__cell-right'
                              component=''
                              scope='row'
                            >
                              $ <input type='text' className={item['Debit'] === 'Error' ? '_input_error' : '_input'}
                                       name='Debit' placeholder={item['Debit']}
                                       onChange={(e) => this.handleInSide(e, item.RowNumber)} />
                            </TableCell>
                            <TableCell
                              className='material-table__cell material-table__cell-right'
                              component=''
                              scope='row'
                            >
                              $ <input type='text' className={item['Credit'] === 'Error' ? '_input_error' : '_input'}
                                       name='Credit' placeholder={item['Credit']}
                                       onChange={(e) => this.handleInSide(e, item.RowNumber)} />
                            </TableCell>
                            <TableCell
                              className='material-table__cell material-table__cell-right'
                              component=''
                              scope='row'
                            >
                              <input type='text'
                                     className={item['Transaction Type'] === 'Error' ? '_input_error' : '_input'}
                                     name='Transaction Type' placeholder={item['Transaction Type']}
                                     onChange={(e) => this.handleInSide(e, item.RowNumber)} />
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                  </TableBody>
                </Table>
                <button onClick={this.saveData} className='btn btn-secondary'>Save</button>
                <Container className='p-0 tool_popup'>
                  <Row>
                    <Col xs={12} md={12}>
                      {/* Design here tool_popup_tbale */}
                      <div className='text-center'
                           style={{ color: '#ff0000', fontSize: '27px', marginBottom: '50px', fontWeight: '600' }}>
                        Oops, there is an error in your CSV.
                      </div>
                      <div className='' style={{ fontSize: '18px', marginBottom: '50px' }}>
                        There was an error in your CSV. Please <button className='btn btn-secondary'
                                                                       onClick={this.BankwestDownloadErrorCSV}>Click
                        Here</button>. to download and review the problem rows
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Modal.Body>
            </Modal>

            {this.state.showBankwestclose ?
              <Modal
                show={this.state.showBankwest}
                onHide={this.closePopup}
                aria-labelledby='contained-modal-title-vcenter'
                centered
                className='tool_modal'>
                <Modal.Header closeButton className='transactions_close' onClick={this.successClose}>
                  <Modal.Title id='contained-modal-title-vcenter'>
                  </Modal.Title>
                </Modal.Header>
                <Table className='material-table transactions_table' id='notesTable'>
                  <TableRow>
                    <th className='material-table__cell'>BSB Number</th>
                    <th className='material-table__cell'> Account Number</th>
                    <th className='material-table__cell'> Transaction Date</th>
                    <th className='material-table__cell'> Narration</th>
                    <th className='material-table__cell'> Cheque</th>
                    <th className='material-table__cell'> Debit</th>
                    <th className='material-table__cell'> Credit</th>
                    <th className='material-table__cell'> Transaction_Type</th>
                  </TableRow>
                  <TableBody>
                    {dataTosend1.map((item, i) => {
                      return (
                        <>
                          <TableRow key={i} role='checkbox'>
                            <TableCell
                              className='material-table__cell material-table__cell-right'
                              component=''
                              scope='row'
                            >
                              {item.BSB_Number}
                            </TableCell>
                            <TableCell
                              className='material-table__cell material-table__cell-right'
                              component=''
                              scope='row'
                            >
                              {item.account}
                            </TableCell>
                            <TableCell
                              className='material-table__cell material-table__cell-right'
                              component=''
                              scope='row'
                            >
                              {item.date}
                            </TableCell>
                            <TableCell
                              className='material-table__cell material-table__cell-right'
                              component=''
                              scope='row'
                            >
                              {item.description}
                            </TableCell>
                            <TableCell
                              className='material-table__cell material-table__cell-right'
                              component=''
                              scope='row'
                            >
                              {item.cheque}
                            </TableCell>
                            <TableCell
                              className='material-table__cell material-table__cell-right'
                              component=''
                              scope='row'
                            >
                              $ {item.debit}
                            </TableCell>
                            <TableCell
                              className='material-table__cell material-table__cell-right'
                              component=''
                              scope='row'
                            >
                              ${item.credit}
                            </TableCell>
                            <TableCell
                              className='material-table__cell material-table__cell-right'
                              component=''
                              scope='row'
                            >
                              {item.transaction_Type}
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                  </TableBody>
                </Table>
                <button onClick={this.saveData} className='btn btn-secondary'>Save</button>
              </Modal>
              : ''}
            {this.state.showPropertieListError ?
              <Modal
                show={this.state.showPropertieListError}
                onHide={this.closePopup}
                // size="lg"
                aria-labelledby='contained-modal-title-vcenter'
                centered
                className='tool_modal'
              >
                <Modal.Header closeButton>
                  <Modal.Title id='contained-modal-title-vcenter'>
                    {/* Oops, there is an error in your CSV. */}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className='p-0 py-4'>
                  <Table className='material-table transactions_table' id='notesTable'>
                    <TableRow>
                      <th className='material-table__cell'>Date</th>
                      <th className='material-table__cell'> Description</th>
                      <th className='material-table__cell'> Account</th>
                      <th className='material-table__cell'> Debit</th>
                      <th className='material-table__cell'> Credit</th>

                    </TableRow>
                    <TableBody>
                      {this.state.errorElements.map((item, i) => {
                        return (
                          <Fragment key={i}>
                            <TableRow role='checkbox'>
                              <TableCell
                                className='material-table__cell material-table__cell-right'
                                component=''
                                scope='row'
                              >
                                {item.Date}
                              </TableCell>
                              <TableCell
                                className='material-table__cell material-table__cell-right'
                                component=''
                                scope='row'
                              >
                                <input type='text'
                                       className={item['Description'] === 'Error' ? '_input_error' : '_input'}
                                       name='Description' placeholder={item['Description']}
                                       onChange={(e) => this.handleIn(e, item.RowNumber)} />
                              </TableCell>
                              <TableCell
                                className='material-table__cell material-table__cell-right'
                                component=''
                                scope='row'
                              >
                                <input type='text' className={item['Account'] === 'Error' ? '_input_error' : '_input'}
                                       name='Account' placeholder={item['Account']}
                                       onChange={(e) => this.handleIn(e, item.RowNumber)} />
                              </TableCell>
                              <TableCell
                                className='material-table__cell material-table__cell-right'
                                component=''
                                scope='row'
                              >
                                <input type='text'
                                       className={item['Debit'] === 'Error' ? '_input_error input_data' : '_input'}
                                       name='Debit' placeholder={item['Debit'] ? item['Debit'] : ''}
                                       onChange={(e) => this.handleIn(e, item.RowNumber)} />
                              </TableCell>
                              <TableCell
                                className='material-table__cell material-table__cell-right'
                                component=''
                                scope='row'
                              >
                                $ {item.Credit === 'Error' ?
                                <input type='text' className={item['Credit'] === 'Error' ? '_input_error' : '_input'}
                                       name='Credit' placeholder={item['Credit']}
                                       onChange={(e) => this.handleIn(e, item.RowNumber)} />

                                // <input type="text" name="Credit"  className="_input" value={item.Credit}  />
                                :
                                <span>{item.Credit}</span>
                              }
                              </TableCell>
                            </TableRow>
                          </Fragment>
                        );
                      })}
                    </TableBody>
                  </Table>


                  <Container className='p-0 tool_popup'>
                    <Row>
                      <Col xs={12} md={12}>
                        {this.state.error ?
                          <button onClick={this.saveData} className='btn btn-secondary'>Save</button>
                          :
                          <>
                            <div className='text-center' style={{
                              color: '#ff0000',
                              fontSize: '27px',
                              marginBottom: '50px',
                              fontWeight: '600',
                            }}>
                              Oops, there is an error in your CSV.
                            </div>
                            <div className='' style={{ fontSize: '18px', marginBottom: '50px' }}>
                              There was an error in your CSV. Please <button className='btn btn-secondary'
                                                                             style={{ cursor: 'pointer' }}
                                                                             onClick={this.BOQDownloadErrorCSV}>Click
                              Here</button>. to download and review the problem rows
                            </div>
                          </>
                        }
                      </Col>
                    </Row>
                  </Container>
                </Modal.Body>
              </Modal>
              : ''}
            {this.state.showPropertieListclose ?
              <Modal
                show={this.state.showPropertieList}
                onHide={this.closePopup}
                aria-labelledby='contained-modal-title-vcenter'
                centered
                className='tool_modal'>

                <Modal.Header closeButton className='transactions_close' onClick={this.successClose}>
                  <Modal.Title id='contained-modal-title-vcenter'>
                  </Modal.Title>
                </Modal.Header>


                <Table className='material-table transactions_table' id='notesTable'>
                  <TableRow>
                    <th className='material-table__cell'>Date</th>
                    <th className='material-table__cell'> Description</th>
                    <th className='material-table__cell'> Account</th>
                    <th className='material-table__cell'> Debit</th>
                    <th className='material-table__cell'> Credit</th>

                  </TableRow>
                  <TableBody>
                    {dataTosend.map((item, i) => {
                      return (
                        <Fragment key={i}>
                          <TableRow role='checkbox'>
                            <TableCell
                              className='material-table__cell material-table__cell-right'
                              component=''
                              scope='row'
                            >
                              {moment(item.date).format('DD-MM-YYYY')}
                            </TableCell>
                            <TableCell
                              className='material-table__cell material-table__cell-right'
                              component=''
                              scope='row'
                            >
                              {item.description}
                            </TableCell>
                            <TableCell
                              className='material-table__cell material-table__cell-right'
                              component=''
                              scope='row'
                            >
                              {item.account}
                            </TableCell>
                            <TableCell
                              className='material-table__cell material-table__cell-right'
                              component=''
                              scope='row'
                            >
                              {item.debit}
                            </TableCell>
                            <TableCell
                              className='material-table__cell material-table__cell-right'
                              component=''
                              scope='row'
                            >
                              {item.credit}
                            </TableCell>
                          </TableRow>
                        </Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
                <button onClick={this.saveData} className='btn btn-secondary'>Save</button>
              </Modal>
              : ''}
            <Modal
              show={this.state.showCBCError}
              onHide={this.closePopup}
              aria-labelledby='contained-modal-title-vcenter'
              centered
              className='tool_modal'
            >
              <Modal.Header closeButton>
                <Modal.Title id='contained-modal-title-vcenter'>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className='p-0 py-4'>

                <Table className='material-table transactions_table' id='notesTable'>
                  <TableRow>
                    <th className='material-table__cell'>HEADER</th>
                    <th className='material-table__cell'> CustomerRefNo1</th>
                    <th className='material-table__cell'> CustomerRefNo2</th>
                    <th className='material-table__cell'> AmountPaid</th>
                  </TableRow>
                  <TableBody>
                    {errorElements2.map((item, i) => {
                      return (
                        <Fragment key={i}>

                          <TableRow role='checkbox'>
                            <TableCell
                              className='material-table__cell material-table__cell-right'
                              component=''
                              scope='row'
                            >
                              <input type='text' value={item['HEADER']} />
                            </TableCell>
                            <TableCell
                              className='material-table__cell material-table__cell-right'
                              component=''
                              scope='row'
                            >
                              {/* <input type="text" name="Description" value={item.Description} onChange={(e) => this.handelInput(e,i)}/> */}
                              <input type='text' value={item['CustomerRefNo1']} />
                            </TableCell>
                            <TableCell
                              className='material-table__cell material-table__cell-right'
                              component=''
                              scope='row'
                            >
                              <input type='text' value={item['CustomerRefNo2']} />
                            </TableCell>
                            <TableCell
                              className='material-table__cell material-table__cell-right'
                              component=''
                              scope='row'
                            >
                              $ <input type='text' value={item['AmountPaid']} />
                            </TableCell>
                          </TableRow>
                        </Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
                <button onClick={this.saveData} className='btn btn-secondary'>Save</button>

                <Container className='p-0 tool_popup'>
                  <Row>
                    <Col xs={12} md={12}>
                      <div className='text-center'
                           style={{ color: '#ff0000', fontSize: '27px', marginBottom: '50px', fontWeight: '600' }}>
                        Oops, there is an error in your CSV.
                      </div>
                      <div className='' style={{ fontSize: '18px', marginBottom: '50px' }}>
                        There was an error in your CSV. Please <button style={{ cursor: 'pointer' }}
                                                                       onClick={this.CBADownloadErrorCSV}>Click
                        Here</button>. to download and review the problem rows
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Modal.Body>
            </Modal>
            {this.state.showCBCclose ?
              <Modal
                show={this.state.showCBC}
                onHide={this.closePopup}
                aria-labelledby='contained-modal-title-vcenter'
                centered
                className='tool_modal'>
                <Modal.Header closeButton className='transactions_close' onClick={this.successClose}>
                  <Modal.Title id='contained-modal-title-vcenter'>
                  </Modal.Title>
                </Modal.Header>
                <Table className='material-table transactions_table' id='notesTable'>
                  <TableRow>
                    <th className='material-table__cell'>HEADER</th>
                    <th className='material-table__cell'> CustomerRefNo1</th>
                    <th className='material-table__cell'> CustomerRefNo2</th>
                    <th className='material-table__cell'> AmountPaid</th>
                  </TableRow>
                  <TableBody>
                    {dataTosend2.map((item, i) => {
                      return (
                        <Fragment key={i}>

                          <TableRow role='checkbox'>
                            <TableCell
                              className='material-table__cell material-table__cell-right'
                              component=''
                              scope='row'
                            >
                              {item.header}
                            </TableCell>
                            <TableCell
                              className='material-table__cell material-table__cell-right'
                              component=''
                              scope='row'
                            >
                              {/* <input type="text" name="Description" value={item.Description} onChange={(e) => this.handelInput(e,i)}/> */}
                              {item.account}
                            </TableCell>
                            <TableCell
                              className='material-table__cell material-table__cell-right'
                              component=''
                              scope='row'
                            >
                              {item.customerRefNo2}
                            </TableCell>
                            <TableCell
                              className='material-table__cell material-table__cell-right'
                              component=''
                              scope='row'
                            >
                              $ {item.credit}
                            </TableCell>
                          </TableRow>
                        </Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
                <button onClick={this.saveData} className='btn btn-secondary'>Save</button>
              </Modal>
              : ''}

            <div id='contacts_accordion' className='form'>
              <div className='card pb-0'>
                <div className='slide_down_wrap mb-4 rounded'>
                  <div className=' card-body bg-white pl-0 rounded'>

                    <div
                      className={`slide_drop_down boxshadow-none cursor-pointer d-flex flex-wrap align-items-center justify-content-between rounded ${this.state.OverviewdropDown ? 'active' : ''}`}>

                      <h3 className='page-title mb-0' data-toggle='collapse'>Unassigned Transactions</h3>
                      <div className='col-12 col-md-6 col-lg-3'>
                        <div className='form-group'>
                          <label className='form__form-group-label' htmlFor=''>Select file CSV:</label>
                          <Select
                            name='csvFile'
                            type='text'
                            options={fileCSVOption}
                            classNamePrefix='react-select'
                            className='react-select'
                            value={this.state.csvFile}
                            onChange={(e) => this.handleChangeCSV(e, 'csvFile')}
                          />
                          <span
                            className='form__form-group-error'>{this.validator.message('Bank Format', this.state.csvfilename, 'required')}</span>
                        </div>
                      </div>
                    </div>
                    <div className={`bg-white hide_contact ${this.state.OverviewdropDown ? 'active pt-4' : ''}`}>
                      <div className='table_wrap m-0  p-0'>
                        <Table className='material-table transactions_table' id='notesTable'>
                          <TableRow>
                            <th className='material-table__cell'> Date</th>
                            <th className='material-table__cell'> Description</th>
                            <th className='material-table__cell'> Account</th>
                            <th className='material-table__cell'> Debit</th>
                            <th className='material-table__cell'> Credit</th>
                            <th className='material-table__cell' />
                          </TableRow>
                          <TableBody>
                            {this.state.staffData.map((item, index) => {
                              return (
                                <div key={index} className='Transactions_warpper'>
                                  <TableRow role='checkbox' style={{borderBottom: "1px solid #ec6b1c"}}>

                                    <TableCell
                                      className='material-table__cell material-table__cell-right border-0'
                                      component=''
                                      scope='row'
                                      padding='none'>
                                      {moment(item.date).format('DD-MM-YYYY')}
                                    </TableCell>

                                    <TableCell
                                      className='material-table__cell material-table__cell-right border-0'>
                                      {item.description ? item.description : '-'}
                                    </TableCell>
                                    <TableCell
                                      className='material-table__cell material-table__cell-right border-0'>
                                      {item.account}
                                    </TableCell>
                                    <TableCell
                                      className='material-table__cell material-table__cell-right border-0'>
                                      $ {item.debit}
                                    </TableCell>
                                    <TableCell
                                      className='material-table__cell material-table__cell-right border-0'>
                                      $ {item.credit}
                                    </TableCell>
                                    <TableCell
                                      className='material-table__cell material-table__cell-left border-0 pl-0 pr-0'>
                                      <Button outline id='Search' onClick={(e) => this.Search(e, item)}>
                                        <label> Search </label>
                                        <svg className='mdi-icon ' width='24' height='24' fill='currentColor'
                                             viewBox='0 0 24 24'>
                                          <path
                                            d='M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z' />
                                        </svg>
                                      </Button>
                                      <UncontrolledTooltip placement='bottom' target='Search'>
                                        Search
                                      </UncontrolledTooltip>
                                    </TableCell>

                                  </TableRow>

                                  {item.searchbutton ?
                                    <>
                                      {this.state.comparingSuccesses.length ? <div style={{
                                        width: '400px',
                                        marginBottom: '14px',
                                        fontSize: '20px',
                                        fontWeight: 'bold',
                                        marginTop: '20px',
                                      }}>
                                        Best Matched Transaction
                                      </div> : null}
                                      {this.state.comparingSuccesses.length ?
                                        <TableRow style={{ background: 'white', border: '1px solid #50e3c2' }}
                                                  className='search_table_header'>
                                          <th> WIP No.</th>
                                          <th> File Location</th>
                                          <th> WIP Lodged</th>
                                          <th> Debtor</th>
                                          <th> {''}</th>
                                          <th> {''}</th>
                                        </TableRow> : ''}
                                      {this.state.comparingSuccesses.map((item, index) => {

                                        const name = item.debtors ? item.debtors.map((list) => {
                                          return list.name_first + ' ' + list.name_last;
                                        }) : nameDebtor;
                                        const loc = item.file_location ? fileLoacationOption.find(_ => _.value === item.file_location) : { label: '' };
                                        return (
                                          <Fragment key={index}>
                                            <TableRow style={{ background: '#e5fbf6', border: '1px solid #50e3c2' }}>
                                              <TableCell
                                                style={{ color: 'black' }}
                                                className='border-0'>
                                                {item.id}
                                              </TableCell>
                                              <TableCell className='border-0'> {loc.label} </TableCell>
                                              <TableCell className='border-0'> {item.date_lodged} </TableCell>
                                              <TableCell
                                                style={{ color: 'black' }}
                                                className='border-0'>
                                                {name}
                                              </TableCell>
                                              <TableCell className='border-0'>
                                                <span style={{ color: 'black' }}>

                                                </span>
                                              </TableCell>
                                              <TableCell className='border-0 pl-0 '>
                                                <button style={{color: 'red',border: "1px solid red",background:"white"}} className='btn-gray payment-button'
                                                        onClick={(e) => this.applypayment(e, item,'BestMatched' )}> Apply
                                                  Payment
                                                </button>
                                              </TableCell>
                                            </TableRow>

                                          </Fragment>
                                        );
                                      })}
                                      <TableRow className='search_table'>
                                        <TableCell colspan='6' className='search_table_main'>
                                          <div className='search_table_warpper'>
                                            <Table>
                                              <TableRow>
                                                <TableCell className='p-0 border-0' colspan='6'>
                                                  <div className='search_box d-flex align-content-center mb-5'>
                                                    <label> Search for WIP: </label>
                                                    <input
                                                      style={{ background: 'white', border: '1px solid #dadada' }}
                                                      type='text' name='searchWIp' value={this.state.searchWIp}
                                                      onChange={this.searchWip}
                                                      list='WIP'
                                                      step='5' min='5' max='200'
                                                    />
                                                    <datalist id='WIP'>
                                                      {wipNumberArray.map((e, i) =>
                                                        <option key={i} onTouchMove={true}>{e.number}</option>,
                                                      )}
                                                    </datalist>
                                                    <button disabled={searchWIp === ''} className='btn-gray'
                                                            onClick={this.searchWipS}> Search
                                                    </button>
                                                    <label className={'ml-5'}> Search for Debtor: </label>
                                                    <div style={{ margin: '24px', width: '300px', paddingTop: '8px' }}
                                                         className='form-group'>
                                                      <Select
                                                        name='debtorSearch'
                                                        type='text'
                                                        options={debtorsOption}
                                                        classNamePrefix='react-select'
                                                        className='react-select'
                                                        value={debtor}
                                                        onChange={(e) => this.handleChangeDebtor(e, 'debtor')}
                                                      />
                                                    </div>
                                                    <button disabled={debtor === null} className='btn-gray'
                                                            onClick={this.searchDebtorId}> Search
                                                    </button>
                                                  </div>

                                                </TableCell>
                                              </TableRow>

                                              {this.state.wipdata.length > 0 ?
                                                <TableRow className='search_table_header'>
                                                  <th> File No.</th>
                                                  <th> File Location</th>
                                                  <th> File Lodged</th>
                                                  <th> Debtor</th>
                                                  <th> Pay Track</th>
                                                  <th style={{ width: '175px' }} className='pl-0 pr-0'> Tools</th>
                                                </TableRow> : ''}
                                              {this.state.wipdata.length === 0 ?
                                                <TableCell colSpan={8}
                                                           className='text-center bg-white material-table__cell  material-table__cell-right'>
                                                  <span>{'None To Display'}</span></TableCell>
                                                : this.state.wipdata && this.state.wipdata.map((item, index) => {

                                                const name = item.debtors ? item.debtors.map((list) => {
                                                  return list.name_first + ' ' + list.name_last;
                                                }) : nameDebtor;

                                                const loc = item.file_location ? fileLoacationOption.find(_ => _.value === item.file_location) : { label: '' };
                                                return (
                                                  <Fragment key={index}>
                                                    <TableRow >
                                                      <TableCell
                                                        style={wipIdCompare ? { color: 'green' } : { color: 'red' }}
                                                        className='border-0'> {item.id} </TableCell>
                                                      <TableCell className='border-0'> {loc.label} </TableCell>
                                                      <TableCell className='border-0'> {item.date_lodged} </TableCell>

                                                      <TableCell
                                                        style={nameCompare ? { color: 'green' } : { color: 'red' }}
                                                        className='border-0'> {name} </TableCell>
                                                      <TableCell className='border-0'>
                                                        <span
                                                          style={amountCompare ? { color: 'green' } : { color: 'red' }}>{payTrack.amount || '-'}</span>
                                                      </TableCell>
                                                      <TableCell className='border-0 pl-0 '>
                                                        <button className='btn-gray payment-button'
                                                                onClick={(e) => this.applypayment(e, item)}> Apply
                                                          Payment
                                                        </button>
                                                      </TableCell>
                                                    </TableRow>
                                                  </Fragment>
                                                );
                                              })}
                                            </Table>
                                          </div>
                                        </TableCell>
                                      </TableRow>

                                    </>
                                    : ''
                                  }
                                </div>
                              );
                            })
                            }

                          </TableBody>
                        </Table>
                        <TablePagination
                          component='div'
                          className='material-table__pagination'
                          count={this.state.total}
                          rowsPerPage={this.state.rowsPerPage}
                          page={this.state.page}
                          backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                          nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                          onPageChange={this.handleChangePage}
                          onChangeRowsPerPage={this.handleChangeRowsPerPage}
                          rowsPerPageOptions={[10, 20]}
                          dir='ltr'
                          SelectProps={{
                            inputProps: { 'aria-label': 'rows per page' },
                            native: true,
                          }}
                        />
                        <Modal
                          show={this.state.showConfirm}
                          onHide={this.closePopup}
                          aria-labelledby='contained-modal-title-vcenter'
                          centered
                          className='tool_modal noheader mediam_modal'
                        >
                          <Modal.Header closeButton>
                            <Modal.Title id='contained-modal-title-vcenter'>
                              Confirm Payment
                            </Modal.Title>
                          </Modal.Header>

                          <TableRow className='search_table green_table'>
                            <TableCell colspan='6' className='search_table_main'>
                              <div style={nameCompare || wipIdCompare ? {
                                background: '#e5fbf6',
                                border: '1px solid #50e3c2',
                              } : { background: 'rgb(252,225,225)', border: '1px solid #DA1C1CFF' }}
                                   className='search_table_warpper'>
                                <Table style={{ minWidth: '1000px' }}>
                                  <TableRow className='search_table_header'>
                                    <th
                                      style={nameCompare || wipIdCompare ? { color: '#417505' } : { color: '#0a0a0a' }}> File
                                      No.
                                    </th>
                                    <th
                                      style={nameCompare || wipIdCompare ? { color: '#417505' } : { color: '#0a0a0a' }}> File
                                      Location
                                    </th>
                                    <th
                                      style={nameCompare || wipIdCompare ? { color: '#417505' } : { color: '#0a0a0a' }}> File
                                      Lodged
                                    </th>
                                    <th
                                      style={nameCompare || wipIdCompare ? { color: '#417505' } : { color: '#0a0a0a' }}> Debtor
                                    </th>
                                    <th
                                      style={nameCompare || wipIdCompare ? { color: '#417505' } : { color: '#0a0a0a' }}> Pay
                                      Track
                                    </th>
                                    <th style={nameCompare || wipIdCompare ? {
                                      width: '175px',
                                      color: '#417505',
                                    } : { width: '175px', color: '#0a0a0a' }} className='pl-0 pr-0'> Tools
                                    </th>
                                  </TableRow>
                                  {this.state.confirm && this.state.confirm.map((item, index) => {
                                    const name = item.debtors ? item.debtors.map((list) => {
                                      return list.name_first + ' ' + list.name_last;
                                    }) : nameDebtor;

                                    const loc = item.file_location ? fileLoacationOption.find(_ => _.value === item.file_location) : { label: '' };
                                    return (
                                      <Fragment key={index}>
                                        <TableRow>
                                          <TableCell className='border-0'> {item.id} </TableCell>
                                          <TableCell className='border-0'> {loc.label} </TableCell>
                                          <TableCell className='border-0'> {item.date_lodged} </TableCell>

                                          <TableCell className='border-0'> {name} </TableCell>
                                          <TableCell className='border-0'>
                                            <span
                                              style={amountCompare ? { color: 'green' } : { color: 'red' }}>{payTrack.amount || '-'}</span>
                                          </TableCell>
                                          <TableCell className='border-0 pl-0 '>
                                            <button className='btn-gray payment-button'
                                                    onClick={() => this.ConfirmforWIPList(unassignPayment, item)}> Confirm
                                              Payment
                                            </button>
                                          </TableCell>
                                        </TableRow>
                                      </Fragment>
                                    );
                                  })}
                                </Table>
                              </div>
                            </TableCell>
                          </TableRow>
                        </Modal>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}
