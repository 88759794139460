import { useMutation, useQueryClient } from "@tanstack/react-query";
import { NOTE_QUERY } from "../const/note-query.const";
import { EmailService } from "../../../../../Communication/services/email.service"

export const useRefreshEmails = () => {
  const queryClient = useQueryClient();

  const refreshEmails = async (params: { wip_id?: number, client_id?: number }) => {
    const emailService = new EmailService()
    await emailService.refresh(params)
    queryClient.invalidateQueries([NOTE_QUERY.EMAIL.GET])
  }

  const mutation = useMutation({ mutationFn: refreshEmails });

  return mutation;
}