import { REACT_APP_API_URL } from '@src/lib/global-variables'
import { UserType } from '@src/types/user'
import { ADMIN_POSITIONS } from '@src/utils'
import axios from 'axios'
import React, { useState } from 'react'

interface IUserContext {
  user: UserType | null
  isAdmin: boolean
  setUser: (user: any) => void
  fetchUser: () => Promise<void>
  logout: () => Promise<void>
}

const _noop = () => {
  throw new Error('useUserContext() must be used within a UserProvider')
}
export const UserContext = React.createContext<IUserContext>({
  user: null,

  isAdmin: false,
  setUser: _noop,
  fetchUser: _noop,
  logout: _noop,
})

type IUserProviderProps = React.PropsWithChildren
export const UserProvider = ({ children }: IUserProviderProps) => {
  const [user, setUser] = React.useState<UserType | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const isAdmin = ADMIN_POSITIONS.includes(user?.access_level || '')

  const fetchUser = async () => {
    try {
      const response = await axios.get<UserType>(`${REACT_APP_API_URL}/auth/me`, {
        withCredentials: true,
      })
      setUser(response.data)
    } catch {
      setUser(null)
    }
    setIsLoading(false)
  }

  const logout = async () => {
    try {
      await axios.post(`${REACT_APP_API_URL}/auth/logout`, {}, { withCredentials: true })
    } catch(e) {
      console.error(e)
    }
    setUser(null)
  }

  React.useEffect(() => {
    fetchUser()
  }, [])

  const value = React.useMemo(
    () => ({ user, isAdmin, setUser, fetchUser, logout }),
    [user, isAdmin],
  )

  return <UserContext.Provider value={value}>{isLoading ? null : children}</UserContext.Provider>
}

export const useUserContext = () => {
  const context = React.useContext(UserContext)
  if (!context) {
    throw new Error('useUserContext must be used within a UserProvider')
  }
  return context
}
export const useIsAdmin = () => {
  const context = React.useContext(UserContext)
  if (!context) {
    throw new Error('useUserContext must be used within a UserProvider')
  }
  const { user } = context
  const isAdmin = ADMIN_POSITIONS.includes(user?.access_level || '')
  return isAdmin
}
