class BaseService {
  protected async withErrorHandling(fn: () => Promise<any>): Promise<any> {
    try {
      return await fn()
    } catch (error) {
      console.log('error', error)
      // throw new Error(error as any)
    }
  }
}

export default BaseService
