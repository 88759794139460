import React from 'react'
import { Card, CardBody, Col, Button, ButtonToolbar } from 'reactstrap'
import { Field, reduxForm } from 'redux-form'
import EyeIcon from 'mdi-react/EyeIcon'
import { withTranslation } from 'react-i18next'
import renderSelectField from '../../../../shared/components/form/Select'
import renderMultiSelectField from '../../../../shared/components/form/MultiSelect'
import Services from '../../../../shared/Services.js'
import { withRouter } from 'react-router'
import qs from 'querystring'
import SimpleReactValidator from 'simple-react-validator'
import Dropzone from 'react-dropzone'
const renderField = ({ input, placeholder, type, meta: { touched, error } }) => (
  <div className="form__form-group-input-wrap">
    <input {...input} placeholder={placeholder} type={type} />
    {/* {touched && error && <span className="form__form-group-error">{error}</span>} */}
  </div>
)

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
}

class Management extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showPassword: false,
      email: '',
      password: '',
      name: '',
      fname: '',
      lname: '',
      phone: '',
      mobile: '',
      position: '',
      Ass_StatesData: [],
      access_type: [],
      role: [],
      profilePic: '',
      profilePhotoView: '',
      email_letter: '',
      emailPhotoView: '',
      imgOption1: '',
      email_letter_name: '',
    }

    this.services = new Services()
    this.addStaff = this.addStaff.bind(this)
    this.changeInput = this.changeInput.bind(this)
    let params = qs.parse(props.location)
    this.validator = new SimpleReactValidator()
  }

  showPassword = (e) => {
    e.preventDefault()
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }))
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }))
  }

  addStaff = () => {
    let url = 'Staff/addStaff'
    let AssData = []
    this.state.Ass_StatesData.forEach((element) => {
      AssData.push(element.value.toString())
    })

    // var self=this;
    if (this.validator.allValid()) {
      var sendData = {
        email: this.state.email,
        password: this.state.password,
        name: this.state.name,
        fname: this.state.fname,
        lname: this.state.lname,
        phone: this.state.phone,
        mobile: this.state.mobile,
        position: this.state.position,
        Ass_States: JSON.stringify(AssData),
        access_type: this.state.access_type,
        role: this.state.role,
      }
      if (this.state.profilePhotoView) {
        sendData['profilePic'] = JSON.stringify(this.state.profilePic)
      }
      if (this.state.emailPhotoView) {
        sendData['email_letter'] = JSON.stringify(this.state.email_letter)
      }
      this.services.addStaff(sendData, url, (res) => {
        if (res.status === 200) {
          this.services.successToast('Staff added successfully')
          this.props.history.push('/staff-list')
        } else {
          this.services.errorToast(res.message)
        }
      })
    } else {
      this.validator.showMessages()
      this.forceUpdate()
    }
  }
  cancle = () => {
    this.props.history.push('/staff-list')
  }
  changeInput(e) {
    this.setState({ [e.target.name]: e.target.value })
  }
  profilePictureUpload = (imgOption) => {
    this.services.uploadImage(imgOption[0], (res) => {
      if (res.status === 200) {
        this.services.successToast('Profile photo upload successfully')
        this.setState({
          profilePhotoView: URL.createObjectURL(imgOption[0]),
          profilePic: res.data.data.profile,
        })
      } else {
        this.services.errorToast(res.message)
      }
    })
  }
  email_letterUpload = (imgOption1) => {
    this.services.uploadImage(imgOption1[0], (res) => {
      if (res.status === 200) {
        this.services.successToast('email letter upload successfully')
        this.setState({
          emailPhotoView: URL.createObjectURL(imgOption1[0]),
          imgOption1: imgOption1,
          email_letter: res.data.data.profile,
        })
      } else {
        this.services.errorToast(res.message)
      }
    })
  }

  handleChange = (selectedOption) => {
    this.setState({ selectedOption, access_type: [selectedOption.value] })
  }
  handleChange1 = (selectedOption1) => {
    this.setState({ selectedOption1, role: [selectedOption1.value] })
  }
  handleChange2 = (selectedOption2) => {
    this.setState({ Ass_StatesData: selectedOption2 })
  }

  removeEmailLatter = (e) => {
    e.preventDefault()
    this.setState({ email_letter: '', email_letter_name: '' })
  }
  removeProfile = (e) => {
    e.preventDefault()
    this.setState({ profilePic: '' })
  }
  render() {
    const { showPassword } = this.state

    return (
      <Col md={12} lg={12} xl={10}>
        <Card>
          <CardBody>
            <div className="card__title"></div>
            <form className="form form--horizontal" onSubmit={this.handleSubmit}>
              <div className="form__form-group">
                <span className="form__form-group-label">First Name</span>
                <div className="form__form-group-field">
                  <Field
                    name="fname"
                    component={renderField}
                    type="text"
                    placeholder="First Name"
                    onChange={this.changeInput}
                  />
                </div>
                <span className="form__form-group-error">
                  {this.validator.message('First Name', this.state.fname, 'required')}
                </span>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Last Name</span>
                <div className="form__form-group-field">
                  <Field
                    name="lname"
                    component={renderField}
                    type="text"
                    placeholder="Last Name"
                    onChange={this.changeInput}
                  />
                </div>
                <span className="form__form-group-error">
                  {this.validator.message('Last Name', this.state.lname, 'required')}
                </span>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Email Address</span>
                <div className="form__form-group-field">
                  <Field
                    name="email"
                    component={renderField}
                    type="text"
                    placeholder="Email address"
                    onChange={this.changeInput}
                  />
                </div>
                <span className="form__form-group-error">
                  {this.validator.message('Email', this.state.email, 'required|email')}
                </span>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">User Name</span>
                <div className="form__form-group-field">
                  <Field
                    name="name"
                    component={renderField}
                    type="text"
                    placeholder="User Name"
                    onChange={this.changeInput}
                  />
                </div>
                <span className="form__form-group-error">
                  {this.validator.message('User Name', this.state.name, 'required')}
                </span>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Password</span>
                <div className="form__form-group-field">
                  <Field
                    name="password"
                    component={renderField}
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Password"
                    onChange={this.changeInput}
                  />
                  <button
                    type="button"
                    className={`form__form-group-button${showPassword ? ' active' : ''}`}
                    tabIndex="-1"
                    onClick={(e) => this.showPassword(e)}
                  >
                    <EyeIcon />
                  </button>
                </div>
                <span className="form__form-group-error">
                  {this.validator.message('Password', this.state.password, 'required')}
                </span>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Access Type</span>
                <div className="form__form-group-field">
                  <Field
                    name="access_type"
                    component={renderSelectField}
                    type="text"
                    options={[
                      { value: 'ADMIN', label: 'Admin' },
                      { value: 'SALES', label: 'Sales' },
                      { value: 'RECOVERY', label: 'Recovery' },
                      // { value: 'ACCOUNT', label: 'Account' },
                    ]}
                    onChange={this.handleChange}
                  />
                </div>
                <span className="form__form-group-error">
                  {this.validator.message('', this.state.access_type, 'required')}
                </span>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Access Information</span>
                <div className="form__form-group-field">
                  <Field
                    name="role"
                    component={renderSelectField}
                    type="text"
                    options={[
                      { value: 'SUPER_ADMIN', label: 'Super Admin' },
                      { value: 'SALES_ADMIN', label: 'Sales Admin' },
                      { value: 'RECOVERY_ADMIN', label: 'Recovery Admin' },
                      { value: 'SALE', label: 'Sale' },
                      { value: 'RECOVERY_USER', label: 'Recovery User' },
                      { value: 'TENNANT', label: 'Tennant' },
                      { value: 'DEBTOR', label: 'Debtor' },
                    ]}
                    onChange={this.handleChange1}
                  />
                </div>
                <span className="form__form-group-error">
                  {this.validator.message('Access Information', this.state.role, 'required')}
                </span>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Associated States</span>
                <div className="form__form-group-field">
                  <Field
                    name="Ass_States"
                    component={renderMultiSelectField}
                    type="text"
                    options={[
                      { value: 'ACT', label: 'ACT' },
                      { value: 'NSW', label: 'NSW' },
                      { value: 'NT', label: 'NT' },
                      { value: 'QLD', label: 'QLD' },
                      { value: 'SA', label: 'SA' },
                      { value: 'TAS', label: 'TAS' },
                      { value: 'VIC', label: 'VIC' },
                      { value: 'WA', label: 'WA' },
                    ]}
                    onChange={this.handleChange2}
                  />
                </div>
                <span className="form__form-group-error">
                  {this.validator.message('Associated States', this.state.Ass_States, 'required')}
                </span>
              </div>
              <div className="card__title">
                <h5 className="bold-text">Personal Information</h5>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Phone</span>
                <div className="form__form-group-field">
                  <Field
                    name="phone"
                    component={renderField}
                    type="number"
                    placeholder="Phone Number"
                    onChange={this.changeInput}
                  />
                </div>
                <span className="form__form-group-error">
                  {this.validator.message('phone', this.state.phone, 'required')}
                </span>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Mobile</span>
                <div className="form__form-group-field">
                  <Field
                    name="mobile"
                    component={renderField}
                    type="number"
                    placeholder="Mobile Number"
                    onChange={this.changeInput}
                  />
                </div>
                <span className="form__form-group-error">
                  {this.validator.message('mobile', this.state.mobile, 'required')}
                </span>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Position</span>
                <div className="form__form-group-field">
                  <Field
                    name="position"
                    component={renderField}
                    type="text"
                    placeholder="Position"
                    onChange={this.changeInput}
                  />
                </div>
                <span className="form__form-group-error">
                  {this.validator.message('Position', this.state.position, 'required')}
                </span>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Email Letterhead</span>
                <div className="form__form-group-field">
                  <div className={`dropzone dropzone--single`}>
                    <Dropzone
                      accept="image/jpeg, image/png"
                      name={'email_letter'}
                      multiple={false}
                      onDrop={(fileToUpload) => {
                        this.email_letterUpload(fileToUpload)
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()} className="dropzone__input">
                          {this.state.email_letter === '' && (
                            <div className="dropzone__drop-here">
                              <span className="lnr lnr-upload" /> Drop File Here To Upload
                            </div>
                          )}
                          <input {...getInputProps()} />
                        </div>
                      )}
                    </Dropzone>
                    {this.state.email_letter && (
                      <aside className="dropzone__img">
                        <img src={this.state.emailPhotoView} alt="drop-img" />
                        {/* <p className="dropzone__img-name">{files[0].name}</p> */}
                        <button
                          className="dropzone__img-delete"
                          type="button"
                          onClick={(e) => this.removeEmailLatter(e)}
                        >
                          Remove
                        </button>
                      </aside>
                    )}
                  </div>
                </div>
                {/* <span className="form__form-group-error">{this.validator.message('', this.state.email_letter, 'required')}</span> */}
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Profile Photo</span>

                <div className="form__form-group-field">
                  <div className={`dropzone dropzone--single`}>
                    <Dropzone
                      accept="image/jpeg, image/png"
                      name={'profilePic'}
                      multiple={false}
                      onDrop={(fileToUpload) => {
                        this.profilePictureUpload(fileToUpload)
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()} className="dropzone__input">
                          {this.state.profilePic === '' && (
                            <div className="dropzone__drop-here">
                              <span className="lnr lnr-upload" /> Drop File Here To Upload
                            </div>
                          )}
                          <input {...getInputProps()} />
                        </div>
                      )}
                    </Dropzone>
                    {this.state.profilePic && (
                      <aside className="dropzone__img">
                        <img src={this.state.profilePhotoView} alt="drop-img" />
                        {/* <p className="dropzone__img-name">{files[0].name}</p> */}
                        <button
                          className="dropzone__img-delete"
                          type="button"
                          onClick={(e) => this.removeProfile(e)}
                        >
                          Remove
                        </button>
                      </aside>
                    )}
                  </div>
                </div>
                {/* <span className="form__form-group-error">{this.validator.message('', this.state.profilePic, 'required')}</span> */}
              </div>
              <ButtonToolbar className="form__button-toolbar">
                <Button color="primary" type="submit" onClick={this.addStaff}>
                  Save
                </Button>
                <Button type="button" onClick={this.cancle}>
                  Cancel
                </Button>
              </ButtonToolbar>
            </form>
          </CardBody>
        </Card>
      </Col>
    )
  }
}

export default reduxForm({
  form: 'add_staff_form_validation', // a unique identifier for this form
})(withRouter(withTranslation('common')(Management)))
