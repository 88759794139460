import Modal from '@components/modal'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import { REACT_APP_API_URL } from '@src/lib/global-variables'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useState } from 'react'
import { Automation, AutomationColumn } from '../../taskboard/types'
import styles from './automations-list-modal.module.scss'
import { useModal } from '@src/hooks/useModal'
import EditAutomationModal from './edit-automation'
import { toast } from 'react-toastify'
import AutomationItem from '../components/AutomationItem/automation-item.component'
import { OpenAutomationModalProps } from '../types'

interface IProps {
  automation_column_id: number
  isOpen: boolean
  onClose: () => void
}

const AutomationsListModal = ({ automation_column_id, isOpen, onClose = () => null }: IProps) => {
  const queryClient = useQueryClient()
  const [automation, setAutomation] = useState<AutomationColumn | null>(null)
  const [autmationList, setAutomationList] = useState<Automation[]>([])

  const [isAutomationOpened, handleOpenAutomation, closeAutomation] = useModal(false)
  const [selectedAutomation, setSelectedAutomation] = useState<OpenAutomationModalProps | null>(
    null,
  )

  const automationListParams = {
    filter: `referrence_column_id||$eq||${automation_column_id}`,
    limit: 999,
  }
  useQuery(
    [GLOBAL_QUERIES.AUTOMATIONS.GET_AUTOMATION_COLUMN_BY_ID, automation_column_id],
    () => axios.get(`${REACT_APP_API_URL}/board-columns/${automation_column_id}`),
    {
      enabled: !!automation_column_id && isOpen,
      select: (data) => data.data,
      onSuccess: (data) => setAutomation(data),
    },
  )

  useQuery(
    [GLOBAL_QUERIES.AUTOMATIONS.GET_AUTOMATION_LIST, automation_column_id],
    () => axios.get(`${REACT_APP_API_URL}/automations`, { params: automationListParams }),
    {
      enabled: !!automation_column_id && isOpen,
      onSuccess: (data) => setAutomationList(data as any),
      select: (data) => data.data.data,
    },
  )
  const handleSelectAutomation = (openAutomationModalProps: OpenAutomationModalProps) => {
    setSelectedAutomation(openAutomationModalProps)
    handleOpenAutomation()
  }

  const handleDeleteAutomation = async (id: number) => {
    try {
      await axios.delete(`${REACT_APP_API_URL}/automations/${id}`)

      queryClient.invalidateQueries([
        GLOBAL_QUERIES.AUTOMATIONS.GET_AUTOMATION_LIST,
        automation_column_id,
      ])
      toast.success('Automation deleted')
    } catch (error) {
      console.log({ error })
      toast.error('Something went wrong')
    }
  }

  const handleCloseAutomation = () => {
    closeAutomation()
    setSelectedAutomation(null)
  }

  const handleModalReset = () => {
    setSelectedAutomation((prev) => (prev ? { id: prev.id } : null))
  }

  const handleSuccessUpdate = () => {
    queryClient.invalidateQueries([
      GLOBAL_QUERIES.AUTOMATIONS.GET_AUTOMATION_LIST,
      automation_column_id,
    ])
  }

  if (!automation_column_id) return
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={styles.wrapper}>
        <div>Title: {automation?.title}</div>
        <div className={styles.automationColumn}>
          {autmationList?.map((automation, i) => {
            return (
              <AutomationItem
                key={`${automation.title}-${automation.id}-${i}`}
                automation={automation}
                onAutomationClick={handleSelectAutomation}
                onDeleteAutomationClick={handleDeleteAutomation}
              />
            )
          })}
          {autmationList?.length === 0 && <div>No automations set</div>}
        </div>
      </div>
      <EditAutomationModal
        automation={selectedAutomation?.id || null}
        isOpen={isAutomationOpened}
        onClose={handleCloseAutomation}
        resetModals={handleModalReset}
        isEmailOpen={selectedAutomation?.isEmailOpen}
        isSmsOpen={selectedAutomation?.isSmsOpen}
        isTaskOpen={selectedAutomation?.isTaskOpen}
        onSuccessUpdate={handleSuccessUpdate}
      />
    </Modal>
  )
}

export default AutomationsListModal
