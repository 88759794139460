import React, { Fragment } from 'react'
import { Button, Col } from 'reactstrap'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import { reduxForm } from 'redux-form'
import { withTranslation } from 'react-i18next'
import Services from '../../../../../shared/Services.js'
import { withRouter } from 'react-router'
import SimpleReactValidator from 'simple-react-validator'
import moment from 'moment'
import DebtorService from '../../../../../services/DebtorService'
import Geosuggest from 'react-geosuggest'

const callResultOption = [
  { value: '0', label: 'SW' },
  { value: '1', label: 'S/OFF' },
  { value: '2', label: 'NHOP/P' },
  { value: '3', label: 'CNBC' },
  { value: '4', label: 'Do Not Ring' },
  { value: '5', label: 'Disconnected' },
]

const phoneSelectOption = [
  { value: 'mobile_secondary_phone', label: 'Mobile Secondary Phone' },
  { value: 'direct_phone', label: 'Direct Phone' },
  { value: 'extension_phone', label: 'Extension Phone' },
  { value: 'fax_phone', label: 'Fax Phone' },
  { value: 'office_phone', label: 'Office Phone' },
]

const australianState = [
  {
    label: 'New South Wales',
    value: 'NSW',
  },
  {
    label: 'Victoria',
    value: 'VIC',
  },
  {
    label: 'Queensland',
    value: 'QLD',
  },
  {
    label: 'Tasmania',
    value: 'TAS',
  },
  {
    label: 'South Australia',
    value: 'SA',
  },
  {
    label: 'Western Australia',
    value: 'WA',
  },
  {
    label: 'Northern Territory',
    value: 'NT',
  },
  {
    label: 'Australian Capital Territory',
    value: 'ACT',
  },
]

const RelationOption = [
  { value: '0', label: 'Ar' },
  { value: '1', label: 'BL' },
  { value: '2', label: 'Bo' },
  { value: '3', label: 'Brother' },
  { value: '4', label: 'Cousin' },
  { value: '5', label: 'Daughter' },
  { value: '6', label: 'DL' },
  { value: '7', label: 'EH' },
  { value: '8', label: 'EW' },
  { value: '9', label: 'Father' },
  { value: '10', label: 'FL' },
  { value: '11', label: 'BL' },
  { value: '12', label: 'GD' },
  { value: '13', label: 'GF' },
  { value: '14', label: 'Gi' },
  { value: '15', label: 'GM' },
  { value: '16', label: 'Hu' },
  { value: '17', label: 'ML' },
  { value: '18', label: 'Mother' },
  { value: '19', label: 'Nethew' },
  { value: '20', label: 'Niece' },
  { value: '21', label: 'Other' },
  { value: '22', label: 'Pa' },
  { value: '23', label: 'Sister' },
  { value: '24', label: 'SL' },
  { value: '22', label: 'Son' },
  { value: '23', label: 'Uncle' },
  { value: '24', label: 'Wife' },
]

class AddDebtor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: 1,
      sDate: null,
      eDate: null,
      adddebtor: false,
      onClosed: true,
      AdddebtorDialog: false,
      judgement_date: '',
      served_date: '',
      debt_cleared_by_tenant: false,
      debt_cleared_by_bankruptcy: false,
      debt_cleared_by_insurance: false,
      debt_cleared_by_other: false,

      payment_arranged: false,
      amount_received: '',
      part_payment_period: '',
      payment_by: '',
      last_payment: '',
      special_payment: false,
      paymentracking: '',
      AssociatedropDown: true,

      pay_plan_debtor_id: '',
      pay_plan_days: '',
      pay_plan_amount: '',
      pay_plan_source: '',
      pay_plan_first_due: '',

      debtors: [],
      email: [''],

      contact_1_first: null,
      contact_1_last: null,

      contact_2_first: null,
      contact_2_last: null,

      contactId: null,
      phone_1: null,
      mobile: null,
      phone_2: null,
      date_of_birth: null,
      date_of_entry: null,
      wipID: '',
      editID: null,
      startdate: null,
      enddate: null,

      file_number: null,
      total_claim: 0,
      total_received: 0,
      total_recov_cost: 0,
      paid_to_agent: 0,

      allDebtor: [],
      subject: [],
      AdddebtordropDown: true,
      PaymentdropDown: true,

      errorModal: false,
      errorMessage: '',
      errorType: '',

      isAlias: false,
      debtorId: null,
      address_hist: [
        {
          id: Math.floor(Math.random() * (1 + 100)) + 1,
          address_1: null,
          address_2: null,
          address_3: null,
          suburb: null,
          state: null,
          postcode: null,
          country: null,
          history_start_date: null,
          history_end_date: null,
          history_rank: null,
          city: 'Riga',
          is_current: true,
          is_service: false,
          edit: false,
        },
        {
          id: Math.floor(Math.random() * (1 + 100)) + 1,
          address_1: null,
          address_2: null,
          address_3: null,
          suburb: null,
          state: null,
          postcode: null,
          country: null,
          history_start_date: null,
          history_end_date: null,
          history_rank: null,
          city: 'Riga',
          is_current: false,
          is_service: true,
          edit: false,
        },
      ],
      //associate details
      relations: [],
      references: [],
      work_place: [],
      other: [],

      relationsDropDown: false,
      status_phone: null,
      home_phone: true,
      work_phone: true,
      mobile_phone: true,
      mobile_secondary_phone: false,
      direct_phone: false,
      extension_phone: false,
      fax_phone: false,
      office_phone: false,
      comms_ph_direct: '',
      comms_ph_extension: '',
      comms_ph_fax: '',
      comms_ph_mobile_secondary: '',
      comms_ph_office: '',
    }

    this.debtor = new DebtorService()
    this.services = new Services()
    this.changeInput = this.changeInput.bind(this)
    this.validator = new SimpleReactValidator()
    this.validatorDE = new SimpleReactValidator()
    this.validatorAddr = new SimpleReactValidator()
    this.validatorRelation = new SimpleReactValidator()
    this.validatorReference = new SimpleReactValidator()
    this.validatorOther = new SimpleReactValidator()
    this.validatorWorkPlace = new SimpleReactValidator()
  }

  componentDidMount() {
    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.wipID
    ) {
      this.setState({ wipID: this.props.location.state.wipID })
    }
    if (this.props.debtorId) {
      this.setState({ debtorId: this.props.debtorId })
      this.getDebtor(this.props.debtorId)
    }
  }

  getDebtor(id) {
    const params = {
      id: id,
      join: ['addresses', 'contact'],
    }
    this.debtor.getDebtorId(params).then((res) => {
      if (res.status === 200) {
        const getData = res.data
        if (getData) {
          getData.date_of_entry = this.dateFormate(getData.date_of_entry)
          getData.date_of_birth = this.dateFormate(getData.date_of_birth)

          getData.relations &&
            getData.relations.map((rl) => {
              rl.id = Math.floor(Math.random() * (1 + 100)) + 1
              rl.relationship = RelationOption.find((_) => _.label === rl.relationship)
              rl.call_result = callResultOption.find((_) => _.label === rl.call_result)
              rl.openTab = false
            })

          getData.references &&
            getData.references.map((re) => {
              re.id = Math.floor(Math.random() * (1 + 100)) + 1
              re.call_result = callResultOption.find((_) => _.label === re.call_result)
              re.openTab = false
            })

          getData.work_places &&
            getData.work_places.map((wp) => {
              wp.id = Math.floor(Math.random() * (1 + 100)) + 1
              wp.call_result = callResultOption.find((_) => _.label === wp.call_result)
              wp.openTab = false
            })

          getData.other &&
            getData.other.map((o) => {
              o.id = Math.floor(Math.random() * (1 + 100)) + 1
              o.call_result = callResultOption.find((_) => _.label === o.call_result)
              o.openTab = false
            })
          this.setState({
            allData: getData,
            other: getData.other && getData.other.length > 0 ? getData.other : this.state.other,
            work_place:
              getData.work_places && getData.work_places.length > 0
                ? getData.work_places
                : this.state.work_place,
            relations:
              getData.relations && getData.relations.length > 0
                ? getData.relations
                : this.state.relations,
            references:
              getData.references && getData.references.length > 0
                ? getData.references
                : this.state.references,
            address_hist:
              getData.addresses && getData.addresses.length > 0
                ? getData.addresses.sort((a, b) => {
                  if (a.is_service === b.is_service) {
                    return 0;
                  }
                
                  if (a.is_service === false) {
                    return -1;
                  }
                
                  return 1;
                })
                : this.state.address_hist,

            contact_1_first: getData.name_first,
            contact_1_last: getData.name_last,
            contact_2_first: getData.second_name_first,
            contact_2_last: getData.second_name_last,
            isAlias: !!(getData.second_name_first || getData.second_name_last),
            home_phone: true,
            work_phone: true,
            mobile_phone: true,
            mobile_secondary_phone: !!getData.contact?.comms_ph_mobile_secondary,
            direct_phone: !!getData.contact?.comms_ph_direct,
            extension_phone: !!getData.contact?.comms_ph_extension,
            fax_phone: !!getData.contact?.comms_ph_fax,
            office_phone: !!getData.contact?.comms_ph_office,
            drivers_licence: getData.drivers_licence,
            vehicle_reg: getData.vehicle_reg,
            state_drivers_licence: australianState.find(({value}) => value === getData.state_drivers_licence) || null ,
            state_vehicle_licence: australianState.find(({value}) => value === getData.state_vehicle_licence) || null ,
            email: getData.contact?.comms_email,
            phone_1: getData.contact?.comms_ph_home,
            mobile: getData.contact?.comms_ph_mobile,
            phone_2: getData.contact?.comms_ph_work,

            comms_ph_direct: getData.contact?.comms_ph_direct,
            comms_ph_extension: getData.contact?.comms_ph_extension,
            comms_ph_fax: getData.contact?.comms_ph_fax,
            comms_ph_mobile_secondary: getData.contact?.comms_ph_mobile_secondary,
            comms_ph_office: getData.contact?.comms_ph_office,

            contactId: getData.contact?.id,
            date_of_entry: this.dateFormate(getData.date_of_entry),
            date_of_birth: this.dateFormate(getData.date_of_birth),
          })
        }
      } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
        this.setState({ accessErrorModal: true })
      }
    })
  }

  dateFormate(dateNew) {
    let fDate
    if (dateNew && dateNew !== '0000-00-00') {
      if (dateNew) {
        const date = moment(dateNew, 'YYYY-MM-DDTHH:mm:ss.sssZ').format('YYYY-MM-DDTHH:mm:ss.sssZ')
        fDate = ''
        if (date === 'Invalid date') {
          fDate = new Date(dateNew.slice(0, dateNew.length - 2).replace(/\//g, '-'))
        } else {
          fDate = new Date(date)
        }
      } else {
        return ''
      }
      if (fDate === 'Invalid Date' || fDate === 'Invalid date') {
        return ''
      } else {
        return fDate
      }
    }
  }

  handleOptionChange = (e, name) => {
    this.setState({ [name]: e.target.checked })
  }

  changeInputInner(e, name, id) {
    if (!e.target.validity.valid) {
      return null
    }
    if (name === 'Address') {
      this.state.address_hist.map((item) => {
        if (item.id === id) {
          item[e.target.name] = e.target.value
        }
      })
    }
    if (name === 'Relations') {
      this.state.relations.map((item) => {
        if (item.id === id) {
          item[e.target.name] = e.target.value
        }
      })
    }
    if (name === 'Reference') {
      this.state.references.map((item) => {
        if (item.id === id) {
          item[e.target.name] = e.target.value
        }
      })
    }
    if (name === 'Other') {
      this.state.other.map((item) => {
        if (item.id === id) {
          item[e.target.name] = e.target.value
        }
      })
    }
    if (name === 'Work_Place') {
      this.state.work_place.map((item) => {
        if (item.id === id) {
          item[e.target.name] = e.target.value
        }
      })
    }
    console.log('force update')
    this.forceUpdate()
  }

  handleChange = (selectedOption, name) => {
    this.setState({ [name]: selectedOption })
  }

  handleChangeArray = (selectedOption, name, keyName, id) => {
    if (keyName === 'Relations') {
      this.state.relations.map((item) => {
        if (item.id === id) {
          item[name] = selectedOption
        }
      })
    }
    if (keyName === 'Reference') {
      this.state.references.map((item) => {
        if (item.id === id) {
          item[name] = selectedOption
        }
      })
    }
    if (keyName === 'Work_Place') {
      this.state.work_place.map((item) => {
        if (item.id === id) {
          item[name] = selectedOption
        }
      })
    }
    if (keyName === 'Other') {
      this.state.other.map((item) => {
        if (item.id === id) {
          item[name] = selectedOption
        }
      })
    } else {
      this.setState({ [name]: selectedOption })
    }
    this.forceUpdate()
  }

  onClosed = () => {
    if (!this.state.editID) {
      this.setState({
        address_hist: [
          {
            id: Math.floor(Math.random() * (1 + 100)) + 1,
            address_1: null,
            address_2: '',
            address_3: '',
            suburb: '',
            state: '',
            postcode: null,
            country: '',
            history_start_date: '',
            history_end_date: '',
            history_rank: '',
          },
        ],

        relations: [
          {
            id: Math.floor(Math.random() * (1 + 100)) + 1,
            name: '',
            relationship: '',
            phone: '',
            address: '',
            call_result: '',
            call_note: '',
          },
        ],

        references: [
          {
            id: Math.floor(Math.random() * (1 + 100)) + 1,
            name: '',
            phone: '',
            email: '',
            address: '',
            call_result: '',
            call_note: '',
          },
        ],

        work_place: [
          {
            id: Math.floor(Math.random() * (1 + 100)) + 1,
            name: '',
            phone: '',
            email: '',
            contact: '',
            address: '',
            call_result: '',
            call_note: '',
          },
        ],

        other: [
          {
            id: Math.floor(Math.random() * (1 + 100)) + 1,
            name: '',
            phone: '',
            email: '',
            address: '',
            call_result: '',
            call_note: '',
          },
        ],
      })
    }
    this.setState({
      contact_1_first: '',
      contact_1_last: '',
      email: [''],
      phone_1: '',
      mobile: '',
      phone_2: '',
      date_of_birth: '',
      date_of_entry: '',
      AdddebtorDialog: false,
      editID: '',
    })

    this.validatorDE.hideMessages()
    this.validatorAddr.hideMessages()
    this.validatorRelation.hideMessages()
    this.validatorReference.hideMessages()
    this.validatorOther.hideMessages()
    this.validatorWorkPlace.hideMessages()
  }

  handleChangeDate = (date, name) => {
    this.setState({
      [name]: date,
    })
  }

  handleChangeDateAddress = (date, name) => {
    this.setState({
      [name]: date,
    })
  }

  handleOptionChangeRadio = (e) => {
    this.setState({
      app_type: e.currentTarget.value,
      otherSpacify: '',
    })
  }

  saveDebtor = () => {
    const transformedRelations =
      this.state.relations &&
      this.state.relations.map(({ id, ...rest }) => {
        if (rest.name && rest.relationship) {
          rest.relationship = rest.relationship ? rest.relationship.label : null
          rest.call_result = rest.call_result ? rest.call_result.label : null
          return rest
        }
      })

    this.state.address_hist &&
      this.state.address_hist.map((hist) => {
        if (hist.address_1) {
          hist.debtor = { id: +this.state.debtorId }
          hist.history_start_date = hist.history_start_date ? hist.history_start_date : null
          hist.history_end_date = hist.history_end_date ? hist.history_end_date : null
          hist.postcode = hist.postcode ? hist.postcode : null
          hist.country = hist.country ? hist.country : null
          hist.state = hist.state ? hist.state : null
          hist.suburb = hist.suburb ? hist.suburb : null
          return hist
        }
      })
    this.state.work_place &&
      this.state.work_place.map((wp) => {
        if (wp.name) {
          delete wp.id
          wp.call_result = wp.call_result ? wp.call_result.label : null
          return wp
        }
      })
    this.state.other &&
      this.state.other.map((o) => {
        if (o.name) {
          delete o.id
          o.call_result = o.call_result ? o.call_result.label : null
          return o
        }
      })
    this.state.references &&
      this.state.references.map((ref) => {
        if (ref.name) {
          delete ref.id
          ref.call_result = ref.call_result ? ref.call_result.label : null
          return ref
        }
      })

    const data = {
      id: this.state.debtorId,
      date_of_entry: this.state.date_of_entry,
      date_of_birth: this.state.date_of_birth,
      name_first: this.state.contact_1_first || null,
      name_last: this.state.contact_1_last || null,
      drivers_licence: this.state.drivers_licence,
      vehicle_reg: this.state.vehicle_reg,
      state_drivers_licence: this.state.state_drivers_licence?.value || null,
      state_vehicle_licence: this.state.state_vehicle_licence?.value || null,
      second_name_first: this.state.isAlias ? this.state.contact_2_first : null,
      second_name_last: this.state.isAlias ? this.state.contact_2_last : null,
      references: this.state.references[0]?.id ? [] : this.state.references,
      relations: transformedRelations.length === 0 ? [] : transformedRelations,
      work_places: this.state.work_place[0]?.id ? [] : this.state.work_place,
      other: this.state.other[0]?.id ? [] : this.state.other,
      wips: [{ id: +this.state.wipID }],
      security: 'null',
      title: 'null',
      name_sms: 'null',
    }

    const newAddress = this.state.address_hist.filter((e) => e.edit === false)
    if (newAddress) {
      newAddress.map(({ id: _, ...el }) => ({ ...el }))
    }

    const editAddress = this.state.address_hist;

    const dataContact = {
      debtor: { id: this.state.debtorId },
      id: this.state.contactId || null,
      comms_email: this.state.email?.filter((email) => email) || [],
      comms_ph_home: this.state.home_phone ? this.state.phone_1 || null : null,
      comms_ph_mobile: this.state.mobile_phone ? this.state.mobile || null : null,
      comms_ph_work: this.state.work_phone ? this.state.phone_2 || null : null,
      comms_ph_direct: this.state.direct_phone ? this.state.comms_ph_direct || null : null,
      comms_ph_extension: this.state.extension_phone ? this.state.comms_ph_extension || null : null,
      comms_ph_fax: this.state.fax_phone ? this.state.comms_ph_fax || null : null,
      comms_ph_mobile_secondary: this.state.mobile_secondary_phone
        ? this.state.comms_ph_mobile_secondary || null
        : null,
      comms_ph_office: this.office_phone ? this.state.comms_ph_office || null : null,
    }

    const validators = [
      this.validatorRelation,
      this.validatorReference,
      this.validatorWorkPlace,
      this.validatorOther,
      this.validator,
      this.validatorAddr,
      this.validatorDE,
    ]
    const filteredValidators = validators.filter(
      (validator, index) => index + 1 === this.state.activeTab || index + 1 > 4,
    )

    const isDataValid = filteredValidators.map((validator) => validator.allValid())
    if (!isDataValid.includes(false)) {
      if (this.state.debtorId === null) {
        this.debtor.addDebtor(data).then((res) => {
          if (res.status === 201) {
            const filteredEmails = this.state.email.filter((email) => email)
            const dataContact = {
              debtor: { id: res.data.id },
              id: this.state.contactId || null,
              comms_email: filteredEmails,
              comms_ph_home: this.state.phone_1 || null,
              comms_ph_mobile: this.state.mobile || null,
              comms_ph_work: this.state.phone_2 || null,
              comms_ph_direct: this.state.comms_ph_direct || null,
              comms_ph_extension: this.state.comms_ph_extension || null,
              comms_ph_fax: this.state.comms_ph_fax || null,
              comms_ph_mobile_secondary: this.state.comms_ph_mobile_secondary || null,
              comms_ph_office: this.state.comms_ph_office || null,
            }
            const idDeb = this.debtor.addDebtorContact(dataContact).then((res) => {
              if (res.status === 201) {
                this.services.successToast('Contact update successfully')
                this.props.getAllDebtorsWIPS(+this.state.wipID)
              } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
                this.setState({ accessErrorModal: true })
              } else {
                this.services.errorToast(res.message)
              }
            })

            const newAddress = this.state.address_hist.map((e) => ({
              ...e,
              id: null,
              debtor: { id: res.data.id },
            }))

            if (newAddress && res.data.id !== 0) {
              this.debtor.addDebtorAddress(newAddress).then((res) => {
                if (res.status === 201) {
                  this.services.successToast('Address update successfully')
                  this.props.getAllDebtorsWIPS(+this.state.wipID)
                } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
                  this.setState({ accessErrorModal: true })
                } else {
                  this.services.errorToast(res.message)
                }
              })
            }
            this.services.successToast('Debtor updated successfully')
            this.props.getAllDebtorsWIPS(+this.state.wipID)
            this.props.onClosed()
          } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
            this.setState({ accessErrorModal: true })
          } else {
            this.services.errorToast(res.message)
          }
        })
      }

      if (this.state.debtorId) {
        this.debtor.editDebtor(data).then((res) => {
          if (res.status === 200) {
            this.services.successToast('Debtor updated successfully')
            this.props.getAllDebtorsWIPS(+this.state.wipID)
            this.props.onClosed()
          } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
            this.setState({ accessErrorModal: true })
          } else {
            this.services.errorToast(res.message)
          }
        })
      }
      // if (newAddress.length && newAddress[0]?.debtor?.id !== 0) {
      //   console.log('addDebtorAddress2', newAddress)
      //   this.debtor.addDebtorAddress(newAddress).then((res) => {
      //     if (res.status === 201) {
      //       this.services.successToast('Address update successfully')
      //       this.props.getAllDebtorsWIPS(+this.state.wipID)
      //     } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
      //       this.setState({ accessErrorModal: true })
      //     } else {
      //       this.services.errorToast(res.message)
      //     }
      //   })
      // }
      if (this.state.debtorId && editAddress.length) {
        this.debtor.editDebtorAddress(editAddress).then((res) => {
          if (res.status === 201) {
            this.services.successToast('Address update successfully')
            this.props.getAllDebtorsWIPS(+this.state.wipID)
            this.props.onClosed()
          } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
            this.setState({ accessErrorModal: true })
          } else {
            this.services.errorToast(res.message)
          }
        })
      }
      if (dataContact.id === null && this.state.debtorId) {
        this.debtor.addDebtorContact(dataContact).then((res) => {
          if (res.status === 201) {
            this.services.successToast('Address update successfully')
            this.props.getAllDebtorsWIPS(+this.state.wipID)
          } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
            this.setState({ accessErrorModal: true })
          } else {
            this.services.errorToast(res.message)
          }
        })
      }
      if (dataContact.id) {
        this.debtor.editDebtorContact(dataContact).then((res) => {
          if (res.status === 201) {
            this.services.successToast('Address update successfully')
            this.props.getAllDebtorsWIPS(+this.state.wipID)
          } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
            this.setState({ accessErrorModal: true })
          } else {
            this.services.errorToast(res.message)
          }
        })
      }
    } else {
      filteredValidators.map((validator) => validator.showMessages())
      this.forceUpdate()
    }
  }

  changeInput(e) {
    if (!e.target.validity.valid) {
      return null
    }
    this.setState({ [e.target.name]: e.target.value })
  }


  handleChangeSelect = (name, value) => {
    this.setState({
      [name]: value,
    })
  }

  changeTab = (e, tab) => {
    const { activeTab } = this.state
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
        task_id: '',
      })
    }
  }

  changeAddressRank = (e, id) => {
    const numericValue = Number(e.target.value)
    if (!Number.isNaN(Number(e.target.value)) && numericValue <= 2 ** 31 - 1 && id) {
      this.state.address_hist.map(function (item) {
        if (item.id === id && e.target.name) {
          item.history_rank = +e.target.value
        }
      })
      this.forceUpdate()
    }
  }

  handleChangeDataAddress = (date, name, id) => {
    this.state.address_hist.map(function (item) {
      if (item.id === id && name) {
        item[name] = date
      }
    })
    this.forceUpdate()
  }

  addAddressList = () => {
    if (this.validatorAddr.allValid()) {
      const data = {
        id: Math.floor(Math.random() * (1 + 100)) + 1,
        address_1: null,
        address_2: '',
        address_3: '',
        suburb: '',
        state: '',
        postcode: null,
        country: '',
        history_start_date: '',
        history_end_date: '',
        history_rank: '',
        edit: false,
        city: 'Riga',
      }

      const list = this.state.address_hist || []
      if (list) {
        list.push(data)
        this.setState({ address_hist: list })
        this.validatorAddr.hideMessages()
      }
    } else {
      this.validatorAddr.showMessages()
      this.forceUpdate()
    }
  }

  removeAddress = (id) => {
    const details = this.state.address_hist.filter((_) => _.id !== id)
    this.setState({
      address_hist: details,
    })
    this.debtor.deleteDebtorAddress(id).then((res) => {
      if (res.status === 200) {
        this.services.successToast('Address delete successfully')
      } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
        this.setState({ accessErrorModal: true })
      } else {
        this.services.errorToast(res.message)
      }
    })
  }

  addRelation = () => {
    if (this.validatorRelation.allValid()) {
      const data = {
        id: Math.floor(Math.random() * (1 + 100)) + 1,
        name: '',
        relationship: '',
        phone: '',
        address: '',
        call_result: '',
        call_note: '',
        openTab: true,
      }
      const list = this.state.relations || []
      if (list) {
        list.push(data)
        this.setState({ relations: list })
        this.validatorRelation.hideMessages()
      }
    } else {
      this.validatorRelation.showMessages()
      this.forceUpdate()
    }
  }

  removeRelation = (id) => {
    const details = this.state.relations.filter((_) => _.id !== id)
    this.setState({
      relations: details,
    })
  }

  addReference = () => {
    if (this.validatorReference.allValid()) {
      const data = {
        id: Math.floor(Math.random() * (1 + 100)) + 1,
        name: '',
        phone: '',
        address: '',
        call_result: '',
        call_note: '',
        openTab: true,
      }
      const list = this.state.references || []
      if (list) {
        list.push(data)
        this.setState({ references: list })
        this.validatorReference.hideMessages()
      }
    } else {
      this.validatorReference.showMessages()
      this.forceUpdate()
    }
  }

  removeReference = (id) => {
    const details = this.state.references.filter((_) => _.id !== id)
    this.setState({
      references: details,
    })
  }

  addOther = () => {
    if (this.validatorOther.allValid()) {
      const data = {
        id: Math.floor(Math.random() * (1 + 100)) + 1,
        name: '',
        phone: '',
        email: '',
        address: '',
        call_result: '',
        call_note: '',
        openTab: true,
      }
      const list = this.state.other || []
      if (list) {
        list.push(data)
        this.setState({ other: list })
        this.validatorOther.hideMessages()
      }
    } else {
      this.validatorOther.showMessages()
      this.forceUpdate()
    }
  }

  removeOther = (id) => {
    const details = this.state.other.filter((_) => _.id !== id)
    this.setState({
      other: details,
    })
  }

  addWorkPlace = () => {
    if (this.validatorWorkPlace.allValid()) {
      const data = {
        id: Math.floor(Math.random() * (1 + 100)) + 1,
        name: '',
        phone: '',
        email: '',
        address: '',
        call_result: '',
        call_note: '',
        openTab: true,
      }
      const list = this.state.work_place || []
      if (list) {
        list.push(data)
        this.setState({ work_place: list })
        this.validatorWorkPlace.hideMessages()
      }
    } else {
      this.validatorWorkPlace.showMessages()
      this.forceUpdate()
    }
  }

  removeWorkPlace = (id) => {
    const details = this.state.work_place.filter((_) => _.id !== id)
    this.setState({ work_place: details })
  }

  addEmail = () => {
    const email = [...this.state.email, '']
    this.setState({ email: email })
  }

  removeEmail = (e, i) => {
    const data = this.state.email
    data.splice(i, 1)
    this.setState({ email: data })
  }

  changeEmail = (e, data, i) => {
    const newData = this.state.email
    newData.forEach((element, j) => {
      if (i === j) {
        newData[j] = e.target.value
      }
    })
    this.setState({ email: newData })
  }

  makeCurrentAddress(id) {
    const addr = this.state.address_hist.find((_) => _.id === id)
    const oldAddr = this.state.address_hist.find((_) => _.is_current === true)
    if (addr && this.state.debtorId) {
      const dataCurrent = {
        id: id,
        address_1: addr.address_1,
        address_2: addr.address_2,
        address_3: addr.address_3,
        suburb: addr.suburb,
        state: addr.state,
        postcode: addr.postcode,
        country: addr.country,
        history_start_date: addr.history_start_date,
        history_end_date: addr.history_end_date,
        history_rank: addr.history_rank,
        is_current: true,
        is_service: false,
        city: 'Riga',
        debtor: { id: +this.state.debtorId },
        is_international: addr.is_international,
      }
      const dataOldCurrent = {
        id: oldAddr.id,
        address_1: oldAddr.address_1,
        address_2: oldAddr.address_2,
        address_3: oldAddr.address_3,
        suburb: oldAddr.suburb,
        state: oldAddr.state,
        postcode: oldAddr.postcode,
        country: oldAddr.country,
        history_start_date: oldAddr.history_start_date,
        history_end_date: oldAddr.history_end_date,
        history_rank: oldAddr.history_rank,
        is_current: false,
        city: 'Riga',
        debtor: { id: +this.state.debtorId },
        is_international: oldAddr.is_international,
      }
      if (dataCurrent.is_international || dataOldCurrent.is_international) {
        this.debtor.editDebtorAddress([dataCurrent, dataOldCurrent]).then((res) => {
          if (res) {
            this.services.successToast('Current address update successfully')
          } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
            this.setState({ accessErrorModal: true })
          } else {
            this.services.errorToast(res.message)
          }
        })
      }
      if (dataCurrent.edit === false) {
      console.log('addDebtorAddress3', dataCurrent)
        this.debtor.addDebtorAddress([dataCurrent]).then((res) => {
          if (res) {
            this.services.successToast('Current address update successfully')
          } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
            this.setState({ accessErrorModal: true })
          } else {
            this.services.errorToast(res.message)
          }
        })
      }
    }
  }

  slideDownSingleContact = (id) => {
    this.state.relations.map((element) => {
      if (element.id === id) {
        element.openTab = !element.openTab
      }
    })
    this.setState({ itemId: id })
  }

  slideDownSingleReferences = (id) => {
    this.state.references.map((element) => {
      if (element.id === id) {
        element.openTab = !element.openTab
      }
    })
    this.setState({ itemId: id })
  }

  slideDownSingleWorkPlace = (id) => {
    this.state.work_place.map((element) => {
      if (element.id === id) {
        element.openTab = !element.openTab
      }
    })
    this.setState({ itemId: id })
  }

  slideDownSingleOtherPlace = (id) => {
    this.state.other.map((element) => {
      if (element.id === id) {
        element.openTab = !element.openTab
      }
    })
    this.setState({ itemId: id })
  }

  handleChangePhone = (selectedOption) => {
    this.setState({ status_phone: selectedOption })
  }

  addPhone = (selectedOption) => {
    if (this.state[selectedOption.value]) {
      this.setState({ [selectedOption.value]: false })
      return
    }
    this.setState({ [selectedOption.value]: true })
    this.forceUpdate()
  }

  isPhoneSelected(selectedOption) {
    if (!selectedOption) return false
    return this.state[selectedOption.value]
  }

  handleSelectGeoSuggest = (place, id) => {
    if (!place) return null

    const componentForm = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      administrative_area_level_1: 'long_name',
      country: 'long_name',
      postal_code: 'short_name',
    }
    for (let i = 0; i < place.gmaps.address_components.length; i++) {
      const addressType = place.gmaps.address_components[i].types[0]
      if (componentForm[addressType]) {
        const val = place.gmaps.address_components[i][componentForm[addressType]]
        if (val) componentForm[addressType] = val
      }
    }

    const add = this.state.address_hist.map((item) => {
      if (item.id === id) {
        item.address_1 = place.label || 'Current_Address'
        item.address_2 =
          componentForm.street_number !== 'short_name' ? componentForm.street_number : ''
        item.address_3 = componentForm.route !== 'long_name' ? componentForm.route : ''
        item.suburb = componentForm.locality !== 'long_name' ? componentForm.locality : ''
        item.state =
          componentForm.administrative_area_level_1 !== 'long_name'
            ? componentForm.administrative_area_level_1
            : ''
        item.postcode = componentForm.postal_code !== 'short_name' ? componentForm.postal_code : ''
        item.country = componentForm.country !== 'long_name' ? componentForm.country : ''
      }
      return item
    })

    this.setState({ address_hist: add })
  }

  render() {
    const { activeTab, email } = this.state
    return (
      <>
        <div className="w-100 pb-5">
          <div id="contacts_accordion" className="form" style={{ justifyContent: 'flex-end' }}>
            <div className="card pb-0">
              <div
                style={{ justifyContent: 'space-between', alignItems: 'baseline' }}
                className=" d-flex"
              >
                <div className="">
                  <h3 className="page-title">
                    {this.props.debtorId ? 'Edit Debtor' : 'Add Debtor'}
                  </h3>
                </div>
                <div className=" text-right d-flex justify-content-end px-3 pt-2">
                  <Button
                    className="btn btn-primary mr-3 mb-0 popup-btn-primary"
                    onClick={this.saveDebtor}
                  >
                    Save
                  </Button>
                  <button onClick={() => this.props.onClosed()} className="btn-gray m-0">
                    Close
                  </button>
                </div>
              </div>
              <div id="collapseOne" className="collapse show" data-parent="#contacts_accordion">
                <div
                  className={`bg-white hide_contact ${
                    this.state.AdddebtordropDown ? 'active pt-4' : ''
                  }`}
                >
                  <Col md={12} lg={12} className="address_history_section">
                    <div className="row">
                      <div className="col-12 col-md-4 col-lg-4">
                        <div className="form-group">
                          <label className="form__form-group-label text-left w-100" htmlFor="">
                            First Name <span style={{ color: '#ff0000' }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="custom-input"
                            name="contact_1_first"
                            onChange={this.changeInput}
                            value={this.state.contact_1_first || ''}
                          />
                          <span className="form__form-group-error text-left">
                            {this.validator.message(
                              'First Name',
                              this.state.contact_1_first,
                              'required',
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="col-12 col-md-4 col-lg-4">
                        <div className="form-group">
                          <label className="form__form-group-label text-left w-100" htmlFor="">
                            Last Name <span style={{ color: '#ff0000' }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="custom-input"
                            name="contact_1_last"
                            onChange={this.changeInput}
                            value={this.state.contact_1_last || ''}
                          />
                          <span className="form__form-group-error text-left">
                            {this.validator.message(
                              'Last Name',
                              this.state.contact_1_last,
                              'required',
                            )}
                          </span>
                        </div>
                      </div>

                      {!this.state.isAlias && (
                        <div className="col-12 col-md-4 pl-0 Add_Alias text-right">
                          <div className="form-group top-24">
                            <button
                              className="btn-gray"
                              onClick={() => this.setState({ isAlias: !this.state.isAlias })}
                            >
                              Add Other Names
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                    {this.state.isAlias && (
                      <div className="row">
                        <div className="col-12 col-md-4 col-lg-4">
                          <div className="form-group">
                            <label className="form__form-group-label text-left w-100" htmlFor="">
                              {' '}
                              First Name{' '}
                            </label>
                            <input
                              type="text"
                              className="custom-input"
                              name="contact_2_first"
                              onChange={this.changeInput}
                              value={this.state.contact_2_first || ''}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-lg-4">
                          <div className="form-group">
                            <label className="form__form-group-label text-left w-100" htmlFor="">
                              {' '}
                              Last Name{' '}
                            </label>
                            <input
                              type="text"
                              className="custom-input"
                              name="contact_2_last"
                              onChange={this.changeInput}
                              value={this.state.contact_2_last || ''}
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-4 Add_Alias text-right">
                          <div className="form-group top-24">
                            <button
                              className="btn-gray"
                              onClick={() =>
                                this.setState({
                                  isAlias: !this.state.isAlias,
                                  contact_2_first: '',
                                  contact_2_last: '',
                                })
                              }
                            >
                              Remove Other Names
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="row">
                      {email.map((e, i) => {
                        return (
                          <Fragment key={i}>
                            <div className="col-12 col-md-3">
                              <div className="form-group">
                                <label
                                  className="form__form-group-label text-left w-100"
                                  htmlFor=""
                                >
                                  Email
                                </label>
                                <input
                                  className="custom-input"
                                  id="name"
                                  value={e}
                                  name="email"
                                  onChange={(ele) => this.changeEmail(ele, e, i)}
                                />
                                <span className="form__form-group-error text-left w-100">
                                  {this.validator.message('Email', e, 'email')}
                                </span>
                              </div>
                            </div>
                            {i === 0 && (
                              <div className="col-12 col-md-2">
                                <div className="form-group top-24">
                                  <button className="btn btn-primary" style={{height: '35px', padding: '5px 10px'}} onClick={this.addEmail}>
                                    Add Email
                                  </button>
                                </div>
                              </div>
                            )}
                            {i !== 0 && (
                              <div className="col-12 col-md-2">
                                <div className="form-group top-24">
                                  <button
                                    className="btn btn-primary"
                                    style={{width: 'max-content', height: '35px', padding: '5px 10px'}}
                                    onClick={(e) => this.removeEmail(e, i)}
                                  >
                                    Remove Email
                                  </button>
                                </div>
                              </div>
                            )}
                          </Fragment>
                        )
                      })}
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-6 col-lg-6">
                        <div className="form-group">
                          <label className="form__form-group-label text-left w-100">
                            Add Status Phone
                          </label>
                          <Select
                            classNamePrefix="react-select"
                            className="react-select"
                            options={phoneSelectOption}
                            value={this.state.status_phone}
                            onChange={(e) => this.handleChangePhone(e)}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-4 col-lg-4">
                        <Button
                          disabled={!this.state.status_phone}
                          onClick={() => this.addPhone(this.state.status_phone)}
                          style={{ marginTop: '23px' }}
                        >
                          {this.isPhoneSelected(this.state.status_phone)
                            ? 'Remove Phone'
                            : 'Add Phone'}
                        </Button>
                      </div>
                    </div>
                    <div className="row">
                      {this.state.home_phone && (
                        <div className="col-12 col-md-4 col-lg-4">
                          <div className="form-group">
                            <label className="form__form-group-label text-left w-100">
                              Home Phone 
                            </label>
                            <input
                              type="text"
                              className="custom-input"
                              name="phone_1"
                              onChange={this.changeInput}
                              value={this.state.phone_1 || ''}
                              pattern="[0-9]*"
                            />
                            <span className="form__form-group-error text-left">
                              {this.validator.message('', this.state.phone_1, 'numeric')}
                            </span>
                          </div>
                        </div>
                      )}
                      {this.state.mobile_phone && (
                        <div className="col-12 col-md-4 col-lg-4">
                          <div className="form-group">
                            <label className="form__form-group-label text-left w-100">
                              Mobile Phone
                            </label>
                            <input
                              type="text"
                              className="custom-input"
                              name="mobile"
                              onChange={this.changeInput}
                              value={this.state.mobile || ''}
                              pattern="[0-9]*"
                            />
                            <span className="form__form-group-error text-left">
                              {this.validator.message('Mobile', this.state.mobile, 'numeric')}
                            </span>
                          </div>
                        </div>
                      )}
                      {this.state.mobile_secondary_phone && (
                        <div className="col-12 col-md-4 col-lg-4">
                          <div className="form-group">
                            <label className="form__form-group-label text-left w-100">
                              {' '}
                              Mobile Secondary Phone
                            </label>
                            <input
                              type="text"
                              className="custom-input"
                              name="comms_ph_mobile_secondary"
                              onChange={this.changeInput}
                              value={this.state.comms_ph_mobile_secondary || ''}
                              pattern="[0-9]*"
                            />
                          </div>
                        </div>
                      )}
                      {this.state.work_phone && (
                        <div className="col-12 col-md-4 col-lg-4">
                          <div className="form-group">
                            <label className="form__form-group-label text-left w-100">
                              Work Phone
                            </label>
                            <input
                              type="text"
                              className="custom-input"
                              name="phone_2"
                              onChange={this.changeInput}
                              value={this.state.phone_2 || ''}
                              pattern="[0-9]*"
                            />
                            <span className="form__form-group-error text-left">
                              {this.validator.message(
                                'Work Phone',
                                this.state.phone_2,
                                'numeric',
                              )}
                            </span>
                          </div>
                        </div>
                      )}
                      {this.state.direct_phone && (
                        <div className="col-12 col-md-4 col-lg-4">
                          <div className="form-group">
                            <label className="form__form-group-label text-left w-100">
                              Direct Phone
                            </label>
                            <input
                              type="text"
                              className="custom-input"
                              name="comms_ph_direct"
                              onChange={this.changeInput}
                              value={this.state.comms_ph_direct || ''}
                              pattern="[0-9]*"
                            />
                          </div>
                        </div>
                      )}
                      {this.state.extension_phone && (
                        <div className="col-12 col-md-4 col-lg-4">
                          <div className="form-group">
                            <label className="form__form-group-label text-left w-100">
                              {' '}
                              Extension Phone
                            </label>
                            <input
                              type="text"
                              className="custom-input"
                              name="comms_ph_extension"
                              onChange={this.changeInput}
                              value={this.state.comms_ph_extension || ''}
                              pattern="[0-9]*"
                            />
                          </div>
                        </div>
                      )}
                      {this.state.fax_phone && (
                        <div className="col-12 col-md-4 col-lg-4">
                          <div className="form-group">
                            <label className="form__form-group-label text-left w-100">
                              {' '}
                              Fax Phone{' '}
                            </label>
                            <input
                              type="text"
                              className="custom-input"
                              name="comms_ph_fax"
                              onChange={this.changeInput}
                              value={this.state.comms_ph_fax || ''}
                              pattern="[0-9]*"
                            />
                          </div>
                        </div>
                      )}
                      {this.state.office_phone && (
                        <div className="col-12 col-md-4 col-lg-4">
                          <div className="form-group">
                            <label className="form__form-group-label text-left w-100">
                              Office Phone
                            </label>
                            <input
                              type="text"
                              className="custom-input"
                              name="comms_ph_office"
                              onChange={this.changeInput}
                              value={this.state.comms_ph_office || ''}
                              pattern="[0-9]*"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="row mt-3">
                <div className="col-12 col-md-3">
                  <div className="form-group">
                    <label className="form__form-group-label text-left w-100">Drivers Licence</label>
                    <input
                      type="text"
                      className="custom-input"
                      id="drivers_licence"
                      value={this.state.drivers_licence || ''}
                      name="drivers_licence"
                      onChange={(e) => this.changeInput(e)}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className="form-group">
                    <label className="form__form-group-label text-left w-100">Vehicle Registration</label>
                    <input
                      type="text"
                      className="custom-input"
                      id="vehicle_reg"
                      value={this.state.vehicle_reg || ''}
                      name="vehicle_reg"
                      onChange={(e) => this.changeInput(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-3">
                  <div className="form__form-group">
                    <span className="form__form-group-label">State Issued</span>
                    <div className="form__form-group-field ">
                      <Select
                        name="state_drivers_licence"
                        options={australianState}
                        onChange={(e) => this.handleChangeSelect('state_drivers_licence', e)}
                        value={this.state.state_drivers_licence}
                        classNamePrefix="react-select"
                        className="react-select"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className="form__form-group">
                    <span className="form__form-group-label">State Issued</span>
                    <div className="form__form-group-field ">
                      <Select
                        name="state_vehicle_licence"
                        options={australianState}
                        onChange={(e) => this.handleChangeSelect('state_vehicle_licence', e)}
                        value={this.state.state_vehicle_licence}
                        classNamePrefix="react-select"
                        className="react-select"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
                    <div className="row">
                      <div className="col-12 col-md-6 col-lg-6">
                        <div className="form-group">
                          <label className="form__form-group-label text-left w-100">
                            Date Of Birth
                          </label>
                          <div className="date-picker date-picker--interval">
                            <DatePicker
                              selected={
                                this.state.date_of_birth
                                  ? this.dateFormate(this.state.date_of_birth)
                                  : ''
                              }
                              onChange={(e) => this.handleChangeDate(e, 'date_of_birth')}
                              name="date_of_birth"
                              dateFormat="dd/MM/yy"
                              dropDownMode="select"
                              popperPlacement="center"
                              placeholderText="Select Date"
                            />
                            <span className="date_icon">
                              <img src={'./image/calendar.svg'} alt="date" />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-lg-6">
                        <div className="form-group">
                          <label className="form__form-group-label text-left w-100">
                            Date Of Entry
                          </label>
                          <div className="date-picker date-picker--interval">
                            <DatePicker
                              selected={
                                this.state.date_of_entry
                                  ? this.dateFormate(this.state.date_of_entry)
                                  : ''
                              }
                              onChange={(e) => this.handleChangeDate(e, 'date_of_entry')}
                              name="date_of_entry"
                              dateFormat="dd/MM/yy"
                              dropDownMode="select"
                              popperPlacement="center"
                              placeholderText="Select Date"
                            />
                            <span className="date_icon">
                              <img src={'./image/calendar.svg'} alt="date" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center  mt-4 mb-4">
                      <h4 className="card-link "> Address History </h4>
                    </div>
                    {this.state.address_hist &&
                      this.state.address_hist.map((item, i) => {
                        return (
                          <div key={i} className="address_history">
                            <div className="row">
                              <div className="col-12 col-md-8">
                                <div className="form-group">
                                  <label className="form__form-group-label">
                                    Search Address Here
                                  </label>
                                  <Geosuggest
                                    ref={(el) => (this._geoSuggest = el)}
                                    onSuggestSelect={(e) => this.handleSelectGeoSuggest(e, item.id)}
                                    initialValue={''}
                                    country={['AU', 'NZ']}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12 col-md-4">
                                <div className="form-group">
                                  <label className="form__form-group-label text-left w-100">
                                    Address Line 2
                                  </label>
                                  <input
                                    type="text"
                                    className="custom-input"
                                    id="address_2"
                                    name="address_2"
                                    value={item.address_2 ?? ''}
                                    onChange={(e) => this.changeInputInner(e, 'Address', item.id)}
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4">
                                <div className="form-group">
                                  <label className="form__form-group-label text-left w-100">
                                    Address Line 3
                                  </label>
                                  <input
                                    type="text"
                                    className="custom-input"
                                    id="address_3"
                                    name="address_3"
                                    value={item.address_3 ?? ''}
                                    onChange={(e) => this.changeInputInner(e, 'Address', item.id)}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-12 col-md-4">
                                <div className="form-group">
                                  <label className="form__form-group-label text-left w-100">
                                    Suburb
                                  </label>
                                  <input
                                    type="text"
                                    className="custom-input"
                                    id="suburb"
                                    name="suburb"
                                    value={item.suburb ?? ''}
                                    onChange={(e) => this.changeInputInner(e, 'Address', item.id)}
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4">
                                <div className="form-group">
                                  <label className="form__form-group-label text-left w-100">
                                    State
                                  </label>
                                  <input
                                    type="text"
                                    className="custom-input"
                                    id="state"
                                    name="state"
                                    value={item.state ?? ''}
                                    onChange={(e) => this.changeInputInner(e, 'Address', item.id)}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-12 col-md-4">
                                <div className="form-group">
                                  <label className="form__form-group-label text-left w-100">
                                    Post Code
                                  </label>
                                  <input
                                    type="text"
                                    className="custom-input"
                                    id="postcode"
                                    name="postcode"
                                    value={item.postcode ?? ''}
                                    onChange={(e) => this.changeInputInner(e, 'Address', item.id)}
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-md-4">
                                <div className="form-group">
                                  <label className="form__form-group-label text-left w-100">
                                    Country
                                  </label>
                                  <input
                                    type="text"
                                    className="custom-input"
                                    id="country"
                                    name="country"
                                    value={item.country ?? ''}
                                    onChange={(e) => this.changeInputInner(e, 'Address', item.id)}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-12 col-md-6 col-lg-6">
                                <div className="form-group">
                                  <label
                                    className="form__form-group-label text-left w-100"
                                    htmlFor=""
                                  >
                                    Start Date
                                  </label>
                                  <div className="date-picker date-picker--interval">
                                    <DatePicker
                                      selected={
                                        item.history_start_date
                                          ? this.dateFormate(item.history_start_date)
                                          : ''
                                      }
                                      onChange={(e) =>
                                        this.handleChangeDataAddress(
                                          e,
                                          'history_start_date',
                                          item.id,
                                        )
                                      }
                                      name="address_hist_start"
                                      dateFormat="dd/MM/yy"
                                      dropDownMode="select"
                                      popperPlacement="center"
                                      placeholderText="Select Date"
                                    />
                                    <span className="date_icon">
                                      <img src={'/image/calendar.svg'} alt="date" />
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 col-md-6 col-lg-6">
                                <div className="form-group">
                                  <label
                                    className="form__form-group-label text-left w-100"
                                    htmlFor=""
                                  >
                                    End Date
                                  </label>
                                  <div className="date-picker date-picker--interval">
                                    <DatePicker
                                      selected={
                                        item.history_end_date
                                          ? this.dateFormate(item.history_end_date)
                                          : ''
                                      }
                                      onChange={(e) =>
                                        this.handleChangeDataAddress(e, 'history_end_date', item.id)
                                      }
                                      name="address_hist_end"
                                      dateFormat="dd/MM/yy"
                                      dropDownMode="select"
                                      popperPlacement="center"
                                      placeholderText="Select Date"
                                    />
                                    <span className="date_icon">
                                      <img src={'/image/calendar.svg'} alt="" />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-12 col-md-6 col-lg-6">
                                <div className="row">
                                  <div className="col-12 col-md-8 col-lg-8">
                                    <div className="form-group text-left">
                                      <label className="form__form-group-label" htmlFor="">
                                        Rank
                                      </label>
                                      <input
                                        type="text"
                                        className="custom-input"
                                        name="history_rank"
                                        value={item.history_rank || ''}
                                        onChange={(e) => this.changeAddressRank(e, item.id)}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-3 col-lg-3 d-flex">
                                    <div className="form-group">
                                      <div className="form-group top-24 position-relative">
                                        {this.state.debtorId && item.address_1 && (
                                          <button
                                            style={{ width: '150px' }}
                                            className="btn-gray"
                                            // disabled={item.is_current}
                                            disabled={true}
                                            // onClick={() => this.makeCurrentAddress(item.id)}
                                          >
                                            {item.is_current ? 'Current Address' : 'Service Address'}
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 col-md-6 col-lg-6">
                                <div className="row">
                                  <div className="col-12 d-flex">
                                    <div className="form-group">
                                      {/* <div className="form-group top-24 position-relative">
                                        {i === this.state.address_hist.length - 1 && this.state.address_hist.length < 2 && (
                                          <>
                                            <button
                                              className="btn-gray mr-3"
                                              onClick={() => this.addAddressList()}
                                            >
                                              Add Address
                                            </button>
                                          </>
                                        )}

                                        {i < this.state.address_hist.length && (
                                          <button
                                            className="btn-gray"
                                            onClick={this.removeAddress.bind(this, item.id)}
                                          >
                                            Remove
                                          </button>
                                        )}
                                      </div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                  </Col>
                </div>
              </div>

              <div className="slide_down_wrap mb-4 rounded mt-5">
                <div className="d-flex align-items-center">
                  <h3 className="page-title mb-4 " data-toggle="collapse">
                    {' '}
                    Associate Details{' '}
                  </h3>
                </div>

                <Col md={12} lg={12} className="popup_tabs_nav mt-3 mb-3">
                  <div className="tabs_nav  bg-white d-flex flex-wrap justify-content-between">
                    <ul className="nav nav-tabs" role="tablist">
                      <li className="nav-item">
                        <span
                          className={activeTab === 1 ? 'nav-link active' : 'nav-link'}
                          id="Relations-link"
                          data-toggle="tab"
                          role="tab"
                          aria-controls="overview"
                          aria-selected="true"
                          onClick={(e) => this.changeTab(e, 1)}
                        >
                          Relations
                        </span>
                      </li>

                      <li className="nav-item">
                        <span
                          className={activeTab === 2 ? 'nav-link active' : 'nav-link'}
                          id="tools-link"
                          data-toggle="tab"
                          role="tab"
                          aria-controls="overview"
                          aria-selected="true"
                          onClick={(e) => this.changeTab(e, 2)}
                        >
                          References
                        </span>
                      </li>

                      <li className="nav-item">
                        <span
                          className={activeTab === 3 ? 'nav-link active' : 'nav-link'}
                          id="tools-link"
                          data-toggle="tab"
                          role="tab"
                          aria-controls="overview"
                          aria-selected="true"
                          onClick={(e) => this.changeTab(e, 3)}
                        >
                          Work Place
                        </span>
                      </li>

                      <li className="nav-item">
                        <span
                          className={activeTab === 4 ? 'nav-link active' : 'nav-link'}
                          id="tools-link"
                          data-toggle="tab"
                          role="tab"
                          aria-controls="overview"
                          aria-selected="true"
                          onClick={(e) => this.changeTab(e, 4)}
                        >
                          Other
                        </span>
                      </li>
                    </ul>
                    <div className="d-flex align-items-center justify-content-md-end m-3"></div>
                  </div>

                  <div style={{ height: '704px' }} className="tab-content">
                    <div
                      className={activeTab === 1 ? 'tab-pane fade show active' : 'tab-pane fade'}
                      id="Relations-tab"
                      role="tabpanel"
                      aria-labelledby="Relations-tab"
                    >
                      <div
                        className={`d-flex flex-wrap align-items-center justify-content-between rounded mb-5`}
                      >
                        <h3>List Relations</h3>
                        <button className="btn-gray" onClick={() => this.addRelation()}>
                          {' '}
                          Add Relation
                        </button>
                      </div>
                      <hr />
                      {this.state.relations &&
                        this.state.relations.map((item) => {
                          const open = item.openTab
                          return (
                            <div key={item.id} className="slide_down_wrap mb-4 rounded">
                              <div
                                style={{ padding: '13px 30px 13px 30px !important' }}
                                className=" bg-white pl-0 rounded "
                              >
                                <div
                                  onClick={() => this.slideDownSingleContact(item.id)}
                                  className={`slide_drop_down boxshadow-none cursor-pointer d-flex flex-wrap 
                                  align-items-center justify-content-between rounded ${
                                    open ? 'active' : ''
                                  }`}
                                >
                                  <div className="d-flex align-items-center">
                                    <div style={{ minWidth: '150px' }}>
                                      <h4>{item.name}</h4>
                                    </div>
                                    <div style={{ minWidth: '150px' }}>
                                      <h4>{item?.relationship?.label}</h4>
                                    </div>
                                    <div style={{ minWidth: '150px' }}>
                                      <h4>{item.phone}</h4>
                                    </div>
                                    <div style={{ minWidth: '300px' }}>
                                      <h4>{item.address}</h4>
                                    </div>
                                  </div>

                                  <span className="show_dropdown">
                                    <button
                                      className="btn-gray mr-3"
                                      onClick={() => this.removeRelation(item.id)}
                                    >
                                      {' '}
                                      Remove{' '}
                                    </button>
                                    <svg
                                      className="ml-2"
                                      version="1.1"
                                      id="Capa_1"
                                      x="0px"
                                      y="0px"
                                      width="14px"
                                      height="14px"
                                      viewBox="0 0 284.929 284.929"
                                    >
                                      <g>
                                        <path
                                          d="M282.082,76.511l-14.274-14.273c-1.902-1.906-4.093-2.856-6.57-2.856c-2.471,0-4.661,0.95-6.563,2.856L142.466,174.441
                                            L30.262,62.241c-1.903-1.906-4.093-2.856-6.567-2.856c-2.475,0-4.665,0.95-6.567,2.856L2.856,76.515C0.95,78.417,0,80.607,0,83.082
                                            c0,2.473,0.953,4.663,2.856,6.565l133.043,133.046c1.902,1.903,4.093,2.854,6.567,2.854s4.661-0.951,6.562-2.854L282.082,89.647
                                            c1.902-1.903,2.847-4.093,2.847-6.565C284.929,80.607,283.984,78.417,282.082,76.511z"
                                        />
                                      </g>{' '}
                                      <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g>{' '}
                                      <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g>{' '}
                                      <g> </g>{' '}
                                    </svg>
                                  </span>
                                </div>

                                <div
                                  className={`bg-white hide_contact ${open ? 'active pt-4' : ''}`}
                                >
                                  <div className="row repeat_tab form">
                                    <div className="col-12 col-md-12 col-lg-12">
                                      <div className="form-group text-left">
                                        <label className="form__form-group-label" htmlFor="">
                                          {' '}
                                          Name: <span style={{ color: '#ff0000' }}>*</span>
                                        </label>
                                        <input
                                          type="text"
                                          className="custom-input"
                                          name="name"
                                          onChange={(e) =>
                                            this.changeInputInner(e, 'Relations', item.id)
                                          }
                                          value={item.name || ''}
                                        />
                                        <span className="form__form-group-error text-left">
                                          {this.validatorRelation.message(
                                            'Name',
                                            item.name,
                                            'required',
                                          )}
                                        </span>
                                      </div>
                                    </div>

                                    <div className="col-12 col-md-12 col-lg-12">
                                      <div className="form-group">
                                        <label className="form__form-group-label" htmlFor="">
                                          {' '}
                                          Relationship: <span style={{ color: '#ff0000' }}>*</span>
                                        </label>
                                        <Select
                                          classNamePrefix="react-select"
                                          className="react-select"
                                          options={RelationOption}
                                          value={item.relationship || ''}
                                          onChange={(e) =>
                                            this.handleChangeArray(
                                              e,
                                              'relationship',
                                              'Relations',
                                              item.id,
                                            )
                                          }
                                        />
                                        <span className="form__form-group-error text-left">
                                          {this.validatorRelation.message(
                                            'Relationship',
                                            item.relationship,
                                            'required',
                                          )}
                                        </span>
                                      </div>
                                    </div>

                                    <div className="col-12 col-md-12 col-lg-12">
                                      <div className="form-group text-left">
                                        <label className="form__form-group-label" htmlFor="">
                                          {' '}
                                          Phone:{' '}
                                        </label>
                                        <input
                                          type="text"
                                          className="custom-input"
                                          pattern="[0-9]*"
                                          name="phone"
                                          value={item.phone || ''}
                                          onChange={(e) =>
                                            this.changeInputInner(e, 'Relations', item.id)
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="col-12 col-md-12 col-lg-12">
                                      <div className="form-group text-left">
                                        <label className="form__form-group-label" htmlFor="">
                                          {' '}
                                          Email:{' '}
                                        </label>
                                        <input
                                          type="text"
                                          className="custom-input"
                                          name="email"
                                          value={item.email || ''}
                                          onChange={(e) =>
                                            this.changeInputInner(e, 'Relations', item.id)
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="col-12 col-md-12 col-lg-12">
                                      <div className="form-group">
                                        <label
                                          className="form__form-group-label text-left w-100"
                                          htmlFor=""
                                        >
                                          {' '}
                                          Address:{' '}
                                        </label>
                                        <input
                                          type="text"
                                          className="custom-input"
                                          name="address"
                                          value={item.address || ''}
                                          onChange={(e) =>
                                            this.changeInputInner(e, 'Relations', item.id)
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="col-12 col-md-12 col-lg-12">
                                      <div className="form-group text-left">
                                        <label className="form__form-group-label" htmlFor="">
                                          {' '}
                                          Call Result:{' '}
                                        </label>
                                        <Select
                                          classNamePrefix="react-select"
                                          className="react-select"
                                          options={callResultOption}
                                          value={item.call_result || ''}
                                          name="assoc_call_result"
                                          onChange={(e) =>
                                            this.handleChangeArray(
                                              e,
                                              'call_result',
                                              'Relations',
                                              item.id,
                                            )
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="col-12 col-md-12 col-lg-12">
                                      <div className="form-group">
                                        <label
                                          className="form__form-group-label text-left w-100"
                                          htmlFor=""
                                        >
                                          {' '}
                                          Call Note:{' '}
                                        </label>
                                        <textarea
                                          className="custom-input"
                                          name="call_note"
                                          value={item.call_note || ''}
                                          onChange={(e) =>
                                            this.changeInputInner(e, 'Relations', item.id)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="d-flex pr-3 justify-content-end w-100">
                                      {/*{*/}
                                      {/*  i < this.state.relations.length - 1 && (*/}
                                      {/*    <button className='btn-gray mr-3'*/}
                                      {/*            onClick={() => this.removeRelation(item.id)}> Remove </button>*/}
                                      {/*  )*/}
                                      {/*}*/}
                                      {/*{*/}
                                      {/*  i === this.state.relations.length - 1 &&*/}
                                      {/*  <button className='btn-gray' onClick={() => this.addRelation()}> Add*/}
                                      {/*    Relation </button>*/}
                                      {/*}*/}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr />
                            </div>
                          )
                        })}
                    </div>
                    <div
                      className={activeTab === 2 ? 'tab-pane fade show active' : 'tab-pane fade'}
                      id="References-tab"
                      role="tabpanel"
                      aria-labelledby="References-tab"
                    >
                      <div
                        className={`d-flex flex-wrap align-items-center justify-content-between rounded mb-5`}
                      >
                        <h3>List References</h3>
                        <button className="btn-gray" onClick={() => this.addReference()}>
                          {' '}
                          Add Reference
                        </button>
                      </div>
                      <hr />
                      {this.state.references &&
                        this.state.references.map((item, i) => {
                          const open = item.openTab
                          return (
                            <div key={item.id} className="slide_down_wrap mb-4 rounded">
                              <div
                                style={{ padding: '13px 30px 13px 30px !important' }}
                                className=" bg-white pl-0 rounded "
                              >
                                <div
                                  onClick={() => this.slideDownSingleReferences(item.id)}
                                  className={`slide_drop_down boxshadow-none cursor-pointer d-flex flex-wrap 
                                  align-items-center justify-content-between rounded ${
                                    open ? 'active' : ''
                                  }`}
                                >
                                  <div className="d-flex align-items-center">
                                    <div style={{ minWidth: '150px' }}>
                                      <h4>{item.name}</h4>
                                    </div>
                                    <div style={{ minWidth: '150px' }}>
                                      <h4>{item?.call_result?.label}</h4>
                                    </div>
                                    <div style={{ minWidth: '150px' }}>
                                      <h4>{item.phone}</h4>
                                    </div>
                                    <div style={{ minWidth: '300px' }}>
                                      <h4>{item.address}</h4>
                                    </div>
                                  </div>

                                  <span className="show_dropdown">
                                    <button
                                      className="btn-gray mr-3"
                                      onClick={() => this.removeReference(item.id)}
                                    >
                                      {' '}
                                      Remove{' '}
                                    </button>
                                    <svg
                                      className="ml-2"
                                      version="1.1"
                                      id="Capa_1"
                                      x="0px"
                                      y="0px"
                                      width="14px"
                                      height="14px"
                                      viewBox="0 0 284.929 284.929"
                                    >
                                      <g>
                                        <path
                                          d="M282.082,76.511l-14.274-14.273c-1.902-1.906-4.093-2.856-6.57-2.856c-2.471,0-4.661,0.95-6.563,2.856L142.466,174.441
                                            L30.262,62.241c-1.903-1.906-4.093-2.856-6.567-2.856c-2.475,0-4.665,0.95-6.567,2.856L2.856,76.515C0.95,78.417,0,80.607,0,83.082
                                            c0,2.473,0.953,4.663,2.856,6.565l133.043,133.046c1.902,1.903,4.093,2.854,6.567,2.854s4.661-0.951,6.562-2.854L282.082,89.647
                                            c1.902-1.903,2.847-4.093,2.847-6.565C284.929,80.607,283.984,78.417,282.082,76.511z"
                                        />
                                      </g>{' '}
                                      <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g>{' '}
                                      <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g>{' '}
                                      <g> </g>{' '}
                                    </svg>
                                  </span>
                                </div>
                                <div
                                  className={`bg-white hide_contact ${open ? 'active pt-4' : ''}`}
                                >
                                  <div key={i} className="row repeat_tab form">
                                    <div className="col-12 col-md-12 col-lg-12">
                                      <div className="form-group text-left">
                                        <label className="form__form-group-label" htmlFor="">
                                          {' '}
                                          Name: <span style={{ color: '#ff0000' }}>*</span>
                                        </label>
                                        <input
                                          type="text"
                                          className="custom-input"
                                          name="name"
                                          onChange={(e) =>
                                            this.changeInputInner(e, 'Reference', item.id)
                                          }
                                          value={item.name || ''}
                                        />
                                        <span className="form__form-group-error text-left">
                                          {this.validatorReference.message(
                                            'Name',
                                            item.name,
                                            'required',
                                          )}
                                        </span>
                                      </div>
                                    </div>

                                    <div className="col-12 col-md-12 col-lg-12">
                                      <div className="form-group text-left">
                                        <label className="form__form-group-label" htmlFor="">
                                          {' '}
                                          Phone:{' '}
                                        </label>
                                        <input
                                          type="text"
                                          className="custom-input"
                                          pattern="[0-9]*"
                                          name="phone"
                                          value={item.phone || ''}
                                          onChange={(e) =>
                                            this.changeInputInner(e, 'Reference', item.id)
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="col-12 col-md-12 col-lg-12">
                                      <div className="form-group text-left">
                                        <label className="form__form-group-label" htmlFor="">
                                          {' '}
                                          Email:{' '}
                                        </label>
                                        <input
                                          type="text"
                                          className="custom-input"
                                          name="email"
                                          value={item.email || ''}
                                          onChange={(e) =>
                                            this.changeInputInner(e, 'Reference', item.id)
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="col-12 col-md-12 col-lg-12">
                                      <div className="form-group">
                                        <label
                                          className="form__form-group-label text-left w-100"
                                          htmlFor=""
                                        >
                                          {' '}
                                          Address:{' '}
                                        </label>
                                        <input
                                          type="text"
                                          className="custom-input"
                                          name="address"
                                          value={item.address || ''}
                                          onChange={(e) =>
                                            this.changeInputInner(e, 'Reference', item.id)
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="col-12 col-md-12 col-lg-12">
                                      <div className="form-group text-left">
                                        <label className="form__form-group-label" htmlFor="">
                                          {' '}
                                          Call Result:{' '}
                                        </label>
                                        <Select
                                          classNamePrefix="react-select"
                                          className="react-select"
                                          options={callResultOption}
                                          value={item.call_result}
                                          name="assoc_call_result"
                                          onChange={(e) =>
                                            this.handleChangeArray(
                                              e,
                                              'call_result',
                                              'Reference',
                                              item.id,
                                            )
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="col-12 col-md-12 col-lg-12">
                                      <div className="form-group">
                                        <label
                                          className="form__form-group-label text-left w-100"
                                          htmlFor=""
                                        >
                                          {' '}
                                          Call Note:{' '}
                                        </label>
                                        <textarea
                                          className="custom-input"
                                          name="call_note"
                                          value={item.call_note || ''}
                                          onChange={(e) =>
                                            this.changeInputInner(e, 'Reference', item.id)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="d-flex pr-3 justify-content-end w-100">
                                      {/*{*/}
                                      {/*  i < this.state.references.length - 1 && (*/}
                                      {/*    <button className='btn-gray mr-3'*/}
                                      {/*            onClick={() => this.removeReference(item.id)}> Remove </button>*/}
                                      {/*  )*/}
                                      {/*}*/}
                                      {/*{*/}
                                      {/*  i === this.state.references.length - 1 &&*/}
                                      {/*  <button className='btn-gray' onClick={() => this.addReference()}> Add*/}
                                      {/*    Reference </button>*/}
                                      {/*}*/}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr />
                            </div>
                          )
                        })}
                    </div>

                    <div
                      className={activeTab === 3 ? 'tab-pane fade show active' : 'tab-pane fade'}
                      id="WorkPlace-tab"
                      role="tabpanel"
                      aria-labelledby="WorkPlace-tab"
                    >
                      <div
                        className={`d-flex flex-wrap align-items-center justify-content-between rounded mb-5`}
                      >
                        <h3>List Work Place</h3>
                        <button className="btn-gray" onClick={() => this.addWorkPlace()}>
                          {' '}
                          Add Work Place
                        </button>
                      </div>
                      <hr />
                      {this.state.work_place &&
                        this.state.work_place.map((item, i) => {
                          const open = item.openTab
                          return (
                            <div key={item.id} className="slide_down_wrap mb-4 rounded">
                              <div
                                style={{ padding: '13px 30px 13px 30px !important' }}
                                className=" bg-white pl-0 rounded "
                              >
                                <div
                                  onClick={() => this.slideDownSingleWorkPlace(item.id)}
                                  className={`slide_drop_down boxshadow-none cursor-pointer d-flex flex-wrap 
                                  align-items-center justify-content-between rounded ${
                                    open ? 'active' : ''
                                  }`}
                                >
                                  <div className="d-flex align-items-center">
                                    <div style={{ minWidth: '150px' }}>
                                      <h4>{item.name}</h4>
                                    </div>
                                    <div style={{ minWidth: '150px' }}>
                                      <h4>{item.contact}</h4>
                                    </div>
                                    <div style={{ minWidth: '150px' }}>
                                      <h4>{item.phone}</h4>
                                    </div>
                                    <div style={{ minWidth: '300px' }}>
                                      <h4>{item.address}</h4>
                                    </div>
                                  </div>

                                  <span className="show_dropdown">
                                    <button
                                      className="btn-gray mr-3"
                                      onClick={() => this.removeWorkPlace(item.id)}
                                    >
                                      {' '}
                                      Remove{' '}
                                    </button>
                                    <svg
                                      className="ml-2"
                                      version="1.1"
                                      id="Capa_1"
                                      x="0px"
                                      y="0px"
                                      width="14px"
                                      height="14px"
                                      viewBox="0 0 284.929 284.929"
                                    >
                                      <g>
                                        <path
                                          d="M282.082,76.511l-14.274-14.273c-1.902-1.906-4.093-2.856-6.57-2.856c-2.471,0-4.661,0.95-6.563,2.856L142.466,174.441
                                            L30.262,62.241c-1.903-1.906-4.093-2.856-6.567-2.856c-2.475,0-4.665,0.95-6.567,2.856L2.856,76.515C0.95,78.417,0,80.607,0,83.082
                                            c0,2.473,0.953,4.663,2.856,6.565l133.043,133.046c1.902,1.903,4.093,2.854,6.567,2.854s4.661-0.951,6.562-2.854L282.082,89.647
                                            c1.902-1.903,2.847-4.093,2.847-6.565C284.929,80.607,283.984,78.417,282.082,76.511z"
                                        />
                                      </g>{' '}
                                      <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g>{' '}
                                      <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g>{' '}
                                      <g> </g>{' '}
                                    </svg>
                                  </span>
                                </div>
                                <div
                                  className={`bg-white hide_contact ${open ? 'active pt-4' : ''}`}
                                >
                                  <div key={i} className="row repeat_tab form">
                                    <div className="col-12 col-md-12 col-lg-12">
                                      <div className="form-group text-left">
                                        <label className="form__form-group-label" htmlFor="">
                                          {' '}
                                          Name: <span style={{ color: '#ff0000' }}>*</span>
                                        </label>
                                        <input
                                          type="text"
                                          className="custom-input"
                                          name="name"
                                          onChange={(e) =>
                                            this.changeInputInner(e, 'Work_Place', item.id)
                                          }
                                          value={item.name || ''}
                                        />
                                        <span className="form__form-group-error text-left">
                                          {this.validatorWorkPlace.message(
                                            'Name',
                                            item.name,
                                            'required',
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-12">
                                      <div className="form-group text-left">
                                        <label className="form__form-group-label" htmlFor="">
                                          {' '}
                                          Contact:{' '}
                                        </label>
                                        <input
                                          type="text"
                                          className="custom-input"
                                          pattern="[0-9]*"
                                          name="contact"
                                          value={item.contact || ''}
                                          onChange={(e) =>
                                            this.changeInputInner(e, 'Work_Place', item.id)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-12">
                                      <div className="form-group text-left">
                                        <label className="form__form-group-label" htmlFor="">
                                          {' '}
                                          Phone:{' '}
                                        </label>
                                        <input
                                          type="text"
                                          className="custom-input"
                                          pattern="[0-9]*"
                                          name="phone"
                                          value={item.phone || ''}
                                          onChange={(e) =>
                                            this.changeInputInner(e, 'Work_Place', item.id)
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="col-12 col-md-12 col-lg-12">
                                      <div className="form-group text-left">
                                        <label className="form__form-group-label" htmlFor="">
                                          {' '}
                                          Email:{' '}
                                        </label>
                                        <input
                                          type="text"
                                          className="custom-input"
                                          name="email"
                                          value={item.email || ''}
                                          onChange={(e) =>
                                            this.changeInputInner(e, 'Work_Place', item.id)
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="col-12 col-md-12 col-lg-12">
                                      <div className="form-group">
                                        <label
                                          className="form__form-group-label text-left w-100"
                                          htmlFor=""
                                        >
                                          {' '}
                                          Address:{' '}
                                        </label>
                                        <input
                                          type="text"
                                          className="custom-input"
                                          name="address"
                                          value={item.address || ''}
                                          onChange={(e) =>
                                            this.changeInputInner(e, 'Work_Place', item.id)
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="col-12 col-md-12 col-lg-12">
                                      <div className="form-group text-left">
                                        <label className="form__form-group-label" htmlFor="">
                                          {' '}
                                          Call Result:{' '}
                                        </label>
                                        <Select
                                          classNamePrefix="react-select"
                                          className="react-select"
                                          options={callResultOption}
                                          value={item.call_result || ''}
                                          name="call_result"
                                          onChange={(e) =>
                                            this.handleChangeArray(
                                              e,
                                              'call_result',
                                              'Work_Place',
                                              item.id,
                                            )
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="col-12 col-md-12 col-lg-12">
                                      <div className="form-group">
                                        <label
                                          className="form__form-group-label text-left w-100"
                                          htmlFor=""
                                        >
                                          {' '}
                                          Call Note:{' '}
                                        </label>
                                        <textarea
                                          className="custom-input"
                                          name="call_note"
                                          value={item.call_note || ''}
                                          onChange={(e) =>
                                            this.changeInputInner(e, 'Work_Place', item.id)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="d-flex pr-3 justify-content-end w-100">
                                      {/*{*/}
                                      {/*  i < this.state.work_place.length - 1 && (*/}
                                      {/*    <button className='btn-gray mr-3'*/}
                                      {/*            onClick={() => this.removeWorkPlace(item.id)}> Remove </button>*/}
                                      {/*  )*/}
                                      {/*}*/}
                                      {/*{*/}
                                      {/*  i === this.state.work_place.length - 1 &&*/}
                                      {/*  <button className='btn-gray' onClick={() => this.addWorkPlace()}> Add Work*/}
                                      {/*    Place </button>*/}
                                      {/*}*/}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr />
                            </div>
                          )
                        })}
                    </div>
                    <div
                      className={activeTab === 4 ? 'tab-pane fade show active' : 'tab-pane fade'}
                      id="Other-tab"
                      role="tabpanel"
                      aria-labelledby="Other-tab"
                    >
                      <div
                        className={`d-flex flex-wrap align-items-center justify-content-between rounded mb-5`}
                      >
                        <h3>List Other</h3>
                        <button className="btn-gray" onClick={() => this.addOther()}>
                          {' '}
                          Add Other Associate
                        </button>
                      </div>
                      <hr />

                      {this.state.other &&
                        this.state.other.map((item, i) => {
                          const open = item.openTab
                          return (
                            <div key={item.id} className="slide_down_wrap mb-4 rounded">
                              <div
                                style={{ padding: '13px 30px 13px 30px !important' }}
                                className=" bg-white pl-0 rounded "
                              >
                                <div
                                  onClick={() => this.slideDownSingleOtherPlace(item.id)}
                                  className={`slide_drop_down boxshadow-none cursor-pointer d-flex flex-wrap 
                                  align-items-center justify-content-between rounded ${
                                    open ? 'active' : ''
                                  }`}
                                >
                                  <div className="d-flex align-items-center">
                                    <div style={{ minWidth: '150px' }}>
                                      <h4>{item.name}</h4>
                                    </div>
                                    <div style={{ minWidth: '150px' }}>
                                      <h4>{item?.call_result?.label}</h4>
                                    </div>
                                    <div style={{ minWidth: '150px' }}>
                                      <h4>{item.phone}</h4>
                                    </div>
                                    <div style={{ minWidth: '300px' }}>
                                      <h4>{item.address}</h4>
                                    </div>
                                  </div>

                                  <span className="show_dropdown">
                                    <button
                                      className="btn-gray mr-3"
                                      onClick={() => this.removeOther(item.id)}
                                    >
                                      {' '}
                                      Remove{' '}
                                    </button>
                                    <svg
                                      className="ml-2"
                                      version="1.1"
                                      id="Capa_1"
                                      x="0px"
                                      y="0px"
                                      width="14px"
                                      height="14px"
                                      viewBox="0 0 284.929 284.929"
                                    >
                                      <g>
                                        <path
                                          d="M282.082,76.511l-14.274-14.273c-1.902-1.906-4.093-2.856-6.57-2.856c-2.471,0-4.661,0.95-6.563,2.856L142.466,174.441
                                            L30.262,62.241c-1.903-1.906-4.093-2.856-6.567-2.856c-2.475,0-4.665,0.95-6.567,2.856L2.856,76.515C0.95,78.417,0,80.607,0,83.082
                                            c0,2.473,0.953,4.663,2.856,6.565l133.043,133.046c1.902,1.903,4.093,2.854,6.567,2.854s4.661-0.951,6.562-2.854L282.082,89.647
                                            c1.902-1.903,2.847-4.093,2.847-6.565C284.929,80.607,283.984,78.417,282.082,76.511z"
                                        />
                                      </g>{' '}
                                      <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g>{' '}
                                      <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g>{' '}
                                      <g> </g>{' '}
                                    </svg>
                                  </span>
                                </div>
                                <div
                                  className={`bg-white hide_contact ${open ? 'active pt-4' : ''}`}
                                >
                                  <div key={i} className="row repeat_tab form">
                                    <div className="col-12 col-md-12 col-lg-12">
                                      <div className="form-group text-left">
                                        <label className="form__form-group-label" htmlFor="">
                                          {' '}
                                          Name: <span style={{ color: '#ff0000' }}>*</span>
                                        </label>
                                        <input
                                          type="text"
                                          className="custom-input"
                                          name="name"
                                          onChange={(e) =>
                                            this.changeInputInner(e, 'Other', item.id)
                                          }
                                          value={item.name || ''}
                                        />
                                        <span className="form__form-group-error text-left">
                                          {this.validatorOther.message(
                                            'Name',
                                            item.name,
                                            'required',
                                          )}
                                        </span>
                                      </div>
                                    </div>

                                    <div className="col-12 col-md-12 col-lg-12">
                                      <div className="form-group text-left">
                                        <label className="form__form-group-label" htmlFor="">
                                          {' '}
                                          Phone:{' '}
                                        </label>
                                        <input
                                          type="text"
                                          className="custom-input"
                                          pattern="[0-9]*"
                                          name="phone"
                                          value={item.phone || ''}
                                          onChange={(e) =>
                                            this.changeInputInner(e, 'Other', item.id)
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="col-12 col-md-12 col-lg-12">
                                      <div className="form-group text-left">
                                        <label className="form__form-group-label" htmlFor="">
                                          {' '}
                                          Email:{' '}
                                        </label>
                                        <input
                                          type="text"
                                          className="custom-input"
                                          name="email"
                                          value={item.email || ''}
                                          onChange={(e) =>
                                            this.changeInputInner(e, 'Other', item.id)
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="col-12 col-md-12 col-lg-12">
                                      <div className="form-group">
                                        <label
                                          className="form__form-group-label text-left w-100"
                                          htmlFor=""
                                        >
                                          {' '}
                                          Address:{' '}
                                        </label>
                                        <input
                                          type="text"
                                          className="custom-input"
                                          name="address"
                                          value={item.address || ''}
                                          onChange={(e) =>
                                            this.changeInputInner(e, 'Other', item.id)
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="col-12 col-md-12 col-lg-12">
                                      <div className="form-group text-left">
                                        <label className="form__form-group-label" htmlFor="">
                                          {' '}
                                          Call Result:{' '}
                                        </label>
                                        <Select
                                          classNamePrefix="react-select"
                                          className="react-select"
                                          options={callResultOption}
                                          value={item.call_result || ''}
                                          name="assoc_call_result"
                                          onChange={(e) =>
                                            this.handleChangeArray(
                                              e,
                                              'call_result',
                                              'Other',
                                              item.id,
                                            )
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="col-12 col-md-12 col-lg-12">
                                      <div className="form-group">
                                        <label
                                          className="form__form-group-label text-left w-100"
                                          htmlFor=""
                                        >
                                          {' '}
                                          Call Note:{' '}
                                        </label>
                                        <textarea
                                          className="custom-input"
                                          name="call_note"
                                          value={item.call_note || ''}
                                          onChange={(e) =>
                                            this.changeInputInner(e, 'Other', item.id)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="d-flex pr-3 justify-content-end w-100">
                                      {/*{*/}
                                      {/*  i < this.state.other.length - 1 && (*/}
                                      {/*    <button className='btn-gray mr-3'*/}
                                      {/*            onClick={() => this.removeOther(item.id)}> Remove </button>*/}
                                      {/*  )*/}
                                      {/*}*/}
                                      {/*{*/}
                                      {/*  i === this.state.other.length - 1 &&*/}
                                      {/*  <button className='btn-gray' onClick={() => this.addOther()}> Add Other*/}
                                      {/*    Associate </button>*/}
                                      {/*}*/}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr />
                            </div>
                          )
                        })}
                    </div>
                  </div>
                </Col>
              </div>
            </div>
            <div className=" text-right d-flex justify-content-end px-3 pt-2">
              <Button
                className="btn btn-primary mr-3 mb-0 popup-btn-primary"
                onClick={this.saveDebtor}
              >
                Save
              </Button>
              <button onClick={() => this.props.onClosed()} className="btn-gray m-0">
                Close
              </button>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default reduxForm({
  form: 'add_staff_form_validation',
})(withRouter(withTranslation('common')(AddDebtor)))
