import axios from "axios";
import { CreateDebtorLegalJudgmentDateData } from "../types/debtor-legal-judgment-date.type";
import { DebtorLegalJudgmentDate } from "../types/debtor-legal.type";
import { REACT_APP_API_URL } from "@src/lib/global-variables";
import { Params } from "@src/types/service.type";
import { DeepPartial } from "react-hook-form";

export class DebtorLegalJudgmentDateService {

  async createDebtorLegalJudgmentDate(data: CreateDebtorLegalJudgmentDateData, params?: Params) {
    const res = await axios.post<DebtorLegalJudgmentDate>(`${REACT_APP_API_URL}/debtor-legals/judgment-dates`, data, { params });
    return res.data;
  }

  async updateDebtorLegalJudgmentDate(debtorLegalJudgmentDateId: number, data: DeepPartial<DebtorLegalJudgmentDate>, params?: Params) {
    const res = await axios.patch<DebtorLegalJudgmentDate>(`${REACT_APP_API_URL}/debtor-legals/judgment-dates/${debtorLegalJudgmentDateId}`, data, { params });
    return res.data;
  }

  async deleteDebtorLegalJudgmentDate(debtorLegalJudgmentDateId: number, params?: Params) {
    const res = await axios.delete<DebtorLegalJudgmentDate>(`${REACT_APP_API_URL}/debtor-legals/judgment-dates/${debtorLegalJudgmentDateId}`, { params });
    return res.data;
  }
}