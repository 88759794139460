import Section from '@components/section/section.component'
import SmsTableWipFilter from '@containers/Communication/components/tables/sms-table/sms-table-with-filter.component'
import { FC } from 'react'
import { WipSmsSectionProps } from '../../../types/wip-communication.type'

const WipSmsSection: FC<WipSmsSectionProps> = ({ wipId }) => {
  return (
    <Section padding={false}>
      <SmsTableWipFilter params={{ 'wips.id': wipId }} />
    </Section>
  )
}

export default WipSmsSection
