import Section from '@components/section/section.component'
import SubSection from '@containers/Recovery/Wip/components/UI/sub-section/sub-section.component'
import { Header, Text } from '@containers/Recovery/Wip/components/UI/text/text.component'
import styles from './payment-overview-section.module.scss'
import { FC } from 'react'
import { formatCurrency, parseStringToNumber } from '@containers/Recovery/Wip/utils/format-currency'
import { PaymentOverviewSectionProps } from '../../../types/transaction.type'

const PaymentOverviewSection: FC<PaymentOverviewSectionProps> = ({
  totalClaims,
  totalRecoverableCost,
  totalPayments,
  totalPaidToAgent,
}) => {
  const total =
    parseStringToNumber(totalClaims) +
    parseStringToNumber(totalRecoverableCost) -
    (parseStringToNumber(totalPayments) + parseStringToNumber(totalPaidToAgent))

  return (
    <Section className={styles.paymentOverviewWrapper}>
      <Header>Payment Overview</Header>
      <SubSection p={4} mb={4}>
        <Text fontWeight="normal" color="black">
          (Total Claims + Total Recoverable Cost) -
        </Text>
        <Text fontWeight="normal" color="black">
          (Total Payments + Total Paid To Agent)
        </Text>
      </SubSection>
      <Text fontWeight="normal" color="black">
        Amount Outstanding Ignoring Interest
      </Text>
      <Text fontWeight="bold" color="black" mb={4}>
        ({formatCurrency(totalClaims)} + {formatCurrency(totalRecoverableCost)}) - (
        {formatCurrency(totalPayments)} + {formatCurrency(totalPaidToAgent)}) ={' '}
        {formatCurrency(total)}
      </Text>
    </Section>
  )
}

export default PaymentOverviewSection
