import React from 'react';

import { Grid, Button } from '@mui/material';

import PropertyAddress from './components/PropertyAddress/PropertyAddress';
import TenantsDetails from './components/TenantsDetails/TenantsDetails';
import RecoveryDetails from './components/RecoveryDetails/RecoveryDetails';
import ManagementDetails from './components/ManagementDetails/ManagementDetails';
import OwnerDetails from './components/OwnerDetails/OwnerDetails';
import FormCheckbox from '../../../shared/components/FormCheckbox/FormCheckbox';
import UploadDocuments from '../../../shared/components/UploadDocuments/UploadDocuments';
import CompanyList from '../CompanyList/CompanyList';
import AppButton from '../../../shared/components/AppButton/AppButton';

const CommercialUncoveredForm = ({
  handleChange,
  submitForm,
  values,
  setFieldValue,
  errors,
  touched,
  loading,
}) => {
  return (
    <Grid item>
      <CompanyList
        handleChange={handleChange}
        values={values}
        name="company.id"
        errors={errors}
        touched={touched}
      />

      <PropertyAddress
        handleChange={handleChange}
        values={values}
        errors={errors}
        touched={touched}
      />

      <TenantsDetails
        handleChange={handleChange}
        values={values}
        setFieldValue={setFieldValue}
        errors={errors}
        touched={touched}
      />
      <RecoveryDetails
        handleChange={handleChange}
        values={values}
        errors={errors}
        touched={touched}
      />

      <ManagementDetails
        handleChange={handleChange}
        values={values}
        errors={errors}
        touched={touched}
      />

      <OwnerDetails handleChange={handleChange} values={values} errors={errors} touched={touched} />

      <FormCheckbox
        changeHandler={handleChange}
        label="Is Entered All Tenants"
        name="is_entered_all_tenants"
        value={values?.is_entered_all_tenants}
      />

      <UploadDocuments setFieldValue={setFieldValue} values={values} errors={errors} />

      <AppButton
        onClick={submitForm}
        content="Submit"
        loading={loading}
        buttonStyle="btn-loading py-1 px-2 m-0"
      />
    </Grid>
  );
};

export default CommercialUncoveredForm;
