import { LogLevel } from "@azure/msal-browser";
import { REACT_APP_APPLICATION_CLIENT_ID, REACT_APP_CLIENT_URL, REACT_APP_DIRECTORY_TENANT_ID } from "@src/lib/global-variables";

/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */

export const msalConfig = {
    auth: {
        tenantId: REACT_APP_DIRECTORY_TENANT_ID,
        clientId: REACT_APP_APPLICATION_CLIENT_ID,
        authority: `https://login.microsoftonline.com/${REACT_APP_DIRECTORY_TENANT_ID}/`,
        redirectUri: `${REACT_APP_CLIENT_URL}/login/redirect`,
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            }
        }
    }
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */

const USER_SCOPES = ['User.Read']

const MAIL_SCOPES = ['Mail.ReadBasic', 'Mail.Read', 'Mail.ReadWrite', 'Mail.Send']

enum MailSettingsScopes {
    Read = 'MailboxSettings.Read',
    ReadWrite = 'MailboxSettings.ReadWrite'
}

const MAIL_SETTINGS_SCOPES = [MailSettingsScopes.Read, MailSettingsScopes.ReadWrite]

export const loginRequest = {
    scopes: [...USER_SCOPES, ...MAIL_SCOPES, ...MAIL_SETTINGS_SCOPES]
};

export const GRAPH_API_REQUEST = {
    MESSAGE_RULES: {
        GET: [MailSettingsScopes.Read],
        UPDATE: [MailSettingsScopes.ReadWrite],
        CREATE: [MailSettingsScopes.ReadWrite],
        DELETE: [MailSettingsScopes.ReadWrite]
    }
}

/**
 * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const graphConfig = {
};