import { FC } from 'react'
import { Link } from 'react-router-dom'
import styles from './submit-button.module.scss'
import { Oval } from 'react-loader-spinner'
import cls from 'classnames'

interface SubmitButtonProps {
  children: React.ReactNode
  onClick?: () => void
  disabled?: boolean
  loading?: boolean
  className?: string
  style?: React.CSSProperties
  type?: 'button' | 'link'
  buttonType?: 'button' | 'submit'
  size?: 'small' | 'medium' | 'large'
  url?: string
  buttonStyle?: 'primary' | 'secondary' | 'highlighted' | 'alert'
}

const SubmitButton: FC<SubmitButtonProps> = ({
  className,
  children,
  type,
  url = '',
  onClick,
  loading = false,
  disabled = false,
  size = 'medium',
  buttonType = 'button',
  buttonStyle = 'primary',
  style,
}) => {
  return (
    <>
      {type === 'link' ? (
        <Link
          className={cls(styles.submitButton, styles[size], className, {
            [styles.secondary]: buttonStyle === 'secondary',
            [styles.highlighted]: buttonStyle === 'highlighted',
            [styles.alert]: buttonStyle === 'alert',
          })}
          style={style}
          to={url}
        >
          {children}
        </Link>
      ) : (
        <button
          className={cls(styles.submitButton, styles[size], className, {
            [styles.secondary]: buttonStyle === 'secondary',
            [styles.highlighted]: buttonStyle === 'highlighted',
            [styles.alert]: buttonStyle === 'alert',
            [styles.disabled]: disabled || loading,
          })}
          onClick={onClick}
          type={buttonType}
          style={style}
          disabled={loading || disabled}
        >
          {children}
          {loading ? (
            <Oval
              visible={true}
              height="16"
              width="16"
              color="white"
              secondaryColor="white"
              ariaLabel="oval-loading"
            />
          ) : null}
        </button>
      )}
    </>
  )
}

export default SubmitButton
