import { useGetDebtorById } from '../../../hooks/get-debtor-by-id.hook'
import EditDebtorForm from '../../forms/debtor-form/edit-debtor-form.component'
import { FC, useEffect } from 'react'
import { GET_DEBTOR_BY_ID_PARAMS } from '../../../const/debtor.const'
import SlideModal from '@components/slide-modal'
import styles from './edit-debtor-modal.module.scss'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import cls from 'classnames'
import { Oval } from 'react-loader-spinner'
import { getWipQueryKey } from '@containers/Recovery/Wip/utils/wip-query-factory'
import { Wip } from '@containers/Recovery/Wip/types/wip.type'
import { useQueryClient } from '@tanstack/react-query'
import { EditDebtorModalProps } from '../../../types/debtor-form.type'


const EditDebtorModal: FC<EditDebtorModalProps> = ({ debtorId, wipId, ...modalProps }) => {
  const queryClient = useQueryClient()
  const { data, isLoading, isRefetching } = useGetDebtorById(
    debtorId || 0,
    GET_DEBTOR_BY_ID_PARAMS,
    !!debtorId && modalProps.isOpen,
  )

  const isDebtorLoading = isLoading || isRefetching

  useEffect(() => {
    if (!data) return

    queryClient.setQueryData<Wip>(getWipQueryKey(wipId), (prevData) =>
      prevData
        ? {
            ...prevData,
            debtors: prevData.debtors.map((debtor) => (debtor.id !== data.id ? debtor : data)),
          }
        : undefined,
    )
  }, [data])

  return (
    <SlideModal
      {...modalProps}
      className={styles.modalWrapper}
      layoutStyle={{ zIndex: 1000, backgroundColor: 'black' }}
    >
      <div className={styles.modalContent}>
        <span
          onClick={modalProps.onClose}
          className={cls('lnr lnr-indent-decrease', styles.closeButton)}
        ></span>
        {isDebtorLoading ? (
          <Oval
            visible={true}
            height="60"
            width="60"
            color="blue"
            secondaryColor="blue"
            ariaLabel="oval-loading"
            wrapperStyle={{
              position: 'absolute',
              top: '50%',
              right: '50%',
              transform: 'translate(50%,-50%)',
            }}
          />
        ) : null}
        <Header mb={4}>Edit Debtor</Header>
        {data && !isDebtorLoading ? <EditDebtorForm direction="vertical" debtor={data} /> : null}
      </div>
    </SlideModal>
  )
}

export default EditDebtorModal
