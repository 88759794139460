import { GLOBAL_QUERIES } from "@src/lib/global-queries"
import { useQuery } from "@tanstack/react-query"
import { EmailTemplateService } from "../services/email-template.service"
import { Params } from "@src/types/service.type"

export const useGetEmailTemplates = (params: Params = {}, enabled = true) => {
  const fetchEmailTemplate = async (params: Params = {}) => {
    const emailTemplateService = new EmailTemplateService()
    return emailTemplateService.getTemplates(params)
  }

  const query = useQuery({
    queryKey: [GLOBAL_QUERIES.EMAIL_TEMPLATE.GET, params],
    queryFn: () => fetchEmailTemplate(params),
    enabled: enabled,
    staleTime: 1000 * 60 * 60 * 24 // 24 hours,
  })

  return query;
}