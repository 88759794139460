import { Params } from "@src/types/service.type";
import { DebtorService } from "../services/debtor.service"
import { useQuery } from "@tanstack/react-query";
import { GLOBAL_QUERIES } from "@src/lib/global-queries";

export const useGetDebtorById = (id: number, params: Params = {}, enabled = true) => {

  const getDebtorById = (id: number, params: Params = {}) => {
    const debtorService = new DebtorService();
    return debtorService.getDebtorById(id, params);
  }

  const query = useQuery({ queryKey: [GLOBAL_QUERIES.DEBTOR.GET_DEBTOR_BY_ID, id, params], queryFn: () => getDebtorById(id, params), enabled, retry: 1 })

  return query;
}