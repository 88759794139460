import { DataGridService } from '@components/data-grid/data-grid.service'
import { DataGridColumn, Row } from '@components/data-grid/data-grid.type'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { Header, SubHeader } from '@containers/Recovery/Wip/components/UI/text/text.component'
import { FC, useEffect, useState } from 'react'
import cls from 'classnames'
import styles from '../upload-multiple-properties-form.module.scss'
import DataGrid from '@components/data-grid/data-grid.component'
import Switch from '@components/switch'
import { useValidatePropertiesMutation } from '@containers/Sales/Properties/hooks/crud-property.hook'
import {
  ValidateProperty,
  ValidatePropertyData,
} from '@containers/Sales/Properties/types/property.type'
import LoaderWrapper from '@components/loader/loader-wrapper.component'
import { toast } from 'react-toastify'
import { useModal } from '@src/hooks/useModal'
// import MergePropertiesModal from '@containers/Sales/Client/_modules/Properties/components/modals/merge-properties-modal/merge-properties-modal.component'
import MergePropertyModal from '../../../modals/merge-property-modal/merge-property-modal.component'
import { usePropertiesContext } from '@src/context/properties.context'

interface ProcessStepProps {
  header: DataGridColumn[]
  isRowValid?: (row: Row, header: DataGridColumn[]) => boolean
  data: Record<string, string>[]
  onSuccess?: (data: Record<string, string>[]) => void
}

const ProcessStep: FC<ProcessStepProps> = ({ header, isRowValid, data, onSuccess }) => {
  const {
    setHeader,
    setRows: setPropertyRows,
    setMergePropertiesRows,
    mergePropertyRows,
    setResolvedRowsIds,
  } = usePropertiesContext()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: _, mutateAsync, isLoading } = useValidatePropertiesMutation()
  const [rows, setRows] = useState<Row[]>([])
  const [isRowsWithErrors, setIsRowsWithErrors] = useState(false)
  const [selectedConflictRow, setSelectedConflictRow] = useState<null | Row>(null)

  const discardSelectedConflictRow = () => {
    setSelectedConflictRow(null)
  }
  const [isOpen, handleOpen, handleClose] = useModal(false, discardSelectedConflictRow)

  const handleShowOnlyWithErrors = () => {
    setIsRowsWithErrors((prev) => !prev)
  }

  const discardSelectedRows = () => {
    setRows((prev) => prev.filter((row) => !row.selected))
  }

  const removeSelectedRow = (id: number) => {
    setRows((prev) => prev.filter((row) => +row.id !== id))
    handleClose()
  }
  const invertSelectedRows = () => {
    setRows((prev) =>
      prev.map((row) => {
        return {
          ...row,
          selected: !row.selected,
        }
      }),
    )
  }
  const handleValidateDataFinish = () => {
    onSuccess && onSuccess(DataGridService.transformDataGridRowsToData(rows, header))
  }

  const handleFinish = () => {
    handleValidateDataFinish()
  }

  const transformDataToValidateProperty = (data: Record<string, string>[]) => {
    const validateProperties: ValidateProperty[] = []

    for (const item of data) {
      validateProperties.push({
        address1: item.address1 || '',
        address2: item.address2 || '',
        address3: item.address3 || undefined,
        suburb: item.suburb || '',
        country: item.country || 'Australia',
        state: item.state || '',
        postcode: item.postcode || '',
        email: item.ownerEmail || '',
        phone: item.ownerPhone?.toString() || '',
        firstName: item.firstName || '',
        lastName: item.lastName || '',
      })
    }

    return validateProperties
  }

  const isRowInvalid = (row: Row): boolean => {
    const foundMergePropertyRow = mergePropertyRows.find(
      (mergePropertyRow) => mergePropertyRow.id === row.id,
    )

    if (!foundMergePropertyRow) {
      return false
    }

    return !!foundMergePropertyRow?.property
  }

  const validateRows = async (data: Record<string, string>[]) => {
    const dataToSend: ValidatePropertyData = {
      properties: transformDataToValidateProperty(data),
    }

    try {
      const result = await mutateAsync(dataToSend)
      if (result.every(item => item === null)) {
        return
      }
      setResolvedRowsIds([])
      setMergePropertiesRows(
        result
          .map((property, index) => ({
            id: String(index),
            property,
          }))
          .filter((v) => !!v),
      )
    } catch (e) {
      toast.error('Failed to match properties')
    }
  }

  const handleRowClick = (row: Row) => {
    const isInvalid = isRowInvalid(row)
    setSelectedConflictRow(row)
    if (!isInvalid) {
      toast.success('This row has no coflict records.')
      return
    }

    handleOpen()
  }

  useEffect(() => {
    validateRows(data)
  }, [data])

  useEffect(() => {
    if (header) {
      setHeader(header)
    }
  }, [header])

  useEffect(() => {
    if (rows) {
      setPropertyRows(rows)
    }
  }, [rows])

  return (
    <>
      {isLoading ? (
        <LoaderWrapper />
      ) : (
        <div className={cls(styles.stepWrapper, styles.withoutPadding)}>
          <div className={styles.validateDataStepHeaderWrapper}>
            <Header>Property Merge</Header>
            <div className={styles.validateDataActionsWrapper}>
              <SubmitButton buttonStyle="highlighted" onClick={invertSelectedRows}>
                Invert selected rows
              </SubmitButton>
              <SubmitButton buttonStyle="highlighted" onClick={discardSelectedRows}>
                Discard selected rows
              </SubmitButton>
              <span className={styles.switchWrapper} onClick={handleShowOnlyWithErrors}>
                <Switch value={isRowsWithErrors} onChange={() => {
                }} />
                <SubHeader fontWeight="semibold">Show only rows with errors</SubHeader>
              </span>
            </div>
          </div>
          <div className={styles.validateGridTableWrapper}>
            <DataGrid
              rows={rows}
              onRowsChange={setRows}
              header={header}
              editable={false}
              onRowClick={handleRowClick}
              data={DataGridService.transformRowsToColumnArrayValue(header, data)}
              showOnlyWithErrors={isRowsWithErrors}
              isRowValid={isRowValid}
              isRowInvalid={isRowInvalid}
            />
          </div>
          <div className={styles.formFooter}>
            <SubmitButton
              className={styles.nextStepButton}
              onClick={handleFinish}
              disabled={mergePropertyRows.some(el => el.property !== null)}
              buttonStyle="highlighted"
              size="large"
            >
              Upload
            </SubmitButton>
          </div>
          <MergePropertyModal removeRow={removeSelectedRow} entity={selectedConflictRow} isOpen={isOpen}
                              onClose={handleClose} />
        </div>
      )}
    </>
  )
}

export default ProcessStep
