import React, { Fragment } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import { Button, ButtonGroup, UncontrolledTooltip } from 'reactstrap';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import Services from '../../../shared/Services';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@material-ui/core/IconButton';
import ModalComponent from '../../../shared/components/ModalCustom';
import Popover from '@material-ui/core/Popover';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import TablePagination from '@material-ui/core/TablePagination';
import TasksService from '../../../services/TasksService';
import StaffService from '../../../services/StaffService';
import { UserContext } from '../../../context/user-context';

class ArchiveListTable extends React.Component {
  static contextType = UserContext
  constructor(props) {
    super(props);
    this.state = {
      openArrow: false,
      addTaskModal: false,
      notesDialog: false,
      id: localStorage.getItem('contactsId'),
      order: 'asc',
      orderBy: '',
      selected: new Map([]),
      taskList: [],
      overDue: [],
      toDay: [],
      later: [],
      page: 0,
      rowsPerPage: 5,
      errorModal: false,
      errorMessage: '',
      errorType: '',
      st_id: '',
      accessErrorModal: false,
      app_type: '',
      filter_app_type: [],
      salesList: [],
      recoveryList: [],
      addAppointment: false,
      typeDropdown: false,
      staffDropdown: false,
      allStaff: [],
      taskFilter: 'All',
      staffFilter: 'All',
      anchorEl: null,
      anchorE2: null,
      isArchive: true,
      isdelated: false,
      total: 0,
    };
    this.services = new Services();
    this.task = new TasksService();
    this.staff = new StaffService();
  }

  componentDidMount() {
    this.getTaskList();
    this.getAllstaff();
  }

  getAllstaff() {
    const data = {};
    this.staff.getStaff(data).then((res) => {
      if (res.data) {
        const getData = res.data;
        let listStaff;
        listStaff =
          getData &&
          getData.map((item) => {
            return {
              value: item.id,
              label: `${item.first_name}  ${item.last_name}`,
            };
          });
        this.setState({ allStaff: listStaff });
      } else if (
        res.statusCode === 401 &&
        res.responseType === 'UNAUTHORIZED'
      ) {
        this.setState({ accessErrorModal: true });
      }
    });
  }


  getTaskList = async () => {
    try {
      const params = {
        join: ['companies||name', 'assigned_staff','debtors'],
        page: 0,
        limit: 100,
        filter: `is_archived||$eq||true`,
        sort: 'time_created,DESC',
      };
      const getData = await this.task.getTasks(params);
      this.setState({ taskList: getData, total: getData.length });
      this.filterData(getData);
    } catch (e) {
      this.services.errorToast(e);
    }
  };

  filterData(getData) {
    const overDue = [];
    getData.forEach(data => {
      overDue.push(data);
    });
    if (this.props.completed === true || this.props.unassigned === true) {
      this.handleFilter(overDue);
    } else {
      this.setState({
        overDue: overDue,
      });
    }

  }

  handleFilter = (overDue) => {
    let filteredOverDue;
    if (this.props.completed === true && this.props.unassigned === false) {
      filteredOverDue = overDue.filter(_ => _.is_done === true);
    } else if (this.props.unassigned === true && this.props.completed === false) {
      filteredOverDue = overDue.filter(_ => _.assigned_staff.length === 0);
    } else {
      filteredOverDue = overDue.filter(_ => _.assigned_staff.length === 0 && _.is_done === true);
    }
    this.setState({
      overDue: filteredOverDue,
    });
  };

  editTask = (task) => {
    this.props.editTask(task);
  };

  TypeClick = (event) => {
    this.setState({
      anchorE2: event.currentTarget,
    });
  };
  StaffClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };
  handleClose = () => {
    this.setState({
      anchorEl: null,
      anchorE2: null,
    });
  };


  filterDataByType = (item) => {
    if (item) {
      this.setState({ taskFilter: item });
      let list = [];
      if (item === 'All') {
        this.setState({ taskListCopy: this.state.taskList });
        this.filterData(this.state.taskList);
      } else {
        list = this.state.taskList.filter(_ => _.type === item);
        if (list) {
          this.setState({ taskListCopy: list });
          this.filterData(list);
        }
      }

    }
  };
  filterDataByStaff = (item) => {
    if (item) {
      this.setState({ staffFilter: item });
      let list = [];
      if (item === 'All') {
        this.setState({ taskListCopy: this.state.taskList });
        this.filterData(this.state.taskList);
      } else {
        list = this.state.taskList.filter(task => {
          const tList = task.assigned_staff.find(_ => _.id === item);
          if (tList) {
            return task;
          }
        });
        if (list) {
          this.setState({ taskListCopy: list });
          this.filterData(list);
        }
      }

    }
  };

  statusChange(item) {
    try {
      const {user} = this.context;
      const data = {
        is_done: !item.is_done,
        staff_id_modified: user.id,
      };
      this.tasksService.editTask(data, item.id).then(() => {
        this.services.successToast('Status changed Successfully');
        this.getTaskList();
      });
    } catch (e) {
      this.services.errorToast(e);
    }
  }


  OpenModalDelete = (id) => {
    this.setState({
      deleteId: id,
      isdelated: true,
      errorMessage: 'Are you sure you want to delete this record? ',
      errorModal: true,
      errorType: 'danger',
    });
  };
  OpenModalArchive = (id) => {
    this.setState({
      deleteId: id,
      isArchive: false,
      errorMessage: 'Are you sure you want to unarchived this record? ',
      errorModal: true,
      errorType: 'danger',

    });
  };

  DeleteTask = () => {
    const {user} = this.context;
    const data = {
      id: this.state.deleteId,
      is_archived: this.state.isArchive,
      is_done: false,
      staff_id_modified: user.id,
    };
    if (!this.state.isArchive) {
      this.task.editTask(data, data.id).then((res) => {
        if (res.status === 200) {
          if (this.state.isArchive)
            this.services.successToast('Task Archived Successfully');
          else
            this.services.successToast('Task Deleted Successfully');
          this.getTaskList();
          this.setState({
            errorMessage: '',
            errorModal: false,
            errorType: 'danger',
            isArchive: false,
            deleteId: '',
          });
        } else if (
          res.statusCode === 401 &&
          res.responseType === 'UNAUTHORIZED'
        ) {
          this.setState({ accessErrorModal: true });
        } else {
          this.services.errorToast(res.message);
        }
      });
    } else if (this.state.isdelated) {
      this.task.deleteTask(this.state.deleteId).then((res) => {
        if (res.status === 200) {
          this.services.successToast('Task Deleted Successfully');
          this.getTaskList();
          this.setState({
            errorMessage: '',
            errorModal: false,
            errorType: 'danger',
            isArchive: false,
            deleteId: '',
          });
        } else if (
          res.statusCode === 401 &&
          res.responseType === 'UNAUTHORIZED'
        ) {
          this.setState({ accessErrorModal: true });
        } else {
          this.services.errorToast(res.message);
        }
      });
    }
  };

  ViewTask = (task) => {
    this.props.ViewTask(task);
  };
  clientClick = (item) => {
    localStorage.setItem('contactsId', item.client_input_id);
    this.props.history.push('/contacts');
  };

  handleChangePage = (event, page) => {
    this.setState({ page }, () => {
      this.getTaskList();
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value }, () => {
      this.getTaskList();
    });
  };

  setClientName = (item) => {
    let list;
    const len = item && item.length || 0;
    list = item && item.map((c, i) => {
      return <div><b onClick={() => this.clientClick(c)}
                     className='link_text cursor-pointer'>{c.client_input} {(i !== len - 1) ? ', ' : ''}</b></div>;
    });
    return list;
  };


  render() {
    const open = Boolean(this.state.anchorEl);
    const openType = Boolean(this.state.anchorE2);
    const id = open ? 'simple-popover' : undefined;
    const idopenType = openType ? 'simple-popover' : undefined;
    const {user} = this.context;

    const {
      overDue,
    } = this.state;
    const tasKTypeOption = [{ value: 'Email', label: 'Email' }, { value: 'Call', label: 'Call' }, {
      value: 'Meeting',
      label: 'Meeting',
    },
      { value: 'Task', label: 'Task' }, { value: 'WIP File Day 1', label: 'WIP File Day 1' }, {
        value: 'WIP File Day 2',
        label: 'WIP File Day 2',
      },
      { value: 'WIP File Day 3', label: 'WIP File Day 3' }, {
        value: 'WIP Locate 1',
        label: 'WIP Locate Day 1',
      }, { value: 'WIP Locate 2', label: 'WIP Locate 2' },
      { value: 'WIP Locate 3', label: 'WIP Locate 3' }, { value: 'WIP Legal', label: 'WIP Legal' }];
    let isAccess = true;
    if (user && user.role === 'SALES_USER' || user.role === 'RECOVERY_USER') {
      isAccess = false;
    }
    return (
      <div>

        <h3 className=''>Archived Task</h3>
        <div className='table_wrap table_radius'>
          <Table className='material-table ' id='notesTable' ref={el => (this.componentRef = el)}>
            <thead>
            <tr>
              <th>Due Date</th>
              <th>Type
                <IconButton className='ml-3' aria-label='expand row' size='small' onClick={this.TypeClick}>
                  {this.state.typeDropdown ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
                <Popover
                  id={idopenType}
                  open={openType}
                  anchorEl={this.state.anchorE2}
                  onClose={this.handleClose}
                  className='dropdown_height theme-light ltr-support'
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  {/* {
                              tasKTypeOption.map(item=>{
                              return (
                                  <li key={item.value}>
                                  <div className="form__form-group">
                                      <div className="form__form-group-field">
                                      <label className={`${"colored-click"}${` radio-btn--colored`}`}>
                                          <input className="radio-btn__radio" type="radio" name={item.value} id={item.value} checked={this.state.taskFilter == item.value} onChange={()=>this.filterDataByType(item.value)}/>
                                          <span className="radio-btn__radio-custom" />
                                          <span className="radio-btn__label mt-0">{item.value}</span>
                                      </label>
                                      </div>
                                  </div>
                              </li>
                              ) 
                              })
                          } */}
                  <li className='table_head_dropdown'>
                    <div className='form__form-group'>
                      <div className='form__form-group-field'>
                        <label className={`colored-click radio-btn--colored`}>
                          <input className='radio-btn__radio' type='radio' name='Appointment' id={'Appointment'}
                                 checked={this.state.taskFilter === 'Appointment'}
                                 onChange={() => this.filterDataByType('Appointment')} />
                          <span className='radio-btn__radio-custom' />
                          <span className='radio-btn__label mt-0'>{'Appointment'}</span>
                        </label>
                      </div>
                    </div>
                  </li>
                  <li className='table_head_dropdown'>
                    <div className='form__form-group'>
                      <div className='form__form-group-field'>
                        <label className={`colored-click radio-btn--colored`}>
                          <input className='radio-btn__radio' type='radio' name='Note' id={'Note'}
                                 checked={this.state.taskFilter === 'Note'}
                                 onChange={() => this.filterDataByType('Note')} />
                          <span className='radio-btn__radio-custom' />
                          <span className='radio-btn__label mt-0'>{'Note'}</span>
                        </label>
                      </div>
                    </div>
                  </li>
                  <li className='table_head_dropdown'>
                    <div className='form__form-group'>
                      <div className='form__form-group-field'>
                        <label className={`colored-click radio-btn--colored`}>
                          <input className='radio-btn__radio' type='radio' name='Task' id={'Task'}
                                 checked={this.state.taskFilter === 'Task'}
                                 onChange={() => this.filterDataByType('Task')} />
                          <span className='radio-btn__radio-custom' />
                          <span className='radio-btn__label mt-0'>{'Task'}</span>
                        </label>
                      </div>
                    </div>
                  </li>
                  <li className='table_head_dropdown'>
                    <div className='form__form-group'>
                      <div className='form__form-group-field'>
                        <label className={`colored-click radio-btn--colored`}>
                          <input className='radio-btn__radio' type='radio' name='All' id={'All'}
                                 checked={this.state.taskFilter === 'All'}
                                 onChange={() => this.filterDataByType('All')} />
                          <span className='radio-btn__radio-custom' />
                          <span className='radio-btn__label mt-0'>{'All'}</span>
                        </label>
                      </div>
                    </div>
                  </li>
                </Popover>
              </th>
              <th>Client</th>
              <th>Title</th>
              <th>Wip #</th>
              <th>Subject</th>
              <th className='text-center'>Created On</th>
              <th className='text-center'>Completed On</th>
              <th>Staff
                <IconButton className='ml-3' aria-label='expand row' size='small' aria-describedby={id}
                            onClick={this.StaffClick}>
                  {this.state.staffDropdown ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
                {/* <ul className={`table_head_dropdown ${this.state.staffDropdown ? 'active' : null}`}> */}
                <Popover
                  id={id}
                  open={open}
                  anchorEl={this.state.anchorEl}
                  onClose={this.handleClose}
                  className='dropdown_height theme-light ltr-support'
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >

                  <li className='table_head_dropdown'>
                    <div className='form__form-group'>
                      <div className='form__form-group-field'>
                        <label className={`colored-click radio-btn--colored`}>
                          <input className='radio-btn__radio' type='radio' name='All' id={'All'}
                                 checked={this.state.staffFilter === 'All'}
                                 onChange={() => this.filterDataByStaff('All')} />
                          <span className='radio-btn__radio-custom' />
                          <span className='radio-btn__label mt-0'>{'All'}</span>
                        </label>
                      </div>
                    </div>
                  </li>
                  {
                    this.state.allStaff.map((item, i) => {
                      return (
                        <li key={i} className='table_head_dropdown'>
                          <div className='form__form-group'>
                            <div className='form__form-group-field'>
                              <label className={`colored-click radio-btn--colored`}>
                                <input className='radio-btn__radio' type='radio' name={item.value} id={item.value}
                                       checked={this.state.staffFilter === item.value}
                                       onChange={() => this.filterDataByStaff(item.value)} />
                                <span className='radio-btn__radio-custom' />
                                <span className='radio-btn__label mt-0'>{item.label}</span>
                              </label>
                            </div>
                          </div>
                        </li>
                      );
                    })
                  }
                </Popover>
                {/* </ul> */}
              </th>

              <th className='text-center'>Done</th>
            </tr>
            </thead>
            {/* <TaskTableHead
              numSelected={[...selected].filter(el => el[1]).length}
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              rowCount={recoveryList.length}
            /> */}
            <TableBody>
              {
                this.state.overDue.length === 0 &&
                <TableRow className='material-table__row boxshadow_tbl' style={{ height: 60 }}>
                  <TableCell colSpan={10}
                             className='text-center bg-white material-table__cell  material-table__cell-right rounded-left'>
                    <span>{'None To Display'}</span></TableCell>
                </TableRow>
              }
              {
                overDue.map((item, i) => {
                  const setClient = this.setClientName(item.client_input);
                  return (
                    <Fragment key={item.id}>
                      {/* {i === 0 ? 
                      <TableRow className="material-table__row" role="checkbox" tabIndex={-1} >
                        <TableCell
                            className="material-table__cell material-table__cell-right"
                            scope="row"
                        >OVERDUE </TableCell>
                      </TableRow> : ''} */}
                      <TableRow key={i} className='material-table__row boxshadow_tbl material-table__row bg-white '
                                role='checkbox' tabIndex={-1}>
                        <TableCell
                          width='5%' className='material-table__cell  material-table__cell-right  rounded-left'
                          scope='row'
                        ><span className=''>{moment(item.date_end).format('DD-MM-YY')}</span>
                        </TableCell>

                        <TableCell
                          width='5%' className='material-table__cell  material-table__cell-right '
                        >
                          {item.type}
                        </TableCell>
                        <TableCell
                          width='10%' className='material-table__cell  material-table__cell-right '
                        > {item.companies.map(e => e.name)}
                        </TableCell>
                        <TableCell
                          width='10%' className='material-table__cell  material-table__cell-right '
                        >{item.title}
                        </TableCell>
                        <TableCell
                          width='5%' className='material-table__cell  material-table__cell-right '
                        >{item.wip_id}
                        </TableCell>
                        <TableCell
                          width='10%' className='material-table__cell  material-table__cell-right '
                        >{item.subject}
                        </TableCell>
                        <TableCell className='material-table__cell material-table__cell-right'>
                          {item.time_created ? moment(item.time_created).format('DD-MM-YY hh:mm:A') : ''}
                        </TableCell>
                        <TableCell className='material-table__cell material-table__cell-right'>
                          {item.is_done
                            ? moment(item.time_modified).format(
                              'DD-MM-YY hh:mm:A',
                            )
                            : ' _ '}
                        </TableCell>
                        <TableCell width='30%' className='material-table__cell  material-table__cell-left'>
                          <div className='d-flex flex-wrap align-items-center justify-content-between'>
                            <div className='d-flex flex-wrap round_three align-items-center'>
                              {item.assigned_staff && item.assigned_staff.map((s, i) => {
                                let name;
                                let staff_nm = `${s.first_name} ${s.last_name}`;
                                if (staff_nm && staff_nm.split(' ') && staff_nm.split(' ').length > 2) {
                                  const n = staff_nm.split(' ');
                                  name = n[0][0] + n[2][0];
                                } else {
                                  name = staff_nm && staff_nm.substring(0, 2);
                                }
                                return (
                                  <span key={i} className='rounded-circle uppercase staff_name mb-1 mr-2'
                                        style={{ border: `2px solid ${s.display_color}`, color: `${s.display_color}` }}>
                                    {name}
                                  </span>
                                );
                              })}
                            </div>
                            <div className='d-flex align-items-center'>
                              <ButtonGroup className='btn-group--icons mb-0' dir='ltr'>
                                {isAccess ? <> <Button outline id='Edit' className='mb-0 d-flex align-items-center mr-2'
                                                       onClick={() => this.editTask(item)}>
                                  Edit
                                </Button>

                                  <Button outline id='Delete' className='mb-0 d-flex align-items-center mr-2'
                                          onClick={() => this.OpenModalDelete(item.id)}>
                                    <span className='lnr lnr-trash' />
                                  </Button>
                                  {
                                    item.is_done === true &&
                                    <Button
                                      outline
                                      id='archive'
                                      className='mb-0 d-flex align-items-center'
                                      onClick={() =>
                                        this.OpenModalArchive(item.id)
                                      }
                                    >
                                      <span className='lnr lnr-exit-up' />
                                    </Button>
                                  }

                                </> : <Button outline id='View' className='mb-0 d-flex align-items-center mr-2'
                                              onClick={() => this.ViewTask(item)}>
                                  View
                                </Button>}

                                {/* <IconButton className="ml-3" aria-label="expand row" size="small" onClick={this.setOpen}>
                                        {this.state.openArrow ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                      </IconButton> */}
                              </ButtonGroup>
                              {
                                isAccess ? <>  <UncontrolledTooltip placement='bottom' target='Edit'>
                                  Edit Task
                                </UncontrolledTooltip> <UncontrolledTooltip placement='bottom' target='Delete'>
                                  Delete Task
                                </UncontrolledTooltip>
                                  {
                                    <UncontrolledTooltip placement='bottom' target='archive'>
                                      Unarchive Task
                                    </UncontrolledTooltip>
                                  }

                                </> : <UncontrolledTooltip placement='bottom' target='View'>
                                  View Task
                                </UncontrolledTooltip>
                              }
                            </div>
                          </div>
                        </TableCell>

                        <TableCell width='2%'
                                   className='material-table__cell  material-table__cell-right  btn-light rounded-right'>
                          <div className='round_radio'>
                            <input type='checkbox' id={item.id} checked={item.is_done}
                                   onChange={() => this.statusChange(item)} />
                            <label htmlFor={item.id} />
                          </div>
                        </TableCell>
                      </TableRow>
                      <TableRow className='material-table__row   '>
                        <TableCell colSpan='10' className='p-0 border-0'>
                          <div className='mt-2' />
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  );
                })
              }
            </TableBody>
          </Table>
          <TablePagination
            component='div'
            className='material-table__pagination'
            count={this.state.total}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            backIconButtonProps={{ 'aria-label': 'Previous Page' }}
            nextIconButtonProps={{ 'aria-label': 'Next Page' }}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 15]}
            dir='ltr'
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
          />
        </div>


        {this.state.errorModal ?
          <ModalComponent modal={this.state.errorModal} color={this.state.errorType} isCancel={true}
                          message={this.state.errorMessage} onConfirm={() => this.DeleteTask()}
                          onClose={() => this.setState({
                            errorModal: false,
                            deleteId: '',
                            isArchive: false,
                            isdelated: false,
                          })} /> : null}

      </div>
    );
  }
}

export default connect(null, null, null, { forwardRef: true })(withRouter(ArchiveListTable));
