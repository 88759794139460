import GeoSuggest from 'react-geosuggest'
import cls from 'classnames'
import styles from './form-input.module.scss'
import { Address, LocationData } from './geo-suggest.type'
import { FC } from 'react'

interface GeoSuggestInputProps {
  onSuggestSelect?: (address: Address) => void
  onChange?: (value: string) => void
  value?: string
}

export const GeoSuggestInput: FC<GeoSuggestInputProps> = ({ onChange, onSuggestSelect,  value = '' }) => {
  const handleSelectGeoSuggest = (e: LocationData) => {
    if (!e) return null

    const componentForm = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      administrative_area_level_1: 'long_name',
      country: 'long_name',
      postal_code: 'short_name',
    }

    for (let i = 0; i < e.gmaps.address_components.length; i++) {
      const addressType = e.gmaps.address_components[i].types[0]
      if (componentForm[addressType]) {
        const val = e.gmaps.address_components[i][componentForm[addressType]]
        if (val) componentForm[addressType] = val
      }
    }

    const address: Address = {
      address_1: e.label,
      address_2: componentForm.street_number !== 'short_name' ? componentForm.street_number : null,
      address_3: componentForm.route !== 'long_name' ? componentForm.route : null,
      suburb: componentForm.locality !== 'long_name' ? componentForm.locality : null,
      state:
        componentForm.administrative_area_level_1 !== 'long_name'
          ? componentForm.administrative_area_level_1
          : null,
      postcode: componentForm.postal_code !== 'short_name' ? componentForm.postal_code : null,
      country: componentForm.country !== 'long_name' ? componentForm.country : null,
    }

    onSuggestSelect && onSuggestSelect(address)
  }

  return (
    <GeoSuggest
      className={styles.geoSuggestContainer}
      inputClassName={cls(styles.getSuggestInput, styles.medium)}
      suggestsClassName={styles.geoSuggestList}
      initialValue={value}
      suggestsHiddenClassName={styles.geoSuggestListHidden}
      onSuggestSelect={handleSelectGeoSuggest}
      onChange={onChange}
      country={['AU', 'NZ']}
    />
  )
}
