import React, { useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import Row from './Row/Row';
import AppButton from '../../../../../../../shared/components/AppButton/AppButton';
import { INVOICE_TEMPLATE_ITEM } from '../../../../../../../shared/utils/generateInvoiceFormInitialValues';

import { invoiceFormStyles } from '../../styles';

const FormTemplateItems = ({ handleChange, values, errors, touched, setFieldValue }) => {

  const addItemHandler = () => {
    setFieldValue('item', [...values?.item, INVOICE_TEMPLATE_ITEM]);
  };

  const subtotal = values?.item?.reduce((acc, cur) => {
    return acc + cur?.price * cur?.quantity;
  }, 0);

  useEffect(() => {
    if (subtotal) {
      setFieldValue('item_total', subtotal);
    }
  }, [subtotal]);

  return (
    <Grid sx={invoiceFormStyles.formTemplateItemsContainer}>
      <Grid sx={invoiceFormStyles.formTemplateItemsTableHead}>
        <Typography sx={invoiceFormStyles.itemColumn}>ITEM</Typography>
        <Typography sx={invoiceFormStyles.column}>QTY</Typography>
        <Typography sx={invoiceFormStyles.column}>PRICE/RATE</Typography>
        <Typography sx={invoiceFormStyles.actionColumn}>ACTION</Typography>
      </Grid>

      {values?.item?.map((_, index) => {
        return (
          <Row
            key={index}
            itemIndex={index}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            values={values}
            errors={errors}
            touched={touched}
          />
        );
      })}

      <AppButton content="Add Item" buttonStyle="primary" onClick={addItemHandler} />

      <Grid sx={invoiceFormStyles.formTemplateItemsInfoContainer}>
        <Grid sx={invoiceFormStyles.formTemplateItemsInfo}>
          <Grid sx={invoiceFormStyles.formTemplateInfo}>
            <Typography sx={invoiceFormStyles.title}>Subtotal:</Typography>
            <Typography sx={invoiceFormStyles.subtitle}>${subtotal}</Typography>
          </Grid>

          <Grid sx={invoiceFormStyles.formTemplateInfo}>
            <Typography sx={invoiceFormStyles.title}>GST:</Typography>
            <Typography sx={invoiceFormStyles.subtitle}>$</Typography>
          </Grid>

          <Grid sx={invoiceFormStyles.formTemplateInfo}>
            <Typography sx={invoiceFormStyles.title}>Total Amount Due:</Typography>
            <Typography sx={invoiceFormStyles.subtitle}>${subtotal}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FormTemplateItems;
