import { useEffect, useState } from 'react'
import { useGetWipTemplates } from './hooks/get-wip-templates.hook'
import {
  GET_WIP_TEMPLATES_PARAMS,
  WIP_TEMPLATE_TABLE_HEAD_OPTIONS,
} from './consts/wip-templates.const'
import Table from '@material-ui/core/Table'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableCell from '@mui/material/TableCell'
import Paper from '@mui/material/Paper'
import { WipTemplateItem } from './wip-template-item.component'
import { PaginationType } from '@containers/Setting/components/Contacts/types/contact-list.type'
import { CreateWipTemplateModal } from './components/modals/create-wip-template-modal.component'
import { EditWipTemplateModal } from './components/modals/edit-wip-template-modal.component'
import { WipTemplate } from './types/wip-templates.type'

const WipTemplateForm = () => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false)
  const [selecetedWipTemplate, setSelectedWipTemplate] = useState<number | null>(null)
  const [pagination, setPagination] = useState<PaginationType>({
    page: 0,
    limit: 10,
  })
  const [params, setParams] = useState(GET_WIP_TEMPLATES_PARAMS)
  const [totalCount, setTotalCount] = useState<number>(0)
  const { data, isLoading, isRefetching, isSuccess } = useGetWipTemplates(params)

  const { page, limit } = pagination

  useEffect(() => {
    if (data) {
      setTotalCount(data.total)
    }
  }, [data])

  useEffect(() => {
    setParams((prevState) => ({ ...prevState, limit: pagination.limit, page: page + 1 }))
  }, [pagination])

  const onPageChange = (event, newPage: number) => {
    setPagination((prevState) => ({ ...prevState, page: newPage }))
  }

  const onRowsPerPageChange = (event) => {
    setPagination({
      page: 0,
      limit: parseInt(event.target.value, 10),
    })
  }

  // modals

  const openCreateModal = () => {
    setIsCreateModalOpen(true)
  }

  const closeCreateModal = () => {
    setIsCreateModalOpen(false)
  }

  const openEditModal = (event, data: WipTemplate) => {
    event.stopPropagation()
    setSelectedWipTemplate(data.id)
    setIsEditModalOpen(true)
  }

  const closeEditModal = () => {
    setIsEditModalOpen(false)
  }

  const isDataNotFound = !(isLoading || isRefetching) && !data
  return (
    <div className="container">
      <Paper>
        <div className="d-flex justify-content-end align-items-center p-2">
          <button className="btn btn-secondary m-0" onClick={openCreateModal}>
            Create Wip Tempalte
          </button>
        </div>
        <div className="material-table__wrap">
          <Table stickyHeader aria-label="sticky table" className="material-table">
            <TableHead>
              {WIP_TEMPLATE_TABLE_HEAD_OPTIONS.map((option) => (
                <TableCell
                  sx={{ textAlign: 'center', width: '50%' }}
                  key={option.id}
                  id={option.id}
                >
                  {option.label}
                </TableCell>
              ))}
            </TableHead>
            <TableBody>
              {isDataNotFound && <div>Not found</div>}
              {isSuccess &&
                data.data.map((wipTemplate) => (
                  <WipTemplateItem
                    key={`wip-template-item-${wipTemplate.id}`}
                    wipTemplate={wipTemplate}
                    openEditTemplateModal={openEditModal}
                  />
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            className="material-table__pagination"
            component="div"
            dir="ltr"
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            rowsPerPage={limit}
            count={totalCount}
            page={page}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </div>
        <EditWipTemplateModal
          isOpen={isEditModalOpen}
          onClose={closeEditModal}
          wipTemplateId={selecetedWipTemplate || 0}
        />
        <CreateWipTemplateModal isOpen={isCreateModalOpen} onClose={closeCreateModal} />
      </Paper>
    </div>
  )
}
export default WipTemplateForm
