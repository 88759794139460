import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { generateInvoiceFormInitialValues } from '../../../../../../shared/utils/generateInvoiceFormInitialValues';
import { generateInvoiceFormValidationSchema } from '../../../../../../shared/validators';
import { Grid } from '@mui/material';
import InvoiceForm from '../InvoiceForm';
import dayjs from 'dayjs';
import Loader from '../../../../../loading/loading';

import { useDispatch } from 'react-redux';
import {
  postInvoice,
  getInvoiceById,
  getInvoiceTemplatesList,
} from '../../../../../../redux/actions';

import { IncoveredStyles } from './styles';

const EditInvoice = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  const { values, errors, touched, submitForm, setFieldValue, handleChange, isValid, setValues } =
    useFormik({
      initialValues: generateInvoiceFormInitialValues,
      validationSchema: generateInvoiceFormValidationSchema,
      validateOnChange: true,
      validateOnMount: true,
      onSubmit: () => {
        dispatch(postInvoice(values))
          .then(() => {
            toast.success('Sent successfully');
          })
          .catch(() => {
            toast.error('Something went wrong!');
          });
      },
    });

  const setIncomingValues = (invoice) => {
    dispatch(getInvoiceTemplatesList()).then((templateList) => {
      // If user was selected template then that template was deleted the template_id for current invoice need to be set null
      removeNotMatchedTemplateIds(invoice?.item, templateList?.data);

      setValues({ ...invoice });
      setFieldValue(`due_date`, dayjs(invoice?.due_date).format('YYYY-MM-DD') || null);
      setFieldValue(`current_date`, dayjs(invoice?.current_date).format('YYYY-MM-DD') || null);
      setFieldValue('payment_details.id', invoice?.payment_details_id);
      setLoading(false);
    });
  };

  const removeNotMatchedTemplateIds = (invoiceTemplate, templateList) => {
    if (invoiceTemplate?.length && templateList?.length) {
      invoiceTemplate.forEach((temp) => {
        const isExist = templateList.find(({ id }) => temp?.template_id === id);
        return isExist ? temp : (temp.template_id = '');
      });
    }
  };

  const handleError = () => {
    toast.error('Something went wrong!');
  };

  useEffect(() => {
    if (id) {
      dispatch(getInvoiceById(id)).then(setIncomingValues).catch(handleError);
    } else {
      handleError();
    }
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <Grid item md={12} lg={12}>
      <h3 className="page-title mb-5">Edit Invoice</h3>

      <Grid item sx={IncoveredStyles?.card}>
        <InvoiceForm
          handleChange={handleChange}
          submitForm={submitForm}
          setFieldValue={setFieldValue}
          values={values}
          errors={errors}
          touched={touched}
          isValid={isValid}
          isEditing={true}
          invoiceID={id}
        />
      </Grid>
    </Grid>
  );
};

export default EditInvoice;
