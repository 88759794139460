import { Control, Controller, FieldValues, Path, useController } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import styles from './form-select.module.scss'
import Select from 'react-select'

const filedColorStyles = {
  container: (styles) => ({ ...styles }),
  control: (styles) => ({
    ...styles,
    backgroundColor: '#f9fafb',
    borderRadius: '8px',
    height: '46px',
  }),
  menu: (styles) => ({ ...styles, maxHeight: 150, zIndex: 9999 }),
  menuList: (styles) => ({ ...styles, maxHeight: 150, overflowY: 'scroll' }),
}

const outlinedColorStyles = {
  container: (styles) => ({ ...styles }),
  control: (styles) => ({
    ...styles,
    backgroundColor: 'white',
    borderRadius: '8px',
    height: '46px',
  }),
  menu: (styles) => ({ ...styles, zIndex: 9999 }),
}

interface FormSelectProps<T extends FieldValues> {
  label: string
  control: Control<T>
  name: Path<T>
  options?: { label: string; value: string | number }[]
  className?: string
  style?: 'filled' | 'outlined'
  isMulti?: boolean
  required?: boolean
  disabled?: boolean
}

const FormSelect = <T extends FieldValues>({
  label,
  control,
  options = [],
  name,
  style = 'filled',
  isMulti = false,
  required = false,
  disabled = false,
}: FormSelectProps<T>) => {
  const {
    formState: { errors },
  } = useController({
    name,
    control,
  })

  return (
    <div className={styles.formSelectWrapper}>
      {label ? (
        <span className={styles.formSelectLabelWrapper}>
          <span className={styles.label}>
            {label}
            {required ? <span className={styles.error}>*</span> : null}
          </span>
          <ErrorMessage
            name={name as T[keyof T]}
            errors={errors}
            render={({ message }) => <span className={styles.error}>{message}</span>}
          />
        </span>
      ) : null}
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, onBlur, value } }) => (
          <Select
            value={value}
            options={options}
            onChange={onChange}
            onBlur={onBlur}
            isDisabled={disabled}
            styles={style === 'filled' ? filedColorStyles : outlinedColorStyles}
            isMulti={isMulti}
          />
        )}
      />
    </div>
  )
}

export default FormSelect
