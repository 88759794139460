import React from 'react';
import { invoiceTemplatesTableStyles } from './styles';
import {
  Grid,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { INVOICE_TEMPLATE_TABLE_HEAD } from '../../../../../shared/utils/invoiceTemplatesTableHead';
import moment from 'moment';
import AppButton from '../../../../../shared/components/AppButton/AppButton';
import { TailSpin } from 'react-loader-spinner';

export const InvoiceTemplatesTable = ({
  loading,
  rowData,
  handleEdit,
  handleRemove,
  count,
  page,
  handlePageChange,
}) => {
  const { tableRow, buttonsWrapper, tableContainer, paginationWrapper, loadingWrapper } =
    invoiceTemplatesTableStyles;

  return loading ? (
    <Grid sx={loadingWrapper}>
      <TailSpin height="70" width="70" color="#f69066" ariaLabel="loading" />
    </Grid>
  ) : (
    <TableContainer sx={tableContainer}>
      <Table>
        <EnhancedTableHead />
        <TableBody>
          {rowData?.map((row) => {
            const labelId = `enhanced-table-checkbox-${row.id}`;

            return (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.id} style={tableRow}>
                <TableCell component="th" id={labelId} scope="row" padding="none">
                  {row.time_created ? moment(row.time_created).format('MM-DD-YYYY HH:mm') : '-'}
                </TableCell>
                <TableCell align="left">{row.title}</TableCell>
                <TableCell align="left">{row.subject}</TableCell>
                <TableCell align="right">
                  <Grid sx={buttonsWrapper}>
                    <AppButton
                      content="Edit"
                      buttonStyle="secondary"
                      onClick={() => handleEdit(row.id)}
                    />
                    <AppButton
                      content="Remove"
                      buttonStyle="error"
                      onClick={() => handleRemove(row.id)}
                    />
                  </Grid>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Grid sx={paginationWrapper}>
        <Pagination count={count} page={page} onChange={handlePageChange} />
      </Grid>
    </TableContainer>
  );
};

const EnhancedTableHead = () => {
  return (
    <TableHead>
      <TableRow>
        {INVOICE_TEMPLATE_TABLE_HEAD.map((headCell) => {
          return (
            <TableCell key={headCell.id} padding={headCell.disablePadding ? 'none' : 'normal'}>
              {headCell.label}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};
