import FormInput from '@components/inputs/form-input/form-input.component'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import styles from './debtor-legal-tribunal-form.module.scss'
import { BaseFormProps } from '@src/types/component-types'
import { DebtorLegalTribunalFormValues } from '../../../types/debtor-legal-tribunal.type'
import FormDatePicker from '@components/inputs/form-date-picker/form-date-picker.component'
import { debtorLegalTribunalSchema } from '../../../schemas/legal.schemas'
import { joiResolver } from '@hookform/resolvers/joi'

const DebtorLegalTribunalForm: FC<BaseFormProps<DebtorLegalTribunalFormValues>> = ({
  submitButtonTitle = 'Submit',
  onSuccessSubmit,
  initialValues,
  loading = false,
}) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<DebtorLegalTribunalFormValues>({
    defaultValues: initialValues,
    resolver: joiResolver(debtorLegalTribunalSchema),
  })

  useEffect(() => {
    if (!initialValues) return
    reset(initialValues)
  }, [initialValues])

  const onSubmit = (data: DebtorLegalTribunalFormValues) => {
    onSuccessSubmit(data)
  }

  return (
    <form className={styles.formWrapper} onSubmit={handleSubmit(onSubmit)}>
      <FormInput
        label="Tribunal Location"
        {...register('tribunalLocation')}
        error={errors.tribunalLocation?.message}
      />
      <FormInput
        label="Tribunal CN"
        {...register('tribunalCaseNumber')}
        error={errors.tribunalCaseNumber?.message}
      />
      <FormDatePicker label="Tribunal Order Date" name="tribunalOrderDate" control={control} />
      <SubmitButton className={styles.submitButton} buttonType="submit" loading={loading}>
        {submitButtonTitle}
      </SubmitButton>
    </form>
  )
}

export default DebtorLegalTribunalForm
