import React from 'react'
import { Grid, Typography } from '@mui/material'
import FormSelect from '../../../../../../shared/components/FormSelect/FormSelect'
import FormInput from '../../../../../../shared/components/FormInput/FormInput'
import FormCheckbox from '../../../../../../shared/components/FormCheckbox/FormCheckbox'
import { TextEditor } from '../../../../../../shared/components/TextEditor/TextEditor'
import AppButton from '../../../../../../shared/components/AppButton/AppButton'
import { useSelector } from 'react-redux'

import { emailModalForm } from './styles'
import { postInvoiceEmailLoading } from '../../../../../../redux/selector/selector'
import EmailCheckbox from './EmailCheckbox'
import { formInputStyles } from '../../../../../../shared/components/FormSelect/styles'

export const EmailModalForm = ({
  onEditorChange,
  handleClose,
  handleSend,
  data,
  contacts,
  selectedContacts,
  onTemplateChange,
  handleFieldChange,
  handleSelectEmail,
}) => {
  const loading = useSelector(postInvoiceEmailLoading)
  const { form, buttonsWrapper, emails } = emailModalForm
  const { templateOptions, templateIndex, subject, editorValueText } = data

  return (
    <Grid sx={form}>
      <Grid sx={emails}>
        <Typography sx={formInputStyles.label}>
          To:<span style={{ color: '#ff0000' }}> *</span>
        </Typography>
        {contacts.map((contact) => (
          <EmailCheckbox
            key={contact.id}
            name={contact.first_name}
            value={contact.comm_email}
            checked={selectedContacts.includes(contact.comm_email)}
            handleSelect={handleSelectEmail}
            required
          />
        ))}
      </Grid>
      <FormSelect
        options={templateOptions}
        label="Template:"
        name="invoice_template"
        value={templateIndex}
        geyByIndex
        changeHandler={onTemplateChange}
        column
        required
      />
      <FormInput
        name="subject"
        placeholder="Subject"
        label="Subject:"
        value={subject}
        changeHandler={(e) => handleFieldChange('subject', e.target.value)}
        column
        required
      />
      <Grid>
        <TextEditor value={editorValueText} onEditorChange={onEditorChange} />
      </Grid>
      <Grid sx={buttonsWrapper}>
        <AppButton content="Cancel" buttonStyle="secondary" onClick={handleClose} />
        <AppButton
          content="Send"
          buttonStyle="primary"
          onClick={handleSend}
          loading={loading}
          disabled={loading}
        />
      </Grid>
    </Grid>
  )
}
