import Section from '@components/section/section.component'
import SubSection from '@containers/Recovery/Wip/components/UI/sub-section/sub-section.component'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import styles from './defendant-section.module.scss'
import { useModal } from '@src/hooks/useModal'
import { FC } from 'react'
import DebtorLegalDefendantList from '../../lists/debtor-defendant-list/debtor-defendant-list.component'
import CreateDebtorLegalDefendantModal from '../../modals/debtor-defendant-modal/create-debtor-defendant-modal.component'
import { DefendantSectionProps } from '../../../types/debtor-legal-defendant.type'

const DefendantSection: FC<DefendantSectionProps> = ({ debtorLegal }) => {
  const [isOpen, handleOpen, handleClose] = useModal(false)
  return (
    <Section>
      <SubSection className={styles.headerWrapper}>
        <Header mb={4}>Defendant/s</Header>
        <SubmitButton onClick={handleOpen}>Add Defendant</SubmitButton>
      </SubSection>

      <DebtorLegalDefendantList entities={debtorLegal.defendants} />

      <CreateDebtorLegalDefendantModal
        isOpen={isOpen}
        onClose={handleClose}
        debtorLegalId={debtorLegal.id}
      />
    </Section>
  )
}

export default DefendantSection
