import axios from "axios";
import { CourtInterestCalculator, CreateCourtInterestCalculatorData, UpdateCourtInterestCalculatorData } from "../types/interest-rate.type";
import { REACT_APP_API_URL } from "@src/lib/global-variables";

export class CourtInterestCalculatorService {


  async createCourtInterestCalculator(data: CreateCourtInterestCalculatorData) {
    const res = await axios.post<CourtInterestCalculator>(`${REACT_APP_API_URL}/court-interest-calculators`, data)
    return res.data
  }

  async updateCourtInterestCalculator(id: number, data: UpdateCourtInterestCalculatorData) {
    const res = await axios.patch<CourtInterestCalculator>(`${REACT_APP_API_URL}/court-interest-calculators/${id}`, data)
    return res.data
  }

}