import React, { useEffect, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { Col, Row } from 'reactstrap'
import Select from 'react-select'
import { ContentState, convertToRaw, EditorState } from 'draft-js'
import { Editor } from '@tinymce/tinymce-react'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import WIPService from '../../services/WIPService'
import { getEmailTypes } from '../Form/RecoveryForm/service'
import { compileTemplate } from '../Form/RecoveryForm/utils'

const repeatTypeOption = [
  { value: '0', label: 'None' },
  { value: '1', label: 'Daily' },
  { value: '2', label: 'Weekly' },
  { value: '3', label: 'Monthly (by Day)' },
  { value: '4', label: 'Monthly (by Date)' },
  { value: '5', label: 'Yearly' },
]

const statusOption = [
  { value: 'client', label: 'Client' },
  { value: 'debtors', label: 'Debtors' },
]

const SetEmailTaskManager = (props) => {
  const [emailTemplates, setEmailTemplates] = useState([])
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [emailBodyPlain, setEmailBodyPlain] = useState('')
  const [recoveryData, setRecoveryData] = useState(null)
  const [text, setText] = useState(null)
  const [valueText, setValueText] = useState('')
  const [recipientsOptionDebtor, setRecipientsOptionDebtor] = useState([])
  const [recipientsOptionClient, setRecipientsOptionClient] = useState([])
  const [valueTo, setValueTo] = useState('')
  const [companyId, setCompanyId] = useState({})
  const [debtorId, setDebtorId] = useState(null)
  const [repeatType, setRepeatType] = useState({})
  const [tempOption, setTempOption] = useState([])

  const wip = new WIPService()

  useEffect(() => {
    if (props.setEmail) {
      if (props.setEmail.status === 'client') {
        setValueTo('client')
      } else {
        setValueTo('debtors')
      }
      const rep = repeatTypeOption.find((e) => e.label === props.setEmail?.repeatEmail)
      setRepeatType(rep)
    }
    getWIPbyId(props.wipID || +localStorage.getItem('wipId'))
    const params = {
      limit: 10000,
    }
    getEmailTypes(params)
      .then((res) => {
        setEmailTemplates(res.data.data)
        const temp = res.data.data.map((i) => ({
          value: i.id,
          label: i.title,
        }))
        setTempOption(temp)
        if (props?.setEmail?.type) {
          const tempBody = res.data.data.find((e) => e.id === props.setEmail?.type?.value)
          setEmailBodyPlain(tempBody?.body)
        }
      })
      .catch((e) => console.log(e))
  }, [])

  const getWIPbyId = async (id) => {
    try {
      const params = {
        wip_id: id,
        join: ['debtors', 'debtors.contact', 'company'],
      }
      const list = await wip.getWipsId(params)
      const getDataDebtor = list.data.debtors[0].contact.comms_email
      const getDataClient = list.data.owner_details
      let debtor = getDataDebtor.map((item) => {
        return {
          value: item,
          label: item,
        }
      })
      let client = [{ value: getDataClient.email, label: getDataClient.email }]
      setRecipientsOptionDebtor(debtor)
      setRecipientsOptionClient(client)
      setCompanyId(list.data.company.id)
      setDebtorId(list.data.debtors[0].id)
    } catch {
      console.log('Error')
    }
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      type: props.setEmail.type,
      email_status_scheduled: props.setEmail?.email_status_scheduled,
      email_header_subject: props.setEmail.subject,
      client: props.setEmail?.client,
      debtors: props.setEmail?.debtor,
      to: statusOption.find((e) => e.value === props.setEmail.status),
      email_time_scheduled: props.setEmail.email_time_scheduled,
    },
  })

  const shouldBeScheduled = useWatch({
    control,
    name: 'email_status_scheduled',
    defaultValue: props.setEmail?.email_status_scheduled
      ? props.setEmail?.email_status_scheduled
      : {
          value: false,
          label: 'No',
        },
  })

  const status = useWatch({
    control,
    name: 'to',
    defaultValue:
      props.setEmail?.status === 'client'
        ? { value: 'client', label: 'Client' }
        : props.setEmail?.status === 'debtors'
        ? { value: 'debtors', label: 'Debtor' }
        : '',
  })

  const saveData = (formData) => {
    const { to, ...rest } = formData

    if (status.value === 'client') {
      const data = {
        to: formData.client.length ? formData.client.map((e) => e.value) : [],
        subject: formData.email_header_subject,
        text: text,
        html: text,
        debtor_id: debtorId,
        company_id: companyId,
        wip_id: props.wipID || +localStorage.getItem('wipId'),
        type: formData.type,
        client: formData.client,
        status: 'client',
        email_status_scheduled: formData.email_status_scheduled,
      }
      if (rest.email_status_scheduled?.value) {
        data.repeatEmail = repeatType.label
        data.email_time_scheduled = formData.email_time_scheduled
      }
      props.handleChangeSetEmail(data, props.valueTab)
    } else if (status.value === 'debtors') {
      const data = {
        to: formData.debtors.length ? formData.debtors.map((e) => e.value) : [],
        subject: formData.email_header_subject,
        text: text,
        html: text,
        debtor_id: debtorId,
        company_id: companyId,
        wip_id: props.wipID || +localStorage.getItem('wipId'),
        type: formData.type,
        debtor: formData.debtors,
        status: 'debtors',
        email_status_scheduled: formData.email_status_scheduled,
      }
      if (rest.email_status_scheduled?.value) {
        data.repeatEmail = repeatType.label
        data.email_time_scheduled = formData.email_time_scheduled
      }
      props.handleChangeSetEmail(data, props.valueTab)
    } else {
      props.handleChangeSetEmail({}, props.valueTab)
    }
  }

  const watchTemplate = (value, { name }) => {
    if (name === 'type') {
      const index = emailTemplates?.findIndex((i) => i.id === value?.type.value)
      if (index !== -1) {
        setValue('email_header_subject', compileTemplate(emailTemplates[index].title, recoveryData))

        // Set the editor state
        const blocksFromHtml = htmlToDraft(
          compileTemplate(emailTemplates[index].body, recoveryData),
        )
        const { contentBlocks, entityMap } = blocksFromHtml
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)
        const editorState = EditorState.createWithContent(contentState)
        setEditorState(editorState)

        // Set the plain text
        setEmailBodyPlain(draftToHtml(convertToRaw(editorState.getCurrentContent())))
      }
    }
  }

  useEffect(() => {
    const subscription = watch(watchTemplate)
    return () => subscription.unsubscribe()
  }, [watch, emailTemplates])

  return (
    <>
      <div>
        <h3 style={{ color: 'black' }} className="form__form-group">
          Set your email
        </h3>
      </div>
      <form onSubmit={handleSubmit(saveData)}>
        <div className="form__form-group">
          <span className="form__form-group-label">To</span>
          <div className="form__form-group-field">
            <Controller
              control={control}
              name="to"
              render={({ field }) => (
                <Select
                  {...field}
                  options={statusOption}
                  classNamePrefix="react-select"
                  className="react-select"
                  onChange={(e) => {
                    setValueTo(e.value)
                  }}
                />
              )}
            />
          </div>
          {errors.to && <span className="form__form-group-error">To is required</span>}
        </div>
        {status.value === 'client' && (
          <div className="form__form-group">
            <span className="form__form-group-label">Recipients</span>
            <div className="form__form-group-field">
              <Controller
                control={control}
                name="client"
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={recipientsOptionClient}
                    classNamePrefix="react-select"
                    className="react-select"
                    isMulti
                    placeholder={'Select recipients'}
                  />
                )}
              />
            </div>
            {errors.client && <span className="form__form-group-error">To is required</span>}
          </div>
        )}
        {status.value === 'debtors' && (
          <div className="form__form-group">
            <span className="form__form-group-label">Recipients</span>
            <div className="form__form-group-field">
              <Controller
                control={control}
                name="debtors"
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={recipientsOptionDebtor}
                    classNamePrefix="react-select"
                    className="react-select"
                    isMulti
                    placeholder={'Select recipients'}
                  />
                )}
              />
            </div>
            {errors.debtors && <span className="form__form-group-error">To is required</span>}
          </div>
        )}
        <div className="form__form-group">
          <span className="form__form-group-label">Template</span>
          <div className="form__form-group-field">
            <Controller
              control={control}
              name="type"
              render={({ field }) => (
                <Select
                  {...field}
                  options={tempOption}
                  classNamePrefix="react-select"
                  className="react-select"
                />
              )}
            />
          </div>
          {errors.type && <span className="form__form-group-error">Type is required</span>}
        </div>

        <div className="form__form-group">
          <span className="form__form-group-label">Subject</span>
          <div className="form__form-group">
            <div className="form form--horizontal">
              <input
                name="email_header_subject"
                placeholder="Subject"
                type="text"
                {...register('email_header_subject')}
              />
            </div>
          </div>
          {errors.email_header_subject && (
            <span className="form__form-group-error">Subject is required</span>
          )}
        </div>

        <div className="form__form-group">
          <div className="form__form-group-field">
            <div className="text-editor" style={{ zIndex: '-5px' }}>
              <Editor
                apiKey="qwxo2mvltn5dflrlsa3iri4nw9z0uum2nl62s3f5rd4nbf0f"
                initialValue={
                  props.textEmail?.body_plain ? props.textEmail.body_plain : emailBodyPlain
                }
                value={valueText}
                onInit={(evt, editor) => {
                  setText(editor.getContent({ format: 'text' }))
                }}
                onEditorChange={(newValue, editor) => {
                  setValueText(newValue)
                  setText(editor.getContent({ format: 'text' }))
                }}
                init={{
                  plugins:
                    'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
                  imagetools_cors_hosts: ['picsum.photos'],
                  menubar: 'file edit view insert format tools table help custom',
                  menu: {
                    custom: {
                      title: 'Variables',
                      items: 'title fullName firstNames lastName loginLink loginButton unsubscribe',
                    },
                  },
                  toolbar:
                    'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
                  toolbar_sticky: true,
                  autosave_ask_before_unload: true,
                  autosave_interval: '30s',
                  autosave_prefix: '{path}{query}-{id}-',
                  autosave_restore_when_empty: false,
                  autosave_retention: '2m',
                  image_advtab: true,
                  link_list: [
                    { title: 'My page 1', value: 'https://www.tiny.cloud' },
                    { title: 'My page 2', value: 'http://www.moxiecode.com' },
                  ],
                  image_list: [
                    { title: 'My page 1', value: 'https://www.tiny.cloud' },
                    { title: 'My page 2', value: 'http://www.moxiecode.com' },
                  ],
                  image_class_list: [
                    { title: 'None', value: '' },
                    { title: 'Some class', value: 'class-name' },
                  ],
                  importcss_append: true,
                  file_picker_callback: function (callback, value, meta) {
                    /* Provide file and text for the link dialog */
                    if (meta.filetype === 'file') {
                      callback('https://www.google.com/logos/google.jpg', { text: 'My text' })
                    }

                    /* Provide image and alt text for the image dialog */
                    if (meta.filetype === 'image') {
                      callback('https://www.google.com/logos/google.jpg', { alt: 'My alt text' })
                    }

                    /* Provide alternative source and posted for the media dialog */
                    if (meta.filetype === 'media') {
                      callback('movie.mp4', {
                        source2: 'alt.ogg',
                        poster: 'https://www.google.com/logos/google.jpg',
                      })
                    }
                  },
                  template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
                  template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
                  height: 600,
                  image_caption: true,
                  quickbars_selection_toolbar:
                    'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                  noneditable_noneditable_class: 'mceNonEditable',
                  toolbar_mode: 'sliding',
                  contextmenu: 'link image imagetools table',
                  skin: 'oxide',
                  content_css: 'default',
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                  setup: function (editor) {
                    const toggleState = false

                    editor.ui.registry.addMenuItem('title', {
                      text: 'Title',
                      onAction: function () {
                        editor.insertContent('%recipient.title%')
                      },
                    })

                    editor.ui.registry.addMenuItem('fullName', {
                      text: 'Full Name',
                      onAction: function () {
                        editor.insertContent('%recipient.full_name%')
                      },
                    })

                    editor.ui.registry.addMenuItem('firstNames', {
                      text: 'First Names',
                      onAction: function () {
                        editor.insertContent('%recipient.first_names%')
                      },
                    })

                    editor.ui.registry.addMenuItem('lastName', {
                      text: 'Last Name',
                      onAction: function () {
                        editor.insertContent('%recipient.last_name%')
                      },
                    })
                    editor.ui.registry.addMenuItem('loginLink', {
                      text: 'Login Link',
                      onAction: function () {
                        editor.insertContent(
                          '<a href="https://www.ezpurim.com/login?uuid=%recipient.uuid%">login link</a>',
                        )
                      },
                    })
                    editor.ui.registry.addMenuItem('loginButton', {
                      text: 'Login Button',
                      onAction: function () {
                        editor.insertContent(
                          '<a href="https://www.ezpurim.com/login?uuid=%recipient.uuid%" class="btn-primary" style="font-family: Helvetica Neue,Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; color: #FFF; text-decoration: none; line-height: 2em; font-weight: bold; text-align: center; cursor: pointer; display: inline-block; border-radius: 5px; text-transform: capitalize; background-color: #348eda; margin: 0; border-color: #348eda; border-style: solid; border-width: 10px 20px;">Login Button</a>',
                        )
                      },
                    })

                    editor.ui.registry.addMenuItem('unsubscribe', {
                      text: 'Unsubscribe',
                      onAction: function () {
                        editor.insertContent(
                          '<a href="%mailing_list_unsubscribe_url%">Unsubscribe</a>',
                        )
                      },
                    })
                  },
                }}
              />
            </div>
          </div>
        </div>

        <div className="form__form-group">
          <span className="form__form-group-label">Schedule Email</span>
          <div className="form__form-group-field">
            <Controller
              control={control}
              name="email_status_scheduled"
              render={({ field }) => (
                <Select
                  {...field}
                  defaultValue={
                    props.setEmail?.email_status_scheduled
                      ? props.setEmail?.email_status_scheduled
                      : {
                          value: false,
                          label: 'No',
                        }
                  }
                  options={[
                    { value: true, label: 'Yes' },
                    { value: false, label: 'No' },
                  ]}
                  classNamePrefix="react-select"
                  className="react-select"
                />
              )}
            />
          </div>
        </div>
        {shouldBeScheduled.value && (
          <Row>
            <Col md={6}>
              <div className="form__form-group">
                <span className="form__form-group-label">Schedule Date</span>
                <div className="form__form-group">
                  <div className="form form--horizontal">
                    <input
                      name="email_time_scheduled"
                      placeholder="date"
                      type="datetime-local"
                      {...register('email_time_scheduled')}
                    />
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="form-group">
                <label className="form__form-group-label" htmlFor="">
                  {' '}
                  Repeat type{' '}
                </label>
                <Select
                  name="rType"
                  type="text"
                  options={repeatTypeOption}
                  classNamePrefix="react-select"
                  className="react-select"
                  value={repeatType}
                  onChange={(e) => setRepeatType(e)}
                />
              </div>
            </Col>
          </Row>
        )}
        <hr />
        <div className={'button_group_custom_email'}>
          <button className="btn-gray mr-3 mb-2" type="button" onClick={props.closeModal}>
            Cancel
          </button>
          <button
            type="submit"
            style={{ padding: '9px 30px 11px 30px' }}
            className="btn btn-secondary m-0 mb-0"
          >
            Next
          </button>
        </div>
      </form>
    </>
  )
}

export default SetEmailTaskManager
