import { REACT_APP_API_URL } from "@src/lib/global-variables";
import { PaginationResponse, Params } from "@src/types/service.type";
import axios from "axios";
import { Debtor } from "../types/debtor.type";
import { DeepPartial } from "react-hook-form";

export class DebtorService {

  async getDebtorList(params: Params = {}) {
    const res = await axios.get<PaginationResponse<Debtor>>(`${REACT_APP_API_URL}/debtors`, { params });
    return res.data;
  }

  async getDebtorById(id: number, params: Params = {}) {
    const res = await axios.get<Debtor>(`${REACT_APP_API_URL}/debtors/${id}`, { params });
    return res.data;
  }

  async updateDebtor(id: number, debtor: DeepPartial<Debtor>, params: Params = {}) {
    const res = await axios.patch<Debtor>(`${REACT_APP_API_URL}/debtors/${id}`, debtor, { params });
    return res.data;
  }

  async deleteDebtor(id: number, params: Params = {}) {
    const res = await axios.delete<Debtor>(`${REACT_APP_API_URL}/debtors/${id}`, { params });
    return res.data;
  }
  
}