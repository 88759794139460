import CompaniesService from '../../services/CompaniesService';
import { getCompaniesByIdActionTypes } from '../actionTypes';

const request = new CompaniesService();

export const getCompaniesById = (params) => async (dispatch) => {
  dispatch({ type: getCompaniesByIdActionTypes.GET_COMPANIES_BY_ID_LOADING });
  try {
    const res = await request.getCompaniesId(params);

    dispatch({
      type: getCompaniesByIdActionTypes.GET_COMPANIES_BY_ID_SUCCESS,
      payload: res,
    });

    return res;
  } catch (error) {
    dispatch({
      type: getCompaniesByIdActionTypes.GET_COMPANIES_BY_ID_ERROR,
      payload: error,
    });
  }
};
