import BaseService from './BaseService'
import axios from 'axios'

export default class AccountService extends BaseService {
  constructor() {
    super()
    this.apiUrl = this.apiUrl + '/client-logins'
  }

  async getAccountInfo(params = {}) {
    try {
      const response = await axios.get(this.apiUrl, { params })
      if (response.status !== 200) {
        throw new Error('Error occured fetching SMS')
      }

      return response.data
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async getLoginTyps(params = {}) {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/login-types`)
      if (response.status !== 200) {
        throw new Error('Error occured fetching SMS')
      }

      return response.data
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }
}
