import { getInvoicesListActionEnum } from '../actionTypes';

export const getInvoicesListReducer = function (
  state = { data: [], loading: false, error: '' },
  action,
) {
  switch (action.type) {
    case getInvoicesListActionEnum.GET_INVOICES_LIST_SUCCESS:
      return { loading: false, error: '', data: action.payload };
    case getInvoicesListActionEnum.IS_LOADING_GET_INVOICES_LIST:
      return { loading: true, error: '', data: [] };
    case getInvoicesListActionEnum.GET_INVOICES_LIST_ERROR:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
