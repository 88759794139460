import BaseService from './BaseService'
import axios from 'axios'

export default class DebtorService extends BaseService {
  constructor() {
    super()
    this.apiUrl = this.apiUrl + '/debtors'
  }

  async getDebtor(params = {}) {
    try {
      const response = await axios.get(this.apiUrl, { params })

      if (response.status !== 200) {
        throw new Error('Failed to fetch staff')
      }

      return response.data
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async getDebtorId(params) {
    try {
      const response = await axios.get(`${this.apiUrl}/${params.id}`, { params })

      if (response.status !== 200) {
        throw new Error('Failed to fetch staff')
      }

      return response
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async addDebtor(data) {
    try {
      const response = await axios.post(this.apiUrl, data)

      if (response.status !== 201) {
        throw new Error('Failed to add staff')
      }

      return response
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async deleteDebtor(debtorId) {
    try {
      const response = await axios.delete(`${this.apiUrl}/${debtorId}`)

      return response
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          //throw new Error(error.response.data);
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async editDebtor(val) {
    try {
      const response = axios.patch(`${this.apiUrl}/${val.id}`, val)

      return response
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async getDebtorAddress(val) {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/debtor-addresses`)

      return response
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async addDebtorAddress(val) {
    try {
      const response = val.map(async (val) =>
        axios.post(`${process.env.REACT_APP_API_URL}/debtor-addresses`, val),
      )
      return await Promise.all(response)
      //const response = axios.post(`${process.env.REACT_APP_API_URL}/debtor-addresses`,dataServiceAddress);

      return response
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async editDebtorAddress(val) {
    try {
      const response = val.map(async (val) =>
        axios.patch(`${process.env.REACT_APP_API_URL}/debtor-addresses/${val.id}`, val),
      )
      return await Promise.all(response)
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }
  async addDebtorContact(val) {
    try {
      const response = axios.post(`${process.env.REACT_APP_API_URL}/debtor-contacts`, val)
      return response
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }
  async editDebtorContact(val) {
    try {
      const response = axios.patch(
        `${process.env.REACT_APP_API_URL}/debtor-contacts/${val.id}`,
        val,
      )
      return response
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }
  async deleteDebtorAddress(val) {
    try {
      const response = axios.delete(`${process.env.REACT_APP_API_URL}/debtor-addresses/${val}`)
      return response
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async getDebtorContactId(val) {
    try {
      const response = axios.get(`${process.env.REACT_APP_API_URL}/debtor-contacts/${val.id}`, val)
      return response
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }
}
