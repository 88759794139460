import React from 'react';
import axios from 'axios';
import moment from 'moment';

import fileIcon from '../../../assets/image/form-images/file-icon.png';
import './styles.scss';

const UploadDocuments = ({ setFieldValue, values }) => {
  const onFileChange = async (event) => {
    if (!event.target.files[0]) {
      return;
    }

    const formData = new FormData();

    formData.append('file', event.target.files[0]);

    const item = {
      file_path: '',
      file_name: '',
      file_type: event?.target?.files[0]?.type,
      file_size: String(event.target.files[0]?.size),
      date: moment(event.target.files[0].lastModified).toISOString(),
    };

    const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/uploads/file-recovery`, formData);

    setFieldValue('files', [...values?.files, { ...item, file_path: data.file_path, file_name: data.file_name }]);
  };

  return (
    <div>
      <h3>Upload Files</h3>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          maxWidth: '100%',
          marginLeft: '97px',
        }}
      >
        <span className="form__form-group-label">
          Files <span className="required-label">*</span>
        </span>
        <div className="form__form-group-field_file">
          <div className={`dropzone dropzone--single`}>
            <div className="form__files">
              {values?.files?.map((item) => {
                return (
                  <div className="file-container-sing" key={item.date}>
                    <img src={fileIcon} alt="file-icon" />
                    <p>{item?.file_name}</p>
                  </div>
                );
              })}
            </div>

            <div className="form__form-group-field_file_container">
              <p className="text">Drag and drop your file here or upload from your drive</p>

              <input
                type="file"
                id="file-input"
                required={values?.files?.length > 0 ? false : true}
                onChange={onFileChange}
                multiple={false}
                defaultValue={values?.files}
                className="file-input__input"
              />

              <label className="file-input__label" htmlFor="file-input">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="upload"
                  className="svg-inline--fa fa-upload fa-w-16"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
                  ></path>
                </svg>
                <span>Upload file</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadDocuments;
