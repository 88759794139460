import { PHONE_REGEX } from "@src/lib/regexp.const"
import Joi from "joi"
import { CommunicationOptionType } from "../types/communication.type"

const task = Joi.object({
  label: Joi.string().required(),
  value: Joi.number().required(),
})
const important = Joi.boolean().required()


const note = Joi.string().required().messages({ 'string.empty': 'Note is required' })

export const addTaskNoteSchema = Joi.object({
  task: task.messages({ 'object.base': 'Task is required' }),
  note,
  important
})

export const addGeneralNoteSchema = Joi.object({
  task: task.allow(null),
  note,
  important
})

export const replyToSmsSchema = Joi.object({
  body: Joi.string().required().messages({ 'string.empty': 'Body is required' }),
})


const recipientEmailList =
  Joi.array().items(
    Joi.object({
      label: Joi.string().required(),
      value: Joi.string().required()
    })
  ).min(1).required().messages({
    'any.required': 'Required',
    'string.empty': 'Required',
    'array.min': 'Required',
    'array.base': 'Required',
  })

const recipientsPhone = Joi.alternatives().try(
  Joi.array().items(
    Joi.object({
      label: Joi.string().required(),
      value: Joi.string()
    })
  ).min(1).required(),
  Joi.string().regex(PHONE_REGEX).required()
).required().messages({
  'alternatives.types': 'Required',
  "string.pattern.base": 'Must be a phone',
  'any.required': 'Required',
  'string.empty': 'Required',
  'string.regex': 'Must be a phone',
  'array.min': 'Required',
})

const to = Joi.object({
  label: Joi.string().required(),
  value: Joi.string().required()
}).required().messages({
  'any.required': 'Required',
  'object.base': 'Required',
})

const template = Joi.object({
  label: Joi.string().required(),
  value: Joi.string().required(),
}).optional().allow(null)


export const sendSmsSchema = Joi.object({
  sendTo: to,
  contact: recipientsPhone,
  template,

  message: Joi.string().required().messages({
    'string.empty': 'Required',
  })
})

export const sendEmailSchema = Joi.object({
  sendTo: to,
  template,
  isCC: Joi.boolean().required(),
  isBCC: Joi.boolean().required(),
  mainRecipients: Joi.when('sendTo.value', {
    is: CommunicationOptionType.Other,
    then: Joi.array().allow(null).optional(),
    otherwise: recipientEmailList
  }),
  inlineRecepient: Joi.when('sendTo.value', {
    is: CommunicationOptionType.Other,
    then: Joi.string().email({ tlds: { allow: false } }).required().messages({
      'string.email': 'Must be a email',
      'string.empty': 'Required',
      'string.required': 'Required',
    }),
    otherwise: Joi.string().allow(null).optional()
  }),
  ccRecipients: Joi.when('isCC', {
    is: true,
    then: recipientEmailList,
    otherwise: Joi.array().allow(null).optional()
  }),
  bccRecipients: Joi.when('isBCC', {
    is: true,
    then: recipientEmailList,
    otherwise: Joi.array().allow(null).optional()
  }),
  subject: Joi.string().required().messages({
    'string.empty': 'Required',
    'string.required': 'Required',
  }),
  body: Joi.string().required().messages({
    'string.empty': 'Required',
    'string.required': 'Required',
  }),
  attachments: Joi.array()
}) 
