import { CombinedNoteType } from "../types/combined-notes.type"
import { CommunicationOptionType } from "../types/communication.type"

export const ADD_NOTE_FORM_OPTIONS = [
  {
    label: 'General Notes',
    value: 'general',
  },
  {
    label: 'Task Notes',
    value: 'task',
  },
]

export const NOTE_TYPE_TO_LABEL: Record<CombinedNoteType, string> = {
  [CombinedNoteType.LOCAL_EMAIL]: 'Email Sent',
  [CombinedNoteType.OUTLOOK_EMAIL]: 'Email Sent',
  [CombinedNoteType.GENERAL_NOTE]: 'General',
  [CombinedNoteType.TASK_NOTE]: 'Task',
  [CombinedNoteType.SMS]: 'SMS Sent',
  [CombinedNoteType.CALL]: 'Call Made',
}

export const COMBINED_NOTES_FILTER_OPTIONS = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'General Notes',
    value: 'generalNote',
  },
  {
    label: 'Task Notes',
    value: 'taskNote',
  },
  {
    label: 'Emails',
    value: 'localEmail|outlookEmail',
  },
  {
    label: 'SMS',
    value: 'sms',
  },
]

export const ADD_NOTE_FORM_DEFAULT_VALUES = {
  note: '',
  task: null,
  important: false,
}

export const WIP_SEND_TO_OPTIONS = [
  { label: 'Debtor', value: CommunicationOptionType.Debtor },
  { label: 'Client', value: CommunicationOptionType.CompanyContact },
]

export const CLIENT_SEND_TO_OPTIONS = [
  { label: 'Client', value: CommunicationOptionType.CompanyContact },
]

export const SEND_TO_DEFAULT_OPTIONS = [{ label: 'Other', value: CommunicationOptionType.Other }]
