import { FC } from 'react'
import TableRow from '@components/table/table-row.component'
import { format } from 'date-fns'
import TableCell from '@components/table/table-cell-component'
import { WipListItemProps } from '../../types/wip.type'
import styles from './wip-list-item.module.scss'
import { useHistory } from 'react-router-dom'

const WipListItem: FC<WipListItemProps> = ({ wip }) => {
  const history = useHistory()
  const getDebtorsAsString = () => {
    return wip.debtors.map((debtor) => `${debtor.name_first} ${debtor.name_last}`).join(', ')
  }

  const debtorsAsString = getDebtorsAsString()

  const handleWipClick = () => {
    history.push(`/wip/${wip.id}/overview`)
  }

  return (
    <TableRow className={styles.wipRow} onClick={handleWipClick}>
      {/* FILE# */}
      <TableCell position="eclipsed" width={70}>
        {wip.id}
      </TableCell>
      {/* LOGGED */}
      <TableCell className={styles.loggedCell} width={155} position="eclipsed">
        {format(wip.created_at, 'kk:mm | dd-MM-yyyy')}
      </TableCell>
      {/* CLIENT */}
      <TableCell position="eclipsed" width={200}>
        {wip?.company?.name || ''}
      </TableCell>
      {/* DEBTORS */}
      <TableCell className={styles.debtorsCell} position="eclipsed">
        {debtorsAsString}
      </TableCell>
      {/* PROPERTY */}
      <TableCell position="eclipsed">1 Cloudwalk Drive, Maleny QLD, Australia</TableCell>
      {/* STATUS */}
      <TableCell position="eclipsed" width={80}>
        Active
      </TableCell>
      {/* COVER */}
      <TableCell className={styles.coverCell} position="eclipsed" width={90}>
        P&C
      </TableCell>
    </TableRow>
  )
}

export default WipListItem
