import { FULL_STATE_OPTIONS } from "@src/lib/select-options.const";
import { DebtorAddress, DebtorAddressFormValues } from "../types/debtor-address.type";

export const transformDebtorAddressToDebtorAddressFormValues = (debtorAddress: DebtorAddress): DebtorAddressFormValues => {
  return ({
    address_1: debtorAddress.address_1,
    address_2: debtorAddress.address_2,
    address_3: debtorAddress.address_3,
    suburb: debtorAddress.suburb,
    state: FULL_STATE_OPTIONS.find(({ value }) => value === debtorAddress.state) || null,
    postcode: debtorAddress.postcode,
    country: debtorAddress.country,
  })
}