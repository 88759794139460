import { postCommercialUncoveredFormEnum } from '../actionTypes';

export const postCommercialUncoveredFormReducer = function (
  state = { data: [], loading: false, error: '' },
  action,
) {
  switch (action.type) {
    case postCommercialUncoveredFormEnum.POST_COMMERCIAL_UNCOVERED_FORM_SUCCESS:
      return { loading: false, error: '', data: action.payload };
    case postCommercialUncoveredFormEnum.IS_LOADING_POST_COMMERCIAL_UNCOVERED_FORM:
      return { loading: true, error: '', data: [] };
    case postCommercialUncoveredFormEnum.POST_COMMERCIAL_UNCOVERED_FORM_ERROR:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
