import React from 'react'
import { Button, ButtonToolbar, Card, CardBody, Col, Spinner } from 'reactstrap'
import { reduxForm } from 'redux-form'
import { withTranslation } from 'react-i18next'
// import renderSelectField from '../../../../shared/components/form/Select';
import renderMultiSelectField from '../../../../shared/components/form/MultiSelect'
import Services from '../../../../shared/Services.js'
import { withRouter } from 'react-router'
import Select from 'react-select'
import SimpleReactValidator from 'simple-react-validator'
import Dropzone from 'react-dropzone'
import StaffService from '../../../../services/StaffService'
import { GLOBAL_USER_ACCESS, GLOBAL_USER_POSITION } from '../../../../utils/index'

const renderField = ({ input, placeholder, value, type, meta: { touched, error } }) => (
  <div className="form__form-group-input-wrap">
    <input {...input} placeholder={placeholder} type={type} value={value} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
)

const acc = ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA']

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
}

class EditStaffForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showPassword: false,
      staffData: '',
      email: '',
      emailChanges: false,
      name: '',
      nameChanges: false,
      fname: '',
      lname: '',
      phone: '',
      mobile: '',
      mobile2: '',
      status: '',
      position: '',
      Ass_States: [],
      access_type: [],
      role: [],
      profilePic: '',
      profilePhotoView: '',
      email_letter: '',
      emailPhotoView: '',
      id: localStorage.getItem('editId'),
      profileLoder: false,
      emailPhotoLoder: false,
      isSelectAllClicked: false,
      display_colour: '',
    }

    this.services = new Services()
    this.staff = new StaffService()
    this.editStaff = this.editStaff.bind(this)
    this.changeInput = this.changeInput.bind(this)
    this.validator = new SimpleReactValidator()
  }

  componentDidMount() {
    this.getStaff(this.state.id)
  }

  getStaff = async (id) => {
    const data = {
      id: id,
    }
    try {
      const res = await this.staff.getStaffId(data)
      const getData = res
      let role = getData.access_level.replace('_', ' ')

      role = role.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
        return g1.toUpperCase() + g2.toLowerCase()
      })
      let AssData = []
      const acc = ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA']
      acc.forEach((element) => {
        AssData.push({ value: element, label: element })
      })
      this.setState({
        staffData: getData,
        email: getData.comms_email,
        name: getData.nickname,
        fname: getData.first_name,
        lname: getData.last_name,
        phone: getData.comms_ph_home,
        status: { value: getData.account_status, label: getData.account_status },
        mobile: getData.comms_ph_mobile_pri,
        mobile2: getData.comms_ph_mobile_sec,
        position: getData.position,
        Ass_States: AssData,
        access_type: { value: getData.position, label: getData.position },
        role: { value: getData.access_level, label: role },
        profilePic: getData.profile_picture,
        email_letter: '',
        display_colour: getData.display_colour,
      })
    } catch (e) {
      this.services.errorToast(e)
      throw new Error(e)
    }
  }

  showPassword = (e) => {
    e.preventDefault()
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }))
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }))
  }

  editStaff = () => {
    let AssData = []
    this.state.Ass_States.forEach((element) => {
      AssData.push(element.value)
    })
    const data = {
      st_id: this.state.id,
      fname: this.state.fname,
      lname: this.state.lname,
      phone: this.state.phone,
      mobile: this.state.mobile,
      mobile2: this.state.mobile2,
      position: this.state.position,
      status: [this.state.status.value],
      Ass_States: JSON.stringify(AssData),
      access_type: this.state.access_type.value,
      role: this.state.role.value,
      display_colour: this.state.display_colour,
      name: this.state.name,
      email: this.state.email,
    }
    if (this.state.profilePhotoView) {
      data['profilePic'] = JSON.stringify(this.state.profilePic)
    }
    if (this.state.profilePic === '') {
      data['profilePic'] = JSON.stringify({ main: '', thumb: '' })
      this.services.deleteFile(this.state.staffData.profilePic, (res) => {
        if (res.status === 200) {
          // this.services.successToast('Photo removed successfully');
        } else {
          this.services.errorToast(res.message)
        }
        this.setState({ profileLoder: false })
      })
    }
    if (this.state.emailPhotoView) {
      data['email_letter'] = JSON.stringify(this.state.email_letter)
    }
    if (this.state.email_letter !== '') {
      data['email_letter'] = JSON.stringify({ main: '', thumb: '' })
      this.services.deleteFile(this.state.staffData.email_letter, (res) => {
        if (res.status === 200) {
          // this.services.successToast('Photo removed successfully');
        } else {
          this.services.errorToast(res.message)
        }
        this.setState({ profileLoder: false })
      })
    }
    if (this.state.emailChanges) {
      data['email'] = this.state.email
    }
    if (this.state.nameChanges) {
      data['name'] = this.state.name
    }
    if (this.validator.allValid()) {
      this.staff
        .editStaff({
          ...data,
          role: GLOBAL_USER_POSITION.find(({ value }) => data.role === value).label,
        })
        .then((res) => {
          if (res) {
            this.services.successToast('Staff edit successfully')
            this.props.history.push('/staff-list')
          } else {
            this.services.errorToast(res)
          }
        })
    } else {
      this.validator.showMessages()
      this.forceUpdate()
    }
  }

  changeInput(e) {
    if (!e.target.validity.valid) {
      return null
    }

    if (e.target.name === 'email') {
      this.setState({ emailChanges: true })
    }
    if (e.target.name === 'name') {
      this.setState({ nameChanges: true })
    }
    this.setState({ [e.target.name]: e.target.value })
  }

  profilePictureUpload = (imgOption) => {
    this.setState({ profileLoder: true })
    this.services.uploadImage(imgOption[0], (res) => {
      if (res.status === 200) {
        this.setState({
          profilePhotoView: URL.createObjectURL(imgOption[0]),
          profilePic: res.data.data.profile,
        })
        this.services.successToast('Profile photo upload successfully')
      } else {
        this.services.errorToast(res.message)
      }
      this.setState({ profileLoder: false })
    })
  }
  email_letterUpload = (imgOption1) => {
    this.setState({ emailPhotoLoder: true })
    this.services.uploadImage(imgOption1[0], (res) => {
      if (res.status === 200) {
        this.services.successToast('Email letter upload successfully')
        this.setState({
          emailPhotoView: URL.createObjectURL(imgOption1[0]),
          imgOption1: imgOption1,
          email_letter: res.data.data.profile,
        })
      } else {
        this.services.errorToast(res.message)
      }
      this.setState({ emailPhotoLoder: false })
    })
  }

  handleChange0 = (selectedOption0) => {
    this.setState({ status: selectedOption0 })
  }
  handleChange = (selectedOption) => {
    this.setState({ access_type: selectedOption })
  }
  handleChange1 = (selectedOption1) => {
    this.setState({ role: selectedOption1 })
  }
  handleChange2 = (selectedOption2) => {
    if (selectedOption2 !== null && selectedOption2.length > 0) {
      if (
        (selectedOption2[selectedOption2.length - 1] &&
          selectedOption2[selectedOption2.length - 1].value === 'selectAll') ||
        (selectedOption2[selectedOption2.length - 1] &&
          selectedOption2[selectedOption2.length - 1].value === 'deSelectAll')
      ) {
        if (selectedOption2[selectedOption2.length - 1].value === 'selectAll') {
          this.selectAllStates()
        }

        if (selectedOption2[selectedOption2.length - 1].value === 'deSelectAll') {
          this.deSelectAllStates()
        }
      } else {
        this.setState({ Ass_States: selectedOption2 })
      }
    } else {
      this.setState({ Ass_States: selectedOption2 })
    }
  }
  removeProfile = (e) => {
    e.preventDefault()
    this.setState({ profilePic: '' })
    // call api for remove image
  }

  removeEmailLatter = (e) => {
    e.preventDefault()
    this.setState({ email_letter: '' })
  }
  cancle = () => {
    this.props.history.push('/staff-list')
  }

  selectAllStates = () => {
    const allStates = [
      { value: 'ACT', label: 'ACT' },
      { value: 'NSW', label: 'NSW' },
      { value: 'NT', label: 'NT' },
      { value: 'QLD', label: 'QLD' },
      { value: 'SA', label: 'SA' },
      { value: 'TAS', label: 'TAS' },
      { value: 'VIC', label: 'VIC' },
      { value: 'WA', label: 'WA' },
    ]
    this.setState({ Ass_States: allStates, isSelectAllClicked: true })
  }

  deSelectAllStates = () => {
    this.setState({ Ass_States: [], isSelectAllClicked: false })
  }

  render() {
    const { phone, mobile, Ass_States, mobile2 } = this.state

    return (
      <Col md={12} lg={12} xl={10}>
        <Card>
          <CardBody>
            <div className="card__title"></div>
            <form className="form form--horizontal" onSubmit={this.handleSubmit}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  First Name <span style={{ color: '#ff0000' }}>*</span>
                </span>
                <div className="form__form-group-field">
                  <input
                    name="fname"
                    //component={renderField}
                    type="text"
                    placeholder="First Name"
                    value={this.state.fname}
                    onChange={this.changeInput}
                  />
                </div>
                <span className="form__form-group-error">
                  {this.validator.message('First Name', this.state.fname, 'required')}
                </span>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  Last Name <span style={{ color: '#ff0000' }}>*</span>
                </span>
                <div className="form__form-group-field">
                  <input
                    name="lname"
                    //component={renderField}
                    type="text"
                    placeholder="Last Name"
                    value={this.state.lname}
                    onChange={this.changeInput}
                  />
                </div>
                <span className="form__form-group-error">
                  {this.validator.message('Last Name', this.state.lname, 'required')}
                </span>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  Email Address <span style={{ color: '#ff0000' }}>*</span>
                </span>
                <div className="form__form-group-field">
                  <input
                    name="email"
                    // component={renderField}
                    type="text"
                    placeholder="Email address"
                    value={this.state.email}
                    onChange={this.changeInput}
                  />
                </div>
                <span className="form__form-group-error">
                  {this.validator.message('Email', this.state.email, 'required|email')}
                </span>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  User Name <span style={{ color: '#ff0000' }}>*</span>
                </span>
                <div className="form__form-group-field">
                  <input
                    name="name"
                    //component={renderField}
                    type="text"
                    placeholder="User Name"
                    value={this.state.name}
                    onChange={this.changeInput}
                  />
                </div>
                <span className="form__form-group-error">
                  {this.validator.message('user Name', this.state.name, 'required')}
                </span>
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">
                  Status <span style={{ color: '#ff0000' }}>*</span>
                </span>
                <div className="form__form-group-field">
                  <Select
                    name="status"
                    //   component={renderSelectField}
                    type="text"
                    options={[
                      { value: 'CURRENT', label: 'CURRENT' },
                      { value: 'SUSPENDED', label: 'SUSPENDED' },
                      { value: 'DEPARTED', label: 'DEPARTED' },
                    ]}
                    classNamePrefix="react-select"
                    className="react-select"
                    value={this.state.status}
                    onChange={this.handleChange0}
                  />
                </div>
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">
                  Access Type <span style={{ color: '#ff0000' }}>*</span>
                </span>
                <div className="form__form-group-field">
                  <Select
                    name="access_type"
                    //   component={renderSelectField}
                    type="text"
                    options={GLOBAL_USER_ACCESS}
                    classNamePrefix="react-select"
                    className="react-select"
                    value={this.state.access_type}
                    onChange={this.handleChange}
                  />
                </div>
                <span className="form__form-group-error">
                  {this.validator.message('Access Type', this.state.access_type, 'required')}
                </span>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  Access Information <span style={{ color: '#ff0000' }}>*</span>
                </span>
                <div className="form__form-group-field">
                  <Select
                    name="role"
                    //  component={renderSelectField}
                    type="text"
                    value={this.state.role}
                    options={GLOBAL_USER_POSITION}
                    onChange={this.handleChange1}
                    classNamePrefix="react-select"
                    className="react-select"
                  />
                </div>
                <span className="form__form-group-error">
                  {this.validator.message('Access Information', this.state.role, 'required')}
                </span>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  Associated States <span style={{ color: '#ff0000' }}>*</span>
                </span>
                <div className="form__form-group-field">
                  <Select
                    name="Ass_States"
                    component={renderMultiSelectField}
                    type="text"
                    value={Ass_States}
                    isMulti
                    options={[
                      this.state.isSelectAllClicked === false ||
                      this.state.Ass_States === null ||
                      (this.state.Ass_States && this.state.Ass_States.length === 0)
                        ? {
                            value: 'selectAll',
                            label: 'Select All',
                          }
                        : { value: 'deSelectAll', label: 'Deselect All' },
                      { value: 'ACT', label: 'ACT' },
                      { value: 'NSW', label: 'NSW' },
                      { value: 'NT', label: 'NT' },
                      { value: 'QLD', label: 'QLD' },
                      { value: 'SA', label: 'SA' },
                      { value: 'TAS', label: 'TAS' },
                      { value: 'VIC', label: 'VIC' },
                      { value: 'WA', label: 'WA' },
                    ]}
                    onChange={this.handleChange2}
                    classNamePrefix="react-select"
                    className="react-select"
                  />
                </div>
                <span className="form__form-group-error">
                  {this.validator.message('Associated States', Ass_States, 'required')}
                </span>
              </div>
              <div className="card__title">
                <h5 className="bold-text">Personal Information</h5>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  Phone <span style={{ color: '#ff0000' }}>*</span>
                </span>
                <div className="form__form-group-field">
                  <input
                    name="phone"
                    //component={renderField}
                    pattern="[0-9]*"
                    maxLength={14}
                    type="text"
                    placeholder="Phone Number"
                    onChange={this.changeInput}
                    value={phone}
                  />
                </div>
                <span className="form__form-group-error">
                  {this.validator.message('Phone', this.state.phone, 'required|number')}
                </span>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  Mobile1 <span style={{ color: '#ff0000' }}>*</span>
                </span>
                <div className="form__form-group-field">
                  <input
                    name="mobile"
                    //component={renderField}
                    pattern="[0-9]*"
                    maxLength={14}
                    type="text"
                    placeholder="Mobile Number"
                    onChange={this.changeInput}
                    value={mobile}
                  />
                </div>
                <span className="form__form-group-error">
                  {this.validator.message('Mobile1', this.state.mobile, 'required|number')}
                </span>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  Mobile2 <span style={{ color: '#ff0000' }}>*</span>
                </span>
                <div className="form__form-group-field">
                  <input
                    name="mobile2"
                    //component={renderField}
                    pattern="[0-9]*"
                    maxLength={14}
                    type="text"
                    placeholder="Mobile Number"
                    onChange={this.changeInput}
                    value={mobile2}
                  />
                </div>
                <span className="form__form-group-error">
                  {this.validator.message('Mobile2', this.state.mobile2, 'required|number')}
                </span>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  Position <span style={{ color: '#ff0000' }}>*</span>
                </span>
                <div className="form__form-group-field">
                  <input
                    name="position"
                    // component={renderField}
                    type="text"
                    placeholder="Position"
                    onChange={this.changeInput}
                    value={this.state.position}
                  />
                </div>
                <span className="form__form-group-error">
                  {this.validator.message('Position', this.state.position, 'required')}
                </span>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Email Letterhead</span>
                <div className="form__form-group-field">
                  {/* <Field
                    name="profilePic"
                    component={renderDropZoneField}
                    onChange={this.email_letterUpload}
                  /> */}
                  <div className={`dropzone dropzone--single`}>
                    {this.state.emailPhotoLoder && (
                      <Spinner
                        animation="border"
                        variant="warning"
                        className="dropzone__drop-here"
                      />
                    )}
                    <Dropzone
                      accept="image/jpeg, image/png"
                      name={'email_letter'}
                      multiple={false}
                      onDrop={(fileToUpload) => {
                        this.email_letterUpload(fileToUpload)
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()} className="dropzone__input">
                          {this.state.email_letter !== '' &&
                            this.state.email_letter === '' &&
                            !this.state.emailPhotoLoder && (
                              <div className="dropzone__drop-here">
                                <span className="lnr lnr-upload" /> Drop File Here To Upload
                              </div>
                            )}
                          <input {...getInputProps()} disabled={this.state.emailPhotoLoder} />
                        </div>
                      )}
                    </Dropzone>
                    {this.state.email_letter !== '' && this.state.email_letter !== '' && (
                      <aside className="dropzone__img">
                        <img
                          src={this.state.emailPhotoView || this.state.email_letter}
                          alt="drop-img"
                        />
                        {/* <p className="dropzone__img-name">{files[0].name}</p> */}
                        <button
                          className="dropzone__img-delete"
                          type="button"
                          onClick={(e) => this.removeEmailLatter(e)}
                        >
                          Remove
                        </button>
                      </aside>
                    )}
                  </div>
                </div>
                {/* <span className="form__form-group-error">{this.validator.message('', this.state.email_letter, 'required')}</span> */}
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Profile Photo</span>
                <div className="form__form-group-field">
                  {/* <Field
                    name="profilePic"
                    component={renderDropZoneField}
                    onChange={this.profilePictureUpload}
                  /> */}
                  <div className={`dropzone dropzone--single`}>
                    {this.state.profileLoder && (
                      <Spinner
                        animation="border"
                        variant="warning"
                        className="dropzone__drop-here"
                      />
                    )}
                    <Dropzone
                      accept="image/jpeg, image/png"
                      name={'profilePic'}
                      multiple={false}
                      onDrop={(fileToUpload) => {
                        this.profilePictureUpload(fileToUpload)
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()} className="dropzone__input">
                          {this.state.profilePic &&
                            this.state.profilePic === '' &&
                            !this.state.profileLoder && (
                              <div className="dropzone__drop-here">
                                <span className="lnr lnr-upload" /> Drop File Here To Upload
                              </div>
                            )}
                          <input {...getInputProps()} disabled={this.state.profileLoder} />
                        </div>
                      )}
                    </Dropzone>
                    {this.state.profilePic && this.state.profilePic !== '' && (
                      <aside className="dropzone__img">
                        <img
                          src={this.state.profilePhotoView || this.state.profilePic}
                          alt="drop-img"
                        />
                        {/* <p className="dropzone__img-name">{files[0].name}</p> */}
                        <button
                          className="dropzone__img-delete"
                          type="button"
                          onClick={(e) => this.removeProfile(e)}
                        >
                          Remove
                        </button>
                      </aside>
                    )}
                  </div>
                </div>
                {/* <span className="form__form-group-error">{this.validator.message('', this.state.profilePic, 'required')}</span> */}
              </div>
              <ButtonToolbar className="form__button-toolbar mb-3">
                <Button color="primary" className="mb-0" type="submit" onClick={this.editStaff}>
                  Save
                </Button>
                <button type="button" className="btn-gray m-0" onClick={this.cancle}>
                  Cancel
                </button>
              </ButtonToolbar>
            </form>
          </CardBody>
        </Card>
      </Col>
    )
  }
}

// export default withRouter(EditStaffForm)

export default reduxForm({
  form: 'add_staff_form_validation',
})(withRouter(withTranslation('common')(EditStaffForm)))
