export const invoiceFormStyles = {
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'no-wrap',
  },

  headerButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'no-wrap',
    gap: '10px',
    border: '1px solid black',
  },

  globalInfoContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
    flexWrap: 'no-wrap',
    borderBottom: '1px solid #CCCCCC',
    marginBottom: '20px',
  },

  title: {
    fontSize: '18px',
    lineHeight: '20px',
    fontFamily: 'var(--SourceSansPro) !important',
    fontWeight: '700',
  },

  subtitle: {
    fontSize: '18px',
    lineHeight: '20px',
    fontFamily: 'var(--SourceSansPro) !important',
    fontWeight: '400',
  },

  row: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '10px',
    paddingBottom: '10px',
  },

  formBillingContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '10px',
    paddingBottom: '10px',
    borderBottom: '1px solid #CCCCCC',
    marginBottom: '20px',
  },

  billContainer: {
    width: '45%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },

  billFormContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },

  billHalfContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '10px',
    paddingBottom: '10px',
  },

  billHalf: {
    width: '45%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },

  formTemplateItemsContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    margin: '10px 0 20px 0',
    paddingBottom: '10px',
    borderBottom: '1px solid #CCCCCC',
  },

  formTemplateItemsTableHead: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #E8E7E7',
    padding: '10px',
  },

  rowContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: '10px 0',
  },

  formTemplateItemsTableRow: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },

  itemColumn: {
    width: '70%',
    fontSize: '18px',
    lineHeight: '20px',
    fontFamily: 'var(--SourceSansPro) !important',
    fontWeight: '700',
  },

  column: {
    width: '10%',
    fontSize: '18px',
    lineHeight: '20px',
    fontFamily: 'var(--SourceSansPro) !important',
    fontWeight: '700',
  },

  actionColumn: {
    width: '5%',
    fontSize: '18px',
    fontFamily: 'var(--SourceSansPro) !important',
    fontWeight: '700',
    display: 'flex',
    justifyContent: 'flex-end',
  },

  formTemplateItemsInfoContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    padding: '10px 0',
  },

  formTemplateItemsInfo: {
    width: '100%',
    maxWidth: '45%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },

  formTemplateInfo: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
  },

  formPaymentDetailsContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: '10px',
  },

  detailsContainer: {
    width: '45%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
    marginBottom: '10px',
  },

  bankDetails: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
    paddingLeft: '20px',
    gap: '5px',
    margin: '20px 0',
  },
};

export const checkboxStyles = {
  checkboxWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  checkbox: {
    padding: '0 9px',
  },
  label: {
    marginBottom: 0,
  },
};

export const emptyListStyles = {
  padding: '20px',
  color: '#646777',
  fontSize: '22px',
  textAlign: 'center',
};
