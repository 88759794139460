import { FC } from 'react'
import { DebtorLegal, DebtorLegalJudgmentDate } from '../../../types/debtor-legal.type'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import { getDebtorLegalByIdQueryKey } from '../../../utils/legal-query-key-factory.utils'
import Modal from '@components/modal'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import styles from './debtor-judgment-date-modal.module.scss'
import { BaseEditModalProps } from '@src/types/component-types'
import EditDebtorLegalJudgmentDateForm from '../../forms/debtor-legal-judgment-date-form/edit-debtor-legal-judgment-date-form.component'
import ModalHeader from '@components/modal-header.component'

const EditDebtorJudgmentDateModal: FC<BaseEditModalProps<DebtorLegalJudgmentDate>> = ({
  entity: debtorLegalJudgmentDate,
  ...modalProps
}) => {
  const queryClient = useQueryClient()
  const handleSuccessfulSubmit = (debtorLegalJudgmentDate: DebtorLegalJudgmentDate) => {
    queryClient.setQueryData<DebtorLegal>(
      getDebtorLegalByIdQueryKey(debtorLegalJudgmentDate.debtorLegalId),
      (prevData) =>
        prevData
          ? {
              ...prevData,
              judgmentDates: prevData.judgmentDates.map((jd) =>
                jd.id === debtorLegalJudgmentDate.id ? debtorLegalJudgmentDate : jd,
              ),
            }
          : undefined,
    )
    toast.success('Judgment date updated successfully')
    modalProps.onClose()
  }

  return (
    <Modal className={styles.modalWrapper} {...modalProps} withBtns={false}>
      <ModalHeader onCloseClick={modalProps.onClose} />
      <Header mb={4}>Edit Judgment Date</Header>
      <EditDebtorLegalJudgmentDateForm
        onSuccessSubmit={handleSuccessfulSubmit}
        entity={debtorLegalJudgmentDate}
      />
    </Modal>
  )
}

export default EditDebtorJudgmentDateModal
