import FormInput from '@components/inputs/form-input/form-input.component'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import styles from './debtor-claim-number-form.module.scss'
import { BaseFormProps } from '@src/types/component-types'
import { DebtorClaimNumberFormValues } from '../../../types/debtor-claim-number.type'
import { joiResolver } from '@hookform/resolvers/joi'
import { debtorClaimNumberSchema } from '../../../schemas/legal.schemas'

const DebtorLegalClaimNumberForm: FC<BaseFormProps<DebtorClaimNumberFormValues>> = ({
  submitButtonTitle = 'Submit',
  onSuccessSubmit,
  initialValues,
  loading = false,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<DebtorClaimNumberFormValues>({
    defaultValues: initialValues,
    resolver: joiResolver(debtorClaimNumberSchema),
  })

  useEffect(() => {
    if (!initialValues) return
    reset(initialValues)
  }, [initialValues])

  const onSubmit = (data: DebtorClaimNumberFormValues) => {
    onSuccessSubmit(data)
  }

  return (
    <form className={styles.formWrapper} onSubmit={handleSubmit(onSubmit)}>
      <FormInput
        label="Claim Number"
        {...register('claimNumber')}
        error={errors.claimNumber?.message}
      />
      <SubmitButton className={styles.submitButton} buttonType="submit" loading={loading}>
        {submitButtonTitle}
      </SubmitButton>
    </form>
  )
}

export default DebtorLegalClaimNumberForm
