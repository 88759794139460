import Modal from '@components/modal'
import { FC } from 'react'
import CreateDebtorRelationForm from '../../forms/debtor-relation-form/create-debtor-relation-form.component'
import styles from './debtor-relation-modal.module.scss'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import { useQueryClient } from '@tanstack/react-query'
import { getDebtorQueryKey } from '@containers/Recovery/Wip/_modules/Debtors/utils/debtor-query-key-factory.utils'
import {
  CreateDebtorAssociateDetailModalProps,
  DebtorRelation,
} from '@containers/Recovery/Wip/_modules/Debtors/types/debtor-associate-detail.type'
import { Debtor } from '@containers/Recovery/Wip/_modules/Debtors/types/debtor.type'

const CreateDebtorRelationModal: FC<CreateDebtorAssociateDetailModalProps> = ({
  debtorId,
  ...modalProps
}) => {
  const queryClient = useQueryClient()

  const handleSuccessSubmit = (debtorRelation: DebtorRelation) => {
    queryClient.setQueryData<Debtor>(getDebtorQueryKey(debtorId), (prevData) =>
      prevData
        ? {
            ...prevData,
            relations: [...prevData.relations, debtorRelation],
          }
        : undefined,
    )
    modalProps.onClose()
  }

  return (
    <Modal className={styles.modalWrapper} {...modalProps} withBtns={false} classNameLayout={styles.layout}>
      <Header mb={4}>Add Relation</Header>
      <CreateDebtorRelationForm
        debtorId={debtorId}
        onSuccessSubmit={handleSuccessSubmit}
        onCancelClick={modalProps.onClose}
      />
    </Modal>
  )
}

export default CreateDebtorRelationModal
