import { TabWrapper } from './TabWrapper/TabWrapper'
import AutomationBoard from './automations'

const AutomationsPage = () => {
  return (
    <div className="container position-relative">
      <div className="client_info-block mt-3">
        <TabWrapper />
      </div>
      <AutomationBoard />
    </div>
  )
}

export default AutomationsPage
