import { REACT_APP_API_URL } from "@src/lib/global-variables";
import { PaginationResponse, Params } from "@src/types/service.type";
import axios from "axios";
import { CreateDebtorAddress, DebtorAddress } from "../types/debtor-address.type";
import { DeepPartial } from "react-hook-form";

export class DebtorAddressHistoryService {

  async createDebtorAddress(address: CreateDebtorAddress) {
    const res = await axios.post<DebtorAddress>(`${REACT_APP_API_URL}/debtor-addresses`, address)
    return res.data;
  }

  async getDebtorAddressHistory(params: Params = {}) {
    const res = await axios.get<PaginationResponse<DebtorAddress[]>>(`${REACT_APP_API_URL}/debtor-addresses`, { params })
    return res.data;
  }

  async updateDebtorAddress(debtorAddressId: number, updateData: DeepPartial<DebtorAddress>, params: Params = {}) {
    const res = await axios.patch<DebtorAddress>(`${REACT_APP_API_URL}/debtor-addresses/${debtorAddressId}`, updateData, { params })
    return res.data;
  }

  async deleteDebtorAddress(debtorAddressId: number) {
    const res = await axios.delete<DebtorAddress>(`${REACT_APP_API_URL}/debtor-addresses/${debtorAddressId}`)
    return res.data;
  }
}