import { BaseModalProps } from '@src/types/component-types'
import { FC, useState } from 'react'
import { HeadOption } from './hierarchical-select.component'
import SecondaryTable from '@containers/Sales/Properties/components/tables/secondary-table/secondary-table.component'
import TableRow from '@components/table/table-row.component'
import TableCell from '@components/table/table-cell-component'
import styles from './select-tail-option-modal.module.scss'
import Modal from '@components/modal'
import { getValueFromObjectByPath } from '@src/shared/helpers/get-value-from-object-by-path.util'
import FormInput from '@components/inputs/form-input/form-input.component'
import SearchIcon from '@src/icons/search'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import CustomRadio from '@components/inputs/custom-radio-input.component'

interface SelectTailOptionModalProps extends BaseModalProps {
  headOption: HeadOption
}

const SelectTailOptionModal: FC<SelectTailOptionModalProps> = ({ headOption, ...modalProps }) => {
  const [checkedRowIndex, setCheckedRowIndex] = useState<number>(0)

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedRowIndex(Number(event.target.value))
  }
  return (
    <Modal
      className={styles.modalWrapper}
      classNameLayout={styles.modalLayout}
      {...modalProps}
      withBtns={false}
    >
      <div className={styles.modalHeader}>
        <p className={styles.modalTitle}>Select Property for Client ‘Real Estate Pty LTD’</p>
        <div className={styles.searchInput}>
          <FormInput size="small" placeholder="Search" icon={SearchIcon} />
        </div>
      </div>
      <div className={styles.tableWrapper}>
        <div className={styles.tableBorder}>
          <SecondaryTable>
            <TableRow>
              <TableCell></TableCell>
              {headOption.columns.map((column, index) => (
                <TableCell
                  className={styles.headerCell}
                  key={`secondary-table-header-${index}`}
                  position="eclipsed"
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
            {headOption.options.map((tailOption, tailOptionRowIndex) => (
              <TableRow key={`tail-option-${tailOptionRowIndex}`}>
                <TableCell className={styles.radioCell}>
                  <CustomRadio
                    checked={tailOptionRowIndex === checkedRowIndex}
                    value={tailOptionRowIndex}
                    onChange={handleRadioChange}
                  />
                </TableCell>
                {headOption.columns.map((column, tailOptionCellIndex) => (
                  <TableCell
                    key={`tail-option-cell-${tailOptionRowIndex}-${tailOptionCellIndex}`}
                    position="eclipsed"
                  >
                    {getValueFromObjectByPath(tailOption.data, column.path) || ''}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </SecondaryTable>
        </div>
      </div>
      <div className={styles.modalFooter}>
        <SubmitButton onClick={modalProps.onClose} className={styles.selectButton} buttonStyle="highlighted" size="large">
          Select Field
        </SubmitButton>
      </div>
    </Modal>
  )
}

export default SelectTailOptionModal
