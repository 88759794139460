import Joi from 'joi'
import { ICreateAutomation } from './types'

const select = {label: Joi.string(), value: Joi.number()};

export const CreateAutomationSchema = Joi.object<ICreateAutomation>({
  contacts: Joi.array()
    .items(
      Joi.object({
        value: Joi.number().required(),
        label: Joi.string().required(),
      }),
    )
    .required()
    .messages({
      'array.base': 'Contacts is required',
    }),
  description: Joi.string().required().messages({
    'string.empty': 'Description is required',
  }),
  title: Joi.string().required().messages({
    'string.empty': 'Title is required',
  }),
  isAllContactsSelected: Joi.boolean().allow(false, ''),
  column: Joi.object().required().messages({
    'object.base': 'Column is required',
  }),
  email_template: Joi.string().allow('', null),
  email_template_id: Joi.number().allow(null),
  email_subject: Joi.string().allow('', null),
  sms_template: Joi.string().allow('', null),
  task_template: Joi.any().allow(null), // Adjust this based on the actual type of task_template
  is_cron: Joi.boolean().allow(false, ''),
  schedule_date_interval: Joi.number()
    .when('is_cron', {
      is: true,
      then: Joi.required(),
    })
    .messages({
      'number.base': 'Schedule date interval is required',
    }),
})

export const SetEmailScheme = Joi.object({
  template: Joi.object({label:Joi.string().required(), value: Joi.object({body: Joi.string().required(), id: Joi.number().required()}).required()}).allow(null),
  message: Joi.string().required().messages({
    'string.empty': 'Message is required',
  }),
  subject: Joi.string().required().messages({
    'string.empty': 'Subject is required',
  }),
})

export const SetSmsSchema = Joi.object({
  message: Joi.string().required().messages({
    'string.empty': 'Message is required',
  }),
  sms_template: Joi.any().allow('', null),
})

export const SetTaskChangeSchema = Joi.object({
  priority: Joi.object(select).required().messages({
    'any.required': 'Task Template is required',
  }),
  type: Joi.string().allow('', null),
  assign_to: Joi.array().items({
    access_level: Joi.string(),
    ...select
  }).allow(null),
  due_date: Joi.any().allow('', null).required(),
  excluded_from_calendar: Joi.boolean().allow(false, ''),
  task_template: Joi.object(select).required().messages({
    'any.required': 'Task Template is required',
  }),
  title: Joi.string().min(1).required().messages({
    'string.empty': 'Title is required',
    'string.min': 'Title is required',
  }),
  description: Joi.string().required().messages({
    'string.empty': 'Description is required',
  }),
  excluded_from_automation: Joi.boolean().allow(false, ''),
  reset_title: Joi.boolean().allow(false, ''),
})

export const EditAuthomationSchema = Joi.object({
  is_all_contacts_selected: Joi.boolean(),
  email_template: Joi.string().allow('', null),
  email_template_id: Joi.number().allow(null),
  email_subject: Joi.string().allow('', null),
  sms_template: Joi.string().allow('', null),
  task_template: Joi.any().allow(null),
  createdBy: Joi.string(),
  createdDate: Joi.string(),
  id: Joi.number(),
  clientId: Joi.number().optional().allow(null),
  wip_id: Joi.string().optional().allow(null),
  column: Joi.any(),
  contacts: Joi.array()
    .items(
      Joi.object({
        value: Joi.number().required(),
        label: Joi.string().required(),
      }),
    )
    .required()
    .messages({
      'array.base': 'Contacts is required',
    }),
  description: Joi.string().required().messages({
    'string.empty': 'Description is required',
  }),
  title: Joi.string().required().messages({
    'string.empty': 'Title is required',
  }),
  is_cron: Joi.boolean().allow(false, ''),
  contacts_automations: Joi.array().items(Joi.number()),
  schedule_date_interval: Joi.number()
  .when('is_cron', {
    is: true,
    then: Joi.required(),
  })
  .messages({
    'number.base': 'Schedule date interval is required',
  }),
  referrence_column_id: Joi.number()
})
