import React, { useEffect, useState } from 'react'
import { useForm, useFieldArray } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import axios from 'axios'
import Loader from './../../loading/loading'

import AddProtectAndCollectForm from './protectAndCollectForm'
import { deepCopyFunction } from '../../../shared/helpers/deepCopy'

import '../../../scss/form/styles.scss'

const EditProtectAndCollectForm = () => {
  const [loading, setLoading] = useState(false)
  const [loadingData, setLoadingData] = useState(false)
  const [files, setFiles] = useState()

  const { id } = useParams()
  const history = useHistory()

  const { reset, handleSubmit, register, watch, control } = useForm({
    mode: 'onSubmit',
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'tenants_details',
  })

  const getItem = async () => {
    setLoadingData(true)
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/protect-and-collect-form/${id}`,
        { params: { join: 'wip_template' } },
      )
      reset({ ...response.data, wip_template: response.data.wip_template?.id })
      setLoadingData(false)
      setFiles(response?.data?.files)
    } catch (error) {
      setLoadingData(false)
      toast.error('Something went wrong!')
    }
  }

  useEffect(() => {
    getItem()
  }, [])

  const editProtectCollectFormData = async (formData) => {
    setLoading(true)
    try {
      await axios.patch(`${process.env.REACT_APP_API_URL}/protect-and-collect-form/${id}`, formData)
      reset()
      setLoading(false)
      toast.success('Submited Successfully')
      history.push('/recoveries/forms/protect_&_collect')
    } catch (error) {
      setLoading(false)
      toast.error(error?.response?.data?.message?.[0])
    }
  }

  const onSubmit = handleSubmit((formData) => {
    console.log(formData)
    const dataClone = deepCopyFunction(
      deepCopyFunction({
        ...formData,
        files: files,
      }),
    )

    editProtectCollectFormData(
      dataClone.recovery_details
        ? {
            ...dataClone,
            is_entered_all_tenants: dataClone.is_entered_all_tenants === 'true' ? true : false,
            recovery_details: Object.fromEntries(
              Object.entries(dataClone.recovery_details).map((item) => {
                if (item[1] === 'true') {
                  return [item[0], true]
                }
                if (item[1] === 'false') {
                  return [item[0], false]
                }
                return item
              }),
            ),
          }
        : {
            ...dataClone,
            is_entered_all_tenants: dataClone.is_entered_all_tenants === 'true' ? true : false,
          },
    )
  })

  return (
    <>
      {loadingData ? (
        <Loader />
      ) : (
        <AddProtectAndCollectForm
          control={control}
          onSubmit={onSubmit}
          register={register}
          fields={fields}
          append={append}
          remove={remove}
          watch={watch}
          setFiles={setFiles}
          files={files}
          loading={loading}
        />
      )}
    </>
  )
}

export default EditProtectAndCollectForm
