import { useUserContext } from "@src/context/user-context"
import { Route, Redirect } from 'react-router-dom'

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const {user} = useUserContext()
  return (
    <Route
      {...rest}
      render={(props) =>
        user ? (
          <>
            <Component {...props} />
          </>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}
