import { useGetSmsConversationFragment } from '@containers/Communication/hooks/get-sms-conversation-fragment.hook'
import { useGetSmsConversation } from '@containers/Communication/hooks/get-sms-conversation.hook'
import { ShortMessage, SmsType } from '@containers/Communication/types/sms.type'
import { FC, useCallback, useEffect, useRef } from 'react'
import styles from './sms-modal.module.scss'
import SmsConversationItem from './sms-conversation-item.component'
import { Oval } from 'react-loader-spinner'

interface SmsConversationProps {
  shortMessage: ShortMessage
}

const LIMIT = 6

const SmsConversation: FC<SmsConversationProps> = ({ shortMessage }) => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const { data: smsConversationFragment } = useGetSmsConversationFragment({
    phone_number:
      shortMessage.sms_type === SmsType.Incoming
        ? encodeURI(shortMessage.from)
        : encodeURI(shortMessage.to),
    start_from_message_id: shortMessage.id,
    limit: LIMIT,
  })

  const {
    data: smsConversation,
    fetchPreviousPage,
    fetchNextPage,
    isFetching,
    hasNextPage,
    hasPreviousPage,
    isLoading,
    isFetchingPreviousPage,
    isFetchingNextPage,
  } = useGetSmsConversation(
    {
      phone_number:
        shortMessage.sms_type === SmsType.Incoming
          ? encodeURI(shortMessage.from)
          : encodeURI(shortMessage.to),
      page: smsConversationFragment?.current_page,
      limit: LIMIT,
    },
    !!smsConversationFragment,
  )

  const firstItemObserver = useRef<IntersectionObserver>()

  const lastItemObserver = useRef<IntersectionObserver>()

  const lastElementRef = useCallback(
    (node: HTMLDivElement) => {
      if (isLoading) return
      if (lastItemObserver.current) lastItemObserver.current.disconnect()
      lastItemObserver.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage && !isFetching) {
          fetchNextPage()
        }
      })
      if (node) lastItemObserver.current.observe(node)
    },
    [isLoading, isFetching, hasNextPage],
  )

  const firstElementRef = useCallback(
    (node: HTMLDivElement) => {
      if (isLoading) return
      if (firstItemObserver.current) firstItemObserver.current.disconnect()
      firstItemObserver.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasPreviousPage && !isFetching) {
          fetchPreviousPage()
        }
      })
      if (node) firstItemObserver.current.observe(node)
    },
    [isLoading, isFetching, hasPreviousPage],
  )

  const getRef = (currentIndex: number, maxIndex: number) => {
    if (currentIndex === 0) {
      return firstElementRef
    }

    if (currentIndex === maxIndex) {
      return lastElementRef
    }

    return null
  }

  useEffect(() => {
    if (wrapperRef.current && smsConversation.length <= LIMIT) {
      wrapperRef.current.scrollTop = wrapperRef.current.scrollHeight
    }
  }, [smsConversation, wrapperRef])

  return (
    <div className={styles.smsConversationWrapper} ref={wrapperRef}>
      {smsConversation.length > LIMIT && !hasPreviousPage ? (
        <span className={styles.endText}>You Reach Top</span>
      ) : null}

      {isFetchingPreviousPage ? (
        <div className={styles.loadingWrapper}>
          <Oval
            visible={true}
            height="40"
            width="40"
            color="blue"
            secondaryColor="blue"
            ariaLabel="oval-loading"
          />
        </div>
      ) : null}

      {smsConversation?.map((sms, index) => {
        return (
          <SmsConversationItem
            elementRef={getRef(index, smsConversation.length - 1)}
            key={sms.id}
            shortMessage={sms}
          />
        )
      })}

      {!smsConversation.length && isLoading ? (
        <Oval
          visible={true}
          height="60"
          width="60"
          color="blue"
          secondaryColor="blue"
          ariaLabel="oval-loading"
          wrapperStyle={{
            position: 'absolute',
            top: '50%',
            right: '50%',
            transform: 'translate(50%,-50%)',
          }}
        />
      ) : null}

      {isFetchingNextPage ? (
        <div className={styles.loadingWrapper}>
          <Oval
            visible={true}
            height="40"
            width="40"
            color="blue"
            secondaryColor="blue"
            ariaLabel="oval-loading"
          />
        </div>
      ) : null}

      {smsConversation.length > LIMIT && !hasNextPage ? (
        <span className={styles.endText}>You Reach End</span>
      ) : null}
    </div>
  )
}

export default SmsConversation
