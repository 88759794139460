import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Container, Row, Spinner } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RecoveryForm from './components/index';
import { RTLProps } from '../../../shared/prop-types/ReducerProps';
import { getURFInfo, updateURF } from './service';
import Services from '../../../shared/Services';

const EditRecoveryForm = ({ rtl }) => {
  const services = new Services();
  const { id } = useParams();
  const [formInfo, setFormInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const submitFormHandler = (data) => {
    updateURF(id, data)
      .then((res) => {
        if (res.status === 200) {
          services.successToast('Form submitted successfully');
          getFormInfo();
          history.push('/recovery');
        } else {
          services.errorToast(res.data.message);
        }
      })
      .catch((e) => {
        services.errorToast(e);
      });
  };

  const getFormInfo = () => {
    const params = {
      join: ['company', 'company.login_type'],
    };
    getURFInfo(id, params)
      .then((res) => {
        setFormInfo(res?.data);
      })
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    getFormInfo();
  }, []);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Recovery Tenant Form</h3>
        </Col>
      </Row>
      <Row>
        {isLoading ? (
          <Spinner />
        ) : (
          formInfo && (
            <RecoveryForm
              onSubmit={submitFormHandler}
              dir={rtl.direction}
              defaultData={formInfo}
              isEdit={true}
            />
          )
        )}
      </Row>
    </Container>
  );
};

EditRecoveryForm.propTypes = {
  t: PropTypes.func.isRequired,
  rtl: RTLProps.isRequired,
};

export default connect((state) => ({
  rtl: state.rtl,
}))(withTranslation('common')(EditRecoveryForm));
