import { FC } from 'react'
import { useGetDebtorLegalListByWipId } from '../../hooks/get-debtor-legal-list-by-wip-id.hook'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import Section from '@components/section/section.component'
import WipLegalTable from '../../components/tables/wip-legal-table/wip-legal-table.component'
import styles from './wip-legal-page.module.scss'
import { WipLegalPageProps } from '../../types/debtor-legal.type'
import { useModal } from '@src/hooks/useModal'
import AddDebtorLegalModal from '../../components/modals/add-debtor-legal-modal/add-debtor-legal-modal.component'
import { GET_LEGAL_LIST_PARAMS } from '../../const/debtor-legal.const'

const WipLegalPage: FC<WipLegalPageProps> = ({ wip }) => {
  const [isOpen, handleOpen, handleClose] = useModal(false)
  const { data, isLoading, isRefetching } = useGetDebtorLegalListByWipId(
    wip.id,
    GET_LEGAL_LIST_PARAMS,
  )

  return (
    <div className={styles.wrapper}>
      <div className={styles.headerWrapper}>
        <Header>Debtors Servicing</Header>
        <SubmitButton onClick={handleOpen}>Add Debtor</SubmitButton>
      </div>
      <Section className={styles.sectionWrapper}>
        <WipLegalTable
          wipId={wip.id}
          debtorLegals={data || []}
          debtors={wip.debtors}
          loading={isLoading || isRefetching}
        />
      </Section>
      <AddDebtorLegalModal
        wipId={wip.id}
        debtorsOptions={wip.debtors.map((debtor) => ({
          label: `${debtor.name_first} ${debtor.name_last}`,
          value: debtor.id,
        }))}
        isOpen={isOpen}
        onClose={handleClose}
      />
    </div>
  )
}

export default WipLegalPage
