import React from 'react';

import { Grid, Typography, Checkbox } from '@mui/material';

import { formInputStyles } from './styles';

const FormCheckbox = ({ label, name, changeHandler, value }) => {
  return (
    <Grid item sx={formInputStyles.container}>
      <Typography sx={formInputStyles.label}>{label}</Typography>

      <Checkbox name={name} onChange={changeHandler} value={value} />
    </Grid>
  );
};

export default FormCheckbox;
