import React,{Component} from 'react';
import { Col, Container, Row } from 'reactstrap';
import SettingTable from '../../components/SettingTable';

class ListStaff extends Component{
  constructor(props){
    super(props)
    this.state={
      title:""
  }
}
  componentDidMount(){
    this.setState({title:"MANAGE STAFF"})
  }
  render(){
    return(
      <Container>
      <Row>
        <Col md={12}>
            <h3 className="page-title">{this.state.title}</h3>
        </Col>
      </Row>
      <Row>
        <SettingTable />
      </Row>
    </Container>
    )
  }
}
export default ListStaff;
