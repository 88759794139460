import { useCreateLandlordAddressMutation } from '@containers/Sales/Landlord/hooks/crud-landlord-address.hook'
import AddressForm, { AddressFormValues } from '../property-address-form/address-form.component'
import { FC } from 'react'
import {
  CreateLandlordAddressData,
  LandlordAddress,
} from '@containers/Sales/Properties/types/property.type'
import { toast } from 'react-toastify'

interface CreateLandlordAddressFormProps {
  landlordId: number
  onSuccessSubmit?: (data: LandlordAddress) => void
}

const CreateLandlordAddressForm: FC<CreateLandlordAddressFormProps> = ({
  landlordId,
  onSuccessSubmit,
}) => {
  const { mutateAsync, isLoading } = useCreateLandlordAddressMutation()

  const handleSuccessSubmit = async (data: AddressFormValues) => {
    try {
      const res = await mutateAsync({
        landlordId,
        data: {
          ...data,
          state: data.state?.value || null,
        } as CreateLandlordAddressData,
      })

      onSuccessSubmit && onSuccessSubmit(res)
    } catch (e) {
      toast.error('Failed to create landlord address')
      console.error(e)
    }
  }

  return <AddressForm onSuccessSubmit={handleSuccessSubmit} loading={isLoading} submitButtonTitle='Create' />
}

export default CreateLandlordAddressForm
