import { FC } from 'react'
import styles from './paginated-select.module.scss'
import { SelectedOptionProps } from './pagination-select.type'
import cls from 'classnames'

const SelectedOption: FC<SelectedOptionProps> = ({ option, onOptionDelete }) => {
  const handleOptionDelete = () => {
    if (!onOptionDelete) return
    const { ref, value, label } = option
    onOptionDelete({ value: false, option: { label, value }, ref })
  }

  return (
    <div className={styles.selectedOptionWrapper}>
      <span>{option.label}</span>
      <span className={cls("lnr lnr-cross", styles.pointer)} onClick={handleOptionDelete}></span>
    </div>
  )
}

export default SelectedOption
