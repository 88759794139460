import { FC, useState } from 'react'
import { DebtorLegalPlaintiff } from '../../../types/debtor-legal.type'
import DebtorLegalPlaintiffListItem from './debtor-legal-plaintiff-list-item.component'
import { useModal } from '@src/hooks/useModal'
import EditDebtorLegalPlaintiffModal from '../../modals/debtor-legal-plaintiff-modal/edit-debtor-legal-plaintiff-modal.component'
import { DebtorLegalPlaintiffListProps } from '../../../types/debtor-plaintiff.type'

const DebtorLegalPlaintiffList: FC<DebtorLegalPlaintiffListProps> = ({ debtorLegalPlaintiffs }) => {
  const [isOpen, handleOpen, handleClose] = useModal(false)
  const [selectedDebtorLegalPlaintiff, setSelectedDebtorLegalPlaintiff] =
    useState<DebtorLegalPlaintiff | null>(null)

  const handleEditClick = (debtorLegalPlaintiff: DebtorLegalPlaintiff) => {
    setSelectedDebtorLegalPlaintiff(debtorLegalPlaintiff)
    handleOpen()
  }

  return (
    <div>
      {debtorLegalPlaintiffs.map((debtorLegalPlaintiff) => {
        return (
          <DebtorLegalPlaintiffListItem
            onEditClick={handleEditClick}
            debtorLegalPlaintiff={debtorLegalPlaintiff}
            key={debtorLegalPlaintiff.id}
          />
        )
      })}
      {selectedDebtorLegalPlaintiff ? (
        <EditDebtorLegalPlaintiffModal
          debtorLegalPlaintiff={selectedDebtorLegalPlaintiff}
          isOpen={isOpen}
          onClose={handleClose}
        />
      ) : null}
    </div>
  )
}

export default DebtorLegalPlaintiffList
