import CloseIcon from '@src/icons/close'
import { FC } from 'react'
import styles from './modal.module.scss'

interface ModalHeaderProps {
  onCloseClick?: () => void
}

const ModalHeader: FC<ModalHeaderProps> = ({ onCloseClick }) => {
  return (
    <div className={styles.headerWrapper}>
      <button className={styles.closeIcon} onClick={onCloseClick}>
        <CloseIcon />
      </button>
    </div>
  )
}

export default ModalHeader
