import React, { Fragment, useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { TailSpin } from 'react-loader-spinner';
import Services from '../../../../shared/Services';
import { Checkbox, makeStyles, TableCell } from '@material-ui/core';
import Select from 'react-select';
import TablePagination from '@material-ui/core/TablePagination';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import MonthReportService from '../../../../services/MonthReport';
import moment from 'moment/moment';
import WIPService from '../../../../services/WIPService';
import { useUserContext } from '../../../../context/user-context';

const useStyles = makeStyles(() => ({
  ButtonUpdate: {
    marginTop: 22,
    marginLeft: 101,
  },
  PageHeader: {
    fontSize: 25,
    fontWeight: 700,
    color: '#000',
  },
  PageSubtitle: {
    fontSize: 16,
    color: '#000',

  },
  Card: {
    padding: '10px 15px',
    marginTop: 20,
  },
  BackButton: {
    background: '#fff',
    borderRadius: 5,
    fontWeight: 700,
  },
  Button: {
    marginBottom: 0,
    borderRadius: 5,
  },
  TableTitle: {
    fontSize: 18,
    fontWeight: 700,
    color: '#000',
    marginBottom: 30,
  },
  TableSubtitle: {
    fontSize: 16,
    color: '#000',
    marginTop: 20,
  },
  TableHeader: {
    background: '#fff',

  },
  Footer: {
    background: '#ffff0014',
    border: '1px dashed #e5de0891',
    padding: '25px 15px',
    marginTop: 20,
  },
  textTable: {
    fontSize: '14px !important',
  },
  color: {
    color: '#BFC5D2!important',
    fontSize: '12.5px !important',
    verticalAlign: 'bottom',
    paddingBottom: '0px',
    lineHeight: '1.2rem',
  },
  centerRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px !important',
  },

}));

const MonthReport = () => {

  const classes = useStyles();
  const {user} = useUserContext()
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [wipNumber, setWipNumber] = useState(266);
  const [transaction, setTransaction] = useState([]);
  const [optionCompany, setOptionCompany] = useState([]);
  const [totalPaidOutDue, setTotalPaidOutDue] = useState('');
  const [selectOption, setSelectOption] = useState([{ value: 0, label: 'All Type' }]);
  const [markSelected, setMarkSelected] = useState([]);
  const transactionService = new MonthReportService();
  const services = new Services();
  const wipService = new WIPService();

  useEffect(() => {
    getReport();
    getWipCoverOptions();
  }, []);

  const getWipCoverOptions = async () => {
    const response = await wipService.getWipsCover();
    if (response) {
      const option = response.data.map((e) => ({
        value: e.id,
        label: e.title,
      }));
      option.push({ value: 0, label: 'All Type' });
      setOptionCompany(option);
    }
  };

  const getReport = async (params) => {
    setIsLoading(true);
    try {
      const res = await transactionService.getMonthReport(params);

      const totalPaid = res.data.totalPaidOutDue;
      setTotalPaidOutDue(totalPaid);
      const arrayReports = Object.values(res.data.rows);
      setTransaction(arrayReports);
    } catch (e) {
      services.errorToast(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getReport(wipNumber, newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getReport(wipNumber, 0, event.target.value);
  };

  const changeOption = e => {
    setSelectOption(e);
    if (e.value === 0) {
      const params = {};
      getReport(params);
    } else {
      const params = {
        wipCoverId: e.value,
      };
      getReport(params);
    }
  };

  const genReport = async () => {
    setIsLoading(true);
    const params = {
      staffId: user.id,
    };
    try {
      const res = await transactionService.generateMonthReport(params);
      if (res.status === 201) {
        getReport();
      }
    } catch (e) {
      services.errorToast(e);
    } finally {
      setIsLoading(false);
    }
  };

  const getPaidOutId = (id, e) => {
    if (e.target.checked) {
      setMarkSelected((val) => [...val, id]);
    } else {
      const selected = markSelected.filter((e) => e !== id);
      setMarkSelected(selected);
    }
  };

  const markSelectedPaidOut = () => {
    setMarkSelected([])
    getReport()
    services.successToast('Marked payment sent successfully');
  };

  return (
    <>
      <div className='container w-100'>
        <div id='contacts_accordion' className='form'>
          <div className='card '>
            <div className='justify-content-between p-relative d-flex  align-content-center'>
              <h3 className='page-title mb-0'>End of Month Reporting</h3>
              <Button
                className='btn-group--icons mb-0'
                onClick={genReport}
              >
                Generate a report
              </Button>
            </div>

            <div
              id='collapseOne'
              className='collapse show '
              data-parent='#contacts_accordion'
              style={{ background: '#fff' }}
            >
            </div>
          </div>
        </div>
      </div>
      {transaction.length !== 0 && <div className='container w-100'>
        <div id='contacts_accordion' className='form'>
          <div className='card mb-4'>

            <div
              id='collapseOne'
              className='collapse show mb-4'
              data-parent='#contacts_accordion'
              style={{ background: '#fff' }}
            >

              <div className='card-body'>
                <div
                  className='justify-content-between p-relative d-flex mb-4 align-content-center align-items-center '>
                  <div className='form-group' style={{ width: '300px' }}>
                    <label className='form__form-group-label' htmlFor=''>Select Type</label>
                    <Select
                      name='client_type'
                      type='text'
                      options={optionCompany}
                      classNamePrefix='react-select'
                      className='react-select'
                      value={selectOption}
                      onChange={(e) => changeOption(e)}
                    />
                  </div>
                  <Button
                    className='btn-group--icons mb-1'
                  >
                    Print PDF
                  </Button>
                </div>
                {isLoading ? (
                  <div style={{ display: 'flex', justifyContent: 'center', height: '300px', marginTop: '220px' }}>
                    <TailSpin
                      height='50'
                      width='50'
                      color='#f69066'
                      ariaLabel='loading'
                    />
                  </div>
                ) : transaction.length !== 0 && (<>
                  <hr width='100%' size='2' color='#BFC5D2' />
                  <div className='d-flex flex-column w-100'>
                    {transaction.map((items, i) =>
                      <Fragment key={i}>
                        {items.rows.map((item, i) => (
                          <Fragment key={i}>
                            {i === 0 ?
                              <h3 className={'mb-2'}><strong>{items.companyName}</strong></h3> :
                              <p className={'mb-2'}><strong>{items.companyName}</strong></p>}
                            <p className={'m-0'}>Status: Current
                              Renewal: {moment(item.createdAt).format('DD-MM-YYYY')}</p>
                            <br />
                            <Table aria-label='simple table' className={'mb-2'}>
                              {i === 0 && <TableHead>
                                <TableRow className={classes.TableHeader}>
                                  <TableCell width={'60px'} className={classes.color}>Prop ID</TableCell>
                                  <TableCell width={'145px'} className={classes.color}>Property</TableCell>
                                  <TableCell width={'160px'} className={classes.color}>Tenants</TableCell>
                                  <TableCell className={classes.color}>Last Payment to Agent</TableCell>
                                  <TableCell className={classes.color}>Last Payment From Tenant</TableCell>
                                  <TableCell className={classes.color}>Total Claim Amount</TableCell>
                                  <TableCell width={'135px'} className={classes.color}>Total Recoverable
                                    Costs</TableCell>
                                  <TableCell className={classes.color}>% of TC to Agent</TableCell>
                                  <TableCell className={classes.color}>% Dispersed to Agent after PP</TableCell>
                                  <TableCell className={classes.color}>Total Dispersed to Agent</TableCell>
                                  <TableCell className={classes.color}>Amount Held Not paid out</TableCell>
                                  <TableCell align={'center'} className={classes.color}>Tools</TableCell>
                                  <TableCell width={'60px'} className={classes.color} />
                                </TableRow>
                              </TableHead>}
                              <TableBody>
                                <TableRow style={{ borderTop: '1px solid #BFC5D2', borderBottom: '1px solid #BFC5D2' }}
                                          className={classes.textTable}>
                                  <TableCell width={'60px'} className={classes.textTable}>{item.propertyId}</TableCell>
                                  <TableCell width={'145px'}
                                             className={classes.textTable}>{item.propertyName}</TableCell>
                                  <TableCell width={'160px'} style={{ color: '#ec6b1c' }} className={classes.textTable}>
                                    {item.debtors.map((e, i) => (
                                      <TableRow> <span key={i}>{e.debtorName}</span></TableRow>
                                    ))}
                                  </TableCell>
                                  <TableCell width={'110px'}
                                             className={classes.textTable}>{item.lastPaymentToAgent || 0}</TableCell>
                                  <TableCell width={'110px'}
                                             className={classes.textTable}>{item.lastPaymentFromTenant || 0}</TableCell>
                                  <TableCell width={'115px'}
                                             className={classes.textTable}><strong>${item.totalClaimAmount}</strong></TableCell>
                                  <TableCell width={'135px'}
                                             className={classes.textTable}><strong>${item.totalRecoverCosts}</strong></TableCell>
                                  <TableCell width={'115px'}
                                             className={classes.textTable}>{item.percentsToAgent}%</TableCell>
                                  <TableCell width={'135px'}
                                             className={classes.textTable}>{item.percentsDispersedToAgent}%</TableCell>
                                  <TableCell width={'135px'}
                                             className={classes.textTable}><strong>${item.totalDispersedToAgent}</strong></TableCell>
                                  <TableCell width={'115px'}
                                             className={classes.textTable}><strong>${item.amountHeld}</strong></TableCell>
                                  <TableCell align={'center'} className={classes.textTable}><Checkbox
                                    color='default' onClick={((e) => getPaidOutId(item.id, e))} /></TableCell>
                                  <TableCell align={'center'} className={classes.textTable}><input
                                    style={{ width: '75px' }}
                                    className={'custom-input'}
                                    defaultValue={`$${item.paidOutDue}`} /></TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                            {item.owner && item.owner.payDirect && (
                              <>
                                <p className={classes.textTable} style={{ color: 'red' }}>Pay Owner Direct:</p>
                                <p className={classes.textTable}>Owner Name: {item.owner.name}</p>
                                <p className={classes.textTable}>Owner Address: {item.owner.address} </p>
                                <p className={classes.textTable}>Owner Contact No.: {item.owner.contanctNumber} </p>
                                <p className={classes.textTable}>Owner Email: {item.owner.email}</p>
                                <p className={classes.textTable}>Owner Bank Details: BSB: 805-050 Account
                                  No.{item.owner.accountNumber} </p>
                              </>)
                            }
                            <p className={classes.textTable}>Agent Total: <strong>${item.totalDispersedToAgent}</strong>
                            </p>
                            <hr width='100%' size='2' color='#BFC5D2' />
                          </Fragment>))}

                      </Fragment>)}

                    <div className='px-2 mb-3'>
                      <div className={`row ${classes.Footer}`}>
                        <div className='col-sm-4 col-xs-12'>
                          <div className='d-flex flex-column mt-2 mb-2'>
                            <p>
                              <strong>Total to be dispersed: $ {totalPaidOutDue}</strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className='justify-content-between p-relative d-flex align-content-center align-items-center mt-3 '>
                      <div className='form-group' style={{ width: '300px' }}>
                      </div>
                      <Button
                        className='btn-group--icons mb-0'
                        onClick={markSelectedPaidOut}
                        disabled={markSelected.length === 0}
                      >
                        Mark Selected as Paid Out
                      </Button>
                    </div>

                  </div>
                </>)}
              </div>
            </div>
            <TablePagination
              component='div'
              count={+total}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>}
    </>
  );
};

export default MonthReport;