import * as Yup from 'yup';

const phoneRegExp =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

export const commercialUncoveredFormValidationSchema = Yup.object().shape({
  company: Yup.object().shape({
    id: Yup.number().required('Please select compnay!'),
  }),

  property_address: Yup.object().shape({
    property_address: Yup.string().required('Please fill property address!'),
    property_sub_urb: Yup.string().required('Please fill property sub urb!'),
  }),

  tenants_details: Yup.array(
    Yup.object({
      email: Yup.string().email('Please fill correct email!'),
      first_name: Yup.string().required('Please fill first name!'),
      last_name: Yup.string().required('Please fill last name!'),
      current_address: Yup.string().required('Please fill current address!'),
      home_phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
      work_phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
      mobile_phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
    }),
  ),

  recovery_details: Yup.object().shape({
    date_vacated: Yup.string().required('Please fill date vacated!'),
    locked_out_by: Yup.string().required('Please fill locked out by!'),
    date_re_leased: Yup.string().required('Please fill date re leased!'),
  }),

  management_details: Yup.object().shape({
    property_manager: Yup.string().required('Please fill property manager!'),
    reports_email: Yup.string()
      .email('Please fill correct email!')
      .required('Pease fill reports email!'),
  }),

  owner_details: Yup.object().shape({
    name: Yup.string().required('Please fill owner name!'),
    address: Yup.string().required('Please fill owner address!'),
    email: Yup.string().email('Please fill correct email!').required('Pease fill owner email!'),
    phone: Yup.string()
      .matches(phoneRegExp, 'Phone number is not valid')
      .required('Please fill owner phone!'),
  }),

  files: Yup.array().min(1, 'Please upload file!'),
});
