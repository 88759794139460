import { FC } from 'react'
import styles from './section.module.scss'
import cls from 'classnames'

interface SectionProps {
  className?: string
  children: React.ReactNode
  takeFullHeight?: boolean
  padding?: boolean
}

const Section: FC<SectionProps> = ({
  children,
  className,
  takeFullHeight = false,
  padding = true,
}) => {
  return (
    <div
      className={cls(styles.sectionWrapper, className, {
        [styles.takeFullHeight]: takeFullHeight,
        [styles.p0]: !padding,
      })}
    >
      {children}
    </div>
  )
}

export default Section
