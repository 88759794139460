import { AustralianState } from "@src/lib/select-options.const";
import { BaseEditFormProps, BaseModalProps, SelectOption } from "@src/types/component-types";

export enum InterestRateCalculatorType {
  CourtInterest = 'CourtInterest',
  GeneralInterest = 'GeneralInterest',
}

export interface InterestRateCalculatorFormValues {
  calculateType: SelectOption<InterestRateCalculatorType> | null
}

export interface GeneralInterestRateCalculatorFormValues {
  calculateType: SelectOption<InterestRateCalculatorType>
  calculatedOn: SelectOption<number>
  interestRate: string
  onClaimAmount: string
  startDate: Date | null
  endDate: Date | null
}

export interface CourtInterestRateCalculatorFormValues {
  calculateType: SelectOption<InterestRateCalculatorType>
  state: SelectOption | null
  interestRate: string
}

export interface CourtInterestCalculator {
  id: number;
  /** 
  * @description The rate of interest to be applied
  * @example 0.085
  **/
  rate: number;
  wipId: number;
}

export interface GeneralInterestCalculator {
  id: number
  /** 
  * @description The rate of interest to be applied
  * @example 0.085
  **/
  rate: number;

  /** 
  * @description The period in days that the rate should be applied
  * @example 30
  **/
  period: number;

  /** 
  * @description The amount that the claim started with if field is not null
  * @example 1000 or null
  **/
  startClaimAmount: number | null;

  /** 
  * @description The date that the last rate was calculated
  * @example 2021-01-01 or null
  **/
  lastRateCalculatedAt: Date | null;

  /** 
  * @description The date that the rate should start being applied
  * @example 2021-01-01 or null
  **/
  dateStart: Date | null;

  /** 
  * @description The date that the rate should stop being applied
  * @example 2021-01-01 or null
  **/
  dateEnd: Date | null;

  wipId: number;
}

export interface CourtInterestRate {
  id: number;
  /** 
  * @description The australian state that the rate is for
  * @example 'NSW'
  **/
  state: AustralianState;
  /** 
  * @description The rate of interest for the state
  * @example 0.085
  **/
  rate: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface CreateCourtInterestCalculatorData {
  rate: number;
  wipId: number;
}

export interface CreateGeneralInterestCalculatorData {
  rate: number;
  period: number;
  startClaimAmount: number | null;
  dateStart: Date | null;
  dateEnd: Date | null;
  wipId: number;
}

export interface UpdateCourtInterestCalculatorData {
  rate?: number;
}

export interface UpdateGeneralInterestCalculatorData {
  rate?: number;
  period?: number;
  startClaimAmount?: number | null;
  dateStart?: Date | null;
  dateEnd?: Date | null;
}


// props
export interface InterestRateCalculatorModalProps extends BaseModalProps {
  wipId: number
  state: string
  courtInterestCalculator: CourtInterestCalculator | null
  generalInterestCalculator: GeneralInterestCalculator | null
}

export interface InterestRateCalculatorFormProps {
  wipId: number
  state: string
  courtInterestRatesOptions?: SelectOption[]
  courtInterestCalculator: CourtInterestCalculator | null
  generalInterestCalculator: GeneralInterestCalculator | null
  onGeneralInterestRateCalculatorSubmit: (data: GeneralInterestCalculator) => void
  onCourtInterestRateCalculatorSubmit: (data: CourtInterestCalculator) => void
  onCancelClick?: () => void
}

export interface GeneralInterestRateCalculatorFormProps {
  onCalculateTypeChange: (type: InterestRateCalculatorType) => void
  loading?: boolean
  onSubmit: (data: GeneralInterestRateCalculatorFormValues) => void
  onCancelClick?: () => void
  initialValues?: GeneralInterestRateCalculatorFormValues
  buttonText?: string
}

export interface CourtInterestRateCalculatorFormProps {
  buttonText?: string
  initialValues?: CourtInterestRateCalculatorFormValues
  courtInterestRatesOptions: SelectOption[]
  loading?: boolean
  onCalculateTypeChange: (type: InterestRateCalculatorType) => void
  onSubmit: (data: CourtInterestRateCalculatorFormValues) => void
  onCancelClick?: () => void
}

export interface EditGeneralInterestRateCalculatorProps
  extends BaseEditFormProps<GeneralInterestCalculator> {
  onCalculateTypeChange: (type: InterestRateCalculatorType) => void
  onCancelClick?: () => void
}

export interface CreateGeneralInterestRateCalculatorFormProps {
  wipId: number
  onSuccessSubmit: (data: GeneralInterestCalculator) => void
  onCalculateTypeChange: (type: InterestRateCalculatorType) => void
  onCancelClick?: () => void
}

export interface CreateCourtInterestRateCalculatorFormProps {
  courtInterestRatesOptions: SelectOption[]
  wipId: number
  state: string
  onSuccessSubmit: (data: CourtInterestCalculator) => void
  onCalculateTypeChange: (type: InterestRateCalculatorType) => void
  onCancelClick?: () => void
}

export interface UpdateCourtInterestRateCalculatorFormProps
  extends BaseEditFormProps<CourtInterestCalculator> {
  state: string
  courtInterestRatesOptions: SelectOption[]
  onCalculateTypeChange: (type: InterestRateCalculatorType) => void
  onCancelClick?: () => void
}

export interface CourtInterestRateTableModalProps extends BaseModalProps {
  courtInterestRates: CourtInterestRate[]
  loading?: boolean
}