import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useDispatch, useSelector } from 'react-redux';
import { getPaymentDetails } from '../../../../../../../redux/actions';
import { getPaymentDetailsSelector } from '../../../../../../../redux/selector/selector';

import { Grid, Typography } from '@mui/material';
import FormSelect from '../../../../../../../shared/components/FormSelect/FormSelect';
import FormTextArea from '../../../../../../../shared/components/FormTextArea/FormTextArea';

import { invoiceFormStyles } from '../../styles';

const FormPaymentDetails = ({
  handleChange,
  values,
  errors,
  touched,
  setFieldValue,
  isEditing,
}) => {
  const [selected, setSelected] = useState();

  const dispatch = useDispatch();
  const { data } = useSelector(getPaymentDetailsSelector);

  useEffect(() => {
    dispatch(getPaymentDetails()).catch(() => {
      toast.error('Something went wrong!');
    });
  }, []);

  const handleSelectChange = (e) => {
    const selectedItem = data?.data?.find((item) => e?.target?.value === item?.id);

    if (selectedItem) {
      setFieldValue('payment_details.id', selectedItem?.id);
      setFieldValue('payment_details.name', selectedItem?.name);
      setFieldValue('payment_details_id', selectedItem?.id);
      setFieldValue('company_details', selectedItem?.description || "");
      setSelected(selectedItem);
    }
  };

  const description = selected ? selected?.description : values?.payment_details?.description
  const name = selected ? selected?.name : values?.payment_details?.name
  const bsb = selected ? selected?.bsb : values?.payment_details?.bsb
  const account = selected ? selected?.account : values?.payment_details?.account


  return (
    <Grid sx={invoiceFormStyles.formPaymentDetailsContainer}>
      <Grid sx={invoiceFormStyles.detailsContainer}>
        <FormSelect
          options={data?.data}
          placeholder="Type new item description or select template..."
          label="Payment Details:"
          name="payment_details.id"
          changeHandler={handleSelectChange}
          value={values?.payment_details?.id}
          error={errors?.payment_details?.id}
          touched={touched?.payment_details?.id}
          column
          bold
        />

        <Grid sx={invoiceFormStyles.bankDetails}>
          <Typography sx={invoiceFormStyles.subtitle}>{description || '-'}</Typography>
          <Typography sx={invoiceFormStyles.subtitle}>{name || '-'}</Typography>
          <Typography sx={invoiceFormStyles.subtitle}>BSB: {bsb || '-'}</Typography>
          <Typography sx={invoiceFormStyles.subtitle}>
            Account: {account || '-'}
          </Typography>
        </Grid>

        <FormTextArea
          label="Company Details:"
          name="company_details"
          changeHandler={handleChange}
          value={values?.company_details}
          error={errors?.company_details}
          touched={touched?.company_details}
          column
          bold
          minRows={5}
          disabled
        />
      </Grid>
      <Grid sx={invoiceFormStyles.detailsContainer}>
        <FormTextArea
          label="Special Notes:"
          name="special_notes"
          changeHandler={handleChange}
          value={values?.special_notes}
          error={errors?.special_notes}
          touched={touched?.special_notes}
          column
          bold
          minRows={5}
        />
      </Grid>
    </Grid>
  );
};

export default FormPaymentDetails;
