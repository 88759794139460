import { FC, useState } from 'react'
import { DebtorLegalJudgmentDate } from '../../../types/debtor-legal.type'
import { useModal } from '@src/hooks/useModal'
import { BaseListProps } from '@src/types/component-types'
import DebtorLegalJudgmentDateListItem from './debtor-legal-judgment-date-list-item.component'
import EditDebtorJudgmentDateModal from '../../modals/debtor-judgment-date-modal/edit-debtor-judgment-date-modal.component'

const DebtorLegalJudgmentDateList: FC<BaseListProps<DebtorLegalJudgmentDate>> = ({
  entities: debtorLegalJudgmentDates,
}) => {
  const [isOpen, handleOpen, handleClose] = useModal(false)
  const [selectedDebtorLegalJudgmentDate, setSelectedDebtorLegalJudgmentDate] =
    useState<DebtorLegalJudgmentDate | null>(null)

  const handleEditClick = (judgmentDate: DebtorLegalJudgmentDate) => {
    setSelectedDebtorLegalJudgmentDate(judgmentDate)
    handleOpen()
  }

  return (
    <div>
      {debtorLegalJudgmentDates.map((judgmentDate) => {
        return (
          <DebtorLegalJudgmentDateListItem
            onEditClick={handleEditClick}
            entity={judgmentDate}
            key={judgmentDate.id}
          />
        )
      })}
      {selectedDebtorLegalJudgmentDate ? (
        <EditDebtorJudgmentDateModal
          entity={selectedDebtorLegalJudgmentDate}
          isOpen={isOpen}
          onClose={handleClose}
        />
      ) : null}
    </div>
  )
}

export default DebtorLegalJudgmentDateList
