import React, { InputHTMLAttributes, forwardRef } from 'react'
import styles from './form-textarea.module.scss'
import cls from 'classnames'

interface FormInputProps extends Omit<InputHTMLAttributes<HTMLTextAreaElement>, 'size'> {
  error?: string
  label?: string
  inputStyle?: 'filled' | 'outlined'
}

const FormTextArea = forwardRef<HTMLTextAreaElement, FormInputProps>(
  ({ error = '', label = '', inputStyle = 'filled', className, ...props }, ref) => {
    return (
      <div className={styles.formTextAreaWrapper}>
        {label ? (
          <label className={styles.labelWrapper}>
            <label className={styles.label}>{label}</label>
            {error ? <label className={styles.error}>{error}</label> : null}
          </label>
        ) : null}
        <textarea
          ref={ref}
          className={cls(className, styles.formTextArea, {
            [styles.outlined]: inputStyle === 'outlined',
          })}
          {...props}
        />
      </div>
    )
  },
)

FormTextArea.displayName = 'FormTextArea'

export default FormTextArea
