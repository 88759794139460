import { FC } from 'react'
import styles from './table.module.scss'
import cls from 'classnames'

interface TableRowProps {
  children: React.ReactNode
  className?: string
  onClick?: () => void
  pointer?: boolean
}

const TableRow: FC<TableRowProps> = ({ children, className, onClick, pointer = false }) => {
  return (
    <tr
      className={cls(styles.tableBodyRow, className, { [styles.pointer]: pointer })}
      onClick={onClick}
    >
      {children}
    </tr>
  )
}

export default TableRow
