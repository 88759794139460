import { GLOBAL_QUERIES } from "@src/lib/global-queries"
import { useQuery } from "@tanstack/react-query"
import { GetCombinedNotesParams } from "../types/combined-notes.type"
import { CombinedNoteService } from "../services/combined-note.service"

export const useGetCombinedNotes = (params: GetCombinedNotesParams) => {

  const getCombinedNotes = (params: GetCombinedNotesParams) => {
    const combinedNoteService = new CombinedNoteService()
    return combinedNoteService.getCombinedNotes(params)
  }

  const query = useQuery({
    queryKey: [GLOBAL_QUERIES.COMBINED_NOTES.GET, params],
    queryFn: () => getCombinedNotes(params),
    keepPreviousData: true
  })

  return query;
}