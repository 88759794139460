import { getCommercialUncoveredListActionEnum } from '../actionTypes';
import { getCommercialUncoveredListService } from '../service';

export const getCommercialUncoveredList = (page) => async (dispatch) => {
  dispatch({ type: getCommercialUncoveredListActionEnum.IS_LOADING_GET_COMMERCIAL_UNCOVERED_LIST });
  try {
    const res = await getCommercialUncoveredListService(page);

    dispatch({
      type: getCommercialUncoveredListActionEnum.GET_COMMERCIAL_UNCOVERED_LIST_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: getCommercialUncoveredListActionEnum.GET_COMMERCIAL_UNCOVERED_LIST_ERROR,
      payload: error,
    });
  }
};
