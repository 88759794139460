import { FC, useState } from 'react'
import AddTaskNoteForm from './add-task-note-form.component'
import CombinedNotesList from './combined-notes-list.component'
import styles from './combined-note.module.scss'
import { CombinedNotesProps } from '../../types/combined-notes.type'
import { CombinedNotesMode, GetCombinedNotesParams } from '../../types/combined-notes.type'
import CombinedNotesModeToggle from './combined-notes-mode-toggle.component'

const CombinedNotes: FC<CombinedNotesProps> = ({ task, onSubmit, onClose }) => {
  if (!task) return
  const [combinedNotesParams, setCombinedNotesParams] = useState<
    Omit<GetCombinedNotesParams, 'page'>
  >({
    task_id: task.id,
    limit: 10,
    mode: CombinedNotesMode.TASK,
  })

  const handleModeChange = (combinedNoteParams: Omit<GetCombinedNotesParams, 'page'>) => {
    setCombinedNotesParams(combinedNoteParams)
  }

  return (
    <div className={styles.noteSection}>
      <div className='d-flex justify-content-between'>
        <h4 className="mb-2">Notes</h4>
        <CombinedNotesModeToggle onChange={handleModeChange} taskId={task.id} />
      </div>
      <CombinedNotesList taskId={task.id} combinedNotesParams={combinedNotesParams} />
      <AddTaskNoteForm taskId={task.id} onSubmit={onSubmit} onClose={onClose} isCompleted={task.column_belong_id === 2} />
    </div>
  )
}

export default CombinedNotes
