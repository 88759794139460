import Section from '@components/section/section.component'
import styles from './client-overview-page.module.scss'
import { Header, SubHeader, Text } from '@containers/Recovery/Wip/components/UI/text/text.component'
import { Client } from '../../types/client.type'
import { FC } from 'react'
import SubSection from '@containers/Recovery/Wip/components/UI/sub-section/sub-section.component'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { AssignedStaff } from '@containers/Setting/components/Contacts/taskboard/types'
import ClientDetailsForm from './components/forms/client-details-form/client-details-form.component'

interface ClientOverviewPageProps {
  client: Client
}

const Ava = `${process.env.PUBLIC_URL}/image/user.jpg`

const ClientOverviewPage: FC<ClientOverviewPageProps> = ({ client }) => {
  const contacts = client.contacts || []
  const staff: AssignedStaff | null = null
  return (
    <div className={styles.sectionsWrapper}>
      <div className={styles.leftSections}>
        <Section>
          <Header mb={4}>Tasks Assigned To</Header>
          {staff ? (
            <SubSection p={2} flex>
              <div>
                <img className="topbar__avatar-img" src={Ava} alt="avatar" />
              </div>
              <div>
                <SubHeader>{'Name not found'}</SubHeader>
                <Text>{'Email not found'}</Text>
              </div>
            </SubSection>
          ) : (
            <SubSection p={2}>
              <SubHeader>No Staff Assigned</SubHeader>
            </SubSection>
          )}
        </Section>
        <Section>
          <SubSection>
            <Header mb={3}>{client.name}</Header>
          </SubSection>
          <SubSection>
            <SubHeader>Client Status</SubHeader>
            <Text>{client.status}</Text>
          </SubSection>
          <div>
            {contacts.map((contact) => (
              <SubSection key={`contact-${contact.id}`}>
                <SubHeader>Contact Name</SubHeader>
                <Text>{`${contact.first_name} ${contact.last_name}`}</Text>
                <SubHeader>Contact Email</SubHeader>
                <Text>{contact.comm_email || 'Not Found'}</Text>
              </SubSection>
            ))}
          </div>
          <SubmitButton className={styles.viewClientButton}>View Client</SubmitButton>
        </Section>
      </div>
      <Section>
        <Header mb={4}>Client Details</Header>
        <ClientDetailsForm onSuccessSubmit={() => {}} />
      </Section>
    </div>
  )
}

export default ClientOverviewPage
