  import { useTaskContext } from '@src/context/task-context'
  import styles from './task-info.module.scss'
  import { FC } from 'react'
  import DebtorInfoList from '../debtor-info-list/debtor-info-list.component'
  import { TaskInfoProps } from '../../types/task.type'

  const TaskInfo: FC<TaskInfoProps> = ({ task }) => {
    const { selectedCompanyState, companies, isSales, isRecovery, currentFoundWip } =
      useTaskContext()
    const debtors = currentFoundWip?.debtors?.filter(({ id }) => task?.assigned_debtors?.includes(id))

    const fullClient = companies?.find(({ id }) => id === Number(selectedCompanyState?.id))

    const contacts =
      fullClient?.contacts?.filter(({ id }) => task?.assign_attributes?.includes(String(id))) || []

    const clientInfo = isSales ? fullClient : currentFoundWip?.company
    const clientAddress1 = clientInfo?.address[0].address_1 
    const zip = clientInfo?.address[0].post_code
    return (
      <div className={styles.taskInfoWrapper}>
        <span>
          <b>Client</b>:
          <span className={styles.highlightedText}>{clientInfo?.name || 'Name not found'}</span>
        </span>
        <span>
          <b>Address</b>: {clientAddress1 || 'No address found'} {zip || 'No zip found'}
        </span>
        {isSales && (
          <span>
            <span>
              <b>Contacts: </b> {contacts.length === 0 ? 'No contacts assigned' : null}
            </span>
            {contacts.map((contact, idx) => {
              const str = `${contact?.first_name?.trim()} ${contact?.is_primary ? '(Primary)' : ''} ${
                idx < contacts?.length - 1 ? ', ' : ''
              }`

              return (
                <div key={contact.id}>
                  {str}
                  <a href={`tel:${contact?.comm_ph_mobile}`}>{contact?.comm_ph_mobile}</a>
                </div>
              )
            }) || 0}
          </span>
        )}
        {isRecovery && (
          <span>
            <span>
              <b>Debtors</b>: {debtors.length === 0 ? 'No debtors assigned' : null}
            </span>
            <DebtorInfoList debtors={debtors} />
          </span>
        )}
      </div>
    )
  }

  export default TaskInfo
