import Modal from '@components/modal'
import styles from './create-automation-modal.module.scss'

import { useForm, useWatch } from 'react-hook-form'

import LabelWrapper from '@components/label-wrapper'

import { useTaskContext } from '@src/context/task-context'
import Select from 'react-select'
import { useEffect, useState } from 'react'
import { formatAssigneName } from '../../taskboard/utils'
import Switch from '@components/switch'
import { REACT_APP_API_URL } from '@src/lib/global-variables'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useUserContext } from '@src/context/user-context'
import { useQueryClient } from '@tanstack/react-query'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import SetMailModal from './set-email-automation'
import { useModal } from '@src/hooks/useModal'
import SetSmsModal from './set-sms-modal'
import SetTaskModal from './set-task-modal'
import { ICreateAutomation } from './types'
import { CreateAutomationSchema } from './validation'
import { joiResolver } from '@hookform/resolvers/joi'
import { SET_TASK } from '../types'

interface IProps {
  isOpen: boolean
  onClose: () => void
}

const defaultValues: ICreateAutomation = {
  column: null,
  contacts: [],
  isAllContactsSelected: false,
  title: '',
  description: '',
  email_template: '',
  email_template_id: null,
  sms_template: '',
  email_subject: '',
  task_template: null,
  is_cron: false,
  schedule_date_interval: 1,
}

const CreateAutomationModal = ({ isOpen, onClose = () => null }: IProps) => {
  const {
    selectedCompanyState,
    currentColumns,
    companies,
    isSales,
    currentFoundWip,
    currentWip,
    canSelectContact,
    externalAutomationOptions,
  } = useTaskContext()
  const { user } = useUserContext()

  const queryClient = useQueryClient()

  const [isSendMailModalOpened, handleSendMailModalOpen, handleSendMailModalClose] = useModal(false)
  const [isSendSmsModalOpened, setIsSendSmsModalOpened] = useState(false)
  const [isSetTaskModalOpened, handleSetTaskModalOpen, handleSetTaskModalClose] = useModal(false)

  const { control, handleSubmit, reset, setValue } = useForm<ICreateAutomation>({
    defaultValues,
    mode: 'all',
    resolver: joiResolver(CreateAutomationSchema),
  })

  const {
    isAllContactsSelected,
    is_cron,
    sms_template,
    email_template,
    email_subject,
    email_template_id,
    task_template
  } = useWatch({
    control,
  })

  const contactsOptions = isSales
    ? companies
        ?.find(({ id }) => id === Number(selectedCompanyState?.id))
        ?.contacts?.map(({ id, first_name, last_name }) => ({
          value: id,
          label: formatAssigneName({ first_name, last_name, isAssigned: false }),
        })) || []
    : currentFoundWip?.debtors?.map(({ id, name_first, name_last }) => ({
        value: id,
        label: formatAssigneName({
          first_name: name_first,
          last_name: name_last,
          isAssigned: false,
        }),
      })) || []

  const automationOptions =
    currentColumns?.map(({ id, title }) => ({
      value: id,
      label: title,
    })) || []

  const submitMailTemplate = ({
    message,
    subject,
    templateId
  }: {
    message: ICreateAutomation['email_template']
    subject: ICreateAutomation['email_subject']
    templateId: number | null
  }) => {
    setValue('email_template', message)
    setValue('email_subject', subject)
    setValue('email_template_id', templateId)
  }

  const submitSmsTemplate = ({
    sms_template,
  }: {
    sms_template: ICreateAutomation['sms_template']
  }) => {
    setValue('sms_template', sms_template)
  }
  const submitTaskTemplate = ({
    task_template,
  }: {
    task_template: ICreateAutomation['task_template']
  }) => {
    if (!task_template) return
    setValue('task_template', task_template)
  }

  const onSubmit = async (data: ICreateAutomation) => {
    const {
      column,
      contacts,
      description,
      title,
      isAllContactsSelected,
      email_template,
      email_template_id,
      email_subject,
      sms_template,
      task_template,
    } = data

    try {
      await axios.post(`${REACT_APP_API_URL}/automations`, {
        ...data,
        referrence_column_id: column?.value,
        contacts_automations: isAllContactsSelected ? [] : contacts.map(({ value }) => value),
        description,
        title,
        clientId: isSales ? Number(selectedCompanyState?.id) : null,
        wip_id: isSales ? null : currentWip,
        is_all_contacts_selected: isAllContactsSelected,
        email_template,
        sms_template,
        email_template_id,
        createdBy: formatAssigneName({
          isAssigned: false,
          first_name: user?.first_name || '',
          last_name: user?.last_name || '',
        }),
        email_subject,
        task_template,
      })
      toast.success(`Automation ${title} has been successfully created`)
    } catch (error) {
      toast.error('Something went wrong')
    } finally {
      onClose()
    }
    queryClient.invalidateQueries([GLOBAL_QUERIES.AUTOMATIONS.GET])
  }

  const handleSendSmsModalOpen = () => {
    setIsSendSmsModalOpened(true)
  }

  const handleSendSmsModalClose = () => {
    setIsSendSmsModalOpened(false)
  }

  useEffect(() => {
    if (!isOpen) {
      reset(defaultValues)
    }
  }, [isOpen])

  useEffect(() => {
    if (isAllContactsSelected) {
      setValue('contacts', contactsOptions)
    } else {
      setValue('contacts', [])
    }
  }, [isAllContactsSelected])

  return (
    <Modal isOpen={isOpen} onClose={onClose} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.wrapper} onClick={(e) => e.stopPropagation()}>
        <span>Create Automation</span>
        <div className={styles.select}>
          <LabelWrapper<ICreateAutomation>
            className={styles.labelWrapper}
            control={control}
            name={'column'}
            label="Automate Column"
          >
            {({ onChange, value }) => {
              return (
                <Select
                  type="text"
                  className="react-select"
                  placeholder="Select column to automate"
                  value={value as string}
                  onChange={onChange}
                  options={externalAutomationOptions || automationOptions}
                />
              )
            }}
          </LabelWrapper>
        </div>
        <div className={styles.select}>
          <LabelWrapper<ICreateAutomation>
            className={styles.labelWrapper}
            control={control}
            name={'isAllContactsSelected'}
            label=""
          >
            {({ onChange, value }) => {
              return (
                <div className={styles.selectContact}>
                  <Switch value={value as boolean} onChange={onChange} />
                  <span>Select All {isSales ? 'Contacts' : 'Debtors'}</span>
                </div>
              )
            }}
          </LabelWrapper>
        </div>

        {canSelectContact ? (
          <div className={styles.select}>
            <LabelWrapper<ICreateAutomation>
              className={styles.labelWrapper}
              control={control}
              name={'contacts'}
              label={isSales ? 'Select Contacts' : 'Select Debtors'}
            >
              {({ onChange, value }) => {
                return (
                  <Select
                    type="text"
                    className="react-select"
                    isDisabled={isAllContactsSelected}
                    placeholder={
                      isSales ? 'Select contacts for automation' : 'Select debtors for automation'
                    }
                    value={value as string}
                    onChange={onChange}
                    options={contactsOptions}
                    isMulti
                  />
                )
              }}
            </LabelWrapper>
          </div>
        ) : null}
        <div className={styles.select}>
          <LabelWrapper<ICreateAutomation>
            className={styles.labelWrapper}
            control={control}
            name={'title'}
            label="Automation Title"
          >
            {({ onChange, value }) => {
              return (
                <input
                  type="text"
                  className="react-select"
                  placeholder="Greeting Automation example"
                  value={value as string}
                  onChange={onChange}
                />
              )
            }}
          </LabelWrapper>
        </div>
        <div className={styles.select}>
          <LabelWrapper<ICreateAutomation>
            className={styles.labelWrapper}
            control={control}
            name={'description'}
            label="Automation Description"
          >
            {({ onChange, value }) => {
              return (
                <textarea
                  className="react-select"
                  placeholder="Greeting Automation example"
                  value={value as string}
                  onChange={onChange}
                />
              )
            }}
          </LabelWrapper>
        </div>
        <div className={styles.select}>
          <LabelWrapper<ICreateAutomation>
            className={styles.labelWrapper}
            control={control}
            name={'is_cron'}
            label="Is Scheduled"
          >
            {({ onChange, value }) => {
              return <Switch value={value as string} onChange={onChange} />
            }}
          </LabelWrapper>
        </div>
        {is_cron && (
          <div className={styles.select}>
            <LabelWrapper<ICreateAutomation>
              className={styles.labelWrapper}
              control={control}
              name={'schedule_date_interval'}
              label="Is Scheduled"
            >
              {({ onChange, value }) => {
                return (
                  <input
                    type="number"
                    className="react-select"
                    placeholder="Greeting Automation example"
                    value={value as string}
                    onChange={onChange}
                  />
                )
              }}
            </LabelWrapper>
          </div>
        )}

        <div className={styles.bottomContainer}>
          <button onClick={handleSendMailModalOpen}>Set Email</button>
          <button onClick={handleSendSmsModalOpen}>Set SMS</button>
          <button onClick={handleSetTaskModalOpen}>Set Task</button>
        </div>
      </div>
      <SetMailModal
        isOpen={isSendMailModalOpened}
        onClose={handleSendMailModalClose}
        onSubmit={submitMailTemplate}
        initialValues={{
          email_template: email_template || '',
          email_subject: email_subject || '',
          email_template_id: email_template_id || null,
        }}
      />
      <SetSmsModal
        isOpen={isSendSmsModalOpened}
        onClose={handleSendSmsModalClose}
        onSubmit={submitSmsTemplate}
        initialValues={{ sms_template: sms_template || '' }}
      />
      <SetTaskModal
        onClose={handleSetTaskModalClose}
        isOpen={isSetTaskModalOpened}
        onSubmit={submitTaskTemplate}
        taskTemplateInitialValues={task_template as SET_TASK}
      />
    </Modal>
  )
}

export default CreateAutomationModal
