import Section from '@components/section/section.component'
import AddNoteForm from '@containers/Communication/components/forms/add-note-form/add-note-form.component'
import styles from './add-note-section.module.scss'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import { FC } from 'react'
import { AddNoteSectionProps } from '../../../types/wip-communication.type'

const AddNoteSection: FC<AddNoteSectionProps> = ({ wipId }) => {
  return (
    <Section className={styles.wrapper}>
      <Header mb={4}>Add Note</Header>
      <AddNoteForm params={{ wip_id: wipId }} />
    </Section>
  )
}

export default AddNoteSection
