import { FC } from 'react'
import styles from './client-communication-notes-page.module.scss'
import Section from '@components/section/section.component'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import CombinedNotesTableWipFilter from '@containers/Communication/components/tables/combined-notes-table/combined-notes-table-with-filter.component'
import { Client } from '@containers/Sales/Client/types/client.type'
import AddNoteForm from '@containers/Communication/components/forms/add-note-form/add-note-form.component'

interface ClientCommunicationNotesPage {
  client: Client
}

const ClientCommunicationNotesPage: FC<ClientCommunicationNotesPage> = ({ client }) => {
  return (
    <div className={styles.sectionsWrapper}>
      <Section className={styles.addNoteSection}>
        <Header>Add Note</Header>
        <AddNoteForm params={{company_id: client.id}}/>
      </Section>
      <Section>
        <CombinedNotesTableWipFilter params={{ company_id: client.id }} />
      </Section>
    </div>
  )
}

export default ClientCommunicationNotesPage
