import Table from '@components/table/table.component'
import { DebtorReferenceTableProps } from '@containers/Recovery/Wip/_modules/Debtors/types/debtor-associate-detail.type'
import { FC } from 'react'
import DebtorReferenceTableItem from './debtor-reference-table-item.component'

const DebtorReferenceTable: FC<DebtorReferenceTableProps> = ({ references, debtorId, onItemClick }) => {
  return (
    <Table
      headers={['NAME', 'PHONE', 'ADDRESS', 'REMOVE']}
      paginated={false}
      bodyHeight={54 * references.length}
    >
      {references.map((reference) => (
        <DebtorReferenceTableItem key={reference.id} reference={reference} debtorId={debtorId} onItemClick={onItemClick} />
      ))}
    </Table>
  )
}

export default DebtorReferenceTable
