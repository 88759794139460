import Section from '@components/section/section.component'
import SendReportModal from '@containers/Communication/components/modals/send-report-modal/send-report-modal.component'
import ReportTable from '@containers/Communication/components/tables/report-table/report-table.component'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { Wip } from '@containers/Recovery/Wip/types/wip.type'
import { useModal } from '@src/hooks/useModal'
import { FC } from 'react'
import styles from './report-section.module.scss'

interface WipReportSection {
  wip: Wip
}

const WipReportSection: FC<WipReportSection> = ({ wip }) => {
  const [isOpen, handleOpen, handleClose] = useModal(false)

  return (
    <Section>
      <div className={styles.filterWrapper}>
        <SubmitButton onClick={handleOpen}>Send Report</SubmitButton>
      </div>
      <ReportTable params={{ s: JSON.stringify({ wipId: wip.id }) }} />
      <SendReportModal isOpen={isOpen} onClose={handleClose} wip={wip} />
    </Section>
  )
}

export default WipReportSection
