import React from 'react';
import { useHistory } from 'react-router-dom';

import { Grid, Modal, Typography } from '@mui/material';
import AppButton from '../../../../../../shared/components/AppButton/AppButton';

import closeIcon from '../../../../../../assets/image/form-images/close-icon.png';
import { saveModalStyles } from './styles.js';

const SaveModal = ({ open, setOpen }) => {
  const history = useHistory();

  const handleClose = () => {
    setOpen(false);
  };

  const changeLocationHandler = (path) => {
    history.push(`/contacts/${path}`);
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid sx={saveModalStyles.container}>
        <Grid sx={saveModalStyles.closeButton} onClick={handleClose}>
          <AppButton icon={closeIcon} buttonStyle="transparent" onClick={handleClose} />
        </Grid>

        <Typography sx={saveModalStyles.title}>Save Succesful</Typography>
        <Typography sx={saveModalStyles.subtitle}>What would you like to do next?</Typography>

        <Grid sx={saveModalStyles.buttonsContainer}>
          <AppButton
            content="View Invoice List"
            buttonStyle="secondary"
            onClick={() => changeLocationHandler('invoice-list')}
          />
          <AppButton
            content="New Invoice"
            buttonStyle="primary"
            onClick={() => changeLocationHandler('add-invoice')}
          />
        </Grid>
      </Grid>
    </Modal>
  );
};

export default SaveModal;
