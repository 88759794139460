import { useMutation } from "@tanstack/react-query"
import { WipService } from "../../services/wip.service"
import { DeepPartial } from "react-hook-form"
import { Wip } from "../../types/wip.type"
import { Params } from "@src/types/service.type"

export const useUpdateWipByIdMutation = () => {
  const updateWip = (id: number, data: DeepPartial<Wip>, params: Params = {}) => {
    const wipService = new WipService()
    return wipService.updateWip(id, data, params)
  }

  const mutation = useMutation(({ id, data, params = {} }: { id: number, data: DeepPartial<Wip>, params?: Params }) => updateWip(id, data, params))

  return mutation
}