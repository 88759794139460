import { FC } from 'react'
import CreateDebtorAddressForm from '../../forms/debtor-address-form/create-debtor-address-form.component'
import Modal from '@components/modal'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import styles from './create-debtor-address-modal.module.scss'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import { CreateDebtorAddressModalProps } from '@containers/Recovery/Wip/_modules/Debtors/types/debtor-address.type'

const CreateDebtorAddressModal: FC<CreateDebtorAddressModalProps> = ({
  debtorId,
  isOpen,
  onClose,
}) => {
  const queryClient = useQueryClient()
  const handleSuccess = () => {
    toast.success('New Address Added')
    queryClient.invalidateQueries({ queryKey: [GLOBAL_QUERIES.DEBTOR_ADDRESS_HISTORY.GET] })
    onClose()
  }
  return (
    <Modal className={styles.modal} onClose={onClose} isOpen={isOpen} withBtns={false} classNameLayout={styles.layout}>
      <Header mb={4}>Add Address</Header>
      <CreateDebtorAddressForm debtorId={debtorId} onSuccess={handleSuccess} />
    </Modal>
  )
}

export default CreateDebtorAddressModal
