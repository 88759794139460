import { FC } from 'react'
import AssignedToSection from './components/sections/assigned-to-section/assigned-to-section.component'
import AttachmentsSection from './components/sections/attachments-section/attachments-section.component'
import ClaimAmountSection from './components/sections/claim-amount-section/claim-amount-section.component'
import ClientInfoSection from './components/sections/client-info-seciton/client-info-section.component'
import WipDetailsSection from './components/sections/wip-details-section/wip-detials-section.component'
import styles from './wip-overview-page.module.scss'
import { WipOverviewPageProps } from './types/sections-props.type'

const WipOverview: FC<WipOverviewPageProps> = ({ wip }) => {
  return (
    <div className={styles.wipOverviewWrapper}>
      <div className={styles.leftSide}>
        <ClaimAmountSection claimAmount={wip.claim_details_total} />
        <AssignedToSection staff={wip.staff_assigned_entity} />
        <ClientInfoSection client={wip.company} />
      </div>
      <div className={styles.rightSide}>
        <WipDetailsSection wip={wip} />
        <AttachmentsSection attachments={wip.attachments} wipId={wip.id} />
      </div>
    </div>
  )
}

export default WipOverview
