import { add, format, getHours } from "date-fns"
import { GlobalKanbanBoardColumn } from "../types/global-kanban-board.type"


export const generateWeeklyColumns = (dateRange: [Date, Date]): GlobalKanbanBoardColumn[] => {
  const startDate = dateRange[0]

  return Array.from({ length: 7 }, (_, i) => {
    const nextDay = add(startDate, { days: i })
    return {
      title: `${format(nextDay, 'd')} ${format(nextDay, 'EEEEEE').toUpperCase()}`,
      tasks: [],
    }
  })
}

export const generateDailyColumns = (dateRange: [Date, Date]): GlobalKanbanBoardColumn[] => {
  const startHour = getHours(dateRange[0])
  const endHour = getHours(dateRange[1])

  return Array.from({ length: endHour - startHour + 1 }, (_, i) => ({
    title: `${i + startHour} ${i >= 12 ? 'PM' : 'AM'}`,
    tasks: [],
  }))
}