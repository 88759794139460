import styles from './view-email-page-skeleton.module.scss'
import cls from 'classnames'

const ViewEmailPageSkeleton = () => {
  return (
    <div className={styles.skeletonWrapper}>
      <div className={styles.skeletonHeader}>
        <div className={styles.headerSideWrapper}>
          <div className={cls(styles.line, styles.square)} />
          <div className={cls(styles.separator)} />
          <div className={cls(styles.line, styles.square)} />
          <div className={cls(styles.line, styles.square)} />
          <div className={cls(styles.line, styles.square)} />
          <div className={cls(styles.separator)} />
          <div className={cls(styles.line, styles.square)} />
        </div>
        <div className={styles.headerSideWrapper}>
          <div className={cls(styles.line, styles.square)} />
          <div className={cls(styles.line, styles.square)} />
          <div className={cls(styles.separator)} />
          <div className={cls(styles.line, styles.square)} />
          <div className={cls(styles.line, styles.square)} />
        </div>
      </div>
      <div className={styles.skeletonBody}>
        <div className={styles.contactInformationWrapper}>
          <div className={cls(styles.line, styles.circle)} />
          <div className={styles.emailWrapper}>
            <div className={cls(styles.line, styles.name)} />
            <div className={cls(styles.line, styles.email)} />
          </div>
        </div>
        <div className={cls(styles.line, styles.subject)}/>
        <div className={cls(styles.line, styles.w60)}/>
        <div className={cls(styles.line, styles.bodyContent)}/>
        <div className={cls(styles.line, styles.w40)}/>
        <div className={cls(styles.line, styles.w30)}/>
      </div>
      <div className={styles.skeletonFooter}>
        <div className={cls(styles.line, styles.replyButton)}/>
        <div className={cls(styles.line, styles.forwardButton)}/>
      </div>
    </div>
  )
}

export default ViewEmailPageSkeleton
