import React, { PureComponent } from 'react'
import moment from 'moment'
import { withRouter } from 'react-router'
import { toast } from 'react-toastify'

import Services from '../../../../../shared/Services'
import DebtorService from '../../../../../services/DebtorService'
import CompaniesService from '../../../../../services/CompaniesService'
import WIPService from '../../../../../services/WIPService'
import PayTrackingService from '../../../../../services/PayTracking'
import StaffService from '../../../../../services/StaffService'
import PropertyService from '../../../../../services/PropertyService'

import PaymentTracking from './PaymentTracking'
import { AttachedFilesWip } from './AttachedFileWip'
import CourtDetails from './CourtDetails'

import Select from 'react-select'
import Table from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import Box from '@material-ui/core/Box'
import DatePicker from 'react-datepicker'
import SimpleReactValidator from 'simple-react-validator'
import TablePagination from '@material-ui/core/TablePagination'
import { Button, ButtonGroup, UncontrolledTooltip } from 'reactstrap'

import LoaderWip from './assets/LoaderWIP'
import Svg from './assets/Svg'
import 'rc-time-picker/assets/index.css'
import mapRecoveryDetails from './utils/mapRecoveryDetails'
import RecoverableCosts from './details/recoverable-costs/recoverable-costs.component'
import ClaimDetails from './details/claim-details/claim-details.component'

const clientStatusOption = [
  { value: '0', label: 'Prospect' },
  { value: '1', label: 'Current' },
  { value: '2', label: 'Inactive' },
  { value: '3', label: 'No Longer Operating' },
]

const PriorityOption = [
  { value: '1', label: 'A' },
  { value: '2', label: 'B' },
  { value: '3', label: 'C' },
]

const PaymentTypeOption = [
  { value: '', label: 'None' },
  { value: 'Deft', label: 'Deft' },
  { value: 'Cheque', label: 'Cheque' },
  { value: 'Money Order', label: 'Money Order' },
  { value: 'Centerlink', label: 'Centerlink' },
  { value: 'Direct Debit', label: 'Direct Debit' },
]

const trackOnPeriod = [
  { value: 7, label: 'Weekly' },
  { value: 14, label: 'Forntnightly' },
  { value: 21, label: 'Tri Weekly' },
  { value: 31, label: 'Monthly' },
  { value: 61, label: 'Bi-Monthly' },
]

const source = [
  { value: '0', label: 'Other' },
  { value: '1', label: 'BOQ' },
  { value: '2', label: 'Centrelink' },
  { value: '3', label: 'Bankwest' },
]

const fileLocationOption = [
  { value: '0', label: 'General filing' },
  { value: '1', label: 'General filing – No Order' },
  { value: '2', label: 'Paying' },
  { value: '3', label: 'Paying Agent Direct' },
  { value: '4', label: 'Eviction' },
  { value: '5', label: 'Paid in full' },
  { value: '6', label: 'Purged – Bankrupt' },
  { value: '7', label: 'Purged – Overseas' },
  { value: '8', label: 'Purged – No TAF' },
  { value: '9', label: 'Purged – No Order' },
  { value: '10', label: 'Paying Agent' },
  { value: '11', label: 'Purged – jail' },
  { value: '12', label: 'Purged – Deceased' },
  { value: '13', label: 'Purged - By Agen' },
  { value: '14', label: 'Purged - No Cover' },
  { value: '15', label: 'Purged - Insurance' },
  { value: '16', label: 'QLD General Filing' },
  { value: '17', label: 'QLD PIF Filing' },
  { value: '18', label: 'QLD Purge Filing' },
  { value: '19', label: 'QLD Audit Box 1' },
  { value: '20', label: 'QLD Audit Box 2' },
  { value: '21', label: 'QLD Audit Box 3' },
  { value: '22', label: 'QLD Audit Box 4' },
  { value: '23', label: 'QLD Other' },
  { value: '24', label: 'NSW General Filing' },
  { value: '25', label: 'NSW PIF Filing' },
  { value: '26', label: 'NSW Purge Filing' },
  { value: '27', label: 'NSW Other' },
  { value: '28', label: 'VIC General Filing' },
  { value: '29', label: 'VIC PIF Filing' },
  { value: '30', label: 'VIC Purge Filing' },
  { value: '31', label: 'VIC Othe' },
]

const getStates = ['ACT', 'NSW', 'NT', 'NZ', 'QLD', 'SA', 'TAS', 'VIC', 'WA']

class Details extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      id: localStorage.getItem('contactsId'),
      allDataStaff: [],
      allClient: [],
      selectedClient: '',
      clientStatus: '',
      clientEmail: '',
      clientContact: '',
      clientContactName: '',
      clientFirstName: '',
      clientLastName: '',
      wipPriority: { value: '', label: '' },
      checkOwner: false,
      checkSend: false,
      list_fee: '',
      fileNumber: '',
      dateLodged: '',
      asDateLog: '',
      coverOption: '',
      allOrigin: [],
      allDebtor: [],
      origin: '',
      fileLocation: '',
      paidOutDate: '',
      pAmount: '',
      checkPayOwner: false,
      checkSendReport: false,
      fileLocationCost: { value: '0', label: 'Add Cost Item' },
      clam_details: [
        {
          id: Math.floor(Math.random() * (1 + 100)) + 1,
          description: '',
          current_amount: '',
          original_amount: '',
        },
      ],
      cost_details: [],
      claimInterest: '',
      errorClaimOne: '',
      errorClaimTwo: '',
      errorClaimThree: '',
      errorCostOne: '',
      errorCostTwo: '',
      errorCostThree: '',
      landlordInsurance: false,
      landlordPayOut: false,
      insuranceBy: '',
      totalOriginalClaim: '',
      amtCoverInsurance: '',
      subjects: [
        {
          id: Math.floor(Math.random() * (1 + 100)) + 1,
          selected: '',
        },
      ],
      errorSubject: '',
      finalizeDate: '',
      tempSub: '',
      currentClaim: '',
      paidDirectToAgent: '',
      totalReceived: '',
      paidAsAtAgent: '',
      interestStartFrom: '',
      interestStartTo: '',
      totalClaimAmount: '',
      claimInterestTotal: '',
      totalCostAmount: '',
      costOfferAmount: '',
      wipData: '',
      ownerData: [],
      ownerName: '',
      ownerAddress: '',
      ownerContact: '',
      ownerEmail: '',
      ownerBank: '',
      claim_details_total: null,
      recoverable_costs_total: 0,

      propID: '',
      propAddress: '',
      suburb: '',
      postCode: '',
      findProps: false,
      address1: '',
      address2: '',
      address3: '',
      propSuburb: '',
      propsState: '',
      propPostCode: '',
      propCountry: '',

      propList: [],
      selectedProperty: '',
      propertyId: '',

      page: 0,
      rowsPerPage: 10,
      total: 0,
      isSearched: false,

      overViewDropDown: true,
      courtDetailsDropDown: true,
      propertyDropDown: false,
      wipDetailsDropDown: true,
      claimAmountDropDown: true,
      payTrackDropDown: true,
      clientDropDown: false,
      landlordDropDown: false,
      ownerDropDown: false,
      attachmentDetailsDropDown: false,

      trackPayments: [],
      firstPayment: '',
      fromPayment: '',
      trackOn: '',
      list: '',
      debtors: [],

      propertyManager: '',
      diared_to: '',
      last_report_sent: '',
      payment: PaymentTypeOption[0],
      listFeesOption: [],
      allStaffOption: [],
      assigned_staff: null,
      allCoverOption: [],
      property: {},
      loader: false,
      payTrack: [],
      payId: null,
      assigned_staff_tracking: null,
      bank_account: '',
      plaintiffName: '',
      plaintiffAddress: '',
      defendantName: '',
      defendantAddress: '',
      courtLocation: '',
      tribunalLocation: '',
      tribunalOrderDate: '',
      tribunalClaim: '',
      claimPlaintiffNumber: '',
      enforcementDate: '',
      attachedFile: [],

      strataWIP: false,
    }
    this.prop = new PropertyService()
    this.payTrack = new PayTrackingService()
    this.staff = new StaffService()
    this.wip = new WIPService()
    this.comp = new CompaniesService()
    this.debtor = new DebtorService()
    this.services = new Services()
    this.changeInput = this.changeInput.bind(this)
    this.changeOwnerInput = this.changeOwnerInput.bind(this)
    this.validator = new SimpleReactValidator()
    this.sell = new SimpleReactValidator()
  }

  componentDidMount() {
    this.getAllClient()
    this.getCoverOption()
    this.getOrigins() //there was a request to the server, now damp
    this.getDebtors()
    this.getListFeeOption()
    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.wipID
    ) {
      this.setState({ wipID: this.props.location.state.wipID })
      localStorage.setItem('wipID', this.props.location.state.wipID)
      this.getWIPbyId(this.props.location.state.wipID)
    } else if (localStorage.getItem('wipID')) {
      this.getWIPbyId(localStorage.getItem('wipID'))
    }
    this.getAllStaff()
  }

  async getDebtors() {
    try {
      const params = {
        join: 'contacts',
        limit: 10000,
      }
      const debtors = await this.debtor.getDebtor(params)
      const getData = debtors.data
      let list = getData.map((item) => {
        return {
          value: item.id,
          label: item.name_first + ' ' + item.name_last,
        }
      })
      this.setState({ allDebtor: list })
      if (this.state.wipData && this.state.wipData.subject) {
        const subject = []
        this.state.tempSub &&
          this.state.wipData.subject.map((item) => {
            if (list && list.length > 0) {
              const data = {
                id: '_' + Math.random().toString(36).substr(2, 9),
                selected: list.find((_) => _.value === item),
              }
              subject.push(data)
            }
          })
        if (subject && subject[0]) {
          this.setState({ subjects: subject })
        }
      }
    } catch {
      this.setState({ accessErrorModal: true })
    }
  }

  getPaymentTracking() {
    const params = {
      join: ['wip||id', 'debtor||id'],
      filter: `wip.id||$eq||${this.props.location.state.wipID}`,
    }
    this.payTrack.getPayTrack(params).then((res) => {
      if (res.status === 200) {
        if (res.data.data.length) {
          this.setState({ payTrack: res.data.data })
          const dataOne = this.state.allDebtor.find((e) => e.value === res.data.data[0].debtor.id)
          const delta = trackOnPeriod.find((e) => e.value == res.data.data[0].trackDayPeriod)
          const staff = this.state.allStaffOption.find(
            (e) => e.value === res.data.data[0].assigned_staff_tracking,
          )
          const pay = source.find((e) => e.label === res.data.data[0].from)
          this.setState({
            firstPayment: res.data.data[0].firstPaymentDue
              ? new Date(res.data.data[0].firstPaymentDue)
              : '',
            forAmount: res.data.data[0].amount,
            trackOn: delta,
            listSelectDebtor: dataOne,
            fromPayment: pay,
            debtors: res.data.data[0].debtor,
            payId: res.data.data[0].id,
            assigned_staff_tracking: staff,
            bank_account: res.data.data[0].bank_account,
          })
        }
      }
    })
  }

  getOrigins() {
    let list = getStates.map((item) => {
      return { value: item, label: item }
    })
    this.setState({ allOrigin: list })
  }

  getAllStaff() {
    const data = {}
    this.staff.getStaff(data).then((res) => {
      if (res.data) {
        const getData = res.data
        let listStaff
        listStaff =
          getData &&
          getData.map((item) => {
            return {
              value: item.id,
              label: `${item.first_name}  ${item.last_name}`,
            }
          })
        this.setState({ allStaffOption: listStaff })
      } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
        this.setState({ accessErrorModal: true })
      }
    })
  }

  getCoverOption() {
    this.wip.getWipsCover().then((res) => {
      if (res) {
        const getData = res.data
        let listCover
        listCover =
          getData &&
          getData.map((e) => {
            return { value: e.id, label: e.title }
          })
        this.setState({ allCoverOption: listCover })
      } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
        this.setState({ accessErrorModal: true })
      }
    })
  }

  getWIPbyId(id) {
    this.setState({ loader: true })
    const params = {
      wip_id: id,
      join: [
        'company',
        'wip_notes',
        'assigned_staff',
        'property',
        'wips||list_fee,cover_id',
        'notes',
        'debtors',
        'debtors.contact',
        'tasks',
      ],
    }
    const self = this

    this.wip.getWipsId(params).then((res) => {
      if (res.status === 200) {
        const getWIPData = res.data
        this.setState({
          claim_details_total: getWIPData.claim_details_total,
          recoverable_costs_total: getWIPData.recoverable_costs_total,
          wipData: res.data,
          costOfferAmount: res.data.recoverable_costs_details.offered_amount,
        })
        if (getWIPData.company.id) {
          const params = {
            id: getWIPData.company.id,
            join: ['contacts'],
          }
          this.comp.getCompaniesId(params).then((res) => {
            if (res.status === 200) {
              const getData = res.data
              const primaryContact = getData.contacts.find((val) => val.is_primary === true)
              this.setState({
                selectedClient: { value: getData.id, label: getData.name },
                clientStatus:
                  clientStatusOption.find((_) => _.label === getData?.status) &&
                  clientStatusOption.find((_) => _.label === getData?.status).label,
                clientEmail:
                  getData.contacts && getData.contacts.length > 0 ? primaryContact?.comm_email : '',
                clientContact: getData.client_phone,
                clientContactName:
                  getData.contacts && getData.contacts.length > 0
                    ? primaryContact?.first_name + ' ' + primaryContact?.last_name
                    : '',
                clientFirstName:
                  getData.contacts && getData.contacts.length > 0 ? primaryContact?.first_name : '',
                clientLastName:
                  getData.contacts && getData.contacts.length > 0 ? primaryContact?.last_name : '',
              })
            } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
              this.setState({ accessErrorModal: true })
            } else {
              this.services.errorToast(res.message)
            }
          })
        }
        this.setState({ tempSub: getWIPData.subject })
        const clam_detailsOther =
          getWIPData.claim_details?.list &&
          getWIPData.claim_details?.list.map((item) => {
            item.id = Math.floor(Math.random() * (1 + 100)) + 1
            return item
          })

        const cost_detailsOther =
          getWIPData.recoverable_costs_details?.list &&
          getWIPData.recoverable_costs_details?.list.map((item) => {
            item.id = Math.floor(Math.random() * (1 + 100)) + 1
            item.date = self.dateFormat(item.date)
            return item
          })

        if (this.state.allDebtor && this.state.allDebtor.length > 0 && !this.state.debtorss) {
          const subject = []
          const other = []
          getWIPData.debtors &&
            getWIPData.debtors.map((item) => {
              const data = {
                id: item.id,
                selected: this.state.allDebtor.find((_) => _.value === item.id),
              }
              other.push(this.state.allDebtor.find((_) => _.value === item.id))
              subject.push(data)
            })

          if (subject && subject[0]) {
            this.setState({ subjects: subject, trackPayments: other })
          }
        }
        this.getPaymentTracking()
        if (clam_detailsOther && clam_detailsOther.length === 0) {
          const clam = {
            id: '_' + Math.random().toString(36).substr(2, 9),
            description: '',
            current_amount: '',
            original_amount: '',
          }
          clam_detailsOther.push(clam)
        }

        const cover = this.state.allCoverOption.find(
          (_) => _.value === getWIPData.landlord_insurance_details?.wip_cover_id,
        )
        const listFee = this.state.listFeesOption.find((_) => _.value === getWIPData?.list_fee)
        const priority = PriorityOption.find((_) => _.label === getWIPData?.priority)
        const staff = this.state.allStaffOption.find((_) => _.value === getWIPData?.staff_assigned)

        this.setState({
          clam_details:
            clam_detailsOther === undefined ? this.state.clam_details : clam_detailsOther,
          cost_details:
            cost_detailsOther === undefined ? this.state.cost_details : cost_detailsOther,
        })

        if (getWIPData?.strata_owners) {
          this.setState({
            strataWIP: true,
          })
        } else {
          this.setState({
            strataWIP: false,
          })
        }

        this.setState({
          assigned_staff: staff,
          property: getWIPData.property,
          wipPriority: priority,
          checkOwner: getWIPData.requested_order,
          checkSend: getWIPData.requested_taf,
          list_fee: listFee,
          fileNumber: getWIPData.file_number,
          dateLodged: self.dateFormat(getWIPData.created_at),
          asDateLog: getWIPData.cover_as_date_log,
          coverOption: cover,
          origin: {
            value: getWIPData.state_of_origin || 'ACT',
            label: getWIPData.state_of_origin || 'ACT',
          },
          finalizeDate: self.dateFormat(getWIPData.finalize_date),
          fileLocation: fileLocationOption.find((_) => _.value === getWIPData.file_location),
          paidOutDate: self.dateFormat(getWIPData.paid_out_date),
          checkPayOwner: getWIPData.pay_owner_direct,
          checkSendReport: getWIPData.send_owner_reports,
          claimInterestTotal: getWIPData.current_interest,
          landlordInsurance:
            getWIPData?.landlord_insurance_details?.is_property_covered_by_landlord_insurance,
          landlordPayOut: getWIPData?.landlord_insurance_details?.is_insurance_didnt_payout,
          insuranceBy: getWIPData?.landlord_insurance_details?.insured_by,
          totalOriginalClaim: getWIPData?.landlord_insurance_details?.total_origin_claim,
          amtCoverInsurance: getWIPData?.landlord_insurance_details?.amount_covered,
          paidDirectToAgent: getWIPData?.paid_to_agent,
          paidAsAtAgent: self.dateFormat(getWIPData.paid_to_agent_date),
          totalClaimAmount: getWIPData.recoverable_costs_details.total,
          totalCostAmount: 0,
          ownerData: getWIPData?.strata_owners || getWIPData?.owner_details,
          ownerName: getWIPData?.owner_details?.name,
          ownerAddress: getWIPData?.owner_details?.address,
          ownerContact: getWIPData?.owner_details?.phone,
          ownerEmail: getWIPData?.owner_details?.email,
          ownerBank: getWIPData?.owner_details?.bank_account,
          diared_to: getWIPData.diared_to,
          propertyManager: getWIPData.property_manager,
          last_report_sent: getWIPData.last_report_sent,
          payment:
            PaymentTypeOption.find(({ value }) => value === getWIPData.payment) ||
            PaymentTypeOption[0],
          address1: getWIPData.property?.address_details?.street_name || '',
          address2: getWIPData.property?.address_details?.street_secondary || '',
          address3: getWIPData.property?.address_details?.city || '',
          propSuburb: getWIPData.property?.address_details?.suburb || '',
          propState: getWIPData.property?.address_details?.state || '',
          propPostCode: getWIPData.property?.address_details?.postcode || '',
          propCountry: getWIPData.property?.address_details?.country || '',
          selectedProperty: '',
          propertyId: getWIPData.property?.id,
          plaintiffName: getWIPData.plaintiff_name,
          plaintiffAddress: getWIPData.plaintiff_address,
          defendantName: getWIPData.defendant_name,
          defendantAddress: getWIPData.defendant_address,
          courtLocation: getWIPData.court_location,
          tribunalLocation: getWIPData.tribunal_location,
          tribunalOrderDate: getWIPData.tribunal_order_date,
          tribunalClaim: getWIPData.tribunal_claim,
          claimPlaintiffNumber: getWIPData.claim_plaintiff_number,
          enforcementDate: getWIPData.enforcement_date,
          attachedFile: getWIPData.attached_file === null ? [] : getWIPData.attached_file,
        })
        if (getWIPData.recovery_details) {
          const recoveryDetails = mapRecoveryDetails(getWIPData.recovery_details)
          this.setState({
            clam_details: [
              ...Object.keys(recoveryDetails).map((key) => ({
                current_amount: key,
                description: recoveryDetails[key],
              })),
            ],
          })
        }

        let cClaim = 0
        if (this.state.totalClaimAmount) {
          cClaim += parseFloat(this.state.totalClaimAmount)
        }
        if (this.state.claimInterestTotal) {
          cClaim += parseFloat(this.state.claimInterestTotal)
        }
        if (this.state.totalCostAmount) {
          cClaim += parseFloat(this.state.totalCostAmount)
        }
        this.setState({
          currentClaim: cClaim,
        })
        if (this.props.sendDataParent) this.props.sendDataParent(getWIPData)
        this.setState({ loader: false })
      } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
        this.setState({ accessErrorModal: true })
      }
    })
  }

  getAllClient() {
    this.comp.getCompanies().then((res) => {
      if (res.status === 200) {
        const getData = res.data.data
        let listClient
        listClient =
          getData &&
          getData.map((item) => {
            return { value: item.id, label: item.name }
          })
        this.setState({
          allClient: listClient,
        })
      } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
        this.setState({ accessErrorModal: true })
      } else {
        this.services.errorToast(res.message)
      }
    })
  }

  getListFeeOption() {
    this.wip.getListFeeOption().then((res) => {
      if (res) {
        const getData = res.data.data
        let listFee
        listFee =
          getData &&
          getData.map((item) => {
            return { value: item.id, label: item.list_fee }
          })
        this.setState({
          listFeesOption: listFee,
        })
      } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
        this.setState({ accessErrorModal: true })
      } else {
        this.services.errorToast(res.message)
      }
    })
  }

  dateFormat(dateNew) {
    let fDate
    if (dateNew && dateNew !== '0000-00-00') {
      if (dateNew) {
        const date = moment(dateNew, 'YYYY-MM-DDTHH:mm:ss.sssZ').format('YYYY-MM-DDTHH:mm:ss.sssZ')
        fDate = ''
        if (date === 'Invalid date') {
          fDate = new Date(dateNew.slice(0, dateNew.length - 2).replace(/\//g, '-'))
        } else {
          fDate = new Date(date)
        }
      } else {
        return ''
      }
      if (fDate === 'Invalid Date' || fDate === 'Invalid date') {
        return ''
      } else {
        return fDate
      }
    }
  }

  changeInput(e) {
    if (!e.target.validity.valid) {
      return null
    }
    this.setState({ [e.target.name]: e.target.value })
  }

  changeInputAddress(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  changeOwnerInput(e, name, itemIndex) {
    const value = e?.target?.value

    this.setState((prevState) => {
      return {
        ...prevState,
        ownerData: prevState?.ownerData?.map((item, index) => {
          if (index === itemIndex) {
            return { ...item, [name]: value }
          }

          return item
        }),
      }
    })
  }

  changeClaimDetails = (e, id) => {
    if (!e.target.validity.valid) {
      return null
    }
    if (e.target.name === 'description' && e.target.value) {
      this.setState({ errorClaimOne: '' })
    } else if (e.target.name === 'original_amount' && e.target.value) {
      this.setState({ errorClaimTwo: '' })
    } else if (e.target.name === 'current_amount' && e.target.value) {
      this.setState({ errorClaimThree: '' })
    }
    const data = this.state.clam_details.map(function (item) {
      if (item.id === id && e.target) {
        item[e.target.name] = e.target.value
      }
      return item
    })
    let claimTotal = 0
    this.state.clam_details.map((item) => {
      claimTotal += parseFloat(item.current_amount)
    })
    this.setState({ clam_details: data, totalClaimAmount: +claimTotal })
  }

  changeCostDetails = (e, id) => {
    if (!e.target.validity.valid) {
      return null
    }
    if (e.target.name === 'description' && e.target.value) {
      this.setState({ errorCostOne: '' })
    } else if (e.target.name === 'amount' && e.target.value) {
      this.setState({ errorCostTwo: '' })
    }

    const data = this.state.cost_details.map(function (item) {
      if (item.id === id && e.target) {
        item[e.target.name] = e.target.value
      }
      return item
    })
    let costTotal = 0
    this.state.cost_details.map((item) => {
      costTotal += parseFloat(item.amount)
    })
    this.setState({ cost_details: data, totalCostAmount: +costTotal })
  }

  changeCostDate = (date, id) => {
    const self = this
    const data = this.state.cost_details.map(function (item) {
      if (item.id === id && date) {
        self.setState({ errorCostThree: '' })
        item.date = date
      }
      return item
    })
    this.setState({ cost_details: data })
  }

  handleChange = (selectedOption, name) => {
    this.setState({ [name]: selectedOption })
    if (name === 'selectedClient' && selectedOption && selectedOption.value) {
      const params = {
        id: selectedOption.value,
        join: ['contacts'],
      }

      this.comp.getCompaniesId(params).then((res) => {
        if (res.status === 200) {
          const getData = res.data

          this.setState({
            clientStatus:
              clientStatusOption.find((_) => _.label === getData?.status) &&
              clientStatusOption.find((_) => _.label === getData?.status).label,
            clientEmail:
              getData.contacts && getData.contacts.length > 0 ? getData.contacts[0].comm_email : '',
            clientContact:
              getData.contacts && getData.contacts.length > 0
                ? getData.contacts[0].comm_ph_mobile
                : '',
            clientContactName:
              getData.contacts && getData.contacts.length > 0
                ? getData.contacts[0].first_name + ' ' + getData.contacts[0].last_name
                : '',
            clientFirstName:
              getData.contacts && getData.contacts.length > 0 ? getData.contacts[0].first_name : '',
            clientLastName:
              getData.contacts && getData.contacts.length > 0 ? getData.contacts[0].last_name : '',
          })
        } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
          this.setState({ accessErrorModal: true })
        } else {
          this.services.errorToast(res.message)
        }
      })
    } else if (name === 'origin' && selectedOption && selectedOption.value) {
      this.setState({ origin: { label: selectedOption.value, value: selectedOption.value } })
    }
  }

  handleChangeDate = (date, name) => {
    this.setState({
      [name]: date,
    })
  }

  handleOptionChange = (e) => {
    this.setState({ [e.target.name]: e.target.checked })
  }

  handleChangeSubject = (selectedOption, id) => {
    const self = this
    const data = this.state.subjects.map(function (item) {
      if (item.id === id && selectedOption) {
        self.setState({ errorSubject: '' })
        item.selected = selectedOption
      }
      return item
    })
    this.setState({ subjects: data })
  }

  handleChangePage = (event, page) => {
    this.setState({ page: page })
    this.propSearch(page + 1)
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10) })
    this.setState({ page: 0 })
    this.propSearch(0, event.target.value)
  }

  handleFor = (e) => {
    this.setState({ forAmount: e.target.value })
  }

  handleBankAccount = (e) => {
    this.setState({ bank_account: e.target.value })
  }

  addClaimDetails = () => {
    const lastData = this.state.clam_details.slice(-1)[0]
    if (lastData) {
      if (
        lastData.description === '' ||
        lastData.current_amount === '' ||
        lastData.original_amount === ''
      ) {
        if (lastData.description === '') {
          this.setState({ errorClaimOne: 'This Filed is Required' })
        } else if (lastData.original_amount === '') {
          this.setState({ errorClaimTwo: 'This Filed is Required' })
        } else if (lastData.current_amount === '') {
          this.setState({ errorClaimThree: 'This Filed is Required' })
        }
      } else {
        const data = {
          id: Math.floor(Math.random() * (1 + 100)) + 1,
          description: '',
          current_amount: '',
          original_amount: '',
        }
        const details = this.state.clam_details
        details.push(data)
        this.setState(
          {
            clam_details: details,
          },
          () => {
            this.forceUpdate()
          },
        )
      }
    }
  }

  addSubject = () => {
    const lastData = this.state.subjects.slice(-1)[0]
    if (lastData && lastData.selected === '') {
      this.setState({ errorSubject: 'This Filed is Required.' })
    } else {
      const data = {
        id: '_' + Math.random().toString(36).substr(2, 9),
        selected: '',
      }
      const details = this.state.subjects
      details.push(data)
      this.setState({ subjects: details }, () => {
        this.forceUpdate()
      })
    }
  }

  pushNewCostDetails = () => {
    const newCostDetails = {
      id: Math.floor(Math.random() * (1 + 100)) + 1,
      description: '',
      amount: '',
      date: new Date(),
    }

    const details = this.state.cost_details
    details.push(newCostDetails)
    this.setState(
      {
        cost_details: details,
      },
      () => {
        this.forceUpdate()
      },
    )
  }

  addCostDetails = () => {
    const lastData = this.state.cost_details.slice(-1)[0]
    const isValid = this.validator.allValid()

    if (!isValid && lastData) {
      this.validator.showMessages()
      this.forceUpdate()
    } else {
      this.pushNewCostDetails()
    }
  }

  removeClaimCost = (item) => {
    const list = ([] = this.state.clam_details.filter((_) => _.id !== item.id))
    if (list) {
      let claimTotal = 0
      this.setState({ clam_details: list }, () => {
        this.state.clam_details.map((item) => {
          claimTotal += parseFloat(item.current_amount)
        })
        this.setState({ totalClaimAmount: claimTotal })
      })
    }
  }

  removeRecovery = (item) => {
    const lastData = this.state.cost_details.slice(-1)[0]

    if (item.id === lastData.id) {
      this.validator.purgeFields()
    }

    const list = ([] = this.state.cost_details.filter((_) => _.id !== item.id))
    if (list) {
      let costTotal = 0
      this.setState({ cost_details: list }, () => {
        this.state.cost_details.map((item) => {
          costTotal += parseFloat(item.amount)
        })
        this.setState({ totalCostAmount: costTotal })
      })
    }
  }

  clientClick = () => {
    localStorage.setItem('contactsId', this.state.selectedClient.value)
    this.props.history.push('/setting')
    setTimeout(() => {
      this.props.history.push(`/contacts/${this.state.selectedClient.value}`, {
        fromWIPs: 'allclient',
      })
    }, 100)
  }

  propSearch = (page, rowsPerPage) => {
    if (this.state.propID) {
      const params = {
        filter: `id||$eq||${this.state.propID}`,
        page: page,
        limit: rowsPerPage,
        sort: 'time_modified,DESC',
      }
      this.prop.getProperty(params).then((res) => {
        if (res.status === 200) {
          this.setState({
            propList: res.data.data,
            total: res.data.total,
            isSearched: true,
          })
        }
      })
    } else {
      const params = {
        page: page,
        limit: rowsPerPage,
        sort: 'time_modified,DESC',
      }
      this.prop.getProperty(params).then((res) => {
        if (res.status === 200) {
          this.setState({
            propList: res.data.data,
            total: res.data.total,
            isSearched: true,
          })
        }
      })
    }
  }

  assignProperties = (item) => {
    this.setState({
      propList: [],
      propState: item.address_details?.state || '',
      propPostCode: item.address_details?.postcode || '',
      propCountry: item.address_details?.country || '',
      propSuburb: item.address_details?.suburb || '',
      address1: item.address_details?.street_name || '',
      address2: item.address_details?.street_secondary || '',
      address3: item.address_details?.city || '',
      selectedProperty: item,
      propertyId: item.id,
    })
  }

  cancel = () => {
    this.props.history.push(`/contacts/${localStorage.getItem('contactsId')}`, {
      fromWIP: true,
    })
  }

  viewProperty = () => {
    if (this.state.propertyId) {
      localStorage.setItem('propertyId', this.state.propertyId)
      localStorage.setItem('propertyIsView', 'true')
      this.props.history.push('/view-properties', {
        fromDetails: this.state.propertyId,
        wipID: this.state.wipID,
      })
    }
  }

  savePaymentTrack = () => {
    if (this.sell.allValid()) {
      const createPayData = {
        debtorId: this.state.listSelectDebtor.value,
        wipId: this.props.location.state.wipID,
        trackDayPeriod: this.state.trackOn.value,
        amount: +this.state.forAmount,
        firstPaymentDue: moment(this.state.firstPayment).format('YYYY-MM-DD'),
        from: this.state.fromPayment.label,
        id: this.state.payId,
        assigned_staff_tracking: this.state.assigned_staff?.value,
        bank_account: this.state.bank_account,
      }

      const changePayData = {
        debtor: { id: this.state.listSelectDebtor.value }, //this.state.list.map((id)=> ({ id: id.value }))
        wip: { id: this.props.location.state.wipID },
        trackDayPeriod: `${this.state.trackOn.value}`,
        amount: +this.state.forAmount,
        firstPaymentDue: moment(this.state.firstPayment).format('YYYY-MM-DD'),
        from: this.state.fromPayment.label,
        id: this.state.payId,
        assigned_staff_tracking: this.state.assigned_staff?.value,
        bank_account: this.state.bank_account,
      }

      if (this.state.payTrack.length === 0) {
        this.payTrack.createPayTrack(createPayData).then((res) => {
          if (res.status === 201) {
            this.services.successToast('Payment Tracking added Successfully')
          }
        })
      } else {
        this.payTrack.changePayTrack(changePayData).then((res) => {
          if (res.status === 200) {
            this.services.successToast('Payment Tracking changed Successfully')
          }
        })
      }
    } else {
      this.sell.showMessages()
      this.forceUpdate()
    }
  }

  saveWIP = () => {
    const subject = []
    this.state.subjects.map((item) => {
      if (item.selected && item.selected.value) {
        subject.push(item.selected.value)
      }
    })

    const recoverable_costs = []
    this.state.cost_details.length &&
      this.state.cost_details.map((item) => {
        if (item.description !== '' || item.date !== '' || item.amount !== '') {
          delete item.id
          item.amount = Number(item.amount)
          item.date = item.date ? item.date : null
          recoverable_costs.push(item)
        }
      })

    let cClaim = 0
    if (this.state.totalClaimAmount) {
      cClaim += parseFloat(this.state.totalClaimAmount)
    }
    if (this.state.claimInterestTotal) {
      cClaim += parseFloat(this.state.claimInterestTotal)
    }
    if (this.state.totalCostAmount) {
      cClaim += parseFloat(this.state.totalCostAmount)
    }

    const formData = {
      file_number: +this.state.fileNumber,
      list_fee: this.state.list_fee && this.state.list_fee.value,
      amount_received: this.state.list_fee && this.state.list_fee.value, //mock for list_fee
      total_received: this.state.totalReceived || 0,
      company: {
        id: this.state.selectedClient && this.state.selectedClient.value,
      },
      category: `${this.state.coverOption?.value}` || null, //mock for cover
      cover_as_date_log: this.state.asDateLog || null,
      date_lodged: this.state.dateLodged || null,
      property_manager: this.state.propertyManager,
      diared_to: this.state.diared_to,
      file_location: (this.state.fileLocation && this.state.fileLocation.value) || null,
      finalize_date: this.state.finalizeDate || null,
      id: this.state.wipData.id,
      landlord_insurance_details: {
        wip_cover_id: (this.state.coverOption && this.state.coverOption.value) || null,
        insured_by: this.state.insuranceBy || null,
        is_property_covered_by_landlord_insurance: this.state.landlordInsurance,
        is_insurance_didnt_payout: this.state.landlordPayOut,
        total_origin_claim: `${this.state.totalOriginalClaim}` || null,
        amount_covered: `${this.state.amtCoverInsurance}` || null,
      },
      last_report_sent: this.state.last_report_sent || null,
      paid_out_date: this.state.paidOutDate || null,
      payment: this.state.payment?.value || null,
      priority: this.state.wipPriority?.label,
      state_of_origin: (this.state.origin && this.state.origin.value) || null,
      staff_assigned: this.state.assigned_staff?.value ? this.state.assigned_staff.value : null,

      recoverable_costs_details: {
        list: recoverable_costs,
        total: this.state.totalCostAmount,
        offered_amount: +this.state.costOfferAmount,
      },

      plaintiff_name: this.state.plaintiffName,
      plaintiff_address: this.state.plaintiffAddress,
      defendant_name: this.state.defendantName,
      defendant_address: this.state.defendantAddress,
      court_location: this.state.courtLocation,
      tribunal_location: this.state.tribunalLocation,
      tribunal_order_date: this.state.tribunalOrderDate,
      tribunal_claim: this.state.tribunalClaim,
      claim_plaintiff_number: this.state.claimPlaintiffNumber,
      enforcement_date: this.state.enforcementDate,
      attached_file: this.state.attachedFile.length ? this.state.attachedFile : null,
    }

    const formDataHelper = () => {
      if (this?.state?.strataWIP) {
        return { ...formData, strata_owners: this.state.ownerData }
      }

      return { ...formData, owner_details: this.state.ownerData }
    }
    console.log(this.validator.getErrorMessages())
    if (this.validator.allValid()) {
      this.wip.editWipsId(formDataHelper()).then((res) => {
        if (res.status === 200) {
          this.services.successToast('WIP Updated Successfully')
          this.cancel()
        } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
          this.setState({ accessErrorModal: true })
        } else {
          this.props.history.push(`/contacts/${this.wipData.company.id}`)
          this.props.history.push('/wip-details')
          this.services.errorToast(res.message)
        }
      })
    } else {
      this.validator.showMessages()
      this.forceUpdate()
      toast.error('Please fill all reqired fields!')
    }
  }

  fileRemove = (params) => {
    this.setState({ attachedFile: params })
  }

  onFeeAdded = (data) => {
    this.setState({
      claim_details_total: data.claim_details_total,
      recoverable_costs_total: data.recoverable_costs_total,
    })
  }

  render() {
    const {
      loader,
      costItem,
      totalClaimAmount,
      claimInterestTotal,
      totalCostAmount,
      trackPayments,
      overViewDropDown,
      propertyDropDown,
      findProps,
      courtDetailsDropDown,
      allStaffOption,
      assigned_staff,
      diared_to,
      last_report_sent,
      wipPriority,
      payment,
      propID,
      propAddress,
      suburb,
      postCode,
      propList,
      isSearched,
      total,
      rowsPerPage,
      page,
      address1,
      address2,
      address3,
      propSuburb,
      propState,
      propPostCode,
      propCountry,
      propertyId,
      wipDetailsDropDown,
      dateLodged,
      asDateLog,
      listFeesOption,
      list_fee,
      recoverable_costs_total,
      allCoverOption,
      coverOption,
      allOrigin,
      origin,
      finalizeDate,
      fileLocation,
      paidOutDate,
      claimAmountDropDown,
      payTrackDropDown,
      wipID,
      trackOn,
      forAmount,
      firstPayment,
      fromPayment,
      assigned_staff_tracking,
      bank_account,
      plaintiffName,
      plaintiffAddress,
      defendantName,
      defendantAddress,
      courtLocation,
      tribunalLocation,
      tribunalOrderDate,
      tribunalClaim,
      claimPlaintiffNumber,
      enforcementDate,
      clientDropDown,
      allClient,
      selectedClient,
      clientStatus,
      clientEmail,
      clientFirstName,
      clientLastName,
      clientContact,
      landlordDropDown,
      landlordInsurance,
      landlordPayOut,
      insuranceBy,
      totalOriginalClaim,
      amtCoverInsurance,
      ownerDropDown,
      ownerData,
      ownerBank,
      listSelectDebtor,
      propertyManager,
      attachmentDetailsDropDown,
      attachedFile,
      claim_details_total,
    } = this.state

    const overViewDetails = `slide_drop_down boxshadow-none cursor-pointer d-flex flex-wrap align-items-center justify-content-between rounded ${
      overViewDropDown ? 'active' : ''
    }`
    const propertyDetails = `slide_drop_down boxshadow-none cursor-pointer d-flex flex-wrap align-items-center justify-content-between rounded ${
      propertyDropDown ? 'active' : ''
    }`
    const wipDetails = `slide_drop_down boxshadow-none cursor-pointer d-flex flex-wrap align-items-center justify-content-between rounded ${
      wipDetailsDropDown ? 'active' : ''
    }`
    const claimDetails = `slide_drop_down boxshadow-none cursor-pointer d-flex flex-wrap align-items-center justify-content-between rounded ${
      claimAmountDropDown ? 'active' : ''
    }`
    const payTrackDetails = `slide_drop_down boxshadow-none cursor-pointer d-flex flex-wrap align-items-center justify-content-between rounded ${
      payTrackDropDown ? 'active' : ''
    }`
    const courtDetails = `slide_drop_down boxshadow-none cursor-pointer d-flex flex-wrap align-items-center justify-content-between rounded ${
      courtDetailsDropDown ? 'active' : ''
    }`
    const attachmentDetails = `slide_drop_down boxshadow-none cursor-pointer d-flex flex-wrap align-items-center justify-content-between rounded ${
      attachmentDetailsDropDown ? 'active' : ''
    }`
    const clientDetails = `slide_drop_down boxshadow-none cursor-pointer d-flex flex-wrap align-items-center justify-content-between rounded ${
      clientDropDown ? 'active' : ''
    }`
    const LandlordDetails = `slide_drop_down boxshadow-none cursor-pointer d-flex flex-wrap align-items-center justify-content-between rounded ${
      landlordDropDown ? 'active' : ''
    }`
    const ownerDetails = `slide_drop_down boxshadow-none cursor-pointer d-flex flex-wrap align-items-center justify-content-between rounded ${
      ownerDropDown ? 'active' : ''
    }`

    let cClaim = 0.0
    if (totalClaimAmount) {
      cClaim += parseFloat(totalClaimAmount)
    }
    if (claimInterestTotal) {
      cClaim += parseFloat(claimInterestTotal)
    }
    if (totalCostAmount) {
      cClaim += parseFloat(totalCostAmount)
    }

    const list =
      trackPayments[0] !== undefined &&
      trackPayments.map((item) => {
        return { value: item.value, label: item.label }
      })

    return loader ? (
      <LoaderWip />
    ) : (
      <div className="w-100">
        <div id="contacts_accordion" className="form">
          <div className="card pb-0">
            {/*----------------overViewDetails------------------*/}

            <div className="slide_down_wrap mb-4 rounded">
              <div className=" card-body bg-white pl-0 rounded">
                <div
                  className={overViewDetails}
                  onClick={() => this.setState({ overViewDropDown: !overViewDropDown })}
                >
                  <h3 className="page-title mb-0" data-toggle="collapse">
                    Details
                  </h3>
                  <Svg />
                </div>
                <div className={`bg-white hide_contact ${overViewDropDown ? 'active pt-4' : ''}`}>
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label">WIP Assigned to:</label>
                        <Select
                          type="text"
                          classNamePrefix="react-select"
                          className="react-select"
                          options={allStaffOption}
                          value={assigned_staff}
                          name="assigned_staff"
                          onChange={(e) => this.handleChange(e, 'assigned_staff')}
                          isDisabled={false}
                        />
                        <span className="form__form-group-error"></span>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label">Last Report Sent:</label>
                        <div className="date-picker date-picker--interval">
                          <DatePicker
                            selected={last_report_sent ? this.dateFormat(last_report_sent) : ''}
                            onChange={(e) => this.handleChangeDate(e, 'last_report_sent')}
                            name="last_report_sent"
                            dateFormat="dd/MM/yy"
                            dropDownMode="select"
                            dis
                            disabled={true}
                            popperPlacement="center"
                          />
                          <span className="date_icon">
                            <img src={'./image/calendar.svg'} alt="date" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label">Priority:</label>
                        <Select
                          type="text"
                          classNamePrefix="react-select"
                          className="react-select"
                          options={PriorityOption}
                          value={wipPriority}
                          name="wipPriority"
                          onChange={(e) => this.handleChange(e, 'wipPriority')}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label">Payment:</label>
                        <Select
                          type="text"
                          classNamePrefix="react-select"
                          className="react-select"
                          options={PaymentTypeOption}
                          value={payment}
                          name="payment"
                          onChange={(e) => this.handleChange(e, 'payment')}
                        />
                      </div>
                    </div>

                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label">Property Manager</label>
                        <input
                          type="text"
                          className="custom-input"
                          placeholder="Property Manager"
                          name="propertyManager"
                          value={propertyManager || ''}
                          onChange={this.changeInput}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*----------------propertyDetails------------------*/}

            <div className="slide_down_wrap mb-4 rounded">
              <div className=" card-body bg-white pl-0 rounded">
                <div
                  className={propertyDetails}
                  onClick={() => this.setState({ propertyDropDown: !propertyDropDown })}
                >
                  <h3 className="page-title mb-0" data-toggle="collapse">
                    Property
                  </h3>
                  <Svg />
                </div>
                <div className={`bg-white hide_contact ${propertyDropDown ? 'active pt-4' : ''}`}>
                  <div className="card">
                    <div className="form-group">
                      <button
                        className="btn-gray"
                        type="button"
                        onClick={() => {
                          this.setState({ findProps: !findProps })
                        }}
                      >
                        Find Property
                      </button>
                    </div>
                    {findProps && (
                      <>
                        <div className="find-property-section">
                          <div className="row">
                            <div className="col-12 col-md-6 col-lg-3">
                              <div className="form-group">
                                <label className="form__form-group-label">PropID</label>
                                <input
                                  type="text"
                                  className="custom-input"
                                  name="propID"
                                  value={propID}
                                  onChange={this.changeInput}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 col-md-9">
                              <div className="form-group">
                                <label className="form__form-group-label"> Address </label>
                                <input
                                  type="text"
                                  className="custom-input"
                                  name="propAddress"
                                  value={propAddress}
                                  onChange={this.changeInput}
                                  disabled={true}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 col-md-6 col-lg-3">
                              <div className="form-group">
                                <label className="form__form-group-label">Suburb</label>
                                <input
                                  type="text"
                                  className="custom-input"
                                  name="suburb"
                                  value={suburb}
                                  onChange={this.changeInput}
                                  disabled={true}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-3">
                              <div className="form-group">
                                <label className="form__form-group-label">Post Code</label>
                                <input
                                  type="text"
                                  className="custom-input"
                                  name="postCode"
                                  value={postCode}
                                  onChange={this.changeInput}
                                  disabled={true}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-4 col-lg-2">
                              <div className="form-group">
                                <div className="form-group top-20">
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => this.propSearch()}
                                  >
                                    Search
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {propList.length !== 0 && isSearched && (
                      <>
                        <hr />
                        <Box margin={1} mt={2} className="table_wrap m-0 p-1">
                          <Table aria-label="purchases">
                            <TableHead>
                              <TableRow>
                                <TableCell>PropID</TableCell>
                                <TableCell>Address</TableCell>
                                <TableCell>Suburb</TableCell>
                                <TableCell>State </TableCell>
                                <TableCell>Post Code</TableCell>
                                <TableCell>Covered To </TableCell>
                                <TableCell>Status</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {propList.map((item) => {
                                return (
                                  <TableRow key={item.id} className="form">
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      className="material-table__cell material-table__cell-right"
                                    >
                                      {item.id}
                                    </TableCell>
                                    <TableCell className="material-table__cell material-table__cell-right">
                                      {item.address_details?.street_name}
                                    </TableCell>
                                    <TableCell className="material-table__cell material-table__cell-right">
                                      {item.address_details?.suburb}
                                    </TableCell>
                                    <TableCell className="material-table__cell material-table__cell-right">
                                      {item.address_details?.state}
                                    </TableCell>
                                    <TableCell className="material-table__cell material-table__cell-right">
                                      {item.address_details?.postcode}
                                    </TableCell>
                                    <TableCell className="material-table__cell material-table__cell-right">
                                      {item.cover_details?.to}
                                    </TableCell>
                                    <TableCell className="material-table__cell material-table__cell-right">
                                      <div className="d-flex flex-wrap align-items-center justify-content-between">
                                        <div className="d-flex  flex-wrap mb-1 align-items-center">
                                          <span>{item.status} </span>
                                        </div>
                                        <div className="d-flex flex-wrap mt-3 align-items-center">
                                          <ButtonGroup className="btn-group--icons" dir="ltr">
                                            <Button
                                              outline
                                              id="Edit"
                                              onClick={() => this.assignProperties(item)}
                                            >
                                              Assign Properties
                                            </Button>
                                          </ButtonGroup>
                                          <UncontrolledTooltip placement="bottom" target="Edit">
                                            Assign Properties
                                          </UncontrolledTooltip>
                                        </div>
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                )
                              })}

                              {propList.length === 0 && isSearched && (
                                <TableRow className="material-table__row" style={{ height: 60 }}>
                                  <TableCell colSpan={8} className="text-center bg-white">
                                    {' '}
                                    <span>{'None To Display'}</span>
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                          <hr />
                          <TablePagination
                            component="div"
                            className="material-table__pagination"
                            count={total}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            backIconButtonProps={{
                              'aria-label': 'Previous Page',
                            }}
                            nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                            onPageChange={this.handleChangePage}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                            rowsPerPageOptions={[5, 10, 15]}
                            dir="ltr"
                            SelectProps={{
                              inputProps: { 'aria-label': 'rows per page' },
                              native: true,
                            }}
                          />
                        </Box>
                      </>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-9">
                      <div className="form-group">
                        <label className="form__form-group-label">Address Line 1</label>
                        <input
                          type="text"
                          className="custom-input"
                          name="address1"
                          value={address1 || ''}
                          onChange={(e) => this.changeInputAddress(e)}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-9">
                      <div className="form-group">
                        <label className="form__form-group-label"> Address Line 2 </label>
                        <input
                          type="text"
                          className="custom-input"
                          name="address2"
                          value={address2}
                          onChange={(e) => this.changeInputAddress(e)}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-9">
                      <div className="form-group">
                        <label className="form__form-group-label"> Address Line 3 </label>
                        <input
                          type="text"
                          className="custom-input"
                          name="address3"
                          value={address3}
                          onChange={(e) => this.changeInputAddress(e)}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label">Suburb</label>
                        <input
                          type="text"
                          className="custom-input"
                          name="propSuburb"
                          value={propSuburb}
                          onChange={(e) => this.changeInputAddress(e)}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label">State</label>
                        <input
                          type="text"
                          className="custom-input"
                          name="propState"
                          value={propState || ''}
                          onChange={(e) => this.changeInputAddress(e)}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label">Post Code</label>
                        <input
                          type="text"
                          className="custom-input"
                          name="propState"
                          value={propPostCode}
                          onChange={(e) => this.changeInputAddress(e)}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label">Country</label>
                        <input
                          type="text"
                          className="custom-input"
                          name="propCountry"
                          value={propCountry}
                          onChange={(e) => this.changeInputAddress(e)}
                          disabled={true}
                        />
                      </div>
                    </div>

                    <div className="col-12 col-md-4 col-lg-2">
                      {propertyId && (
                        <div className="form-group">
                          <div className="form-group top-20">
                            <button className="btn btn-primary" onClick={() => this.viewProperty()}>
                              View Property
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*----------------wipDetails-----------------------*/}

            <div className="slide_down_wrap mb-4 rounded">
              <div className=" card-body bg-white pl-0 rounded">
                <div
                  className={wipDetails}
                  onClick={() => this.setState({ wipDetailsDropDown: !wipDetailsDropDown })}
                >
                  <h3 className="page-title mb-0" data-toggle="collapse">
                    WIP Details
                  </h3>
                  <Svg />
                </div>
                <div className={`bg-white hide_contact ${wipDetailsDropDown ? 'active pt-4' : ''}`}>
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label">File Number</label>
                        <input
                          type="text"
                          className="custom-input"
                          name="fileNumber"
                          onChange={this.changeInput}
                          value={wipID || ''}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label">Date Lodged</label>
                        <div className="date-picker date-picker--interval">
                          <DatePicker
                            selected={dateLodged ? this.dateFormat(dateLodged) : ''}
                            onChange={(e) => this.handleChangeDate(e, 'dateLodged')}
                            name="dateLodged"
                            dateFormat="dd/MM/yy"
                            dropDownMode="select"
                            popperPlacement="center"
                            placeholderText="Select Date"
                          />
                          <span className="date_icon">
                            <img src={'./image/calendar.svg'} alt="date" />
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label">Cover as at Date Log</label>
                        <input
                          type="text"
                          className="custom-input"
                          name="asDateLog"
                          onChange={this.changeInput}
                          value={asDateLog || ''}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label">
                          Cover
                          {/*<span style={{ color: '#ff0000' }}>*</span>*/}
                        </label>
                        <Select
                          type="text"
                          classNamePrefix="react-select"
                          className="react-select"
                          name="coverOption"
                          options={allCoverOption}
                          onChange={(e) => this.handleChange(e, 'coverOption')}
                          value={coverOption}
                        />
                        {/*<span className="form__form-group-error">*/}
                        {/*  {this.validator.message('Cover', coverOption, 'required')}*/}
                        {/*</span>*/}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label">State of Origin</label>
                        <Select
                          type="text"
                          classNamePrefix="react-select"
                          className="react-select"
                          options={allOrigin}
                          name="origin"
                          value={origin}
                          onChange={(e) => this.handleChange(e, 'origin')}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label">
                          Finalize Date
                          {/*<span style={{ color: '#ff0000' }}>*</span>*/}
                        </label>
                        <div className="date-picker date-picker--interval">
                          <DatePicker
                            selected={finalizeDate ? this.dateFormat(finalizeDate) : ''}
                            onChange={(e) => this.handleChangeDate(e, 'finalizeDate')}
                            name="finalizeDate"
                            dateFormat="dd/MM/yy"
                            dropDownMode="select"
                            popperPlacement="center"
                            placeholderText="Select Date"
                          />
                          <span className="date_icon">
                            <img src={'./image/calendar.svg'} alt="date" />
                          </span>
                        </div>
                        {/*<span className="form__form-group-error">*/}
                        {/*  {this.validator.message('finalize Date', finalizeDate, 'required')}*/}
                        {/*</span>*/}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label">
                          File Location
                          {/*<span style={{ color: '#ff0000' }}>*</span>*/}
                        </label>
                        <Select
                          type="text"
                          classNamePrefix="react-select"
                          className="react-select"
                          options={fileLocationOption}
                          name="fileLocation"
                          onChange={(e) => this.handleChange(e, 'fileLocation')}
                          value={fileLocation}
                        />
                        {/*<span className="form__form-group-error">*/}
                        {/*  {this.validator.message('file location', fileLocation, 'required')}*/}
                        {/*</span>*/}
                      </div>
                    </div>

                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label">Paid Out Date</label>
                        <div className="date-picker date-picker--interval">
                          <DatePicker
                            selected={paidOutDate ? this.dateFormat(paidOutDate) : ''}
                            onChange={(e) => this.handleChangeDate(e, 'paidOutDate')}
                            name="paidOutDate"
                            dateFormat="dd/MM/yy"
                            dropDownMode="select"
                            popperPlacement="center"
                            placeholderText="Select Date"
                          />
                          <span className="date_icon">
                            <img src={'./image/calendar.svg'} alt="date" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <RecoverableCosts wipId={wipID} recoverableCostsTotal={recoverable_costs_total} stateOfOrigin={origin?.value || null} onFeeAdded={this.onFeeAdded}  />
                </div>
              </div>
            </div>

            {/*----------------claimDetails---------------------*/}

            <div className="slide_down_wrap mb-4 rounded">
              <div className=" card-body bg-white pl-0 rounded">
                <div
                  className={claimDetails}
                  onClick={() => this.setState({ claimAmountDropDown: !claimAmountDropDown })}
                >
                  <h3 className="page-title mb-0" data-toggle="collapse">
                    Claim Amount Details
                  </h3>
                  <Svg />
                </div>
                <div
                  className={`bg-white hide_contact ${claimAmountDropDown ? 'active pt-4' : ''}`}
                >
                  <ClaimDetails
                    wipId={wipID}
                    claimDetailsInitialValues={{ claim_details_total }}
                    stateOfOrigin={origin?.value || null}
                  />
                </div>
              </div>
            </div>

            {/*----------------payTrackDetails------------------*/}

            <div className="slide_down_wrap mb-4 rounded">
              <div className=" card-body bg-white pl-0 rounded">
                <div
                  className={payTrackDetails}
                  onClick={() => this.setState({ payTrackDropDown: !payTrackDropDown })}
                >
                  <h3 className="page-title mb-0" data-toggle="collapse">
                    Payment Tracking
                  </h3>
                  <Svg />
                </div>
                <div className={`bg-white hide_contact ${payTrackDropDown ? 'active pt-4' : ''}`}>
                  <PaymentTracking
                    wipId={wipID}
                    list={list}
                    valueList={listSelectDebtor}
                    handleChange={this.handleChange}
                    message={this.sell}
                    trackOnPeriod={trackOnPeriod}
                    trackOnValue={trackOn}
                    forAmount={forAmount}
                    handlerFor={this.handleFor}
                    handleBankAccount={this.handleBankAccount}
                    firstPayment={firstPayment}
                    dateFormat={this.dateFormat}
                    handleChangeDate={this.handleChangeDate}
                    source={source}
                    fromPayment={fromPayment}
                    savePaymentTrack={this.savePaymentTrack}
                    allStaffOption={allStaffOption}
                    trackOnStaff={assigned_staff_tracking}
                    value={assigned_staff}
                    bankAccount={bank_account}
                  />
                </div>
              </div>
            </div>

            {/*----------------courtDetails---------------------*/}

            <div className="slide_down_wrap mb-4 rounded">
              <div className=" card-body bg-white pl-0 rounded">
                <div
                  className={courtDetails}
                  onClick={() =>
                    this.setState({
                      courtDetailsDropDown: !courtDetailsDropDown,
                    })
                  }
                >
                  <h3 className="page-title mb-0" data-toggle="collapse">
                    Court Details
                  </h3>
                  <Svg />
                </div>
                <div
                  className={`bg-white hide_contact ${courtDetailsDropDown ? 'active pt-4' : ''}`}
                >
                  <CourtDetails
                    dateFormat={this.dateFormat}
                    handleChangeDate={this.handleChangeDate}
                    handleCourtDetails={this.changeInput}
                    plaintiffName={plaintiffName}
                    plaintiffAddress={plaintiffAddress}
                    defendantName={defendantName}
                    defendantAddress={defendantAddress}
                    courtLocation={courtLocation}
                    tribunalLocation={tribunalLocation}
                    tribunalOrderDate={tribunalOrderDate}
                    tribunalClaim={tribunalClaim}
                    claimPlaintiffNumber={claimPlaintiffNumber}
                    enforcementDate={enforcementDate}
                  />
                </div>
              </div>
            </div>

            {/*----------------Download file---------------------*/}

            <div className="slide_down_wrap mb-4 rounded">
              <div className=" card-body bg-white pl-0 rounded">
                <div
                  className={attachmentDetails}
                  onClick={() =>
                    this.setState({
                      attachmentDetailsDropDown: !attachmentDetailsDropDown,
                    })
                  }
                >
                  <h3 className="page-title mb-0" data-toggle="collapse">
                    Attachment Details
                  </h3>
                  <Svg />
                </div>
                <div
                  className={`bg-white hide_contact ${
                    attachmentDetailsDropDown ? 'active pt-4' : ''
                  }`}
                >
                  <AttachedFilesWip wipDetails={attachedFile} setWipDetails={this.fileRemove} />
                </div>
              </div>
            </div>

            {/*----------------clientDetails--------------------*/}

            <div className="slide_down_wrap mb-4 rounded">
              <div className=" card-body bg-white pl-0 rounded">
                <div
                  className={clientDetails}
                  onClick={() => this.setState({ clientDropDown: !clientDropDown })}
                >
                  <h3 className="page-title mb-0" data-toggle="collapse">
                    Client
                  </h3>
                  <Svg />
                </div>
                <div className={`bg-white hide_contact ${clientDropDown ? 'active pt-4' : ''}`}>
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label">
                          Client <span style={{ color: '#ff0000' }}>*</span>
                        </label>
                        <Select
                          type="text"
                          classNamePrefix="react-select"
                          className="react-select"
                          options={allClient}
                          value={selectedClient}
                          onChange={(e) => this.handleChange(e, 'selectedClient')}
                        />
                        <span className="form__form-group-error">
                          {this.validator.message('Client', selectedClient, 'required')}
                        </span>
                      </div>
                    </div>

                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label">Client Status:</label>
                        <input type="text" className="custom-input" value={clientStatus} disabled />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label">Client Email Address:</label>
                        <input type="text" className="custom-input" value={clientEmail} disabled />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label">Client First Name:</label>
                        <input
                          type="text"
                          className="custom-input"
                          value={clientFirstName}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label">Client Last Name:</label>
                        <input
                          type="text"
                          className="custom-input"
                          value={clientLastName}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label">Client Phone Number:</label>
                        <input
                          type="text"
                          className="custom-input"
                          value={clientContact}
                          disabled
                        />
                      </div>
                    </div>
                    {selectedClient && (
                      <div className="col-12 col-md-4 col-lg-2">
                        <div className="form-group">
                          <label className="form__form-group-label"> &nbsp; </label>
                          <button className="btn btn-primary mt-4" onClick={this.clientClick}>
                            {' '}
                            View Client{' '}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="row" />
                </div>
              </div>
            </div>

            {/*----------------landlordDetails------------------*/}

            <div className="slide_down_wrap mb-4 rounded">
              <div className=" card-body bg-white pl-0 rounded">
                <div
                  className={LandlordDetails}
                  onClick={() => this.setState({ landlordDropDown: !landlordDropDown })}
                >
                  <h3 className="page-title mb-0" data-toggle="collapse">
                    Landlord Insurance
                  </h3>
                  <Svg />
                </div>
                <div className={`bg-white hide_contact ${landlordDropDown ? 'active pt-4' : ''}`}>
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="d-flex align-items-center ml-2 mb-3">
                        <div className="round_checkbox">
                          <input
                            type="checkbox"
                            className=""
                            name="landlordInsurance"
                            id="Landlord"
                            checked={landlordInsurance || false}
                            onChange={(e) => {
                              this.setState({
                                landlordInsurance: e.target.checked,
                              })
                            }}
                          />
                          <label className="mb-0" htmlFor="Landlord" />
                        </div>
                        <p className="m-0 ml-3">Was Property Covered by Landlord Insurance?</p>
                      </div>
                    </div>

                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="d-flex align-items-center ml-2 mb-3">
                        <div className="round_checkbox">
                          <input
                            type="checkbox"
                            className=""
                            name="landlordPayOut"
                            id="Insurance"
                            checked={landlordPayOut || false}
                            onChange={(e) => {
                              this.setState({
                                landlordPayOut: e.target.checked,
                              })
                            }}
                          />
                          <label className="mb-0" htmlFor="Insurance" />
                        </div>
                        <p className="m-0 ml-3">Insurance did not pay out? </p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label">Insured By</label>
                        <input
                          type="text"
                          className="custom-input"
                          name="insuranceBy"
                          onChange={this.changeInput}
                          value={insuranceBy || ''}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label">Total Original Claim</label>
                        <input
                          type="text"
                          className="Total Original Claim"
                          name="totalOriginalClaim"
                          pattern="^([0-9]+(\.?[0-9]?[0-9]?)?)"
                          onChange={this.changeInput}
                          value={totalOriginalClaim || ''}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label">Amount Covered</label>
                        <input
                          type="text"
                          className="custom-input"
                          name="amtCoverInsurance"
                          pattern="^([0-9]+(\.?[0-9]?[0-9]?)?)"
                          value={amtCoverInsurance || ''}
                          onChange={this.changeInput}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*----------------ownerDetails---------------------*/}

            <div className="slide_down_wrap mb-4 rounded">
              <div className=" card-body bg-white pl-0 rounded">
                <div
                  className={ownerDetails}
                  onClick={() => this.setState({ ownerDropDown: !ownerDropDown })}
                >
                  <h3 className="page-title mb-0" data-toggle="collapse">
                    Owner
                  </h3>
                  <Svg />
                </div>

                {ownerData &&
                  Array.isArray(ownerData) &&
                  ownerData?.map((item, index) => {
                    return (
                      <div
                        className={`bg-white hide_contact ${ownerDropDown ? 'active pt-4' : ''}`}
                        key={index}
                      >
                        <div className="row">
                          <div className="col-12 col-md-6 col-lg-3">
                            <div className="form-group">
                              <label className="form__form-group-label">Name:</label>
                              <input
                                type="text"
                                className="custom-input"
                                name="first_name"
                                index={index}
                                onChange={(e) => {
                                  if (this?.state?.strataWIP) {
                                    this.changeOwnerInput(e, 'first_name', index)
                                  } else {
                                    this.changeOwnerInput(e, 'name', index)
                                  }
                                }}
                                value={this?.state?.strataWIP ? item?.first_name : item?.name}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 col-md-9">
                            <div className="form-group">
                              <label className="form__form-group-label">Address:</label>
                              <input
                                type="text"
                                className="custom-input"
                                name="oi_address1"
                                onChange={(e) => {
                                  if (this?.state?.strataWIP) {
                                    this.changeOwnerInput(e, 'oi_address1', index)
                                  } else {
                                    this.changeOwnerInput(e, 'address', index)
                                  }
                                }}
                                value={this?.state?.strataWIP ? item?.oi_address1 : item?.address}
                                disabled={true}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 col-md-6 col-lg-3">
                            <div className="form-group">
                              <label className="form__form-group-label">Contact Number:</label>
                              <input
                                type="text"
                                className="custom-input"
                                name="mobile_phone"
                                index={index}
                                onChange={(e) => {
                                  if (this?.state?.strataWIP) {
                                    this.changeOwnerInput(e, 'mobile_phone', index)
                                  } else {
                                    this.changeOwnerInput(e, 'phone', index)
                                  }
                                }}
                                value={this?.state?.strataWIP ? item?.mobile_phone : item?.phone}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-lg-3">
                            <div className="form-group">
                              <label className="form__form-group-label">Email:</label>
                              <input
                                type="text"
                                className="custom-input"
                                name="oi_email"
                                onChange={(e) => {
                                  if (this?.state?.strataWIP) {
                                    this.changeOwnerInput(e, 'oi_email', index)
                                  } else {
                                    this.changeOwnerInput(e, 'email', index)
                                  }
                                }}
                                value={this?.state?.strataWIP ? item?.oi_email : item?.email}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-9 col-lg-9">
                            <div className="form-group">
                              <label className="form__form-group-label">Bank Account:</label>
                              <textarea
                                onChange={this.changeInput}
                                value={ownerBank}
                                className="custom-input"
                                name="ownerBank"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
              </div>
            </div>
          </div>

          {/*----------------button Save/Cancel---------------*/}

          <div className="w-100 d-flex justify-content-end ">
            <button
              type="button"
              className="btn-group--icons btn btn-secondary"
              onClick={() => this.saveWIP()}
            >
              Save Changes
            </button>
            <button type="button" className="btn-gray m-0" onClick={() => this.cancel()}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Details)
