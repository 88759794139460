import { useEffect, useState } from 'react'

import { withRouter } from 'react-router'

import TaskBoard from './taskboard/index'
import { useTaskContext } from '@src/context/task-context'
import { TASK_BOARD_MAP_TO_URL } from '@src/utils'
import axios from 'axios'
import { useUserContext } from '@src/context/user-context'

interface IStatus {
  value: string
  label: string
}

interface IState {
  selectedTab: number
  addTaskModal: boolean
  id: string | null
  order: 'asc' | 'desc'
  orderBy: string
  selected: Map<any, any>
  taskList: any[] // You might want to define a more detailed type for the task objects
  page: number
  rowsPerPage: number
  errorModal: boolean
  errorMessage: string
  errorType: string
  st_id: string
  user: any // You might want to define a more detailed type for the user object
  accessErrorModal: boolean
  app_type: string
  filter_app_type: any[] // You might want to define a more detailed type for the filter objects
  salesList: any[] // Define a more detailed type for the sale objects
  recoveryList: any[] // Define a more detailed type for the recovery objects
  selectedTask: string
  isEdit: boolean
  mainType: string
  allStaff: any[] // Define a more detailed type for staff objects
  status: IStatus
  isCreateTaskModalOpen: boolean
  wip: any | null
}
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
const TaskList = ({ location }: { location: any }) => {
  const {user} = useUserContext()
  const { setSelectedCompanyState, setMode } = useTaskContext()

  const [state] = useState<IState>({
    wip: null,
    selectedTab: 2,
    addTaskModal: false,
    id: localStorage.getItem('contactsId'),
    isCreateTaskModalOpen: false,
    order: 'asc',
    orderBy: '',
    selected: new Map([]),
    taskList: [],
    page: 0,
    rowsPerPage: 5,
    errorModal: false,
    errorMessage: '',
    errorType: '',
    st_id: '',
    accessErrorModal: false,
    app_type: '',
    user,
    filter_app_type: [],
    salesList: [],
    recoveryList: [],
    selectedTask: '',
    isEdit: false,
    mainType: 'SALES',
    allStaff: [],
    status: { value: 'To Do', label: 'To Do' },
  })

  useEffect(() => {
    if (state) {
      setSelectedCompanyState(state)
    }
  }, [])

  useEffect(() => {
    if (location.pathname) {
      setMode(TASK_BOARD_MAP_TO_URL[location.pathname])
    }
  }, [location.pathname])

  return (
    <div>
      <TaskBoard />
    </div>
  )
}

export default withRouter(TaskList)
