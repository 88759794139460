import { formatDistance } from 'date-fns'
import { FC, useEffect, useState } from 'react'
import styles from './email-table.module.scss'
import { LastSyncDateProps } from '@containers/Communication/types/communication.type'

const LastSyncDate: FC<LastSyncDateProps> = ({ date }) => {
  const [currentDate, setCurrentDate] = useState<Date>(new Date())

  useEffect(() => {
    const ref = setInterval(() => {
      setCurrentDate(new Date())
    }, 1000)

    return () => {
      clearInterval(ref)
    }
  }, [])

  return (
    <span className={styles.syncText}>{`Synchronized ${formatDistance(
      date,
      currentDate,
    )} ago`}</span>
  )
}

export default LastSyncDate
