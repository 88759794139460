import Modal from '@components/modal'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import { FC } from 'react'
import {
  DebtorOther,
  EditDebtorOtherModalProps,
} from '@containers/Recovery/Wip/_modules/Debtors/types/debtor-associate-detail.type'
import { DEBTOR_CALL_RESULT_OPTIONS } from '@containers/Recovery/Wip/_modules/Debtors/const/debtor-associate-details.const'
import { useQueryClient } from '@tanstack/react-query'
import { getDebtorQueryKey } from '@containers/Recovery/Wip/_modules/Debtors/utils/debtor-query-key-factory.utils'
import { Debtor } from '@containers/Recovery/Wip/_modules/Debtors/types/debtor.type'
import EditDebtorOtherForm from '../../forms/debtor-other-form/edit-debtor-other-form.component'
import styles from './debtor-other-modal.module.scss'

const EditDebtorOtherModal: FC<EditDebtorOtherModalProps> = ({
  debtorOther,
  debtorId,
  ...modalProps
}) => {
  const queryClient = useQueryClient()
  const handleSuccessSubmit = (debtorOther: DebtorOther) => {
    queryClient.setQueryData<Debtor>(getDebtorQueryKey(debtorId), (prevData) =>
      prevData
        ? {
            ...prevData,
            other: prevData.other.map((other) =>
              other.id === debtorOther.id ? debtorOther : other,
            ),
          }
        : undefined,
    )
    modalProps.onClose()
  }
  return (
    <Modal
      className={styles.modalWrapper}
      {...modalProps}
      withBtns={false}
      classNameLayout={styles.layout}
    >
      <Header mb={4}>Edit Other</Header>
      <EditDebtorOtherForm
        onCancelClick={modalProps.onClose}
        onSuccessSubmit={handleSuccessSubmit}
        debtorOtherId={debtorOther.id}
        initialValues={{
          name: debtorOther.name,
          phone: debtorOther.phone,
          address: debtorOther.address,
          call_note: debtorOther.call_note,
          call_result:
            DEBTOR_CALL_RESULT_OPTIONS.find((option) => option.value === debtorOther.call_result) ||
            DEBTOR_CALL_RESULT_OPTIONS[0],
        }}
      />
    </Modal>
  )
}

export default EditDebtorOtherModal
