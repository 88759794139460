import { FC } from 'react'
import { RecoverableCostItemProps } from '../../types/recoverable-costs.type'
import { format } from 'date-fns'

const RecoverableCostItem: FC<RecoverableCostItemProps> = ({ recoverableCost }) => {
  const { amount, description, created_at } = recoverableCost
  return (
    <div className="d-flex" style={{ gap: '10px' }}>
      <div className="form-group">
        <label className="form__form-group-label">Description</label>
        <input
          type="text"
          className="custom-input"
          placeholder="Description"
          value={description}
          disabled
        />
      </div>
      <div className="form-group">
        <label className="form__form-group-label">Amount</label>
        <input type="text" className="custom-input" placeholder="Amount" value={amount} disabled />
      </div>
      <div className="form-group">
        <label className="form__form-group-label">Created At</label>
        <input
          type="text"
          className="custom-input"
          value={format(created_at, 'MM/dd/yyyy')}
          disabled
        />
      </div>
    </div>
  )
}

export default RecoverableCostItem
