import CurrencyFormInput from '@components/inputs/form-input/currency-form-input.component'
import FormInput from '@components/inputs/form-input/form-input.component'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { useForm } from 'react-hook-form'
import styles from './add-claim-detail-note-form.module.scss'
import { useCreateClaimDetailNoteMutation } from '../../../hooks/mutations/create-claim-detail-note.hook'
import { toast } from 'react-toastify'
import { parseStringToNumber } from '@containers/Recovery/Wip/utils/format-currency'
import { useUserContext } from '@src/context/user-context'
import { FC } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import { AddClaimDetailNoteFormProps, AddClaimDetailNoteFormValues } from '../../../types/claim-detail.type'

const AddClaimDetailNoteForm: FC<AddClaimDetailNoteFormProps> = ({ wipId, onSuccess }) => {
  const queryClient = useQueryClient()
  const { user } = useUserContext()
  const { mutateAsync, isLoading } = useCreateClaimDetailNoteMutation()
  const { control, handleSubmit, register } = useForm<AddClaimDetailNoteFormValues>()

  const onSubmit = async (data: AddClaimDetailNoteFormValues) => {
    const { total, description } = data
    try {
      await mutateAsync({
        data: {
          wip_id: wipId,
          total_amount: parseStringToNumber(total),
          description,
          staff_created_id: user!.id,
        },
      })
      queryClient.invalidateQueries([GLOBAL_QUERIES.CLAIM_DETAILS_NOTES.GET])
      onSuccess && onSuccess()
      toast.success('Note added')
    } catch (e) {
      console.error(e)
      toast.error('Failed to add note')
    }
  }

  return (
    <form className={styles.formWrapper} onSubmit={handleSubmit(onSubmit)}>
      <CurrencyFormInput control={control} name="total" label="Total" placeholder="$00.00" />
      <FormInput label="Description" {...register('description')} />
      <SubmitButton buttonType="submit" loading={isLoading}>
        Add Note
      </SubmitButton>
    </form>
  )
}

export default AddClaimDetailNoteForm
