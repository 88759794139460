import Modal from '@components/modal'
import React, { FC, useEffect } from 'react'
import {
  CreateWipTemplateForm,
  CreateWipTemplateModalProps,
  WipTemplateMode,
} from '../../types/wip-templates.type'
import styles from './wip-template-modal.module.scss'
import { useForm } from 'react-hook-form'
import LabelWrapper from '@components/label-wrapper'
import { WipTemplatesService } from '../../services/wip-templates.service'
import { toast } from 'react-toastify'
import { joiResolver } from '@hookform/resolvers/joi'
import { createWipTemplateSchema } from '../../schemas/create-wip-template.schema'
import { useQueryClient } from '@tanstack/react-query'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'

const wipTemplatesService = new WipTemplatesService()

export const CreateWipTemplateModal: FC<CreateWipTemplateModalProps> = ({ isOpen, onClose }) => {
  const queryClient = useQueryClient()
  const { control, handleSubmit, reset } = useForm<CreateWipTemplateForm>({
    defaultValues: { name: '' },
    resolver: joiResolver(createWipTemplateSchema),
  })

  const onSubmit = async (data: CreateWipTemplateForm) => {
    try {
      await wipTemplatesService.createTemplate({ name: data.name, mode: WipTemplateMode.RECOVERY })
      toast.success('Wip Template Created')
      queryClient.refetchQueries({ queryKey: [GLOBAL_QUERIES.WIP_TEMPLATE.GET] })
      onClose()
    } catch {
      toast.error('Something Went Wrong')
    }
  }

  useEffect(() => {
    if (!isOpen) {
      reset()
    }
  }, [isOpen])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      className={styles.modalWrapper}
    >
      <h3 className="fw-bold mb-3">Create Task Template</h3>
      <LabelWrapper className={styles.labelWrapper} control={control} name="name" label="Name">
        {({ onChange, value }) => {
          return (
            <input
              type="text"
              className="react-select"
              placeholder="Example: Base Wip Template"
              value={value as string}
              onChange={onChange}
            />
          )
        }}
      </LabelWrapper>
    </Modal>
  )
}
