export const TABLE_ROWS_AND_COLUMNS = [
  {
    label: 'Client',
    path: 'client',
  },
  {
    label: 'Property Address',
    path: 'address',
  },
  {
    label: 'Suburb',
    path: 'suburb',
  },
  {
    label: 'State',
    path: 'state',
  },
  {
    label: 'Postcode',
    path: 'postcode',
  },
  {
    label: 'Landlords',
    path: 'landlords',
  },
]
