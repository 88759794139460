import LabelWrapper from '@components/label-wrapper'
import Modal from '@components/modal'
import { useUserContext } from '@src/context/user-context'
import { REACT_APP_API_URL } from '@src/lib/global-variables'
import axios from 'axios'
import { FC, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import styles from './generic-send-sms-modal.module.scss'
import { useForm } from 'react-hook-form'
import Select from 'react-select'
import { joiResolver } from '@hookform/resolvers/joi'
import { genericSendSmsSchema } from './validation-schema'
import Switch from '@components/switch'
import { ShortMessageTo } from '@containers/Communication/types/sms.type'

interface GenericSendSmsModalProps {
  isOpen: boolean
  onClose: () => void
  sendToOther?: boolean
  senderOptions: { label: string; value: string }[]
  smsTemplates: { label: string; value: string }[]
  additionalValuesInRequeset: NonNullable<unknown>
  onSmsSend?: () => void
}

interface IForm {
  to: { label: string; value: string } | null | string
  template: { label: string; value: string } | undefined
  message: string
}

const GenericSendSmsModal: FC<GenericSendSmsModalProps> = ({
  isOpen,
  onClose,
  senderOptions,
  smsTemplates,
  sendToOther = false,
  additionalValuesInRequeset,
  onSmsSend,
}) => {
  const { user } = useUserContext()
  const [isAnotherPhone, setIsAnotherPhone] = useState(false)

  const {
    control,
    reset,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<IForm>({
    defaultValues: {
      message: '',
      to: sendToOther ? '' : null,
      template: undefined,
    },
    mode: 'all',
    resolver: joiResolver(genericSendSmsSchema),
  })

  const onSubmit = async (data: IForm) => {
    const { to, message } = data
    const dataToSend = {
      body: message,
      to: typeof to === 'string' ? to : to?.value,
      staff_id_created: user?.id || null,
      ...additionalValuesInRequeset,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      to_mode: !isAnotherPhone ? additionalValuesInRequeset?.to_mode :  ShortMessageTo.Other,
    }

    try {
      await axios.post(`${REACT_APP_API_URL}/short-messages`, dataToSend)
      onSmsSend && onSmsSend()
      toast.success(`Message sent to ${typeof to === 'string' ? to : to?.label}`)
    } catch (error) {
      toast.error('Something went wrong')
    } finally {
      onClose()
    }
  }

  const handleAnotherPhoneChange = () => {
    setIsAnotherPhone((prevState) => !prevState)
  }

  useEffect(() => {
    if (isAnotherPhone) {
      setValue('to', '')
      return
    }
    setValue('to', null)
  }, [isAnotherPhone])

  useEffect(() => {
    if (!isOpen) {
      setIsAnotherPhone(false)
      reset({}, { keepDirty: false, keepDefaultValues: true, keepValues: false })
    }
  }, [isOpen])

  useEffect(() => {
    const subscription = watch(({ template }, { name }) => {
      if (name === 'template' && template) {
        if (template?.value) {
          setValue('message', template?.value, { shouldValidate: true })
          return
        }
      }
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [watch])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      style={{ height: 500 }}
      saveBtnText="Send"
      canecelBtnText="Back"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <span className={styles.headerText}>Send SMS</span>

          {sendToOther ? (
            <div className="d-flex justify-content-end">
              <Switch value={isAnotherPhone} onChange={handleAnotherPhoneChange} />
              <span className="ml-2">Another Phone</span>
            </div>
          ) : null}

          {!isAnotherPhone ? (
            <LabelWrapper<IForm> control={control} name="to" label="To">
              {({ onChange, value }) => {
                return (
                  <div>
                    <Select options={senderOptions} value={value} onChange={onChange} />
                    {(errors as any)?.to?.value ? (
                      <p className={styles.error}>Invalid phone format</p>
                    ) : null}
                  </div>
                )
              }}
            </LabelWrapper>
          ) : null}

          {isAnotherPhone ? (
            <LabelWrapper<IForm> control={control} name="to" label="To">
              {({ onChange, value }) => {
                return (
                  <input
                    type="text"
                    className="react-select"
                    value={value as string}
                    onChange={onChange}
                  />
                )
              }}
            </LabelWrapper>
          ) : null}

          <LabelWrapper<IForm> control={control} name="template" label="Template">
            {({ onChange, value }) => {
              return <Select options={smsTemplates} value={value} onChange={onChange} />
            }}
          </LabelWrapper>
          <LabelWrapper<IForm> control={control} name="message" label="Message">
            {({ onChange, value }) => {
              return (
                <div>
                  <textarea value={value as string} onChange={onChange} />
                </div>
              )
            }}
          </LabelWrapper>
        </div>
      </div>
    </Modal>
  )
}

export default GenericSendSmsModal
