/* eslint-disable react/display-name */
import React from 'react'
import styles from './switch.module.scss'

import cls from 'classnames'

interface SwitchProps {
  value: string | number | boolean | Date | undefined
  onChange: (value: boolean) => void
}

const Switch = React.forwardRef<HTMLDivElement, SwitchProps>(
  ({ onChange = () => value, value, ...rest }, ref) => {
    if (typeof value !== 'boolean') return null

    return (
      <div
        {...rest}
        ref={ref}
        onClick={() => onChange(!value)}
        className={cls(styles.wrapper, { [styles.left]: value })}
      />
    )
  },
)

export default Switch
