import { GLOBAL_QUERIES } from "@src/lib/global-queries"
import { Params } from "@src/types/service.type"
import { useMutation, useQuery } from "@tanstack/react-query"
import { ProtectAndCollectFormService } from "../services/protect-and-collect-form.service"
import { CreateWipData } from "../types/protect-and-collect-form.type"

export const useGetProtectAndCollectForms = (params: Params = {}) => {

  const getProtectAndCollectForms = (params?: Params) => {
    const protectAndCollectFormService = new ProtectAndCollectFormService()
    return protectAndCollectFormService.getProtectAndCollectForms(params)
  }

  const query = useQuery({
    queryKey: [GLOBAL_QUERIES.PROTECT_AND_COLLECT.GET, params],
    queryFn: () => getProtectAndCollectForms(params),
    keepPreviousData: true,
  })

  return query;
}

export const useDeleteProtectAndCollectFormMutation = () => {
  const deleteProtectAndCollectForm = async (id: number) => {
    const protectAndCollectFormService = new ProtectAndCollectFormService()
    return protectAndCollectFormService.deleteProtectAndCollectForm(id)
  }

  const mutation = useMutation(deleteProtectAndCollectForm)

  return mutation
}

export const useCreateWipMutation = () => {
  const createWip = async (data: CreateWipData) => {
    const protectAndCollectFormService = new ProtectAndCollectFormService()
    return protectAndCollectFormService.createWip(data)
  }

  const mutation = useMutation(createWip)

  return mutation
}