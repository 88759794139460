import React from 'react';
import {Link} from 'react-router-dom';
import Services from '../../Services.js';
import {withRouter} from 'react-router';
import queryString from 'query-string';

const logoImg = `${process.env.PUBLIC_URL}/image/logo.png`;

const loginRightImg = `${process.env.PUBLIC_URL}/image/login-right-img.jpg`;

class ResetPassword extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      password: '',
      cpassword: '',
      token: ''
    };

    this.services = new Services();

    this.showPassword = this.showPassword.bind(this);
    this.sendLink = this.sendLink.bind(this);
    this.changeInput = this.changeInput.bind(this);
  }

  componentDidMount() {
    let url = this.props.location.search;
    let params = queryString.parse(url);
    this.setState({
      token: params.token
    })
  }

  showPassword(e) {
    e.preventDefault();
    this.setState(prevState => ({showPassword: !prevState.showPassword}));
  }

  sendLink = () => {
    let url = "admin/Resetpassword";
    if (this.state.cpassword !== this.state.password) {
      this.services.errorToast("Password and confirm password is not match");
      return;
    }
    const data = {
      password: this.state.password,
      token: this.state.token
    };

    this.services.login(data, url, (res) => {
      if (res.status === 200) {
        // localStorage.setItem('token',res.data.data.accessToken);
        // localStorage.setItem('user',JSON.stringify(res.data.data));
        this.services.successToast('Link send to your email');
        this.props.history.push('/login');
      } else {
        this.services.errorToast(res.message);
      }
    })

  }

  changeInput(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  render() {
    return (

      <div className="container-scroller">
        <div className="login_wrapper">
          <div className="login_wrapper_inner">
            <div className="login_left">
              <div className="login_right_banner">
                <img src={loginRightImg} alt="login"/>
              </div>
            </div>
            <div className="login_right">

              {/* <div className="login_logo_div">
                <a className="" href="index.html"><img src={logoImg} alt="logo" /></a>
              </div> */}
              <div className="login_form_wrap">
                <div className="login_form_inner">
                  <div className="login_logo_div ml-0 pb-4">
                    <a className="" href="index.html"><img src={logoImg} alt="logo"/></a>
                  </div>
                  <div className="login_head">
                    <h1>Sign in to BMS</h1>
                    <p className="mb-md-0 fw-500">Please enter your email to proceed.</p>
                  </div>
                  <form>
                    <div className="form-group">
                      <label>Password</label>
                      <input className="form-control" placeholder="" name="password" id="password" type="password"
                             onChange={this.changeInput}/>
                    </div>
                    <div className="form-group">
                      <label>Confirm password</label>
                      <input className="form-control" placeholder="" name="cpassword" id="cpassword" type="password"
                             onChange={this.changeInput}/>
                    </div>


                    <Link className="btn btn-secondary" onClick={this.sendLink}>Change Password</Link>
                    {/* <div className="login_bottom_content text-center">Dont’ have an account?  */}
                    {/* <Link className="c_orange" to="/register"> Sign up</Link></div> */}

                  </form>
                </div>
              </div>

              <div className="login-footer">
                <div className="copyright">
                  <p>Copyright ©2020 Barclay MIS. All rights reserved.</p>
                </div>
                <div className="privacy-policy">
                  <a href="http://13.211.158.213/BarclayMIS/?page=disclaimer">Disclaimer</a>
                  <a href="http://13.211.158.213/BarclayMIS/?page=privacy">Privacy Policy</a>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>


    );
  }
}

export default withRouter(ResetPassword)