import TableCell from '@components/table/table-cell-component'
import TableRow from '@components/table/table-row.component'
import Table from '@components/table/table.component'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { Property } from '@containers/Sales/Properties/types/property.type'
import { format, isDate } from 'date-fns'
import { FC, useEffect, useState } from 'react'
import styles from './client-properties-table.module.scss'
import FormCheckbox from '@components/inputs/form-checkbox/form-checkbox.component'

interface ClientPropertiesTableProps {
  clientId: number
  properties: Property[]
  page?: number
  limit?: number
  totalCount?: number
  loading?: boolean
  notFound?: boolean
  selected?: Property[]
  onSelectedChange?: (properties: Property[]) => void
  onPageChange?: (page: number) => void
}

interface ClientPropertiesRowProps {
  clientId: number
  property: Property
  selected?: boolean
  onRowSelect: (property: Property, value) => void
}

const ClientPropertiesRow: FC<ClientPropertiesRowProps> = ({
  property,
  clientId,
  selected = false,
  onRowSelect,
}) => {
  return (
    <TableRow>
      <TableCell position="center" width={54}>
        <FormCheckbox checked={selected} onChange={() => onRowSelect(property, !selected)} />
      </TableCell>
      {/* PID# */}
      <TableCell position="eclipsed" width={70}>
        {property.id}
      </TableCell>
      {/* LANDLORD */}
      <TableCell className={styles.landlordCell} position="eclipsed">
        {property.landlord?.companyName || 'Not Found'}
      </TableCell>
      {/* PROPERTY ADDRESS */}
      <TableCell position="eclipsed">{property.fullAddress || 'Not Found'}</TableCell>
      {/* STATE */}
      <TableCell position="center" width={65}>
        {property.state}
      </TableCell>
      {/* POSTCODE */}
      <TableCell position="eclipsed" width={100}>
        {property.postcode}
      </TableCell>
      {/* COVER */}
      <TableCell position="eclipsed">{property.invoiceType || 'Not Found'}</TableCell>
      {/* COVER EXPIRES */}
      <TableCell position="eclipsed">
        {isDate(property.coveredTo) ? format(property.coveredTo, 'MM/dd/yyyy') : 'Invalid Date'}
      </TableCell>
      {/* DUE */}
      <TableCell position="center" width={50}>
        {'-'}
      </TableCell>
      {/* ACTION */}
      <TableCell position="eclipsed">
        <SubmitButton type="link" url={`/client/${clientId}/property/${property.id}`}>
          Edit
        </SubmitButton>
      </TableCell>
    </TableRow>
  )
}

const ClientPropertiesTable: FC<ClientPropertiesTableProps> = ({
  clientId,
  properties,
  selected,
  page = 1,
  totalCount = 0,
  limit = 10,
  loading = false,
  notFound = false,
  onPageChange,
  onSelectedChange,
}) => {
  const [innerSelected, setInnerSelected] = useState<Property[]>([])
  const handleRowSelect = (property: Property, value: boolean) => {
    const newSelected = value
      ? [...innerSelected, property]
      : innerSelected.filter((p) => p.id !== property.id)
    setInnerSelected(newSelected)
    onSelectedChange && onSelectedChange(newSelected)
  }

  useEffect(() => {
    if (!selected) return
    setInnerSelected(selected)
  }, [selected])

  return (
    <Table
      limit={limit}
      totalCount={totalCount}
      headers={[
        '',
        'PID#',
        'LANDLORD',
        'PROPERTY ADDRESS',
        'STATE',
        'POSTCODE',
        'COVER',
        'COVER EXPIRES',
        'DUE',
        'ACTION',
      ]}
      page={page}
      bodyHeight={limit * 54}
      loading={loading}
      notFound={notFound}
      onPageChange={onPageChange}
    >
      {properties.map((property) => (
        <ClientPropertiesRow
          selected={innerSelected.some((p) => p.id === property.id)}
          onRowSelect={handleRowSelect}
          key={property.id}
          clientId={clientId}
          property={property}
        />
      ))}
    </Table>
  )
}

export default ClientPropertiesTable
