export const emailModalStyles = {
  container: {
    width: '100%',
    height: '100%',
    maxHeight: '80vh',
    overflowY: 'scroll',
    overflowX: 'hidden',
    maxWidth: '1028px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minHeight: '300px',
    bgcolor: 'white',
    padding: '30px 10px',
    borderRadius: '15px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '15px',
  },
  title: {
    fontFamily: 'Source Sans Pro',
    fontSize: '22px',
    color: '#000',
    fontWeight: '700',
  },
};

export const emailModalForm = {
  form: {
    padding: '0 15px',
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
  },
  inputWrapper: {
    marginTop: '50px',
  },
  scheduledEmailWrapper: {
    width: '100%',
    marginTop: '15px',
  },
  buttonsWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '20px',
    marginTop: '30px',
  },
  emails: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
};
