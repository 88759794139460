import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import RecoveryForm from './componentsCorporateForm/index';
import { Col, Container, Row } from 'reactstrap';

import { RTLProps } from '../../../shared/prop-types/ReducerProps';
import { submitURF } from './serviceCorporateRecoveryForm';
import PropTypes from 'prop-types';

const AddCorporateRecoveryForm = ({ t, rtl }) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const submitFormHandler = async (data) => {
    setLoading(true);
    try {
      await submitURF(data)
        .then(() => {
          setLoading(false);
          toast.success('Submited Successfully');
          history.push('/recoveries/forms/strata-body');
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.response?.data?.message?.[0]);
        });
    } catch (error) {
      toast.error('Something went wrong!');
    }
  };

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">
            Add Strata Body Corporate Recovery Form
          </h3>
        </Col>
      </Row>
      <Row>
        <RecoveryForm
          onSubmit={submitFormHandler}
          dir={rtl.direction}
          loading={loading}
        />
      </Row>
    </Container>
  );
};

RecoveryForm.propTypes = {
  t: PropTypes.func.isRequired,
  rtl: RTLProps.isRequired,
};

export default connect((state) => ({
  rtl: state.rtl,
}))(withTranslation('common')(AddCorporateRecoveryForm));
