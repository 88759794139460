import axios from "axios";
import { CreatePaymentTrackingData, PaymentTracking } from "../types/payment-tracking.type";
import { REACT_APP_API_URL } from "@src/lib/global-variables";
import { Params } from "@src/types/service.type";
import { DeepPartial } from "react-hook-form";

export class PaymentTrackingService {

  async createPaymentTracking(data: CreatePaymentTrackingData): Promise<PaymentTracking> {
    const res = await axios.post<PaymentTracking>(`${REACT_APP_API_URL}/pay-trackings`, data)
    return res.data;
  }

  async updatePaymentTracking(id: number, data: DeepPartial<PaymentTracking>, params?: Params): Promise<PaymentTracking> {
    const res = await axios.patch<PaymentTracking>(`${REACT_APP_API_URL}/pay-trackings/${id}`, data, { params })
    return res.data;
  }
}