import Section from '@components/section/section.component'
import CombinedNotesTableWipFilter from '@containers/Communication/components/tables/combined-notes-table/combined-notes-table-with-filter.component'
import { FC } from 'react'
import { WipNotesSectionProps } from '../../../types/wip-communication.type'

const WipNotesSection: FC<WipNotesSectionProps> = ({ wipId }) => {
  return (
    <Section padding={false}>
      <CombinedNotesTableWipFilter params={{ wip_id: wipId }} />
    </Section>
  )
}

export default WipNotesSection
