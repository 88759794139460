import { FC } from 'react'
import { DebtorLegal } from '../../../types/debtor-legal.type'
import SubSection from '@containers/Recovery/Wip/components/UI/sub-section/sub-section.component'
import { SubHeader, Text } from '@containers/Recovery/Wip/components/UI/text/text.component'
import TrashIcon from '@src/icons/trash'
import styles from './debtor-legal-plaintiff-list.module.scss'
import EditIcon from '@src/icons/edit'
import { useQueryClient } from '@tanstack/react-query'
import { getDebtorLegalByIdQueryKey } from '../../../utils/legal-query-key-factory.utils'
import { useDeleteDebtorLegalPlaintiffMutation } from '../../../hooks/mutations/crud-debtor-legal-plaintiff.hook'
import { toast } from 'react-toastify'
import { DebtorLegalPlaintiffListItemProps } from '../../../types/debtor-plaintiff.type'

const DebtorLegalPlaintiffListItem: FC<DebtorLegalPlaintiffListItemProps> = ({
  debtorLegalPlaintiff,
  onEditClick,
}) => {
  const { mutateAsync } = useDeleteDebtorLegalPlaintiffMutation()
  const queryClient = useQueryClient()

  const handleDeleteClick = async () => {
    try {
      await mutateAsync({ id: debtorLegalPlaintiff.id })
      queryClient.setQueryData<DebtorLegal>(
        getDebtorLegalByIdQueryKey(debtorLegalPlaintiff.debtorLegalId),
        (prevData) =>
          prevData
            ? {
                ...prevData,
                plaintiffs: prevData.plaintiffs.filter((p) => p.id !== debtorLegalPlaintiff.id),
              }
            : undefined,
      )
    } catch (error) {
      toast.error('Failed to delete plaintiff')
      console.error(error)
    }
  }

  return (
    <SubSection className={styles.debtorLegalPlaintiffListItemWrapper} p={4}>
      <div className={styles.debtorLegalPlaintiffListItemContent}>
        <div className={styles.row}>
          <SubHeader>Plaintiff Name:</SubHeader>
          <Text>{debtorLegalPlaintiff.plaintiffName}</Text>
        </div>
        <div className={styles.row}>
          <SubHeader>Plaintiff Address:</SubHeader>
          <Text>{debtorLegalPlaintiff.plaintiffAddress}</Text>
        </div>
      </div>
      <div className={styles.row}>
        <button className={styles.actionButton} onClick={() => onEditClick(debtorLegalPlaintiff)}>
          <EditIcon />
        </button>
        <button onClick={handleDeleteClick} className={styles.actionButton}>
          <TrashIcon />
        </button>
      </div>
    </SubSection>
  )
}

export default DebtorLegalPlaintiffListItem
