import { useMutation } from "@tanstack/react-query"
import { CreateDebtorRelationData, DebtorRelation } from "../../types/debtor-associate-detail.type"
import { DebtorRelationService } from "../../services/debtor-relation.service"
import { DeepPartial } from "react-hook-form"

export const useCreateDebtorRelationMutation = () => {

  const createDebtorRelation = (data: CreateDebtorRelationData) => {
    const debtorRelationService = new DebtorRelationService()
    return debtorRelationService.createDebtorRelation(data)
  }

  const mutation = useMutation(createDebtorRelation)

  return mutation
}


export const useUpdateDebtorRelationMutation = () => {

  const updateDebtorRelation = (id: number, data: DeepPartial<DebtorRelation>) => {
    const debtorRelationService = new DebtorRelationService()
    return debtorRelationService.updateDebtorRelation(id, data)
  }

  const mutation = useMutation({ mutationFn: ({ id, data }: { id: number, data: DeepPartial<DebtorRelation> }) => updateDebtorRelation(id, data) })

  return mutation;
}