import { deleteInvoiceTemplateActionTypes } from '../actionTypes';
import { deleteInvoiceTemplateService } from '../service';

export const deleteInvoiceTemplate = (id) => async (dispatch) => {
  dispatch({ type: deleteInvoiceTemplateActionTypes.DELETE_INVOICE_TEMPLATE_LOADING });
  try {
    const res = await deleteInvoiceTemplateService(id);

    dispatch({
      type: deleteInvoiceTemplateActionTypes.DELETE_INVOICE_TEMPLATE_SUCCESS,
      payload: res,
    });

    return res;
  } catch (error) {
    dispatch({
      type: deleteInvoiceTemplateActionTypes.DELETE_INVOICE_TEMPLATE_ERROR,
      payload: error,
    });

    throw error;
  }
};
