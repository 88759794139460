import Table from '@components/table/table.component'
import { DebtorWorkPlaceTableProps } from '@containers/Recovery/Wip/_modules/Debtors/types/debtor-associate-detail.type'
import { FC } from 'react'
import DebtorWorkPlaceTableItem from './debtor-work-place-table-item.component'

const DebtorWorkPlaceTable: FC<DebtorWorkPlaceTableProps> = ({ workPlaces, debtorId, onItemClick }) => {
  return (
    <Table
      headers={['NAME', 'PHONE', 'ADDRESS', 'REMOVE']}
      paginated={false}
      bodyHeight={54 * workPlaces.length}
    >
      {workPlaces.map((workPlace) => (
        <DebtorWorkPlaceTableItem key={workPlace.id} workPlace={workPlace} debtorId={debtorId} onItemClick={onItemClick} />
      ))}
    </Table>
  )
}

export default DebtorWorkPlaceTable
