import { getEmailTemplateByIdActionTypes } from '../actionTypes';

export const getEmailTemplateByIdReducer = (
  state = { data: [], loading: false, error: '' },
  action,
) => {
  const { type, payload } = action;

  switch (type) {
    case getEmailTemplateByIdActionTypes.GET_EMAIL_TEMPLATE_BY_ID_LOADING: {
      return { ...state, loading: true };
    }
    case getEmailTemplateByIdActionTypes.GET_EMAIL_TEMPLATE_BY_ID_SUCCESS: {
      return { ...state, loading: false, data: payload };
    }
    case getEmailTemplateByIdActionTypes.GET_EMAIL_TEMPLATE_BY_ID_ERROR: {
      return { ...state, loading: false, error: payload };
    }
    default:
      return state;
  }
};
