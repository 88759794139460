import { SpeedDialOption, SpeedDialSelectOption } from "../types/speed-dial.type";
import CheckListIcon from "../components/icons/check-list";
import PhoneIcon from "../components/icons/phone";
import EmailIcon from "../components/icons/email";
import SmsIcon from "../components/icons/sms";
import CalendarIcon from "../components/icons/calendar";

export const SPEED_DIAL_OPTIONS: SpeedDialOption[] = [
  {
    label: 'Payment Tracking',
    value: SpeedDialSelectOption.PaymentTracking,
    icon: CheckListIcon
  },
  {
    label: 'Send Email',
    value: SpeedDialSelectOption.SendEmail,
    icon: EmailIcon
  },
  {
    label: 'Make Call',
    value: SpeedDialSelectOption.MakeCall,
    icon: PhoneIcon
  },
  {
    label: 'Send SMS',
    value: SpeedDialSelectOption.SendSms,
    icon: SmsIcon
  },
  {
    label: 'Send Invitation',
    value: SpeedDialSelectOption.SendInvitation,
    icon: CalendarIcon
  }
]