import { FC } from 'react'
import CourtInterestRateCalculatorForm from './court-interest-rate-calculator-form.component'
import {
  CourtInterestRateCalculatorFormValues,
  CreateCourtInterestRateCalculatorFormProps,
} from '../../../../types/interest-rate.type'
import { useCreateCourtInterestCalculatorMutation } from '../../../../hooks/crud-court-interest-calculator.hook'
import { toast } from 'react-toastify'
import { CALCULATOR_TYPE_OPTIONS } from '../../../../const/interest-rate.const'

const CreateCourtInterestRateCalculatorForm: FC<CreateCourtInterestRateCalculatorFormProps> = ({
  wipId,
  state,
  courtInterestRatesOptions,
  onSuccessSubmit,
  onCalculateTypeChange,
  onCancelClick,
}) => {
  const { mutateAsync, isLoading } = useCreateCourtInterestCalculatorMutation()
  const handleSubmit = async (data: CourtInterestRateCalculatorFormValues) => {
    console.log(data)
    try {
      const courtInterestCalculator = await mutateAsync({
        rate: Number(data.interestRate) / 100,
        wipId: wipId,
      })
      toast.success('Court interest calculator created successfully')
      onSuccessSubmit(courtInterestCalculator)
    } catch (e) {
      toast.error('Failed to create court interest calculator')
      console.log(e)
    }
  }

  const getInterestRate = (state: string) => {
    const foundOption = courtInterestRatesOptions.find((option) => option.label === state)
    if (!foundOption) {
      return ''
    }
    return String(Number(foundOption.value.split('-')[0]) * 100)
  }

  return (
    <CourtInterestRateCalculatorForm
      loading={isLoading}
      initialValues={{
        calculateType: CALCULATOR_TYPE_OPTIONS[0],
        state: courtInterestRatesOptions.find((option) => option.label === state) || null,
        interestRate: getInterestRate(state),
      }}
      buttonText="Create Rate"
      courtInterestRatesOptions={courtInterestRatesOptions}
      onSubmit={handleSubmit}
      onCalculateTypeChange={onCalculateTypeChange}
      onCancelClick={onCancelClick}
    />
  )
}

export default CreateCourtInterestRateCalculatorForm
