import React, { FC, useEffect, useState } from 'react'
import { Button, ButtonGroup } from 'reactstrap'
import TableCell from '@material-ui/core/TableCell'
import { useUserContext } from '@src/context/user-context'
import TableRow from '@material-ui/core/TableRow'
import { ContactItemProps } from '../types/contact-list.type'
import { useHistory } from 'react-router'
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import CompaniesService from '../../../../../services/CompaniesService'
import { useQueryClient } from '@tanstack/react-query'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'

const companyService = new CompaniesService()

const ContactItem: FC<ContactItemProps> = ({ isSelected, contact, openModal }) => {
  const history = useHistory()
  const { user } = useUserContext()
  const queryClient = useQueryClient();
  const { id, contacts, name, client_code,assigned_to_favorite,  status } = contact
  const companyName = { label: 'test' }
  const isInFavoriteInitalValue = assigned_to_favorite.find((assigned_user) =>assigned_user.id === user!.id)


  const handleClick = (event, companyId) => {
    event.stopPropagation()
    localStorage.setItem('contactsId', companyId)
    localStorage.setItem('client_Id', companyId)
    history.push(`/contacts/${companyId}`)
  }

  const addToFavorite = async (event) => {
    event.stopPropagation()

    const params = {
      id: contact.id,
      assigned_to_favorite: [...assigned_to_favorite,{id: user?.id}]
    }
    await companyService.editCompanies(params)
    queryClient.invalidateQueries({queryKey: [GLOBAL_QUERIES.COMPANIES.GET]});
  };
  
  const removeFromFavorite = async (event) => {
    event.stopPropagation()
  
    const params = {
      id: contact.id,
      assigned_to_favorite: assigned_to_favorite.filter((assigned_user) => assigned_user.id !== user!.id)
    }
    await companyService.editCompanies(params)
    queryClient.invalidateQueries({queryKey: [GLOBAL_QUERIES.COMPANIES.GET]});
  }

  return (
    <TableRow
      className="material-table__row"
      role="checkbox"
      aria-checked={isSelected}
      tabIndex={-1}
      selected={isSelected}
    >
      <TableCell
        className="material-table__cell material-table__cell-right"
        onClick={(e) => handleClick(e, id)}
      >
        {client_code}
      </TableCell>
      <TableCell
        className="material-table__cell material-table__cell-right"
        onClick={(e) => handleClick(e, id)}
      >
        {name}
      </TableCell>
      <TableCell
        className="material-table__cell material-table__cell-right"
        onClick={(e) => handleClick(e, id)}
      >
        {contacts && contacts.map((e) => <p key={e.id}>{e.comm_email}</p>)}
      </TableCell>
      <TableCell
        className="material-table__cell material-table__cell-right"
        onClick={(e) => handleClick(e, id)}
      >
        {companyName && companyName.label ? companyName.label : ''}
      </TableCell>
      <TableCell
        className="material-table__cell material-table__cell-right"
        onClick={(e) => handleClick(e, id)}
      >
        {status}
      </TableCell>
      <TableCell
        className="material-table__cell material-table__cell-right gap-3"
        style={{ zIndex: 10000 }}
        onClick={(e) => user?.access_level !== 'SUPER' && handleClick(e, id)}
      >
        {user?.access_level !== 'SUPER' && (
          <>
            <ButtonGroup className="btn-group--icons" dir="ltr">
              <Button outline id="Delete" onClick={(e) => openModal(e, contact)}>
                <span className="lnr lnr-trash" />
              </Button>
            </ButtonGroup>
          </>
        )}
        <ButtonGroup className="btn-group--icons ml-3" dir="ltr">  
          <Button outline id="Favorite" onClick={isInFavoriteInitalValue ? removeFromFavorite : addToFavorite}>
              {isInFavoriteInitalValue ? <FavoriteIcon/> : <FavoriteBorderIcon/> } 
          </Button>
        </ButtonGroup>
      </TableCell>
    </TableRow>
  )
}

export default ContactItem
