import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { useForm } from 'react-hook-form'
import styles from './total-claim-amount-form.module.scss'
import { FC, useEffect } from 'react'
import CurrencyFormInput from '@components/inputs/form-input/currency-form-input.component'
import {
  TotalClaimAmountFormInitialValues,
  TotalClaimAmountFormProps,
  TotalClaimAmountFormValues,
} from '../../../types/claim.type'
import { formatCurrency, parseStringToNumber } from '../../../../../../utils/format-currency'
import { TOTAL_CLAIM_AMOUNT_FORM_DEFAULT_VALUES } from '../../../const/claim.const'
import EditIcon from '@src/icons/edit'
import SubSection from '@containers/Recovery/Wip/components/UI/sub-section/sub-section.component'
import { useModal } from '@src/hooks/useModal'
import CourtInterestRateTableModal from '../../modals/court-interest-rate-table-modal/court-interest-rate-table-modal.component'
import { useGetCourtInterestRates } from '../../../hooks/get-court-interest-rates.hook'
import { SubHeader } from '@containers/Recovery/Wip/components/UI/text/text.component'
import cls from 'classnames'

const TotalClaimAmountForm: FC<TotalClaimAmountFormProps> = ({
  initialValues,
  onSubmit,
  onEditInterestRateClick,
  loading = false,
}) => {
  const { data, isLoading } = useGetCourtInterestRates()
  const [isOpen, handleOpen, handleClose] = useModal(false)
  const transformInitialValues = (
    initialValues: TotalClaimAmountFormInitialValues,
  ): TotalClaimAmountFormValues => {
    return {
      interestRate: formatCurrency(initialValues.interestRate),
      claimTotalAmount: formatCurrency(initialValues.claimTotalAmount),
      recoverableTotalAmount: formatCurrency(initialValues.recoverableTotalAmount),
      unenforceableTotalAmount: formatCurrency(initialValues.unenforceableTotalAmount),
      totalAmount: formatCurrency(initialValues.totalAmount),
      totalAmountRecoverable: formatCurrency(initialValues.totalAmountRecoverable),
    }
  }

  const { control, reset, handleSubmit } = useForm<TotalClaimAmountFormValues>({
    defaultValues: initialValues
      ? transformInitialValues(initialValues)
      : TOTAL_CLAIM_AMOUNT_FORM_DEFAULT_VALUES,
  })

  const innerOnSubmit = (data: TotalClaimAmountFormValues) => {
    onSubmit({
      recoverableTotalAmount: Math.floor(parseStringToNumber(data.recoverableTotalAmount)),
      claimTotalAmount: parseStringToNumber(data.claimTotalAmount),
      unenforceableTotalAmount: Math.floor(parseStringToNumber(data.unenforceableTotalAmount)),
      totalAmount: Math.floor(parseStringToNumber(data.totalAmount)),
      interestRate: Math.floor(parseStringToNumber(data.interestRate)),
      totalAmountRecoverable: Math.floor(parseStringToNumber(data.totalAmountRecoverable)),
    })
  }

  useEffect(() => {
    if (!initialValues) {
      return
    }

    reset(transformInitialValues(initialValues))
  }, [initialValues])

  return (
    <form className={styles.formWrapper} onSubmit={handleSubmit(innerOnSubmit)}>
      <div className={styles.row}>
        <CurrencyFormInput
          control={control}
          name="claimTotalAmount"
          label="Claim Amount"
          inputStyle="outlined"
          placeholder="$00.00"
        />
        <SubmitButton buttonType="submit" size="large" loading={loading}>
          Change Amount
        </SubmitButton>
      </div>
      <SubSection className={styles.row} p={4}>
        <CurrencyFormInput
          control={control}
          disabled
          name="interestRate"
          label="Interest Calculated"
          placeholder="$00.00"
        />
        <button className={styles.editButton} type="button" onClick={onEditInterestRateClick}>
          <EditIcon />
        </button>
      </SubSection>
      <SubSection p={4}>
        <CurrencyFormInput
          control={control}
          disabled
          name="recoverableTotalAmount"
          label="Recoverable Cost"
          className={styles.recoverableCostInput}
          placeholder="$00.00"
        />
      </SubSection>
      <SubSection p={4}>
        <CurrencyFormInput
          control={control}
          disabled
          name="unenforceableTotalAmount"
          label="Un-enforceable Cost"
          placeholder="$00.00"
        />
      </SubSection>
      <SubSection p={4}>
        <div className={cls(styles.labelWrapper, styles.totalAmountRecoverableLabel)}>
          <SubHeader fontWeight="semibold">Recovery Total Amount</SubHeader>
        </div>
        <CurrencyFormInput
          className={styles.recoverableCostInput}
          control={control}
          disabled
          name="totalAmountRecoverable"
          placeholder="$00.00"
        />
      </SubSection>
      <SubSection p={4}>
        <div className={cls(styles.labelWrapper, styles.totalAmountLabel)}>
          <SubHeader fontWeight="semibold">Total Amount</SubHeader>
          <button className={styles.openTableButton} type="button" onClick={handleOpen}>
            Cost Table?
          </button>
        </div>
        <CurrencyFormInput control={control} disabled name="totalAmount" placeholder="$00.00" />
      </SubSection>
      <CourtInterestRateTableModal
        courtInterestRates={data?.data || []}
        isOpen={isOpen}
        onClose={handleClose}
        loading={isLoading}
      />
    </form>
  )
}

export default TotalClaimAmountForm
