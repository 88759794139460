import React from 'react';
import { Button, ButtonToolbar, Card, CardBody, Col, Container, Modal, Row } from 'reactstrap';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Services from '../../../../shared/Services.js';
import { withRouter } from 'react-router';
import SimpleReactValidator from 'simple-react-validator';
import ModalComponent from '../../../../shared/components/ModalCustom';
import WIPService from '../../../../services/WIPService';
import { TailSpin } from 'react-loader-spinner';

class WIPCostsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      text: '',
      amount: '',
      dataname: '',
      datatext: '',
      dataamount: '',
      editName: '',
      editText: '',
      editAmount: '',
      order: 'asc',
      orderBy: '',
      selected: new Map([]),
      data: [],
      WIPData: [],
      page: 0,
      rowsPerPage: 5,
      errorModal: false,
      errorMessage: '',
      errorType: '',
      st_id: '',
      item: '',
      listId: '',
      isEditModal: false,
      accessErrorModal: false,
      stateId: '',
      loader: false,
    };

    this.wip = new WIPService();
    this.services = new Services();
    this.changeInput = this.changeInput.bind(this);

    this.addWip = this.addWip.bind(this);
    this.editWIP = this.editWIP.bind(this);
    this.clearValue = this.clearValue.bind(this);
    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    this.clearValue();
    this.getWIP();
  }

  clearValue = () => {
    this.setState({
      name: '',
      text: '',
      amount: '',
      errorNameId: '',
      errorAmountId: '',
      errorTextId: '',
    });
    if (document.getElementById(this.state.stateId.replace(/.$/, 'A')))
      document.getElementById(this.state.stateId.replace(/.$/, 'A')).value = '';
    if (document.getElementById(this.state.stateId.replace(/.$/, 'B')))
      document.getElementById(this.state.stateId.replace(/.$/, 'B')).value = '';
    if (document.getElementById(this.state.stateId.replace(/.$/, 'C')))
      document.getElementById(this.state.stateId.replace(/.$/, 'C')).value = '';
  };

  getWIP() {
    this.setState({ loader: true });
    const data = {
      join: 'list'
    };
    this.wip.getWIPCost(data).then((res) => {
      if (res.status === 200) {
        const getData = res.data.data;
        console.log('data', getData)
        getData.map(rl => {
          rl.list.map((val) => {
              val.id = Math.floor(Math.random() * (1 + 100)) + 1;
            },
          );
        });
        let sortData = getData.sort(function(a, b) {
          return a.id - b.id;
        });
        this.setState({ WIPData: sortData });
        this.setState({ loader: false });
      } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
        this.setState({ accessErrorModal: true });
        this.setState({ loader: false });
      }
    });
  }

  onCloseEditModal = () => {
    this.setState({ isEditModal: false });
  };

  addWip = (e, id) => {
    if (document.getElementById(id + 'A').value === '') {
      this.validator.fields.name = false;
      this.setState({ errorNameId: id + 'A' });
    } else {
      this.validator.fields.name = true;
    }
    if (document.getElementById(id + 'B').value === '') {
      this.validator.fields.text = false;
      this.setState({ errorTextId: id + 'B' });
    } else {
      this.validator.fields.text = true;
    }
    if (document.getElementById(id + 'C').value === '' || !/^[0-9]+$/.test(document.getElementById(id + 'C').value)) {
      this.validator.fields.amount = false;
      this.setState({ errorAmountId: id + 'C' });
    } else {
      this.validator.fields.amount = true;
    }
    const addCost = this.state.WIPData.find(e => e.id === id);
    const cost = {
      name: this.state.name,
      text: this.state.text,
      amount: this.state.amount,
    };
    const data = {
      id: id,
      list: [...addCost.list, cost],
    };
    if (this.validator.allValid()) {
      this.wip.editWPICost(data).then((res) => {
        if (res.status === 200) {
          this.services.successToast('WIP cost added successfully');
          this.clearValue();
          this.getWIP();
        } else {
          this.services.errorToast(res.message);
        }
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }

  };

  OpenModal = (e, d, data) => {
    this.setState({
      st_id: d.id,
      listId: data.id,
      errorMessage: 'Are you sure you want to delete this record? ',
      errorModal: true,
      errorType: 'danger',
    });
  };

  DeleteWIP = () => {
    this.setState({
      errorMessage: '',
      errorModal: false,
      errorType: '',
    });
    const deleteCost = this.state.WIPData.find(e => e.id === this.state.st_id);
    const list = deleteCost.list.filter(e => e.id !== this.state.listId);
    const data = {
      id: this.state.st_id,
      list: list,
    };
    this.wip.editWPICost(data).then((res) => {
      if (res.status === 200) {
        this.services.successToast('WIP cost removed successfully');
        this.getWIP();
      } else {
        this.services.errorToast(res.message);
      }
    });
  };

  isEditModalOpen = (e, st, item) => {
    this.setState({
      isEditModal: true,
      st_id: st.id,
      editName: item.name,
      editText: item.text,
      editAmount: item.amount,
      item: item,
    });
  };

  editWIP = () => {
    const editCost = this.state.WIPData.find(e => e.id === this.state.st_id);
    const list = editCost.list.filter(e => e.id !== this.state.item.id);
    const cost = {
      id: this.state.item.id,
      name: this.state.editName,
      text: this.state.editText,
      amount: this.state.editAmount,
    };

    const data = {
      id: this.state.st_id,
      list: [...list, cost],
    };
    this.wip.editWPICost(data).then((res) => {
      if (res.status === 200) {
        this.services.successToast('WIP cost update successfully');
        this.onCloseEditModal();
        this.getWIP();
      } else {
        this.services.errorToast(res.message);
      }
    });
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      const { data } = this.state;
      const newSelected = new Map();
      data.map(n => newSelected.set(n.id, true));
      this.setState({ selected: newSelected });
      return;
    }
    this.setState({ selected: new Map([]) });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const newSelected = new Map(selected);
    const value = newSelected.get(id);
    let isActive = true;
    if (value) {
      isActive = false;
    }
    newSelected.set(id, isActive);
    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleDeleteSelected = () => {
    const { data } = this.state;
    let copyData = [...data];
    const { selected } = this.state;

    for (let i = 0; i < [...selected].filter(el => el[1]).length; i += 1) {
      copyData = copyData.filter(obj => obj.id !== selected[i]);
    }

    this.setState({ data: copyData, selected: new Map([]) });
  };

  isSelected = (id) => {
    const { selected } = this.state;
    return !!selected.get(id);
  };

  accessErrorModalClose = () => {
    this.setState({ accessErrorModal: false });
    this.props.history.push('/dashboard');
  };

  changeInput(e) {

    if (!e.target.validity.valid) {
      document.getElementById(e.target.id).value = '';
      return null;
    }

    this.setState({ [e.target.name]: e.target.value });
    const id = e.target.id;
    this.setState({ stateId: id });
    if (e.target.name === 'name') {
      this.setState({
        name: e.target.value,
      });
    } else if (e.target.text === 'text') {
      this.setState({
        text: e.target.value,
      });
    } else if (e.target.text === 'amount') {
      this.setState({
        amount: e.target.value,
      });
    }
    //document.getElementById(id).value = e.target.value;
  }

  render() {
    const { WIPData, loader } = this.state;
    return (
      (loader ? (
          <div className={'loader'}>
            <TailSpin
              height='100'
              width='100'
              color='#f69066'
              ariaLabel='loading'
            />
          </div>
        ) :
        <Container>
          <Row>
            <Col md={12}>
              <h3 className='page-title'>WIP Costs Table</h3>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={12}>
              <Card>
                <CardBody>
                  {WIPData
                    .map((d, i) => {
                      return (
                        <div key={d.id} style={{ paddingTop: '42px' }}>
                          <div className='card__title'>
                            <h4 className='bold-text'>{d.state}</h4>
                          </div>

                          <div className='material-table__wrap'>
                            <Table className='material-table'>
                              <TableBody>
                                {d.list.sort(function(a, b) {
                                  return a.amount - b.amount;
                                }).map((data, j) => {
                                  return (
                                    <TableRow className='material-table__row form' tabIndex={-1} key={j}>
                                      <TableCell className='material-table__cell material-table__cell-right'
                                                 padding='none'>
                                        <div className=''>
                                          <span className='form__form-group-label'>Name</span>
                                          <div className='form__form-group-field'>
                                            <input className='inp_width_sm' name='dataname' id={'name' + i + '' + j}
                                                   type='text' disabled placeholder='Enter Name' value={data.name} />
                                          </div>
                                        </div>
                                      </TableCell>

                                      <TableCell className='material-table__cell material-table__cell-right'>
                                        <div className=''>
                                          <span className='form__form-group-label'>Title</span>
                                          <div className='form__form-group-field'>
                                            <input className='inp_width_sm' name='datatext' id={'text' + i + '' + j}
                                                   type='text' disabled placeholder='Enter Title' value={data.text} />
                                          </div>
                                        </div>
                                      </TableCell>
                                      <TableCell className='material-table__cell material-table__cell-right'>
                                        <div className=''>
                                          <span className='form__form-group-label'>Amount</span>
                                          <div className='form__form-group-field'>
                                            <input name='dataamount' id={'amount' + i + '' + j} type='text' disabled
                                                   placeholder='Amount Name' value={data.amount} />
                                          </div>
                                        </div>
                                      </TableCell>
                                      <TableCell className='material-table__cell material-table__cell-right pt-5'>
                                        {/* Delete function call on click */}
                                        <span style={{
                                          fontSize: '16px',
                                          paddingRight: '10px',
                                        }} className='lnr lnr-pencil'
                                              onClick={(e) => this.isEditModalOpen(e, d, data)} />
                                        <span style={{
                                          color: 'red',
                                          fontSize: '20px',
                                        }} className='lnr lnr-circle-minus'
                                              onClick={(e) => this.OpenModal(e, d, data)} />
                                      </TableCell>

                                    </TableRow>
                                  );
                                })}

                                <TableRow className='material-table__row form' tabIndex={-1}>
                                  <TableCell className='material-table__cell material-table__cell-right' padding='none'>
                                    <div className='form__form-group'>
                                    <span className='form__form-group-label'>Name  <span
                                      style={{ color: '#ff0000' }}>*</span></span>
                                      <div className='form__form-group-field'>
                                        <input className='inp_width_sm' name='name' id={d.id + 'A'} type='text'
                                               placeholder='Enter Name' onChange={this.changeInput} />
                                      </div>
                                      {/* {this.state.isShowNameError ? <span className="form__form-group-error">error</span> : ''} */}
                                      <span
                                        className='form__form-group-error'>{this.validator.message('', (document.getElementById(d.id + 'A') && document.getElementById(d.id + 'A').value),
                                        this.state.stateId === d.id + 'A' || this.state.errorNameId === d.id + 'A' ? 'required' : 'string')}</span>

                                    </div>
                                  </TableCell>

                                  <TableCell className='material-table__cell material-table__cell-right'>
                                    <div className='form__form-group'>
                                    <span className='form__form-group-label'>Title  <span
                                      style={{ color: '#ff0000' }}>*</span></span>
                                      <div className='form__form-group-field'>
                                        <input className='inp_width_sm' name='text' id={d.id + 'B'} type='text'
                                               placeholder='Enter Title' onChange={this.changeInput} />
                                      </div>
                                      <span
                                        className='form__form-group-error'>{this.validator.message('', (document.getElementById(d.id + 'B') && document.getElementById(d.id + 'B').value),
                                        this.state.stateId === d.id + 'B' || this.state.errorTextId === d.id + 'B' ? 'required' : 'string')}</span>

                                    </div>
                                  </TableCell>
                                  <TableCell className='material-table__cell material-table__cell-right'>
                                    <div className='form__form-group'>
                                    <span className='form__form-group-label'>Amount  <span
                                      style={{ color: '#ff0000' }}>*</span></span>
                                      <div className='form__form-group-field'>
                                        <input name='amount' pattern='[0-9]*' id={d.id + 'C'} type='text'
                                               placeholder='Amount' onChange={this.changeInput} />
                                      </div>
                                      <span
                                        className='form__form-group-error'>{this.validator.message('', (document.getElementById(d.id + 'C') && document.getElementById(d.id + 'C').value),
                                        this.state.stateId === d.id + 'C' || this.state.errorAmountId === d.id + 'C' ? 'required|numeric' : 'numeric')}</span>

                                    </div>
                                  </TableCell>
                                  <TableCell className='material-table__cell material-table__cell-right pt-4'>
                                    {/* Add function call on click */}
                                    <span style={{ fontSize: '20px' }} className='lnr lnr-plus-circle'
                                          onClick={event => this.addWip(event, d.id)} />
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </div>
                        </div>
                      );
                    })}
                </CardBody>
              </Card>
              {this.state.errorModal ?
                <ModalComponent modal={this.state.errorModal} color={this.state.errorType} isCancel={true}
                                message={this.state.errorMessage} onConfirm={(e) => this.DeleteWIP(e)}
                                onClose={() => this.setState({ errorModal: false })} /> : null}
            </Col>
          </Row>

          <Modal
            isOpen={this.state.isEditModal}
            className='theme-light'

          >
            <div className='modal__header'>
              <button className='lnr lnr-cross modal__close-btn' type='button' onClick={this.onCloseEditModal} />
              <h4 className='text-modal  modal__title'>{'Edit Cost'}</h4>
            </div>
            <div className='modal__body'>
              <form className='form'>
                <div className='form__form-group'>
                  <span className='form__form-group-label'>Name</span>
                  <div className='form__form-group-field'>
                    <input
                      name='editName'
                      type='text'
                      placeholder='Enter Name'
                      value={this.state.editName}
                      onChange={this.changeInput}
                      className='inp_width_sm'
                    />
                  </div>
                </div>
                <div className='form__form-group'>
                  <span className='form__form-group-label'>Title</span>
                  <div className='form__form-group-field'>
                    <input
                      name='editText'
                      type='text'
                      placeholder='Enter Title'
                      value={this.state.editText}
                      onChange={this.changeInput}
                      className='inp_width_sm'
                    />
                  </div>
                </div>
                <div className='form__form-group'>
                  <span className='form__form-group-label'>Amount</span>
                  <div className='form__form-group-field'>
                    <input
                      name='editAmount'
                      type='text'
                      placeholder='Enter Amount'
                      value={this.state.editAmount}
                      onChange={this.changeInput}
                    />
                  </div>
                </div>
              </form>
            </div>

            <ButtonToolbar className='modal__footer'>
              <Button className='modal_cancel' onClick={this.onCloseEditModal}>Cancel</Button>{' '}
              <Button className='modal_ok' color='primary' onClick={(e) => this.editWIP(e)}>Ok</Button>
            </ButtonToolbar>
          </Modal>

          {this.state.accessErrorModal ?
            <ModalComponent modal={this.state.accessErrorModal} color='danger' isCancel={false}
                            message='You do not have access to this, please contact your manager for assistance'
                            onConfirm={(e) => this.accessErrorModalClose(e)}
                            onClose={(e) => this.accessErrorModalClose(e)} /> : null}

        </Container>)

    );
  }
}

export default withRouter(WIPCostsTable);