import { FC } from 'react'
import { DebtorLegal, DebtorLegalTribunal } from '../../../types/debtor-legal.type'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import { getDebtorLegalByIdQueryKey } from '../../../utils/legal-query-key-factory.utils'
import Modal from '@components/modal'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import styles from './debtor-legal-tribunal-modal.module.scss'
import { BaseEditModalProps } from '@src/types/component-types'
import EditDebtorLegalTribunalForm from '../../forms/debtor-legal-tribunal-form/edit-debtor-legal-tribunal-form.component'
import ModalHeader from '@components/modal-header.component'

const EditDebtorLegalTribunalModal: FC<BaseEditModalProps<DebtorLegalTribunal>> = ({
  entity: debtorLegalTribunal,
  ...modalProps
}) => {
  const queryClient = useQueryClient()
  const handleSuccessfulSubmit = (debtorLegalTribunal: DebtorLegalTribunal) => {
    queryClient.setQueryData<DebtorLegal>(
      getDebtorLegalByIdQueryKey(debtorLegalTribunal.debtorLegalId),
      (prevData) =>
        prevData
          ? {
              ...prevData,
              tribunals: prevData.tribunals.map((t) =>
                t.id === debtorLegalTribunal.id ? debtorLegalTribunal : t,
              ),
            }
          : undefined,
    )
    toast.success('Tribunal updated successfully')
    modalProps.onClose()
  }

  return (
    <Modal className={styles.modalWrapper} {...modalProps} withBtns={false}>
      <ModalHeader onCloseClick={modalProps.onClose} />
      <Header mb={4}>Edit Tribunal Details</Header>
      <EditDebtorLegalTribunalForm
        onSuccessSubmit={handleSuccessfulSubmit}
        entity={debtorLegalTribunal}
      />
    </Modal>
  )
}

export default EditDebtorLegalTribunalModal
