import { InteractionStatus } from '@azure/msal-browser'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import AzureLoginButton from '@src/shared/components/login/azure-login-button.component'
import { FC } from 'react'
import styles from './microsoft-guard.module.scss'
import { Oval } from 'react-loader-spinner'

const LoadingComponent = () => (
  <div className={styles.wrapper}>
    <Oval
      visible={true}
      height="40"
      width="40"
      color="#1e40af"
      secondaryColor="#1e40af"
      ariaLabel="oval-loading"
    />
  </div>
)
export const microsoftGuard = (Component: FC): FC => {
  const { inProgress } = useMsal()
  const isAuthenticated = useIsAuthenticated()

  const isLoadingFinished = inProgress === InteractionStatus.None

  if (!isLoadingFinished) {
    return LoadingComponent
  }

  const isLoggedIn = isLoadingFinished && isAuthenticated

  return isLoggedIn
    ? Component
    : () => (
        <div className={styles.wrapper}>
          <div>
            <AzureLoginButton />
          </div>
        </div>
      )
}
