import { WipAttachment } from "@containers/Recovery/Wip/types/wip.type"
import { REACT_APP_API_URL } from "@src/lib/global-variables"
import axios from "axios"

export class WipAttachmentService {

  async createAttachment(wipId: number, file: File): Promise<WipAttachment> {
    const formData = new FormData()
    formData.append('file', file)

    const res = await axios.post<WipAttachment>(`${REACT_APP_API_URL}/wips/${wipId}/attachments`, formData)
    return res.data
  }

  async createAttachmentBulk(wipId: number, files: File[]): Promise<WipAttachment[]> {
    const formData = new FormData()

    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i])
    }

    const res = await axios.post<WipAttachment[]>(`${REACT_APP_API_URL}/wips/${wipId}/attachments/bulk`, formData)

    return res.data
  }
}