import { useQuery } from "@tanstack/react-query"
import TasksService from '@services/TasksService.js'
import { GLOBAL_QUERIES } from "@src/lib/global-queries";
import { TaskProps } from "../../taskboard/types";


const tasksService = new TasksService();

export const useGetTasks = (params = {}, enabled: boolean = true) => {
    const query = useQuery<TaskProps[]>({
        queryKey: [GLOBAL_QUERIES.TASKSERVICE.GET, params],
        queryFn: async () => {
            return tasksService.getTasks(params);
        },
        enabled
    });

    return query;
}