import React, { FC, useState } from 'react'
import styles from './tabs.module.scss';
import classNames from 'classnames'

interface TabOption {
  value: string
  label: string
}
interface TabsProps {
  options: TabOption[]
  defaultValue?: TabOption
  onChange: (value: string) => void
}

const Tabs: FC<TabsProps> = ({ options, defaultValue, onChange }) => {
  const [value, setValue] = useState<TabOption | null>(defaultValue || null)
  const handleChange = (option: TabOption) => {
    onChange(option.value)
    setValue(option)
  }
  return (
    <div className={styles.wrapper}>
      {options.map((option, index) => (
        <div
          className={classNames(styles.item, {[styles.active]: value?.value === option.value})}
          onClick={() => handleChange(option)}
          key={`${option.label}-tab-${index * Math.random()}`}
        >
          {option.label}
        </div>
      ))}
    </div>
  )
}

export default Tabs
