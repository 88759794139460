import GenericSendSmsModal from '@components/GenricSendSmsModal/generic-send-sms-modal'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import { REACT_APP_API_URL } from '@src/lib/global-variables'
import { useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { FC, useEffect, useState } from 'react'
import { SendSmsToStaffProps } from '../../../types/notes.type'

const SendSmsToStaffModal: FC<SendSmsToStaffProps> = ({ clientId, isOpen, onClose }) => {
  const queryClient = useQueryClient()
  const [senderOptions, setSenderOptions] = useState<{ label: string; value: string }[] | null>(
    null,
  )

  const [smsTemplates, setSmsTemplates] = useState<{ label: string; value: string }[] | null>(null)

  const additionalValuesInRequeset = { company_id: clientId }

  const getSenderOptions = async (id: number) => {
    const params = {
      join: 'contacts',
    }

    const response = await axios.get(`${REACT_APP_API_URL}/companies/${id}`, { params })

    const data = response.data

    const senderOptions = data.contacts.map((contact) => ({
      label: `${contact.first_name || ''} ${contact.last_name || ''}`,
      value: contact.comm_ph_direct,
    }))

    setSenderOptions(senderOptions)
  }

  const fetchSmsTemplate = async () => {
    const response = await axios.get(`${REACT_APP_API_URL}/short-message-templates`)
    const data = response.data.data

    const formatedTemplates =
      data.map((template) => ({
        label: template?.sms_template_title,
        value: template?.sms_template_body,
      })) || []

    setSmsTemplates(formatedTemplates)
  }

  useEffect(() => {
    if (clientId && isOpen) {
      getSenderOptions(clientId)
    }
  }, [clientId, isOpen])

  useEffect(() => {
    fetchSmsTemplate()
  }, [])

  const isInnerOpen = clientId && isOpen && senderOptions && smsTemplates

  return (
    <GenericSendSmsModal
      sendToOther
      senderOptions={senderOptions || []}
      smsTemplates={smsTemplates || []}
      isOpen={!!isInnerOpen}
      onClose={onClose}
      additionalValuesInRequeset={additionalValuesInRequeset}
      onSmsSend={() => queryClient.invalidateQueries([GLOBAL_QUERIES.SMS.GET])}
    />
  )
}

export default SendSmsToStaffModal
