import { useModal } from '@src/hooks/useModal'
import React from 'react'

interface IModalContext {
  isAutomationModalOpened: boolean
  handleOpenAutomationModal: () => void
  handleCloseAutomationModal: () => void
}

const _noop = () => {
  throw new Error('useModalContext() must be used within a UserProvider')
}

const ModalContext = React.createContext<IModalContext>({
  isAutomationModalOpened: false,
  handleOpenAutomationModal: _noop,
  handleCloseAutomationModal: _noop,
})

type IMolalProviderProps = React.PropsWithChildren
export const ModalsProvider = ({ children }: IMolalProviderProps) => {
  const [isAutomationModalOpened, handleOpenAutomationModal, handleCloseAutomationModal] =
    useModal(false)

  const value = React.useMemo(
    () => ({ isAutomationModalOpened, handleOpenAutomationModal, handleCloseAutomationModal }),
    [isAutomationModalOpened],
  )

  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
}

export const useModalContext = () => {
  const context = React.useContext(ModalContext)
  if (!context) {
    throw new Error('useModalContext must be used within a UserProvider')
  }
  return context
}
