import React, {Component} from "react";
import {Col, Row, Modal} from "reactstrap";

export default class RecoveryIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "ADD STAFF",
      activeTab: 1,
      task_id: "",
      cName: "",
      sendEmailDialog: false,
      manageEmailDetails: "",
    };
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({title: "EDIT STAFF"});
    }
  }

  toggle = (tab) => {
    const {activeTab} = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };
  changeTab = (e, tab) => {
    const {activeTab} = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
        task_id: "",
      });
    }
  };

  viewTaskNotes = (id) => {
    this.setState({
      activeTab: 6,
      task_id: id,
    });
  };
  // getClientName =(name) =>{
  //     this.setState({cName:name})
  //   }

  // SendEmailClick = () => {
  //   this.setState({
  //     sendEmailDialog: true,
  //   });
  // };

  changeEmail = (flag) => {
    this.setState({
      manageEmailDetails: flag,
    });
  };

  render() {

    return (
      <>
        <div className="">

          <Modal
            isOpen={this.state.sendEmailDialog}
            size="lg"
            style={{maxWidth: "800px", width: "95%"}}
            className="theme-light"
          >
            <Col md={12} lg={12}>
              <Row>
                <Col md={12}>
                  <h3 className="page-title">
                    What email would you like to send.
                  </h3>
                </Col>
                <Col md={6}>
                  <button
                    className="btn btn-primary"
                    onClick={this.changeEmail.bind(this, "Debtor")}
                  >
                    Debtor Email
                  </button>
                </Col>
                <Col md={6}>
                  <button
                    className="btn btn-primary"
                    onClick={this.changeEmail.bind(this, "Agent")}
                  >
                    Agent Email
                  </button>
                </Col>
              </Row>
              {this.state.manageEmailDetails === "Debtor" && (
                <Row>Debtor Email</Row>
              )}
              {this.state.manageEmailDetails === "Agent" && (
                <Row>Agent Email</Row>
              )}
            </Col>
          </Modal>
        </div>
      </>
    );
  }
}
