import { TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { Pagination } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { ChangeEvent, useEffect, useState } from 'react'
import { Oval } from 'react-loader-spinner'
import { toast } from 'react-toastify'
import { Table } from 'reactstrap'
import styles from './custom-table-cell.module.scss';
import classNames from 'classnames'

interface GenericTableProps<T> {
  queryKey: unknown[]
  queryFn: ({ page, limit }: { page: number; limit: number }) => Promise<{
    total: number
    data: T[]
  }>
  tableClassName?: string
  headerOptions: string[]
  limit?: number
  enabled?: boolean
  children: (props: { data: T[] }) => React.ReactNode
  rowHeight?: number
}

const GenericTable = <T,>({
  queryKey,
  queryFn,
  headerOptions,
  children,
  limit = 10,
  rowHeight = 75,
  enabled = true,
  tableClassName
}: GenericTableProps<T>) => {
  const [page, setPage] = useState<number>(1)
  const { data, isLoading, isRefetching, isError } = useQuery({
    queryKey: [...queryKey, page],
    queryFn: async () => {
      const data = await queryFn({ page, limit })
      return data
    },
    enabled,
    keepPreviousData: true,
    cacheTime: 0,
  })

  useEffect(() => {
    setPage(1)
  }, [queryKey])

  useEffect(() => {
    if (isError) {
      toast.error('Something went wrong')
    }
  }, [isError])

  const handlePageChange = (_: ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage)
  }

  const count = data?.total ? Math.ceil(data.total / limit) : 0

  const tableHeight = limit * rowHeight

  return (
    <TableContainer>
      <Table className={classNames(styles.genericTable,tableClassName)}>
        <TableHead>
          <TableRow>
            {headerOptions.map((option, index) => (
              <TableCell key={`${option}-${index}-table-header`} style={{ textAlign: 'center' }}>
                {option}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody style={{ height: tableHeight, position: 'relative' }}>
          <>
            {data ? children({ data: data.data }) : null} <tr className="h-100"></tr>
          </>
          {data && data.data.length === 0 ? (
            <h3
              style={{
                position: 'absolute',
                top: '50%',
                right: '50%',
                transform: 'translate(50%,-50%)',
              }}
            >
              Not found
            </h3>
          ) : null}
          {isLoading ? (
            <Oval
              visible={true}
              height="60"
              width="60"
              color="blue"
              secondaryColor="blue"
              ariaLabel="oval-loading"
              wrapperStyle={{
                position: 'absolute',
                top: '50%',
                right: '50%',
                transform: 'translate(50%,-50%)',
              }}
            />
          ) : null}
        </TableBody>
      </Table>
      <Pagination
        page={page}
        count={count || 1}
        disabled={isRefetching}
        color="primary"
        onChange={handlePageChange}
      />
    </TableContainer>
  )
}

export default GenericTable
