import axios from "axios";
import { CreateDebtorReferenceData, DebtorReference } from "../types/debtor-associate-detail.type";
import { REACT_APP_API_URL } from "@src/lib/global-variables";
import { DeepPartial } from "react-hook-form";

export class DebtorReferenceService {

  async createDebtorReference(data: CreateDebtorReferenceData) {
    const res = await axios.post<DebtorReference>(`${REACT_APP_API_URL}/debtor-references`, data)
    return res.data
  }

  async deleteDebtorReference(id: number) {
    const res = await axios.delete<DebtorReference>(`${REACT_APP_API_URL}/debtor-references/${id}`)
    return res.data
  }

  async updateDebtorReference(id: number, data: DeepPartial<DebtorReference>) {
    const res = await axios.put<DebtorReference>(`${REACT_APP_API_URL}/debtor-references/${id}`, data)
    return res.data
  }
}