import { REACT_APP_API_URL } from "@src/lib/global-variables"
import { PaginationResponse } from "@src/types/service.type";
import axios from "axios"
import { SmsTemplate } from "../types/sms.type";

export class SmsTemplateService {
  async getSmsTemplates() {
    const res = await axios.get<PaginationResponse<SmsTemplate[]>>(`${REACT_APP_API_URL}/short-message-templates`)
    return res.data;
  }
}