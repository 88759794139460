import { useQuery } from "@tanstack/react-query";
import { DebtorLegalService } from "../services/debtor-legal.service";
import { getDebtorLegalByIdQueryKey } from "../utils/legal-query-key-factory.utils";
import { Params } from "@src/types/service.type";

export const useGetDebtorLegalById = (debtorLegalId: number, params?: Params) => {

  const getDebtorLegalById = async (debtorLegalId: number, params?: Params) => {
    const debtorLegalService = new DebtorLegalService();
    return await debtorLegalService.getDebtorLegalById(debtorLegalId, params);
  }

  const query = useQuery({
    queryKey: getDebtorLegalByIdQueryKey(debtorLegalId),
    queryFn: () => getDebtorLegalById(debtorLegalId, params),
    staleTime: 1000 * 60 * 5 // 5 minutes,
  })

  return query;
}