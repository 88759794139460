import { BaseFormProps } from '@src/types/component-types'
import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import styles from './client-details-form.module.scss'
import FormInput from '@components/inputs/form-input/form-input.component'
import { SubHeader } from '@containers/Recovery/Wip/components/UI/text/text.component'
import FormCheckbox from '@components/inputs/form-checkbox/form-checkbox.component'
import FormDatePicker from '@components/inputs/form-date-picker/form-date-picker.component'
import FormSelect from '@components/inputs/form-select/form-select.component'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'

interface ClientDetailsFormValues {
  clientCode: number
  coverType: string
  finalizationDate: string
  stateOfOrigin: string
  coverAsAtDateLog: string
  generalFilling: string

  payOwnerDirectly: boolean
  sendReportsToOwner: boolean
}

const defaultValues: ClientDetailsFormValues = {
  clientCode: 0,
  coverType: '',
  finalizationDate: '',
  stateOfOrigin: '',
  coverAsAtDateLog: '',
  generalFilling: '',

  payOwnerDirectly: false,
  sendReportsToOwner: false,
}

const ClientDetailsForm: FC<BaseFormProps<ClientDetailsFormValues>> = ({
  onSuccessSubmit,
  submitButtonTitle = 'Save',
  initialValues,
}) => {
  const { register, control, reset, handleSubmit } = useForm<ClientDetailsFormValues>({
    defaultValues: {
      ...initialValues,
      ...defaultValues,
    },
  })

  useEffect(() => {
    if (!initialValues) {
      return
    }
    reset({
      ...initialValues,
      ...defaultValues,
    })
  }, [initialValues])

  return (
    <form className={styles.formWrapper} onClick={handleSubmit(onSuccessSubmit)}>
      <div className={styles.row}>
        <FormInput label="Client Code" {...register('clientCode')} />
        <FormInput label="Cover Type" {...register('coverType')} />
      </div>
      <div className={styles.row}>
        <FormDatePicker label="Finalization Date" control={control} name="finalizationDate" />
        <FormInput label="Cover as at date log" {...register('coverAsAtDateLog')} />
      </div>
      <div className={styles.row}>
        <FormSelect label="State of Origin" control={control} name="stateOfOrigin" />
        <FormSelect label="General Filing" control={control} name="generalFilling" />
      </div>
      <SubHeader fontWeight="semibold">Distribution</SubHeader>
      <div className={styles.row}>
        <FormCheckbox label="Pay Owner Direct" {...register('payOwnerDirectly')} />
        <FormCheckbox label="Send Reports to Owner" {...register('sendReportsToOwner')} />
      </div>
      <SubmitButton buttonType="submit">{submitButtonTitle}</SubmitButton>
    </form>
  )
}

export default ClientDetailsForm
