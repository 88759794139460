import { FC } from 'react'
import EditDebtorLegalPlaintiffForm from '../../forms/debtor-legal-plaintiff-form/edit-debtor-legal-plaintiff-form.component'
import { DebtorLegal, DebtorLegalPlaintiff } from '../../../types/debtor-legal.type'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import { getDebtorLegalByIdQueryKey } from '../../../utils/legal-query-key-factory.utils'
import Modal from '@components/modal'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import styles from './debtor-legal-plaintiff-modal.module.scss'
import { EditDebtorLegalPlaintiffModalProps } from '../../../types/debtor-plaintiff.type'
import ModalHeader from '@components/modal-header.component'

const EditDebtorLegalPlaintiffModal: FC<EditDebtorLegalPlaintiffModalProps> = ({
  debtorLegalPlaintiff,
  ...modalProps
}) => {
  const queryClient = useQueryClient()
  const handleSuccessfulSubmit = (debtorLegalPlaintiff: DebtorLegalPlaintiff) => {
    queryClient.setQueryData<DebtorLegal>(
      getDebtorLegalByIdQueryKey(debtorLegalPlaintiff.debtorLegalId),
      (prevData) =>
        prevData
          ? {
              ...prevData,
              plaintiffs: prevData.plaintiffs.map((p) =>
                p.id === debtorLegalPlaintiff.id ? debtorLegalPlaintiff : p,
              ),
            }
          : undefined,
    )
    toast.success('Plaintiff updated successfully')
    modalProps.onClose()
  }

  return (
    <Modal className={styles.modalWrapper} {...modalProps} withBtns={false}>
      <ModalHeader onCloseClick={modalProps.onClose} />
      <Header mb={4}>Edit Plaintiff Details</Header>
      <EditDebtorLegalPlaintiffForm
        onSuccessfulSubmit={handleSuccessfulSubmit}
        debtorLegalPlaintiff={debtorLegalPlaintiff}
      />
    </Modal>
  )
}

export default EditDebtorLegalPlaintiffModal
