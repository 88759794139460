import axios from "axios";
import { GetSmsConversationFragmentParams, GetSmsConversationParams, SmsConversationResponse } from "../types/sms-conversation.type";
import { REACT_APP_API_URL } from "@src/lib/global-variables";

export class SmsConversationService {
  async getSmsConversation(params: GetSmsConversationParams) {
    const res = await axios.get<SmsConversationResponse>(`${REACT_APP_API_URL}/sms-conversation`, { params })
    return res.data;
  }

  async getSmsConversationFragment(params: GetSmsConversationFragmentParams) {
    const res = await axios.get<SmsConversationResponse>(`${REACT_APP_API_URL}/sms-conversation/fragment`, { params })
    return res.data;
  }
}