import { Params } from '@src/types/service.type'
import { WipCostListService } from '../services/wip-cost-list.service'
import { useQuery } from '@tanstack/react-query'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'

export const useGetWipCostList = (params?: Params) => {
  const getWipCostList = async (params?: Params) => {
    const wipCostListService = new WipCostListService()
    return await wipCostListService.getCostList(params)
  }

  const query = useQuery({
    queryKey: [GLOBAL_QUERIES.WIP_COST_LIST.GET],
    queryFn: () => getWipCostList(params),
  })

  return query;
}
