import { ThemeOptions, createTheme } from '@material-ui/core/styles';

export const initTheme: ThemeOptions = createTheme({
  typography: {
    fontSize: 16,
  },
  overrides: {
    MuiButton: {
      text: {
        fontSize: '16px !important',
      },
    },
  },
  palette: {
    primary: {
      main: '#000',
    },
    secondary: {
      main: '#EC6B1C',
    },
  },
});
