import { getDate } from './getDateForInput';

export const generateInvoiceFormInitialValues = {
  bill_to: {
    client_id: 0,
    name: '',
    email: '',
    address_1: '',
    address_2: '',
    address_3: '',
    suburb: '',
    state: '',
    post_code: '',
    country: '',
  },
  bill_from: {
    client_id: 0,
    name: 'Barclay MIS',
    email: 'Accounts@barlaymis.com.au',
    address_1: '71',
    address_2: 'Clara Street',
    address_3: 'Clara Street 2',
    suburb: 'Wynnum',
    state: 'QLD',
    post_code: '4178',
    country: 'Australia',
  },

  item: [
    {
      template_id: '',
      description: '',
      quantity: null,
      price: null,
    },
  ],

  due_date: getDate(7),
  current_date: getDate(0),
  invoice_no: '',
  item_total: '',
  payment_details: {
    id: null,
    name: '',
  },
  special_notes: '',
  company_details: 'Barclay MiS Protect & Collect',
  payment_details_id: '',
};

export const INVOICE_TEMPLATE_ITEM = {
  template_id: '',
  description: '',
  quantity: null,
  price: null,
};
