export const commercialUncoveredFormInitialValues = {
  company: {
    id: null,
  },

  property_address: {
    property_address: null,
    property_sub_urb: null,
    property_state: null,
    post_code: null,
  },

  tenants_details: [
    {
      first_name: null,
      middle_name: null,
      last_name: null,
      alias: null,
      current_address: null,
      date_of_birth: null,
      email: null,
      home_phone: null,
      work_phone: null,
      mobile_phone: null,
      license_no: null,
      passport_no: null,
      other_contact_info: null,
    },
  ],

  recovery_details: {
    is_tenant_vacated: false,
    date_vacated: null,
    is_tenant_locked_out: false,
    locked_out_by: null,
    is_break_fixed_term: false,
    date_re_leased: null,
    rent_from: null,
    rent_to: null,
    rent: null,
    other: null,
    sub_total: null,
    less_held_in_trust: null,
    total_amount_of_claim: null,
    relevant_info: null,
  },

  management_details: {
    property_manager: null,
    reports_email: null,
  },

  owner_details: {
    name: null,
    address: null,
    phone: null,
    email: null,
  },
  property_type: 'Commercial',
  is_entered_all_tenants: false,
  files: [],
};

export const tenant = {
  first_name: null,
  middle_name: null,
  last_name: null,
  alias: null,
  current_address: null,
  date_of_birth: '2023-03-22T19:49:46.931Z',
  email: null,
  home_phone: null,
  work_phone: null,
  mobile_phone: null,
  license_no: null,
  passport_no: null,
  other_contact_info: null,
};
