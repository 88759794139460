import React from 'react';

const OwnerDetails = ({ register }) => {
  return (
    <>
      <h3>Enter the Property Owners Details</h3>
      <label>
        <span className="form__form-group-label">
          Name <span style={{ color: '#ff0000' }}>*</span>
        </span>
        <input
          type="text"
          placeholder="Owner Name"
          required={true}
          {...register(`owner_details.name`)}
        />
      </label>
      <label>
        <span className="form__form-group-label">
          Address <span style={{ color: '#ff0000' }}>*</span>
        </span>
        <input
          type="text"
          placeholder="Owner Address"
          required={true}
          {...register(`owner_details.address`)}
        />
      </label>
      <label>
        <span className="form__form-group-label">
          Phone <span style={{ color: '#ff0000' }}>*</span>
        </span>
        <input
          type="tel"
          placeholder="Owner Phone"
          required={true}
          {...register(`owner_details.phone`)}
        />
      </label>
      <label>
        <span className="form__form-group-label">
          Email <span style={{ color: '#ff0000' }}>*</span>
        </span>
        <input
          type="email"
          placeholder="Owner Email"
          required={true}
          {...register(`owner_details.email`)}
        />
      </label>
    </>
  );
};

export default OwnerDetails;
