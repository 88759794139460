import DropdownWithSearch from '@components/dropdown-with-search/dropdown-with-search.component'
import { useGetClientOptions } from '@containers/layout/hooks/get-clients-option.hook'
import { SideBarDropdownProps } from '@containers/layout/types/side-bar.type'
import UsersIcon from '@src/icons/users'
import { FC, useMemo, useState } from 'react'
import { useHistory } from 'react-router'

const WipDropDown: FC<SideBarDropdownProps> = ({ isCloseWithoutHover }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [search, setSearch] = useState<string | null>(null)
  const history = useHistory()

  const params = useMemo(
    () => ({
      limit: 10,
      sort: 'time_created,DESC',
      s: search ? JSON.stringify({ name: { $contL: search } }) : undefined,
    }),
    [search],
  )

  const {
    data: clientOptions,
    hasNextPage,
    isFetching,
    fetchNextPage,
    isLoading
  } = useGetClientOptions(params, isOpen)

  const handleSearchChange = (search: string) => {
    setSearch(search ? search : null)
  }

  const handleReachEnd = () => {
    if (hasNextPage && !isFetching) {
      fetchNextPage()
    }
  }

  const handleOptionClick = (id: number) => {
    history.push(`/contacts/${id}`)
    localStorage.setItem('contactsId', String(id))
  }

  const handleIsOpenChange = (value: boolean) => {
    setIsOpen(value)
  }

  const isClientOptionsLoading = isLoading || isFetching

  return (
    <DropdownWithSearch
      icon={UsersIcon}
      label="Client List"
      onSearchChange={handleSearchChange}
      onReachEnd={handleReachEnd}
      onOptionClick={handleOptionClick}
      onIsOpenChange={handleIsOpenChange}
      isOpen={isOpen}
      isLabelHidden={isCloseWithoutHover}
      isLoading={isClientOptionsLoading}
      options={clientOptions}
    />
  )
}

export default WipDropDown
