import { GLOBAL_QUERIES } from "@src/lib/global-queries"
import { useQuery } from "@tanstack/react-query"
import { SmsTemplateService } from "../services/sms-template.service"

export const useGetSmsTemplates = (enabled = true) => {
  const fetchSmsTemplate = async () => {
    const smsTemplateService = new SmsTemplateService()
    return smsTemplateService.getSmsTemplates()
  }

  const query = useQuery({
    queryKey: [GLOBAL_QUERIES.SMS_TEMPLATE.GET],
    queryFn: fetchSmsTemplate,
    enabled: enabled,
    staleTime: 1000 * 60 * 60 * 24 // 24 hours,
  })

  return query
}

