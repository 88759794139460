import { FC } from 'react'
import AttachmentForm from './attachment-form.component'
import { useCreateWipAttachmentBulk } from '../../../hooks/create-wip-attachment.hook'
import { toast } from 'react-toastify'
import {
  AttachmentFormValues,
  CreateAttachmentFormProps,
} from '../../../types/wip-overview-forms.type'

const CreateAttachmentForm: FC<CreateAttachmentFormProps> = ({
  wipId,
  children,
  onSuccessSubmit,
  initialValues,
}) => {
  const { mutateAsync, isLoading } = useCreateWipAttachmentBulk()
  const handleSubmit = async (data: AttachmentFormValues) => {
    try {
      const res = await mutateAsync({ wipId, files: data.files })
      onSuccessSubmit && onSuccessSubmit(res)
    } catch (e) {
      toast.error('Failed to upload files')
      console.log(e)
    }
  }
  return (
    <AttachmentForm onSubmit={handleSubmit} loading={isLoading} initialValues={initialValues}>
      {children}
    </AttachmentForm>
  )
}

export default CreateAttachmentForm
