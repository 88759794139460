import { useHistory, useParams } from 'react-router'
import EditDebtorForm from '../../components/forms/debtor-form/edit-debtor-form.component'
import { useGetDebtorById } from '../../hooks/get-debtor-by-id.hook'
import { GET_DEBTOR_BY_ID_PARAMS } from '../../const/debtor.const'
import { useEffect } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { Wip } from '@containers/Recovery/Wip/types/wip.type'
import { getWipQueryKey } from '@containers/Recovery/Wip/utils/wip-query-factory'
import LoaderWrapper from '@components/loader/loader-wrapper.component'
import { FETCH_WIP_PARAMS } from '@containers/Recovery/Wip/const/wip.const'
import { useGetWipById } from '@containers/Recovery/Wip/hooks/get-wip-by-id.hook'

const EditDebtorPage = () => {
  const history = useHistory()
  const queryClient = useQueryClient()
  const { debtorId, wipId } = useParams<{ debtorId: string; wipId: string }>()
  const { data: wip } = useGetWipById(Number(wipId), FETCH_WIP_PARAMS)
  const { data, isLoading, isError } = useGetDebtorById(Number(debtorId), GET_DEBTOR_BY_ID_PARAMS)

  useEffect(() => {
    if (!wip) return
    const debtorInsideWip = wip.debtors.find((debtor) => debtor.id === Number(debtorId))

    if (debtorInsideWip) return
    history.push(`/wip/${wipId}/overview`)
  }, [wip])

  useEffect(() => {
    if (!isError) return
    history.push(`/wip/${wipId}/overview`)
  }, [isError])

  useEffect(() => {
    if (!data) return

    queryClient.setQueryData<Wip>(getWipQueryKey(Number(wipId)), (prevData) =>
      prevData
        ? {
            ...prevData,
            debtors: prevData.debtors.map((debtor) => (debtor.id !== data.id ? debtor : data)),
          }
        : undefined,
    )
  }, [data])

  const isDebtorFound = !!data && !isLoading

  return isDebtorFound ? <EditDebtorForm debtor={data} /> : <LoaderWrapper />
}

export default EditDebtorPage
