import { getCompaniesByIdActionTypes } from '../actionTypes';

export const getCompaniesByIdReducer = function (
  state = { data: {}, loading: false, error: '' },
  action,
) {
  switch (action.type) {
    case getCompaniesByIdActionTypes.GET_COMPANIES_BY_ID_SUCCESS:
      return { loading: false, error: '', data: action.payload };
    case getCompaniesByIdActionTypes.GET_COMPANIES_BY_ID_LOADING:
      return { loading: true, error: '', data: {} };
    case getCompaniesByIdActionTypes.GET_COMPANIES_BY_ID_ERROR:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
