import Section from '@components/section/section.component'
import ProtectAndCollectTable from '../../components/tables/protect-and-collect-table/protect-and-collect-table.component'
import { useMainStyleContext } from '@containers/App/contexts/main-style.context'
import { useEffect } from 'react'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import styles from './protect-and-collect-list-page.module.scss'

const ProtectAndCollectListPage = () => {
  const { setStyle } = useMainStyleContext()

  useEffect(() => {
    setStyle({ backgroundColor: '#f9fafb' })
    return () => {
      setStyle({ backgroundColor: 'white' })
    }
  }, [])

  return (
    <>
      <Header mb={4}>Protect & Collect</Header>
      <Section className={styles.sectionWrapper}>
        <SubmitButton type="link" url="/recoveries/forms/protect_&_collect_form">
          Add Protect & Collect Form
        </SubmitButton>
        <ProtectAndCollectTable />
      </Section>
    </>
  )
}

export default ProtectAndCollectListPage
