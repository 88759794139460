import { PureComponent } from 'react'
import { Col, Row } from 'reactstrap'
import AddTaskModal from '../Todo/AddTaskModal'
import ArchiveListTable from './components/ArchiveListTable'
import ViewTaskModal from './components/ViewTaskModal'
import Select from 'react-select'
import Services from '../../shared/Services'
import Calendar from '@components/calendar'
import OverdueTaskTable from '../Tasks/components/TaskTable/overdue-task-table.component'
import TodayTaskTable from '../Tasks/components/TaskTable/today-task-table.component'
import GlobalKanbanBoard from '../Tasks/components/GlobalKanbanBoard/components/global-kanban-board/global-kanban-board.component'
import { UserContext } from '../../context/user-context'
class TaskPage extends PureComponent {
  static contextType = UserContext

  constructor(props) {
    super(props)
    this.state = {
      completed: false,
      unassigned: false,
      addTaskModal: false,
      currentView: 'List', //    List / Calander
      newDate: new Date(),
      viewModal: false,
      selectedTask: '',
      currentViewCopy: 'List',
      selectedStaff: '',
      allStaff: [],
    }

    this.services = new Services()
    // this.getAllStaff = this.getAllStaff.bind(this);
  }

  componentDidMount() {
    this.getDetail()
  }

  AddTaskClick = () => {
    this.setState({
      newDate: new Date(),
      addTaskModal: true,
      isEdit: false,
    })
  }

  closeModal = () => {
    this.setState({
      addTaskModal: false,
      selectedTask: '',
      isEdit: false,
      viewModal: false,
    })
  }

  handleOptionChange = (e) => {
    this.setState({ [e.target.name]: e.target.checked })

    this.refs.TaskTable && this.refs.TaskTable.getTaskList()
    this.refs.Calander && this.refs.Calander.getTaskList()
    this.setState(
      {
        currentView: '',
      },
      () => {
        this.setState({
          currentView: this.state.currentViewCopy || 'List',
        })
      },
    )
  }

  EditTask = (task) => {
    this.setState({
      selectedTask: task,
      isEdit: true,
      addAppointment: true,
      addTaskModal: true,
    })
  }

  eventClick = () => {
    this.setState({
      addTaskModal: true,
    })
  }

  handleChangeLIst = (e) => {
    this.setState({
      currentView: e.target.name,
      currentViewCopy: e.target.name,
    })
    if (e.target.name === 'ArchiveList') {
      this.setState({ completed: true })
    } else if (e.target.name === 'List') {
      this.setState({ completed: false })
    }
  }

  reloadList = () => {
    this.refs.TaskTable && this.refs.TaskTable.getTaskList()
    this.refs.Calander && this.refs.Calander.getTaskList()
    this.setState({
      currentView: '',
    })
    this.setState({
      currentView: this.state.currentViewCopy || 'List',
    })
  }

  editTask = (item) => {
    this.setState({
      selectedTask: item,
      isEdit: true,
      addAppointment: true,
    })
  }
  ViewTask = (item) => {
    this.setState({
      selectedTask: item,
      isEdit: true,
      viewModal: true,
    })
  }

  handleDateClick = (date) => {
    const {user} = this.context;

    if (
      (user && user.access_level === 'SALES') ||
      user.access_level === 'RECOVERY'
    ) {
    } else {
      this.setState({
        newDate: date.date,
        addTaskModal: true,
      })
    }
  }

  handleChange = (selectedOption, name) => {
    this.setState({ [name]: selectedOption, currentView: 'List' }, () => {
      this.setState({ currentView: 'Calander' })
    })
  }

  getDetail = () => {
    const url = 'Staff/getDebtor'
    const data = {}
    this.services.getStaff(data, url, (res) => {
      if (res.status === 200) {
        const getData = res.data.data.list
        let listStaff
        listStaff =
          getData &&
          getData.map((item) => {
            return { value: item._id, label: `${item.fname}  ${item.lname}` }
          })
        this.setState({ allStaff: listStaff })
      } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
        this.setState({ accessErrorModal: true })
      }
    })
  }

  render() {
    let isAccess = true
    const {user} = this.context;
    if (
      (user && user.access_level === 'SALES') ||
      user.access_level === 'RECOVERY'
    ) {
      isAccess = false
    }
    return (
      <>
        <div className="container page-body-wrapper position-relative pb-5">
          <Row className="align-items-center ">
            <Col md={6} className="pb-4">
              <h3 className="page-title mb-5">Tasks Manager</h3>
              <div className="">
                <button className="btn btn-primary" name="List" onClick={this.handleChangeLIst}>
                  List View
                </button>
                <button className="btn btn-primary" name="Calander" onClick={this.handleChangeLIst}>
                  Calendar View
                </button>
                <button
                  className="btn btn-primary"
                  name="ArchiveList"
                  onClick={this.handleChangeLIst}
                >
                  Archive List
                </button>
                <button
                  className="btn btn-primary"
                  name="KanbanBoard"
                  onClick={this.handleChangeLIst}
                >
                  Kanban Board
                </button>
              </div>
            </Col>
            <Col md={6} className="pb-4">
              <div
                style={{ marginRight: '55px', marginTop: '60px' }}
                className="d-flex justify-content-end align-items-center flex-wrap text-right form"
              >
                {this.state.currentView === 'Calander' &&
                  user &&
                  user.access_level === 'SUPER' && (
                    <div className="form-group col-md-4 text-left">
                      <label className="form__form-group-label" htmlFor="">
                        {' '}
                        Filter By:
                        <span style={{ color: '#ff0000' }} />
                      </label>
                      <Select
                        name="selectedStaff"
                        type="text"
                        options={this.state.allStaff}
                        classNamePrefix="react-select"
                        className="react-select height_auto"
                        value={this.state.selectedStaff}
                        isClearable
                        onChange={(e) => this.handleChange(e, 'selectedStaff')}
                      />
                      {/* <span className="form__form-group-error">{this.validator.message('', this.state.task_type, 'required')}</span> */}
                    </div>
                  )}

                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    name="completed"
                    id="completed"
                    checked={this.state.completed}
                    onChange={this.handleOptionChange}
                  />
                  <label className="custom-control-label" htmlFor="completed">
                    Completed
                  </label>
                </div>
                <div className="custom-control custom-switch mx-3">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    name="unassigned"
                    id="unassigned"
                    checked={this.state.unassigned}
                    onChange={this.handleOptionChange}
                  />
                  <label className="custom-control-label" htmlFor="unassigned">
                    Unassigned
                  </label>
                </div>
                {isAccess && (
                  <button
                    className="btn btn-primary mb-0 add_plus rounded-circle"
                    onClick={this.AddTaskClick}
                  >
                    +
                  </button>
                )}
              </div>
            </Col>
          </Row>
          {this.state.currentView === 'List' && (
            <div>
              <h3 className="mt-2 mb-3 text-danger">Overdue</h3>
              <div
                style={{
                  backgroundColor: '#f1f5f9',
                  padding: '20px',
                  borderRadius: '10px',
                  marginBottom: '20px',
                }}
              >
                <OverdueTaskTable />
              </div>
              <h3 className="mt-2 mb-3 ">Today</h3>
              <div
                style={{
                  backgroundColor: '#f1f5f9',
                  padding: '20px',
                  borderRadius: '10px',
                  marginBottom: '20px',
                }}
              >
                <TodayTaskTable />
              </div>
            </div>
          )}
          {this.state.currentView === 'Calander' && (
            <Calendar
              events={[
                {
                  title: 'Board Meeting',
                  startTime: new Date(2024, 1, 26, 6, 0), // February 12, 2024, 9:00 AM
                  endTime: new Date(), // February 12, 2024, 10:00 AM
                  description: 'Monthly board meeting to discuss company strategies.',
                  color: 'blue',
                },
              ]}
            />
          )}
          {this.state.currentView === 'ArchiveList' && (
            <ArchiveListTable
              refs="ArchiveList"
              editTask={this.EditTask}
              ViewTask={this.ViewTask}
              taskList={this.state.taskList}
              completed={this.state.completed}
              unassigned={this.state.unassigned}
            />
          )}

          {this.state.currentView === 'KanbanBoard' ? <GlobalKanbanBoard /> : null}
        </div>
        {this.state.addTaskModal ? (
          <AddTaskModal
            newDate={this.state.newDate}
            addTaskModal={this.state.addTaskModal}
            reloadList={this.reloadList}
            isEdit={this.state.isEdit}
            data={this.state.selectedTask}
            closeModal={this.closeModal}
          />
        ) : (
          ''
        )}
        {this.state.viewModal ? (
          <ViewTaskModal
            isView={true}
            viewModal={this.state.viewModal}
            data={this.state.selectedTask}
            closeModal={this.closeModal}
          />
        ) : (
          ''
        )}
      </>
    )
  }
}

export default TaskPage
