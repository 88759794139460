import { FC, useEffect } from 'react'
import { SmsModalProps } from '../../../../Setting/components/Contacts/types/combined-notes.type'
import { toast } from 'react-toastify'
import SlideModal from '@components/slide-modal'
import { Oval } from 'react-loader-spinner'
import cls from 'classnames'
import modalStyles from '../note-modal.module.scss'
import styles from './sms-modal.module.scss'
import { Header, SubHeader } from '@containers/Recovery/Wip/components/UI/text/text.component'
import ReplyToShortMessageForm from '../../forms/reply-to-short-message-form/reply-to-short-message-form.component'
import { useGetSms } from '@containers/Communication/hooks/get-sms.hook'
import SmsConversation from './sms-conversation.component'
import {
  ShortMessage,
  ShortMessageFrom,
  ShortMessageTo,
} from '@containers/Communication/types/sms.type'
import { Contact } from '@containers/Setting/components/Contacts/types/contact-list.type'
import { Debtor } from '@containers/Setting/components/Contacts/taskboard/types'
import { format } from 'date-fns'

const SmsModal: FC<SmsModalProps> = ({ isOpen, onClose, id }) => {
  const { data, isLoading, isRefetching, isError } = useGetSms(id as number, !!id && isOpen)

  useEffect(() => {
    if (isError) {
      toast.error('Something went wrong')
    }
  }, [isError])

  if (isError) return null

  const isTaskNoteLoading = isLoading || isRefetching

  const getDebtorName = (debtor: Debtor, phoneNumber: string) => {
    if (!debtor) return 'Debtor not found'
    return `${debtor.name_first} ${debtor.name_last} | ${phoneNumber}`
  }

  const getCompanyContactName = (companyContact: Contact, phoneNumber: string) => {
    if (!companyContact) return 'Company Contact not found'
    return `${companyContact.first_name} ${companyContact.last_name} | ${phoneNumber}`
  }

  const getSentByText = (sms: ShortMessage) => {
    if (sms.from_mode === ShortMessageFrom.CompanyContact) {
      return getCompanyContactName(sms.company_contact, sms.from)
    }
    if (sms.from_mode === ShortMessageFrom.Debtor) {
      return getDebtorName(sms.debtor, sms.from)
    }

    if (sms.from_mode === ShortMessageFrom.Staff) {
      return sms.staff_created?.nickname || 'Staff not found'
    }

    if (sms.from_mode === ShortMessageFrom.System) {
      return 'System'
    }
  }

  const getSentToText = (sms: ShortMessage) => {
    if (sms.to_mode === ShortMessageTo.CompanyContact) {
      return getCompanyContactName(sms.company_contact, sms.to)
    }
    if (sms.to_mode === ShortMessageTo.Debtor) {
      return getDebtorName(sms.debtor, sms.to)
    }

    if (sms.to_mode === ShortMessageTo.System) {
      return 'System'
    }
  }

  return (
    <SlideModal
      isOpen={isOpen}
      onClose={onClose}
      style={{ width: '675px' }}
      layoutStyle={{ zIndex: 1000, backgroundColor: 'black' }}
    >
      <div className={modalStyles.taskNoteModal}>
        <span
          onClick={onClose}
          className={cls('lnr lnr-indent-decrease', modalStyles.closeIcon)}
        ></span>
        {isTaskNoteLoading ? (
          <Oval
            visible={true}
            height="60"
            width="60"
            color="blue"
            secondaryColor="blue"
            ariaLabel="oval-loading"
            wrapperStyle={{
              position: 'absolute',
              top: '50%',
              right: '50%',
              transform: 'translate(50%,-50%)',
            }}
          />
        ) : null}

        {!isTaskNoteLoading && data ? (
          <div className={styles.smsModalWrapper}>
            <Header>SMS Conversation</Header>
            <div className={styles.row}>
              <div className={styles.textWrapper}>
                <SubHeader fontSize="large">Sent By:</SubHeader>
                <p className={styles.text}>{getSentByText(data)}</p>
              </div>
              <div className={styles.textWrapper}>
                <SubHeader fontSize="large">Original Sent: </SubHeader>
                <p className={styles.text}>
                  {format(new Date(data.time_created), 'dd/MM/yyyy - HH:mm')}
                </p>
              </div>
            </div>
            <div className={styles.textWrapper}>
              <SubHeader fontSize="large">Sent To: </SubHeader>
              <p className={cls(styles.text, styles.highlight)}>{getSentToText(data)}</p>
            </div>
            <SmsConversation shortMessage={data} />
            <ReplyToShortMessageForm shortMessage={data} />
          </div>
        ) : null}
      </div>
    </SlideModal>
  )
}

export default SmsModal
