import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import styles from './debtor-legal-judgment-date-form.module.scss'
import { BaseFormProps } from '@src/types/component-types'
import { DebtorJudgmentDateFormValues } from '../../../types/debtor-legal-judgment-date.type'
import FormDatePicker from '@components/inputs/form-date-picker/form-date-picker.component'
import { joiResolver } from '@hookform/resolvers/joi'
import { debtorLegalJudgmentDateSchema } from '../../../schemas/legal.schemas'

const DebtorLegalJudgmentDateForm: FC<BaseFormProps<DebtorJudgmentDateFormValues>> = ({
  submitButtonTitle = 'Submit',
  onSuccessSubmit,
  initialValues,
  loading = false,
}) => {
  const { control, handleSubmit, reset } = useForm<DebtorJudgmentDateFormValues>({
    defaultValues: initialValues,
    resolver: joiResolver(debtorLegalJudgmentDateSchema),
  })

  useEffect(() => {
    if (!initialValues) return
    reset(initialValues)
  }, [initialValues])

  const onSubmit = (data: DebtorJudgmentDateFormValues) => {
    onSuccessSubmit(data)
  }

  return (
    <form className={styles.formWrapper} onSubmit={handleSubmit(onSubmit)}>
      <FormDatePicker label="Judgment Date" control={control} name="judgmentDate" />
      <SubmitButton className={styles.submitButton} buttonType="submit" loading={loading}>
        {submitButtonTitle}
      </SubmitButton>
    </form>
  )
}

export default DebtorLegalJudgmentDateForm
