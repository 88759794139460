import Modal from '@components/modal'
import styles from './set-task-modal.module.scss'
import Select from 'react-select'

import { useForm } from 'react-hook-form'
import Switch from '@components/switch'
import DatePicker from 'react-datepicker'
import cls from 'classnames'
import LabelWrapper from '@components/label-wrapper'
import { useEffect } from 'react'

import { useTaskContext } from '@src/context/task-context'
import { useUserContext } from '@src/context/user-context'
import { RULES_TO_ASSIGN_TASK } from '@src/utils'
import { PRIORITY_OPTIONS, TASK_TEMPLATE_OPTIONS } from '../../taskboard/modals/utils'
import { SET_TASK } from '../types'
import { joiResolver } from '@hookform/resolvers/joi'
import { SetTaskChangeSchema } from './validation'

interface IProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: ({ task_template }: { task_template: SET_TASK }) => void
  taskTemplateInitialValues?: SET_TASK
}

interface IOption {
  label: string
  value: number
}

interface ICreateFrom {
  priority: IOption | undefined
  assign_to: IOption[] | undefined
  due_date: Date | undefined
  excluded_from_calendar: boolean
  excluded_from_automation: boolean
  task_template: IOption | undefined
  reset_title: boolean
  title: string
  description: string
}

const defaultValues: ICreateFrom = {
  priority: undefined,
  assign_to: undefined,
  due_date: new Date(),
  excluded_from_calendar: false,
  task_template: undefined,
  title: '',
  description: '',
  excluded_from_automation: false,
  reset_title: false,
}

const SetTaskModal = ({
  isOpen,
  onClose = () => null,
  onSubmit = () => null,
  taskTemplateInitialValues,
}: IProps) => {
  const { staff } = useTaskContext()
  const { user } = useUserContext()

  const filteredStuffMemebers =
    staff?.map((member) => ({
      value: member.id,
      label: member.first_name.trim() + ' ' + member.last_name.trim(),
      access_level: member.access_level,
    })) || []

  const allowedToBeAssigned =
    RULES_TO_ASSIGN_TASK[user?.access_level as keyof typeof RULES_TO_ASSIGN_TASK]

  const filteredStuffMembersByRules = [...filteredStuffMemebers]?.filter(
    ({ access_level }) => allowedToBeAssigned?.includes(access_level),
  )

  const { control, reset, handleSubmit } = useForm<ICreateFrom>({
    defaultValues,
    mode: 'all',
    resolver: joiResolver(SetTaskChangeSchema),
  })

  const onModalSubmit = async (data: ICreateFrom) => {
    const {
      assign_to,
      description,
      due_date,
      excluded_from_calendar,
      excluded_from_automation,
      priority,
      task_template,
      title,
      reset_title,
    } = data

    const set_task: SET_TASK = {
      assign_to: assign_to?.map(({ value }) => value) as number[] || [],
      createdBy: user?.id ?? 0,
      description,
      due_date: due_date ?? new Date(),
      excluded_from_calendar,
      priority: priority?.label as string,
      task_template: task_template?.value as number,
      title,
      excluded_from_automation,
      reset_title,
    }
    onSubmit({ task_template: set_task })
    onClose()
  }

  useEffect(() => {
    if (taskTemplateInitialValues && filteredStuffMembersByRules && isOpen) {
      const {createdBy: _, ...rest} = taskTemplateInitialValues  
      console.log('taskTemplateInitialValues', taskTemplateInitialValues)
      console.log('test', taskTemplateInitialValues.assign_to)
      reset(
        {
          ...rest,
          task_template: TASK_TEMPLATE_OPTIONS.find(
            (option) => taskTemplateInitialValues.task_template === option.value,
          ),
          priority: PRIORITY_OPTIONS.find(
            (option) => taskTemplateInitialValues.priority === option.label,
          ),
          assign_to: filteredStuffMembersByRules.filter((option) =>
            taskTemplateInitialValues.assign_to?.find((id) => id === option.value),
          ),
          due_date: new Date(taskTemplateInitialValues.due_date),
        },
        { keepDirty: false, keepValues: false },
      )
    }
  }, [taskTemplateInitialValues, isOpen])

  return (
    <Modal
      isOpen={isOpen}
      saveBtnText="Set task"
      onClose={onClose}
      onSubmit={handleSubmit(onModalSubmit)}
      isLayoutOverlap
      style={{ zIndex: 1001 }}
    >
      <div className={styles.wrapper} onClick={(e) => e.stopPropagation()}>
        <span>Create Task Automation</span>
        <div className={styles.select}>
          <LabelWrapper<ICreateFrom>
            className={styles.labelWrapper}
            control={control}
            name={'priority'}
            label="Priority"
          >
            {({ onChange, value }) => {
              return (
                <Select
                  type="text"
                  options={PRIORITY_OPTIONS}
                  className="react-select"
                  value={value}
                  onChange={onChange}
                />
              )
            }}
          </LabelWrapper>

          <LabelWrapper<ICreateFrom>
            className={styles.labelWrapper}
            control={control}
            name={'assign_to'}
            label="Assign To"
          >
            {({ onChange, value }) => {
              return (
                <Select
                  isMulti
                  type="text"
                  options={filteredStuffMembersByRules}
                  className="react-select"
                  value={value}
                  onChange={onChange}
                />
              )
            }}
          </LabelWrapper>
        </div>

        <div className={styles.select}>
          <LabelWrapper<ICreateFrom>
            className={styles.labelWrapper}
            control={control}
            name={'due_date'}
            label="Due Date"
          >
            {({ onChange, value }) => {
              return (
                <DatePicker
                  type="text"
                  dateFormat="dd/MM/yyyy"
                  className="react-select"
                  selected={value}
                  onChange={(e) => {
                    const date = e as Date
                    onChange(date)
                  }}
                />
              )
            }}
          </LabelWrapper>

          <LabelWrapper<ICreateFrom>
            className={styles.labelWrapper}
            control={control}
            name={'excluded_from_automation'}
            label=""
          >
            {({ value, onChange }) => {
              return (
                <div className={styles.excludedCalendar}>
                  <Switch value={value as boolean} onChange={onChange} />
                  <span>Exclude from Automation</span>
                </div>
              )
            }}
          </LabelWrapper>

          <LabelWrapper<ICreateFrom>
            className={styles.labelWrapper}
            control={control}
            name={'excluded_from_calendar'}
            label=""
          >
            {({ value, onChange }) => {
              return (
                <div className={styles.excludedCalendar}>
                  <Switch value={value as boolean} onChange={onChange} />
                  <span>Exclude from Calender</span>
                </div>
              )
            }}
          </LabelWrapper>
        </div>
        <div className={styles.select}>
          <LabelWrapper<ICreateFrom>
            className={styles.labelWrapper}
            control={control}
            name={'task_template'}
            label="Select Task Template"
          >
            {({ onChange, value }) => {
              return (
                <Select
                  type="text"
                  options={TASK_TEMPLATE_OPTIONS}
                  className="react-select"
                  value={value}
                  onChange={onChange}
                />
              )
            }}
          </LabelWrapper>
        </div>
        <div className={styles.select}>
          <LabelWrapper<ICreateFrom>
            className={cls(styles.labelWrapper, styles.resetTitle)}
            control={control}
            name={'reset_title'}
            label=""
          >
            {({ value, onChange }) => {
              return (
                <div className={styles.resetTitle}>
                  <Switch value={value as boolean} onChange={onChange} />
                  <span>Reset title of the ticket?</span>
                  <span>If selected - would overwrite the title of the ticket</span>
                </div>
              )
            }}
          </LabelWrapper>
        </div>

        <div className={styles.select}>
          <LabelWrapper<ICreateFrom>
            className={styles.labelWrapper}
            control={control}
            name={'title'}
            label="Task Title"
          >
            {({ onChange, value }) => {
              return (
                <input
                  type="text"
                  className="react-select"
                  placeholder="Task Template 1. Title Example"
                  value={value as string}
                  onChange={onChange}
                />
              )
            }}
          </LabelWrapper>
        </div>
        <div className={styles.select}>
          <LabelWrapper<ICreateFrom>
            className={styles.labelWrapper}
            control={control}
            name={'description'}
            label="Task Description"
          >
            {({ onChange, value }) => {
              return (
                <div>
                  {' '}
                  <textarea
                    rows={10}
                    cols={50}
                    placeholder={`Task Template one template desciption.
 * Think of this point
 * And this one too when doing task`}
                    value={value as string}
                    onChange={onChange}
                  />
                </div>
              )
            }}
          </LabelWrapper>
        </div>
      </div>
    </Modal>
  )
}

export default SetTaskModal
