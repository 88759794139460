import { FC } from "react";
import { RecoverableCostsProps } from "../types/recoverable-costs.type";
import AddFeeForm from "./add-fee-form/add-fee-form.component";
import RecoverableCostList from "./recoverable-cost-list/recoverable-cost-list.component";

const RecoverableCosts: FC<RecoverableCostsProps> = ({wipId, stateOfOrigin, onFeeAdded, recoverableCostsTotal}) => {
  return (
    <div>
      <h3 className="mb-3">Recoverable Costs</h3>
      <AddFeeForm stateOfOrigin={stateOfOrigin} wipId={wipId} onFeeAdded={onFeeAdded}/>
      <RecoverableCostList wipId={wipId}/>
      <div className="form-group" style={{width: 100}}>
        <label className="form__form-group-label">RC Total</label>
        <input type="text" className="custom-input" value={recoverableCostsTotal} disabled />
      </div>
    </div>
  )
}

export default RecoverableCosts;