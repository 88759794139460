import { FC, useEffect, useRef } from 'react'
import styles from './data-grid.module.scss'
import cls from 'classnames'
import { Checkbox } from '@mui/material'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList as List } from 'react-window'
import { DataGridRowRenderer } from './data-grid-row.component'
import { DataGridProps, DataGridResizeProps, Row } from './data-grid.type'
import { DataGridService } from './data-grid.service'
import { useDataGrid } from './data-grid.hook'

const DataGrid: FC<DataGridProps> = ({
  header,
  data,
  showOnlyWithErrors,
  rows,
  width,
  editable = true,
  onRowClick,
  onRowsChange,
  isRowValid,
  isRowInvalid
}) => {
  const checkboxWidth = 55
  const widthWithoutCheckbox = width - checkboxWidth
  const isComponentInitialized = useRef(false)
  const {
    isAllRowsSelected,
    innerRows,
    innerHeader,
    shownRows,
    setInnerHeader,
    setInnerRows,
    handleCellChange,
    handleCellSelectionChange,
    handleErrorsChange,
    handleSelectAll,
    handleHeaderSelect,
    handleRowSelect,
    setShownRows,
  } = useDataGrid(width, header, data, onRowsChange)

  useEffect(() => {
    if (showOnlyWithErrors === undefined) {
      return
    }
    setShownRows(
      showOnlyWithErrors ? DataGridService.getDataGridRowsWithErrors(innerRows) : innerRows,
    )
  }, [innerRows, showOnlyWithErrors])

  useEffect(() => {
    if (rows === innerRows || !rows || !isComponentInitialized.current) {
      return
    }

    setInnerRows(rows)
    setShownRows((prev) =>
      prev.length === innerRows.length ? rows : DataGridService.getDataGridRowsWithErrors(rows),
    )
  }, [innerRows, rows])

  useEffect(() => {
    isComponentInitialized.current = true
    handleRowsChange(innerRows)
  }, [])

  useEffect(() => {
    const newHeader = innerHeader.map((column) => ({
      ...column,
      width: widthWithoutCheckbox / header.length,
    }))
    setInnerHeader(newHeader)
  }, [width])

  const handleRowsChange = (newRows: Row[]) => {
    setInnerRows(newRows)
    onRowsChange && onRowsChange(newRows)
  }

  return (
    <div className={styles.gridTable}>
      <div className={styles.row}>
        <div className={cls(styles.header, styles.center, styles.checkboxWrapper)}>
          <Checkbox
            checked={isAllRowsSelected}
            onClick={handleSelectAll}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </div>
        {innerHeader.map((column, index) => {
          return (
            <div
              key={index}
              className={cls(styles.header, {
                [styles.selected]: column.selected,
              })}
              style={{ width: column.width }}
              onClick={editable ? () => handleHeaderSelect(index) : undefined}
            >
              {column.label}
            </div>
          )
        })}
      </div>
      <AutoSizer style={{ height: '100%', width: '100%' }}>
        {({ height, width }) => (
          <List
            height={height}
            width={width - 2}
            itemCount={shownRows.length}
            itemSize={35}
            itemData={{
              rows: shownRows,
              header: innerHeader,
              editable,
              onRowClick,
              onRowSelect: handleRowSelect,
              onCellChange: handleCellChange,
              onCellSelectionChange: handleCellSelectionChange,
              onErrorsChange: handleErrorsChange,
              isRowValid,
              isRowInvalid
            }}
          >
            {DataGridRowRenderer}
          </List>
        )}
      </AutoSizer>
    </div>
  )
}

const DataGridWithResize: FC<DataGridResizeProps> = ({ ...props }) => {
  return (
    <AutoSizer style={{ height: '100%', width: '100%' }}>
      {({ width }) => <DataGrid {...props} width={width} />}
    </AutoSizer>
  )
}

export default DataGridWithResize
