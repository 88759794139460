import * as React from 'react'
const CheckListIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
    <path
      fill="#1F2A37"
      d="M19 3h-3.278A1.992 1.992 0 0 0 14 2h-4a1.993 1.993 0 0 0-1.722 1H5a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2Zm-5 1v2h-4V4h4Zm5 16H5V5h3v1a1 1 0 0 0 0 2h8a1 1 0 1 0 0-2V5h3v15Z"
    />
    <path
      fill="#1F2A37"
      d="M11.024 16.429a.992.992 0 0 1-.7-.283L8.3 14.182a1.002 1.002 0 1 1 1.4-1.436l1.328 1.289 3.28-3.181a1 1 0 1 1 1.392 1.435l-3.975 3.857a1 1 0 0 1-.701.283Z"
    />
  </svg>
)
export default CheckListIcon
