import { SPEED_DIAL_OPTIONS } from '@containers/Communication/const/speed-dial.const'
import SpeedDial from './speed-dial.component'
import {
  SpeedDialOption,
  SpeedDialSelectOption,
  WipSpeedDialProps,
} from '@containers/Communication/types/speed-dial.type'
import { useModal } from '@src/hooks/useModal'
import { FC } from 'react'
import WipSendSmsModal from '../modals/send-sms-modal/wip-send-sms-modal.component'
import WipSendEmailModal from '../modals/send-email-modal/wip-send-email-modal.component'
import WipMakeCallModal from '../modals/make-call-modal/wip-make-call-modal.component'
import PaymentTrackingModal from '@containers/Recovery/Wip/_modules/Financial/Tracking/components/modals/payment-tracking-modal/payment-tracking-modal.component'

const WipSpeedDial: FC<WipSpeedDialProps> = ({ wip }) => {
  const [isSendSmsModalOpen, openSendSmsModal, closeSendSmsModal] = useModal(false)
  const [isSendEmailModalOpen, openSendEmailModal, closeSendEmailModal] = useModal(false)
  const [isMakeCallModalOpen, openMakeCallModal, closeMadeCallModal] = useModal(false)
  const [isPaymentTrackingModalOpen, openPaymentTrackingModal, closePaymentTrackingModal] =
    useModal(false)

  const handleSelect = (option: SpeedDialOption) => {
    if (option.value === SpeedDialSelectOption.MakeCall) openMakeCallModal()
    if (option.value === SpeedDialSelectOption.SendEmail) openSendEmailModal()
    if (option.value === SpeedDialSelectOption.SendSms) openSendSmsModal()
    if (option.value === SpeedDialSelectOption.PaymentTracking) openPaymentTrackingModal()
  }

  return (
    <>
      <SpeedDial options={SPEED_DIAL_OPTIONS} onSelect={handleSelect} />
      <WipSendSmsModal wip={wip} isOpen={isSendSmsModalOpen} onClose={closeSendSmsModal} />
      <WipSendEmailModal wip={wip} onClose={closeSendEmailModal} isOpen={isSendEmailModalOpen} />
      <WipMakeCallModal wip={wip} onClose={closeMadeCallModal} isOpen={isMakeCallModalOpen} />
      <PaymentTrackingModal
        wip={wip}
        isOpen={isPaymentTrackingModalOpen}
        onClose={closePaymentTrackingModal}
      />
    </>
  )
}

export default WipSpeedDial
