import React from 'react';

export const LandlordInsuranceDetails = ({ wipDetails, setWipDetails }) => {
  const onInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if(name === "total_origin_claim" || name === "amount_covered" ){
      setWipDetails((prev) => ({ ...prev, [name]: +value }));
    }else {
      setWipDetails((prev) => ({ ...prev, [name]: value }));
    }
  };

  const onCheckboxChange = (e) => {
    const name = e.target.name;
    const isChecked = e.target.checked;
    setWipDetails((prev) => ({ ...prev, [name]: isChecked }));
  };

  return (
    <>
      <div className="card__title">
        <h5 className="bold-text">Landlord Insurance</h5>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label" />
        <div>
          <div className="col-12 ">
            <div className="d-flex align-items-center ml-2 mb-3">
              <div className="round_checkbox">
                <input
                  type="checkbox"
                  name="is_property_covered_by_landlord_insurance"
                  id="is_property_covered_by_landlord_insurance"
                  onChange={onCheckboxChange}
                  value={wipDetails.is_property_covered_by_landlord_insurance ?? false}
                  defaultChecked={wipDetails.is_property_covered_by_landlord_insurance}
                />
                <label className="mb-0" htmlFor="is_property_covered_by_landlord_insurance" />
              </div>
              <p className="m-0 ml-3">
                Was Property Covered by Landlord Insurance?
              </p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-3">
          <div className="d-flex align-items-center ml-2 mb-3">
            <div className="round_checkbox">
              <input
                type="checkbox"
                name="is_insurance_didnt_payout"
                id="Insurance"
                onChange={onCheckboxChange}
                value={wipDetails.is_insurance_didnt_payout}
                defaultChecked={
                  wipDetails.is_insurance_didnt_payout
                 }
              />
              <label className="mb-0" htmlFor="Insurance" />
            </div>
            <p className="m-0 ml-3">Insurance did not pay out? </p>
          </div>
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">Insured By</span>
        <div className="form__form-group-field">
          <input
            name="insured_by"
            type="text"
            placeholder="Insured By"
            pattern="^([0-9]+(\.?[0-9]?[0-9]?)?)"
            onChange={onInputChange}
            value={wipDetails?.insured_by}
            // defaultValue={defaultData?.wip_details?.insured_by}
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">Total Original Claim</span>
        <div className="form__form-group-field">
          <input
            name="total_origin_claim"
            type="number"
            pattern="^([0-9]+(\.?[0-9]?[0-9]?)?)"
            placeholder="Total Original Claim"
            onChange={onInputChange}
            value={wipDetails?.total_origin_claim}
            // defaultValue={defaultData?.wip_details?.total_origin_claim}
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">Amount Covered</span>
        <div className="form__form-group-field">
          <input
            name="amount_covered"
            type="number"
            placeholder="Amount Covered"
            onChange={onInputChange}
            value={wipDetails?.amount_covered}
            // defaultValue={defaultData?.wip_details?.amount_covered}
          />
        </div>
      </div>
    </>
  );
};
