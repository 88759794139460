import { Button, ButtonGroup, TableRow } from '@material-ui/core'
import { FC } from 'react'
import { NoteItemProps } from '../../../types/notes.type'
import moment from 'moment'
import CustomTableCell from '@components/generic-table/custom-table-cell'
import styles from './note-item.module.scss'
import cls from 'classnames'

export const NoteItem: FC<NoteItemProps> = ({ note, selectNote, openViewModal }) => {
  const { id, created_at, content, is_important, staff_created } = note
  const staffName = `${staff_created.first_name} ${staff_created.last_name}`

  const handleNoteSelect = (id: number) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    selectNote(id)
  }
  return (
    <TableRow
      className={cls(styles.tableRow, { [styles.active]: is_important })}
      style={{ height: 70 }}
    >
      <CustomTableCell>{moment(created_at).format('DD-MM-YYYY')}</CustomTableCell>
      <CustomTableCell>{moment(created_at).format('HH:MM A')}</CustomTableCell>
      <CustomTableCell position="eclipsed">{content}</CustomTableCell>
      <CustomTableCell>{staffName}</CustomTableCell>
      <CustomTableCell>
        <ButtonGroup className="btn-group--icons" dir="ltr">
          <Button className={styles.actionButton} onClick={() => handleNoteSelect(id)}>
            <span className="lnr lnr-pencil" />
          </Button>
          <Button className={styles.actionButton} onClick={() => openViewModal(id)}>
            <span className="lnr lnr-eye" />
          </Button>
        </ButtonGroup>
      </CustomTableCell>
    </TableRow>
  )
}
