import React, { useEffect, useState } from 'react';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { Button, Col, Row } from 'reactstrap';
import Select from 'react-select';
import { createTimeLog, getURFInfo, sendSMS } from '../service';
import Services from '../../../../shared/Services';
import { compileTemplate } from '../utils';
import { getSmsTypes } from '../service';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from 'mdi-react/CloseIcon';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import { useUserContext } from '../../../../context/user-context';

const SendReplySMS = (props) => {
  const { closeModal, refetchSMSList, debtorId } = props;
  const {user} = useUserContext()
  const [smsTemplates, setSmsTemplates] = useState([]);
  const [recoveryData, setRecoveryData] = useState(null);
  const [loading, setLoading] = useState(false);
  const services = new Services();
  const id = 13;

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      short_message_status_scheduled: { value: false, label: 'No' },
    },
  });

  const shouldBeScheduled = useWatch({
    control,
    name: 'short_message_status_scheduled',
    defaultValue: { value: false, label: 'No' },
  });

  const onSubmitHandler = async (formData) => {
    const { to, ...rest } = formData;
    setLoading(true);

    if (!rest.short_message_status_scheduled.value)
      delete rest['short_message_status_time_scheduled'];

    const data = [
      {
        to: props.replyToNumber,
        text: rest.short_message_body,
        debtor_id: debtorId,
        // Don't mock Ids
        company_id: 2,
        wip_id: props.wipID,
        urf_id: id,
        task_id: 0,
      },
    ];
    const timeLogSMS = {
      wip_id: props.wipID,
      entry_time: moment(),
      entry_details: 'Send SMS',
      entry_notes: '',
      staff_id: user.id,
    };
    await sendSMS(data);
    await createTimeLog(timeLogSMS);
    await refetchSMSList();
    services.successToast('SMS Queued Successfully');
    closeModal();
  };

  useEffect(() => {
    getFormInfo();
    getSmsTypes()
      .then((res) => {
        setSmsTemplates(res.data);
      })
      .catch((e) => console.log(e));
  }, []);

  const getFormInfo = () => {
    getURFInfo(id)
      .then((res) => {
        setRecoveryData(res.data);
      })
      .catch((e) => console.error(e));
  };

  const watchTemplate = (value, { name }) => {
    if (name === 'type') {
      const index = smsTemplates?.findIndex((i) => i.id === value?.type.value);
      if (index !== -1) {
        setValue(
          'short_message_body',
          compileTemplate(smsTemplates[index].sms_template_body, recoveryData),
        );
      }
    }
  };

  useEffect(() => {
    const subscription = watch(watchTemplate);
    return () => subscription.unsubscribe();
  }, [watch, smsTemplates]);

  return (
    <>
      <div style={{ position: 'relative', left: '375px', marginTop: '-25px' }}>
        <IconButton
          style={{ right: '24px', marginTop: '-16px' }}
          onClick={closeModal}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div>
        <h3 style={{ color: 'black' }} className="form__form-group">
          Incoming SMS from {props.debtor}:
        </h3>
      </div>
      <Typography className="form__form-group">{props.text}</Typography>

      <div style={{ marginTop: '20px' }}>
        <h3 style={{ color: 'black' }} className="form__form-group">
          Send reply SMS
        </h3>
      </div>

      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <Row className="align-items-center">
          <Col md={6}>
            <div className="form-group form">
              <label className="form__form-group-label " htmlFor="">
                Template<span style={{ color: '#ff0000' }}>*</span>
              </label>
              {errors.to && (
                <span
                  style={{ marginRight: '15px' }}
                  className="form__form-group-error"
                >
                  Type is required
                </span>
              )}
              <Controller
                control={control}
                name="type"
                render={({ field }) => (
                  <Select
                    {...field}
                    options={smsTemplates.map((i) => ({
                      value: i.id,
                      label: i.sms_template_title,
                    }))}
                    classNamePrefix="react-select"
                    className="react-select"
                  />
                )}
              />
            </div>
          </Col>
        </Row>

        <div className="row">
          <div className="col-12 ">
            <div className="form-group form">
              <span className="form__form-group-label">Message</span>
              <span style={{ color: '#ff0000' }}>*</span>
              {errors.short_message_body && (
                <span
                  style={{ marginRight: '15px' }}
                  className="form__form-group-error"
                >
                  Message is required
                </span>
              )}
              <textarea
                name="short_message_body"
                {...register('short_message_body', { required: true })}
                className="custom-input"
              />
            </div>
          </div>
        </div>
        <Row>
          <Col md={6}></Col>
          <Col md={6}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '20px',
              }}
            >
              <div>
                <Button
                  onClick={() => props.closeModal(false)}
                  disabled={loading}
                >
                  Cancel
                </Button>
              </div>
              <div>
                <Button className={'ml-4'} type="submit" disabled={loading}>
                  {loading ? 'Sending' : 'Send'}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default SendReplySMS;
