import LabelWrapper from '@components/label-wrapper'
import NavigationButton from '@components/navigation-button/navigation-button'
import {
  ForwardEmailFormProps,
  ForwardEmailFormValues,
} from '@containers/Inbox/view-email-page/types/view-email-page.type'
import SendIcon from '@src/icons/send'
import styles from './forward-email-form.module.scss'
import { FC } from 'react'
import { useForm } from 'react-hook-form'
import TrashIcon from '@src/icons/trash'
import cls from 'classnames'
import { GraphApiMailService } from '@containers/Inbox/services/graph-api-mail.service'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { ForwardMessageBody } from '@containers/Inbox/types/graph-api-mail.type'
import { toast } from 'react-toastify'
import { FORWARD_FORM_INITIAL_VALUES } from '@containers/Inbox/view-email-page/const/view-email-page.const'
import { joiResolver } from '@hookform/resolvers/joi'
import { forwardEmailSchema } from '@containers/Inbox/view-email-page/schemas/email-event.schema'

const ForwardToEmailForm: FC<ForwardEmailFormProps> = ({ onCloseClick, messageId }) => {
  const { accounts, instance } = useMsal()
  const isAuthenticated = useIsAuthenticated()
  const { control, handleSubmit } = useForm<ForwardEmailFormValues>({
    defaultValues: FORWARD_FORM_INITIAL_VALUES,
    resolver: joiResolver(forwardEmailSchema),
  })

  const onSubmit = async (data: ForwardEmailFormValues) => {
    if (!isAuthenticated) return
    const graphApiMailService = new GraphApiMailService()
    const account = accounts[0] || {}
    const dataToSend: ForwardMessageBody = {
      comment: data.comment,
      toRecipients: [
        {
          emailAddress: {
            address: data.recipient,
          },
        },
      ],
    }

    try {
      const authResult = await instance.acquireTokenSilent({ account, scopes: ['Mail.Send'] })
      await graphApiMailService.forwardMessage(authResult.accessToken, messageId, dataToSend)
      toast.success('Email has been forwarded')
      onCloseClick()
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <form className={styles.formWrapper} onSubmit={handleSubmit(onSubmit)}>
      <LabelWrapper label="Recipient" control={control} name="recipient">
        {({ onChange, value }) => (
          <input
            className={styles.forwardField}
            onChange={onChange}
            type="text"
            placeholder="Recipient"
            value={value as string}
          />
        )}
      </LabelWrapper>
      <LabelWrapper label="Comment" control={control} name="comment">
        {({ onChange, value }) => (
          <textarea
            onChange={onChange}
            value={value as string}
            className={cls(styles.forwardField, styles.bodyField)}
            placeholder="Write text here ..."
          />
        )}
      </LabelWrapper>
      <div className={styles.actionWrapper}>
        <NavigationButton className={styles.sendButton} label="Send">
          <SendIcon className={styles.right} />
        </NavigationButton>
        <div>
          <NavigationButton icon={TrashIcon} onClick={onCloseClick} />
        </div>
      </div>
    </form>
  )
}

export default ForwardToEmailForm
