import React, { useEffect, useState } from 'react';
import SearchBlock from './SearchBlock';
import SearchLog from './SearchLog';
import { link } from './SearchLink';
import { useUserContext } from '../../../../../../context/user-context';

const SearchDatabases = ({ dataDebtor,getDebtor }) => {

  const [links, setLinks] = useState([]);
  const {user} = useUserContext()
  useEffect(()=>{
    const userLink = JSON.parse(localStorage.getItem(`link ${dataDebtor?.data?.id}`))
    if(userLink){
      setLinks(userLink)
    }else {
      setLinks(link)
    }
  },[dataDebtor])

  const handleOptionChange = (id) => {
    const checked = links.map((item) => {
      if (item.id === id) {
        item.checked = !item.checked;
        item.user = `${user.first_name} ${user.last_name}`
        item.time = Date.now()
      }
      return item
    });

    localStorage.setItem(`link ${dataDebtor?.data?.id}`,JSON.stringify(checked));
    setLinks(checked);
    getDebtor(dataDebtor?.data?.id)
  };

    return (
      <>
        <div className='w-100'>
          <div id='contacts_accordion' className='form'>
            <div className='card mb-4'>
              <div id='collapseOne' className='d-flex justify-between ' data-parent='#contacts_accordion'>

                {/*--------------------Search Databases-----------------------*/}

                <div className={'col-6'}>
                  <div className='card-header mb-4 pl-0'>
                    <h4 className='card-link' data-toggle='collapse'> Search Databases </h4>
                  </div>
                  <div className='row'>
                    <div className='col-12'>
                      <SearchBlock links={links} handleOptionChange={handleOptionChange}/>
                    </div>
                  </div>
                </div>

                {/*--------------------Search Log-----------------------*/}

                <div className={'col-6'}>
                  <div className='card-header mb-4 pl-0'>
                    <h4 className='card-link' data-toggle='collapse'> Search Log </h4>
                  </div>
                  {links.length > 0 && <SearchLog links={links} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );

}

export default SearchDatabases
