import React, { useEffect, useState } from 'react';
import { Checkbox } from '@mui/material';
import Select from 'react-select';
import moment from 'moment';

export const UrfDetails = ({
  setUrfDetails,
  urfDetails,
  staffOptions,
  priorityOptions,
}) => {

  useEffect(()=>{
    const defaultSelectedStaff = staffOptions.find((val) => val.value === urfDetails.assign_staff)
    setSelectedStaff(defaultSelectedStaff)
    const defaultSelectedPriority = priorityOptions.find((val) => val.value === urfDetails.priority)
    setSelectedPriority(defaultSelectedPriority)
  },[staffOptions,priorityOptions])

  const [selectedStaff, setSelectedStaff] = useState(null);
  const [selectedPriority, setSelectedPriority] = useState(null);

  const getOnSelectStaff = (name) => (value) => {
    setSelectedStaff(value)
    setUrfDetails((prev) => ({ ...prev, [name]: value.value }));
  };

  const getOnSelectChange = (name) => (value) => {
    setSelectedPriority(value)
    setUrfDetails((prev) => ({ ...prev, [name]: value.value }));
  };

  const onInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUrfDetails((prev) => ({ ...prev, [name]: value }));
  };

  const onInputChangeRecuringData = (e) => {
    const name = e.target.name;
    const value = new Date(e.target.value).toISOString();
    setUrfDetails((prev) => ({ ...prev, [name]: value }));
  };

  const onCheckboxChange = (e) => {
    const name = e.target.name;
    const value = e.target.checked;
    setUrfDetails((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <>
      <div className="form__form-group">
        <span className="form__form-group-label">Assign to staff member<span style={{ color: '#ff0000' }}>*</span></span>
        <div className="form__form-group-field ">
          <Select
            name="assign_staff"
            value={selectedStaff}
            onChange={getOnSelectStaff('assign_staff')}
            options={staffOptions}
            classNamePrefix="react-select"
            className="react-select"
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Priority<span style={{ color: '#ff0000' }}>*</span></span>
        <div className="form__form-group-field ">
          <Select
            name="priority"
            value={selectedPriority}
            onChange={getOnSelectChange('priority')}
            options={priorityOptions}
            classNamePrefix="react-select"
            className="react-select"
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label" />
        <div>
          <div className="col-12 ">
            <div className="d-flex align-items-center ml-2 mb-3">
              <div className="round_checkbox">
                <input
                  type='checkbox'
                  name="add_recuring_calendar"
                  id="add_recuring_calendar"
                  checked={urfDetails.add_recuring_calendar}
                  onChange={onCheckboxChange}
                />
                <label className="mb-0" htmlFor="add_recuring_calendar" />
              </div>
              <p className="m-0 ml-3">
                Add Recurring Calendar appoint for this staff member?
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Recurring data</span>
        <div className="form__form-group-field d-flex flex-column">
          <input
            name="recuring_date"
            type="date"
            onChange={onInputChangeRecuringData}
            value={moment(urfDetails.recuring_date).format('YYYY-MM-DD')}

          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Add Note to WIP</span>
        <div className="form__form-group-field d-flex flex-column">
          <textarea
            name="note_wip"
            placeholder="Note WIP"
            onChange={onInputChange}
            value={urfDetails.note_wip}
          />
        </div>
      </div>
    </>
  );
};
