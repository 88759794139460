import { getInvoicesListActionEnum } from '../actionTypes';
import { getInvoiceListService } from '../service';

export const getInvoicesList = (page, status) => async (dispatch) => {
  dispatch({ type: getInvoicesListActionEnum.IS_LOADING_GET_INVOICES_LIST });
  try {
    const res = await getInvoiceListService(page, status);

    dispatch({
      type: getInvoicesListActionEnum.GET_INVOICES_LIST_SUCCESS,
      payload: res,
    });

    return res;
  } catch (error) {
    dispatch({
      type: getInvoicesListActionEnum.GET_INVOICES_LIST_ERROR,
      payload: error,
    });
  }
};
