import Modal from '@components/modal'
import styles from './edit-task-modal.module.scss'
import Select from 'react-select'

import { useForm, useWatch } from 'react-hook-form'
import { PRIORITY_OPTIONS, TASK_TEMPLATE_OPTIONS } from './utils'
import Switch from '@components/switch'
import DatePicker from 'react-datepicker'
import { joiResolver } from '@hookform/resolvers/joi'

import LabelWrapper from '@components/label-wrapper'
import React, { useEffect, useState } from 'react'
import AssignAttributes from '@components/assign-attributes'
import newTaskService from '@src/services/task-service'
import { useQueryClient } from '@tanstack/react-query'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import AssignAttributesIcon from '@src/icons/assign-attributes'
import { useTaskContext } from '@src/context/task-context'
import { useUserContext } from '@src/context/user-context'
import { RULES_TO_ASSIGN_TASK } from '@src/utils'
import { formatAssigneName } from '../utils'
import { TaskProps } from '../types'
import { CreateTaskSchema } from '@containers/Setting/components/Contacts/taskboard/modals/validations'
import cls from 'classnames'
interface IProps {
  isOpen: boolean
  onClose: () => void
  column_belong_id?: number
  task: TaskProps | null
}

interface IOption {
  label: string
  value: number
}

interface ICreateFrom {
  priority: IOption | undefined
  type: IOption | undefined
  assign_to: IOption[] | undefined
  due_date: Date | undefined
  assign_attributes: number[]
  excluded_from_calendar: boolean
  task_template: IOption | undefined
  title: string
  description: string
  excluded_from_automation: boolean
}

const defaultValues = {
  priority: undefined,
  type: undefined,
  assign_to: undefined,
  due_date: new Date(),
  assign_attributes: [],
  excluded_from_calendar: false,
  task_template: undefined,
  title: '',
  description: '',
  excluded_from_automation: false
}

const EditTaskModal = ({ isOpen, onClose = () => null, task }: IProps) => {
  if (!task) return null
  const [isOpened, setIsOpened] = useState<boolean>(false)
  const [TASK_TYPE_OPTIONS, setTaskTypeOptions] = useState<any[]>([])

  const {
    selectedCompanyState,
    companies,
    staff,
    isSales,
    currentWip,
    fetchColumns,
    isRecovery,
    currentFoundWip,
  } = useTaskContext()
  const { user } = useUserContext()
  const handleIsOpenStateChange = () => {
    setIsOpened((prev) => !prev)
  }
  const handleCloseDropdown = () => setIsOpened(false)

  const filteredStuffMemebers = staff?.map((member) => ({
    value: member.id,
    label: member.first_name.trim() + ' ' + member.last_name.trim(),
    access_level: member.access_level,
  }))

  const allowedToBeAssigned =
    RULES_TO_ASSIGN_TASK[user?.access_level as keyof typeof RULES_TO_ASSIGN_TASK]

  const filteredStuffMembersByRules = [...filteredStuffMemebers]?.filter(
    ({ access_level }) => allowedToBeAssigned?.includes(access_level),
  )

  const debtorOptions =
    currentFoundWip?.debtors?.map(({ name_first, name_last, id }) => ({
      label: formatAssigneName({ first_name: name_first, last_name: name_last, isAssigned: false }),
      value: id,
    })) || []

  const assignAttributesOptions = isSales
    ? [
        {
          innerOptions:
            companies?.map(({ id, name, contacts }: any) => ({
              label: name,
              value: id,
              options: contacts?.map(({ id, first_name, last_name }: any) => ({
                label: first_name + last_name,
                value: id,
              })),
            })) || [],
          isNested: true,
          name: 'Client',
          filter: false,
        },
      ]
    : [
        {
          innerOptions: [
            {
              label: `WIP ${currentWip}`,
              value: currentWip,
              options: debtorOptions,
            },
          ],
          isNested: true,
          name: 'WIP',
          filter: false,
        },
      ]

  const queryClient = useQueryClient()
  const { control, reset, handleSubmit } = useForm<ICreateFrom>({
    defaultValues,
    resolver: joiResolver(CreateTaskSchema),
  })

  const { assign_attributes, assign_to, excluded_from_automation } = useWatch({ control })
  const onSubmit = async (data: ICreateFrom) => {
    const { priority, type, task_template, assign_to, excluded_from_automation,  ...rest } = data
    
    const dataTOSend = {
      ...task,
      ...rest,
      priority: PRIORITY_OPTIONS.find(({ value }) => value === priority?.value)?.label,
      task_template: task_template?.value,
      type: excluded_from_automation ? null : type?.value,
      wip_id: isSales ? null : currentWip,
      staff_id_created: user?.id,
      assigned_debtors: isRecovery
        ? assign_attributes?.filter((n) => n !== Number(currentWip))
        : [],
      assign_to: assign_to?.map(({ value }) => value),
      company_id: Number(selectedCompanyState.id),
      excluded_from_automation,
      staff_id_modified: user?.id,
      automation_column_belong_id: excluded_from_automation ? null : type?.value
    }
    await newTaskService
      .updateTask(dataTOSend as any)
      .then((res) => {
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        onClose()
        queryClient.invalidateQueries({ queryKey: [GLOBAL_QUERIES.TASKSERVICE.GET] })
        queryClient.invalidateQueries({ queryKey: [GLOBAL_QUERIES.TASKSERVICE.GET_BY_ID, task.id] })
      })
  }

  useEffect(() => {
    if (task && isOpen) {
      reset({
        priority: PRIORITY_OPTIONS?.find(({ label }) => label === task.priority) || undefined,
        type: TASK_TYPE_OPTIONS.find(({ value }) => task.type === value) || null,
        assign_to: filteredStuffMembersByRules?.filter(({ value }) =>
          task.assign_to.includes(value),
        ),
        due_date: new Date(task.due_date) || new Date(),
        assign_attributes:
          task.wip_id
            ? [currentWip, ...task.assigned_debtors]
            : task.assign_attributes.map((v) => Number(v)) || [],
        excluded_from_calendar: task.excluded_from_calendar || false,
        excluded_from_automation: task.excluded_from_automation,
        task_template:
          TASK_TEMPLATE_OPTIONS.find(({ value }) => task.task_template === value) || undefined,
        title: task.title || '',
        description: task.description || '',
      })
      return
    }
    reset(defaultValues, { keepDirty: false, keepValues: false, keepDefaultValues: true })
  }, [task, isOpen])

  useEffect(() => {
    if (!selectedCompanyState?.id) return
    fetchColumns(selectedCompanyState?.id).then((res) => {
      const taskTypes =
        res?.map(({ id, title }) => ({
          value: id,
          label: title,
        })) || []
      setTaskTypeOptions(taskTypes)
    })
  }, [selectedCompanyState?.id])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      canecelBtnText="Get Back"
    >
      <div className={styles.wrapper}>
        <span>Edit Task</span>
        <div className={styles.select}>
          <LabelWrapper<ICreateFrom>
            className={styles.labelWrapper}
            control={control}
            name={'priority'}
            label="Priority"
          >
            {({ onChange, value }) => {
              return (
                <Select
                  type="text"
                  options={PRIORITY_OPTIONS}
                  className="react-select"
                  value={value}
                  onChange={onChange}
                />
              )
            }}
          </LabelWrapper>
          <LabelWrapper<ICreateFrom>
            className={styles.labelWrapper}
            control={control}
            name={'type'}
            label="Task Type"
          >
            {({ onChange, value }) => {
              return (
                <Select
                  type="text"
                  isDisabled={excluded_from_automation}
                  options={TASK_TYPE_OPTIONS}
                  className="react-select"
                  value={value}
                  onChange={onChange}
                />
              )
            }}
          </LabelWrapper>
          <LabelWrapper<ICreateFrom>
            className={styles.labelWrapper}
            control={control}
            name={'assign_to'}
            label="Assign To"
          >
            {({ onChange, value }) => {
              return (
                <Select
                  type="text"
                  isMulti
                  options={filteredStuffMembersByRules}
                  className="react-select"
                  value={value}
                  onChange={onChange}
                />
              )
            }}
          </LabelWrapper>
        </div>

        <div className={styles.select}>
          <LabelWrapper<ICreateFrom>
            className={styles.labelWrapper}
            control={control}
            name={'due_date'}
            label="Due Date"
          >
            {({ onChange, value }) => {
              return (
                <DatePicker
                  type="text"
                  dateFormat="dd/MM/yyyy"
                  className="react-select"
                  selected={value}
                  onChange={(e) => {
                    const date = e as Date
                    onChange(date)
                  }}
                />
              )
            }}
          </LabelWrapper>
          <LabelWrapper<ICreateFrom>
            className={styles.labelWrapper}
            control={control}
            name={'assign_attributes'}
            label="Assign Attributes"
          >
            {({ onChange, value }) => {
              return (
                <AssignAttributes
                  isOpen={isOpened}
                  handleDropdownChangeState={handleIsOpenStateChange}
                  handleCloseDropdown={handleCloseDropdown}
                  values={value as number[]}
                  onChange={(value) => onChange(value)}
                  options={assignAttributesOptions}
                >
                  <AssignAttributesIcon />
                  <span>Assign Attributes</span>
                </AssignAttributes>
              )
            }}
          </LabelWrapper>
        </div>
        <div className={cls(styles.select,'mt-2')}>
          <LabelWrapper<ICreateFrom>
            control={control}
            name={'excluded_from_calendar'}
            label=""
          >
            {({ value, onChange }) => {
              return (
                <div className="d-flex">
                  <Switch value={value as boolean} onChange={onChange} />
                  <span className="ml-2">Exclude from Calender</span>
                </div>
              )
            }}
          </LabelWrapper>
          <LabelWrapper<ICreateFrom>
            control={control}
            name={'excluded_from_automation'}
            label=""
          >
            {({ value, onChange }) => {
              return (
                <div className="d-flex">
                  <Switch value={value as boolean} onChange={onChange} />
                  <span className="ml-2">Exclude from Automation</span>
                </div>
              )
            }}
          </LabelWrapper>
        </div>
        <div className={styles.select}>
          <div className={styles.assignedAttributes}>
            <label>Assigned Attributes</label>
            <div>
              {assign_attributes && assign_attributes?.length < 1 && (
                <div>No Attributes Assaigned</div>
              )}
              {assignAttributesOptions?.map(({ innerOptions }) => {
                const selectedOptions = innerOptions?.filter(({ value }) =>
                  (assign_attributes as number[]).includes(value),
                )
                return (
                  <div key={React.useId()}>
                    {selectedOptions?.map(({ label }) => <span key={label}>{label}</span>)}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className={styles.select}>
          <LabelWrapper<ICreateFrom>
            className={styles.labelWrapper}
            control={control}
            name={'task_template'}
            label="Select Task Template"
          >
            {({ onChange, value }) => {
              return (
                <Select
                  type="text"
                  options={TASK_TEMPLATE_OPTIONS}
                  className="react-select"
                  value={value}
                  onChange={onChange}
                />
              )
            }}
          </LabelWrapper>
        </div>
        <div className={styles.select}>
          <LabelWrapper<ICreateFrom>
            className={styles.labelWrapper}
            control={control}
            name={'title'}
            label="Task Title"
          >
            {({ onChange, value }) => {
              return (
                <input
                  type="text"
                  className="react-select"
                  placeholder="Task Template 1. Title Example"
                  value={value as string}
                  onChange={onChange}
                />
              )
            }}
          </LabelWrapper>
        </div>
        <div className={styles.select}>
          <LabelWrapper<ICreateFrom>
            className={styles.labelWrapper}
            control={control}
            name={'description'}
            label="Task Description"
          >
            {({ onChange, value }) => {
              return (
                <div>
                  {' '}
                  <textarea
                    rows={10}
                    cols={50}
                    placeholder={`Task Template one template desciption.
 * Think of this point
 * And this one too when doing task`}
                    value={value as string}
                    onChange={onChange}
                  />
                </div>
              )
            }}
          </LabelWrapper>
        </div>
      </div>
    </Modal>
  )
}

export default EditTaskModal
