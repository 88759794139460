import { FC } from 'react'
import styles from './loader.module.scss'
import Loader from './loader.component'

interface LoaderWrapperProps {
  size?: number
}

const LoaderWrapper: FC<LoaderWrapperProps> = ({ size }) => {
  return (
    <div className={styles.loaderWrapper}>
      <Loader size={size} />
    </div>
  )
}

export default LoaderWrapper
