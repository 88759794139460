import React from 'react';

import { Grid } from '@mui/material';
import BillTo from './components/BillTo/BillTo';
import BillFrom from './components/BillFrom/BillFrom';

import { invoiceFormStyles } from '../../styles';

const FormBilling = ({ handleChange, values, errors, touched }) => {
  return (
    <Grid sx={invoiceFormStyles.formBillingContainer}>
      <Grid sx={invoiceFormStyles.billContainer}>
        <BillTo handleChange={handleChange} values={values} errors={errors} touched={touched} />
      </Grid>
      <Grid sx={invoiceFormStyles.billContainer}>
        <BillFrom handleChange={handleChange} values={values} errors={errors} touched={touched} />
      </Grid>
    </Grid>
  );
};

export default FormBilling;
