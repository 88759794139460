import Section from '@components/section/section.component'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import {
  UploadMultiplePropertiesModal,
} from '@containers/Sales/Properties/components/modals/upload-multiple-properties-modal/upload-multiple-properties-modal.component'
import { useGetClientProperties } from '@containers/Sales/Properties/hooks/crud-property.hook'
import { Company } from '@containers/Setting/components/Contacts/types/contact-list.type'
import { useModal } from '@src/hooks/useModal'
import { FC, useEffect, useState } from 'react'
import ClientPropertiesTable from '../../components/tables/client-properties-table/client-properties-table.component'
import ClientPropertiesTableFilter
  from '../../components/tables/client-properties-table/client-properties-table-filter.component'
import { useClientPageActions } from '@containers/Sales/Client/view/client-page/client-page-actions-context.page'
import { Property } from '@containers/Sales/Properties/types/property.type'
import ClientPropertiesPageActions from './client-properties-page-actions.component'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'

interface ClientPropertiesPageProps {
  company: Company
}

const ClientPropertiesPage: FC<ClientPropertiesPageProps> = ({ company }) => {
  const { setActions } = useClientPageActions()
  const [selected, setSelected] = useState<Property[]>([])
  const [page, setPage] = useState(1)
  const queryClient = useQueryClient()
  const { data, isLoading, isRefetching } = useGetClientProperties(company.id, {
    limit: 10,
    page: page,
    join: [
      'landlord',
    ],
    sort: 'id,DESC',
  })
  const [isOpen, handleOpen, handleClose] = useModal(false)

  const properties = data?.data || []
  const isPropertiesLoading = isLoading || isRefetching
  const isPropertiesNotFound = !isPropertiesLoading && properties.length === 0

  const handleFilterChange = (filter: unknown) => {
    console.log('filter change', filter)
  }

  const handleDetach = () => {
    queryClient.invalidateQueries({ queryKey: [GLOBAL_QUERIES.PROPERTIES.GET] })
    setSelected([])
    toast.success('Landlords has been successfully detached')
  }

  const handleMerge = () => {
    queryClient.invalidateQueries({ queryKey: [GLOBAL_QUERIES.PROPERTIES.GET] })
    setSelected([])
    toast.success('Properties merged')
  }

  useEffect(() => {
    setSelected([])
  }, [page])

  useEffect(() => {
    setActions(
      <ClientPropertiesPageActions
        properties={selected}
        onDetach={handleDetach}
        onMerge={handleMerge}
      />,
    )
    return () => {
      setActions(null)
    }
  }, [selected])

  return (
    <Section padding={false}>
      <SubmitButton onClick={handleOpen}>Open Modal</SubmitButton>
      <UploadMultiplePropertiesModal isOpen={isOpen} onClose={handleClose} companyId={company.id} />
      <ClientPropertiesTableFilter onFilterChange={handleFilterChange} />
      <ClientPropertiesTable
        clientId={company.id}
        page={page}
        selected={selected}
        properties={properties}
        loading={isPropertiesLoading}
        notFound={isPropertiesNotFound}
        totalCount={data?.total}
        onPageChange={setPage}
        onSelectedChange={setSelected}
      />
    </Section>
  )
}

export default ClientPropertiesPage
