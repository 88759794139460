import React from 'react';

export const AllOwners = ({ setAllOwners, allOwners }) => {
  const onCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setAllOwners(isChecked);
  };

  return (
    <>
      <div className="form__form-group">
        <span className="form__form-group-label" />
        <div>
          <div className="col-12 ">
            <div className="d-flex align-items-center ml-2 mb-3">
              <div className="round_checkbox">
                <input
                  type="checkbox"
                  name="is_all_owners"
                  id="is_property_covered_by_landlord_insurance"
                  onChange={onCheckboxChange}
                  value={allOwners}
                  defaultChecked={allOwners}
                />
                <label
                  className="mb-0"
                  htmlFor="is_property_covered_by_landlord_insurance"
                />
              </div>
              <p className="m-0 ml-3">
                Have you entered all Owners for this recovery?
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
