import { toast } from 'react-toastify'
import { useUpdateDebtorLegalPlaintiffMutation } from '../../../hooks/mutations/crud-debtor-legal-plaintiff.hook'
import {
  EditDebtorLegalPlaintiffFormProps,
  PlaintiffFormValues,
} from '../../../types/debtor-plaintiff.type'
import DebtorLegalPlaintiffForm from './debtor-legal-plaintiff-form.component'
import { FC, useMemo } from 'react'

const EditDebtorLegalPlaintiffForm: FC<EditDebtorLegalPlaintiffFormProps> = ({
  debtorLegalPlaintiff,
  onSuccessfulSubmit,
}) => {
  const { mutateAsync, isLoading } = useUpdateDebtorLegalPlaintiffMutation()
  const handleSuccessSubmit = async (data: PlaintiffFormValues) => {
    try {
      const updatedDebtorLegalPlaintiff = await mutateAsync({ id: debtorLegalPlaintiff.id, data })
      onSuccessfulSubmit(updatedDebtorLegalPlaintiff)
    } catch (error) {
      toast.error('Failed to update plaintiff')
      console.error(error)
    }
  }

  const initialValues = useMemo(
    () => ({
      plaintiffName: debtorLegalPlaintiff.plaintiffName,
      plaintiffAddress: debtorLegalPlaintiff.plaintiffAddress,
    }),
    [debtorLegalPlaintiff],
  )

  return (
    <DebtorLegalPlaintiffForm
      onSuccessfulSubmit={handleSuccessSubmit}
      initialValues={initialValues}
      loading={isLoading}
      submitButtonTitle="Update Plaintiff"
    />
  )
}

export default EditDebtorLegalPlaintiffForm
