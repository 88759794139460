import { REACT_APP_API_URL } from '../lib/global-variables'
import BaseService from './BaseService'
import axios from 'axios'

export default class NotesService extends BaseService {
  constructor() {
    super()
    this.apiUrl = this.apiUrl + '/note-types'
  }

  async getNotesAppType() {
    try {
      const response = await axios.get(this.apiUrl)
      if (response.status !== 200) {
        throw new Error('Error occured fetching emails')
      }
      return response
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async getTaskNotes(params) {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/task-notes`, { params })
      if (response.status !== 200) {
        throw new Error('Error occured fetching emails')
      }
      return response
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async getTaskNotesId(params) {
    try {
      const {id, ...queryParams} = params
      const response = await axios.get(`${REACT_APP_API_URL}/task-notes/${id}`,{params: queryParams})
      if (response.status !== 200) {
        throw new Error('Error occured fetching emails')
      }
      return response
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async editTaskNotes(params) {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/task-notes/${params.id}`,
        params,
      )
      if (response.status !== 200) {
        throw new Error('Error occured fetching emails')
      }
      return response
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async addTaskNotes(params) {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/task-notes`, params)
      if (response.status !== 201) {
        throw new Error('Error occured fetching emails')
      }
      return response
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async getAllTasksNotes() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/task-notes`)
      if (response.status !== 200) {
        throw new Error('Error occured fetching emails')
      }
      return response
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async getDebtorNotes(params) {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/debtor-notes`, { params })
      if (response.status !== 200) {
        throw new Error('Error occured fetching emails')
      }
      return response
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async addDebtorNotes(params) {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/debtor-notes`, params)
      if (response.status !== 201) {
        throw new Error('Error occured fetching emails')
      }
      return response
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async addDebtorNotesArray(params) {
    try {
      const response = params.map(async (val) =>
        axios.post(`${process.env.REACT_APP_API_URL}/debtor-notes`, val),
      )
      return await Promise.all(response)
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async editDebtorNotes(params) {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/debtor-notes/${params.id}`,
        params,
      )
      if (response.status !== 200) {
        throw new Error('Error occured fetching emails')
      }
      return response
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async getDebtorNotesId(params) {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/debtor-notes/${params.id}`)
      if (response.status !== 200) {
        throw new Error('Error occured fetching emails')
      }
      return response
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }
}
