import { REACT_APP_API_URL } from '@src/lib/global-variables'
import { Params, PaginationResponse } from '@src/types/service.type'
import axios from 'axios'
import { CreatePropertyData, Property, ValidatePropertyData } from '../types/property.type'
import { DeepPartial } from 'react-hook-form'

export class PropertyService {
  // get
  async getProperties(params: Params = {}) {
    const res = await axios.get<PaginationResponse<Property[]>>(`${REACT_APP_API_URL}/properties`, {
      params,
    })
    return res.data
  }

  async getPropertyById(id: number, params: Params = {}) {
    const res = await axios.get<Property>(`${REACT_APP_API_URL}/properties/${id}`, { params })
    return res.data
  }

  async getClientProperties(clientId: number, params: Params = {}) {
    const res = await axios.get<PaginationResponse<Property[]>>(
      `${REACT_APP_API_URL}/companies/${clientId}/properties`,
      { params },
    )
    return res.data
  }

  async getClientByPropertyId(params: Params = {}) {
    const res = await axios.get<PaginationResponse<Property[]>>(`${REACT_APP_API_URL}/companies`, {
      params,
    })
    return res.data
  }

  // post

  async createProperty(data: CreatePropertyData, companyId: number) {
    const res = await axios.post<Property>(
      `${REACT_APP_API_URL}/companies/${companyId}/properties`,
      data,
    )
    return res.data
  }

  async createManyProperties(data: CreatePropertyData[], companyId: number) {
    const res = await axios.post<Property[]>(
      `${REACT_APP_API_URL}/companies/${companyId}/properties/bulk`,
      { data },
    )
    return res.data
  }

  async validate(data: ValidatePropertyData) {
    const res = await axios.post<Property[]>(`${REACT_APP_API_URL}/properties/validate`, data)
    return res.data
  }

  // patch

  async updateProperty(id: number, data: DeepPartial<Property>) {
    const res = await axios.patch<Property>(`${REACT_APP_API_URL}/properties/${id}`, data)
    return res.data
  }

  // put

  async replaceProperty(id: number, data: Property) {
    const res = await axios.put<Property>(`${REACT_APP_API_URL}/properties/${id}`, data)
    return res.data
  }

  async mergeProperties(landlordId: number, propertiesIds: number[]): Promise<Property[]> {
    const res = await axios.put<Property[]>(`${REACT_APP_API_URL}/properties/merge`, {
      landlordId,
      propertiesIds,
    })
    return res.data
  }

  async detachLandlordByPropertyId(propertyId: number) {
    const res = await axios.put<Property>(`${REACT_APP_API_URL}/properties/${propertyId}/detach`)
    return res.data
  }

  async detachLandlord(propertiesIds: number[]): Promise<Property[]> {
    const res = await axios.put<Property[]>(`${REACT_APP_API_URL}/properties/detach`, {
      propertiesIds,
    })
    return res.data
  }

  async attachLandlord(propertyId: number, landlordId: number) {
    const res = await axios.put<Property>(`${REACT_APP_API_URL}/properties/${propertyId}/attach`, {
      landlordId,
    })
    return res.data
  }
}
export const PropertyServiceInstance = new PropertyService();