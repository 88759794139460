import { FC, useState } from 'react'
import styles from '../upload-multiple-properties-form.module.scss'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import cls from 'classnames'
import { Header, SubHeader, Text } from '@containers/Recovery/Wip/components/UI/text/text.component'
import Switch from '@components/switch'
import { useModal } from '@src/hooks/useModal'
import ConfirmationDialog from '@components/dialogs/confirmation-dialog/confirmation-dialog.component'
import { DataGridColumn, Row } from '@components/data-grid/data-grid.type'
import { DataGridService } from '@components/data-grid/data-grid.service'
import DataGrid from '@components/data-grid/data-grid.component'

interface ValidateDataStepProps {
  header: DataGridColumn[]
  isRowValid?: (row: Row, header: DataGridColumn[]) => boolean
  data: Record<string, string[]>
  onValidateDataFinish?: (data: Record<string, string>[]) => void
}

const ValidateDataStep: FC<ValidateDataStepProps> = ({
                                                       data,
                                                       header,
                                                       onValidateDataFinish,
                                                       isRowValid,
                                                     }) => {
  const [isOpen, handleOpen, handleClose] = useModal(false)
  const [rows, setRows] = useState<Row[]>([])
  const [isRowsWithErrors, setIsRowsWithErrors] = useState(false)
  const handleShowOnlyWithErrors = () => {
    setIsRowsWithErrors((prev) => !prev)
  }

  const discardSelectedRows = () => {
    setRows((prev) => prev.filter((row) => !row.selected))
  }

  const invertSelectedRows = () => {
    setRows((prev) =>
      prev.map((row) => {
        return {
          ...row,
          selected: !row.selected,
        }
      }),
    )
  }

  const handleValidateDataFinish = () => {
    onValidateDataFinish &&
    onValidateDataFinish(DataGridService.transformDataGridRowsToData(rows, header))
  }

  const handleFinish = () => {
    const rowsWithErrors = DataGridService.getDataGridRowsWithErrors(rows)

    if (rowsWithErrors.length > 0) {
      handleOpen()
      return
    }

    handleValidateDataFinish()
  }

  const handleDialogConfirm = () => {
    handleValidateDataFinish()
    handleClose()
  }
  return (
    <div className={cls(styles.stepWrapper, styles.withoutPadding)}>
      <div className={styles.validateDataStepHeaderWrapper}>
        <Header>Validate data</Header>
        <div className={styles.validateDataActionsWrapper}>
          <SubmitButton buttonStyle="highlighted" onClick={invertSelectedRows}>
            Invert selected rows
          </SubmitButton>
          <SubmitButton buttonStyle="highlighted" onClick={discardSelectedRows}>
            Discard selected rows
          </SubmitButton>
          <span className={styles.switchWrapper} onClick={handleShowOnlyWithErrors}>
            <Switch value={isRowsWithErrors} onChange={() => {
            }} />
            <SubHeader fontWeight="semibold">Show only rows with errors</SubHeader>
          </span>
        </div>
      </div>
      <div className={styles.validateGridTableWrapper}>
        <DataGrid
          rows={rows}
          onRowsChange={setRows}
          header={header}
          data={data}
          showOnlyWithErrors={isRowsWithErrors}
          isRowValid={isRowValid}
        />
      </div>
      <div className={styles.formFooter}>
        <SubmitButton
          className={styles.nextStepButton}
          onClick={handleFinish}
          buttonStyle="highlighted"
          size="large"
        >
          Next
        </SubmitButton>
      </div>
      <ConfirmationDialog
        title="Errors detected"
        isOpen={isOpen}
        onClose={handleClose}
        onConfirm={handleDialogConfirm}
        confirmButtonText="Submit"
      >
        <Text color="black" fontSize="large">
          There are still some rows that contain errors.
        </Text>
        <Text color="black" fontSize="large">
          Rows with errors will be ignored when submitting.
        </Text>
      </ConfirmationDialog>
    </div>
  )
}

export default ValidateDataStep
