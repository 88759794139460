import { REACT_APP_API_URL } from "@src/lib/global-variables";
import { DebtorLegalDefendant } from "../types/debtor-legal.type";
import { CreateDebtorLegalDefendantData } from "../types/debtor-legal-defendant.type";
import { Params } from "@src/types/service.type";
import axios from "axios";
import { DeepPartial } from "react-hook-form";

export class DebtorLegalDefendantService {

  async createDebtorLegalDefendant(data: CreateDebtorLegalDefendantData, params?: Params): Promise<DebtorLegalDefendant> {
    const res = await axios.post<DebtorLegalDefendant>(`${REACT_APP_API_URL}/debtor-legals/defendants`, data, { params });
    return res.data;
  }

  async updateDebtorLegalDefendant(debtorLegalDefendantId: number, data: DeepPartial<DebtorLegalDefendant>, params?: Params): Promise<DebtorLegalDefendant> {
    const res = await axios.patch<DebtorLegalDefendant>(`${REACT_APP_API_URL}/debtor-legals/defendants/${debtorLegalDefendantId}`, data, { params });
    return res.data;
  }

  async deleteDebtorLegalDefendant(debtorLegalDefendantId: number, params?: Params): Promise<DebtorLegalDefendant> {
    const res = await axios.delete<DebtorLegalDefendant>(`${REACT_APP_API_URL}/debtor-legals/defendants/${debtorLegalDefendantId}`, { params });
    return res.data;
  }
}