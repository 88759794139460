import React, { Fragment, useEffect, useState } from 'react';
import { link } from './SearchLink';
import moment from 'moment';

const styles = {
  block: {
    width: '100%',
    maxHeight: '380px',
    border: '2px solid #dadada',
    borderRadius: '10px',
    overflow: 'auto',
    minHeight: '380px',

  },
  title: {
    fontSize: '17px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  link: {
    color: '#ec6b1c',
    fontSize: '15px',
    fontWeight: 'bold',
  },

  header:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding:'15px',
    color: 'black',
    fontSize: '19px',
    fontWeight: 'bold',
  }

};

const SearchLog = ({links}) => {

  const [log, setLog] = useState([]);

  useEffect(()=>{
    if(links.length){
      const log = links.filter((el)=> el.user !== null)
      setLog( log )
    }else {
      setLog([])
    }
  },[links])


  return (
    <div style={styles.block}>
      <div style={styles.header}>
      <div>Date / Time Searched</div>
      <div style={{ paddingRight: '50px' }}>By Who</div>
      </div>
      <hr style={{marginTop:'0px'}}/>
      <div>
        {log.map((el, index) =>
          <Fragment key={index}>
            <div className={'pl-3 font-weight-bold'} style={styles.title}>
              <div>
                {moment(el.time).format('dddd L LT')}
              </div>
              <div className='round_checkbox pr-5'>
                {el.user}
              </div>
            </div>
            <hr />
          </Fragment>,
        )}
      </div>
    </div>
  );
};

export default SearchLog;
