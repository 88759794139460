import React, { useEffect, useState } from 'react';
import AppButton from '../../../../../../shared/components/AppButton/AppButton';
import { EmailModalForm } from './EmailModalForm';
import { Grid, Modal, Typography } from '@mui/material';
import { invoicetemplatesListOptionsById } from '../../../../../../shared/helpers/invoicetemplatesListOptions';
import { toast } from 'react-toastify';

import { useDispatch, useSelector } from 'react-redux';
import { getEmailTemplateById, postInvoiceEmail } from '../../../../../../redux/actions';
import {
  postInvoiceDataSelector,
  getCompaniesByIdData,
} from '../../../../../../redux/selector/selector';

import closeIcon from '../../../../../../assets/image/form-images/close-icon.png';

import { emailModalStyles } from './styles';

export const EmailModal = ({ open, setOpen, place, templateIds, selectedItemsIds }) => {
  // editor is a value for editor field
  // subject is a value for a subject input
  // templateId is a value for template dropdown
  const [invoiceEmailValues, setInvoiceEmailValues] = useState({
    editor: '',
    subject: '',
    templateIndex: null,
  });
  const [templates, setTemplates] = useState([]);
  const { contacts } = useSelector(getCompaniesByIdData);
  const [selectedContacts, setSelectedContacts] = useState([]);

  const dispatch = useDispatch();

  // Getting current invoice data
  const invoiceData = useSelector(postInvoiceDataSelector);

  // Getting whole templates list

  const { container, header, title } = emailModalStyles;
  const { subject, editor, templateIndex } = invoiceEmailValues;
  const email = invoiceData?.bill_to?.email;

  useEffect(() => {
    let promises;
    if (place === 'list') {
      promises = templateIds?.map((id) => dispatch(getEmailTemplateById(id)));
    } else if (invoiceData?.item?.length) {
      promises = invoiceData.item?.map(({ template_id }) =>
        dispatch(getEmailTemplateById(template_id)),
      );
    }
    Promise.all(promises).then((results) => {
      setTemplates(results);
    });
  }, [invoiceData, templateIds]);

  useEffect(() => {
    if (contacts.length) {
      const isInvoiceItem = contacts.find((contact) => !!contact.is_invoice);
      if (isInvoiceItem) {
        setSelectedContacts([isInvoiceItem.comm_email]);
      }
    }
  }, [contacts]);

  const handleSelectEmail = (email) => {
    if (selectedContacts.includes(email)) {
      setSelectedContacts((prev) => prev.filter((item) => item !== email));
    } else {
      setSelectedContacts((prev) => [...prev, email]);
    }
  };

  // Grouping data to send as a prop to EmailModalForm component
  const invoiceFormData = {
    email,
    templateOptions: invoicetemplatesListOptionsById(templates),
    templateIndex,
    subject,
    editorValueText: editor,
  };

  // Setting by default first option with first index for template dropdown
  useEffect(() => {
    if (templates?.length) {
      handleTemplateChange(0);
    }
  }, [templates]);

  // Handling editor value changes
  const onEditorChange = (newValue) => {
    setInvoiceEmailValues((prev) => ({ ...prev, editor: newValue }));
  };

  // Handling template dropdown change
  const onTemplateChange = (e) => {
    const index = e.target.value;
    handleTemplateChange(index);
  };

  // Taking id and find matched template from adapted templates by id
  const handleTemplateChange = (index = 0) => {
    if (!templates.length) return;
    // If user selected the same template which was selected in dropdown, do nothing
    if (index === templateIndex) return;

    // Looking for a template by selected dropdown template id
    const { subject: subj, body: bod } = templates[index];

    setInvoiceEmailValues({ editor: bod, subject: subj, templateIndex: index });
  };

  const handleFieldChange = (field, value) => {
    setInvoiceEmailValues((prev) => {
      return { ...prev, [field]: value };
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSend = () => {
    const data = {
      to: selectedContacts,
      text: editor,
      subject: subject,
      ids: place === 'list' ? selectedItemsIds : [invoiceData?.id],
    };
    dispatch(postInvoiceEmail(data)).then(onSuccess).catch(onError);
  };

  const onSuccess = () => {
    toast.success('Email has been sent!');
    handleClose();
  };

  const onError = () => {
    toast.error('Something went wrong!');
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-email"
      aria-describedby="modal-modal-email"
    >
      <Grid sx={container}>
        <Grid sx={header}>
          <Typography sx={title}>Send Email</Typography>
          <AppButton icon={closeIcon} buttonStyle="transparent" onClick={handleClose} />
        </Grid>
        <EmailModalForm
          onEditorChange={onEditorChange}
          handleClose={handleClose}
          handleSend={handleSend}
          onTemplateChange={onTemplateChange}
          handleFieldChange={handleFieldChange}
          data={invoiceFormData}
          contacts={contacts}
          selectedContacts={selectedContacts}
          handleSelectEmail={handleSelectEmail}
        />
      </Grid>
    </Modal>
  );
};
