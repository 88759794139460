import { FC, ReactNode } from 'react'
import styles from './text.module.scss'
import cls from 'classnames'

interface TextProps {
  children: ReactNode
  pb?: 1 | 2 | 3
  mb?: 1 | 2 | 3 | 4
  className?: string
}

interface TextPropsExtended extends TextProps {
  color?: 'grey' | 'black' | 'lightGrey'
  fontWeight?: 'thin' | 'normal' | 'bold'
  fontSize?: 'small' | 'medium' | 'large'
  className?: string
  style?: React.CSSProperties
}

interface SubHeaderProps extends TextProps {
  fontSize?: 'small' | 'medium' | 'large'
  fontWeight?: 'semibold' | 'bold'
}

export const Header: FC<TextProps> = ({ children, pb, mb, className }) => {
  return (
    <p
      className={cls(
        styles.header,
        {
          [styles[`mb${mb}`]]: mb,
          [styles[`pb${pb}`]]: pb,
        },
        className,
      )}
    >
      {children}
    </p>
  )
}

export const SubHeader: FC<SubHeaderProps> = ({
  children,
  pb,
  mb,
  fontWeight = 'bold',
  fontSize = 'medium',
}) => {
  return (
    <p
      className={cls(styles.subHeader, {
        [styles[`mb${mb}`]]: mb,
        [styles[`pb${pb}`]]: pb,
        [styles[fontWeight]]: fontWeight,
        [styles[fontSize]]: fontSize,
      })}
    >
      {children}
    </p>
  )
}

export const Text: FC<TextPropsExtended> = ({
  children,
  pb,
  mb,
  color = 'grey',
  fontWeight = 'thin',
  fontSize = 'medium',
  className,
  style,
}) => {
  return (
    <p
      className={cls(className, styles.text, styles[fontSize], {
        [styles[`mb${mb}`]]: mb,
        [styles[`pb${pb}`]]: pb,
        [styles[color]]: color,
        [styles[fontWeight]]: fontWeight,
      })}
      style={style}
    >
      {children}
    </p>
  )
}
