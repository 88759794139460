import { useIsAuthenticated, useMsal } from "@azure/msal-react"
import { GraphApiRuleService } from "../services/graph-api-rule.service"
import { GRAPH_API_REQUEST } from "@src/config/azure-auth.config"
import { AUTOMATICALLY_MOVE_EMAIL_TO_BMS_FOLDER_RULE_NAME } from "../const/rule.const"
import { RuleFactory } from "../utils/rule-factory.utils"
import { useCreateMessageRuleMutation } from "./mutations/create-message-rule.hook"
import { GraphApiRule } from "../types/graph-api-mail.type"
import { GLOBAL_QUERIES } from "@src/lib/global-queries"
import { useQuery } from "@tanstack/react-query"

export const useGetRuleToMoveEmailToBmsFolderOrCreate = (folderId?: string) => {
  const { mutateAsync } = useCreateMessageRuleMutation()
  const { accounts, instance } = useMsal()
  const isAuthenticated = useIsAuthenticated()


  const getRuleByDisplayNameOrCreate = async (folderId: string) => {
    const graphApiRuleService = new GraphApiRuleService();
    const account = accounts[0] || {};

    const token = await instance.acquireTokenSilent({ scopes: GRAPH_API_REQUEST.MESSAGE_RULES.GET, account: account })
    const rule = await graphApiRuleService.getRules(
      token.accessToken,
      { $filter: `displayName eq '${AUTOMATICALLY_MOVE_EMAIL_TO_BMS_FOLDER_RULE_NAME}'` }
    )

    if (rule[0]) {
      return rule[0]
    }

    const rules = await graphApiRuleService.getRules(token.accessToken, { $top: 999, $select: 'id' })

    const createRuleData = RuleFactory.moveEmailToBmsFolder(folderId, rules.length || 1)

    const createdRule: GraphApiRule = await mutateAsync(createRuleData) as GraphApiRule;

    return createdRule
  }

  const query = useQuery({
    queryKey: [GLOBAL_QUERIES.MESSAGE_RULES.GET_BY_DISPLAY_NAME, folderId],
    queryFn: () => getRuleByDisplayNameOrCreate(folderId as string),
    enabled: isAuthenticated && !!folderId,
    retry: 1
  })

  return query;
}