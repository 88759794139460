import { GLOBAL_QUERIES } from "@src/lib/global-queries";
import { useInfiniteQuery } from "@tanstack/react-query"
import { GetSmsConversationParams } from "../types/sms-conversation.type";
import { SmsConversationService } from "../services/sms-conversation.service";
import { useMemo } from "react";


export const useGetSmsConversation = (smsConversationParams: GetSmsConversationParams, enabled = true) => {
  const { data, ...restQuery } = useInfiniteQuery({
    queryKey: [GLOBAL_QUERIES.SMS_CONVERSATION.GET, smsConversationParams],
    queryFn: async (params) => {
      const pageParam = params.pageParam;
      const smsConversationService = new SmsConversationService()
      const page = pageParam?.currentPage ? pageParam.currentPage : smsConversationParams.page;
      const limit = smsConversationParams.limit;
      const data = await smsConversationService.getSmsConversation({ ...smsConversationParams, limit, page })
      return {
        results: data.messages.reverse(),
        totalPages: data.total_pages,
        currentPage: page,
      }
    },
    cacheTime: 0,
    getNextPageParam: (params) => {
      if (params.currentPage <= 1) {
        return undefined
      }
      return { ...params, currentPage: params.currentPage - 1 }
    },
    getPreviousPageParam: (params) => {
      if (params.currentPage >= params.totalPages) {
        return undefined
      }
      return { ...params, currentPage: params.currentPage + 1 }
    },
    enabled: enabled
  });

  const flattenedData = useMemo(
    () => (data ? data?.pages.flatMap(item => item.results) : []),
    [data]
  );

  return { data: flattenedData, ...restQuery }
}
