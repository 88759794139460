import { useMsal } from '@azure/msal-react'
import { loginRequest } from '../../../config/azure-auth.config'
import MicrosoftIcon from '@src/icons/microsoft'
import styles from './azure-login-button.module.scss'

const AzureLoginButton = () => {
  const { instance } = useMsal()

  const handleAzureADLogin = async () => {
    try {
      await instance.loginRedirect(loginRequest)
    } catch (e) {
      console.log('error', e)
    }
  }

  return (
    <button type="button" className={styles.azureAdButton} onClick={handleAzureADLogin}>
      <MicrosoftIcon />
      Sign in with Microsoft
    </button>
  )
}

export default AzureLoginButton
