import Section from '@components/section/section.component'
import EmailTableWipFilter from '@containers/Communication/components/tables/email-table/email-table-with-filter.component'
import { Client } from '@containers/Sales/Client/types/client.type'
import { FC } from 'react'

interface ClientCommunicationEmailsPageProps {
  client: Client
}

const ClientCommunicationEmailsPage: FC<ClientCommunicationEmailsPageProps> = ({ client }) => {
  return (
    <Section>
      <EmailTableWipFilter params={{ client_id: client.id }} />
    </Section>
  )
}

export default ClientCommunicationEmailsPage
