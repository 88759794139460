import React, { Component } from 'react';
import { Col, Modal, Row } from 'reactstrap';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import SimpleReactValidator from 'simple-react-validator';
import moment from 'moment';
import Services from '../../shared/Services';
import { withRouter } from 'react-router';
import 'rc-time-picker/assets/index.css';
import { Tab, Tabs } from '@material-ui/core';
import TasksService from '../../services/TasksService';
import StaffService from '../../services/StaffService';
import DebtorService from '../../services/DebtorService';
import WIPService from '../../services/WIPService';
import SetEmailTaskManager from './SetEmailTaskManager';
import SetSMSTaskManager from './SetSMSTaskManager';
import { getSmsTypes } from '../Form/RecoveryForm/service';
import { UserContext } from '../../context/user-context';

const daysOption = [
  { value: 'Monday', label: 'Monday' },
  { value: 'Tuesday', label: 'Tuesday' },
  { value: 'Wednesday', label: 'Wednesday' },
  { value: 'Thursday', label: 'Thursday' },
  { value: 'Friday', label: 'Friday' },
  { value: 'Saturday', label: 'Saturday' },
  { value: 'Sunday', label: 'Sunday' },
];

const repeatTypeOption = [
  { value: '1', label: 'Daily' },
  { value: '2', label: 'Weekly' },
  { value: '3', label: 'Monthly (by Day)' },
  { value: '4', label: 'Monthly (by Date)' },
  { value: '5', label: 'Yearly' },
];

const typeOption = [
  { value: 'Email', label: 'Email' },
  { value: 'Call', label: 'Call' },
  { value: 'Meeting', label: 'Meeting' },
  { value: 'Process Step', label: 'Process Step' },
  { value: 'CN Data Collection', label: 'CN Data Collection' },
  { value: 'CN Welcome Email', label: 'CN Welcome Email' },
  { value: 'CN Training', label: 'CN Training' },
  { value: 'Presentation', label: 'Presentation' },
  { value: 'Catch Up', label: 'Catch Up' },
  { value: 'Priority Task', label: 'Priority Task' },
  { value: 'Follow Up', label: 'Follow Up' },
  { value: 'Priority Follow Up', label: 'Priority Follow Up' },
];

const priorityOption = [
  { value: 'High', label: 'High' },
  { value: 'Medium', label: 'Medium' },
  { value: 'Normal', label: 'Normal' },
];


class AddTaskModal extends Component {
  static contextType = UserContext
  constructor(props) {
    super(props);
    console.log(props)
    this.state = {
      id: '',
      task_type: '',
      wip_no: null,
      title: '',
      internal: false,
      staff: [],
      client: '',
      sDate: new Date(),
      sTimeH: '',
      sTimeM: '',
      timeTypeS: { value: 'AM', label: 'AM' },
      eDate: new Date(),
      eTimeH: '',
      eTimeM: '',
      timeTypeE: { value: 'AM', label: 'AM' },
      rType: '',
      priority: '',
      eSubject: '',
      eDescription: '',
      location: '',
      guest: '',
      smaReminder: { value: '0', label: '0' },
      dayAppointment: { value: '8:00', label: '8:00' },
      useEnddate: false,
      allClient: [],
      allStaff: [],
      textSMS: '',
      toNumber: '',
      isEdit: false,
      mainType: this.props.mainType || 'RECOVERY',
      status: { value: false, label: 'To Do' },
      startTime: '',
      endTime: '',
      taskSubject: null,
      taskDescription: '',
      allDataStaff: [],
      calanderType: { value: 'Task', label: 'Task' },
      meet_with: '',
      rpt_use_end: false,
      rpt_day: [],
      recur_enddate: new Date(),
      emailList: [],
      assignto: [],
      valueTab: 0,
      is_archived: false,
      is_deleted: false,
      is_duplicated: false,
      is_private: false,
      debtorsOption: [],
      debtorId: '',
      debtor: '',
      wipId: '',
      wips: [],
      setEmail: {},
      setSMS: {},
      repeat_date_start: '',
      repeat_date_end: '',
      repeatSchedule: false,
      tempSMS: "",
    };

    this.wip = new WIPService();
    this.debtor = new DebtorService();
    this.staff = new StaffService();
    this.task = new TasksService();
    this.services = new Services();
    this.changeInput = this.changeInput.bind(this);
    this.saveTask = this.saveTask.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.validator = new SimpleReactValidator();
    this.validatorEmail = new SimpleReactValidator();
    this.validatorSMS = new SimpleReactValidator();
  }

  componentDidMount() {
    this.loadingData();
    if (this.props?.data?.set_sms) {
      getSmsTypes()
        .then((res) => {
          const option = res.data.data.map((i) => ({
            value: i.id,
            label: i.sms_template_title,
          }));
          const value = option.find(e => e.label === this.props.data.set_sms.template);

          this.setState({tempSMS: value});
        })
        .catch((e) => console.log(e));
    }
    if (this.props?.wipId){
      this.setState({wipId: this.props?.wipId})
    }
  }

  async loadingData() {
    await this.getAllWIPs();
    await this.getCompanies();
    await this.getAllstaff();
    await this.getDebtors();
  }

  async getDebtors() {
    try {
      const params = {
        limit: 1000,
      };
      const debtors = await this.debtor.getDebtor(params);
      const option = debtors.data.map((e) => ({
        value: e.id, label: `${e.name_first} ${e.name_last}`,
      }));
      this.setState({ debtorsOption: option });
    } catch {
      this.setState({ accessErrorModal: true });
    }
  }

  async getAllWIPs() {
    const params = {
      limit: 10000,
    };
    const list = await this.wip.getWips(params);
    const option = list.data.map((e) => ({
      value: e.id, label: `WIP # ${e.id}`,
    }));

    this.setState({
      wips: option,
    });
    if (this.props.wipId) {
      const setWip = option.find((val) => val.value === this.props.wipId)
      this.setState({
        wip_no: setWip,
        wipId: setWip.value
      });
    }
  }

  addRecord() {

    let date;
    if (this.props.data) {
      const { data, isEdit } = this.props;
      let startDate;
      let endDate;
      if (data.date_start) {
        date = moment(data.date_start,'MM-DD-YY').format('MM-DD-YY');
        if (date === 'Invalid date') {
          startDate = new Date(data.date_start.slice(0, data.date_start.length - 2).replace(/\//g, '-'));
        } else {
          startDate = new Date(date);
        }
      } else {
        startDate = new Date();
      }
      if (startDate === 'Invalid Date' || date === 'Invalid date') {
        startDate = new Date();
      }
      if (data.date_end) {
        date = moment(data.date_end,'MM-DD-YY').format('MM-DD-YY');
        if (date === 'Invalid date') {
          endDate = new Date(data.date_end.slice(0, data.date_end.length - 2).replace(/\//g, '-'));
        } else {
          endDate = new Date(date);
        }
      } else {
        endDate = new Date();
      }
      if (endDate === 'Invalid Date' || date === 'Invalid date') {
        endDate = new Date();
      }

      let selectedStaff;
      selectedStaff = data.assigned_staff && data.assigned_staff.map(s => {
        return { label: `${s.first_name} ${s.last_name}`, value: s.id };
      });

      let selectedClient = [];
      if (data.companies && typeof data.companies === 'object') {
        selectedClient = data.companies && data.companies.map(s => {
          return { label: s.name, value: s.id };
        });
      }

      let selectedDebtors = [];
      if (data.debtors && typeof data.debtors === 'object') {
        selectedDebtors = data.debtors && data.debtors.map(s => {
          return { label: `${s.name_first} ${s.name_last}`, value: s.id };
        });
      }

      const selectedCreate = [];
      if (data.staff_id_created) {
        const createBy = this.state.allStaff.find(_ => _.value === data.staff_id_created);
        if (createBy)
          selectedCreate.push(createBy);
      }


      if (data.rpt_day) {
        this.state.rpt_day.map((item) => {
          data.rpt_day.map(sub => {
            if (sub === item.name) {
              item.checked = true;
            }
          });
        });
      }


      this.setState({
        id: data.id,
        task_type: { value: data.type, label: data.type },
        wip_no: this.state.wips.find((val) => val.value === this.props.data.wip_id),
        wipId: data.wip_id,
        title: data.title,
        internal: data.internal,
        staff: selectedStaff,
        client: selectedClient,
        debtor:selectedDebtors,
        sDate: endDate,

        priority: { value: data.priority, label: data.priority },
        eSubject: data.email_subject,
        eDescription: data.email_description,
        location: data.location,
        smaReminder: { value: data.csms_days, label: data.csms_days },
        dayAppointment: { value: data.csms_time, label: data.csms_time },
        useEnddate: data.rpt_use_end,
        textSMS: data.csms_msg,
        toNumber: data.csms_to,
        isEdit: isEdit,
        mainType: data.main_type,
        status: { value: data.is_done, label: (data.is_done ? 'Completed' : 'To Do') },
        taskSubject: data.subject,
        taskDescription: data.description,
        meet_with: data.meeting_with,
        rpt_use_end: data.rpt_use_end,
        assignto: selectedCreate,
        is_archived: data.is_archived,
        is_deleted: data.is_deleted,
        is_duplicated: data.is_duplicated,
        is_private: data.is_private,

        rType: repeatTypeOption.find(_ => _.label === data.schedule.repeat_type),
        repeat_date_start: data.schedule.start_date ? new Date(data.schedule.start_date) : '',
        repeat_date_end: data.schedule.end_date ? new Date(data.schedule.end_date) : '',
        rpt_day: data.schedule.selected_days,

        setEmail: {
          client: data.set_email.to === 'client' ? data.set_email.recipients.map(e => ({ value: e, label: e })) : [],
          debtor: data.set_email.to === 'debtors' ? data.set_email.recipients.map(e => ({ value: e, label: e })) : [],
          status: data.set_email.to,
          to: data.set_email.recipients,
          type: data.set_email.template,
          subject: data.set_email.subject,
          text: data.set_email.text,
          html: data.set_email.html,
          email_status_scheduled: data.set_email.schedule_email ? { value: true, label: 'Yes' } : {
            value: false,
            label: 'No',
          },
          email_time_scheduled: data.set_email.schedule_date ? moment(data.set_email.schedule_date).format('YYYY-MM-DDTHH:mm'):null,
          repeatEmail: data.set_email.repeat_type,
        },
        setSMS: {
          client: data.set_sms.to === 'client' ? data.set_sms.recipients.map(e => ({ value: e, label: e })) : [],
          debtors: data.set_sms.to === 'debtors' ? data.set_sms.recipients.map(e => ({ value: e, label: e })) : [],
          repeatSMS: data.set_sms.repeat_type,
          short_message_body: data.set_sms.text,
          sms_status_scheduled: data.set_sms.schedule_sms,
          sms_status_time_scheduled: data.set_sms.schedule_date ? moment(data.set_sms.schedule_date).format('YYYY-MM-DDTHH:mm'): null,
          status: data.set_sms.to,
          text: data.set_sms.text,
          to: data.set_sms.recipients,
          //type: value,
        },
      });
    }
  }

  getAllstaff() {
    const data = {};
    const {user} = this.context;
    this.staff.getStaff(data).then((res) => {
      if (res?.data) {
        const getData = res.data;
        let listStaff;
        listStaff = getData && getData.map((item) => {
          return { value: item.id, label: `${item.first_name}  ${item.last_name}` };
        });
        const initStaff = listStaff.find(_ => _.value === user.id)

        this.setState({ assignto: initStaff});
        this.setState({ allStaff: listStaff, allDataStaff: getData });
        this.addRecord();
      } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
        this.setState({ accessErrorModal: true });
      }
    });
  }

  getCompanies() {
    const data = {};
    this.services.getCompanies(data, (res) => {
      if (res.status === 200) {
        const getData = res.data.data;
        let listClient = getData && getData.map((item) => {
          return { value: item.id, label: item.name };
        });
        this.setState({
          allClient: listClient,
        });
        this.addRecord();
      } else if (
        res.statusCode === 401 &&
        res.responseType === 'UNAUTHORIZED'
      ) {
        this.setState({ accessErrorModal: true });
      }
    });
  }

  closeModal = () => {
    this.setState({ task_id: '' });
    this.props.closeModal();
  };

  handleSelectGeoSuggest = (place) => {
    if (!place) return null;
    this.setState({
      location: place.label,
    });
  };

  handleChange = (selectedOption, name) => {
    if ((name === 'calanderType') && (selectedOption.value === 'Appointment' || selectedOption.value === 'Task')) {
      this.setState({
        eDate: new Date(),
      });
    } else if ((name === 'calanderType') && (selectedOption.value === 'Note')) {
      this.setState({
        eDate: '',
      });
    }
    this.setState({ [name]: selectedOption });

    if (name === 'staff') {
      this.setState({ assignto: [] });
    }

    if (name === 'guest') {
      const list = [];
      if (selectedOption) {

        selectedOption.map(item => {
          list.push(item.value);
        });
        const data = {
          client_id: JSON.stringify(list),
        };
      }
    }
  };

  changeInput(e) {
    if (!e.target.validity.valid) {
      return null;
    }
    this.setState({ [e.target.name]: e.target.value });
  }

  handleChangeDate = (date, name) => {
    this.setState({
      [name]: date,
    });
    if (name === 'recur_enddate') {
      this.setState({ recur_enddate: date });
    }
  };

  handleOptionChange = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  async saveTask(isCopy) {

    const {user} = this.context;
    let self;
    const staffList = [];
    const clientList = [];
    const debtorList = [];
    const all = this.state.allDataStaff;
    if (this.state.staff) {
      this.state.staff.map(item => {
        const color = all.find(_ => _.id === item.value);
        const list = {
          id: item.value,
        };
        staffList.push(list);
      });
    }
    if (this.state.client) {
      this.state.client.map(item => {
        const list = {
          id: item.value,
        };
        clientList.push(list);
      });
    }

    if (this.state.debtor) {
      this.state.debtor.map(item => {
        const list = {
          id: item.value,
        };
        debtorList.push(list);
      });
    }


    const formData = {
      type: this.state.task_type && this.state.task_type.value,
      priority: this.state.priority && this.state.priority.value,
      is_done: this.state.status && this.state.status.value,
      is_archived: this.state.is_archived,
      is_deleted: this.state.is_deleted,
      is_duplicated: this.state.is_duplicated,
      is_private: this.state.is_private,
      subject: this.state.taskSubject,
      title: this.state.title,
      meeting_with: this.state.meet_with,
      location: this.state.location,
      companies: clientList,
      assigned_staff: staffList,
      staff_id_created: (this.state.assignto && this.state.assignto.value ? this.state.assignto && this.state.assignto.value : user.id),
      staff_id_modified: (this.state.assignto && this.state.assignto.value ? this.state.assignto && this.state.assignto.value : user.id),
      wip_id: this.state.wipId,
      description: this.state.taskDescription,
      date_start: this.state.sDate,
      date_end: this.state.sDate,
    };
    if (this.state.debtor) {
      formData.debtors = debtorList;
    }
    if (this.state.rType?.label || this.state.repeatSchedule) {
      formData.schedule = {
        repeat_type: this.state.rType?.label,
        start_date: this.state.repeat_date_start,
        end_date: this.state.repeat_date_end,
        selected_days: this.state.rpt_day,
      };
    }
    if (this.state.setEmail.status) {
      const date = new Date(this.state.setEmail.email_time_scheduled);
      formData.set_email = {
        to: this.state.setEmail.status,
        recipients: this.state.setEmail.to,
        template: this.state.setEmail?.type,
        subject: this.state.setEmail.subject,
        text: this.state.setEmail.text,
        html: this.state.setEmail.html,
        schedule_email: this.state.setEmail?.email_status_scheduled?.value,
        schedule_date: this.state.setEmail?.email_time_scheduled ? date.toISOString() : null,
        repeat_type: this.state.setEmail.repeatEmail,
      };
    }
    if (this.state.setSMS.status) {
      const date = new Date(this.state.setSMS.sms_status_time_scheduled);
      formData.set_sms = {
        to: this.state.setSMS.status,
        recipients: this.state.setSMS.to,
        template: this.state.setSMS.type?.label,
        text: this.state.setSMS.text,
        schedule_sms: this.state.setSMS.sms_status_scheduled.value,
        schedule_date: this.state.setSMS.sms_status_time_scheduled ? date.toISOString() : null,
        repeat_type: this.state.setSMS.repeatSMS,
      };
    }

    if (this.validator.allValid()) {
      if (this.state.isEdit && this.state.id && isCopy === 'no') {
        let id = this.state.id;
        self = this;
        let res = await this.task.editTask(formData, id);
        if (res.status === 200) {
          this.services.successToast('Task Updated Successfully');
          this.closeModal();
          if(self.props.reloadList){
            self.props.reloadList();
          }
        } else if (
          res.statusCode === 401 &&
          res.responseType === 'UNAUTHORIZED'
        ) {
          this.setState({ accessErrorModal: true });
        } else {
          this.services.errorToast(res.message);
        }

      } else {

        self = this;
        await this.task.addTask(formData).then((res) => {
          if (res.status === 201) {
            this.services.successToast('Task Added Successfully');
            this.closeModal();
            if(self.props.reloadList){
            self.props.reloadList();
            }
          } else if (
            res.statusCode === 401 &&
            res.responseType === 'UNAUTHORIZED'
          ) {
            this.setState({ accessErrorModal: true });
          } else {
            this.services.errorToast(res);
          }
        });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  handleSelectDay(e) {
    this.setState({ rpt_day: [e] });
  }

  handleUseEndDate = (e) => {
    if (e) {
      if (e.target.checked) {
        this.setState({ recur_enddate: this.state.eDate });
      } else {
        this.setState({ recur_enddate: new Date() });
      }
      this.setState({ rpt_use_end: e.target.checked });
    }
  };

  handleChangeTab = (event, newValue) => {
    this.setState({ valueTab: newValue });
  };

  nextTab = (newValue) => {
    if (this.validator.allValid()) {
      this.setState({ valueTab: newValue + 1 });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleChangeWip = (selectedOption, name) => {
    this.setState({ wipId: selectedOption.value });
    this.setState({ [name]: selectedOption });
  };

  handleChangeSetEmail = (data, value) => {

    this.setState({ setEmail: data });
    this.setState({ valueTab: value + 1 });
  };

  handleChangeSetSMS = (data, value) => {

    this.setState({ setSMS: data });
    this.setState({ valueTab: value + 1 });
  };

  ucFirst(str) {
    if (!str) return str;
    return str[0].toUpperCase() + str.slice(1);
  }

  stopSchedule = () => {
    this.setState({
      rType: '',
      repeat_date_start: null,
      repeat_date_end: null,
      rpt_day: [],
      repeatSchedule: true,
    });
  };

  render(
  ) {
    const { tempSMS } = this.state;
    return (
      <div>
        <Modal
          isOpen={this.props.addTaskModal} size='lg' style={{ maxWidth: '1000px', width: '95%' }}
          className='theme-light addtaskModal'
        >
          <form className=''>
            <div className='modal__header pt-0' style={{ marginBottom: '25px' }}>
              <div className='flex_jusbt'>
                <h3 className='mb-2'> {this.props.isEdit ? 'Update Your Task' : 'Create your Task'}</h3>
              </div>
            </div>
            <div className='modal__body'>
              <Tabs indicatorColor='orange'
                    textColor='inherit'
                    variant='fullWidth'
                    value={this.state.valueTab}
                    onChange={this.handleChangeTab}>
                <Tab className={this.state.valueTab === 0 ? 'btn btn-secondary-active' : 'btn btn-secondary-tab'}
                     label='Task Details' />
                <Tab className={this.state.valueTab === 1 ? 'btn btn-secondary-active' : 'btn btn-secondary-tab'}
                     label='Email' />
                <Tab className={this.state.valueTab === 2 ? 'btn btn-secondary-active' : 'btn btn-secondary-tab'}
                     label='SMS' />
                <Tab className={this.state.valueTab === 3 ? 'btn btn-secondary-active' : 'btn btn-secondary-tab'}
                     label='SCHEDULE' />
                <Tab className={this.state.valueTab === 4 ? 'btn btn-secondary-active' : 'btn btn-secondary-tab'}
                     label='SUMMARY' />
              </Tabs>
              <div style={{ marginTop: '5px' }}>
                {this.state.valueTab === 0 &&
                <div className='innerContent'>
                  <Row className='form'>
                    <Col md={6}>
                      <div className='form-group'>
                        <label className='form__form-group-label' htmlFor=''>Type <span
                          style={{ color: '#ff0000' }}>*</span></label>
                        <Select
                          name='task_type'
                          type='text'
                          options={typeOption}
                          classNamePrefix='react-select'
                          className='react-select'
                          value={this.state.task_type}
                          onChange={(e) => this.handleChange(e, 'task_type')}
                        />
                        <span
                          className='form__form-group-error'>{this.validator.message('Type', this.state.task_type, 'required')}</span>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className='form-group'>
                        <label className='form__form-group-label' htmlFor=''>Associated to a WIP<span
                          style={{ color: '#ff0000' }}>*</span></label>
                        <Select
                          name='wip_no'
                          type='text'
                          options={this.state.wips}
                          classNamePrefix='react-select'
                          className='react-select'
                          value={this.state.wip_no}
                          onChange={(e) => this.handleChangeWip(e, 'wip_no')}
                        />
                        <span
                          className='form__form-group-error'>{this.validator.message('Associated to a WIP', this.state.wip_no, 'required')}</span>
                      </div>
                    </Col>
                  </Row>
                  <Row className='align-items-center'>
                    <Col md={12}>
                      <div className='form-group'>
                        <label className='form__form-group-label' htmlFor=''>Title of Task/Appointment<span
                          style={{ color: '#ff0000' }}>*</span></label>
                        <input
                          type='text'
                          className='modal_input'
                          name='title'
                          value={this.state.title}
                          onChange={this.changeInput}
                        />
                        <span
                          className='form__form-group-error'>
                          {this.validator.message('Title', this.state.title, 'required')}</span>
                        <span
                          className='form__form-group-error'>
                          {this.validatorEmail.message('Title', this.state.title, 'required')}</span>
                      </div>
                    </Col>
                  </Row>
                  <Row className='form'>
                    <Col md={6}>
                      <div className='form-group'>
                        <label className='form__form-group-label ' htmlFor=''>Assign to staff<span
                          style={{ color: '#ff0000' }} /></label>
                        <Select
                          name='staff'
                          type='text'
                          options={this.state.allStaff}
                          classNamePrefix='react-select'
                          className='react-select height_auto'
                          value={this.state.staff}
                          isMulti
                          onChange={(e) => this.handleChange(e, 'staff')}
                        />
                        {/* <span className="form__form-group-error">{this.validator.message('', this.state.staff, 'required')}</span> */}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className='form-group'>
                        <label className='form__form-group-label ' htmlFor=''>Client<span
                          style={{ color: '#ff0000' }} /></label>
                        <Select
                          name='client'
                          type='text'
                          options={this.state.allClient}
                          classNamePrefix='react-select'
                          className='react-select height_auto'
                          value={this.state.client}
                          isMulti
                          onChange={(e) => this.handleChange(e, 'client')}
                        />
                        {/* <span className="form__form-group-error">{this.validator.message('', this.state.client, 'required')}</span> */}
                      </div>
                    </Col>
                  </Row>
                  <Row className='form'>
                    <Col md={6}>
                      <div className='form-group'>
                        <label className='form__form-group-label ' htmlFor=''>Debtor<span
                          style={{ color: '#ff0000' }} /></label>
                        <Select
                          name='debtor'
                          type='text'
                          options={this.state.debtorsOption}
                          classNamePrefix='react-select'
                          className='react-select height_auto'
                          value={this.state.debtor}
                          onChange={(e) => this.handleChange(e, 'debtor')}
                          isMulti
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className='form-group form'>
                        <label className='form__form-group-label ' htmlFor=''>Status<span
                          style={{ color: '#ff0000' }}>*</span></label>
                        <Select
                          name='status'
                          type='text'
                          options={[{ value: false, label: 'To Do' }, { value: true, label: 'Completed' }]}
                          classNamePrefix='react-select'
                          className='react-select'
                          value={this.state.status}
                          onChange={(e) => this.handleChange(e, 'status')}
                        />
                      </div>
                      <span
                        className='form__form-group-error'>{this.validator.message('Status', this.state.status, 'required')}</span>
                    </Col>
                  </Row>
                  <Row className='align-items-center'>
                    <Col sm={12}>
                      <div className='form-group'>
                        <label className='form__form-group-label pt-0' htmlFor=''>Subject<span
                          style={{ color: '#ff0000' }}>*</span></label>
                        <input
                          type='text'
                          placeholder={'Subject to go here...'}
                          className='modal_input'
                          name='taskSubject'
                          value={this.state.taskSubject || ''}
                          onChange={this.changeInput}
                        />
                        <span
                          className='form__form-group-error'>
                          {this.validator.message(' Subject', this.state.taskSubject, 'required')}</span>
                        <span
                          className='form__form-group-error'>
                          {this.validatorEmail.message(' Subject', this.state.taskSubject, 'required')}</span>
                      </div>
                    </Col>
                  </Row>
                  <Row className='align-items-center'>
                    <Col sm={12}>
                      <div className='form-group'>
                        <label className='form__form-group-label pt-0' htmlFor=''>Description<span
                          style={{ color: '#ff0000' }} /></label>
                        <textarea className='modal_textarea'
                                  placeholder='What is this about?...'
                                  onChange={this.changeInput}
                                  value={this.state.taskDescription}
                                  type='textarea' name='taskDescription'
                                  id='exampleText' />
                        <span
                          className='form__form-group-error'>
                          {this.validator.message(' Description', this.state.taskDescription, 'required')}</span>
                        <span
                          className='form__form-group-error'>
                          {this.validatorEmail.message(' Description', this.state.taskDescription, 'required')}</span>
                      </div>
                    </Col>
                  </Row>

                  <Row className='align-items-center form'>
                    <Col>
                      <Row className='align-items-center'>
                        <Col md={6}>
                          <div className='form-group'>
                            <label className='form__form-group-label' htmlFor=''>Priority<span
                              style={{ color: '#ff0000' }}>*</span></label>
                            <Select
                              name='priority'
                              type='text'
                              options={priorityOption}
                              classNamePrefix='react-select'
                              className='react-select'
                              value={this.state.priority}
                              onChange={(e) => this.handleChange(e, 'priority')}
                            />
                            <span
                              className='form__form-group-error'>{this.validator.message('priority', this.state.priority, 'required')}</span>
                          </div>
                        </Col>

                        <Col md={6}>
                          <div className='form-group'>
                            <label className='form__form-group-label' htmlFor=''>Created By<span
                              style={{ color: '#ff0000' }} /></label>
                            <Select
                              name='assignto'
                              type='text'
                              options={this.state.allStaff}
                              classNamePrefix='react-select'
                              className='react-select'
                              value={this.state.assignto}
                              onChange={(e) => this.handleChange(e, 'assignto')}
                              isDisabled={true}
                            />
                          </div>
                        </Col>
                        <Col>

                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className='align-items-center form'>
                    <Col md={6}>
                      <div className='form-group'>
                        <label className='form__form-group-label' htmlFor=''>Task Due Date<span
                          style={{ color: '#ff0000' }}>*</span></label>
                        <div className='date-picker date-picker--interval'>
                          <DatePicker
                            selected={this.state.sDate}
                            onChange={(e) => this.handleChangeDate(e, 'sDate')}
                            name='sDate'
                            dateFormat='dd/MM/yy'
                            dropDownMode='select'
                            popperPlacement='center'
                            minDate={new Date()}
                            placeholderText='Select Date'
                          />
                          <span className='date_icon'>
                                                    <img src={'./image/calendar.svg'} alt='date' />
                                                </span>
                        </div>
                      </div>
                    </Col>
                  </Row>

                </div>
                }
                {this.state.valueTab === 1 &&
                <div style={{ paddingTop: '15px' }}>
                  <SetEmailTaskManager
                    handleChangeSetEmail={this.handleChangeSetEmail}
                    wipID={this.state.wipId}
                    valueTab={this.state.valueTab}
                    closeModal={this.props.closeModal}
                    setEmail={this.state.setEmail}
                  />
                </div>
                }
                {this.state.valueTab === 2 &&
                <div style={{ paddingTop: '15px' }}>
                  <SetSMSTaskManager
                    handleChangeSetSMS={this.handleChangeSetSMS}
                    wipID={this.state.wipId}
                    valueTab={this.state.valueTab}
                    closeModal={this.props.closeModal}
                    setSMS={this.state.setSMS}
                    tempSMS={tempSMS}
                  />
                </div>
                }
                {this.state.valueTab === 3 &&
                <div style={{ paddingTop: '15px' }}>
                  {this.state.calanderType && this.state.calanderType.value !== 'Note' &&
                  <>
                    <h3 style={{ color: 'black' }} className='form__form-group'>Schedule your Task</h3>
                    <div style={{ padding: '10px 0px 10px' }} className='innerContent'>
                      <Row className='form'>
                        <Col md={12}>
                          <div className='form-group'>
                            <label className='form__form-group-label' htmlFor=''> Repeat type:</label>
                            <Select
                              name='rType'
                              type='text'
                              options={repeatTypeOption}
                              classNamePrefix='react-select'
                              className='react-select'
                              value={this.state.rType}
                              onChange={(e) => this.handleChange(e, 'rType')}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className='align-items-center form'>
                        <Col md={6}>
                          <div className='form-group'>
                            <label className='form__form-group-label' htmlFor=''>Start date<span
                              style={{ color: '#ff0000' }}>*</span></label>
                            <div className='date-picker date-picker--interval'>
                              <DatePicker
                                selected={this.state.repeat_date_start}
                                onChange={(e) => this.handleChangeDate(e, 'repeat_date_start')}
                                name='repeat_date_start'
                                dateFormat='dd/MM/yy'
                                dropDownMode='select'
                                popperPlacement='center'
                                minDate={new Date()}
                                placeholderText='Select Date'
                              />
                              <span className='date_icon'>
                                <img src={'./image/calendar.svg'} alt='date' />
                              </span>
                            </div>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className='form-group'>
                            <label className='form__form-group-label' htmlFor=''>End date<span
                              style={{ color: '#ff0000' }}>*</span></label>
                            <div className='date-picker date-picker--interval'>
                              <DatePicker
                                selected={this.state.repeat_date_end}
                                onChange={(e) => this.handleChangeDate(e, 'repeat_date_end')}
                                name='repeat_date_end'
                                dateFormat='dd/MM/yy'
                                dropDownMode='select'
                                popperPlacement='center'
                                minDate={new Date()}
                                placeholderText='Select Date'
                              />
                              <span className='date_icon'>
                                <img src={'./image/calendar.svg'} alt='date' />
                              </span>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <div className='form-group'>
                            <label className='form__form-group-label' htmlFor=''>Select days</label>
                            <Select
                              name='rpt_day'
                              type='text'
                              options={daysOption}
                              classNamePrefix='react-select'
                              className='react-select height_auto'
                              isMulti
                              value={this.state.rpt_day}
                              onChange={(e) => this.handleChange(e, 'rpt_day')}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </>}
                </div>
                }
                {this.state.valueTab === 4 && <>
                  <h3 style={{ paddingTop: '15px' }} className='page-title'>Summary</h3>
                  <div style={{ paddingLeft: '30px' }}>

                    {/*Task details*/}

                    <div className='pl-0'>
                      <h4 style={{ color: '#0a090999' }} className='card-link' data-toggle='collapse'>
                        Task Details:
                      </h4>
                    </div>
                    <div className='summary'>
                      <Row className='form'>
                        <Col md={4}>
                          <div className='form-group'>
                            <label className='form__form-group-label' htmlFor=''>Type</label>
                            <span
                              className={'summary-value'}>{this.state.task_type?.label ? this.state.task_type?.label : '-'}</span>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className='form-group'>
                            <label className='form__form-group-label' htmlFor=''>Associated to a WIP</label>
                            <span
                              className={'summary-value'}>{this.state.wip_no?.label ? this.state.wip_no?.label : '-'}</span>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className='form-group'>
                            <label className='form__form-group-label' htmlFor=''>Title of Task/Appointment</label>
                            <span className={'summary-value'}>{this.state.title ? this.state.title : '-'}</span>
                          </div>
                        </Col>
                      </Row>
                      <Row className='form'>
                        <Col md={4}>
                          <div className='form-group'>
                            <label className='form__form-group-label ' htmlFor=''>Assign to staff</label>
                            <span
                              className={'summary-value'}>{this.state.staff ? this.state.staff.map((e) => `${e.label} `) : '-'}</span>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className='form-group'>
                            <label className='form__form-group-label ' htmlFor=''>Client</label>
                            <span
                              className={'summary-value'}>{this.state.client ? this.state.client.map(e => `${e.label} `) : '-'}</span>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className='form-group'>
                            <label className='form__form-group-label ' htmlFor=''>Debtor</label>
                            <span
                              className={'summary-value'}>{this.state.debtor ? this.state.debtor.map(e => `${e.label} `) : '-'}</span>
                          </div>
                        </Col>
                      </Row>
                      <Row className='form'>

                      </Row>
                      <Row className='align-items-center form'>
                        <Col>
                          <Row className='align-items-center'>
                            <Col md={4}>
                              <div className='form-group'>
                                <label className='form__form-group-label' htmlFor=''>Priority</label>
                                <span
                                  className={'summary-value'}>{this.state.priority?.label ? this.state.priority?.label : '-'}</span>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className='form-group'>
                                <label className='form__form-group-label' htmlFor=''>Created By</label>
                                <span
                                  className={'summary-value'}>{this.state.assignto?.label ? this.state.assignto?.label : '-'}</span>
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className='form-group'>
                                <label className='form__form-group-label' htmlFor=''>Task Due Date</label>
                                <span
                                  className={'summary-value'}>{this.state.sDate ? moment(this.state.sDate).format('DD-MM-YYYY') : '-'}</span>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className='align-items-center'>
                        <Col sm={4}>
                          <div className='form-group'>
                            <label className='form__form-group-label pt-0' htmlFor=''>Subject </label>
                            <span
                              className={'summary-value'}>{this.state.taskSubject ? this.state.taskSubject : '-'}</span>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className='form-group form'>
                            <label className='form__form-group-label ' htmlFor=''>Status</label>
                            <span
                              className={'summary-value'}>{this.state.status?.label ? this.state.status?.label : '-'}</span>
                          </div>
                        </Col>
                      </Row>
                      <Row className='align-items-center'>
                        <Col sm={12}>
                          <div className='form-group'>
                            <label className='form__form-group-label pt-0' htmlFor=''>Description</label>
                            <span
                              className={'summary-value'}>{this.state.taskDescription ? this.state.taskDescription : '-'}</span>
                          </div>
                        </Col>
                      </Row>
                    </div>

                    {/*Email*/}

                    {this.state.setEmail?.status && <>
                      <div style={{ marginTop: '-50px' }} className='pl-0'>
                        <hr />
                        <h4 style={{ color: '#0a090999' }} className='card-link' data-toggle='collapse'>
                          Email:
                        </h4>
                      </div>
                      <div className='summary'>
                        <form>
                          <Row className='form'>
                            <Col md={4}>
                              <div className='form-group'>
                                <label className='form__form-group-label' htmlFor=''>To</label>
                                <span
                                  className={'summary-value'}>{this.state.setEmail?.status ? this.ucFirst(this.state.setEmail.status) : '-'}</span>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className='form-group'>
                                <label className='form__form-group-label' htmlFor=''>Recipients</label>
                                <span
                                  className={'summary-value'}>{this.state.setEmail?.to ? this.state.setEmail?.to.map(e => `${e} `) : '-'}</span>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className='form-group'>
                                <label className='form__form-group-label' htmlFor=''>Template</label>
                                <span
                                  className={'summary-value'}>{this.state.setEmail?.type ? this.state.setEmail?.type?.label : '-'}</span>
                              </div>
                            </Col>
                          </Row>
                          <Row className='align-items-center'>
                            <Col sm={12}>
                              <div className='form-group'>
                                <label className='form__form-group-label pt-0' htmlFor=''>Subject</label>
                                <span
                                  className={'summary-value'}>{this.state.setEmail?.subject ? this.state.setEmail?.subject : '-'}</span>
                              </div>
                            </Col>
                          </Row>
                          <Row className='align-items-center'>
                            <Col sm={12}>
                              <div className='form-group'>
                                <label className='form__form-group-label pt-0' htmlFor=''>Text</label>
                                <span
                                  className={'summary-value'}>{this.state.setEmail?.text ? this.state.setEmail?.text : '-'}</span>
                              </div>
                            </Col>
                          </Row>
                          {this.state.setEmail?.email_status_scheduled !== undefined && <Row className='form'>
                            <Col md={4}>
                              <div className='form-group'>
                                <label className='form__form-group-label' htmlFor=''>Schedule Email</label>
                                <span
                                  className={'summary-value'}>{this.state.setEmail?.email_status_scheduled ? this.state.setEmail?.email_status_scheduled.label : '-'}</span>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className='form-group'>
                                <label className='form__form-group-label' htmlFor=''>Schedule Date</label>
                                <span
                                  className={'summary-value'}>{this.state.setEmail?.email_time_scheduled ? moment(this.state.setEmail?.email_time_scheduled).format('DD-MM-YYYY') : '-'}</span>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className='form-group'>
                                <label className='form__form-group-label' htmlFor=''>Repeat type</label>
                                <span
                                  className={'summary-value'}>{this.state.setEmail?.repeatEmail ? this.state.setEmail?.repeatEmail : '-'}</span>
                              </div>
                            </Col>
                          </Row>}
                        </form>
                      </div>
                    </>}

                    {/*SMS*/}

                    {this.state.setSMS?.status && <>
                      <div style={{ marginTop: '-50px' }} className='pl-0'>
                        <hr />
                        <h4 style={{ color: '#0a090999' }} className='card-link' data-toggle='collapse'>
                          SMS:
                        </h4>
                      </div>
                      <div className='summary'>
                        <form>
                          <Row className='form'>
                            <Col md={4}>
                              <div className='form-group'>
                                <label className='form__form-group-label' htmlFor=''>To</label>
                                <span
                                  className={'summary-value'}>{this.state.setSMS?.status ? this.ucFirst(this.state.setSMS.status) : '-'}</span>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className='form-group'>
                                <label className='form__form-group-label' htmlFor=''>Recipients</label>
                                <span
                                  className={'summary-value'}>{this.state.setSMS?.to ? this.state.setSMS?.to.map(e => `${e} `) : '-'}</span>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className='form-group'>
                                <label className='form__form-group-label' htmlFor=''>Template</label>
                                <span
                                  className={'summary-value'}>{this.state.setSMS?.type ? this.state.setSMS?.type?.label : '-'}</span>
                              </div>
                            </Col>
                          </Row>
                          <Row className='align-items-center'>
                            <Col sm={12}>
                              <div className='form-group'>
                                <label className='form__form-group-label pt-0' htmlFor=''>Text</label>
                                <span
                                  className={'summary-value'}>{this.state.setSMS?.text ? this.state.setSMS?.short_message_body : '-'}</span>
                              </div>
                            </Col>
                          </Row>
                          {this.state.setSMS?.sms_status_scheduled !== undefined && <Row className='form'>
                            <Col md={4}>
                              <div className='form-group'>
                                <label className='form__form-group-label' htmlFor=''>Schedule Email</label>
                                <span
                                  className={'summary-value'}>{this.state.setSMS?.sms_status_scheduled ? this.state.setSMS?.sms_status_scheduled.label : '-'}</span>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className='form-group'>
                                <label className='form__form-group-label' htmlFor=''>Schedule Date</label>
                                <span
                                  className={'summary-value'}>{this.state.setSMS?.sms_status_time_scheduled ? moment(this.state.setSMS?.sms_status_time_scheduled).format('DD-MM-YYYY') : '-'}</span>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className='form-group'>
                                <label className='form__form-group-label' htmlFor=''>Repeat type</label>
                                <span
                                  className={'summary-value'}>{this.state.setSMS?.repeatSMS ? this.state.setSMS?.repeatSMS : '-'}</span>
                              </div>
                            </Col>
                          </Row>}
                        </form>
                      </div>
                    </>}

                    {/*Schedule*/}

                    {this.state.rType?.label && <>
                      <div style={{ marginTop: '-50px' }} className='pl-0'>
                        <hr />
                        <h4 style={{ color: '#0a090999' }} className='card-link' data-toggle='collapse'>
                          Schedule:
                        </h4>
                      </div>
                      <div className='summary'>
                        <form>
                          <Row className='form'>
                            <Col md={4}>
                              <div className='form-group'>
                                <label className='form__form-group-label' htmlFor=''>Repeat type</label>
                                <span
                                  className={'summary-value'}>{this.state.rType?.label ? this.state.rType?.label : '-'}</span>
                              </div>
                            </Col>
                            <Col sm={4}>
                              <div className='form-group'>
                                <label className='form__form-group-label pt-0' htmlFor=''>Start date</label>
                                <span
                                  className={'summary-value'}>{this.state.repeat_date_start ? moment(this.state.repeat_date_start).format('DD-MM-YYYY') : '-'}</span>
                              </div>
                            </Col>
                            <Col sm={4}>
                              <div className='form-group'>
                                <label className='form__form-group-label pt-0' htmlFor=''>End date</label>
                                <span
                                  className={'summary-value'}>{this.state.repeat_date_end ? moment(this.state.repeat_date_end).format('DD-MM-YYYY') : '-'}</span>
                              </div>
                            </Col>
                          </Row>
                          <Row className='align-items-center'>
                            <Col sm={12}>
                              <div className='form-group'>
                                <label className='form__form-group-label pt-0' htmlFor=''>Select days</label>
                                <span
                                  className={'summary-value'}>{this.state.rpt_day ? this.state.rpt_day.map(e => `${e.label} `) : '-'}</span>
                              </div>
                            </Col>
                          </Row>
                        </form>
                      </div>
                    </>}
                  </div>
                </>
                }
              </div>
              {this.state.valueTab === 1 || this.state.valueTab === 2 ? ' ' : <div>
                <hr />
                <div className={'button_group_custom'}>
                  <div>{this.props.isEdit ? <button className='btn-gray mr-2 mb-2' type='button'
                                                    onClick={() => this.saveTask('yes')}>Duplicate</button> : ''}</div>
                  <div>
                    {this.state.valueTab === 3 &&
                    <button className='btn-gray mr-3 mb-2' type='button' onClick={this.stopSchedule}>Stop
                      Schedule</button>}
                    <button className='btn-gray mr-3 mb-2' type='button' onClick={this.props.closeModal}>Cancel</button>
                    {this.state.valueTab === 4 ?
                      <button style={{ padding: '9px 30px 11px 30px' }} className='btn btn-secondary m-0 mb-0'
                              type='button' onClick={() => this.saveTask('no')}>Save
                      </button> :
                      <button style={{ padding: '9px 30px 11px 30px' }} className='btn btn-secondary m-0 mb-0'
                              type='button'
                              onClick={() => this.nextTab(this.state.valueTab)}>Next
                      </button>}
                  </div>
                </div>
              </div>}
            </div>
          </form>
        </Modal>
      </div>
    );
  }
}

export default withRouter(AddTaskModal);
