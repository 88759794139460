import { FC, useEffect, useState } from 'react'
import GenericSendSmsModal from '../../../../components/GenricSendSmsModal/generic-send-sms-modal'
import { REACT_APP_API_URL } from '@src/lib/global-variables'
import axios from 'axios'
import { useQueryClient } from '@tanstack/react-query'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import { ShortMessageTo } from '@containers/Communication/types/sms.type'

interface SendSmsModalToDebtorProps {
  wipId?: number | null
  isOpen: boolean
  onClose: () => void
  debtorId?: number
}

const SendSmsModalToDebtor: FC<SendSmsModalToDebtorProps> = ({
  isOpen,
  onClose,
  debtorId = null,
  wipId = null,
}) => {
  const queryClient = useQueryClient()
  const [senderOptions, setSenderOptions] = useState<{ label: string; value: string }[] | null>(
    null,
  )

  const [smsTemplates, setSmsTemplates] = useState<{ label: string; value: string }[] | null>(null)

  const additionalValuesInRequeset = {
    debtor_id: debtorId,
    wip_id: wipId,
    to_mode: ShortMessageTo.Debtor,
  }

  const getSenderOptions = async () => {
    if (wipId) {
      return getSenderOptionsForWip(wipId)
    }
    if (debtorId) {
      return getSenderOptionsForDebtor(debtorId)
    }
  }

  const getSenderOptionsForDebtor = async (debtorId: number) => {
    const params = {
      join: 'contact',
    }
    const response = await axios.get(`${REACT_APP_API_URL}/debtors/${debtorId}`, { params })

    const data = response.data

    const phone = data.contact.comms_ph_mobile
    const label = `${data.name_first} ${data.name_last}`

    setSenderOptions(phone ? [{ label, value: phone }] : [])
  }

  const getSenderOptionsForWip = async (wipId: number) => {
    const params = {
      join: ['debtors', 'debtors.contact'],
    }

    const response = await axios.get(`${REACT_APP_API_URL}/wips/${wipId}`, { params })

    const data = response.data

    const senderOptions = data.debtors
      .map((debtor) => {
        const contact = debtor?.contact

        if (!contact) {
          return undefined
        }

        return {
          label: `${debtor.name_first} ${debtor.name_last}`,
          value: contact.comms_ph_mobile,
        }
      })
      .filter((option) => !!option && !!option.value)

    setSenderOptions(senderOptions)
  }

  const fetchSmsTemplate = async () => {
    const response = await axios.get(`${REACT_APP_API_URL}/short-message-templates`)
    const data = response.data.data

    const formatedTemplates =
      data.map((template) => ({
        label: template?.sms_template_title,
        value: template?.sms_template_body,
      })) || []

    setSmsTemplates(formatedTemplates)
  }

  useEffect(() => {
    if ((debtorId || wipId) && isOpen) {
      getSenderOptions()
    }
  }, [debtorId, isOpen])

  useEffect(() => {
    fetchSmsTemplate()
  }, [])

  const isInnerOpen = (debtorId || wipId) && isOpen && senderOptions && smsTemplates

  return (
    <GenericSendSmsModal
      sendToOther
      senderOptions={senderOptions || []}
      smsTemplates={smsTemplates || []}
      isOpen={!!isInnerOpen}
      onClose={onClose}
      additionalValuesInRequeset={additionalValuesInRequeset}
      onSmsSend={() => queryClient.invalidateQueries([GLOBAL_QUERIES.SMS.GET])}
    />
  )
}

export default SendSmsModalToDebtor
