import { deleteInvoiceByIDActionEnum } from '../actionTypes';
import { deleteInvoicByIDService } from '../service';

export const deleteInvoiceById = (id) => async (dispatch) => {
  dispatch({ type: deleteInvoiceByIDActionEnum.IS_LOADING_DELETE_INVOICE_BY_ID });
  try {
    const res = await deleteInvoicByIDService(id);

    dispatch({
      type: deleteInvoiceByIDActionEnum.DELETE_INVOICE_BY_ID_SUCCESS,
      payload: res,
    });

    return res;
  } catch (error) {
    dispatch({
      type: deleteInvoiceByIDActionEnum.DELETE_INVOICE_BY_ID_ERROR,
      payload: error,
    });
  }
};
