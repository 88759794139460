import { FC } from "react";
import { INoteForm } from "./notes-form.type";
import { UseFormRegister, UseFormSetValue } from "react-hook-form";
import { Sms } from "../../taskboard/types";

export enum NOTE_TAB {
    NOTE = 'note',
    EMAIL = 'email',
    SMS = 'sms'
}

export interface NotesNavigationProps {
    onTabClick: (tab: NOTE_TAB) => void;
    activeTab: NOTE_TAB;
}

export interface NotesProps {

}

export interface INoteType {
    id: number,
    name: string,
}

export interface NoteTypeListProps {
    notesAppType?: INoteType[],
    register: UseFormRegister<INoteForm>,
    setValue: UseFormSetValue<INoteForm>,
    custom_type: string,
}

export interface TaskNote {
    id: number;
    content: string;
    is_important: boolean;
    created_at: Date;
    custom_type: string;
    task: {
        id: number;
        title: string;
    }
    staff_created: {
        id: number;
        first_name: string;
        last_name: string;
    }
}


export interface NoteListProps {
    selectNote: (id: number | null) => void
}

export interface NoteItemProps {
    note: TaskNote
    selectNote: (id: number | null) => void
    openViewModal: (id: number) => void
}

export interface AddNoteProps {
    selectedNote: number | null;
    selectNote: (id: number | null) => void
}

export interface NoteFilterProps {
    onChange: (selectedOptions: Record<string, string[]>) => void
}

export type NoteTabComponents = Record<NOTE_TAB, FC>;


export interface SmsItemProps {
    sms: Sms
    openViewModal: (id: number) => void
}


export interface SendSmsToStaffProps {
    clientId: number | null
    isOpen: boolean
    onClose: () => void
}
export interface SendEmailToStaffModalProps {
    clientId: number | null
    isOpen: boolean
    onClose: () => void
}