import React from 'react';

import { Grid, Typography } from '@mui/material';

import FormInput from '../../../../../shared/components/FormInput/FormInput';

import { commercialUncoveredFormStyles } from '../../styles';

const OwnerDetails = ({ handleChange, values, errors, touched }) => {
  return (
    <Grid item sx={commercialUncoveredFormStyles?.collectionContainer}>
      <Typography sx={commercialUncoveredFormStyles?.collectionTitle}>Owner Details</Typography>

      <FormInput
        changeHandler={handleChange}
        label="Name"
        name="owner_details.name"
        placeholder="Name"
        type="text"
        value={values?.owner_details?.name}
        required={true}
        error={errors?.owner_details?.name}
        touched={touched?.owner_details?.name}
      />

      <FormInput
        changeHandler={handleChange}
        label="Address"
        name="owner_details.address"
        placeholder="Address"
        type="text"
        value={values?.owner_details?.address}
        required={true}
        error={errors?.owner_details?.address}
        touched={touched?.owner_details?.address}
      />

      <FormInput
        changeHandler={handleChange}
        label="Phone"
        name="owner_details.phone"
        placeholder="Phone"
        type="text"
        value={values?.owner_details?.phone}
        required={true}
        error={errors?.owner_details?.phone}
        touched={touched?.owner_details?.phone}
      />

      <FormInput
        changeHandler={handleChange}
        label="Email"
        name="owner_details.email"
        placeholder="Email"
        type="text"
        value={values?.owner_details?.email}
        required={true}
        error={errors?.owner_details?.email}
        touched={touched?.owner_details?.email}
      />
    </Grid>
  );
};

export default OwnerDetails;
