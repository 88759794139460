import { useTaskContext } from '@src/context/task-context'
import styles from './add-assignees.module.scss'
import { useUserContext } from '@src/context/user-context'
import { useEffect, useRef, useState } from 'react'
import useOutsideClick from '@src/hooks/useOutside'
import { RULES_TO_ASSIGN_TASK } from '@src/utils'
import cls from 'classnames'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import axios from 'axios'
import { REACT_APP_API_URL } from '@src/lib/global-variables'
import { toast } from 'react-toastify'

interface IProps {
  ticketId: number | null
  isOpen: boolean
  onClose: () => void
}

const AddAssignees = ({ ticketId = null, isOpen = false, onClose = () => null }: IProps) => {
  const queryClient = useQueryClient()
  const { staff } = useTaskContext()
  const { user } = useUserContext()
  const params = {
    filter: `id||$eq||${ticketId}`,
  }
  const { data } = useQuery(
    [GLOBAL_QUERIES.TASKSERVICE.GET_BY_ID, ticketId],
    async () => {
      const data = await axios.get(`${REACT_APP_API_URL}/tasks/${ticketId}`, {
        params,
      })
      return data
    },
    {
      enabled: !!ticketId && isOpen,
      retry: false,
    },
  )
  const ticket = data?.data || null

  const [selectedAssignees, setSelectedAssignees] = useState<any[]>([])

  const filteredStuffMemebers =
    staff?.map((member) => ({
      value: member.id,
      label: member.first_name.trim() + ' ' + member.last_name.trim(),
      access_level: member.access_level,
    })) || []

  const allowedToBeAssigned =
    RULES_TO_ASSIGN_TASK[user?.access_level as keyof typeof RULES_TO_ASSIGN_TASK]

  const filteredStuffMembersByRules = [...filteredStuffMemebers]?.filter(
    ({ access_level }) => allowedToBeAssigned?.includes(access_level),
  )
  const ref = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (filteredStuffMembersByRules.length > 0) setSelectedAssignees(filteredStuffMembersByRules)
  }, [])
  useOutsideClick([ref], onClose)

  const handleAddNewAssignee = async (assigneeId: number) => {
    const isArray = Array.isArray(ticket?.assign_to)
    const isIncluded = isArray
      ? ticket.assign_to.includes(assigneeId)
      : ticket.assign_to === assigneeId

    const ticketAssignee = isArray ? ticket.assign_to : [ticket.assign_to]
    const assigneeIds: number[] = Array.from(new Set([assigneeId, ...ticketAssignee]))

    const filteredArray = isIncluded ? assigneeIds.filter((id) => id !== assigneeId) : assigneeIds

    try {
      await axios.put(`${REACT_APP_API_URL}/tasks/${ticketId}`, {
        ...ticket,
        assign_to: [...filteredArray],
        staff_id_modified: user?.id || null,
      })

      toast.success('Assignee has been updated')
    } catch (error) {
      console.log('Assignee has not been updated')
      toast.error('Something went wrong while updating assignee list')
    } finally {
      onClose()
    }
    queryClient.invalidateQueries([GLOBAL_QUERIES.TASKSERVICE.GET])
  }

  if (!isOpen) return null

  return (
    <div ref={ref} onClick={(e) => e.stopPropagation()} className={styles.wrapper}>
      {selectedAssignees?.map(({ value, label }) => (
        <div
          key={value}
          onClick={() => handleAddNewAssignee(value)}
          className={cls(styles.item, {
            [styles.active]: Array.isArray(ticket?.assign_to)
              ? ticket?.assign_to?.includes(value)
              : ticket?.assign_to === value,
          })}
        >
          <span>{label}</span>
        </div>
      ))}
    </div>
  )
}

export default AddAssignees
