import BaseService from './BaseService'
import axios from 'axios'
export default class TasksService extends BaseService {
  constructor() {
    super()
    this.apiUrl = this.apiUrl + '/tasks'
  }

  async changeColumnBelongId(taskId, columnId, position, isAutomationBoard) {
    try {
      const response = await axios.post(this.apiUrl + '/changeColumnBelongId', {
        taskId,
        columnId,
        position,
        isAutomationBoard,
      })

      if (response.status !== 200 && response.status !== 201) {
        throw new Error('Failed to move the task')
      }

      return response.data.data
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async getTasks(params = {}) {
    try {
      const response = await axios.get(this.apiUrl, { params })

      if (response.status !== 200) {
        throw new Error('Failed to fetch staff')
      }

      return response.data.data
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async addTask(val) {
    try {
      const response = await axios.post(this.apiUrl, val)

      if (response.status !== 201) {
        throw new Error('Failed to add staff')
      }

      return response
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async deleteTask(taskId) {
    try {
      const response = await axios.delete(this.apiUrl + `/${taskId}`)
      return response
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async editTask(val, id) {
    try {
      const formData = new FormData()
      formData.append('type', val.type)
      formData.append('priority', val.priority)
      formData.append('is_done', val.status)
      formData.append('duplicate', val.duplicate)
      formData.append('deleted', val.deleted)
      formData.append('private', val.private)
      formData.append('title', val.title)
      formData.append('meeting_with', val.meeting_with)
      formData.append('location', val.location)
      formData.append('companies', val.companies)
      formData.append('assigned_staff', val.assigned_staff)
      formData.append('staff_id_created', val.staff_id_created)
      formData.append('staff_id_modified', val.staff_id_modified)
      formData.append('description', val.description)
      formData.append('date_start', val.date_start)
      formData.append('date_end', val.date_end)
      formData.append('is_archived', val.isArchive)

      const response = axios.patch(this.apiUrl + `/${id}`, val)

      return response
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }
}
