import { Params } from "@src/types/service.type"
import { GLOBAL_QUERIES } from "@src/lib/global-queries"
import { useQuery } from "@tanstack/react-query"
import { ShortMessageService } from "../services/short-message.service"

export const useGetShortMessageList = (params: Params = {}) => {
  const getShortMessageList = (params: Params = {}) => {
    const shortMessageService = new ShortMessageService()
    return shortMessageService.getMessages(params)
  }

  const query = useQuery({ queryKey: [GLOBAL_QUERIES.SMS.GET, params], queryFn: () => getShortMessageList(params), keepPreviousData: true })

  return query
}