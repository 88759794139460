import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import moment from 'moment';

export const optionsState = [
  { value: 'ACT', label: 'ACT' },
  { value: 'NSW', label: 'NSW' },
  { value: 'NT', label: 'NT' },
  { value: 'QLD', label: 'QLD' },
  { value: 'SA', label: 'SA' },
  { value: 'TAS', label: 'TAS' },
  { value: 'VIC', label: 'VIC' },
  { value: 'WA', label: 'WA' },
];

const locationOption = [
  { value: '0', label: 'General filing' },
  { value: '1', label: 'General filing – No Order' },
  { value: '2', label: 'Paying' },
  { value: '3', label: 'Paying Agent Direct' },
  { value: '4', label: 'Eviction' },
  { value: '5', label: 'Paid in full' },
  { value: '6', label: 'Purged – Bankrupt' },
  { value: '7', label: 'Purged – Overseas' },
  { value: '8', label: 'Purged – No TAF' },
  { value: '9', label: 'Purged – No Order' },
  { value: '10', label: 'Paying Agent' },
  { value: '11', label: 'Purged – jail' },
  { value: '12', label: 'Purged – Deceased' },
  { value: '13', label: 'Purged - By Agen' },
  { value: '14', label: 'Purged - No Cover' },
  { value: '15', label: 'Purged - Insurance' },
  { value: '16', label: 'QLD General Filing' },
  { value: '17', label: 'QLD PIF Filing' },
  { value: '18', label: 'QLD Purge Filing' },
  { value: '19', label: 'QLD Audit Box 1' },
  { value: '20', label: 'QLD Audit Box 2' },
  { value: '21', label: 'QLD Audit Box 3' },
  { value: '22', label: 'QLD Audit Box 4' },
  { value: '23', label: 'QLD Other' },
  { value: '24', label: 'NSW General Filing' },
  { value: '25', label: 'NSW PIF Filing' },
  { value: '26', label: 'NSW Purge Filing' },
  { value: '27', label: 'NSW Other' },
  { value: '28', label: 'VIC General Filing' },
  { value: '29', label: 'VIC PIF Filing' },
  { value: '30', label: 'VIC Purge Filing' },
  { value: '31', label: 'VIC Othe' },
];

export const WipDetails = ({ wipDetails, setWipDetails, wipCoverOptions }) => {

  useEffect(() => {
    const defaultSelectedCover = wipCoverOptions.find((val) => val.value === wipDetails.wip_cover_id);
    setSelectedCover(defaultSelectedCover);
    const defaultSelectedState = optionsState.find((val) => val.value === wipDetails.state_of_origin);
    setSelectedState(defaultSelectedState);
    const defaultSelectedLocation = locationOption.find((val) => val.value === wipDetails.file_location);
    setSelectedLocation(defaultSelectedLocation);
  }, [wipCoverOptions, wipDetails]);

  const [selectedCover, setSelectedCover] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const onInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === 'file_number') {
      setWipDetails((prev) => ({ ...prev, [name]: +value }));
    } else if (name === 'date_lodged') {
      setWipDetails((prev) => ({ ...prev, [name]: new Date(value).toISOString() }));
    } else {
      setWipDetails((prev) => ({ ...prev, [name]: value }));
    }

  };

  const setWipCowerId = (name) => (value) => {
    setSelectedCover(value);
    setWipDetails((prev) => ({ ...prev, [name]: value.value }));
  };

  const setStateofOrigin = (name) => (value) => {
    setSelectedState(value);
    setWipDetails((prev) => ({ ...prev, [name]: value.value }));
  };

  const setFileLocation = (name) => (value) => {
    setSelectedLocation(value);
    setWipDetails((prev) => ({ ...prev, [name]: value.value }));
  };


  return (
    <>
      <div className='card__title'>
        <h5 className='bold-text'>Wip details</h5>
      </div>

      <div className='form__form-group'>
        <span className='form__form-group-label'>File Number</span>
        <div className='form__form-group-field  d-flex flex-column'>
          <input
            name='file_number'
            type='number'
            pattern='^([0-9]+(\.?[0-9]?[0-9]?)?)'
            placeholder='Name'
            onChange={onInputChange}
            value={wipDetails.file_number}
            required={true}
          />
        </div>
      </div>
      <div className='form__form-group'>
        <span className='form__form-group-label'>Date Lodged</span>
        <div className='form__form-group-field d-flex flex-column'>
          <input
            name='date_lodged'
            type='date'
            placeholder='Date Lodged'
            onChange={onInputChange}
            value={moment(wipDetails.date_lodged).format('YYYY-MM-DD')}
            required={true}
          />
        </div>
      </div>
      <div className='form__form-group'>
        <span className='form__form-group-label'>Cover</span>
        <div className='form__form-group-field'>
          <Select
            name='wip_cover_id'
            value={selectedCover}
            onChange={setWipCowerId('wip_cover_id')}
            options={wipCoverOptions}
            classNamePrefix='react-select'
            className='react-select'
          />
        </div>
      </div>
      <div className='form__form-group'>
        <span className='form__form-group-label'>State of Origin</span>
        <div className='form__form-group-field '>
          <Select
            name='state_of_origin'
            options={optionsState}
            onChange={setStateofOrigin('state_of_origin')}
            value={selectedState}
            classNamePrefix='react-select'
            className='react-select'
            disabled
          />
        </div>
      </div>

      <div className='form__form-group'>
        <span className='form__form-group-label'>File Location</span>
        <div className='form__form-group-field '>
          <Select
            name='file_location'
            options={locationOption}
            classNamePrefix='react-select'
            className='react-select'
            onChange={setFileLocation('file_location')}
            value={selectedLocation}
            disabled
          />
        </div>
      </div>
    </>
  );
};
