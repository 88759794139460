import { REACT_APP_API_URL } from "@src/lib/global-variables";
import { PaginationResponse, Params } from "@src/types/service.type";
import axios from "axios";

export interface Report {
  id: number;
  subject: string;
  body: string;
  wipId: number;
  createdAt: Date;
}

export class ApiReportService {


  async createReport(wipId: number, data: any) {
    const res = await axios.post<Report>(`${REACT_APP_API_URL}/reports`, {
      subject: data.title,
      body: data.body,
      wipId,
    })

    return res.data;
  }

  async getReports(params?: Params) {
    const res = await axios.get<PaginationResponse<Report[]>>(`${REACT_APP_API_URL}/reports`, {
      params
    })

    return res.data;
  }

}