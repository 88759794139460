const DIRECTIONS = {
  top: 'rotate(180deg)',
  bottom: 'rotate(0deg)',
  right: 'rotate(270deg)',
  left: 'rotate(90deg)',
}

type ArrowIconProps = React.SVGProps<SVGSVGElement> & {
  direction?: 'left' | 'right' | 'top' | 'bottom'
}
const ArrowIcon = ({ direction = 'right', onClick, ...rest }: ArrowIconProps) => {
  return (
    <svg
      {...rest}
      onClick={onClick}
      width="12"
      height="6"
      fill="none"
      style={{ transform: DIRECTIONS[direction] }}
      viewBox="0 0 12 6"
    >
      <path stroke="#979797" strokeWidth="2" d="M1 1l4.917 3 4.917-3"></path>
    </svg>
  )
}

export default ArrowIcon
