import React,{Component}from 'react';
import { Col, Container, Row } from 'reactstrap';
import Management from './management';


class ManagementTool extends Component{
  constructor(props){
    super(props)
    this.state={
      title:'ADD STAFF'
    }
  }
  componentDidMount(){
    if(this.props.match.params.id){
      this.setState({title : "EDIT STAFF"})
    }
  }
  render(){
    return(
      <Container>
      <Row>
        <Col md={12}>
         <h3 className="page-title"><b>ADD STAFF</b></h3>
        </Col>
      </Row>
      <Row>
        <Management />
      </Row>
    </Container>
    )
  }
}
export default ManagementTool;
