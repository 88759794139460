import React from 'react';

import { Grid } from '@mui/material';
import FormDatePicker from '../../../../../../../shared/components/FormDatePicker/FormDatePicker';
import FormInput from '../../../../../../../shared/components/FormInput/FormInput';

import { invoiceFormStyles } from '../../styles';

import request from '../../../../../../../shared/request/request';

const FormGlobalInfo = ({ touched, values, errors, handleChange, setFieldValue }) => {
  const handleBlur = async () => {
    const num = +values.invoice_no;
    const res = await request('post', '/invoices/check/invoice-no', {
      invoice_no: num,
    });
    setFieldValue('invoice_no', res.invoice_no);
  };

  return (
    <Grid sx={invoiceFormStyles.globalInfoContainer}>
      <FormDatePicker
        label="Current Date:"
        placeholder="Date Vacated"
        name="current_date"
        changeHandler={handleChange}
        value={values?.current_date || undefined}
        error={errors?.current_date}
        touched={touched?.current_date}
        required
        bold={true}
        max={values?.due_date || null}
      />

      <Grid sx={invoiceFormStyles.row}>
        <FormDatePicker
          label="Due Date:"
          placeholder="Date Vacated"
          name="due_date"
          changeHandler={handleChange}
          value={values?.due_date || undefined}
          error={errors?.due_date}
          touched={touched?.due_date}
          required
          bold={true}
        />

        <FormInput
          changeHandler={handleChange}
          handleBlur={handleBlur}
          label="Invoice Number:"
          name="invoice_no"
          placeholder="Property Manager"
          type="text"
          value={values?.invoice_no}
          error={errors?.invoice_no}
          touched={touched?.invoice_no}
          bold={true}
          required
          width="150px"
        />
      </Grid>
    </Grid>
  );
};

export default FormGlobalInfo;
