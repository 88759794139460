import Section from '@components/section/section.component'
import SubSection from '@containers/Recovery/Wip/components/UI/sub-section/sub-section.component'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import styles from './debtor-references-section.module.scss'
import DebtorReferenceTable from '../../tables/debtor-reference-table/debtor-reference-table.component'
import { FC, useState } from 'react'
import { useModal } from '@src/hooks/useModal'
import CreateDebtorReferenceModal from '../../modals/debtor-reference-modal/create-debtor-reference-modal.component'
import {
  DebtorReference,
  DebtorReferencesSectionProps,
} from '@containers/Recovery/Wip/_modules/Debtors/types/debtor-associate-detail.type'
import EditDebtorReferenceModal from '../../modals/debtor-reference-modal/edit-debtor-reference-modal.component'

const DebtorReferencesSection: FC<DebtorReferencesSectionProps> = ({ references, debtorId }) => {
  const [isOpen, handleOpen, handleClose] = useModal(false)
  const [isEditModalOpen, handleEditModalOpen, handleEditModalClose] = useModal(false)
  const [selectedDebtorReference, setSelectedDebtorReference] = useState<DebtorReference | null>(
    null,
  )

  const handleItemClick = (reference: DebtorReference) => {
    setSelectedDebtorReference(reference)
    handleEditModalOpen()
  }

  return (
    <Section>
      <SubSection className={styles.headerWrapper} mb={4} p={4}>
        <Header>List References</Header>
        <SubmitButton buttonType="button" onClick={handleOpen}>
          Add Reference
        </SubmitButton>
      </SubSection>
      <DebtorReferenceTable
        references={references}
        debtorId={debtorId}
        onItemClick={handleItemClick}
      />
      <CreateDebtorReferenceModal debtorId={debtorId} isOpen={isOpen} onClose={handleClose} />
      {selectedDebtorReference && (
        <EditDebtorReferenceModal
          debtorId={debtorId}
          debtorReference={selectedDebtorReference}
          isOpen={isEditModalOpen}
          onClose={handleEditModalClose}
        />
      )}
    </Section>
  )
}

export default DebtorReferencesSection
