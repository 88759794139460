import React, { useState } from 'react';

import { Grid } from '@mui/material';

import FormButtons from './components/FormButtons/FormButtons';
import FormGlobalInfo from './components/FormGlobalInfo/FormGlobalInfo';
import FormBilling from './components/FormBilling/FormBilling';
import FormTemplateItems from './components/FormTemplateItems/FormTemplateItems';
import FormPaymentDetails from './components/FormPaymentDetails/FormPaymentDetails';

import PreviewModal from './PreviewModal/PreviewModal';
import SaveModal from './SaveModal/SaveModal';
import VoidModal from './VoidModal/VoidModal';
import { EmailModal } from './EmailModal/EmailModal';

import { invoiceFormStyles } from './styles';

const InvoiceForm = ({
  submitForm,
  handleChange,
  setFieldValue,
  values,
  errors,
  touched,
  isValid,
  isEditing,
  invoiceID,
}) => {
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [openVoidModal, setOpenVoidModal] = useState(false);
  const [openEmailModal, setOpenEmailModal] = useState(false);

  return (
    <Grid sx={invoiceFormStyles.container}>
      <FormButtons
        values={values}
        title={true}
        submitForm={submitForm}
        setOpenPreviewModal={setOpenPreviewModal}
        setOpenSaveModal={setOpenSaveModal}
        setOpenVoidModal={setOpenVoidModal}
        isValid={isValid}
        isEditing={isEditing}
        invoiceID={invoiceID}
        errors={errors}
      />

      <FormGlobalInfo
        handleChange={handleChange}
        setFieldValue={setFieldValue}
        values={values}
        errors={errors}
        touched={touched}
      />

      <FormBilling handleChange={handleChange} values={values} errors={errors} touched={touched} />

      <FormTemplateItems
        handleChange={handleChange}
        values={values}
        errors={errors}
        touched={touched}
        setFieldValue={setFieldValue}
      />

      <FormPaymentDetails
        handleChange={handleChange}
        values={values}
        errors={errors}
        touched={touched}
        setFieldValue={setFieldValue}
        isEditing={isEditing}
      />

      <FormButtons
        values={values}
        title={false}
        submitForm={submitForm}
        setOpenPreviewModal={setOpenPreviewModal}
        setOpenSaveModal={setOpenSaveModal}
        setOpenVoidModal={setOpenVoidModal}
        isValid={isValid}
        isEditing={isEditing}
        invoiceID={invoiceID}
        errors={errors}
      />

      <PreviewModal
        open={openPreviewModal}
        setOpen={setOpenPreviewModal}
        setOpenVoidModal={setOpenVoidModal}
        setOpenEmailModal={setOpenEmailModal}
        setOpenSaveModal={setOpenSaveModal}
      />
      <SaveModal open={openSaveModal} setOpen={setOpenSaveModal} />

      <VoidModal
        open={openVoidModal}
        setOpen={setOpenVoidModal}
        isEditing={isEditing}
        invoiceID={invoiceID}
      />
      {openEmailModal && <EmailModal open={openEmailModal} setOpen={setOpenEmailModal} />}
    </Grid>
  );
};

export default InvoiceForm;
