import SubSection from '@containers/Recovery/Wip/components/UI/sub-section/sub-section.component'
import { EditWipCostItemProps, IWipCostList, WipCostListItem } from '@containers/Recovery/WipCost/types/wip-cost.type'
import { FC } from 'react'
import EditWipCostItemForm from '../../forms/wip-cost-form/edit-wip-cost-form.component'
import { useQueryClient } from '@tanstack/react-query'
import { PaginationResponse } from '@src/types/service.type'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import { toast } from 'react-toastify'

const EditWipCostItem: FC<EditWipCostItemProps> = ({ wipCostListItem }) => {
  const queryClient = useQueryClient()
  const handleSuccessSubmit = (item: WipCostListItem) => {
    queryClient.setQueryData<PaginationResponse<IWipCostList[]>>(
      [GLOBAL_QUERIES.WIP_COST_LIST.GET],
      (prev) =>
        prev
          ? {
              ...prev,
              data: prev.data.map((wipCostList) => {
                if (wipCostList.id === wipCostListItem.wipCostListId) {
                  return {
                    ...wipCostList,
                    list: wipCostList.list.map((wipCostListItem) =>
                      wipCostListItem.id === item.id ? item : wipCostListItem,
                    ),
                  }
                }
                return wipCostList
              }),
            }
          : undefined,
    )
    toast.success('WIP cost item updated')
  }

  const handleDelete = () => {
    queryClient.setQueryData<PaginationResponse<IWipCostList[]>>(
      [GLOBAL_QUERIES.WIP_COST_LIST.GET],
      (prev) =>
        prev
          ? {
              ...prev,
              data: prev.data.map((wipCostList) => {
                if (wipCostList.id === wipCostListItem.wipCostListId) {
                  return {
                    ...wipCostList,
                    list: wipCostList.list.filter(
                      (wc) => wc.id !== wipCostListItem.id,
                    ),
                  }
                }
                return wipCostList
              }),
            }
          : undefined,
    )
    toast.success('WIP cost item deleted')
  }

  return (
    <li>
      <SubSection p={4}>
        <EditWipCostItemForm
          entity={wipCostListItem}
          onSuccessSubmit={handleSuccessSubmit}
          onDelete={handleDelete}
        />
      </SubSection>
    </li>
  )
}

export default EditWipCostItem
