import { toast } from 'react-toastify'
import { FC } from 'react'
import { useCreateDebtorLegalTribunalMutation } from '../../../hooks/mutations/crud-debtor-legal-tribunal.hook'
import { CreateDebtorLegalTribunalFormProps, DebtorLegalTribunalFormValues } from '../../../types/debtor-legal-tribunal.type'
import DebtorLegalTribunalForm from './debtor-legal-tribunal-form.component'

const CreateDebtorLegalTribunalForm: FC<CreateDebtorLegalTribunalFormProps> = ({
  debtorLegalId,
  onSuccessSubmit,
}) => {
  const { mutateAsync, isLoading } = useCreateDebtorLegalTribunalMutation()
  const handleSuccessSubmit = async (data: DebtorLegalTribunalFormValues) => {
    try {
      const createdData = await mutateAsync({
        data: {
          debtorLegalId,
          ...data,
        },
      })
      onSuccessSubmit(createdData)
    } catch (error) {
      toast.error('Failed to create tribunal')
      console.error(error)
    }
  }

  return (
    <DebtorLegalTribunalForm
      onSuccessSubmit={handleSuccessSubmit}
      loading={isLoading}
      submitButtonTitle="Create Tribunal"
    />
  )
}

export default CreateDebtorLegalTribunalForm
