export  const fileLoacationOption = [
    { value: "0", label: "General filing" },
    { value: "1", label: "General filing – No Order" },
    { value: "2", label: "Paying" },
    { value: "3", label: "Paying Agent Direct" },
    { value: "4", label: "Eviction" },
    { value: "5", label: "Paid in full" },
    { value: "6", label: "Purged – Bankrupt" },
    { value: "7", label: "Purged – Overseas" },
    { value: "8", label: "Purged – No TAF" },
    { value: "9", label: "Purged – No Order" },
    { value: "10", label: "Paying Agent" },
    { value: "11", label: "Purged – jail" },
    { value: "12", label: "Purged – Deceased" },
    { value: "13", label: "Purged - By Agen" },
    { value: "14", label: "Purged - No Cover" },
    { value: "15", label: "Purged - Insurance" },
    { value: "16", label: "QLD General Filing" },
    { value: "17", label: "QLD PIF Filing" },
    { value: "18", label: "QLD Purge Filing" },
    { value: "19", label: "QLD Audit Box 1" },
    { value: "20", label: "QLD Audit Box 2" },
    { value: "21", label: "QLD Audit Box 3" },
    { value: "22", label: "QLD Audit Box 4" },
    { value: "23", label: "QLD Other" },
    { value: "24", label: "NSW General Filing" },
    { value: "25", label: "NSW PIF Filing" },
    { value: "26", label: "NSW Purge Filing" },
    { value: "27", label: "NSW Other" },
    { value: "28", label: "VIC General Filing" },
    { value: "29", label: "VIC PIF Filing" },
    { value: "30", label: "VIC Purge Filing" },
    { value: "31", label: "VIC Othe" },
  ];