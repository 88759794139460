import React from 'react';
import { Button, ButtonToolbar, Card, CardBody, Col, Container, Modal, Row } from 'reactstrap';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Select from 'react-select';
import Services from '../../../../shared/Services.js';
import { withRouter } from 'react-router';
import SimpleReactValidator from 'simple-react-validator';
import ModalComponent from '../../../../shared/components/ModalCustom';
import DatePicker from 'react-datepicker';
import WIPService from '../../../../services/WIPService';
import { TailSpin } from 'react-loader-spinner';

class WIPCostsAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stateName: '',
      name: '',
      date: new Date(),
      amount: '',
      newName: '',
      newAmount: '',
      newDate: new Date(),
      // date:'',
      total: '',
      offeramount: '',
      order: 'asc',
      orderBy: '',
      selected: new Map([]),
      data: [],
      WIPData: [],
      CostData: [{
        id: 0,
        name: '',
        amount: 0,
        date: new Date(),
      }],
      costItem: '',
      page: 0,
      rowsPerPage: 5,
      errorModal: false,
      errorMessage: '',
      errorType: '',
      st_id: '',
      item: '',
      listId: '',
      isEditModal: false,
      wipUserId: localStorage.getItem('wipUserId'),
      isNew: true,
      accessErrorModal: false,
      stateId: '',
      stateOfOrigin: '',
      costItemSelected: '',
      // editWIPCostId:localStorage.getItem("editWIPCostId"),
      indexOfCost: 0,
      Ass_States: '',
      loader: false,
      wip_id: null,
    };

    this.wip = new WIPService();
    this.services = new Services();
    this.changeInput = this.changeInput.bind(this);

    this.addWip = this.addWip.bind(this);
    this.editWIP = this.editWIP.bind(this);
    this.clearValue = this.clearValue.bind(this);
    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    if (this.state.wipUserId) {
      this.getWIP(this.state.wipUserId);
    }
    this.clearValue();
  }

  clearValue = () => {
    this.setState({
      name: '',
      text: '',
      amount: '',
      errorNameId: '',
      errorAmountId: '',
      errorTextId: '',
    });
  };

  getWIP(id) {
    this.setState({ loader: true });
    const params = {
      join: ['assigned_staff', 'recoverable_costs_details'],
      filter: `assigned_staff.id||$eq||${id}`,
      count: 1000,
    };
    this.wip.getWips(params).then((res) => {
      if (res.data) {
        if (res.data.length > 0) {
          this.setState({ isNew: false });
          this.setState({ wip_id: res.data[0].id });
          this.getState({ value: res.data[0].state_of_origin, label: res.data[0].state_of_origin });
          const CostData = this.state.CostData.concat(res.data[0].recoverable_costs_details.list);
          const dataCostData = [];
          CostData.forEach(element => {
            dataCostData.push({
              id: Math.floor(Math.random() * (1 + 100)) + 1,
              name: element.description,
              amount: element.amount,
              date: element.date ? new Date(element.date) : new Date(),
            });
          });

          this.setState({
            CostData: dataCostData,
            offeramount: res.data[0]?.recoverable_costs_details?.offered_amount,
            Ass_States: { value: res.data[0].state_of_origin, label: res.data[0].state_of_origin },
            total: res.data.total,
          });
          let total = 0;
          CostData.forEach(element => {
            total = total + parseInt(element.amount);
          });
          this.setState({ 'total': total });
        } else {
          this.setState({ isNew: true });
          this.setState({ loader: false });
        }
      } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
        this.setState({ accessErrorModal: true });
        this.setState({ loader: false });
      }
    });
  }

  getState = d => {
    this.setState({ loader: true });
    this.setState({ Ass_States: d });
    this.setState({ stateOfOrigin: d.value });
    const params = {
      filter: `short||$eq||${d.value}`,
    };
    this.wip.getWIPCost(params).then((res) => {
      if (res.status === 200) {
        const allData = res.data.data[0].list;
        const costItem = [];
        allData.forEach(element => {
          costItem.push({ value: element.id, label: element.name });
        });
        this.setState({
          'WIPData': allData,
          'costItem': costItem,
          'stateName': res.data.data[0].state,
        });
        this.setState({ loader: false });
      } else {
        this.services.errorToast(res.message);
        this.setState({ loader: false });
      }
    });
  };

  onCloseEditModal = () => {
    this.setState({ isEditModal: false });
  };

  addCostToList = d => {
    this.setState({ costItemSelected: d.value, indexOfCost: this.state.indexOfCost + 1 });
    const data = this.state.WIPData.find(ele => ele.id === d.value);
    const dataTodave = {
      'id': data.id,
      'name': data.name,
      'amount': data.amount,
      'date': data.date ? data.date : new Date(),
      'index': this.state.indexOfCost,
    };
    const CostData = [...this.state.CostData, dataTodave];

    this.setState({
      stateName: d,
      'CostData': CostData,
    });
    // this.state.CostData.push(data)
  };

  addWip = () => {
    let isValid = true;
    this.state.CostData.forEach(e => {
      if (e.id === 0) {
        if (e.name === '') {
          this.validator.showMessages();
          this.forceUpdate();
          isValid = false;
        }
      }
    });
    if (!isValid)
      return;
    this.state.CostData.map(e => {
      return delete e.index;
    });
    const CostData = this.state.CostData;
    const newCostData = [];
    let total = 0;
    CostData.forEach(ele => {
      if (ele.name) {
        newCostData.push({
          description: ele.name,
          amount: ele.amount > 0 ? +ele.amount : 0,
          date: ele.date ? ele.date.toISOString() : new Date().toISOString(),
        });
        total = total + parseInt(ele.amount);
      }
    });

    const data = {
      id: this.state.wip_id,
      recoverable_costs_details: {
        list: newCostData,
        offered_amount: this.state.offeramount > 0 ? +this.state.offeramount : 0,
        total: total,
      },
    };
    if (!this.state.isNew) {
      if (this.validator.allValid()) {
        this.wip.editWipsId(data).then((res) => {
          if (res.status === 200) {
            this.services.successToast('WIP cost update successfully');
            this.props.history.push('/wip-costs-user');
          } else {
            this.services.errorToast(res.message);
          }
        });
      } else {
        this.validator.showMessages();
        this.forceUpdate();
      }
    } else {
      if (this.validator.allValid()) {
        this.services.errorToast('From the beginning it is necessary to assign WIP');
      } else {
        this.validator.showMessages();
        this.forceUpdate();
      }
    }
  };

  changeInput(e) {
    if (e.target.name === 'offeramount') {
      this.setState({ [e.target.name]: (e.target.value >= 0 ? e.target.value : null) });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }

  }

  changtNewDate(e) {
    this.setState({ newDate: new Date(e) });
  }

  isEditModalOpen = (e, st, item) => {
    this.setState({
      isEditModal: true,
      st_id: st.id,
      editName: item.name,
      editText: item.text,
      editAmount: item.amount,
      item: item,
    });
  };

  editWIP = () => {
    const list = this.state.CostData.filter(e => e.id !== this.state.item.id);
    const cost = {
      id: this.state.item.id,
      description: this.state.editName,
      date: this.state.editText,
      amount: this.state.editAmount,
    };
    const data = {
      id: this.state.st_id,
      recoverable_costs_details: {
        list: [...list, cost],
        offered_amount: this.state.offeramount,
        total: this.state.total,
      },
    };
    this.wip.editWipsId(data).then((res) => {
      if (res.status === 200) {
        this.services.successToast('WIP cost update successfully');
        this.onCloseEditModal();
        this.getWIP();
      } else {
        this.services.errorToast(res.message);
      }
    });
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      const { data } = this.state;
      const newSelected = new Map();
      data.map(n => newSelected.set(n.id, true));
      this.setState({ selected: newSelected });
      return;
    }
    this.setState({ selected: new Map([]) });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const newSelected = new Map(selected);
    const value = newSelected.get(id);
    let isActive = true;
    if (value) {
      isActive = false;
    }
    newSelected.set(id, isActive);
    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleDeleteSelected = () => {
    const { data } = this.state;
    let copyData = [...data];
    const { selected } = this.state;

    for (let i = 0; i < [...selected].filter(el => el[1]).length; i += 1) {
      copyData = copyData.filter(obj => obj.id !== selected[i]);
    }

    this.setState({ data: copyData, selected: new Map([]) });
  };

  accessErrorModalClose = () => {
    this.setState({ accessErrorModal: false });
    this.props.history.push('/dashboard');
  };

  removeItem = (e, data) => {
    const CostData = this.state.CostData;
    const filteredPeople = CostData.filter((item) => item.id !== data.id);
    this.setState({ CostData: filteredPeople });

  };

  addItem = (e, data) => {
    this.setState({ CostData: [...this.state.CostData, { id: data.id + 1, name: '', amount: 0, date: new Date() }] });
  };

  InnerChange = (e, data) => {
    let name = e.target.name;
    let value = e.target.value;
    const CostData = this.state.CostData;
    if (name === 'name') {
      CostData.map((element) => {
        if (element.id === data.id && data.index === element.index) {
          Object.assign(element, { 'name': value });
        }
        return null;
      });
    } else if (name === 'amount') {

      CostData.map((element) => {
        if (element.id === data.id && data.index === element.index) {
          Object.assign(element, { 'amount': value >= 0 ? value : '' });
        }
        return null;
      });
    }
    this.setState({
      CostData: CostData,
    });
  };

  handleChange = (date, data) => {
    const CostData = this.state.CostData;
    CostData.forEach(element => {
      if (element.id === data.id && element.index === data.index) {
        Object.assign(element, { 'date': date });
      }
    });
    this.setState({
      date: date,
      CostData: CostData,
    });
  };

  render() {
    const { costItem, CostData, offeramount, loader } = this.state;
    let total = 0;
    CostData.forEach(element => {
      total = total + parseInt(element.amount ? element.amount : 0);
    });
    return (
      (loader ? (
        <div className={'loader'}>
          <TailSpin
            height='100'
            width='100'
            color='#f69066'
            ariaLabel='loading'
          />
        </div>
      ) : <Container>
        <Row>
          <Col md={12}>
            <h3 className='page-title'>Add WIP Costs </h3>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <div className='material-table__toolbar-wrap' style={{ right: '11px' }}>
                  <div className='btn btn-secondary' onClick={event => this.addWip(event)}>Save</div>
                </div>
                <div style={{ paddingTop: '42px' }}>
                  {/* <h4 className="bold-text"></h4> */}
                  <div className='material-table__wrap'>
                    <Table className='material-table' style={{ paddingBottom: '10px' }}>
                      <TableBody>
                        <TableRow className='material-table__row form' tabIndex={-1}>
                          <TableCell className='material-table__cell material-table__cell-right'>
                            <div className='mb-4'>
                              <span className='form__form-group-label'>State of Origin <span
                                style={{ color: '#ff0000' }}>*</span></span>
                              <div className='form__form-group-field'>
                                <Select name='stateName' component={SimpleReactValidator} value={this.state.Ass_States}
                                        options={[
                                          { value: 'ACT', label: 'ACT' },
                                          { value: 'NSW', label: 'NSW' },
                                          { value: 'NT', label: 'NT' },
                                          { value: 'QLD', label: 'QLD' },
                                          { value: 'SA', label: 'SA' },
                                          { value: 'TAS', label: 'TAS' },
                                          { value: 'VIC', label: 'VIC' },
                                          { value: 'WA', label: 'WA' },
                                        ]}
                                        onChange={this.getState} classNamePrefix='react-select' className='react-select'
                                />
                              </div>
                              <span
                                className='form__form-group-error'>{this.validator.message('State', this.state.stateOfOrigin, 'required')}</span>
                            </div>
                          </TableCell>

                          <TableCell className='material-table__cell material-table__cell-right'>
                            {costItem && (
                              <div className='mb-4'>
                                <span className='form__form-group-label'>Add Cost Item <span
                                  style={{ color: '#ff0000' }}>*</span></span>
                                <div className='form__form-group-field'>
                                  <Select name='state' component={SimpleReactValidator} value={this.state.stateName}
                                          options={costItem}
                                          onChange={this.addCostToList} classNamePrefix='react-select'
                                          className='react-select'
                                  />
                                </div>
                                {/* <span className="form__form-group-error">{this.validator.message('', this.state.costItemSelected, 'required' )}</span> */}
                              </div>
                            )}
                          </TableCell>

                        </TableRow>

                      </TableBody>
                    </Table>
                    <Table className='material-table'>
                      <TableBody>
                        {CostData.map((data, j) => {
                          return (
                            <TableRow className='material-table__row form' tabIndex={-1} key={j}>
                              <TableCell className='material-table__cell material-table__cell-right'>
                                <div className='form__form-group'>
                                  <span className='form__form-group-label'>Name <span
                                    style={{ color: '#ff0000' }}>*</span></span>
                                  <div className='form__form-group-field'>
                                    <input className='inp_width_md' name='name' id={data.index} type='text'
                                           onChange={(e) => this.InnerChange(e, data, j)} placeholder='Enter Name'
                                           value={data.name} />
                                  </div>
                                  <span
                                    className='form__form-group-error'>{this.validator.message('Name', data.name, 'required')}</span>
                                </div>
                              </TableCell>
                              <TableCell className='material-table__cell material-table__cell-right'>
                                <div className='form__form-group'>
                                  <span className='form__form-group-label'>Amount<span
                                    style={{ color: '#ff0000' }}>*</span></span>
                                  <div className='form__form-group-field'>
                                    <input name='amount' className='inp_width_sm' id={data.index} pattern='[0-9]*'
                                           type='text' onChange={(e) => this.InnerChange(e, data, j)}
                                           placeholder='Amount Name' value={data.amount} />
                                  </div>
                                  <span
                                    className='form__form-group-error'>{this.validator.message('Amount', CostData[j].amount, 'required|numeric')}</span>
                                </div>
                              </TableCell>
                              <TableCell className='material-table__cell material-table__cell-right'>
                                <div className='form__form-group'>
                                  <span className='form__form-group-label'>Date  <span
                                    style={{ color: '#ff0000' }}>*</span></span>
                                  <div className='form__form-group-field'>
                                    <div className='date-picker'>
                                      <DatePicker
                                        selected={data.date ? new Date(data.date) : new Date()}
                                        name='date'
                                        onChange={(e) => this.handleChange(e, data, j)}
                                        dateFormat='dd/MM/yyyy'
                                        dropDownMode='select'
                                        popperPlacement='center'
                                        className='inp_width_md'
                                        placeholderText='Select Date'
                                      />
                                      <span className='date_icon'>
                                              <img src={'./image/calendar.svg'} alt='date' />
                                            </span>
                                    </div>
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell className='material-table__cell material-table__cell-right'>
                                <span style={{ fontSize: '20px', paddingRight: '10px' }} className='lnr lnr-plus-circle'
                                      onClick={(e) => this.addItem(e, data)} />
                                <span style={{ color: 'red', fontSize: '20px' }} className='lnr lnr-circle-minus'
                                      onClick={(e) => this.removeItem(e, data)} />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                        {/* <TableRow className="material-table__row form" tabIndex={-1}>
                                  <TableCell className="material-table__cell material-table__cell-right" padding="none" >
                                    <div className="form__form-group">
                                        <span className="form__form-group-label">Name <span style={{color:"#ff0000"}}>*</span></span>
                                        <div className="form__form-group-field">
                                            <input name="newName" id="newName"  type="text"   placeholder="Enter Name" value={this.state.newName} onChange={(e)=> this.changeInput(e)}/>
                                        </div>
                                    </div>
                                  </TableCell>
                                  <TableCell className="material-table__cell material-table__cell-right" > 
                                    <div className="form__form-group">
                                        <span className="form__form-group-label">Amount <span style={{color:"#ff0000"}}>*</span></span>
                                        <div className="form__form-group-field">
                                            <input name="newAmount" id="amount" type="number" placeholder="Amount Name" value={this.state.newAmount} onChange={(e)=> this.changeInput(e)}/>
                                        </div>
                                    </div>
                                  </TableCell>
                                  <TableCell className="material-table__cell material-table__cell-right" > 
                                    <div className="form__form-group">
                                        <span className="form__form-group-label">Date  <span style={{color:"#ff0000"}}>*</span></span>
                                        <div className="form__form-group-field">
                                            <div className="date-picker">
                                            <DatePicker
                                                selected={newDate ? newDate : new Date()}
                                                name="newDate"
                                                onChange={(e)=> this.changtNewDate(e)}
                                                dateFormat="dd/MM/yyyy"
                                                dropDownMode="select"
                                                popperPlacement="center"
                                           />
                                        </div>
                                        </div>
                                    </div>
                                  </TableCell>
                            
                                </TableRow> */}
                      </TableBody>
                    </Table>
                    <Table style={{ marginBottom: '35px', marginTop: '35px' }} className='material-table'>
                      <TableBody>
                        <TableRow className='material-table__row form' tabIndex={-1}>
                          <TableCell className='material-table__cell material-table__cell-right'>
                            <div className='form__form-group'>
                              <span className='form__form-group-label'>Total  <span
                                style={{ color: '#ff0000' }}>*</span></span>
                              <div className='form__form-group-field'>
                                <input name='total' id={'C'} type='text' placeholder='Total' pattern='[0-9]*'
                                       value={total} onChange={this.changeInput} />
                              </div>
                              <span
                                className='form__form-group-error'>{this.validator.message('Total', total, 'required|numeric|min:0,num')}</span>
                            </div>
                          </TableCell>
                          <TableCell className='material-table__cell material-table__cell-right'>
                            <div className='form__form-group'>
                              <span className='form__form-group-label'>Offered Amount <span
                                style={{ color: '#ff0000' }}>*</span></span>
                              <div className='form__form-group-field'>
                                <input name='offeramount' id={'D'} pattern='[0-9]*' type='text' placeholder='Amount'
                                       value={offeramount} onChange={this.changeInput} />
                              </div>
                              <span
                                className='form__form-group-error'>{this.validator.message('Offered Amount', offeramount, 'required|numeric|min:0,num')}</span>
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                </div>
              </CardBody>
            </Card>
            {this.state.errorModal ?
              <ModalComponent modal={this.state.errorModal} color={this.state.errorType} isCancel={true}
                              message={this.state.errorMessage} onConfirm={(e) => this.DeleteWIP(e)}
                              onClose={() => this.setState({ errorModal: false })} /> : null}
          </Col>
        </Row>

        <Modal
          isOpen={this.state.isEditModal}
          className='theme-light'

        >
          <div className='modal__header'>
            <button className='lnr lnr-cross modal__close-btn' type='button' onClick={this.onCloseEditModal} />
            <h4 className='text-modal  modal__title'>{'Edit Cost'}</h4>
          </div>
          <div className='modal__body'>
            <form className='form'>
              <div className='form__form-group'>
                <span className='form__form-group-label'>Name</span>
                <div className='form__form-group-field'>
                  <input
                    name='editName'
                    type='text'
                    placeholder='Enter Name'
                    value={this.state.editName}
                    onChange={this.changeInput}
                  />
                </div>
              </div>
              <div className='form__form-group'>
                <span className='form__form-group-label'>Text</span>
                <div className='form__form-group-field'>
                  <input
                    name='editText'
                    type='text'
                    placeholder='Enter Text'
                    value={this.state.editText}
                    onChange={this.changeInput}
                  />
                </div>
              </div>
              <div className='form__form-group'>
                <span className='form__form-group-label'>Amount</span>
                <div className='form__form-group-field'>
                  <input
                    name='editAmount'
                    type='number'
                    placeholder='Enter Amount'
                    value={this.state.editAmount}
                    onChange={this.changeInput}
                  />
                </div>
              </div>
            </form>
          </div>

          <ButtonToolbar className='modal__footer'>
            <Button className='modal_cancel' onClick={this.onCloseEditModal}>Cancel</Button>{' '}
            <Button className='modal_ok' color='primary' onClick={(e) => this.editWIP(e)}>Ok</Button>
          </ButtonToolbar>
        </Modal>

        {this.state.accessErrorModal ?
          <ModalComponent modal={this.state.accessErrorModal} color='danger' isCancel={false}
                          message='You do not have access to this, please contact your manager for assistance'
                          onConfirm={(e) => this.accessErrorModalClose(e)}
                          onClose={(e) => this.accessErrorModalClose(e)} /> : null}

      </Container>)
    );
  }
}


export default withRouter(WIPCostsAdd);