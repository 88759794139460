import NavigationButton from '@components/navigation-button/navigation-button'
import { useGetMailFolderByDisplayNameOrCreate } from '@containers/Inbox/hooks/get-mail-folder-by-display-name-or-create.hook'
import { useGetMe } from '@containers/Inbox/hooks/get-me.hook'
import { SHARED_FOLDER_NAME } from '@containers/Inbox/inbox-page/const/inbox.const'
import { ShareEmailButtonProps } from '@containers/Inbox/view-email-page/types/view-email-page.type'
import ShareIcon from '@src/icons/share'
import { REACT_APP_CLIENT_URL } from '@src/lib/global-variables'
import { FC } from 'react'
import { toast } from 'react-toastify'

const ShareEmailButton: FC<ShareEmailButtonProps> = ({ className, email }) => {
  const { mailFolder } = useGetMailFolderByDisplayNameOrCreate(SHARED_FOLDER_NAME)
  const { data: graphApiUser } = useGetMe()

  const handleShareEmailClick = async () => {
    if (!graphApiUser) return

    const shareEmailUrl = `${REACT_APP_CLIENT_URL}/shared/${graphApiUser.id}/inbox/email/${email.id}`

    await navigator.clipboard.writeText(shareEmailUrl)
    toast.success('Email link copied to clipboard')
  }

  return email.parentFolderId === mailFolder?.id ? (
    <NavigationButton className={className} icon={ShareIcon} onClick={handleShareEmailClick} />
  ) : null
}

export default ShareEmailButton
