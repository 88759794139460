import { useForm, useWatch } from 'react-hook-form'
import { Automation, IOption } from '../../taskboard/types'
import { useEffect, useMemo, useState } from 'react'
import Modal from '@components/modal'
import LabelWrapper from '@components/label-wrapper'
import styles from './edit-automation.module.scss'
import Select from 'react-select'
import Switch from '@components/switch'
import SetMailModal from './set-email-automation'
import { useTaskContext } from '@src/context/task-context'
import { formatAssigneName } from '../../taskboard/utils'
import { useModal } from '@src/hooks/useModal'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import axios from 'axios'
import { REACT_APP_API_URL } from '@src/lib/global-variables'
import { toast } from 'react-toastify'
import SetSmsModal from './set-sms-modal'
import { joiResolver } from '@hookform/resolvers/joi'
import { EditAuthomationSchema } from './validation'
import SetTaskModal from './set-task-modal'
import { SET_TASK } from '../types'

interface IProps {
  isOpen: boolean
  onClose: () => void
  automation: Automation['id'] | null
  isSmsOpen?: boolean
  isEmailOpen?: boolean
  isTaskOpen?: boolean
  resetModals?: () => void
  onSuccessUpdate?: (data: Automation) => void
}

interface ICreateFrom extends Automation {
  column: IOption | null
  contacts: IOption[] | []
}

const defaultValues: ICreateFrom = {
  clientId: 0,
  contacts_automations: [],
  createdBy: '',
  createdDate: new Date(),
  email_subject: '',
  email_template: '',
  email_template_id: null,
  id: 0,
  is_all_contacts_selected: false,
  referrence_column_id: 0,
  sms_template: '',
  title: '',
  description: '',
  column: null,
  contacts: [],
  task_template: null,
}
const EditAutomationModal = ({
  automation = null,
  isOpen = false,
  onClose = () => null,
  isSmsOpen,
  isEmailOpen,
  isTaskOpen,
  resetModals,
  onSuccessUpdate
}: IProps) => {
  const [foundAutomation, setFoundAutomation] = useState<Automation | null>(null)
  const queryClient = useQueryClient()
  const { control, reset, setValue, handleSubmit } = useForm<ICreateFrom>({
    defaultValues,
    resolver: joiResolver(EditAuthomationSchema),
  })
  const {
    selectedCompanyState,
    currentColumns,
    companies,
    isSales,
    currentFoundWip,
    canSelectContact,
    externalAutomationOptions,
  } = useTaskContext()
  const {
    is_all_contacts_selected,
    email_subject,
    email_template,
    email_template_id,
    sms_template,
    task_template,
  } = useWatch({ control })

  const [isSendSmsModalOpened, handleSendSmsModalOpen, handleSendSmsModalClose] = useModal(false)
  const [isSendMailModalOpened, handleSendMailModalOpen, handleSendMailModalClose] = useModal(false)
  const [isSetTaskModalOpened, handleSetTaskModalOpen, handleSetTaskModalClose] = useModal(false)

  useQuery(
    [GLOBAL_QUERIES.AUTOMATIONS.GET_AUTOMATION_BY_ID, automation],
    () => axios.get(`${REACT_APP_API_URL}/automations/${automation}`),
    {
      enabled: !!automation && isOpen,
      select: (data) => data.data,
      onSuccess: (data) => {
        setFoundAutomation(data)
      },
    },
  )

  const contactsOptions = isSales
    ? companies
        ?.find(({ id }) => id === Number(selectedCompanyState?.id))
        ?.contacts?.map(({ id, first_name, last_name }) => ({
          value: id,
          label: formatAssigneName({ first_name, last_name, isAssigned: false }),
        })) || []
    : currentFoundWip?.debtors?.map(({ id, name_first, name_last }) => ({
        value: id,
        label: formatAssigneName({
          first_name: name_first,
          last_name: name_last,
          isAssigned: false,
        }),
      })) || []

  const automationOptions =
    currentColumns?.map(({ id, title }) => ({
      value: id,
      label: title,
    })) || []

  const submitMailTemplate = ({
    message,
    subject,
    templateId,
  }: {
    message: ICreateFrom['email_template']
    subject: ICreateFrom['email_template']
    templateId: number | null
  }) => {
    setValue('email_template', message)
    setValue('email_template_id', templateId)
    setValue('email_subject', subject)
  }

  const submitSmsTemplate = ({ sms_template }: { sms_template: ICreateFrom['sms_template'] }) => {
    setValue('sms_template', sms_template)
  }

  const submitTaskTemplate = ({ task_template }: { task_template: SET_TASK }) => {
    if (!task_template) return
    setValue('task_template', task_template as unknown as ICreateFrom['task_template'])
  }

  const onSubmit = async (data) => {
    try {
      const response = await axios.patch(`${REACT_APP_API_URL}/automations/${automation}`, {
        ...data,
        wip_id: Number(data?.wip_id),
        clientId: Number(data.clientId),
        referrence_column_id: data.column?.value || data.referrence_column_id,
        contacts_automations: is_all_contacts_selected
          ? []
          : data.contacts.map(({ value }) => value),
      })
      onSuccessUpdate && onSuccessUpdate(response.data)
      toast.success('Automation updated successfully')
    } catch (error) {
      console.log(error)
      toast.error('Something went wrong')
    } finally {
      onClose()
    }
    queryClient.invalidateQueries([GLOBAL_QUERIES.AUTOMATIONS.GET])
  }

  const findSelectedContacts = (selectedOptions: number[]): IOption[] => {
    const selectedContacts: IOption[] = []
    for (const selectedOption of selectedOptions) {
      selectedContacts.push(contactsOptions.find(({ value }) => value === selectedOption))
    }
    return selectedContacts
  }

  const handleModalReset = (cb: () => void) => {
    if (
      (isSmsOpen !== undefined || isEmailOpen !== undefined || isTaskOpen !== undefined) &&
      resetModals
    ) {
      resetModals()
    }
    cb()
  }

  useEffect(() => {
    if (isOpen) {
      if (foundAutomation) {
        reset({
          ...foundAutomation,
          column: (externalAutomationOptions || automationOptions).find(
            ({ value }) => value === foundAutomation.referrence_column_id,
          ),
          contacts: contactsOptions.filter(
            ({ value }) => foundAutomation?.contacts_automations?.includes(value),
          ),
        })
      }
    } else {
      reset(defaultValues, { keepDirty: false, keepDefaultValues: true, keepValues: false })
    }
  }, [foundAutomation, isOpen])

  useEffect(() => {
    if (is_all_contacts_selected) {
      setValue('contacts', contactsOptions)
    } else {
      const selectedContacts = findSelectedContacts(foundAutomation?.contacts_automations || [])
      setValue('contacts', selectedContacts)
    }
  }, [is_all_contacts_selected, foundAutomation])


  const emailInitialValues = useMemo(() => ({
    email_template: email_template || '',
    email_subject: email_subject || '',
    email_template_id: email_template_id || null,
  }), [email_template, email_subject, email_template_id])
  
  return (
    <Modal isOpen={isOpen} onClose={onClose} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.wrapper} onClick={(e) => e.stopPropagation()}>
        <span>Edit Automation</span>
        <div className={styles.select}>
          <LabelWrapper<ICreateFrom>
            className={styles.labelWrapper}
            control={control}
            name={'column'}
            label="Automate Column"
          >
            {({ onChange, value }) => {
              return (
                <Select
                  type="text"
                  className="react-select"
                  placeholder="Select column to automate"
                  value={value as string}
                  onChange={onChange}
                  options={externalAutomationOptions || automationOptions}
                />
              )
            }}
          </LabelWrapper>
        </div>
        <div className={styles.select}>
          <LabelWrapper<ICreateFrom>
            className={styles.labelWrapper}
            control={control}
            name={'is_all_contacts_selected'}
            label=""
          >
            {({ onChange, value }) => {
              return (
                <div className={styles.selectContact}>
                  <Switch value={value as boolean} onChange={onChange} />
                  <span>Select All {isSales ? 'Contacts' : 'Debtors'}</span>
                </div>
              )
            }}
          </LabelWrapper>
        </div>

        {canSelectContact ? (
          <div className={styles.select}>
            <LabelWrapper<ICreateFrom>
              className={styles.labelWrapper}
              control={control}
              name={'contacts'}
              label={isSales ? 'Select Contacts' : 'Select debtors'}
            >
              {({ onChange, value }) => {
                return (
                  <Select
                    type="text"
                    className="react-select"
                    isDisabled={is_all_contacts_selected}
                    placeholder={
                      isSales ? 'Select contacts for automation' : 'Select debtors for automation'
                    }
                    value={value as string}
                    onChange={onChange}
                    options={contactsOptions}
                    isMulti
                  />
                )
              }}
            </LabelWrapper>
          </div>
        ) : null}
        <div className={styles.select}>
          <LabelWrapper<ICreateFrom>
            className={styles.labelWrapper}
            control={control}
            name={'title'}
            label="Automation Title"
          >
            {({ onChange, value }) => {
              return (
                <input
                  type="text"
                  className="react-select"
                  placeholder="Greeting Automation example"
                  value={value as string}
                  onChange={onChange}
                />
              )
            }}
          </LabelWrapper>
        </div>
        <div className={styles.select}>
          <LabelWrapper<ICreateFrom>
            className={styles.labelWrapper}
            control={control}
            name={'description'}
            label="Automation Description"
          >
            {({ onChange, value }) => {
              return (
                <textarea
                  className="react-select"
                  placeholder="Greeting Automation example"
                  value={value as string}
                  onChange={onChange}
                />
              )
            }}
          </LabelWrapper>
        </div>
        <div className={styles.bottomContainer}>
          <button onClick={handleSendMailModalOpen}>Set Email</button>
          <button onClick={handleSendSmsModalOpen}>Set SMS</button>
          <button onClick={handleSetTaskModalOpen}>Set Task</button>
        </div>
      </div>
      <SetMailModal
        isOpen={isEmailOpen !== undefined ? isEmailOpen : isSendMailModalOpened}
        onClose={() => handleModalReset(handleSendMailModalClose)}
        onSubmit={submitMailTemplate}
        initialValues={emailInitialValues}
      />
      <SetSmsModal
        isOpen={isSmsOpen !== undefined ? isSmsOpen : isSendSmsModalOpened}
        onClose={() => handleModalReset(handleSendSmsModalClose)}
        onSubmit={submitSmsTemplate}
        initialValues={{ sms_template: sms_template || '' }}
      />
      <SetTaskModal
        isOpen={isTaskOpen !== undefined ? isTaskOpen : isSetTaskModalOpened}
        onClose={() => handleModalReset(handleSetTaskModalClose)}
        onSubmit={submitTaskTemplate}
        taskTemplateInitialValues={task_template as unknown as SET_TASK}
      />
    </Modal>
  )
}

export default EditAutomationModal
