import { editInvoiceActionEnum } from '../actionTypes';
import { editInvoiceService } from '../service';

export const editInvoiceByID = (data, invoiceID) => async (dispatch) => {
  dispatch({ type: editInvoiceActionEnum.IS_LOADING_EDIT_INVOICE_FORM });
  try {
    const res = await editInvoiceService(data, invoiceID);

    dispatch({
      type: editInvoiceActionEnum.EDIT_INVOICE_SUCCESS,
      payload: res,
    });

    return res;
  } catch (error) {
    dispatch({
      type: editInvoiceActionEnum.EDIT_INVOICE_ERROR,
      payload: error,
    });

    throw error;
  }
};
