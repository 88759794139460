import { TaskProps } from "../taskboard/types";

export enum CombinedNotesMode {
    TASK = 'task',
    WIP = 'wip',
    COMPANY = 'company'
}

export enum CombinedNoteType {
    LOCAL_EMAIL = 'localEmail',
    OUTLOOK_EMAIL = 'outlookEmail',
    GENERAL_NOTE = 'generalNote',
    TASK_NOTE = 'taskNote',
    SMS = 'sms',
    CALL = 'call'
}

export interface CombinedNote {
    id: number;
    important: boolean;
    title: string;
    type: CombinedNoteType;
    created_user_id: string | number | null;
    createdAt: Date;
}

export interface GetCombinedNotesParams {
    task_id?: number;
    company_id?: number;
    wip_id?: number;
    mode: CombinedNotesMode;
    page: number;
    limit: number;
}


// props

export interface CombinedNotesModeToggleProps {
    taskId: number;
    onChange: (params: Omit<GetCombinedNotesParams, 'page'>) => void;
}

export interface AddCombinedNoteFormProps {
    taskId: number | null
    isCompleted: boolean
    onSubmit: () => void
    onClose: () => void
}

export interface CombinedNoteProps {
    taskNote: CombinedNote
    openTaskNoteModal: (taskNote: { id: number, type: CombinedNoteType, userId: string | number | null }) => void
    lastElementRef: ((node: HTMLDivElement) => void) | null
}


export interface CombinedNotesListProps {
    taskId: number | null
    combinedNotesParams: Omit<GetCombinedNotesParams, 'page'>
}

export interface CombinedNotesProps {
    task: TaskProps
    onSubmit: () => void
    onClose: () => void
}


export interface ViewModalProps {
    id: number | string | null;
    onClose: () => void;
    isOpen: boolean;
}

export interface SmsModalProps extends ViewModalProps {
}

export interface EmailModalProps extends ViewModalProps {
    userId: number | string | null;
    emailType: 'Local' | 'Outlook'
}

export interface CombinedNoteModalProps extends ViewModalProps {
    type: CombinedNoteType
    userId: number | string | null
}