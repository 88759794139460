import { useMutation } from "@tanstack/react-query"
import { CreateDebtorReferenceData, DebtorReference } from "../../types/debtor-associate-detail.type"
import { DebtorReferenceService } from "../../services/debtor-reference.service"
import { DeepPartial } from "react-hook-form"

export const useCreateDebtorReferenceMutation = () => {

  const createDebtorReference = (data: CreateDebtorReferenceData) => {
    const debtorReferenceService = new DebtorReferenceService()
    return debtorReferenceService.createDebtorReference(data)
  }

  const mutation = useMutation(createDebtorReference)

  return mutation
}

export const useUpdateDebtorReferenceMutation = () => {

  const updateDebtorReference = (id: number, data: DeepPartial<DebtorReference>) => {
    const debtorRelationService = new DebtorReferenceService()
    return debtorRelationService.updateDebtorReference(id, data)
  }

  const mutation = useMutation({ mutationFn: ({ id, data }: { id: number, data: DeepPartial<DebtorReference> }) => updateDebtorReference(id, data) })

  return mutation;
}