export const NOTE_QUERY = {
    NOTE_TYPE: {
        GET: 'getAppType'
    },
    NOTE: {
        GET: 'getNotes',
        GET_BY_ID: 'getNoteByID'
    },
    EMAIL: {
        GET: 'getEmails',
        GET_EMAIL_BY_ID: 'getEmailById'
    }
}