/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { UserContext } from '../../../../context/user-context';

const data = [
  { name: 'Page A', pv: 25 },
  { name: 'Page B', pv: 30 },
  { name: 'Page C', pv: 55 },
  { name: 'Page D', pv: 42 },
  { name: 'Page E', pv: 85 },
  { name: 'Page F', pv: 45 },
  { name: 'Page G', pv: 21 },
  { name: 'Page H', pv: 56 },
  { name: 'Page I', pv: 68 },
  { name: 'Page J', pv: 32 },
];

class BounceRate extends PureComponent {
  static contextType = UserContext
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    const {user} = this.context;

    this.state = {
      activeIndex: 0,
      user
    };
  }

  handleClick = (item) => {
    const index = data.indexOf(item.payload);
    this.setState({
      activeIndex: index,
    });
  };

  render() {
    const { user } = this.state;
    return (
      <Col md={12} xl={3} lg={6} xs={12}>
        <Card>
          <CardBody className="dashboard__card-widget mb-3">
            <h4 className="bold-text text-center">
              {(user && user.access_level === 'RECOVERY') ||
              (user && user.access_level === 'SUPER')
                ? 'ADD DEBTOR'
                : 'MAILOUT REPORT'}
            </h4>
          </CardBody>
          <CardBody className="dashboard__card-widget">
            <h4 className="bold-text text-center">
              {(user && user.access_level === 'RECOVERY') ||
              (user && user.access_level === 'SUPER') ? (
                <Link to="/recoveries/forms/protect_&_collect">RECOVERY FORM</Link>
              ) : (
                'ADD SERVICE'
              )}
            </h4>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default withTranslation('common')(BounceRate);
