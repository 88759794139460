import { PaginationResponse } from "@src/types/service.type"
import { UniformEmail } from "./email.type"
import { ShortMessage } from "./sms.type"
import { CombinedNote, CombinedNoteType } from "./combined-notes.type"
import { SelectOption } from "@src/types/component-types"

export interface SmsTableItemProps {
  shortMessage: ShortMessage
  onSmsClick?: (shortMessage: ShortMessage) => void
}

export interface SmsTableWipFilterProps {
  params: NonNullable<unknown>
}

export interface SmsTableProps {
  s: string
  currentPage: number
  onPageChange: (page: number) => void
}


export interface LastSyncDateProps {
  date: Date
}

export interface EmailTableProps {
  params: {
    wip_id?: number
    client_id?: number
  }
  currentPage: number
  onPageChange: (page: number) => void
  onEmailListChange?: (emails: PaginationResponse<UniformEmail[]> & { last_synced_at: Date }) => void
}

export interface EmailTableWithFilterProps {
  params: {
    wip_id?: number
    client_id?: number
  }
}

export interface EmailTableItemProps {
  email: UniformEmail
  onEmailClick?: (email: UniformEmail) => void
}

export interface CombinedNotesTableProps {
  params: {
    wip_id?: number
    company_id?: number
    type?: `${CombinedNoteType}`[]
  }
  currentPage?: number
  onPageChange: (page: number) => void
}

export interface CombinedNotesTableWipFilterProps {
  params: {
    wip_id?: number
    company_id?: number
  }
}

export interface CombinedNotesTableItemProps {
  note: CombinedNote
  onNoteClick?: (note: CombinedNote) => void
}

export interface AddNoteFormValue {
  task: {
    label: string
    value: number
  } | null

  note: string
  important: boolean
}

export interface AddNoteFormProps {
  params: {
    wip_id?: number
    company_id?: number
  }
}

export interface ReplyToShortMessageFormProps {
  shortMessage: ShortMessage
}

export interface ReplyToFromValues {
  body: string
}


export type PhoneContactOption = SelectOption<{
  id: number
  phone: string
  type: string
}>

export type EmailRecipientOption = SelectOption<{
  id: number
  email: string
  type: string
}>

export enum CommunicationOptionType {
  Debtor = 'Debtor',
  CompanyContact = 'CompanyContact',
  Other = 'Other'
}