import { FC, useMemo } from 'react'
import PropertyCoverForm, { PropertyCoverFormValues } from './property-cover-form.component'
import { BaseEditFormProps } from '@src/types/component-types'
import { Property, PropertyStatus } from '@containers/Sales/Properties/types/property.type'
import { useUpdatePropertyMutation } from '@containers/Sales/Properties/hooks/crud-property.hook'
import { toast } from 'react-toastify'
import { transformEmptyStringToNull } from '@containers/Recovery/Wip/_modules/Debtors/utils/transform-debtor-to-values.utils'
import { PROPERTY_STATUS_OPTIONS } from '@containers/Sales/Properties/const/properties.const'

const EditPropertyCoverForm: FC<BaseEditFormProps<Property>> = ({ entity, onSuccessSubmit }) => {
  const { mutateAsync, isLoading } = useUpdatePropertyMutation()
  const handleSuccessSubmit = async (data: PropertyCoverFormValues) => {
    const transformedData = transformEmptyStringToNull(data)
    try {
      const updateRes = await mutateAsync({
        id: entity.id,
        data: {
          ...transformedData,
          invoiceType: transformedData.invoiceType?.value,
          status: transformedData.status?.value || PropertyStatus.CurrentOpt,
        },
      })
      onSuccessSubmit(updateRes)
    } catch (e) {
      toast.error('Failed to update property cover')
      console.error(e)
    }
  }

  const initialValues = useMemo(
    () => ({
      coveredFrom: entity.coveredFrom,
      coveredTo: entity.coveredTo,
      invoiceType: entity.invoiceType
        ? { label: entity.invoiceType, value: entity.invoiceType }
        : null,
      status: PROPERTY_STATUS_OPTIONS.find((option) => option.value === entity.status) || null,
      invoiceOwner: entity.invoiceOwner,
    }),
    [entity],
  )

  return (
    <PropertyCoverForm
      onSuccessSubmit={handleSuccessSubmit}
      loading={isLoading}
      initialValues={initialValues}
    />
  )
}

export default EditPropertyCoverForm
