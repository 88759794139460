import { FC } from 'react'
import DailyRangePicker from './daily-range-picker.component'
import WeeklyRangePicker from './weekly-range-picker.compomponent'
import { DateRangePickerProps } from '../../types/global-kanban-board.type'

const DateRangePicker: FC<DateRangePickerProps> = ({
  mode = 'day',
  onChange,
  initialDate = new Date(),
  onWeekDayClick,
}) => {
  return (
    <>
      {mode === 'day' ? <DailyRangePicker onChange={onChange} initialDate={initialDate} /> : null}
      {mode === 'week' ? (
        <WeeklyRangePicker
          onChange={onChange}
          initialDate={initialDate}
          onDayClick={onWeekDayClick}
        />
      ) : null}
    </>
  )
}

export default DateRangePicker
