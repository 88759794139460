import Dropdown from '@components/dropdown/dropdown.component'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { SubHeader } from '@containers/Recovery/Wip/components/UI/text/text.component'
import styles from './add-note-form.module.scss'
import { FC, useMemo, useState } from 'react'
import {
  ADD_NOTE_FORM_DEFAULT_VALUES,
  ADD_NOTE_FORM_OPTIONS,
} from '@containers/Communication/const/communication.const'
import { Controller, useForm } from 'react-hook-form'
import { useGetTasks } from '@containers/Tasks/hooks/get-tasks.hook'
import { TaskProps } from '@containers/Setting/components/Contacts/taskboard/types'
import { useCreateTaskNoteMutation } from '@containers/Communication/hooks/mutations/create-task-note.hook'
import { useCreateGeneralNoteMutation } from '@containers/Communication/hooks/mutations/create-general-note.hook'
import { useUserContext } from '@src/context/user-context'
import { joiResolver } from '@hookform/resolvers/joi'
import { toast } from 'react-toastify'
import {
  addGeneralNoteSchema,
  addTaskNoteSchema,
} from '@containers/Communication/schemas/communication.schemas'
import {
  AddNoteFormProps,
  AddNoteFormValue,
} from '@containers/Communication/types/communication.type'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import { useQueryClient } from '@tanstack/react-query'
import FormCheckbox from '@components/inputs/form-checkbox/form-checkbox.component'

const AddNoteForm: FC<AddNoteFormProps> = ({ params }) => {
  const queryClient = useQueryClient()
  const [selectedNoteOption, setSelectedNoteOption] = useState(ADD_NOTE_FORM_OPTIONS[0])
  const { user } = useUserContext()
  const { mutateAsync: createTaskNote, isLoading: isTaskNoteCreating } = useCreateTaskNoteMutation()
  const { mutateAsync: createGeneralNote, isLoading: isGeneralNoteCreating } =
    useCreateGeneralNoteMutation()

  const { data } = useGetTasks({ fields: 'title', s: JSON.stringify(params), limit: 999 })
  const {
    handleSubmit,
    control,
    register,
    reset,
    formState: { errors },
  } = useForm<AddNoteFormValue>({
    defaultValues: ADD_NOTE_FORM_DEFAULT_VALUES,
    resolver: joiResolver(
      selectedNoteOption.value === 'task' ? addTaskNoteSchema : addGeneralNoteSchema,
    ),
  })

  const resetForm = () => {
    reset(ADD_NOTE_FORM_DEFAULT_VALUES)
  }

  const transformTaskToOptions = (tasks: TaskProps[]) => {
    return tasks.map((task) => ({
      label: task.title,
      value: task.id,
    }))
  }

  const handleGeneralNoteSubmit = async (data: AddNoteFormValue) => {
    await createGeneralNote({
      data: {
        content: data.note,
        is_important: data.important,
        task: data.task?.value ? { id: data.task.value } : null,
        staff_created: { id: user!.id },
        company_id: params.company_id || null,
        wip_id: params.wip_id || null,
      },
    })
    queryClient.invalidateQueries({ queryKey: [GLOBAL_QUERIES.COMBINED_NOTES.GET] })
    toast.success('General Note added')
    resetForm()
  }

  const handleTaskNoteSubmit = (data: AddNoteFormValue) => {
    if (!data.task) return
    createTaskNote({
      data: {
        note: data.note,
        task_id: data.task.value,
        wip_id: params.wip_id || null,
        company_id: params.company_id || null,
        createdBy: `${user?.first_name} ${user?.last_name}`,
      },
    })
    queryClient.invalidateQueries({ queryKey: [GLOBAL_QUERIES.COMBINED_NOTES.GET] })
    toast.success('Task Note added')
    resetForm()
  }

  const tasks = data?.data || []

  const taskOption = useMemo(() => transformTaskToOptions(tasks), [tasks])

  return (
    <form
      className={styles.formWrapper}
      onSubmit={handleSubmit(
        selectedNoteOption.value === 'task' ? handleTaskNoteSubmit : handleGeneralNoteSubmit,
      )}
    >
      <div className={styles.dropdownWrapper}>
        <Dropdown
          options={ADD_NOTE_FORM_OPTIONS}
          selected={selectedNoteOption}
          onSelectedChange={setSelectedNoteOption}
        />
        <Controller
          name="task"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div>
              {errors.task ? <span className={styles.error}>{errors.task.message}</span> : null}
              <Dropdown
                options={taskOption}
                selected={value}
                onSelectedChange={onChange}
                onClear={() => onChange(null)}
                clearable
              />
            </div>
          )}
        />
      </div>

      <div className={styles.labelWrapper}>
        <SubHeader fontWeight="semibold">Your message</SubHeader>
        {selectedNoteOption.value === 'general' ? (
          <FormCheckbox label="Important" {...register('important')} />
        ) : null}
        {errors.note ? <span className={styles.error}>{errors.note.message}</span> : null}
      </div>
      <div className={styles.textSectionWrapper}>
        <textarea
          placeholder="Write text here ..."
          className={styles.textarea}
          {...register('note')}
        />
        <div className={styles.textSectionActionWrapper}>
          <SubmitButton buttonType="submit" loading={isTaskNoteCreating || isGeneralNoteCreating}>
            Add Note
          </SubmitButton>
        </div>
      </div>
    </form>
  )
}

export default AddNoteForm
