import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { FormControl, MenuItem, Select } from '@material-ui/core'
import { getPropertyName } from "@containers/Sales/Properties/utils/property.utils";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: '100%',
    width: '100%',
    marginLeft: '122px',
    border: '1px solid silver',
    borderRadius: '3px',
  },
}))

export const PropertyDetailsProtectCollect = ({ register, watch, properties }) => {
  const classes = useStyles()

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          gap: '5px',
          marginBottom: '20px',
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
            width: '100%',
            marginTop: '25px',
          }}
        >
          <div className="form__form-group-label" style={{ width: '123px' }}>
            Select Property <span style={{ color: '#ff0000' }}>*</span>
          </div>
          <FormControl
            className={classes.formControl}
            style={{ margin: '0px', padding: '0px', width: '100%' }}
          >
            <Select
              disabled={properties.length === 0}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
              {...register('property.id')}
              style={{
                background: '#f5f9fc',
                borderRadius: '3px',
                width: '100%',
              }}
              defaultValue={watch('property')?.id}
              required={true}
            >
              {properties?.data?.map((property) => {
                return (
                  <MenuItem key={property.id} value={property.id}>
                    {property?.fullAddress || 'Invalid Address'}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </div>
      </div>
    </>
  )
}
