import React from 'react';
import { TailSpin } from 'react-loader-spinner';

const LoaderWip = () => {
  return (
    <div className='loader'>
      <TailSpin
        height='100'
        width='100'
        color='#f69066'
        ariaLabel='loading'
      />
    </div>
  );
};

export default LoaderWip;