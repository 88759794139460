import React from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';
import {
  deleteCommercialUncoveredByIdSelector,
  createWIPCommercialUncoveredSelector,
} from '../../../../../redux/selector/selector';
import {
  deleteCommercialUncoveredById,
  getCommercialUncoveredList,
  createWIPCommercialUncoveredForm,
} from '../../../../../redux/actions';

import { toast } from 'react-toastify';
import { TableRow, TableCell } from '@material-ui/core';
import AppButton from '../../../../../shared/components/AppButton/AppButton';

import { styles } from './styles';
import { useUserContext } from '../../../../../context/user-context';

const CommercialUncoveredTableRow = ({ row, index }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {user} = useUserContext()

  const { loading } = useSelector(deleteCommercialUncoveredByIdSelector);
  const WIP = useSelector(createWIPCommercialUncoveredSelector);

  const deleteItemById = () => {
    dispatch(deleteCommercialUncoveredById(row?.id))
      .then(() => {
        toast.success('Submited Successfully');
        dispatch(getCommercialUncoveredList());
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const createWip = () => {
    const currentStaff = user;
    const createWIPDto = {
      form_id: row?.id,
      staff_created_id: currentStaff.id,
    };

    dispatch(createWIPCommercialUncoveredForm(createWIPDto))
      .then((res) => {
        toast.success(`WIP File #${res?.data?.id} Created Successfully`);
      })
      .catch(() => {
        toast.error('Something went wrong!');
      });
  };

  const editFormById = () => {
    history.push(`/recoveries/forms/edit-commercial/${row.id}`);
  };

  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={index} style={styles.tableRow}>
      <TableCell component="th" scope="row" padding="none">
        {row.created_at ? moment(row.created_at).format('MM-DD-YYYY HH:mm') : '-'}
      </TableCell>
      <TableCell align="right">{row?.recovery_details?.carpet_cleaning || '-'}</TableCell>
      <TableCell align="right">
        {row?.tenants_details?.map((item, index) => {
          return <span key={index}>{item.first_name || '-'}</span>;
        })}
      </TableCell>
      <TableCell align="right">{row.id || '-'}</TableCell>
      <TableCell align="right">{row?.owner_details?.name || '-'}</TableCell>
      <TableCell align="right">Submitted</TableCell>
      <TableCell align="right" style={styles.tableCell}>
        <AppButton
          onClick={createWip}
          content="Create WIP"
          loading={WIP?.loading}
          buttonStyle="btn-success-wip py-1 px-2 m-0 mr-2"
        />
        <AppButton onClick={editFormById} content="Edit" buttonStyle="btn-info py-1 px-2 m-0" />
        <AppButton
          onClick={deleteItemById}
          content="Remove"
          loading={loading}
          buttonStyle="btn-danger py-1 px-2 m-0 ml-2"
        />
      </TableCell>
    </TableRow>
  );
};

export default CommercialUncoveredTableRow;
