import { ValidationErrorItem } from 'joi'
import { useEffect, useState } from 'react'
import { DataGridColumn, InnerDataGridColumn, Row } from './data-grid.type'
import { DataGridService } from './data-grid.service'
import { usePropertiesContext } from '@src/context/properties.context'

export const useDataGrid = (
  width: number,
  header: DataGridColumn[],
  data: Record<string, string[]>,
  onRowsChange?: (rows: Row[]) => void,
) => {
  const { mergePropertyRows, resolvedRowsIds, setMergePropertiesRows } = usePropertiesContext()

  const checkboxWidth = 55
  const widthWithoutCheckbox = width - checkboxWidth
  const [isAllRowsSelected, setIsAllRowsSelected] = useState(false)
  const [innerRows, setInnerRows] = useState<Row[]>(DataGridService.initializeRows(header, data))
  const [shownRows, setShownRows] = useState<Row[]>(innerRows)
  const [innerHeader, setInnerHeader] = useState<InnerDataGridColumn[]>(
    header.map((column) => ({
      ...column,
      selected: false,
      width: widthWithoutCheckbox / header.length,
    })),
  )

  const changeCellValueForColumn = (columnIndex: number, value: string) => {
    const newRows = [...innerRows]
    newRows.forEach((row) => {
      row.data[columnIndex].value = value
    })
    handleRowsChange(newRows)
  }

  const handleRowsChange = (newRows: Row[]) => {
    setInnerRows(newRows)
    onRowsChange && onRowsChange(newRows)
  }

  const handleCellChange = (rowId: string, cellIndex: number, value: string) => {
    const header = innerHeader[cellIndex]
    if (header.selected) {
      changeCellValueForColumn(cellIndex, value)
      return
    }

    const newRows = [...innerRows]
    const rowIndex = newRows.findIndex((row) => row.id === rowId)
    if (rowIndex === -1) {
      return
    }
    newRows[rowIndex].data[cellIndex].value = value
    handleRowsChange(newRows)
  }

  const handleCellSelectionChange = (rowId: string, cellIndex: number, value: boolean) => {
    const isHeaderSelected = innerHeader[cellIndex].selected

    if (!isHeaderSelected) {
      setInnerHeader((prev) => prev.map((column) => ({ ...column, selected: false })))
    }

    const newRows = [...innerRows].map((row) => ({
      ...row,
      data: row.data.map((cell) => ({ ...cell, selected: false })),
    }))
    const rowIndex = newRows.findIndex((row) => row.id === rowId)
    if (rowIndex === -1) {
      return
    }
    newRows[rowIndex].data[cellIndex].selected = value
    handleRowsChange(newRows)
  }

  const handleErrorsChange = (
    rowId: string,
    cellIndex: number,
    errors: ValidationErrorItem[] | undefined,
  ) => {
    const newRows = [...innerRows]
    const rowIndex = newRows.findIndex((row) => row.id === rowId)
    if (rowIndex === -1) {
      return
    }
    newRows[rowIndex].data[cellIndex].error = errors?.[0]
    handleRowsChange(newRows)
  }

  /* 
    Select functions
  */

  const handleSelectAll = () => {
    const newRows = [...innerRows]
    const newCheckboxState = !isAllRowsSelected
    newRows.forEach((row) => {
      row.selected = newCheckboxState
    })
    setIsAllRowsSelected(newCheckboxState)
    handleRowsChange(newRows)
  }

  const handleHeaderSelect = (index: number) => {
    const newHeader = innerHeader.map((column, i) => ({ ...column, selected: i === index }))
    setInnerHeader(newHeader)
    const newRows = [...innerRows].map((row) => ({
      ...row,
      data: row.data.map((cell) => ({ ...cell, selected: false })),
    }))
    newRows[0].data[index].selected = true
    handleRowsChange(newRows)
  }

  const handleRowSelect = (rowId: string) => {
    const newRows = [...innerRows]
    const rowIndex = newRows.findIndex((row) => row.id === rowId)
    if (rowIndex === -1) {
      return
    }

    newRows[rowIndex].selected = !newRows[rowIndex].selected
    handleRowsChange(newRows)
  }

  const findColumns = (arr: DataGridColumn[], values: string[]) => {
    const indexes = arr
      .map((el, idx) => (values.includes(el.name) ? idx : null))
      .filter((v) => typeof v === 'number')
    return indexes || []
  }
  const innerRowMap = innerRows.map((data) => ({ [data.id]: data }))
  const [isMergePropertyBeingDeleted, setIsMergePropertyBeingDeleted] = useState(false)
  const mergeConflict = (id: number) => {
    if (!mergePropertyRows) return
    setMergePropertiesRows(mergePropertyRows?.filter((row) => Number(row.id) !== id))
  }
  useEffect(() => {
    if (isMergePropertyBeingDeleted) {
      setIsMergePropertyBeingDeleted(false)
      return
    }
    if (!mergePropertyRows) return
    mergePropertyRows.map(({ id, property }) => {
      const indexes = findColumns(header, ['firstName', 'lastName'])
      const foundRow: Record<string, Record<string, Row>> = innerRowMap[id]

      if (resolvedRowsIds && resolvedRowsIds.length > 0) {
        const findRowId = resolvedRowsIds.find((idx) => idx === Number(id))

        if (typeof findRowId !== 'number') return
        indexes.map((idx) => (foundRow[findRowId].data[Number(idx)].error = undefined))
        mergeConflict(findRowId)
        setIsMergePropertyBeingDeleted(true)
        return
      }

      if (!property) return
      indexes.map((idx) => (foundRow[id].data[Number(idx)].error = {}))
    })
  }, [mergePropertyRows, resolvedRowsIds])
  return {
    isAllRowsSelected,
    innerRows,
    innerHeader,
    shownRows,
    setInnerRows,
    setInnerHeader,
    handleCellChange,
    handleCellSelectionChange,
    handleErrorsChange,
    handleSelectAll,
    handleHeaderSelect,
    handleRowSelect,
    setShownRows,
  }
}
