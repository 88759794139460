import { useEffect } from "react"
import { useGetMailFolders } from "./get-mail-folders.hook"
import { useCreateMailFolderMutation } from "./mutations/create-mail-folder.hook"
import { useQueryClient } from "@tanstack/react-query"
import { GLOBAL_QUERIES } from "@src/lib/global-queries"
import { useIsAuthenticated } from "@azure/msal-react"

export const useGetMailFolderByDisplayNameOrCreate = (displayName: string) => {
  const isAuthenticated = useIsAuthenticated()
  const queryClient = useQueryClient()
  const { mutateAsync, isLoading: isMailFolderCreating } = useCreateMailFolderMutation()
  const { data: mailFolders, isLoading: isMailFoldersLoading, isRefetching: isMailFoldersRefetching } = useGetMailFolders({ $filter: `displayName eq '${displayName}'` })

  const isMailFoldersFetching = isMailFoldersLoading || isMailFoldersRefetching

  const createMailFolder = async (displayName: string) => {
    if (isMailFolderCreating) return null
    try {
      const mailFolder = await mutateAsync(displayName)
      queryClient.invalidateQueries({ queryKey: [GLOBAL_QUERIES.MAIL_FOLDER.GET] })
      return mailFolder
    } catch (error) {
      console.error(error)
      return null
    }
  }

  useEffect(() => {
    if (!mailFolders?.length && !isMailFoldersFetching && !isMailFolderCreating) {
      createMailFolder(displayName)
    }
  }, [mailFolders, isMailFoldersFetching, isMailFolderCreating])

  const mailFolder = mailFolders?.[0] || null

  return { mailFolder, isMailFoldersFetching: isAuthenticated ? (isMailFoldersFetching || isMailFolderCreating) : false }
}