import React from 'react'
import TableCell from '@components/table/table-cell-component'
import TableRow from '@components/table/table-row.component'
import { DebtorRelationTableItemProps } from '@containers/Recovery/Wip/_modules/Debtors/types/debtor-associate-detail.type'
import { FC } from 'react'
import styles from './debtor-relation-table.module.scss'
import TrashIcon from '@src/icons/trash'
import { DebtorRelationService } from '@containers/Recovery/Wip/_modules/Debtors/services/debtor-relation.service'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import { getDebtorQueryKey } from '@containers/Recovery/Wip/_modules/Debtors/utils/debtor-query-key-factory.utils'
import { Debtor } from '@containers/Recovery/Wip/_modules/Debtors/types/debtor.type'

const DebtorRelationTableItem: FC<DebtorRelationTableItemProps> = ({
  relation,
  debtorId,
  onItemClick,
}) => {
  const queryClient = useQueryClient()

  const handleRemoveClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    const debtorRelationService = new DebtorRelationService()
    try {
      await debtorRelationService.deleteDebtorRelation(relation.id)
      queryClient.setQueryData<Debtor>(getDebtorQueryKey(debtorId), (prevData) =>
        prevData
          ? {
              ...prevData,
              relations: prevData.relations.filter((r) => r.id !== relation.id),
            }
          : undefined,
      )
    } catch (e) {
      toast.error('Something went wrong')
      console.error(e)
    }
  }

  return (
    <TableRow onClick={() => onItemClick(relation)} pointer>
      {/* NAME */}
      <TableCell className={styles.debtorName} width={200} position="eclipsed">
        {relation.name}
      </TableCell>
      {/* RELATIONSHIP */}
      <TableCell width={100} position="eclipsed">
        {relation.relationship}
      </TableCell>
      {/* PHONE*/}
      <TableCell width={150} position="eclipsed">
        {relation.phone}
      </TableCell>
      {/* ADDRESS */}
      <TableCell position="eclipsed">{relation.address}</TableCell>
      {/* REMOVE */}
      <TableCell width={95} position="center">
        <button className={styles.deleteButton} onClick={handleRemoveClick}>
          <TrashIcon />
        </button>
      </TableCell>
    </TableRow>
  )
}

export default DebtorRelationTableItem
