import { FC, useCallback, useMemo, useRef, useState } from 'react'
import styles from './claim-details-note-list.module.scss'
import { useModal } from '@src/hooks/useModal'
import ClaimDetailsNoteListItem from './claim-details-note-list-item.component'
import { ClaimDetailsNoteListProps } from '../../types/claim-detail.type'
import { useGetClaimDetails } from '../../hooks/get-claim-details-notes.hook'
import ViewClaimDetailsNoteModal from '../modals/view-claim-detial-note-modal/view-claim-detail-note-modal.component'

const ClaimDetailsNoteList: FC<ClaimDetailsNoteListProps> = ({ wipId }) => {
  const params = useMemo(
    () => ({
      s: JSON.stringify({ wip_id: wipId }),
      sort: 'created_at,DESC',
      limit: 5,
    }),
    [wipId],
  )
  const [isNoteModalOpen, openNoteModal, closeNoteModal] = useModal(false)
  const [selectedNoteId, setSelectedNoteId] = useState<number | null>(null)
  const { data, isLoading, fetchNextPage, hasNextPage, isFetching } = useGetClaimDetails(params)

  const observer = useRef<IntersectionObserver>()

  const lastElementRef = useCallback(
    (node: HTMLDivElement) => {
      if (isLoading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage && !isFetching) {
          fetchNextPage()
        }
      })
      if (node) observer.current.observe(node)
    },
    [isLoading, isFetching, hasNextPage],
  )

  const handleOpenNoteClick = (id: number) => {
    setSelectedNoteId(id)
    openNoteModal()
  }

  return (
    <div>
      <div className={styles.claimDetailsNoteListWrapper}>
        {data.map((note, i) => (
          <ClaimDetailsNoteListItem
            onOpenNoteClick={handleOpenNoteClick}
            lastElementRef={data.length === i + 1 ? lastElementRef : null}
            key={`${i}-${note.id}-details-note`}
            note={note}
          />
        ))}
      </div>

      <ViewClaimDetailsNoteModal
        isOpen={isNoteModalOpen}
        onClose={closeNoteModal}
        claimDetailsNoteId={selectedNoteId}
      />
    </div>
  )
}

export default ClaimDetailsNoteList
