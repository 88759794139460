import { useCreateLandlordContactMutation } from '@containers/Sales/Landlord/hooks/crud-landlord-contact.hook'
import LandlordContactForm, { LandlordContactFormValues } from './landlord-contact-form.component'
import { FC } from 'react'
import { transformEmptyStringToNull } from '@containers/Recovery/Wip/_modules/Debtors/utils/transform-debtor-to-values.utils'
import { toast } from 'react-toastify'
import { LandlordContact } from '@containers/Sales/Properties/types/property.type'

interface CreateLandlordContactFormProps {
  landlordId: number
  onSuccessSubmit?: (landlordContact: LandlordContact) => void
  onCancelClick?: () => void
}

const CreateLandlordContactForm: FC<CreateLandlordContactFormProps> = ({
  landlordId,
  onSuccessSubmit,
  onCancelClick,
}) => {
  const { mutateAsync, isLoading } = useCreateLandlordContactMutation()

  const handleSuccessSubmit = async (data: LandlordContactFormValues) => {
    try {
      const res = await mutateAsync({
        landlordId,
        data: transformEmptyStringToNull(data),
      })
      onSuccessSubmit && onSuccessSubmit(res)
    } catch (e) {
      toast.error('faild to create landlord contact')
      console.log(e)
    }
  }

  return (
    <LandlordContactForm
      loading={isLoading}
      onSuccessSubmit={handleSuccessSubmit}
      onCancelClick={onCancelClick}
    />
  )
}

export default CreateLandlordContactForm
