/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
// import { Card, CardBody, Col } from 'reactstrap';
import TrendingUpIcon from 'mdi-react/TrendingUpIcon';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  BarChart, Bar, Cell, ResponsiveContainer,
} from 'recharts';
import Panel from '../../../../shared/components/Panel';


const data = [
  { name: 'Page A', amt: 4000 },
  { name: 'Page B', amt: 2210 },
  { name: 'Page C', amt: 2290 },
  { name: 'Page D', amt: 2000 },
  { name: 'Page E', amt: 2181 },
  { name: 'Page F', amt: 2500 },
  { name: 'Page G', amt: 2100 },
  { name: 'Page H', amt: 2290 },
  { name: 'Page I', amt: 2000 },
  { name: 'Page J', amt: 2181 },
];

class TotalPageViews extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
    };
  }

  handleClick = (item) => {
    const index = data.indexOf(item.payload);
    this.setState({
      activeIndex: index,
    });
  };

  render() {
    const { activeIndex } = this.state;
    const activeItem = data[activeIndex];

    return (
      // <Col md={12} xl={3} lg={6} xs={12}>
      //   <Card>
      //     <CardBody className="dashboard__card-widget">
      //       <div className="card__title">
      //       <h5 className="bold-text">Task Completed (Week 12)</h5>
      //       <h1>85</h1>
      //       </div>
      //       <div className="dashboard__total">
      //         <TrendingUpIcon className="dashboard__trend-icon" />
      //         <p className="dashboard__total-stat">
      //           {activeItem.amt}
      //         </p>
      //         <div className="dashboard__chart-container">
      //           <ResponsiveContainer height={50}>
      //             <BarChart data={data}>
      //               <Bar dataKey="amt" onClick={this.handleClick}>
      //                 {
      //                   data.map((entry, index) => (
      //                     <Cell
      //                       cursor="pointer"
      //                       fill={index === activeIndex ? '#ff5a16' : '#70bbfd'}
      //                       key={`cell-${index}`}
      //                     />
      //                   ))
      //                 }
      //               </Bar>
      //             </BarChart>
      //           </ResponsiveContainer>
      //         </div>
      //       </div>
      //     </CardBody>
      //   </Card>
      // </Col>

      <Panel md={12} xl={3} lg={6} xs={12} title="Task Completed">
        {/* <Card> */}
          {/* <CardBody className="dashboard__card-widget"> */}
            {/* <div className="card__title">
            <h5 className="bold-text">Fund Collected (June)</h5>
            <h1>$9,600</h1>

            </div> */}
            <div className="dashboard__total">
              <TrendingUpIcon className="dashboard__trend-icon" />
              <p className="dashboard__total-stat">
                {activeItem.uv}
              </p>
              <div className="dashboard__chart-container">
                <ResponsiveContainer height={50}>
                  <BarChart data={data}>
                    <Bar dataKey="amt" onClick={this.handleClick}>
                      {
                        data.map((entry, index) => (
                          <Cell
                            cursor="pointer"
                            fill={index === activeIndex ? '#ff5a16' : '#70bbfd'}
                            key={`cell-${index}`}
                          />
                        ))
                      }
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          {/* </CardBody> */}
        {/* </Card> */}
      </Panel>
    );
  }
}

export default withTranslation('common')(TotalPageViews);
