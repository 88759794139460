import { useUserContext } from '@src/context/user-context'
import { Route, Redirect } from 'react-router-dom'

export const PublicRoute = ({ component: Component, ...rest }) => {
  const { user } = useUserContext()
  return (
    <Route
      {...rest}
      render={(props) =>
        !user ? (
          <div>
            <Component {...props} />
          </div>
        ) : (
          <Redirect
            to={{
              pathname: '/dashboard',
            }}
          />
        )
      }
    />
  )
}
