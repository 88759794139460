import React, { FC, createContext, useContext, useState } from 'react'

type Style = {
  backgroundColor: string
}

const initialStyle: Style = {
  backgroundColor: 'white',
}

export const MainStyleContext = createContext<{
  style: Style
  setStyle: React.Dispatch<React.SetStateAction<Style>>
}>({
  style: initialStyle,
  setStyle: () => {},
})

interface MainStyleProviderProps {
  children: React.ReactNode
}

export const MainStyleProvider: FC<MainStyleProviderProps> = ({ children }) => {
  const [style, setStyle] = useState<Style>(initialStyle)

  return (
    <MainStyleContext.Provider value={{ style, setStyle }}>{children}</MainStyleContext.Provider>
  )
}

export const useMainStyleContext = () => {
  const context = useContext(MainStyleContext)

  if (!context) {
    throw new Error('useMainStyleContext must be used within a MainStyleProvider')
  }

  return context
}
