import FormSelect from '@components/inputs/form-select/form-select.component'
import { Header, SubHeader, Text } from '@containers/Recovery/Wip/components/UI/text/text.component'
import { useForm, useWatch } from 'react-hook-form'
import styles from './merge-property-form.module.scss'
import FormTextArea from '@components/inputs/form-textarea/form-textarea.component'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import HierarchicalSelect from '@components/hierarchical-select/hierarchical-select.component'
import { BaseEditModalProps } from '@src/types/component-types'
import { FC, useEffect, useState } from 'react'
import { Row } from '@components/data-grid/data-grid.type'
import { usePropertiesContext } from '@src/context/properties.context'
import { getFullAddressByObject } from '@containers/Sales/Properties/utils/property.utils'
import { ClientServiceInstance } from '@containers/Sales/Client/services/client.service'
import { useGetInfinityLandlords } from '@containers/Sales/Landlord/hooks/crud-landlord.hook'
import { useLocation } from 'react-router'
import { TABLE_ROWS_AND_COLUMNS } from './utils'
import { DataGridService } from '@components/data-grid/data-grid.service'
import {
  MergePropertyRow,
  Property,
} from '@containers/Sales/Properties/types/property.type'
import { PropertyServiceInstance } from '@containers/Sales/Properties/services/property.service'

type SelectOptionsType = {
  label: string
  value: number
}

interface MergePropertyFormValues {
  preSelectedLandlord: string
  preSelectedProperty: string
  selectedClientOption: SelectOptionsType | null
  selectedLandlordOption: SelectOptionsType | null
  selectedPropertyOption: SelectOptionsType | null
}

interface Props extends Omit<BaseEditModalProps<Row | null>, 'isOpen'> {
  removeRow: (id: number) => void
}

const MergePropertyForm: FC<Props> = ({
                                        onClose = () => null,
                                        entity,
                                        removeRow,
                                      }) => {

  const params = useLocation()
  const currentClientId = Number(params.pathname.split('/')[2] || -1)


  const [currentClient, setCurrentClient] = useState<null | SelectOptionsType>(null)
  const [currentLandLord, setCurrentLandLord] = useState<null | SelectOptionsType>(null)
  const [clientOptions, setClientOptions] = useState<SelectOptionsType[]>([])
  const [landlordOptions, setLandlordOptions] = useState<SelectOptionsType[]>([])
  const [properties, setProperties] = useState<Property[]>([])
  const { data: landlords } = useGetInfinityLandlords({ limit: 999 })
  const { mergePropertyRows, header, setResolvedRowId } = usePropertiesContext()
  const parsedEntity = DataGridService.transformDataGridRowsToData(
    [entity || ([] as any)],
    header as any,
  )[0] as any
  const parsedRowEntity: MergePropertyRow | null =
    mergePropertyRows?.find(({ id }) => id === entity?.id) || null

  const preSelectedPropertyValue = getFullAddressByObject({
    addressLine1: parsedEntity.address1,
    addressLine2: parsedEntity.address2,
    addressLine3: parsedEntity.address3,
  } as any as Property)

  const preSelectedLandlordValue = `${parsedEntity.firstName} ${parsedEntity.lastName}`

  const { control, handleSubmit, setValue } = useForm<MergePropertyFormValues>({
    defaultValues: {
      preSelectedLandlord: preSelectedLandlordValue,
      preSelectedProperty: preSelectedPropertyValue,
      selectedClientOption: null,
      selectedLandlordOption: null,
      selectedPropertyOption: null,
    },
  })
  const { preSelectedLandlord, preSelectedProperty } = useWatch({ control })
  const onSubmit = (data: MergePropertyFormValues) => {

    if (entity) {
      const [firstName, lastName] = preSelectedLandlordValue.split(' ')
      const newLandlordValue = landlords.find(el => el.id === data.selectedLandlordOption?.value)?.companyName?.split(' ') || []
      const firstNameIdx = entity.data.findIndex(el => el.value === firstName)
      const lastNameIdx = entity.data.findIndex(el => el.value === lastName)
      entity.data[firstNameIdx].value = newLandlordValue[0]
      entity.data[lastNameIdx].value = newLandlordValue[1]
      entity.action.landlordId = data.selectedLandlordOption?.value || null;
      entity.action.clientToId = data.selectedClientOption?.value || null;
      entity.action.clientFromId = existRecordCompanyId || null;
    }
    setResolvedRowId(Number(entity?.id || -1))
    onClose()
  }

  const isLandlordMissMatch = parsedRowEntity?.property?.isFullNameEquals
  const existRecordCompanyId = parsedRowEntity?.property?.companies[0].id;
  useEffect(() => {
    if (parsedRowEntity) {
      setCurrentLandLord({
        label: parsedRowEntity?.property?.landlord?.companyName || '',
        value: parsedRowEntity?.property?.landlord?.id || -1,
      })
    }
  }, [parsedRowEntity])

  useEffect(() => {

    (async () => {
      try {
        const res = await PropertyServiceInstance.getProperties()
        setProperties(res.data)
      } catch (error) {
        console.log(error)
      }

    })()
  }, [])

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const currentClient = (await ClientServiceInstance.getClientById(currentClientId)) || null
        if (currentClient) {
          setCurrentClient({ label: currentClient.name, value: currentClient?.id })
        }

        const response = await ClientServiceInstance.getClients({ limit: 999 })
        const formatedResponse =
          response?.data?.map((el) => ({ label: el.name, value: el.id })) || []
        setClientOptions(formatedResponse)
      } catch (error) {
        console.log(error)
      }
    }
    fetchClients()
  }, [])

  useEffect(() => {
    if (landlords && landlords.length > 0) {
      const formatedLandlords =
        landlords.map(({ id, companyName }) => ({
          label: companyName,
          value: id,
        })) || []
      setLandlordOptions(formatedLandlords)
    }
  }, [landlords])

  useEffect(() => {
    if (currentClient) {
      setValue('selectedClientOption', currentClient)
    }
    if (currentLandLord) {
      setValue('selectedLandlordOption', currentLandLord)
    }

  }, [currentClient, currentLandLord])
  return (
    <form className={styles.formWrapper} onSubmit={handleSubmit(onSubmit)}>
      <Header>Current Conflict</Header>
      <Text>CSV</Text>
      <div className={styles.row}>
        <div
          className={styles.inputWrapper}
        >
          <span>Client</span>
          <div>
            <span>{currentClient?.label}</span>
          </div>
        </div>
        <div
          className={styles.inputWrapper}
        >
          <span>Property</span>
          <div>
            <span>{preSelectedProperty}</span>
          </div>
        </div>
        {!isLandlordMissMatch && (
          <div
            className={styles.inputWrapper}
          >
            <span>Landlord</span>
            <div>
              <span>{preSelectedLandlord || '-'}</span>
            </div>
          </div>
        )}
      </div>
      <Text>Existing record</Text>
      <div className={styles.row}>
        <div
          className={styles.inputWrapper}
        >
          <span>Client</span>
          <div>
            <span>{clientOptions.find(el=>el.value === existRecordCompanyId)?.label}</span>
          </div>
        </div>
        <div
          className={styles.inputWrapper}
        >
          <span>Property</span>
          <div>
            <span>{ preSelectedProperty }</span>
          </div>
        </div>
        {!isLandlordMissMatch && (
          <div
            className={styles.inputWrapper}
          >
            <span>Landlord</span>
            <div>
              <span>{parsedRowEntity?.property?.landlord?.companyName || '-'}</span>
            </div>
          </div>
        )}
      </div>
      <div className={styles.buttonWrapper}>
        <SubmitButton buttonType='button' buttonStyle='alert'  onClick={() => {
          removeRow(Number(entity?.id || -1))
        }}>
          Leave existing record
        </SubmitButton>
      </div>

      <Header>Merge option</Header>
      <Text>Select options to resolve the conflict</Text>
      <div className={styles.row}>
        <FormSelect
          label="Client"
          control={control}
          name="selectedClientOption"
          options={clientOptions}
        />
        <div className={styles.selectWrapper}>
          <SubHeader fontWeight="semibold" mb={2}>
            Property
          </SubHeader>
          <HierarchicalSelect
            defaultSelectedId={ properties.find(el => el.id === parsedRowEntity?.property?.id)?.id  }
            options={[
              {
                label: 'PROPERTY',
                columns: TABLE_ROWS_AND_COLUMNS,
                options: properties.map((item) => {
                  return {
                    label: item.fullAddress || '',
                    id: item.id,
                    data: {
                      client: '',
                      address: `${item.addressLine1} ${item.addressLine2} ${item.addressLine3}`,
                      suburb: item.suburb,
                      state: item.state,
                      postcode: item.postcode,
                      landlords: landlords.find(el => el.id === item.landlordId)?.companyName || ' ',
                    },
                  }
                }),
              },
            ]}
          />
        </div>
        <FormSelect
          label="Landlord"
          control={control}
          name="selectedLandlordOption"
          options={landlordOptions}
        />
      </div>
      <FormTextArea
        label="Do you have any comment to leave?"
        placeholder="Your note to store here."
      />
      <div className={styles.actionWrapper}>
        <SubmitButton onClick={onClose} buttonStyle="secondary" buttonType="button">
          Cancel
        </SubmitButton>
        <SubmitButton
          buttonType="submit"
        >
          Merge
        </SubmitButton>
      </div>
    </form>
  )
}

export default MergePropertyForm
