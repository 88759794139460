import { FC, useState } from 'react'
import UploadMultiplePropertiesStepper from './upload-multiple-properties-stepper.component'
import styles from './upload-multiple-properties-form.module.scss'
import UploadFileStep from './steps/upload-file-step.component'
import SelectHeaderRowStep from './steps/select-header-row-step.component'
import { SelectHeaderStepResponse } from '@containers/Sales/Properties/types/property.type'
import PropertyMatchColumnsStep from './steps/property-match-columns-step.component'
import ValidateDataStep from './steps/validate-data-step.component'
import {
  PROPERTY_DATA_GRID_COLUMNS_MAP,
  PropertyColumn,
} from '@containers/Sales/Properties/const/properties.const'
import { DataGridColumn, Row } from '@components/data-grid/data-grid.type'
import ProcessStep from './steps/process-step.component'

interface UploadMultiplePropertiesFormProps {
  onSubmit: (data: Record<string, string>[]) => void
}

const UploadMultiplePropertiesForm: FC<UploadMultiplePropertiesFormProps> = ({ onSubmit }) => {
  const [activeStep, setActiveStep] = useState(0)
  const [jsonSheet, setJsonSheet] = useState<Record<string, string>[]>([{}])
  const [selectHeaderStepResponse, setSelectHeaderStepResponse] =
    useState<SelectHeaderStepResponse | null>(null)
  const [matchedColumns, setMatchedColumns] = useState<Record<string, string[]>>({})
  const [validatedData, setValidatedData] = useState<Record<string, string>[]>([])

  const handleSuccessfulUpload = async (jsonSheet: Record<string, string>[]) => {
    setJsonSheet(jsonSheet)
    setActiveStep(1)
  }

  const handleSuccessfulSelectHeader = (data: SelectHeaderStepResponse) => {
    setSelectHeaderStepResponse(data)
    setActiveStep(2)
  }

  const handleSuccessfulMatchColumns = (data: Record<string, string[]>) => {
    setMatchedColumns(data)
    setActiveStep(3)
  }

  const handleFinishDataValidation = (data: Record<string, string>[]) => {
    setValidatedData(data)
    setActiveStep(4)
  }

  const isRowValid = (row: Row, header: DataGridColumn[]) => {
    const cells = row.data
    const requiredColumns = [
      PropertyColumn.OWNER_FIRST_NAME,
      PropertyColumn.OWNER_LAST_NAME,
      PropertyColumn.PROPERTY_ADDRESS_1,
      PropertyColumn.PROPERTY_ADDRESS_2,
      PropertyColumn.PROPERTY_SUBURB,
      PropertyColumn.PROPERTY_STATE,
      PropertyColumn.PROPERTY_POSTCODE,
    ]
    const missingColumns: string[] = []
    for (const column of requiredColumns) {
      const columnIndex = header.findIndex((headerColumn) => headerColumn.name === column)
      if (columnIndex === -1) {
        continue
      }
      const cell = cells[columnIndex]
      if (!cell.value) {
        missingColumns.push(column)
      }
    }

    return missingColumns.length === 0
  }

  return (
    <div className={styles.wrapper}>
      <UploadMultiplePropertiesStepper activeStep={activeStep} />
      {activeStep === 0 ? <UploadFileStep onSuccessfulUpload={handleSuccessfulUpload} /> : null}
      {activeStep === 1 ? (
        <SelectHeaderRowStep
          onSuccessfulSelectHeader={handleSuccessfulSelectHeader}
          jsonSheet={jsonSheet}
        />
      ) : null}
      {activeStep === 2 && selectHeaderStepResponse ? (
        <PropertyMatchColumnsStep
          onNextStepClick={handleSuccessfulMatchColumns}
          header={selectHeaderStepResponse.rows[selectHeaderStepResponse.headerRowIndex]}
          rows={selectHeaderStepResponse.rows.filter(
            (_, index) => index !== selectHeaderStepResponse.headerRowIndex,
          )}
        />
      ) : null}
      {activeStep === 3 ? (
        <ValidateDataStep
          data={matchedColumns}
          header={Object.keys(matchedColumns).map((key) => PROPERTY_DATA_GRID_COLUMNS_MAP[key])}
          onValidateDataFinish={handleFinishDataValidation}
          isRowValid={isRowValid}
        />
      ) : null}
      {activeStep === 4 ? (
        <ProcessStep
          data={validatedData}
          header={Object.keys(matchedColumns).map((key) => PROPERTY_DATA_GRID_COLUMNS_MAP[key])}
          onSuccess={onSubmit}
          isRowValid={isRowValid}
        />
      ) : null}
    </div>

  )
}

export default UploadMultiplePropertiesForm
