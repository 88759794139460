import { toast } from 'react-toastify'
import { FC, useMemo } from 'react'
import { BaseEditFormProps } from '@src/types/component-types'
import { DebtorLegalTribunal } from '../../../types/debtor-legal.type'
import DebtorLegalTribunalForm from './debtor-legal-tribunal-form.component'
import { DebtorLegalTribunalFormValues } from '../../../types/debtor-legal-tribunal.type'
import { useUpdateDebtorLegalTribunalMutation } from '../../../hooks/mutations/crud-debtor-legal-tribunal.hook'

const EditDebtorLegalTribunalForm: FC<BaseEditFormProps<DebtorLegalTribunal>> = ({
  entity: debtorLegalTribunal,
  onSuccessSubmit,
}) => {
  const { mutateAsync, isLoading } = useUpdateDebtorLegalTribunalMutation()
  const handleSuccessSubmit = async (data: DebtorLegalTribunalFormValues) => {
    try {
      const updatedData = await mutateAsync({
        debtorLegalTribunalId: debtorLegalTribunal.id,
        data,
      })
      onSuccessSubmit(updatedData)
    } catch (error) {
      toast.error('Failed to update tribunal')
      console.error(error)
    }
  }

  const initialValues = useMemo(
    () => ({
      tribunalCaseNumber: debtorLegalTribunal.tribunalCaseNumber,
      tribunalLocation: debtorLegalTribunal.tribunalLocation,
      tribunalOrderDate: debtorLegalTribunal.tribunalOrderDate,
    }),
    [debtorLegalTribunal],
  )

  return (
    <DebtorLegalTribunalForm
      onSuccessSubmit={handleSuccessSubmit}
      initialValues={initialValues}
      loading={isLoading}
      submitButtonTitle="Update Tribunal"
    />
  )
}

export default EditDebtorLegalTribunalForm;
