import { FC, useMemo } from 'react'
import PaymentTrackingForm from './payment-tracking-form.component'
import { BaseEditFormProps } from '@src/types/component-types'
import { PaymentTracking, PaymentTrackingFormValues } from '../../../types/payment-tracking.type'
import { useUpdatePaymentTrackingMutation } from '../../hooks/mutations/crud-payment-tracking.hook'
import { ACCOUNTS_OPTIONS, TRACKING_PERIOD_OPTIONS } from '../../../const/tracking.const'
import { formatCurrency, parseStringToNumber } from '@containers/Recovery/Wip/utils/format-currency'
import { toast } from 'react-toastify'

const EditPaymentTrackingForm: FC<BaseEditFormProps<PaymentTracking>> = ({
  entity,
  onSuccessSubmit,
}) => {
  const { mutateAsync, isLoading } = useUpdatePaymentTrackingMutation()

  const handleSubmit = async (data: PaymentTrackingFormValues) => {
    try {
      const updatedPaymentTracking = await mutateAsync({
        id: entity.id,
        data: {
          ...data,
          amount: Math.round(parseStringToNumber(data.amount)),
          trackDayPeriod: data.trackDayPeriod!.value,
          from: data.from!.value,
        },
      })
      onSuccessSubmit && onSuccessSubmit(updatedPaymentTracking)
    } catch (e) {
      console.error(e)
      toast.error('Failed to update payment tracking')
    }
  }

  const initialValues = useMemo(
    () => ({
      amount: formatCurrency(entity.amount),
      trackDayPeriod:
        TRACKING_PERIOD_OPTIONS.find((option) => option.value === entity.trackDayPeriod) || null,
      firstPaymentDue: entity.firstPaymentDue,
      from: ACCOUNTS_OPTIONS.find((option) => option.value === entity.from) || null,
    }),
    [entity],
  )

  return (
    <PaymentTrackingForm
      onSubmit={handleSubmit}
      initialValues={initialValues}
      loading={isLoading}
    />
  )
}

export default EditPaymentTrackingForm
