import React from 'react';
import DatePicker from 'react-datepicker';

const CourtDetails = (props) => {

  const {
    message,
    dateFormat,
    handleChangeDate,
    plaintiffName,
    plaintiffAddress,
    handleCourtDetails,
    defendantName,
    defendantAddress,
    courtLocation,
    tribunalLocation,
    tribunalOrderDate,
    tribunalClaim,
    claimPlaintiffNumber,
    enforcementDate,
  } = props;





  return (
    <div>
      <div className='row'>
        <div className='col-12 col-md-6 col-lg-3'>
          <div className='form-group'>
            <label className='form__form-group-label'>
              Plaintiff Name
            </label>
            <input
              type='text'
              step='0.1'
              className='custom-input'
              name='plaintiffName'
              value={plaintiffName || ''}
              onChange={handleCourtDetails}
              placeholder='Plaintiff Name'
            />
            <span className='form__form-group-error'>
              {/*{message.message('Plaintiff Name', plaintiffName, 'required')}*/}
            </span>
          </div>
        </div>
        <div className='col-12 col-md-6'>
          <div className='form-group'>
            <label className='form__form-group-label'>
              Plaintiff Address
            </label>
            <input
              type='text'
              step='0.1'
              className='custom-input'
              name='plaintiffAddress'
              value={plaintiffAddress || ''}
              onChange={handleCourtDetails}
              placeholder='Plaintiff Address'
            />
            <span className='form__form-group-error'>
              {/*{message.message('Plaintiff Address', plaintiffAddress, 'required')}*/}
            </span>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-12 col-md-6 col-lg-3'>
          <div className='form-group'>
            <label className='form__form-group-label'>
              Defendant Name
            </label>
            <input
              type='text'
              step='0.1'
              className='custom-input'
              name='defendantName'
              value={defendantName || ''}
              onChange={handleCourtDetails}
              placeholder='Defendant Name'
            />
            <span className='form__form-group-error'>
              {/*{message.message('Defendant Name', defendantName, 'required')}*/}
            </span>
          </div>
        </div>
        <div className='col-12 col-md-6 '>
          <div className='form-group'>
            <label className='form__form-group-label'>
              Defendant Address
            </label>
            <input
              type='text'
              step='0.1'
              className='custom-input'
              name='defendantAddress'
              value={defendantAddress || ''}
              onChange={handleCourtDetails}
              placeholder='Defendant Address'
            />
            <span className='form__form-group-error'>
              {/*{message.message('Defendant Address', defendantAddress, 'required')}*/}
            </span>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-12 col-md-6 col-lg-3'>
          <div className='form-group'>
            <label className='form__form-group-label'>
              Tribunal Location
            </label>
            <input
              type='text'
              step='0.1'
              className='custom-input'
              name='tribunalLocation'
              value={tribunalLocation || ''}
              onChange={handleCourtDetails}
              placeholder='Tribunal Location'
            />
            <span className='form__form-group-error'>
              {/*{message.message('Tribunal Location', tribunalLocation, 'required|currency')}*/}
            </span>
          </div>
        </div>
        <div className='col-12 col-md-6 col-lg-3'>
          <div className='form-group'>
            <label className='form__form-group-label'>
              Tribunal Order Date
            </label>
            <div className='date-picker date-picker--interval'>
              <DatePicker
                selected={
                  tribunalOrderDate
                    ? dateFormat(tribunalOrderDate)
                    : ''
                }
                onChange={(e) =>
                  handleChangeDate(e, 'tribunalOrderDate')
                }
                minDate={new Date()}
                name='tribunalOrderDate'
                dateFormat='dd/MM/yy'
                dropDownMode='select'
                popperPlacement='center'
                placeholderText='Select Date'

              />
              <span className='form__form-group-error'>
                {/*{message.message('Tribunal Order Date', tribunalOrderDate, 'required')}*/}
              </span>
              <span className='date_icon'>
                <img src={'./image/calendar.svg'} alt='date' />
              </span>
            </div>
            <span className='form__form-group-error' />
          </div>
        </div>
        <div className='col-12 col-md-6 col-lg-3'>
          <div className='form-group'>
            <label className='form__form-group-label'>
              Tribunal Claim No.
            </label>
            <input
              type='text'
              step='0.1'
              className='custom-input'
              name='tribunalClaim'
              value={tribunalClaim || ''}
              onChange={handleCourtDetails}
              placeholder='Tribunal Claim No.'
            />
            <span className='form__form-group-error'>
              {/*{message.message('Tribunal Claim No.', tribunalClaim, 'required')}*/}
            </span>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-12 col-md-6 col-lg-3'>
          <div className='form-group'>
            <label className='form__form-group-label'>
              Claim/Plaintiff Number
            </label>
            <input
              type='text'
              step='0.1'
              className='custom-input'
              name='claimPlaintiffNumber'
              value={claimPlaintiffNumber || ''}
              onChange={handleCourtDetails}
              placeholder='Claim/Plaintiff Number'
            />
            <span className='form__form-group-error'>
              {/*{message.message('Claim/Plaintiff Number', claimPlaintiffNumber, 'required|currency')}*/}
            </span>
          </div>
        </div>
        <div className='col-12 col-md-6 col-lg-3'>
          <div className='form-group'>
            <label className='form__form-group-label'>
              Enforcement Date
            </label>
            <div className='date-picker date-picker--interval'>
              <DatePicker
                selected={
                  enforcementDate
                    ? dateFormat(enforcementDate)
                    : ''
                }
                onChange={(e) =>
                  handleChangeDate(e, 'enforcementDate')
                }
                minDate={new Date()}
                name='enforcementDate'
                dateFormat='dd/MM/yy'
                dropDownMode='select'
                popperPlacement='center'
                placeholderText='Select Date'

              />
              <span className='form__form-group-error'>
                {/*{message.message('Enforcement Date', enforcementDate, 'required')}*/}
              </span>
              <span className='date_icon'>
                <img src={'./image/calendar.svg'} alt='date' />
              </span>
            </div>
            <span className='form__form-group-error' />
          </div>
        </div>
        <div className='col-12 col-md-6 col-lg-3'>
          <div className='form-group'>
            <label className='form__form-group-label'>
              Court Location
            </label>
            <input
              type='text'
              step='0.1'
              className='custom-input'
              name='courtLocation'
              value={courtLocation || ''}
              onChange={handleCourtDetails}
              placeholder='Court Location'
            />
            <span className='form__form-group-error'>
              {/*{message.message('Bank account', courtLocation, 'required')}*/}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourtDetails;