import React from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { postInvoice, getInvoicePDF, editInvoiceByID } from '../../../../../../../redux/actions';
import { downloadImage } from '../../../../../../../shared/request/useDownloadFile';

import { Grid, Typography } from '@mui/material';
import AppButton from '../../../../../../../shared/components/AppButton/AppButton';

import { formButtons } from './styles';

const FormButtons = ({
  values,
  title,
  submitForm,
  setOpenPreviewModal,
  setOpenSaveModal,
  setOpenVoidModal,
  isValid,
  isEditing,
  invoiceID,
  errors,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const openPreviewModalHandler = () => {
    if (isValid) {
      setOpenPreviewModal(true);

      dispatch(postInvoice(values))
        .then((res) => {
          dispatch(getInvoicePDF(res?.id));
        })
        .catch(() => {
          toast.error('Something went wrong post!');
        });
    } else {
      submitForm();
      toast.error('Please fill all required fields!');
    }
  };

  const openSaveModalHandler = () => {
    if (isEditing && invoiceID) {
      if (isValid) {
        dispatch(editInvoiceByID(values, invoiceID))
          .then((res) => {
            downloadImage(res?.id);
            setOpenSaveModal(true);
          })
          .catch(() => {
            toast.error('Something went wrong post!');
          });
      } else {
        console.log(errors, 'errors');
        submitForm();
        toast.error('Please fill all required fields!');
      }
    } else {
      if (isValid) {
        dispatch(postInvoice(values))
          .then((res) => {
            downloadImage(res?.id);
            setOpenSaveModal(true);
          })
          .catch(() => {
            toast.error('Something went wrong post!');
          });
      } else {
        submitForm();
        toast.error('Please fill all required fields!');
      }
    }
  };

  const openVoidModalHandler = () => {
    setOpenVoidModal(true);
  };

  const chancelInvoiceHandler = () => {
    history.push('/contacts/invoice-list');
  };

  return (
    <Grid sx={formButtons.container}>
      {title && <Typography sx={formButtons.title}>Invoice</Typography>}

      <Grid />

      <Grid sx={formButtons.buttonsContainer}>
        <AppButton content="Cancel" buttonStyle="secondary" onClick={chancelInvoiceHandler} />
        <AppButton content="Save" buttonStyle="success" onClick={openSaveModalHandler} />
        <AppButton content="Void" buttonStyle="error" onClick={openVoidModalHandler} />

        <AppButton
          content="Review & Send"
          buttonStyle="primary"
          onClick={openPreviewModalHandler}
        />
      </Grid>
    </Grid>
  );
};

export default FormButtons;
