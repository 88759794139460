import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RecoveryForm from './components/index';
import { RTLProps } from '../../../shared/prop-types/ReducerProps';
import { submitURF } from './service';
import Services from '../../../shared/Services';
import { useHistory } from 'react-router-dom';

const AddRecoveryForm = ({ t, rtl }) => {
  const services = new Services();
  const history = useHistory();

  const submitFormHandler = async (data) => {
    try {
      const result = await submitURF(data);
      if (result?.status === 201) {
        services.successToast('Form submitted successfully');
        history.push('/recovery');
      } else {
        throw new Error('Error while submiting');
      }
    } catch (error) {
      services.errorToast(error.message);
    }
  };

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Recovery Tenant Form</h3>
        </Col>
      </Row>
      <Row>
        <RecoveryForm onSubmit={submitFormHandler} dir={rtl.direction} />
      </Row>
    </Container>
  );
};

RecoveryForm.propTypes = {
  t: PropTypes.func.isRequired,
  rtl: RTLProps.isRequired,
};

export default connect((state) => ({
  rtl: state.rtl,
}))(withTranslation('common')(AddRecoveryForm));
