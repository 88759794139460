import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import { FC, useEffect } from 'react'
import { useParams } from 'react-router'
import styles from './debtor-legal-page.module.scss'
import { useGetDebtorLegalById } from '../../hooks/get-debtor-legal-by-id.hook'
import { GET_LEGAL_BY_ID_PARAMS } from '../../const/debtor-legal.const'
import DebtorServiceSection from '../../components/sections/debtor-service-section/debtor-service-section.component'
import { useQueryClient } from '@tanstack/react-query'
import { getWipLegalListQueryKey } from '../../utils/legal-query-key-factory.utils'
import { DebtorLegal, DebtorLegalPageProps } from '../../types/debtor-legal.type'
import PlaintiffSection from '../../components/sections/plaintiff-section/plaintiff-section.component'
import DefendantSection from '../../components/sections/defendant-section/defendant-section.component'
import TribunalSection from '../../components/sections/tribunal-section/tribunal-section.component'
import ClaimNumberSection from '../../components/sections/claim-number-section/claim-number-section.component'
import JudgmentDateSection from '../../components/sections/debtor-legal-judgment-date-section/debtor-legal-judgment-date-section.component'
import DebtorLegalNoteSection from '../../components/sections/debtor-legal-note-section/debtor-legal-note-section.component'

const DebtorLegalPage: FC<DebtorLegalPageProps> = ({ wip }) => {
  const queryClient = useQueryClient()
  const { debtorId, debtorLegalId } = useParams<{ debtorId: string; debtorLegalId: string }>()
  const debtor = wip.debtors.find((debtor) => debtor.id === Number(debtorId))

  const { data } = useGetDebtorLegalById(Number(debtorLegalId), GET_LEGAL_BY_ID_PARAMS)

  useEffect(() => {
    if (!data) return
    queryClient.setQueryData<DebtorLegal[]>(getWipLegalListQueryKey(data.wipId), (prevData) => {
      if (!prevData) return undefined
      return prevData.map((debtorLegal) => {
        if (debtorLegal.id === data.id) {
          return data
        }
        return debtorLegal
      })
    })
  }, [data])

  if (!debtor) {
    return <Header>Debtor not found</Header>
  }

  return (
    <>
      {data && debtor ? (
        <div className={styles.wrapper}>
          <div>
            <Header>
              Debtor:
              <span
                className={styles.highlighted}
              >{` ${debtor.name_first} ${debtor.name_last}`}</span>
            </Header>
          </div>
          <div className={styles.sectionsWrapper}>
            <div className={styles.leftSide}>
              <DebtorServiceSection debtorLegal={data} />
              <DebtorLegalNoteSection debtorLegal={data} />
            </div>
            <div className={styles.rightSide}>
              <PlaintiffSection debtorLegal={data} />
              <DefendantSection debtorLegal={data} />
              <TribunalSection debtorLegal={data} />
              <div className={styles.sectionsWrapper}>
                <ClaimNumberSection debtorLegal={data} />
                <JudgmentDateSection debtorLegal={data} />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default DebtorLegalPage
