import Section from '@components/section/section.component'
import { Header, SubHeader } from '@containers/Recovery/Wip/components/UI/text/text.component'
import { FC } from 'react'
import { ClaimAmountSectionProps } from '../../../types/sections-props.type'
import SubSection from '@containers/Recovery/Wip/components/UI/sub-section/sub-section.component'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { formatCurrency } from '@containers/Recovery/Wip/utils/format-currency'

const ClaimAmountSection: FC<ClaimAmountSectionProps> = ({ claimAmount }) => {
  return (
    <Section>
      <Header mb={4}>Total Claim Amount</Header>
      <SubSection mb={4} p={2}>
        <SubHeader>Total:{` ${formatCurrency(claimAmount)}`}</SubHeader>
      </SubSection>
      <SubmitButton type="link" url="financial">
        Edit
      </SubmitButton>
    </Section>
  )
}

export default ClaimAmountSection
