import Modal from '@components/modal'
import { BaseModalProps } from '@src/types/component-types'
import { FC } from 'react'
import SendReportForm from '../../forms/send-report-form/send-report-form.component'
import { Wip } from '@containers/Recovery/Wip/types/wip.type'
import { transformEmailTemplateToOption } from '@containers/Communication/utils/communcation-transfrom.utils'
import { useGetEmailTemplates } from '@containers/Communication/hooks/get-email-templates.hook'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import { toast } from 'react-toastify'

interface SendReportModalProps extends BaseModalProps {
  wip: Wip
}

const SendReportModal: FC<SendReportModalProps> = ({ wip, ...modalProps }) => {
  const { data } = useGetEmailTemplates({ limit: 999 })
  const templateOptions = data?.data?.map(transformEmailTemplateToOption) || []

  const handleSuceessfulSubmit = () => {
    toast.success('Report sent successfully')
    modalProps.onClose()
  }

  return (
    <Modal {...modalProps} withBtns={false}>
      <Header mb={4}>Send Report</Header>
      <SendReportForm
        wip={wip}
        templateOptions={templateOptions}
        onSuccessfulSubmit={handleSuceessfulSubmit}
        onCancelClick={modalProps.onClose}
      />
    </Modal>
  )
}

export default SendReportModal
