import {
  DebtorOtherFormValues,
  EditDebtorOtherFormProps,
} from '@containers/Recovery/Wip/_modules/Debtors/types/debtor-associate-detail.type'
import { FC } from 'react'
import { toast } from 'react-toastify'
import DebtorOtherForm from './debtor-other-form.component'
import { useUpdateDebtorOtherMutation } from '@containers/Recovery/Wip/_modules/Debtors/hooks/mutations/crud-debtor-other.hook'

const EditDebtorOtherForm: FC<EditDebtorOtherFormProps> = ({
  debtorOtherId,
  onSuccessSubmit,
  onCancelClick,
  initialValues,
}) => {
  const { mutateAsync, isLoading } = useUpdateDebtorOtherMutation()

  const handleSubmit = async (data: DebtorOtherFormValues) => {
    try {
      const updatedDebtorOther = await mutateAsync({
        id: debtorOtherId,
        data: {
          ...data,
          call_result: data.call_result.value,
        },
      })
      onSuccessSubmit(updatedDebtorOther)
      toast.success('Updated')
    } catch (e) {
      toast.error('Something went wrong')
      console.log(e)
    }
  }

  return (
    <DebtorOtherForm
      onSubmit={handleSubmit}
      onCancelClick={onCancelClick}
      loading={isLoading}
      initialValues={initialValues}
    />
  )
}

export default EditDebtorOtherForm
