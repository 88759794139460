import axios from "axios";
import { GRAPH_API_ENDPOINT } from "../const/graph-api.const";
import { BaseGraphApiParams, ForwardMessageBody, GetMessageInsideSharedFolderData, GraphApiMessage, GraphApiResponse, MailFolderName, ReplyMessageBody, SendMessageBody, UpdateMessageBody } from "../types/graph-api-mail.type";
import { PaginationResponse } from "@src/types/service.type";

export class GraphApiMailService {

  async getMessagesInsideFolderByName(accessToken: string, folderName: MailFolderName, requestParams: BaseGraphApiParams = {}): Promise<PaginationResponse<GraphApiMessage[]>> {
    const defaultPaginationParams = { $top: 5, $skip: 0, $count: true }
    const params = {
      ...defaultPaginationParams,
      ...requestParams
    }
    const headers = this.getAuthorizationHeaders(accessToken);
    const res = await axios.get<GraphApiResponse<GraphApiMessage[]>>(`${GRAPH_API_ENDPOINT.MAIL_FOLDERS}/${folderName}/messages`, {
      headers, params: {
        ...defaultPaginationParams,
        ...params
      }
    })

    const total = res.data['@odata.count'];

    const transformedResponse = {
      count: res.data.value.length,
      page: params.$skip / params.$top + 1,
      pageCount: total / params.$top,
      total,
      data: res.data.value
    };

    return transformedResponse;
  }

  async getMessagesInsideFolder(accessToken: string, folderId: string, requestParams: BaseGraphApiParams = {}): Promise<PaginationResponse<GraphApiMessage[]>> {
    const defaultPaginationParams = { $top: 5, $skip: 0, $count: true }
    const params = {
      ...defaultPaginationParams,
      ...requestParams
    }
    const headers = this.getAuthorizationHeaders(accessToken);
    const res = await axios.get<GraphApiResponse<GraphApiMessage[]>>(`${GRAPH_API_ENDPOINT.MAIL_FOLDERS}/${folderId}/messages`, {
      headers, params: {
        ...defaultPaginationParams,
        ...params
      }
    })

    const total = res.data['@odata.count'];

    const transformedResponse = {
      count: res.data.value.length,
      page: params.$skip / params.$top + 1,
      pageCount: total / params.$top,
      total,
      data: res.data.value
    };

    return transformedResponse;
  }

  async getMessageInsideSharedFolder({ accessToken, userId, messageId, folderName = 'Inbox' }: GetMessageInsideSharedFolderData, params: BaseGraphApiParams = {}) {
    const headers = this.getAuthorizationHeaders(accessToken);
    const res = await axios.get<GraphApiMessage>(`${GRAPH_API_ENDPOINT.USERS}/${userId}/mailfolders('${folderName}')/messages/${messageId}`, { headers, params })

    return res.data;
  }

  async getMessageById(accessToken: string, messageId: string, params: BaseGraphApiParams = {}) {
    const headers = this.getAuthorizationHeaders(accessToken);
    const res = await axios.get<GraphApiMessage>(`${GRAPH_API_ENDPOINT.MESSAGES}/${messageId}`, { headers, params })
    return res.data;
  }

  async updateMessage(accessToken: string, messageId: string, body: Partial<UpdateMessageBody>) {
    const headers = this.getAuthorizationHeaders(accessToken);
    const res = await axios.patch<GraphApiMessage>(`${GRAPH_API_ENDPOINT.MESSAGES}/${messageId}`, body, { headers })
    return res.data;
  }

  async sendMessage(accessToken: string, body: SendMessageBody) {
    const headers = this.getAuthorizationHeaders(accessToken);
    const res = await axios.post<void>(`${GRAPH_API_ENDPOINT.SEND_MAIL}`, body, { headers })
    return res.data;
  }

  // email actions

  async replyMessage(accessToken: string, messageId: string, body: ReplyMessageBody) {
    const headers = this.getAuthorizationHeaders(accessToken);
    const res = await axios.post<void>(`${GRAPH_API_ENDPOINT.MESSAGES}/${messageId}/reply`, body, { headers })
    return res.data;
  }

  async forwardMessage(accessToken: string, messageId: string, body: ForwardMessageBody) {
    const headers = this.getAuthorizationHeaders(accessToken);
    const res = await axios.post<void>(`${GRAPH_API_ENDPOINT.MESSAGES}/${messageId}/forward`, body, { headers })
    return res.data;
  }

  async moveMessageToFolder(accessToken: string, messageId: string, folderId: string) {
    const headers = this.getAuthorizationHeaders(accessToken);
    const res = await axios.post<GraphApiMessage>(`${GRAPH_API_ENDPOINT.MESSAGES}/${messageId}/move`, { destinationId: folderId }, { headers })
    return res.data;
  }

  private getAuthorizationHeaders(accessToken: string) {
    return ({ Authorization: `Bearer ${accessToken}` })
  }
}