import Section from '@components/section/section.component'
import SubSection from '@containers/Recovery/Wip/components/UI/sub-section/sub-section.component'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import styles from './tribunal-section.module.scss'
import { useModal } from '@src/hooks/useModal'
import { FC } from 'react'
import { TribunalSectionProps } from '../../../types/debtor-legal-tribunal.type'
import DebtorLegalTribunalList from '../../lists/debtor-tribunal-list/debtor-tribunal-list.component'
import CreateDebtorLegalTribunalModal from '../../modals/debtor-legal-tribunal-modal/create-debtor-legal-tribunal-modal.component'

const TribunalSection: FC<TribunalSectionProps> = ({ debtorLegal }) => {
  const [isOpen, handleOpen, handleClose] = useModal(false)
  return (
    <Section>
      <SubSection className={styles.headerWrapper}>
        <Header mb={4}>Tribunal/s</Header>
        <SubmitButton onClick={handleOpen}>Add Tribunal</SubmitButton>
      </SubSection>

      <DebtorLegalTribunalList entities={debtorLegal.tribunals} />

      <CreateDebtorLegalTribunalModal
        isOpen={isOpen}
        onClose={handleClose}
        debtorLegalId={debtorLegal.id}
      />
    </Section>
  )
}

export default TribunalSection
