import { SelectOption } from "@components/pagination-select/pagination-select.type"
import { startOfToday, sub } from "date-fns"

export const transformDropdownDateOptionToDate = (option: SelectOption): Date | null => {
  switch (option.value) {
    case 'today':
      return startOfToday()
    case 'week':
      return sub(new Date(), { days: 7 })
    case 'month':
      return sub(new Date(), { months: 1 })
    case 'year':
      return sub(new Date(), { years: 1 })
    default:
      return null
  }
}
