export const WIP_DROPDOWN_OPTIONS = [
  { label: 'Today', value: 'today' },
  { label: 'Last 7 days', value: 'week' },
  { label: 'Last month', value: 'month' },
  { label: 'For all time', value: 'all' },
]

export const FETCH_WIP_PARAMS = {
  join: [
    'company',
    'company.contacts',
    'cover',
    'debtors',
    'debtors_added_to_pay_tracking',
    'debtors.contact',
    'property',
    'pay_trackings',
    'courtInterestCalculator',
    'generalInterestCalculator',
    'attachments'
  ]
}