import FormInput from '@components/inputs/form-input/form-input.component'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import styles from './debtor-details-names-form.module.scss'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useUpdateDebtorMutation } from '@containers/Recovery/Wip/_modules/Debtors/hooks/mutations/update-debtor.hook'
import { toast } from 'react-toastify'
import { transformEmptyStringToNull } from '../../../../../utils/transform-debtor-to-values.utils'
import { useQueryClient } from '@tanstack/react-query'
import { Debtor } from '@containers/Recovery/Wip/_modules/Debtors/types/debtor.type'
import { getDebtorQueryKey } from '@containers/Recovery/Wip/_modules/Debtors/utils/debtor-query-key-factory.utils'
import { DebtorDetailsNamesFormValues, DebtorDetailsNamesFormProps } from '@containers/Recovery/Wip/_modules/Debtors/types/debtor-form.type'

const DebtorDetailsNamesForm: FC<DebtorDetailsNamesFormProps> = ({ initialValues, debtorId }) => {
  const queryClient = useQueryClient()
  const { mutateAsync, isLoading } = useUpdateDebtorMutation()
  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm<DebtorDetailsNamesFormValues>({
    defaultValues: initialValues,
  })
  const [isAnotherNameVisible, setIsAnotherNameVisible] = useState(initialValues.second_name_first !== null)

  const handleClick = () => {
    setIsAnotherNameVisible((prev) => !prev)
  }

  const onSubmit = async (data: DebtorDetailsNamesFormValues) => {
    try {
      const updatedDebtor = await mutateAsync({
        id: debtorId,
        debtor: transformEmptyStringToNull(data),
        params: { fields: Object.keys(data).join(',') },
      })
      queryClient.setQueryData<Debtor>(getDebtorQueryKey(debtorId), (prevData) => ({
        ...(prevData as unknown as Debtor),
        ...updatedDebtor,
      }))
      toast.success('Debtor updated successfully')
    } catch (e) {
      console.error(e)
      toast.error('Failed to update debtor')
    }
  }

  useEffect(() => {
    reset(initialValues)
    setIsAnotherNameVisible(initialValues.second_name_first !== null)
  }, [initialValues])

  return (
    <form className={styles.formWrapper} onSubmit={handleSubmit(onSubmit)}>
      <Header>Names</Header>
      <div className={styles.row}>
        <FormInput
          label="First Name"
          disabled
          {...register('name_first')}
          error={errors.name_first?.message}
        />
        <FormInput
          label="Middle Name"
          disabled
          {...register('name_middle')}
          error={errors.name_middle?.message}
        />
        <FormInput
          label="Last Name"
          disabled
          {...register('name_last')}
          error={errors.name_last?.message}
        />
      </div>
      {isAnotherNameVisible ? (
        <div className={styles.row}>
          <FormInput
            label="Second First Name"
            {...register('second_name_first')}
            error={errors.second_name_first?.message}
          />
          <FormInput
            label="Second Middle Name"
            {...register('second_name_middle')}
            error={errors.second_name_middle?.message}
          />
          <FormInput
            label="Second Last Name"
            {...register('second_name_last')}
            error={errors.second_name_last?.message}
          />
        </div>
      ) : null}
      <div className={styles.actionWrapper}>
        <SubmitButton onClick={handleClick}>
          {isAnotherNameVisible ? 'Remove' : 'Add'} Another Name
        </SubmitButton>
        {isAnotherNameVisible ? (
          <SubmitButton buttonType="submit" loading={isLoading}>
            Save
          </SubmitButton>
        ) : null}
      </div>
    </form>
  )
}

export default DebtorDetailsNamesForm
