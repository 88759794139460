import { postInvoiceActionEnum } from '../actionTypes';
import { postInvoiceService } from '../service';

export const postInvoice = (data) => async (dispatch) => {
  dispatch({ type: postInvoiceActionEnum.IS_LOADING_POST_INVOICE_FORM });
  try {
    const res = await postInvoiceService(data);

    dispatch({
      type: postInvoiceActionEnum.POST_INVOICE_SUCCESS,
      payload: res,
    });

    return res;
  } catch (error) {
    dispatch({
      type: postInvoiceActionEnum.POST_INVOICE_ERROR,
      payload: error,
    });

    throw error;
  }
};
