import { useState } from 'react'
import { Grid } from '@mui/material'
import { Editor } from '@tinymce/tinymce-react'
import { TailSpin } from 'react-loader-spinner'
import { editorLoadingStyles } from './styles'

import { defaultInitOptions } from './defaultInitOptions'

interface TextEditorProps {
  initialValue?: string
  value: string
  onEditorChange: (newValue: string, editor: any) => void
  initOptions?: any
}
export const TextEditor = ({
  value,
  onEditorChange = () => null,
  initOptions = null,
}: TextEditorProps) => {
  const [loading, setLoading] = useState(true)

  const init = initOptions ? initOptions : defaultInitOptions
  const apiKey = 'qwxo2mvltn5dflrlsa3iri4nw9z0uum2nl62s3f5rd4nbf0f'
  return (
    <>
      {loading && (
        <Grid sx={editorLoadingStyles}>
          <TailSpin height="70" width="70" color="#f69066" ariaLabel="loading" />
        </Grid>
      )}
      <Editor
        onInit={() => {
          setLoading(false)
        }}
        apiKey={apiKey}
        value={value}
        onEditorChange={(newValue, editor) => {
          onEditorChange(newValue, editor)
        }}
        init={init}
      />
    </>
  )
}
