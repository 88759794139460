import { toast } from 'react-toastify'
import { FC } from 'react'
import {
  CreateDebtorLegalClaimNumberFormProps,
  DebtorClaimNumberFormValues,
} from '../../../types/debtor-claim-number.type'
import { useCreateDebtorLegalClaimNumberMutation } from '../../../hooks/mutations/crud-debtor-legal-claim-number.hook'
import DebtorLegalClaimNumberForm from './debtor-claim-number-form.component'

const CreateDebtorLegalClaimNumberForm: FC<CreateDebtorLegalClaimNumberFormProps> = ({
  debtorLegalId,
  onSuccessSubmit,
}) => {
  const { mutateAsync, isLoading } = useCreateDebtorLegalClaimNumberMutation()
  const handleSuccessSubmit = async (data: DebtorClaimNumberFormValues) => {
    try {
      const createdData = await mutateAsync({
        data: {
          debtorLegalId,
          ...data,
        },
      })
      onSuccessSubmit(createdData)
    } catch (error) {
      toast.error('Failed to create claim number')
      console.error(error)
    }
  }

  return (
    <DebtorLegalClaimNumberForm
      onSuccessSubmit={handleSuccessSubmit}
      loading={isLoading}
      submitButtonTitle="Create Claim Number"
    />
  )
}

export default CreateDebtorLegalClaimNumberForm
