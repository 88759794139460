import React, { Component } from "react";
import { Col, Container, Row, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
class listRecoveryTab extends Component {
  render() {
    return (
      <Container style={{ height: "100vh" }}>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Recoveries Form</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody className="settings-mangement">
                <div className="card__title">
                  <h5 className="bold-text">Recovery form tools</h5>
                </div>
                <Row>
                  <Col xl={3} lg={4} md={6} sm={12}>
                    <Link
                      to="/recoveries/forms/strata-body"
                      className="mg_tools_box"
                    >
                      <div className='mg_icon'/>
                      <p className="mg_title">Strata Body Corporate Recovery</p>
                    </Link>
                  </Col>

                  <Col xl={3} lg={4} md={6} sm={12}>
                    <Link
                      to="/recoveries/forms/protect_&_collect"
                      className="mg_tools_box"
                    >
                       <div className='mg_icon'/>
                      <p className="mg_title">Protect & Collect Recovery Form</p>
                    </Link>
                  </Col>

                  <Col xl={3} lg={4} md={6} sm={12}>
                    <Link
                      to="/recoveries/forms/lodge_an_eviction"
                      className="mg_tools_box"
                    >
                      <div className='mg_icon'/>
                      <p className="mg_title">Lodge an Eviction</p>
                    </Link>
                  </Col>
                  <Col xl={3} lg={4} md={6} sm={12}>
                    <Link
                      to="/recoveries/forms/no_current_subscription"
                      className="mg_tools_box"
                    >
                      <div className='mg_icon'/>
                      <p className="mg_title">No Current Subscription (Uncovered)</p>
                    </Link>
                  </Col>
                </Row>
                <Row>
                  <Col xl={3} lg={4} md={6} sm={12}>
                    <Link
                      to="/recoveries/forms/commercial"
                      className="mg_tools_box"
                    >
                      <div className='mg_icon'/>
                      <p className="mg_title">Commercial (Uncovered)</p>
                    </Link>
                  </Col>

                  <Col xl={3} lg={4} md={6} sm={12}>
                    <Link
                      to="/recoveries/forms/storage_shed"
                      className="mg_tools_box"
                    >
                      <div className='mg_icon'/>
                      <p className="mg_title">Storage Shed (Uncovered)</p>
                    </Link>
                  </Col>

                  <Col xl={3} lg={4} md={6} sm={12}>
                    <Link
                      to="/recoveries/forms/principal_protection"
                      className="mg_tools_box"
                    >
                      <div className='mg_icon'/>
                      <p className="mg_title">Principal Protection</p>
                    </Link>
                  </Col>
                  <Col xl={3} lg={4} md={6} sm={12}>
                    <Link
                      to="/recoveries/forms/arrears_contact"
                      className="mg_tools_box"
                    >
                      <div className='mg_icon'/>
                      <p className="mg_title">Arrears Contact Form</p>
                    </Link>
                  </Col>
                </Row>
                <Row>
                <Col xl={3} lg={4} md={6} sm={12}>
                    <Link
                      to="/recoveries/forms/wip-template"
                      className="mg_tools_box"
                    >
                      <div className='mg_icon'/>
                      <p className="mg_title">Wip Templates Form</p>
                    </Link>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default listRecoveryTab;
