import { CreateGraphApiRule } from "../types/graph-api-mail.type";

const AUTOMATICALLY_MOVE_EMAIL_TO_BMS_FOLDER_HASHTAG = "#BMS";

export const AUTOMATICALLY_MOVE_EMAIL_TO_BMS_FOLDER_RULE_NAME = "Move email to BMS folder";

export const AUTOMATICALLY_MOVE_EMAIL_TO_BMS_FOLDER_RULE: Omit<CreateGraphApiRule, 'actions' | 'sequence'> = {
  displayName: AUTOMATICALLY_MOVE_EMAIL_TO_BMS_FOLDER_RULE_NAME,
  conditions: {
    bodyOrSubjectContains: [AUTOMATICALLY_MOVE_EMAIL_TO_BMS_FOLDER_HASHTAG]
  },
  isEnabled: true,
}