import React from 'react';

export const ClaimAmountDetails = ({ claimAmountDetails, totalClaim }) => {
  return (
    <>
      <div className='card__title'>
        <h5 className='bold-text'>Claim Amount Details</h5>
      </div>
      {claimAmountDetails?.rent && (
        <div className='form__form-group'>
          <span className='form__form-group-label'>Rent</span>
          <div className='col-12 col-md-6 col-lg-3'>
            <span className='form__form-group-label'>Date from</span>
            <div className='form-group'>
              <input
                name='date_from'
                type='text'
                defaultValue={claimAmountDetails?.rent?.date_from}
                disabled
              />
            </div>
          </div>
          <div className='col-12 col-md-6 col-lg-3'>
            <div className='form-group'>
              <span className='form__form-group-label'>Date to</span>
              <input
                name='date_to'
                type='text'
                defaultValue={claimAmountDetails?.rent?.date_to}
                disabled
              />
            </div>
          </div>
          <div className='col-12 col-md-6 col-lg-3'>
            <div className='form-group'>
              <span className='form__form-group-label'>Total</span>
              <input
                name='amount'
                type='text'
                defaultValue={claimAmountDetails?.rent?.amount}
                disabled
              />
            </div>
          </div>
        </div>
      )}
      {claimAmountDetails?.claims &&
        claimAmountDetails.claims.map((item, index) => (
          <React.Fragment key={index}>
            <div className='form__form-group'>
              <span className='form__form-group-label'>Claim {index + 1}</span>
              <div className='col-12 col-md-6 col-lg-3'>
                <span className='form__form-group-label'>Name</span>
                <div className='form-group'>
                  <input
                    name='name'
                    type='text'
                    value={item?.description}
                    disabled
                  />
                </div>
              </div>
              <div className='col-12 col-md-6 col-lg-3 offset-3'>
                <div className='form-group'>
                  <span className='form__form-group-label'>Total</span>
                  <input
                    name='amount'
                    type='text'
                    disabled
                    value={item?.current_amount}
                  />
                </div>
              </div>
            </div>
          </React.Fragment>
        ))}
      <div className='form__form-group'>
        <span className='form__form-group-label'>Total</span>
        <div className='col-12 col-md-6 col-lg-3'>
          <div className='form-group'></div>
        </div>
        <div className='col-12 col-md-6 col-lg-3'>
          <div className='form-group'></div>
        </div>
        <div className='col-12 col-md-6 col-lg-3'>
          <div className='form-group'>
            <span className='form__form-group-label'>Total</span>
            <input
              name='total'
              type='text'
              defaultValue={totalClaim}
              disabled
            />
          </div>
        </div>
      </div>
    </>
  );
};
