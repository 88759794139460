import { PaginationResponse } from "@src/types/service.type";
import axios from "axios";
import { WipCover } from "../types/wip.type";

export class WipCoverService {

  async getWipCovers() {
    const response = await axios.get<PaginationResponse<WipCover[]>>(`${process.env.REACT_APP_API_URL}/wip-covers`)
    return response.data;
  }

}

