import { SPEED_DIAL_OPTIONS } from '@containers/Communication/const/speed-dial.const'
import SpeedDial from './speed-dial.component'
import {
  SpeedDialOption,
  SpeedDialSelectOption,
  WipTaskSpeedDialProps,
} from '@containers/Communication/types/speed-dial.type'
import { useModal } from '@src/hooks/useModal'
import { FC } from 'react'
import WipTaskSendSmsModal from '../modals/send-sms-modal/wip-task-send-sms-modal.component'
import WipTaskSendEmailModal from '../modals/send-email-modal/wip-task-send-email-modal.component'
import WipTaskMakeCallModal from '../modals/make-call-modal/wip-task-make-call-modal.component'
import PaymentTrackingModal from '@containers/Recovery/Wip/_modules/Financial/Tracking/components/modals/payment-tracking-modal/payment-tracking-modal.component'

const WipTaskSpeedDial: FC<WipTaskSpeedDialProps> = ({ wip, task }) => {
  const [isSendSmsModalOpen, openSendSmsModal, closeSendSmsModal] = useModal(false)
  const [isSendEmailModalOpen, openSendEmailModal, closeSendEmailModal] = useModal(false)
  const [isMakeCallModalOpen, openMakeCallModal, closeMadeCallModal] = useModal(false)
  const [isPaymentTrackingModalOpen, openPaymentTrackingModal, closePaymentTrackingModal] =
    useModal(false)

  const handleSelect = (option: SpeedDialOption) => {
    if (option.value === SpeedDialSelectOption.MakeCall) openMakeCallModal()
    if (option.value === SpeedDialSelectOption.SendEmail) openSendEmailModal()
    if (option.value === SpeedDialSelectOption.SendSms) openSendSmsModal()
    if (option.value === SpeedDialSelectOption.PaymentTracking) openPaymentTrackingModal()
  }

  return (
    <>
      <SpeedDial verticalDirection="top" options={SPEED_DIAL_OPTIONS} onSelect={handleSelect} />
      <WipTaskSendSmsModal
        wip={wip}
        task={task}
        isOpen={isSendSmsModalOpen}
        onClose={closeSendSmsModal}
      />
      <WipTaskSendEmailModal
        wip={wip}
        task={task}
        onClose={closeSendEmailModal}
        isOpen={isSendEmailModalOpen}
      />
      <WipTaskMakeCallModal
        wip={wip}
        task={task}
        onClose={closeMadeCallModal}
        isOpen={isMakeCallModalOpen}
      />
      <PaymentTrackingModal
        wip={wip}
        isOpen={isPaymentTrackingModalOpen}
        onClose={closePaymentTrackingModal}
      />
    </>
  )
}

export default WipTaskSpeedDial
