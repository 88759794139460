import { FC, useState } from 'react'
import { Client } from '../../types/client.type'
import { useGetClientWips } from '../../hooks/get-client-wips.hook'
import Section from '@components/section/section.component'
import ClientWipsTable from './components/tables/client-wips-table.component'
import { Header, SubHeader } from '@containers/Recovery/Wip/components/UI/text/text.component'

interface ClientWipsPageProps {
  client: Client
}

const ClientWipsPage: FC<ClientWipsPageProps> = ({ client }) => {
  const [page, setPage] = useState(1)
  const { data, isLoading, isRefetching } = useGetClientWips(client.id, {
    page,
    join: ['debtors', 'property', 'cover'],
    sort: 'id,DESC'
  })

  const wips = data?.data || []
  const total = data?.total
  const loading = isLoading || isRefetching
  const notFound = !loading && wips.length === 0

  return (
    <Section>
      <Header>Work In Progress File</Header>
      <SubHeader mb={4}>
        Total Number of WIP&apos;s: {total === undefined ? '...loading' : total}
      </SubHeader>
      <ClientWipsTable
        wips={wips}
        total={total}
        page={page}
        loading={loading}
        notFound={notFound}
        onPageChange={setPage}
      />
    </Section>
  )
}

export default ClientWipsPage
