import { getPaymentDetailsActionEnum } from '../actionTypes';

export const getPaymentDetailsReducer = function (
  state = { data: [], loading: false, error: '' },
  action,
) {
  switch (action.type) {
    case getPaymentDetailsActionEnum.GET_PAYMENT_DETAILS_SUCCESS:
      return { loading: false, error: '', data: action.payload };
    case getPaymentDetailsActionEnum.IS_LOADING_GET_PAYMENT_DETAILS:
      return { loading: true, error: '', data: [] };
    case getPaymentDetailsActionEnum.GET_PAYMENT_DETAILS_ERROR:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
