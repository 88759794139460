import { FC } from 'react'
import { DebtorLegal, DebtorLegalDefendant } from '../../../types/debtor-legal.type'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import { getDebtorLegalByIdQueryKey } from '../../../utils/legal-query-key-factory.utils'
import Modal from '@components/modal'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import styles from './debtor-defendant-modal.module.scss'
import CreateDebtorLegalDefendantForm from '../../forms/debtor-legal-defendant-form/create-debtor-legal-defendant-form.component'
import { CreateDebtorLegalDefendantModalProps } from '../../../types/debtor-legal-defendant.type'
import ModalHeader from '@components/modal-header.component'

const CreateDebtorLegalDefendantModal: FC<CreateDebtorLegalDefendantModalProps> = ({
  debtorLegalId,
  ...modalProps
}) => {
  const queryClient = useQueryClient()
  const handleSuccessfulSubmit = (debtorLegalDefendant: DebtorLegalDefendant) => {
    queryClient.setQueryData<DebtorLegal>(
      getDebtorLegalByIdQueryKey(debtorLegalDefendant.debtorLegalId),
      (prevData) =>
        prevData
          ? {
              ...prevData,
              defendants: [...prevData.defendants, debtorLegalDefendant],
            }
          : undefined,
    )
    toast.success('Defendant created successfully')
    modalProps.onClose()
  }

  return (
    <Modal className={styles.modalWrapper} {...modalProps} withBtns={false}>
      <ModalHeader onCloseClick={modalProps.onClose} />
      <Header mb={4}>Create Defendant Details</Header>
      <CreateDebtorLegalDefendantForm
        onSuccessSubmit={handleSuccessfulSubmit}
        debtorLegalId={debtorLegalId}
      />
    </Modal>
  )
}

export default CreateDebtorLegalDefendantModal
