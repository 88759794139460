import Section from '@components/section/section.component'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import {
  Landlord,
  LandlordAddress,
  Property,
} from '@containers/Sales/Properties/types/property.type'
import { FC } from 'react'
import CreateLandlordAddressForm from '../../form/landlord-address-form/create-landlord-address-form.component'
import { toast } from 'react-toastify'
import { getPropertyByIdKey } from '../../../utils/client-property.utils'
import { GET_CLIENT_PROPERTIES_PARAMS } from '@containers/Sales/Client/const/client.const'
import EditLandlordAddressForm from '../../form/landlord-address-form/edit-landlord-address-form.component'
import { useQueryClient } from '@tanstack/react-query'

interface LandlordAddressSectionProps {
  landlord: Landlord
}

const LandlordAddressSection: FC<LandlordAddressSectionProps> = ({ landlord }) => {
  const queryClient = useQueryClient()
  const handleSuccessSubmit = (data: LandlordAddress) => {
    queryClient.setQueryData<Property>(
      getPropertyByIdKey(landlord.propertyId, GET_CLIENT_PROPERTIES_PARAMS),
      (prevData) =>
        prevData
          ? {
              ...prevData,
              landlord: prevData.landlord
                ? {
                    ...prevData.landlord,
                    address: {
                      ...prevData.landlord?.address,
                      ...data,
                    },
                  }
                : null,
            }
          : undefined,
    )

    if (landlord.address) {
      toast.success('Landlord address updated')
    } else {
      toast.success('Landlord address created')
    }
  }

  return (
    <Section>
      <Header mb={4}>Owners Address</Header>
      {landlord.address ? (
        <EditLandlordAddressForm entity={landlord.address} onSuccessSubmit={handleSuccessSubmit} />
      ) : (
        <CreateLandlordAddressForm landlordId={landlord.id} onSuccessSubmit={handleSuccessSubmit} />
      )}
    </Section>
  )
}

export default LandlordAddressSection
