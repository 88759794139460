import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { PropertyService } from '@containers/Sales/Properties/services/property.service'
import { Property } from '@containers/Sales/Properties/types/property.type'
import { useModal } from '@src/hooks/useModal'
import { FC } from 'react'
import { toast } from 'react-toastify'
import MergePropertiesModal from '../../components/modals/merge-properties-modal/merge-properties-modal.component'

interface ClientPropertiesPageActionsProps {
  properties: Property[]
  onDetach?: () => void
  onMerge?: () => void
}

const ClientPropertiesPageActions: FC<ClientPropertiesPageActionsProps> = ({
  properties,
  onDetach,
  onMerge,
}) => {
  const [isOpen, handleOpen, handleClose] = useModal(false)
  const handleDetach = async () => {
    if (properties.length === 0) return

    const propertyIds = properties.map((property) => property.id)
    const propertyService = new PropertyService()
    try {
      await propertyService.detachLandlord(propertyIds)
      onDetach && onDetach()
    } catch (error) {
      toast.error('Failed to detach landlord')
      console.log(error)
    }
  }

  const handleMerge = () => {
    onMerge && onMerge()
  }

  return (
    <>
      <SubmitButton onClick={() => handleDetach()}>Detach</SubmitButton>
      <SubmitButton onClick={handleOpen}>Merge</SubmitButton>
      <MergePropertiesModal
        isOpen={isOpen}
        onClose={handleClose}
        onSuccessMerge={handleMerge}
        propertiesIds={properties.map((property) => property.id)}
      />
    </>
  )
}

export default ClientPropertiesPageActions
