import { FC } from 'react'
import EmailHistoryTable from './email-history-table.component'

export const EmailTab: FC = () => {
  return (
    <div className="tabs_wrapper card-body" style={{borderTopLeftRadius: 0, borderTopRightRadius: 0}}>
      <EmailHistoryTable />
    </div>
  )
}
