import { SelectOption } from "@src/types/component-types"
import { CallResult, Relationships } from "../types/debtor-associate-detail.type"

export const DEBTOR_RELATIONS_OPTIONS: SelectOption<Relationships>[] = [
  {
    label: 'Ar',
    value: Relationships.Ar
  },
  {
    label: 'BL',
    value: Relationships.BL
  },
  {
    label: 'Bo',
    value: Relationships.Bo
  },
  {
    label: 'Brother',
    value: Relationships.Brother
  },
  {
    label: 'Cousin',
    value: Relationships.Cousin
  },
  {
    label: 'Daughter',
    value: Relationships.Daughter
  },
  {
    label: 'DL',
    value: Relationships.DL
  },
  {
    label: 'EH',
    value: Relationships.EH
  },
  {
    label: 'EW',
    value: Relationships.EW
  },
]

export const DEBTOR_CALL_RESULT_OPTIONS: SelectOption<CallResult>[] = [
  {
    label: 'SW',
    value: CallResult.SW
  },
  {
    label: 'S/OFF',
    value: CallResult["S/OFF"]
  },
  {
    label: 'NHOP/P',
    value: CallResult["NHOP/P"]
  },
  {
    label: 'CNBC',
    value: CallResult.CNBC
  },
  {
    label: 'Do Not Ring',
    value: CallResult.DoNotRing
  },
  {
    label: 'Disconnected',
    value: CallResult.Disconnected
  }
]