import React from 'react';
import { Button, Col } from 'reactstrap';
import Select from 'react-select';
import { reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import SimpleReactValidator from 'simple-react-validator';
import DebtorService from '../../../../../../services/DebtorService';
import Services from '../../../../../../shared/Services';
import AddTaskModal from '../../../../../Todo/AddTaskModal';
import NotesService from '../../../../../../services/NotesServices';
import { UserContext } from '../../../../../../context/user-context';

const callResultOption = [
  { value: '0', label: 'SW' },
  { value: '1', label: 'S/OFF' },
  { value: '2', label: 'NHOP/P' },
  { value: '3', label: 'CNBC' },
  { value: '4', label: 'Do Not Ring' },
  { value: '5', label: 'Disconnected' },
];

const RelationOption = [
  { value: '0', label: 'Ar' },
  { value: '1', label: 'BL' },
  { value: '2', label: 'Bo' },
  { value: '3', label: 'Brother' },
  { value: '4', label: 'Cousin' },
  { value: '5', label: 'Daughter' },
  { value: '6', label: 'DL' },
  { value: '7', label: 'EH' },
  { value: '8', label: 'EW' },
  { value: '9', label: 'Father' },
  { value: '10', label: 'FL' },
  { value: '11', label: 'BL' },
  { value: '12', label: 'GD' },
  { value: '13', label: 'GF' },
  { value: '14', label: 'Gi' },
  { value: '15', label: 'GM' },
  { value: '16', label: 'Hu' },
  { value: '17', label: 'ML' },
  { value: '18', label: 'Mother' },
  { value: '19', label: 'Nethew' },
  { value: '20', label: 'Niece' },
  { value: '21', label: 'Other' },
  { value: '22', label: 'Pa' },
  { value: '23', label: 'Sister' },
  { value: '24', label: 'SL' },
  { value: '22', label: 'Son' },
  { value: '23', label: 'Uncle' },
  { value: '24', label: 'Wife' },
];

class AssociateDetail extends React.Component {
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      wipID: localStorage.getItem('debtorId') || '',
      editID: null,
      errorModal: false,
      errorMessage: '',
      errorType: '',
      relations: [{
        id: Math.floor(Math.random() * (1 + 100)) + 1,
        name: null,
        relationship: null,
        phone: null,
        email: null,
        address: null,
        call_result: null,
        call_note: null,
      }],
      references: [{
        id: Math.floor(Math.random() * (1 + 100)) + 1,
        name: null,
        relationship: null,
        phone: null,
        email: null,
        address: null,
        call_result: null,
        call_note: null,
      }],
      work_place: [{
        id: Math.floor(Math.random() * (1 + 100)) + 1,
        name: null,
        relationship: null,
        phone: null,
        email: null,
        contact: null,
        address: null,
        call_result: null,
        call_note: null,
      }],
      other: [{
        id: Math.floor(Math.random() * (1 + 100)) + 1,
        name: null,
        relationship: null,
        phone: null,
        email: null,
        address: null,
        call_result: null,
        call_note: null,
      }],
      isEdit: null,
      addAppointment: false,
      changeWorkPlace: false,
      changeReferences: false,
      changeRelations: false,
      changeOther: false,
      debtorId: '',
    };

    this.debtor = new DebtorService();
    this.note = new NotesService()
    this.services = new Services();
    this.validator = new SimpleReactValidator();
    this.validatorRelation = new SimpleReactValidator();
    this.validatorReference = new SimpleReactValidator();
    this.validatorOther = new SimpleReactValidator();
    this.validatorWorkPlace = new SimpleReactValidator();
  }

  componentDidMount() {
    this.setDebtor(this.props.dataDebtor);
  }

  setDebtor(data) {
    if (data) {
      const getData = data.data;

      getData.relations && getData.relations.map(rl => {
        rl.id = Math.floor(Math.random() * (1 + 100)) + 1;
        rl.relationship = RelationOption.find(_ => _.label === rl.relationship);
        rl.call_result = callResultOption.find(_ => _.label === rl.call_result);
      });

      getData.references && getData.references.map(re => {
        re.id = Math.floor(Math.random() * (1 + 100)) + 1;
        re.call_result = callResultOption.find(_ => _.label === re.call_result);
        re.relationship = RelationOption.find(_ => _.label === re.relationship);
      });

      getData.work_places && getData.work_places.map(wp => {
        wp.id = Math.floor(Math.random() * (1 + 100)) + 1;
        wp.call_result = callResultOption.find(_ => _.label === wp.call_result);
        wp.relationship = RelationOption.find(_ => _.label === wp.relationship);
      });

      getData.other && getData.other.map(o => {
        o.id = Math.floor(Math.random() * (1 + 100)) + 1;
        o.call_result = callResultOption.find(_ => _.label === o.call_result);
        o.relationship = RelationOption.find(_ => _.label === o.relationship);
      });

      this.setState({
        other: getData.other && getData.other.length > 0 ? getData.other : this.state.other,
        work_place: getData.work_places && getData.work_places.length > 0 ? getData.work_places : this.state.work_place,
        relations: getData.relations && getData.relations.length > 0 ? getData.relations : this.state.relations,
        references: getData.references && getData.references.length > 0 ? getData.references : this.state.references,
        debtorId: getData.id
      });
    }
  }

  changeInputInner(e, name, id) {
    if (!e.target.validity.valid) {
      return null;
    }
    if (name === 'Address') {
      this.state.address_hist.map(item => {
        if (item.id === id) {
          item[e.target.name] = e.target.value;
        }
      });
    }
    if (name === 'Relations') {
      this.state.relations.map(item => {
        if (item.id === id) {
          item[e.target.name] = e.target.value;
          if(e.target.name === 'call_note'){
            this.setState({changeRelations: true})
          }
        }
      });
    }
    if (name === 'Reference') {
      this.state.references.map(item => {
        if (item.id === id) {
          item[e.target.name] = e.target.value;
          if(e.target.name === 'call_note'){
            this.setState({changeReferences: true})
          }
        }
      });
    }
    if (name === 'Other') {
      this.state.other.map(item => {
        if (item.id === id) {
          item[e.target.name] = e.target.value;
          if(e.target.name === 'call_note'){
            this.setState({changeOther: true})
          }
        }
      });
    }
    if (name === 'Work_Place') {
      this.state.work_place.map(item => {
        if (item.id === id) {
          item[e.target.name] = e.target.value;
          if(e.target.name === 'call_note'){
            this.setState({changeWorkPlace: true})
          }
        }
      });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
    this.forceUpdate();
  }

  handleChangeArray = (selectedOption, name, keyName, id) => {
    if (keyName === 'Relations') {
      this.state.relations.map(item => {
        if (item.id === id) {
          item[name] = selectedOption;
        }
      });
    }
    if (keyName === 'Reference') {
      this.state.references.map(item => {
        if (item.id === id) {
          item[name] = selectedOption;
        }
      });
    }
    if (keyName === 'Work_Place') {
      this.state.work_place.map(item => {
        if (item.id === id) {
          item[name] = selectedOption;
        }
      });
    }
    if (keyName === 'Other') {
      this.state.other.map(item => {
        if (item.id === id) {
          item[name] = selectedOption;
        }
      });
    } else {
      this.setState({ [name]: selectedOption });
    }
    this.forceUpdate();
  };

  filterData(data){
    return data.name && data.relationship
  }

  transformData({id, ...rest}){
    return ({
      ...rest,
      relationship: rest.relationship ? rest.relationship.label : null,
      call_result: rest.call_result ? rest.call_result.label : null,
    })
  }

  saveDebtor = () => {

    const transformedRelations = this.state.relations.filter(this.filterData).map(this.transformData)
    const transformedWorkPlace = this.state.work_place.filter(this.filterData).map(this.transformData)
    const transformedOther = this.state.other.filter(this.filterData).map(this.transformData)
    const transformedReferences = this.state.references.filter(this.filterData).map(this.transformData)

    this.state.address_hist && this.state.address_hist.map(hist => {

      if (hist.address_1) {
        hist.debtor = { id: +this.state.wipID };
        hist.history_start_date = hist.history_start_date ? hist.history_start_date : null;
        hist.history_end_date = hist.history_end_date ? hist.history_end_date : null;
        return hist;
      }
    });

    const data = {
      id: this.state.wipID,
      references: transformedReferences,
      relations: transformedRelations,
      work_places: transformedWorkPlace,
      other: transformedOther,
    };

    if(this.state.changeWorkPlace){
      const workPlaceNote = this.state.work_place.map((note,)=>{
        if(note.call_note){
          const {user} = this.context;
          const arrayNote = {
            debtor: { id: +this.state.wipID, },
            wip: {id: this.props.dataDebtor.data.wips[0].id,},
            title: note.relationship?.label,
            content: note.call_note,
            is_important: false,
            actions: 'Debtor contact',
            is_contact: true,
            staff_created: `${user.first_name} ${user.last_name}`,
          }
          return arrayNote
        }
      })
      if(workPlaceNote[0].content){
        this.note.addDebtorNotesArray(workPlaceNote).then((res) => {
          if (res.status === 201) {
            this.services.successToast('Note added successfully');
            this.props.getDebtorNotes(+this.state.debtorId)
          } else if (
            res.statusCode === 401 &&
            res.responseType === 'UNAUTHORIZED'
          ) {
            this.setState({ accessErrorModal: true });
          } else {
            this.services.errorToast(res.message);
          }
        });
      }
    }

    if(this.state.changeReferences){
      const referencesNote = this.state.references.map((note,)=>{
        if(note.call_note){
          const {user} = this.context;
          const arrayNote = {
            debtor: { id: +this.state.wipID, },
            wip: {id: this.props.dataDebtor.data.wips[0].id,},
            title: note.relationship?.label,
            content: note.call_note,
            is_important: false,
            actions: 'Debtor contact',
            is_contact: true,
            staff_created: `${user.first_name} ${user.last_name}`,
          }
          return arrayNote
        }
      })

      if(referencesNote[0].content){
        this.note.addDebtorNotesArray(referencesNote).then((res) => {
          if (res.status === 201) {
            this.services.successToast('Note added successfully');
            this.props.getDebtorNotes(+this.state.debtorId)
          } else if (
            res.statusCode === 401 &&
            res.responseType === 'UNAUTHORIZED'
          ) {
            this.setState({ accessErrorModal: true });
          } else {
            this.services.errorToast(res.message);
          }
        });
      }
    }

    if(this.state.changeRelations){
      const relationsNote = this.state.relations.map((note,)=>{
        if(note.call_note){
          const {user} = this.context;
          const arrayNote = {
            debtor: { id: +this.state.wipID, },
            wip: {id: this.props.dataDebtor.data.wips[0].id,},
            title: note.relationship?.label,
            content: note.call_note,
            is_important: false,
            actions: 'Debtor contact',
            is_contact: true,
            staff_created: `${user.first_name} ${user.last_name}`,
          }
          return arrayNote
        }
      })
      if(relationsNote[0].content){
        this.note.addDebtorNotesArray(relationsNote).then((res) => {
          if (res.status === 201) {
            this.services.successToast('Note added successfully');
            this.props.getDebtorNotes(+this.state.debtorId)
          } else if (
            res.statusCode === 401 &&
            res.responseType === 'UNAUTHORIZED'
          ) {
            this.setState({ accessErrorModal: true });
          } else {
            this.services.errorToast(res.message);
          }
        });
      }
    }

    if(this.state.changeOther){
      const otherNote = this.state.other.map((note,)=>{
        if(note.call_note){
          const {user} = this.context;
          const arrayNote = {
            debtor: { id: +this.state.wipID, },
            wip: {id: this.props.dataDebtor.data.wips[0].id,},
            title: note.relationship?.label,
            content: note.call_note,
            is_important: false,
            actions: 'Debtor contact',
            is_contact: true,
            staff_created: `${user.first_name} ${user.last_name}`,
          }
          return arrayNote
        }
      })
      if(otherNote[0].content){
        this.note.addDebtorNotesArray(otherNote).then((res) => {
          if (res.status === 201) {
            this.services.successToast('Note added successfully');
            this.props.getDebtorNotes(+this.state.debtorId)
          } else if (
            res.statusCode === 401 &&
            res.responseType === 'UNAUTHORIZED'
          ) {
            this.setState({ accessErrorModal: true });
          } else {
            this.services.errorToast(res.message);
          }
        });
      }
    }

    if (this.validator.allValid()) {
      this.debtor.editDebtor(data).then((res) => {
        if (res.status === 200) {
          this.services.successToast('Debtor updated successfully');
          this.props.getDebtor(+this.state.debtorId)
          this.props.getDebtorNotes(+this.state.debtorId)
        } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
          this.setState({ accessErrorModal: true });
        } else {
          this.services.errorToast(res.message);
        }
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  changeTab = (e, tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
        task_id: '',
      });
    }
  };

  addRelation = () => {

    //"The relationship field is required."
    if (this.validatorRelation.allValid()) {
      const data = {
        id: Math.floor(Math.random() * (1 + 100)) + 1,
        name: '',
        relationship: '',
        phone: '',
        email: '',
        address: '',
        call_result: '',
        call_note: '',
      };
      const list = this.state.relations || [];
      if (list) {
        list.push(data);
        this.setState({ relations: list });
        this.validatorRelation.hideMessages();
      }
    } else {
      this.validatorRelation.showMessages();
      this.forceUpdate();
    }
  };

  removeRelation = (id) => {
    const details = this.state.relations.filter((_) => _.id !== id);
    this.setState({
      relations: details,
    });
  };

  addReference = () => {
    if (this.validatorReference.allValid()) {
      const data = {
        id: Math.floor(Math.random() * (1 + 100)) + 1,
        name: '',
        relationship: '',
        phone: '',
        email: '',
        address: '',
        call_result: '',
        call_note: '',
      };
      const list = this.state.references || [];
      if (list) {
        list.push(data);
        this.setState({ references: list });
        this.validatorReference.hideMessages();
      }
    } else {
      this.validatorReference.showMessages();
      this.forceUpdate();
    }
  };

  removeReference = (id) => {
    const details = this.state.references.filter((_) => _.id !== id);
    this.setState({
      references: details,
    });
  };

  addOther = () => {
    if (this.validatorOther.allValid()) {
      const data = {
        id: Math.floor(Math.random() * (1 + 100)) + 1,
        name: '',
        relationship: '',
        phone: '',
        email: '',
        address: '',
        call_result: '',
        call_note: '',
      };
      const list = this.state.other || [];
      if (list) {
        list.push(data);
        this.setState({ other: list });
        this.validatorOther.hideMessages();
      }
    } else {
      this.validatorOther.showMessages();
      this.forceUpdate();
    }
  };

  removeOther = (id) => {
    const details = this.state.other.filter((_) => _.id !== id);
    this.setState({
      other: details,
    });
  };

  addWorkPlace = () => {
    if (this.validatorWorkPlace.allValid()) {
      const data = {
        id: Math.floor(Math.random() * (1 + 100)) + 1,
        name: '',
        relationship: '',
        phone: '',
        email: '',
        address: '',
        call_result: '',
        call_note: '',
      };
      const list = this.state.work_place || [];
      if (list) {
        list.push(data);
        this.setState({ work_place: list });
        this.validatorWorkPlace.hideMessages();
      }
    } else {
      this.validatorWorkPlace.showMessages();
      this.forceUpdate();
    }
  };

  removeWorkPlace = (id) => {
    const details = this.state.work_place.filter((_) => _.id !== id);
    this.setState({ work_place: details });
  };

  addAppointmentClick = () => {
    this.setState({
      isEdit: false,
      addAppointment: true,
    });
  };

  closeModal = () => {
    this.setState({
      addAppointment: false,
    });
  };

  reloadList = () => {
    this.setDebtor(this.props.dataDebtor);
  };


  render() {
    const { activeTab, relations, references, work_place, other } = this.state;

    return (
      <div className='w-100 pb-5'>
        <div id='contacts_accordion' className='form'>
          <div className='card pb-0'>
            <div className='slide_down_wrap mb-4 rounded'>
              <Col md={12} lg={12} className='popup_tabs_nav mt-3 mb-3'>
                <div className='tabs_nav  bg-white d-flex flex-wrap justify-content-between'>
                  <ul className='nav nav-tabs' role='tablist'>
                    <li className='nav-item'>
                      <span
                        className={activeTab === 1 ? 'nav-link active' : 'nav-link'}
                        id='Relations-link'
                        data-toggle='tab'
                        role='tab'
                        aria-controls='overview'
                        aria-selected='true'
                        onClick={(e) => this.changeTab(e, 1)}
                      >
                        Relations
                      </span>
                    </li>
                    <li className='nav-item'>
                      <span
                        className={activeTab === 2 ? 'nav-link active' : 'nav-link'}
                        id='tools-link'
                        data-toggle='tab'
                        role='tab'
                        aria-controls='overview'
                        aria-selected='true'
                        onClick={(e) => this.changeTab(e, 2)}
                      >
                        References
                      </span>
                    </li>
                    <li className='nav-item'>
                      <span
                        className={activeTab === 3 ? 'nav-link active' : 'nav-link'}
                        id='tools-link'
                        data-toggle='tab'
                        role='tab'
                        aria-controls='overview'
                        aria-selected='true'
                        onClick={(e) => this.changeTab(e, 3)}
                      >
                        Work Place
                      </span>
                    </li>
                    <li className='nav-item'>
                      <span
                        className={activeTab === 4 ? 'nav-link active' : 'nav-link'}
                        id='tools-link'
                        data-toggle='tab'
                        role='tab'
                        aria-controls='overview'
                        aria-selected='true'
                        onClick={(e) => this.changeTab(e, 4)}
                      >
                        Other
                      </span>
                    </li>
                  </ul>
                  <div className={'mr-3 mt-2 mb-2 mr-2'}>
                    <button
                      className='btn btn-secondary add_plus rounded-circle m-0'
                      onClick={this.addAppointmentClick}
                    >
                      +
                    </button>
                  </div>
                </div>

                <div className='tab-content'>
                  <div
                    className={activeTab === 1 ? 'tab-pane fade show active' : 'tab-pane fade'}
                    id='Relations-tab'
                    role='tabpanel'
                    aria-labelledby='Relations-tab'
                  >
                    {relations && relations.map((item, i) => {
                      return (
                        <div key={i} className='row repeat_tab form'>
                          <div className='col-12 col-md-12 col-lg-12'>
                            <div className='form-group text-left'>
                              <label className='form__form-group-label' htmlFor=''> Name: <span
                                style={{ color: '#ff0000' }}>*</span></label>
                              <input type='text'
                                     className='custom-input'
                                     name='name'
                                     onChange={(e) => this.changeInputInner(e, 'Relations', item.id)}
                                     value={item.name || ''}
                              />
                              <span className='form__form-group-error text-left'>
                                {this.validatorRelation.message('Name', item.name, 'required')}
                              </span>
                            </div>
                          </div>

                          <div className='col-12 col-md-12 col-lg-12'>
                            <div className='form-group'>
                              <label className='form__form-group-label' htmlFor=''> Relationship: <span
                                style={{ color: '#ff0000' }}>*</span></label>
                              <Select
                                classNamePrefix='react-select'
                                className='react-select'
                                options={RelationOption}
                                value={item.relationship || ''}
                                onChange={(e) => this.handleChangeArray(e, 'relationship', 'Relations', item.id)}
                              />
                            </div>
                          </div>

                          <div className='col-12 col-md-12 col-lg-12'>
                            <div className='form-group text-left'>
                              <label className='form__form-group-label' htmlFor=''> Phone: </label>
                              <input type='text'
                                     className='custom-input'
                                     pattern='[0-9]*'
                                     name='phone'
                                     value={item.phone || ''}
                                     onChange={(e) => this.changeInputInner(e, 'Relations', item.id)}
                              />
                            </div>
                          </div>

                          <div className='col-12 col-md-12 col-lg-12'>
                            <div className='form-group text-left'>
                              <label className='form__form-group-label' htmlFor=''> Email: </label>
                              <input type='text'
                                     className='custom-input'
                                     name='email'
                                     value={item.email || ''}
                                     onChange={(e) => this.changeInputInner(e, 'Relations', item.id)}
                              />
                            </div>
                          </div>

                          <div className='col-12 col-md-12 col-lg-12'>
                            <div className='form-group'>
                              <label className='form__form-group-label text-left w-100'
                                     htmlFor=''> Address: </label>
                              <input type='text'
                                     className='custom-input'
                                     name='address'
                                     value={item.address || ''}
                                     onChange={(e) => this.changeInputInner(e, 'Relations', item.id)}
                              />
                            </div>
                          </div>

                          <div className='col-12 col-md-12 col-lg-12'>
                            <div className='form-group text-left'>
                              <label className='form__form-group-label' htmlFor=''> Call Result: </label>
                              <Select
                                classNamePrefix='react-select'
                                className='react-select'
                                options={callResultOption}
                                value={item.call_result || ''}
                                name='assoc_call_result'
                                onChange={(e) => this.handleChangeArray(e, 'call_result', 'Relations', item.id)}
                              />
                            </div>
                          </div>

                          <div className='col-12 col-md-12 col-lg-12'>
                            <div className='form-group'>
                              <label className='form__form-group-label text-left w-100' htmlFor=''> Call
                                Note: </label>
                              <textarea className='custom-input'
                                        name='call_note'
                                        value={item.call_note || ''}
                                        onChange={(e) => this.changeInputInner(e, 'Relations', item.id)}
                              />
                            </div>
                          </div>
                          <div className='d-flex pr-3 justify-content-end w-100'>
                            {
                              i < this.state.relations.length - 1 && (
                                <button className='btn-gray mr-3'
                                        onClick={() => this.removeRelation(item.id)}> Remove </button>
                              )
                            }
                            {
                              i === this.state.relations.length - 1 &&
                              <button className='btn-gray' onClick={() => this.addRelation()}> Add Relation </button>
                            }
                          </div>

                        </div>
                      );
                    })}
                  </div>
                  <div className={activeTab === 2 ? 'tab-pane fade show active' : 'tab-pane fade'}
                       id='References-tab'
                       role='tabpanel'
                       aria-labelledby='References-tab'
                  >
                    {references && references.map((item, i) => {
                      return (
                        <div key={i} className='row repeat_tab form'>
                          <div className='col-12 col-md-12 col-lg-12'>
                            <div className='form-group text-left'>
                              <label className='form__form-group-label' htmlFor=''> Name: <span
                                style={{ color: '#ff0000' }}>*</span></label>
                              <input type='text'
                                     className='custom-input'
                                     name='name'
                                     onChange={(e) => this.changeInputInner(e, 'Reference', item.id)}
                                     value={item.name || ''}
                              />
                              <span className='form__form-group-error text-left'>
                                        {this.validatorReference.message('Name', item.name, 'required')}
                                      </span>
                            </div>
                          </div>

                          <div className='col-12 col-md-12 col-lg-12'>
                            <div className='form-group'>
                              <label className='form__form-group-label' htmlFor=''> Relationship: <span
                                style={{ color: '#ff0000' }}>*</span></label>
                              <Select
                                classNamePrefix='react-select'
                                className='react-select'
                                options={RelationOption}
                                value={item.relationship || ''}
                                onChange={(e) => this.handleChangeArray(e, 'relationship', 'Reference', item.id)}
                              />
                            </div>
                          </div>

                          <div className='col-12 col-md-12 col-lg-12'>
                            <div className='form-group text-left'>
                              <label className='form__form-group-label' htmlFor=''> Phone: </label>
                              <input type='text'
                                     className='custom-input'
                                     pattern='[0-9]*'
                                     name='phone'
                                     value={item.phone || ''}
                                     onChange={(e) => this.changeInputInner(e, 'Reference', item.id)}
                              />
                            </div>
                          </div>

                          <div className='col-12 col-md-12 col-lg-12'>
                            <div className='form-group text-left'>
                              <label className='form__form-group-label' htmlFor=''> Email: </label>
                              <input type='text'
                                     className='custom-input'
                                     name='email'
                                     value={item.email || ''}
                                     onChange={(e) => this.changeInputInner(e, 'Reference', item.id)}
                              />
                            </div>
                          </div>

                          <div className='col-12 col-md-12 col-lg-12'>
                            <div className='form-group'>
                              <label className='form__form-group-label text-left w-100'
                                     htmlFor=''> Address: </label>
                              <input type='text'
                                     className='custom-input'
                                     name='address'
                                     value={item.address || ''}
                                     onChange={(e) => this.changeInputInner(e, 'Reference', item.id)}
                              />
                            </div>
                          </div>

                          <div className='col-12 col-md-12 col-lg-12'>
                            <div className='form-group text-left'>
                              <label className='form__form-group-label' htmlFor=''> Call Result: </label>
                              <Select
                                classNamePrefix='react-select'
                                className='react-select'
                                options={callResultOption}
                                value={item.call_result}
                                name='assoc_call_result'
                                onChange={(e) => this.handleChangeArray(e, 'call_result', 'Reference', item.id)}
                              />
                            </div>
                          </div>

                          <div className='col-12 col-md-12 col-lg-12'>
                            <div className='form-group'>
                              <label className='form__form-group-label text-left w-100' htmlFor=''> Call
                                Note: </label>
                              <textarea className='custom-input'
                                        name='call_note'
                                        value={item.call_note || ''}
                                        onChange={(e) => this.changeInputInner(e, 'Reference', item.id)}
                              />
                            </div>
                          </div>

                          <div className='d-flex pr-3 justify-content-end w-100'>
                            {
                              i < this.state.references.length - 1 && (
                                <button className='btn-gray mr-3'
                                        onClick={() => this.removeReference(item.id)}> Remove </button>
                              )
                            }
                            {
                              i === this.state.references.length - 1 &&
                              <button className='btn-gray' onClick={() => this.addReference()}> Add
                                Reference </button>
                            }
                          </div>

                        </div>
                      );
                    })}
                  </div>
                  <div className={activeTab === 3 ? 'tab-pane fade show active' : 'tab-pane fade'}
                       id='WorkPlace-tab'
                       role='tabpanel'
                       aria-labelledby='WorkPlace-tab'
                  >
                    {work_place && work_place.map((item, i) => {
                      return (
                        <div key={i} className='row repeat_tab form'>
                          <div className='col-12 col-md-12 col-lg-12'>
                            <div className='form-group text-left'>
                              <label className='form__form-group-label' htmlFor=''> Name: <span
                                style={{ color: '#ff0000' }}>*</span></label>
                              <input type='text'
                                     className='custom-input'
                                     name='name'
                                     onChange={(e) => this.changeInputInner(e, 'Work_Place', item.id)}
                                     value={item.name || ''}
                              />
                              <span className='form__form-group-error text-left'>
                                        {this.validatorWorkPlace.message('Name', item.name, 'required')}
                                      </span>
                            </div>
                          </div>

                          <div className='col-12 col-md-12 col-lg-12'>
                            <div className='form-group'>
                              <label className='form__form-group-label' htmlFor=''> Relationship: <span
                                style={{ color: '#ff0000' }}>*</span></label>
                              <Select
                                classNamePrefix='react-select'
                                className='react-select'
                                options={RelationOption}
                                value={item.relationship || ''}
                                onChange={(e) => this.handleChangeArray(e, 'relationship', 'Work_Place', item.id)}
                              />
                            </div>
                          </div>

                          <div className='col-12 col-md-12 col-lg-12'>
                            <div className='form-group text-left'>
                              <label className='form__form-group-label' htmlFor=''> Contact: </label>
                              <input type='text'
                                     className='custom-input'
                                     pattern='[0-9]*'
                                     name='contact'
                                     value={item.contact || ''}
                                     onChange={(e) => this.changeInputInner(e, 'Work_Place', item.id)}
                              />
                            </div>
                          </div>

                          <div className='col-12 col-md-12 col-lg-12'>
                            <div className='form-group text-left'>
                              <label className='form__form-group-label' htmlFor=''> Phone: </label>
                              <input type='text'
                                     className='custom-input'
                                     pattern='[0-9]*'
                                     name='phone'
                                     value={item.phone || ''}
                                     onChange={(e) => this.changeInputInner(e, 'Work_Place', item.id)}
                              />
                            </div>
                          </div>

                          <div className='col-12 col-md-12 col-lg-12'>
                            <div className='form-group text-left'>
                              <label className='form__form-group-label' htmlFor=''> Email: </label>
                              <input type='text'
                                     className='custom-input'
                                     name='email'
                                     value={item.email || ''}
                                     onChange={(e) => this.changeInputInner(e, 'Work_Place', item.id)}
                              />
                            </div>
                          </div>

                          <div className='col-12 col-md-12 col-lg-12'>
                            <div className='form-group'>
                              <label className='form__form-group-label text-left w-100'
                                     htmlFor=''> Address: </label>
                              <input type='text'
                                     className='custom-input'
                                     name='address'
                                     value={item.address || ''}
                                     onChange={(e) => this.changeInputInner(e, 'Work_Place', item.id)}
                              />
                            </div>
                          </div>

                          <div className='col-12 col-md-12 col-lg-12'>
                            <div className='form-group text-left'>
                              <label className='form__form-group-label' htmlFor=''> Call Result: </label>
                              <Select
                                classNamePrefix='react-select'
                                className='react-select'
                                options={callResultOption}
                                value={item.call_result || ''}
                                name='call_result'
                                onChange={(e) => this.handleChangeArray(e, 'call_result', 'Work_Place', item.id)}
                              />
                            </div>
                          </div>

                          <div className='col-12 col-md-12 col-lg-12'>
                            <div className='form-group'>
                              <label className='form__form-group-label text-left w-100' htmlFor=''> Call
                                Note: </label>
                              <textarea className='custom-input'
                                        name='call_note'
                                        value={item.call_note || ''}
                                        onChange={(e) => this.changeInputInner(e, 'Work_Place', item.id)}
                              />
                            </div>
                          </div>

                          <div className='d-flex pr-3 justify-content-end w-100'>
                            {
                              i < this.state.work_place.length - 1 && (
                                <button className='btn-gray mr-3'
                                        onClick={() => this.removeWorkPlace(item.id)}> Remove </button>
                              )
                            }
                            {
                              i === this.state.work_place.length - 1 &&
                              <button className='btn-gray' onClick={() => this.addWorkPlace()}> Add Work
                                Place </button>
                            }
                          </div>

                        </div>
                      );
                    })
                    }
                  </div>
                  <div className={activeTab === 4 ? 'tab-pane fade show active' : 'tab-pane fade'}
                       id='Other-tab'
                       role='tabpanel'
                       aria-labelledby='Other-tab'
                  >
                    {other && other.map((item, i) => {
                      return (
                        <div key={i} className='row repeat_tab form'>

                          <div className='col-12 col-md-12 col-lg-12'>
                            <div className='form-group text-left'>
                              <label className='form__form-group-label' htmlFor=''> Name: <span
                                style={{ color: '#ff0000' }}>*</span></label>
                              <input type='text'
                                     className='custom-input'
                                     name='name'
                                     onChange={(e) => this.changeInputInner(e, 'Other', item.id)}
                                     value={item.name || ''}
                              />
                              <span className='form__form-group-error text-left'>
                                        {this.validatorOther.message('Name', item.name, 'required')}
                                      </span>
                            </div>
                          </div>

                          <div className='col-12 col-md-12 col-lg-12'>
                            <div className='form-group'>
                              <label className='form__form-group-label' htmlFor=''> Relationship: <span
                                style={{ color: '#ff0000' }}>*</span></label>
                              <Select
                                classNamePrefix='react-select'
                                className='react-select'
                                options={RelationOption}
                                value={item.relationship || ''}
                                onChange={(e) => this.handleChangeArray(e, 'relationship', 'Other', item.id)}
                              />
                            </div>
                          </div>

                          <div className='col-12 col-md-12 col-lg-12'>
                            <div className='form-group text-left'>
                              <label className='form__form-group-label' htmlFor=''> Phone: </label>
                              <input type='text'
                                     className='custom-input'
                                     pattern='[0-9]*'
                                     name='phone'
                                     value={item.phone || ''}
                                     onChange={(e) => this.changeInputInner(e, 'Other', item.id)}
                              />
                            </div>
                          </div>

                          <div className='col-12 col-md-12 col-lg-12'>
                            <div className='form-group text-left'>
                              <label className='form__form-group-label' htmlFor=''> Email: </label>
                              <input type='text'
                                     className='custom-input'
                                     name='email'
                                     value={item.email || ''}
                                     onChange={(e) => this.changeInputInner(e, 'Other', item.id)}
                              />
                            </div>
                          </div>

                          <div className='col-12 col-md-12 col-lg-12'>
                            <div className='form-group'>
                              <label className='form__form-group-label text-left w-100'
                                     htmlFor=''> Address: </label>
                              <input type='text'
                                     className='custom-input'
                                     name='address'
                                     value={item.address || ''}
                                     onChange={(e) => this.changeInputInner(e, 'Other', item.id)}
                              />
                            </div>
                          </div>

                          <div className='col-12 col-md-12 col-lg-12'>
                            <div className='form-group text-left'>
                              <label className='form__form-group-label' htmlFor=''> Call Result: </label>
                              <Select
                                classNamePrefix='react-select'
                                className='react-select'
                                options={callResultOption}
                                value={item.call_result || ''}
                                name='assoc_call_result'
                                onChange={(e) => this.handleChangeArray(e, 'call_result', 'Other', item.id)}
                              />
                            </div>
                          </div>

                          <div className='col-12 col-md-12 col-lg-12'>
                            <div className='form-group'>
                              <label className='form__form-group-label text-left w-100' htmlFor=''> Call
                                Note: </label>
                              <textarea className='custom-input'
                                        name='call_note'
                                        value={item.call_note || ''}
                                        onChange={(e) => this.changeInputInner(e, 'Other', item.id)}
                              />
                            </div>
                          </div>

                          <div className='d-flex pr-3 justify-content-end w-100'>
                            {
                              i < this.state.other.length - 1 && (
                                <button className='btn-gray mr-3'
                                        onClick={() => this.removeOther(item.id)}> Remove </button>
                              )
                            }
                            {
                              i === this.state.other.length - 1 &&
                              <button className='btn-gray' onClick={() => this.addOther()}> Add Other
                                Associate </button>
                            }
                          </div>

                        </div>
                      );
                    })
                    }
                  </div>
                </div>
              </Col>
            </div>
          </div>
          <div className='w-100 d-flex justify-content-end'>
            <Button className='btn-group--icons' onClick={this.saveDebtor}>Save</Button>
            <button className='btn-gray m-0' onClick={() => this.props.history.push('/debtor')}>Cancel</button>
          </div>
        </div>
        {this.state.addAppointment && (
          <AddTaskModal
            addTaskModal={this.state.addAppointment}
            reloadList={this.reloadList}
            isEdit={this.state.isEdit}
            data={this.state.selectedTask}
            wipId={this.props.dataDebtor.data.wips[0].id}
            closeModal={this.closeModal}
          />
        )}
      </div>
    );
  }
}


export default reduxForm({ form: 'add_staff_form_validation' })
(withRouter(withTranslation('common')(AssociateDetail)));
