import Table from '@components/table/table.component'
import { useGetWipList } from '../../hooks/get-wip-list.hook'
import { FC, useEffect, useState } from 'react'
import { Wip } from '../../types/wip.type'
import WipListItem from '../../components/wip-list-item/wip-list-item.component'
import Section from '@components/section/section.component'
import styles from './wip-list-page.module.scss'
import { useMainStyleContext } from '@containers/App/contexts/main-style.context'
import WipListFilter from '../../components/wip-list-filter/wip-list-filter.component'
import { Header } from '../../components/UI/text/text.component'
import SubmitButton from '../../components/UI/submit-button/submit-button.component'

const WipListPage: FC = () => {
  const [filter, setFilter] = useState<string | null>(null)
  const [page, setPage] = useState(1)
  const { setStyle } = useMainStyleContext()
  const { data, isLoading, isRefetching } = useGetWipList(
    {
      limit: 5,
      page,
      join: ['company', 'debtors'],
      sort: 'id,DESC',
      s: filter && filter !== '{}' ? filter : undefined,
    },
    !!filter,
  )

  useEffect(() => {
    setStyle({ backgroundColor: '#f9fafb' })
    return () => {
      setStyle({ backgroundColor: 'white' })
    }
  }, [])

  const handleFilterChange = (filter: NonNullable<unknown>) => {
    setPage(1)
    setFilter(JSON.stringify(filter))
  }

  const wips = (data?.data || []) as Wip[]

  const isWipsLoading = isLoading || isRefetching
  const isWipsNotFound = wips.length === 0 && !isWipsLoading

  return (
    <>
      <div className={styles.headerWrapper}>
        <Header>Work in Progress Files</Header>
        <div className={styles.navigationWrapper}>
          <SubmitButton type="link" url="recoveries/tools">
            Recovery Tools
          </SubmitButton>
          <SubmitButton type="link" url='recoveries/forms/protect_&_collect'>Submitted Recoveries</SubmitButton>
        </div>
      </div>
      <Section className={styles.wipListSection}>
        <WipListFilter onFilterChange={handleFilterChange} />
        <Table
          totalCount={data?.total || 0}
          limit={5}
          page={page}
          onPageChange={setPage}
          bodyHeight={54 * 5}
          headers={['FILE #', 'LOGGED', 'CLIENT', 'DEBTORS', 'PROPERTY', 'STATUS', 'COVER']}
          loading={isWipsLoading}
          notFound={isWipsNotFound}
        >
          {wips.map((wip) => (
            <WipListItem key={`wip-${wip.id}`} wip={wip} />
          ))}
        </Table>
      </Section>
    </>
  )
}

export default WipListPage
