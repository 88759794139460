import { useTaskContext } from "@src/context/task-context"
import { useGetTasks } from "./get-tasks.hook";
import { TaskProps } from "../../taskboard/types";
import { useMemo } from "react";

export const useGetTasksOptions = () => {
    const { currentClient, currentWip, isRecovery } = useTaskContext();

    const params = useMemo(() => ({
        s: JSON.stringify(isRecovery ? {wip_id: currentWip}: { company_id: currentClient?.id }),
        limit: 1000
    }), [currentClient])

    const { data } = useGetTasks(params, !!currentClient);

    const transformTasksToTaskOptions = (tasks: TaskProps[]) => {
        return tasks.map(({ id, title }) => ({ value: id, label: title }))
    }

    return data ? transformTasksToTaskOptions(data) : []
}