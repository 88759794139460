import React, { CSSProperties, ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import styles from './dropdown-with-search.module.scss'
import cls from 'classnames'
import ArrowIcon from '@src/icons/arrow'
import NavigationButton from '@components/navigation-button/navigation-button'
import { useDebounce } from '@src/hooks/useDebounce'
import { Oval } from 'react-loader-spinner'

interface DropdownProps<T> {
  className?: string
  style?: CSSProperties
  withSearch?: boolean
  isLabelHidden?: boolean
  options?: {
    label: string
    value: T
  }[]
  isOpen?: boolean
  isLoading?: boolean
  label: string
  icon?: React.FC<{ className: string }>

  //events
  onSearchChange?: (search: string) => void
  onIsOpenChange?: (isOpen: boolean) => void
  onOptionClick?: (value: T) => void
  onReachEnd?: () => void
}

const DropdownWithSearch = <T,>({
  className,
  style,
  label,
  icon,
  options = [],
  isLabelHidden = false,
  isLoading = false,
  withSearch = true,
  onReachEnd,
  onSearchChange,
  onOptionClick,
  onIsOpenChange,
  isOpen,
}: DropdownProps<T>) => {
  const [searchValue, setSearchValue] = useState('')
  const debouncedSearchValue = useDebounce(searchValue)
  const [isInnerOpen, setIsInnerOpen] = useState(false)

  const handleToggleButtonClick = () => {
    if (isOpen === undefined) {
      setIsInnerOpen((prevState) => !prevState)
    } else if(isOpen !== undefined && onIsOpenChange){
      onIsOpenChange(!isOpen)
    }
  }

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }

  const observer = useRef<IntersectionObserver>()

  const lastElementRef = useCallback(
    (node: HTMLLIElement) => {
      if (isLoading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && onReachEnd) {
          onReachEnd()
        }
      })
      if (node) observer.current.observe(node)
    },
    [options, isLoading],
  )

  useEffect(() => {
    if (onSearchChange) {
      onSearchChange(debouncedSearchValue)
    }
  }, [debouncedSearchValue])

  useEffect(() => {
    if (isOpen !== undefined) {
      setIsInnerOpen(isOpen)
    }
  }, [isOpen])

  const isDataFound = options.length > 0
  const isDataNotFound = !isDataFound && !isLoading

  return (
    <div className={className} style={style}>
      <NavigationButton
        icon={icon}
        label={label}
        isLabelHidden={isLabelHidden}
        onClick={handleToggleButtonClick}
      >
        <ArrowIcon
          direction="bottom"
          className={cls(styles.arrowIcon, {
            [styles.hidden]: isLabelHidden,
            [styles.active]: isInnerOpen,
          })}
        />
      </NavigationButton>
      {isInnerOpen && !isLabelHidden ? (
        <div className={styles.dropDownMenu}>
          {withSearch ? (
            <input
              className={styles.dropDownSearch}
              placeholder="Search"
              value={searchValue}
              onChange={handleSearchChange}
            />
          ) : null}
          <ul className={styles.dropDownList}>
            {isDataFound
              ? options.map((option, i) => (
                  <li
                    className={styles.dropDownItem}
                    key={`${option.label}-${i}-${label}`}
                    ref={options.length === i + 1 ? lastElementRef : null}
                    onClick={onOptionClick ? () => onOptionClick(option.value) : undefined}
                  >
                    {option.label}
                  </li>
                ))
              : null}
            {isDataNotFound ? (
              <span className={cls(styles.notFound, styles.center)}>Not Found</span>
            ) : null}
            {isLoading ? (
              <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <Oval
                  visible={true}
                  height="40"
                  width="40"
                  color="blue"
                  secondaryColor="blue"
                  ariaLabel="oval-loading"
                />
              </div>
            ) : null}
          </ul>
        </div>
      ) : null}
    </div>
  )
}

export default DropdownWithSearch
