import { FC } from 'react'
import styles from './debtor-info-item.module.scss'
import { PHONE_CONTACT_LABEL } from '../../const/task.const'
import { useModal } from '@src/hooks/useModal'
import ContactInfoModal from '../contact-info-modal/contact-info-modal.component'
import { DebtorInfoItemProps } from '../../types/task.type'


const DebtorInfoItem: FC<DebtorInfoItemProps> = ({ debtor }) => {
  const [
    isPhoneNumberInfoModalOpen,
    handlePhoneNumberInfoModalOpen,
    handlePhoneNumberInfoModalClose,
  ] = useModal(false)

  const [isEmailInfoModalOpen, handlEmailInfoModalOpen, handlEmailInfoModalClose] = useModal(false)

  const getPhoneOptions = () => {
    const contacts = debtor.contact
    const phoneOptions: { label: string; value: string }[] = []

    for (const key in contacts) {
      const label = PHONE_CONTACT_LABEL[key]
      const value = contacts[key]
      if (label && value) {
        phoneOptions.push({ label, value })
      }
    }
    return phoneOptions
  }

  const getEmaiiOptions = () => {
    const filteredEmails = debtor.contact.comms_email.filter((email) => email)
    return filteredEmails.map((email, i) => ({ label: `Email ${i + 1}`, value: email }))
  }

  const phoneOptions = getPhoneOptions()
  const emailOptions = getEmaiiOptions()

  const isPhoneAndEmailNotEmpty = phoneOptions.length && emailOptions.length

  return (
    <>
      <div className={styles.itemWrapper} key={`debtor-info-item-${debtor.id}`}>
        <span>{`${debtor.name_first} ${debtor.name_last}`}</span>
        {phoneOptions.length ? (
          <span onClick={handlePhoneNumberInfoModalOpen} className={styles.openInfoButton}>
            phone
          </span>
        ) : null}
        {isPhoneAndEmailNotEmpty ? <div className={styles.separator} /> : null}
        {emailOptions.length ? (
          <span onClick={handlEmailInfoModalOpen} className={styles.openInfoButton}>
            email
          </span>
        ) : null}
      </div>
      <ContactInfoModal
        isOpen={isPhoneNumberInfoModalOpen}
        onClose={handlePhoneNumberInfoModalClose}
        options={phoneOptions}
      />
      <ContactInfoModal
        isOpen={isEmailInfoModalOpen}
        onClose={handlEmailInfoModalClose}
        linkType="mailto"
        options={emailOptions}
      />
    </>
  )
}

export default DebtorInfoItem
