import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { InvoiceTemplateLayout } from './InvoiceTemplateLayout';
import { ConfirmationModal } from '../../../../../shared/components/ConfirmationModal/ConfirmationModal';
import { InvoiceTemplatesTable } from './InvoiceTemplatesTable';
import { toast } from 'react-toastify';

import { useDispatch, useSelector } from 'react-redux';
import { getInvoiceTemplatesList } from '../../../../../redux/actions/getInvoiceTemplatesList';
import { getInvoiceTemplatesListSelector } from '../../../../../redux/selector/selector';
import { deleteInvoiceTemplate } from '../../../../../redux/actions';

export const InvoiceTemplates = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const invoiceTemplates = useSelector(getInvoiceTemplatesListSelector);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const templateIdRef = useRef(null);

  useEffect(() => {
    getInvoiceTemplates(page);
  }, []);

  // Getting templates list
  const getInvoiceTemplates = (page) => {
    dispatch(getInvoiceTemplatesList(page))
      .then(handleGetInvoiceTemplatesSuccess)
      .catch(handleFailure);
  };

  // Firing on get invoice template request success
  const handleGetInvoiceTemplatesSuccess = (res) => {
    setCount(res.pageCount);
  };

  // Firing on page changing
  const handlePageChange = (_, newPage) => {
    // If user clicking on the same page nothing to do
    if (newPage === page) return;
    setPage(newPage);
    getInvoiceTemplates(newPage);
  };

  // Editing the template
  const handleEdit = (id) => {
    history.push(`/edit-invoice-template/${id}`);
  };

  // Removing the template
  const handleRemove = (id) => {
    // Setting the id of current template to delete after
    templateIdRef.current = id;
    setOpenConfirmationModal(true);
  };

  // Confirmation popup
  const handleConfirm = () => {
    dispatch(deleteInvoiceTemplate(templateIdRef.current))
      .then(handleConfirmSuccess)
      .catch(handleFailure);

    // Closing the modal
    handleConfirmationModalClose();
  };

  const handleConfirmSuccess = () => {
    // If deleting the last template of page it should return to the previous page
    if (templates.length - 1 === 0) {
      setPage((prev) => prev - 1);
      getInvoiceTemplates(page - 1);
      return;
    }

    getInvoiceTemplates(page);
  };

  const handleConfirmationModalClose = () => {
    setOpenConfirmationModal(false);
    // Setting the id of template to null when modal is closed
    templateIdRef.current = null;
  };

  const handleFailure = () => {
    toast.error('Something went wrong!');
  };

  const templates = invoiceTemplates?.data?.data;
  const loading = invoiceTemplates.loading;

  return (
    <InvoiceTemplateLayout heading={'Invoice Templates'}>
      <Link className="btn btn-secondary" to="/add-invoice-template">
        Add New Invoice Template
      </Link>
      <InvoiceTemplatesTable
        loading={loading}
        rowData={templates}
        count={count}
        page={page}
        handleEdit={handleEdit}
        handleRemove={handleRemove}
        handlePageChange={handlePageChange}
      />
      <ConfirmationModal
        open={openConfirmationModal}
        title="Are you sure?"
        description="By clicking confirm you will delete the template"
        handleClose={handleConfirmationModalClose}
        handleConfirm={handleConfirm}
      />
    </InvoiceTemplateLayout>
  );
};
