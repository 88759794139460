import React, { ChangeEvent, useState, FC } from 'react'
import { ContactSearchProps } from '../types/contact-list.type'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Button from '@mui/material/Button'

const ContactSearch: FC<ContactSearchProps> = ({ setSearchValue }) => {
  const [value, setValue] = useState('')
  const [field, setField] = React.useState('client_code||$eq')

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  const handleSelectChange = (event: SelectChangeEvent) => {
    setValue('')
    setField(event.target.value)
  }

  const onlyNumber = (
    e: ChangeEvent<HTMLInputElement>,
    cb: (e: ChangeEvent<HTMLInputElement>) => void,
  ) => {
    const numericValue = Number(e.target.value)
    if (Number.isNaN(numericValue)) {
      return
    }
    cb(e)
  }

  const handleSearchClick = () => {
    if(!value){
      setSearchValue(null);
      return;
    }
    const [name, rule] = field.split('||');
    setSearchValue({[name]: {[rule]: value}});
  }

  return (
    <div className="d-flex align-items-center">
      <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
        <InputLabel id="demo-select-small-label">Field</InputLabel>
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={field}
          label="Field"
          onChange={handleSelectChange}
        >
          <MenuItem value={'client_code||$eq'}>Client Code</MenuItem>
          <MenuItem value={'name||$contL'}>Client Name</MenuItem>
          <MenuItem value={'contacts.comm_email||$contL'}>Email</MenuItem>
        </Select>
      </FormControl>
      <input
        type="text"
        className="custom-input mr-3"
        value={value}
        onChange={(e) =>
          field === 'client_code||$eq' ? onlyNumber(e, handleInputChange) : handleInputChange(e)
        }
      />
      <Button variant="contained" onClick={handleSearchClick}>Search</Button>
    </div>
  )
}

export default ContactSearch
