import React from 'react'
import { Button } from 'reactstrap'
import { reduxForm } from 'redux-form'
import { withTranslation } from 'react-i18next'
import Services from '../../../../shared/Services.js'
import { withRouter } from 'react-router'
import Select from 'react-select'
import SimpleReactValidator from 'simple-react-validator'
import ModalComponent from '../../../../shared/components/ModalCustom'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import Geosuggest from 'react-geosuggest'
import StaffService from '../../../../services/StaffService'
import CompaniesService from '../../../../services/CompaniesService'
import { UserContext } from '../../../../context/user-context'

const renderField = ({ input, placeholder, value, type, meta: { touched, error } }) => (
  <div className="form__form-group-input-wrap">
    <input {...input} placeholder={placeholder} type={type} value={value} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
)

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
}

const optionsMonth = [
  { value: '01', label: '01' },
  { value: '02', label: '02' },
  { value: '02', label: '03' },
  { value: '04', label: '04' },
  { value: '05', label: '05' },
  { value: '06', label: '06' },
  { value: '07', label: '07' },
  { value: '08', label: '08' },
  { value: '09', label: '09' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
]

const clientStatusOption = [
  { value: 0, label: 'Prospect' },
  { value: 1, label: 'Current' },
  { value: 2, label: 'Inactive' },
  { value: 3, label: 'No Longer Operating' },
]

const clientTypeoption = [
  { value: '1', label: 'Admin' },
  { value: '2', label: 'Sales' },
  { value: '3', label: 'Recovery' },
  { value: '4', label: 'Account' },
]
const optionDay = []
for (let index = 1; index <= 31; index++) {
  if (index < 10) {
    optionDay.push({ value: `0${index}`, label: `0${index}` })
  } else {
    optionDay.push({ value: `${index}`, label: `${index}` })
  }
}

class Contatcts extends React.Component {
  static contextType = UserContext

  constructor(props) {
    super(props)
    this.state = {
      salesStaffOption: [],
      groupTypeOption: [],
      group_type: '',
      sales_staff: '',
      old_ten_id: '',
      id: '',
      client_code: '',
      name: '',
      sms_name: '',
      real_group: '',
      sales_staff_id: '',
      lead_source: '',
      lead_status: '',
      client_type: '',
      title_note: '',
      cnote_year: '',
      num_props: '',
      renewal_date: '',
      abn: '',
      acn: '',
      invoice_type: '',
      props_as_at: new Date(),
      phone_1: '',
      phone_2: '',
      trade_nm: '',
      status: '',
      client_status: '',
      renewal_day: '',
      renewal_month: '',
      Contact_details: [
        {
          id: '_' + Math.random().toString(36).substr(2, 9),
          position: '',
          title: '',
          primary: false,
          invoice: false,
          first: '',
          last: '',
          email: '',
          directline: '',
          mobile: '',
          fax: '',
        },
      ],
      primary_address: [
        {
          id: Math.floor(Math.random() * (1 + 100)) + 1,
          title: '',
          address_1: '',
          address_2: '',
          address_3: '',
          suburb: '',
          state: '',
          post_code: '',
          country: '',
          primary: false,
          postal: false,
        },
      ],
      contdropDown: false,
      ovdropDown: false,
    }

    this.comp = new CompaniesService()
    this.services = new Services()
    this.staff = new StaffService()
    this.editStaff = this.editStaff.bind(this)
    this.changeInput = this.changeInput.bind(this)
    this.changeAddress = this.changeAddress.bind(this)
    this.validator = new SimpleReactValidator()
  }

  SlideDownContact = () => {
    this.setState({
      contdropDown: !this.state.contdropDown,
    })
  }

  AccountOverview = () => {
    this.setState({
      ovdropDown: !this.state.ovdropDown,
    })
  }

  componentDidMount() {
    this.getSalesStaff()
    this.getGroupTypes()
  }

  getGroupTypes() {
    this.comp.getGroupTypes().then((res) => {
      if (res.status === 200) {
        const gType = []
        res.data.data.map((ele) => {
          gType.push({ value: ele.id, label: ele.name })
        })
        this.setState({ groupTypeOption: gType })
      } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
        this.setState({ accessErrorModal: true })
      } else {
        this.services.errorToast(res.message)
      }
    })
  }

  getSalesStaff() {
    const data = {}
    this.staff.getStaff(data).then((res) => {
      if (res.data) {
        const staff = []
        res.data.map((ele) => {
          staff.push({ value: ele.id, label: `${ele.first_name} ${ele.last_name}` })
        })
        this.setState({ salesStaffOption: staff })
      } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
        this.setState({ accessErrorModal: true })
      } else {
        this.services.errorToast(res.message)
      }
    })
  }

  getStaff(id) {
    const data = {
      client_ID: id,
    }
    this.services.getContactList(data, (res) => {
      if (res.status === 200) {
        const getData = res.data.data.list[0]
        let rday = ''
        let rmonth = ''
        if (getData.renewal_date.length > 2) {
          const d = getData.renewal_date.split('-')
          if (d.length === 2) {
            rmonth = optionsMonth.find((_) => _.value === d[0])
            rday = optionDay.find((_) => _.value === d[0])
          }
        }
        getData.address.map((addr) => {
          // addr.id="_" + Math.random().toString(36).substr(2, 9)
          return Object.assign(addr, { id: '_' + Math.random().toString(36).substr(2, 9) })
        })
        if (getData.contact_det) {
          getData.contact_det.map((addr) => {
            return Object.assign(addr, { id: '_' + Math.random().toString(36).substr(2, 9) })
          })
        }
        this.setState({
          client_code: getData.client_code,
          name: getData.name,
          sms_name: getData.sms_name,
          real_group: this.state.groupTypeOption.find((_) => _.value === getData.real_group),
          sales_staff_id: this.state.salesStaffOption.find(
            (_) => _.value === getData.sales_staff_id,
          ),
          lead_source: { value: getData.lead_source, label: getData.lead_source },
          lead_status: { value: getData.lead_status, label: getData.lead_status },
          client_type: clientTypeoption.find((_) => _.value === getData.client_type),
          title_note: getData.title_note,
          cnote_year: getData.cnote_year,
          num_props: getData.num_props,
          renewal_date: getData.renewal_date,
          renewal_day: rday,
          renewal_month: rmonth,
          abn: getData.abn,
          acn: getData.acn,
          invoice_type: { value: getData.invoice_type, label: getData.invoice_type },
          props_as_at:
            getData.props_as_at !== '' &&
            getData.props_as_at !== '0000-00-00' &&
            getData.props_as_at !== 'Invalid date'
              ? getData.props_as_at
              : new Date(),
          primary_address: getData.address || this.state.primary_address,
          Contact_details: getData.contact_det || this.state.Contact_details,
          phone_1: getData.phone_1,
          phone_2: getData.phone_2,
          trade_nm: getData.trade_nm,
          status: clientStatusOption.find((_) => _.value === getData.status),
          client_status: clientStatusOption.find((_) => _.value === getData.client_status),
        })
      } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
        this.setState({ accessErrorModal: true })
      } else {
        this.services.errorToast(res.message)
      }
    })
  }

  accessErrorModalClose = () => {
    this.setState({ accessErrorModal: false })
    this.props.history.push('/dashboard')
  }

  editStaff = () => {
    const {user} = this.context;
    
    let addContact = []
    let editContact = []
    if (this.state.Contact_details.length > 0) {
      const contact = this.state.Contact_details.map((val) => ({
        id: val.id,
        companies: [{ id: +this.state.id }],
        role: val.position,
        title: val.title,
        is_primary: val.primary,
        first_name: val.first,
        last_name: val.last,
        comm_email: val.email,
        comm_ph_mobile: val.mobile,
        comm_ph_direct: +val.directline,
        comm_ph_fax: +val.fax,
        is_invoice: true,
        middle_name: 'Dima',
        comm_ph_office: +this.state.phone_1,
        comm_ph_extension: +this.state.phone_2,
      }))

      addContact = contact
        .filter((val) => typeof val.id === 'string')
        .map((val) => ({
          ...val,
          staff_id_created: user.id,
          id: null,
        }))

      editContact = contact
        .filter((val) => typeof val.id === 'number')
        .map((val) => ({
          ...val,
          staff_id_modified: user.id,
        }))
    }

    const formData = {
      client_code: this.state.client_code,
      name: this.state.name,
      name_sms: this.state.sms_name,
      abn: this.state.abn,
      acn: this.state.acn,
      group:
        this.state.real_group && this.state.real_group.label ? this.state.real_group.label : '',
      am_email_pdf: 'google.com',
      am_email_invoice: 'google.com',
      sales_staff_id:
        this.state.sales_staff_id && this.state.sales_staff_id.value
          ? this.state.sales_staff_id.value
          : null,
      status: this.state.status && this.state.status.label ? this.state.status.label : '',
      website: 'google.com',
      bank_au_bsb: 0,
      bank_au_acc: 0,
      bank_nz: 'anton',
      bank_int_swift: 'anton',
      bank_int_ibn: 'anton',
      staff_id_created: user.id,

      address: this.state.primary_address,
      title_note: this.state.title_note,
      client_type:
        this.state.client_type && this.state.client_type.label ? this.state.client_type.label : '',
      trade_name: this.state.trade_nm,
      lead_source:
        this.state.lead_source && this.state.lead_source.value ? this.state.lead_source.value : '',
      lead_status:
        this.state.lead_status && this.state.lead_status.value ? this.state.lead_status.value : '',
      property_count: +this.state.num_props || '',
      property_count_date: moment(this.state.props_as_at || new Date()).format('DD-MM-YY'),
      client_status:
        this.state.client_status && this.state.client_status.value
          ? this.state.client_status.value
          : '',
      invoice_type:
        this.state.invoice_type && this.state.invoice_type.value
          ? this.state.invoice_type.value
          : '',
      renewal_date:
        this.state.renewal_month && this.state.renewal_month.value
          ? `${this.state.renewal_month.value}-${this.state.renewal_day.value}`
          : '',
      cover_note_year: +this.state.cnote_year,
    }

    if (this.validator.allValid()) {
      this.comp.addCompanies(formData).then((res) => {
        if (res.status === 201) {
          if (addContact.length > 0) {
            const cont = addContact.map((val) => ({ ...val, companies: [{ id: res.data.id }] }))
            this.comp.addCompaniesContacts(cont).then((res) => {
              if (res.status === 200) {
                this.services.successToast('Contacts added successfully')
              }
            })
          }
          this.services.successToast('Client updated successfully')
          this.props.history.push('/contact-list')
        } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
          this.setState({ accessErrorModal: true })
        } else {
          this.services.errorToast(res.message)
        }
      })
    } else {
      this.validator.showMessages()
      this.forceUpdate()
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }))
  }

  changeInput(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  uncheckAll = (id) => {
    this.state.primary_address.forEach((addr) => {
      if (addr.id !== id) {
        addr.postal = false
      }
    })
  }

  uncheckAllPrimary = (id) => {
    this.state.primary_address.forEach((addr) => {
      if (addr.id !== id) {
        addr.primary = false
      }
    })
  }

  uncheckAllInvoice = (id) => {
    this.state.Contact_details.forEach((addr) => {
      if (addr.id !== id) {
        addr.invoice = false
      }
    })
  }

  uncheckAllPrimaryContact = (id) => {
    this.state.Contact_details.forEach((addr) => {
      if (addr.id !== id) {
        addr.primary = false
      }
    })
  }

  changeAddress(e, id) {
    if (id) {
      const self = this
      const data = this.state.primary_address.map(function (item) {
        if (item.id === id && e.target.name) {
          if (e.target.name === 'postal' || e.target.name === 'primary') {
            if (e.target.name === 'postal') {
              item.postal = e.target.checked
              self.uncheckAll(id)
            }
            if (e.target.name === 'primary') {
              item.primary = e.target.checked
              self.uncheckAllPrimary(id)
            }
          } else {
            item[e.target.name] = e.target.value
          }
        }
        return item
      })
      if (e && e.target && e.target.name && id) this.setState({ primary_address: data })
    }
  }

  changeContact(e, id) {
    if (id) {
      const self = this
      const data = this.state.Contact_details.map(function (item) {
        if (item.id === id && e.label && e.value) {
          item.title = e.value
        } else {
          if (item.id === id && e.target.name) {
            if (e.target.name === 'primary' || e.target.name === 'invoice') {
              if (e.target.name === 'invoice') {
                item.invoice = e.target.checked
                self.uncheckAllInvoice(id)
              }
              if (e.target.name === 'primary') {
                item.primary = e.target.checked
                self.uncheckAllPrimaryContact(id)
              }
            } else {
              item[e.target.name] = e.target.value
            }
          }
        }
        return item
      })
      if ((e && e.target && e.target.name && id) || (e.label && e.value))
        this.setState({ Contact_details: data })
    }
  }

  handleChange = (selectedOption, name) => {
    this.setState({ [name]: selectedOption })
  }

  cancle = () => {
    this.props.history.push('/contact-list')
  }

  addContatct = () => {
    const data = {
      id: '_' + Math.random().toString(36).substr(2, 9),
      position: '',
      title: '',
      primary: false,
      invoice: false,
      first: '',
      last: '',
      email: '',
      directline: '',
      mobile: '',
      fax: '',
    }
    const details = this.state.Contact_details
    details.push(data)
    this.setState({
      Contact_details: details,
    })
  }

  addAddress = () => {
    const data = {
      id: Math.floor(Math.random() * (1 + 100)) + 1,
      title: '',
      address_1: '',
      address_2: '',
      address_3: '',
      suburb: '',
      state: '',
      postal_code: '',
      country: '',
      primary: false,
      postal: false,
    }

    const details = this.state.primary_address
    details.push(data)
    this.setState({
      primary_address: details,
    })
  }

  removeAddress = (id) => {
    const details = this.state.primary_address.filter((_) => _.id !== id)
    this.setState({
      primary_address: details,
    })
  }

  removeContact = (id) => {
    const details = this.state.Contact_details.filter((_) => _.id !== id)
    this.setState({
      Contact_details: details,
    })
  }

  handleChangeDate = (date) => {
    this.setState({
      props_as_at: date,
    })
  }

  handleSelectGeoSuggest = (place, id) => {
    if (!place) return null

    const componentForm = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      administrative_area_level_1: 'long_name',
      country: 'long_name',
      postal_code: 'short_name',
    }
    for (let i = 0; i < place.gmaps.address_components.length; i++) {
      const addressType = place.gmaps.address_components[i].types[0]
      if (componentForm[addressType]) {
        const val = place.gmaps.address_components[i][componentForm[addressType]]
        if (val) componentForm[addressType] = val
      }
    }
    const addList = this.state.primary_address.map((addr) => {
      if (addr.id === id) {
        addr.address_1 = place.label
        addr.address_2 =
          componentForm.street_number !== 'short_name' ? componentForm.street_number : ''
        addr.address_3 = componentForm.route !== 'long_name' ? componentForm.route : ''
        addr.suburb = componentForm.locality !== 'long_name' ? componentForm.locality : ''
        addr.state =
          componentForm.administrative_area_level_1 !== 'long_name'
            ? componentForm.administrative_area_level_1
            : ''
        addr.country = componentForm.country !== 'long_name' ? componentForm.country : ''
        addr.post_code = componentForm.postal_code !== 'short_name' ? componentForm.postal_code : ''
      }

      return addr
    })
    this.setState({
      primary_address: addList,
    })
  }

  getDateFormate() {
    return moment(this.state.props_as_at || new Date()).format('DD-MM-YY')
    if (this.state.props_as_at) {
      const date = moment(this.state.props_as_at || new Date()).format('DD-MM-YY')
      if (date === 'Invalid date') {
        return new Date(
          this.state.props_as_at.slice(0, this.state.props_as_at.length - 2).replace(/\//g, '-'),
        )
      } else {
        return new Date(date)
      }
    } else {
      return new Date()
    }
  }

  render() {
    const { client_code, phone_1, phone_2 } = this.state
    return (
      <>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="w-100">
                <div id="contacts_accordion" className="form">
                  <div className="card mb-4">
                    <h3 className="page-title">Add Client</h3>
                    <div
                      id="collapseOne"
                      className="collapse show mb-4"
                      data-parent="#contacts_accordion"
                      style={{ background: '#fff' }}
                    >
                      <div className="card-body">
                        <div className="row">
                          <div className="col-12 col-md-3">
                            <div className="form-group">
                              <label className="form__form-group-label" htmlFor="">
                                Client Code
                              </label>
                              <input
                                type="text"
                                className="custom-input"
                                id="old_ten_id"
                                value={client_code}
                                name="client_code"
                                onChange={this.changeInput}
                              />
                              <span className="form__form-group-error">
                                {this.validator.message('client code', client_code, 'required')}
                              </span>
                            </div>
                          </div>
                          <div className="col-12 col-md-3">
                            <div className="form-group">
                              <label className="form__form-group-label" htmlFor="">
                                Client Name <span style={{ color: '#ff0000' }}>*</span>
                              </label>
                              <input
                                type="text"
                                className="custom-input"
                                id="old_ten_id"
                                value={this.state.name}
                                name="name"
                                onChange={this.changeInput}
                              />
                              <span className="form__form-group-error">
                                {this.validator.message('Client Name', this.state.name, 'required')}
                              </span>
                            </div>
                          </div>
                          <div className="col-12 col-md-3">
                            <div className="form-group">
                              <label className="form__form-group-label" htmlFor="">
                                Client Type<span style={{ color: '#ff0000' }}>*</span>
                              </label>
                              <Select
                                name="client_type"
                                type="text"
                                options={clientTypeoption}
                                classNamePrefix="react-select"
                                className="react-select"
                                value={this.state.client_type}
                                onChange={(e) => this.handleChange(e, 'client_type')}
                              />
                              <span className="form__form-group-error">
                                {this.validator.message(
                                  'Client Type',
                                  this.state.client_type,
                                  'required',
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="col-12 col-md-3">
                            <div className="form-group">
                              <label className="form__form-group-label" htmlFor="">
                                Group<span style={{ color: '#ff0000' }}>*</span>
                              </label>
                              <Select
                                name="real_group"
                                type="text"
                                options={this.state.groupTypeOption}
                                classNamePrefix="react-select"
                                className="react-select"
                                value={this.state.real_group}
                                onChange={(e) => this.handleChange(e, 'real_group')}
                              />
                              <span className="form__form-group-error">
                                {this.validator.message('Group', this.state.real_group, 'required')}
                              </span>
                            </div>
                          </div>
                          {/* <div className="col-12 col-md-3">
                                          <div className="form-group">
                                            <label className="form__form-group-label" for="">Name</label>
                                            <input type="text" className="custom-input" disabled
                                              id="name" value={name} name="name"
                                              onChange={this.changeInput} />
                                          </div>
                                        </div> */}
                        </div>

                        <div className="row">
                          <div className="col-12 col-md-3">
                            <div className="form-group">
                              <label className="form__form-group-label" htmlFor="">
                                Title Note<span style={{ color: '#ff0000' }}>*</span>
                              </label>
                              <input
                                type="text"
                                className="custom-input"
                                id="title_note"
                                value={this.state.title_note}
                                name="title_note"
                                onChange={this.changeInput}
                              />
                              <span className="form__form-group-error">
                                {this.validator.message(
                                  'Title Note',
                                  this.state.title_note,
                                  'required',
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="col-12 col-md-3">
                            <div className="form-group">
                              <label className="form__form-group-label" htmlFor="">
                                SMS Name<span style={{ color: '#ff0000' }}>*</span>
                              </label>
                              <input
                                type="text"
                                className="custom-input"
                                id="sms_name"
                                value={this.state.sms_name}
                                name="sms_name"
                                onChange={this.changeInput}
                              />
                              <span className="form__form-group-error">
                                {this.validator.message(
                                  'SMS Name',
                                  this.state.sms_name,
                                  'required',
                                )}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 col-md-3">
                            <div className="form-group">
                              <label className="form__form-group-label" htmlFor="">
                                Phone Number<span style={{ color: '#ff0000' }}>*</span>
                              </label>
                              <input
                                type="number"
                                className="custom-input"
                                id="phone"
                                name="phone_1"
                                value={phone_1}
                                onChange={this.changeInput}
                              />
                              <span className="form__form-group-error">
                                {this.validator.message('Phone Number', phone_1, 'required')}
                              </span>
                            </div>
                          </div>
                          <div className="col-12 col-md-3">
                            <div className="form-group">
                              <label className="form__form-group-label" htmlFor="">
                                Phone Number<span style={{ color: '#ff0000' }}>*</span>
                              </label>
                              <input
                                type="number"
                                className="custom-input"
                                id="phone_2"
                                name="phone_2"
                                value={phone_2}
                                onChange={this.changeInput}
                              />
                              <span className="form__form-group-error">
                                {this.validator.message('Phone Number', phone_2, 'required')}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-3">
                            <div className="form-group">
                              <label className="form__form-group-label" htmlFor="">
                                Trading Name<span style={{ color: '#ff0000' }}>*</span>
                              </label>
                              <input
                                type="text"
                                className="custom-input"
                                id="trade_nm"
                                name="trade_nm"
                                value={this.state.trade_nm}
                                onChange={this.changeInput}
                              />
                              <span className="form__form-group-error">
                                {this.validator.message(
                                  'Trading Name',
                                  this.state.trade_nm,
                                  'required',
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="col-12 col-md-3">
                            <div className="form-group">
                              <label className="form__form-group-label" htmlFor="">
                                ACN<span style={{ color: '#ff0000' }}>*</span>
                              </label>
                              <input
                                type="text"
                                className="custom-input"
                                id="acn"
                                name="acn"
                                value={this.state.acn}
                                onChange={this.changeInput}
                              />
                              <span className="form__form-group-error">
                                {this.validator.message('ACN', this.state.acn, 'required')}
                              </span>
                            </div>
                          </div>
                          <div className="col-12 col-md-3">
                            <div className="form-group">
                              <label className="form__form-group-label" htmlFor="">
                                ABN<span style={{ color: '#ff0000' }}>*</span>
                              </label>
                              <input
                                type="text"
                                className="custom-input"
                                id="abn"
                                name="abn"
                                value={this.state.abn}
                                onChange={this.changeInput}
                              />
                              <span className="form__form-group-error">
                                {this.validator.message('ABN', this.state.abn, 'required')}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-3">
                            <div className="form-group">
                              <label className="form__form-group-label" htmlFor="">
                                Client Status<span style={{ color: '#ff0000' }}>*</span>
                              </label>
                              <Select
                                name="status"
                                type="text"
                                options={clientStatusOption}
                                classNamePrefix="react-select"
                                className="react-select"
                                value={this.state.status}
                                onChange={(e) => this.handleChange(e, 'status')}
                              />
                              <span className="form__form-group-error">
                                {this.validator.message(
                                  'Client Status',
                                  this.state.status,
                                  'required',
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="col-12 col-md-3">
                            <div className="form-group">
                              <label className="form__form-group-label" htmlFor="">
                                Lead Source
                              </label>
                              <Select
                                name="lead_source"
                                type="text"
                                options={[
                                  {
                                    value: 'Client Referral',
                                    label: 'Client Referral',
                                  },
                                  { value: 'Telephone', label: 'Telephone' },
                                  {
                                    value: 'Landlord Referral',
                                    label: 'Landlord Referral',
                                  },
                                  {
                                    value: 'Internet Search',
                                    label: 'Internet Search',
                                  },
                                  {
                                    value: 'Online Marketing',
                                    label: 'Online Marketing',
                                  },
                                  {
                                    value: 'Direct Marketing',
                                    label: 'Direct Marketing',
                                  },
                                  { value: 'CCB', label: 'CCB' },
                                  { value: 'Conference', label: 'Conference' },
                                ]}
                                classNamePrefix="react-select"
                                className="react-select"
                                value={this.state.lead_source}
                                onChange={(e) => this.handleChange(e, 'lead_source')}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-3">
                            <div className="form-group">
                              <label className="form__form-group-label" htmlFor="">
                                Lead Status<span style={{ color: '#ff0000' }}>*</span>
                              </label>
                              <Select
                                name="lead_status"
                                type="text"
                                options={[
                                  { value: 'Hot', label: 'Hot' },
                                  { value: 'Warm', label: 'Warm' },
                                  { value: 'Cold', label: 'Cold' },
                                  { value: 'Lost', label: 'Lost' },
                                  { value: 'Converted', label: 'Converted' },
                                ]}
                                classNamePrefix="react-select"
                                className="react-select"
                                value={this.state.lead_status}
                                onChange={(e) => this.handleChange(e, 'lead_status')}
                              />
                              <span className="form__form-group-error">
                                {this.validator.message(
                                  'lead status',
                                  this.state.lead_status,
                                  'required',
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div className="mb-4 pl-0">
                          <h5 className="bold-text"> Primary Address </h5>
                        </div>
                        {this.state.primary_address.map((c, i) => {
                          return (
                            <>
                              <div className="row">
                                <div className="col-12 col-md-3">
                                  <div className="form-group">
                                    <label className="form__form-group-label" htmlFor="">
                                      Title
                                    </label>
                                    <input
                                      type="text"
                                      className="custom-input"
                                      name="title"
                                      value={c.title}
                                      onChange={(e) => this.changeAddress(e, c.id)}
                                    />
                                  </div>
                                </div>
                                <div className="col-12 col-md-3">
                                  <div className="form-group">
                                    <div className="custom-control custom-switch mt-4 pt-2">
                                      <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id={c.id + i}
                                        name="primary"
                                        checked={c.primary}
                                        onChange={(e) => this.changeAddress(e, c.id)}
                                      />
                                      <label
                                        className="form__form-group-label"
                                        className="custom-control-label"
                                        htmlFor={c.id + i}
                                      >
                                        {'Same as Primary Address'}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12 col-md-9">
                                  <div className="form-group">
                                    <label className="form__form-group-label" htmlFor="">
                                      Search Address Here
                                    </label>
                                    {/* <input
                                type="text"
                                className="custom-input"
                                id="address_1"
                                name="address_1"
                                value={c.address_1}
                                onChange={(e) => this.changeAddress(e, c.id)}
                              /> */}
                                    <Geosuggest
                                      ref={(el) => (this._geoSuggest = el)}
                                      onSuggestSelect={(e) => this.handleSelectGeoSuggest(e, c.id)}
                                      initialValue={c.address_1}
                                      country={['AU', 'NZ']}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12 col-md-9">
                                  <div className="form-group">
                                    <label className="form__form-group-label" htmlFor="">
                                      Address Line 2
                                    </label>
                                    <input
                                      type="text"
                                      className="custom-input"
                                      id="address_1"
                                      name="address_2"
                                      value={c.address_2}
                                      onChange={(e) => this.changeAddress(e, c.id)}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12 col-md-9">
                                  <div className="form-group">
                                    <label className="form__form-group-label" htmlFor="">
                                      Address Line 3
                                    </label>
                                    <input
                                      type="text"
                                      className="custom-input"
                                      id="address_3"
                                      name="address_3"
                                      value={c.address_3}
                                      onChange={(e) => this.changeAddress(e, c.id)}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12 col-md-3">
                                  <div className="form-group">
                                    <label className="form__form-group-label" htmlFor="">
                                      Suburb
                                    </label>
                                    <input
                                      type="text"
                                      className="custom-input"
                                      id="suburb"
                                      name="suburb"
                                      value={c.suburb}
                                      onChange={(e) => this.changeAddress(e, c.id)}
                                    />
                                  </div>
                                </div>
                                <div className="col-12 col-md-3">
                                  <div className="form-group">
                                    <label className="form__form-group-label" htmlFor="">
                                      State
                                    </label>
                                    <input
                                      type="text"
                                      className="custom-input"
                                      id="state"
                                      name="state"
                                      value={c.state}
                                      onChange={(e) => this.changeAddress(e, c.id)}
                                    />
                                  </div>
                                </div>
                                <div className="col-12 col-md-3">
                                  <div className="form-group">
                                    <label className="form__form-group-label" htmlFor="">
                                      Post Code
                                    </label>
                                    <input
                                      type="number"
                                      className="custom-input"
                                      id="post_code"
                                      name="post_code"
                                      value={c.post_code}
                                      onChange={(e) => this.changeAddress(e, c.id)}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12 col-md-3">
                                  <div className="form-group">
                                    <label className="form__form-group-label" htmlFor="">
                                      Country
                                    </label>
                                    <input
                                      type="text"
                                      className="custom-input"
                                      id="country"
                                      name="country"
                                      value={c.country}
                                      onChange={(e) => this.changeAddress(e, c.id)}
                                    />
                                  </div>
                                </div>

                                <div className="col-12 col-md-3">
                                  <div className="form-group">
                                    <div className="custom-control custom-switch mt-4 pt-2">
                                      <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id={c.id}
                                        name="postal"
                                        checked={c.postal}
                                        onChange={(e) => this.changeAddress(e, c.id)}
                                      />
                                      <label
                                        className="form__form-group-label"
                                        className="custom-control-label"
                                        htmlFor={c.id}
                                      >
                                        {'Use This as Postal Address'}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12 col-md-1">
                                  <div className="form-group">
                                    {i < this.state.primary_address.length - 1 && (
                                      <div className="form-group top-24">
                                        <button
                                          className="btn-gray"
                                          onClick={this.removeAddress.bind(this, c.id)}
                                        >
                                          Remove
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="col-12 col-md-2">
                                  <div className="form-group">
                                    {i === this.state.primary_address.length - 1 && (
                                      <div className="form-group top-24">
                                        <button className="btn-gray" onClick={this.addAddress}>
                                          Add Address
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              {i < this.state.primary_address.length - 1 && (
                                <div className="card-header mb-4 pl-0">
                                  <hr />
                                </div>
                              )}
                            </>
                          )
                        })}
                      </div>
                    </div>
                    <div className="slide_down_wrap mb-4 rounded">
                      <div className="card-body bg-white pl-0 rounded">
                        {/* <hr /> */}
                        <div
                          className={`boxshadow-none slide_drop_down p-0 cursor-pointer d-flex flex-wrap align-items-center justify-content-between ${
                            this.state.contdropDown ? 'active' : ''
                          }`}
                          onClick={this.SlideDownContact}
                        >
                          <h3 style={{ color: 'black' }}>Contact Details</h3>

                          <span className="show_dropdown">
                            <svg
                              className="ml-2"
                              version="1.1"
                              id="Capa_1"
                              x="0px"
                              y="0px"
                              width="14px"
                              height="14px"
                              viewBox="0 0 284.929 284.929"
                            >
                              <g>
                                <path
                                  d="M282.082,76.511l-14.274-14.273c-1.902-1.906-4.093-2.856-6.57-2.856c-2.471,0-4.661,0.95-6.563,2.856L142.466,174.441
		L30.262,62.241c-1.903-1.906-4.093-2.856-6.567-2.856c-2.475,0-4.665,0.95-6.567,2.856L2.856,76.515C0.95,78.417,0,80.607,0,83.082
		c0,2.473,0.953,4.663,2.856,6.565l133.043,133.046c1.902,1.903,4.093,2.854,6.567,2.854s4.661-0.951,6.562-2.854L282.082,89.647
		c1.902-1.903,2.847-4.093,2.847-6.565C284.929,80.607,283.984,78.417,282.082,76.511z"
                                />
                              </g>{' '}
                              <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g>{' '}
                              <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g>{' '}
                              <g> </g>{' '}
                            </svg>
                          </span>
                        </div>
                        <div
                          className={`bg-white hide_contact pt-4  ${
                            this.state.contdropDown ? 'active py-2' : ''
                          }`}
                        >
                          {this.state.Contact_details.map((c, i) => {
                            return (
                              <>
                                <div className="row">
                                  <div className="col-12 col-md-4 col-lg-3">
                                    <div className="form-group">
                                      <label className="form__form-group-label" htmlFor="">
                                        Position<span style={{ color: '#ff0000' }}>*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="custom-input"
                                        name="position"
                                        value={c.position}
                                        onChange={(e) => this.changeContact(e, c.id)}
                                      />
                                      <span className="form__form-group-error">
                                        {this.validator.message('Position', c.position, 'required')}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-4 col-lg-3">
                                    <div className="form-group">
                                      <label className="form__form-group-label" htmlFor="">
                                        Title<span style={{ color: '#ff0000' }}>*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="custom-input"
                                        name="title"
                                        value={c.title}
                                        onChange={(e) => this.changeContact(e, c.id)}
                                      />
                                      <span className="form__form-group-error">
                                        {this.validator.message('Title', c.title, 'required')}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-4 col-lg-3">
                                    <div className="form-group">
                                      <div className="custom-control custom-switch mt-4 pt-3">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id={c.id}
                                          name="primary"
                                          checked={c.primary}
                                          onChange={(e) => this.changeContact(e, c.id)}
                                        />
                                        <label
                                          className="form__form-group-label"
                                          className="custom-control-label"
                                          htmlFor={c.id}
                                        >
                                          {'Primary Contact'}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-4 col-lg-3">
                                    <div className="form-group">
                                      <div className="custom-control custom-switch mt-4 pt-3">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id={c.id + i}
                                          name="invoice"
                                          checked={c.invoice}
                                          onChange={(e) => this.changeContact(e, c.id)}
                                        />
                                        <label
                                          className="form__form-group-label"
                                          className="custom-control-label"
                                          htmlFor={c.id + i}
                                        >
                                          {'Invoice Contact'}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12 col-md-3">
                                    <div className="form-group">
                                      <label className="form__form-group-label" htmlFor="">
                                        First Name<span style={{ color: '#ff0000' }}>*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="custom-input"
                                        id="first"
                                        name="first"
                                        value={c.first}
                                        onChange={(e) => this.changeContact(e, c.id)}
                                      />
                                      <span className="form__form-group-error">
                                        {this.validator.message('First Name', c.first, 'required')}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-3">
                                    <div className="form-group">
                                      <label className="form__form-group-label" htmlFor="">
                                        Last Name<span style={{ color: '#ff0000' }}>*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="custom-input"
                                        id="last"
                                        name="last"
                                        value={c.last}
                                        onChange={(e) => this.changeContact(e, c.id)}
                                      />
                                      <span className="form__form-group-error">
                                        {this.validator.message('Last Name', c.last, 'required')}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-3">
                                    <div className="form-group">
                                      <label className="form__form-group-label" htmlFor="">
                                        Email<span style={{ color: '#ff0000' }}>*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="custom-input"
                                        id="email"
                                        name="email"
                                        value={c.email}
                                        onChange={(e) => this.changeContact(e, c.id)}
                                      />
                                      <span className="form__form-group-error">
                                        {this.validator.message('Email', c.email, 'required')}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12 col-md-3">
                                    <div className="form-group">
                                      <label className="form__form-group-label" htmlFor="">
                                        Direct Line<span style={{ color: '#ff0000' }}>*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="custom-input"
                                        id="dLine"
                                        name="directline"
                                        value={c.directline}
                                        onChange={(e) => this.changeContact(e, c.id)}
                                      />
                                      <span className="form__form-group-error">
                                        {this.validator.message(
                                          'Direct Line',
                                          c.directline,
                                          'required',
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-3">
                                    <div className="form-group">
                                      <label className="form__form-group-label" htmlFor="">
                                        Mobile<span style={{ color: '#ff0000' }}>*</span>
                                      </label>
                                      <input
                                        type="number"
                                        className="custom-input"
                                        id="cMobile"
                                        name="mobile"
                                        value={c.mobile}
                                        onChange={(e) => this.changeContact(e, c.id)}
                                      />
                                      <span className="form__form-group-error">
                                        {this.validator.message('Mobile', c.mobile, 'required')}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-3">
                                    <div className="form-group">
                                      <label className="form__form-group-label" htmlFor="">
                                        Fax<span style={{ color: '#ff0000' }}>*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="custom-input"
                                        id="cFax"
                                        name="fax"
                                        value={c.fax}
                                        onChange={(e) => this.changeContact(e, c.id)}
                                      />
                                      <span className="form__form-group-error">
                                        {this.validator.message('Fax', c.fax, 'required')}
                                      </span>
                                    </div>
                                  </div>
                                  {i < this.state.Contact_details.length - 1 && (
                                    <div className="col-12 col-md-1">
                                      <div className="form-group">
                                        <div className="form-group top-24">
                                          <button
                                            className="btn-gray"
                                            onClick={this.removeContact.bind(this, c.id)}
                                          >
                                            Remove
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  <div className="col-12 col-md-2">
                                    <div className="form-group">
                                      {i === this.state.Contact_details.length - 1 && (
                                        <div className="form-group top-24">
                                          <button className="btn-gray" onClick={this.addContatct}>
                                            Add Contact
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                {i < this.state.Contact_details.length - 1 && (
                                  <div className="card-header mb-4 pl-0">
                                    <hr />
                                  </div>
                                )}
                              </>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="slide_down_wrap  mb-4 rounded">
                      <div className="card-body bg-white pl-0 rounded">
                        {/* <hr /> */}
                        <div
                          className={`boxshadow-none slide_drop_down p-0 cursor-pointer d-flex flex-wrap align-items-center justify-content-between ${
                            this.state.ovdropDown ? 'active' : ''
                          }`}
                          onClick={this.AccountOverview}
                        >
                          <h3 style={{ color: 'black' }}>Account Overview</h3>
                          <span className="show_dropdown">
                            <svg
                              className="ml-2"
                              version="1.1"
                              id="Capa_1"
                              x="0px"
                              y="0px"
                              width="14px"
                              height="14px"
                              viewBox="0 0 284.929 284.929"
                            >
                              <g>
                                <path
                                  d="M282.082,76.511l-14.274-14.273c-1.902-1.906-4.093-2.856-6.57-2.856c-2.471,0-4.661,0.95-6.563,2.856L142.466,174.441
                              L30.262,62.241c-1.903-1.906-4.093-2.856-6.567-2.856c-2.475,0-4.665,0.95-6.567,2.856L2.856,76.515C0.95,78.417,0,80.607,0,83.082
                              c0,2.473,0.953,4.663,2.856,6.565l133.043,133.046c1.902,1.903,4.093,2.854,6.567,2.854s4.661-0.951,6.562-2.854L282.082,89.647
                              c1.902-1.903,2.847-4.093,2.847-6.565C284.929,80.607,283.984,78.417,282.082,76.511z"
                                />
                              </g>{' '}
                              <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g>{' '}
                              <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g>{' '}
                              <g> </g>{' '}
                            </svg>
                          </span>
                        </div>
                        <div
                          className={`bg-white hide_contact pt-4 ${
                            this.state.ovdropDown ? 'active py-2' : ''
                          }`}
                        >
                          <div className="w-100" id="client_overview_tab">
                            <div className="outer_block_div">
                              <div className="row">
                                <div className="col-12 col-md-3">
                                  <div className="form-group">
                                    <label className="form__form-group-label" htmlFor="">
                                      Number Of Properties
                                      <span style={{ color: '#ff0000' }}>*</span>
                                    </label>
                                    <input
                                      type="number"
                                      className="custom-input"
                                      id="num_props"
                                      name="num_props"
                                      value={this.state.num_props}
                                      onChange={this.changeInput}
                                    />
                                    <span className="form__form-group-error">
                                      {this.validator.message(
                                        'Number of properties',
                                        this.state.num_props,
                                        'required',
                                      )}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-12 col-md-3">
                                  <div className="form-group">
                                    <label className="form__form-group-label" htmlFor="">
                                      As at Date
                                    </label>
                                    <div className="date-picker date-picker--interval">
                                      <DatePicker
                                        selected={new Date()}
                                        onChange={(e) => this.handleChangeDate(e)}
                                        name="date"
                                        dateFormat="dd/MM/yy"
                                        dropDownMode="select"
                                        popperPlacement="center"
                                        placeholderText="Select Date"
                                      />
                                      <span className="date_icon">
                                        <img src={'./image/calendar.svg'} alt="date" />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12 col-md-3">
                                  <div className="form-group">
                                    <label className="form__form-group-label" htmlFor="">
                                      Contact Status<span style={{ color: '#ff0000' }}>*</span>
                                    </label>
                                    <Select
                                      name="client_status"
                                      type="text"
                                      options={clientStatusOption}
                                      classNamePrefix="react-select"
                                      className="react-select"
                                      value={this.state.client_status}
                                      onChange={(e) => this.handleChange(e, 'client_status')}
                                    />
                                    <span className="form__form-group-error">
                                      {this.validator.message(
                                        'Contact Status',
                                        this.state.client_status,
                                        'required',
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-12 col-md-3">
                                  <div className="form-group">
                                    <label className="form__form-group-label" htmlFor="">
                                      Sales Staff<span style={{ color: '#ff0000' }}>*</span>
                                    </label>
                                    <Select
                                      name="sales_staff_id"
                                      type="text"
                                      options={this.state.salesStaffOption}
                                      classNamePrefix="react-select"
                                      className="react-select"
                                      value={this.state.sales_staff_id}
                                      onChange={(e) => this.handleChange(e, 'sales_staff_id')}
                                    />
                                    <span className="form__form-group-error">
                                      {this.validator.message(
                                        'Sales staff',
                                        this.state.sales_staff_id,
                                        'required',
                                      )}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-12 col-md-3">
                                  <div className="form-group">
                                    <label className="form__form-group-label" htmlFor="">
                                      Invoice Type
                                    </label>
                                    <Select
                                      name="invoice_type"
                                      type="text"
                                      options={[
                                        { value: 'Upload', label: 'Upload' },
                                        { value: 'PDF', label: 'PDF' },
                                        { value: 'Hard Copy', label: 'Hard Copy' },
                                        { value: 'Both/Mixed', label: 'Both/Mixed' },
                                      ]}
                                      classNamePrefix="react-select"
                                      className="react-select"
                                      value={this.state.invoice_type}
                                      onChange={(e) => this.handleChange(e, 'invoice_type')}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-12 col-md-3">
                                  <label className="form__form-group-label" htmlFor="">
                                    Renewal Date
                                  </label>
                                  <div className="row">
                                    <div className="col-12 col-sm-6">
                                      <div className="form-group">
                                        <Select
                                          name="renewal_month"
                                          type="text"
                                          options={optionsMonth}
                                          classNamePrefix="react-select"
                                          className="react-select"
                                          value={this.state.renewal_month}
                                          onChange={(e) => this.handleChange(e, 'renewal_month')}
                                        />
                                        <label className="form__form-group-label" htmlFor="">
                                          MM
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                      <div className="form-group">
                                        <Select
                                          name="renewal_day"
                                          type="text"
                                          options={optionsMonth}
                                          classNamePrefix="react-select"
                                          className="react-select"
                                          value={this.state.renewal_day}
                                          onChange={(e) => this.handleChange(e, 'renewal_day')}
                                        />
                                        <label className="form__form-group-label" htmlFor="">
                                          DD
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12 col-md-3">
                                  <div className="form-group">
                                    <label className="form__form-group-label" htmlFor="">
                                      Cover Note Year
                                    </label>
                                    <input
                                      type="number"
                                      className="custom-input"
                                      id="cnote_year"
                                      name="cnote_year"
                                      value={this.state.cnote_year}
                                      onChange={this.changeInput}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-100 d-flex justify-content-end p-4">
                      <Button className="btn-group--icons mb-0" onClick={this.editStaff}>
                        Save Changes
                      </Button>
                      <button className="btn-gray" type="button" onClick={this.cancle}>
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
                {this.state.errorModal ? (
                  <ModalComponent
                    modal={this.state.errorModal}
                    color={this.state.errorType}
                    isCancel={true}
                    message={this.state.errorMessage}
                    onConfirm={(e) => this.DeleteStaff(e)}
                    onClose={() => this.setState({ errorModal: false })}
                  />
                ) : null}
                {this.state.accessErrorModal ? (
                  <ModalComponent
                    modal={this.state.accessErrorModal}
                    color="danger"
                    isCancel={false}
                    message="You do not have access to this, please contact your manager for assistance"
                    onConfirm={(e) => this.accessErrorModalClose(e)}
                    onClose={(e) => this.accessErrorModalClose(e)}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default reduxForm({
  form: 'add_staff_form_validation',
})(withRouter(withTranslation('common')(Contatcts)))
