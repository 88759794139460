export const formInputStyles = {
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '15px',
  },

  label: {
    minWidth: '165px',
    color: '#979ca7',
    marginBottom: '4px',
    display: 'inline-block',
    fontSize: '16px',
    lineHeight: '21px',
    fontWeight: '400',
  },

  input: {
    fontSize: '16px',
    lineHeight: '16.8px',
    fontWeight: 400,
    color: '#535F77',
    padding: '5px 10px',
    outline: 'none',
    border: '1px solid silver',
    borderRadius: '8px',

    ':focus': {
      border: '1px solid #fd7e14',
    },
  },
};
