import axios from "axios";
import { DebtorLegalPlaintiff } from "../types/debtor-legal.type";
import { REACT_APP_API_URL } from "@src/lib/global-variables";
import { CreateDebtorLegalPlaintiffData } from "../types/debtor-plaintiff.type";
import { Params } from "@src/types/service.type";
import { DeepPartial } from "react-hook-form";

export class DebtorLegalPlaintiffService {

  async createDebtorLegalPlaintiff(data: CreateDebtorLegalPlaintiffData, params?: Params): Promise<DebtorLegalPlaintiff> {
    const res = await axios.post<DebtorLegalPlaintiff>(`${REACT_APP_API_URL}/debtor-legals/plaintiffs`, data, { params });
    return res.data;
  }

  async updateDebtorLegalPlaintiff(debtorLegalPlaintiffId: number, data: DeepPartial<DebtorLegalPlaintiff>, params?: Params): Promise<DebtorLegalPlaintiff> {
    const res = await axios.patch<DebtorLegalPlaintiff>(`${REACT_APP_API_URL}/debtor-legals/plaintiffs/${debtorLegalPlaintiffId}`, data, { params });
    return res.data;
  }

  async deleteDebtorLegalPlaintiff(debtorLegalPlaintiffId: number, params?: Params): Promise<DebtorLegalPlaintiff> {
    const res = await axios.delete<DebtorLegalPlaintiff>(`${REACT_APP_API_URL}/debtor-legals/plaintiffs/${debtorLegalPlaintiffId}`, { params });
    return res.data;
  }
}