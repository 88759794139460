import { FC, useEffect, useRef, useState } from "react";

interface IFrameWrapperProps { 
  html: string;
  className?: string;
}

export const FrameWrapper:FC<IFrameWrapperProps> = ({ html }) => {
  const ref = useRef<HTMLIFrameElement>(null);
  const [height, setHeight] = useState('600px');

  const onLoad = () => {
    const height = (ref.current?.contentWindow?.document.body.scrollHeight || 600) + 20 ;
    setHeight(height + 'px');
  };
  
  useEffect(() => {
    onLoad();
  }, []);

  return (
    <iframe
      ref={ref}
      onLoad={onLoad}
      id='iframe'
      srcDoc={html}
      style={{ minHeight: height, height: height, border: 'none', width: '100%'}}
      scrolling="no"
    ></iframe>
  );
};