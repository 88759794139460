import { useMutation } from "@tanstack/react-query"
import { CreateDebtorWorkPlaceData, DebtorWorkPlace } from "../../types/debtor-associate-detail.type"
import { DebtorWorkPlaceService } from "../../services/debtor-work-place.service"
import { DeepPartial } from "react-hook-form"

export const useCreateDebtorWorkPlaceMutation = () => {

  const createDebtorWorkPlace = (data: CreateDebtorWorkPlaceData) => {
    const debtorWorkPlaceService = new DebtorWorkPlaceService()
    return debtorWorkPlaceService.createDebtorWorkPlace(data)
  }

  const mutation = useMutation(createDebtorWorkPlace)

  return mutation
}

export const useUpdateDebtorWorkPlaceMutation = () => {

  const updateDebtorWorkPlace = (id: number, data: DeepPartial<DebtorWorkPlace>) => {
    const debtorRelationService = new DebtorWorkPlaceService()
    return debtorRelationService.updateDebtorWorkPlace(id, data)
  }

  const mutation = useMutation({ mutationFn: ({ id, data }: { id: number, data: DeepPartial<DebtorWorkPlace> }) => updateDebtorWorkPlace(id, data) })

  return mutation;
}