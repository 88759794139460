import FormInput from '@components/inputs/form-input/form-input.component'
import FormTextArea from '@components/inputs/form-textarea/form-textarea.component'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
  DebtorLegalPlaintiffFormProps,
  PlaintiffFormValues,
} from '../../../types/debtor-plaintiff.type'
import styles from './debtor-legal-plaintiff-form.module.scss'
import { joiResolver } from '@hookform/resolvers/joi'
import { debtorLegalPlaintiffSchema } from '../../../schemas/legal.schemas'

const DebtorLegalPlaintiffForm: FC<DebtorLegalPlaintiffFormProps> = ({
  submitButtonTitle = 'Submit',
  onSuccessfulSubmit,
  initialValues,
  loading = false,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<PlaintiffFormValues>({
    defaultValues: initialValues,
    resolver: joiResolver(debtorLegalPlaintiffSchema),
  })

  useEffect(() => {
    if (!initialValues) return
    reset(initialValues)
  }, [initialValues])

  const onSubmit = (data: PlaintiffFormValues) => {
    onSuccessfulSubmit(data)
  }

  return (
    <form className={styles.formWrapper} onSubmit={handleSubmit(onSubmit)}>
      <FormInput
        label="Plaintiff Name"
        {...register('plaintiffName')}
        error={errors.plaintiffName?.message}
      />
      <FormTextArea
        className={styles.textarea}
        label="Plaintiff Address"
        {...register('plaintiffAddress')}
        error={errors.plaintiffAddress?.message}
      />
      <SubmitButton className={styles.submitButton} buttonType="submit" loading={loading}>
        {submitButtonTitle}
      </SubmitButton>
    </form>
  )
}

export default DebtorLegalPlaintiffForm
