import FormInput from '@components/inputs/form-input/form-input.component'
import { GeoSuggestInput } from '@components/inputs/form-input/geo-suggest-input.component'
import FormSelect from '@components/inputs/form-select/form-select.component'
import { FULL_STATE_OPTIONS } from '@src/lib/select-options.const'
import { Controller, useForm } from 'react-hook-form'
import styles from './debtor-address-form.module.scss'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { SubHeader } from '@containers/Recovery/Wip/components/UI/text/text.component'
import { FC, useEffect, useState } from 'react'
import { Address } from '@components/inputs/form-input/geo-suggest.type'
import { joiResolver } from '@hookform/resolvers/joi'
import { ErrorMessage } from '@hookform/error-message'
import { debtorAddressSchema } from '@containers/Recovery/Wip/_modules/Debtors/schemas/debtor.schemas'
import { DebtorAddressFormProps, DebtorAddressFormValues } from '@containers/Recovery/Wip/_modules/Debtors/types/debtor-address.type'

const DebtorAddressForm: FC<DebtorAddressFormProps> = ({
  onSuccessSubmit,
  loading = false,
  initialValues,
  children,
}) => {
  const [suggest, setSuggest] = useState<Address | null>(null)
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<DebtorAddressFormValues>({
    resolver: joiResolver(debtorAddressSchema),
  })

  useEffect(() => {
    if (!suggest) return
    reset({
      ...suggest,
      state: FULL_STATE_OPTIONS.find(({ value }) => value === suggest.state) || null,
    })
  }, [suggest])

  useEffect(() => {
    if (!initialValues) return

    reset(initialValues)
  }, [initialValues])

  const onSubmit = (data: DebtorAddressFormValues) => {
    const submitData: Address = {
      ...data,
      address_1: [data.address_2, data.address_3, data.suburb, data.state?.value, data.postcode]
        .filter((i) => !!i)
        .join(', '),
      state: data.state?.value || null,
      postcode: String(data.postcode)
    }

    onSuccessSubmit && onSuccessSubmit(submitData)
  }

  return (
    <form className={styles.formWrapper} onSubmit={handleSubmit(onSubmit)}>
      {children}
      <div>
        <div className={styles.labelWrapper}>
          <SubHeader fontWeight="semibold">Address Line 1</SubHeader>
          <ErrorMessage
            name="address_1"
            errors={errors}
            render={(error) => <p className={styles.error}>{error.message}</p>}
          />
        </div>
        <Controller
          name="address_1"
          control={control}
          render={({ field: { onChange, value } }) => (
            <GeoSuggestInput onSuggestSelect={setSuggest} onChange={onChange} value={value} />
          )}
        />
      </div>
      <FormInput
        label="Address Line 2"
        error={errors.address_2?.message}
        {...register('address_2')}
      />
      <FormInput
        label="Address Line 3"
        error={errors.address_3?.message}
        {...register('address_3')}
      />
      <div className={styles.row}>
        <FormInput label="Suburb" error={errors.suburb?.message} {...register('suburb')} />
        <FormSelect name="state" control={control} label="State" options={FULL_STATE_OPTIONS} />
      </div>
      <div className={styles.row}>
        <FormInput label="Postcode" error={errors.postcode?.message} {...register('postcode')} />
        <FormInput label="Country" error={errors.country?.message} {...register('country')} />
      </div>
      <SubmitButton buttonType="submit" loading={loading}>
        Save
      </SubmitButton>
    </form>
  )
}

export default DebtorAddressForm
