import Modal from '@components/modal'
import { Header, Text } from '@containers/Recovery/Wip/components/UI/text/text.component'
import { BaseEditModalProps } from '@src/types/component-types'
import { FC } from 'react'
import MergePropertyForm from '../../forms/merge-property-form/merge-property-form.component'
import styles from './merge-property-modal.module.scss'
import { Row } from '@components/data-grid/data-grid.type'

interface Props extends BaseEditModalProps<Row | null> {
  removeRow: (id: number) => void
}

const MergePropertyModal: FC<Props> = ({ entity, removeRow, ...modalProps }) => {
  if (!entity && modalProps.isOpen) return null

  return (
    <Modal
      className={styles.modalWrapper}
      classNameLayout={styles.layout}
      {...modalProps}
      withBtns={false}
    >
      <Header mb={4}>Conflict Found In Records</Header>
      <Text mb={4}>
        Highlighted in <label className={styles.highlighted}>Red</label> below are the fields have
        been found in the database. Please match or exclude.
      </Text>
      <MergePropertyForm removeRow={removeRow} onClose={modalProps.onClose} entity={entity} />
    </Modal>
  )
}

export default MergePropertyModal
