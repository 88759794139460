import {
  Property,
  PropertyAddressDetails,
  SelectHeaderStepResponse,
} from '@containers/Sales/Properties/types/property.type'
import { AustralianState } from '@src/lib/select-options.const'

export const getPropertyName = (addressDetails?: PropertyAddressDetails | null) => {
  if (!addressDetails) return 'Invalid Address'

  const addressDetailsArray = [
    addressDetails.street_number,
    addressDetails.street_name,
    addressDetails.city,
    addressDetails.state,
    addressDetails.postcode,
  ].filter((el) => !!el)

  if (addressDetailsArray.length === 0) return 'Invalid Address'

  return addressDetailsArray.join(', ')
}

export const transformHeaderRow = (
  selectHeaderStepResponse: SelectHeaderStepResponse,
  columnToMatch: string[],
) => {
  const headerRow = selectHeaderStepResponse.rows[selectHeaderStepResponse.headerRowIndex]
  return headerRow.map((column) => {
    return {
      name: column,
      match: columnToMatch.find((match) => match === column.toLowerCase().trim()) || null,
    }
  })
}

export function capitalizeWords(str) {
  return str
    .split(' ')
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

export const parseOwnerText = (input: string) => {
  let entityName: string = ''
  let parts: string[]
  if (input.includes('-')) {
    const [part, partWithEntity] = input.split('-').map((part) => part.trim())
    parts = [...partWithEntity.split(/ & | and /), ...part.split(/ & | and /)]
    entityName = parts.shift() || ''
  } else {
    parts = input.split(/ & | and /).map((part) => part.trim())
  }
  const splittedPart = parts.map((s) => s.split(' '))
  return {
    firstName: splittedPart[0][0] || '',
    middleName: splittedPart[0].slice(1, -1).join(' ') || '',
    lastName: splittedPart[0][splittedPart[0].length - 1] || '',
    entityName: entityName,
    additionalLandlord: splittedPart.slice(1).map(row => row.join(' ')).join(' & '),
  }

}

// export const parseOwnerText = (input: string) => {
//
//   let entityName = ''
//   let parts: string[]
//   if (input.includes('-')) {
//     const [part, partWithEntity] = input.split('-').map((part) => part.trim())
//     parts = [...partWithEntity.split(/ & | and /), ...part.split(/ & | and /)]
//     entityName = parts.shift() || ''
//   } else {
//     parts = input.split(/ & | and /).map((part) => part.trim())
//     entityName = parts.join(';')
//   }
//   const splittedPart = parts.map((s) => s.split(' '))
//   return splittedPart.map((part) => ({
//     firstName: part[0] || '',
//     middleName: part.slice(1, -1).join(' ') || '',
//     lastName: part[part.length - 1] || '',
//     entityName: entityName,
//   }))
// }

export const parsePropertyAddress = (address: string) => {

  const result = address.split(',')
  const fullAddress = result?.[0] || ''
  const address1 =
    fullAddress.split(' ')?.[0].split('/').length > 1
      ? fullAddress.split(' ')?.[0].split('/')[0]
      : ''
  const address2 =
    fullAddress.split(' ')?.[0].split('/').length > 1
      ? fullAddress.split(' ')?.[0].split('/')[1]
      : fullAddress.split(' ')?.[0]
  const address3 = fullAddress.split(' ')?.slice(1)?.join(' ') || ''

  let addressElementAfterComma = result
    .slice(1)
    .flatMap((el) => el.split(' '))
    .filter((el) => el)
  const postcodeIndex = addressElementAfterComma.findIndex((el) => !Number.isNaN(Number(el)))
  const postcode = addressElementAfterComma[postcodeIndex] || ''

  if (postcodeIndex !== -1) {
    addressElementAfterComma = addressElementAfterComma.filter(
      (_, index) => index !== postcodeIndex,
    )
  }

  const australianStateArray = Object.keys(AustralianState)

  const stateIndex = addressElementAfterComma.findIndex((el) =>
    australianStateArray.includes(el.toUpperCase()),
  )
  const state = addressElementAfterComma[stateIndex]?.toUpperCase() || ''

  if (stateIndex !== -1) {
    addressElementAfterComma = addressElementAfterComma.filter((_, index) => index !== stateIndex)
  }

  const suburb = addressElementAfterComma.join(' ')

  const addresses =
    address1 === ''
      ? {
        address1: address2,
        address2: address3,
        address3: '',
      }
      : {
        address1,
        address2,
        address3,
      }

  return {
    ...addresses,
    suburb,
    state,
    postcode,
  }
}

export function transformFieldToArray<T extends { [key: string]: any }>(data: T, fieldName: keyof T): T {
  if (typeof data[fieldName] === 'string') {
    return {
      ...data,
      [fieldName]: (data[fieldName] as string).split(' & '),
    }
  }
  return data
}

export function flattenObjectToArrays(input) {
  const result = {}

  function recurse(current, path) {
    if (Array.isArray(current)) {
      current.forEach((item) => {
        recurse(item, path)
      })
    } else if (typeof current === 'object' && current !== null) {
      Object.keys(current).forEach((key) => {
        const newPath = path ? `${path}.${key}` : key
        recurse(current[key], newPath)
      })
    } else {
      if (!result[path]) {
        result[path] = []
      }
      result[path].push(current)
    }
  }

  recurse(input, '')

  const nestedPathKeys = {}
  const plainPathKeys = {}

  for (const key in result) {
    const path = key.split('.')
    if (path.length > 1) {
      nestedPathKeys[path[path.length - 1]] = result[key]
    } else {
      plainPathKeys[key] = result[key]
    }
  }

  return {
    ...nestedPathKeys,
    ...plainPathKeys,
  }
}

const addressKeys = ['addressLine1', 'addressLine2', 'addressLine3']

export const getFullAddressByObject = (property: Property | null) => {
  if (!property) return ''

  return Object.entries(property || {})
    .map(([key, value]) => {
      const finalAddress: any[] = []
      if (addressKeys.includes(key)) {
        finalAddress.push(value as any)
      }
      return finalAddress
    })
    .filter((v) => v.length > 0)
    .flat(1)
    .join(', ')
}
