import { EventProps } from './types'

const Event = ({ endTime, startTime, title, color, description, styles }: EventProps) => {
  return (
    <div style={{ ...styles, background: 'red', width: 100, height: 100, position: 'absolute' }}>
      title
    </div>
  )
}

export default Event
