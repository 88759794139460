import { CourtInterestCalculatorService } from "../services/court-interest-calculator.service"
import { CreateCourtInterestCalculatorData, UpdateCourtInterestCalculatorData } from "../types/interest-rate.type"
import { useMutation } from "@tanstack/react-query"

export const useCreateCourtInterestCalculatorMutation = () => {
  const createCourtInterestCalculator = async (data: CreateCourtInterestCalculatorData) => {
    const courtInterestCalculatorService = new CourtInterestCalculatorService()
    return courtInterestCalculatorService.createCourtInterestCalculator(data)
  }

  const mutation = useMutation(createCourtInterestCalculator)
  return mutation
}

export const useUpdateCourtInterestCalculatorMutation = () => {
  const updateCourtInterestCalculator = async (id: number, data: UpdateCourtInterestCalculatorData) => {
    const courtInterestCalculatorService = new CourtInterestCalculatorService()
    return courtInterestCalculatorService.updateCourtInterestCalculator(id, data)
  }

  const mutation = useMutation({
    mutationFn: ({ id, data }: { id: number, data: UpdateCourtInterestCalculatorData }) => updateCourtInterestCalculator(id, data)
  })

  return mutation
}