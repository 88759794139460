import React from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import SimpleReactValidator from 'simple-react-validator';
import NotesService from '../../../../../../services/NotesServices';
import Services from '../../../../../../shared/Services';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from 'mdi-react/CloseIcon';
import { UserContext } from '../../../../../../context/user-context';

class AddNotesDebtor extends React.Component {
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.state = {
      id: localStorage.getItem('debtorId'),
      title: '',
      notes: '',
      app_type: '',
      asPin: false,
    };

    this.note = new NotesService();
    this.services = new Services();
    this.addNotes = this.addNotes.bind(this);
    this.changeInput = this.changeInput.bind(this);
    this.validator = new SimpleReactValidator();
  }

  addNotes = () => {
    const wips = JSON.parse(localStorage.getItem('wipDebId'));
    if (this.validator.allValid()) {
      if (!this.state.title) {
        this.services.errorToast('Title field is required.');
        return;
      }
      if (!this.state.notes) {
        this.services.errorToast('Notes field is required.');
        return;
      }

      if (wips) {
        const {user} = this.context;
        const formData = {
          debtor: { id: +this.state.id },
          wip: wips[0],
          title: this.state.title,
          content: this.state.notes,
          is_important: this.state.asPin,
          actions: 'Debtor contact',
          is_contact: true,
          staff_created: `${user.first_name} ${user.last_name}`,
        };
        if(this.state.asPin){
          const data = this.props.debtorNotes.find((el)=> el.is_important === true)
          if(data !== undefined){
            data.is_important = false
            this.note.editDebtorNotes(data).then((res) => {
              if (res.status === 200) {
              } else if (
                res.statusCode === 401 &&
                res.responseType === 'UNAUTHORIZED'
              ) {
                this.setState({ accessErrorModal: true });
              } else {
                this.services.errorToast(res.message);
              }
            });
          }
        }
        this.note.addDebtorNotes(formData).then((res) => {
          if (res.status === 201) {
            this.services.successToast('Note added successfully');
            this.clear();
            this.props.getDebtorNotes(+this.state.id)
            this.props.closeModal(false)
          } else if (
            res.statusCode === 401 &&
            res.responseType === 'UNAUTHORIZED'
          ) {
            this.setState({ accessErrorModal: true });
          } else {
            this.services.errorToast(res.message);
          }
        });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  changeInput(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleOptionAsPin = (e) => {
    this.setState({
      asPin: e.currentTarget.checked,
    });
  };

  clear = () => {
    this.setState({
      note_Id: '',
      title: '',
      notes: '',
      type: 'DEBTOR',
      time: '',
      date: '',
      app_type: '',
      asPin: false,
      selected_type: '',
    });
  };

  render() {

    const { title, notes, asPin, } = this.state;

    return (
      <>
        <div style={{ position: 'relative', left: '375px', marginTop: '-25px' }}>
          <IconButton style={{ right: '24px', marginTop: '-16px' }} onClick={() => this.props.closeModal(false)}>
            < CloseIcon />
          </IconButton>
        </div>
        <div>
          <h3 style={{ color: 'black' }} className='form__form-group'>Notes</h3>
        </div>
        <div>
          <div className='bg-white w-100'>
            <div className='row mt-2'>
              <div className='col-12 col-md-8'>
                <div style={{textAlign: 'initial'}}  className='form-group'>
                  <label className='form__form-group-label'> Note Title </label>
                  <input className='custom-input' name='title' type='text' placeholder='Add title' value={title}
                         onChange={this.changeInput} />
                </div>
              </div>
              <div className='col-12 col-md-1 col-lg-4'>
                <div  className='form-group'>
                  <label
                    className='form__form-group-label'
                    htmlFor=''
                  >
                    &nbsp;
                  </label>
                  <div className='custom-control custom-switch pt-2'>
                    <input
                      type='checkbox'
                      className='custom-control-input'
                      id='customRadio'
                      name='asPin'
                      checked={asPin}
                      onChange={this.handleOptionAsPin}
                    />
                    <label
                      className='custom-control-label'
                      htmlFor={'customRadio'}
                    >
                      {'Pin as Important Note'}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className='row mt-2'>
              <div className='col-12'>
                <div style={{textAlign: 'initial'}}  className='form-group'>
                  <label className='form__form-group-label'>Note Details</label>
                  <textarea name='notes' className='custom-input' value={notes} onChange={this.changeInput} />
                </div>
              </div>
            </div>
            <div className='d-flex justify-content-end w-100 py-3'>
              <ButtonToolbar className='form__button-toolbar'>
                <Button color='primary'
                        type='submit'
                        onClick={this.addNotes}>
                  Save
                </Button>
                <Button className={'ml-3'}
                  type='button'
                  onClick={this.clear}>
                  Cancel
                </Button>
              </ButtonToolbar>
            </div>
          </div>
        </div>
      </>

    );
  }
}

export default reduxForm({ form: 'add_staff_form_validation' })
(withRouter(withTranslation('common')(AddNotesDebtor)));
