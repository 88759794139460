import TableCell from '@components/table/table-cell-component'
import TableRow from '@components/table/table-row.component'
import { SmsTableItemProps } from '@containers/Communication/types/communication.type'
import { format } from 'date-fns'
import { FC } from 'react'

const SmsTableItem: FC<SmsTableItemProps> = ({ shortMessage, onSmsClick }) => {
  const { to, body, time_created } = shortMessage

  const handleSmsClick = () => {
    onSmsClick && onSmsClick(shortMessage)
  }

  return (
    <TableRow onClick={handleSmsClick} pointer>
      {/* DATE */}
      <TableCell position="eclipsed" width={120}>
        {format(time_created, 'MMM dd yyyy')}
      </TableCell>
      {/* TIME */}
      <TableCell position="eclipsed" width={120}>
        {format(time_created, 'HH:mm aa')}
      </TableCell>
      {/* TO */}
      <TableCell position="eclipsed" width={160}>
        {to}
      </TableCell>
      {/* BODY */}
      <TableCell position="eclipsed"> {body}</TableCell>
      {/* SENDER */}
      <TableCell position="eclipsed">
        {' '}
        {shortMessage.staff_created?.nickname || 'System SMS'}
      </TableCell>
    </TableRow>
  )
}

export default SmsTableItem
