import { TaskBoardMode } from '@containers/Setting/components/Contacts/taskboard/types'

export const GLOBAL_USER_ACCESS = [
  { value: 'ADMIN', label: 'Admin' },
  { value: 'SALES_USER', label: 'Sales' },
  { value: 'RECOVERY_USER', label: 'Recovery' },
  { value: 'SALES_ADMIN', label: 'Sales Admin' },
  { value: 'RECOVERY_ADMIN', label: 'Recovery Admin' },
]

export const GLOBAL_USER_POSITION = [
  { value: 'ADMIN', label: 'Admin' },
  { value: 'SALES_ADMIN', label: 'Sales Admin' },
  { value: 'RECOVERY_ADMIN', label: 'Recovery Admin' },
  { value: 'SALES_USER', label: 'Sale User' },
  { value: 'RECOVERY_USER', label: 'Recovery User' },
]
export const ADMIN_POSITIONS = ['ADMIN', 'SALES_ADMIN', 'RECOVERY_ADMIN']

export const RULES_TO_ASSIGN_TASK = {
  [GLOBAL_USER_POSITION[0].value]: [...GLOBAL_USER_POSITION.map((item) => item.value)],
  [GLOBAL_USER_POSITION[1].value]: [GLOBAL_USER_POSITION[1].value, GLOBAL_USER_POSITION[3].value],
  [GLOBAL_USER_POSITION[2].value]: [GLOBAL_USER_POSITION[2].value, GLOBAL_USER_POSITION[4].value],
  [GLOBAL_USER_POSITION[3].value]: [GLOBAL_USER_POSITION[3].value, GLOBAL_USER_POSITION[1].value],
  [GLOBAL_USER_POSITION[4].value]: [GLOBAL_USER_POSITION[4].value, GLOBAL_USER_POSITION[2].value],
}

export const TASK_BOARD_MAP_TO_URL = {
  '/contacts/task-list': 'SALES' as TaskBoardMode,
  '/wip-details': 'RECOVERY' as TaskBoardMode,
}
