import Section from '@components/section/section.component'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import EditDebtorServiceForm from '../../forms/debtor-service-form/edit-debtor-service-form.component'
import { FC } from 'react'
import { DebtorServiceSectionProps } from '../../../types/debtor-legal-service.type'

const DebtorServiceSection: FC<DebtorServiceSectionProps> = ({ debtorLegal }) => {
  return (
    <Section>
      <Header mb={4}>Service</Header>
      <EditDebtorServiceForm debtorLegal={debtorLegal} />
    </Section>
  )
}

export default DebtorServiceSection
