import TableCell from '@components/table/table-cell-component'
import TableRow from '@components/table/table-row.component'
import Table from '@components/table/table.component'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { Wip } from '@containers/Recovery/Wip/types/wip.type'
import { format } from 'date-fns'
import { FC } from 'react'
import styles from './client-wips-table.module.scss'

interface ClientWipsTableProps {
  wips: Wip[]
  loading?: boolean
  notFound?: boolean
  total?: number
  limit?: number
  page?: number
  onPageChange?: (page: number) => void
}

interface ClientWipsTableRowProps {
  wip: Wip
}

const ClientWipsTableRow: FC<ClientWipsTableRowProps> = ({ wip }) => {
  return (
    <TableRow>
      {/* WIP ID */}
      <TableCell position="eclipsed" width={70}>{wip.id}</TableCell>
      {/* DEBTORS */}
      <TableCell className={styles.debtorCell} position="eclipsed">
        {wip.debtors.map((debtor) => `${debtor.name_first} ${debtor.name_last}`).join(', ')}
      </TableCell>
      {/* LOGGED */}
      <TableCell position="eclipsed">{format(wip.created_at, 'MM/dd/yyyy')}</TableCell>
      {/* PROPERTY */}
      <TableCell position="eclipsed">{wip.property?.fullAddress || 'Not Found'}</TableCell>
      {/* STATUS */}
      <TableCell position="eclipsed">Active</TableCell>
      {/* COVER */}
      <TableCell position="eclipsed">{wip.cover?.title || 'Not Found'}</TableCell>
      {/* ACTION */}
      <TableCell position="eclipsed">
        <SubmitButton type="link" url={`/wip/${wip.id}/overview`}>
          View
        </SubmitButton>
      </TableCell>
    </TableRow>
  )
}

const ClientWipsTable: FC<ClientWipsTableProps> = ({
  wips,
  loading,
  notFound,
  total,
  page,
  limit = 10,
  onPageChange,
}) => {
  return (
    <Table
      headers={['WIP ID', 'DEBTORS', 'LOGGED', 'PROPERTY', 'STATUS', 'COVER', 'ACTION']}
      totalCount={total}
      loading={loading}
      notFound={notFound}
      page={page}
      bodyHeight={limit * 54}
      limit={10}
      onPageChange={onPageChange}
    >
      {wips.map((wip) => (
        <ClientWipsTableRow key={`client-wip-${wip.id}`} wip={wip} />
      ))}
    </Table>
  )
}

export default ClientWipsTable
