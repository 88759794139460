import { useEffect, useRef, useState } from 'react'
import styles from './assign-attributes.module.scss'
import cls from 'classnames'
import ArrowIcon from '@src/icons/arrow'
import { IAssignAttributesProps } from '@src/types/component-types'
import useOutsideClick from '@src/hooks/useOutside'

const AssignAttributes = ({
  options = [],
  onChange = () => null,
  values = [],
  children,
  isOpen = false,
  handleDropdownChangeState = () => null,
  handleCloseDropdown = () => null,
  className = '',
  isDisabled = false,
}: IAssignAttributesProps) => {
  const [activeSelectOptions, setActiveSelectOptions] = useState<string[]>([])

  const [selectedInnerOptions, setSelectedInnerOptions] = useState<number[]>(values)

  const handleExpandOption = (name: string) => {
    setActiveSelectOptions((prev) => {
      if (prev.includes(name)) {
        return prev.filter((item) => item !== name)
      }
      return [...prev, name]
    })
  }

  useEffect(() => {
    setSelectedInnerOptions(values)
  }, [values])


  const handleChaneInnerOption = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    value: number,
  ) => {
    event.stopPropagation()

    setSelectedInnerOptions((prev) => {
      if (prev.includes(value)) {
        onChange(prev.filter((item) => item !== value) || [])
        return prev.filter((item) => item !== value)
      }
      onChange([...prev, value] || [])
      return [...prev, value]
    })
  }

  const filterChildren = (children: number[]) => {
    setSelectedInnerOptions((prev) => children.filter((item) => !prev.includes(item)))
    return
  }

  const containerRef = useRef<HTMLDivElement | null>(null)
  useOutsideClick([containerRef], handleCloseDropdown)

  return (
    <div
      ref={containerRef}
      className={cls(styles.wrapper, className, { [styles.withOutChildren]: !children })}
      onClick={handleDropdownChangeState}
    >
      {children && children}
      {isOpen && (
        <div className={styles.dropdown} onClick={(e) => e.stopPropagation()}>
          {options
            ?.filter(({ filter }) => !filter)
            ?.map((option, i) => {
              return (
                <div
                  key={`${option.name}-${i}`}
                  onClick={() => handleExpandOption(option.name)}
                  className={cls(styles.option, {
                    [styles.activeOption]: activeSelectOptions.includes(option.name),
                  })}
                >
                  <div>
                    <ArrowIcon
                      direction={activeSelectOptions.includes(option.name) ? 'bottom' : 'right'}
                    />
                    <span>{option.name}</span>
                  </div>
                  {activeSelectOptions.includes(option.name) &&
                    option?.innerOptions?.map(({ label, value, options = [] }, i) => {
                      const selectedOption = selectedInnerOptions.includes(value)
                      return (
                        <div
                          onClick={(e) => {
                            e.stopPropagation()
                            if (isDisabled && options?.length < 1) return

                            if (selectedOption && options?.length > 0) {
                              handleChaneInnerOption(e, value)

                              const allChildrenOptions = options.map((item) => item.value)
                              const activeChidrenOptions = selectedInnerOptions.filter((item) =>
                                allChildrenOptions.includes(item),
                              )

                              filterChildren(activeChidrenOptions)

                              return
                            }
                            handleChaneInnerOption(e, value)
                          }}
                          key={`${label}-${i}`}
                          className={cls(styles.innerOption, {
                            [styles.withChildren]: options?.length > 0,
                            [styles.active]: selectedOption,
                          })}
                        >
                          <div>
                            <input checked={selectedOption} onChange={() => null} type="checkbox" />
                            <span>{label}</span>
                          </div>
                          {selectedOption && (
                            <div
                              className={styles.secondInnerContainer}
                              onClick={(e) => e.stopPropagation()}
                            >
                              {options?.map(({ label, value }, i) => (
                                <div
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    if (isDisabled) return
                                    handleChaneInnerOption(e, value)
                                  }}
                                  key={`${label}-${value}-${i}`}
                                  className={styles.secondInnerOption}
                                >
                                  <div>
                                    <input
                                      onChange={() => null}
                                      checked={selectedInnerOptions.includes(value)}
                                      type="checkbox"
                                    />
                                    <span>{label}</span>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      )
                    })}
                </div>
              )
            })}
        </div>
      )}
    </div>
  )
}

export default AssignAttributes
