import { FC, ReactNode } from 'react'
import styles from './sub-section.module.scss'
import cls from 'classnames'

interface SubSectionProps {
  className?: string
  lineStyle?: 'solid' | 'dashed'
  children: ReactNode
  flex?: boolean
  mb?: 1 | 2 | 3 | 4
  p?: 1 | 2 | 3 | 4
}

const SubSection: FC<SubSectionProps> = ({
  children,
  mb,
  p = 1,
  flex,
  className,
  lineStyle = 'solid',
}) => {
  return (
    <div
      className={cls(styles.subSection, className, {
        [styles.flex]: flex,
        [styles[`p${p}`]]: p,
        [styles[`mb${mb}`]]: mb,
        [styles[`line-${lineStyle}`]]: lineStyle,
      })}
    >
      {children}
    </div>
  )
}

export default SubSection
