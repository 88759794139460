import React from 'react';
import { Button } from 'reactstrap';
import { reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import Services from '../../../../../shared/Services.js';
import { withRouter } from 'react-router';
import Select from 'react-select';
import SimpleReactValidator from 'simple-react-validator';
import ModalComponent from '../../../../../shared/components/ModalCustom';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Geosuggest from 'react-geosuggest';
import PropertyService from '../../../../../services/PropertyService';
import CompaniesService from '../../../../../services/CompaniesService';
import { TailSpin } from 'react-loader-spinner';
import { UserContext } from '../../../../../context/user-context';

const renderField = ({ input, placeholder, value, type, meta: { touched, error } }) => (
  <div className="form__form-group-input-wrap">
    <input {...input} placeholder={placeholder} type={type} value={value} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
};

const invoiceTypeOption = [
  { value: '1', label: 'Pdf' },
  { value: '2', label: 'Hard Copy' },
  { value: '3', label: 'Both/Mixed' },
  { value: '4', label: 'Upload' },
];

const status = [
  { value: '1', label: 'Prospect' },
  { value: '2', label: 'Current' },
  { value: '3', label: 'Inactive' },
  { value: '4', label: 'db only' },
  { value: '4', label: 'No Longer operating' },
];

const serviceOption = [
  {
    value: 'Protect & Collect',
    label: 'Protect & Collect',
  },
  {
    value: 'Property Track',
    label: 'Property Track',
  },
  {
    value: 'Recovery Only',
    label: 'Recovery Only',
  },
];

class AddProperty extends React.Component {
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.state = {
      isView: localStorage.getItem('propertyEdit') !== 'yes',
      isAdd: localStorage.getItem('propertyAdd') === 'yes',
      client_id: localStorage.getItem('client_Id'),
      propertyData: '',
      client_code: '',
      id: '',
      agent_nm: '',
      agent_phone: '',
      agent_address_1: '',
      agent_address_2: '',
      agent_address_3: '',
      agent_suburb: '',
      agent_state: '',
      agent_post_code: '',
      agent_country: '',
      cover_to: new Date(),
      cover_from: new Date(),
      prop_address_1: '',
      prop_address_2: '',
      prop_address_3: '',
      prop_suburb: '',
      prop_state: '',
      prop_post_code: '',
      prop_country: '',
      landlord_title: '',
      landlord_first: '',
      landlord_last: '',
      owner_email: '',
      owner_phone: '',
      landlord_address_1: '',
      landlord_address_2: '',
      landlord_address_3: '',
      landlord_suburb: '',
      landlord_state: '',
      landlord_post_code: '',
      landlord_country: '',
      landlord_invoice: 0,
      invoice_type: '',
      price: 0,
      credit: 500,
      tax: 0,
      total: 0,
      status: 0,
      service: 0,
      tenancy_plus_3_months: 0,
      tenancy_completed_all: 0,
      bond_4_weeks: 0,
      rent_arrears: 0,
      aware_of_details: 0,
      crby: 'Kevin',
      awareerrorModal: false,
      errorMessage: '',
      allClient: [],
      selectedClient: '',
      loader: false,
      client: [],
    };
    this.comp = new CompaniesService();
    this.prop = new PropertyService();
    this.services = new Services();
    this.saveProperty = this.saveProperty.bind(this);
    this.changeInput = this.changeInput.bind(this);
    this.changeAddress = this.changeAddress.bind(this);
    this.validator = new SimpleReactValidator();
    this.getClient = this.getClient.bind(this);
  }

  componentDidMount() {
    if (!this.state.isAdd) {
      this.getAllClient();
    }
    this.getClient(this.state.client_id);
  }

  getAllClient() {
    this.setState({ loader: true });
    const params = {
      limit: 1000,
      join: 'contacts',
    };
    this.comp.getCompanies(params).then((res) => {
      if (res.status === 200) {
        this.getStaff(localStorage.getItem('contactsId'));
        const getData = res.data.data;
        this.setState({
          client: getData,
        });
        let listClient;
        listClient =
          getData &&
          getData.map((item) => {
            return { value: item.id, label: item.name };
          });
        this.setState({
          allClient: listClient,
        });
      } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
        this.setState({ accessErrorModal: true });
      } else {
        this.services.errorToast(res.message);
      }
    });
  }

  getStaff() {
    if (!this.state.isAdd) this.editProperty(localStorage.getItem('propertyId'));
  }

  accessErrorModalClose = () => {
    this.setState({ accessErrorModal: false });
    this.props.history.push('/dashboard');
  };

  editProperty = (propertyId) => {
    this.setState({ loader: true });
    const params = {
      id: propertyId,
      join: ['company'],
    };
    this.prop.getPropertyId(params).then((res) => {
      if (res.status === 200) {
        const propertyData = res.data;
        const cover_to =
          propertyData?.cover_details?.to && propertyData.cover_details.to !== 'Invalid date'
            ? new Date(propertyData.cover_details.to)
            : new Date();
        const cover_from =
          propertyData?.cover_details?.from && propertyData.cover_details.from !== 'Invalid date'
            ? new Date(propertyData.cover_details.from)
            : new Date();
        const client = this.state.client.find((e) => e.id === propertyData?.company?.id);
        const clientSelect = this.state.allClient.find(
          (e) => e.value === propertyData?.company?.id,
        );
        this.setState({
          propertyData: propertyData,
          selectedClient: clientSelect,
          agent_address_1: client?.address[0]?.address_1,
          agent_address_2: client?.address[0]?.address_2,
          agent_address_3: client?.address[0]?.address_3,
          agent_country: client?.address[0]?.country,
          agent_nm: client?.name,
          agent_phone: client?.contacts[0]?.comm_ph_office,
          agent_post_code: client?.address[0]?.post_code,
          agent_state: client?.address[0]?.state,
          agent_suburb: client?.address[0]?.suburb,
          bond_4_weeks: propertyData.bond_4_weeks,
          client_code: propertyData.client_code ? propertyData.client_code : this.state.client_code,
          client_id: propertyData?.company?.id,
          cover_to: cover_to,
          cover_from: cover_from,
          crDate: new Date(propertyData.time_created),
          crby: propertyData.crby,
          credit: propertyData.credit,
          invoice_type: invoiceTypeOption.find(
            (_) => _.label === propertyData?.cover_details?.type,
          ),
          isdel: propertyData.isdel,
          landlord_address_1: '',
          landlord_address_2: propertyData.landlord_details?.address_street_secondary,
          landlord_address_3: propertyData.landlord_details?.address_city,
          landlord_country: propertyData.landlord_details?.address_country,
          landlord_first: propertyData.landlord_details?.name_first,
          landlord_invoice: propertyData.landlord_details?.invoice,
          landlord_last: propertyData.landlord_details?.name_last,
          landlord_post_code: propertyData.landlord_details?.address_postcode,
          landlord_state: propertyData.landlord_details?.address_state,
          landlord_suburb: propertyData.landlord_details?.address_suburb,
          landlord_title: propertyData.landlord_details?.title,
          owner_email: propertyData.landlord_details?.comms_email,
          owner_phone: propertyData.landlord_details?.comms_ph_mobile,
          price: propertyData?.cover_details?.price || this.state.price,
          prop_address_1: '',
          prop_address_2: propertyData.address_details?.street_secondary,
          prop_address_3: propertyData.address_details?.city,
          prop_country: propertyData.address_details?.country,
          prop_post_code: propertyData.address_details?.postcode,
          prop_state: propertyData.address_details?.state,
          prop_suburb: propertyData.address_details?.suburb,
          rent_arrears: propertyData.rent_arrears,
          aware_of_details: propertyData.aware_of_details ? propertyData.aware_of_details : 0,
          status: status.find((_) => _.label === propertyData.status),
          service: {
            value: propertyData?.agent_details?.service,
            label: propertyData?.agent_details?.service,
          },
          tax: +propertyData?.cover_details?.tax || this.state.tax,
          tenancy_completed_all: propertyData.tenancy_completed_all,
          tenancy_plus_3_months: propertyData.tenancy_plus_3_months,
          total: propertyData?.cover_details?.total,
          id: propertyData.id,
        });
        this.setState({ loader: false });
      } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
        this.setState({ accessErrorModal: true });
        this.setState({ loader: false });
      }
    });
  };

  /**
   * Get Client/Company Details
   **/
  getClient(id) {
    const params = {
      id: id
    };
    this.comp.getCompaniesId(params).then((res) => {
      if (res.status === 200) {
        const company = res.data;
        const primaryAddress = company.address.find((address) =>  address.primary === true);
        const onlyAddress = company.address.length > 0 ? company.address[0] : undefined;
        this.setState({
          client_id: parseInt(id),
          agent_nm: company.name,
          agent_phone: company.client_phone,
          agent_address_1:  (primaryAddress && primaryAddress.address_1) ?? company.address[0] ?? '-',
          agent_address_2: company.address[1] ?? '-',
          agent_address_3: company.address[2] ?? '-',
          agent_suburb: (primaryAddress && primaryAddress.suburb) ?? onlyAddress.suburb ?? '-',
          agent_state: (primaryAddress && primaryAddress.state) ?? onlyAddress.state ?? '-',
          agent_post_code: (primaryAddress && primaryAddress.post_code) ?? onlyAddress.post_code ?? '-',
          agent_country: (primaryAddress && primaryAddress.country) ?? onlyAddress.country ?? '-',
          service: (primaryAddress && primaryAddress.service) ?? onlyAddress.service ?? '-',
        });
      } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
        this.setState({ accessErrorModal: true });
      } else {
        this.services.errorToast(res.message);
      }
    });
  }

  saveProperty = () => {
    const property = this.state;
    const {user} = this.context;
    const companyId = this.state.selectedClient.value;

    if (property.aware_of_details === 1 || property.rent_arrears === 1) {
      this.setState({
        errorMessage:
          'If there the tenant is currently defaulted, please contact the management prior to adding into system',
        awareerrorModal: true,
        errorType: 'danger',
      });
    } else {
      const total = parseInt(property.price) + parseInt(property.tax);

      const updateData = {
        company: { id: property.client_id || companyId },
        status: property.status && property.status.label ? property.status.label : property.status,
        cover_details: {
          from: moment(this.state.cover_from).format('YYYY-MM-DD'),
          to: moment(this.state.cover_to).format('YYYY-MM-DD'),
          price: +property.price,
          tax: +property.tax,
          total: total,
          type:
            property.invoice_type && property.invoice_type.label
              ? property.invoice_type.label
              : property.invoice_type,
        },
        agent_details: {
          name: property.agent_nm,
          phone: `${property.agent_phone}`,
          address_1: property.agent_address_1.address_1 || '-',
          address_2: property.agent_address_2,
          address_3: property.agent_address_3,
          suburb: property.agent_suburb,
          state: property.agent_state,
          post_code: property.agent_post_code,
          country: property.agent_country,
          service: property.service.value,
        },

        address_details: {
          street_name: property.prop_address_1,
          street_secondary: property.prop_address_2,
          city: property.prop_address_3,
          country: property.prop_country,
          postcode: property.prop_post_code,
          state: property.prop_state,
          suburb: property.prop_suburb,
        },
        landlord_details: {
          address_street_name: property.landlord_address_1 || '-',
          address_street_secondary: property.landlord_address_2 || '-',
          address_city: property.landlord_address_3 || '-',
          address_country: property.landlord_country,
          name_first: property.landlord_first,
          invoice: property.landlord_invoice,
          name_last: property.landlord_last,
          address_postcode: property.landlord_post_code,
          address_state: property.landlord_state,
          address_suburb: property.landlord_suburb,
          title: property.landlord_title,
          comms_email: property.owner_email,
          comms_ph_mobile: property.owner_phone,
        },
      };
      
      updateData['staff_id_created'] = +user.id;

      if (this.validator.allValid()) {
        if (!this.state.isView && this.state.isAdd) {
          let companyId = this.props.client_id;
          this.prop.addProperty(updateData).then((res) => {
            if (res.status === 201) {
              this.services.successToast('Property Added successfully');
              this.props.history.push(`/contacts/${companyId}`);
            } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
              this.setState({ accessErrorModal: true });
            } else {
              this.services.errorToast(res.message);
            }
          });
          
        } else {
          updateData['id'] = localStorage.getItem('propertyId');
          updateData['staff_id_modified'] = +user.id;

          this.prop.editProperty(updateData).then((res) => {
            if (res.status === 200) {
              this.services.successToast('Property update successfully');
              this.backToFrom();
            } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
              this.setState({ accessErrorModal: true });
            } else {
              this.services.errorToast(res.message);
            }
          });
        }
      } else {
        this.validator.showMessages();
        this.forceUpdate();
      }
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  addaware_of_details = () => {
    this.setState({
      awareerrorModal: false,
    });
  };

  changeInput(e) {
    if (
      e.target.name === 'tenancy_plus_3_months' ||
      e.target.name === 'tenancy_completed_all' ||
      e.target.name === 'bond_4_weeks' ||
      e.target.name === 'rent_arrears' ||
      e.target.name === 'aware_of_details'
    ) {
      this.setState({
        [e.target.name]: JSON.parse(e.target.value),
      });
    } else if (e.target.name === 'landlord_invoice') {
      this.setState({
        [e.target.name]: this.state.landlord_invoice === 0 ? 1 : 0,
      });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  uncheckAll = (id) => {
    this.state.primary_address.forEach((addr) => {
      if (addr.id !== id) {
        addr.postal = false;
      }
    });
  };

  uncheckAllPrimary = (id) => {
    this.state.primary_address.forEach((addr) => {
      if (addr.id !== id) {
        addr.primary = false;
      }
    });
  };

  uncheckAllInvoice = (id) => {
    this.state.Contact_details.forEach((addr) => {
      if (addr.id !== id) {
        addr.invoice = false;
      }
    });
  };

  uncheckAllPrimaryContact = (id) => {
    this.state.Contact_details.forEach((addr) => {
      if (addr.id !== id) {
        addr.primary = false;
      }
    });
  };

  changeAddress(e, id) {
    if (id) {
      const self = this;
      const data = this.state.primary_address.map(function (item) {
        if (item.id === id && e.target.name) {
          if (e.target.name === 'postal' || e.target.name === 'primary') {
            if (e.target.name === 'postal') {
              item.postal = e.target.checked;
              self.uncheckAll(id);
            }
            if (e.target.name === 'primary') {
              item.primary = e.target.checked;
              self.uncheckAllPrimary(id);
            }
          } else {
            item[e.target.name] = e.target.value;
          }
        }
        return item;
      });
      if (e && e.target && e.target.name && id) this.setState({ primary_address: data });
    }
  }

  handleChange = (selectedOption, name) => {
    this.setState({ [name]: selectedOption });
  };

  handleChangeClient = (selectedOption) => {
    this.setState({ selectedClient: selectedOption });
    const client = this.state.client.find((e) => e.id === selectedOption.value);

    this.setState({
      agent_address_1: client?.address[0]?.address_1 ? client?.address[0]?.address_1 : '',
      agent_address_2: client?.address[0]?.address_2 ? client?.address[0]?.address_2 : '',
      agent_address_3: client?.address[0]?.address_3 ? client?.address[0]?.address_3 : '',
      agent_country: client?.address[0]?.country ? client?.address[0]?.country : '',
      agent_nm: client?.name ? client?.name : '',
      agent_phone: client?.contacts[0]?.comm_ph_office ? client?.contacts[0]?.comm_ph_office : '',
      agent_post_code: client?.address[0]?.post_code ? client?.address[0]?.post_code : '',
      agent_state: client?.address[0]?.state ? client?.address[0]?.state : '',
      agent_suburb: client?.address[0]?.suburb ? client?.address[0]?.suburb : '',
      client_id: client?.id,
    });
  };

  cancle = () => {
    this.props.history.push(`/contacts/${this.state.propertyData.company.id}`);
  };

  handleChangeToDate = (date) => {
    this.setState({
      cover_to: new Date(date),
    });
  };

  handleChangeFromDate = (date) => {
    this.setState({
      cover_from: new Date(date),
    });
  };

  handleSelectGeoSuggest = (place, id) => {
    if (!place) return null;

    const componentForm = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      administrative_area_level_1: 'short_name',
      country: 'long_name',
      postal_code: 'short_name',
    };
    for (var i = 0; i < place.gmaps.address_components.length; i++) {
      var addressType = place.gmaps.address_components[i].types[0];
      if (componentForm[addressType]) {
        var val = place.gmaps.address_components[i][componentForm[addressType]];
        if (val) componentForm[addressType] = val;
      }
    }

    if (id === 'Agent') {
      this.setState({
        agent_address_1: place.label || 'empty',
        agent_address_2:
          componentForm.street_number !== 'short_name' ? componentForm.street_number : '',
        agent_address_3: componentForm.route !== 'long_name' ? componentForm.route : '',
        agent_suburb: componentForm.locality !== 'long_name' ? componentForm.locality : '',
        agent_state:
          componentForm.administrative_area_level_1 !== 'long_name'
            ? componentForm.administrative_area_level_1
            : '',
        agent_country: componentForm.country !== 'long_name' ? componentForm.country : '',
        agent_post_code:
          componentForm.postal_code !== 'short_name' ? componentForm.postal_code : '',
      });
    }
    if (id === 'Props') {
      this.setState({
        prop_address_1: place.label || 'empty',
        prop_address_2:
          componentForm.street_number !== 'short_name' ? componentForm.street_number : '',
        prop_address_3: componentForm.route !== 'long_name' ? componentForm.route : '',
        prop_suburb: componentForm.locality !== 'long_name' ? componentForm.locality : '',
        prop_state:
          componentForm.administrative_area_level_1 !== 'long_name'
            ? componentForm.administrative_area_level_1
            : '',
        prop_country: componentForm.country !== 'long_name' ? componentForm.country : '',
        prop_post_code: componentForm.postal_code !== 'short_name' ? componentForm.postal_code : '',
      });
    }
    if (id === 'Landlord') {
      this.setState({
        landlord_address_1: place.label || 'empty',
        landlord_address_2:
          componentForm.street_number !== 'short_name' ? componentForm.street_number : '',
        landlord_address_3: componentForm.route !== 'long_name' ? componentForm.route : '',
        landlord_suburb: componentForm.locality !== 'long_name' ? componentForm.locality : '',
        landlord_state:
          componentForm.administrative_area_level_1 !== 'long_name'
            ? componentForm.administrative_area_level_1
            : '',
        landlord_country: componentForm.country !== 'long_name' ? componentForm.country : '',
        landlord_post_code:
          componentForm.postal_code !== 'short_name' ? componentForm.postal_code : '',
      });
    }
  };

  backToFrom = () => {
    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.fromDetails
    ) {
      this.props.history.push('/wip-details');
    } else if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.fromTools
    ) {
      this.props.history.push('/dashboard', { fromTools: true });
    } else {
      this.props.history.push(`/contacts/${this.state.propertyData.company.id}`, {
        fromViewPro: 'property',
      });
    }
  };

  render() {
    return (
      <>
        {this.state.awareerrorModal ? (
          <ModalComponent
            modal={this.state.awareerrorModal}
            color={this.state.errorType}
            isCancel={true}
            message={this.state.errorMessage}
            onConfirm={(e) => this.addaware_of_details(e)}
            onClose={() => this.setState({ awareerrorModal: false })}
          />
        ) : null}
        {this.state.loader ? (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '350px' }}>
            <TailSpin height="75" width="75" color="#f69066" ariaLabel="loading" />
          </div>
        ) : (
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="w-100">
                  <div id="contacts_accordion" className="add_new_propery form">
                    <div className="card mb-4">
                      <div className="d-flex" style={{ alignItems: 'stretch' }}>
                        <div>
                          <h3
                            className="page-title"
                            style={{ paddingTop: '28px', marginRight: '45px' }}
                          >
                            {!this.state.isView && this.state.isAdd
                              ? 'Add Property'
                              : 'Edit Property'}
                          </h3>
                        </div>
                        <div>
                          {!this.state.isAdd && (
                            <div style={{ width: '300px' }}>
                              <div>
                                <label className="form__form-group-label">Associate Client</label>
                                <Select
                                  name="client"
                                  type="text"
                                  placeholder={'Search for client by postcode'}
                                  options={this.state.allClient}
                                  value={this.state.selectedClient}
                                  classNamePrefix="react-select"
                                  className="react-select"
                                  onChange={(e) => this.handleChangeClient(e, 'selectedClient')}
                                />
                                <span className="form__form-group-error">
                                  {this.validator.message(
                                    'Service',
                                    this.state.service,
                                    'required',
                                  )}
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        id="collapseOne"
                        className="collapse show mb-4"
                        data-parent="#contacts_accordion"
                      >
                        <div className="card-body">
                          <div className="mb-4 pl-0">
                            <h3 className="page-title mb-0 mr-4" data-toggle="collapse">
                              {' '}
                              Property Details{' '}
                            </h3>
                          </div>
                          <div className="row">
                            <div className="col-12 col-md-4">
                              <div className="form-group">
                                <label className="form__form-group-label">
                                  Period of Cover From (As of 4PM)
                                </label>
                                <div className="date-picker date-picker--interval">
                                  <DatePicker
                                    selected={this.state.cover_from}
                                    onChange={(e) => this.handleChangeFromDate(e)}
                                    name="cover_from"
                                    dropDownMode="select"
                                    disabled={this.state.isView}
                                    placeholderText="Select Date"
                                    dateFormat="dd/MM/yy"
                                  />
                                  <span className="date_icon">
                                    <img src={'./image/calendar.svg'} alt="date" />
                                  </span>
                                  {/* <span className="form__form-group-error">{this.validator.message('Cover From', this.state.cover_from, 'required')}</span>  */}
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-4">
                              <div className="form-group">
                                <label className="form__form-group-label">
                                  Period of Cover To (As of 4PM)
                                </label>
                                <div className="date-picker date-picker--interval">
                                  <DatePicker
                                    selected={this.state.cover_to}
                                    onChange={(e) => this.handleChangeToDate(e)}
                                    name="cover_to"
                                    dropDownMode="select"
                                    disabled={this.state.isView}
                                    placeholderText="Select Date"
                                    dateFormat="dd/MM/yy"
                                  />
                                  <span className="date_icon">
                                    <img src={'./image/calendar.svg'} alt="date" />
                                  </span>
                                  {/* <span className="form__form-group-error">{this.validator.message('Cover To', this.state. cover_to, 'required')}</span>  */}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="mb-4 pl-0">
                            <span className="card-link" data-toggle="collapse">
                              Address of Property to be Covered
                            </span>
                          </div>

                          <div className="row">
                            <div className="col-12 col-md-9">
                              <div className="form-group">
                                <label className="form__form-group-label">
                                  Search Address Here
                                </label>
                                <Geosuggest
                                  ref={(el) => (this._geoSuggest = el)}
                                  onSuggestSelect={(e) => this.handleSelectGeoSuggest(e, 'Props')}
                                  initialValue={this.state.prop_address_1}
                                  disabled={this.state.isView}
                                  country={['AU', 'NZ']}
                                />
                                {/*<input*/}
                                {/*  type='text'*/}
                                {/*  className='custom-input'*/}
                                {/*  value={this.state.prop_address_1}*/}
                                {/*  name='prop_address_1'*/}
                                {/*  onChange={this.changeInput}*/}
                                {/*  disabled={this.state.isView}*/}
                                {/*/>*/}
                              </div>
                            </div>
                            <div className="col-12 col-md-9">
                              <div className="form-group">
                                <label className="form__form-group-label">
                                  Address Line 2<span style={{ color: '#ff0000' }} />
                                </label>
                                <input
                                  type="text"
                                  className="custom-input"
                                  value={this.state.prop_address_2}
                                  name="prop_address_2"
                                  onChange={this.changeInput}
                                  disabled={this.state.isView}
                                />
                                {/* <span className="form__form-group-error">{this.validator.message('Address line 2', this.state.prop_address_2, 'required')}</span> */}
                              </div>
                            </div>

                            <div className="col-12 col-md-9">
                              <div className="form-group">
                                <label className="form__form-group-label">
                                  Address Line 3<span style={{ color: '#ff0000' }} />
                                </label>
                                <input
                                  type="text"
                                  className="custom-input"
                                  value={this.state.prop_address_3}
                                  name="prop_address_3"
                                  onChange={this.changeInput}
                                  disabled={this.state.isView}
                                />
                                {/* <span className="form__form-group-error">{this.validator.message('Address line 3', this.state.prop_address_3, 'required')}</span> */}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 col-md-3">
                              <div className="form-group">
                                <label className="form__form-group-label">Suburb</label>
                                <input
                                  type="text"
                                  className="custom-input"
                                  name="prop_suburb"
                                  value={this.state.prop_suburb}
                                  onChange={this.changeInput}
                                  disabled={this.state.isView}
                                />
                                {/* <span className="form__form-group-error">{this.validator.message('Suburb', this.state.prop_suburb, 'required')}</span> */}
                              </div>
                            </div>
                            <div className="col-12 col-md-3">
                              <div className="form-group">
                                <label className="form__form-group-label">
                                  State<span style={{ color: '#ff0000' }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="custom-input"
                                  name="prop_state"
                                  value={this.state.prop_state}
                                  onChange={this.changeInput}
                                  disabled={this.state.isView}
                                />
                                <span className="form__form-group-error">
                                  {this.validator.message(
                                    'State',
                                    this.state.prop_state,
                                    'required',
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className="col-12 col-md-3">
                              <div className="form-group">
                                <label className="form__form-group-label">
                                  Post Code<span style={{ color: '#ff0000' }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="custom-input"
                                  name="prop_post_code"
                                  value={this.state.prop_post_code}
                                  onChange={this.changeInput}
                                  disabled={this.state.isView}
                                />
                                <span className="form__form-group-error">
                                  {this.validator.message(
                                    'Postcode',
                                    this.state.prop_post_code,
                                    'required',
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 col-md-3">
                              <div className="form-group">
                                <label className="form__form-group-label">Country</label>
                                <input
                                  type="text"
                                  className="custom-input"
                                  name="prop_country"
                                  value={this.state.prop_country}
                                  onChange={this.changeInput}
                                  disabled={this.state.isView}
                                />
                                <span className="form__form-group-error">
                                  {this.validator.message(
                                    'Country',
                                    this.state.prop_country,
                                    'required',
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        id="collapseOne"
                        className="collapse show mb-4"
                        data-parent="#contacts_accordion"
                      >
                        <div className="card-body">
                          <div className="mb-4 pl-0">
                            <h3 className="page-title mb-0 mr-4" data-toggle="collapse">
                              {' '}
                              LandLord{' '}
                            </h3>
                          </div>
                          <div className="row">
                            <div className="col-12 col-md-3">
                              <div className="form-group">
                                <label className="form__form-group-label">
                                  Landlord Title<span style={{ color: '#ff0000' }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="custom-input"
                                  name="landlord_title"
                                  value={this.state.landlord_title}
                                  onChange={this.changeInput}
                                  disabled={this.state.isView}
                                />
                                <span className="form__form-group-error">
                                  {this.validator.message(
                                    'Landlord Title',
                                    this.state.landlord_title,
                                    'required',
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className="col-12 col-md-3">
                              <div className="form-group">
                                <label className="form__form-group-label">
                                  First Name<span style={{ color: '#ff0000' }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="custom-input"
                                  name="landlord_first"
                                  value={this.state.landlord_first}
                                  onChange={this.changeInput}
                                  disabled={this.state.isView}
                                />
                                <span className="form__form-group-error">
                                  {this.validator.message(
                                    'First Name',
                                    this.state.landlord_first,
                                    'required',
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className="col-12 col-md-3">
                              <div className="form-group">
                                <label className="form__form-group-label">
                                  SurName<span style={{ color: '#ff0000' }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="custom-input"
                                  name="landlord_last"
                                  value={this.state.landlord_last}
                                  onChange={this.changeInput}
                                  disabled={this.state.isView}
                                />
                                <span className="form__form-group-error">
                                  {this.validator.message(
                                    'SurName',
                                    this.state.landlord_last,
                                    'required',
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 col-md-3">
                              <div className="form-group">
                                <label className="form__form-group-label">
                                  Owner Email<span style={{ color: '#ff0000' }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="custom-input"
                                  name="owner_email"
                                  value={this.state.owner_email}
                                  onChange={this.changeInput}
                                  disabled={this.state.isView}
                                />
                                <span className="form__form-group-error">
                                  {this.validator.message(
                                    'Owner Email',
                                    this.state.owner_email,
                                    'required|email',
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className="col-12 col-md-3">
                              <div className="form-group">
                                <label className="form__form-group-label">
                                  Owner Phone<span style={{ color: '#ff0000' }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="custom-input"
                                  name="owner_phone"
                                  value={this.state.owner_phone}
                                  onChange={this.changeInput}
                                  disabled={this.state.isView}
                                />
                                <span className="form__form-group-error">
                                  {this.validator.message(
                                    'Owner Phone',
                                    this.state.owner_phone,
                                    'required',
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-12 col-md-9">
                              <div className="form-group">
                                <label className="form__form-group-label">
                                  Search Address Here
                                </label>
                                <Geosuggest
                                  ref={(el) => (this._geoSuggest = el)}
                                  onSuggestSelect={(e) =>
                                    this.handleSelectGeoSuggest(e, 'Landlord')
                                  }
                                  initialValue={this.state.landlord_address_1}
                                  disabled={this.state.isView}
                                  country={['AU', 'NZ']}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-9">
                              <div className="form-group">
                                <label className="form__form-group-label">
                                  Address Line 2<span style={{ color: '#ff0000' }} />
                                </label>
                                <input
                                  type="text"
                                  className="custom-input"
                                  value={this.state.landlord_address_2}
                                  name="landlord_address_2"
                                  onChange={this.changeInput}
                                  disabled={this.state.isView}
                                />
                                {/* <span className="form__form-group-error">{this.validator.message('Address Line 2', this.state.landlord_address_2, 'required')}</span> */}
                              </div>
                            </div>

                            <div className="col-12 col-md-9">
                              <div className="form-group">
                                <label className="form__form-group-label">
                                  Address Line 3<span style={{ color: '#ff0000' }} />
                                </label>
                                <input
                                  type="text"
                                  className="custom-input"
                                  value={this.state.landlord_address_3}
                                  name="landlord_address_3"
                                  onChange={this.changeInput}
                                  disabled={this.state.isView}
                                />
                                {/* <span className="form__form-group-error">{this.validator.message('Address Line 3', this.state.landlord_address_3, 'required')}</span> */}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 col-md-3">
                              <div className="form-group">
                                <label className="form__form-group-label">Suburb<span style={{ color: '#ff0000' }}>*</span></label>
                                <input
                                  type="text"
                                  className="custom-input"
                                  name="landlord_suburb"
                                  value={this.state.landlord_suburb}
                                  onChange={this.changeInput}
                                  disabled={this.state.isView}
                                />
                                 <span className="form__form-group-error">{this.validator.message('Suburb', this.state.landlord_suburb, 'required')}</span> 
                              </div>
                            </div>
                            <div className="col-12 col-md-3">
                              <div className="form-group">
                                <label className="form__form-group-label">
                                  State<span style={{ color: '#ff0000' }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="custom-input"
                                  name="landlord_state"
                                  value={this.state.landlord_state}
                                  onChange={this.changeInput}
                                  disabled={this.state.isView}
                                />
                                <span className="form__form-group-error">
                                  {this.validator.message(
                                    'State',
                                    this.state.landlord_state,
                                    'required',
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className="col-12 col-md-3">
                              <div className="form-group">
                                <label className="form__form-group-label">
                                  Post Code<span style={{ color: '#ff0000' }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="custom-input"
                                  name="landlord_post_code"
                                  value={this.state.landlord_post_code}
                                  onChange={this.changeInput}
                                  disabled={this.state.isView}
                                />
                                <span className="form__form-group-error">
                                  {this.validator.message(
                                    'PostCode',
                                    this.state.landlord_post_code,
                                    'required',
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 col-md-3">
                              <div className="form-group">
                                <label className="form__form-group-label">
                                  Country<span style={{ color: '#ff0000' }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="custom-input"
                                  name="landlord_country"
                                  value={this.state.landlord_country}
                                  onChange={this.changeInput}
                                  disabled={this.state.isView}
                                />
                                <span className="form__form-group-error">
                                  {this.validator.message(
                                    'Country',
                                    this.state.landlord_country,
                                    'required',
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        id="collapseOne"
                        className="collapse show mb-4"
                        data-parent="#contacts_accordion"
                      >
                        <div className="card-body">
                          <div className="mb-4 pl-0">
                            <h3 className="page-title mb-0 mr-4" data-toggle="collapse">
                              {' '}
                              Financial{' '}
                            </h3>
                          </div>
                          <div className="row">
                            <div className="col-12 col-md-3">
                              <div className="form-group">
                                <label>Invoice Landlord</label>
                              </div>
                            </div>
                            <div className="col-12 col-md-3">
                              <div className="form__form-group tools_radio_btn">
                                <div className="form__form-group-field">
                                  <label className={`colored-click radio-btn--colored`}>
                                    <input
                                      className="radio-btn__radio"
                                      type="radio"
                                      id="landlord_invoice"
                                      name="landlord_invoice"
                                      value={1}
                                      checked={this.state.landlord_invoice === 1}
                                      onChange={this.changeInput}
                                      disabled={this.state.isView}
                                    />
                                    <span className="radio-btn__radio-custom" />
                                    <span className="radio-btn__label">Yes</span>
                                  </label>
                                  <label className={`colored-click radio-btn--colored`}>
                                    <input
                                      className="radio-btn__radio"
                                      type="radio"
                                      id="landlord_invoice"
                                      name="landlord_invoice"
                                      value={0}
                                      checked={this.state.landlord_invoice === 0}
                                      onChange={this.changeInput}
                                      disabled={this.state.isView}
                                    />
                                    <span className="radio-btn__radio-custom" />
                                    <span className="radio-btn__label">No</span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 col-md-3">
                              <div className="form-group">
                                <label className="form__form-group-label">
                                  Invoice Type<span style={{ color: '#ff0000' }}>*</span>
                                </label>
                                <Select
                                  name="invoice_type"
                                  type="text"
                                  options={invoiceTypeOption}
                                  classNamePrefix="react-select"
                                  className="react-select"
                                  value={this.state.invoice_type}
                                  onChange={(e) => this.handleChange(e, 'invoice_type')}
                                  disabled={this.state.isView}
                                />
                                <span className="form__form-group-error">
                                  {this.validator.message(
                                    'Invoice Type',
                                    this.state.invoice_type,
                                    'required',
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className="col-12 col-md-3">
                              <div className="form-group">
                                <label className="form__form-group-label">
                                  Base Price(Before Accrual)$
                                  <span style={{ color: '#ff0000' }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="custom-input"
                                  name="price"
                                  value={this.state.price}
                                  onChange={this.changeInput}
                                  disabled={this.state.isView}
                                />
                                <span className="form__form-group-error">
                                  {this.validator.message(
                                    'Base Price',
                                    this.state.price,
                                    'required|numeric',
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className="col-12 col-md-3">
                              <div className="form-group">
                                <label className="form__form-group-label">
                                  + Tax $<span style={{ color: '#ff0000' }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="custom-input"
                                  name="tax"
                                  value={this.state.tax}
                                  onChange={this.changeInput}
                                  disabled={this.state.isView}
                                />
                                <span className="form__form-group-error">
                                  {this.validator.message(
                                    'Tax',
                                    this.state.tax,
                                    'required|numeric',
                                  )}
                                </span>
                              </div>
                            </div>
                            {!this.state.isView && (
                              <div className="col-12 col-md-3 mt-4">
                                <div className="form-group">
                                  <label className="form__form-group-label"> </label>
                                  <Button onClick={this.saveProperty}>Save Changes</Button>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="row">
                            <div className="col-12 col-md-3">
                              <div className="form-group">
                                <label className="form__form-group-label">
                                  Total $<span style={{ color: '#ff0000' }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="custom-input"
                                  name="total"
                                  value={
                                    this.state.price &&
                                    this.state.tax &&
                                    parseInt(this.state.price) + parseInt(this.state.tax)
                                  }
                                  onChange={this.changeInput}
                                  disabled="true"
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-3">
                              <div className="form-group">
                                <label className="form__form-group-label">
                                  Status<span style={{ color: '#ff0000' }}>*</span>
                                </label>
                                <Select
                                  name="status"
                                  type="text"
                                  options={status}
                                  classNamePrefix="react-select"
                                  className="react-select"
                                  value={this.state.status}
                                  onChange={(e) => this.handleChange(e, 'status')}
                                  disabled={this.state.isView}
                                />
                                <span className="form__form-group-error">
                                  {this.validator.message('Status', this.state.status, 'required')}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className=" w-100 d-flex justify-content-end">
                        {!this.state.isView && (
                          <Button className="btn-group--icons mb-0" onClick={this.saveProperty}>
                            Save Changes
                          </Button>
                        )}
                        <button className="btn-gray" onClick={this.backToFrom}>
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                  {this.state.errorModal ? (
                    <ModalComponent
                      modal={this.state.errorModal}
                      color={this.state.errorType}
                      isCancel={true}
                      message={this.state.errorMessage}
                      onConfirm={(e) => this.DeleteStaff(e)}
                      onClose={() => this.setState({ errorModal: false })}
                    />
                  ) : null}
                  {this.state.accessErrorModal ? (
                    <ModalComponent
                      modal={this.state.accessErrorModal}
                      color="danger"
                      isCancel={false}
                      message="You do not have access to this, please contact your manager for assistance"
                      onConfirm={(e) => this.accessErrorModalClose(e)}
                      onClose={(e) => this.accessErrorModalClose(e)}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default reduxForm({
  form: 'add_staff_form_validation',
})(withRouter(withTranslation('common')(AddProperty)));
