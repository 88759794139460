import Section from '@components/section/section.component'
import EmailTableWipFilter from '@containers/Communication/components/tables/email-table/email-table-with-filter.component'
import { FC } from 'react'
import { WipEmailSectionProps } from '../../../types/wip-communication.type'

const WipEmailSection: FC<WipEmailSectionProps> = ({ wipId }) => {
  return (
    <Section padding={false}>
      <EmailTableWipFilter params={{ wip_id: wipId }} />
    </Section>
  )
}

export default WipEmailSection
