import { FC } from 'react'
import TaskHeader from '../../../task-header'
import { TaskProps } from '../../../types'
import { PRIORITY_OPTIONS } from '../../../utils'
import styles from './notification-modal-header.module.scss'
import cls from 'classnames'
import { format } from 'date-fns'

interface NotificationModalHeaderProps {
  task: TaskProps
}

const NotificationModalHeader: FC<NotificationModalHeaderProps> = ({ task }) => {
  const priority = task.priority || 'Normal'

  const dueDate = format(new Date(task.due_date || new Date()), 'dd/MM/yyyy') || 'No due date'

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div
          className={cls(styles.text, styles.low, {
            [styles.low]: priority === 'Normal',
            [styles.medium]: priority === 'Medium',
            [styles.high]: priority === 'High',
          })}
        >
          <span>{PRIORITY_OPTIONS[priority] || PRIORITY_OPTIONS.Normal}</span>
        </div>
        <div>
          <span className={styles.elipsisContainer}>
            <TaskHeader assign_to={task.assign_to} />
          </span>
        </div>
      </div>
      <span className={styles.title}>{task.title || 'Name not found'}</span>
      <span className={styles.dueDate}>
        <b>Due Date</b>: {dueDate}
      </span>
    </div>
  )
}

export default NotificationModalHeader
