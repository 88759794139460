import LoaderWrapper from '@components/loader/loader-wrapper.component'
import SlideModal from '@components/slide-modal'
import { useGetCallLogById } from '@containers/Communication/hooks/get-call-log-by-id.hook'
import { CallLogModalProps, CallMadeTo } from '@containers/Communication/types/call.type'
import { Header, SubHeader, Text } from '@containers/Recovery/Wip/components/UI/text/text.component'
import { format } from 'date-fns'
import { FC, useEffect } from 'react'
import { toast } from 'react-toastify'
import SubSection from '@containers/Recovery/Wip/components/UI/sub-section/sub-section.component'

const CallLogModal: FC<CallLogModalProps> = ({ id, ...modalProps }) => {
  const {
    data: callLog,
    isLoading,
    isError,
  } = useGetCallLogById(id, { join: ['staffMadeCall', 'clientContact', 'debtor'] })

  useEffect(() => {
    if (!isError) return

    toast.error('Failed to fetch call log')
    console.error('Failed to fetch call log', id)
    modalProps.onClose()
  }, [isError])

  const nameTo =
    callLog?.madeTo === CallMadeTo.Debtor
      ? `${callLog?.debtor?.name_first} ${callLog?.debtor?.name_last}`
      : `${callLog?.clientContact?.first_name} ${callLog?.clientContact?.last_name}`

  return (
    <SlideModal
      style={{ width: '620px' }}
      layoutStyle={{ backgroundColor: 'black', zIndex: 1000 }}
      {...modalProps}
    >
      {isLoading || !callLog ? (
        <LoaderWrapper />
      ) : (
        <div>
          <Header mb={4}>Call Log</Header>
          <SubSection flex p={4}>
            <SubHeader>From:</SubHeader>
            <Text>{callLog.staffMadeCall.nickname}</Text>
          </SubSection>
          <SubSection flex p={4}>
            <SubHeader>To:</SubHeader>
            <Text>{`${nameTo} | ${callLog.phoneTo}`}</Text>
          </SubSection>
          <SubSection flex p={4}>
            <SubHeader>Date:</SubHeader>
            <Text>{`${format(callLog.createdAt, 'dd-MM-yyyy')} ${format(
              callLog.createdAt,
              'kk:mm:ss',
            )}`}</Text>
          </SubSection>
        </div>
      )}
    </SlideModal>
  )
}

export default CallLogModal
