import { useCreateDebtorAddressMutation } from '@containers/Recovery/Wip/_modules/Debtors/hooks/mutations/create-debtor-address.hook'
import DebtorAddressForm from './debtor-address-form.component'
import { FC } from 'react'
import { Address } from '@components/inputs/form-input/geo-suggest.type'
import { toast } from 'react-toastify'
import { CreateDebtorAddressFormProps } from '@containers/Recovery/Wip/_modules/Debtors/types/debtor-address.type'

const CreateDebtorAddressForm: FC<CreateDebtorAddressFormProps> = ({ debtorId, onSuccess }) => {
  const { mutateAsync, isLoading } = useCreateDebtorAddressMutation()

  const handleSuccessSubmit = async (address: Address) => {
    try {
      const debtorAddress = await mutateAsync({
        ...address,
        debtor: { id: debtorId },
        city: 'Unknown',
      })
      onSuccess && onSuccess(debtorAddress)
    } catch (e) {
      toast.error('Something went wrong')
      console.log(e)
    }
  }

  return <DebtorAddressForm onSuccessSubmit={handleSuccessSubmit} loading={isLoading} />
}

export default CreateDebtorAddressForm
