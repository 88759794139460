import axios from "axios";
import { CreateDebtorWorkPlaceData, DebtorWorkPlace } from "../types/debtor-associate-detail.type";
import { REACT_APP_API_URL } from "@src/lib/global-variables";
import { DeepPartial } from "react-hook-form";

export class DebtorWorkPlaceService {

  async createDebtorWorkPlace(data: CreateDebtorWorkPlaceData) {
    const res = await axios.post<DebtorWorkPlace>(`${REACT_APP_API_URL}/debtor-work-places`, data)
    return res.data
  }

  async deleteDebtorWorkPlace(id: number) {
    const res = await axios.delete<DebtorWorkPlace>(`${REACT_APP_API_URL}/debtor-work-places/${id}`)
    return res.data
  }

  async updateDebtorWorkPlace(id: number, data: DeepPartial<DebtorWorkPlace>) {
    const res = await axios.put<DebtorWorkPlace>(`${REACT_APP_API_URL}/debtor-work-places/${id}`, data)
    return res.data
  }
}

