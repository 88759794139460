import { REACT_APP_API_URL } from '@src/lib/global-variables'
import { PaginationResponse, Params } from '@src/types/service.type'
import axios from 'axios'
import { Client } from '../types/client.type'
import { Wip } from '@containers/Recovery/Wip/types/wip.type'

export class ClientService {
  async getClients(params?: Params) {
    const res = await axios.get<PaginationResponse<Client[]>>(`${REACT_APP_API_URL}/companies`, {
      params,
    })
    return res.data
  }

  async getClientById(id: number, params?: Params) {
    const res = await axios.get<Client>(`${REACT_APP_API_URL}/companies/${id}`, { params })
    return res.data
  }

  async getClientWips(id: number, params?: Params): Promise<PaginationResponse<Wip[]>> {
    const res = await axios.get<PaginationResponse<Wip[]>>(
      `${REACT_APP_API_URL}/companies/${id}/wips`,
      { params },
    )
    return res.data
  }
}

export const ClientServiceInstance = new ClientService()
