import { REACT_APP_API_URL } from '../lib/global-variables'
import BaseService from './BaseService'
import axios from 'axios'

export default class WIPService extends BaseService {
  constructor() {
    super()
  }

  async createWip(wipData) {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/wips`, wipData)

      if (response.status !== 201) {
        throw new Error('Error occured while WIP creation')
      }

      return response.data
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async createProtectCollectWip(wipData) {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/protect-and-collect-form/wip`,
        wipData,
      )

      if (response.status !== 201) {
        throw new Error('Error occured while WIP creation')
      }

      return response.data
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async getWips(params = {}) {
    try {
      const response = await axios.get(`${REACT_APP_API_URL}/wips`, { params })

      if (response.status !== 200) {
        throw new Error('Error occured fetching WIPs')
      }

      return response.data
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async getWipsId(params = {}) {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/wips/${params.wip_id}`, {
        params,
      })

      if (response.status !== 200) {
        throw new Error('Error occured fetching WIPs')
      }

      return response
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async editWipsId(params) {
    try {
      const response = await axios.patch(`${this.apiUrl}/wips/${params.id}`, params)

      if (response.status !== 200) {
        throw new Error('Error occured fetching WIPs')
      }

      return response
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async getWipsCover() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/wip-covers`)

      if (response.status !== 200) {
        throw new Error('Error occured fetching WIPs')
      }

      return response.data
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async getListFeeOption() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/wip-scalefees`)

      if (response.status !== 200) {
        throw new Error('Error occured fetching WIPs')
      }

      return response
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async editWPIFees(params) {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/wip-scalefees/${params.id}`,
        params,
      )

      if (response.status !== 200) {
        throw new Error('Error occured fetching WIPs')
      }

      return response
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async deleteWIPFees(params) {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/wip-scalefees/${params.id}`,
      )

      if (response.status !== 200) {
        throw new Error('Error occured fetching WIPs')
      }

      return response
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async addWIPFees(data) {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/wip-scalefees`, data)

      if (response.status !== 201) {
        throw new Error('Error occured while WIP creation')
      }

      return response.data
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async getWIPCost(params = {}) {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/wip-cost-lists`, {
        params,
      })

      if (response.status !== 200) {
        throw new Error('Error occured fetching WIPs')
      }

      return response
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async deleteWIPCost(params) {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/wip-cost-lists/${params.id}`,
      )

      if (response.status !== 200) {
        throw new Error('Error occured fetching WIPs')
      }

      return response
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async editWPICost(params) {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/wip-cost-lists/${params.id}`,
        params,
      )

      if (response.status !== 200) {
        throw new Error('Error occured fetching WIPs')
      }

      return response
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }
}
