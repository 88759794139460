import React, { Fragment, useEffect, useState } from 'react';
import { reduxForm } from 'redux-form';
import { Button, Card, CardBody, Col } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import WIPService from '../../../../services/WIPService';

import { PropertyDetails } from './PropertyDetails';
import { OwnersIndividual } from './OwnersIndividual';
import { ManagementDetails } from './ManagementDetails';
import { RecoveryDetails } from './RecoveryDetails';
import { AttachedFilesDetails } from './AttachedFilesDetails';
import { OwnersCompany } from './OwnersCompany';
import { AllOwners } from './AllOwners';
import Company from './companies';
import Loader from '../../../loading/loading';

const invoiceStatusOptions = [
  { value: 1, label: 'Paid' },
  { value: 2, label: 'Unpaid' },
  { value: 3, label: 'DISPUTED' },
  { value: 4, label: 'OTHER' },
];

const RecoveryForm = ({ onSubmit, defaultData, isEdit = false, loading }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [listFee, setListFee] = useState([]);
  const [companyID, setCompanyID] = useState(null);
  const [property, setProperty] = useState({ id: null });
  const [ownersIndividual, setOwnersIndividual] = useState([{}]);
  const [ownersCompany, setOwnersCompany] = useState([{}]);
  const [allOwners, setAllOwners] = useState(false);
  const [filesResponse, setFilesResponse] = useState([]);
  const [recoveryDetails, setRecoveryDetails] = useState({
    arrears_from: null,
    arrears_to: null,
    arrears: null,
    sub_total: null,
    total_amount: null,
    information: null,
    email: null,
  });
  const [managementDetails, setManagementDetails] = useState({
    sm_corporate_name: null,
    sm_abn: null,
    sm_address1: null,
    sm_address2: null,
    sm_address3: null,
    sm_suburb: null,
    sm_post_code: null,
    sm_country: null,
    sm_person_details: null,
    sm_email_reports: null,
    bc_name: null,
    bc_abn: null,
    bc_manager_name: null,
    bc_contact_person: null,
    bc_contact_details: null,
    bc_reports_email: null,
  });
  const [fullAddress, setFullAddress] = useState();
  const [propertyDetails, setPropertyDetails] = useState({
    property_select: null,
    recovery_list_fee: null,
    unit_address: null,
  });

  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  const wipService = new WIPService();

  const getWipCoverOptions = async () => {
    const response = await wipService.getWipsCover();
    if (response) {
      const option = response.data.map((e) => ({
        value: e.id,
        label: e.title,
      }));
    }
  };

  const getListFeeOptions = async () => {
    const response = await wipService.getListFeeOption();
    if (response) {
      const options = response.data.data.map((item) => ({
        value: item.id,
        label: item.list_fee,
      }));
      setListFee(options);
    }
  };

  const fetchDataToCreate = async () => {
    try {
      setIsLoading(true);
      await getListFeeOptions();
      await getWipCoverOptions();
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDataToCreate();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmitHandler = () => {
    onSubmit({
      company: {
        id: companyID,
      },
      property: {
        id: property,
      },
      full_address: fullAddress,
      owners_individual: ownersIndividual,
      owners_company: ownersCompany,
      recovery_details: recoveryDetails,
      management_details: managementDetails,
      is_all_owners: allOwners,
      files: filesResponse,
    });
  };

  useEffect(() => {
    if (defaultData && isEdit) {
      setOwnersIndividual(defaultData?.owners_individual || null);
      setOwnersCompany(defaultData?.owners_company || null);
      setRecoveryDetails(defaultData?.recovery_details || null);
      setManagementDetails(defaultData?.management_details || null);
      setAllOwners(defaultData?.is_all_owners || false);
      setFullAddress(defaultData?.full_address || null);
      setFilesResponse(defaultData?.files || null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeFullAddressHandler = (e) => {
    const value = e.target.value;
    setFullAddress(value);
  };

  useEffect(() => {
    if (defaultData) {
      setProperty({
        id: defaultData?.property?.id,
      });

      setCompanyID({
        id: defaultData?.company?.id,
      });
    }
  }, [defaultData]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Fragment>
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <form
              className="form form--horizontal"
              onSubmit={handleSubmit(onSubmitHandler)}
            >
              <PropertyDetails
                propertyDetails={propertyDetails}
                setProperty={setProperty}
                property={property}
                setPropertyDetails={setPropertyDetails}
                listFeeOptions={listFee}
                statusOptions={invoiceStatusOptions}
                errors={errors}
              />

              <Company setCompanyID={setCompanyID} companyID={companyID} />

              <div className="form__form-group">
                <span className="form__form-group-label">
                  Full Address<span style={{ color: '#ff0000' }}>*</span>
                </span>
                <div className="form__form-group-field">
                  <input
                    name="full_address"
                    type="text"
                    placeholder="Full Address"
                    required={true}
                    onChange={onChangeFullAddressHandler}
                    value={fullAddress}
                  />
                </div>
                <span className="form__form-group-error" />
              </div>

              <OwnersIndividual
                ownersIndividual={ownersIndividual}
                setOwnersIndividual={setOwnersIndividual}
                errors={errors}
              />

              <OwnersCompany
                ownersCompany={ownersCompany}
                setOwnersCompany={setOwnersCompany}
                errors={errors}
              />

              <AllOwners allOwners={allOwners} setAllOwners={setAllOwners} />

              <RecoveryDetails
                recoveryDetails={recoveryDetails}
                setRecoveryDetails={setRecoveryDetails}
                errors={errors}
              />

              <ManagementDetails
                managementDetails={managementDetails}
                setManagementDetails={setManagementDetails}
                errors={errors}
              />

              <AttachedFilesDetails
                filesResponse={filesResponse}
                setFilesResponse={setFilesResponse}
              />
              <Button
                color="primary"
                className="mb-0"
                type="submit"
                style={{ marginLeft: '122px', width: '125px' }}
              >
                {loading ? 'Loading...' : 'Save'}
              </Button>
            </form>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};

export default reduxForm({
  form: 'add_staff_form_validation', // a unique identifier for this form
})(withRouter(withTranslation('common')(RecoveryForm)));
