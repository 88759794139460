import { REACT_APP_API_URL } from "@src/lib/global-variables";
import { PaginationResponse, Params } from "@src/types/service.type";
import axios from "axios";
import { CreateWipData, ProtectAndCollectForm } from "../types/protect-and-collect-form.type";
import { Wip } from "@containers/Recovery/Wip/types/wip.type";


export class ProtectAndCollectFormService {

  async getProtectAndCollectForms(params?: Params) {
    const res = await axios.get<PaginationResponse<ProtectAndCollectForm[]>>(`${REACT_APP_API_URL}/protect-and-collect-form`, { params })
    return res.data
  }

  async deleteProtectAndCollectForm(id: number) {
    const res = await axios.delete(`${REACT_APP_API_URL}/protect-and-collect-form/${id}`)
    return res.data
  }

  async createWip(data: CreateWipData) {
    const response = await axios.post<Wip>(`${process.env.REACT_APP_API_URL}/protect-and-collect-form/wip`, data)
    return response.data
  }
}