import { FC } from 'react'
import styles from './table.module.scss'
import { ButtonPagination } from '@components/button-pagination/button-pagination.component'
import FormCheckbox from '@components/inputs/form-checkbox/form-checkbox.component'
import Loader from '@components/loader/loader.component'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'

interface TableProps {
  children?: React.ReactNode
  onSelect?: () => void
  loading?: boolean
  notFound?: boolean
  notFoundText?: string
  headers: string[]
  bodyHeight?: number
  onPageChange?: (page: number) => void
  totalCount?: number
  limit?: number
  page?: number
  paginated?: boolean
  selectable?: boolean
}

const Table: FC<TableProps> = ({
  headers,
  children,
  bodyHeight = 400,
  page = 1,
  totalCount = 0,
  limit = 5,
  onPageChange,
  notFoundText = 'Not found',
  paginated = true,
  selectable = false,
  loading = false,
  notFound = false,
}) => {
  const handleItemClick = (page: number): void => {
    onPageChange && onPageChange(page)
  }

  const handleNextForwardClick = (): void => {
    onPageChange && onPageChange(page + 1)
  }

  const handleBackActionClick = (): void => {
    onPageChange && onPageChange(page - 1)
  }

  const from = page === 1 ? (totalCount > 0 ? 1 : 0) : (page - 1) * limit
  const to = page * limit > totalCount ? totalCount : page * limit

  return (
    <div>
      <div className={styles.tableContainer}>
        <table className={styles.tableWrapper}>
          <thead className={styles.tableHeader}>
            <tr className={styles.tableHeaderRow}>
              {selectable ? (
                <th className={styles.tableHeaderRow}>
                  <FormCheckbox />
                </th>
              ) : null}
              {headers.map((header, index) => (
                <th key={`table-cell-${header}-${index}`} className={styles.tableHeaderCell}>
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className={styles.tableBody} style={{ height: bodyHeight }}>
            {!loading && !notFound ? children : null}
            <tr className={styles.tableFullSizeRow} />
          </tbody>
        </table>
        {loading ? <Loader className={styles.centerT} /> : null}
        {notFound ? (
          <div className={styles.centerT}>
            <Header>{notFoundText}</Header>
          </div>
        ) : null}
      </div>

      {paginated ? (
        <div className={styles.tablePaginationWrapper}>
          <span className={styles.paginationCount}>
            Showing{' '}
            <span>
              {from} - {to}
            </span>{' '}
            of <span>{totalCount}</span>
          </span>
          <ButtonPagination
            totalCount={totalCount || 1}
            currentPage={page}
            limit={limit}
            onItemClick={handleItemClick}
            onNextForwardClick={handleNextForwardClick}
            onBackActionClick={handleBackActionClick}
          />
        </div>
      ) : null}
    </div>
  )
}

export default Table
