export function getValueFromObjectByPath<T>(obj: T, path: string): any {
  const keys = path.split('.');
  let result: any = obj;

  for (const key of keys) {
      if (result == null) {
          return undefined;
      }
      result = result[key];
  }

  return result;
}