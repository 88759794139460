import { FC, useState } from "react";
import { ClaimDetailsNoteProps } from "../../types/claim-details.type";
import styles from './claim-details-note.module.scss'
import cls from 'classnames'
import moment from "moment";

const ClaimDetailsNote: FC<ClaimDetailsNoteProps> = ({note, lastElementRef, onOpenNoteClick}) => {
  const {id, created_at, total_amount, description} = note;

  const [isOpenButtonShown, setIsOpenButtonShown] = useState(false)

  const handleMouseEnter = () => {
    setIsOpenButtonShown(true)
  }

  const handleMouseLeave = () => {
    setIsOpenButtonShown(false)
  }

  return (
    <div
    key={id}
    className={cls(styles.noteItem)}
    ref={lastElementRef}
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
  >
    <div className={styles.noteItemContent}>
      <div className={styles.avatarWrapper}>
        <span className="lnr lnr-user"></span>
      </div>
      <div>
        <h5 className={styles.noteHeader}>Description</h5>
        <p className={styles.noteTitle}>{description}</p>
      </div>
    </div>
    <div style={{width: '20%'}}>
        <h5 className={styles.noteHeader}>Total</h5>
        <p className={styles.noteTitle}>{total_amount}</p>
      </div>
    <div className={styles.noteDate}>
      <p className="m-0">{moment(created_at).format('DD-MM-YYYY')}</p>
      <p className="m-0">{moment(created_at).format('kk:mm:ss')}</p>
    </div>
    {isOpenButtonShown ? (
      <div className={styles.noteOpenButton} onClick={() => onOpenNoteClick(id)}>
        <span className="lnr lnr-text-align-right"></span> OPEN
      </div>
    ) : null}
  </div>
  )
}

export default ClaimDetailsNote;