export const commercialUncoveredFormListSelector = (state) => state.commercialUncoveredList;
export const postCommercialUncoveredFormSelector = (state) => state.postCommercialUncoveredForm;
export const deleteCommercialUncoveredByIdSelector = (state) => state.deleteCommercialUncoveredById;
export const createWIPCommercialUncoveredSelector = (state) => state.createWIPCommercialUncovered;
export const commercialUncoveredFormByIdSelector = (state) => state.getCommercialUncoveredFormById;
export const editCommercialUncoveredFormSelector = (state) => state.editCommercialUncoveredForm;
export const getPropertyByIdSelector = (state) => state.getPropertyById;
export const getPaymentDetailsSelector = (state) => state.getPaymentDetails;
export const getInvoiceTemplatesListSelector = (state) => state.getInvoiceTemplatesList;
export const postInvoiceSelector = (state) => state.postInvoice;
export const postInvoiceDataSelector = (state) => state.postInvoice.data;
export const getInvoicePDFSelector = (state) => state.getInvoicePDF;
export const getInvoicesListSelector = (state) => state.getInvoicesListS;
export const getInvoiceByIDSelector = (state) => state.getInvoiceByID;
export const editInvoiceByIDSelector = (state) => state.editInvoiceByID;
export const deleteInvoiceByIDSelector = (state) => state.deleteInvoiceByID;
export const createInvoiceTemplateLoading = (state) => state.createInvoiceTemplateReducer.loading;
export const editInvoiceTemplateLoading = (state) => state.editInvoiceTemplateReducer.loading;
export const getCompaniesByIdData = (state) => state.getCompaniesByIdReducer.data.data;
export const postInvoiceEmailLoading = (state) => state.postInvoiceEmailReducer.loading;
export const getEmailTemplatesListSelector = (state) => state.getEmailTemplatesList;
