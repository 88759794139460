import Table from '@components/table/table.component'
import { useGetProtectAndCollectForms } from '../../../hooks/crud-protect-and-collect-forms.hook'
import { useState } from 'react'
import ProtectAndCollectTableRow from './protect-and-collect-table-row.component'

const ProtectAndCollectTable = () => {
  const [page, setPage] = useState(1)
  const {
    data: protectAndCollectForms,
    isLoading,
    isRefetching,
  } = useGetProtectAndCollectForms({ page, limit: 10, order: 'created_at,DESC' })

  const isLoadingOrRefetching = isLoading || isRefetching
  const notFound = !isLoadingOrRefetching && !protectAndCollectForms?.data?.length

  return (
    <Table
      headers={[
        'DATE',
        'TIME',
        'RECOVERY TYPE',
        'DEBTORS',
        'PROPERTY',
        'CLIENT',
        'STATUS',
        'ACTIONS',
      ]}
      onPageChange={(page) => setPage(page)}
      totalCount={protectAndCollectForms?.total}
      page={page}
      bodyHeight={10 * 54}
      limit={10}
      loading={isLoadingOrRefetching}
      notFound={notFound}
    >
      {protectAndCollectForms?.data.map((protectAndCollectForm) => (
        <ProtectAndCollectTableRow key={protectAndCollectForm.id} entity={protectAndCollectForm} />
      ))}
    </Table>
  )
}

export default ProtectAndCollectTable
