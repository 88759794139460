import { TaskNoteService } from "@containers/Communication/services/task-note.service"
import { CreateTaskNoteData } from "@containers/Communication/types/note.type"
import { useMutation } from "@tanstack/react-query"

export const useCreateTaskNoteMutation = () => {
  const createTaskNote = (data: CreateTaskNoteData) => {
    const taskNoteService = new TaskNoteService()
    return taskNoteService.createTaskNote(data)
  }

  return useMutation({ mutationFn: ({ data }: { data: CreateTaskNoteData }) => createTaskNote(data) })
}