import { FC, useState } from 'react'
import { DebtorLegalClaimNumber } from '../../../types/debtor-legal.type'
import { useModal } from '@src/hooks/useModal'
import { BaseListProps } from '@src/types/component-types'
import DebtorLegalClaimNumberListItem from './debtor-claim-number-list-item.component'
import EditDebtorLegalClaimNumberModal from '../../modals/debtor-claim-number-modal/edit-debtor-claim-number-modal.component'

const DebtorLegalClaimNumberList: FC<BaseListProps<DebtorLegalClaimNumber>> = ({
  entities: debtorLegalClaimNumbers,
}) => {
  const [isOpen, handleOpen, handleClose] = useModal(false)
  const [selectedDebtorLegalClaimNumber, setSelectedDebtorLegalClaimNumber] =
    useState<DebtorLegalClaimNumber | null>(null)

  const handleEditClick = (claimNumber: DebtorLegalClaimNumber) => {
    setSelectedDebtorLegalClaimNumber(claimNumber)
    handleOpen()
  }

  return (
    <div>
      {debtorLegalClaimNumbers.map((claimNumber) => {
        return (
          <DebtorLegalClaimNumberListItem
            onEditClick={handleEditClick}
            entity={claimNumber}
            key={claimNumber.id}
          />
        )
      })}
      {selectedDebtorLegalClaimNumber ? (
        <EditDebtorLegalClaimNumberModal
          entity={selectedDebtorLegalClaimNumber}
          isOpen={isOpen}
          onClose={handleClose}
        />
      ) : null}
    </div>
  )
}

export default DebtorLegalClaimNumberList
