import { FC, useEffect } from 'react'
import { ViewModalProps } from '../../../Setting/components/Contacts/types/combined-notes.type'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import axios from 'axios'
import { REACT_APP_API_URL } from '@src/lib/global-variables'
import { useQuery } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import SlideModal from '@components/slide-modal'
import styles from './note-modal.module.scss'
import { Oval } from 'react-loader-spinner'
import cls from 'classnames'
import SubSection from '@containers/Recovery/Wip/components/UI/sub-section/sub-section.component'
import { Header, SubHeader, Text } from '@containers/Recovery/Wip/components/UI/text/text.component'
import { format } from 'date-fns'

const TaskNoteModal: FC<ViewModalProps> = ({ isOpen, onClose, id }) => {
  const { data, isLoading, isRefetching, isError } = useQuery({
    queryKey: [GLOBAL_QUERIES.TASK_NOTES.GET_TASK_NOTE_BY_ID, id],
    queryFn: async () => {
      const response = await axios.get(`${REACT_APP_API_URL}/task-note/${id}`, {
        params: { join: 'staff_created' },
      })
      return response.data
    },
    enabled: !!id && isOpen,
  })

  useEffect(() => {
    if (isError) {
      toast.error('Something went wrong')
    }
  }, [isError])

  if (isError) return null

  const isTaskNoteLoading = isLoading || isRefetching

  return (
    <SlideModal
      isOpen={isOpen}
      onClose={onClose}
      style={{ width: '675px' }}
      layoutStyle={{ zIndex: 1000, backgroundColor: 'black' }}
    >
      <div className={styles.taskNoteModal}>
        <span onClick={onClose} className={cls('lnr lnr-indent-decrease', styles.closeIcon)}></span>
        {isTaskNoteLoading ? (
          <Oval
            visible={true}
            height="60"
            width="60"
            color="blue"
            secondaryColor="blue"
            ariaLabel="oval-loading"
            wrapperStyle={{
              position: 'absolute',
              top: '50%',
              right: '50%',
              transform: 'translate(50%,-50%)',
            }}
          />
        ) : null}

        {!isTaskNoteLoading && data ? (
          <div>
            <SubSection mb={4}>
              <Header>Task Note</Header>
            </SubSection>
            <SubSection>
              <SubHeader>Created By:</SubHeader>
              <Text>{data.createdBy}</Text>
            </SubSection>
            <SubSection>
              <SubHeader>Created On:</SubHeader>
              <Text>{format(data.createdDate, 'dd/MM/yyyy')}</Text>
            </SubSection>
            <SubSection>
              <SubHeader>Note: </SubHeader>
              <Text>{data.note}</Text>
            </SubSection>
          </div>
        ) : null}
      </div>
    </SlideModal>
  )
}

export default TaskNoteModal
