import React from 'react';

import { Grid, Typography } from '@mui/material';
import FormInput from '../../../../../../../../../shared/components/FormInput/FormInput';

import { invoiceFormStyles } from '../../../../styles';

const BillTo = ({ handleChange, values, errors, touched }) => {
  return (
    <Grid sx={invoiceFormStyles.billFormContainer}>
      <Typography sx={invoiceFormStyles.title}>Bill to:</Typography>

      <FormInput
        label="Client:"
        placeholder="name"
        name="bill_to.name"
        changeHandler={handleChange}
        value={values?.bill_to?.name || undefined}
        error={errors?.bill_to?.name}
        touched={touched?.bill_to?.name}
        column
      />

      <FormInput
        label="Email Address:"
        placeholder="email"
        name="bill_to.email"
        changeHandler={handleChange}
        value={values?.bill_to?.email || undefined}
        error={errors?.bill_to?.email}
        touched={touched?.bill_to?.email}
        column
      />

      <FormInput
        label="Billing Address:"
        placeholder="Address line 1"
        name="bill_to.address_1"
        changeHandler={handleChange}
        value={values?.bill_to?.address_1 || undefined}
        error={errors?.bill_to?.address_1}
        touched={touched?.bill_to?.address_1}
        column
      />

      <FormInput
        column
        placeholder="Address line 2"
        name="bill_to.address_2"
        changeHandler={handleChange}
        value={values?.bill_to?.address_2 || undefined}
        error={errors?.bill_to?.address_2}
        touched={touched?.bill_to?.address_2}
      />
      <FormInput
        column
        placeholder="Address line 3"
        name="bill_to.address_3"
        changeHandler={handleChange}
        value={values?.bill_to?.address_3 || undefined}
        error={errors?.bill_to?.address_3}
        touched={touched?.bill_to?.address_3}
      />

      <Grid sx={invoiceFormStyles.billHalfContainer}>
        <Grid sx={invoiceFormStyles.billHalf}>
          <FormInput
            label="Suburb"
            placeholder="Suburb"
            name="bill_to.suburb"
            changeHandler={handleChange}
            value={values?.bill_to?.suburb || undefined}
            error={errors?.bill_to?.suburb}
            touched={touched?.bill_to?.suburb}
            required
            column
          />

          <FormInput
            label="Postcode"
            placeholder="Postcode"
            name="bill_to.post_code"
            changeHandler={handleChange}
            value={values?.bill_to?.post_code || undefined}
            error={errors?.bill_to?.post_code}
            touched={touched?.bill_to?.post_code}
            required
            column
          />
        </Grid>
        <Grid sx={invoiceFormStyles.billHalf}>
          <FormInput
            label="State"
            placeholder="State"
            name="bill_to.state"
            changeHandler={handleChange}
            value={values?.bill_to?.state || undefined}
            error={errors?.bill_to?.state}
            touched={touched?.bill_to?.state}
            required
            column
          />

          <FormInput
            label="Country"
            placeholder="Country"
            name="bill_to.country"
            changeHandler={handleChange}
            value={values?.bill_to?.country || undefined}
            error={errors?.bill_to?.country}
            touched={touched?.bill_to?.country}
            required
            column
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BillTo;
