import Modal from '@components/modal'
import { BaseEditModalProps } from '@src/types/component-types'
import { FC } from 'react'
import EditLandlordContactForm from '../../form/landlord-contact-form/edit-landlord-contact-form.component'
import { LandlordContact } from '@containers/Sales/Properties/types/property.type'
import styles from './landlord-contact-modal.module.scss'

interface EditLandlordContactModalProps extends BaseEditModalProps<LandlordContact> {
  onSuccessSubmit?: (landlordContact: LandlordContact) => void
}

const EditLandlordContactModal: FC<EditLandlordContactModalProps> = ({
  entity,
  onSuccessSubmit,
  ...modalProps
}) => {
  const handleSuccessSubmit = (landlordContact: LandlordContact) => {
    onSuccessSubmit && onSuccessSubmit(landlordContact)
    modalProps.onClose()
  }

  return (
    <Modal className={styles.modalWrapper} {...modalProps} withBtns={false}>
      <EditLandlordContactForm
        entity={entity}
        onSuccessSubmit={handleSuccessSubmit}
        onCancelClick={modalProps.onClose}
      />
    </Modal>
  )
}

export default EditLandlordContactModal
