import { Wip } from '@containers/Setting/components/Contacts/taskboard/types'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import { REACT_APP_API_URL } from '@src/lib/global-variables'
import { PaginationResponse } from '@src/types/service.type'
import { useInfiniteQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useMemo } from 'react'

export const useGetWipsOptions = (params, enabled = true) => {
  const { data, ...restQuery } = useInfiniteQuery({
    queryKey: [GLOBAL_QUERIES.CLAIM_DETAILS_NOTES.GET, params],
    queryFn: async ({ pageParam }) => {
      const page = pageParam ? pageParam : 1
      const limit = params.limit
      const response = await axios.get<PaginationResponse<Wip[]>>(`${REACT_APP_API_URL}/wips`, {
        params: { ...params, limit, page },
      })
      const data = response.data
      return {
        results: data.data,
        page: page < data.pageCount ? page + 1 : undefined,
      }
    },
    cacheTime: 0,
    enabled,
    refetchOnWindowFocus: false,
    getNextPageParam: (lastPage) => lastPage.page,
    staleTime: 1000 * 60 * 5,
  })

  const flattenedData = useMemo(
    () =>
      data
        ? data?.pages.flatMap((item) =>
            item.results.map((wip) => ({ label: `WIP ${wip.id}`, value: wip.id })),
          )
        : [],
    [data],
  )

  return { data: flattenedData, ...restQuery }
}
