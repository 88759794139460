import { FC } from 'react'
import { Client } from '../../types/client.type'
import SubNavBar from '@components/nav-bar/sub-nav-bar.component'
import { Redirect, Route, useLocation, useRouteMatch } from 'react-router'
import ClientCommunicationNotesPage from './views/client-communication-notes-page/client-communication-notes-page.component'
import ClientCommunicationEmailsPage from './views/client-communication-emails-page/client-communication-email-page.component'
import ClientCommunicationSmsPage from './views/client-communication-sms-page/client-communication-sms-page.component'

interface ClientCommunicationPageProps {
  client: Client
}

const options = [
  {
    label: 'Notes',
    url: 'notes',
  },
  {
    label: 'Emails',
    url: 'emails',
  },
  {
    label: 'Sms',
    url: 'sms',
  },
  {
    label: 'Letters',
    url: 'letters',
  },
  {
    label: 'Reports',
    url: 'reports',
  },
]

const ClientCommunicationPage: FC<ClientCommunicationPageProps> = ({ client }) => {
  const { path } = useRouteMatch()
  const location = useLocation()

  return (
    <div>
      <SubNavBar options={options} activeUrl={location.pathname} />
      <Route
        exact
        path={`${path}/notes`}
        render={() => <ClientCommunicationNotesPage client={client} />}
      />
      <Route
        exact
        path={`${path}/emails`}
        render={() => <ClientCommunicationEmailsPage client={client} />}
      />
      <Route
        exact
        path={`${path}/sms`}
        render={() => <ClientCommunicationSmsPage client={client} />}
      />
      <Route exact path={`${path}/letters`} render={() => <div>letters</div>} />
      <Route exact path={`${path}/reports`} render={() => <div>reports</div>} />
      <Route
        path={'*'}
        render={() => <Redirect to={`/client/${client.id}/communication/notes`} />}
      />
    </div>
  )
}

export default ClientCommunicationPage
