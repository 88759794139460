import Table from '@components/table/table.component'
import { useGetReports } from '@containers/Communication/hooks/get-reports.hook'
import { Params } from '@src/types/service.type'
import { FC, useState } from 'react'
import ReportTableItem from './report-table-item.component'

interface ReportTableProps {
  params?: Params
}

const ReportTable: FC<ReportTableProps> = ({ params }) => {
  const [page, setPage] = useState(1)
  const { data, isLoading, isRefetching } = useGetReports({ ...params, page, limit: 5})

  const isReportsLoading = isLoading || isRefetching
  const isReportsNotFound = !isReportsLoading && !data?.data.length
  return (
    <Table
      headers={['DATE', 'TIME', 'SUBJECT']}
      notFound={isReportsNotFound}
      loading={isReportsLoading}
      bodyHeight={params?.limit ? params?.limit * 54 : 5 * 54}
      totalCount={data?.total}
      page={page}
      onPageChange={setPage}
    >
      {data?.data?.map((report) => <ReportTableItem key={report.id} entity={report} />)}
    </Table>
  )
}

export default ReportTable
