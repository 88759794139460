import { GLOBAL_QUERIES } from "@src/lib/global-queries"
import { useQuery } from "@tanstack/react-query"
import { BaseGraphApiParams, GetMessageInsideSharedFolderData } from "../types/graph-api-mail.type"
import { useIsAuthenticated, useMsal } from "@azure/msal-react"
import { GraphApiMailService } from "../services/graph-api-mail.service"

export const useGetMessageInsideSharedFolder = ({ userId, messageId, folderName = 'Inbox' }: Omit<GetMessageInsideSharedFolderData, 'accessToken'>, params: BaseGraphApiParams = {}) => {
  const isAuthenticated = useIsAuthenticated()
  const { accounts, instance } = useMsal()

  const getMessageInsideSharedFolder = async (data: Omit<GetMessageInsideSharedFolderData, 'accessToken'>, params: BaseGraphApiParams) => {
    const graphApiMailService = new GraphApiMailService();
    const account = accounts[0] || {};

    try {
      const authResult = await instance.acquireTokenSilent({ scopes: ['Mail.Read','Mail.Read.Shared', 'Mail.ReadWrite.Shared'], account: account })
      const message = await graphApiMailService.getMessageInsideSharedFolder({ ...data, accessToken: authResult.accessToken }, params)
      return message
    } catch (e) {
      console.log(e)
    }
  }

  const query = useQuery({
    queryKey: [GLOBAL_QUERIES.GRAPH_API_MESSAGES.GET_BY_ID, messageId, userId, params],
    queryFn: () => getMessageInsideSharedFolder({ userId, messageId, folderName }, params),
    enabled: isAuthenticated && !!userId && !!messageId
  })

  return query;
}