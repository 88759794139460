import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useDispatch, useSelector } from 'react-redux';
import { commercialUncoveredFormListSelector } from '../../../../redux/selector/selector';
import { getCommercialUncoveredList } from '../../../../redux/actions';

import { TableContainer, Table, TableBody } from '@material-ui/core';
import { Grid, Stack, Pagination } from '@mui/material';

import EnhancedTableHead from '../../../../shared/components/TableHead/TableHead';
import CommercialUncoveredTableRow from './TableRow/TableRow';
import Loader from '../../../loading/loading';

import { getComparator } from '../../../../shared/helpers/getComparator';
import { tableSort } from '../../../../shared/helpers/tableSort';
import { SPROTECT_AND_COLLECT_LIST_TABLE_HEAD_ITEMS } from '../../../../shared/utils/protectAndCollectListTableHead.constants';

import { commercialUncoveredStyles } from '../styles';
import { useStyles } from './useStyles';

const CommercialUncoveredFormList = () => {
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('time_created');

  const classes = useStyles();
  const dispatch = useDispatch();
  const { data, loading } = useSelector(commercialUncoveredFormListSelector);

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    dispatch(getCommercialUncoveredList(page));
  }, [dispatch]);

  const handleChange = (_, value) => {
    setPage(value);
    dispatch(getCommercialUncoveredList(value));
  };

  return (
    <Grid className={'container page-body-wrapper position-relative pb-4'} md={12} lg={12}>
      <h3 className="page-title mb-5">Commercial Uncovered</h3>
      <Grid sx={commercialUncoveredStyles?.card}>
        <Link to="/recoveries/forms/create-commercial" className="btn btn-secondary">
          Create Commercial Uncovered Form
        </Link>
        <TableContainer>
          <Table className={classes.table} aria-labelledby="tableTitle" aria-label="enhanced table">
            <EnhancedTableHead
              items={SPROTECT_AND_COLLECT_LIST_TABLE_HEAD_ITEMS}
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {loading ? (
                <Loader />
              ) : (
                tableSort(data?.data, getComparator(order, orderBy))?.map((row, index) => {
                  return <CommercialUncoveredTableRow row={row} key={index} />;
                })
              )}
            </TableBody>
          </Table>

          <Stack spacing={2} style={{ marginTop: '30px' }}>
            <Pagination count={data?.pageCount} page={page} onChange={handleChange} />
          </Stack>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default CommercialUncoveredFormList;
