import { getEmailTemplateByIdActionTypes } from '../actionTypes';
import { getEmailTemplateByIdService } from '../service';

export const getEmailTemplateById = (id) => async (dispatch) => {
  dispatch({ type: getEmailTemplateByIdActionTypes.GET_EMAIL_TEMPLATE_BY_ID_LOADING });

  try {
    const res = await getEmailTemplateByIdService(id);
    dispatch({
      type: getEmailTemplateByIdActionTypes.GET_EMAIL_TEMPLATE_BY_ID_SUCCESS,
      payload: res,
    });

    return res;
  } catch (err) {
    dispatch({
      type: getEmailTemplateByIdActionTypes.GET_EMAIL_TEMPLATE_BY_ID_ERROR,
      payload: err,
    });
    throw err;
  }
};
