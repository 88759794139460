import Modal from '@components/modal'
import styles from './set-mail-modal.module.scss'
import { TextEditor } from '@src/shared/components/TextEditor/TextEditor'
import LabelWrapper from '@components/label-wrapper'
import Select from 'react-select'
import { Controller, useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'

import { fetchEmailTemplate } from '../utils'
import { joiResolver } from '@hookform/resolvers/joi'
import { SetEmailScheme } from './validation'
import { ErrorMessage } from '@hookform/error-message'
interface IProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: ({ message, subject, templateId }: { message: string; subject: string, templateId: number | null}) => void
  initialValues?: {
    email_subject: string
    email_template: string
    email_template_id: number | null
  }
}

interface IForm {
  template: {label: string, value: {body: string, id: number}} | undefined
  message: string
  subject: string
}

const SetMailModal = ({
  isOpen,
  onClose = () => null,
  onSubmit = () => null,
  initialValues,
}: IProps) => {
  const [emailTemplates, setEmailTemplates] = useState<any[]>([])

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<IForm>({
    defaultValues: {
      template: undefined,
      message: '',
      subject: '',
    },
    mode: 'all',
    resolver: joiResolver(SetEmailScheme),
  })

  const onFormSubmit = (data: IForm) => {
    const { message, subject, template } = data
    onSubmit({ message: message || '', subject: subject || '', templateId: template?.value?.id || null  })
    onClose()
  }
  useEffect(() => {
    if (!isOpen) {
      reset({}, { keepDirty: false, keepDefaultValues: true, keepValues: false })
    }
  }, [isOpen])

  useEffect(() => {
    fetchEmailTemplate().then((res) => {
      setEmailTemplates(res)
    })
  }, [])

  useEffect(() => {
    const subscription = watch(({ template }, { name }) => {
      if (template && name === 'template') {
        if (template?.value?.body) {
          console.log('change template')
          setValue('message', template.value.body)
          return
        }
      }
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [watch])

  useEffect(() => {
    if (initialValues && isOpen && emailTemplates) {
      reset({
        subject: initialValues.email_subject,
        message: initialValues.email_template,
        template:
          emailTemplates.find((option) => option.value.id === initialValues.email_template_id) || null,
      })
    }
  }, [initialValues, emailTemplates, isOpen])

  return (
    <Modal
      onSubmit={handleSubmit(onFormSubmit)}
      isOpen={isOpen}
      onClose={onClose}
      isLayoutOverlap
      style={{ zIndex: 1001 }}
      saveBtnText="Save"
      canecelBtnText="Back"
    >
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <LabelWrapper<IForm> control={control} name="template" label="Template">
            {({ onChange, value }) => {
              return (
                <Select
                  className="react-select"
                  options={emailTemplates}
                  value={value}
                  onChange={onChange}
                />
              )
            }}
          </LabelWrapper>
        </div>
        <div className={styles.content}>
          <LabelWrapper<IForm> control={control} name="subject" label="Subject">
            {({ onChange, value }) => {
              return <input className="react-select" value={value as string} onChange={onChange} />
            }}
          </LabelWrapper>
        </div>
        <div style={{ zIndex: 0 }}>
          <span>
            <ErrorMessage
              errors={errors}
              name={'message'}
              render={({ message }) => {
                return <p style={{ color: 'red' }}>{message}</p>
              }}
            />
          </span>
          <Controller
            control={control}
            name="message"
            render={({ field }) => (
              <TextEditor value={field.value} onEditorChange={field.onChange} />
            )}
          />
        </div>
      </div>
    </Modal>
  )
}

export default SetMailModal
