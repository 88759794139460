import Section from '@components/section/section.component'
import styles from './payment-tracking-section.module.scss'
import { Header, SubHeader } from '@containers/Recovery/Wip/components/UI/text/text.component'

const NotFoundPaymentTrackingSection = () => {
  return (
    <Section className={styles.sectionWrapper}>
      <Header mb={4}>Payment Tracking</Header>
      <SubHeader>No payment tracking found</SubHeader>
    </Section>
  )
}

export default NotFoundPaymentTrackingSection
