import { getInvoiceByIDActionEnum } from '../actionTypes';
import { getInvoicByIDService } from '../service';

export const getInvoiceById = (id) => async (dispatch) => {
  dispatch({ type: getInvoiceByIDActionEnum.IS_LOADING_GET_INVOICE_BY_ID });
  try {
    const res = await getInvoicByIDService(id);

    dispatch({
      type: getInvoiceByIDActionEnum.GET_INVOICE_BY_ID_SUCCESS,
      payload: res,
    });

    return res;
  } catch (error) {
    dispatch({
      type: getInvoiceByIDActionEnum.GET_INVOICE_BY_ID_ERROR,
      payload: error,
    });
  }
};
