import * as React from "react"
const ShareIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M18 2a3 3 0 0 0-3 3 3 3 0 0 0 .055.56L7.939 9.71A3 3 0 0 0 6 9a3 3 0 0 0-3 3 3 3 0 0 0 3 3 3 3 0 0 0 1.936-.713l7.119 4.152A3 3 0 0 0 15 19a3 3 0 0 0 3 3 3 3 0 0 0 3-3 3 3 0 0 0-3-3 3 3 0 0 0-1.938.713L8.945 12.56A3 3 0 0 0 9 12a3 3 0 0 0-.055-.56l7.116-4.15A3 3 0 0 0 18 8a3 3 0 0 0 3-3 3 3 0 0 0-3-3z" />
  </svg>
)
export default ShareIcon
