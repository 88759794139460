import React from 'react';

import { useSelector } from 'react-redux';
import { getInvoiceTemplatesListSelector } from '../../../../../../../../redux/selector/selector';

import { Grid } from '@mui/material';
import FormInput from '../../../../../../../../shared/components/FormInput/FormInput';
import FormSelect from '../../../../../../../../shared/components/FormSelect/FormSelect';
import AppButton from '../../../../../../../../shared/components/AppButton/AppButton';

import trashicon from '../../../../../../../../assets/image/form-images/trash-icon-white.png';
import { invoiceFormStyles } from '../../../styles';
import { invoicetemplatesListOptionsById } from '../../../../../../../../shared/helpers/invoicetemplatesListOptions';

const Row = ({ handleChange, values, errors, touched, itemIndex, setFieldValue }) => {
  const templatesList = useSelector(getInvoiceTemplatesListSelector);

  const removeItemHandler = () => {
    if (itemIndex === 0) {
      return;
    }

    const newItemsList = values?.item?.filter((_, index) => {
      return index !== itemIndex;
    });

    setFieldValue('item', newItemsList);
  };

  return (
    <Grid sx={invoiceFormStyles.rowContainer}>
      <Grid sx={invoiceFormStyles.formTemplateItemsTableRow}>
        <Grid sx={invoiceFormStyles.itemColumn}>
          <FormSelect
            name={`item.[${itemIndex}].template_id`}
            changeHandler={handleChange}
            value={values?.item?.[itemIndex]?.template_id}
            error={errors?.item?.[itemIndex]?.template_id}
            touched={touched?.item?.[itemIndex]?.template_id}
            options={
              !!templatesList?.data && invoicetemplatesListOptionsById(templatesList?.data?.data)
            }
            required
          />
        </Grid>

        <Grid sx={invoiceFormStyles.column}>
          <FormInput
            name={`item.[${itemIndex}].quantity`}
            changeHandler={handleChange}
            value={values?.item?.[itemIndex]?.quantity}
            error={errors?.item?.[itemIndex]?.quantity}
            touched={touched?.item?.[itemIndex]?.quantity}
            type="number"
            required
            column
          />
        </Grid>

        <Grid sx={invoiceFormStyles.column}>
          <FormInput
            name={`item.[${itemIndex}].price`}
            changeHandler={handleChange}
            value={values?.item?.[itemIndex]?.price}
            error={errors?.item?.[itemIndex]?.price}
            touched={touched?.item?.[itemIndex]?.price}
            required
            column
          />
        </Grid>

        <Grid sx={invoiceFormStyles.actionColumn}>
          <AppButton
            icon={trashicon}
            buttonStyle="error"
            onClick={removeItemHandler}
            disabled={itemIndex === 0}
          />
        </Grid>
      </Grid>

      <Grid sx={invoiceFormStyles.itemColumn}>
        <FormInput
          name={`item.[${itemIndex}].description`}
          changeHandler={handleChange}
          value={values?.item?.[itemIndex]?.description}
          error={errors?.item?.[itemIndex]?.description}
          touched={touched?.item?.[itemIndex]?.description}
          required
          column
        />
      </Grid>
    </Grid>
  );
};

export default Row;
