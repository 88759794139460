import Joi from "joi";

export const genericSendEmailSchema = Joi.object({
    to: Joi.alternatives().try(
        Joi.object({ label: Joi.string().required(), value: Joi.string().required() })
            .required(),
        Joi.string().email({ tlds: { allow: false } }).required().messages({
            'string.email': 'To must be a email',
        })
    ).required().messages({
        'any.required': 'To is required',
        'string.empty': 'To is required',
    }),

    subject: Joi.string().required().messages({
        'string.empty': 'Subject is required',
    }),

    message: Joi.string().required().messages({
        'string.empty': 'Message is required',
    }),

    template: Joi.object({
        label: Joi.string().required(),
        value: Joi.string().required(),
    }).optional().allow(null)
})