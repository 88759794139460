import axios from "axios";
import { CreateDebtorLegalClaimNumber } from "../types/debtor-claim-number.type";
import { DebtorLegalClaimNumber } from "../types/debtor-legal.type";
import { REACT_APP_API_URL } from "@src/lib/global-variables";
import { Params } from "@src/types/service.type";
import { DeepPartial } from "react-hook-form";

export class DebtorLegalClaimNumberService {

  async createDebtorClaimNumber(data: CreateDebtorLegalClaimNumber, params?: Params) {
    const res = await axios.post<DebtorLegalClaimNumber>(`${REACT_APP_API_URL}/debtor-legals/claim-numbers`, data, { params });
    return res.data;
  }

  async updateDebtorClaimNumber(debtorClaimNumberId: number, data: DeepPartial<DebtorLegalClaimNumber>, params?: Params) {
    const res = await axios.patch<DebtorLegalClaimNumber>(`${REACT_APP_API_URL}/debtor-legals/claim-numbers/${debtorClaimNumberId}`, data, { params });
    return res.data;
  }

  async deleteDebtorClaimNumber(debtorClaimNumberId: number, params?: Params) {
    const res = await axios.delete<DebtorLegalClaimNumber>(`${REACT_APP_API_URL}/debtor-legals/claim-numbers/${debtorClaimNumberId}`, { params });
    return res.data;
  }
}