import { FC } from 'react'
import { PaginationActionBackProps } from './button-pagination.type'
import styles from './button-pagination.module.scss'
import cls from 'classnames'
import RoundedArrow from '@src/icons/rounded-arrow'

export const PaginationActionBack: FC<PaginationActionBackProps> = ({ currentPage, onClick }) => {
  const isFirstPage = currentPage === 1
  const handleActionBack = () => {
    if (isFirstPage) {
      return
    }
    onClick()
  }

  return (
    <button
      className={cls(styles.itemWrapper, styles.paginationActionWrapper, {
        [styles.disabled]: isFirstPage,
      })}
      onClick={handleActionBack}
    >
      <RoundedArrow />
    </button>
  )
}
