import { REACT_APP_API_URL } from "@src/lib/global-variables";
import { PaginationResponse, Params } from "@src/types/service.type";
import axios from "axios";
import { CourtInterestRate } from "../types/interest-rate.type";

export class CourtInterestRateService {

  async getInterestRates(params?: Params): Promise<PaginationResponse<CourtInterestRate[]>> {
    const res = await axios.get<PaginationResponse<CourtInterestRate[]>>(`${REACT_APP_API_URL}/court-interest-rates`, { params })
    return res.data
  }

}