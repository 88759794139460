import React, { Component } from 'react'
import SimpleReactValidator from 'simple-react-validator'
import Services from '../../../../shared/Services.js'
import { withRouter } from 'react-router'
import { Button, Row } from 'reactstrap'
import Modal from 'react-bootstrap/Modal'
import ModalComponent from '../../../../shared/components/ModalCustom'
import CompaniesService from '../../../../services/CompaniesService'
import Select from 'react-select'
import AccountService from '../../../../services/AccountService'

class Account extends Component {
  constructor(props) {
    super(props)

    this.state = {
      id: localStorage.getItem('contactsId'),
      accountName: '',
      bsb: '',
      accountNumber: '',
      userName: '',
      userPassword: null,
      isShowPassword: true,
      paying: false,
      clientPays: false,
      webSiteOption: false,
      referral: false,
      doNotLapse: false,
      platinum: false,
      holdPayment: false,
      accessErrorModal: false,
      ibn: '',
      swift: '',
      newPassword: '',
      newAccountName: '',
      createLoginModal: false,
      isShowPasswordNew: true,
      accounts: [],
      deleteId: '',
      login_type: '',
      clientTypeOption: [],
    }
    this.services = new Services()
    this.account = new AccountService()
    this.comp = new CompaniesService()
    this.changeInput = this.changeInput.bind(this)
    this.validator = new SimpleReactValidator()
    this.subValidator = new SimpleReactValidator()
    this.subValidatorModal = new SimpleReactValidator()
  }

  componentDidMount() {
    this.getClient(this.state.id)
    this.getAccountDetail(this.state.id)
    this.getAccountDetailLoginType()
  }

  getClient(id) {
    const params = {
      id: id,
      join: ['contacts'],
    }

    this.comp.getCompaniesId(params).then((res) => {
      if (res.data) {
        const getData = res.data
        this.setState({
          id: getData.id,
          client_code: getData.client_code,
          accountName: getData.bank_nz,
          accountNumber: getData.acn,
          bsb: getData.bank_au_bsb,

          ibn: getData.bank_int_ibn,
          swift: getData.bank_int_swift,
          userName: getData.account_login,
          userPassword: getData.account_password,
          accounts: getData.contacts,

          holdPayment: getData.hold_payment || false,
          referral: getData.refferal || false,
          doNotLapse: getData.do_not_lapse || false,
          webSiteOption: getData.web_property || false,
          paying: getData.pay_monthly || false,
          platinum: getData.platinum_partner || false,
          clientPays: getData.pay_owner_direct || false,
        })
      } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
        this.setState({ accessErrorModal: true })
      } else {
        this.services.errorToast(res.message)
      }
    })
  }

  getAccountDetail(id) {
    const params = {
      join: ['company'],
      filter: `company.id||$eq||${id}`,
    }

    this.account.getAccountInfo(params).then((res) => {
      if (res.data) {
        const getData = res.data
        this.setState({
          userName: getData.username,
          userPassword: getData.password,
          accounts: getData,
        })
      } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
        this.setState({ accessErrorModal: true })
      } else {
        this.services.errorToast(res.message)
      }
    })
  }

  getAccountDetailLoginType() {
    this.account.getLoginTyps().then((res) => {
      if (res.data) {
        const option = res.data.map((ele) => ({ value: ele.id, label: ele.name }))
        this.setState({
          clientTypeOption: option,
        })
      } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
        this.setState({ accessErrorModal: true })
      } else {
        this.services.errorToast(res.message)
      }
    })
  }

  handleChange = (selectedOption, name) => {
    this.setState({ [name]: selectedOption })
  }

  accessErrorModalClose = () => {
    this.setState({ accessErrorModal: false })
    this.props.history.push('/dashboard')
  }

  changeInput(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  sendCredential = (item) => {
    const data = {
      client_id: this.state.id,
      creds_id: item.id,
    }
    if (this.subValidator.allValid()) {
      this.services.sendAccountDetails(data, (res) => {
        if (res.status === 200) {
          this.services.successToast('Email sent successfully')
        } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
          this.setState({ accessErrorModal: true })
        } else {
          this.services.errorToast(res.message)
        }
      })
    } else {
      this.subValidator.showMessages()
      this.forceUpdate()
    }
  }

  handleOptionChange = (e) => {
    this.setState({ [e.target.name]: e.target.checked })
  }

  openCreteModal = () => {
    this.setState({ createLoginModal: true })
  }

  updateAccount = () => {
    const data = {
      client_ID: this.state.id,
      account_bsb: this.state.bsb,
      account_name: this.state.accountName,
      account_number: this.state.accountNumber,
      // account_ibn: this.state.ibn || "",
      //  account_swift: this.state.swift || "",
      account_login: this.state.userName,
      account_password: this.state.userPassword,

      hold_payment: this.state.holdPayment,
      refferal: this.state.referral,
      do_not_lapse: this.state.doNotLapse,
      web_property: this.state.webSiteOption,
      pay_monthly: this.state.paying,
      platinum_partner: this.state.platinum || false,
      pay_owner_direct: this.clientPays,
    }

    if (this.validator.allValid()) {
      this.services.editAccountDetails(data, (res) => {
        if (res.status === 200) {
          this.services.successToast('Account Detalis Updated successfully')
          //this.props.history.push("/contact-list");
        } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
          this.setState({ accessErrorModal: true })
        } else {
          this.services.errorToast(res.message)
        }
      })
    } else {
      this.validator.showMessages()
      this.forceUpdate()
    }
  }

  createNewAccount = () => {
    const data = {
      client_id: this.state.id,
      account_login: this.state.newAccountName,
      account_password: this.state.newPassword,
    }
    if (this.validator.allValid()) {
      this.services.createAccountDetails(data, (res) => {
        if (res.status === 200) {
          this.services.successToast('Account created successfully')
          //this.props.history.push("/contact-list");
          this.setState({
            createLoginModal: false,
            newAccountName: '',
            newPassword: '',
          })
          this.getStaff(this.state.id)
        } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
          this.setState({ accessErrorModal: true })
        } else {
          this.services.errorToast(res.message)
        }
      })
    } else {
      this.validator.showMessages()
      this.forceUpdate()
    }
  }

  closePopup = () => {
    this.setState({ createLoginModal: false })
  }

  OpenModalDelete = (id) => {
    this.setState({
      deleteId: id,
      errorMessage: 'Are you sure you want to delete this record? ',
      errorModal: true,
      errorType: 'danger',
    })
  }

  RemoveCredential() {
    const data = {
      creds_id: this.state.deleteId,
    }
    this.services.removeAccountDetails(data, (res) => {
      if (res.status === 200) {
        this.services.successToast('Account created successfully')
        //this.props.history.push("/contact-list");
        this.setState({
          createLoginModal: false,
          newAccountName: '',
          newPassword: '',
          deleteId: '',
          errorModal: false,
        })
        this.getStaff(this.state.id)
      } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
        this.setState({ accessErrorModal: true })
      } else {
        this.services.errorToast(res.message)
      }
    })
  }

  showPassword = (item) => {
    this.state.accounts.map((c) => {
      if (c.id === item.id) {
        c.isShow = !c.isShow
      } else {
        c.isShow = false
      }
    })
    this.forceUpdate()
  }

  render() {
    const { accountName, bsb, accountNumber, newPassword, newAccountName } = this.state
    return (
      <div className="w-100">
        <div className="form" autoComplete="off">
          <div className="card ">
            <h3 className="page-title">Account Details</h3>

            <div className="card-body">
              <div className="card-header mb-4 pl-0">
                <h4 className="card-link" data-toggle="collapse">
                  Bank Account Details
                </h4>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 col-lg-3">
                  <div className="form-group">
                    <label className="form__form-group-label" htmlFor="">
                      Account Name <span style={{ color: '#ff0000' }}>*</span>
                    </label>
                    <input
                      type="text"
                      className="custom-input"
                      id="old_ten_id"
                      value={accountName}
                      name="accountName"
                      onChange={this.changeInput}
                    />
                    <span className="form__form-group-error">
                      {this.validator.message('Account Name', accountName, 'required')}
                    </span>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3">
                  <div className="form-group">
                    <label className="form__form-group-label" htmlFor="">
                      BSB <span style={{ color: '#ff0000' }}>*</span>
                    </label>
                    <input
                      type="text"
                      className="custom-input"
                      value={bsb}
                      name="bsb"
                      onChange={this.changeInput}
                    />
                    <span className="form__form-group-error">
                      {this.validator.message('BSB', bsb, 'required')}
                    </span>
                  </div>
                </div>

                <div className="col-12 col-md-6 col-lg-3">
                  <div className="form-group">
                    <label className="form__form-group-label" htmlFor="">
                      Account Number <span style={{ color: '#ff0000' }}>*</span>
                    </label>
                    <input
                      type="text"
                      className="custom-input"
                      value={accountNumber ?? ''}
                      name="accountNumber"
                      onChange={this.changeInput}
                    />
                    <span className="form__form-group-error">
                      {this.validator.message('Account Number', accountNumber, 'required|numeric')}
                    </span>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3">
                  <div className="form-group">
                    <label className="form__form-group-label" htmlFor="">
                      Login Type <span style={{ color: '#ff0000' }}>*</span>
                    </label>
                    <Select
                      name="login_type"
                      type="text"
                      options={this.state.clientTypeOption}
                      classNamePrefix="react-select"
                      className="react-select"
                      value={this.state.login_type}
                      onChange={(e) => this.handleChange(e, 'login_type')}
                    />
                    <span className="form__form-group-error">
                      {this.validator.message('Login Type', this.state.login_type, 'required')}
                    </span>
                  </div>
                </div>
              </div>
              <div className="card-header mb-4 pl-0">
                <h4 className="card-link" data-toggle="collapse">
                  Login Details
                </h4>
              </div>
              {this.state?.accounts?.map((item, i) => {
                return (
                  <div className="row" key={item.id}>
                    <div className="col-12 col-md-6 col-lg-3">
                      <label className="form__form-group-label" htmlFor="">
                        User Name
                      </label>

                      <div className="form-group">
                        <input
                          type="text"
                          className="custom-input"
                          value={item.username}
                          name="userName"
                          disabled
                          autoComplete="new-password"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label" htmlFor="">
                          Password{' '}
                        </label>

                        <input
                          type={!item.isShow ? 'password' : 'text'}
                          className="custom-input"
                          value={item.password}
                          name="userPassword"
                          disabled
                          autoComplete="new-password"
                        />
                        <button
                          type="button"
                          onClick={() => this.showPassword(item)}
                          className="form__form-group-button position-absolute eye-right"
                        >
                          <svg
                            className="mdi-icon "
                            width="24"
                            height="24"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path d="M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9M12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17M12,4.5C7,4.5 2.73,7.61 1,12C2.73,16.39 7,19.5 12,19.5C17,19.5 21.27,16.39 23,12C21.27,7.61 17,4.5 12,4.5Z" />
                          </svg>
                        </button>
                      </div>
                    </div>

                    <div className="col-12 col-md-12 col-lg-6">
                      <div className="form-group mb-1">
                        <label className="form__form-group-label" />
                      </div>
                      <div className="d-flex flex-wrap">
                        <Button className="btn mr-4 mb-2" onClick={() => this.sendCredential(item)}>
                          Send Client Login Details
                        </Button>
                        <button
                          className="btn-gray mr-4 mb-2"
                          onClick={() => this.OpenModalDelete(item.id)}
                        >
                          Remove
                        </button>
                        {this.state.accounts && this.state.accounts.length == i + 1 ? (
                          <Button className="btn btn-primary mb-2" onClick={this.openCreteModal}>
                            Create New Login
                          </Button>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                )
              })}

              {this.state.accounts && this.state.accounts.length === 0 ? (
                <Button className="btn btn-primary mb-2" onClick={this.openCreteModal}>
                  Create New Login
                </Button>
              ) : (
                ''
              )}

              <div className="card-header mb-4 pl-0">
                <hr />
                <span className="card-link" data-toggle="collapse">
                  Account Controls
                </span>
              </div>
              <div className="row mb-5">
                <div className="col-12 col-md-6 col-lg-3 ">
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      name="holdPayment"
                      id="hp"
                      checked={this.state.holdPayment}
                      onChange={this.handleOptionChange}
                    />
                    <label className="custom-control-label" htmlFor="hp">
                      Hold Payment
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3 ">
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      name="referral"
                      id="referral"
                      checked={this.state.referral}
                      onChange={this.handleOptionChange}
                    />
                    <label className="custom-control-label" htmlFor="referral">
                      Referral
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3 ">
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      name="doNotLapse"
                      id="doNotLapse"
                      checked={this.state.doNotLapse}
                      onChange={this.handleOptionChange}
                    />
                    <label className="custom-control-label" htmlFor="doNotLapse">
                      Do Not Lapse
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3">
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      name="clientPays"
                      id="clientPays"
                      checked={this.state.clientPays}
                      onChange={this.handleOptionChange}
                    />
                    <label className="custom-control-label" htmlFor="clientPays">
                      Client pays
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 col-lg-3">
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      name="webSiteOption"
                      id="webSiteOption"
                      checked={this.state.webSiteOption}
                      onChange={this.handleOptionChange}
                    />
                    <label className="custom-control-label" htmlFor="webSiteOption">
                      Web Site Properties Free Option
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3">
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      name="platinum"
                      id="platinum"
                      checked={this.state.platinum}
                      onChange={this.handleOptionChange}
                    />
                    <label className="custom-control-label" htmlFor="platinum">
                      Platinum Partner
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3">
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      name="paying"
                      id="paying"
                      checked={this.state.paying}
                      onChange={this.handleOptionChange}
                    />
                    <label className="custom-control-label" htmlFor="paying">
                      Paying Monthly
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-100 d-flex justify-content-end pt-4">
              <Button className="btn-group--icons" onClick={this.updateAccount}>
                Save Changes
              </Button>
            </div>
          </div>
        </div>
        {this.state.accessErrorModal ? (
          <ModalComponent
            modal={this.state.accessErrorModal}
            color="danger"
            isCancel={false}
            message="You do not have access to this, please contact your manager for assistance"
            onConfirm={(e) => this.accessErrorModalClose(e)}
            onClose={(e) => this.accessErrorModalClose(e)}
          />
        ) : null}
        <Modal
          show={this.state.createLoginModal}
          onHide={this.closePopup}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="tool_modal small_modal"
        >
          <Modal.Body className="p-0 py-4 text-left">
            <div className="mb-4">
              <h4> Create New Login</h4>
            </div>
            <Row>
              <div className="col-12 form">
                <div className="form-group w-100">
                  <label className="form__form-group-label" htmlFor="">
                    User Name<span style={{ color: '#ff0000' }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="custom-input"
                    id="old_ten_id"
                    value={newAccountName}
                    name="newAccountName"
                    onChange={this.changeInput}
                  />
                  {this.state.createLoginModal && (
                    <span className="form__form-group-error">
                      {this.validator.message('UserName', newAccountName, 'required')}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-12 form">
                <div className="form-group w-100">
                  <label className="form__form-group-label" htmlFor="">
                    Password <span style={{ color: '#ff0000' }}>*</span>
                  </label>
                  <input
                    type={this.state.isShowPasswordNew ? 'password' : 'text'}
                    className="custom-input"
                    value={newPassword}
                    name="newPassword"
                    onChange={this.changeInput}
                    autoComplete="new-password"
                  />
                  <span
                    className="float-right mt-1 pointer linkText"
                    onClick={() => {
                      this.setState({ isShowPasswordNew: !this.state.isShowPasswordNew })
                    }}
                  >
                    Show Password
                  </span>
                  {this.state.createLoginModal && (
                    <span className="form__form-group-error">
                      {this.validator.message('Password', newPassword, 'required')}
                    </span>
                  )}
                </div>
              </div>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn-group--icons" onClick={this.createNewAccount}>
              {' '}
              Save Changes{' '}
            </Button>
            <Button className="btn-group--icons" onClick={this.closePopup}>
              {' '}
              Cancel{' '}
            </Button>
          </Modal.Footer>
        </Modal>
        {this.state.errorModal ? (
          <ModalComponent
            modal={this.state.errorModal}
            color={this.state.errorType}
            isCancel={true}
            message={this.state.errorMessage}
            onConfirm={() => this.RemoveCredential()}
            onClose={() => this.setState({ errorModal: false })}
          />
        ) : null}
      </div>
    )
  }
}

export default withRouter(Account)
