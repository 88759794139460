import React from 'react';
import { Button, ButtonGroup, Col, UncontrolledTooltip } from 'reactstrap';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import NotesTableHead from './NotesTableHead';
import Services from '../../../../../shared/Services.js';
import { withRouter } from 'react-router';
import ModalComponent from '../../../../../shared/components/ModalCustom';
import moment from 'moment';
import NotesService from '../../../../../services/NotesServices';

function getSorting(order, orderBy) {
  if (order === 'desc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
}

class ListNotes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: 'asc',
      orderBy: '',
      selected: new Map([]),
      staffData: [],
      page: 0,
      rowsPerPage: 5,
      errorModal: false,
      errorMessage: '',
      errorType: '',
      st_id: '',
      accessErrorModal: false,
      app_type: '',
      filter_app_type: [],
      logoImageHeader: '',
      debtorId: localStorage.getItem('debtorId'),
      reloading: null,
    };

    this.note = new NotesService();
    this.services = new Services();
    this.EditNote = this.EditNote.bind(this);
  }

  componentDidMount() {
    if (this.props.reloading) {
      this.setState({
        reloading: this.props.reloading,
      });
    }
    this.getDebtorNotes();
    this.toDataURL('./image/logo.png')
      .then(dataUrl => {
        this.setState({ logoImageHeader: dataUrl });
      });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.reloading !== this.props.reloading) {
      this.getDebtorNotes();
    }
  }

  toDataURL = url => fetch(url)
    .then(response => response.blob())
    .then(blob => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    }));


  getDebtorNotes() {
    const params = {
      join: 'debtor',
      filter: `debtor.id||$eq||${this.state.debtorId}`,
      limit: 10000,
    };
    this.note.getDebtorNotes(params).then((res) => {
      if (res.status === 200) {
        res.data.data.forEach((val) => {
          if (val.is_important === true) {
            res.data.data.sort(function(x, y) {
              return x === val ? -1 : y === val ? 1 : 0;
            });
          }
        });
        this.setState({ staffData: res.data.data });
      } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
        this.setState({ accessErrorModal: true });
      }
    });
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === 'desc') {
      order = 'asc';
    }
    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      const { data } = this.state;
      const newSelected = new Map();
      data.map(n => newSelected.set(n.id, true));
      this.setState({ selected: newSelected });
      return;
    }
    this.setState({ selected: new Map([]) });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const newSelected = new Map(selected);
    const value = newSelected.get(id);
    let isActive = true;
    if (value) {
      isActive = false;
    }
    newSelected.set(id, isActive);
    this.setState({ selected: newSelected });
    //localStorage.setItem('contactsId', id);
    this.props.history.push(`/contacts-notes/${this.state.debtorId}`);
  };

  handleDeleteSelected = () => {
    const { data } = this.state;
    let copyData = [...data];
    const { selected } = this.state;

    for (let i = 0; i < [...selected].filter(el => el[1]).length; i += 1) {
      copyData = copyData.filter(obj => obj.id !== selected[i]);
    }

    this.setState({ data: copyData, selected: new Map([]) });
  };

  isSelected = (id) => {
    const { selected } = this.state;
    return !!selected.get(id);
  };

  EditNote(e, d) {
    localStorage.setItem('note_Id', d.id);
    this.props.editNodeList(d.id);
    window.scrollTo(
      {
        top: 0,
        behavior: 'smooth',
      },
    );
  }

  DeleteStaff = () => {
    this.setState({
      errorMessage: '',
      errorModal: false,
      errorType: '',
    });
    let url = 'Staff/editDebtor';
    const data = {
      st_id: this.state.st_id,
      isdel: true,
    };
    this.services.editStaffDelete(data, url, (res) => {
      if (res.status === 200) {
        this.services.successToast('Staff removed successfully');
        this.getStaff();
      } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
        this.setState({ accessErrorModal: true });
      }
    });
  };

  OpenModal = (e, d) => {
    this.setState({
      st_id: d._id,
      errorMessage: 'Are you sure you want to delete this record? ',
      errorModal: true,
      errorType: 'danger',
    });
  };

  handleChange0 = (selectedOption0, d) => {
    let url = 'Staff/editDebtor';

    const data = {
      st_id: d._id,
      status: [selectedOption0.value],
    };
    this.services.editStaffStatus(data, url, (res) => {
      if (res.status === 200) {
        this.services.successToast('Status update successfully');
        this.getStaff();
      } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
        this.setState({ accessErrorModal: true });
      }
    });
  };

  handleOptionChange = (e) => {
    const y = e.currentTarget.id;
    const d = this.state.filter_app_type;
    const index = d.indexOf(e.currentTarget.value);
    if (index > -1) {
      d.splice(index, 1);
    } else {
      d.push(e.currentTarget.value);
    }
    if (d.length) {
      this.note.getDebtorNotes().then((res) => {
        const result = res.data.data.filter((e) => e.actions === y);
        this.setState({ staffData: result });
      });
    } else {
      this.getDebtorNotes();
    }
  };

  accessErrorModalClose = () => {
    this.setState({ accessErrorModal: false });
    this.props.history.push('/dashboard');
  };

  print = () => {
    const content = document.getElementById('not_print');
    const pri = document.getElementById('ifmcontentstoprint').contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  };

  render() {
    const {
      order, orderBy, staffData, selected,
    } = this.state;

    return (
      <>
        <div className='bg-white w-100'>
          <Col md={12} lg={12}>
            <div className='row mb-5'>
              <label />
            </div>
            <div className='material-table__wrap'>
              <div style={{ right: '33px', position: 'absolute', top: '10px' }}><Button
                onClick={this.print}>Print</Button>
              </div>
              <Table className='material-table' id='notesTable' ref={el => (this.componentRef = el)}>
                <NotesTableHead
                  numSelected={[...selected].filter(el => el[1]).length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={this.handleSelectAllClick}
                  onRequestSort={this.handleRequestSort}
                  rowCount={staffData.length}
                />
                <TableBody>
                  {staffData
                    .sort(getSorting(order, orderBy))
                    .map((d, i) => {
                      const isSelected = this.isSelected(d.id);
                      return (
                        <TableRow
                          className='material-table__row'
                          role='checkbox'
                          onClick={event => this.handleClick(event, d.id)}
                          aria-checked={isSelected}
                          tabIndex={-1}
                          key={i}
                          selected={isSelected}

                        >
                          <TableCell
                            className='material-table__cell material-table__cell-right'
                            component='th'
                            scope='row'
                            padding='none'
                            style={d.is_important ? { color: '#ff0000' } : null}
                          >{moment(d.date).format('MMMM Do YYYY')}
                          </TableCell>
                          <TableCell
                            style={d.is_important ? { color: '#ff0000' } : null}
                            className='material-table__cell material-table__cell-right'
                          >
                            {moment(d.created_at).format('HH:mm A')}
                          </TableCell>
                          <TableCell
                            style={d.is_important ? { color: '#ff0000' } : null}
                            className='material-table__cell material-table__cell-right'
                          >{d.actions}
                          </TableCell>
                          <TableCell
                            style={d.is_important ? { color: '#ff0000' } : null}
                            className='material-table__cell material-table__cell-right'
                          >{d.title}
                          </TableCell>
                          <TableCell
                            style={d.is_important ? { color: '#ff0000' } : null}
                            className='material-table__cell material-table__cell-right'
                          >{d.content}
                          </TableCell>
                          <TableCell
                            style={d.is_important ? { color: '#ff0000' } : null}
                            className='material-table__cell material-table__cell-left'
                          >
                            <ButtonGroup className='btn-group--icons' dir='ltr'>
                              <Button outline id='Edit' onClick={(e) => this.EditNote(e, d)}>
                                <span className='lnr lnr-pencil' />
                              </Button>
                            </ButtonGroup>
                            <UncontrolledTooltip placement='bottom' target='Edit'>
                              Edit note
                            </UncontrolledTooltip>
                          </TableCell>

                        </TableRow>
                      );
                    })}

                </TableBody>
              </Table>
              <iframe title='hide' id='ifmcontentstoprint' style={{ display: 'none' }} />
            </div>
            {this.state.errorModal ?
              <ModalComponent modal={this.state.errorModal} color={this.state.errorType} isCancel={true}
                              message={this.state.errorMessage} onConfirm={(e) => this.DeleteStaff(e)}
                              onClose={() => this.setState({ errorModal: false })} /> : null}
            {this.state.accessErrorModal ?
              <ModalComponent modal={this.state.accessErrorModal} color='danger' isCancel={false}
                              message='You do not have access to this, please contact your manager for assistance'
                              onConfirm={(e) => this.accessErrorModalClose(e)}
                              onClose={(e) => this.accessErrorModalClose(e)} /> : null}
          </Col>

        </div>
        <div style={{ height: '10px', overflow: 'auto', opacity: '0' }}>
          <div id='not_print' className='not_print' style={{ width: '900px', marginLeft: 'auto', marginRight: 'auto' }}>
            <div style={{ margin: '0 20px' }}>
              <div style={{ margin: '30px 0' }}>
                <img src={this.state.logoImageHeader} alt='logo' style={{ maxWidth: '200px' }} />
              </div>
              <table>
                <tbody>
                <tr>
                  <th style={{ padding: '5px', textAlign: 'left' }}>Date</th>
                  <th style={{ padding: '5px', textAlign: 'left' }}>Time</th>
                  <th style={{ padding: '5px', textAlign: 'left' }}>Application</th>
                  <th style={{ padding: '5px', textAlign: 'left' }}>Title</th>
                  <th style={{ padding: '5px', textAlign: 'left' }}>Note</th>
                </tr>
                </tbody>
                {this.state.staffData.map(e => {
                  return (
                    <tbody key={e.id}>
                    <tr>
                      <td style={{ padding: '5px', width: '15%' }}>{moment(e.created_at).format('MMMM Do YYYY')}</td>
                      <td style={{
                        padding: '5px',
                        width: '15%',
                      }}>{moment(e.created_at).format('HH:mm A')}</td>
                      <td style={{ padding: '5px', width: '15%' }}>{e.actions}</td>
                      <td style={{ padding: '5px', width: '15%' }}>{e.title}</td>
                      <td style={{ padding: '5px', width: '40%' }}>{e.content}</td>
                    </tr>
                    </tbody>
                  );
                })
                }
              </table>
            </div>
          </div>
        </div>
      </>
    );
  }
}


export default withRouter(ListNotes);
