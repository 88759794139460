import Section from '@components/section/section.component'
import { Header, SubHeader } from '@containers/Recovery/Wip/components/UI/text/text.component'
import styles from './track-payment-from-section.module.scss'
import { FC } from 'react'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { useModal } from '@src/hooks/useModal'
import AddDebtorsToPayTrackingModal from '../../modals/add-debtors-to-pay-tracking-modal/add-debtors-to-pay-tracking-modal.component'
import SubSection from '@containers/Recovery/Wip/components/UI/sub-section/sub-section.component'
import { TrackPaymentFromSectionProps } from '../../../types/payment-tracking.type'
import cls from 'classnames'

const TrackPaymentFromSection: FC<TrackPaymentFromSectionProps> = ({
  wipId,
  debtors,
  debtorsAddedToPayTracking,
  selectedDebtor,
  onSelectDebtor,
  full = false,
}) => {
  const [isOpen, handleOpen, handleClose] = useModal(false)

  return (
    <Section className={cls(styles.sectionWrapper, { [styles.full]: full })}>
      <Header>Track Payment From</Header>
      <ul className={styles.debtorPayTrackingList}>
        {debtorsAddedToPayTracking.length === 0 && (
          <li>
            <SubHeader>No debtors added to pay tracking</SubHeader>
          </li>
        )}
        {debtorsAddedToPayTracking.map((debtor) => (
          <li
            key={debtor.id}
            className={styles.debtorPayTrackingItem}
            onClick={() => onSelectDebtor(debtor)}
          >
            <SubSection
              className={cls(styles.subSection, {
                [styles.selected]: selectedDebtor?.id === debtor.id,
              })}
              p={2}
            >
              <SubHeader>{`${debtor.name_first} ${debtor.name_last}`}</SubHeader>
            </SubSection>
          </li>
        ))}
      </ul>
      <SubmitButton
        disabled={debtors.length === debtorsAddedToPayTracking.length}
        onClick={handleOpen}
      >
        Add Debtor
      </SubmitButton>
      <AddDebtorsToPayTrackingModal
        isOpen={isOpen}
        onClose={handleClose}
        debtorsAddedToPayTracking={debtorsAddedToPayTracking}
        wipId={wipId}
        debtors={debtors}
      />
    </Section>
  )
}

export default TrackPaymentFromSection
