import { SubOptionChangeEvent } from '@components/pagination-select/pagination-select.type'
import WipClientPaginationSelect from '@components/wip-client-pagination-select/wip-client-pagination-select.component'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import { useQueryClient } from '@tanstack/react-query'
import { FC } from 'react'
import { EmailAttributesProps } from '../../types/view-email-page.type'
import { GraphApiMailService } from '@containers/Inbox/services/graph-api-mail.service'
import { addAttributeToSubject, removeAttributeFromSubject } from '../utils/attribute.utils'
import { useMsal } from '@azure/msal-react'
import { toast } from 'react-toastify'
import { encryptXOR } from '../utils/xor-encryption.utils'
import { REACT_APP_XOR_ENCRYPTION_KEY } from '@src/lib/global-variables'
import { CLIENT_ATTRIBUTE_KEYWORD, WIP_ATTRIBUTE_KEYWORD } from '../../const/view-email-page.const'
import { useGetAssignedAttributes } from '../../hooks/get-assigned-attributes.hook'

const EmailAttributes: FC<EmailAttributesProps> = ({ email }) => {
  const { assignedAttributes, isLoading } = useGetAssignedAttributes(email.subject)
  const { accounts, instance } = useMsal()
  const queryClient = useQueryClient()

  const handleSubOptionChange = async (event: SubOptionChangeEvent) => {
    try {
      if (event.ref === 'client') {
        await handleClientSubOptionChange(event)
      } else {
        await handleWipSubOptionChange(event)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handleClientSubOptionChange = async (event: SubOptionChangeEvent) => {
    const encryptedId = encryptXOR(event.option.value as number, REACT_APP_XOR_ENCRYPTION_KEY)
    const attribute = `${CLIENT_ATTRIBUTE_KEYWORD}:${encryptedId}`

    const newSubject = event.value
      ? addAttributeToSubject(email.subject, attribute)
      : removeAttributeFromSubject(email.subject, attribute)

    updateEmailSubject(newSubject)
  }

  const handleWipSubOptionChange = async (event: SubOptionChangeEvent) => {
    const encryptedId = encryptXOR(event.option.value as number, REACT_APP_XOR_ENCRYPTION_KEY)
    const attribute = `${WIP_ATTRIBUTE_KEYWORD}:${encryptedId}`

    const newSubject = event.value
      ? addAttributeToSubject(email.subject, attribute)
      : removeAttributeFromSubject(email.subject, attribute)

    updateEmailSubject(newSubject)
  }

  const updateEmailSubject = async (newSubject: string) => {
    const graphApiMailService = new GraphApiMailService()
    const account = accounts[0] || {}

    try {
      const token = await instance.acquireTokenSilent({
        scopes: ['Mail.ReadBasic', 'Mail.Read', 'Mail.ReadWrite'],
        account: account,
      })
      await graphApiMailService.updateMessage(token.accessToken, email.id, { subject: newSubject })
      queryClient.invalidateQueries([GLOBAL_QUERIES.GRAPH_API_MESSAGES.GET_BY_ID, email.id])
      queryClient.resetQueries([GLOBAL_QUERIES.GRAPH_API_MESSAGES.GET_BY_FOLDER_ID])
    } catch (e) {
      console.error(e)
      toast.error('Failed to update email')
    }
  }

  return (
    <WipClientPaginationSelect
      selectedSubOptions={isLoading || !assignedAttributes ? [] : assignedAttributes}
      onSubOptionChange={handleSubOptionChange}
    />
  )
}

export default EmailAttributes
