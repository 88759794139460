import CustomPhoneInput from '@components/inputs/form-input/custom-phone-input/custom-phone-input.component'
import FormInput from '@components/inputs/form-input/form-input.component'
import FormTextArea from '@components/inputs/form-textarea/form-textarea.component'
import SubSection from '@containers/Recovery/Wip/components/UI/sub-section/sub-section.component'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { BaseFormProps } from '@src/types/component-types'
import { FC, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import styles from './landlord-contact-form.module.scss'
import cls from 'classnames'

export interface LandlordContactFormValues {
  position: string | null
  firstName: string
  lastName: string
  email: string | null
  mobilePhone: string | null
  landlinePhone: string | null
  faxNumber: string | null
  note: string | null
}

const landlordContactDefaultValues: LandlordContactFormValues = {
  position: null,
  firstName: '',
  lastName: '',
  email: null,
  mobilePhone: null,
  landlinePhone: null,
  faxNumber: null,
  note: null,
}

interface LandlordContactFormProps extends BaseFormProps<LandlordContactFormValues> {
  onCancelClick?: () => void
}

const LandlordContactForm: FC<LandlordContactFormProps> = ({
  initialValues,
  loading = false,
  submitButtonTitle = 'Add Owner',
  onSuccessSubmit,
  onCancelClick,
}) => {
  const {
    reset,
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LandlordContactFormValues>({
    defaultValues: {
      ...landlordContactDefaultValues,
      ...initialValues,
    },
  })

  useEffect(() => {
    if (!initialValues) {
      return
    }

    reset({
      ...landlordContactDefaultValues,
      ...initialValues,
    })
  }, [initialValues])

  return (
    <form onSubmit={handleSubmit(onSuccessSubmit)}>
      <SubSection p={4} mb={4} lineStyle="dashed">
        <div className={styles.half}>
          <FormInput
            label="Position / Salutation"
            inputStyle="outlined"
            {...register('position')}
          />
        </div>
      </SubSection>
      <div className={cls(styles.row, styles.mb4)}>
        <FormInput label="First Name" required inputStyle="outlined" {...register('firstName')} />
        <FormInput label="Last Name" required inputStyle="outlined" {...register('lastName')} />
      </div>
      <div className={styles.row}>
        <FormInput label="Email" inputStyle="outlined" {...register('email')} />
        <Controller
          name="mobilePhone"
          control={control}
          render={({ field }) => (
            <CustomPhoneInput
              label="Mobile Phone"
              inputStyle="outlined"
              error={errors.mobilePhone?.message}
              value={field.value || undefined}
              onChange={(value) => field.onChange(value.formattedPhone)}
            />
          )}
        />
      </div>
      <SubSection className={styles.row} p={4} mb={4} lineStyle="dashed">
        <Controller
          name="landlinePhone"
          control={control}
          render={({ field }) => (
            <CustomPhoneInput
              label="Landline"
              inputStyle="outlined"
              error={errors.landlinePhone?.message}
              value={field.value || undefined}
              onChange={(value) => field.onChange(value.formattedPhone)}
            />
          )}
        />
        <Controller
          name="faxNumber"
          control={control}
          render={({ field }) => (
            <CustomPhoneInput
              label="Fax Number"
              inputStyle="outlined"
              error={errors.faxNumber?.message}
              value={field.value || undefined}
              onChange={(value) => field.onChange(value.formattedPhone)}
            />
          )}
        />
      </SubSection>
      <FormTextArea
        label="Add a Note"
        placeholder="Your note here"
        {...register('note')}
        inputStyle="outlined"
      />
      <div className={styles.actionsWrapper}>
        <SubmitButton buttonStyle="secondary" buttonType="button" onClick={onCancelClick}>
          Cancel
        </SubmitButton>
        <SubmitButton loading={loading} buttonType="submit">
          {submitButtonTitle}
        </SubmitButton>
      </div>
    </form>
  )
}

export default LandlordContactForm
