import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import { REACT_APP_API_URL } from '@src/lib/global-variables'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { FC } from 'react'
import SubTaskItem from './sub-task-item.component'
import { SubTask, SubTaskListProps } from '../../types/sub-task.type'
import Dropdown from '@components/dropdown/dropdown.component'
import { useModal } from '@src/hooks/useModal'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import CreateSubTaskModal from '../modals/create-sub-task.modal'
import styles from './sub-task.module.scss'

const SubTaskList: FC<SubTaskListProps> = ({ task }) => {
  const [isCreateSubTaskModalOpen, handleCreateSubTaskModalOpen, handleCreateSubTaskModalClose] =
    useModal(false)
  const params = {
    join: 'assigned_to',
    s: JSON.stringify({ task_id: task.id }),
    sort: 'created_at,DESC',
  }

  const { data, isLoading } = useQuery({
    queryKey: [GLOBAL_QUERIES.SUB_TASK.GET, task.id],
    queryFn: async () => {
      const response = await axios.get(`${REACT_APP_API_URL}/sub-tasks`, {
        params,
      })
      return response.data.data as SubTask[]
    },
    enabled: !!task.id,
  })

  return (
    <>
      <Dropdown
        options={[{ label: 'Sub Tasks', value: 'Sub Tasks' }]}
        selected={{ label: 'Sub Tasks', value: 'Sub Tasks' }}
      >
        {data &&
          data.map((subTask, i) => (
            <SubTaskItem subTask={subTask} key={`sub-task-${subTask.id}-${i}`} />
          ))}
        <div className={styles.buttonWrapper}>
          <SubmitButton onClick={handleCreateSubTaskModalOpen}>Add Sub Task</SubmitButton>
        </div>
      </Dropdown>
      <CreateSubTaskModal
        isOpen={isCreateSubTaskModalOpen}
        onClose={handleCreateSubTaskModalClose}
        task={task}
      />
    </>
  )
}

export default SubTaskList
