import LabelWrapper from '@components/label-wrapper'
import Modal from '@components/modal'
import { FC, useEffect, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { TextEditor } from '@src/shared/components/TextEditor/TextEditor'
import Select from 'react-select'
import styles from './email-sending-modal-with-recipients.module.scss'
import { useUserContext } from '@src/context/user-context'
import { joiResolver } from '@hookform/resolvers/joi'
import axios from 'axios'
import { REACT_APP_API_URL } from '@src/lib/global-variables'
import { toast } from 'react-toastify'
import {
  EmailSendingFormWithRecipientsValues,
  EmailSendingModalWithRecipientsProps,
  NotificationOption,
} from '../notification-modals.type'
import {
  DEFAULT_EMAIL_SENDING_WITH_RECEPIENTS_FORM_VALUES,
  DEFAULT_TO_OPTIONS,
} from '../notification-modals.const'
import {
  formatHtmlText,
  handleRecipientsChange,
  transformRecipientsOptions,
} from '../notification-modals.utils'
import { emailSendingModalWithRecipientsSchema } from '../notification-modals.schema'
import { ErrorMessage } from '@hookform/error-message'
import Switch from '@components/switch'
import { useIsAuthenticated } from '@azure/msal-react'
import { useGetMailFolderByDisplayNameOrCreate } from '@containers/Inbox/hooks/get-mail-folder-by-display-name-or-create.hook'
import { SHARED_FOLDER_NAME } from '@containers/Inbox/inbox-page/const/inbox.const'
import { SendMessageBody } from '@containers/Inbox/types/graph-api-mail.type'
import { useSendMessageMutation } from '@containers/Inbox/hooks/mutations/send-message.hook'
import { Oval } from 'react-loader-spinner'
import cls from 'classnames'

const EmailSendingModalWithRecipients: FC<EmailSendingModalWithRecipientsProps> = ({
  isOpen,
  onClose,
  debtorOptions = [],
  clientOptions = [],
  staffOptions = [],
  excludeFromTo = [],
  templateOptions = [],
  additionalValuesInRequest = {},
  children,
  onSuccessfulSend,
  replacementValues = {},
  tagToSubject = '',
}) => {
  const [isEmailSending, setIsEmailSending] = useState(false)
  const { mutateAsync } = useSendMessageMutation()
  const { mailFolder } = useGetMailFolderByDisplayNameOrCreate(SHARED_FOLDER_NAME)
  const isAuthenticated = useIsAuthenticated()
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<EmailSendingFormWithRecipientsValues>({
    defaultValues: DEFAULT_EMAIL_SENDING_WITH_RECEPIENTS_FORM_VALUES,
    resolver: joiResolver(emailSendingModalWithRecipientsSchema),
  })
  const to = useWatch({ control, name: 'to' })
  const template = useWatch({ control, name: 'template' })
  const { user } = useUserContext()

  const onSubmit = async (data: EmailSendingFormWithRecipientsValues) => {
    if (!data.recipients) return

    setIsEmailSending(true)
    try {
      if (isAuthenticated) {
        await sendEmailViaOutlook(data)
      } else {
        await sendEmailViaLocalServer(data)
      }
      onSuccessfulSend && onSuccessfulSend()
    } catch (e) {
      console.error(e)
      if (axios.isAxiosError(e)) {
        toast.error(e.response?.data?.message || 'Failed to send emails')
      } else if (e instanceof Error) {
        toast.error(e.message)
      } else {
        toast.error('Failed to send emails')
      }
      onClose()
    } finally {
      setIsEmailSending(false)
    }
  }

  const sendEmailViaOutlook = async (data: EmailSendingFormWithRecipientsValues) => {
    if (!data.recipients || !mailFolder) return

    const recepients = data.recipients

    const toRecipients =
      typeof recepients === 'string'
        ? [{ emailAddress: { address: recepients } }]
        : recepients.map(({ value }) => ({ emailAddress: { address: value.value } }))
    const mainRecipient = toRecipients[0]
    const recipientsWithoutMain = toRecipients.slice(1)

    const dataToSend: SendMessageBody = {
      message: {
        subject: `${data.subject}${tagToSubject}`,
        body: {
          contentType: 'html',
          content: formatHtmlText(data.body, replacementValues),
        },
        toRecipients: [mainRecipient],
        bccRecipients: data.bcc ? recipientsWithoutMain : [],
        ccRecipients: data.bcc ? [] : recipientsWithoutMain,
      },
    }

    const [, err] = await mutateAsync(dataToSend)

    if (err) {
      throw err
    }
  }

  const sendEmailViaLocalServer = async (data: EmailSendingFormWithRecipientsValues) => {
    if (!data.recipients) return

    const formattedBody = formatHtmlText(data.body, replacementValues)

    if (typeof data.recipients === 'string') {
      const email = {
        to: data.recipients,
        subject: data.subject,
        html: formattedBody,
        text: data.body,
        staff_id_created: user?.id,
        ...additionalValuesInRequest,
      }

      await axios.post(`${REACT_APP_API_URL}/emails`, email)
    } else {
      const emails = data.recipients.map(({ value }) => ({
        to: value.value,
        debtor_id: data.to?.value === 'debtor' ? value.id : null,
        company_id: data.to?.value === 'client' ? value.id : null,
        staff_id: data.to?.value === 'staff' ? value.id : null,
        staff_id_created: user?.id,
        ...additionalValuesInRequest,
      }))

      await axios.post(`${REACT_APP_API_URL}/emails/send/${data.bcc ? 'bcc' : 'cc'}`, {
        subject: data.subject,
        html: formattedBody,
        emails,
      })
    }
  }

  useEffect(() => {
    if (!to) return
    setValue('recipients', to?.value === 'other' ? '' : null)
  }, [to])

  useEffect(() => {
    if (!template) return
    setValue('body', template.value)
  }, [template])

  useEffect(() => {
    if (!isOpen) {
      reset(DEFAULT_EMAIL_SENDING_WITH_RECEPIENTS_FORM_VALUES)
    }
  }, [isOpen])

  const recipientsOptions = {
    debtor: debtorOptions,
    client: clientOptions,
    staff: staffOptions,
  }

  const currentRecepientsOptions = transformRecipientsOptions(
    recipientsOptions[to?.value || 'debtor'],
  )

  const filteredToOptions = DEFAULT_TO_OPTIONS.filter(
    (option) => !excludeFromTo.includes(option.value),
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose} withBtns={false}>
      {children}
      <form className={styles.wrapper}>
        <LabelWrapper label="To" name="to" control={control}>
          {({ onChange, value }) => (
            <Select onChange={onChange} value={value} options={filteredToOptions} />
          )}
        </LabelWrapper>
        {['debtor', 'client', 'staff'].includes(to?.value || '') ? (
          <div>
            <LabelWrapper control={control} name={'bcc'} label="">
              {({ value, onChange }) => {
                return (
                  <div className="d-flex" style={{ justifyContent: 'end' }}>
                    <Switch value={value as boolean} onChange={onChange} />
                    <span className="ml-2">BCC</span>
                  </div>
                )
              }}
            </LabelWrapper>
            <LabelWrapper label="Recipients" name="recipients" control={control}>
              {({ onChange, value }) => (
                <Select
                  onChange={(value) => handleRecipientsChange(value, onChange)}
                  value={transformRecipientsOptions(value as NotificationOption[])}
                  options={currentRecepientsOptions}
                  isMulti
                />
              )}
            </LabelWrapper>
          </div>
        ) : null}
        {to?.value === 'other' ? (
          <LabelWrapper label="Recipient" name="recipients" control={control}>
            {({ onChange, value }) => (
              <input
                className="react-select"
                type="text"
                onChange={onChange}
                value={value as string}
              />
            )}
          </LabelWrapper>
        ) : null}
        <LabelWrapper label="Template" name="template" control={control}>
          {({ onChange, value }) => (
            <Select onChange={onChange} value={value} options={templateOptions} />
          )}
        </LabelWrapper>
        <LabelWrapper label="Subject" name="subject" control={control}>
          {({ onChange, value }) => (
            <input className="react-select" onChange={onChange} value={value as string}></input>
          )}
        </LabelWrapper>

        <div className={styles.textEditorWrapper}>
          <ErrorMessage
            errors={errors}
            name={'body'}
            render={({ message }) => {
              return <p className={styles.error}>{message}</p>
            }}
          />
          <Controller
            control={control}
            name="body"
            render={({ field }) => (
              <TextEditor value={field.value} onEditorChange={field.onChange} />
            )}
          />
        </div>
        <div className={styles.formActionsWrapper}>
          <button
            type="button"
            className={styles.cancelButton}
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            type="submit"
            className={cls('btn btn-secondary', styles.sendButton)}
            onClick={handleSubmit(onSubmit)}
          >
            Send
            {isEmailSending ? (
              <Oval
                visible={true}
                height="12"
                width="12"
                color="white"
                secondaryColor="white"
                ariaLabel="oval-loading"
              />
            ) : null}
          </button>
        </div>
      </form>
    </Modal>
  )
}

export default EmailSendingModalWithRecipients
