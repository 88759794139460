import TableCell from '@components/table/table-cell-component'
import TableRow from '@components/table/table-row.component'
import { NOTE_TYPE_TO_LABEL } from '@containers/Communication/const/communication.const'
import { format } from 'date-fns'
import { FC } from 'react'
import styles from './combined-note-table.module.scss'
import cls from 'classnames'
import { CombinedNotesTableItemProps } from '@containers/Communication/types/communication.type'

const Ava = `${process.env.PUBLIC_URL}/image/user.jpg`

const CombinedNotesTableItem: FC<CombinedNotesTableItemProps> = ({ note, onNoteClick }) => {
  const { important, title, type, createdAt } = note

  const handleCombinedNoteClick = () => {
    onNoteClick && onNoteClick(note)
  }

  return (
    <TableRow
      className={cls(styles.combinedNoteRow, styles[type], {
        [styles.important]: important,
      })}
      onClick={handleCombinedNoteClick}
      pointer
    >
      {/* STAFF */}
      <TableCell position="eclipsed" width={80}>
        <img className="topbar__avatar-img" src={Ava} alt="avatar" />
      </TableCell>
      {/* DATE */}
      <TableCell position="eclipsed" width={120}>
        {format(createdAt, 'MMM dd yyyy')}
      </TableCell>
      {/* TIME */}
      <TableCell position="eclipsed" width={120}>
        {' '}
        {format(createdAt, 'HH:mm aa')}
      </TableCell>
      {/* NOTE */}
      <TableCell position="eclipsed"> {title}</TableCell>
      {/* NOTE TYPE */}
      <TableCell className={styles.noteTypeCell} position="eclipsed" width={120}>
        {important ? 'Important' : NOTE_TYPE_TO_LABEL[type]}
      </TableCell>
    </TableRow>
  )
}

export default CombinedNotesTableItem
