import { DeepPartial } from "react-hook-form";
import { DebtorAddressHistoryService } from "../../services/debtor-address-history.service"
import { DebtorAddress } from "../../types/debtor-address.type";
import { Params } from "@src/types/service.type";
import { useMutation } from "@tanstack/react-query";

export const useUpdateDebtorAddressMutation = () => {

  const updateDebtorAddress = (debtorAddressId: number, updateData: DeepPartial<DebtorAddress>, params: Params = {}) => {
    const debtorAddressService = new DebtorAddressHistoryService();
    return debtorAddressService.updateDebtorAddress(debtorAddressId, updateData, params)
  }

  const mutation = useMutation({
    mutationFn: ({ debtorAddressId, updateData, params }: { debtorAddressId: number, updateData: DeepPartial<DebtorAddress>, params?: Params }) => updateDebtorAddress(debtorAddressId, updateData, params)
  })

  return mutation;
}