import { Landlord } from "@containers/Sales/Properties/types/property.type";
import { REACT_APP_API_URL } from "@src/lib/global-variables";
import { PaginationResponse, Params } from "@src/types/service.type";
import axios from "axios";
import { DeepPartial } from "react-hook-form";


export class LandlordService {

  async getLandlords(params: Params = {}): Promise<PaginationResponse<Landlord[]>> {
    const res = await axios.get<PaginationResponse<Landlord[]>>(`${REACT_APP_API_URL}/landlords`, { params })
    return res.data;
  }

  async updateLandlord(landlordId: number, data: DeepPartial<Landlord>) {
    const res = await axios.patch(`${REACT_APP_API_URL}/landlords/${landlordId}`, data)
    return res.data;
  }
}