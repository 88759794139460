import { useTaskContext } from '@src/context/task-context'
import { NOTE_QUERY } from '../../../const/note-query.const'
import GenericTable from '@components/generic-table/generic-table'
import { UniformEmail } from '../../../../../../../Communication/types/email.type'
import EmailHistoryItem from './email-history-item.component'
import { useModal } from '@src/hooks/useModal'
import { useMemo, useState } from 'react'
import EmailModal from '@containers/Communication/components/modals/email-modal.component'
import { EmailService } from '../../../../../../../Communication/services/email.service'
import LastSyncDate from '../../../../../../../Communication/components/tables/email-table/last-sync-date.component'
import styles from './email-tab.module.scss'
import { useRefreshEmails } from '../../../hooks/refresh-emails.hook'
import RecoverySendMailModal from '@containers/Setting/components/Contacts/taskboard/modals/notification-modals/recovery-send-mail-modal/recovery-send-mail-modal.component'
import SalesSendMailModal from '@containers/Setting/components/Contacts/taskboard/modals/notification-modals/sales-send-mail-modal/sales-send-mail-modal.component'
import RefreshButton from '@containers/Inbox/components/refresh-button/refresh-button.component'

const EmailHistoryTable = () => {
  const { mutateAsync: refreshEmailMutation } = useRefreshEmails()
  const [lastSyncedDate, setLastSyncedDate] = useState<Date | null>(null)
  const [isSendEmailModalOpen, handleSendEmailOpen, handleSendEmailClose] = useModal(false)
  const [isViewEmailModalOpen, openViewModal, handleViewEmailClose] = useModal(false)
  const [selectedEmail, setSelectedEmail] = useState<UniformEmail | null>(null)

  const { selectedCompanyState, isRecovery, currentWip } = useTaskContext()

  const params = {
    wip_id: isRecovery ? currentWip : undefined,
    client_id: isRecovery ? undefined : selectedCompanyState?.id,
  }

  const handleViewEmailOpen = (email: UniformEmail) => {
    setSelectedEmail(email)
    openViewModal()
  }

  const getUniformEmails = async (pagination: { page: number; limit: number }) => {
    const emailService = new EmailService()
    try {
      const response = await emailService.getLocalEmailsSyncedWithOutlookEmails({
        ...params,
        ...pagination,
      })
      setLastSyncedDate(new Date(response.last_synced_at))
      return {
        total: response.total,
        data: response.data,
      }
    } catch (e) {
      return {
        total: 0,
        data: [],
      }
    }
  }

  const queryKey = useMemo(() => [NOTE_QUERY.EMAIL.GET, params], [selectedCompanyState])

  return (
    <div>
      <div className="d-flex justify-content-between mb-3">
        <h3>Email History</h3>
        <button type="submit" className="btn btn-secondary" onClick={handleSendEmailOpen}>
          Send Email
        </button>
      </div>
      {lastSyncedDate ? (
        <div className={styles.syncWrapper}>
          <LastSyncDate date={lastSyncedDate} />
          <RefreshButton onClick={() => refreshEmailMutation(params)} />
        </div>
      ) : null}
      <GenericTable
        queryKey={queryKey}
        queryFn={async (pagination) => getUniformEmails(pagination)}
        enabled={isRecovery ? !!currentWip : !!selectedCompanyState?.id}
        headerOptions={['Date', 'Time', 'To', 'Subject', 'Sender', 'Action']}
        limit={5}
      >
        {({ data }) => {
          return data.map((email, index) => (
            <EmailHistoryItem email={email} openViewModal={handleViewEmailOpen} key={`${index}`} />
          ))
        }}
      </GenericTable>
      <EmailModal
        id={selectedEmail?.id || null}
        emailType={selectedEmail?.email_type || 'Local'}
        userId={selectedEmail?.user_id || null}
        onClose={handleViewEmailClose}
        isOpen={isViewEmailModalOpen}
      />
      {isRecovery ? (
        <RecoverySendMailModal
          isOpen={isSendEmailModalOpen && !!selectedCompanyState?.id}
          onClose={handleSendEmailClose}
        />
      ) : (
        <SalesSendMailModal
          isOpen={isSendEmailModalOpen && !!selectedCompanyState?.id}
          onClose={handleSendEmailClose}
        />
      )}
    </div>
  )
}

export default EmailHistoryTable
