import React from 'react';

import { invoiceTemplatesSharedStyles } from './styles';
import { Grid, Typography } from '@mui/material';

export const InvoiceTemplateLayout = ({ heading, children }) => {
  const { container, title, card, header } = invoiceTemplatesSharedStyles;

  return (
    <Grid sx={container}>
      <Grid sx={header}>
        <Typography sx={title}>{heading}</Typography>
      </Grid>
      <Grid sx={card}>{children}</Grid>
    </Grid>
  );
};