import React, { FC, useEffect, useState } from 'react'
import styles from './editable-div.module.scss'
import cls from 'classnames'

interface EditableDivProps {
  value: string
  isEditing?: boolean
  editable?: boolean
  onSelect?: () => void
  onUnselect?: () => void
  onChange?: (value: string) => void
}

const EditableDiv: FC<EditableDivProps> = ({
  value,
  isEditing,
  editable = true,
  onSelect,
  onUnselect,
  onChange,
}) => {
  const [isInnerEditing, setIsInnerEditing] = useState(false)
  const [text, setText] = useState(value)

  const handleDivClick = () => {
    if (!editable) {
      return
    }
    onSelect && onSelect()
    setIsInnerEditing(true)
  }

  const handleInputBlur = (event) => {
    setText(event.target.value)
    setIsInnerEditing(false)
    onUnselect && onUnselect()
  }

  const handleInputChange = (event) => {
    setText(event.target.value)
    onChange && onChange(event.target.value)
  }

  useEffect(() => {
    if (!isEditing) {
      return
    }

    setIsInnerEditing(isEditing)
  }, [isEditing])

  useEffect(() => {
    setText(value)
  }, [value])

  return (
    <>
      {isInnerEditing ? (
        <div className={cls(styles.divInputWrapper)}>
          <input
            value={text}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            autoFocus
            className={styles.divInput}
          />
        </div>
      ) : (
        <div
          className={cls(styles.div, {
            [styles.notEditable]: !editable,
          })}
          onClick={handleDivClick}
        >
          {text}
        </div>
      )}
    </>
  )
}

export default EditableDiv
