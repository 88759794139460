import { FC, useCallback, useEffect, useRef, useState } from 'react'
import PaginatedSelectSubOption from './paginated-select-sub-option.component'
import styles from './paginated-select.module.scss'
import RoundedArrow from '@src/icons/rounded-arrow'
import { SelectSubOption, PaginatedSelectOptionProps } from './pagination-select.type'
import { useDebounce } from '@src/hooks/useDebounce'
import { Oval } from 'react-loader-spinner'

const PaginatedSelectOption: FC<PaginatedSelectOptionProps> = ({
  option,
  subOptions,
  selectedSubOptions,
  isLoading = false,
  onSubOptionChange,
  onReachEnd,
  onSearchChange,
}) => {
  const observer = useRef<IntersectionObserver>()
  const [search, setSearch] = useState<string | null>(null)
  const debouncedSearch = useDebounce(search, 500)
  const [isOptionOpen, setIsOptionOpen] = useState(false)

  const handleOptionClick = () => {
    setIsOptionOpen((prev) => !prev)
  }

  const isSubOptionSelected = (subOption: SelectSubOption) => {
    return selectedSubOptions.some(
      (selectedSubOption) => selectedSubOption.value === subOption.value,
    )
  }

  const handleSubOptionChange = (event: { option: SelectSubOption; value: boolean }) => {
    if (!onSubOptionChange) return
    onSubOptionChange({
      ref: option.value,
      option: event.option,
      value: event.value,
    })
  }

  const lastElementRef = useCallback(
    (node: HTMLLIElement) => {
      // isLoading
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && onReachEnd) {
          onReachEnd({ ref: option.value })
        }
      })
      if (node) observer.current.observe(node)
    },
    // isLoading
    [subOptions],
  )

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value)
  }

  useEffect(() => {
    if (debouncedSearch === null || !onSearchChange) return
    onSearchChange({ ref: option.value, value: debouncedSearch })
  }, [debouncedSearch])

  return (
    <div className={styles.optionWrapper}>
      <div className={styles.headerOptionWrapper} onClick={handleOptionClick}>
        <span>{option.label}</span>
        <RoundedArrow className={styles.openIcon} />
      </div>
      {isOptionOpen && (
        <div>
          <input
            className={styles.search}
            placeholder="Search"
            value={search || ''}
            onChange={handleSearchChange}
            type="text"
          />
          <ul className={styles.subOptionsWrapper}>
            {subOptions.map((subOption, index) => (
              <PaginatedSelectSubOption
                key={`sub-option-${subOption.value}-${index}`}
                option={subOption}
                ref={subOptions.length === index + 1 ? lastElementRef : null}
                onSubOptionChange={handleSubOptionChange}
                selected={isSubOptionSelected(subOption)}
              />
            ))}
            {isLoading ? (
              <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <Oval
                  visible={true}
                  height="40"
                  width="40"
                  color="blue"
                  secondaryColor="blue"
                  ariaLabel="oval-loading"
                />
              </div>
            ) : null}
          </ul>
        </div>
      )}
    </div>
  )
}

export default PaginatedSelectOption
