import * as React from "react"
const PieChartIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    className="YIUegm7fh_CpJbivTu6B MnxxlQlR1H0xJuMEE8Yr PeR2JZ9BZHYIH8Ea3F36 bcsWqjK52oeyT6oeC2Az gZ3KuFw1JESHhOJhjT8j _Oyukq8JlN1X9w2FmPds XIIs8ZOri3wm8Wnj9N_y Lld6j9B1iilEqA6j31e4"
    viewBox="0 0 20 20"
    {...props}
  >
    <path d="M2 10a8 8 0 0 1 8-8v8h8a8 8 0 1 1-16 0z" />
    <path d="M12 2.252A8.014 8.014 0 0 1 17.748 8H12V2.252z" />
  </svg>
)
export default PieChartIcon