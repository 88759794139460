import Modal from '@components/modal'
import { FC } from 'react'
import styles from './debtor-reference-modal.module.scss'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import { useQueryClient } from '@tanstack/react-query'
import {
  CreateDebtorAssociateDetailModalProps,
  DebtorReference,
} from '@containers/Recovery/Wip/_modules/Debtors/types/debtor-associate-detail.type'
import CreateDebtorReferenceForm from '../../forms/debtor-reference-form/create-debtor-reference-form.component'
import { getDebtorQueryKey } from '@containers/Recovery/Wip/_modules/Debtors/utils/debtor-query-key-factory.utils'
import { Debtor } from '@containers/Recovery/Wip/_modules/Debtors/types/debtor.type'

const CreateDebtorReferenceModal: FC<CreateDebtorAssociateDetailModalProps> = ({
  debtorId,
  ...modalProps
}) => {
  const queryClient = useQueryClient()

  const handleSuccessSubmit = (debtorReference: DebtorReference) => {
    queryClient.setQueryData<Debtor>(getDebtorQueryKey(debtorId), (prevData) =>
      prevData ? { ...prevData, references: [...prevData.references, debtorReference] } : undefined,
    )
    modalProps.onClose()
  }

  return (
    <Modal className={styles.modalWrapper} {...modalProps} withBtns={false} classNameLayout={styles.layout}>
      <Header mb={4}>Add Reference</Header>
      <CreateDebtorReferenceForm
        debtorId={debtorId}
        onSuccessSubmit={handleSuccessSubmit}
        onCancelClick={modalProps.onClose}
      />
    </Modal>
  )
}

export default CreateDebtorReferenceModal
