import { GeneralInterestCalculatorService } from "../services/general-interest-calculator.service"
import { CreateGeneralInterestCalculatorData, UpdateGeneralInterestCalculatorData } from "../types/interest-rate.type"
import { useMutation } from "@tanstack/react-query"


export const useCreateGeneralInterestCalculator = () => {

  const createGeneralInterestCalculator = async (data: CreateGeneralInterestCalculatorData) => {
    const generalInterestCalculatorService = new GeneralInterestCalculatorService()
    return generalInterestCalculatorService.createGeneralInterestCalculator(data)
  }

  const mutation = useMutation(createGeneralInterestCalculator)

  return mutation
}

export const useUpdateGeneralInterestCalculator = () => {

  const updateGeneralInterestCalculator = async (id: number, data: UpdateGeneralInterestCalculatorData) => {
    const generalInterestCalculatorService = new GeneralInterestCalculatorService()
    return generalInterestCalculatorService.updateGeneralInterestCalculator(id, data)
  }

  const mutation = useMutation({
    mutationFn: ({ id, data }: { id: number, data: UpdateGeneralInterestCalculatorData }) => updateGeneralInterestCalculator(id, data)
  })

  return mutation
}