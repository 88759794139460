import { useForm } from 'react-hook-form'
import styles from './claim-details-form.module.scss'
import { CLAIM_DETAILS_DEFAULT_VALUES } from '../../const/claim-details.const'
import { ClaimDetailsFormProps, ClaimDetailsFormValues } from '../../types/claim-details.type'
import LabelWrapper from '@components/label-wrapper'
import { FC, useEffect } from 'react'
import axios from 'axios'
import { REACT_APP_API_URL } from '@src/lib/global-variables'
import { toast } from 'react-toastify'
import { onlyNumber } from '../../utils/input.utils'
import { useModal } from '@src/hooks/useModal'
import CreateClaimDetailNoteModal from '../create-claim-details-note-modal/create-claim-details-note-modal.component'
import { joiResolver } from '@hookform/resolvers/joi'
import { claimDetailsSchema } from '../../schemas/claim-details.schema'

const ClaimDetailsForm: FC<ClaimDetailsFormProps> = ({ wipId, claimDetailsInitialValues }) => {
  const { control, handleSubmit, setValue, reset } = useForm<ClaimDetailsFormValues>({
    defaultValues: CLAIM_DETAILS_DEFAULT_VALUES,
    resolver: joiResolver(claimDetailsSchema)
  })
  const [isCreateNoteModalOpen, openCreateNoteModal, closeCreateNoteModal] = useModal(false)

  const onSubmit = async (data: ClaimDetailsFormValues) => {
    const { currentTotal } = data

    try {
      await axios.patch(`${REACT_APP_API_URL}/wips/${wipId}`, { claim_details_total: currentTotal })
      setValue('total', currentTotal)
      toast.success('Total updated')
    } catch {
      toast.error('Something went wrong')
    }
  }

  useEffect(() => {
    reset({
      total: claimDetailsInitialValues.claim_details_total || 0,
      currentTotal: claimDetailsInitialValues.claim_details_total || 0,
    })
  }, [claimDetailsInitialValues])

  return (
    <form className={styles.wrapper} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.fieldWrapper}>
        <LabelWrapper className={styles.labelWrapper} label="Total:" control={control} name="total">
          {({ value }) => (
            <input className="custom-input" disabled={true} value={value} type="text" />
          )}
        </LabelWrapper>
        <LabelWrapper
          className={styles.labelWrapper}
          label="Current Total:"
          control={control}
          name="currentTotal"
        >
          {({ onChange, value }) => (
            <input
              className="custom-input"
              onChange={(e) => onlyNumber(e, onChange)}
              value={value}
              type="text"
            />
          )}
        </LabelWrapper>
      </div>
      <div className={styles.actionWrapper}>
        <button type="button" className="btn btn-secondary" onClick={openCreateNoteModal}>
          Add Note
        </button>
        <button type="submit" className="btn btn-secondary">
          Change Total
        </button>
      </div>
      <CreateClaimDetailNoteModal
        wipId={wipId}
        isOpen={isCreateNoteModalOpen}
        onClose={closeCreateNoteModal}
      />
    </form>
  )
}

export default ClaimDetailsForm
