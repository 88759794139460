import Joi from "joi"

export const debtorDetailsEmailsFormSchema = Joi.object({
  emails: Joi.array().items(Joi.string().email({ tlds: { allow: false } }).required()).required().messages({
    'string.email': 'Invalid email',
    'string.empty': 'Required',
  })
})


export const debtorAddressSchema = Joi.object({
  address_1: Joi.string().required(),
  address_2: Joi.string().required(),
  address_3: Joi.string().required(),
  suburb: Joi.string().required(),
  state: Joi.object({ label: Joi.string().required(), value: Joi.string().required() }).required(),
  postcode: Joi.number().required(),
  country: Joi.string().required(),
}).messages({ 'string.empty': "Required", 'any.required': "Required", 'number.base': 'Must be a number' })

const phoneSchema = Joi.string().regex(/^\+(61|64)\d{8,9}$/).allow(null)

export const debtorDetailsPhoneSchema = Joi.object({
  selectedPhoneNumber: Joi.object().allow(null).optional(),
  phones: Joi.object({
    comms_ph_extension: phoneSchema,
    comms_ph_fax: phoneSchema,
    comms_ph_direct: phoneSchema,
    comms_ph_office: phoneSchema,
    comms_ph_mobile: phoneSchema,
    comms_ph_home: phoneSchema,
    comms_ph_work: phoneSchema,
    comms_ph_mobile_secondary: phoneSchema,
  })
}).messages({ 'string.pattern.base': 'Must be a phone', 'string.empty': 'Required' })

export const debtorReferenceSchema = Joi.object({
  name: Joi.string().required(),
  address: Joi.string().required(),
  call_result: Joi.object({ label: Joi.string().required(), value: Joi.string().required() }).required(),
  call_note: Joi.string().required(),
  phone: phoneSchema.required()
}).messages({ 'string.empty': "Required", 'any.required': "Required", 'string.pattern.base': 'Invalid phone number' })

export const debtorOtherSchema = Joi.object({
  name: Joi.string().required(),
  address: Joi.string().required(),
  call_result: Joi.object({ label: Joi.string().required(), value: Joi.string().required() }).required(),
  call_note: Joi.string().required(),
  phone: phoneSchema.required()
}).messages({ 'string.empty': "Required", 'any.required': "Required", 'string.pattern.base': 'Invalid phone number' })

export const debtorRelationSchema = Joi.object({
  name: Joi.string().required(),
  relationship: Joi.object({ label: Joi.string().required(), value: Joi.string().required() }).required(),
  address: Joi.string().required(),
  call_result: Joi.object({ label: Joi.string().required(), value: Joi.string().required() }).required(),
  call_note: Joi.string().required(),
  phone: phoneSchema.required()
}).messages({ 'string.empty': "Required", 'any.required': "Required", 'string.pattern.base': 'Invalid phone number' })

export const debtorWorkPlaceSchema = Joi.object({
  name: Joi.string().required(),
  contact: Joi.string().required(),
  address: Joi.string().required(),
  call_result: Joi.object({ label: Joi.string().required(), value: Joi.string().required() }).required(),
  call_note: Joi.string().required(),
  phone: phoneSchema.required()
}).messages({ 'string.empty': "Required", 'any.required': "Required", 'string.pattern.base': 'Invalid phone number' })