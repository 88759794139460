import Dropdown from '@components/dropdown/dropdown.component'
import EmailTable from './email-table.component'
import { TABLE_DROPDOWN_DATE_OPTIONS } from '@components/table/table.const'
import { FC, useState } from 'react'
import FormInput from '@components/inputs/form-input/form-input.component'
import SearchIcon from '@src/icons/search'
import styles from './email-table.module.scss'
import CloackIcon from '@src/icons/cloack'
import RefreshButton from '@containers/Inbox/components/refresh-button/refresh-button.component'
import { useRefreshEmails } from '@containers/Setting/components/Contacts/Notes/hooks/refresh-emails.hook'
import LastSyncDate from './last-sync-date.component'
import { PaginationResponse } from '@src/types/service.type'
import { UniformEmail } from '@containers/Communication/types/email.type'
import { EmailTableWithFilterProps } from '@containers/Communication/types/communication.type'
import { SelectOption } from '@src/types/component-types'

const EmailTableWipFilter: FC<EmailTableWithFilterProps> = ({ params }) => {
  const { mutateAsync } = useRefreshEmails()
  const [page, setPage] = useState(1)
  const [lastSyncedDate, setLastSyncedDate] = useState<Date | null>(null)
  const [selectedDate, setSelectedDate] = useState(TABLE_DROPDOWN_DATE_OPTIONS[3])

  const handleEmailListChange = (
    emails: PaginationResponse<UniformEmail[]> & { last_synced_at: Date },
  ) => {
    setLastSyncedDate(emails.last_synced_at)
  }

  const handleSelectedDateChange = (selectedDate: SelectOption) => {
    setSelectedDate(selectedDate)
    setPage(1)
  }

  return (
    <>
      <div>
        <div className={styles.emailTableFilter}>
          <Dropdown
            options={TABLE_DROPDOWN_DATE_OPTIONS}
            selected={selectedDate}
            onSelectedChange={handleSelectedDateChange}
            icon={CloackIcon}
          />
          <div className={styles.searchFieldWrapper}>
            <FormInput size="small" placeholder="Search" icon={SearchIcon} />
          </div>
        </div>
        {lastSyncedDate ? (
          <div className={styles.syncWrapper}>
            <LastSyncDate date={lastSyncedDate} />
            <RefreshButton onClick={() => mutateAsync(params)} />
          </div>
        ) : null}
      </div>
      <EmailTable
        currentPage={page}
        onPageChange={setPage}
        params={params}
        onEmailListChange={handleEmailListChange}
      />
    </>
  )
}

export default EmailTableWipFilter
