import { FC } from 'react'
import { TotalClaimAmountFormInitialValues } from '../../../types/claim.type'
import TotalClaimAmountForm from './total-claim-amount-form.component'
import { useUpdateWipByIdMutation } from '@containers/Recovery/Wip/hooks/mutations/update-wip-by-id.hook'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import { getWipQueryKey } from '@containers/Recovery/Wip/utils/wip-query-factory'
import { Wip } from '@containers/Recovery/Wip/types/wip.type'

export interface EditTotalClaimAmountFormProps {
  initialValues?: TotalClaimAmountFormInitialValues
  wipId: number
  onEditInterestRateClick?: () => void
}

const EditTotalClaimAmountForm: FC<EditTotalClaimAmountFormProps> = ({
  initialValues,
  wipId,
  onEditInterestRateClick,
}) => {
  const queryClient = useQueryClient()
  const { mutateAsync, isLoading } = useUpdateWipByIdMutation()

  const handleSubmit = async ({ claimTotalAmount }: TotalClaimAmountFormInitialValues) => {
    try {
      const updatedWip = await mutateAsync({
        id: wipId,
        data: { claim_details_total: claimTotalAmount },
        params: { select: 'claim_details_total' },
      })

      const queryKey = getWipQueryKey(wipId)
      queryClient.setQueryData<Wip>(queryKey, (prevData) =>
        prevData
          ? {
              ...prevData,
              claim_details_total: updatedWip.claim_details_total,
            }
          : undefined,
      )

      toast.success('Total claim amount updated successfully')
    } catch (e) {
      console.error(e)
      toast.error('Failed to update total claim amount')
    }
  }

  return (
    <TotalClaimAmountForm
      initialValues={initialValues}
      onEditInterestRateClick={onEditInterestRateClick}
      onSubmit={handleSubmit}
      loading={isLoading}
    />
  )
}

export default EditTotalClaimAmountForm
