import { useEffect } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { TabWrapper } from './TabWrapper/TabWrapper'
import { getCompaniesById } from '../../../../redux/actions'
import { useDispatch } from 'react-redux'
import Tools from './../Tools/tools'
import PropertiesList from './../Tools/Properties/PropertyList'
import WIPList from './WIPList'
import Account from './Account'
import TaskList from './TaskList'
import Contacts from './Contacts'
import InvoiceList from '../Tools/Invoice/InvoiceList'
import EditInvoice from '../Tools/Invoice/EditInvoice/EditInvoice'
import AddInvoice from '../Tools/Invoice/AddInvoice/AddInvoice'
import Notes from './Notes/notes.component'

const ContactsPage = () => {
  const { path } = useRouteMatch()
  const dispatch = useDispatch()
  const contactsId = localStorage.getItem('contactsId')

  useEffect(() => {
    getClientById()
  }, [])

  const getClientById = () => {
    if (contactsId) {
      const params = {
        id: contactsId,
        join: ['contacts', 'properties'],
      }

      dispatch(getCompaniesById(params))
    }
  }

  return (
    <div className="container position-relative">
      <div className="client_info-block mt-3">
        <TabWrapper />
        <div className="tab-content">
          <Switch>
            <Route
              exact
              path={`${path}/invoice-list`}
              render={(props) => <InvoiceList {...props} dispatch={dispatch} />}
            />

            <Route exact path={`${path}/tools`} render={(props) => <Tools {...props} />} />

            <Route
              exact
              path={`${path}/properties-list`}
              render={(props) => <PropertiesList {...props} />}
            />

            <Route exact path={`${path}/account`} render={(props) => <Account {...props} />} />

            <Route exact path={`${path}/notes`} render={(props) => <Notes {...props} />} />

            <Route exact path={`${path}/task-list`} render={(props) => <TaskList {...props} />} />

            <Route exact path={`${path}/wip-list`} render={(props) => <WIPList {...props} />} />

            <Route
              exact
              path={`${path}/add-invoice`}
              render={(props) => <AddInvoice {...props} />}
            />

            <Route
              exact
              path={`${path}/edit-invoice/:id`}
              render={(props) => <EditInvoice {...props} />}
            />

            <Route exact path={`${path}/:id`} render={(props) => <Contacts {...props} />} />
          </Switch>
        </div>
      </div>
    </div>
  )
}

export default ContactsPage
