import request from '../../shared/request/request';
import { applicationEndpoints } from '../utils/endpoints';

export const getCommercialUncoveredListService = async (page) =>
  request('get', `${applicationEndpoints.commercialUncoveredForm}?page=${page}`);

export const postCommercialUncoveredFormService = async (data) =>
  request('post', applicationEndpoints.commercialUncoveredForm, data);

export const removeCommercialUncoveredById = async (id) =>
  request('delete', `${applicationEndpoints.commercialUncoveredForm}/${id}`);

export const createWIPCommercialUncoveredService = async (data) =>
  request('post', `${applicationEndpoints.commercialUncoveredForm}/wip`, data);

export const getCommercialUncoveredFormByIdService = async (id) =>
  request('get', `${applicationEndpoints.commercialUncoveredForm}/${id}`);

export const editCommercialUncoveredFormService = async (id, data) =>
  request('patch', `${applicationEndpoints.commercialUncoveredForm}/${id}`, data);

export const getPropertyByIdService = async (id) =>
  request('get', `${applicationEndpoints.property}/${id}`);

export const getPaymentDetailsService = async () =>
  request('get', `${applicationEndpoints.paymentDetails}`);

export const getInvoiceTemplatesListService = async (page) => {
  if (page)
    return request(
      'get',
      `${applicationEndpoints.invoiceTemplates}?limit=10&page=${page}&sort=id,DESC`,
    );

  return request('get', `${applicationEndpoints.invoiceTemplates}?sort=id,DESC`);
};

export const createInvoiceTemplateService = async (data) =>
  request('post', `${applicationEndpoints.invoiceTemplates}`, data);

export const postInvoiceService = async (data) =>
  request('post', `${applicationEndpoints.invoice}`, data);

export const getInvoiceListService = async (page, status) =>
  request(
    'get',
    `${applicationEndpoints.invoice}?page=${page}${
      status && status !== 'All' ? `&filter=status||$eq||${status}` : ''
    }`,
  );

export const getInvoicePDFService = async (pdfID) =>
  request('get', `${applicationEndpoints.invoice}/pdf?ids=${pdfID}`);

export const getInvoicByIDService = async (id) =>
  request('get', `${applicationEndpoints.invoice}/${id}`);

export const editInvoiceService = async (data, id) =>
  request('patch', `${applicationEndpoints.invoice}/${id}`, data);

export const postInvoiceEmailService = async (data) =>
  request('post', applicationEndpoints.invoiceEmail, data);

export const deleteInvoicByIDService = async (id) =>
  request('delete', `${applicationEndpoints.invoice}/${id}`);

export const getInvoiceTemplateByIdService = async (id) =>
  request('get', `${applicationEndpoints.invoiceTemplates}/${id}`);

export const editInvoiceTemplateService = async (id, data) =>
  request('patch', `${applicationEndpoints.invoiceTemplates}/${id}`, data);

export const deleteInvoiceTemplateService = async (id) =>
  request('delete', `${applicationEndpoints.invoiceTemplates}/${id}`);

export const getEmailTemplateByIdService = async (id) =>
  request('get', `${applicationEndpoints.emailTemplates}/${id}`);
