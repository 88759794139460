import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

const PaymentTracking = (props) => {

  const { value,
    wipId, list, valueList, handleChange, message, trackOnPeriod, trackOnValue, forAmount,
    handlerFor, firstPayment, dateFormat, handleChangeDate, source = [], fromPayment, savePaymentTrack,allStaffOption,trackOnStaff,bankAccount,handleBankAccount
  } = props;

  const history = useHistory();

  return (
    <div>
      <div className='form-group'>
        <button
          className='btn-gray'
          type='button'
          onClick={() => history.push(`/recoveries/transactions`, { wipId: wipId })}
        >
          View Payments
        </button>
      </div>
      <div className='row'>
        <div className='col-12 col-md-6 col-lg-3'>
          <div className='form-group'>
            <label className='form__form-group-label'>
              Track Payments From:
            </label>
            <Select
              type='text'
              classNamePrefix='react-select'
              className='react-select'
              options={list}
              value={valueList}
              name='list'
              onChange={(e) => handleChange(e, 'listSelectDebtor')}
              //isMulti
            />
            <span className='form__form-group-error'>
              {message.message('track payment', valueList, 'required')}
            </span>

          </div>
        </div>
        <div className='col-12 col-md-6 col-lg-3'>
          <div className='form-group'>
            <label className='form__form-group-label'>
              {' '}
              Track on period Of:{' '}
            </label>
            <Select
              type='text'
              classNamePrefix='react-select'
              className='react-select'
              options={trackOnPeriod}
              value={trackOnValue}
              name='trackOn'
              onChange={(e) => handleChange(e, 'trackOn')}
            />
            <span className='form__form-group-error'>
              {message.message('track on period', trackOnValue, 'required')}
            </span>
          </div>
        </div>
        <div className='col-12 col-md-6 col-lg-3'>
          <div className='form-group'>
            <label className='form__form-group-label'>
              {' '}
              Assign staff:{' '}
            </label>
            <Select
              type='text'
              classNamePrefix='react-select'
              className='react-select'
              options={allStaffOption}
              value={value}
              name='assigned_staff'
              onChange={(e) => handleChange(e, 'assigned_staff_tracking')}
              isDisabled={true}
            />
            <span className='form__form-group-error'>
              {message.message('tarck on period', trackOnValue, 'required')}
            </span>
          </div>
        </div>
        <div className='col-12 col-md-6 col-lg-3'>
          <div className='form-group'>
            <label className='form__form-group-label'>
              {' '}
              Bank account:{' '}
            </label>
            <input
              type='text'
              step='0.1'
              className='custom-input'
              name='bank_account'
              value={bankAccount || ''}
              onChange={handleBankAccount}
              placeholder='Account'
            />
            <span className='form__form-group-error'>
              {message.message('Bank account', bankAccount, 'required')}
            </span>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-12 col-md-6 col-lg-3'>
          <div className='form-group'>
            <label className='form__form-group-label'>
              {' '}
              For Amount of ($):{' '}
            </label>
            <input
              type='text'
              step='0.1'
              className='custom-input'
              name='foramount'
              value={forAmount || ''}
              onChange={handlerFor}
              placeholder='Staff Name'
            />
            <span className='form__form-group-error'>
              {message.message('amount', forAmount, 'required|currency')}
            </span>
          </div>
        </div>
        <div className='col-12 col-md-6 col-lg-3'>
          <div className='form-group'>
            <label className='form__form-group-label'>
              {' '}
              First Payment Due:{' '}
            </label>
            <div className='date-picker date-picker--interval'>
              <DatePicker
                selected={
                  firstPayment
                    ? dateFormat(firstPayment)
                    : ''
                }
                onChange={(e) =>
                  handleChangeDate(e, 'firstPayment')
                }
                minDate={new Date()}
                name='firstPayment'
                dateFormat='dd/MM/yy'
                dropDownMode='select'
                popperPlacement='center'
                placeholderText='Select Date'

              />
              <span className='form__form-group-error'>
                {message.message('Date', firstPayment, 'required')}
              </span>
              <span className='date_icon'>
                <img src={'./image/calendar.svg'} alt='date' />
              </span>
            </div>
            <span className='form__form-group-error' />
          </div>
        </div>
        <div className='col-12 col-md-6 col-lg-3'>
          <div className='form-group'>
            <label className='form__form-group-label'>
              {' '}
              From:{' '}
            </label>
            <Select
              type='text'
              classNamePrefix='react-select'
              className='react-select'
              options={source}
              value={fromPayment}
              name='fromPayment'
              onChange={(e) => handleChange(e, 'fromPayment')}
            />
            <span className='form__form-group-error'>
              {message.message('from', fromPayment, 'required')}
            </span>
          </div>
        </div>
        <div className='w-100 d-flex justify-content-end '>
          <button
            type='button'
            className='btn-group--icons btn btn-secondary'
            onClick={savePaymentTrack}
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentTracking;
