export const STRATA_LIST_TABLE_HEAD_ITEMS = [
  {
    id: 'time_created',
    numeric: false,
    disablePadding: true,
    label: 'Submitted',
    sortable: true,
  },
  {
    id: 'recovery_type',
    numeric: true,
    disablePadding: false,
    label: 'Recovery Type',
    sortable: false,
  },
  {
    id: 'tenant',
    numeric: true,
    disablePadding: false,
    label: 'Tenants',
    sortable: false,
  },
  {
    id: 'urf_property_type',
    numeric: true,
    disablePadding: false,
    label: 'Property',
    sortable: false,
  },
  {
    id: 'owner_name',
    numeric: true,
    disablePadding: false,
    label: 'Agent',
    sortable: false,
  },
  {
    id: '',
    numeric: true,
    disablePadding: false,
    label: 'Status',
    sortable: false,
  },
  {
    id: 'protein',
    numeric: true,
    disablePadding: false,
    label: '',
    sortable: false,
  },
];
