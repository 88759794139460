import SelectWithSearch from '@components/inputs/select-with-search/select-with-search.component'
import { FC, useState } from 'react'
import { useGetInfinityLandlords } from '../hooks/crud-landlord.hook'

interface LandlordSearchSelectProps {
  onSelect: (value: number) => void
}

const LandlordSearchSelect: FC<LandlordSearchSelectProps> = ({ onSelect }) => {
  const [page, setPage] = useState<number>(1)
  const [search, setSearch] = useState<string>('')
  const {
    data: landlords,
    hasNextPage,
    isLoading,
    isRefetching,
  } = useGetInfinityLandlords({
    page,
    limit: 10,
    s: search ? JSON.stringify({ companyName: { $contL: search } }) : undefined,
  })

  const isLandlordLoading = isLoading || isRefetching
  const isLandlordNotFound = !isLandlordLoading && landlords.length === 0
  const options = landlords.map((landlord) => ({ label: landlord.companyName, value: landlord.id }))

  return (
    <SelectWithSearch
      searchValue={search}
      onSearchValueChange={setSearch}
      options={options}
      loading={isLandlordLoading}
      notFound={isLandlordNotFound}
      // onReachEnd={handleReachEnd}
      onSelect={onSelect}
    />
  )
}

export default LandlordSearchSelect
