import CustomTableCell from '@components/generic-table/custom-table-cell'
import { format } from 'date-fns'
import { FC } from 'react'
import styles from './sms-tab.module.scss'
import { Button, ButtonGroup, TableRow } from '@material-ui/core'
import { SmsItemProps } from '../../../types/notes.type'

const SmsItem: FC<SmsItemProps> = ({ sms, openViewModal }) => {
  const {id, to, body, from, time_created } = sms
  return (
    <TableRow className={styles.tableRow}>
      <CustomTableCell>{format(time_created, 'dd/MM/LL')}</CustomTableCell>
      <CustomTableCell>{to}</CustomTableCell>
      <CustomTableCell position="eclipsed">{body}</CustomTableCell>
      <CustomTableCell>{from}</CustomTableCell>
      <CustomTableCell>
        <ButtonGroup className="btn-group--icons" dir="ltr">
          <Button style={{ padding: '10px' }} onClick={() => openViewModal(id)}>
            <span className="lnr lnr-eye" />
          </Button>
        </ButtonGroup>
      </CustomTableCell>
    </TableRow>
  )
}

export default SmsItem
