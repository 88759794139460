import { EMAIL_REGEX, PHONE_REGEX } from "@src/lib/regexp.const"

export const PRIORITY_OPTIONS = {
  Normal: 'Normal Priority',
  Medium: 'Medium Priority',
  High: 'High Priority',
}

export const TASK_STATUS_OPTIONS = {
  new: 'New',
  in_progress: 'In Progress',
  done: 'Done',
}

export const TASK_TYPES = {
  0: 'Task',
  1: 'Bug',
  2: 'Story',
}

export const formatAssigneName = ({
  isAssigned,
  first_name,
  last_name,
}: {
  isAssigned: boolean
  first_name: string
  last_name: string
}) => {
  if (!first_name && !last_name) return 'Unassigned'

  if (isAssigned) {
    return `${first_name?.trim()} ${last_name?.trim()} (You)`
  } else {
    return `${first_name?.trim()} ${last_name?.trim()}`
  }
}

export const initialColumns = (taskMode: string) =>
  taskMode === 'SALES'
    ? [
        { id: 1, step: 1, columnColor: '#44A6F5', columnName: 'To do', tasks: [] },
        { id: 2, step: 2, columnColor: '#82C786', columnName: 'In Progress', tasks: [] },
        { id: 3, step: 3, columnColor: '#82C786', columnName: 'Done', tasks: [] },
      ]
    : [
        { id: 1, step: 1, columnColor: '#44A6F5', columnName: 'To do', tasks: [] },
        { id: 2, step: 2, columnColor: '#82C786', columnName: 'Done', tasks: [] },
      ]

export const validateEmail = (email: string): boolean => {
  return EMAIL_REGEX.test(email)
}

export const validatePhone = (phone: string): boolean => {
  return PHONE_REGEX.test(phone)
}