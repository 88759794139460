import { TaskProps } from "@containers/Setting/components/Contacts/taskboard/types";
import { REACT_APP_API_URL } from "@src/lib/global-variables";
import { PaginationResponse, Params } from "@src/types/service.type";
import axios from "axios";

export class TaskService {

  async getTasks(params: Params = {}) {
    const res = await axios.get<PaginationResponse<TaskProps[]>>(`${REACT_APP_API_URL}/tasks`, { params })
    return res.data;
  }
}