import { UserType } from "@src/types/user";

export interface Company {
  id: number;
  name: string;
  client_code: number;
  contacts?: Contact[];
  status: string;
  assigned_to_favorite: UserType[]
  address: {
    address_1: string;
  }
}

export interface Contact {
  id: number;
  comm_email: string;
  first_name: string;
  last_name: string;
  comm_ph_direct: string | null;
  comm_ph_extension: string | null;
  comm_ph_fax: string | null;
  comm_ph_mobile: string | null;
  comm_ph_office: string | null;
}


export interface ErrorModal {
  errorMessage: string;
  errorType: string;
  errorModal: boolean;
  onClose: () => void | Promise<void>;
  onConfirm: () => void;
}

export interface IOrder {
  direction: 'asc' | 'desc';
  orderBy: string;
}

export interface PaginationType {
  page: number;
  limit: number;
}

export interface ContactItemProps {
  isSelected: boolean
  contact: Company,
  openModal: (event, contact: Company) => void
}

export interface ContactSearchProps {
  setSearchValue: (debouncedValue: null | { [x: string]: { [x: string]: string; }; }) => void
}

export interface ContactListModeSwitchProps {
  onChange: (value: null | { 'assigned_to_favorite.id': { $eq: number; }; }) => void;
}

export enum ListModeEnum {
  ALL = 'ALL',
  FAVORITE = 'FAVORITE',
}