import SubNavBar from '@components/nav-bar/sub-nav-bar.component'
import { FC, useState } from 'react'
import { EditDebtorFormTab } from '../../../types/debtor.type'
import { DEBTOR_FORM_NAVIGATION_TABS } from '../../../const/debtor.const'
import DebtorDetailsForm from '../debtor-details-form/debtor-details-form.component'
import DebtorAddressHistoryForm from '../debtor-address-history-form/debtor-address-history-form.component'
import DebtorAssociateDetailsForm from '../debtor-associate-details-form/debtor-associate-details-form.component'
import { EditDebtorFormProps } from '../../../types/debtor-form.type'

const EditDebtorForm: FC<EditDebtorFormProps> = ({ debtor, direction = 'horizontal' }) => {
  const [activeUrl, setActiveUrl] = useState(EditDebtorFormTab.Details)

  const handleSubNavBarChange = (url: string) => {
    setActiveUrl(url as EditDebtorFormTab)
  }

  return (
    <div>
      <SubNavBar
        options={DEBTOR_FORM_NAVIGATION_TABS}
        activeUrl={activeUrl}
        isLink={false}
        onChange={handleSubNavBarChange}
      />
      {activeUrl === EditDebtorFormTab.Details ? (
        <DebtorDetailsForm debtor={debtor} direction={direction} />
      ) : null}
      {activeUrl === EditDebtorFormTab.AddressHistory ? (
        <DebtorAddressHistoryForm debtorId={debtor.id} direction={direction} />
      ) : null}
      {activeUrl === EditDebtorFormTab.AssociateDetails ? (
        <DebtorAssociateDetailsForm debtor={debtor} direction={direction} />
      ) : null}
    </div>
  )
}

export default EditDebtorForm
