import axios from 'axios'
// import constant from "../utils/constant.js";
import qs from 'querystring'
import { toast } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'

const BMS5 = process.env.REACT_APP_API_URL
const mainURL = process.env.REACT_APP_API_URL
// const mainURL="https://new-dev.artoon.in:6098";
// const mainURL="http://13.237.138.212:3000";
const API_URL = mainURL

class Services {
  constructor() {
    if (localStorage.getItem('token') !== undefined) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
    }
  }

  // Authentication

  async login(val, url, cb) {
    let data = qs.stringify(val)

    axios
      .post(BMS5 + '/' + url, data)
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          this.errorToast(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  // Staff Module

  // #TODO: add getOneStaff
  async getStaff(data, cb) {
    const url = '/staff'

    axios
      .get(BMS5 + url)
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
        }
      })
  }

  async addStaff(val, url, cb) {
    let formData = new FormData()
    formData.append('email', val.email)
    formData.append('password', val.password)
    formData.append('name', val.name)
    formData.append('fname', val.fname)
    formData.append('lname', val.lname)
    formData.append('phone', val.phone)
    formData.append('mobile', val.mobile)
    formData.append('mobile2', val.mobile2)
    formData.append('position', val.position)
    formData.append('Ass_States', val.Ass_States)
    formData.append('access_type', val.access_type)
    formData.append('role', val.role)
    if (val.profilePic) {
      formData.append('profilePic', val.profilePic)
    }
    if (val.email_letter) {
      formData.append('email_letter', val.email_letter)
    }

    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, formData, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async editStaffDelete(val, url, cb) {
    // let data = qs.stringify(val);
    let formData = new FormData()
    formData.append('st_id', val.st_id)
    formData.append('isdel', val.isdel)

    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, formData, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async editStaff(val, url, cb) {
    // let data = qs.stringify(val);
    let formData = new FormData()
    formData.append('st_id', val.st_id)
    if (val.email) {
      formData.append('email', val.email)
    }
    if (val.name) {
      formData.append('name', val.name)
    }
    formData.append('fname', val.fname)
    formData.append('lname', val.lname)
    formData.append('phone', val.phone)
    formData.append('mobile', val.mobile)
    formData.append('mobile2', val.mobile2)
    formData.append('position', val.position)
    formData.append('Ass_States', val.Ass_States)
    formData.append('access_type', val.access_type)
    formData.append('role', val.role)
    formData.append('status', val.status)
    if (val.profilePic) {
      formData.append('profilePic', val.profilePic)
    }
    if (val.email_letter) {
      formData.append('email_letter', val.email_letter)
    }
    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, formData, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async editStaffStatus(val, url, cb) {
    // let data = qs.stringify(val);
    let formData = new FormData()
    formData.append('st_id', val.st_id)
    formData.append('status', val.status)
    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, formData, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async uploadImage(val, cb) {
    let url = mainURL + '/api/extra/uploadImage'
    const formData = new FormData()
    formData.append('file', val)
    axios
      .post(url, formData)
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async uploadPDFONS3(val, cb) {
    let url = mainURL + '/api/extra/uploadPDFONS3'
    const formData = new FormData()
    formData.append('file', val)
    axios
      .post(url, formData)
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async uploadPDF(val, cb) {
    let url = mainURL + '/api/extra/uploadFile'
    const formData = new FormData()
    formData.append('file', val)
    axios
      .post(url, formData)
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async deleteFile(val, cb) {
    let url = mainURL + '/api/extra/deleteFile'
    const formData = new FormData()
    formData.append('file', val)
    axios
      .post(url, formData)
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  //manage template
  async addTemplate(val, cb) {
    let url = mainURL + '/api/EmailTemp/addEmailtemp'
    let data = qs.stringify(val)
    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async editTemplate(val, cb) {
    let url = mainURL + '/api/EmailTemp/editEmailtemp'
    let data = qs.stringify(val)
    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async sendNotesfromClient(val, cb) {
    let url = mainURL + '/api/email/sendNotesfromClient'
    let data = qs.stringify(val)
    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async getTemplateByType(val, cb) {
    let url = mainURL + '/api/EmailTemp/getEmailtemp'
    let data = qs.stringify(val)
    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async sendAgentWIPemails(val, cb) {
    let url = mainURL + '/api/WIP/sendAgentWIPemails'
    let data = qs.stringify(val)
    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async getWIPReport(val, cb) {
    let url = mainURL + '/api/WIP/getWIPReport'
    let data = qs.stringify(val)
    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async getWIPTimeLogs(wipId, limit = 15, skip = 1) {
    try {
      const url = `${BMS5}/wip-time-logs?wip_id=${wipId}&limit=${limit}&skip=${skip}`

      const response = await axios.get(url)
      if (response.status !== 200) {
        throw new Error('Fetch WIP time logs error')
      }
      return response.data.data
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async getWIPId(wipId, cb) {
    let url = BMS5 + `/wips/${wipId}`
    axios
      .get(url)
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async getWIPTimeLogsBMS5(id_wip, cb) {
    let url = BMS5 + `/wip-time-logs?wip_id=${id_wip}`
    axios
      .get(url)
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async getTemplateType(cb) {
    let url = mainURL + '/api/EmailTemp/getEmailtempTypes'
    axios
      .get(url)
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async getOrigins(cb) {
    let url = mainURL + '/api/extra/getStates'
    axios
      .get(url)
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async getDebtorListDropDown(cb) {
    let url = mainURL + '/api/extra/getDebtorList'
    axios
      .get(url)
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  // WIP Cost Table Module

  async addWPICost(val, cb) {
    let url = 'WIP/addWIPcostWithState'
    let data = qs.stringify(val)
    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async editWPICost(val, cb) {
    let url = 'WIP/editWIPcostWithState'
    let data = qs.stringify(val)
    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async getWPICost(val, cb) {
    let url = 'WIP/getWIPcostWithState'
    let data = qs.stringify(val)

    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async delWPICost(val, cb) {
    let url = 'WIP/delWIPcostWithState'
    let data = qs.stringify(val)

    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  // WIP Scale of Fees Module
  async addWPIFees(val, cb) {
    let url = 'WIP/addWIPscaleFees'
    let data = qs.stringify(val)
    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async editWPIFees(val, cb) {
    let url = 'WIP/editWIPscaleFees'
    let data = qs.stringify(val)

    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async getWPIFees(cb) {
    let url = 'WIP/getWIPscaleFees'

    axios
      .get(API_URL + url)
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async delWPIFees(val, cb) {
    let url = 'WIP/delWIPscaleFees'
    let data = qs.stringify(val)

    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  // Profile Setting
  async getProfile(cb) {
    let url = mainURL + '/api/extra/getProfileData'

    axios
      .get(url)
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async editProfile(val, cb) {
    let url = mainURL + '/api/extra/editProfileData'
    let data = qs.stringify(val)
    // let authConfig = {
    //   headers: {
    //     "content-type": "application/x-www-form-urlencoded"
    //   }
    // };
    axios
      .post(url, data)
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  // WIP cost module

  async addWPIReCost(val, cb) {
    let url = 'Recoverable/addRecoverableCost'
    let data = qs.stringify(val)

    axios
      .post(API_URL + url, data)
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async editWPIReCost(val, cb) {
    let url = 'Recoverable/editRecoverableCost'
    let data = qs.stringify(val)

    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async getWPIReCost(val, cb) {
    let url = 'Recoverable/getRecoverableCost'
    let data = qs.stringify(val)

    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async getState(val, cb) {
    let url = mainURL + '/api/extra/getWIPCostfromStates'
    let data = qs.stringify(val)

    axios
      .post(url, data)
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  // debtor
  // Contect
  async getDebtorListId(id) {
    try {
      const url = `/debtors/${id}?join=debtor_addresses`
      const authConfig = {
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
      }

      const res = await axios.get(BMS5 + url, { authConfig })
      if (res.status !== 200) {
        throw new Error('Fetch debotrs error')
      }
      return res
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async getDebtorList() {
    try {
      const url = '/debtors?join=contacts'
      const authConfig = {
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
      }

      const res = await axios.get(BMS5 + url, { authConfig })
      if (res.status !== 200) {
        throw new Error('Fetch debotrs error')
      }
      return res.data
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async getCompanies(val, cb) {
    const url = '/companies'
    let data = qs.stringify(val)

    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .get(BMS5 + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async getCompaniesId(id, cb) {
    const url = `/companies/${id}`
    let data = qs.stringify(id)

    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .get(BMS5 + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async getAccountDetails(val, cb) {
    let url = 'Contact/getAccountDetails'
    let data = qs.stringify(val)

    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async getClientTaskList(val, cb) {
    let url = 'Contact/getTaskList'
    let data = qs.stringify(val)

    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async editWIPCommunicationNotes(val, cb) {
    let url = 'WIP/editWIPCommunicationNotes'
    let data = qs.stringify(val)
    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async addWIPCommunicationNotes(val, cb) {
    let url = 'WIP/addWIPCommunicationNotes'
    let data = qs.stringify(val)
    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async getWIPCommunicationNote(val, cb) {
    let url = 'WIP/getWIPCommunicationNote'
    let data = qs.stringify(val)
    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async getAllTaskList(val, cb) {
    let url = 'Contact/getallTaskList?client_id=' + val.client_id
    axios
      .get(API_URL + url)
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async viewProperty(val, cb) {
    let url = 'Contact/viewProperty'
    let data = qs.stringify(val)
    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async getPropertyList(val, cb) {
    let url = 'Contact/getPropertyList'
    let data = qs.stringify(val)

    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async getSalesStaff(cb) {
    let url = 'Contact/getSalesStaff'
    axios
      .get(BMS5 + url)
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async getallClient(cb) {
    let url = 'Contact/getallClient'
    axios
      .get(API_URL + url)
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async getGroupTypes(cb) {
    let url = 'Contact/getGroupTypes'
    axios
      .get(BMS5 + url)
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async editDebtorList(val, cb) {
    let url = 'Debtor/editDebtorList'
    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, val, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async editContact(val, cb) {
    let url = 'Contact/editContactList'
    let data = qs.stringify(val)

    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async createClientTask(val, cb) {
    let url = 'Contact/createTask'
    let data = qs.stringify(val)

    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async createTask(val, cb) {
    let url = 'Dashbord/createTask'
    let data = qs.stringify(val)

    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async sendInvitationfromTask(val, cb) {
    let url = 'extra/sendInvitationfromTask'
    let data = qs.stringify(val)
    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async getTaskList(params = {}) {
    try {
      const url = `${BMS5}/tasks`

      const response = await axios.get(url, { params })
      if (response.status !== 200) {
        throw new Error('Failed to fetch tasks')
      }

      return response?.data?.data
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async getWIPSearch(val, cb) {
    let url = 'WIP/getWIPforEmailData'
    let data = qs.stringify(val)

    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async editClientTask(val, cb) {
    let url = 'Contact/editTask'
    let data = qs.stringify(val)

    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async editDashBoardTask(val, cb) {
    let url = 'Dashbord/editTask'
    let data = qs.stringify(val)

    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async editAccountDetails(val, cb) {
    let url = 'Contact/editAccountDetails'
    let data = qs.stringify(val)
    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async createAccountDetails(val, cb) {
    let url = 'Contact/createAccountDetails'
    let data = qs.stringify(val)
    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  //POST /api/Contact/removeAccountDetails

  async removeAccountDetails(val, cb) {
    let url = 'Contact/removeAccountDetails'
    let data = qs.stringify(val)
    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async sendAccountDetails(val, cb) {
    let url = 'Contact/sendAccountDetails'
    let data = qs.stringify(val)
    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async AddContact(val, cb) {
    let url = 'Contact/createContactList'
    let data = qs.stringify(val)

    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async addProperty(val, cb) {
    let url = 'Contact/addSingleProperty'
    let data = qs.stringify(val)
    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async updateSingleProperty(val, cb) {
    let url = 'Contact/updateSingleProperty'
    let data = qs.stringify(val)
    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  //cover notes

  // async getCoverNotes(val, cb) {
  //   let url = 'Contact/getCoverNote';
  //   let data = qs.stringify(val);

  //   let authConfig = {
  //     headers: {
  //       'content-type': 'application/x-www-form-urlencoded',
  //     },
  //   };

  //   axios
  //     .post(API_URL + url, data, { authConfig })
  //     .then((res) => {
  //       cb(res);
  //     })
  //     .catch((error) => {
  //       if (
  //         error.response &&
  //         error.response.status === 401 &&
  //         error.response.data.responseType !== 'UNAUTHORIZED'
  //       ) {
  //         this.unAuthorizeUser(error.response.data.message);
  //       } else {
  //         if (error.response) {
  //           cb(error.response.data);
  //         }
  //         if (!error.response) {
  //           cb(error);
  //         }
  //       }
  //     });
  // }

  async getCoverNotes(val, cb) {
    let url = 'cover-notes'

    axios
      .get(`${API_URL}/${url}`)
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async AddCoverNote(val, cb) {
    let url = 'Contact/addCoverNote'
    let data = qs.stringify(val)

    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async EditCoverNote(val, cb) {
    let url = 'Contact/editCoverNote'
    let data = qs.stringify(val)
    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async getNoteAgent(val, cb) {
    let url = 'Contact/getContactAgent'
    let data = qs.stringify(val)

    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  // async getPropertyInvoiceList(val, cb) {
  //   let url = 'Contact/getPropertyInvoiceList';
  //   let data = qs.stringify(val);

  //   let authConfig = {
  //     headers: {
  //       'content-type': 'application/x-www-form-urlencoded',
  //     },
  //   };
  //   axios
  //     .post(API_URL + url, data, { authConfig })
  //     .then((res) => {
  //       cb(res);
  //     })
  //     .catch((error) => {
  //       if (
  //         error.response &&
  //         error.response.status === 401 &&
  //         error.response.data.responseType !== 'UNAUTHORIZED'
  //       ) {
  //         this.unAuthorizeUser(error.response.data.message);
  //       } else {
  //         if (error.response) {
  //           cb(error.response.data);
  //         }
  //         if (!error.response) {
  //           cb(error);
  //         }
  //       }
  //     });
  // }

  async getPropertyInvoiceList(val, cb) {
    let url = 'property-invoices'

    axios
      .get(`${API_URL}/${url}`)
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async getPropertyInvoiceData(val, cb) {
    let url = 'Contact/getPropertyInvoiceData'
    let data = qs.stringify(val)

    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async generatePropertyInvoice(val, cb) {
    let url = 'Contact/generatePropertyInvoice'
    let data = qs.stringify(val)

    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async updatePropertyInvoice(val, cb) {
    let url = 'Contact/updatePropertyInvoice'
    let data = qs.stringify(val)

    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  // Notes
  async getnotes(val, cb) {
    let url = 'Notes/getnotes'
    let data = qs.stringify(val)

    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async getTasknotes(val, cb) {
    let url = 'Notes/getTasknotes'
    let data = qs.stringify(val)

    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async getcontactsfromClient(val, cb) {
    let url = 'extra/getcontactsfromClient'
    let data = qs.stringify(val)

    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async getemails(val, cb) {
    let url = 'email/getemails'
    let data = qs.stringify(val)

    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  // async   getWIPforClientList(clientId, params = {}) {
  //   try {
  //     const url = `${BMS5}/wips/company/${clientId}`;
  //     const response = await axios.get(url, { params });
  //     console.log(response);
  //     if (response.status !== 200) {
  //       throw new Error('Failed to fetch WIP');
  //     }
  //     return response.data.data;
  //   } catch (error) {
  //     if (
  //       error.response &&
  //       error.response.status === 401 &&
  //       error.response.data.responseType !== 'UNAUTHORIZED'
  //     ) {
  //       this.unAuthorizeUser(error.response.data.message);
  //     } else {
  //       if (error.response) {
  //         throw new Error(error.response.data);
  //       }
  //       if (!error.response) {
  //         throw new Error(error);
  //       }
  //     }
  //   }
  // }

  async getPropertyListSearch(val, cb) {
    let url = 'extra/getPropertyList'
    let data = qs.stringify(val)

    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async getLetters(val, cb) {
    let url = 'Letter/getLetters'
    let data = qs.stringify(val)

    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async editnotes(val, cb) {
    let url = 'Notes/editnotes'
    let data = qs.stringify(val)

    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async editTasknotes(val, cb) {
    let url = 'Notes/editTasknotes'
    let data = qs.stringify(val)
    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async addnotes(val, cb) {
    let url = 'Notes/addnotes'
    let data = qs.stringify(val)
    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async addClientnotes(val, cb) {
    let url = 'Contact/addTasknotes'
    let data = qs.stringify(val)
    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async addTasknotes(val, cb) {
    let url = 'Dashbord/addTasknotes'
    let data = qs.stringify(val)
    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async EditWIP(val, cb) {
    let url = 'WIP/editWIPforClient'
    let data = qs.stringify(val)
    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async makeDebtorCurrentAddress(val, cb) {
    let url = 'Debtor/makeDebtorCurrentAddress'
    let data = qs.stringify(val)
    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async getNotesApplicationtype(cb) {
    let url = mainURL + '/api/extra/getNotesApplicationtype'

    axios
      .get(url)
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async uploadCSVforProperty(val, cb) {
    let url = 'Contact/uploadCSVforProperty'

    let formData = new FormData()
    formData.append('client_id', val.client_id)
    formData.append('client_ID', val.client_ID)
    formData.append('csvdata', val.csvdata)

    let data = qs.stringify(val)
    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, val, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async getDatausingGlobalSearch(val, url, cb) {
    let formData = new FormData()
    formData.append('main_cat', 'Contact')
    formData.append('sub_cat_1', val.sub_cat_1)
    formData.append('val_cat_1', val.val_cat_1)
    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, formData, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async PaymentTracking(val, cb) {
    let url = 'WIP/getWIPPaymentTracking'
    let data = val

    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async AddPaymentTracking(val, cb) {
    let url = 'WIP/addWIPPaymentTracking'
    let data = val

    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async AddCSV(val, cb) {
    let url = 'Recovery/addCSVfiledata'
    let data = val

    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async getBankRecords(val, cb) {
    let url = 'Recovery/getBankRecordsData'
    let data = val

    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async getWIPBankRecords(val, cb) {
    let url = 'Recovery/getWIPforBankRecord'
    let data = val

    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async applyPaymentforWIP(val, cb) {
    let url = 'Recovery/applyPaymentforWIP'
    let data = val

    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  async ConfirmPaymentforWIPlist(val, cb) {
    let url = 'Recovery/complatePaymentforRecovery'
    let data = val

    let authConfig = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
    }
    axios
      .post(API_URL + url, data, { authConfig })
      .then((res) => {
        cb(res)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.responseType !== 'UNAUTHORIZED'
        ) {
          this.unAuthorizeUser(error.response.data.message)
        } else {
          if (error.response) {
            cb(error.response.data)
          }
          if (!error.response) {
            cb(error)
          }
        }
      })
  }

  toastBasicOption() {
    return {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    }
  }

  successToast(msg) {
    toast.success(msg, this.toastBasicOption())
    // toast.success(msg, {
    //   position: toast.POSITION.TOP_RIGHT
    // });
  }

  errorToast(msg) {
    toast.error(msg, this.toastBasicOption())
  }

  unAuthorizeUser(msg) {
    window.x = 'e40'
    //this.errorToast("Please Login Again, Your Session Is Expired");
  }
}

export default Services
