import { CreateLandlordAddressData, LandlordAddress } from "@containers/Sales/Properties/types/property.type";
import { REACT_APP_API_URL } from "@src/lib/global-variables";
import axios from "axios";
import { DeepPartial } from "react-hook-form";


export class LandlordAddressService {

  async updateLandlordAddress(landlordId: number, addressId: number, data: DeepPartial<LandlordAddress>): Promise<LandlordAddress> {
    const res = await axios.patch<LandlordAddress>(`${REACT_APP_API_URL}/landlords/${landlordId}/address/${addressId}`, data)
    return res.data;
  }

  async createLandlordAddress(landlordId: number, data: CreateLandlordAddressData): Promise<LandlordAddress> {
    const res = await axios.post<LandlordAddress>(`${REACT_APP_API_URL}/landlords/${landlordId}/address`, data)
    return res.data;
  }

}