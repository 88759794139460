import React from 'react';
import { Button, ButtonToolbar } from 'reactstrap';

export const ClaimsDetails = (props) => {
  const { claims, setClaims, setClaimAmountDetails, claimAmountDetails } =
    props;

  const getOnInputChange = (index) => (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setClaims((prev) => {
      if (index === 0) {
        return [
          {
            ...prev[index],
            [name]: +value,
          },
          ...prev.slice(index + 1),
        ];
      }

      return [
        ...prev.slice(0, index),
        {
          ...prev[index],
          [name]: +value,
          original_amount: +value,
        },
        ...prev.slice(index + 1),
      ];
    });
  };

  const getOnDescriptionChange = (index) => (e) => {
    const name = 'description';
    const value = e.target.value;

    setClaims((prev) => {
      if (index === 0) {
        return [
          {
            ...prev[index],
            [name]: value,
          },
          ...prev.slice(index + 1),
        ];
      }

      return [
        ...prev.slice(0, index),
        {
          ...prev[index],
          [name]: value,
        },
        ...prev.slice(index + 1),
      ];
    });
  };

  const onClaimAdd = () => {
    setClaims((prev) => (prev ? [...prev, {}] : [{}]));
  };

  const getOnDescriptionBlur = (index) => () => {
    if(claimAmountDetails?.claims){
      setClaimAmountDetails((prev) => {
        if (!prev.claims[index + 1]) {
          return prev;
        }
        return {
          ...prev,
          claims: [
            ...prev.claims.slice(0, index + 1),
            {
              ...prev.claims[index + 1],
              description: claims[index].description,
            },
            ...prev.claims.slice(index + 2),
          ],
        };
      });
    }
  };

  const getOnAddClaimAmountDetails = (index) => () => {
    // if changing order amount
    if (index === 0) {
      // if 'Adjust' claim is not in the list
      // 'Adjust' claim will always have index === 2
      if (claimAmountDetails?.claims[2]?.description !== 'Adjust') {
        setClaimAmountDetails((prev) => ({
          ...prev,
          claims: [
            ...prev.claims,
            {
              current_amount:+claims[0].current_amount - +claims[0].original_amount,
              description: 'Adjust',
            },
          ],
        }));
        return;
      }
      setClaimAmountDetails((prev) => ({
        ...prev,
        claims: [
          ...prev.claims.slice(0, 2),
          {
            ...prev.claims[2],
            current_amount:
              +claims[0].current_amount - +claims[0].original_amount,
          },
          ...prev.claims.slice(3),
        ],
      }));
      return;
    }

    if(claimAmountDetails?.claims){
      setClaimAmountDetails((prev) => ({
        ...prev,
        claims: [
          ...prev.claims.slice(0, index + 1),
          {
            description: claims[index].description,
            current_amount: +claims[index].current_amount,
          },
          ...prev.claims.slice(index + 2),
        ],
      }));
    }
  };

  const onClaimRemove = (index) => {
    const array = claims.filter((cost,i)=> i !== index)
    setClaims(array)
  };

  return (
    <>
      <div className="card__title">
        <h5 className="bold-text">Claims</h5>
      </div>
      {claims &&
        claims.map((item, index) => (
          <React.Fragment key={index}>
            <div className="form__form-group d-flex align-item-center">
              <span className="form__form-group-label">Claim {index + 1}</span>
              <div className="col-12 col-md-6 col-lg-3">
                <span className="form__form-group-label">Description</span>
                <div className="form-group">
                  <input
                    name="description"
                    type="text"
                    onChange={getOnDescriptionChange(index)}
                    onBlur={getOnDescriptionBlur(index)}
                    value={item.description}
                    disabled={index === 0}
                    // defaultValue={item.description}
                  />
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="form-group d-flex flex-column align-items-center justify-content-between">
                  <span className="form__form-group-label">
                    Original Amount
                  </span>
                  <span className="form__form-group-label">
                    {item.original_amount}
                  </span>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="form-group">
                  <span className="form__form-group-label">Current Amount</span>
                  <input
                    name="current_amount"
                    type="number"
                    value={+item.current_amount}
                    onBlur={getOnAddClaimAmountDetails(index)}
                    onChange={getOnInputChange(index)}
                    // defaultValue={index > 0 ? item.current_amount : total}
                  />
                </div>
              </div>
              <div style={{marginTop:'17px',marginLeft:'35px'}}>
                <Button style={{lineHeight: '13px', fontSize: '16.5px'}}
                        color='primary'
                        className='mb-0'
                        type='button'
                        onClick={()=>onClaimRemove(index)}
                >
                  Remove
                </Button>
              </div>
            </div>
          </React.Fragment>
        ))}
      <div className="form__form-group">
        <ButtonToolbar className="form__button-toolbar mb-3">
          <Button
            disabled={claims && claims.length === 3}
            color="primary"
            className="mb-0"
            type="button"
            onClick={onClaimAdd}
          >
            Add Claims
          </Button>
        </ButtonToolbar>
      </div>
    </>
  );
};
