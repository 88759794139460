import CourtInterestRateCalculatorForm from './court-interest-rate-calculator-form.component'
import {
  CourtInterestRateCalculatorFormValues,
  UpdateCourtInterestRateCalculatorFormProps,
} from '../../../../types/interest-rate.type'
import { FC } from 'react'
import { CALCULATOR_TYPE_OPTIONS } from '../../../../const/interest-rate.const'
import { useUpdateCourtInterestCalculatorMutation } from '../../../../hooks/crud-court-interest-calculator.hook'
import { toast } from 'react-toastify'

const EditCourtInterestRateCalculatorForm: FC<UpdateCourtInterestRateCalculatorFormProps> = ({
  state,
  entity,
  courtInterestRatesOptions,
  onCalculateTypeChange,
  onSuccessSubmit,
  onCancelClick,
}) => {
  const { mutateAsync, isLoading } = useUpdateCourtInterestCalculatorMutation()
  const handleSubmit = async (data: CourtInterestRateCalculatorFormValues) => {
    try {
      const res = await mutateAsync({
        id: entity.id,
        data: {
          rate: Number(data.interestRate) / 100,
        },
      })
      toast.success('Court interest calculator updated successfully')
      onSuccessSubmit(res)
    } catch (e) {
      toast.error('Failed to update court interest calculator')
      console.log(e)
    }
  }
  return (
    <CourtInterestRateCalculatorForm
      loading={isLoading}
      initialValues={{
        calculateType: CALCULATOR_TYPE_OPTIONS[0],
        state: courtInterestRatesOptions.find((option) => option.label === state) || null,
        interestRate: String(Number(entity.rate) * 100),
      }}
      courtInterestRatesOptions={courtInterestRatesOptions}
      onSubmit={handleSubmit}
      onCalculateTypeChange={onCalculateTypeChange}
      onCancelClick={onCancelClick}
    />
  )
}

export default EditCourtInterestRateCalculatorForm
