import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { reducer as reduxFormReducer } from 'redux-form';

import {
  cryptoTableReducer,
  newOrderTableReducer,
  sidebarReducer,
  themeReducer,
  customizerReducer,
  todoReducer,
  rtlReducer,
  authReducer,
  protectCollectReducer,
  getCommercialUncoveredListReducer,
  postCommercialUncoveredFormReducer,
  deleteCommercialUncoveredByIdReducer,
  createWIPCommercialUncoveredFormReducer,
  getCommercialUncoveredFormByIdReducer,
  editCommercialUncoveredFormReducer,
  getPropertyByIdReducer,
  getPaymentDetailsReducer,
  getInvoiceTemplatesListReducer,
  postInvoiceReducer,
  getInvoicePDFReducer,
  postInvoiceEmailReducer,
  getInvoicesListReducer,
  getInvoiceByIDReducer,
  editInvoiceByIDReducer,
  deleteInvoiceByIDReducer,
  createInvoiceTemplateReducer,
  editInvoiceTemplateReducer,
  deleteInvoiceTemplateReducer,
  getCompaniesByIdReducer,
  getEmailTemplateByIdReducer,
} from '../reducers/index';

const reducer = combineReducers({
  form: reduxFormReducer, // mounted under "form",
  theme: themeReducer,
  rtl: rtlReducer,
  sidebar: sidebarReducer,
  cryptoTable: cryptoTableReducer,
  newOrder: newOrderTableReducer,
  customizer: customizerReducer,
  todos: todoReducer,
  user: authReducer,
  protectCollectForm: protectCollectReducer,
  commercialUncoveredList: getCommercialUncoveredListReducer,
  postCommercialUncoveredForm: postCommercialUncoveredFormReducer,
  deleteCommercialUncoveredById: deleteCommercialUncoveredByIdReducer,
  createWIPCommercialUncovered: createWIPCommercialUncoveredFormReducer,
  getCommercialUncoveredFormById: getCommercialUncoveredFormByIdReducer,
  editCommercialUncoveredForm: editCommercialUncoveredFormReducer,
  getPropertyById: getPropertyByIdReducer,
  getPaymentDetails: getPaymentDetailsReducer,
  getInvoiceTemplatesList: getInvoiceTemplatesListReducer,
  postInvoice: postInvoiceReducer,
  getInvoicePDF: getInvoicePDFReducer,
  postInvoiceEmailReducer,
  getInvoicesListS: getInvoicesListReducer,
  getInvoiceByID: getInvoiceByIDReducer,
  editInvoiceByID: editInvoiceByIDReducer,
  deleteInvoiceByID: deleteInvoiceByIDReducer,
  createInvoiceTemplateReducer,
  editInvoiceTemplateReducer,
  deleteInvoiceTemplateReducer,
  getCompaniesByIdReducer,
  getEmailTemplateById: getEmailTemplateByIdReducer,
});

const composedEnhancers = compose(applyMiddleware(thunk));

const store = createStore(reducer, composedEnhancers);

export default store;
