export const DEBTOR_PHONE_FIELD_TO_LABEL = {
  comms_ph_direct: 'Direct',
  comms_ph_extension: 'Extension',
  comms_ph_home: 'Home',
  comms_ph_mobile: 'Mobile',
  comms_ph_mobile_secondary: 'Secondary',
  comms_ph_office: 'Office',
  comms_ph_work: 'Work',
}

export const CLIENT_CONTACT_PHONE_FIELD_TO_LABEL = {
  comm_ph_direct: 'Direct',
  comm_ph_extension: 'Extension',
  comm_ph_mobile: 'Mobile',
  comm_ph_office: 'Office',
}