import { FC } from 'react'
import { Oval } from 'react-loader-spinner'

interface LoaderProps {
  size?: number
  className?: string
}

const Loader: FC<LoaderProps> = ({ size = 40, className }) => {
  return (
    <Oval
      visible={true}
      height={size}
      width={size}
      wrapperClass={className}
      color="blue"
      secondaryColor="blue"
      ariaLabel="oval-loading"
    />
  )
}

export default Loader
