import { useEffect, useState } from "react"
import { parseSubjectForAttributesByGroup } from "../components/utils/attribute.utils"
import { SelectSubOptionWithRef } from "@components/pagination-select/pagination-select.type"

export const useGetAssignedAttributes = (subject: string) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [assignedAttributes, setAssignedAttributes] = useState<SelectSubOptionWithRef[] | null>(null)

  const transformSubjectToSubOptions = async (subject): Promise<SelectSubOptionWithRef[]> => {
    const parsedSubject = await parseSubjectForAttributesByGroup(subject)

    const clientSubOptions = parsedSubject.attributes.client.map((client) => ({
      label: client.name,
      value: client.id,
      ref: 'client',
    }))
    const wipSubOptions = parsedSubject.attributes.wip.map((wip) => ({
      label: `WIP ${wip}`,
      value: Number(wip),
      ref: 'wip',
    }))

    return [...clientSubOptions, ...wipSubOptions]
  }

  const getAssignedAttributes = async (subject: string) => {
    setIsLoading(true)
    const subOptions = await transformSubjectToSubOptions(subject)
    setAssignedAttributes(subOptions)
    setIsLoading(false)
  }

  useEffect(() => {
    if (subject) {
      getAssignedAttributes(subject)
    }
  }, [subject])


  return {
    isLoading,
    assignedAttributes,
  }
}