import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import { Col } from 'reactstrap';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import moment from 'moment';
import TablePagination from '@material-ui/core/TablePagination';

const styles = { color: '#ff0000',maxWidth: '300px' }

const NotesDebtor = ({ debtorNotes,total,page,rowsPerPage,handleChangePage,handleChangeRowsPerPage,addNote}) => {

  const [notes, setNotes] = useState([]);

  useEffect(() => {
    setNotes(debtorNotes);
  }, [debtorNotes]);

  return (
    <Box margin={1}>
      <div className='row align-items-center justify-content-md-between py-3'>
        <Col md={4}>
          <h3>NOTES HISTORY: </h3>
        </Col>
        <Col md={8}>
          <div className='d-flex justify-content-md-end'>
            <button
              style={{ lineHeight: '13px' }}
              className='btn btn-primary'
              onClick={addNote}
            >
              Add Note
            </button>
          </div>
        </Col>
      </div>
      <Table aria-label='purchases'>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Time</TableCell>
            <TableCell>Application</TableCell>
            <TableCell>Note</TableCell>
            <TableCell>Staff</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {notes.map((note) => {
            return (
              <TableRow key={note.id} className='form'>
                <TableCell
                  component='th'
                  scope='row'
                  style={note.is_important ? styles : null}
                >
                  {note.created_at ? moment(note.created_at).format('MMMM Do YYYY') : '-'}
                </TableCell>
                <TableCell style={note.is_important ? styles : null}>
                  {note.created_at ? moment(note.created_at).format('hh:mm a') : '-'}
                </TableCell>
                <TableCell style={note.is_important ? styles : null}>
                    {note.actions}
                </TableCell>
                <TableCell  style={note.is_important ? styles : { maxWidth: '500px' }}>
                  {note.content}
                </TableCell>
                <TableCell style={note.is_important ? styles : null}>
                  {note.staff_created}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <hr />
      <TablePagination
        style={{ marginTop: '25px' }}
        component='div'
        count={total}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 20]}
      />
    </Box>

  );
};

export default NotesDebtor;
