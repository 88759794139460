import AssignAttributes from '@components/assign-attributes'
import { IAssignAttributesProps } from '@src/types/component-types'
import { FC, useEffect, useState } from 'react'
import { NoteFilterProps } from '../../../types/notes.type'
import styles from './note-filter.module.scss'
import FilterIcon from '@src/icons/filter'
import classNames from 'classnames'

const NoteFilter: FC<NoteFilterProps> = ({ onChange }) => {
  const [noteFiterOptions, setNoteFilterOptions] = useState<
    IAssignAttributesProps['options'] | null
  >(null)

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false)

  const getFilterOptions = async () => {

    // TODO: implement filter
    setNoteFilterOptions([
    ])
  }

  const handleFilterChange = (selectedValues: string[]) => {
    const transformData: Record<string, string[]> = {}
    selectedValues.forEach((selectedValue) => {
      const [field, value] = selectedValue.split('||')
      if (!transformData[field]) {
        transformData[field] = [value]
      } else {
        transformData[field] = [...transformData[field], value]
      }
    })
    onChange(transformData)
  }

  const handleFilterClick = () => {
    setIsFilterOpen((prev) => !prev)
  }

  useEffect(() => {
    getFilterOptions()
  }, [])

  return (
    <div className={styles.wrapper}>
      <div className={styles.filterContent}>
        <button className={styles.filterButton} onClick={handleFilterClick}>
          <FilterIcon /> Filter
        </button>

        {isFilterOpen ? (
          <AssignAttributes
            options={noteFiterOptions || []}
            isOpen={true}
            onChange={handleFilterChange as () => void}
            handleDropdownChangeState={() => {}}
            handleCloseDropdown={() => {}}
            values={[]}
            className={classNames(styles.filter, { [styles.isActive]: isFilterOpen })}
          />
        ) : null}
      </div>
    </div>
  )
}

export default NoteFilter
