import Section from '@components/section/section.component'
import { Header, SubHeader, Text } from '@containers/Recovery/Wip/components/UI/text/text.component'
import { FC } from 'react'
import { AssignedToSectionProps } from '../../../types/sections-props.type'
import SubSection from '@containers/Recovery/Wip/components/UI/sub-section/sub-section.component'

const Ava = `${process.env.PUBLIC_URL}/image/user.jpg`;

const AssignedToSection: FC<AssignedToSectionProps> = ({ staff }) => {
  return (
    <Section>
      <Header mb={4}>Tasks Assigned To</Header>
      {staff ? (
        <SubSection p={2} flex>
          <div>
            <img className="topbar__avatar-img" src={Ava} alt="avatar" />
          </div>
          <div>
            <SubHeader>{staff.nickname}</SubHeader>
            <Text>{staff.comms_email || 'Email not found'}</Text>
          </div>
        </SubSection>
      ) : (
        <SubSection p={2}>
          <SubHeader>No Staff Assigned</SubHeader>
        </SubSection>
      )}
    </Section>
  )
}

export default AssignedToSection
