import React from 'react';

export const OwnerDetails = ({ setOwnerDetails, ownerDetails }) => {
  const onInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setOwnerDetails((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <>
      <div className="card__title">
        <h5 className="bold-text">Owner Details</h5>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">
          Name<span style={{ color: '#ff0000' }}>*</span>
        </span>
        <div className="form__form-group-field  d-flex flex-column">
          <input
            name="name"
            type="text"
            placeholder="Name"
            required={true}
            onChange={onInputChange}
            value={ownerDetails.name}
            // defaultValue={defaultData?.owner_details?.name}
          />
          {/* {errors.name && (
            <span className="text-danger">Owner name is required</span>
          )} */}
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">
          Address<span style={{ color: '#ff0000' }}>*</span>
        </span>
        <div className="form__form-group-field d-flex flex-column">
          <input
            name="address"
            type="text"
            placeholder="Address"
            required={true}
            onChange={onInputChange}
            value={ownerDetails.address}
            // defaultValue={defaultData?.owner_details?.address}
          />
          {/* {errors.address && (
            <span className="text-danger">Owner address is required</span>
          )} */}
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">
          Phone<span style={{ color: '#ff0000' }}>*</span>
        </span>
        <div className="form__form-group-field d-flex flex-column">
          <input
            name="phone"
            type="text"
            placeholder="Phone"
            required={true}
            onChange={onInputChange}
            value={ownerDetails.phone}
            // defaultValue={defaultData?.owner_details?.phone}
          />
          {/* {errors.phone && (
            <span className="text-danger">Owner phone is required</span>
          )} */}
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">
          Email<span style={{ color: '#ff0000' }}>*</span>
        </span>
        <div className="form__form-group-field d-flex flex-column">
          <input
            name="email"
            type="text"
            placeholder="Email"
            required={true}
            onChange={onInputChange}
            value={ownerDetails.email}
            // defaultValue={defaultData?.owner_details?.email}
          />
          {/* {errors.email && (
            <span className="text-danger">Owner email is required</span>
          )} */}
        </div>
      </div>
    </>
  );
};
