import Modal from '@components/modal'
import styles from './set-sms-modal.module.scss'

import { useForm } from 'react-hook-form'
import LabelWrapper from '@components/label-wrapper'
import Select from 'react-select'
import { useEffect, useState } from 'react'
import { getSmsTypes } from '../../../../../Form/RecoveryForm/service.js'
import { toast } from 'react-toastify'
import { IOption } from '../../taskboard/types'
import { joiResolver } from '@hookform/resolvers/joi'
import { SetSmsSchema } from './validation'

interface IProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: ({ sms_template }: { sms_template: string }) => void
  initialValues?: {
    sms_template: string
  }
}

interface IForm {
  sms_template: IOption | undefined
  message: string
}

const SetSmsModal = ({
  isOpen,
  onClose = () => null,
  onSubmit = () => null,
  initialValues,
}: IProps) => {
  const [smsTemplates, setSmsTemplates] = useState<any[]>([])

  const { control, reset, setValue, watch, handleSubmit } = useForm<IForm>({
    defaultValues: {
      message: '',
      sms_template: undefined,
    },
    mode: 'all',
    resolver: joiResolver(SetSmsSchema),
  })

  const fetchSmsTemplate = async () => {
    const response = await getSmsTypes()
    const res = await response.json()

    const formatedTemplates =
      res?.data?.map((template) => ({
        label: template?.sms_template_title,
        value: template?.sms_template_body,
      })) || []
    setSmsTemplates(formatedTemplates)
  }

  const onSmsSubmit = async (data: IForm) => {
    try {
      const { message } = data
      onSubmit({ sms_template: message || '' })
      //
    } catch (error) {
      toast.error('Something went wrong')
    } finally {
      onClose()
    }
  }

  useEffect(() => {
    if (!isOpen) {
      reset({}, { keepDirty: false, keepDefaultValues: true, keepValues: false })
    }
  }, [isOpen])

  useEffect(() => {
    fetchSmsTemplate()
  }, [])

  useEffect(() => {
    if (initialValues && isOpen && smsTemplates) {
      reset({
        message: initialValues.sms_template,
        sms_template: smsTemplates.find((option) => option.value === initialValues.sms_template) || null,
      })
    }
  }, [initialValues, smsTemplates])

  useEffect(() => {
    const subscription = watch(({ sms_template }, { name }) => {
      if (name === 'sms_template' && sms_template) {
        if (sms_template?.value as any) {
          setValue('message', sms_template?.value as any, { shouldValidate: true })
          return
        }
      }
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [watch])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isLayoutOverlap
      style={{ zIndex: 1001 }}
      saveBtnText="Save"
      canecelBtnText="Back"
      onSubmit={handleSubmit(onSmsSubmit)}
    >
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <span className={styles.headerText}>Send SMS</span>

          <LabelWrapper<IForm> control={control} name="sms_template" label="Template">
            {({ onChange, value }) => {
              return <Select options={smsTemplates} value={value} onChange={onChange} />
            }}
          </LabelWrapper>
          <LabelWrapper<IForm> control={control} name="message" label="Message">
            {({ onChange, value }) => {
              return (
                <div>
                  <textarea value={value as string} onChange={onChange} />
                </div>
              )
            }}
          </LabelWrapper>
        </div>
      </div>
    </Modal>
  )
}

export default SetSmsModal
