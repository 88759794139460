import React, { useEffect, useState } from 'react';
import Notes from './TabNotes/Notes';
import Communication from './TabCommunication/Communication';
import { Button, Modal } from 'reactstrap';
import DetailDebtor from './TabDetaileDebtor/DetailDebtor';
import DebtorService from '../../../../services/DebtorService';
import Services from '../../../../shared/Services';
import { useHistory } from 'react-router-dom';
import AddNotesDebtor from './TabDetaileDebtor/Notes/AddNotesDebtor';
import NotesService from '../../../../services/NotesServices';
import SendEmailToDebtorModal from "@containers/Form/RecoveryForm/notifications/send-email-to-debtor-modal";
import SendSmsToDebtorModal from "@containers/Form/RecoveryForm/notifications/send-sms-to-debtor-modal";
import { useModal } from '@src/hooks/useModal'


const styles = {
  associated_bloc: {
    fontSize: '16px',
    color: '#000',
    marginTop: '20px',
  },
  go_back: {
    background: '#fff',
    borderRadius: '5px',
    fontWeight: '700',
  },
  send_sms: {
    background: '#bdbbbb',
    border: '#bdbbbb',
    width: '7rem',
    height: '2.6rem',
    color: '#000000',
    fontSize: '15px',
    lineHeight: 'normal',
    borderRadius: '5px',
    fontWeight: 'bold',
  },
  link_wip: {
    color: 'coral',
    cursor: 'pointer',
    textDecoration: 'underline',
    fontWeight: '600',
  },
  button_tab:{
    width: '7rem',
    height: '2.6rem'
  }
};


const Index = (props) => {
  const [activeTab, setActiveTab] = useState(1);
  const [isEmailModalOpen, openEmailModal, closeEmailModal] = useModal()
  const [isSmsModalOpen, openSmsModal, closeSmsModal] = useModal()

  const [openModalNote, setOpenModalNote] = useState(false);
  const [debtor, setDebtor] = useState(null);
  const [debtorNotes, setDebtorNotes] = useState(null);
  const [associatedWIP, setAssociatedWIP] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [total, setTotal] = useState(0);
  const [fetch,setFetch] = useState('')

  const id = localStorage.getItem('debtorId');
  const deb = new DebtorService();
  const note = new NotesService();
  const services = new Services();
  const history = useHistory();

  useEffect(() => {
    getDebtors(id);
    getDebtorNotes(id, page, rowsPerPage);
  }, []);

  const getDebtors = async (id) => {
    try {
      const params = {
        id: id,
        join: ['addresses', 'contact', 'wips'],
      };
      const debtors = await deb.getDebtorId(params);
      setDebtor(debtors);
      setAssociatedWIP(debtors.data.wips);
    } catch (error) {
      services.errorToast(error.message);
    }
  };

  const getDebtorNotes = async (id, page, rowsPerPage) => {
    try {
      const params = {
        join: 'debtor',
        filter: [`debtor.id||$eq||${id}`, 'is_contact||$eq||true'],
        page: page,
        limit: rowsPerPage || 5,
        sort: 'id,DESC',
      };
      const debtorsNote = await note.getDebtorNotes(params);
      debtorsNote.data.data.forEach((val) => {
        if (val.is_important === true) {
          debtorsNote.data.data.sort(function(x, y) {
            return x === val ? -1 : y === val ? 1 : 0;
          });
        }
      });
      setTotal(debtorsNote.data.total);
      setDebtorNotes(debtorsNote.data.data);
    } catch (error) {
      services.errorToast(error.message);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getDebtorNotes(id, newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getDebtorNotes(id, 0, event.target.value);
  };

  const changeTab = (e, tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const sendEmail = (e) => {
    openEmailModal()
  };

  const addNote = () => {
    setOpenModalNote(true);
  };

  const closeNoteModal = () => {
    setOpenModalNote(false);
  };

  return (
    <>
      {debtor && <div className={'container page-body-wrapper position-relative pb-5'}>
        <div>
          <div className='main-panel '>
            <div className='content-wrapper'>
              <div className='client_info-block mt-3'>
                <div className='row mb-3'>
                  <div className='col-6'>
                    <div className='mb-1 contact_username-wip'>
                      {`${debtor?.data.name_first} ${debtor?.data.name_last}`}
                    </div>
                    <div style={styles.associated_bloc}>
                        <span className={'mr-3'}>
                          <strong>Associated WIP:  </strong>
                          {associatedWIP.length && associatedWIP?.map((el, i) =>
                            <span
                              onClick={() => {
                                history.push('/wip-details', { wipID: el.id });
                              }}
                              style={styles.link_wip} className={'pl-2'} key={i}>{el.id}</span>,
                          )}
                        </span>
                      <span className={'mr-3'}>
                          <strong>Debtor ID:  </strong>
                          <span className={'pl-2'}>{debtor?.data?.id}</span>
                        </span>
                      <span>
                          <strong>Amount of Debt:  </strong>
                          <span className={'pl-2'}>{debtor?.data?.wips.map((claim, i) =>
                            <span key={i}
                                  className={'font-weight-bold text-danger'}>
                              {claim.claim_details.total}
                            </span>)}</span>
                        </span>
                    </div>
                  </div>
                  <div className='col-6 d-flex justify-content-end'>
                    <button
                      onClick={() => props.history.push(`/debtor`)}
                      className={'btn'}
                      style={styles.go_back}
                    >
                      Go Back
                    </button>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12'>
                    <div className='tabs_nav bg-white d-flex flex-wrap justify-content-between'>
                      <ul className='nav nav-tabs' role='tablist'>
                        <li className='nav-item'>
                              <span className={activeTab === 1 ? 'nav-link active' : 'nav-link'} id='Relationships-link'
                                    data-toggle='tab'
                                    role='tab' aria-controls='purchases' aria-selected='false'
                                    onClick={(e) => changeTab(e, 1)}>Details</span>
                        </li>
                        <li className='nav-item'>
                              <span className={activeTab === 2 ? 'nav-link active' : 'nav-link'} id='notes-link'
                                    data-toggle='tab'
                                    role='tab' aria-controls='purchases' aria-selected='false'
                                    onClick={(e) => changeTab(e, 2)}>Notes</span>
                        </li>
                        <li className='nav-item'>
                              <span className={activeTab === 3 ? 'nav-link active' : 'nav-link'} id='communication-link'
                                    data-toggle='tab'
                                    role='tab' aria-controls='purchases' aria-selected='false'
                                    onClick={(e) => changeTab(e, 3)}>Communication</span>
                        </li>
                      </ul>
                      <ul className='d-flex align-items-center justify-content-md-end m-3'>
                        <Button
                          className='btn-primary'
                          style={styles.button_tab}
                          onClick={addNote}
                        >
                          Add Note
                        </Button>
                        <Button
                          className='btn-primary'
                          style={styles.button_tab}
                          onClick={() => sendEmail('LETTERS')}
                        >
                          Send Letters
                        </Button>
                        <Button
                          className='btn-primary'
                          style={styles.button_tab}
                          onClick={openEmailModal}
                        >
                          Send Email
                        </Button>
                        <button
                          style={styles.send_sms}
                          onClick={openSmsModal}
                        >
                          Send SMS
                        </button>
                      </ul>
                    </div>

                    <div className='tab-content'>
                      <div className={activeTab === 1 ? 'tab-pane active' : 'tab-pane'} id='Relationships-tab'
                           role='tabpanel' aria-labelledby='Relationships-tab'>
                        {activeTab === 1 && <DetailDebtor dataDebtor={debtor}
                                                          getDebtor={getDebtors}
                                                          debtorNotes={debtorNotes}
                                                          total={total}
                                                          page={page}
                                                          rowsPerPage={rowsPerPage}
                                                          handleChangePage={handleChangePage}
                                                          handleChangeRowsPerPage={handleChangeRowsPerPage}
                                                          addNote={addNote}
                                                          getDebtorNotes={getDebtorNotes}
                        />}
                      </div>
                      <div className={activeTab === 2 ? 'tab-pane active' : 'tab-pane'} id='notes-tab' role='tabpanel'
                           aria-labelledby='sales-tab'>
                        {activeTab === 2 && <Notes history={props.history} />}
                      </div>
                      <div className={activeTab === 3 ? 'tab-pane active' : 'tab-pane'} id='communication-tab'
                           role='tabpanel' aria-labelledby='communication-tab'>
                        {activeTab === 3 && <Communication setFetch={setFetch} fetch={fetch} />}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>}
      <SendEmailToDebtorModal debtorId={Number(id)} isOpen={isEmailModalOpen} onClose={closeEmailModal}/>
      <SendSmsToDebtorModal debtorId={id} isOpen={isSmsModalOpen} onClose={closeSmsModal}/>
      
      {/* TODO: add send letter modal */}
      {openModalNote && (
        <Modal isOpen={openModalNote}
               size='lg'
               style={{ maxWidth: '800px', width: '95%' }}
               className='theme-light'
        >
          <AddNotesDebtor closeModal={closeNoteModal}
                          getDebtorNotes={getDebtorNotes}
                          debtorNotes={debtorNotes}
          />
        </Modal>
      )}
    </>
  );
};

export default Index;
