import axios from 'axios'
import { CreateWipTemplate, CreateWipTemplateColumn } from '../types/wip-templates-service.type';
import { PaginationResponse } from '@src/types/service.type';
import { WipTemplate } from '../types/wip-templates.type';

export class WipTemplatesService {
    private readonly apiUrl = process.env.REACT_APP_API_URL;

    async getTemplates(params?) {
        const response = await axios.get<PaginationResponse<WipTemplate[]>>(`${this.apiUrl}/wip-templates`, { params });
        return response.data;
    }

    async getTemplate(id: number, params?) {
        const response = await axios.get<WipTemplate>(`${this.apiUrl}/wip-templates/${id}`, { params })
        return response.data;
    }

    async createTemplate(body: CreateWipTemplate) {
        const response = await axios.post<WipTemplate>(`${this.apiUrl}/wip-templates`, body);
        return response.data;
    }

    async createTemplateColumn(body: CreateWipTemplateColumn) {
        const response = await axios.post<WipTemplate>(`${this.apiUrl}/wip-templates/create-column`, body)
        return response.data;
    }

    async updateWipTemplate(body: Partial<CreateWipTemplate>, id: number) {
        const response = await axios.patch<WipTemplate>(`${this.apiUrl}/wip-templates/${id}`, body)
        return response.data;
    }

    async deleteTemplate(id: number) {
        const response = await axios.delete<WipTemplate>(`${this.apiUrl}/wip-templates/${id}`)
        return response.data;
    }
}
