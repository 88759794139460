import TableRow from '@components/table/table-row.component'
import { PaymentTableItemProps } from '../../../types/transaction.type'
import { FC } from 'react'
import { format } from 'date-fns'
import { formatCurrency } from '@containers/Recovery/Wip/utils/format-currency'
import TableCell from '@components/table/table-cell-component'
import styles from './payment-table.module.scss'

const PaymentTableItem: FC<PaymentTableItemProps> = ({ payment }) => {
  const debtorName = `${payment.debtor?.name_first || ''} ${payment.debtor?.name_last || ''}`
  return (
    <TableRow>
      {/* TID # */}
      <TableCell position="eclipsed" width={65}>{payment.id}</TableCell>
      {/* LOGGED */}
      <TableCell className={styles.loggedCell} position="eclipsed" width={160}> {format(new Date(), 'kk:mm | dd-MM-yyyy')}</TableCell>
      {/* DEBTOR */}
      <TableCell className={styles.debtorsCell} position="eclipsed"> {debtorName}</TableCell>
      {/* DEPOSIT AMOUNT */}
      <TableCell position="eclipsed"> {formatCurrency(payment.deposit)}</TableCell>
      {/* WITHDRAWAL */}
      <TableCell position="eclipsed"> {formatCurrency(payment.withdrawal)}</TableCell>
      {/* TYPE */}
      <TableCell className={styles.typeCell} position="eclipsed" width={100}> {payment.type}</TableCell>
    </TableRow>
  )
}

export default PaymentTableItem
