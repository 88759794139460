import LoaderWrapper from '@components/loader/loader-wrapper.component'
import NavBar from '@components/nav-bar/nav-bar.component'
import { useMainStyleContext } from '@containers/App/contexts/main-style.context'
import { Header, SubHeader } from '@containers/Recovery/Wip/components/UI/text/text.component'
import { useTaskContext } from '@src/context/task-context'
import { useEffect, useMemo } from 'react'
import {
  useHistory,
  useLocation,
  useRouteMatch,
  useParams,
  Switch,
  Route,
  Redirect,
} from 'react-router'
import styles from './client-page.module.scss'
import { getClientNavBarOptions } from '../../utils/get-client-nav-bar-options.util'
import { useGetClientById } from '../../hooks/get-client-by-id.hook'
import { GET_CLIENT_PARAMS } from '../../const/client.const'
import ClientPropertiesPage from '../../_modules/Properties/views/client-properties-page/client-properties-page.component'
import EditClientPropertyPage from '../../_modules/Properties/views/edit-client-property-page/edit-client-property-page.component'
import ClientWipsPage from '../../_modules/Wips/client-wips-page.component'
import TaskBoard from '@containers/Setting/components/Contacts/taskboard'
import AutomationBoard from '@containers/Setting/components/Contacts/automations'
import ClientCommunicationPage from '../../_modules/Communication/client-communication-page.component'
import ClientOverviewPage from '../../_modules/Overview/client-overview-page.component'
import { ClientPageActionsProvider } from './client-page-actions-context.page'
import ClientSpeedDialWrapper from './client-speed-dial-wrapper.component'

const ClientPage = () => {
  const history = useHistory()
  const location = useLocation()

  const { setCurrentClient, setMode } = useTaskContext()

  const { path } = useRouteMatch()
  const { clientId } = useParams<{ clientId: string; propertyId: string }>()

  const { data: client, isLoading, isError } = useGetClientById(Number(clientId), GET_CLIENT_PARAMS)
  const { setStyle } = useMainStyleContext()

  useEffect(() => {
    setStyle({ backgroundColor: '#f9fafb' })
    setMode('SALES')
    setCurrentClient(Number(clientId))

    return () => {
      setStyle({ backgroundColor: 'white' })
    }
  }, [])

  useEffect(() => {
    if (!isError) return
    history.push('/contact-list')
  }, [isError])

  const options = useMemo(() => getClientNavBarOptions(Number(clientId)), [clientId])
  return (
    <ClientPageActionsProvider>
      {isLoading || !client ? (
        <LoaderWrapper />
      ) : (
        <>
          <div className={styles.wipInfo}>
            <Header>{client.name}</Header>
            <SubHeader>
              Main Contact:
              {`${client.contacts?.[0]?.first_name} ${client.contacts?.[0]?.last_name}`}
            </SubHeader>
          </div>
          <div className={styles.navbarWrapper}>
            <NavBar options={options} activeUrl={location.pathname} />
            <ClientSpeedDialWrapper client={client} />
          </div>
          <Switch>
            <Route
              exact
              path={`${path}/overview`}
              render={() => <ClientOverviewPage client={client} />}
            />
            <Route path={`${path}/accounts`} render={() => <div>accounts</div>} />
            <Route
              path={`${path}/task-board`}
              render={() => (
                <div>
                  <TaskBoard />
                </div>
              )}
            />
            <Route
              path={`${path}/communication`}
              render={() => <ClientCommunicationPage client={client} />}
            />
            <Route exact path={`${path}/automations`} render={() => <AutomationBoard />} />
            <Route
              exact
              path={`${path}/properties`}
              render={() => <ClientPropertiesPage company={client} />}
            />
            <Route
              exact
              path={`${path}/property/:propertyId`}
              render={() => <EditClientPropertyPage client={client} />}
            />
            <Route exact path={`${path}/wips`} render={() => <ClientWipsPage client={client} />} />
            <Route path={'*'} render={() => <Redirect to={`/client/${clientId}/overview`} />} />
          </Switch>
        </>
      )}
    </ClientPageActionsProvider>
  )
}

export default ClientPage
