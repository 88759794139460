import { REACT_APP_API_URL } from "@src/lib/global-variables"
import { PaginationResponse, Params } from "@src/types/service.type"
import axios from "axios"
import { AddFeeData, AddFeeResponse, UpdateFeeData, WipCostList } from "../types/claim.type"

export class WipCostListsService {
  async getListOfFees(params: Params = {}) {
    const res = await axios.get<PaginationResponse<WipCostList[]>>(`${REACT_APP_API_URL}/wip-cost-lists`, { params })
    const data = res.data.data[0]
    if (data) {
      return data.list
        .filter((item) => item.id)
        .map((item) => ({ label: item.name, value: item.id }))
    }
    return []
  }

  async addFee(data: AddFeeData) {
    const response = await axios.post<AddFeeResponse>(`${REACT_APP_API_URL}/wips/add-fee`, data)
    return response.data
  }

  async removeFee(id: number) {
    const response = await axios.delete<AddFeeResponse>(`${REACT_APP_API_URL}/wips/remove-fee/${id}`)
    return response.data
  }

  async updateFee(id: number,data: UpdateFeeData) {
    const response = await axios.put<AddFeeResponse>(`${REACT_APP_API_URL}/wips/update-fee/${id}`, data)
    return response.data
  }
}