import axios from "axios";
import { CreateDebtorRelationData, DebtorRelation } from "../types/debtor-associate-detail.type";
import { REACT_APP_API_URL } from "@src/lib/global-variables";
import { DeepPartial } from "react-hook-form";

export class DebtorRelationService {

  async createDebtorRelation(data: CreateDebtorRelationData) {
    const res = await axios.post<DebtorRelation>(`${REACT_APP_API_URL}/debtor-relations`, data)
    return res.data
  }

  async deleteDebtorRelation(debtorRelationId: number) {
    const res = await axios.delete<DebtorRelation>(`${REACT_APP_API_URL}/debtor-relations/${debtorRelationId}`)
    return res.data
  }

  async updateDebtorRelation(debtorRelationId: number, data: DeepPartial<DebtorRelation>) {
    const res = await axios.put<DebtorRelation>(`${REACT_APP_API_URL}/debtor-relations/${debtorRelationId}`, data)
    return res.data
  }
}