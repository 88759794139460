import { TableCell } from '@material-ui/core'
import React, { FC } from 'react'
import styles from './custom-table-cell.module.scss'
import cls from 'classnames'

interface CustomTableCellProps {
  position?: 'center' | 'eclipsed'
  children: React.ReactNode
  width?: string
  className?: string
}

const CustomTableCell: FC<CustomTableCellProps> = ({
  position = 'center',
  children,
  width,
  className,
}) => {
  return (
    <TableCell
      style={width ? { width } : undefined}
      className={cls(
        {
          [styles.ellipsis]: position === 'eclipsed',
          [styles.center]: position === 'center',
        },
        className,
      )}
    >
      <span>{children}</span>
    </TableCell>
  )
}

export default CustomTableCell
