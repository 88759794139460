import { GLOBAL_QUERIES } from "@src/lib/global-queries";
import { useInfiniteQuery } from "@tanstack/react-query"
import { useMemo } from "react";
import { CombinedNotesService } from "../../services/combined-notes.service";

const combinedNotesService = new CombinedNotesService();

export const useGetCombinedNotes = (noteParams) => {
    const { data, ...restQuery } = useInfiniteQuery({
        queryKey: [GLOBAL_QUERIES.COMBINED_NOTES.GET, noteParams],
        queryFn: async ({pageParam}) => {
            const page = pageParam ? pageParam : 1;
            const limit = noteParams.limit;
            const data = await combinedNotesService.getCombinedNotes({ ...noteParams, limit, page })
            // const data = await axios.get(`${REACT_APP_API_URL}/task-notes/combined-notes`, { params: { ...noteParams, limit, page } })
            return {
                results: data.data,
                page: page < data.pageCount ? page + 1 : undefined 
            }
        },
        cacheTime: 0,
        getNextPageParam: (lastPage) => lastPage.page
    });

    const flattenedData = useMemo(
        () => (data ? data?.pages.flatMap(item => item.results) : []),
        [data]
    );

    return { data: flattenedData, ...restQuery }
}