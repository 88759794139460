import { ChangeEvent, FC, useEffect, useRef, useState } from 'react'
import styles from './hierarchical-select.module.scss'
import RoundedArrow from '@src/icons/rounded-arrow'
import FormInput from '@components/inputs/form-input/form-input.component'
import SearchIcon from '@src/icons/search'
import SquareAddIcon from '@src/icons/square-add'
import { useModal } from '@src/hooks/useModal'
import SelectTailOptionModal from './select-tail-option-modal.component'
import useOutsideClick from '@src/hooks/useOutside'

interface Column {
  label: string
  // can be represent like path.path or path
  path: string
}

export interface HeadOption {
  label: string
  columns: Column[]

  options: TailOption[]
}

export interface TailOption {
  id: number
  label: string
  type?: string
  data: NonNullable<unknown>
}

interface HierarchicalSelectProps {
  onSelect?: (headOption: HeadOption, tailOption: TailOption) => void
  options: HeadOption[]
  defaultSelectedId?: number
}

const HierarchicalSelect: FC<HierarchicalSelectProps> = ({ onSelect, options, defaultSelectedId }) => {
  const [isModalOpen, handleModalOpen, handleModalClose] = useModal(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const [searchInput, setSearchInput] = useState('')

  const [selectedTailOption, setTailOptionSelected] = useState<TailOption | null>(null)
  const [selectedHeadOption, setSelectedHeadOption] = useState<HeadOption | null>(null)

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value)
  }

  const handleOpen = () => {
    setIsOpen(true)
  }
  useEffect(() => {
    const tailOptions = options.find((option) => option.label === 'PROPERTY')?.options
    setTailOptionSelected(tailOptions?.find((option) => option.id === defaultSelectedId) || null)
  }, [defaultSelectedId])
  const handleClose = () => {
    setIsOpen(false)
  }
  const handleSelect = (headOption: HeadOption, tailOption: TailOption) => {
    setTailOptionSelected(tailOption)
    setSelectedHeadOption(headOption)
    setIsOpen(false)
  }

  const handleHeadOptionButtonClick = (headOption: HeadOption) => {
    setSelectedHeadOption(headOption)
    handleModalOpen()
  }
  const selectRef = useRef(null)
  useOutsideClick([selectRef], handleClose)
  return (
    <div className={styles.wrapper} ref={selectRef}>
      <div className={styles.controlWrapper} onClick={handleOpen}>
        <div className={styles.controlContent}>
          <FormInput placeholder={selectedTailOption?.label} className={styles.fromSearch} onChange={onChange} />
        </div>
        <button className={styles.controlButton}>
          <RoundedArrow />
        </button>
      </div>
      {isOpen ? (
        <div className={styles.dropdownWrapper}>
          {options.map(({ columns, label, options }, index) => {
            return (
              <>
                <div className={styles.headOptionWrapper} key={`${label}-${index}`}>
                  <div className={styles.headOptionLabel}>{label}</div>
                  {options.length > 2 ? (
                    <button
                      className={styles.headOptionButton}
                      onClick={() => handleHeadOptionButtonClick({ columns, label, options })}
                    >
                      <SquareAddIcon />
                    </button>
                  ) : null}
                </div>
                <div>
                  {options
                    .filter((tailOption) =>
                      tailOption.label.toLowerCase().includes(searchInput.toLowerCase()),
                    )
                    .slice(0, 2)
                    .map((tailOption) => (
                      <div
                        className={styles.tailOptionWrapper}
                        key={`tail-option-${tailOption.id}`}
                        onClick={() => handleSelect({ columns, label, options }, tailOption)}
                      >
                        {tailOption.label}
                      </div>
                    ))}
                </div>
              </>
            )
          })}
        </div>
      ) : null}
      {selectedHeadOption ? (
        <SelectTailOptionModal
          headOption={selectedHeadOption}
          isOpen={isModalOpen}
          onClose={handleModalClose}
        />
      ) : null}
    </div>
  )
}

export default HierarchicalSelect
