import { FC } from 'react'
import styles from './email-body.module.scss'
import { EmailBodyProps } from '../../types/view-email-page.type'
import { FrameWrapper } from '@components/iframe-wrapper.component'

const EmailBody: FC<EmailBodyProps> = ({ email }) => {
  const { from, subject, body } = email
  return (
    <div className={styles.emailBodyWrapper}>
      <div className={styles.senderInformationWrapper}>
        <div className={styles.senderInformation}>
          <div className={styles.senderImage}>
            <img
              src="https://img.freepik.com/free-photo/portrait-man-laughing_23-2148859448.jpg?size=338&ext=jpg&ga=GA1.1.87170709.1707782400&semt=ais"
              alt="sender"
            />
          </div>
          <div className={styles.senderProfile}>
            <span className={styles.senderName}>{from.emailAddress.name}</span>
            <span className={styles.senderEmail}>{from.emailAddress.address}</span>
          </div>
        </div>
      </div>
      <div className={styles.subject}>{subject}</div>
      {body.contentType === 'html' ? (
        <FrameWrapper html={body.content}/>
      ) : (
        <div className={styles.bodyContent}>{body.content}</div>
      )}
    </div>
  )
}

export default EmailBody
