import { editInvoiceActionEnum } from '../actionTypes';

export const editInvoiceByIDReducer = function (
  state = { data: [], loading: false, error: '' },
  action,
) {
  switch (action.type) {
    case editInvoiceActionEnum.EDIT_INVOICE_SUCCESS:
      return { loading: false, error: '', data: action.payload };
    case editInvoiceActionEnum.IS_LOADING_EDIT_INVOICE_FORM:
      return { loading: true, error: '', data: [] };
    case editInvoiceActionEnum.EDIT_INVOICE_ERROR:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
