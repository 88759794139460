export const confirmationModalStyles = {
  container: {
    width: '100%',
    maxWidth: '600px',
    minHeight: '200px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
  header: {
    padding: "10px 10px 0 10px",
    display: 'flex',
    justifyContent: 'end',
  },
  body: {
    padding: '0 20px',
  },
  heading: {
    fontFamily: 'Source Sans Pro',
    fontSize: '28px',
    color: 'gray',
    fontWeight: '700',
    marginBottom: '15px  !important',
  },
  subheading: {
    fontFamily: 'Source Sans Pro',
    fontSize: '18px',
    color: 'gray',
    marginBottom: '15px  !important',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '20px',
    bgcolor: '#ECF0F1',
    padding: '15px',
    boxShadow: "inset 0 0 5px #d5d5d5"
  },
};
