import { FC } from 'react'
import MatchColumnsStep from './new-match-columns-step.component'
import {
  COLUMN_TO_MATCH_OPTIONS,
  OWNER_MATCH_FIELDS,
  PROPERTY_MATCH_FIELDS,
  PropertyColumn,
} from '@containers/Sales/Properties/const/properties.const'
import {
  flattenObjectToArrays,
  parseOwnerText,
  parsePropertyAddress,
} from '@containers/Sales/Properties/utils/property.utils'
import { PropertyMatchColumnsStepProps } from '@containers/Sales/Properties/types/property.type'

const PropertyMatchColumnsStep: FC<PropertyMatchColumnsStepProps> = ({
                                                                       rows,
                                                                       header,
                                                                       onNextStepClick,
                                                                     }) => {
  const handleOwnerRowsChange = (rows: string[][], columnIndex: number) => {
    const newRows: string[][] = []
    for (const row of rows) {
      const ownerCell = row[columnIndex]
      const owner = parseOwnerText(ownerCell)
      const newRow = [...row]
      !owner.middleName ? (newRow[columnIndex] = `${owner.firstName} ${owner.lastName}`) :
        (newRow[columnIndex] = `${owner.firstName} ${owner.middleName} ${owner.lastName}`)
      if (owner.additionalLandlord !== '') {
        newRow[columnIndex] += ` & ${owner.additionalLandlord}`
      }
      newRow[columnIndex] += ` - ${owner.entityName}`

      newRows.push(newRow)
    }

    // for (const row of rows) {
    //   const ownerCell = row[columnIndex]
    //   const owners = parseOwnerText(ownerCell)
    //   for (const owner of owners) {
    //     const newRow = [...row]
    //     !owner.middleName ? (newRow[columnIndex] = `${owner.firstName} ${owner.lastName}`) :
    //       (newRow[columnIndex] = `${owner.firstName} ${owner.middleName} ${owner.lastName}`)
    //     newRow[columnIndex] += ` - ${owner.entityName}`
    //     newRows.push(newRow)
    //   }
    // }
    return newRows
  }
  const handleNextStep = (data: Record<string, string | Record<string, string>>) => {
    onNextStepClick(flattenObjectToArrays(data))
  }


  return (
    <MatchColumnsStep
      header={header}
      rows={rows}
      onNextStepClick={handleNextStep}
      matchOptions={[
        {
          label: 'Owner',
          value: PropertyColumn.OWNER,
          matchDropdown: {
            rowsChangeAction: handleOwnerRowsChange,
            jsonResolver: (cell: string) => parseOwnerText(cell),
            fields: OWNER_MATCH_FIELDS,
          },
        },
        {
          label: 'Property',
          value: PropertyColumn.PROPERTY,
          matchDropdown: {
            jsonResolver: (cell: string) => parsePropertyAddress(cell),
            fields: PROPERTY_MATCH_FIELDS,
          },
        },
        ...COLUMN_TO_MATCH_OPTIONS,
      ]}
    />
  )
}

export default PropertyMatchColumnsStep
