import { useMutation } from "@tanstack/react-query";
import { DebtorLegalClaimNumberService } from "../../services/debtor-legal-claim-number.service";
import { CreateDebtorLegalClaimNumber } from "../../types/debtor-claim-number.type";
import { Params } from "@src/types/service.type";
import { DeepPartial } from "react-hook-form";
import { DebtorLegalClaimNumber } from "../../types/debtor-legal.type";


export const useCreateDebtorLegalClaimNumberMutation = () => {
  const createDebtorLegalClaimNumber = async (data: CreateDebtorLegalClaimNumber, params?: Params) => {
    const debtorLegalClaimNumberService = new DebtorLegalClaimNumberService();
    return debtorLegalClaimNumberService.createDebtorClaimNumber(data, params);
  }

  const mutation = useMutation({ mutationFn: ({ data, params }: { data: CreateDebtorLegalClaimNumber, params?: Params }) => createDebtorLegalClaimNumber(data, params) });

  return mutation;
}

export const useUpdateDebtorLegalClaimNumberMutation = () => {
  const updateDebtorLegalClaimNumber = async (debtorClaimNumberId: number, data: DeepPartial<DebtorLegalClaimNumber>, params?: Params) => {
    const debtorLegalClaimNumberService = new DebtorLegalClaimNumberService();
    return debtorLegalClaimNumberService.updateDebtorClaimNumber(debtorClaimNumberId, data, params);
  }

  const mutation = useMutation({ mutationFn: ({ debtorClaimNumberId, data, params }: { debtorClaimNumberId: number, data: DeepPartial<DebtorLegalClaimNumber>, params?: Params }) => updateDebtorLegalClaimNumber(debtorClaimNumberId, data, params) });

  return mutation;
}

export const useDeleteDebtorLegalClaimNumberMutation = () => {
  const deleteDebtorLegalClaimNumber = async (debtorClaimNumberId: number, params?: Params) => {
    const debtorLegalClaimNumberService = new DebtorLegalClaimNumberService();
    return debtorLegalClaimNumberService.deleteDebtorClaimNumber(debtorClaimNumberId, params);
  }

  const mutation = useMutation({ mutationFn: ({ debtorClaimNumberId, params }: { debtorClaimNumberId: number, params?: Params }) => deleteDebtorLegalClaimNumber(debtorClaimNumberId, params) });

  return mutation;
}