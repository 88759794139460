import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useGetCombinedNotes } from './hooks/get-combined-notes.hook'
import CombinedNote from './combined-note.component'
import { useModal } from '@src/hooks/useModal'
import { Oval } from 'react-loader-spinner'
import styles from './combined-note.module.scss'
import { CombinedNotesListProps } from '../../types/combined-notes.type'
import CombinedNoteModal from '../../../../../Communication/components/modals/combined-note-modal.component'
import { CombinedNoteType } from '../../types/combined-notes.type'

const CombinedNotesList: FC<CombinedNotesListProps> = ({ taskId, combinedNotesParams }) => {
  const [isTaskNoteModalOpen, openTaskNoteModal, handleTaskModalClose] = useModal(false)
  const [selectedTaskNote, setSelectedTaskNote] = useState<{
    id: number
    type: CombinedNoteType
    userId: string | number | null
  } | null>(null)

  const { data, isLoading, hasNextPage, isFetching, fetchNextPage } =
    useGetCombinedNotes(combinedNotesParams)

  const observer = useRef<IntersectionObserver>()

  const lastElementRef = useCallback(
    (node: HTMLDivElement) => {
      if (isLoading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage && !isFetching) {
          fetchNextPage()
        }
      })
      if (node) observer.current.observe(node)
    },
    [isLoading, isFetching, hasNextPage],
  )

  useEffect(() => {
    if (taskId) {
      fetchNextPage()
    }
  }, [taskId])

  const handleTaskNoteModalOpen = (selectedTaskNote: { id: number; type: CombinedNoteType, userId: string | number | null }) => {
    setSelectedTaskNote(selectedTaskNote)
    openTaskNoteModal()
  }
  const isDataNoteFound = data.length === 0 && !isFetching
  return (
    <>
      <div className={styles.combinedNoteListWrapper}>
        {isDataNoteFound ? <h4 className={styles.notFound}>No notes to display</h4> : null}
        {data.map((combinedNote, i) => (
          <CombinedNote
            key={`task-note-${i}-${combinedNote.id}`}
            lastElementRef={data.length === i + 1 ? lastElementRef : null}
            taskNote={combinedNote}
            openTaskNoteModal={handleTaskNoteModalOpen}
          />
        ))}
        {isFetching ? (
          <Oval
            visible={true}
            height="40"
            width="40"
            color="blue"
            secondaryColor="blue"
            ariaLabel="oval-loading"
            wrapperStyle={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              marginTop: '10px',
            }}
          />
        ) : null}
      </div>
      <CombinedNoteModal
        isOpen={isTaskNoteModalOpen}
        onClose={handleTaskModalClose}
        id={selectedTaskNote?.id || null}
        userId={selectedTaskNote?.userId || null}
        type={selectedTaskNote?.type || CombinedNoteType.TASK_NOTE}
      />
    </>
  )
}

export default CombinedNotesList
