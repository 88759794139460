import { Params } from "@src/types/service.type";
import { DebtorLegalPlaintiffService } from "../../services/debtor-legal-plaintiff.service";
import { DebtorLegalPlaintiff } from "../../types/debtor-legal.type";
import { CreateDebtorLegalPlaintiffData } from "../../types/debtor-plaintiff.type";
import { useMutation } from "@tanstack/react-query";
import { DeepPartial } from "react-hook-form";

export const useCreateDebtorLegalPlaintiffMutation = () => {

  const createDebtorLegal = (data: CreateDebtorLegalPlaintiffData, params?: Params) => {
    const debtorLegalPlaintiffService = new DebtorLegalPlaintiffService();
    return debtorLegalPlaintiffService.createDebtorLegalPlaintiff(data, params);
  }

  const mutation = useMutation({
    mutationFn: ({ data, params }: { data: CreateDebtorLegalPlaintiffData, params?: Params }) => createDebtorLegal(data, params)
  })

  return mutation
}

export const useUpdateDebtorLegalPlaintiffMutation = () => {

  const updateDebtorLegal = (id: number, data: DeepPartial<DebtorLegalPlaintiff>, params?: Params) => {
    const debtorLegalPlaintiffService = new DebtorLegalPlaintiffService();
    return debtorLegalPlaintiffService.updateDebtorLegalPlaintiff(id, data, params);
  }

  const mutation = useMutation({
    mutationFn: ({ id, data, params }: { id: number, data: DeepPartial<DebtorLegalPlaintiff>, params?: Params }) => updateDebtorLegal(id, data, params)
  })

  return mutation
}

export const useDeleteDebtorLegalPlaintiffMutation = () => {

  const deleteDebtorLegal = (id: number, params?: Params) => {
    const debtorLegalPlaintiffService = new DebtorLegalPlaintiffService();
    return debtorLegalPlaintiffService.deleteDebtorLegalPlaintiff(id, params);
  }

  const mutation = useMutation({
    mutationFn: ({ id, params }: { id: number, params?: Params }) => deleteDebtorLegal(id, params)
  })

  return mutation
}