import React from 'react';

import { Grid, OutlinedInput, Typography, outlinedInputClasses, Tooltip } from '@mui/material';

import { inputStyles, formInputStyles } from './styles';

const FormInput = ({
  label,
  type,
  placeholder,
  name,
  changeHandler,
  required,
  value,
  error,
  touched,
  bold,
  width,
  column,
  disabled,
  handleBlur,
}) => {
  return (
    <Grid item sx={width ? formInputStyles.container : formInputStyles.containerFull}>
      <Grid sx={column ? formInputStyles.containerColumn : formInputStyles.containerFull}>
        {label && (
          <Typography sx={bold ? formInputStyles.labelBold : formInputStyles.label}>
            {label} {required && <span style={formInputStyles.required}>*</span>}
          </Typography>
        )}

        <Grid sx={formInputStyles.inputContainer} style={{ maxWidth: width ? width : '' }}>
          <OutlinedInput
            onChange={changeHandler}
            onBlur={handleBlur}
            type={type}
            value={value}
            placeholder={placeholder}
            name={name}
            required={required}
            sx={inputStyles(outlinedInputClasses)}
            disabled={disabled}
          />

          <Grid sx={formInputStyles.errorMessage}>
            {error && touched && (
              <Tooltip title={error} placement="bottom-start">
                <Typography sx={formInputStyles.errorText}>{error}</Typography>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FormInput;
