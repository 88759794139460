import React from 'react'
import { Link } from 'react-router-dom'
import Services from '../../Services.js'
import { withRouter } from 'react-router'
import AzureLoginButton from './azure-login-button.component'
import axios from 'axios'
import { toast } from 'react-toastify'
import { UserContext } from '../../../context/user-context';

const logoImg = `${process.env.PUBLIC_URL}/image/logo.png`

class LogInForm extends React.Component {
  static contextType = UserContext

  constructor(props) {
    super(props)
    this.state = {
      showPassword: false,
      email: '',
      password: '',
    }

    this.services = new Services()

    this.showPassword = this.showPassword.bind(this)
    this.login = this.login.bind(this)
    this.changeInput = this.changeInput.bind(this)
  }

  componentDidMount() {
    localStorage.setItem('token', '')
  }

  showPassword(e) {
    e.preventDefault()
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }))
  }

  login = async () => {
    const data = {
      comms_email: this.state.email,
      password: this.state.password,
    }
    const {fetchUser} = this.context;
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, data, {
        withCredentials: true,
      })
      await fetchUser()
      toast.success('Welcome back')
    } catch (e){
      console.log(e)
      toast.error('Wrong email or password')
    }
  }

  changeInput(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  render() {
    return (
      <div className="login_form_wrap">
        <div className="login_form_inner">
          <div className="login_logo_div ml-0 pb-4">
            <a className="" href="index.html">
              <img src={logoImg} alt="logo" />
            </a>
          </div>
          <div className="login_head">
            <h1>Sign in to BMS</h1>
            <p className="mb-md-0 fw-500">Please enter your credentials to proceed.</p>
          </div>
          <form>
            <div className="form-group">
              <label>EMAIL ADDRESS</label>
              <input
                className="form-control"
                placeholder=""
                name="email"
                id="email"
                type="email"
                onChange={this.changeInput}
              />
            </div>
            <div className="form-group">
              <Link to={'/login'} className="forgot_pass_link" onClick={this.props.forgotPassword}>
                Forgot password?
              </Link>
              <label>PASSWORD</label>
              <input
                className="form-control"
                placeholder=""
                name="password"
                id="password"
                type="password"
                onChange={this.changeInput}
              />
            </div>

            <Link to={'/login'} className="btn btn-secondary" onClick={this.login}>
              Sign in
            </Link>
            <AzureLoginButton />
          </form>
        </div>
      </div>
    )
  }
}

export default withRouter(LogInForm)
