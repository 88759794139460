import React, { Component } from 'react';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
// import SettingTable from './components/SettingTable';
import { Link } from 'react-router-dom';

class Setting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
    };
  }
  componentDidMount() {
    this.setState({ title: 'MANAGE STAFF' });
  }
  render() {
    return (
      <Container style={{ height: '100vh' }}>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Settings</h3>
          </Col>
        </Row>
        <Row>
          {/* Here we will design setting screen */}
          <Col md={12} lg={12}>
            <Card>
              <CardBody className="settings-mangement">
                <div className="card__title">
                  <h5 className="bold-text">Management tools</h5>
                </div>
                <Row>
                  <Col lg={3} md={6} sm={12}>
                    <Link to="/manage-templates" className="mg_tools_box setting_section">
                      <div className="mg_icon no_bg">
                        <img src="./image/managetemplate.svg" alt="Manage Templates" />
                      </div>
                      <p className="mg_title">Manage Templates</p>
                    </Link>
                  </Col>
                  {/*<Col xl={3} lg={4} md={6} sm={12}>*/}
                  {/*    <Link to="/staff-list" className="mg_tools_box setting_section">*/}
                  {/*      <div className="mg_icon no_bg"><img src="./image/manageStaff.svg" alt="Manage Staff"/></div>*/}
                  {/*      <p className="mg_title">Manage Staff</p>*/}
                  {/*    </Link>*/}
                  {/*</Col>*/}
                  <Col lg={3} md={6} sm={12}>
                    <Link to="/wip-costs-user" className="mg_tools_box setting_section">
                      <div className="mg_icon no_bg">
                        <img src="./image/wipcost.svg" alt="WIP Cost" />
                      </div>
                      <p className="mg_title">WIP Cost (Calculation)</p>
                    </Link>
                  </Col>
                  <Col lg={3} md={6} sm={12}>
                    <Link to="/wip-costs-table" className="mg_tools_box setting_section">
                      <div className="mg_icon no_bg">
                        <img src="./image/wipcost.svg" alt="WIP Cost" />
                      </div>
                      <p className="mg_title">WIP Costs Table</p>
                    </Link>
                  </Col>
                  <Col lg={3} md={6} sm={12}>
                    <Link to="/wip-scale-of-fees" className="mg_tools_box setting_section">
                      <div className="mg_icon no_bg">
                        <img src="./image/wipcost.svg" alt="WIP Cost" />
                      </div>
                      <p className="mg_title">WIP Scale of Fees</p>
                    </Link>
                  </Col>
                </Row>
                <Col md={12} className="toolbox_divider">
                  <hr />
                </Col>
                <Row>
                  {/*<Col lg={3} md={6} sm={12}>*/}
                  {/*    <Link to="/debtor" className="mg_tools_box setting_section">*/}
                  {/*      <div className="mg_icon no_bg"><img src="./image/tenant.svg" alt="Tenant"/></div>*/}
                  {/*      <p className="mg_title">Debtors</p>*/}
                  {/*    </Link>*/}
                  {/*</Col>*/}
                  {/*<Col lg={3} md={6} sm={12}>*/}
                  {/*    <Link to="/contact-list" className="mg_tools_box setting_section">*/}
                  {/*      <div className="mg_icon no_bg sidebar__link-icon"><img src="./image/clients.svg" alt="Tenant"/></div>*/}
                  {/*      <p className="mg_title">Clients</p>*/}
                  {/*    </Link>*/}
                  {/*</Col>*/}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default Setting;
