import Joi from "joi";

export const sendEmailSchema = Joi.object({
  to: Joi.string().email({ tlds: { allow: false } }).required().messages({
    'string.email': 'To must be a email',
    'string.empty': 'To is required',
    'string.required': 'To is required',
  }),
  subject: Joi.string().required().messages({
    'string.empty': 'Subject is required',
    'string.required': 'Subject is required',
  }),
  body: Joi.string().required().messages({
    'string.empty': 'Body is required',
    'string.required': 'Body is required',
  }),
})