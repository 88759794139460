import React from 'react';
import { Button, Col } from 'reactstrap';
import { reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import Services from '../../../../../shared/Services.js';
import { withRouter } from 'react-router';
import Select from 'react-select';
import SimpleReactValidator from 'simple-react-validator';
import ModalComponent from '../../../../../shared/components/ModalCustom';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Geosuggest from 'react-geosuggest';

const renderField = ({ input, placeholder, value, type, meta: { touched, error } }) => (
  <div className="form__form-group-input-wrap">
    <input {...input} placeholder={placeholder} type={type} value={value} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
};

const serviceOption = [
  {
    value: '0',
    label: 'Basic',
  },
  {
    value: '1',
    label: 'Commercial',
  },
  {
    value: '2',
    label: 'Private Space',
  },
  {
    value: '3',
    label: 'Single Storage Shed',
  },
  {
    value: '4',
    label: 'Storage Shed Complex',
  },
  {
    value: '5',
    label: 'Short Term Accom',
  },
  {
    value: '6',
    label: 'Room',
  },
  {
    value: '7',
    label: 'Body Corporate',
  },
];

const cTypeOption = [
  { value: '0', label: 'Cover Note' },
  { value: '1', label: 'Property Track Only' },
];

class AddCoverNote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      client_id: localStorage.getItem('client_Id'),
      propertyData: '',
      client_code: '',
      id: '',
      agent_nm: '',
      agent_phone: '',
      agent_address_1: '',
      agent_address_2: '',
      agent_address_3: '',
      agent_suburb: '',
      agent_state: '',
      agent_post_code: '',
      agent_country: '',
      cover_to: new Date(),
      cover_from: new Date(),
      prop_address_1: '',
      prop_address_2: '',
      prop_address_3: '',
      prop_suburb: '',
      prop_state: '',
      prop_post_code: '',
      prop_country: '',
      landlord_title: '',
      landlord_first: '',
      landlord_last: '',
      owner_email: '',
      owner_phone: '',
      landlord_address_1: '',
      landlord_address_2: '',
      landlord_address_3: '',
      landlord_suburb: '',
      landlord_state: '',
      landlord_post_code: '',
      landlord_country: '',
      landlord_invoice: 0,
      inovice_type: 0,
      status: 0,
      service: { value: '0', label: 'Basic' },
      tenancy_plus_3_months: 0,
      tenancy_completed_all: 0,
      bond_4_weeks: 0,
      rent_arrears: 0,
      aware_of_details: 0,
      crby: 'Kevin',
      awareerrorModal: false,
      errorMessage: '',
      isShowSearch: false,
      search_code: '',
      cType: { value: '0', label: 'Cover Note' },
      weekly_rent: '',
      agentList: [],
      isEdit: false,
    };

    this.services = new Services();
    this.saveCoverNote = this.saveCoverNote.bind(this);
    this.changeInput = this.changeInput.bind(this);
    this.changeAddress = this.changeAddress.bind(this);
    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.coverNoteId
    ) {
      this.getStaff(
        this.props.history.location.state.coverNoteId,
        this.props.history.location.state.client_Id,
      );
    }
  }

  getStaff(id, cid) {
    const data = {
      coverNote_id: id,
      client_id: cid,
    };
    this.services.getCoverNotes(data, (res) => {
      if (res.status === 200) {
        const getData = res.data.data.list[0];
        const cover_to =
          getData.cover_to && getData.cover_to !== 'Invalid date'
            ? new Date(getData.cover_to)
            : moment(new Date()).format('YYYY-MM-DD');
        const cover_from =
          getData.cover_from && getData.cover_from !== 'Invalid date'
            ? new Date(getData.cover_from)
            : moment(new Date()).format('YYYY-MM-DD');
        this.setState({
          isEdit: true,
          _id: getData._id,
          client_id: getData.client_id,
          agent_nm: getData.agent_nm,
          agent_phone: getData.agent_phone,
          agent_address_1: getData.agent_address_1,
          agent_address_2: getData.agent_address_2,
          agent_address_3: getData.agent_address_3,
          agent_suburb: getData.agent_suburb,
          agent_state: getData.agent_state,
          agent_post_code: getData.agent_post_code,
          agent_country: getData.agent_country,
          cover_to: cover_to,
          cover_from: cover_from,
          prop_address_1: getData.prop_address_1,
          prop_address_2: getData.prop_address_2,
          prop_address_3: getData.prop_address_3,
          prop_suburb: getData.prop_suburb,
          prop_state: getData.prop_state,
          prop_post_code: getData.prop_post_code,
          prop_country: getData.prop_country,
          service: serviceOption.find((_) => _.value === getData.inovice_type),
          status: cTypeOption.find((_) => _.value === getData.status),
          tenancy_plus_3_months: getData.tenancy_plus_3_months,
          tenancy_completed_all: getData.tenancy_completed_all,
          bond_4_weeks: getData.bond_4_weeks,
          rent_arrears: getData.rent_arrears,
          weekly_rent: getData.weekly_rent,
        });
      } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
        this.setState({ accessErrorModal: true });
      } else {
        this.services.errorToast(res.message);
      }
    });
  }

  accessErrorModalClose = () => {
    this.setState({ accessErrorModal: false });
    this.props.history.push('/dashboard');
  };

  editProperty = (propertyId) => {
    const data = {
      client_id: this.state.client_id,
      Prop_ID: propertyId,
    };

    this.services.getPropertyList(data, (res) => {
      if (res.status === 200) {
        const propertyData = res.data.data.list[0];
        const cover_to =
          propertyData.cover_to && propertyData.cover_to !== 'Invalid date'
            ? new Date(propertyData.cover_to)
            : moment(new Date()).format('YYYY-MM-DD');
        const cover_from =
          propertyData.cover_from && propertyData.cover_from !== 'Invalid date'
            ? new Date(propertyData.cover_from)
            : moment(new Date()).format('YYYY-MM-DD');

        this.setState({
          propertyData: propertyData,
          agent_address_1: propertyData.agent_address_1,
          agent_address_2: propertyData.agent_address_2,
          agent_address_3: propertyData.agent_address_3,
          agent_country: propertyData.agent_country,
          agent_nm: propertyData.agent_nm,
          agent_phone: propertyData.agent_phone,
          agent_post_code: propertyData.agent_post_code,
          agent_state: propertyData.agent_state,
          agent_suburb: propertyData.agent_suburb,
          bond_4_weeks: propertyData.bond_4_weeks,
          client_code: propertyData.client_code,
          client_id: propertyData.client_id,
          cover_to: cover_to,
          cover_from: cover_from,
          crDate: new Date(propertyData.crDate),
          crby: propertyData.crby,
          credit: propertyData.credit,
          inovice_type: propertyData.inovice_type,
          isdel: propertyData.isdel,
          landlord_address_1: propertyData.landlord_address_1,
          landlord_address_2: propertyData.landlord_address_2,
          landlord_address_3: propertyData.landlord_address_3,
          landlord_country: propertyData.landlord_country,
          landlord_first: propertyData.landlord_first,
          landlord_invoice: propertyData.landlord_invoice,
          landlord_last: propertyData.landlord_last,
          landlord_post_code: propertyData.landlord_post_code,
          landlord_state: propertyData.landlord_state,
          landlord_suburb: propertyData.landlord_suburb,
          landlord_title: propertyData.landlord_title,
          owner_email: propertyData.owner_email,
          owner_phone: propertyData.owner_phone,
          price: propertyData.price,
          prop_address_1: propertyData.prop_address_1,
          prop_address_2: propertyData.prop_address_2,
          prop_address_3: propertyData.prop_address_3,
          prop_country: propertyData.prop_country,
          prop_post_code: propertyData.prop_post_code,
          prop_state: propertyData.prop_state,
          prop_suburb: propertyData.prop_suburb,
          rent_arrears: propertyData.rent_arrears,
          aware_of_details: propertyData.aware_of_details,
          status: propertyData.status,
          service: propertyData.service,
          tax: propertyData.tax,
          tenancy_completed_all: propertyData.tenancy_completed_all,
          tenancy_plus_3_months: propertyData.tenancy_plus_3_months,
          total: propertyData.total,
          id: propertyData._id,
        });
      } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
        this.setState({ accessErrorModal: true });
      }
    });
  };

  saveCoverNote = () => {
    const property = this.state;

    if (property.aware_of_details === 1 || property.rent_arrears === 1) {
      this.setState({
        errorMessage: 'Cannot upload, please contact Barclay to discuss.',
        awareerrorModal: true,
        errorType: 'danger',
      });
    } else {
      this.setState({ search_code: '  ' });
      const data = {
        client_id: property.client_id,
        agent_nm: property.agent_nm,
        agent_phone: property.agent_phone,
        agent_address_1: property.agent_address_1,
        agent_address_2: property.agent_address_2,
        agent_address_3: property.agent_address_3,
        agent_suburb: property.agent_suburb,
        agent_state: property.agent_state,
        agent_post_code: property.agent_post_code,
        agent_country: property.agent_country,
        cover_to: this.state.cover_to ? moment(this.state.cover_to).format('YYYY-MM-DD') : '',
        cover_from: this.state.cover_from ? moment(this.state.cover_from).format('YYYY-MM-DD') : '',
        prop_address_1: property.prop_address_1,
        prop_address_2: property.prop_address_2,
        prop_address_3: property.prop_address_3,
        prop_suburb: property.prop_suburb,
        prop_state: property.prop_state,
        prop_post_code: property.prop_post_code,
        prop_country: property.prop_country,
        invoice_type: property.service && property.service.value ? property.service.value : '0',
        status: property.status && property.status.value ? property.status.value : '0',
        tenancy_plus_3_months: property.tenancy_plus_3_months,
        tenancy_completed_all: property.tenancy_completed_all,
        bond_4_weeks: property.bond_4_weeks,
        rent_arrears: property.rent_arrears,
        weekly_rent: property.weekly_rent,
      };

      if (this.validator.allValid()) {
        if (this.state.isEdit && this.state._id) {
          data.coverNote_id = this.state._id;
          this.services.EditCoverNote(data, (res) => {
            if (res.status === 200) {
              this.services.successToast('Cover Note Updated successfully');
              this.props.history.push('/cover-note-list');
            } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
              this.setState({ accessErrorModal: true });
            } else {
              this.services.errorToast(res.message);
            }
            this.setState({ search_code: '' });
          });
        } else {
          this.services.AddCoverNote(data, (res) => {
            if (res.status === 200) {
              this.services.successToast('Cover Note Added successfully');
              this.props.history.push('/cover-note-list');
            } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
              this.setState({ accessErrorModal: true });
            } else {
              this.services.errorToast(res.message);
            }
            this.setState({ search_code: '' });
          });
        }
      } else {
        this.validator.showMessages();
        this.forceUpdate();
      }
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };
  addaware_of_details = () => {
    this.setState({
      awareerrorModal: false,
    });
  };

  changeInput(e) {
    if (
      e.target.name === 'tenancy_plus_3_months' ||
      e.target.name === 'tenancy_completed_all' ||
      e.target.name === 'bond_4_weeks' ||
      e.target.name === 'rent_arrears' ||
      e.target.name === 'aware_of_details'
    ) {
      this.setState({
        [e.target.name]: JSON.parse(e.target.value),
      });
    } else if (e.target.name === 'landlord_invoice') {
      this.setState({
        [e.target.name]: this.state.landlord_invoice === 0 ? 1 : 0,
      });
    } else if (e.target.name === 'price' || e.target.name === 'tax') {
      this.setState({
        [e.target.name]: e.target.value ? e.target.value : 0,
      });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  //_______________________________________________________

  uncheckAll = (id) => {
    this.state.primary_address.forEach((addr) => {
      if (addr.id !== id) {
        addr.postal = false;
      }
    });
  };
  uncheckAllPrimary = (id) => {
    this.state.primary_address.forEach((addr) => {
      if (addr.id !== id) {
        addr.primary = false;
      }
    });
  };
  uncheckAllInvoice = (id) => {
    this.state.Contact_details.forEach((addr) => {
      if (addr.id !== id) {
        addr.invoice = false;
      }
    });
  };
  uncheckAllPrimaryContact = (id) => {
    this.state.Contact_details.forEach((addr) => {
      if (addr.id !== id) {
        addr.primary = false;
      }
    });
  };

  changeAddress(e, id) {
    if (id) {
      const self = this;
      const data = this.state.primary_address.map(function (item) {
        if (item.id === id && e.target.name) {
          if (e.target.name === 'postal' || e.target.name === 'primary') {
            if (e.target.name === 'postal') {
              item.postal = e.target.checked;
              self.uncheckAll(id);
            }
            if (e.target.name === 'primary') {
              item.primary = e.target.checked;
              self.uncheckAllPrimary(id);
            }
          } else {
            item[e.target.name] = e.target.value;
          }
        }
        return item;
      });
      if (e && e.target && e.target.name && id) this.setState({ primary_address: data });
    }
  }

  changeContact(e, id) {
    if (id) {
      const self = this;
      const data = this.state.Contact_details.map(function (item) {
        if (item.id === id && e.label && e.value) {
          item.title = e.value;
        } else {
          if (item.id === id && e.target.name) {
            if (e.target.name === 'primary' || e.target.name === 'invoice') {
              if (e.target.name === 'invoice') {
                item.invoice = e.target.checked;
                self.uncheckAllInvoice(id);
              }
              if (e.target.name === 'primary') {
                item.primary = e.target.checked;
                self.uncheckAllPrimaryContact(id);
              }
            } else {
              item[e.target.name] = e.target.value;
            }
          }
        }
        return item;
      });
      if ((e && e.target && e.target.name && id) || (e.label && e.value))
        this.setState({ Contact_details: data });
    }
  }

  //_____________________________________________________________________________

  handleChange = (selectedOption, name) => {
    this.setState({ [name]: selectedOption });
  };

  cancle = () => {
    this.props.history.push('/contact-list');
  };

  handleChangeToDate = (date) => {
    this.setState({
      cover_to: new Date(date),
    });
  };

  handleChangeFromDate = (date) => {
    this.setState({
      cover_from: new Date(date),
    });
  };

  handleSelectGeoSuggest = (place, id) => {
    if (!place) return null;

    const componentForm = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      administrative_area_level_1: 'long_name',
      country: 'long_name',
      postal_code: 'short_name',
    };
    for (var i = 0; i < place.gmaps.address_components.length; i++) {
      var addressType = place.gmaps.address_components[i].types[0];
      if (componentForm[addressType]) {
        var val = place.gmaps.address_components[i][componentForm[addressType]];
        if (val) componentForm[addressType] = val;
      }
    }

    if (id === 'Agent') {
      this.setState({
        agent_address_1: place.label,
        agent_address_2:
          componentForm.street_number !== 'short_name' ? componentForm.street_number : '',
        agent_address_3: componentForm.route !== 'long_name' ? componentForm.route : '',
        agent_suburb: componentForm.locality !== 'long_name' ? componentForm.locality : '',
        agent_state:
          componentForm.administrative_area_level_1 !== 'long_name'
            ? componentForm.administrative_area_level_1
            : '',
        agent_country: componentForm.country !== 'long_name' ? componentForm.country : '',
        agent_post_code:
          componentForm.postal_code !== 'short_name' ? componentForm.postal_code : '',
      });
    }
    if (id === 'Props') {
      this.setState({
        prop_address_1: place.label,
        prop_address_2:
          componentForm.street_number !== 'short_name' ? componentForm.street_number : '',
        prop_address_3: componentForm.route !== 'long_name' ? componentForm.route : '',
        prop_suburb: componentForm.locality !== 'long_name' ? componentForm.locality : '',
        prop_state:
          componentForm.administrative_area_level_1 !== 'long_name'
            ? componentForm.administrative_area_level_1
            : '',
        prop_country: componentForm.country !== 'long_name' ? componentForm.country : '',
        prop_post_code: componentForm.postal_code !== 'short_name' ? componentForm.postal_code : '',
      });
    }
  };

  findAgent = () => {
    const data = {
      post_code: this.state.search_code.trim(),
    };
    this.services.getNoteAgent(data, (res) => {
      if (res.status === 200) {
        this.setState({ agentList: res.data.data.list });
      } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
        this.setState({ accessErrorModal: true });
      } else {
        this.services.errorToast(res.message);
      }
    });
  };
  SelectAgent = (data) => {
    if (data) {
      this.setState({
        agent_nm: data.name,
        agent_address_1: data.address ? data.address[0].address_1 : '',
        agent_address_2: data.address ? data.address[0].address_2 : '',
        agent_address_3: data.address ? data.address[0].address_3 : '',
        agent_suburb: data.address ? data.address[0].suburb : '',
        agent_state: data.address ? data.address[0].state : '',
        agent_post_code: data.address ? data.address[0].post_code : '',
        agent_country: data.address ? data.address[0].country : '',
      });
    }
  };
  clearAgentSearch = () => {
    this.setState({
      isShowSearch: false,
      search_code: '',
      agentList: [],
    });
  };

  render() {
    return (
      <>
        <div className="container">
          {this.state.awareerrorModal ? (
            <ModalComponent
              modal={this.state.awareerrorModal}
              color={this.state.errorType}
              isCancel={true}
              message={this.state.errorMessage}
              onConfirm={(e) => this.addaware_of_details(e)}
              onClose={() => this.setState({ awareerrorModal: false })}
            />
          ) : null}

          <div className="w-100">
            <div id="contacts_accordion" className="add_new_propery form">
              <div className="card mb-4">
                <div>
                  <Col md={12}>
                    <h3 className="page-title">
                      {this.state.isEdit ? 'Update Cover Note' : 'Add Cover Note'}
                    </h3>
                  </Col>
                </div>
                <div
                  id="collapseOne"
                  className="collapse show mb-4"
                  data-parent="#contacts_accordion"
                >
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 col-md-3">
                        <div className="form-group">
                          <label className="form__form-group-label">
                            Name of Managing Agent<span style={{ color: '#ff0000' }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="custom-input"
                            value={this.state.agent_nm}
                            name="agent_nm"
                            onChange={this.changeInput}
                          />
                          <span className="form__form-group-error">
                            {this.validator.message('Agent Name', this.state.agent_nm, 'required')}
                          </span>
                        </div>
                      </div>

                      <div className="col-12 col-md-3">
                        <div className="form-group">
                          <label className="form__form-group-label">
                            Phone<span style={{ color: '#ff0000' }}>*</span>
                          </label>
                          <input
                            type="number"
                            className="custom-input"
                            value={this.state.agent_phone}
                            name="agent_phone"
                            onChange={this.changeInput}
                          />
                          <span className="form__form-group-error">
                            {this.validator.message('Phone', this.state.agent_phone, 'required')}
                          </span>
                        </div>
                      </div>

                      <div className="col-12 col-md-3">
                        <div className="form-group">
                          {!this.state.isShowSearch && (
                            <div className=" w-100 d-flex justify-content-end top-24 mt-4">
                              <Button
                                className="btn-group--icons mb-0"
                                onClick={() => this.setState({ isShowSearch: true })}
                              >
                                Find Agent
                              </Button>
                            </div>
                          )}
                          {this.state.isShowSearch && (
                            <div className=" w-100 d-flex justify-content-end top-24 mt-4">
                              <Button
                                className="btn-group--icons mb-0"
                                onClick={() => this.clearAgentSearch()}
                              >
                                Close
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {this.state.isShowSearch && (
                      <>
                        <div className="row">
                          <div className="col-12 col-md-9">
                            <label>
                              Enter the post code for the area you're interested in and click the
                              Find Agent button. We will display agents who already manage
                              properties in that postcode.
                            </label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-3">
                            <div className="form-group">
                              <label className="form__form-group-label">
                                Post Code<span style={{ color: '#ff0000' }}>*</span>
                              </label>
                              <input
                                type="text"
                                className="custom-input"
                                value={this.state.search_code}
                                name="search_code"
                                onChange={this.changeInput}
                              />
                              {/* <span className="form__form-group-error">{this.validator.message('Post Code', this.state.search_code, this.state.isShowSearch ? 'required':'string')}</span> */}
                            </div>
                          </div>
                          <div className="col-12 col-md-3">
                            <div className="form-group">
                              <div className=" w-100 d-flex justify-content-end top-24 mt-4">
                                <Button
                                  className="btn-group--icons"
                                  onClick={() => this.findAgent()}
                                >
                                  Find Agent
                                </Button>
                              </div>
                            </div>
                          </div>

                          {/* {
                            this.state.agentList.length === 0 &&  <label className="form__form-group-label">No Data Found.</label>
                        } */}
                        </div>
                      </>
                    )}

                    <div className="row">
                      {this.state.isShowSearch &&
                        this.state.agentList.map((item, i) => {
                          return (
                            <div key={i} className="col-12 col-md-4">
                              <div className="form-group">
                                <label className="form__form-group-label">
                                  <h4>{item.name}</h4>
                                </label>
                              </div>
                              <div className="">
                                <label className="form__form-group-label">
                                  {item.address && item.address[0].address_1}
                                </label>
                              </div>
                              <div className="">
                                <label className="form__form-group-label">
                                  {item.address && item.address[0].post_code}
                                </label>
                              </div>
                              <div className="form-group">
                                <Button
                                  className="btn-group--icons"
                                  onClick={() => this.SelectAgent(item)}
                                >
                                  Select Agent
                                </Button>
                              </div>
                            </div>
                          );
                        })}
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-9">
                        <div className="form-group">
                          <label className="form__form-group-label">
                            Search Address Here<span style={{ color: '#ff0000' }}>*</span>
                          </label>
                          <Geosuggest
                            ref={(el) => (this._geoSuggest = el)}
                            onSuggestSelect={(e) => this.handleSelectGeoSuggest(e, 'Agent')}
                            initialValue={this.state.agent_address_1}
                            country={['AU', 'NZ']}
                          />
                          <span className="form__form-group-error">
                            {this.validator.message(
                              'Agent',
                              this.state.agent_address_1,
                              'required',
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="col-12 col-md-9">
                        <div className="form-group">
                          <label className="form__form-group-label">
                            Address Line 2<span style={{ color: '#ff0000' }} />
                          </label>
                          <input
                            type="text"
                            className="custom-input"
                            value={this.state.agent_address_2}
                            name="agent_address_2"
                            onChange={this.changeInput}
                          />
                          {/* <span className="form__form-group-error">{this.validator.message('Address Line 2', this.state.agent_address_2, 'required')}</span> */}
                        </div>
                      </div>

                      <div className="col-12 col-md-9">
                        <div className="form-group">
                          <label className="form__form-group-label">Address Line 3</label>
                          <input
                            type="text"
                            className="custom-input"
                            value={this.state.agent_address_3}
                            name="agent_address_3"
                            onChange={this.changeInput}
                          />
                          {/* <span className="form__form-group-error">{this.validator.message('Address Line 3', this.state.agent_address_3, 'required')}</span> */}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-3">
                        <div className="form-group">
                          <label className="form__form-group-label">
                            Suburb<span style={{ color: '#ff0000' }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="custom-input"
                            name="agent_suburb"
                            value={this.state.agent_suburb}
                            onChange={this.changeInput}
                          />
                          <span className="form__form-group-error">
                            {this.validator.message('Suburb', this.state.agent_suburb, 'required')}
                          </span>
                        </div>
                      </div>
                      <div className="col-12 col-md-3">
                        <div className="form-group">
                          <label className="form__form-group-label">
                            Post Code<span style={{ color: '#ff0000' }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="custom-input"
                            name="agent_post_code"
                            value={this.state.agent_post_code}
                            onChange={this.changeInput}
                          />
                          <span className="form__form-group-error">
                            {this.validator.message(
                              'Postcode',
                              this.state.agent_post_code,
                              'required',
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="col-12 col-md-3">
                        <div className="form-group">
                          <label className="form__form-group-label">State</label>
                          <input
                            type="text"
                            className="custom-input"
                            name="agent_state"
                            value={this.state.agent_state}
                            onChange={this.changeInput}
                          />
                          {/* <span className="form__form-group-error">{this.validator.message('State', this.state.agent_state, 'required')}</span> */}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-3">
                        <div className="form-group">
                          <label className="form__form-group-label">Country</label>
                          <input
                            type="text"
                            className="custom-input"
                            name="agent_country"
                            value={this.state.agent_country}
                            onChange={this.changeInput}
                          />
                          <span className="form__form-group-error">
                            {this.validator.message(
                              'Country',
                              this.state.agent_country,
                              'required',
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  id="collapseOne"
                  className="collapse show mb-4"
                  data-parent="#contacts_accordion"
                >
                  <div className="card-body">
                    <div className="mb-4 pl-0">
                      <span className="card-link" data-toggle="collapse">
                        Property Details (include Building / Complex name if applicable)
                      </span>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-4">
                        <div className="form-group">
                          <label className="form__form-group-label">
                            Period of Cover From (As of 4PM)
                            <span style={{ color: '#ff0000' }}>*</span>
                          </label>
                          <div className="date-picker date-picker--interval">
                            <DatePicker
                              selected={this.state.cover_from}
                              onChange={(e) => this.handleChangeFromDate(e)}
                              name="cover_from"
                              dropDownMode="select"
                              placeholderText="Select Date"
                            />
                            <span className="date_icon">
                              <img src={'./image/calendar.svg'} alt="date" />
                            </span>
                            <span className="form__form-group-error">
                              {this.validator.message(
                                'Cover From',
                                this.state.cover_from,
                                'required',
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-4">
                        <div className="form-group">
                          <label className="form__form-group-label">
                            Period of Cover To (As of 4PM)
                            <span style={{ color: '#ff0000' }}>*</span>
                          </label>
                          <div className="date-picker date-picker--interval">
                            <DatePicker
                              selected={this.state.cover_to}
                              onChange={(e) => this.handleChangeToDate(e)}
                              name="cover_to"
                              dropDownMode="select"
                              placeholderText="Select Date"
                            />
                            <span className="date_icon">
                              <img src={'./image/calendar.svg'} alt="date" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mb-4 pl-0">
                      <span className="card-link" data-toggle="collapse">
                        Address of Property to be Covered
                      </span>
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-9">
                        <div className="form-group">
                          <label className="form__form-group-label">
                            Search Address Here<span style={{ color: '#ff0000' }}>*</span>
                          </label>
                          <Geosuggest
                            ref={(el) => (this._geoSuggest = el)}
                            onSuggestSelect={(e) => this.handleSelectGeoSuggest(e, 'Props')}
                            initialValue={this.state.prop_address_1}
                            country={['AU', 'NZ']}
                          />
                          <span className="form__form-group-error">
                            {this.validator.message(
                              'Address',
                              this.state.prop_address_1,
                              'required',
                            )}
                          </span>
                        </div>
                      </div>
                      {/* <div className="col-12 col-md-9">
                      <div className="form-group">
                        <label className="form__form-group-label">Address Line 2<span style={{color:"#ff0000"}}></span></label>
                        <input
                          type="text"
                          className="custom-input"
                          value={this.state.prop_address_2}
                          name="prop_address_2"
                          onChange={this.changeInput}
                          disabled={this.state.isView}
                        />
                      </div>
                    </div> */}

                      {/* <div className="col-12 col-md-9">
                      <div className="form-group">
                        <label className="form__form-group-label">Address Line 3<span style={{color:"#ff0000"}}></span></label>
                        <input
                          type="text"
                          className="custom-input"
                          value={this.state.prop_address_3}
                          name="prop_address_3"
                          onChange={this.changeInput}
                          disabled={this.state.isView}
                        />
                      </div>
                    </div> */}
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-3">
                        <div className="form-group">
                          <label className="form__form-group-label">
                            Suburb<span style={{ color: '#ff0000' }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="custom-input"
                            name="prop_suburb"
                            value={this.state.prop_suburb}
                            onChange={this.changeInput}
                          />
                          <span className="form__form-group-error">
                            {this.validator.message('Suburb', this.state.prop_suburb, 'required')}
                          </span>
                        </div>
                      </div>
                      <div className="col-12 col-md-3">
                        <div className="form-group">
                          <label className="form__form-group-label">
                            PostCode<span style={{ color: '#ff0000' }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="custom-input"
                            name="prop_post_code"
                            value={this.state.prop_post_code}
                            onChange={this.changeInput}
                          />
                          <span className="form__form-group-error">
                            {this.validator.message(
                              'Postcode',
                              this.state.prop_post_code,
                              'required',
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="col-12 col-md-3">
                        <div className="form-group">
                          <label className="form__form-group-label">State</label>
                          <input
                            type="text"
                            className="custom-input"
                            name="prop_state"
                            value={this.state.prop_state}
                            onChange={this.changeInput}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-3">
                        <div className="form-group">
                          <label className="form__form-group-label">Country</label>
                          <input
                            type="text"
                            className="custom-input"
                            name="prop_country"
                            value={this.state.prop_country}
                            onChange={this.changeInput}
                          />
                          {/* <span className="form__form-group-error">{this.validator.message('Country', this.state.prop_country, 'required')}</span> */}
                        </div>
                      </div>
                      <div className="col-12 col-md-3">
                        <div className="form-group">
                          <label className="form__form-group-label">
                            What is the weekly amount of rent for the Property? $
                          </label>
                          <input
                            type="number"
                            className="custom-input"
                            name="weekly_rent"
                            value={this.state.weekly_rent}
                            onChange={this.changeInput}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  id="collapseOne"
                  className="collapse show mb-4"
                  data-parent="#contacts_accordion"
                >
                  <div className="card-body">
                    <div className="mb-4 pl-0">
                      <span className="card-link" data-toggle="collapse">
                        Details
                      </span>
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-3">
                        <div className="form-group">
                          <label>
                            Has a residential tenancy agreement for 3 months or more been entered
                            into?
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-3">
                        <div className="form__form-group tools_radio_btn">
                          <div className="form__form-group-field">
                            <label className={`colored-click radio-btn--colored`}>
                              <input
                                className="radio-btn__radio"
                                type="radio"
                                id="tenancy_plus_3_months"
                                name="tenancy_plus_3_months"
                                value={1}
                                checked={this.state.tenancy_plus_3_months === 1}
                                onChange={this.changeInput}
                              />
                              <span className="radio-btn__radio-custom" />
                              <span className="radio-btn__label">Yes</span>
                            </label>
                            <label className={`colored-click radio-btn--colored`}>
                              <input
                                className="radio-btn__radio"
                                type="radio"
                                id="tenancy_plus_3_months"
                                name="tenancy_plus_3_months"
                                value={0}
                                checked={this.state.tenancy_plus_3_months === 0}
                                onChange={this.changeInput}
                              />
                              <span className="radio-btn__radio-custom" />
                              <span className="radio-btn__label">No</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-3">
                        <div className="form-group">
                          <label>
                            Has a residential application form been completed by each audit occupant
                            who will reside at the property
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-3">
                        <div className="form__form-group tools_radio_btn">
                          <div className="form__form-group-field">
                            <label className={`colored-click radio-btn--colored`}>
                              <input
                                className="radio-btn__radio"
                                type="radio"
                                value={1}
                                id="tenancy_completed_all"
                                name="tenancy_completed_all"
                                checked={this.state.tenancy_completed_all === 1}
                                onChange={this.changeInput}
                              />
                              <span className="radio-btn__radio-custom" />
                              <span className="radio-btn__label">Yes</span>
                            </label>
                            <label className={`colored-click radio-btn--colored`}>
                              <input
                                className="radio-btn__radio"
                                type="radio"
                                value={0}
                                id="tenancy_completed_all"
                                name="tenancy_completed_all"
                                checked={this.state.tenancy_completed_all === 0}
                                onChange={this.changeInput}
                              />
                              <span className="radio-btn__radio-custom" />
                              <span className="radio-btn__label">No</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-3">
                        <div className="form-group">
                          <label>
                            Has a bond equivalent to 4 weeks rent been loged with the appropriate
                            state body?
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-3">
                        <div className="form__form-group tools_radio_btn">
                          <div className="form__form-group-field">
                            <label className={`colored-click radio-btn--colored`}>
                              <input
                                className="radio-btn__radio"
                                type="radio"
                                value={1}
                                id="bond_4_weeks"
                                name="bond_4_weeks"
                                checked={this.state.bond_4_weeks === 1}
                                onChange={this.changeInput}
                              />
                              <span className="radio-btn__radio-custom" />
                              <span className="radio-btn__label">Yes</span>
                            </label>
                            <label className={`colored-click radio-btn--colored`}>
                              <input
                                className="radio-btn__radio"
                                type="radio"
                                value={0}
                                id="bond_4_weeks"
                                name="bond_4_weeks"
                                checked={this.state.bond_4_weeks === 0}
                                onChange={this.changeInput}
                              />
                              <span className="radio-btn__radio-custom" />
                              <span className="radio-btn__label">No</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-3">
                        <div className="form-group">
                          <label>
                            Is rent currently in arreas or has rent been in arrears in the last 2
                            months?
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-3">
                        <div className="form__form-group tools_radio_btn">
                          <div className="form__form-group-field">
                            <label className={`colored-click radio-btn--colored`}>
                              <input
                                className="radio-btn__radio"
                                type="radio"
                                value={1}
                                id="rent_arrears"
                                name="rent_arrears"
                                checked={this.state.rent_arrears === 1}
                                onChange={this.changeInput}
                              />
                              <span className="radio-btn__radio-custom" />
                              <span className="radio-btn__label">Yes</span>
                            </label>
                            <label className={`colored-click radio-btn--colored`}>
                              <input
                                className="radio-btn__radio"
                                type="radio"
                                value={0}
                                id="rent_arrears"
                                name="rent_arrears"
                                checked={this.state.rent_arrears === 0}
                                onChange={this.changeInput}
                              />
                              <span className="radio-btn__radio-custom" />
                              <span className="radio-btn__label">No</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-3">
                        <div className="form-group">
                          <label className="form__form-group-label">Invoice Type</label>
                          <Select
                            name="service"
                            type="text"
                            options={serviceOption}
                            classNamePrefix="react-select"
                            className="react-select"
                            value={this.state.service}
                            onChange={(e) => this.handleChange(e, 'service')}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-3">
                        <div className="form-group">
                          <label className="form__form-group-label">Status</label>
                          <Select
                            name="statys"
                            type="text"
                            options={cTypeOption}
                            classNamePrefix="react-select"
                            className="react-select"
                            value={this.state.status}
                            onChange={(e) => this.handleChange(e, 'status')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className=" w-100 d-flex justify-content-end p-4">
                  <Button className="btn-group--icons" onClick={this.saveCoverNote}>
                    {this.state.isEdit ? 'Update Changes' : 'Save'}
                  </Button>
                </div>
              </div>
            </div>
            {this.state.errorModal ? (
              <ModalComponent
                modal={this.state.errorModal}
                color={this.state.errorType}
                isCancel={true}
                message={this.state.errorMessage}
                onConfirm={(e) => this.DeleteStaff(e)}
                onClose={() => this.setState({ errorModal: false })}
              />
            ) : null}
            {this.state.accessErrorModal ? (
              <ModalComponent
                modal={this.state.accessErrorModal}
                color="danger"
                isCancel={false}
                message="You do not have access to this, please contact your manager for assistance"
                onConfirm={(e) => this.accessErrorModalClose(e)}
                onClose={(e) => this.accessErrorModalClose(e)}
              />
            ) : null}
          </div>
        </div>
      </>
    );
  }
}

export default reduxForm({
  form: 'add_staff_form_validation',
})(withRouter(withTranslation('common')(AddCoverNote)));
