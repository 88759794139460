import { REACT_APP_API_URL } from "@src/lib/global-variables"
import axios from "axios"
import { CreateDebtorLegalData, DebtorLegal } from "../types/debtor-legal.type"
import { Wip } from "@containers/Recovery/Wip/types/wip.type"
import { Params } from "@src/types/service.type"
import { DeepPartial } from "react-hook-form"

export class DebtorLegalService {

  async getDebtorListByWipId(wipId: number, params?: Params): Promise<DebtorLegal[]> {
    const res = await axios.get<Wip>(`${REACT_APP_API_URL}/wips/${wipId}`, { params })
    return res.data.debtor_legals;
  }

  async getDebtorLegalById(debtorLegalId: number, params?: Params): Promise<DebtorLegal> {
    const res = await axios.get<DebtorLegal>(`${REACT_APP_API_URL}/debtor-legals/${debtorLegalId}`, { params })
    return res.data;
  }

  async createDebtorLegal(debtorLegal: CreateDebtorLegalData, params?: Params): Promise<DebtorLegal> {
    const res = await axios.post<DebtorLegal>(`${REACT_APP_API_URL}/debtor-legals`, debtorLegal, { params })
    return res.data;
  }

  async updateDebtorLegal(debtorLegalId: number, debtorLegal: DeepPartial<DebtorLegal>, params?: Params): Promise<DebtorLegal> {
    const res = await axios.patch<DebtorLegal>(`${REACT_APP_API_URL}/debtor-legals/${debtorLegalId}`, debtorLegal, { params })
    return res.data;
  }

  async deleteDebtorLegal(debtorLegalId: number, params?: Params): Promise<DebtorLegal> {
    const res = await axios.delete<DebtorLegal>(`${REACT_APP_API_URL}/debtor-legals/${debtorLegalId}`, { params })
    return res.data;
  }
}