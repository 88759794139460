import React, {useContext, useState} from 'react';
const MyContext = React.createContext({notifications: [], setNotificationsHandler: () => {}, clearNotificationsHandler: () => {}} );


export const useNotificationContext=()=> useContext(MyContext)

const NotificationContextProvider = ({children}) => {

  const [notifications, setNotifications] = useState([])
  const setNotificationsHandler=(val)=>{
    console.log(notifications)
      setNotifications((prev)=>([val,...prev]))
    }
  const clearNotificationsHandler=()=>{
    console.log(notifications)
    setNotifications([])
  }
  return (

      <MyContext.Provider value={{notifications, setNotificationsHandler, clearNotificationsHandler}}>
        {children}
      </MyContext.Provider>
  );
};

export default NotificationContextProvider;