import Joi from 'joi'

export const CreateTaskSchema = Joi.object({
  priority: Joi.object({
    label: Joi.string().required(),
    value: Joi.number().required(),
  })
    .required()
    .messages({
      'any.required': 'Priority is required',
    }),
  type: Joi.object({
    value: Joi.number().required(),
    label: Joi.string().required(),
  })
    .when('excluded_from_automation', {
      is: true,
      then: Joi.object({
        value: Joi.number().required(),
        label: Joi.string().required(),
      }),
      otherwise: Joi.object({
        value: Joi.number().required(),
        label: Joi.string().required(),
      }).required(),
    })
    .messages({
      'any.required': 'Type is required.',
    }),
  assign_to: Joi.array()
    .items({
      label: Joi.string().required(),
      value: Joi.number().required(),
      access_level: Joi.string().required(),
    })
    .messages({
      'any.required': 'Assign To is required',
    })
    .required(),
  due_date: Joi.any().allow('', null).required(),
  assign_attributes: Joi.array()
    .items(Joi.number())
    .min(1)
    .required()
    .messages({ 'array.min': 'Required ' }),
  excluded_from_calendar: Joi.boolean().allow(false, ''),
  excluded_from_automation: Joi.boolean(),
  task_template: Joi.object({
    label: Joi.string().required(),
    value: Joi.number().required(),
  })
    .required()
    .messages({
      'any.required': 'Task template is required',
    }),
  title: Joi.string().min(1).required().messages({
    'string.empty': 'Title is required',
    'string.min': 'Title is required',
  }),
  description: Joi.string().required().messages({
    'string.empty': 'Description is required',
  }),
})

export const CreateColumnScheme = Joi.object({
  title: Joi.string().min(1).required().messages({
    'string.empty': 'Column Title is required',
    'string.min': 'Title is required',
  }),
  description: Joi.string().required().messages({
    'string.empty': 'Column Description is required',
  }),
  columnColor: Joi.object().required().messages({
    'object.base': 'Color is required',
  }),
  amount_of_cron_days: Joi.number().required().messages({
    'number.base': 'Amount of days is required',
  }),
  is_cron: Joi.boolean().allow(false, ''),
})
export const CreateColumnSchemeForSales = Joi.object({
  title: Joi.string().min(1).required().messages({
    'string.empty': 'Column Title is required',
    'string.min': 'Title is required',
  }),
  description: Joi.string().required().messages({
    'string.empty': 'Column Description is required',
  }),
  columnColor: Joi.object().required().messages({
    'object.base': 'Color is required',
  }),
  amount_of_cron_days: Joi.number().allow('', null),
  is_cron: Joi.boolean().allow(false, ''),
})

export const TaskSchema = Joi.object({
  note: Joi.string().allow(''),
  status: Joi.number().required(),
})

export const SendEmailSchema = Joi.object({
  to: Joi.array()
    .items(Joi.object({ label: Joi.string().required(), value: Joi.number().required() }))
    .required()
    .messages({
      'object.base': 'To is required',
    }),

  subject: Joi.string().required().messages({
    'string.empty': 'Subject is required',
  }),
  message: Joi.string().required().messages({
    'string.empty': 'Message is required',
  }),
  template: Joi.object({
    label: Joi.string().required(),
    value: Joi.string().required(),
  }).required(),
})

export const SendSmsSchema = Joi.object({
  to: Joi.array()
    .items(Joi.object({ label: Joi.string().required(), value: Joi.number().required() }))
    .required()
    .messages({
      'object.base': 'To is required',
    }),
  message: Joi.string().required().messages({
    'string.empty': 'Message is required',
  }),
  template: Joi.object({ label: Joi.string().required(), value: Joi.string().required() })
    .allow('', null)
    .required(),
})
