import NavigationButton from '@components/navigation-button/navigation-button'
import styles from './email-header.module.scss'
import TrashIcon from '@src/icons/trash'
import ArchiveIcon from '@src/icons/archive'
import { formatRelative } from 'date-fns'
import { FC } from 'react'
import { EmailHeaderProps } from '../../types/view-email-page.type'
import CloackIcon from '@src/icons/cloack'
import RoundedLeftLongArrow from '@src/icons/rounded-left-long-arrow'
import ShareEmailButton from '../buttons/share-email-button/share-email-button.component'
import EmailAttributes from '../email-attributes/email-attributes.component'

const EmailHeader: FC<EmailHeaderProps> = ({ email }) => {
  const { createdDateTime } = email
  return (
    <div className={styles.emailHeaderWrapper}>
      <div className={styles.leftSideHeaderWrapper}>
        <NavigationButton
          className={styles.smallSquareButton}
          icon={RoundedLeftLongArrow}
          isLink
          to="/inbox"
        />
        <div className={styles.separator} />
        <div className={styles.actionsHeaderWrapper}>
          <NavigationButton className={styles.smallSquareButton} icon={CloackIcon} />
          <NavigationButton className={styles.smallSquareButton} icon={ArchiveIcon} />
        </div>
        <div className={styles.separator} />
        <div>{formatRelative(new Date(createdDateTime), new Date())}</div>
      </div>
      <div className={styles.navigationWrapper}>
        <div className={styles.actionsHeaderWrapper}>
          <NavigationButton className={styles.smallSquareButton} icon={TrashIcon} />
          <ShareEmailButton email={email} className={styles.smallSquareButton} />
        </div>
        <EmailAttributes email={email}/>
      </div>
    </div>
  )
}

export default EmailHeader
