import Modal from '@components/modal'
import { FC, useEffect } from 'react'
import {
  CreateClaimDeatailNoteValues,
  CreateClaimDetailNoteModalProps,
} from '../../types/claim-details.type'
import LabelWrapper from '@components/label-wrapper'
import styles from './create-claim-details.module.scss'
import { onlyNumber } from '../../utils/input.utils'
import { useForm } from 'react-hook-form'
import { CREATE_CLAIM_DETAIL_NOTE_DEFAULT_VALUES } from '../../const/claim-details.const'
import classNames from 'classnames'
import { toast } from 'react-toastify'
import axios from 'axios'
import { REACT_APP_API_URL } from '@src/lib/global-variables'
import { useQueryClient } from '@tanstack/react-query'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import { useUserContext } from '@src/context/user-context'
import { joiResolver } from '@hookform/resolvers/joi'
import { createClaimDetailsNoteSchema } from '../../schemas/claim-details.schema'

const CreateClaimDetailNoteModal: FC<CreateClaimDetailNoteModalProps> = ({
  wipId,
  isOpen,
  onClose,
}) => {
  const queryClient = useQueryClient()
  const {user} = useUserContext()
  const { control, handleSubmit, reset } = useForm<CreateClaimDeatailNoteValues>({
    defaultValues: CREATE_CLAIM_DETAIL_NOTE_DEFAULT_VALUES,
    resolver: joiResolver(createClaimDetailsNoteSchema)
  })

  const onSubmit = async (data: CreateClaimDeatailNoteValues) => {
    try {
      const dataToSend = {
        wip_id: wipId,
        staff_created_id: user?.id,
        ...data,
      }
      await axios.post(`${REACT_APP_API_URL}/claim-detail-notes`, dataToSend)
      queryClient.refetchQueries({queryKey: [GLOBAL_QUERIES.CLAIM_DETAILS_NOTES.GET,]})
      toast.success('Note created')
      onClose()
    } catch {
      toast.error('Something went wrong')
    }
  }

  useEffect(() => {
    reset({ total_amount: 0, description: '' })
  }, [isOpen])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      style={{ height: 300, width: 400 }}
    >
      <form className={styles.formWrapper}>
        <LabelWrapper label="Total Amount" control={control} name="total_amount">
          {({ onChange, value }) => (
            <input
              className="react-select"
              onChange={(e) => onlyNumber(e, onChange)}
              value={value}
              type="text"
            />
          )}
        </LabelWrapper>
        <LabelWrapper label="Description" control={control} name="description">
          {({ onChange, value }) => (
            <textarea
              className={classNames('react-select', styles.textarea)}
              onChange={onChange}
              value={value}
            />
          )}
        </LabelWrapper>
      </form>
    </Modal>
  )
}

export default CreateClaimDetailNoteModal
