import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import { Dialog, DialogActions, styled, dialogClasses } from '@mui/material'
import { FC } from 'react'
import styles from './confirmation-dialog.module.scss'

interface ConfirmationDialogProps {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  cancelButtonText?: string
  confirmButtonText?: string
  confirmationButtonStyle?: 'primary' | 'secondary' | 'highlighted' | 'alert'
  title?: string
  children?: React.ReactNode
}

const CustomDialog = styled(Dialog)(({ theme }) => ({
  [`& .${dialogClasses.paper}`]: {
    width: 448,
    padding: 16,
    borderRadius: 8,
  },
}))

const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  isOpen,
  onClose,
  onConfirm,
  cancelButtonText = 'Cancel',
  confirmButtonText = 'Confirm',
  confirmationButtonStyle = 'highlighted',
  title = 'Confirm Dialog',
  children,
}) => {
  return (
    <CustomDialog open={isOpen} onClose={onClose} aria-describedby="alert-dialog-description">
      <Header mb={4}>{title}</Header>
      <div className={styles.dialogContent}>{children}</div>
      <DialogActions>
        <SubmitButton buttonStyle="secondary" onClick={onClose}>
          {cancelButtonText}
        </SubmitButton>
        <SubmitButton buttonStyle={confirmationButtonStyle} onClick={onConfirm}>
          {confirmButtonText}
        </SubmitButton>
      </DialogActions>
    </CustomDialog>
  )
}

export default ConfirmationDialog
