import NavigationButton from '@components/navigation-button/navigation-button'
import styles from './pagination-arrows.module.scss'
import RoundedArrow from '@src/icons/rounded-arrow'
import { FC } from 'react'
import cls from 'classnames'

interface PaginationEvent {
  page: number
  limit: number
  skip: number
}

interface PaginationArrowsProps {
  total: number
  page: number
  limit: number
  onNextClick?: (data: PaginationEvent) => void
  onPrevClick?: (data: PaginationEvent) => void
  onChangePage?: (data: PaginationEvent) => void
}

const PaginationArrows: FC<PaginationArrowsProps> = ({
  total,
  page,
  limit,
  onNextClick,
  onPrevClick,
  onChangePage,
}) => {
  const from = page === 1 ? (total > 0 ? 1 : 0) : (page - 1) * limit
  const to = page * limit > total ? total : page * limit

  const handleNextClick = () => {
    if (page * limit > total) return
    const paginationEventData = { page: page + 1, limit, skip: page * limit }
    onNextClick && onNextClick(paginationEventData)
    onChangePage && onChangePage(paginationEventData)
  }

  const handlePrevClick = () => {
    if (page === 1) return
    const paginationEventData = { page: page - 1, limit, skip: (page - 2) * limit }
    onPrevClick && onPrevClick(paginationEventData)
    onChangePage && onChangePage(paginationEventData)
  }

  return (
    <div className={styles.paginationArrowsWrapper}>
      <NavigationButton
        className={cls(styles.paginatonButton, { [styles.disabled]: page === 1 })}
        icon={RoundedArrow}
        onClick={handlePrevClick}
      />
      <NavigationButton
        className={cls(styles.paginatonButton, {[styles.disabled]:page * limit >= total } )}
        icon={() => <RoundedArrow className={styles.right} />}
        onClick={handleNextClick}
      />
      <span className={styles.paginationInformation}>
        Show
        <span>
          {from}-{to}
        </span>
        of <span>{total}</span>
      </span>
    </div>
  )
}

export default PaginationArrows
