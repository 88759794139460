import EmailSendingModalWithRecipients from '@components/notification-modals/email-sending-modal-with-recipients/email-sending-modal-with-recipients.component'
import { useTaskContext } from '@src/context/task-context'
import { FC, useMemo } from 'react'
import { Debtor, TaskProps } from '../../../types'
import { Company } from '../../../../types/contact-list.type'
import { getEmailTypes } from '../../../../../../../Form/RecoveryForm/service.js'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import { toast } from 'react-toastify'
import NotificationModalHeader from '../notification-modal-header/notification-modal-header.component'
import { formatAssigneName, validateEmail } from '../../../utils'
import { useUserContext } from '@src/context/user-context'
import moment from 'moment'
import {
  ATTRIBUTES_SUBJECT_DELIMITER,
  WIP_ATTRIBUTE_KEYWORD,
} from '@containers/Inbox/view-email-page/const/view-email-page.const'
import { encryptXOR } from '@containers/Inbox/view-email-page/components/utils/xor-encryption.utils'
import { REACT_APP_XOR_ENCRYPTION_KEY } from '@src/lib/global-variables'
import { useRefreshEmails } from '@containers/Setting/components/Contacts/Notes/hooks/refresh-emails.hook'
import { NOTE_QUERY } from '@containers/Setting/components/Contacts/Notes/const/note-query.const'

interface RecoverySendMailModalProps {
  isOpen: boolean
  onClose: () => void
  task?: TaskProps
}

const RecoverySendMailModal: FC<RecoverySendMailModalProps> = ({ isOpen, onClose, task }) => {
  const { mutateAsync } = useRefreshEmails()
  const { user } = useUserContext()
  const fetchEmailTemplate = async () => {
    const response = await getEmailTypes()
    const res = await response.json()
    const formattedTemplates =
      res?.data?.map((template) => ({ label: template?.title, value: template?.body })) || []

    return formattedTemplates
  }

  const {
    data: emailTemplates,
    isLoading,
    isRefetching,
  } = useQuery({
    queryKey: [GLOBAL_QUERIES.EMAIL_TEMPLATE.GET],
    queryFn: fetchEmailTemplate,
    enabled: isOpen,
  })

  const queryClient = useQueryClient()

  const { currentFoundWip } = useTaskContext()

  const transformDebtorsToOptions = (debtors: Debtor[]) => {
    const filteredDebtors = task
      ? debtors.filter((debtor) =>
          task.assigned_debtors.find((assignedDebtor) => Number(assignedDebtor) === debtor.id),
        )
      : debtors

    return filteredDebtors
      .map((debtor) => {
        const debtorEmails = (debtor.contact.comms_email || []).filter(
          (email) => email && validateEmail(email),
        )

        return debtorEmails.map((email, index) => ({
          label: `${debtor.name_first} ${debtor.name_last} ${
            debtorEmails.length > 1 ? `(${index + 1})` : ''
          }`,
          value: {
            id: debtor.id,
            value: email,
          },
        }))
      })
      .flat()
  }

  const transformClientContactsToOptions = (company: Company) => {
    const contacts = (company.contacts || []).filter(
      (contact) => contact.comm_email && validateEmail(contact.comm_email),
    )

    return contacts.map((contact) => ({
      label: `${contact.first_name} ${contact.last_name}`,
      value: {
        id: company.id,
        value: contact.comm_email,
      },
    }))
  }

  const onSuccessfulSend = async () => {
    toast.success('Email sent successfully')
    onClose()
    try {
      await mutateAsync({ wip_id: currentFoundWip?.id })
      Promise.all([
        queryClient.invalidateQueries([GLOBAL_QUERIES.MAIL_FOLDER.GET]),
        queryClient.invalidateQueries([GLOBAL_QUERIES.GRAPH_API_MESSAGES.GET_BY_FOLDER_ID]),
        queryClient.invalidateQueries([NOTE_QUERY.EMAIL.GET]),
        queryClient.invalidateQueries([GLOBAL_QUERIES.COMBINED_NOTES.GET]),
      ])
    } catch (e) {
      console.error(e)
    }
  }

  const debtorEmailsOptions = useMemo(
    () => transformDebtorsToOptions(currentFoundWip?.debtors || []),
    [currentFoundWip],
  )

  const clientEmailsOptions = useMemo(
    () => transformClientContactsToOptions(currentFoundWip?.company || {}),
    [currentFoundWip],
  )

  return (
    <EmailSendingModalWithRecipients
      isOpen={isOpen && (!isLoading || !isRefetching)}
      onClose={onClose}
      debtorOptions={debtorEmailsOptions}
      clientOptions={clientEmailsOptions}
      templateOptions={emailTemplates}
      excludeFromTo={['staff']}
      onSuccessfulSend={onSuccessfulSend}
      additionalValuesInRequest={{ wip_id: currentFoundWip?.id, task_id: task?.id }}
      tagToSubject={`${ATTRIBUTES_SUBJECT_DELIMITER} ${WIP_ATTRIBUTE_KEYWORD}:${encryptXOR(
        currentFoundWip?.id,
        REACT_APP_XOR_ENCRYPTION_KEY,
      )}`}
      replacementValues={{
        client_name: currentFoundWip?.company?.name || '',
        client_address: `${currentFoundWip?.company?.address[0]?.address_3} ${currentFoundWip?.company?.address[0]?.address_2}`,
        wip_prop: currentFoundWip?.id,
        staff_name: formatAssigneName({
          first_name: user?.first_name || '',
          last_name: user?.last_name || '',
          isAssigned: false,
        }),
        date_long: moment(new Date()).format('dddd DD MMMM YYYY'),
        staff_title: user?.position || '',
        comb_name_amp: 'John Doe',
      }}
    >
      {task ? <NotificationModalHeader task={task} /> : null}
    </EmailSendingModalWithRecipients>
  )
}

export default RecoverySendMailModal
