import { Grid, Modal, Typography } from '@mui/material';
import React from 'react';
import AppButton from '../AppButton/AppButton';

import closeIcon from '../../../assets/image/form-images/close-icon.png';

import { confirmationModalStyles } from './styles';

export const ConfirmationModal = ({
  open,
  title = '',
  description = '',
  handleClose,
  handleConfirm,
}) => {
  const { container, header, body, heading, subheading, footer } = confirmationModalStyles;

  return (
    <Modal open={open} onClose={handleClose}>
      <Grid sx={container}>
        <Grid>
          <Grid sx={header}>
            <AppButton icon={closeIcon} buttonStyle="transparent" onClick={handleClose} />
          </Grid>
          <Grid sx={body}>
            <Typography sx={heading}>{title}</Typography>
            <Typography sx={subheading}>{description}</Typography>
          </Grid>
        </Grid>
        <Grid sx={footer}>
          <AppButton content="Cancel" buttonStyle="secondary" onClick={handleClose} />
          <AppButton content="Confirm" buttonStyle="primary" onClick={handleConfirm} />
        </Grid>
      </Grid>
    </Modal>
  );
};
