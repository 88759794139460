import FormSelect from '@components/inputs/form-select/form-select.component'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { useForm, useWatch } from 'react-hook-form'
import styles from './interest-rate-calculator-form.module.scss'
import {
  InterestRateCalculatorFormProps,
  InterestRateCalculatorFormValues,
  InterestRateCalculatorType,
} from '../../../types/interest-rate.type'
import { CALCULATOR_TYPE_OPTIONS } from '../../../const/interest-rate.const'
import { FC } from 'react'
import EditCourtInterestRateCalculatorForm from './court-interest-rate-calculator/edit-court-interest-rate-calculator-form.component'
import CreateCourtInterestRateCalculatorForm from './court-interest-rate-calculator/create-court-interest-rate-calculator-form.component'
import CreateGeneralInterestRateCalculatorForm from './general-interest-rate-calculator/create-general-interest-rate-calculator.component'
import EditGeneralInterestRateCalculatorForm from './general-interest-rate-calculator/edit-general-interest-rate-calculator.component'

const InterestRateCalculatorForm: FC<InterestRateCalculatorFormProps> = ({
  wipId,
  state,
  courtInterestCalculator,
  generalInterestCalculator,
  courtInterestRatesOptions = [],
  onCourtInterestRateCalculatorSubmit,
  onGeneralInterestRateCalculatorSubmit,
  onCancelClick,
}) => {
  const { control, setValue } = useForm<InterestRateCalculatorFormValues>()
  const calculatorType = useWatch({ control, name: 'calculateType' })

  const handleCalculateTypeChange = (type: InterestRateCalculatorType) => {
    setValue(
      'calculateType',
      CALCULATOR_TYPE_OPTIONS.find((option) => option.value === type) || null,
    )
  }

  const renderCourtInterestRateCalculatorForm = () => {
    if (courtInterestCalculator) {
      return (
        <EditCourtInterestRateCalculatorForm
          courtInterestRatesOptions={courtInterestRatesOptions}
          state={state}
          entity={courtInterestCalculator}
          onCalculateTypeChange={handleCalculateTypeChange}
          onCancelClick={onCancelClick}
          onSuccessSubmit={onCourtInterestRateCalculatorSubmit}
        />
      )
    }

    return (
      <CreateCourtInterestRateCalculatorForm
        courtInterestRatesOptions={courtInterestRatesOptions}
        wipId={wipId}
        state={state}
        onCancelClick={onCancelClick}
        onCalculateTypeChange={handleCalculateTypeChange}
        onSuccessSubmit={onCourtInterestRateCalculatorSubmit}
      />
    )
  }

  const renderGeneralInterestRateCalculatorForm = () => {
    if (generalInterestCalculator) {
      return (
        <EditGeneralInterestRateCalculatorForm
          entity={generalInterestCalculator}
          onCancelClick={onCancelClick}
          onCalculateTypeChange={handleCalculateTypeChange}
          onSuccessSubmit={onGeneralInterestRateCalculatorSubmit}
        />
      )
    }

    return (
      <CreateGeneralInterestRateCalculatorForm
        wipId={wipId}
        onCancelClick={onCancelClick}
        onCalculateTypeChange={handleCalculateTypeChange}
        onSuccessSubmit={onGeneralInterestRateCalculatorSubmit}
      />
    )
  }

  return (
    <>
      {!calculatorType ? (
        <form className={styles.formWrapper}>
          <FormSelect
            control={control}
            name="calculateType"
            label="Calculate Type"
            options={CALCULATOR_TYPE_OPTIONS}
            style="outlined"
          />
          <div className={styles.actionWrapper}>
            <SubmitButton buttonStyle="secondary" buttonType="button" onClick={onCancelClick}>
              Cancel
            </SubmitButton>
            <SubmitButton disabled>Save Rate</SubmitButton>
          </div>
        </form>
      ) : null}
      {calculatorType?.value === InterestRateCalculatorType.CourtInterest
        ? renderCourtInterestRateCalculatorForm()
        : null}
      {calculatorType?.value === InterestRateCalculatorType.GeneralInterest
        ? renderGeneralInterestRateCalculatorForm()
        : null}
    </>
  )
}

export default InterestRateCalculatorForm
