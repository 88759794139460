export const INVOICE_TEMPLATE_TABLE_HEAD = [
  {
    id: 'creation_time',
    numeric: false,
    disablePadding: true,
    label: 'Creation time',
    sortable: true,
  },
  {
    id: 'title',
    numeric: false,
    disablePadding: false,
    label: 'Title',
    sortable: false,
  },
  {
    id: 'subject',
    numeric: false,
    disablePadding: false,
    label: 'Subject',
    sortable: false,
  },
  {
    id: 'test',
    numeric: false,
    disablePadding: false,
  },
];
