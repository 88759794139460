import { ShortMessageService } from "@containers/Communication/services/short-message.service";
import { SendShortMessageData } from "@containers/Communication/types/sms.type"
import { useMutation } from "@tanstack/react-query";

export const useSendShortMessageInBulkMutation = () => {
  const sendShortMessage = async (data: SendShortMessageData[]) => {
    const shortMessageService = new ShortMessageService();
    return shortMessageService.sendShortMessages(data);
  }

  const mutation = useMutation(sendShortMessage);

  return mutation;
}

