import React from 'react';

import { Grid, Typography } from '@mui/material';
import FormInput from '../../../../../shared/components/FormInput/FormInput';

import { commercialUncoveredFormStyles } from '../../styles';

const PropertyAddress = ({ handleChange, values, errors, touched }) => (
  <Grid item sx={commercialUncoveredFormStyles?.collectionContainer}>
    <Typography sx={commercialUncoveredFormStyles?.collectionTitle}>Property Address</Typography>

    <FormInput
      changeHandler={handleChange}
      label="Property address"
      name="property_address.property_address"
      placeholder="Property address"
      type="text"
      value={values?.property_address?.property_address}
      required={true}
      error={errors?.property_address?.property_address}
      touched={touched?.property_address?.property_address}
    />

    <FormInput
      changeHandler={handleChange}
      label="Property suburb"
      name="property_address.property_sub_urb"
      placeholder="Property suburb"
      type="text"
      value={values?.property_address?.property_sub_urb}
      required={true}
      error={errors?.property_address?.property_sub_urb}
      touched={touched?.property_address?.property_sub_urb}
    />

    <FormInput
      changeHandler={handleChange}
      label="Property state"
      name="property_address.property_state"
      placeholder="Property state"
      type="text"
      value={values?.property_address?.property_state}
    />

    <FormInput
      changeHandler={handleChange}
      label="Post code"
      name="property_address.post_code"
      placeholder="Post code"
      type="text"
      value={values?.property_address?.post_code}
    />
  </Grid>
);

export default PropertyAddress;
