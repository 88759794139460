import Section from '@components/section/section.component'
import SubSection from '@containers/Recovery/Wip/components/UI/sub-section/sub-section.component'
import { Header, SubHeader, Text } from '@containers/Recovery/Wip/components/UI/text/text.component'
import { FC } from 'react'
import { ClientInfoSectionProps } from '../../../types/sections-props.type'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'

const ClientInfoSection: FC<ClientInfoSectionProps> = ({ client }) => {
  const contacts = client.contacts || []
  return (
    <Section>
      <SubSection>
        <Header mb={4}>{client.name}</Header>
      </SubSection>
      <SubSection mb={!contacts.length ? 4 : undefined} p={2}>
        <SubHeader>Client Status</SubHeader>
        <Text>Current</Text>
      </SubSection>
      {contacts.map((contact, index) => (
        <SubSection
          key={`client-contact-${index}-${contact.first_name}`}
          mb={index === contacts.length - 1 ? 4 : undefined}
          p={2}
        >
          <SubHeader>Contact Name</SubHeader>
          <Text mb={2}>{`${contact.first_name} ${contact.last_name}`}</Text>
          <SubHeader>Contact Email</SubHeader>
          <Text>{`${contact.comm_email}`}</Text>
        </SubSection>
      ))} 
      <SubmitButton type="link" url={`/contacts/${client.id}`}>
        View Client
      </SubmitButton>
    </Section>
  )
}

export default ClientInfoSection
