import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';

import './styles.scss';

const Loader = ({ color = 'inherit' }) => (
  <div className="continer">
    <CircularProgress color={color} />
  </div>
);

export default Loader;
