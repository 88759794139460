import EmailBody from './components/email-body/email-body.component'
import EmailFooter from './components/email-footer/email-footer.component'
import EmailHeader from './components/email-header/email-header.component'
import styles from './view-email-page.module.scss'
import { useHistory, useParams } from 'react-router'
import { useGetMessageById } from '../hooks/get-message-by-id.hook'
import ViewEmailPageSkeleton from './components/view-email-page-skeleton/view-email-page-skeleton.component'
import { useEffect } from 'react'
import { useUpdateMessageByIdMutation } from '../hooks/mutations/update-message-by-id.hook'
import { useQueryClient } from '@tanstack/react-query'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'

const ViewEmailPage = () => {
  const history = useHistory()
  const queryClient = useQueryClient()
  const { id } = useParams<{ id: string }>()
  const { data: email, isLoading, isError } = useGetMessageById(id)
  const mutation = useUpdateMessageByIdMutation()

  const markEmailAsRead = async (emailId: string) => {
    try {
      await mutation.mutateAsync({ id: emailId, body: { isRead: true } })
      queryClient.invalidateQueries({
        queryKey: [GLOBAL_QUERIES.MAIL_FOLDER.GET],
      })
      queryClient.invalidateQueries({
        queryKey: [GLOBAL_QUERIES.GRAPH_API_MESSAGES.GET_BY_FOLDER_ID],
      })
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (!email || email.isRead) return
    markEmailAsRead(email.id)
  }, [email])

  useEffect(() => {
    if(!isError) return
    history.push('/inbox')
  }, [isError])

  const isEmailLoading = isLoading
  const isEmailFound = email && !isEmailLoading

  return isEmailFound ? (
    <div className={styles.viewEmailWrapper}>
      <EmailHeader email={email} />
      <EmailBody email={email} />
      <EmailFooter email={email} />
    </div>
  ) : (
    <ViewEmailPageSkeleton />
  )
}

export default ViewEmailPage
