import { FC } from 'react'
import styles from './sub-task-indicator.module.scss'
import cls from 'classnames'

export type SubTaskIndicatorStatus = 'notCompleted' | 'partiallyCompleted' | 'fullyCompleted'

interface SubTaskIndicatorProps {
  status?: SubTaskIndicatorStatus
}

const SubTaskIndicator: FC<SubTaskIndicatorProps> = ({ status = 'notCompleted' }) => {
  return (
    <div
      className={cls(styles.subTaskIndicatorWrapper, {
        [styles.notCompleted]: status === 'notCompleted',
        [styles.partiallyCompleted]: status === 'partiallyCompleted',
        [styles.fullyCompleted]: status === 'fullyCompleted',
      })}
    >
      <div
        className={cls(styles.subTaskIndicatorDot, {
          [styles.notCompleted]: status === 'notCompleted',
          [styles.partiallyCompleted]: status === 'partiallyCompleted',
          [styles.fullyCompleted]: status === 'fullyCompleted',
        })}
      ></div>
    </div>
  )
}

export default SubTaskIndicator
