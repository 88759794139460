import cls from 'classnames'
import styles from './assign-avatar.module.scss'
import { GLOBAL_USER_POSITION } from '@src/utils'
interface IAssignAvatarProps {
  name: string
  avatarUrl: string
  accessLevel: string
}
const COLOR_MAP_AVATAR = {
  [GLOBAL_USER_POSITION[0]?.value]: styles['avatar--blue'],
  [GLOBAL_USER_POSITION[1]?.value]: styles['avatar--red'],
  [GLOBAL_USER_POSITION[2]?.value]: styles['avatar--green'],
  [GLOBAL_USER_POSITION[3]?.value]: styles['avatar--orange'],
  [GLOBAL_USER_POSITION[4]?.value]: styles['avatar--purple'],
}
const AssignAvatar = ({ avatarUrl, name, accessLevel }: IAssignAvatarProps) => {
  const nameAbbr =
    name
      ?.split(' ')
      ?.map((item) => item[0])
      ?.join('') || 'NA'

  return (
    <div data-tooltip={name} className={cls(styles.wrapper, COLOR_MAP_AVATAR[accessLevel])}>
      {!avatarUrl && <span>{nameAbbr}</span>}
      {avatarUrl && <img className={styles.img} src={avatarUrl} alt={name} />}
    </div>
  )
}

export default AssignAvatar
