import { useQuery } from "@tanstack/react-query";
import { DebtorLegalService } from "../services/debtor-legal.service";
import { Params } from "@src/types/service.type";
import { getWipLegalListQueryKey } from "../utils/legal-query-key-factory.utils";

export const useGetDebtorLegalListByWipId = (wipId: number, params?: Params) => {

  const getDebtorLegalListByDebtorIdArray = async (wipId: number, params?: Params) => {
    const debtorLegalService = new DebtorLegalService();
    return await debtorLegalService.getDebtorListByWipId(wipId, params);
  }

  const query = useQuery({
    queryKey: getWipLegalListQueryKey(wipId),
    queryFn: () => getDebtorLegalListByDebtorIdArray(wipId, params),
    staleTime: 1000 * 60 * 5 // 5 minutes,
  })

  return query;
}