import React from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { deleteInvoiceByIDSelector } from '../../../../../../redux/selector/selector';
import { useDispatch, useSelector } from 'react-redux';
import { deleteInvoiceById } from '../../../../../../redux/actions';

import { Grid, Modal, Typography } from '@mui/material';
import AppButton from '../../../../../../shared/components/AppButton/AppButton';

import closeIcon from '../../../../../../assets/image/form-images/close-icon.png';
import { voidModalStyles } from './styles.js';

const VoidModal = ({ open, setOpen, isEditing, invoiceID }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading } = useSelector(deleteInvoiceByIDSelector);

  const handleClose = () => {
    setOpen(false);
  };

  const voidhandler = () => {
    console.log(isEditing, invoiceID);
    if (isEditing && invoiceID) {
      dispatch(deleteInvoiceById(invoiceID))
        .then(() => {
          history.push('/invoice-list');
        })
        .catch(() => {
          toast.error('Something went wrong!');
        });
    } else {
      history.push('/invoice-list');
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid sx={voidModalStyles.container}>
        <Grid sx={voidModalStyles.closeButton} onClick={handleClose}>
          <AppButton icon={closeIcon} buttonStyle="transparent" onClick={handleClose} />
        </Grid>

        <Typography sx={voidModalStyles.title}>Void Invoice</Typography>

        <Grid sx={voidModalStyles.subtitleContainer}>
          <Typography sx={voidModalStyles.subtitle}>
            You are about to Void an invoice. Are you sure you want to do this?
          </Typography>

          <Typography sx={voidModalStyles.subtitle}>
            A voided invoice cannot be re-instated.
          </Typography>

          <Typography sx={voidModalStyles.subtitle}>
            A new invoice will be drawn with a new invoice #.
          </Typography>
        </Grid>

        <Grid sx={voidModalStyles.buttonsContainer}>
          <AppButton content="Go Back" buttonStyle="secondary" onClick={handleClose} />
          <AppButton
            content="Yep, VOID"
            buttonStyle="error"
            onClick={voidhandler}
            loading={loading}
          />
        </Grid>
      </Grid>
    </Modal>
  );
};

export default VoidModal;
