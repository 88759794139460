import React, { Fragment } from 'react';
import { Button, Col } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import SimpleReactValidator from 'simple-react-validator';
import moment from 'moment';
import Geosuggest from 'react-geosuggest';
import DebtorService from '../../../../../../services/DebtorService';
import Services from '../../../../../../shared/Services';


class AddressHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      wipID: localStorage.getItem('debtorId') || '',
      errorModal: false,
      errorMessage: '',
      errorType: '',
      address_hist: [
        {
          id: Math.floor(Math.random() * (1 + 100)) + 1,
          address_1: null,
          address_2: null,
          address_3: null,
          suburb: null,
          state: null,
          postcode: null,
          country: null,
          history_start_date: null,
          history_end_date: null,
          history_rank: null,
          time_created: null,
        },
      ],
    };

    this.debtor = new DebtorService();
    this.services = new Services();
    this.validator = new SimpleReactValidator();
    this.validatorDE = new SimpleReactValidator();
    this.validatorAddr = new SimpleReactValidator();
  }

  componentDidMount() {
      this.setDebtor(this.props.dataDebtor);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.dataDebtor !== this.props.dataDebtor) {
      this.setDebtor(this.props.dataDebtor);
    }
  }


  setDebtor(data) {
    if (data) {
      const getData = data.data;
      if (getData) {
        // eslint-disable-next-line array-callback-return
        getData.addresses && getData.addresses.map(value => {
          value.edit = true;
        });
        const sortRank = getData.addresses.sort((a, b) => a.history_rank - b.history_rank);
        const addFiled = sortRank.map(element => ({ ...element, openTab: false }));
        this.setState({
          allData: getData,
          address_hist: addFiled && addFiled.length > 0 ? addFiled : this.state.address_hist,
        });
      }
    }
  }

  dateFormat(dateNew) {
    let fDate;
    if (dateNew && dateNew !== '0000-00-00') {
      if (dateNew) {
        const date = moment(dateNew, 'YYYY-MM-DDTHH:mm:ss.sssZ').format('YYYY-MM-DDTHH:mm:ss.sssZ');
        fDate = '';
        if (date === 'Invalid date') {
          fDate = new Date(dateNew.slice(0, dateNew.length - 2).replace(/\//g, '-'));
        } else {
          fDate = new Date(date);
        }
      } else {
        return '';
      }
      if (fDate === 'Invalid Date' || fDate === 'Invalid date') {
        return '';
      } else {
        return fDate;
      }
    }
  }

  changeInputInner(e, name, id) {
    if (!e.target.validity.valid) {
      return null;
    }
    if (name === 'Address') {
      // eslint-disable-next-line array-callback-return
      this.state.address_hist.map(item => {
        if (item.id === id) {
          item[e.target.name] = e.target.value;
        }
      });
    }
    if (name === 'Relations') {
      // eslint-disable-next-line array-callback-return
      this.state.relations.map(item => {
        if (item.id === id) {
          item[e.target.name] = e.target.value;
        }
      });
    }
    if (name === 'Reference') {
      // eslint-disable-next-line array-callback-return
      this.state.references.map(item => {
        if (item.id === id) {
          item[e.target.name] = e.target.value;
        }
      });
    }
    if (name === 'Other') {
      // eslint-disable-next-line array-callback-return
      this.state.other.map(item => {
        if (item.id === id) {
          item[e.target.name] = e.target.value;
        }
      });
    }
    if (name === 'Work_Place') {
      // eslint-disable-next-line array-callback-return
      this.state.work_place.map(item => {
        if (item.id === id) {
          item[e.target.name] = e.target.value;
        }
      });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
    this.forceUpdate();
  }

  saveDebtor = () => {

    // eslint-disable-next-line array-callback-return
    this.state.address_hist && this.state.address_hist.map(hist => {
      if (hist.address_1) {
        hist.debtor = { id: +this.state.wipID };
        hist.history_start_date = hist.history_start_date ? hist.history_start_date : null;
        hist.history_end_date = hist.history_end_date ? hist.history_end_date : null;
        return hist;
      }
    });

    const newAddress = this.state.address_hist.filter((e) => e.edit === false);
    const editAddress = this.state.address_hist.filter((e) => e.edit === true);

    if (this.validator.allValid()) {
      if (newAddress.length) {
        this.debtor.addDebtorAddress(newAddress).then((res) => {
          if (res[0].status === 201) {
            this.services.successToast('Address added successfully');

          } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
            this.setState({ accessErrorModal: true });
          } else {
            this.services.errorToast(res.message);
          }
        });
      }
      if (editAddress.length) {
        this.debtor.editDebtorAddress(editAddress).then((res) => {
          if (res[0].status === 200) {
            this.services.successToast('Address update successfully');
          } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
            this.setState({ accessErrorModal: true });
          } else {
            this.services.errorToast(res.message);
          }
        });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  changeAddressRank = (e, id) => {
    if (id) {
      // eslint-disable-next-line array-callback-return
      this.state.address_hist.map(function(item) {
        if (item.id === id && e.target.name) {
          item.history_rank = +e.target.value;
        }
      });
      this.forceUpdate();
    }
  };

  handleChangeDataAddress = (date, name, id) => {
    // eslint-disable-next-line array-callback-return
    this.state.address_hist.map(function(item) {
      if (item.id === id && name) {
        item[name] = date;
      }
    });
    this.forceUpdate();
  };

  addAddressList = () => {
    if (this.validatorAddr.allValid()) {
      const data = {
        id: Math.floor(Math.random() * (1 + 100)) + 1,
        address_1: '',
        address_2: '',
        address_3: '',
        suburb: '',
        state: '',
        postcode: '',
        country: '',
        history_start_date: '',
        history_end_date: '',
        history_rank: '',
        edit: false,
        city: 'Riga',
        openTab: true,
      };

      const list = this.state.address_hist || [];
      if (list) {
        list.push(data);
        this.setState({ address_hist: list });
        this.validatorAddr.hideMessages();
      }
    } else {
      this.validatorAddr.showMessages();
      this.forceUpdate();
    }
  };

  removeAddress = (id) => {
    const details = this.state.address_hist.filter((_) => _.id !== id);
    this.setState({
      address_hist: details,
    });
    this.debtor.deleteDebtorAddress(id).then((res) => {
      if (res.status === 200) {
        this.services.successToast('Address delete successfully');
      } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
        this.setState({ accessErrorModal: true });
      } else {
        this.services.errorToast(res.message);
      }
    });
  };

  handleSelectGeoSuggest = (place, id) => {
    if (!place) return null;

    const componentForm = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      administrative_area_level_1: 'long_name',
      country: 'long_name',
      postal_code: 'short_name',
    };
    for (let i = 0; i < place.gmaps.address_components.length; i++) {
      const addressType = place.gmaps.address_components[i].types[0];
      if (componentForm[addressType]) {
        const val = place.gmaps.address_components[i][componentForm[addressType]];
        if (val) componentForm[addressType] = val;
      }
    }

    const add = this.state.address_hist.map(item => {
      if (item.id === id) {
        item.address_1 = place.label || 'Current_Address';
        item.address_2 = componentForm.street_number !== 'short_name' ? componentForm.street_number : '';
        item.address_3 = componentForm.route !== 'long_name' ? componentForm.route : '';
        item.suburb = componentForm.locality !== 'long_name' ? componentForm.locality : '';
        item.state = componentForm.administrative_area_level_1 !== 'long_name' ? componentForm.administrative_area_level_1 : '';
        item.postcode = componentForm.postal_code !== 'short_name' ? componentForm.postal_code : '';
        item.country = componentForm.country !== 'long_name' ? componentForm.country : '';
      }
      return item;
    });

    this.setState({ address_hist: add });
  };

  makeCurrentAddress(id) {
    const addr = this.state.address_hist.find(_ => _.id === id);
    const oldAddr = this.state.address_hist.find(_ => _.is_current === true);
    if (addr && this.state.wipID) {
      const dataCurrent = {
        id: id,
        address_1: addr.address_1,
        address_2: addr.address_2,
        address_3: addr.address_3,
        suburb: addr.suburb,
        state: addr.state.label,
        postcode: addr.postcode,
        country: addr.country.label,
        history_start_date: addr.history_start_date,
        history_end_date: addr.history_end_date,
        history_rank: addr.history_rank,
        is_current: true,
        is_service: false,
        city: 'Riga',
        debtor: { id: +this.state.wipID },
        is_international: addr.is_international,
      };
      const dataOldCurrent = {
        id: oldAddr.id,
        address_1: oldAddr.address_1,
        address_2: oldAddr.address_2,
        address_3: oldAddr.address_3,
        suburb: oldAddr.suburb,
        state: oldAddr.state.label,
        postcode: oldAddr.postcode,
        country: oldAddr.country.label,
        history_start_date: oldAddr.history_start_date,
        history_end_date: oldAddr.history_end_date,
        history_rank: oldAddr.history_rank,
        is_current: false,
        city: 'Riga',
        debtor: { id: +this.state.wipID },
        is_international: oldAddr.is_international,
      };
      if (dataCurrent.is_international || dataOldCurrent.is_international) {
        this.debtor.editDebtorAddress([dataCurrent, dataOldCurrent]).then((res) => {
          if (res) {
            this.services.successToast('Current address update successfully');
          } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
            this.setState({ accessErrorModal: true });
          } else {
            this.services.errorToast(res.message);
          }
        });
      }
      if (dataCurrent.edit === false) {
        this.debtor.addDebtorAddress([dataCurrent]).then((res) => {
          if (res) {
            this.services.successToast('Current address update successfully');
          } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
            this.setState({ accessErrorModal: true });
          } else {
            this.services.errorToast(res.message);
          }
        });
      }
    }
  }

  slideDownSingleAddress = (id) => {
    // eslint-disable-next-line array-callback-return
    this.state.address_hist.map(element => {
        if (element.id === id) {
          element.openTab = !element.openTab;
        }
      },
    );
    this.setState({ itemId: id });
  };

  render() {
    const { address_hist } = this.state;
    return (
      <div className='w-100 pt-3'>
        <div id='contacts_accordion' className='form'>
          <div className='card pb-0'>
            <div id='collapseOne' className='collapse show' data-parent='#contacts_accordion'>
              <div className='slide_down_wrap mb-4 rounded'>
                <Col md={12} lg={12} className='address_history_section'>
                  <div className={'d-flex'}>
                    <div className={'col-8'}><h4>Address</h4></div>
                    <div className={'col-2'}><h4 className={'pl-3'}>Rank Order</h4></div>
                  </div>
                  <hr />
                  {address_hist && address_hist.map((item, i) => {
                    const open = item.openTab;
                    return (
                      <Fragment key={i}>
                        <div className='slide_down_wrap mb-4 rounded'>
                          <div style={{ padding: '13px 30px 13px 30px !important' }}
                               className=' bg-white pl-0 rounded '>
                            <div className={`boxshadow-none slide_drop_down cursor-pointer d-flex flex-wrap align-items-center justify-content-between rounded
                                 ${open ? `active` : ''}`}
                                 onClick={() => this.slideDownSingleAddress(item.id)}
                            >
                              <div className={'col-8'}><h4>{item.address_1}</h4></div>
                              <div className={'col-2'}><h4>{item.history_rank}</h4></div>
                              <span className='show_dropdown'>
                                <button
                                  className='btn-gray mr-4'
                                  onClick={this.removeAddress.bind(this, item.id)}
                                  disabled={item.is_current}
                                >
                                 Remove
                                </button>
                                <svg
                                  className='ml-2'
                                  version='1.1'
                                  id='Capa_1'
                                  x='0px'
                                  y='0px'
                                  width='14px'
                                  height='14px'
                                  viewBox='0 0 284.929 284.929'
                                >
                                    <g>
                                   <path
                                     d='M282.082,76.511l-14.274-14.273c-1.902-1.906-4.093-2.856-6.57-2.856c-2.471,0-4.661,0.95-6.563,2.856L142.466,174.441
		                                  L30.262,62.241c-1.903-1.906-4.093-2.856-6.567-2.856c-2.475,0-4.665,0.95-6.567,2.856L2.856,76.515C0.95,78.417,0,80.607,0,83.082
		                                  c0,2.473,0.953,4.663,2.856,6.565l133.043,133.046c1.902,1.903,4.093,2.854,6.567,2.854s4.661-0.951,6.562-2.854L282.082,89.647
		                                  c1.902-1.903,2.847-4.093,2.847-6.565C284.929,80.607,283.984,78.417,282.082,76.511z'
                                   />
                                    </g>
                                    <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g>
                                    <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g>
                                    <g> </g> <g> </g> <g> </g>
                                    </svg>
                                </span>
                            </div>
                            <div className={`bg-white hide_contact pt-2  ${open ? 'active py-2' : ''}`}>
                              <hr />
                              <div className='row'>
                                <div className='col-12 col-md-6'>
                                  <div className='form-group'>
                                    <label className='form__form-group-label'>Search Address Here</label>
                                    <Geosuggest
                                      ref={el => this._geoSuggest = el}
                                      onSuggestSelect={(e) => this.handleSelectGeoSuggest(e, item.id)}
                                      initialValue={''}
                                      country={['AU', 'NZ']}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className='row'>
                                <div className='col-12 col-md-3'>
                                  <div className='form-group'>
                                    <label className='form__form-group-label'>Address Line 2</label>
                                    <input type='text' className='custom-input'
                                           id='address_2' name='address_2'
                                           value={item.address_2 ?? ''}
                                           onChange={(e) => this.changeInputInner(e, 'Address', item.id)}
                                    />
                                  </div>
                                </div>
                                <div className='col-12 col-md-3'>
                                  <div className='form-group'>
                                    <label className='form__form-group-label'>Address Line 3</label>
                                    <input type='text' className='custom-input'
                                           id='address_3' name='address_3'
                                           value={item.address_3 ?? ''}
                                           onChange={(e) => this.changeInputInner(e, 'Address', item.id)}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className='row'>
                                <div className='col-12 col-md-3'>
                                  <div className='form-group'>
                                    <label className='form__form-group-label'>Suburb</label>
                                    <input type='text' className='custom-input'
                                           id='suburb' name='suburb'
                                           value={item.suburb ?? ''}
                                           onChange={(e) => this.changeInputInner(e, 'Address', item.id)}
                                    />
                                  </div>
                                </div>
                                <div className='col-12 col-md-3'>
                                  <div className='form-group'>
                                    <label className='form__form-group-label'>State</label>
                                    <input type='text' className='custom-input'
                                           id='state' name='state'
                                           value={item.state ?? ''}
                                           onChange={(e) => this.changeInputInner(e, 'Address', item.id)}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className='row'>
                                <div className='col-12 col-md-3'>
                                  <div className='form-group'>
                                    <label className='form__form-group-label'>Post Code</label>
                                    <input type='text' className='custom-input'
                                           id='postcode' name='postcode'
                                           value={item.postcode ?? ''}
                                           onChange={(e) => this.changeInputInner(e, 'Address', item.id)}
                                    />
                                  </div>
                                </div>
                                <div className='col-12 col-md-3'>
                                  <div className='form-group'>
                                    <label className='form__form-group-label'>Country</label>
                                    <input type='text' className='custom-input'
                                           id='country' name='country'
                                           value={item.country ?? ''}
                                           onChange={(e) => this.changeInputInner(e, 'Address', item.id)}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className='row'>
                                <div className='col-12 col-md-6 col-lg-6'>
                                  <div className='form-group'>
                                    <label className='form__form-group-label text-left w-100' htmlFor=''> Start
                                      Date </label>
                                    <div className='date-picker date-picker--interval'>
                                      <DatePicker
                                        selected={item.history_start_date ? this.dateFormat(item.history_start_date) : ''}
                                        onChange={(e) => this.handleChangeDataAddress(e, 'history_start_date', item.id)}
                                        name='address_hist_start'
                                        dateFormat='dd/MM/yy'
                                        dropDownMode='select'
                                        popperPlacement='center'
                                        placeholderText='Select Date'
                                        required={true}
                                      />
                                      <span className='date_icon'>
                                          <img src={'/image/calendar.svg'} alt='' />
                                        </span>
                                    </div>
                                  </div>
                                </div>
                                <div className='col-12 col-md-6 col-lg-6'>
                                  <div className='form-group'>
                                    <label className='form__form-group-label text-left w-100' htmlFor=''> End
                                      Date </label>
                                    <div className='date-picker date-picker--interval'>
                                      <DatePicker
                                        selected={item.history_end_date ? this.dateFormat(item.history_end_date) : ''}
                                        onChange={(e) => this.handleChangeDataAddress(e, 'history_end_date', item.id)}
                                        name='address_hist_end'
                                        dateFormat='dd/MM/yy'
                                        dropDownMode='select'
                                        popperPlacement='center'
                                        placeholderText='Select Date'
                                        required={true}
                                      />
                                      <span className='date_icon'>
                                          <img src={'/image/calendar.svg'} alt='' />
                                        </span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className='row'>
                                <div className='col-12 col-md-6 col-lg-6'>
                                  <div className='row'>
                                    <div className='col-12 col-md-8 col-lg-8'>
                                      <div className='form-group text-left'>
                                        <label className='form__form-group-label' htmlFor=''> Rank </label>
                                        <input type='number'
                                               className='custom-input'
                                               name='history_rank'
                                               value={item.history_rank || ''}
                                               onChange={(e) => this.changeAddressRank(e, item.id)}
                                        />
                                      </div>
                                    </div>
                                    <div className='col-12 col-md-3 col-lg-3 d-flex'>
                                      <div className='form-group'>
                                        <div className='form-group top-24 position-relative'>
                                          {
                                            this.state.wipID && item.address_1 &&
                                            <button
                                              disabled={item.is_current}
                                              className='btn-gray'
                                              onClick={() => this.makeCurrentAddress(item.id)}
                                            >
                                              {item.is_current ? 'Current Address' : 'Set Current'}
                                            </button>
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className='col-12 col-md-6 col-lg-6'>
                                  <div className='row'>
                                    <div className='col-12 d-flex'>
                                      <div className='form-group'>
                                        <div className='form-group top-24 position-relative'>
                                          {i === address_hist.length - 1 && (
                                            <button className='btn-gray mr-3' onClick={() => this.addAddressList()}>
                                              Add Address
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                        <hr />
                      </Fragment>
                    );
                  })
                  }
                </Col>
              </div>
            </div>
          </div>
          <div className='w-100 d-flex justify-content-end mt-5'>
            <Button className='btn-group--icons' onClick={this.saveDebtor}>Save</Button>
            <button className='btn-gray m-0' onClick={() => this.props.history.push('/debtor')}>Cancel</button>
          </div>
        </div>
      </div>
    );
  }
}

export default reduxForm({ form: 'add_staff_form_validation' })
(withRouter(withTranslation('common')(AddressHistory)));
