import { REACT_APP_API_URL } from "@src/lib/global-variables";
import { PaginationResponse, Params } from "@src/types/service.type";
import axios from "axios";
import { Transaction } from "../types/transaction.type";

type TransactionServiceType<T> = PaginationResponse<T> & {total_payments: number}
export class TransactionService {

  async getTransactions(params: Params) {
    const res = await axios.get<TransactionServiceType<Transaction[]>>(`${REACT_APP_API_URL}/transactions`, { params })
    return res.data;
  }
}