import { AUTOMATICALLY_MOVE_EMAIL_TO_BMS_FOLDER_RULE } from "../const/rule.const";
import { CreateGraphApiRule } from "../types/graph-api-mail.type";

export class RuleFactory {
  static moveEmailToBmsFolder(folderId: string, sequence: number): CreateGraphApiRule {
    return {
      ...AUTOMATICALLY_MOVE_EMAIL_TO_BMS_FOLDER_RULE,
      actions: {
        moveToFolder: folderId
      },
      sequence
    }
  }
}