import { FC, useMemo, useState } from 'react'
import { useTaskContext } from '@src/context/task-context'
import GenericTable from '@components/generic-table/generic-table'
import { NOTE_QUERY } from '../../../const/note-query.const'
import NotesService from '../../../../../../../../services/NotesServices'
import { NoteItem } from './note-item.component'
import { NoteListProps, TaskNote } from '../../../types/notes.type'
import NoteFilter from './note-filter.component'
import { useModal } from '@src/hooks/useModal'
import GeneralNoteModal from '@containers/Communication/components/modals/general-note-modal.component'

const notesService = new NotesService()

export const NoteList: FC<NoteListProps> = ({ selectNote }) => {
  const [noteFilter, setNoteFilter] = useState<Record<string, unknown> | null>(null)
  const [selectedNoteId, setSelectedNoteId] = useState<number | null>(null)
  const [isNoteMoldalOpen, openNoteModal, closeNoteModal] = useModal(false)
  const { selectedCompanyState, currentWip, isRecovery } = useTaskContext()

  const params = {
    s: isRecovery ? { wip_id: currentWip } : { company_id: selectedCompanyState?.id },
    join: ['staff_created'],
    sort: ['is_important,DESC', 'created_at,DESC'],
  }

  const handleNoteFilterChange = (data) => {
    console.log('Changed ')
    const query = {
      custom_type: { $in: data.custom_type },
    }

    setNoteFilter(query)
  }

  const handleOpenTaskModal = (id: number) => {
    setSelectedNoteId(id)
    openNoteModal()
  }

  const getNotes = async (pagination) => {
    const { s, ...rest } = params
    const newS = JSON.stringify({ ...s, ...(noteFilter || {}) })
    const response = await notesService.getTaskNotes({
      ...pagination,
      ...rest,
      s: newS,
    })
    return response.data as {
      total: number
      data: TaskNote[]
    }
  }

  const queryKey = useMemo(
    () => [NOTE_QUERY.NOTE.GET, params, noteFilter],
    [selectedCompanyState, noteFilter],
  )

  return (
    <div>
      <h3 className="mb-3">General Notes History</h3>
      <div className="d-flex w-100 justify-content-end">
        <NoteFilter onChange={handleNoteFilterChange} />
      </div>
      <GenericTable
        queryKey={queryKey}
        queryFn={getNotes}
        enabled={!!selectedCompanyState?.id}
        headerOptions={['Date', 'Time', 'Note', 'Staff', 'Action']}
        limit={5}
      >
        {({ data }) => {
          return data.map((note, index) => (
            <NoteItem
              key={`${index}-${note.id}`}
              note={note}
              openViewModal={handleOpenTaskModal}
              selectNote={selectNote}
            />
          ))
        }}
      </GenericTable>
      <GeneralNoteModal id={selectedNoteId} onClose={closeNoteModal} isOpen={isNoteMoldalOpen} />
    </div>
  )
}

export default NoteList
