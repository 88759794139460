import * as React from "react"

const BookIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#1C274D"
      fillRule="evenodd"
      d="M9.945 1.25h4.11c1.368 0 2.47 0 3.337.117.9.12 1.658.38 2.26.981.602.602.86 1.36.982 2.26.116.867.116 1.97.116 3.337v8.11c0 1.367 0 2.47-.116 3.337-.121.9-.38 1.658-.982 2.26-.602.602-1.36.86-2.26.982-.867.116-1.97.116-3.337.116h-4.11c-1.367 0-2.47 0-3.337-.116-.9-.122-1.658-.38-2.26-.982-.601-.602-.86-1.36-.981-2.26a11.487 11.487 0 0 1-.082-.943.746.746 0 0 1-.016-.392 65.809 65.809 0 0 1-.019-2.002v-8.11c0-1.367 0-2.47.117-3.337.12-.9.38-1.658.982-2.26.601-.602 1.36-.86 2.26-.981.866-.117 1.969-.117 3.336-.117Zm-5.168 17c.015.353.039.664.076.942.099.734.28 1.122.556 1.399.277.277.666.457 1.4.556.755.101 1.756.103 3.191.103h4c1.436 0 2.437-.002 3.192-.103.734-.099 1.122-.28 1.4-.556.196-.196.343-.449.448-.841H8a.75.75 0 0 1 0-1.5h11.223c.019-.431.025-.925.026-1.5H7.898c-.978 0-1.32.006-1.582.077a2.25 2.25 0 0 0-1.54 1.422Zm14.473-3H7.782c-.818 0-1.376 0-1.855.128a3.748 3.748 0 0 0-1.177.548V8c0-1.435.002-2.437.103-3.192.099-.734.28-1.122.556-1.399.277-.277.666-.457 1.4-.556.755-.101 1.756-.103 3.191-.103h4c1.436 0 2.437.002 3.192.103.734.099 1.122.28 1.4.556.276.277.456.665.555 1.4.102.754.103 1.756.103 3.191v7.25ZM7.25 7A.75.75 0 0 1 8 6.25h8a.75.75 0 0 1 0 1.5H8A.75.75 0 0 1 7.25 7Zm0 3.5A.75.75 0 0 1 8 9.75h5a.75.75 0 0 1 0 1.5H8a.75.75 0 0 1-.75-.75Z"
      clipRule="evenodd"
    />
  </svg>
)

export default BookIcon
