import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import { FormControl, MenuItem, Select } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: '100%',
    width: '100%',
    marginLeft: '122px',
    border: '1px solid silver',
    borderRadius: '3px',
  },
}));

export const PropertyDetails = ({ setProperty, property }) => {
  const [propertyData, setPropertyData] = useState([]);
  const classes = useStyles();

  const getProperties = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/properties`,
    );

    setPropertyData(response.data.data);
  };

  useEffect(() => {
    getProperties();
  }, []);

  const selectHandler = (e) => {
    setProperty(e.target.value);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        gap: '5px',
        marginBottom: '20px',
        width: '100%',
      }}
    >
      <div className="card__title">
        <h3 className="bold-text">Property</h3>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '5px',
          width: '100%',
          marginTop: '25px',
        }}
      >
        <div className="form__form-group-label" style={{ width: '150px' }}>
          Select Property <span style={{ color: '#ff0000' }}>*</span>
        </div>
        <FormControl
          className={classes.formControl}
          style={{ margin: '0px', padding: '0px', width: '100%' }}
        >
          <Select
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
            defaultChecked={property?.id}
            defaultValue={property?.id}
            onChange={selectHandler}
            style={{
              background: '#f5f9fc',
              borderRadius: '3px',
              width: '100%',
            }}
            required={true}
          >
            {propertyData?.map((property) => {
              return (
                <MenuItem key={property.id} value={property.id}>
                  {property?.landlord_details?.name_first} {property?.id}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};
