import BaseService from './BaseService'
import axios from 'axios'

export default class CompaniesService extends BaseService {
  constructor() {
    super()
    this.apiUrl = `${process.env.REACT_APP_API_URL}` + '/companies'
  }

  async getCompanies(params = { join: ['contacts'] }) {
    try {
      const response = await axios.get(this.apiUrl, { params })
      if (response.status !== 200) {
        throw new Error('Failed to fetch staff')
      }
      return response
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async getCompaniesId(params) {
    try {
      const response = await axios.get(`${this.apiUrl}/${params.id}`, {
        params,
      })

      if (response.status !== 200) {
        throw new Error('Failed to fetch staff')
      }

      return response
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async addCompanies(val) {
    try {
      const response = await axios.post(this.apiUrl, val)

      if (response.status !== 201) {
        throw new Error('Failed to add staff')
      }

      return response
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async deleteCompanies(params) {
    try {
      const response = await axios.delete(`${this.apiUrl}/${params.id}`, params)

      return response
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async editCompanies(params) {
    try {
      const response = axios.put(`${this.apiUrl}/${params.id}`, params)

      return response
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async addCompaniesContacts(params) {
    try {
      const response = params.map(async (val) =>
        axios.post(`${process.env.REACT_APP_API_URL}/company-contacts`, val),
      )
      return await Promise.all(response)
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          //throw new Error(error.response.data);
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async editCompaniesContacts(params) {
    try {
      const response = params.map(async (val) =>
        axios.patch(`${process.env.REACT_APP_API_URL}/company-contacts/${val.id}`, val),
      )
      return await Promise.all(response)
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async deleteCompaniesContacts(id) {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_API_URL}/company-contacts/${id}`)

      return response
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async getGroupTypes() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/company-groups`)

      return response
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        // if (error.response) {
        //   throw new Error(error.response.data);
        // }
        // if (!error.response) {
        //   throw new Error(error);
        // }
      }
    }
  }
}
