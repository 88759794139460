import Handlebars from 'handlebars';

export const removeEmpty = (obj) => {

  return Object.fromEntries(
    Object.entries(obj)
      .filter(([_, v]) => v != '')
      .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
  );
};

export const compileTemplate = (text, data) => {
  const template = Handlebars.compile(text);
  return template(data);
};
