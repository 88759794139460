import React from 'react';
import { Button, ButtonToolbar, Col, Modal } from 'reactstrap';
import { reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import Services from '../../../../../shared/Services.js';
import { withRouter } from 'react-router';
import SimpleReactValidator from 'simple-react-validator';
import ModalComponent from '../../../../../shared/components/ModalCustom';
import NotesService from '../../../../../services/NotesServices';
import Select from 'react-select';
import { UserContext } from '../../../../../context/user-context';

class AddNotes extends React.Component {
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.state = {
      id: localStorage.getItem('debtorId'),
      note_Id: localStorage.getItem('note_Id'),
      crby: '',
      title: '',
      notes: '',
      time: '',
      date: '',
      app_type: '',
      accessErrorModal: false,
      showEmailPopup: false,
      allTask: [],
      asPin: false,
      selected_task: '',
      selected_type:'',
      allApp_type: '',
      staff_created:'',
      is_contact: false,
    };

    this.note = new NotesService();
    this.services = new Services();
    this.addNotes = this.addNotes.bind(this);
    this.changeInput = this.changeInput.bind(this);
    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    localStorage.setItem('note_Id', '');
    if (this.state.note_Id) {
      this.getNotes(this.state.note_Id);
    }

  }

  componentWillUpdate() {
    if (localStorage.getItem('note_Id')) {
      const id = localStorage.getItem('note_Id');
      this.getNotes(localStorage.getItem('note_Id'));
      setTimeout(() => {
        this.setState({
          note_Id: id,
        });
        localStorage.setItem('note_Id', '');
      }, 10);
    }
  }


  getNotes(note_Id) {
    const params = {
      id: note_Id,
    };

    this.note.getDebtorNotesId(params).then((res) => {
      if (res.status === 200) {
        const dd = res.data;
        this.setState({
          crby: dd.id,
          title: dd.title,
          notes: dd.content,
          app_type: dd.actions,
          selected_type: this.props.allApp_type.find((el) => el.label === dd.actions),
          asPin: dd.is_important,
          staff_created: dd.staff_created,
          is_contact: dd.is_contact,
        });
      } else if (
        res.statusCode === 401 &&
        res.responseType === 'UNAUTHORIZED'
      ) {
        this.setState({ accessErrorModal: true });
      } else {
        this.services.errorToast(res.message);
      }
    });
  }

  accessErrorModalClose = () => {
    this.setState({ accessErrorModal: false });
    this.props.history.push('/dashboard');
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  };

  addNotes = () => {
    const wips = JSON.parse(localStorage.getItem('wipDebId'));
    if (this.validator.allValid()) {
      if (!this.state.title) {
        this.services.errorToast('Title field is required.');
        return;
      }
      if (!this.state.notes) {
        this.services.errorToast('Notes field is required.');
        return;
      }
      if (!this.state.app_type) {
        this.services.errorToast('Application field is required.');
        return;
      }

      if (this.state.note_Id) {
        // Edit
        const {user} = this.context;
        const formData = {
          debtor: { id: +this.state.id },
          wip: wips[0],
          id: +this.state.note_Id,
          title: this.state.title,
          content: this.state.notes,
          is_important: this.state.asPin,
          actions: this.state.app_type,
          is_contact: this.state.is_contact,
          staff_created: `${user.first_name} ${user.last_name}`,
        };
        if(this.state.asPin){
          const data = this.props.staffData.find((el)=> el.is_important === true)
          data.is_important = false
          this.note.editDebtorNotes(data).then((res) => {
            if (res.status === 200) {
              this.props.cancle();
              this.clear();
              this.props.reloadList(formData);
            } else if (
              res.statusCode === 401 &&
              res.responseType === 'UNAUTHORIZED'
            ) {
              this.setState({ accessErrorModal: true });
            } else {
              this.services.errorToast(res.message);
            }
          });
        }
        this.note.editDebtorNotes(formData).then((res) => {
          if (res.status === 200) {
            this.services.successToast('Note updated successfully');
            this.props.cancle();
            this.clear();
            this.props.reloadList(formData);
          } else if (
            res.statusCode === 401 &&
            res.responseType === 'UNAUTHORIZED'
          ) {
            this.setState({ accessErrorModal: true });
          } else {
            this.services.errorToast(res.message);
          }
        });
      } else {
        // Add
        const {user} = this.context;
        const formData = {
          debtor: { id: +this.state.id },
          wip: wips[0],
          title: this.state.title,
          content: this.state.notes,
          is_important: this.state.asPin,
          actions: this.state.app_type,
          is_contact: this.state.is_contact,
          staff_created: `${user.first_name} ${user.last_name}`,
        };
        if(this.state.asPin){
          const data = this.props.staffData.find((el)=> el.is_important === true)
          if(data !== undefined){
            data.is_important = false
            this.note.editDebtorNotes(data).then((res) => {
              if (res.status === 200) {
                this.props.cancle();
                this.clear();
                this.props.reloadList(formData);
              } else if (
                res.statusCode === 401 &&
                res.responseType === 'UNAUTHORIZED'
              ) {
                this.setState({ accessErrorModal: true });
              } else {
                this.services.errorToast(res.message);
              }
            });
          }
        }
        this.note.addDebtorNotes(formData).then((res) => {
          if (res.status === 201) {
            this.services.successToast('Note added successfully');
            this.props.cancle();
            this.clear();
            this.props.reloadList(formData);
          } else if (
            res.statusCode === 401 &&
            res.responseType === 'UNAUTHORIZED'
          ) {
            this.setState({ accessErrorModal: true });
          } else {
            this.services.errorToast(res.message);
          }
        });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  changeInput(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleOptionChange = (e) => {
    this.setState({
      app_type: e.currentTarget.value,
    });
  };

  handleOptionAsPin = (e) => {
    this.setState({
      asPin: e.currentTarget.checked,
    });
  };

  clear = () => {
    this.setState({
      note_Id: '',
      crby: '',
      title: '',
      notes: '',
      type: 'DEBTOR',
      time: '',
      date: '',
      app_type: '',
      asPin: false,
      selected_type:''
    });
  };

  handleChange = (selectedOption) => {
    this.setState({ app_type: selectedOption.label });
    this.setState({ selected_type: selectedOption });
  };



  render() {
    const { title, notes,selected_type } = this.state;
    const { cancle,allApp_type } = this.props;
    return (
      <>
        <div className='bg-white w-100'>
          <div className='row'>
            <div className='col-12'>
              <div className='form-group'>
                <label className='form__form-group-label'> Add Title </label>
                <input className='custom-input' name='title' type='text' placeholder='Add title' value={title}
                       onChange={this.changeInput} />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <div className='form-group'>
                <label className='form__form-group-label'>GENERAL NOTE</label>
                <textarea name='notes' className='custom-input' value={notes} onChange={this.changeInput} />
              </div>
            </div>
          </div>
          <div className='row align-items-center form'>
            <div className='col-12 col-md-6 col-lg-6'>
              <div className='d-flex align-items-center ml-2'>
                <div className='round_checkbox'>
                  <input
                    type='checkbox'
                    className=''
                    id='customRadio'
                    name='asPin'
                    checked={this.state.asPin}
                    onChange={this.handleOptionAsPin}
                  />
                  <label className='mb-0' htmlFor='customRadio' />
                </div>
                <p className='m-0 ml-3'>Pin as Important Note</p>
              </div>
            </div>

          </div>
          <div className='row mt-3'>
            <div className='col-3'>
              <div className='form-group'>
                <label className='form__form-group-label'> Application </label>
                <Select
                  name='app_type'
                  type='text'
                  options={allApp_type}
                  classNamePrefix='react-select'
                  className='react-select height_auto'
                  value={selected_type}
                  onChange={this.handleChange}
                  placeholder='Select...'
                />
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-end w-100 py-3'>
            <ButtonToolbar className='form__button-toolbar'>
              <Button color='primary' type='submit'
                      onClick={this.addNotes}>{this.state.note_Id ? 'Update Note' : 'Save Note'}</Button>
              {this.state.note_Id ? <Button type='button' onClick={this.clear}>Cancel</Button> :
                <button type='button' className='btn-gray m-0' onClick={cancle}>Cancel</button>}

            </ButtonToolbar>
          </div>

          {this.state.errorModal ?
            <ModalComponent modal={this.state.errorModal} color={this.state.errorType} isCancel={true}
                            message={this.state.errorMessage} onConfirm={(e) => this.DeleteStaff(e)}
                            onClose={() => this.setState({ errorModal: false })} /> : null}
          {this.state.accessErrorModal ?
            <ModalComponent modal={this.state.accessErrorModal} color='danger' isCancel={false}
                            message='You do not have access to this, please contact your manager for assistance'
                            onConfirm={(e) => this.accessErrorModalClose(e)}
                            onClose={(e) => this.accessErrorModalClose(e)} /> : null}

        </div>
      </>
    );
  }
}

export default reduxForm({
  form: 'add_staff_form_validation', // a unique identifier for this form
})(withRouter(withTranslation('common')(AddNotes)));
