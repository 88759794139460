import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { Property } from '@containers/Sales/Properties/types/property.type'
import { useModal } from '@src/hooks/useModal'
import { FC } from 'react'
import MergePropertiesModal from '../../components/modals/merge-properties-modal/merge-properties-modal.component'
import { toast } from 'react-toastify'
import { PropertyService } from '@containers/Sales/Properties/services/property.service'
import { useQueryClient } from '@tanstack/react-query'
import { getPropertyByIdKey } from '../../utils/client-property.utils'
import { GET_CLIENT_PROPERTIES_PARAMS } from '@containers/Sales/Client/const/client.const'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'

interface EditClientPropertyActionsProps {
  property: Property
}

const EditClientPropertyActions: FC<EditClientPropertyActionsProps> = ({ property }) => {
  const queryClient = useQueryClient()
  const [isOpen, handleOpen, handleClose] = useModal(false)

  const handleDetach = async () => {
    const propertyService = new PropertyService()
    try {
      await propertyService.detachLandlordByPropertyId(property.id)
      queryClient.setQueryData<Property>(
        getPropertyByIdKey(property.id, GET_CLIENT_PROPERTIES_PARAMS),
        (prevData) => (prevData ? { ...prevData, landlord: null } : undefined),
      )
      toast.success('Landlord detached')
    } catch (e) {
      console.error(e)
      toast.error('Failed to detach landlord')
    }
  }

  const handleSuccessMerge = () => {
    queryClient.invalidateQueries([GLOBAL_QUERIES.PROPERTIES.GET_BY_ID])
  }

  return (
    <>
      <SubmitButton onClick={handleOpen}>Attach</SubmitButton>
      {property.landlord ? <SubmitButton onClick={handleDetach}>Detach</SubmitButton> : null}

      {isOpen ? (
        <MergePropertiesModal
          headerText="Attach Landlord"
          propertiesIds={[property.id]}
          onSuccessMerge={handleSuccessMerge}
          isOpen={isOpen}
          onClose={handleClose}
        />
      ) : null}
    </>
  )
}

export default EditClientPropertyActions
