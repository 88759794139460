import { REACT_APP_API_URL } from "@src/lib/global-variables";
import { PaginationResponse, Params } from "@src/types/service.type";
import axios from "axios";
import { IWipCostList } from "../types/wip-cost.type";

export class WipCostListService {

  async getCostList(params: Params = {}) {
    const res = await axios.get<PaginationResponse<IWipCostList[]>>(`${REACT_APP_API_URL}/wip-cost-lists`, { params })
    return res.data;
  }
}