import { GLOBAL_QUERIES } from "@src/lib/global-queries"
import { Params } from "@src/types/service.type"
import { useQuery } from "@tanstack/react-query"
import { WipService } from "../services/wip.service"

export const useGetWipList = (params: Params = {}, enabled = true) => {
  const getWipList = async () => {
    const wipService = new WipService()
    return wipService.getWipList(params)
  }

  const query = useQuery({ queryKey: [GLOBAL_QUERIES.WIP.GET, params], queryFn: getWipList, keepPreviousData: true, enabled })

  return query
}