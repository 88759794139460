import React from 'react';
import { Select, MenuItem } from '@mui/material';
import { useStyles } from './styles';

const CustomSelect = ({ value, options, handleSelect }) => {
  const classes = useStyles();
  return (
    <Select value={value} onChange={handleSelect} className={classes.select}>
      {options.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  );
};

export default CustomSelect;
