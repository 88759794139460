import { GLOBAL_QUERIES } from "@src/lib/global-queries"
import { Params } from "@src/types/service.type"
import { useQuery } from "@tanstack/react-query"
import { ClientService } from "../services/client.service"

export const useGetClientById = (id: number, params?: Params) => {

  const getClientById = async (id: number, params?: Params) => {
    const clientService = new ClientService()
    return clientService.getClientById(id, params)
  }

  const query = useQuery({
    queryKey: [GLOBAL_QUERIES.COMPANIES.GET_BY_ID, id],
    queryFn: () => getClientById(id, params)
  })

  return query;
}