import React, { PureComponent } from 'react';
import {
  Card, Col, Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import classnames from 'classnames';

import { withRouter } from 'react-router';
// import ProfileTimeLine from './ProfileTimeLine';
// import ProfileActivities from './ProfileActivities';
import ProfileSettings from './ProfileSettings';
import Services from '../../../../shared/Services.js';


class ProfileTabs extends PureComponent {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '3',
    };
    this.services = new Services();

  }

  toggle(tab) {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  showResults=(values)=>{
    const data = {
      id: values._id,
      // email: values.email,
      fname: values.fname,
      lname: values.lname,
      mobile: values.mobile,
      // name: values.name,
      password: values.password,
      phone: values.phone
    };
    this.services.editProfile(data,(res)=>{
      if(res.status===200){
        localStorage.setItem('user',JSON.stringify(res.data.data));
        this.services.successToast("Profile update successfully");
      }
      else{
        this.services.errorToast(res.message);
      }
    })
  }

  backHome=()=>{
    this.props.history.push('/dashboard');
  }

  render() {
    const {user}=this.props;

    const { activeTab } = this.state;
    return (
      <Col md={12} lg={12} xl={8}>
        <Card>
          <div className="profile__card tabs tabs--bordered-bottom card-body">
            <div className="tabs__wrap">
              <Nav tabs>
                {/* <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => {
                      this.toggle('1');
                    }}
                  >
                    Activity
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => {
                      this.toggle('2');
                    }}
                  >
                    TimeLine
                  </NavLink>
                </NavItem> */}
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '3' })}
                    onClick={() => {
                      this.toggle('3');
                    }}
                  >
                    Settings
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                {/* <TabPane tabId="1">
                  <ProfileActivities />
                </TabPane>
                <TabPane tabId="2">
                  <ProfileTimeLine />
                </TabPane> */}
                <TabPane tabId="3">
                  <ProfileSettings onSubmit={this.showResults} initialValues={user} backHome={this.backHome}/>
                </TabPane>
              </TabContent>
            </div>
          </div>
        </Card>
      </Col>
    );
  }
}

export default withRouter(ProfileTabs)
