import { FC, useState } from 'react'
import { LayoutProps } from './types/layout.type'
import TopBar from './top-bar/top-bar.component'
import SideBar from './side-bar/side-bar.component'
import Main from './main/main.component'

const Layout: FC<LayoutProps> = ({ children, withMainPadding = true }) => {
  const [isSideBarOpen, setIsSideBarOpen] = useState(true)

  const handleSideBarIconClick = () => {
    setIsSideBarOpen((prevState) => !prevState)
  }
  
  return (
    <>
      <TopBar onSideBarIconClick={handleSideBarIconClick} />
      <div className="d-flex">
        <SideBar isOpen={isSideBarOpen} />
        <Main withPadding={withMainPadding}>{children}</Main>
      </div>
    </>
  )
}

export default Layout
