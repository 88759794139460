import React from 'react';

import './AppButton.scss';

const AppButton = ({ type, content, buttonStyle, loading, onClick, icon, disabled }) => {
  return (
    <button
      type={type}
      className={`app-button ${buttonStyle} ${icon && 'small'}`}
      onClick={onClick}
      disabled={disabled}
    >
      {loading ? 'Loading...' : content}
      {icon && <img src={icon} alt="button-icon" className="icon" />}
    </button>
  );
};

export default AppButton;
