import React, { FC, InputHTMLAttributes, forwardRef } from 'react';
import cls from 'classnames';
import styles from './form-checkbox.module.scss';

interface FormCheckboxProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
  label?: string;
}

const FormCheckbox: FC<FormCheckboxProps> = forwardRef<HTMLInputElement, FormCheckboxProps>(({ label, ...props }, ref) => {
  return (
    <div className={styles.checkboxWrapper}>
      <label className={cls(styles.checkbox, styles.bounce)}>
        <input type="checkbox" ref={ref} {...props} />
        <svg viewBox="0 0 21 21">
          <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
        </svg>
      </label>
      {label ? <label className={styles.checkboxLabel}>{label}</label> : null}
    </div>
  );
});

FormCheckbox.displayName = 'FormCheckbox';

export default FormCheckbox;
