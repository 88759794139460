import axios from "axios";
import { GraphApiUser } from "../types/graph-api-user.type";
import { GRAPH_API_ENDPOINT } from "../const/graph-api.const";

export class GraphApiUserService {

  async getMe(accessToken: string): Promise<GraphApiUser> {
    const headers = this.getAuthorizationHeaders(accessToken);
    const res = await axios.get<GraphApiUser>(`${GRAPH_API_ENDPOINT.ME}`, { headers })
    return res.data;
  }

  private getAuthorizationHeaders(accessToken: string) {
    return ({ Authorization: `Bearer ${accessToken}` })
  }
}