import { SelectOption } from "@src/types/component-types"

export const TRACKING_PERIOD_OPTIONS: SelectOption<number>[] = [
  { value: 7, label: 'Weekly' },
  { value: 14, label: 'Forntnightly' },
  { value: 21, label: 'Tri Weekly' },
  { value: 31, label: 'Monthly' },
  { value: 61, label: 'Bi-Monthly' },
]

export const ACCOUNTS_OPTIONS: SelectOption<string>[] = [
  { value: 'Other', label: 'Other' },
  { value: 'BOQ', label: 'BOQ' },
  { value: 'Centrelink', label: 'Centrelink' },
  { value: 'Bankwest', label: 'Bankwest' },
]