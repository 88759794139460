const SettingsIcon = () => {
  return (
    <svg width="18" height="18" fill="none" viewBox="0 0 18 18">
      <path
        fill="#CCCCD7"
        fillRule="evenodd"
        d="M7.826 7.043l3.913 2.348-3.913 2.348V7.043zM9 12.522A3.53 3.53 0 015.478 9 3.53 3.53 0 019 5.478 3.519 3.519 0 0112.522 9 3.519 3.519 0 019 12.522zm9-1.665V7.143h-3.259c-.114-.303-.227-.606-.379-.871l2.33-2.33-2.633-2.616-2.387 2.369a6.417 6.417 0 00-.834-.341V0H7.143v3.354a6.404 6.404 0 00-.834.34L3.941 1.327 1.326 3.941l2.33 2.33a5.054 5.054 0 00-.378.872H0v3.695h3.164c.095.341.228.663.38.985L1.325 14.06l2.615 2.615 2.179-2.18c.322.171.663.323 1.023.437V18h3.695v-3.07a6.16 6.16 0 001.023-.435l2.179 2.179 2.615-2.615-2.198-2.236c.151-.303.284-.644.379-.985H18v.019z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default SettingsIcon
