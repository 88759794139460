import Dropdown from '@components/dropdown/dropdown.component'
import { FC, useState } from 'react'
import FormInput from '@components/inputs/form-input/form-input.component'
import SearchIcon from '@src/icons/search'
import styles from './combined-note-table.module.scss'
import CombinedNotesTable from './combined-notes-table.component'
import { COMBINED_NOTES_FILTER_OPTIONS } from '@containers/Communication/const/communication.const'
import { CombinedNotesTableWipFilterProps } from '@containers/Communication/types/communication.type'
import { CombinedNoteType } from '@containers/Communication/types/combined-notes.type'
import { SelectOption } from '@src/types/component-types'

const CombinedNotesTableWipFilter: FC<CombinedNotesTableWipFilterProps> = ({ params }) => {
  const [selectedType, setSelectedType] = useState(COMBINED_NOTES_FILTER_OPTIONS[0])
  const [page, setPage] = useState(1)

  const handleSelectedTypeChange = (selectedType: SelectOption) => {
    setSelectedType(selectedType)
    setPage(1)
  }

  return (
    <>
      <div className={styles.emailTableFilter}>
        <Dropdown
          options={COMBINED_NOTES_FILTER_OPTIONS}
          selected={selectedType}
          onSelectedChange={handleSelectedTypeChange}
        />
        <div className={styles.searchFieldWrapper}>
          <FormInput size="small" placeholder="Search" icon={SearchIcon} />
        </div>
      </div>
      <CombinedNotesTable
        onPageChange={setPage}
        currentPage={page}
        params={{
          ...params,
          type:
            selectedType.value !== 'all'
              ? ([...selectedType.value.split('|')] as `${CombinedNoteType}`[])
              : undefined,
        }}
      />
    </>
  )
}

export default CombinedNotesTableWipFilter
