import React, { FC } from 'react'
import { MsalProvider } from '@azure/msal-react'
import { EventMessage, EventType, PublicClientApplication } from '@azure/msal-browser'
import { msalConfig } from '@src/config/azure-auth.config'
import { useUserContext } from './user-context'

interface AuthProviderProps {
  children: React.ReactNode
}

const msalInstance = new PublicClientApplication(msalConfig)

const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const { logout } = useUserContext()

  msalInstance.addEventCallback(async (event: EventMessage) => {
    if (event.eventType === EventType.LOGOUT_END) {
      await logout()
    }
  })

  return <MsalProvider instance={msalInstance}>{children}</MsalProvider>
}

export default AuthProvider
