import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Collapse } from 'reactstrap'
import moment from 'moment'
import { getSentSMSDamp } from '../../../../mock-data/serviceDataBaseSMSEmail'
import NavigationButton from '@components/navigation-button/navigation-button'
import InboxIcon from '@src/icons/inbox'

const TopbarMail = () => {
  const notifications = getSentSMSDamp()
  const [collapse, setCollapse] = useState(false)

  const toggle = () => {
    setCollapse((prevState) => !prevState)
  }

  return (
    <div className="topbar__collapse">
      <NavigationButton icon={InboxIcon} onClick={toggle} isActive={collapse} />
      {collapse && <button className="topbar__back" type="button" onClick={toggle} />}
      <Collapse isOpen={collapse} className="topbar__collapse-content">
        <div className="topbar__collapse-title-wrap">
          <p className="topbar__collapse-title">Unread messages</p>
          <button className="topbar__collapse-button" type="button">
            Mark all as read
          </button>
        </div>
        {notifications.map((notification, index) => (
          <div className="topbar__collapse-item" key={index}>
            <div className="topbar__collapse-img-wrap">
              <img
                className="topbar__collapse-img"
                src={`${process.env.PUBLIC_URL}/img/topbar/ava.png`}
                alt=""
              />
            </div>
            <p className="topbar__collapse-name">
              {moment(notification.time_created).format('MM-DD-YY')}
            </p>
            <p className="topbar__collapse-message topbar__collapse-message--mail">
              {notification.short_message_body}. Wait connection...
            </p>

            <p className="topbar__collapse-date">
              {moment(notification.time_created).format('HH:MM')}
            </p>
          </div>
        ))}
        <Link className="topbar__collapse-link" to="/mail" onClick={toggle}>
          See all messages
        </Link>
      </Collapse>
    </div>
  )
}
export default TopbarMail
