import * as React from 'react'
const CalendarIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
    <path
      fill="#1F2A37"
      d="M19.2 4.8h-1.8v-.9a.9.9 0 1 0-1.8 0v.9h-2.7v-.9a.9.9 0 1 0-1.8 0v.9H8.4v-.9a.9.9 0 0 0-1.8 0v.9H4.8A1.8 1.8 0 0 0 3 6.6v12.6A1.8 1.8 0 0 0 4.8 21h14.4a1.8 1.8 0 0 0 1.8-1.8V6.6a1.8 1.8 0 0 0-1.8-1.8ZM6.6 6.6a.9.9 0 0 0 1.8 0h2.7a.9.9 0 1 0 1.8 0h2.7a.9.9 0 1 0 1.8 0h1.8v1.8H4.8V6.6h1.8ZM4.8 19.2v-9h14.4v9H4.8Z"
    />
    <path
      fill="#1F2A37"
      d="M7.95 12h-.9a.45.45 0 0 0-.45.45v.9c0 .248.201.45.45.45h.9a.45.45 0 0 0 .45-.45v-.9a.45.45 0 0 0-.45-.45ZM7.95 15.6h-.9a.45.45 0 0 0-.45.45v.9c0 .248.201.45.45.45h.9a.45.45 0 0 0 .45-.45v-.9a.45.45 0 0 0-.45-.45ZM12.45 12h-.9a.45.45 0 0 0-.45.45v.9c0 .248.202.45.45.45h.9a.45.45 0 0 0 .45-.45v-.9a.45.45 0 0 0-.45-.45ZM12.45 15.6h-.9a.45.45 0 0 0-.45.45v.9c0 .248.202.45.45.45h.9a.45.45 0 0 0 .45-.45v-.9a.45.45 0 0 0-.45-.45ZM16.95 12h-.9a.45.45 0 0 0-.45.45v.9c0 .248.202.45.45.45h.9a.45.45 0 0 0 .45-.45v-.9a.45.45 0 0 0-.45-.45ZM16.95 15.6h-.9a.45.45 0 0 0-.45.45v.9c0 .248.202.45.45.45h.9a.45.45 0 0 0 .45-.45v-.9a.45.45 0 0 0-.45-.45Z"
    />
  </svg>
)
export default CalendarIcon
