import { Component } from 'react'
import Select from 'react-select'
import Timeline from './Timeline'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import SimpleReactValidator from 'simple-react-validator'

import { Col, Modal } from 'reactstrap'
import { ContentState, convertToRaw, EditorState } from 'draft-js'

import TaskList from '../TaskList'
import Details from './Details'
import Debtors from './Debtors'
import AddModal from '../../../../Todo/AddTaskModal'
import Transactions from './Transactions'

import Services from '../../../../../shared/Services'
import PayTrackingService from '../../../../../services/PayTracking'
import CreateTaskModal from '../taskboard/modals/create-task-modal'
import { TaskContext } from '../../../../../context/task-context'
import { UserContext } from '../../../../../context/user-context'
import CreateColumnModal from '../taskboard/modals/create-column-modal'
import CreateAutomationModal from '../automations/modals/create-automation-modal'
import AutomationBoard from '../automations'

import Notes from '../../../../Setting/components/Contacts/Notes/notes.component'

const ToolbarOptions = {
  options: [
    'inline',
    'blockType',
    'fontFamily',
    'list',
    'textAlign',
    'link',
    'emoji',
    'image',
    'remove',
    'history',
  ],
  inline: {
    options: ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript'],
  },
  blockType: {
    options: ['Normal', 'H1', 'H2', 'H3'],
  },
  fontFamily: {
    options: ['Georgia', 'Trebuchet'],
  },
}

export default class RecoveryIndex extends Component {
  static contextType = TaskContext
  constructor(props) {
    console.log(props)
    // TODO: replace JSON.parse(localStorage.getItem('user')) with UserContext
    super(props)
    this.state = {
      isCreateColumnModalOpened: false,
      isCreateAutomationModalOpened: false,
      title: 'ADD STAFF',
      activeTab: 1,
      task_id: '',
      cName: '',
      sendEmailDialog: false,
      printLetterDialog: false,
      manageEmailDetails: false,
      allData: '',
      emailType: '',
      subTypeList: [],
      subType: [],
      wipData: '',
      wipNumber: '',
      debtorList: [],
      emailList: [],
      isCreateTaskModalOpen: false,
      selectedDebtor: '',
      selectedEmail: '',
      selectedSubtype: '',
      bcc: '',
      cc: '',
      subject: '',
      attachment: [],
      wipID: '',
      editorState: EditorState.createEmpty(),
      bodyString: '',
      logoImageHeader: '',
      isMailSent: false,
      user: JSON.parse(localStorage.getItem('user')),
      isPreViewMail: false,
      fileLoder: false,
      notfound: false,
      openModalSend: false,
      whatSend: '',
      payTrack: {},
      payTrackOn: [],
      addTaskModal: false,
      isEdit: false,
      selectedTask: '',
      wip_id: '',
    }
    this.payTrack = new PayTrackingService()
    this.services = new Services()
    this.preViewValidator = new SimpleReactValidator()
    this.validator = new SimpleReactValidator()
    this.validatorWIP = new SimpleReactValidator()
    this.changeInput = this.changeInput.bind(this)
    this.getWip = this.getWip.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.onEditorStateChange = this.onEditorStateChange.bind(this)
  }

  componentDidMount() {
    const { setCurrentWip, setMode } = this.context
    if (!this.props.location) return null
    setCurrentWip(this.props.location.state.wipID)
    setMode('RECOVERY')

    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.wipID
    ) {
      this.setState({ wipID: this.props.location.state.wipID })
    }
    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.cName
    ) {
      this.setState({ cName: this.props.location.state.cName })
    } else if (localStorage.getItem('cName')) {
      this.setState({ cName: localStorage.getItem('cName') })
    }
    this.toDataURL('./image/logo.png').then((dataUrl) => {
      this.setState({ logoImageHeader: dataUrl })
    })
    if (this.props.match.params.id) {
      this.setState({ title: 'EDIT STAFF' })
    }
    this.getPaymentTracking()
  }

  toDataURL = (url) =>
    fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result)
            reader.onerror = reject
            reader.readAsDataURL(blob)
          }),
      )

  getPaymentTracking() {
    const params = {
      join: ['wip||id', 'debtor||id'],
      filter: `wip.id||$eq||${this.props.location.state.wipID}`,
    }
    this.payTrack.getPayTrack(params).then((res) => {
      if (res.status === 200) {
        if (res.data.data.length) {
          this.setState({
            payTrack: res.data.data[0],
            payTrackOn: res.data.data,
          })
        }
      }
    })
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    })
    this.setState({
      bodyString: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    })
  }

  changeInput(e) {
    if (!e.target.validity.valid) {
      return null
    }
    this.setState({ [e.target.name]: e.target.value })
  }

  getTemplateTypeList = () => {
    const data = {
      type: this.state.emailType === 'Debtor' ? 'WIP' : 'WIP - Agent Letter',
    }
    this.services.getTemplateByType(data, (res) => {
      if (res.status === 200) {
        const item = []
        res.data &&
          res.data.data.forEach((element) => {
            item.push({ value: element._id, label: element.title })
          })
        this.setState({
          subTypeList: item,
          subType: res.data && res.data.data,
        })
      } else {
        this.services.errorToast(res.message)
      }
    })
  }

  toggle = (tab) => {
    const { activeTab } = this.state
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  changeTab = (e, tab) => {
    const { activeTab } = this.state
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
        task_id: '',
      })
    }
  }

  viewTaskNotes = (id) => {
    this.setState({
      activeTab: 6,
      task_id: id,
    })
  }

  sendEmail = (e) => {
    this.setState({
      openModalSend: true,
      whatSend: e,
    })
  }

  sendDataParent = (item) => {
    this.setState({
      allData: item,
    })
  }

  getWip() {
    if (this.validatorWIP.allValid()) {
      const data = {
        wip_id: this.state.wipNumber,
      }
      this.services.getWIPSearch(data, (res) => {
        if (res.status === 200) {
          const getData = res.data.data
          console.log(getData)
          const list = []
          const emailList = []
          if (getData.length === undefined && !res.data.data._id) {
            this.setState({ notfound: true, wipData: '' })

            setTimeout(() => {
              this.setState({ notfound: false })
            }, 8000)
          }
          if (getData && getData.debtors) {
            getData.debtors.map((item) => {
              list.push({
                value: item._id,
                label: `${item.first_name} ${item.last_name}`,
              })
              if (this.state.emailType === 'Debtor') {
                emailList.push({ value: item._id, label: item.email })
              }
            })
            this.setState({
              wipData: getData,
              debtorList: list,
              emailList: emailList,
            })
          }
          if (this.state.emailType !== 'Debtor' && getData.client && getData.client.contact_det) {
            getData.client.contact_det.map((c) => {
              emailList.push({
                value: c._id,
                label: `${c.position}-${c.first} ${c.last}: ${c.email}`,
              })
            })
          }
          this.setState({ emailList: emailList })
        } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
          this.setState({ accessErrorModal: true })
        } else {
          this.services.errorToast(res.message)
        }
      })
    } else {
      this.validatorWIP.showMessages()
      this.forceUpdate()
    }
  }

  handleChange(option, item, type) {
    let email
    let debt
    if (item === 'Debtor') {
      debt = this.state.debtorList.find((_) => _.value === option.value)
      if (debt) {
        this.setState({
          selectedDebtor: debt,
        })
      }
      email = this.state.emailList.find((_) => _.value === option.value)
      if (email) {
        this.setState({
          selectedEmail: email,
          to: email.label,
        })
      }
    } else if (item === 'subType') {
      this.setState({
        selectedSubtype: option,
        subject: option && option.label,
      })
    } else if (item === 'Agent') {
      if (type === 'debtor') {
        debt = this.state.debtorList.find((_) => _.value === option.value)
        if (debt) {
          this.setState({
            selectedDebtor: debt,
          })
        }
      }
      if (type === 'recipient') {
        email = this.state.emailList.find((_) => _.value === option.value)
        if (email) {
          this.setState({
            selectedEmail: email,
            to: email.label.split(': ') ? email.label.split(': ')[1] : '',
          })
        }
      }
    }
  }

  sendMail() {
    if (this.validator.allValid()) {
      const htmlString = this.state.subType.find((_) => _._id === this.state.selectedSubtype.value)
      const data = {
        wip_ID: this.state.wipID,
        template_id: this.state.selectedSubtype && this.state.selectedSubtype.value,
        to: this.state.to,
        subject: this.state.subject,
        type: this.state.emailType,
        //attachment: 'https://bms4.s3.amazonaws.com/mediaFile/sample.pdf',
        html: htmlString ? htmlString.body : '',
      }

      if (this.state.cc) data.cc = this.state.cc

      if (this.state.bcc) data.bcc = this.state.bcc

      if (this.state.attachment) data.attachment = JSON.stringify(this.state.attachment)

      this.services.sendAgentWIPemails(data, (res) => {
        if (res.status === 200) {
          this.setState({ isMailSent: true })
          //this.onClosed()
        } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
          this.setState({ accessErrorModal: true })
        } else {
          this.services.errorToast(res.message)
        }
      })
    } else {
      this.validator.showMessages()
      this.forceUpdate()
    }
  }

  clear = () => {
    this.setState({
      sendEmailDialog: false,
      manageEmailDetails: false,
      emailType: '',
      wipData: '',
      wipNumber: '',
      debtorList: [],
      emailList: [],
      selectedDebtor: '',
      selectedEmail: '',
      selectedSubtype: '',
      bcc: '',
      cc: '',
      to: '',
      subject: '',
      attachment: [],
      bodyString: '',
      isPreViewMail: false,
    })
  }

  preViewEmail = () => {
    const option = this.state.selectedSubtype

    if (option && this.preViewValidator.allValid()) {
      const selectedTemplate = this.state.subType.find((_) => _._id === option.value)

      if (selectedTemplate.body.search('%date_long%') > 1) {
        selectedTemplate.body = selectedTemplate.body.replace(
          '%date_long%',
          new Date().toDateString(),
        )
      }
      if (selectedTemplate.body.search('%file_number%') > 1) {
        selectedTemplate.body = selectedTemplate.body.replace('%file_number%', this.state.wipNumber)
      }
      if (selectedTemplate.body.search('%comb_name_amp%') > 1) {
        selectedTemplate.body = selectedTemplate.body.replace(
          '%comb_name_amp%',
          this.state.selectedDebtor && this.state.selectedDebtor.label,
        )
      }
      if (selectedTemplate.body.search('%staff_name%') > 1) {
        selectedTemplate.body = selectedTemplate.body.replace(
          '%staff_name%',
          this.state.user && this.state.user.fname + ' ' + this.state.user.lname,
        )
      }
      selectedTemplate.body = selectedTemplate.body.replace('%staff_title%', '')

      if (selectedTemplate.body.search('%client_name%') > 1) {
        selectedTemplate.body = selectedTemplate.body.replace(
          '%client_name%',
          this.state.wipData && this.state.wipData.client && this.state.wipData.client.name,
        )
      }

      if (selectedTemplate.body.search('%client_address%')) {
        selectedTemplate.body = selectedTemplate.body.replace(
          '%client_address%',
          this.state.wipData &&
            this.state.wipData.client &&
            this.state.wipData.client.address &&
            this.state.wipData.client.address[0].address_1 +
              ' ' +
              this.state.wipData.client.address[0].post_code,
        )
      }
      if (selectedTemplate.body.search('%wip_prop%') > 1) {
        selectedTemplate.body = selectedTemplate.body.replace(
          '%wip_prop%',
          this.state.wipData &&
            this.state.wipData.property &&
            this.state.wipData.property.prop_address_1 +
              ' ' +
              this.state.wipData.property.prop_post_code,
        )
      }

      const blocksFromHtml = htmlToDraft(selectedTemplate.body)
      const { contentBlocks, entityMap } = blocksFromHtml
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)
      const editorState = EditorState.createWithContent(contentState)
      this.setState({
        currentId: selectedTemplate._id,
        title: selectedTemplate.title,
        bodyString: selectedTemplate.body,
        editorState: editorState,
        isPreViewMail: !this.state.isPreViewMail,
      })
    } else {
      this.preViewValidator.showMessages()
      this.forceUpdate()
    }
  }

  fileUploadS3 = (fileOption) => {
    if (fileOption && fileOption) {
      this.setState({ fileLoder: true })

      fileOption.map((item) => {
        const data = {
          href: '',
          filename: item.name,
          contentType: item.type,
        }
        this.services.uploadPDFONS3(item, (res) => {
          if (res.status === 200) {
            // this.services.successToast('File Uploded successfully');
            data.href = res.data.data.pdfPath
            const list = self.state.attachment
            list.push(data)
            self.setState({ attachment: list })
          } else {
            this.services.errorToast(res.message)
          }
        })
      })
      this.setState({ fileLoder: false })
    }
  }

  printMail = () => {
    const content = document.getElementById('print_templateview')
    const pri = document.getElementById('ifmcontentstoprint').contentWindow
    pri.document.open()
    pri.document.write(content.innerHTML)
    pri.document.close()
    pri.focus()
    pri.print()
  }

  handleCreateTaskModalClose = () => {
    this.setState({ isCreateTaskModalOpen: false })
  }

  handleCreateTaskModalOpen = () => {
    this.setState({ isCreateTaskModalOpen: true })
  }

  handleCreateColumnModalClose = () => {
    this.setState({ isCreateColumnModalOpened: false })
  }

  handleCreateColumnModalOpen = () => {
    this.setState({ isCreateColumnModalOpened: true })
  }

  handleCreateAutomationModalClose = () => {
    this.setState({ isCreateAutomationModalOpened: false })
  }

  handleCreateAutomationModalOpen = () => {
    this.setState({ isCreateAutomationModalOpened: true })
  }

  render() {
    const {
      activeTab,
      isCreateTaskModalOpen,
      isCreateAutomationModalOpened,
      isCreateColumnModalOpened,
    } = this.state
    return (
      <>
        <>
          {activeTab === 3 && (
            <div className="btns">
              <UserContext.Consumer>
                {({ isAdmin }) => (
                  <>
                    {isAdmin && (
                      <button onClick={this.handleCreateAutomationModalOpen}>
                        Create Automation
                      </button>
                    )}
                    {isAdmin && (
                      <button onClick={this.handleCreateColumnModalOpen}>Create Column</button>
                    )}
                  </>
                )}
              </UserContext.Consumer>

              <button onClick={this.handleCreateTaskModalOpen}>Create Task</button>
              <button>Go Back</button>
            </div>
          )}
        </>
        <div className="">
          <div className="container">
            <div className="main-panel">
              <div className="content-wrapper" />
              <div className="client_info-block mt-3">
                <div className="row mb-3">
                  <div className="col-6">
                    <div className="mb-1 contact_username-wip">WIP Number: {this.state.wipID}</div>
                    <div
                      style={{
                        fontSize: '16px',
                        color: '#000',
                        marginTop: '20px',
                      }}
                    >
                      <strong>Client: </strong>
                      {this.state.cName}
                    </div>
                  </div>
                  {/* <div className="col-12 d-flex justify-content-end">
                    {payTrackOn.length ? (
                      <div className={'notificationTabWip'}>
                        {' '}
                        Payment:
                        {payTrack.isOverdue && (
                          <span style={{ color: 'red', marginLeft: '5px' }}>Overdue!</span>
                        )}
                        {payTrack.isOverdue === false && payTrack.firstPaymentDue && (
                          <span style={{ marginLeft: '5px' }}>
                            Due {moment(payTrack.firstPaymentDue).format('DD-MM-YYYY')}{' '}
                          </span>
                        )}
                      </div>
                    ) : null}
                    <button
                      onClick={() =>
                        this.props.history.push(`/contacts/${localStorage.getItem('client_Id')}`, {
                          fromWIP: true,
                        })
                      }
                      className={'btn'}
                      style={{
                        background: '#fff',
                        borderRadius: '5px',
                        fontWeight: '700',
                      }}
                    >
                      Go Back
                    </button>
                  </div> */}
                </div>
                <div className="row">
                  <div className="col-12">
                    {/* <div className="tabs_wrapper card-body"> */}
                    <div className="tabs_nav bg-white d-flex flex-wrap justify-content-between">
                      <ul className="nav nav-tabs" role="tablist">
                        <li className="nav-item">
                          <span
                            className={activeTab === 1 ? 'nav-link active' : 'nav-link'}
                            id="Details-link"
                            data-toggle="tab"
                            role="tab"
                            aria-controls="overview"
                            aria-selected="true"
                            onClick={(e) => this.changeTab(e, 1)}
                          >
                            Details
                          </span>
                        </li>
                        <li className="nav-item">
                          <span
                            className={activeTab === 2 ? 'nav-link active' : 'nav-link'}
                            id="tools-link"
                            data-toggle="tab"
                            role="tab"
                            aria-controls="overview"
                            aria-selected="true"
                            onClick={(e) => this.changeTab(e, 2)}
                          >
                            Debtors
                          </span>
                        </li>

                        <li className="nav-item">
                          <span
                            className={activeTab === 3 ? 'nav-link active' : 'nav-link'}
                            id="tools-link"
                            data-toggle="tab"
                            role="tab"
                            aria-controls="overview"
                            aria-selected="true"
                            onClick={(e) => this.changeTab(e, 3)}
                          >
                            Task Board
                          </span>
                        </li>

                        <li className="nav-item">
                          <span
                            className={activeTab === 4 ? 'nav-link active' : 'nav-link'}
                            id="tools-link"
                            data-toggle="tab"
                            role="tab"
                            aria-controls="overview"
                            aria-selected="true"
                            onClick={(e) => this.changeTab(e, 4)}
                          >
                            Communication
                          </span>
                        </li>
                        <li className="nav-item">
                          <span
                            className={activeTab === 5 ? 'nav-link active' : 'nav-link'}
                            id="tools-link"
                            data-toggle="tab"
                            role="tab"
                            aria-controls="overview"
                            aria-selected="true"
                            onClick={(e) => this.changeTab(e, 5)}
                          >
                            Transactions
                          </span>
                        </li>
                        <li className="nav-item">
                          <span
                            className={activeTab === 6 ? 'nav-link active' : 'nav-link'}
                            id="tools-link"
                            data-toggle="tab"
                            role="tab"
                            aria-controls="overview"
                            aria-selected="true"
                            onClick={(e) => this.changeTab(e, 6)}
                          >
                            Timeline
                          </span>
                        </li>
                        <li className="nav-item">
                          <span
                            className={activeTab === 7 ? 'nav-link active' : 'nav-link'}
                            id="tools-link"
                            data-toggle="tab"
                            role="tab"
                            aria-controls="overview"
                            aria-selected="true"
                            onClick={(e) => this.changeTab(e, 7)}
                          >
                            Automations
                          </span>
                        </li>
                      </ul>
                    </div>

                    <div className="tab-content">
                      <div
                        className={activeTab === 1 ? 'tab-pane fade show active' : 'tab-pane fade'}
                        id="Details-tab"
                        role="tabpanel"
                        aria-labelledby="Details-tab"
                      >
                        {activeTab === 1 && (
                          <Details sendDataParent={(item) => this.sendDataParent(item)} />
                        )}
                      </div>
                      <div
                        className={activeTab === 2 ? 'tab-pane fade show active' : 'tab-pane fade'}
                        id="Debtors-tab"
                        role="tabpanel"
                        aria-labelledby="Debtors-tab"
                      >
                        {activeTab === 2 && (
                          <Debtors
                            changeTab={(e, tab) => this.changeTab(e, tab)}
                            allData={this.state.allData}
                          />
                        )}
                      </div>

                      <div
                        className={activeTab === 3 ? 'tab-pane fade show active' : 'tab-pane fade'}
                        id="Debtors-tab"
                        role="tabpanel"
                        aria-labelledby="Debtors-tab"
                      >
                        {activeTab === 3 && (
                          <TaskList
                            changeTab={(e, tab) => this.changeTab(e, tab)}
                            allData={this.state.allData}
                          />
                        )}
                      </div>

                      <div
                        className={activeTab === 4 ? 'tab-pane fade show active' : 'tab-pane fade'}
                        id="Communication-tab"
                        role="tabpanel"
                        aria-labelledby="Communication-tab"
                      >
                        {activeTab === 4 && <Notes />}
                      </div>
                      <div
                        className={activeTab === 5 ? 'tab-pane fade show active' : 'tab-pane fade'}
                        id="Timeline-tab"
                        role="tabpanel"
                        aria-labelledby="Timeline-tab"
                      >
                        {activeTab === 5 && <Transactions allData={this.state.allData} />}
                      </div>
                      <div
                        className={activeTab === 6 ? 'tab-pane fade show active' : 'tab-pane fade'}
                        id="Timeline-tab"
                        role="tabpanel"
                        aria-labelledby="Timeline-tab"
                      >
                        {activeTab === 6 && <Timeline allData={this.state.allData} />}
                      </div>
                      <div
                        className={activeTab === 7 ? 'tab-pane fade show active' : 'tab-pane fade'}
                        id="Timeline-tab"
                        role="tabpanel"
                        aria-labelledby="Timeline-tab"
                      >
                        {activeTab === 7 && <AutomationBoard />}
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal
            isOpen={this.state.printLetterDialog}
            size="lg"
            style={{ maxWidth: '1200px', width: '95%' }}
            className="theme-light"
            centered
          >
            <div className="w-100 d-flex  justify-content-end position-absolute close-email-popup">
              <span className="lnr lnr-cross p-2" onClick={() => this.onClosed()} />
            </div>
            <Col md={12} lg={12}>
              {this.state.printLetterDialog && (
                <div className="card email-popup">
                  <h3 className="page-title text-left"> Print Letter </h3>
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-6">
                      <div className="form-group text-left">
                        <label className="form__form-group-label" htmlFor="">
                          Print Letter
                        </label>
                        <Select
                          type="text"
                          classNamePrefix="react-select"
                          className="react-select"
                          options={this.state.subTypeList}
                          onChange={(e) => this.handleChange(e, 'subType')}
                        />
                        <span className="form__form-group-error">
                          {this.preViewValidator.message(
                            'Print Letter',
                            this.state.selectedSubtype,
                            'required',
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group text-left">
                        <label className="form__form-group-label" htmlFor="">
                          {' '}
                          WIP File Number{' '}
                        </label>
                        <input
                          type="text"
                          className="custom-input"
                          name="wipNumber"
                          onChange={this.changeInput}
                          value={this.state.wipNumber}
                        />
                        <span className="form__form-group-error">
                          {this.validator.message('Wip Number', this.state.wipNumber, 'required')}
                        </span>
                        <span className="form__form-group-error">
                          {this.validatorWIP.message(
                            'Wip Number',
                            this.state.wipNumber,
                            'required',
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-12 col-md-4 col-lg-3">
                      <div className="form-group">
                        <div className="form-group text-left mt-4 mb-0">
                          <button
                            className="btn btn-primary m-0 line-height19"
                            onClick={() => this.getWip()}
                          >
                            {' '}
                            Get WIP Details
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.state.notfound && (
                    <span className="form__form-group-error">
                      None To Display, Try Agian With Other WIP File Number.
                    </span>
                  )}
                  {this.state.wipData && (
                    <div className="row">
                      <div className="col-12 col-md-6 col-lg-3">
                        <div className="form-group text-left">
                          <label className="form__form-group-label " htmlFor="">
                            {' '}
                            Lodged Date
                          </label>
                          <input
                            type="text"
                            className="custom-input"
                            disabled
                            value={this.state.wipData && this.state.wipData.lodged}
                          />
                          <span className="form__form-group-error" />
                        </div>
                      </div>
                    </div>
                  )}

                  {this.state.wipData && (
                    <>
                      <hr />
                      <h3 className="page-title text-left mt-3"> Email Details </h3>
                      <div className="row">
                        <div className="col-12 col-md-6 col-lg-3">
                          <div className="form-group text-left">
                            <label className="form__form-group-label" htmlFor="">
                              {' '}
                              Select Debtor
                            </label>
                            <Select
                              type="text"
                              classNamePrefix="react-select"
                              className="react-select"
                              options={this.state.debtorList}
                              value={this.state.selectedDebtor}
                              onChange={(e) => this.handleChange(e, this.state.emailType, 'debtor')}
                            />
                            <span className="form__form-group-error">
                              {this.preViewValidator.message(
                                'Debtor',
                                this.state.selectedDebtor,
                                'required',
                              )}
                            </span>
                            <span className="form__form-group-error">
                              {this.validator.message(
                                'Debtor',
                                this.state.selectedEmail,
                                'required',
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3">
                          <div className="form-group text-left">
                            <label className="form__form-group-label" htmlFor="">
                              {' '}
                              Select Email Recipient :
                            </label>
                            <Select
                              type="text"
                              classNamePrefix="react-select"
                              className="react-select"
                              options={this.state.emailList}
                              value={this.state.selectedEmail}
                              onChange={(e) =>
                                this.handleChange(e, this.state.emailType, 'recipient')
                              }
                            />
                            <span className="form__form-group-error">
                              {this.preViewValidator.message(
                                'Email Recipient',
                                this.state.selectedEmail,
                                'required',
                              )}
                            </span>
                            <span className="form__form-group-error">
                              {this.validator.message(
                                'Email Recipient',
                                this.state.selectedEmail,
                                'required',
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-lg-2">
                          <div className="form-group text-left mt-4 line-height36  ">
                            <button className="btn-gray m-0" onClick={() => this.preViewEmail()}>
                              {' '}
                              View Letter
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="row m-1 mb-3">
                        {this.state.isPreViewMail && (
                          <div className="text-editor" style={{ zIndex: '-5px' }}>
                            <Editor
                              editorState={this.state.editorState}
                              wrapperClassName="demo-wrapper"
                              editorClassName="demo-editor"
                              onEditorStateChange={this.onEditorStateChange}
                              toolbar={ToolbarOptions}
                            />
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              )}
            </Col>
          </Modal>
        </div>

        <div style={{ height: '100px', overflow: 'auto', opacity: '0' }}>
          <div id="print_templateview">
            <div style={{ width: '700px', margin: '50px auto', padding: '0 40px' }}>
              <div className="preview_logo text-left">
                <img src={this.state.logoImageHeader} alt="logo" style={{ maxWidth: '200px' }} />
              </div>
              <p
                dangerouslySetInnerHTML={{
                  __html: this.state.bodyString,
                }}
              />
            </div>
          </div>
        </div>
        <iframe
          id="ifmcontentstoprint"
          style={{ height: '0px', width: '0px', position: 'absolute' }}
        />

        {this.state.addTaskModal && (
          <AddModal
            addTaskModal={this.state.addTaskModal}
            closeModal={this.closeModal}
            data={this.state.selectedTask}
            isEdit={this.state.isEdit}
            wipId={this.state.wipID}
          />
        )}
        <CreateTaskModal isOpen={isCreateTaskModalOpen} onClose={this.handleCreateTaskModalClose} />
        <CreateColumnModal
          isOpen={isCreateColumnModalOpened}
          onClose={this.handleCreateColumnModalClose}
        />

        <CreateAutomationModal
          isOpen={isCreateAutomationModalOpened}
          onClose={this.handleCreateAutomationModalClose}
        />
      </>
    )
  }
}
