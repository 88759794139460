import * as React from 'react'
const EmailIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
    <path
      fill="#1F2A37"
      d="M20 4H4c-.53 0-1.04.225-1.414.625-.375.4-.586.943-.586 1.508v11.734c0 .566.21 1.108.586 1.508.375.4.884.625 1.414.625h16c.53 0 1.04-.225 1.414-.625.375-.4.586-.942.586-1.508V6.133c0-.565-.21-1.108-.586-1.508A1.939 1.939 0 0 0 20 4Zm-.547 2.133-7.41 6.703-7.487-6.703h14.897ZM4 17.867V8.42l6.8 6.08c.342.275.759.423 1.187.422a2.001 2.001 0 0 0 1.257-.457L20 8.421v9.446H4Z"
    />
  </svg>
)
export default EmailIcon
