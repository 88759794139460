import React from 'react';
import { Link } from 'react-router-dom';
import Services from '../../Services.js';
import { withRouter } from 'react-router';

const logoImg = `${process.env.PUBLIC_URL}/image/logo.png`;

class ForgotPassword extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      email: '',
    };

    this.services = new Services();

    this.showPassword = this.showPassword.bind(this);
    this.sendLink = this.sendLink.bind(this);
    this.changeInput = this.changeInput.bind(this);
  }

  showPassword(e) {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  }
  sendLink = () => {
    if (this.state.email) {
      const hrefURL = window.location.href;
      let url = "admin/forgotPassword";
      var data = {
        email: this.state.email,
        resetroute: hrefURL.replace('login', 'reset')
      }

      this.services.login(data, url, (res) => {
        if (res.status === 200) {
          this.services.successToast('Link send to your email');
        }
        else {
          this.services.errorToast(res.message);
        }
      })
    }
    else {
      this.services.errorToast("Email is required!");
    }

  }
  changeInput(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return (

      <>
        {/* <div className="login_logo_div">
        <a className="" href="index.html"><img src={logoImg} alt="logo" /></a>
      </div> */}
        <div className="login_form_wrap">
          <div className="login_form_inner">
            <div className="login_logo_div ml-0 pb-4">
              <a className="" href="index.html"><img src={logoImg} alt="logo" /></a>
            </div>
            <div className="login_head">
              <h1>Sign in to BMS</h1>
              <p className="mb-md-0 fw-500">Please enter your email to proceed.</p>
            </div>
            <form>
              <div className="form-group">
                <label>EMAIL ADDRESS</label>
                <input className="form-control" placeholder="" name="email" id="email" type="email" onChange={this.changeInput} />
              </div>


              <Link className="btn btn-secondary" onClick={this.sendLink}>Send link</Link>
              {/* <div className="login_bottom_content text-center">Dont’ have an account?  */}
              {/* <Link className="c_orange" to="/register"> Sign up</Link></div> */}

            </form>
          </div>
        </div>

      </>



    );
  }
}

export default withRouter(ForgotPassword)