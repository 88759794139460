import { GLOBAL_QUERIES } from "@src/lib/global-queries"
import { useQuery } from "@tanstack/react-query"
import { EmailService } from "../services/email.service"
import { GetSyncedEmailsParams } from "../types/email.type"

export const useGetUniformEmailList = (params: GetSyncedEmailsParams) => {

  const getUniformEmail = (params: GetSyncedEmailsParams) => {
    const emailService = new EmailService()
    return emailService.getLocalEmailsSyncedWithOutlookEmails(params)
  }


  const query = useQuery({ queryKey: [GLOBAL_QUERIES.UNIFORM_EMAILS.GET, params], queryFn: () => getUniformEmail(params), keepPreviousData: true })

  return query
}