import { useMutation } from "@tanstack/react-query"
import { WipAttachmentService } from "../services/wip-attachment.service"

export const useCreateWipAttachment = () => {

  const createAttachment = (wipId: number, file: File) => {
    const wipAttachmentService = new WipAttachmentService()
    return wipAttachmentService.createAttachment(wipId, file)
  }

  const mutation = useMutation({ mutationFn: ({ wipId, file }: { wipId: number, file: File }) => createAttachment(wipId, file) })

  return mutation

}

export const useCreateWipAttachmentBulk = () => {

  const createAttachmentBulk = (wipId: number, files: File[]) => {
    const wipAttachmentService = new WipAttachmentService()
    return wipAttachmentService.createAttachmentBulk(wipId, files)
  }

  const mutation = useMutation({ mutationFn: ({ wipId, files }: { wipId: number, files: File[] }) => createAttachmentBulk(wipId, files) })

  return mutation
}