import { editCommercialUncoveredFormEnum } from '../actionTypes';

export const editCommercialUncoveredFormReducer = function (
  state = { data: [], loading: false, error: '' },
  action,
) {
  switch (action.type) {
    case editCommercialUncoveredFormEnum.EDIT_COMMERCIAL_UNCOVERED_FORM_SUCCESS:
      return { loading: false, error: '', data: action.payload };
    case editCommercialUncoveredFormEnum.IS_LOADING_EDIT_COMMERCIAL_UNCOVERED_FORM:
      return { loading: true, error: '', data: [] };
    case editCommercialUncoveredFormEnum.EDIT_COMMERCIAL_UNCOVERED_FORM_ERROR:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
