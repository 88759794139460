import { PROTECT_COLLECT } from './../actions/protectCollectAction';
  
  const initialState = {
    error: null
  };
  
   const protectCollectReducer = (state = initialState, action) => {
    switch (action.type) {
      case PROTECT_COLLECT:
        
        return {...state}
      default:
        return state;
    }
  };
  

  export default protectCollectReducer;