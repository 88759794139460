export const previewModalStyles = {
  container: {
    width: '100%',
    maxWidth: '1000px',
    height: '80vh',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'transparent',
    minHeight: '250px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  pdfContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
  },

  buttonsContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '10px',
    marginBottom: '25px',
  },

  iframe: {
    width: '100%',
    maxWidth: '1000px',
    height: '100%',
    maxHeight: '80vh',
    border: 'none',
    overflowX: 'hidden',

    '&::-webkit-scrollbar': {
      border: 'none',
      width: '3px',
      height: '5px',
      padding: '5px 0',
    },

    '&::-webkit-scrollbar-track': {
      backgroundColor: '#fff',
      border: 'none',
      height: '10px',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#D4D6E0',
      width: '2px',
      borderRadius: '4px',
      cursor: 'pointer',
    },
  },
};
