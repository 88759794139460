import FormInput from '@components/inputs/form-input/form-input.component'
import FormSelect from '@components/inputs/form-select/form-select.component'
import { AustralianState, STATE_OPTIONS } from '@src/lib/select-options.const'
import { BaseFormProps, SelectOption } from '@src/types/component-types'
import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import styles from './property-address.module.scss'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'

export interface AddressFormValues {
  addressLine1: string | null
  addressLine2: string | null
  addressLine3: string | null
  postcode: string | null
  country: string | null
  suburb: string | null
  state: SelectOption<AustralianState> | null
}

const propertyAddressDefaultValues: AddressFormValues = {
  addressLine1: null,
  addressLine2: null,
  addressLine3: null,
  postcode: null,
  country: null,
  suburb: null,
  state: null,
}

const AddressForm: FC<BaseFormProps<AddressFormValues>> = ({
  initialValues,
  loading = false,
  submitButtonTitle = 'Save',
  onSuccessSubmit,
}) => {
  const { control, register, reset, handleSubmit } = useForm<AddressFormValues>({
    defaultValues: {
      ...propertyAddressDefaultValues,
      ...initialValues,
    },
  })

  useEffect(() => {
    if (!initialValues) {
      return
    }

    reset({
      ...propertyAddressDefaultValues,
      ...initialValues,
    })
  }, [initialValues])
  return (
    <form className={styles.formWrapper} onSubmit={handleSubmit(onSuccessSubmit)}>
      <FormInput {...register('addressLine1')} inputStyle="outlined" label="Address Line 1" />
      <FormInput {...register('addressLine2')} inputStyle="outlined" label="Address Line 2" />
      <FormInput {...register('addressLine3')} inputStyle="outlined" label="Address Line 3" />
      <div className={styles.row}>
        <FormInput {...register('suburb')} inputStyle="outlined" label="Suburb" />
        <FormSelect
          control={control}
          style="outlined"
          name="state"
          label="State"
          options={STATE_OPTIONS}
        />
      </div>
      <div className={styles.row}>
        <FormInput {...register('postcode')} inputStyle="outlined" label="Postcode" />
        <FormInput {...register('country')} inputStyle="outlined" label="Country" />
      </div>
      <div className={styles.submitButtonWrapper}>
        <SubmitButton buttonType="submit" loading={loading}>
          {submitButtonTitle}
        </SubmitButton>
      </div>
    </form>
  )
}

export default AddressForm
