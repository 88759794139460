import { FC, useState } from 'react'
import { DebtorLegalDefendant } from '../../../types/debtor-legal.type'
import { useModal } from '@src/hooks/useModal'
import { BaseListProps } from '@src/types/component-types'
import DebtorLegalDefendantListItem from './debtor-defendant-list-item.component'
import EditDebtorLegalDefendantModal from '../../modals/debtor-defendant-modal/edit-debtor-defendant-modal.component'

const DebtorLegalDefendantList: FC<BaseListProps<DebtorLegalDefendant>> = ({
  entities: debtorLegalDefendants,
}) => {
  const [isOpen, handleOpen, handleClose] = useModal(false)
  const [selectedDebtorLegalDefendant, setSelectedDebtorLegalDefendant] =
    useState<DebtorLegalDefendant | null>(null)

  const handleEditClick = (debtorLegalDefendant: DebtorLegalDefendant) => {
    setSelectedDebtorLegalDefendant(debtorLegalDefendant)
    handleOpen()
  }

  return (
    <div>
      {debtorLegalDefendants.map((debtorLegalDefendant) => {
        return (
          <DebtorLegalDefendantListItem
            onEditClick={handleEditClick}
            entity={debtorLegalDefendant}
            key={debtorLegalDefendant.id}
          />
        )
      })}
      {selectedDebtorLegalDefendant ? (
        <EditDebtorLegalDefendantModal
          entity={selectedDebtorLegalDefendant}
          isOpen={isOpen}
          onClose={handleClose}
        />
      ) : null}
    </div>
  )
}

export default DebtorLegalDefendantList
