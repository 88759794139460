import Table from '@components/table/table.component'
import { WipLegalTableProps } from '../../../types/debtor-legal.type'
import { FC } from 'react'
import WipLegalTableItem from './wip-legal-table-item.component'

const WipLegalTable: FC<WipLegalTableProps> = ({
  debtorLegals,
  debtors,
  wipId,
  loading = false,
}) => {
  const notFound = debtorLegals.length === 0 && !loading
  return (
    <Table
      headers={[
        'ID#',
        'LOGGED',
        'DEBTORS',
        'COURT LOCATION',
        'CLAIM NUMBER/S',
        'JUDGMENT DATE',
        'REMOVE',
      ]}
      bodyHeight={loading || notFound ? 5 * 54 : debtorLegals.length * 54}
      paginated={false}
      loading={loading}
      notFound={notFound}
    >
      {debtorLegals.map((debtorLegal) => (
        <WipLegalTableItem
          wipId={wipId}
          debtorLegal={debtorLegal}
          debtor={debtors.find(({ id }) => debtorLegal.debtorId === id) || null}
          key={debtorLegal.id}
        />
      ))}
    </Table>
  )
}

export default WipLegalTable
