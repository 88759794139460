export const link = [
  { id: 1,
    title: '1. ABR Search (Current and History)',
    link: 'https://abr.business.gov.au/',
    titleLink: 'ABN Lookup',
    checked: false,
    user: null,
  },
  { id: 2,
    title: '2. NSW Contractor License Search',
    link: 'https://www.onegov.nsw.gov.au/publicregister/#/publicregister/search/Trades',
    titleLink: 'OneGov Mobile | Public Register',
    checked: false,
    user: null,
  },
  { id: 3,
    title: '3 .QBCC Search ',
    link: 'http://www.onlineservices.qbcc.qld.gov.au/OnlineLicenceSearch/VisualElements/SearchBSALicenseeContent.aspx',
    titleLink: 'QBCC',
    checked: false,
    user: null,
  },
  { id: 4,
    title: '4. Gumtree (Names & Numbers) ',
    link: 'https://www.gumtree.com.au/',
    titleLink: 'Gumtree Australia',
    checked: false,
    user: null,
  },
  { id: 5,
    title: '5. Person Lookup – Also look at default Property.  ',
    link: 'https://personlookup.com.au/',
    titleLink: 'Person Lookup',
    checked: false,
    user: null,
  },
  { id: 6,
    title: '6. WA Trades Check.',
    link: 'http://www.commerce.wa.gov.au/consumer-protection/consumer-protection-licence-and-registration-search',
    titleLink: 'Consumer Protection licence and registration search',
    checked: false,
    user: null,
  },
  { id: 7,
    title: '7. Real Estate Listings (Address).',
    link: 'http://www.realestate.com.au/',
    titleLink: 'Real Estate, Property & Homes for Sale - realestate.com.au',
    checked: false,
    user: null,
  },
  { id: 8,
    title: '8. Linkedin.',
    link: 'http://www.linkedin.com/',
    titleLink: 'LinkedIn: Log In or Sign Up',
    checked: false,
    user: null,
  },
  { id: 9,
    title: '9. Licence Trades.Australia Wide',
    link: 'https://www.licensedtrades.com.au/',
    titleLink: 'Licensed Trades - Lookup or Check a Tradesman Licence',
    checked: false,
    user: null,
  },

  { id: 10,
    title: '10. Barclay Residential Data Base',
    link: '',
    titleLink: '',
    checked: false,
    user: null,
  },

  { id: 11,
    title: '11. Australia Lookup',
    link: 'http://www.australialookup.com/',
    titleLink: 'White Pages® - Search for an Australian Business, Government Department or Person',
    checked: false,
    user: null,
  },

  { id: 12,
    title: '12. Reverse Australia ',
    link: 'https://www.reverseaustralia.com/',
    titleLink: 'Reverse Australia - Reverse Phone Lookup',
    checked: false,
    user: null,
  },

  { id: 13,
    title: '13. Pipl Search ',
    link: 'https://pipl.com/',
    titleLink: 'Pipl Search',
    checked: false,
    user: null,
  },

  { id: 14,
    title: '14. Google.(Name/Emails/Addresses/Po Box/Contact numbers etc.) ',
    link: 'https://googl.com/',
    titleLink: '',
    checked: false,
    user: null,
  },

  { id: 15,
    title: '15. White Pages/Yellow Pages ',
    link: 'http://www.whitepages.com.au/',
    titleLink: 'White Pages® - Search for an Australian Business, Government Department or Person',
    checked: false,
    user: null,
  },

  { id: 16,
    title: '16. AEC Electoral Role Online - Confirmation Only ',
    link: 'https://check.aec.gov.au/',
    titleLink: 'Check my enrolment',
    checked: false,
    user: null,
  },
];
