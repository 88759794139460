import { Company } from '@containers/Setting/components/Contacts/types/contact-list.type'
import { useTaskContext } from '@src/context/task-context'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { FC } from 'react'
import { getEmailTypes } from '../../../../../../../Form/RecoveryForm/service.js'
import NotificationModalHeader from '../notification-modal-header/notification-modal-header.component'
import { TaskProps } from '../../../types.js'
import { toast } from 'react-toastify'
import EmailSendingModalWithRecipients from '@components/notification-modals/email-sending-modal-with-recipients/email-sending-modal-with-recipients.component'
import { formatAssigneName, validateEmail } from '../../../utils'
import { useUserContext } from '@src/context/user-context'
import moment from 'moment'
import {
  ATTRIBUTES_SUBJECT_DELIMITER,
  CLIENT_ATTRIBUTE_KEYWORD,
} from '@containers/Inbox/view-email-page/const/view-email-page.const'
import { encryptXOR } from '@containers/Inbox/view-email-page/components/utils/xor-encryption.utils'
import { REACT_APP_XOR_ENCRYPTION_KEY } from '@src/lib/global-variables'
import { NOTE_QUERY } from '@containers/Setting/components/Contacts/Notes/const/note-query.const'
import { useRefreshEmails } from '@containers/Setting/components/Contacts/Notes/hooks/refresh-emails.hook'

interface SalesSendMailModalProps {
  task?: TaskProps
  isOpen: boolean
  onClose: () => void
}

const SalesSendMailModal: FC<SalesSendMailModalProps> = ({ isOpen, onClose, task }) => {
  const { mutateAsync } = useRefreshEmails()
  const fetchEmailTemplate = async () => {
    const response = await getEmailTypes()
    const res = await response.json()
    const formattedTemplates =
      res?.data?.map((template) => ({ label: template?.title, value: template?.body })) || []

    return formattedTemplates
  }

  const {
    data: emailTemplates,
    isLoading,
    isRefetching,
  } = useQuery({
    queryKey: [GLOBAL_QUERIES.EMAIL_TEMPLATE.GET],
    queryFn: fetchEmailTemplate,
    enabled: isOpen,
  })
  const queryClient = useQueryClient()
  const { user } = useUserContext()
  const { currentClient } = useTaskContext()

  const transformClientContactsToOptions = (company: Company) => {
    const contacts = (company.contacts || []).filter(
      (contact) => contact.comm_email && validateEmail(contact.comm_email),
    )

    return contacts.map((contact) => ({
      label: `${contact.first_name} ${contact.last_name}`,
      value: {
        id: company.id,
        value: contact.comm_email,
      },
    }))
  }

  const onSuccessfulSend = async () => {
    toast.success('Email sent successfully')
    onClose()
    try {
      await mutateAsync({ wip_id: currentClient?.id })
      Promise.all([
        queryClient.invalidateQueries([GLOBAL_QUERIES.MAIL_FOLDER.GET]),
        queryClient.invalidateQueries([GLOBAL_QUERIES.GRAPH_API_MESSAGES.GET_BY_FOLDER_ID]),
        queryClient.invalidateQueries([NOTE_QUERY.EMAIL.GET]),
        queryClient.invalidateQueries([GLOBAL_QUERIES.COMBINED_NOTES.GET]),
      ])
    } catch (e) {
      console.error(e)
    }
  }

  const clientOptions = transformClientContactsToOptions(currentClient)

  return (
    <EmailSendingModalWithRecipients
      isOpen={isOpen && (!isLoading || !isRefetching)}
      onClose={onClose}
      clientOptions={clientOptions}
      templateOptions={emailTemplates}
      excludeFromTo={['staff', 'debtor']}
      additionalValuesInRequest={{ task_id: task?.id, company_id: currentClient?.id }}
      onSuccessfulSend={onSuccessfulSend}
      tagToSubject={`${ATTRIBUTES_SUBJECT_DELIMITER} ${CLIENT_ATTRIBUTE_KEYWORD}:${encryptXOR(
        currentClient?.id,
        REACT_APP_XOR_ENCRYPTION_KEY,
      )}`}
      replacementValues={{
        client_name: currentClient?.name || '',
        client_address: `${currentClient?.address[0]?.address_3} ${currentClient?.address[0]?.address_2}`,
        staff_name: formatAssigneName({
          first_name: user?.first_name || '',
          last_name: user?.last_name || '',
          isAssigned: false,
        }),
        date_long: moment(new Date()).format('dddd DD MMMM YYYY'),
        staff_title: user?.position || '',
        comb_name_amp: 'John Doe',
      }}
    >
      {task ? <NotificationModalHeader task={task} /> : null}
    </EmailSendingModalWithRecipients>
  )
}

export default SalesSendMailModal
