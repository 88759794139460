import { Wip } from "@containers/Recovery/Wip/types/wip.type"
import { TaskProps } from "@containers/Setting/components/Contacts/taskboard/types"
import { FC } from "react"


export enum SpeedDialSelectOption {
  PaymentTracking = 'PaymentTracking',
  SendEmail = 'SendEmail',
  SendSms = 'SendSms',
  MakeCall = 'MakeCall',
  SendInvitation = 'SendInvitation',
}

export interface SpeedDialOption {
  label: string
  value: string
  icon: FC<{ className: string }>
}

export interface SpeedDialProps {
  options: SpeedDialOption[]
  verticalDirection?: 'top' | 'bottom'

  onSelect?: (option: SpeedDialOption) => void
}


export interface WipSpeedDialProps {
  wip: Wip
}

export interface WipTaskSpeedDialProps {
  wip: Wip
  task: TaskProps
}