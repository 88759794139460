import React, { useEffect, useState } from 'react';
import Select from 'react-select';

export const InvoiceDetails = (props) => {
  const {
    invoiceDetails,
    setInvoiceDetails,
    totalClaim,
    listFeeOptions,
    statusOptions,
  } = props;

  useEffect(()=>{
    const defaultSelectedListFee = listFeeOptions.find((val) => val.value === invoiceDetails.list_fee)
    setSelectedListFee(defaultSelectedListFee)
    const defaultSelectedStatus = statusOptions.find((val) => val.label === invoiceDetails.status)
    setSelectedStatus(defaultSelectedStatus)
  },[listFeeOptions,invoiceDetails,statusOptions])


  const [selectedListFee, setSelectedListFee] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const onChnageFeeHandler = (value) => {
    setSelectedListFee(value)
    setInvoiceDetails((prev) => ({ ...prev, list_fee: value.value }));
  };

  const onChnageStatusHandler = (value) => {
    setSelectedStatus(value)
    setInvoiceDetails((prev) => ({ ...prev, status: value.label }));
  };

  const onChnageRetainHanlder = (e) => {
    const value = e.target.value;
    setInvoiceDetails((prev) => ({ ...prev, retain: value }));
  };

  return (
    <>
      <div className="card__title">
        <h5 className="bold-text">Invoice</h5>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Total Claim</span>
        <div className="form__form-group-field">
          <input
            name="total_claim"
            placeholder="Total Claim"
            type="number"
            defaultValue={totalClaim}
            disabled
          />
        </div>
        <span className="form__form-group-error" />
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">List Fee</span>
        <div className="form__form-group-field">
          <Select
            name="recovery-list-fee"
            value={selectedListFee}
            onChange={onChnageFeeHandler}
            options={listFeeOptions}
            classNamePrefix="react-select"
            className="react-select"
          />
        </div>
        <span className="form__form-group-error" />
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Retain</span>
        <div className="form__form-group-field">
          <input
            name="retain"
            type="text"
            placeholder="Retain"
            onChange={onChnageRetainHanlder}
            value={invoiceDetails.retain}
          />
        </div>
        <span className="form__form-group-error" />
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Invoice Status</span>
        <div className="form__form-group-field">
          <Select
            name="urf_invoice_status"
            value={selectedStatus}
            onChange={onChnageStatusHandler}
            options={statusOptions}
            classNamePrefix="react-select"
            className="react-select"
          />
        </div>
      </div>
    </>
  );
};
