import { REACT_APP_API_URL } from "@src/lib/global-variables";
import { UserType } from "@src/types/user";
import axios from "axios";

export class StaffService {

  async getStaffById(id: number) {
    const res = await axios.get<UserType>(`${REACT_APP_API_URL}/staff/${id}`)
    return res.data;
  }
}