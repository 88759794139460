import SlideModal from '@components/slide-modal'
import { FC, useEffect } from 'react'
import { Oval } from 'react-loader-spinner'
import { toast } from 'react-toastify'
import cls from 'classnames'
import { ViewClaimDetailsNoteModalProps } from '../../../types/claim-detail.type'
import { useGetClaimDetailNote } from '../../../hooks/get-claim-detail-note.hook'
import SubSection from '@containers/Recovery/Wip/components/UI/sub-section/sub-section.component'
import { Header, SubHeader, Text } from '@containers/Recovery/Wip/components/UI/text/text.component'
import { format } from 'date-fns'
import { formatCurrency } from '../../../../../../utils/format-currency'
import styles from './view-claim-detail-note-modal.module.scss'

const ViewClaimDetailsNoteModal: FC<ViewClaimDetailsNoteModalProps> = ({
  claimDetailsNoteId,
  isOpen,
  onClose,
}) => {
  const { data, isLoading, isRefetching, isError } = useGetClaimDetailNote(
    claimDetailsNoteId,
    { join: 'staff_created' },
    !!claimDetailsNoteId && isOpen,
  )

  useEffect(() => {
    if (isError) {
      toast.error('Something went wrong')
    }
  }, [isError])

  if (isError) return null

  const isTaskNoteLoading = isLoading || isRefetching

  const staffFullName = `${data?.staff_created?.first_name || ''} ${data?.staff_created?.last_name || ''}`;

  console.log('data', data)

  return (
    <SlideModal
      isOpen={isOpen}
      onClose={onClose}
      style={{ width: '675px' }}
      layoutStyle={{ zIndex: 1000, backgroundColor: 'black' }}
    >
      <div className={styles.modalWrapper}>
        <span onClick={onClose} className={cls('lnr lnr-indent-decrease', styles.closeIcon)}/>
        {isTaskNoteLoading ? (
          <Oval
            visible={true}
            height="60"
            width="60"
            color="blue"
            secondaryColor="blue"
            ariaLabel="oval-loading"
            wrapperStyle={{
              position: 'absolute',
              top: '50%',
              right: '50%',
              transform: 'translate(50%,-50%)',
            }}
          />
        ) : null}

        {!isTaskNoteLoading && data ? (
          <div>
            <SubSection>
              <Header mb={4}>Claim Details Note</Header>
            </SubSection>
            <SubSection p={2}>
              <SubHeader>Created By:</SubHeader>
              <Text>{staffFullName}</Text>
            </SubSection>
            <SubSection p={2}>
              <SubHeader>Created On:</SubHeader>
              <Text>{format(data.created_at, 'dd/MM/yyyy')}</Text>
            </SubSection>
            <SubSection p={2}>
              <SubHeader>Total:</SubHeader>
              <Text>{formatCurrency(data.total_amount)}</Text>
            </SubSection>
            <SubSection p={2}>
              <SubHeader>Description:</SubHeader>
              <Text>{data.description}</Text>
            </SubSection>
          </div>
        ) : null}
      </div>
    </SlideModal>
  )
}

export default ViewClaimDetailsNoteModal
