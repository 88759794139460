import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { generateInvoiceFormInitialValues } from '../../../../../../shared/utils/generateInvoiceFormInitialValues';
import { generateInvoiceFormValidationSchema } from '../../../../../../shared/validators';
import InvoiceForm from '../InvoiceForm';
import Loader from '../../../../../loading/loading';

import { useDispatch, useSelector } from 'react-redux';
import { getInvoiceTemplatesList, postInvoice } from '../../../../../../redux/actions';
import { getCompaniesByIdData } from '../../../../../../redux/selector/selector';

import { IncoveredStyles } from './styles';

const AddInvoice = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const clientData = useSelector(getCompaniesByIdData) || {};
  const clientId = clientData.id;
  const clientAddress = clientData.address?.[0];
  const clientEmail = clientData?.contacts?.[0]?.comm_email;

  useEffect(() => {
    if (clientId !== undefined && clientAddress && loading) {
      setFieldValue(`bill_to.client_id`, clientData?.id || 0);
      setFieldValue(`bill_to.name`, clientData?.name || null);
      setFieldValue(`bill_to.email`, clientEmail || null);
      setFieldValue(`bill_to.address_1`, clientAddress?.address_1 || null);
      setFieldValue(`bill_to.address_2`, clientAddress?.address_2 || null);
      setFieldValue(`bill_to.address_3`, clientAddress?.address_3 || null);
      setFieldValue(`bill_to.suburb`, clientAddress?.suburb || null);
      setFieldValue(`bill_to.state`, clientAddress?.state || null);
      setFieldValue(`bill_to.post_code`, clientAddress?.post_code || null);
      setFieldValue(`bill_to.country`, clientAddress?.country || null);
      setLoading(false);
    }
  }, [clientId]);

  useEffect(() => {
    dispatch(getInvoiceTemplatesList());
  }, []);

  const { values, errors, touched, submitForm, setFieldValue, handleChange, isValid } = useFormik({
    initialValues: generateInvoiceFormInitialValues,
    validationSchema: generateInvoiceFormValidationSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: () => {
      dispatch(postInvoice(values))
        .then(() => {
          toast.success('Sent successfully');
        })
        .catch(() => {
          toast.error('Something went wrong!');
        });
    },
  });

  if (loading) {
    return <Loader />;
  }

  return (
    <Grid item md={12} lg={12}>
      <h3 className="page-title mb-5">Generate Invoice</h3>
      <Grid item sx={IncoveredStyles?.card}>
        <InvoiceForm
          handleChange={handleChange}
          submitForm={submitForm}
          setFieldValue={setFieldValue}
          values={values}
          errors={errors}
          touched={touched}
          isValid={isValid}
        />
      </Grid>
    </Grid>
  );
};

export default AddInvoice;
