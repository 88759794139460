import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import { REACT_APP_API_URL } from '@src/lib/global-variables'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { toast } from 'react-toastify'
import { getEmailTypes } from '../../../../Form/RecoveryForm/service.js'
import { Automation, AutomationColumn } from '../taskboard/types.js'
import { useTaskContext } from '@src/context/task-context'
import React from 'react'

export const fetchAutomations = async ({ clientId, wip_id }: { clientId?: number, wip_id?: number }) => {
  try {
    const params = {
      filter: clientId ? `clientId||$eq||${clientId}` : `wip_id||$eq||${wip_id}`,
      limit: 10000
    }
    const response = await axios.get(`${REACT_APP_API_URL}/automations`, { params })
    return response?.data.data || []
  } catch (error) {
    console.log(error)
    toast.error('Error fetching automations')
  }
}

export const useGetAutomations = ({ clientId, wip_id }: { clientId?: number, wip_id?: number }) => {
  const [automations, setAutomations] = React.useState<Automation[]>([])
  const [columns, setColumns] = React.useState<AutomationColumn[]>([])

  const { fetchColumns } = useTaskContext()

  useQuery<Automation[]>(
    [GLOBAL_QUERIES.AUTOMATIONS.GET],
    () => fetchAutomations({ clientId, wip_id }),
    {
      onSuccess: (data) => {
        setAutomations(data)
      },
    },
  )

  useQuery<AutomationColumn[]>([GLOBAL_QUERIES.COLUMNS.GET], () => fetchColumns(Number(clientId)), {
    onSuccess: (data) => {
      setColumns(data)
    },
    enabled: !!clientId || !!wip_id
  })
  return { automations, columns }
}

export const fetchEmailTemplate = async () => {
  const response = await getEmailTypes()
  const res = await response.json()
  const formatedTemplates =
    res?.data?.map((template) => ({ label: template?.title, value: {body: template?.body, id: template?.id} })) || []

  return formatedTemplates
}
