import { deleteCommercialUncoveredByIdEnum } from '../actionTypes';

export const deleteCommercialUncoveredByIdReducer = function (
  state = { data: [], loading: false, error: '' },
  action,
) {
  switch (action.type) {
    case deleteCommercialUncoveredByIdEnum.DELETE_COMMERCIAL_UNCOVERED_BY_ID_SUCCESS:
      return { loading: false, error: '', data: action.payload };
    case deleteCommercialUncoveredByIdEnum.IS_LOADING_DELETE_COMMERCIAL_UNCOVERED_BY_ID:
      return { loading: true, error: '', data: [] };
    case deleteCommercialUncoveredByIdEnum.DELETE_COMMERCIAL_UNCOVERED_BY_ID_ERROR:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
