import FormSelect from '@components/inputs/form-select/form-select.component'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { FC } from 'react'
import { useForm } from 'react-hook-form'
import styles from './add-debtor-legal-form.module.scss'
import { useCreateDebtorLegalMutation } from '../../../hooks/mutations/crud-debtor-legal.hook'
import { toast } from 'react-toastify'
import { AddDebtorLegalFormProps, AddDebtorLegalFormValues } from '../../../types/debtor-legal.type'
import { joiResolver } from '@hookform/resolvers/joi'
import { addDebtorLegalSchema } from '../../../schemas/legal.schemas'

const AddDebtorLegalForm: FC<AddDebtorLegalFormProps> = ({
  debtorsOptions,
  wipId,
  onSuccessfulSubmit,
}) => {
  const { mutateAsync, isLoading } = useCreateDebtorLegalMutation()
  const { control, handleSubmit } = useForm<AddDebtorLegalFormValues>({
    resolver: joiResolver(addDebtorLegalSchema),
  })

  const onSubmit = async (data: AddDebtorLegalFormValues) => {
    try {
      const debtorLegal = await mutateAsync({
        data: { wipId: wipId, debtorId: data.debtor.value },
        params: { join: ['judgmentDates', 'claimNumbers'] },
      })

      onSuccessfulSubmit && onSuccessfulSubmit(debtorLegal)
    } catch (error) {
      toast.error('Failed to add debtor')
      console.error(error)
    }
  }

  return (
    <form className={styles.formWrapper} onSubmit={handleSubmit(onSubmit)}>
      <FormSelect label="Debtors" control={control} name="debtor" options={debtorsOptions} />
      <SubmitButton className={styles.submitButton} buttonType="submit" loading={isLoading}>
        Add Debtor
      </SubmitButton>
    </form>
  )
}

export default AddDebtorLegalForm
