import React from 'react';
import { Grid } from '@mui/material';
import { TextEditor } from '../../../../../shared/components/TextEditor/TextEditor';
import FormInput from '../../../../../shared/components/FormInput/FormInput';
import FormSelect from '../../../../../shared/components/FormSelect/FormSelect';
import AppButton from '../../../../../shared/components/AppButton/AppButton';
import { templateTypesOptions } from './types';

import { invoiceTemplatesFormStyles } from './styles';

export const InvoiceTemplateForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleEditorChange,
  editorValue,
  handleSubmit,
  handleClose,
  loading = false,
}) => {
  const { buttonsWrapper } = invoiceTemplatesFormStyles;
  return (
    <Grid>
      <FormSelect
        label="Type:"
        name="type"
        placeholder="Select template type"
        options={templateTypesOptions}
        changeHandler={handleChange}
        value={values?.type}
        error={errors?.type}
        touched={touched?.type}
        required
        bold
        column
      />
      <FormInput
        label="Title:"
        name="title"
        changeHandler={handleChange}
        value={values?.title}
        error={errors?.title}
        touched={touched?.title}
        required
        bold
        column
      />
      <FormInput
        label="Subject:"
        name="subject"
        changeHandler={handleChange}
        value={values?.subject}
        error={errors?.subject}
        touched={touched?.subject}
        required
        bold
        column
      />
      {values?.type &&
        (values?.type === 'Description' ? (
          <FormInput
            label="Description:"
            name="body"
            changeHandler={(e) => handleEditorChange(e.target.value)}
            value={editorValue}
            error={errors?.body}
            required
            bold
            column
            multilined
          />
        ) : (
          <TextEditor value={editorValue} onEditorChange={handleEditorChange} />
        ))}
      <Grid sx={buttonsWrapper}>
        <AppButton content="Save" buttonStyle="primary" onClick={handleSubmit} disabled={loading} />
        <AppButton content="Cancel" buttonStyle="secondary" onClick={handleClose} />
      </Grid>
    </Grid>
  );
};
