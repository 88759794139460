import React from 'react';
import {withRouter} from 'react-router';
import {
  Card, CardBody, Col
} from 'reactstrap';
// import MessageTextOutlineIcon from 'mdi-react/MessageTextOutlineIcon';
import Services from '../../../../shared/Services.js';
import { UserContext } from '../../../../context/user-context';

const Ava = `${process.env.PUBLIC_URL}/image/user.jpg`;

class ProfileMain extends React.Component {
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.state = {
      profilePhotoView: '',
    };

    this.handleClick = this.handleClick.bind(this);
    this.uploadPic = this.uploadPic.bind(this);
    this.services = new Services();
  }

  handleClick() {
    this.React.forwardRef().fileUploader.click();
  }

  uploadPic = (event) => {
    let file = event.target.files[0];

    this.services.uploadImage(file, (res) => {
      if (res.status === 200) {
        this.setState({profilePhotoView: URL.createObjectURL(file)});
        const {user} = this.context;
        const data = {
          id: user.id,
          profilePic: JSON.stringify(res.data.data.profile)
        };
        this.services.editProfile(data, (res) => {
          if (res.status === 200) {
            // localStorage.setItem('user', JSON.stringify(res.data.data));
            this.services.successToast('Profile photo upload successfully');
          } else {
            this.services.errorToast(res.message);
          }
        })
      } else {
        this.services.errorToast(res.message);
      }
      this.setState({profileLoder: false})
    })
  }

  render() {
    const {user} = this.context;
    const {profilePhotoView} = this.state;
    return (
      <Col md={12} lg={12} xl={12}>
        <Card>
          <CardBody className="profile__card">
            <div className="profile__information">
              <div className="profile__avatar">
                <img
                  src={profilePhotoView ? profilePhotoView : user.profile_picture && user.profile_picture ? user.profile_picture : Ava}
                  onClick={this.handleClick} alt="avatar"/>
                <input type="file" id="file" ref="fileUploader" style={{display: "none"}} onChange={this.uploadPic}/>
              </div>
              <div className="profile__data">
                <p className="profile__name">{user.first_name} {user.last_name}</p>
                {/* <p className="profile__work">Senior Account Manager</p> */}
                <p className="profile__contact">{user.comms_email}</p>
                <p className="profile__contact" dir="ltr">{user.comms_ph_home}</p>
                {/* <Button color="primary" className="icon profile__btn"><p><MessageTextOutlineIcon /> Message</p></Button> */}
              </div>
            </div>
            <div className="profile__stats">
              <div className="profile__stat">
                <p className="profile__stat-number">05</p>
                <p className="profile__stat-title">Projects</p>
              </div>
              <div className="profile__stat">
                <p className="profile__stat-number">24</p>
                <p className="profile__stat-title">Tasks</p>
              </div>
              <div className="profile__stat">
                <p className="profile__stat-number">12</p>
                <p className="profile__stat-title">Reports</p>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    )
  }
}

export default withRouter(ProfileMain);

