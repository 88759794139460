import { useMsal } from '@azure/msal-react'
import { useEffect } from 'react'
import { useHistory } from 'react-router'
import styles from './login-redirect-page.module.scss'
import { Oval } from 'react-loader-spinner'
import { useUserContext } from '@src/context/user-context'
import { REACT_APP_API_URL } from '@src/lib/global-variables'
import axios from 'axios'
import { toast } from 'react-toastify'

const LoginRedirectPage = () => {
  const { user, fetchUser } = useUserContext()
  const history = useHistory()
  const { accounts, inProgress, instance } = useMsal()

  const loginWithAzure = async (onErrorRedirect: string = '/login') => {
    const account = accounts[0]

    if (!account) {
      history.push('/login')
    }

    try {
      const authResult = await instance.acquireTokenSilent({ account, scopes: ['User.Read'] })
      await axios.post(
        `${REACT_APP_API_URL}/auth/azure-ad/login`,
        {},
        { headers: { Authorization: `Bearer ${authResult.accessToken}` }, withCredentials: true },
      )
      await fetchUser()
      history.push('/dashboard')
    } catch (e) {
      localStorage.clear()
      toast.error('Something went wrong with login. Please try again.')
      console.log(e)  
      history.push(onErrorRedirect)
    }
  }

  useEffect(() => {
    const isLoadingFinished = inProgress === 'none'
    const account = accounts[0]

    if (!isLoadingFinished) return

    if (!account && user) {
      history.push('/dashboard')
    }

    if (!account) {
      history.push('/login')
    }

    if (account && user) {
      loginWithAzure('/dashboard')
    }

    if (account && !user) {
      loginWithAzure()
    }
  }, [inProgress])

  return (
    <div className={styles.wrapper}>
      <Oval
        visible={true}
        height="40"
        width="40"
        color="#1e40af"
        secondaryColor="#1e40af"
        ariaLabel="oval-loading"
      />
    </div>
  )
}

export default LoginRedirectPage
