import Table from '@components/table/table.component'
import { FC } from 'react'
import { DebtorTableProps } from '../../../types/debtor.type'
import DebtorTableItem from './debtor-table-item.component'

const DebtorTable: FC<DebtorTableProps> = ({ debtors, wipId }) => {
  return (
    <Table
      headers={['ID #', 'LOGGED', 'DEBTORS', 'EMAIL', 'PHONE', 'REMOVE']}
      bodyHeight={debtors.length * 54}
      limit={debtors.length}
      paginated={false}
      selectable={true}
    >
      {debtors.map((debtor, index) => (
        <DebtorTableItem key={`debtor-table-item-${debtor.id}-${index}`} debtor={debtor} wipId={wipId} />
      ))}
    </Table>
  )
}

export default DebtorTable
