import CurrencyFormInput from '@components/inputs/form-input/currency-form-input.component'
import FormInput from '@components/inputs/form-input/form-input.component'
import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import styles from './wip-cost-form.module.scss'
import {
  WipCostFormProps,
  WipCostFormValues,
} from '@containers/Recovery/WipCost/types/wip-cost.type'
import { joiResolver } from '@hookform/resolvers/joi'
import { wipCostSchema } from '@containers/Recovery/WipCost/schemas/wip-cost.schemas'

const WipCostForm: FC<WipCostFormProps> = ({ initialValues, children, onSubmit }) => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset,
  } = useForm<WipCostFormValues>({
    defaultValues: initialValues,
    resolver: joiResolver(wipCostSchema),
  })

  useEffect(() => {
    if (!initialValues) return
    reset(initialValues)
  }, [initialValues])
  return (
    <form className={styles.wipCostFormWrapper} onSubmit={handleSubmit(onSubmit)}>
      <FormInput
        {...register('name')}
        error={errors.name?.message}
        label="Name"
        placeholder="name"
        size="small"
      />
      <CurrencyFormInput
        control={control}
        name="recoverableCost"
        label="Recoverable Cost"
        placeholder="$00.00"
        size="small"
      />
      <CurrencyFormInput
        control={control}
        name="unenforceableCost"
        label="Un-enforceable Cost"
        placeholder="$00.00"
        size="small"
      />
      {children}
    </form>
  )
}

export default WipCostForm
