import React, { ForwardedRef, forwardRef } from 'react'
import styles from './paginated-select.module.scss'
import { PaginatedSelectSubOptionProps } from './pagination-select.type'
import cls from 'classnames'

const PaginatedSelectSubOption = forwardRef<HTMLLIElement, PaginatedSelectSubOptionProps>(
  (
    { option, onSubOptionChange, selected }: PaginatedSelectSubOptionProps,
    ref: ForwardedRef<HTMLLIElement>,
  ) => {
    const handleSubOptionChange = () => {
      onSubOptionChange && onSubOptionChange({ option, value: !selected })
    }

    return (
      <li
        className={cls(styles.subOptionWrapper, styles.pointer)}
        onClick={handleSubOptionChange}
        ref={ref}
      >
        <input type="checkbox" checked={selected} onChange={() => {}} />
        <span>{option.label}</span>
      </li>
    )
  },
)

PaginatedSelectSubOption.displayName = 'PaginatedSelectSubOption'
export default PaginatedSelectSubOption
