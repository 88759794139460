import { Params } from "@src/types/service.type";
import { ClaimDetailNoteService } from "../services/claim-detail-note.service";
import { GLOBAL_QUERIES } from "@src/lib/global-queries";
import { useQuery } from "@tanstack/react-query";

export const useGetClaimDetailNote = (id: number | null, params: Params = {}, enabled = true) => {
  const getClaimDetailNote = (id: number | null, params: Params = {}) => { 
    if(!id) return
    const claimDetailNoteService = new ClaimDetailNoteService()
    return claimDetailNoteService.getClaimDetailNoteById(id, params)
  }

  const query = useQuery({
    queryKey: [GLOBAL_QUERIES.CLAIM_DETAILS_NOTES.GET_BY_ID, id],
    queryFn: () => getClaimDetailNote(id, params),
    staleTime: 1000 * 60 * 60, // 1 hour
    enabled
  })

  return query
}