import { SelectOption } from '@src/types/component-types'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import styles from './select-with-search.module.scss'
import RoundedArrow from '@src/icons/rounded-arrow'
import cls from 'classnames'

interface SelectWithSearchProps {
  searchValue?: string
  loading?: boolean
  notFound?: boolean
  options: SelectOption<number>[]
  onSearchValueChange?: (value: string) => void
  onSelect?: (value: number) => void
  onReachEnd?: () => void
}

const SelectWithSearch: FC<SelectWithSearchProps> = ({
  searchValue,
  options,
  loading,
  notFound,
  onSearchValueChange,
  onSelect,
  onReachEnd
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [innerSearchValue, setInnerSearchValue] = useState<string>(searchValue || '')
  const [innerSelectedOption, setInnerSelectedOption] = useState<SelectOption<number> | null>(null)

  useEffect(() => {
    if (!searchValue || searchValue === innerSearchValue) return

    setInnerSearchValue(searchValue)
  }, [searchValue])

  const changeSearchValue = (value: string) => {
    setInnerSearchValue(value)
    onSearchValueChange && onSearchValueChange(value)
  }

  const changeSelected = (option: SelectOption<number>) => {
    setInnerSelectedOption(option)
    setIsOpen(false)
    onSelect && onSelect(option.value)
  }

  const handleControlClick = () => {
    setIsOpen((prev) => !prev)
    changeSearchValue('')
  }
  
  const observer = useRef<IntersectionObserver>()

  const lastElementRef = useCallback(
    (node: HTMLDivElement) => {
      if (loading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && onReachEnd) {
          onReachEnd()
        }
      })
      if (node) observer.current.observe(node)
    },
    [options, loading],
  )

  return (
    <div className={styles.selectWrapper}>
      <div className={styles.controlWrapper} onClick={handleControlClick}>
        <div className={styles.inputWrapper}>
          <input
            className={styles.input}
            value={isOpen ? innerSearchValue : ''}
            placeholder={innerSelectedOption?.label || 'Select...'}
            onChange={(e) => changeSearchValue(e.target.value)}
          />
        </div>
        <button className={styles.selectButton} type="button">
          <RoundedArrow className={styles.arrowIcon} />
        </button>
      </div>

      {isOpen ? (
        <div className={styles.dropdownWrapper}>
          <div className={styles.dropdownList}>
            {options.map((option, index) => (
              <div
                className={cls(styles.dropdownItem, {
                  [styles.selected]: innerSelectedOption?.value === option.value,
                })}
                ref={options.length === index + 1 ? lastElementRef : null}
                onClick={() => changeSelected(option)}
                key={`option-item-${index}`}
              >
                {option.label}
              </div>
            ))}
            {loading ? <div className={styles.center}>...Loading</div> : null}
            {notFound ? <div className={styles.center}>Not found</div> : null}
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default SelectWithSearch
