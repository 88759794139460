import Modal from '@components/modal'
import styles from './create-column-modal.module.scss'

import { useForm, useWatch } from 'react-hook-form'

import LabelWrapper from '@components/label-wrapper'

import { useTaskContext } from '@src/context/task-context'
import { REACT_APP_API_URL } from '@src/lib/global-variables'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import Select from 'react-select'
import { IOption } from '../types'
import cls from 'classnames'
import { useEffect } from 'react'
import { AUTOMATION_COLUMN_CUSTOM_DATES, COLUMN_COLORS } from './utils'
import { joiResolver } from '@hookform/resolvers/joi'
import { CreateColumnScheme, CreateColumnSchemeForSales } from './validations'

interface IProps {
  isOpen: boolean
  onClose: () => void
  externalOnSubmit?: (data: ICreateFrom) => void | Promise<void>
}

interface ICreateFrom {
  title: string
  description: string
  columnColor: IOption | null
  is_cron: boolean
  amount_of_cron_days: number | null
}

const defaultValues: ICreateFrom = {
  description: '',
  title: '',
  columnColor: null,
  is_cron: false,
  amount_of_cron_days: null,
}

const CreateColumnModal = ({ isOpen, onClose = () => null, externalOnSubmit }: IProps) => {
  const { selectedCompanyState, taskMode, isRecovery, isSales, currentColumns, currentWip } = useTaskContext()
  const queryClient = useQueryClient()

  const { control, handleSubmit, reset, setValue } = useForm<ICreateFrom>({
    defaultValues,
    resolver: joiResolver(isSales ? CreateColumnSchemeForSales : CreateColumnScheme),
    mode: 'all',
  })
  const { columnColor, amount_of_cron_days } = useWatch({ control })

  const onSubmit = async (data: ICreateFrom) => {
    console.log(data)
    const dataToSend = {
      ...data,
      columnColor: data.columnColor?.value || null,
      clientId: Number(selectedCompanyState.id),
      mode: taskMode,
      step: currentColumns?.length + 1,
      order: currentColumns?.length + 1,
      wip_id: isRecovery ? currentWip || null : null,
      is_cron: data.amount_of_cron_days ? true : false,
      amount_of_cron_days: data.amount_of_cron_days,
    }
    try {
      const res = await fetch(`${REACT_APP_API_URL}/board-columns`, {
        method: 'POST',
        body: JSON.stringify({ ...dataToSend }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
      if (res?.status !== 201) throw new Error(await res.json())

      toast.success('Create column successfully')
      onClose()
    } catch (error: any) {
      console.log(error)
      toast.error(`Create column failed. ${error}`)
    }
    queryClient.invalidateQueries([GLOBAL_QUERIES.TASKSERVICE.GET, taskMode])
  }

  const setCustomTime = (time: number) => {
    setValue('amount_of_cron_days', time)
  }

  useEffect(() => {
    if (!isOpen) {
      reset(defaultValues)
    }
  }, [isOpen])
  useEffect(() => {
    if (isOpen && isRecovery) {
      setValue('amount_of_cron_days', AUTOMATION_COLUMN_CUSTOM_DATES[0])
    }
  }, [taskMode, isOpen])
  
  return (
    <Modal isOpen={isOpen} onClose={onClose} onSubmit={handleSubmit(externalOnSubmit || onSubmit)}>
      <div className={styles.wrapper} onClick={(e) => e.stopPropagation()}>
        <span>Create Column</span>
        <div className={styles.select}>
          <LabelWrapper<ICreateFrom>
            className={styles.labelWrapper}
            control={control}
            name={'title'}
            label="Column Title"
          >
            {({ onChange, value }) => {
              return (
                <input
                  type="text"
                  className="react-select"
                  placeholder="Task Template 1. Title Example"
                  value={value as string}
                  onChange={onChange}
                />
              )
            }}
          </LabelWrapper>
        </div>
        <div className={styles.select}>
          <LabelWrapper<ICreateFrom>
            className={styles.labelWrapper}
            control={control}
            name={'description'}
            label="Column Description"
          >
            {({ onChange, value }) => {
              return (
                <textarea
                  className="react-select"
                  placeholder="Description Example"
                  value={value as string}
                  onChange={onChange}
                />
              )
            }}
          </LabelWrapper>
        </div>
        {isRecovery && (
          <div className={styles.dayIndicator}>
            <span>Selected cron time job interval: {amount_of_cron_days}</span>
          </div>
        )}
        {isRecovery && (
          <>
            <div className={cls(styles.selectTime)}>
              <div>
                {' '}
                {AUTOMATION_COLUMN_CUSTOM_DATES.map((dayInterval) => {
                  return (
                    <div
                      onClick={() => setCustomTime(dayInterval)}
                      className={cls(styles.customDate, {
                        [styles.activeDate]: dayInterval === amount_of_cron_days,
                      })}
                      key={dayInterval}
                    >
                      {dayInterval} {dayInterval > 1 ? 'Days' : 'Day'}
                    </div>
                  )
                })}
              </div>
              <LabelWrapper<ICreateFrom>
                className={cls(styles.labelWrapper, styles.addCustomTime)}
                control={control}
                name={'amount_of_cron_days'}
                label="Custom amount"
              >
                {({ onChange, value }) => {
                  return (
                    <input
                      type="number"
                      className="react-select"
                      value={value as number}
                      onChange={onChange}
                    />
                  )
                }}
              </LabelWrapper>
            </div>
          </>
        )}
        <div className={styles.select}>
          <LabelWrapper<ICreateFrom>
            className={styles.labelWrapper}
            control={control}
            name={'columnColor'}
            label="Column Color"
          >
            {({ onChange, value }) => {
              return (
                <Select
                  className="react-select"
                  placeholder="Column Color"
                  value={value as string}
                  onChange={onChange}
                  options={COLUMN_COLORS}
                />
              )
            }}
          </LabelWrapper>
        </div>

        {columnColor && (
          <div className={styles.colorContainer}>
            <span>
              {' '}
              Selected color:{' '}
              <span
                className={cls(styles.text, {
                  [styles[
                    columnColor.label?.split(' ').join('').toLowerCase() as keyof typeof styles
                  ]]: columnColor,
                })}
              >
                {' '}
                {columnColor.label}
              </span>
            </span>
            <span
              className={cls(styles.border, {
                [styles[
                  columnColor.label?.split(' ').join('').toLowerCase() as keyof typeof styles
                ]]: columnColor,
              })}
            ></span>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default CreateColumnModal
