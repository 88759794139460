import axios from "axios";
import { GRAPH_API_ENDPOINT } from "../const/graph-api.const";
import { BaseGraphApiParams, CreateGraphApiRule, GraphApiResponse, GraphApiRule } from "../types/graph-api-mail.type";
import { BaseGraphApiService } from "./base-graph-api.service";

export class GraphApiRuleService extends BaseGraphApiService {

  constructor() {
    super();
  }

  async getRules(accessToken: string, params: BaseGraphApiParams = {}): Promise<GraphApiRule[]> {
    const headers = this.getAuthorizationHeaders(accessToken);
    const response = await axios.get<GraphApiResponse<GraphApiRule[]>>(`${GRAPH_API_ENDPOINT.MESSAGE_RULES}`, { headers, params })
    return response.data.value;
  }

  async getRule(accessToken: string, ruleId: string): Promise<GraphApiRule> {
    const headers = this.getAuthorizationHeaders(accessToken);
    const response = await axios.get<GraphApiRule>(`${GRAPH_API_ENDPOINT.MESSAGE_RULES}/${ruleId}`, { headers });
    return response.data;
  }

  async createRule(accessToken: string, rule: CreateGraphApiRule): Promise<GraphApiRule> {
    const headers = this.getAuthorizationHeaders(accessToken);
    const response = await axios.post<GraphApiRule>(`${GRAPH_API_ENDPOINT.MESSAGE_RULES}`, rule, { headers });
    return response.data;
  }

  async updateRule(accessToken: string, ruleId: string, rule: GraphApiRule): Promise<GraphApiRule> {
    const headers = this.getAuthorizationHeaders(accessToken);
    const response = await axios.patch<GraphApiRule>(`${GRAPH_API_ENDPOINT.MESSAGE_RULES}/${ruleId}`, rule, { headers });
    return response.data;
  }

  async deleteRule(accessToken: string, ruleId: string): Promise<void> {
    const headers = this.getAuthorizationHeaders(accessToken);
    await axios.delete(`${GRAPH_API_ENDPOINT.MESSAGE_RULES}/${ruleId}`, { headers });
  }
}