import { FC, useCallback, useRef, useState } from 'react'
import { useGetDebtorLegalNotes } from '../../../hooks/mutations/crud-debtor-legal-note.hook'
import styles from './debtor-legal-note-list.module.scss'
import DebtorLegalNoteListItem from './debtor-legal-note-list-item.component'
import { Oval } from 'react-loader-spinner'
import { useModal } from '@src/hooks/useModal'
import { DebtorLegalNote } from '../../../types/debtor-legal.type'
import ViewDebtorLegalNoteModal from '../../modals/debtor-legal-note-modal/view-debtor-legal-note-modal.component'
import { DebtorLegalNoteListProps } from '../../../types/debtor-legal-note.type'

const DebtorLegalNoteList: FC<DebtorLegalNoteListProps> = ({ debtorLegalId }) => {
  const [isOpen, handleOpen, handleClose] = useModal(false)
  const [selectedNote, setSelectedNote] = useState<DebtorLegalNote | null>(null)
  const { data, isLoading, hasNextPage, isFetching, fetchNextPage } = useGetDebtorLegalNotes({
    s: JSON.stringify({ debtorLegalId }),
    sort: 'createdAt,DESC',
    limit: 5,
  })

  const observer = useRef<IntersectionObserver>()

  const lastElementRef = useCallback(
    (node: HTMLDivElement) => {
      if (isLoading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage && !isFetching) {
          fetchNextPage()
        }
      })
      if (node) observer.current.observe(node)
    },
    [isLoading, isFetching, hasNextPage],
  )

  const handleOpenNote = (note: DebtorLegalNote) => {
    setSelectedNote(note)
    handleOpen()
  }

  const isDataNoteFound = data.length === 0 && !isFetching

  return (
    <>
      <div className={styles.noteListWrapper}>
        {isDataNoteFound ? <h4 className={styles.notFound}>No notes to display</h4> : null}
        {data.map((note, i) => (
          <DebtorLegalNoteListItem
            onEditClick={handleOpenNote}
            lastElementRef={data.length === i + 1 ? lastElementRef : null}
            key={`${i}-${note.id}-debtor-legal-note`}
            entity={note}
          />
        ))}
        {isFetching ? (
          <Oval
            visible={true}
            height="40"
            width="40"
            color="blue"
            secondaryColor="blue"
            ariaLabel="oval-loading"
            wrapperStyle={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              marginTop: '10px',
            }}
          />
        ) : null}

        {selectedNote ? (
          <ViewDebtorLegalNoteModal entity={selectedNote} isOpen={isOpen} onClose={handleClose} />
        ) : null}
      </div>
    </>
  )
}

export default DebtorLegalNoteList
