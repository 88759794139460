import SubNavBar from '@components/nav-bar/sub-nav-bar.component'
import { WIP_COMMUNICATION_SUB_NAV_BAR_OPTIONS } from './const/wip-communication.const'
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router'
import { FC } from 'react'
import WipNotesPage from './view/wip-notes-page/wip-notes-page.component'
import WipEmailSection from './components/sections/email-section/wip-email-section.component'
import WipSmsSection from './components/sections/sms-section/wip-sms-section.component'
import { WipCommunicationPageProps } from './types/wip-communication.type'
import WipReportSection from './components/sections/report-section/wip-report-section.component'

const WipCommunicationPage: FC<WipCommunicationPageProps> = ({ wip }) => {
  const { path } = useRouteMatch()
  const location = useLocation()

  return (
    <>
      <SubNavBar options={WIP_COMMUNICATION_SUB_NAV_BAR_OPTIONS} activeUrl={location.pathname} />
      <Switch>
        <Route exact path={`${path}/notes`} render={() => <WipNotesPage wipId={wip.id} />} />
        <Route exact path={`${path}/emails`} render={() => <WipEmailSection wipId={wip.id} />} />
        <Route exact path={`${path}/sms`} render={() => <WipSmsSection wipId={wip.id} />} />
        <Route exact path={`${path}/letters`} render={() => <div>letters</div>} />
        <Route exact path={`${path}/reports`} render={() => <WipReportSection wip={wip} />} />
        <Route path={'*'} render={() => <Redirect to={`/wip/${wip.id}/communication/notes`} />} />
      </Switch>
    </>
  )
}

export default WipCommunicationPage
