import NavigationButton from '@components/navigation-button/navigation-button'
import { InboxButtonProps } from '@containers/layout/types/layout.type'
import InboxIcon from '@src/icons/inbox'

import { FC } from 'react'
import styles from './inbox-button.module.scss'
import { useIsAuthenticated } from '@azure/msal-react'
import { Oval } from 'react-loader-spinner'
import { useGetMailFolderByDisplayNameOrCreate } from '@containers/Inbox/hooks/get-mail-folder-by-display-name-or-create.hook'
import { SHARED_FOLDER_NAME } from '@containers/Inbox/inbox-page/const/inbox.const'

const InboxButton: FC<InboxButtonProps> = ({ isActive, isCloseWithoutHover }) => {
  const isAuthenticated = useIsAuthenticated()
  const { mailFolder, isMailFoldersFetching } = useGetMailFolderByDisplayNameOrCreate(SHARED_FOLDER_NAME)

  const isInboxFolderFound = !!mailFolder?.unreadItemCount && !isMailFoldersFetching

  return (
    <NavigationButton
      icon={InboxIcon}
      label="Inbox"
      isActive={isActive}
      isLabelHidden={isCloseWithoutHover}
      isLink
      to="/inbox"
    >
      {isAuthenticated ? (
        <>
          {isMailFoldersFetching ? (
            <span className={styles.unreadEmailIndicator}>
              {
                <Oval
                  visible={true}
                  height="15"
                  width="15"
                  color="#1e40af"
                  secondaryColor="#1e40af"
                  ariaLabel="oval-loading"
                />
              }
            </span>
          ) : null}
          {isInboxFolderFound ? (
            <span className={styles.unreadEmailIndicator}>{mailFolder.unreadItemCount}</span>
          ) : null}
        </>
      ) : null}
    </NavigationButton>
  )
}

export default InboxButton
