import React, { useEffect, useState } from 'react';
import Select from 'react-select';

export const ClientDetails = (props) => {
  const { clients, setClientDetails, clientDetails, isEdit,setClientPhone,clientPhone } = props;

  const [selectedClient, setSelectedClient] = useState(null);

  useEffect(()=>{
    const defaultSelectedClients = clients.find((val) => val.value.id === clientDetails.id)
    setSelectedClient(defaultSelectedClients)
    setClientPhone(clientDetails?.client_phone)
  },[clients,clientDetails])



  const onClientNameChange = (item) => {
    setClientDetails(item.value);
    setSelectedClient(item);
  };

  return (
    <>
      <div className="card__title">
        <h5 className="bold-text">Client Details</h5>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Name<span style={{ color: '#ff0000' }}>*</span></span>
        <div className="form__form-group-field">
          <Select
            name="urf-client-name"
            value={selectedClient}
            onChange={onClientNameChange}
            options={clients}
            classNamePrefix="react-select"
            className="react-select"
            isDisabled={isEdit}
            required={true}
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Client Status</span>
        <div className="form__form-group-field">
          <input
            name="urf_client_details_status"
            type="text"
            placeholder="Client Status"
            disabled
            value={clientDetails.status}
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Login Type</span>
        <div className="form__form-group-field">
          <input
            name="login_type"
            disabled
            value={clientDetails?.login_type?.name}
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Client phone</span>
        <div className="form__form-group-field">
          <input
            name="client_phone"
            value={clientPhone || ""}
            onChange={(e)=>setClientPhone(e.target.value)}
          />
        </div>
      </div>
    </>
  );
};
