import { FC, useEffect } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { BaseNoteData, INoteForm } from '../../../types/notes-form.type'
import { NOTE_FORM_DEFAULT_VALUES } from '../../../const/note.const'
import NotesService from '../../../../../../../../services/NotesServices'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { NOTE_QUERY } from '../../../const/note-query.const'
import { AddNoteProps, TaskNote } from '../../../types/notes.type'
import Select from 'react-select'
import { useGetTasksOptions } from '../../../hooks/get-tasks-options.hook'
import LabelWrapper from '@components/label-wrapper'
import { useUserContext } from '@src/context/user-context'
import { useTaskContext } from '@src/context/task-context'
import { toast } from 'react-toastify'
import { joiResolver } from '@hookform/resolvers/joi'
import { addNoteSchema } from '../../../schemas/add-note.schema'

const notesService = new NotesService()

export const AddNote: FC<AddNoteProps> = ({ selectedNote, selectNote }) => {
  const { currentClient, currentWip, isRecovery, isSales } = useTaskContext()
  const { user } = useUserContext()
  const queryClient = useQueryClient()
  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm<INoteForm>({
    defaultValues: NOTE_FORM_DEFAULT_VALUES,
    resolver: joiResolver(addNoteSchema),

  })

  const [is_important] = useWatch({ name: ['is_important'], control })

  const { data: noteData } = useQuery({
    queryKey: [NOTE_QUERY.NOTE.GET_BY_ID, selectedNote],
    queryFn: async () => {
      const response = await notesService.getTaskNotesId({ id: selectedNote, join: 'task' })
      const note = response.data as TaskNote
      return note
    },
    enabled: !!selectedNote,
    keepPreviousData: true,
  })

  const tasksOptions = useGetTasksOptions()

  useEffect(() => {
    if (noteData) {
      reset({
        notes: noteData.content,
        is_important: noteData.is_important,
        selected_task: { label: noteData.task.title, value: noteData.task.id as unknown as string },
      })
    }
  }, [noteData])

  const resetNoteForm = () => {
    reset({ notes: '', is_important: false, selected_task: null })
  }

  const getBaseNoteData = (data: INoteForm): BaseNoteData => {

    const baseNoteData = {
      content: data.notes,
      is_important: data.is_important,
      task: data.selected_task?.value
        ? { id: data.selected_task.value as unknown as number }
        : null,
    }

    return baseNoteData
  }

  const createNote = async (baseNoteData: BaseNoteData) => {
    const createNoteData = {
      ...baseNoteData,
      staff_created: { id: user?.id },
      company_id: isSales ? currentClient?.id : null,
      wip_id: isRecovery ? currentWip : null
    }
    try {
      await notesService.addTaskNotes(createNoteData)
      resetNoteForm()
      queryClient.invalidateQueries({ queryKey: [NOTE_QUERY.NOTE.GET] })
      toast.success('Note created')
    } catch {
      toast.error('Something went wrong')
    }
  }

  const updateNote = async (baseNoteData: BaseNoteData) => {
    if (!noteData) {
      return
    }

    const updateNoteData = { id: noteData.id, ...baseNoteData, staff_updated: { id: user?.id } }
    try {
      await notesService.editTaskNotes(updateNoteData)
      cancelNoteEditing()
      queryClient.invalidateQueries({ queryKey: [NOTE_QUERY.NOTE.GET] })
      toast.success('Note updated')
    } catch {
      toast.error('Something went wrong')
    }
  }

  const cancelNoteEditing = () => {
    resetNoteForm()
    selectNote(null)
  }

  const onSubmit = (data: INoteForm) => {
    const baseNoteData = getBaseNoteData(data)

    if (selectedNote) {
      updateNote(baseNoteData)
      return
    }
    createNote(baseNoteData)
  }

  return (
    <div>
      <p className="text-secondary font-weight-bold">CLIENT FILE NOTES</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label className="form__form-group-label uppercase">GENERAL NOTE</label>
              <textarea className="custom-input" {...register('notes')} />
              <p className="m-0 text-danger">{errors?.notes?.message || ''}</p>
            </div>
          </div>
        </div>

        <div className="row align-items-center form">
          <div className="col-12 col-md-6 col-lg-8">
            <div className="d-flex align-items-center ml-2">
              <div className="round_checkbox">
                <input
                  type="checkbox"
                  className=""
                  id="customRadio"
                  name="asPin"
                  checked={is_important}
                  onChange={(e) => {
                    setValue('is_important', e.target.checked)
                  }}
                />
                <label className="mb-0" htmlFor="customRadio" />
              </div>
              <p className="m-0 ml-3">Pin as Important Note</p>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="form-group">
              <LabelWrapper control={control} name="selected_task" label="TASK">
                {({ onChange, value }) => {
                  return (
                    <Select
                      type="text"
                      options={tasksOptions}
                      classNamePrefix="react-select"
                      className="react-select"
                      value={value}
                      onChange={onChange}
                    />
                  )
                }}
              </LabelWrapper>
            </div>
          </div>
        </div>

        <button type="submit" className="btn btn-primary">
          {selectedNote ? 'Update Note' : 'Add Note'}
        </button>

        {selectedNote ? (
          <button type="button" className="btn btn-primary" onClick={cancelNoteEditing}>
            Cancel
          </button>
        ) : null}
      </form>
    </div>
  )
}

export default AddNote
