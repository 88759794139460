import { BaseNotificationFormValues, EmailSendingFormWithRecipientsValues, SmsSendingFormWithRecipientsValues, ToOptions } from "./notification-modals.type";

export const DEFAULT_TO_OPTIONS = [
  { label: 'Debtor', value: 'debtor' },
  { label: 'Client', value: 'client' },
  { label: 'Staff', value: 'staff' },
  { label: 'Other', value: 'other' },
] as { label: string; value: ToOptions }[]

export const DEFAULT_NOTIFICATION_FORM_VALUES: BaseNotificationFormValues = {
  to: null,
  recipients: null,
  template: null,
}

export const DEFAULT_EMAIL_SENDING_WITH_RECEPIENTS_FORM_VALUES: EmailSendingFormWithRecipientsValues = {
  ...DEFAULT_NOTIFICATION_FORM_VALUES,
  subject: '',
  body: '',
  bcc: false
}

export const DEFAULT_SMS_SENDING_WITH_RECEPIENTS_FORM_VALUES: SmsSendingFormWithRecipientsValues  = {
  ...DEFAULT_NOTIFICATION_FORM_VALUES,
  message: '',
}