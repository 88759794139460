import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import CompaniesService from '@services/CompaniesService'
import WIPService from '@services/WIPService'
import StaffService from '@services/StaffService'
import { IOption, TaskBoardMode, TaskProps } from '@containers/Setting/components/Contacts/taskboard/types'
import { REACT_APP_API_URL } from '@src/lib/global-variables'
import axios from 'axios'

interface ITaskContext {
  selectedCompanyState: any
  setSelectedCompanyState: (selectedCompanyState: any) => void
  //
  selectedWips: any
  setSelectedWips: (selectedWips: any) => void
  //
  companies: any[]
  setCompanies: (companies: any[]) => void
  //
  staff: any[]
  setStaff: (staff: any[]) => void
  //
  filterTaskListDependingOnType: ({
    mode,
    taskList,
  }: {
    mode: TaskBoardMode
    taskList: TaskProps[]
  }) => TaskProps[]
  //
  taskMode: TaskBoardMode,
  isRecovery: boolean,
  isSales: boolean,
  setMode: (mode: TaskBoardMode) => void
  //
  currentWip: any
  currentFoundWip?: any
  setCurrentWip: (wip: any) => void
  //
  currentClient: any
  setCurrentClient: (client: any) => void
  //
  canSelectContact: boolean,
  setCanSelectContact: (canSelect: boolean) => void
  //
  externalAutomationOptions: IOption[] | null
  setExternalAutomationOptions: (option: IOption[] | null) => void
  //
  currentColumns: any[]
  setCurrentColumns: (columns: any[]) => void
  //
  fetchColumns: (id: number | null) => Promise<any[]>
}

const _noop = () => {
  throw new Error('useTaskContext() must be used within a TaskProvider')
}

export const TaskContext = createContext<ITaskContext>({
  companies: [],
  setCompanies: _noop,
  //
  selectedWips: [],
  setSelectedWips: _noop,
  //
  selectedCompanyState: {},
  setSelectedCompanyState: _noop,
  //
  staff: [],
  setStaff: _noop,
  //
  filterTaskListDependingOnType: _noop,
  //
  taskMode: 'ALL',
  isRecovery: false,
  isSales: false,
  setMode: _noop,
  //
  currentWip: {},
  currentFoundWip: undefined,
  setCurrentWip: _noop,
  //
  canSelectContact: true, 
  setCanSelectContact: _noop,
  //
  externalAutomationOptions: null,
  setExternalAutomationOptions: _noop,
  //
  currentClient: {},
  setCurrentClient: _noop,
  //
  currentColumns: [],
  setCurrentColumns: _noop,
  //
  fetchColumns: _noop,
})

const companiesService = new CompaniesService()
const wipService = new WIPService()
const staffService = new StaffService()

type ITaskProviderProps = React.PropsWithChildren

export const TaskProvider = ({ children }: ITaskProviderProps) => {
  const [selectedCompanyState, setSelectedCompanyState] = useState<any>(null)
  const [companies, setCompanies] = useState<any>(null)
  const [staff, setStaff] = useState<any>(null)
  const [selectedWips, setSelectedWips] = useState<any>(null)
  const [currentWip, setCurrentWip] = useState<any>(null)
  const [taskMode, setMode] = useState<TaskBoardMode>('SALES')
  const [currentClient, setCurrentClient] = useState<any>(null)
  const [currentColumns, setCurrentColumns] = useState<any>(null)
  const [currentFoundWip, setCurrentFoundWip] = useState<any>(null)
  const [canSelectContact, setCanSelectContact] = useState<boolean>(true);
  const [externalAutomationOptions, setExternalAutomationOptions] = useState<IOption[] | null>(null);

  const isRecovery = useMemo(() => taskMode === 'RECOVERY',[taskMode]);
  const isSales = useMemo(() => taskMode === 'SALES', [taskMode])

  const fetchColumns = async (id: number | null) => {
    const columnParams =
      isRecovery
        ? {
            filter: [
              // `clientId||$eq||${id || 0}`,
              `mode||$eq||RECOVERY`,
              `wip_id||$eq||${currentWip}`,
            ],
            limit: 100,
          }
        : {
            filter: [`clientId||$eq||${id || 0}`, `mode||$eq||SALES`],
            limit: 100,
          }
    let columns = []
    try {
      const response = await axios.get(`${REACT_APP_API_URL}/board-columns`, {
        params: columnParams,
      })
      const result = response.data.data
      columns = result
    } catch (error) {
      console.log(error)
    }
    return columns
  }
  const filterTaskListDependingOnType = ({
    mode,
    taskList,
  }: {
    mode: TaskBoardMode
    taskList: TaskProps[]
  }) => {
    if (mode === 'SALES') {
      return taskList.filter(({ wip_id }) => !wip_id)
    }
    if (mode === 'RECOVERY') {
      if (currentWip) {
        return taskList.filter(({ wip_id }) => wip_id === currentWip)
      }
      return taskList.filter(({ wip_id }) => wip_id)
    }

    return taskList
  }

  useEffect(() => {
    if (selectedCompanyState?.id) {
      const wip_params = {
        sort: 'id,DESC',
        join: ['debtors', 'debtors.contact', 'property', 'company'],
        filter: `company.id||$eq||${Number(selectedCompanyState?.id) || 0}`,
      }
      wipService.getWips(wip_params).then((res) => {
        setSelectedWips(res.data)
      })
    }
  }, [selectedCompanyState])

  useEffect(() => {
    if(!currentWip){
      return;
    }
    const wipService = new WIPService();
    const params = {
      wip_id: currentWip,
      join: ['debtors', 'debtors.contact', 'company', 'company.contacts'],
    };
    wipService.getWipsId(params).then((res) => {
      setCurrentFoundWip(res.data);
    });
  }, [currentWip])

  useEffect(() => {
    staffService.getStaff({limit: 10000}).then((res) => {
      setStaff(res.data)
    })
  }, [])

  useEffect(() => {
    if (!selectedCompanyState) {
      const client_id = localStorage.getItem('client_Id')
      if (client_id && companies) {
        const currentClient = companies?.find(({ id }) => id === Number(client_id))
        setSelectedCompanyState(currentClient)
      }
    }
  }, [selectedCompanyState, companies])

  useEffect(() => {
    if (selectedCompanyState && selectedCompanyState?.id && companies?.length > 0) {
      const currentClient = companies?.find(({ id }) => id === Number(selectedCompanyState.id))

      setCurrentClient(currentClient)
    }
  }, [selectedCompanyState, companies])

  const getCompanies = async () => {
    companiesService.getCompanies().then((res) => {
      setCompanies(res.data.data)
    })
  }

  useEffect(() => {
    if (currentWip && !selectedCompanyState?.id) {
      const currentWipCompany = companies?.find(({ wip_id }) => wip_id === currentWip)
      setSelectedCompanyState(currentWipCompany)
    }
  }, [currentWip])

  useEffect(() => {
    getCompanies()
  }, [])

  const value = useMemo(
    () => ({
      companies,
      setCompanies,
      //
      selectedWips,
      setSelectedWips,

      //
      selectedCompanyState,
      setSelectedCompanyState,
      //
      staff,
      setStaff,

      //
      filterTaskListDependingOnType,
      //
      taskMode,
      isRecovery,
      isSales,
      setMode,
      //
      currentWip,
      currentFoundWip,
      setCurrentWip,
      //
      canSelectContact,
      setCanSelectContact,
      //
      externalAutomationOptions,
      setExternalAutomationOptions,
      //
      currentClient,
      setCurrentClient,
      //
      currentColumns,
      setCurrentColumns,
      //
      fetchColumns,
    }),
    [
      selectedCompanyState,
      companies,
      selectedWips,
      staff,
      taskMode,
      currentWip,
      currentClient,
      currentColumns,
      isRecovery,
      isSales,
      externalAutomationOptions, 
      canSelectContact
    ],
  )
  return <TaskContext.Provider value={value}>{children}</TaskContext.Provider>
}

export const useTaskContext = () => {
  const context = useContext(TaskContext)
  if (context === undefined) {
    throw new Error('useTaskContext() must be used within a TaskProvider')
  }
  return context
}
