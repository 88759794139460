import Modal from '@components/modal'
import { BaseModalProps } from '@src/types/component-types'
import { FC } from 'react'
import MergePropertiesForm from '../../form/merge-properties-form/merge-properties-form.component'
import { Property } from '@containers/Sales/Properties/types/property.type'
import { PropertyService } from '@containers/Sales/Properties/services/property.service'
import { toast } from 'react-toastify'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'

interface MergePropertiesModalProps extends BaseModalProps {
  headerText?: string
  propertiesIds: number[]
  onSuccessMerge?: (properties: Property[]) => void
}

const MergePropertiesModal: FC<MergePropertiesModalProps> = ({
  headerText = 'Merge Properties',
  propertiesIds,
  onSuccessMerge,
  ...modalProps
}) => {
  const handleSubmit = async (landlordId: number) => {
    const propertyService = new PropertyService()
    try {
      const mergedProperties = await propertyService.mergeProperties(landlordId, propertiesIds)
      onSuccessMerge && onSuccessMerge(mergedProperties)
      modalProps.onClose()
    } catch (e) {
      toast.error('Failed to merge properties')
      console.error(e)
    }
  }

  return (
    <Modal
      {...modalProps}
      withBtns={false}
      style={{ width: 'fit-content', minWidth: 350, height: 'fit-content', overflow: 'visible' }}
    >
      <Header mb={4}>{headerText}</Header>
      <MergePropertiesForm onSubmit={handleSubmit} />
    </Modal>
  )
}

export default MergePropertiesModal
