import { useForm } from 'react-hook-form'
import styles from './reply-to-email-form.module.scss'
import cls from 'classnames'
import NavigationButton from '@components/navigation-button/navigation-button'
import TrashIcon from '@src/icons/trash'
import { FC } from 'react'
import {
  ReplyFormValues,
  ReplyToEmailFormProps,
} from '@containers/Inbox/view-email-page/types/view-email-page.type'
import LabelWrapper from '@components/label-wrapper'
import SendIcon from '@src/icons/send'
import { toast } from 'react-toastify'
import { joiResolver } from '@hookform/resolvers/joi'
import { replyToEmailSchema } from '@containers/Inbox/view-email-page/schemas/email-event.schema'
import { Oval } from 'react-loader-spinner'
import { useReplyMessageMutation } from '@containers/Inbox/hooks/mutations/reply-message.hook'

const ReplyToEmailForm: FC<ReplyToEmailFormProps> = ({
  onCloseClick,
  onReplySuccess,
  defaultValues,
  messageId,
  subject,
}) => {
  const { mutateAsync, isLoading: isReplying } = useReplyMessageMutation()
  const { handleSubmit, control } = useForm<ReplyFormValues>({
    defaultValues,
    resolver: joiResolver(replyToEmailSchema),
  })

  const onSubmit = async (data: ReplyFormValues) => {
    const [, err] = await mutateAsync({
      comment: data.comment,
      message: { id: messageId, subject },
    })

    if (err) {
      toast.error(err.message)
      console.error(err)
      return
    }

    toast.success('Email replied successfully')
    onReplySuccess && onReplySuccess()
    onCloseClick()
  }

  return (
    <form className={styles.formWrapper} onSubmit={handleSubmit(onSubmit)}>
      <LabelWrapper label="To" control={control} name="to">
        {({ value }) => (
          <input
            className={styles.replyField}
            type="text"
            placeholder="To"
            value={(value as { name: string; address: string }).address}
            disabled
          />
        )}
      </LabelWrapper>
      <LabelWrapper label="Comment" control={control} name="comment">
        {({ onChange, value }) => (
          <textarea
            onChange={onChange}
            value={value as string}
            className={cls(styles.replyField, styles.bodyField)}
            placeholder="Write text here ..."
          />
        )}
      </LabelWrapper>
      <div className={styles.actionWrapper}>
        <NavigationButton className={styles.sendButton} label="Send">
          {isReplying ? (
            <Oval
              visible={true}
              height="24"
              width="24"
              color="white"
              secondaryColor="white"
              ariaLabel="oval-loading"
            />
          ) : (
            <SendIcon className={styles.right} />
          )}
        </NavigationButton>
        <div>
          <NavigationButton icon={TrashIcon} onClick={onCloseClick} />
        </div>
      </div>
    </form>
  )
}

export default ReplyToEmailForm
