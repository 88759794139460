import { DeepPartial } from "react-hook-form"
import { Debtor } from "../../types/debtor.type"
import { Params } from "@src/types/service.type"
import { DebtorService } from "../../services/debtor.service"
import { useMutation } from "@tanstack/react-query"

export const useUpdateDebtorMutation = () => {

  const updateDebtor = async (id: number, debtor: DeepPartial<Debtor>, params: Params = {}) => {
    const debtorService = new DebtorService()
    return debtorService.updateDebtor(id, debtor, params)
  }

  const mutation = useMutation({
    mutationFn: ({ id, debtor, params }: { id: number, debtor: DeepPartial<Debtor>, params?: Params }) => updateDebtor(id, debtor, params)
  })

  return mutation
}