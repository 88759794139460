import { postInvoiceActionEnum } from '../actionTypes';

export const postInvoiceReducer = function (
  state = { data: [], loading: false, error: '' },
  action,
) {
  switch (action.type) {
    case postInvoiceActionEnum.POST_INVOICE_SUCCESS:
      return { loading: false, error: '', data: action.payload };
    case postInvoiceActionEnum.IS_LOADING_POST_INVOICE_FORM:
      return { loading: true, error: '', data: [] };
    case postInvoiceActionEnum.POST_INVOICE_ERROR:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
