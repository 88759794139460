import { createInvoiceTemplateActionTypes } from '../actionTypes';
import { createInvoiceTemplateService } from '../service';

export const createInvoiceTemplate = (data) => async (dispatch) => {
  dispatch({ type: createInvoiceTemplateActionTypes.CREATE_INVOICE_TEMPLATE_LOADING });
  try {
    const res = await createInvoiceTemplateService(data);

    dispatch({
      type: createInvoiceTemplateActionTypes.CREATE_INVOICE_TEMPLATE_SUCCESS,
      payload: res,
    });

    return res;
  } catch (error) {
    dispatch({
      type: createInvoiceTemplateActionTypes.CREATE_INVOICE_TEMPLATE_ERROR,
      payload: error,
    });

    throw error;
  }
};
