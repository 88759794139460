import Joi from "joi";

export const addDebtorsToPayTrackingSchema = Joi.object({
  debtors: Joi.array().items({ label: Joi.string().required(), value: Joi.number().required() }).required(),
}).messages({ 'any.required': 'Required', 'array.base': 'Required' })

export const paymentTrackingSchema = Joi.object({
  trackDayPeriod: Joi.object({ label: Joi.string().required(), value: Joi.number().required() }).required(),
  from: Joi.object({ label: Joi.string().required(), value: Joi.string().required() }).required(),
  amount: Joi.string().required(),
  firstPaymentDue: Joi.date().required(),
}).messages({ 'any.required': 'Required', 'date.base': 'Required', 'string.base': 'Required', 'object.base': 'Required', 'string.empty': 'Required' })