import { useState } from 'react'
import TodayKanbanBoard from './daily-kanban-board.component'
import WeeklyKanbanBoard from './weekly-kanban-board.component'
import { GlobalKanbanBoardHeader } from '../global-kanban-board-header/global-kanban-board-header.component'
import { GlobalKanbanBoardHeaderValues } from '../../types/global-kanban-board.type'


const GlobalKanbanBoard = () => {
  const [values, setValues] = useState<GlobalKanbanBoardHeaderValues | null>(null)

  const handleBoardHeaderChange = (newValues: GlobalKanbanBoardHeaderValues) => {
    setValues(newValues)
  }

  return (
    <>
      <GlobalKanbanBoardHeader onChange={handleBoardHeaderChange} />
      {values?.mode === 'day' ? <TodayKanbanBoard values={values} /> : null}
      {values?.mode === 'week' ? <WeeklyKanbanBoard values={values} /> : null}
    </>
  )
}

export default GlobalKanbanBoard
