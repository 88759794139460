import React, { useState, useEffect } from 'react';

import { Button, ButtonToolbar } from 'react-bootstrap';
import Company from './companies';
import ManagementDetails from './managementDetails';
import OwnerDetails from './ownerDetails';
import RecoveryDetails from './recoveryDetails';
import TenantsDetails from './tenantsDetails';
import UploadDocuments from './UploadDocuments';
import { PropertyDetailsProtectCollect } from './properties';

import '../../../scss/form/styles.scss';
import axios from 'axios';
import WipDetails from './wipDetails';

const AddProtectAndCollectForm = ({
  control,
  onSubmit,
  register,
  fields,
  append,
  watch,
  setFiles,
  files,
  loading,
  remove
}) => {
  const [isDataFectched, setIsDataFetched] = useState({companies: false, properties: false});
  const [companies, setCompanies] = React.useState([]);
  const [properties, setProperties] = useState([]);

  React.useEffect(() => {
    getCompanies();
  }, []);

  React.useEffect(() => {
    if (watch('company')?.id) {
      setProperties([]);
      getProperties(watch('company')?.id);
    }
  }, [watch('company')?.id]);

  const getCompanies = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/companies`);
    setCompanies(response.data);
    setIsDataFetched((prevState) => ({...prevState, companies: true}))
  };

  // todo: improve efficiency of this function
  // because it is called twice and it is also called on posting of the form
  const getProperties = async (id) => {
    const url = `${process.env.REACT_APP_API_URL}/companies/${id}/properties`;
    await axios.get(url).then(async (res) => {
      const response = await axios.get(`${url}?limit=${res.data.total}`);
      setProperties(response.data);
    });
    setIsDataFetched((prevState) => ({...prevState, properties: true}))
  };

  if(!isDataFectched.companies && !isDataFectched.properties){
    return null;
  }

  return (
    <div className="form-div">
      <form onSubmit={onSubmit}>
        <div className="card__title">
          <h3 className="bold-text">Property</h3>
        </div>
        <Company register={register} watch={watch} companies={companies} />
        <PropertyDetailsProtectCollect register={register} watch={watch} properties={properties} />
        <TenantsDetails control={control} register={register} fields={fields} append={append} remove={remove} />
        <RecoveryDetails register={register} watch={watch} />
        <WipDetails register={register} watch={watch}/>
        <ManagementDetails register={register} />
        <OwnerDetails register={register} />
        <UploadDocuments register={register} setFiles={setFiles} files={files} />
        <ButtonToolbar className="form__button-toolbar mb-3">
          <Button
            color="primary"
            className="mb-0"
            type="submit"
            style={{ marginLeft: '122px', width: '125px' }}
          >
            {loading ? 'Loading...' : 'Save'}
          </Button>
        </ButtonToolbar>
      </form>
    </div>
  );
};

export default AddProtectAndCollectForm;
