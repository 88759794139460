import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TablePagination from '@material-ui/core/TablePagination'
import ContactItem from './contact-item'
import { Link } from 'react-router-dom'
import SettingTableHead from '../SettingTableHead'
import Services from '../../../../../shared/Services.js'
import CompaniesService from '../../../../../services/CompaniesService'
import { useTaskContext } from '@src/context/task-context'
import { useQuery } from '@tanstack/react-query'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import { Company, ErrorModal, IOrder, PaginationType } from '../types/contact-list.type'
import ContactSearch from './contact-search'
import ModalComponent from '../../../../../shared/components/ModalCustom'
import ContactListModeSwitch from './contact-list-mode-switch'
const companyService = new CompaniesService()

const ContactList = () => {
  const services = new Services()
  const [pagination, setPagination] = useState<PaginationType>({
    page: 0,
    limit: 10,
  })
  const [totalCount, setTotalCount] = useState<number>(0)
  const [errorModal, setErrorModal] = useState<ErrorModal | null>(null)
  const [order, setOrder] = useState<IOrder>({
    direction: 'asc',
    orderBy: '',
  })
  const [filterQuery, setFilerQuery] = useState<null | { [x: string]: { [x: string]: string; }; }>(null)
  const [contactMode, setContactMode] = useState<null | { 'assigned_to_favorite.id': { $eq: number; }; }>(null)
  const [selected, setSelected] = useState<Company | null>(null)
  const { setMode } = useTaskContext()

  const { page, limit } = pagination
  const { orderBy, direction } = order

  const { data, refetch } = useQuery<Company[]>({
    queryKey: [GLOBAL_QUERIES.COMPANIES.GET],
    queryFn: async () => {
      const params = {
        join: ['contacts', 'assigned_to_favorite'],
        page: page + 1,
        limit,
        s: JSON.stringify({$and: [filterQuery, contactMode].filter((value) => value)}),
        sort: orderBy ? `${orderBy},${direction.toLocaleUpperCase()}` : 'id,DESC',
      }
      const response = await companyService.getCompanies(params)
      setTotalCount(response.data.total)
      return response.data.data as Company[]
    },
  })

  useEffect(() => {
    refetch()
  }, [pagination, order, filterQuery, contactMode])

  useEffect(() => {
    setMode('SALES')
  }, [])

  const handleRequestSort = (event, property) => {
    let direction: 'asc' | 'desc' = 'desc'

    if (order.orderBy === property && order.direction === 'desc') {
      direction = 'asc'
    }

    setOrder({ orderBy: property, direction })
  }

  const handleSearchValueChange = (value: null | { [x: string]: { [x: string]: string; }; }) => {
    setFilerQuery(value)
  }

  const handleModeChange = (value) => {
    setFilerQuery(null);
    setContactMode(value)
  }

  const handleCloseModal = () => {
    setErrorModal(null)
  }

  const deleteCompany = async () => {
    if (!selected) {
      return
    }

    setErrorModal(null)
    const params = {
      id: selected.id,
      isdel: true,
    }

    const res = await companyService.deleteCompanies(params)

    if (res.status === 200) {
      services.successToast('Client Deleted successfully')
      refetch()
      return
    }

    if (res.status === 401 && res.responseType === 'UNAUTHORIZED') {
      setErrorModal({
        errorMessage: 'You do not have access to this, please contact your manager for assistance',
        errorType: 'danger',
        errorModal: true,
        onConfirm: handleCloseModal,
        onClose: handleCloseModal,
      })
      return
    }

    services.errorToast(res.message)
  }

  const handleOpenDeleteModal = (event, contact: Company) => {
    event.stopPropagation()
    setErrorModal({
      errorMessage: 'Are you sure you want to delete this record?',
      errorType: 'danger',
      errorModal: true,
      onConfirm: handleCloseModal,
      onClose: handleCloseModal,
    })
    setSelected(contact)
  }

  const handleChangePage = (event, newPage) => {
    setPagination((prevState) => ({ ...prevState, page: newPage }))
  }

  const handleChangeRowsPerPage = (event) => {
    setPagination({
      page: 0,
      limit: parseInt(event.target.value, 10),
    })
  }

  return (
    <div className="container">
      <Row>
        <Col md={12} lg={12}>
          {errorModal && (
            <ModalComponent
              modal={errorModal}
              color={errorModal.errorType}
              isCancel={true}
              message={errorModal.errorMessage}
              onConfirm={deleteCompany}
              onClose={() => handleCloseModal()}
            />
          )}
          <Row>
            <Col md={12}>
              <div className='d-flex mb-3 align-items-center' style={{gap: '10px'}}>
                <h3 className="page-title m-0 gap-10">Clients</h3>
                <ContactListModeSwitch onChange={handleModeChange} />
              </div>
            </Col>
          </Row>
          <Card>
            <CardBody>
            <ContactSearch setSearchValue={handleSearchValueChange} />
              <div className="d-flex" style={{ height: '35px', justifyContent: 'end' }}>
                <Link
                  className="btn btn-secondary m-0"
                  style={{ width: '160px' }}
                  to="/Add-Contact"
                >
                  Add Client
                </Link>
              </div>
              <div className="material-table__wrap">
                <Table className="material-table">
                  <SettingTableHead
                    // numSelected={[...selected].filter((el) => el[1]).length}
                    order={direction}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={data?.length}
                  />
                  <TableBody>
                    {data &&
                      data.map((d, i) => {
                        return (
                          <ContactItem
                            contact={d as any}
                            key={i}
                            isSelected={selected?.id === d.id}
                            openModal={handleOpenDeleteModal}
                          />
                        )
                      })}
                  </TableBody>
                </Table>
              </div>
              <TablePagination
                component="div"
                className="material-table__pagination"
                count={totalCount}
                rowsPerPage={limit}
                page={page}
                backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[10, 20, 30]}
                dir="ltr"
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default ContactList
