import { FC } from 'react'
import SlideModal from '@components/slide-modal'
import Table from '@components/table/table.component'
import { CourtInterestRateTableModalProps } from '../../../types/interest-rate.type'
import TableRow from '@components/table/table-row.component'
import TableCell from '@components/table/table-cell-component'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'

const CourtInterestRateTableModal: FC<CourtInterestRateTableModalProps> = ({
  courtInterestRates,
  loading = false,
  ...modalProps
}) => {
  return (
    <SlideModal
      style={{ width: 620 }}
      layoutStyle={{ zIndex: 1000, backgroundColor: 'black' }}
      {...modalProps}
    >
      <Header mb={4}>Court Interest Rate Table</Header>
      <Table
        headers={['STATE', 'RATE']}
        paginated={false}
        loading={loading}
        bodyHeight={loading ? 400 : courtInterestRates.length * 54}
      >
        {courtInterestRates.map((rate) => (
          <TableRow key={rate.id}>
            <TableCell position="eclipsed">{rate.state}</TableCell>
            <TableCell position="eclipsed">{`${rate.rate * 100} %`}</TableCell>
          </TableRow>
        ))}
      </Table>
    </SlideModal>
  )
}

export default CourtInterestRateTableModal
