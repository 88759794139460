// Function to encrypt a number using XOR encryption

export function encryptXOR(number: number, key: string) {
  // Convert the number to its binary representation
  if (typeof number !== "number") return -1;
  let binaryNumber = number.toString(2);

  // Pad with zeros to match the length of the key
  while (binaryNumber.length < key.length) {
      binaryNumber = "0" + binaryNumber;
  }

  let encryptedBinary = "";

  // Perform XOR operation for each bit of the number with the corresponding bit of the key
  for (let i = 0; i < binaryNumber.length; i++) {
      encryptedBinary += (parseInt(binaryNumber[i]) ^ parseInt(key[i])).toString();
  }

  // Convert the result back to decimal number
  const encryptedNumber = parseInt(encryptedBinary, 2);
  return encryptedNumber;
}

// Function to decrypt an encrypted number using XOR decryption
export function decryptXOR(encryptedNumber: number, key) {
  // Convert the encrypted number to its binary representation
  let binaryEncryptedNumber = encryptedNumber.toString(2);

  // Pad with zeros to match the length of the key
  while (binaryEncryptedNumber.length < key.length) {
      binaryEncryptedNumber = "0" + binaryEncryptedNumber;
  }

  let decryptedBinary = "";

  // Perform XOR operation for each bit of the encrypted number with the corresponding bit of the key
  for (let i = 0; i < binaryEncryptedNumber.length; i++) {
      decryptedBinary += (parseInt(binaryEncryptedNumber[i]) ^ parseInt(key[i])).toString();
  }

  // Convert the result back to decimal number
  const decryptedNumber = parseInt(decryptedBinary, 2);
  return decryptedNumber;
}