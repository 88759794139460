import { toast } from 'react-toastify'
import { FC, useMemo } from 'react'
import { BaseEditFormProps } from '@src/types/component-types'
import { DebtorLegalJudgmentDate } from '../../../types/debtor-legal.type'
import { useUpdateDebtorJudgmentDateMutation } from '../../../hooks/mutations/crud-debtor-legal-judgment-date.hook'
import { DebtorJudgmentDateFormValues } from '../../../types/debtor-legal-judgment-date.type'
import DebtorLegalJudgmentDateForm from './debtor-legal-judgment-date-form.component'

const EditDebtorLegalJudgmentDateForm: FC<BaseEditFormProps<DebtorLegalJudgmentDate>> = ({
  entity: debtorLegalJudgmentDate,
  onSuccessSubmit,
}) => {
  const { mutateAsync, isLoading } = useUpdateDebtorJudgmentDateMutation()
  const handleSuccessSubmit = async (data: DebtorJudgmentDateFormValues) => {
    try {
      const updatedData = await mutateAsync({
        debtorJudgmentDateId: debtorLegalJudgmentDate.id,
        data,
      })
      onSuccessSubmit(updatedData)
    } catch (error) {
      toast.error('Failed to update judgment date')
      console.error(error)
    }
  }

  const initialValues = useMemo(
    () => ({
      judgmentDate: debtorLegalJudgmentDate.judgmentDate,
    }),
    [debtorLegalJudgmentDate],
  )

  return (
    <DebtorLegalJudgmentDateForm
      onSuccessSubmit={handleSuccessSubmit}
      initialValues={initialValues}
      loading={isLoading}
      submitButtonTitle="Update Judgment Date"
    />
  )
}

export default EditDebtorLegalJudgmentDateForm;
