import { FC } from 'react'
import { PaginationItemProps } from './button-pagination.type'
import styles from './button-pagination.module.scss'
import cls from 'classnames'

export const PaginationItem: FC<PaginationItemProps> = ({ onClick, page, currentPage }) => {
  const isActive = currentPage === page
  const handleItemClick = () => {
    if (isActive) {
      return
    }

    onClick(page)
  }

  return (
    <button
      className={cls(styles.itemWrapper, styles.paginationItemWrapper, {
        [styles.active]: isActive,
      })}
      onClick={handleItemClick}
    >
      {page}
    </button>
  )
}
