import { FC, ReactNode, useEffect, useRef, useState } from 'react'
import RoundedArrow from '@src/icons/rounded-arrow'
import styles from './dropdown.module.scss'
import cls from 'classnames'
import CloseIcon from '@src/icons/close'

interface DropdownProps<T> {
  options: { label: string; value: T }[]
  selected?: { label: string; value: T } | null
  icon?: FC<{ className: string }>
  placeholder?: string
  clearable?: boolean
  children?: ReactNode
  className?: string
  onSelectedChange?: (option: { label: string; value: T }) => void
  onClear?: () => void
}

const Dropdown = <T,>({
  options,
  selected,
  icon: Icon,
  placeholder = 'Select',
  clearable = false,
  className,
  children,
  onClear,
  onSelectedChange,
}: DropdownProps<T>) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const [width, setWidth] = useState<number | null>(null)
  const [isOpen, setIsOpen] = useState(false)

  const handleOpenClick = () => {
    setIsOpen((prev) => !prev)
  }

  const handleOptionClick = (option: { label: string; value: T }) => {
    onSelectedChange && onSelectedChange(option)
    setIsOpen(false)
  }

  useEffect(() => {
    if (!ref.current) {
      setWidth(null)
      return
    }
    setWidth(ref.current.offsetWidth)
  }, [isOpen])

  return (
    <div className={cls(styles.dropdownWrapper, className)}>
      <div className={styles.dropdownHeader} style={width ? { width } : undefined}>
        <div
          className={cls(styles.dropdownHeaderContent, {
            [styles.withCloseIcon]: clearable && selected,
          })}
          onClick={handleOpenClick}
        >
          {Icon ? <Icon className={styles.dropdownIcon} /> : null}
          <span className={styles.dropDownHeaderLabel}>{selected?.label || placeholder}</span>
          <RoundedArrow className={cls(styles.dropdownIcon, styles.down)} />
        </div>
        {clearable && selected ? (
          <CloseIcon onClick={onClear} className={styles.closeIcon} />
        ) : null}
      </div>
      {isOpen ? (
        <div className={styles.dropdownListWrapper} ref={ref}>
          <ul className={styles.dropdownList}>
            {children
              ? children
              : options.map((option) => (
                  <li
                    className={cls(styles.dropdownItem, {
                      [styles.selected]: option.value === selected?.value,
                    })}
                    key={`${option.label}-${option.value}`}
                    onClick={() => handleOptionClick(option)}
                  >
                    {Icon ? <Icon className={styles.dropdownIcon} /> : null}
                    {option.label}
                  </li>
                ))}
          </ul>
        </div>
      ) : null}
    </div>
  )
}

export default Dropdown
