import { useState } from 'react'
import styles from './elipsis-button.module.scss'

interface IElipsisOption {
  value: string
  callback: () => void
}

interface IProps {
  options: IElipsisOption[]
}

const ElipsisButton = ({ options = [] }: IProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleClickOnElipsis = () => {
    setIsOpen((prev) => !prev)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.elipsis} onClick={handleClickOnElipsis}>
        <span />
        <span />
        <span />
        {isOpen && (
          <div className={styles.options}>
            {options.map(({ value, callback }) => (
              <div onClick={() => callback()} key={value} className={styles.option}>
                <span>{value}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default ElipsisButton
