import VerticalNavBar from '@components/nav-bar/vertical-nav-bar.component'
import Section from '@components/section/section.component'
import { FC, useState } from 'react'
import styles from './debtor-details-form.module.scss'
import DebtorDetailsNamesForm from './forms/debtor-details-names-form/debtor-details-names-form.component'
import DebtorDetailsEmailsForm from './forms/debtor-details-emails-form/debtor-details-emails-form.component'
import DebtorDetailsPhoneForm from './forms/debtor-details-phone-form/debtor-details-phone-form.component'
import DebtorDetailsOtherForm from './forms/debtor-details-other-form/debtor-details-other-form.component'
import {
  transformDebtorToDebtorDetailsNamesFormValues,
  transformDebtorToDebtorDetailsOtherFormValues,
  transformDebtorToDebtorDetailsPhoneFormValues,
} from '../../../utils/transform-debtor-to-values.utils'
import { DebtorDetailsFormProps, DebtorDetailsFormTab } from '../../../types/debtor-form.type'
import { DEBTOR_DETAILS_FORM_NAVIGATION_TABS } from '../../../const/debtor.const'
import cls from 'classnames'

const DebtorDetailsForm: FC<DebtorDetailsFormProps> = ({ debtor, direction }) => {
  const [activeValue, setActiveValue] = useState(DebtorDetailsFormTab.Names)

  const handleVerticalNavBarChange = (value: string) => {
    setActiveValue(value as DebtorDetailsFormTab)
  }

  const getActiveTab = (value: DebtorDetailsFormTab) => {
    switch (value) {
      case DebtorDetailsFormTab.Names:
        return (
          <DebtorDetailsNamesForm
            debtorId={debtor.id}
            initialValues={transformDebtorToDebtorDetailsNamesFormValues(debtor)}
          />
        )
      case DebtorDetailsFormTab.Emails:
        return (
          <DebtorDetailsEmailsForm
            debtorId={debtor.id}
            debtorContactId={debtor.contact.id}
            initialValues={{ emails: debtor.contact.comms_email }}
          />
        )
      case DebtorDetailsFormTab.Phone:
        return (
          <DebtorDetailsPhoneForm
            debtorId={debtor.id}
            debtorContactId={debtor.contact.id}
            initialValues={transformDebtorToDebtorDetailsPhoneFormValues(debtor)}
          />
        )
      case DebtorDetailsFormTab.Other:
        return (
          <DebtorDetailsOtherForm
            debtorId={debtor.id}
            initialValues={transformDebtorToDebtorDetailsOtherFormValues(debtor)}
          />
        )
      default:
        return null
    }
  }

  return (
    <div
      className={cls(styles.debtorDetailsFormWrapper, {
        [styles.vertical]: direction === 'vertical',
      })}
    >
      <VerticalNavBar
        options={DEBTOR_DETAILS_FORM_NAVIGATION_TABS}
        activeValue={activeValue}
        onChange={handleVerticalNavBarChange}
        position={direction === 'horizontal' ? 'vertical' : 'horizontal'}
      />
      <Section>{getActiveTab(activeValue)}</Section>
    </div>
  )
}

export default DebtorDetailsForm
