import { toast } from 'react-toastify'
import { FC, useMemo } from 'react'
import { BaseEditFormProps } from '@src/types/component-types'
import { DebtorClaimNumberFormValues } from '../../../types/debtor-claim-number.type'
import DebtorLegalClaimNumberForm from './debtor-claim-number-form.component'
import { useUpdateDebtorLegalClaimNumberMutation } from '../../../hooks/mutations/crud-debtor-legal-claim-number.hook'
import { DebtorLegalClaimNumber } from '../../../types/debtor-legal.type'

const EditDebtorLegalClaimNumberForm: FC<BaseEditFormProps<DebtorLegalClaimNumber>> = ({
  entity: debtorLegalClaimNumber,
  onSuccessSubmit,
}) => {
  const { mutateAsync, isLoading } = useUpdateDebtorLegalClaimNumberMutation()
  const handleSuccessSubmit = async (data: DebtorClaimNumberFormValues) => {
    try {
      const updatedData = await mutateAsync({
        debtorClaimNumberId: debtorLegalClaimNumber.id,
        data,
      })
      onSuccessSubmit(updatedData)
    } catch (error) {
      toast.error('Failed to update claim number')
      console.error(error)
    }
  }

  const initialValues = useMemo(
    () => ({
      claimNumber: debtorLegalClaimNumber.claimNumber,
    }),
    [debtorLegalClaimNumber],
  )

  return (
    <DebtorLegalClaimNumberForm
      onSuccessSubmit={handleSuccessSubmit}
      initialValues={initialValues}
      loading={isLoading}
      submitButtonTitle="Update Claim Number"
    />
  )
}

export default EditDebtorLegalClaimNumberForm
