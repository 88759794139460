import { GLOBAL_QUERIES } from "@src/lib/global-queries"
import { useQuery } from "@tanstack/react-query"
import { GetSmsConversationFragmentParams } from "../types/sms-conversation.type"
import { SmsConversationService } from "../services/sms-conversation.service"

export const useGetSmsConversationFragment = (params: GetSmsConversationFragmentParams) => {

  const getSmsConversationFragment = async (params: GetSmsConversationFragmentParams) => {
    const smsConversationService = new SmsConversationService()
    return smsConversationService.getSmsConversationFragment(params)
  }

  const query = useQuery({ queryKey: [GLOBAL_QUERIES.SMS_CONVERSATION.FRAGMENT.GET_BY_MESSAGE_ID, params.start_from_message_id], queryFn: () => getSmsConversationFragment(params) })

  return query
}