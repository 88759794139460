import Section from '@components/section/section.component'
import WipPropertiesListWithSearch from './components/wip-properties-list/wip-properties-list-with-search.component'
import { Header, SubHeader } from '../../components/UI/text/text.component'
import styles from './wip-properties.module.scss'
import { Wip } from '../../types/wip.type'
import { FC, useEffect, useState } from 'react'
import PropertyForm from './components/forms/property-form/property-form.component'
import SubmitButton from '../../components/UI/submit-button/submit-button.component'
import { FULL_STATE_OPTIONS } from '@src/lib/select-options.const'
import { useHistory } from 'react-router'
import { Property } from '@containers/Sales/Properties/types/property.type'

interface WipPropertiesProps {
  wip: Wip
}

const WipProperties: FC<WipPropertiesProps> = ({ wip }) => {
  const history = useHistory()
  const [selectedAssignProperty, setSelectedAssignProperty] = useState<Property | null>(
    wip.property,
  )

  useEffect(() => {
    setSelectedAssignProperty(wip.property)
  }, [wip.property])

  const handleAssignPropertyClick = (property: Property) => {
    setSelectedAssignProperty(property)
  }

  const handleViewProperty = () => {
    if (!selectedAssignProperty) return
    localStorage.setItem('propertyId', String(selectedAssignProperty.id))
    localStorage.setItem('propertyIsView', 'true')
    history.push('/view-properties', {
      fromDetails: selectedAssignProperty.id,
      wipID: wip.id,
    })
  }

  return (
    <div className={styles.wrapper}>
      <Section className={styles.assignPropertySearchSection}>
        <Header mb={4}>Assign Property Search 13 </Header>
        <WipPropertiesListWithSearch
          selectedAssignProperty={selectedAssignProperty}
          onAssignPropertyClick={handleAssignPropertyClick}
          wipId={wip.id}
          clientId={wip.company.id}
          initialSelectedProperty={wip.property}
        />
      </Section>
      <Section>
        <Header mb={4}>Assigned Property</Header>
        <div className={styles.formWrapper}>
          {selectedAssignProperty ? (
            <>
              <PropertyForm
                withSubmitButton={false}
                isViewOnly={true}
                initialValues={{
                  addressLine1: selectedAssignProperty.addressLine1 || '',
                  addressLine2: selectedAssignProperty.addressLine2 || '',
                  addressLine3: selectedAssignProperty.addressLine3 || '',
                  suburb: selectedAssignProperty.suburb || '',
                  state:
                    FULL_STATE_OPTIONS.find(
                      (option) => option.value === selectedAssignProperty.state,
                    ) || null,
                  postcode: selectedAssignProperty.postcode || '',
                  country: selectedAssignProperty.country || '',
                }}
              />
              <SubmitButton buttonType="button" onClick={handleViewProperty}>
                View Property
              </SubmitButton>
            </>
          ) : (
            <SubHeader>Property isn&apos;t selected</SubHeader>
          )}
        </div>
      </Section>
    </div>
  )
}

export default WipProperties
