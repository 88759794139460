import { DeepPartial } from "react-hook-form";
import { LandlordAddressService } from "../services/landlord-address.service";
import { CreateLandlordAddressData, LandlordAddress } from "@containers/Sales/Properties/types/property.type";
import { useMutation } from "@tanstack/react-query";


export const useUpdateLandlordAddressMutation = () => {
  const updateLandlordAddress = (landlordId: number, landlordAddressId: number, data: DeepPartial<LandlordAddress>) => {
    const landlordAddressService = new LandlordAddressService();
    return landlordAddressService.updateLandlordAddress(landlordId, landlordAddressId, data);
  }

  const mutation = useMutation({
    mutationFn: ({ landlordId, landlordAddressId, data }: {
      landlordId: number,
      landlordAddressId: number,
      data: DeepPartial<LandlordAddress>
    }) => updateLandlordAddress(landlordId, landlordAddressId, data)
  })

  return mutation
}

export const useCreateLandlordAddressMutation = () => {

  const createLandlordAddress = async (landlordId: number, data: CreateLandlordAddressData) => {
    const landlordAddressService = new LandlordAddressService()
    return landlordAddressService.createLandlordAddress(landlordId, data)
  }

  const mutation = useMutation({
    mutationFn: ({ landlordId, data }: {
      landlordId: number,
      data: CreateLandlordAddressData
    }) => createLandlordAddress(landlordId, data)
  })

  return mutation;
}
