import React, { useState } from 'react';
import ListSMSDebtor from './ListSMSDebtor';
import ListSendEmailDebtor from './ListSendEmailDebtor';
import ListLetterSend from './ListLetterSend';


const Communication = ({setFetch,fetch}) => {



  return (
    <>
      <h3 className="page-title">
        SMS Sent to debtor
      </h3>
      <div className="tabs_wrapper card-body">
        <ListSMSDebtor/>
      </div>
      <h3 className="page-title mt-4 ">
        Email Sent to debtor
      </h3>
      <div className="tabs_wrapper card-body">
        <ListSendEmailDebtor fetch={fetch}/>
      </div>
      <h3 className="page-title mt-4 ">
        Letters Sent to debtor
      </h3>
      <div className="tabs_wrapper card-body">
        <ListLetterSend fetch={fetch}/>
      </div>
    </>
  );
};

export default Communication;
