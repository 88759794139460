import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from 'mdi-react/CloseIcon';
import Typography from '@material-ui/core/Typography';

const ViewModal = (props) => {
  const { closeModal } = props;
  return (
    <>
      <div style={{ position: 'relative', left: '375px', marginTop: '-25px' }}>
        <IconButton
          style={{ right: '24px', marginTop: '-16px' }}
          onClick={closeModal}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div>
        <h3 style={{ color: 'black' }} className="form__form-group">
          Message to {props.debtor}:
        </h3>
      </div>
      <div
        className="form__form-group"
        dangerouslySetInnerHTML={{ __html: props.text }}
      />
    </>
  );
};

export default ViewModal;
