import Section from '@components/section/section.component'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import EditWipDetailsForm from '../../forms/wip-details-form/edit-wip-details-form.component'
import { FC } from 'react'
import { WipDetailsSectionProps } from '../../../types/sections-props.type'

const WipDetailsSection: FC<WipDetailsSectionProps> = ({ wip }) => {
  return (
    <Section>
      <Header mb={4}>WIP Details</Header>
      <EditWipDetailsForm
        wipId={wip.id}
        initialValues={{
          cover_type: wip.cover?.id || null,
          date_lodged: wip.date_lodged,
          finalize_date: wip.finalize_date || null,
          state_of_origin: wip.state_of_origin || null,
          // TODO: Implement general_filing
          cover_as_date_log: wip.cover_as_date_log || null,
          general_filing: null,
        }}
      />
    </Section>
  )
}

export default WipDetailsSection
