import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { GraphApiMailService } from "@containers/Inbox/services/graph-api-mail.service";
import { UpdateMessageParams } from "@containers/Inbox/types/graph-api-mail.type";
import { useMutation } from "@tanstack/react-query";

export const useUpdateMessageByIdMutation = () => {
  const isAuthenticated = useIsAuthenticated()
  const { accounts, instance } = useMsal()

  const updateMessageById = async ({ id, body = {} }: UpdateMessageParams) => {
    if (!isAuthenticated) return null;
    const account = accounts[0] || {};
    const graphApiMailService = new GraphApiMailService();

    try {
      const { accessToken } = await instance.acquireTokenSilent({ scopes: ['Mail.ReadWrite'], account: account })
      const updatedMessage = await graphApiMailService.updateMessage(accessToken, id, body)
      return updatedMessage
    } catch (e) {
      console.log(e)
      return null;
    }
  }

  const mutation = useMutation({ mutationFn: updateMessageById });
  return mutation
}