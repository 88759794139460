import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { RTLProps } from '../../../shared/prop-types/ReducerProps';

import { Col, Container, Row } from 'reactstrap';
import { getURFInfo, updateURF } from './serviceCorporateRecoveryForm';
import RecoveryForm from './componentsCorporateForm/index';

const EditCorporateRecoveryForm = ({ rtl }) => {
  const [formInfo, setFormInfo] = useState(null);
  const [loading, setLoading] = useState(null);

  const { id } = useParams();
  const history = useHistory();

  const submitFormHandler = (data) => {
    setLoading(true);
    updateURF(id, data)
      .then(() => {
        setLoading(false);
        toast.success('Submited Successfully');
        history.push('/recoveries/forms/strata-body');
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message?.[0]);
      });
  };

  const getFormInfo = () => {
    const params = {
      join: ['company', 'company.login_type'],
    };

    getURFInfo(id, params)
      .then((res) => {
        setFormInfo(res.data);
      })
      .catch((e) => {
        toast.error('Something went wrong!');
      });
  };

  useEffect(() => {
    getFormInfo();
  }, []);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">
            Edit Strata Body Corporate Recovery Form
          </h3>
        </Col>
      </Row>
      <Row>
        {formInfo && (
          <RecoveryForm
            onSubmit={submitFormHandler}
            dir={rtl.direction}
            defaultData={formInfo}
            isEdit={true}
            loading={loading}
          />
        )}
      </Row>
    </Container>
  );
};

EditCorporateRecoveryForm.propTypes = {
  t: PropTypes.func.isRequired,
  rtl: RTLProps.isRequired,
};

export default connect((state) => ({
  rtl: state.rtl,
}))(withTranslation('common')(EditCorporateRecoveryForm));
