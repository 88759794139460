import { toast } from 'react-toastify'
import { useCreateDebtorLegalPlaintiffMutation } from '../../../hooks/mutations/crud-debtor-legal-plaintiff.hook'
import {
  CreateDebtorLegalPlaintiffFormProps,
  PlaintiffFormValues,
} from '../../../types/debtor-plaintiff.type'
import DebtorLegalPlaintiffForm from './debtor-legal-plaintiff-form.component'
import { FC } from 'react'

const CreateDebtorLegalPlaintiffForm: FC<CreateDebtorLegalPlaintiffFormProps> = ({
  debtorLegalId,
  onSuccessfulSubmit,
}) => {
  const { mutateAsync, isLoading } = useCreateDebtorLegalPlaintiffMutation()
  const handleSuccessSubmit = async (data: PlaintiffFormValues) => {
    try {
      const createdDebtorPlaintiff = await mutateAsync({
        data: {
          debtorLegalId,
          ...data,
        },
      })
      onSuccessfulSubmit(createdDebtorPlaintiff)
    } catch (error) {
      toast.error('Failed to create plaintiff')
      console.error(error)
    }
  }

  return (
    <DebtorLegalPlaintiffForm
      onSuccessfulSubmit={handleSuccessSubmit}
      loading={isLoading}
      submitButtonTitle="Create Plaintiff"
    />
  )
}

export default CreateDebtorLegalPlaintiffForm
