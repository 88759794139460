import { DebtorLegal, WipLegalTableItemProps } from '../../../types/debtor-legal.type'
import React, { FC } from 'react'
import TableRow from '@components/table/table-row.component'
import TableCell from '@components/table/table-cell-component'
import { format } from 'date-fns'
import styles from './wip-legal-table.module.scss'
import TrashIcon from '@src/icons/trash'
import { DebtorLegalService } from '../../../services/debtor-legal.service'
import { useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { getWipLegalListQueryKey } from '../../../utils/legal-query-key-factory.utils'
import { useHistory } from 'react-router'

const WipLegalTableItem: FC<WipLegalTableItemProps> = ({ debtorLegal, debtor, wipId }) => {
  const history = useHistory()
  const queryClient = useQueryClient()
  const { id, claimNumbers, courtLocation, judgmentDates, createdAt } = debtorLegal

  const handleRowClick = () => {
    if (!debtor) return
    history.push(`/wip/${wipId}/debtors/${debtor.id}/legal/${debtorLegal.id}`)
  }

  const handleDeleteClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    const debtorLegalService = new DebtorLegalService()
    try {
      await debtorLegalService.deleteDebtorLegal(debtorLegal.id)
      queryClient.setQueriesData<DebtorLegal[]>(getWipLegalListQueryKey(wipId), (prevData) =>
        prevData ? prevData.filter((dl) => dl.id !== debtorLegal.id) : [],
      )
    } catch (e) {
      console.error(e)
      toast.error('Failed to delete debtor')
    }
  }
  return (
    <TableRow pointer={!!debtor} onClick={handleRowClick}>
      {/* ID */}
      <TableCell width={70} position="eclipsed">
        {id}
      </TableCell>
      {/* LOGGED */}
      <TableCell className={styles.loggedCell} width={161} position="eclipsed">
        {format(createdAt, 'kk:mm | dd-MM-yyyy')}
      </TableCell>
      {/* DEBTORS */}
      <TableCell className={styles.debtorCell} position="eclipsed">
        {debtor ? `${debtor.name_first} ${debtor.name_last}` : 'Not Found'}
      </TableCell>
      {/* COURT LOCATION */}
      <TableCell position="eclipsed">{courtLocation ? courtLocation : 'Not Found'}</TableCell>
      {/* CLAIM NUMBER/S */}
      <TableCell position="eclipsed">
        {claimNumbers.length > 0
          ? claimNumbers.map((cn) => cn.claimNumber).join(', ')
          : 'Not Found'}
      </TableCell>
      {/* JUDGMENT DATE */}
      <TableCell position="eclipsed">
        {judgmentDates.length > 0
          ? judgmentDates.map((jd) => format(jd.judgmentDate, 'MM/dd/yyyy')).join(', ')
          : 'Not Found'}
      </TableCell>
      {/* REMOVE */}
      <TableCell position="eclipsed" width={70}>
        <button onClick={handleDeleteClick} className={styles.removeButton}>
          <TrashIcon />
        </button>
      </TableCell>
    </TableRow>
  )
}

export default WipLegalTableItem
