import { FC } from 'react'
import styles from './button-pagination.module.scss'
import { PaginationActionForwardProps } from './button-pagination.type'
import cls from 'classnames'
import RoundedArrow from '@src/icons/rounded-arrow'

export const PaginationActionForward: FC<PaginationActionForwardProps> = ({
  currentPage,
  totalCount,
  limit,
  onClick,
}) => {
  const lastPage = Math.ceil(totalCount / limit)
  const isLastPage = currentPage === lastPage

  const handleActionForward = () => {
    if (isLastPage) {
      return
    }
    onClick()
  }

  return (
    <button
      className={cls(styles.itemWrapper, styles.paginationActionWrapper, {
        [styles.disabled]: isLastPage,
      })}
      onClick={handleActionForward}
    >
      <RoundedArrow className={styles.rightArrow} />
    </button>
  )
}
