import { useCreateDebtorRelationMutation } from '@containers/Recovery/Wip/_modules/Debtors/hooks/mutations/crud-debtor-relation.hook'
import DebtorRelationForm from './debtor-relation-form.component'
import {
  CreateDebtorRelationFormProps,
  DebtorRelationFormValues,
} from '@containers/Recovery/Wip/_modules/Debtors/types/debtor-associate-detail.type'
import { toast } from 'react-toastify'
import { FC } from 'react'

const CreateDebtorRelationForm: FC<CreateDebtorRelationFormProps> = ({
  debtorId,
  onSuccessSubmit,
  onCancelClick,
}) => {
  const { mutateAsync, isLoading } = useCreateDebtorRelationMutation()

  const handleSubmit = async (data: DebtorRelationFormValues) => {
    try {
      const debtorRelation = await mutateAsync({
        ...data,
        debtor_id: debtorId,
        relationship: data.relationship.value,
        call_result: data.call_result.value,
      })
      onSuccessSubmit && onSuccessSubmit(debtorRelation)
      toast.success('Created')
    } catch (e) {
      toast.error('Something went wrong')
      console.log(e)
    }
  }

  return (
    <DebtorRelationForm onSubmit={handleSubmit} onCancelClick={onCancelClick} loading={isLoading} />
  )
}

export default CreateDebtorRelationForm
