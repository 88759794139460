import { FC, useState } from 'react'
import { DebtorLegalTribunal } from '../../../types/debtor-legal.type'
import { useModal } from '@src/hooks/useModal'
import { BaseListProps } from '@src/types/component-types'
import EditDebtorLegalTribunalModal from '../../modals/debtor-legal-tribunal-modal/edit-debtor-legal-tribunal-modal.component'
import DebtorLegalTribunalListItem from './debtor-tribunal-list-item.component'

const DebtorLegalTribunalList: FC<BaseListProps<DebtorLegalTribunal>> = ({
  entities: debtorLegalTribunals,
}) => {
  const [isOpen, handleOpen, handleClose] = useModal(false)
  const [selectedDebtorLegalTribunal, setSelectedDebtorLegalTribunal] =
    useState<DebtorLegalTribunal | null>(null)

  const handleEditClick = (debtorLegalTribunal: DebtorLegalTribunal) => {
    setSelectedDebtorLegalTribunal(debtorLegalTribunal)
    handleOpen()
  }

  return (
    <div>
      {debtorLegalTribunals.map((debtorLegalTribunal) => {
        return (
          <DebtorLegalTribunalListItem
            onEditClick={handleEditClick}
            entity={debtorLegalTribunal}
            key={debtorLegalTribunal.id}
          />
        )
      })}
      {selectedDebtorLegalTribunal ? (
        <EditDebtorLegalTribunalModal
          entity={selectedDebtorLegalTribunal}
          isOpen={isOpen}
          onClose={handleClose}
        />
      ) : null}
    </div>
  )
}

export default DebtorLegalTribunalList
