import React from 'react';

import { Grid, Typography } from '@mui/material';

import FormInput from '../../../../../shared/components/FormInput/FormInput';

import { commercialUncoveredFormStyles } from '../../styles';

const ManagementDetails = ({ handleChange, values, errors, touched }) => {
  return (
    <Grid item sx={commercialUncoveredFormStyles?.collectionContainer}>
      <Typography sx={commercialUncoveredFormStyles?.collectionTitle}>
        Management Details
      </Typography>

      <FormInput
        changeHandler={handleChange}
        label="Property Manager"
        name="management_details.property_manager"
        placeholder="Property Manager"
        type="text"
        value={values?.management_details?.property_manager}
        required={true}
        error={errors?.management_details?.property_manager}
        touched={touched?.management_details?.property_manager}
      />

      <FormInput
        changeHandler={handleChange}
        label="Reports Email"
        name="management_details.reports_email"
        placeholder="Reports Email"
        type="text"
        value={values?.management_details?.reports_email}
        required={true}
        error={errors?.management_details?.reports_email}
        touched={touched?.management_details?.reports_email}
      />
    </Grid>
  );
};

export default ManagementDetails;
