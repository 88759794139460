import { FC } from 'react'
import styles from './secondary-table.module.scss'
import { SecondaryTableProps } from '@containers/Sales/Properties/types/property.type'

const SecondaryTable: FC<SecondaryTableProps> = ({ children }) => {
  return (
    <table className={styles.tableWrapper}>
      <tbody>{children}</tbody>
    </table>
  )
}

export default SecondaryTable
