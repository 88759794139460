import { Checkbox } from '@mui/material';
import Select from 'react-select';
import React from 'react';

export const RecoveryDetails = (props) => {
  const { companyLoginType, setRecoveryDetails, recoveryDetails } = props;

  const onCheckboxChange = (e) => {
    const name = e.target.name;
    const isChecked = e.target.checked;
    setRecoveryDetails((prev) => ({ ...prev, [name]: isChecked }));
  };

  const onInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setRecoveryDetails((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <>
      {companyLoginType === 'REA Residential' && (
        <>
          <div className="card__title">
            <h5 className="bold-text">Recovery Details</h5>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Tenant Vacated</span>
            <div className="form__form-group-field">
              <Checkbox
                name="is_tenant_vacated"
                value={recoveryDetails.is_tenant_vacated}
                onChange={onCheckboxChange}
                className="form-control"
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Date Vacated</span>
            <div className="form__form-group-field">
              <input
                name="date_vacated"
                type="date"
                placeholder="Date Vacated"
                onChange={onInputChange}
                // defaultValue={defaultData?.recovery_details?.date_vacated}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Tenant Evicted</span>
            <div className="form__form-group-field">
              <Checkbox
                name="is_tenant_evicted"
                value={recoveryDetails.is_tenant_evicted}
                onChange={onCheckboxChange}
                className="form-control"
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Evicted by</span>
            <div className="form__form-group-field">
              <input
                name="evicted_by"
                type="text"
                placeholder="Evicted by"
                onChange={onInputChange}
                value={recoveryDetails.evicted_by}
                // defaultValue={defaultData?.recovery_details?.evicted_by}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Break Fixed Term</span>
            <div className="form__form-group-field">
              <input
                name="break_fixed_term"
                type="text"
                placeholder="Break Fixed Term"
                onChange={onInputChange}
                value={recoveryDetails.break_fixed_term}
                // defaultValue={defaultData?.recovery_details?.break_fixed_term}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Date Re-Leased</span>
            <div className="form__form-group-field">
              <input
                name="date_re_leased"
                type="date"
                placeholder="Date Re-Leased"
                onChange={onInputChange}
                value={recoveryDetails.date_re_leased}
                // defaultValue={defaultData?.recovery_details?.date_re_leased}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">
              Applied for hearing or received order
            </span>
            <div className="form__form-group-field">
              <Select
                name="applied_for"
                value={recoveryDetails.applied_for}
                onChange={onInputChange}
                options={['Hearing', 'Received Order']}
                classNamePrefix="react-select"
                className="react-select"
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Date Of Hearing</span>
            <div className="form__form-group-field">
              <input
                name="date_of_hearing"
                type="date"
                placeholder="Date Of Hearing"
                value={recoveryDetails.date_of_hearing}
                onChange={onInputChange}
                // defaultValue={defaultData?.recovery_details?.date_of_hearing}
              />
            </div>
          </div>

          <div className="form__form-group">
            <span className="form__form-group-label">Insurance Cover</span>
            <div className="form__form-group-field">
              <input
                name="insurance_cover"
                type="text"
                placeholder="Insurance Cover"
                value={recoveryDetails.insurance_cover}
                onChange={onInputChange}
                // defaultValue={defaultData?.recovery_details?.insurance_cover}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Insurance Payout</span>
            <div className="form__form-group-field">
              <input
                name="insurance_payout"
                type="string"
                placeholder="Insurance Payout"
                value={recoveryDetails.insurance_payout}
                onChange={onInputChange}
                // defaultValue={defaultData?.recovery_details?.insurance_payout}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Insurance Company</span>
            <div className="form__form-group-field">
              <input
                name="insurance_company"
                type="text"
                placeholder="Insurance Company"
                value={recoveryDetails.insurance_company}
                onChange={onInputChange}
                // defaultValue={defaultData?.recovery_details?.insurance_company}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Policy No</span>
            <div className="form__form-group-field">
              <input
                name="policy_no"
                type="text"
                placeholder="Policy No"
                value={recoveryDetails.policy_no}
                onChange={onInputChange}
                // defaultValue={defaultData?.recovery_details?.policy_no}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Insurance Covered</span>
            <div className="form__form-group-field">
              <input
                name="insurance_covered"
                type="text"
                placeholder="Insurance Covered"
                value={recoveryDetails.insurance_covered}
                onChange={onInputChange}
                // defaultValue={defaultData?.recovery_details?.insurance_covered}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">
              Insurance payout in reduction of total
            </span>
            <div className="form__form-group-field">
              <input
                name="insurance_payout_reduction"
                type="text"
                placeholder="Insurance payout in reduction of total"
                value={recoveryDetails.insurance_payout_reduction}
                onChange={onInputChange}
                // defaultValue={
                //   defaultData?.recovery_details?.insurance_payout_reduction
                // }
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};
