import { FC, useMemo } from 'react'
import LandlordDetailsForm, { LandlordDetailsFormValues } from './landlord-details-form.component'
import { BaseEditFormProps } from '@src/types/component-types'
import { Landlord } from '@containers/Sales/Properties/types/property.type'
import { useUpdateLandlordMutation } from '@containers/Sales/Landlord/hooks/crud-landlord.hook'
import { toast } from 'react-toastify'
import { useGetLandlordContacts } from '@containers/Sales/Landlord/hooks/crud-landlord-contact.hook'

const EditLandlordDetailsForm: FC<BaseEditFormProps<Landlord>> = ({ entity, onSuccessSubmit }) => {
  const { data } = useGetLandlordContacts(entity.id)
  const { mutateAsync } = useUpdateLandlordMutation()
  console.log(data?.data)
  const initialValues = useMemo(
    () => ({
      companyName: entity.companyName,
      abn: entity.abn,
      ownershipType: entity.ownershipType,
    }),
    [entity],
  )
  const handleSubmit = async (data: LandlordDetailsFormValues) => {
    try {
      const res = await mutateAsync({
        landlordId: entity.id,
        data: {
          companyName: data.companyName,
          abn: data.abn,
          ownershipType: data.ownershipType,
        },
      })

      onSuccessSubmit(res)
    } catch (e) {
      toast.error('Failed to update landlord details')
      console.error(e)
    }
  }

  return <LandlordDetailsForm onSuccessSubmit={handleSubmit} initialValues={initialValues} />
}

export default EditLandlordDetailsForm
