import { FC, useState } from 'react'
import AddNote from './add-note.component'
import NoteList from './note-list.component'

export const NoteTab: FC = () => {
  const [selectedNote, setSelectedNote] = useState<number | null>(null)

  const handleNoteSelect = (id: number | null) => {
    setSelectedNote(id)
  }

  return (
    <div>
      <div className="tabs_wrapper card-body mb-3" style={{borderTopLeftRadius: 0, borderTopRightRadius: 0}}>
        <AddNote selectedNote={selectedNote} selectNote={handleNoteSelect} />
      </div>
      <div className="tabs_wrapper card-body mb-3">
        <NoteList selectNote={handleNoteSelect} />
      </div>
    </div>
  )
}
