import { Component } from 'react'
import { Col, Container, Row, ButtonGroup } from 'reactstrap'
import Visits from '../Dashboards/Default/components/Visits'
import TotalPageViews from '../Dashboards/Default/components/TotalPageViews'
import NewUsers from '../Dashboards/Default/components/NewUsers'
import BounceRate from '../Dashboards/Default/components/BounceRate'
import { withRouter } from 'react-router'
import IconButton from '@material-ui/core/IconButton'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import Collapse from '@material-ui/core/Collapse'
import Box from '@material-ui/core/Box'
import Select from 'react-select'
import TableHead from '@material-ui/core/TableHead'
import Popover from '@material-ui/core/Popover'
import AddTaskModal from '../Todo/AddTaskModal'
import Services from './../../shared/Services'

const fileLoacationOption = [
  { value: '0', label: 'General filing' },
  { value: '1', label: 'General filing – No Order' },
  { value: '2', label: 'Paying' },
  { value: '3', label: 'Paying Agent Direct' },
  { value: '4', label: 'Eviction' },
  { value: '5', label: 'Paid in full' },
  { value: '6', label: 'Purged – Bankrupt' },
  { value: '7', label: 'Purged – Overseas' },
  { value: '8', label: 'Purged – No TAF' },
  { value: '9', label: 'Purged – No Order' },
  { value: '10', label: 'Paying Agent' },
  { value: '11', label: 'Purged – jail' },
  { value: '12', label: 'Purged – Deceased' },
  { value: '13', label: 'Purged - By Agen' },
  { value: '14', label: 'Purged - No Cover' },
  { value: '15', label: 'Purged - Insurance' },
  { value: '16', label: 'QLD General Filing' },
  { value: '17', label: 'QLD PIF Filing' },
  { value: '18', label: 'QLD Purge Filing' },
  { value: '19', label: 'QLD Audit Box 1' },
  { value: '20', label: 'QLD Audit Box 2' },
  { value: '21', label: 'QLD Audit Box 3' },
  { value: '22', label: 'QLD Audit Box 4' },
  { value: '23', label: 'QLD Other' },
  { value: '24', label: 'NSW General Filing' },
  { value: '25', label: 'NSW PIF Filing' },
  { value: '26', label: 'NSW Purge Filing' },
  { value: '27', label: 'NSW Other' },
  { value: '28', label: 'VIC General Filing' },
  { value: '29', label: 'VIC PIF Filing' },
  { value: '30', label: 'VIC Purge Filing' },
  { value: '31', label: 'VIC Othe' },
]

class WIPList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      dropdownopen: false,
      anchorEl: null,
      anchorE2: null,
      WipidDropdown: false,
      ClientDropdown: false,
      staffDropdown: false,
      isEdit: false,
      addAppointment: false,
      allStaff: [],
      staffFilter: 'All',
      staffData: [],
      allDebtor: [],
      staffDataCopy: [],
      allClient: [],
      clientFilter: 'All',
      currentId: '',
      openArrow: false,
    }
    this.services = new Services()
  }

  componentDidMount() {
    this.getAllstaff()
    this.getWIPStaff()
    this.getDebtorList()
    this.getAllClient()
  }

  getAllClient() {
    this.services.getallClient((res) => {
      if (res.status === 200) {
        const getData = res.data.data
        let listClient
        listClient =
          getData &&
          getData.map((item) => {
            return { value: item._id, label: item.name }
          })
        this.setState({
          allClient: listClient,
        })
      } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
        this.setState({ accessErrorModal: true })
      } else {
        this.services.errorToast(res.message)
      }
    })
  }

  getAllstaff() {
    let url = 'Staff/getDebtor'
    const data = {}
    this.services.getStaff(data, url, (res) => {
      if (res.status === 200) {
        const getData = res.data.data.list
        let listStaff
        listStaff =
          getData &&
          getData.map((item) => {
            return { value: item.id, label: `${item.first_name}  ${item.last_name}` }
          })
        this.setState({ allStaff: listStaff })
      } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
        this.setState({ accessErrorModal: true })
      }
    })
  }

  getWIPStaff = () => {
    const data = {}
    this.services.getWIPforClientList(data, (res) => {
      if (res.status === 200) {
        this.setState({
          staffData: res.data.data.list,
          staffDataCopy: res.data.data.list,
        })
      } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
        this.setState({ accessErrorModal: true })
      }
    })
  }

  getDebtorList() {
    this.services.getDebtorListDropDown((res) => {
      if (res.status === 200) {
        const getData = res.data.data
        let list
        list =
          getData &&
          getData.map((item) => {
            return {
              value: item._id,
              label: item.contact_1_first + ' ' + item.contact_1_last,
            }
          })
        this.setState({
          allDebtor: list,
        })
      } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
        this.setState({ accessErrorModal: true })
      } else {
        this.services.errorToast(res.message)
      }
    })
  }

  // ActionClick = (event) => {
  //   this.props.history.push("/Recovery-Action");
  // };
  ClientClick = (event) => {
    this.setState({
      anchorE2: event.currentTarget,
    })
  }
  setOpen = () => {
    this.setState({
      dropdownopen: !this.state.dropdownopen,
    })
  }
  // WipidClick = (event) => {
  //   this.setState({
  //     anchorE2: event.currentTarget,
  //   });
  // };
  StaffClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    })
  }
  handleClose = () => {
    this.setState({
      anchorEl: null,
      anchorE2: null,
    })
  }

  addAppointmentClick = () => {
    this.setState({
      isEdit: false,
      addAppointment: true,
    })
  }

  closeModal = () => {
    this.setState({
      addAppointment: false,
    })
  }

  filterDataByStaff = (item) => {
    if (item) {
      this.setState({ staffFilter: item })
      let list = []
      if (item === 'All') {
        this.setState({ staffDataCopy: this.state.staffData })
      } else {
        list = this.state.staffData.filter((task) => {
          const tList = task.staff.find((_) => _.staff_id === item)
          if (tList) {
            return task
          }
        })
        if (list) {
          this.setState({ staffDataCopy: list })
        }
      }
    }
  }
  filterDataByClient = (item) => {
    if (item) {
      this.setState({ staffFilter: item })
      let list = []
      if (item === 'All') {
        this.setState({ staffDataCopy: this.state.staffData })
      } else {
        list = this.state.allClient.filter((task) => {
          const tList = task.staff.find((_) => _.staff_id === item)
          if (tList) {
            return task
          }
        })
        if (list) {
          this.setState({ staffDataCopy: list })
        }
      }
    }
  }

  render() {
    const open = Boolean(this.state.anchorEl)
    const openType = Boolean(this.state.anchorE2)
    const id = open ? 'simple-popover' : undefined
    const idopenType = openType ? 'simple-popover' : undefined
    const { allStaff, staffDataCopy } = this.state
    return (
      <div>
        <Container className="dashboard">
          <Row>
            <Visits />
            <TotalPageViews />
            <NewUsers />
            <BounceRate />
          </Row>
          <Row>
            <Col xs={12}>
              <Row className="align-items-center">
                <Col lg={6} className="pb-4 col-lg-6 col-md-6 col-sm-6 col-6">
                  <h3 className="page-title mb-0">{'List of WIPs'}</h3>
                </Col>
                <Col lg={6} className="pb-4 col-lg-6 col-md-6 col-sm-6 col-6">
                  <div className="text-right">
                    <button
                      className="btn btn-secondary add_plus rounded-circle m-0"
                      onClick={this.addAppointmentClick}
                    >
                      +
                    </button>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col xs={12} className="">
              <div className="table_wrap table_radius min_height_50">
                <Table className="MuiTable-root material-table ">
                  <thead>
                    <th>
                      {' '}
                      Wip Id{' '}
                      {/* <IconButton
                        className="ml-3"
                        aria-label="expand row"
                        size="small"
                        onClick={this.WipidClick}
                      >
                        {this.state.WipidDropdown ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </IconButton>
                      <Popover
                        id={idopenType}
                        open={openType}
                        anchorEl={this.state.anchorE2}
                        onClose={this.handleClose}
                        className="dropdown_height theme-light ltr-support"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                      >
                        <li className="table_head_dropdown">
                          <div className="form__form-group">
                            <div className="form__form-group-field">
                              <label
                                className={`${"colored-click"}${` radio-btn--colored`}`}
                              >
                                <input
                                  className="radio-btn__radio"
                                  type="radio"
                                  name="Appointment"
                                  id={"Appointment"}
                                  checked={
                                    this.state.taskFilter === "Appointment"
                                  }
                                />
                                <span className="radio-btn__radio-custom" />
                                <span className="radio-btn__label mt-0">
                                  {"Appointment"}
                                </span>
                              </label>
                            </div>
                          </div>
                        </li>
                      </Popover> */}
                    </th>
                    <th>Subjects</th>
                    <th>Lodged</th>
                    <th>Property</th>
                    <th>Status</th>
                    <th>Cover</th>
                    <th>
                      {' '}
                      Client{' '}
                      <IconButton
                        className="ml-3"
                        aria-label="expand row"
                        size="small"
                        onClick={this.ClientClick}
                      >
                        {this.state.ClientDropdown ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </IconButton>
                      <Popover
                        id={idopenType}
                        open={openType}
                        anchorEl={this.state.anchorE2}
                        onClose={this.handleClose}
                        className="dropdown_height theme-light ltr-support"
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                      >
                        <li className="table_head_dropdown">
                          <div className="form__form-group">
                            <div className="form__form-group-field">
                              <label className={`colored-click radio-btn--colored`}>
                                <input
                                  className="radio-btn__radio"
                                  type="radio"
                                  name="clientAll"
                                  id={'ClientAll'}
                                  checked={this.state.taskFilter === 'All'}
                                />
                                <span className="radio-btn__radio-custom" />
                                <span className="radio-btn__label mt-0">{'All'}</span>
                              </label>
                            </div>
                          </div>
                        </li>
                        {this.state.allClient.map((item) => {
                          return (
                            <li className="table_head_dropdown" key={item._id}>
                              {/* <div className="custom-control custom-radio">
                                        <input type="radio" className="custom-control-input" name={item.value} id={item.value} checked={this.state.staffFilter === item.value} onChange={()=>this.filterDataByStaff(item.value)} />
                                        <label className="custom-control-label" for={item.value}>{item.label}</label>
                                    </div> */}
                              <div className="form__form-group">
                                <div className="form__form-group-field">
                                  <label className={`colored-click radio-btn--colored`}>
                                    <input
                                      className="radio-btn__radio"
                                      type="radio"
                                      name={item.value}
                                      id={item.value}
                                      checked={this.state.clientFilter === item.value}
                                      // onChange={() =>
                                      //   this.filterDataByStaff(item.value)
                                      // }
                                    />
                                    <span className="radio-btn__radio-custom" />
                                    <span className="radio-btn__label mt-0">{item.label}</span>
                                  </label>
                                </div>
                              </div>
                            </li>
                          )
                        })}
                      </Popover>
                    </th>
                    <th>
                      {' '}
                      Staff{' '}
                      <IconButton
                        className="ml-3"
                        aria-label="expand row"
                        size="small"
                        onClick={this.StaffClick}
                      >
                        {this.state.staffDropdown ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </IconButton>
                      <Popover
                        id={id}
                        open={open}
                        anchorEl={this.state.anchorEl}
                        onClose={this.handleClose}
                        className="dropdown_height theme-light ltr-support"
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                      >
                        <li className="table_head_dropdown">
                          <div className="form__form-group">
                            <div className="form__form-group-field">
                              <label className={`colored-click radio-btn--colored`}>
                                <input
                                  className="radio-btn__radio"
                                  type="radio"
                                  name="All"
                                  id={'All'}
                                  checked={this.state.staffFilter === 'All'}
                                  onChange={() => this.filterDataByStaff('All')}
                                />
                                <span className="radio-btn__radio-custom" />
                                <span className="radio-btn__label mt-0">{'All'}</span>
                              </label>
                            </div>
                          </div>
                        </li>
                        {allStaff.map((item) => {
                          return (
                            <li className="table_head_dropdown" key={item._id}>
                              {/* <div className="custom-control custom-radio">
                                        <input type="radio" className="custom-control-input" name={item.value} id={item.value} checked={this.state.staffFilter === item.value} onChange={()=>this.filterDataByStaff(item.value)} />
                                        <label className="custom-control-label" for={item.value}>{item.label}</label>
                                    </div> */}
                              <div className="form__form-group">
                                <div className="form__form-group-field">
                                  <label className={`colored-click radio-btn--colored`}>
                                    <input
                                      className="radio-btn__radio"
                                      type="radio"
                                      name={item.value}
                                      id={item.value}
                                      checked={this.state.staffFilter === item.value}
                                      // onChange={() =>
                                      //   this.filterDataByStaff(item.value)
                                      // }
                                    />
                                    <span className="radio-btn__radio-custom" />
                                    <span className="radio-btn__label mt-0">{item.label}</span>
                                  </label>
                                </div>
                              </div>
                            </li>
                          )
                        })}
                      </Popover>
                    </th>
                  </thead>
                  <TableBody>
                    {staffDataCopy.map((d) => {
                      let fileLocation = fileLoacationOption.find(
                        (_) => _.value === d.file_location,
                      )

                      if (fileLocation) {
                        fileLocation = fileLocation.label
                      }
                      const subject =
                        d.subject &&
                        d.subject
                          .map((sub) => {
                            const temp =
                              this.state.allDebtor &&
                              this.state.allDebtor.find((_) => _.value === sub)
                            if (temp) {
                              return temp.label
                            }
                          })
                          .join(', ')
                      const client =
                        this.state.allClient &&
                        this.state.allClient.find((_) => _.value === d.client_id)
                      return (
                        <>
                          <TableRow
                            className="material-table__row bg-white boxshadow_tbl"
                            role="checkbox"
                            tabIndex={-1}
                          >
                            <TableCell className="material-table__cell material-table__cell-right">
                              <b className="link_text"> {d.file_number} </b>
                            </TableCell>

                            <TableCell className="material-table__cell material-table__cell-right">
                              <b className="link_text">{subject}</b>
                            </TableCell>
                            <TableCell className="material-table__cell material-table__cell-right">
                              {d.lodged}
                            </TableCell>

                            <TableCell className="material-table__cell material-table__cell-right">
                              {' - '}
                            </TableCell>
                            <TableCell className="material-table__cell material-table__cell-right">
                              {fileLocation}
                            </TableCell>
                            <TableCell className="material-table__cell material-table__cell-right ">
                              {d.cover === '0' ? 'Protect & Collect' : 'None'}
                            </TableCell>

                            <TableCell className="material-table__cell material-table__cell-right ">
                              <b className="link_text"> {client ? client.label : ''} </b>
                            </TableCell>

                            <TableCell className="material-table__cell material-table__cell-left">
                              <div className="d-flex flex-wrap align-items-center justify-content-between">
                                <div className="d-flex round_three flex-wrap mb-2 align-items-center">
                                  <span
                                    className="rounded-circle uppercase staff_name mr-2"
                                    style={{
                                      border: `2px solid rgb(237, 12, 57)`,
                                      color: `rgb(237, 12, 57)`,
                                    }}
                                  >
                                    TC
                                  </span>
                                  <div className="d-flex align-items-center">
                                    <ButtonGroup className="btn-group--icons mb-0" dir="ltr">
                                      <IconButton
                                        className="ml-3"
                                        aria-label="expand row"
                                        size="small"
                                        onClick={() => this.setOpen(d._id)}
                                      >
                                        {this.state.currentId === d._id ? (
                                          <KeyboardArrowUpIcon />
                                        ) : (
                                          <KeyboardArrowDownIcon />
                                        )}
                                      </IconButton>
                                    </ButtonGroup>
                                  </div>
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                          <TableRow className="form ">
                            <TableCell
                              style={{ paddingBottom: 0, paddingTop: 0 }}
                              className="border-0"
                              colSpan={8}
                            >
                              <div className="bg-white inner_tbl">
                                <Collapse
                                  className="px-lg-4"
                                  timeout="auto"
                                  unmountOnExit
                                  in={this.state.currentId === d._id}
                                >
                                  <Box margin={1}>
                                    <div className="row align-items-center justify-content-md-between py-3">
                                      <Col md={4}>
                                        <h3> Overview </h3>
                                      </Col>

                                      <Col md={8}>
                                        <div className="d-flex align-items-center justify-content-md-end">
                                          <button className="btn btn-primary">SMS</button>
                                          <button className="btn btn-primary">Call</button>
                                          <button className="btn btn-primary">Send Email</button>
                                        </div>
                                      </Col>
                                    </div>
                                    <hr />
                                    <Col md={12}>
                                      <span className="card-link form__form-group-label">
                                        TOTALS
                                      </span>
                                      <textarea placeholder="Add Your Note Here" className="p-3" />
                                    </Col>
                                    <div className="w-100 d-flex justify-content-end p-3">
                                      <button className="btn btn-primary mb-0">Save</button>
                                      <button className="btn-gray mr-2">Action Task</button>
                                    </div>
                                  </Box>
                                  <hr />
                                  <Box margin={1}>
                                    <div className="row align-items-center justify-content-md-between py-3">
                                      <Col md={4}>
                                        <h3>NOTES HISTORY: </h3>
                                      </Col>
                                      <Col md={8}>
                                        <div className="d-flex align-items-center justify-content-md-end">
                                          <Col md={4}>
                                            <div className="form-group">
                                              <Select
                                                name="client_type"
                                                type="text"
                                                classNamePrefix="react-select"
                                                className="react-select height_auto"
                                                placeholder="Filter.."
                                              />
                                            </div>
                                          </Col>
                                        </div>
                                      </Col>
                                    </div>
                                    <Table aria-label="purchases">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell>Date</TableCell>
                                          <TableCell>Time</TableCell>
                                          <TableCell>Application</TableCell>
                                          <TableCell>Task </TableCell>
                                          <TableCell>Note</TableCell>
                                          <TableCell>Staff</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        <TableRow className="form">
                                          <TableCell
                                            className="material-table__cell material-table__cell-right"
                                            component="th"
                                            scope="row"
                                          >
                                            15-09-20
                                          </TableCell>
                                          <TableCell className="material-table__cell material-table__cell-right">
                                            15:09:PM
                                          </TableCell>
                                          <TableCell className="material-table__cell material-table__cell-right">
                                            <div className="form-group mb-0 mt-2">Meeting:</div>
                                          </TableCell>
                                          <TableCell className="material-table__cell material-table__cell-right">
                                            Meeting:
                                          </TableCell>
                                          <TableCell className="material-table__cell material-table__cell-right">
                                            here is important notes
                                          </TableCell>
                                          <TableCell className="material-table__cell material-table__cell-right">
                                            Krushal sonani
                                          </TableCell>
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  </Box>

                                  <Box margin={1} mt={2}>
                                    <div className="row align-items-center justify-content-md-between py-3">
                                      <Col md={4}>
                                        <h3>EMAIL HISTORY: </h3>
                                      </Col>
                                    </div>
                                    <Table aria-label="purchases">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell>Date</TableCell>
                                          <TableCell>Time</TableCell>
                                          <TableCell>To</TableCell>
                                          <TableCell>Subject </TableCell>
                                          <TableCell>Sender</TableCell>
                                          <TableCell>Status</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        <TableRow className="form">
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            className="material-table__cell material-table__cell-right"
                                          >
                                            15-09-20
                                          </TableCell>
                                          <TableCell className="material-table__cell material-table__cell-right">
                                            15:09:PM
                                          </TableCell>
                                          <TableCell className="material-table__cell material-table__cell-right">
                                            <div className="form-group mb-0 mt-2">
                                              test@gmail.com
                                            </div>
                                          </TableCell>
                                          <TableCell className="material-table__cell material-table__cell-right">
                                            Recruitment Requirements
                                          </TableCell>
                                          <TableCell className="material-table__cell material-table__cell-right">
                                            Rhonda Thorncraft
                                          </TableCell>
                                          <TableCell className="material-table__cell material-table__cell-right">
                                            <div className="d-flex flex-wrap align-items-center justify-content-between">
                                              <div className="d-flex round_three flex-wrap mb-2 align-items-center">
                                                <span> Sent </span>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <button
                                                  style={{
                                                    border: 'none',
                                                    background: 'Transparent',
                                                  }}
                                                >
                                                  <b className="link_text">Calio &amp; View </b>{' '}
                                                </button>
                                              </div>
                                            </div>
                                          </TableCell>
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  </Box>

                                  <Box margin={1} mt={2}>
                                    <div className="row align-items-center justify-content-md-between py-3">
                                      <Col md={4}>
                                        <h3>SMS HISTORY: </h3>
                                      </Col>
                                    </div>
                                    <Table aria-label="purchases">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell>Date</TableCell>
                                          <TableCell>Time</TableCell>
                                          <TableCell>To</TableCell>
                                          <TableCell>Subject </TableCell>
                                          <TableCell>Sender</TableCell>
                                          <TableCell>Status</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        <TableRow className="form">
                                          <TableCell
                                            className="material-table__cell material-table__cell-right"
                                            component="th"
                                            scope="row"
                                          >
                                            15-09-20
                                          </TableCell>
                                          <TableCell className="material-table__cell material-table__cell-right">
                                            15:09:PM
                                          </TableCell>
                                          <TableCell className="material-table__cell material-table__cell-right">
                                            <div className="form-group mb-0 mt-2">
                                              test@gmail.com
                                            </div>
                                          </TableCell>
                                          <TableCell className="material-table__cell material-table__cell-right">
                                            Recruitment Requirements
                                          </TableCell>
                                          <TableCell className="material-table__cell material-table__cell-right">
                                            Rhonda Thorncraft
                                          </TableCell>
                                          <TableCell className="material-table__cell material-table__cell-right">
                                            <div className="d-flex flex-wrap align-items-center justify-content-between">
                                              <div className="d-flex round_three flex-wrap mb-2 align-items-center">
                                                <span> Sent </span>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <button
                                                  style={{
                                                    border: 'none',
                                                    background: 'Transparent',
                                                  }}
                                                >
                                                  <b className="link_text">Calio &amp; View </b>{' '}
                                                </button>
                                              </div>
                                            </div>
                                          </TableCell>
                                        </TableRow>

                                        <TableRow className="form">
                                          <TableCell
                                            className="material-table__cell material-table__cell-right"
                                            component="th"
                                            scope="row"
                                          >
                                            15-09-20
                                          </TableCell>
                                          <TableCell className="material-table__cell material-table__cell-right">
                                            15:09:PM
                                          </TableCell>
                                          <TableCell className="material-table__cell material-table__cell-right">
                                            <div className="form-group mb-0 mt-2">
                                              test@gmail.com
                                            </div>
                                          </TableCell>
                                          <TableCell className="material-table__cell material-table__cell-right">
                                            Recruitment Requirements
                                          </TableCell>
                                          <TableCell className="material-table__cell material-table__cell-right">
                                            Rhonda Thorncraft
                                          </TableCell>
                                          <TableCell className="material-table__cell material-table__cell-right">
                                            <div className="d-flex flex-wrap align-items-center justify-content-between">
                                              <div className="d-flex round_three flex-wrap mb-2 align-items-center">
                                                <span> Sent </span>
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <button
                                                  style={{
                                                    border: 'none',
                                                    background: 'Transparent',
                                                  }}
                                                >
                                                  <b className="link_text">Calio &amp; View </b>{' '}
                                                </button>
                                              </div>
                                            </div>
                                          </TableCell>
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  </Box>
                                  <div className="w-100 d-flex justify-content-end p-3">
                                    <button className="btn btn-primary mb-0">Complete Task</button>
                                  </div>
                                </Collapse>
                              </div>
                            </TableCell>
                          </TableRow>
                        </>
                      )
                    })}
                  </TableBody>
                </Table>
              </div>
            </Col>
          </Row>
        </Container>
        {this.state.addAppointment && (
          <AddTaskModal
            addTaskModal={this.state.addAppointment}
            reloadList={this.closeModal}
            isEdit={this.state.isEdit}
            closeModal={this.closeModal}
          />
        )}
      </div>
    )
  }
}

export default withRouter(WIPList)
