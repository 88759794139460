import EmailBody from './components/email-body/email-body.component'
import EmailFooter from './components/email-footer/email-footer.component'
import EmailHeader from './components/email-header/email-header.component'
import styles from './view-email-page.module.scss'
import { useParams } from 'react-router'
import ViewEmailPageSkeleton from './components/view-email-page-skeleton/view-email-page-skeleton.component'
import { useGetMessageInsideSharedFolder } from '../hooks/get-message-inside-shared-folder.hook'
import { SharedViewEmailPageParams } from './types/view-email-page.type'

const SharedViewEmailPage = () => {
  const { userId, messageId } = useParams<SharedViewEmailPageParams>()
  const {
    data: email,
    isLoading,
    isRefetching,
  } = useGetMessageInsideSharedFolder({ userId, messageId })

  const isEmailLoading = isLoading || isRefetching
  const isEmailFound = email && !isEmailLoading

  return isEmailFound ? (
    <div className={styles.viewEmailWrapper}>
      <EmailHeader email={email} />
      <EmailBody email={email} />
      <EmailFooter email={email} />
    </div>
  ) : (
    <ViewEmailPageSkeleton />
  )
}

export default SharedViewEmailPage
