import React, { Fragment, useEffect, useState } from 'react';
import { link } from './SearchLink';

const styles = {
  block: {
    width: '100%',
    maxHeight: '380px',
    border: '2px solid #dadada',
    borderRadius: '10px',
    overflow: 'auto',

  },
  title: {
    fontSize: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  link: {
    color: '#ec6b1c',
    fontSize: '15px',
    fontWeight: 'bold',
  },

};

const SearchBlock = ({links,handleOptionChange}) => {


  return (
    <div style={styles.block}>
      <div>
        {links.map((el, index) =>
          <Fragment key={el.link}>
            <div className={index === 0 ? 'pt-3 pl-3 font-weight-bold' : 'pl-3 font-weight-bold'} style={styles.title}>
              <div>
                {el.title}
                <a target='_blank' rel={'#'} style={styles.link} href={el.link}> - Click to open </a>
              </div>
              <div className='round_checkbox pr-5'>
                <input
                  type='checkbox'
                  className=''
                  id={el.id}
                  name='link'
                  checked={el.checked}
                  onChange={() => handleOptionChange(el.id)}
                />
                <label className='mb-0' htmlFor={el.id} />
              </div>
            </div>
            <hr />
          </Fragment>,
        )}
      </div>
    </div>
  );
};

export default SearchBlock;
