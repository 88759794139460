import { toast } from 'react-toastify'
import { FC } from 'react'
import DebtorLegalDefendantForm from './debtor-legal-defendant-form.component'
import {
  CreateDebtorLegalDefendantFormProps,
  DefendantFormValues,
} from '../../../types/debtor-legal-defendant.type'
import { useCreateDebtorLegalDefendantMutation } from '../../../hooks/mutations/crud-debtor-legal-defendant.hook'

const CreateDebtorLegalDefendantForm: FC<CreateDebtorLegalDefendantFormProps> = ({
  debtorLegalId,
  onSuccessSubmit,
}) => {
  const { mutateAsync, isLoading } = useCreateDebtorLegalDefendantMutation()
  const handleSuccessSubmit = async (data: DefendantFormValues) => {
    try {
      const createdData = await mutateAsync({
        data: {
          debtorLegalId,
          ...data,
        },
      })
      onSuccessSubmit(createdData)
    } catch (error) {
      toast.error('Failed to create defendant')
      console.error(error)
    }
  }

  return (
    <DebtorLegalDefendantForm
      onSuccessSubmit={handleSuccessSubmit}
      loading={isLoading}
      submitButtonTitle="Create Defendant"
    />
  )
}

export default CreateDebtorLegalDefendantForm
