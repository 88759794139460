import { useState } from 'react'
import { useGetMessagesInsideFolder } from '../hooks/get-messages-inside-folder.hook'
import EmailList from './components/email-list/email-list.component'
import InboxEmailFooter from './components/inbox-email-footer/inbox-email-footer.component'
import InboxEmailHeader from './components/inbox-email-header/inbox-email-header.component'
import styles from './inbox-page.module.scss'
import { BaseGraphApiParams } from '../types/graph-api-mail.type'
import { INITIAL_PAGINATION_GET_MAILS_PARAMS, SHARED_FOLDER_NAME } from './const/inbox.const'
import { useGetMailFolderByDisplayNameOrCreate } from '../hooks/get-mail-folder-by-display-name-or-create.hook'
import { useGetRuleToMoveEmailToBmsFolderOrCreate } from '../hooks/get-rule-to-move-email-to-bms-folder-or-create.hook'

const InboxPage = () => {
  const [params, setParams] = useState(INITIAL_PAGINATION_GET_MAILS_PARAMS)
  
  const {
   mailFolder, 
   isMailFoldersFetching
  } = useGetMailFolderByDisplayNameOrCreate(SHARED_FOLDER_NAME)

  useGetRuleToMoveEmailToBmsFolderOrCreate(mailFolder?.id)

  const {
    data: emails,
    isLoading: isEmailsLoading,
    isRefetching: isEmailRefetching,
  } = useGetMessagesInsideFolder(mailFolder?.id, {
    ...params,
    $select: 'id,subject,from,sentDateTime,isRead,flag',
    $orderby: 'createdDateTime DESC',
  })

  const handleParamsChange = (params: BaseGraphApiParams) => {
    setParams(params)
  }

  const isEmailListLoading = isEmailsLoading || isEmailRefetching || isMailFoldersFetching

  return (
    <div className={styles.inboxPageWrapper}>
      <InboxEmailHeader
        total={emails?.total || 0}
        defaultLimit={INITIAL_PAGINATION_GET_MAILS_PARAMS.$top}
        onParamsChange={handleParamsChange}
      />
      <EmailList emails={emails?.data} isLoading={isEmailListLoading} />
      <InboxEmailFooter />
    </div>
  )
}

export default InboxPage
