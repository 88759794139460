import React from 'react'

export const useModal = (
  defaultVal: boolean,
  handleCloseCallback?: () => void,
): [boolean, () => void, () => void] => {
  const [isOpen, setIsOpen] = React.useState(defaultVal)

  const handleOpen = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    handleCloseCallback && handleCloseCallback()
    setIsOpen(false)
  }

  return [isOpen, handleOpen, handleClose]
}
