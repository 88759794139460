import { EmailTab } from "../components/tabs/EmailTab/emai-tab.component";
import { NoteTab } from "../components/tabs/NoteTab/note-tab.component";
import SmsTab from "../components/tabs/SmsTab/sms-tab.component";
import { INoteForm } from "../types/notes-form.type";
import { NOTE_TAB, NoteTabComponents } from "../types/notes.type";

export const NOTE_TAB_COMPONENTS: NoteTabComponents = {
    [NOTE_TAB.NOTE]: NoteTab,
    [NOTE_TAB.EMAIL]: EmailTab,
    [NOTE_TAB.SMS]: SmsTab,
}

export const NOTE_FORM_DEFAULT_VALUES: INoteForm = {
    notes: '',
    is_important: false,
    selected_task: null
};
