import { FC } from 'react'
import { NOTE_TAB, NotesNavigationProps } from './types/notes.type'
import classNames from 'classnames'

const NotesNavigation: FC<NotesNavigationProps> = ({ onTabClick, activeTab }) => {
  const isNoteTabActive = activeTab === NOTE_TAB.NOTE
  const isEmailTabActive = activeTab === NOTE_TAB.EMAIL
  const isSmsTabActive = activeTab === NOTE_TAB.SMS
  
  return (
    <div className="col-12 p-0 bg-white cursor-pointer">
      <ul className="nav nav-tabs p-0 cursor-pointer" role="tablist">
        <li
          className={classNames('nav-item nav-link', { active: isNoteTabActive })}
          style={{ cursor: 'pointer' }}
          onClick={() => onTabClick(NOTE_TAB.NOTE)}
        >
          Note
        </li>
        <li
          className={classNames('nav-item nav-link', { active: isEmailTabActive })}
          style={{ cursor: 'pointer' }}
          onClick={() => onTabClick(NOTE_TAB.EMAIL)}
        >
          Email
        </li>
        <li
          className={classNames('nav-item nav-link', { active: isSmsTabActive })}
          style={{ cursor: 'pointer' }}
          onClick={() => onTabClick(NOTE_TAB.SMS)}
        >
          Sms
        </li>
      </ul>
    </div>
  )
}

export default NotesNavigation
