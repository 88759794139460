import { FC, useMemo } from 'react'
import { TaskProps } from '../types'
import { useTaskContext } from '@src/context/task-context'
import { useForm } from 'react-hook-form'
import Modal from '@components/modal'
import LabelWrapper from '@components/label-wrapper'
import Select from 'react-select'
import styles from './sub-task-modal.module.scss'
import { toast } from 'react-toastify'
import axios from 'axios'
import { REACT_APP_API_URL } from '@src/lib/global-variables'
import { useQueryClient } from '@tanstack/react-query'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import {
  CreateSubTaskForm,
  CreateSubTaskPayload,
  CreateSubTaskProps,
} from '../../types/sub-task.type'

const CreateSubTaskModal: FC<CreateSubTaskProps> = ({ isOpen, onClose, task }) => {
  const { staff } = useTaskContext()
  const queryClient = useQueryClient()

  const { reset, control, handleSubmit } = useForm<CreateSubTaskForm>({
    defaultValues: { title: '', assign_to: [] },
  })

  const staffOptions = useMemo(
    () =>
      staff?.map((member) => ({
        value: member.id,
        label: member.first_name.trim() + ' ' + member.last_name.trim(),
        access_level: member.access_level,
      })),
    [staff],
  )

  const assignedStaffToTask = useMemo(
    () => staffOptions.filter((staff) => task?.assign_to.includes(staff.value)),
    [task],
  )

  const createSubTask = (data: CreateSubTaskPayload) => {
    return axios.post(`${REACT_APP_API_URL}/sub-tasks`, data)
  }

  const handleClose = () => {
    reset({ title: '', assign_to: [] })
    onClose()
  }

  const onSubmit = async (data: CreateSubTaskForm) => {
    if (!task) return

    const { title, assign_to } = data
    const transformedAssignTo = assign_to.map(({ value }) => value)

    try {
      await createSubTask({ title, assigned_to: transformedAssignTo, task_id: task.id })
      queryClient.invalidateQueries({ queryKey: [GLOBAL_QUERIES.SUB_TASK.GET] })
      toast.success('Sub tasks created')
      handleClose()
    } catch (e) {
      console.log(e)
      toast.error('Something went wrong')
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      withBtns={false}
      style={{ width: 500, minHeight: 300, height: 'fit-content' }}
    >
      <form className={styles.subTaskModal}>
        <LabelWrapper control={control} name="title" label="Title">
          {({ onChange, value }) => (
            <input
              className="react-select"
              type="text"
              value={value as string}
              onChange={onChange}
            />
          )}
        </LabelWrapper>
        <LabelWrapper control={control} name="assign_to" label="Assigned To">
          {({ onChange, value }) => (
            <Select
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={onChange}
              isMulti
              options={assignedStaffToTask}
              value={value}
            />
          )}
        </LabelWrapper>
        <button className={styles.cancelButton} type="button" onClick={handleClose}>
          <span className="lnr lnr-cross"></span>
        </button>
        <button className="btn btn-secondary mt-4" type="button" onClick={handleSubmit(onSubmit)}>
          Create Sub Task
        </button>
      </form>
    </Modal>
  )
}

export default CreateSubTaskModal
