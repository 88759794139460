export const TOTAL_CLAIM_AMOUNT_FORM_DEFAULT_VALUES = {
  claimTotalAmount: '',
  recoverableTotalAmount: '',
  unenforceableTotalAmount: '',
  interestRate: '',
  totalAmount: '',
}

export const WIP_DETAILS_FORM_DEFAULT_VALUES = {
  cover_as_date_log: '',
  cover_type: null,
  date_lodged: null,
  finalize_date: null,
  general_filing: null,
  state_of_origin: null,
}