import { FC, useMemo } from 'react'
import { getFinancialSubNavBarOption } from './utils/get-financial-sub-nav-bar-option.util'
import { Wip } from '../../types/wip.type'
import SubNavBar from '@components/nav-bar/sub-nav-bar.component'
import { Route, useLocation, useRouteMatch, Switch, Redirect } from 'react-router'
import ClaimPage from './Claim/claim-page.component'
import PaymentPage from './Payments/payment-page.component'
import PaymentTrackingPage from './Tracking/payment-tracking-page.component'

interface WipFinancialPageProps {
  wip: Wip
}

const WipFinancialPage: FC<WipFinancialPageProps> = ({ wip }) => {
  const { path } = useRouteMatch()
  const location = useLocation()
  const options = useMemo(() => getFinancialSubNavBarOption(wip.id), [wip.id])
  return (
    <div>
      <SubNavBar options={options} activeUrl={location.pathname} />
      <Switch>
        <Route exact path={`${path}/claim`} render={() => <ClaimPage wip={wip} />} />
        <Route path={`${path}/payments`} render={() => <PaymentPage wip={wip} />} />
        <Route path={`${path}/tracking`} render={() => <PaymentTrackingPage wip={wip} />} />
        <Route path={'*'} render={() => <Redirect to={`/wip/${wip.id}/financial/claim`} />} />
      </Switch>
    </div>
  )
}

export default WipFinancialPage
