export function formatCurrency(num): string {
  const parsedNumber = parseFloat(num);

  if (isNaN(parsedNumber)) {
      return "Invalid number";
  }

  const formattedNum = parsedNumber.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
  });

  return '$' + formattedNum;
}

export const parseStringToNumber = (value: string | number) => {
  if (typeof value === 'number') {
    return value
  }
  const parsedValue = value.replace(/[^0-9.]/g, '')
  return parseFloat(parsedValue)
}
