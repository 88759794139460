import Section from '@components/section/section.component'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import styles from './debtor-address-history-form.module.scss'
import { FC, useState } from 'react'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { useModal } from '@src/hooks/useModal'
import CreateDebtorAddressModal from './components/modals/create-debtor-address-modal/create-debtor-address-modal.component'
import EditDebtorAddressForm from './components/forms/debtor-address-form/edit-debtor-address-form.component'
import { DebtorAddress, DebtorAddressHistoryFormProps } from '../../../types/debtor-address.type'
import AddressHistoryListWithSearch from './components/address-history-list/address-history-list-with-search.component'
import cls from 'classnames'

const DebtorAddressHistoryForm: FC<DebtorAddressHistoryFormProps> = ({ debtorId, direction }) => {
  const [selectedDebtorAddress, setSelectedDebtorAddress] = useState<DebtorAddress | null>(null)
  const [isOpen, handleOpen, handleClose] = useModal(false)
  return (
    <div className={cls(styles.wrapper, { [styles.vertical]: direction === 'vertical' })}>
      <Section
        className={cls(styles.assignPropertySection, {
          [styles.vertical]: direction === 'vertical',
        })}
      >
        <Header>Assign Property Search</Header>
        <AddressHistoryListWithSearch
          debtorId={debtorId}
          onSelectedAddressChange={setSelectedDebtorAddress}
        />
        <SubmitButton onClick={handleOpen}>Add Address</SubmitButton>
      </Section>
      <Section>
        {selectedDebtorAddress ? (
          <EditDebtorAddressForm debtorAddress={selectedDebtorAddress} />
        ) : (
          <Header>Address Not Selected</Header>
        )}
      </Section>
      <CreateDebtorAddressModal isOpen={isOpen} onClose={handleClose} debtorId={debtorId} />
    </div>
  )
}

export default DebtorAddressHistoryForm
