import Section from '@components/section/section.component'
import SubSection from '@containers/Recovery/Wip/components/UI/sub-section/sub-section.component'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import styles from './landlord-contacts-section.module.scss'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import LandlordContactList from '../../lists/landlord-contact-list/landlord-contact-list.component'
import { LandlordContact, Property } from '@containers/Sales/Properties/types/property.type'
import { FC } from 'react'
import { useModal } from '@src/hooks/useModal'
import CreateLandlordContactModal from '../../modals/landlord-contact-modal/create-landlord-contact-modal.component'
import { useQueryClient } from '@tanstack/react-query'
import { getPropertyByIdKey } from '../../../utils/client-property.utils'
import { toast } from 'react-toastify'
import { GET_CLIENT_PROPERTIES_PARAMS } from '@containers/Sales/Client/const/client.const'

interface LandlordContactsSectionProps {
  landlordId: number
  propertyId: number
  contacts: LandlordContact[]
}

const LandlordContactsSection: FC<LandlordContactsSectionProps> = ({
  landlordId,
  propertyId,
  contacts,
}) => {
  const queryClient = useQueryClient()
  const [isModalOpen, handleModalOpen, handleModalClose] = useModal(false)

  const handleLandlordContactCreate = (landlordContact: LandlordContact) => {
    queryClient.setQueryData<Property>(
      getPropertyByIdKey(propertyId, GET_CLIENT_PROPERTIES_PARAMS),
      (prevData) =>
        prevData
          ? {
              ...prevData,
              landlord: prevData.landlord
                ? {
                    ...prevData.landlord,
                    contacts: [...prevData.landlord.contacts, landlordContact],
                  }
                : null,
            }
          : undefined,
    )
    toast.success('Owner added successfully')
  }

  return (
    <Section>
      <SubSection className={styles.sectionHeaderWrapper} p={4} mb={4}>
        <Header>Owners / Landlords</Header>
        <SubmitButton onClick={handleModalOpen}>Add Owner</SubmitButton>
      </SubSection>
      <LandlordContactList contacts={contacts} propertyId={propertyId} />
      <CreateLandlordContactModal
        landlordId={landlordId}
        onSuccessSubmit={handleLandlordContactCreate}
        isOpen={isModalOpen}
        onClose={handleModalClose}
      />
    </Section>
  )
}

export default LandlordContactsSection
