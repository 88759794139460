import Modal from '@components/modal'
import { FC } from 'react'
import AddDebtorLegalForm from '../../forms/add-debtor-legal-form/add-debtor-legal-form.component'
import styles from './add-debtor-legal-modal.module.scss'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import { AddDebtorLegalModalProps, DebtorLegal } from '../../../types/debtor-legal.type'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import { getWipLegalListQueryKey } from '../../../utils/legal-query-key-factory.utils'
import ModalHeader from '@components/modal-header.component'

const AddDebtorLegalModal: FC<AddDebtorLegalModalProps> = ({
  debtorsOptions,
  wipId,
  ...modalProps
}) => {
  const queryClient = useQueryClient()
  const handleSubmit = (debtorLegal: DebtorLegal) => {
    queryClient.setQueriesData<DebtorLegal[]>(getWipLegalListQueryKey(wipId), (prevData) =>
      prevData ? [...prevData, debtorLegal] : [debtorLegal],
    )
    toast.success('Debtor added successfully')
    modalProps.onClose()
  }
  return (
    <Modal className={styles.modalWrapper} {...modalProps} withBtns={false}>
      <ModalHeader onCloseClick={modalProps.onClose} />
      <Header mb={4}>Add Debtor</Header>
      <AddDebtorLegalForm
        debtorsOptions={debtorsOptions}
        wipId={wipId}
        onSuccessfulSubmit={handleSubmit}
      />
    </Modal>
  )
}

export default AddDebtorLegalModal
