import { FC, useEffect, useState } from 'react'
import DateRangePicker from '../date-range-picker/date-range-picker.component'
import styles from './global-kanban-board-header.module.scss'
import Switch from '@components/switch'
import { GlobalKanbanBoardHeaderProps } from '../../types/global-kanban-board.type'
import { useUserContext } from '@src/context/user-context'

export const GlobalKanbanBoardHeader: FC<GlobalKanbanBoardHeaderProps> = ({ onChange }) => {
  const [currentDate, setCurrentDate] = useState(new Date())
  const [isWeekMode, setIsWeekMode] = useState(false)
  const { user } = useUserContext()
  const [isAssignedToMeShown, setIsAssignedToMeShown] = useState(false)
  const [dateRange, setDateRange] = useState<[Date, Date] | null>(null)

  const handleWeekModeChange = () => {
    setIsWeekMode((prevState) => !prevState)
  }

  const handleAssignedToMeChange = () => {
    setIsAssignedToMeShown((prevState) => !prevState)
  }

  const handleDateRangeChange = (date: [Date, Date]) => {
    setDateRange(date)
  }

  const handleWeekDayClick = (date: Date) => {
    setCurrentDate(date)
    setIsWeekMode(false)
  }

  useEffect(() => {
    setCurrentDate(new Date())
  }, [isWeekMode])

  useEffect(() => {
    if (!dateRange) {
      return
    }

    if (onChange) {
      onChange({
        dateRange,
        mode: isWeekMode ? 'week' : 'day',
        assignedToMe: isAssignedToMeShown && user ? user.id : null,
      })
    }
  }, [dateRange, isWeekMode, isAssignedToMeShown])

  return (
    <div className={styles.wrapper}>
      <DateRangePicker
        mode={isWeekMode ? 'week' : 'day'}
        onChange={handleDateRangeChange}
        onWeekDayClick={handleWeekDayClick}
        initialDate={currentDate}
      />
      <div className="d-flex align-items-center">
        <div className="d-flex mr-3">
          <Switch value={isAssignedToMeShown} onChange={handleAssignedToMeChange} />
          <span className="ml-2">Show Assigned To Me</span>
        </div>
        <div className="d-flex mr-3">
          <Switch value={isWeekMode} onChange={handleWeekModeChange} />
          <span className="ml-2">Weekly View</span>
        </div>
        <button className="btn btn-secondary m-0">Filter</button>
      </div>
    </div>
  )
}
