import { Params } from "@src/types/service.type";
import { DebtorAssociateDetailsTab, EditDebtorFormTab } from "../types/debtor.type";
import { DebtorDetailsFormTab, PhoneOption } from "../types/debtor-form.type";

export const DEBTOR_FORM_NAVIGATION_TABS = [
  { label: 'Details ', url: EditDebtorFormTab.Details },
  { label: 'Address History ', url: EditDebtorFormTab.AddressHistory },
  { label: 'Associate Details ', url: EditDebtorFormTab.AssociateDetails },
]

export const GET_DEBTOR_BY_ID_PARAMS: Params = {
  join: ['contact', 'relations', 'references', 'other', 'work_places']
}

export const DEBTOR_DETAILS_FORM_NAVIGATION_TABS = [
  { label: 'Names', value: DebtorDetailsFormTab.Names },
  { label: 'Emails', value: DebtorDetailsFormTab.Emails },
  { label: 'Phone', value: DebtorDetailsFormTab.Phone },
  { label: 'Other', value: DebtorDetailsFormTab.Other },
]

//Extension phone, fax phone, office phone, direct phone

export const LANDLINE_PHONE_OPTIONS: PhoneOption[] = [
  { value: 'comms_ph_extension', label: 'Extension Phone' },
  { value: 'comms_ph_fax', label: 'Fax Phone' },
  { value: 'comms_ph_direct', label: 'Direct Phone' },
  { value: 'comms_ph_office', label: 'Office Phone' },
]

export const REQUIRED_PHONE_OPTIONS: PhoneOption[] = [
  { value: 'comms_ph_mobile', label: 'Mobile Phone' },
  { value: 'comms_ph_home', label: 'Home Phone' },
  { value: 'comms_ph_work', label: 'Work Phone' },
]

export const PHONE_SELECT_OPTIONS: PhoneOption[] = [
  { value: 'comms_ph_mobile_secondary', label: 'Mobile Secondary Phone' },
  ...LANDLINE_PHONE_OPTIONS
]

export const PHONE_OPTIONS: PhoneOption[] = [...REQUIRED_PHONE_OPTIONS, ...PHONE_SELECT_OPTIONS]


export const DEBTOR_ASSOCIATE_DETAILS_FORM_NAVIGATION_TABS = [
  { label: 'Relation', value: DebtorAssociateDetailsTab.Relation },
  { label: 'References', value: DebtorAssociateDetailsTab.References },
  { label: 'Work Place', value: DebtorAssociateDetailsTab.WorkPlace },
  { label: 'Other', value: DebtorAssociateDetailsTab.Other },
]