import { FC } from 'react'
import RecoverableCostListItem from './recoverable-cost-list-item.component'
import { RecoverableCostListProps } from '../../types/claim.type'
import { useGetRecoverableCostList } from '../../hooks/get-recoverable-cost-list.hook'
import styles from './recoverable-cost-list.module.scss'

const RecoverableCostList: FC<RecoverableCostListProps> = ({ wipId }) => {
  const { data } = useGetRecoverableCostList({
    s: JSON.stringify({ wip_id: wipId }),
    sort: 'created_at,DESC',
  })

  const recoverableCosts = data?.data || []

  return (
    <div>
      <div className={styles.recoverableCostListWrapper}>
        {recoverableCosts.map((recoverableCost, i) => (
          <RecoverableCostListItem
            recoverableCost={recoverableCost}
            key={`recoverable-cost-item-${recoverableCost.id}-${i}`}
          />
        ))}
      </div>
    </div>
  )
}

export default RecoverableCostList
