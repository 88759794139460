import { useForm } from 'react-hook-form'
import styles from './landlord-details-form.module.scss'
import { FC, useEffect } from 'react'
import { BaseFormProps } from '@src/types/component-types'
import FormInput from '@components/inputs/form-input/form-input.component'
import FormSelect from '@components/inputs/form-select/form-select.component'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'

export interface LandlordDetailsFormValues {
  companyName: string
  abn: string
  ownershipType: string
}

const landlordDetailsDefaultValues: LandlordDetailsFormValues = {
  companyName: '',
  abn: '',
  ownershipType: '',
}

const LandlordDetailsForm: FC<BaseFormProps<LandlordDetailsFormValues>> = ({
  initialValues,
  loading = false,
  submitButtonTitle = 'Save',
  onSuccessSubmit,
}) => {
  const { reset, control, register, handleSubmit } = useForm<LandlordDetailsFormValues>({
    defaultValues: {
      ...landlordDetailsDefaultValues,
      ...initialValues,
    },
  })

  useEffect(() => {
    if (!initialValues) {
      return
    }

    reset({
      ...landlordDetailsDefaultValues,
      ...initialValues,
    })
  }, [initialValues])

  return (
    <form className={styles.formWrapper} onSubmit={handleSubmit(onSuccessSubmit)}>
      <div className={styles.row}>
        <FormInput label="Company Name" inputStyle="outlined" {...register('companyName')} />
        <FormInput label="ABN" inputStyle="outlined" {...register('abn')} />
        <FormSelect
          label="Ownership Type"
          style="outlined"
          control={control}
          name="ownershipType"
        />
      </div>
      <div className={styles.submitButtonWrapper}>
        <SubmitButton loading={loading} buttonType="submit">
          {submitButtonTitle}
        </SubmitButton>
      </div>
    </form>
  )
}

export default LandlordDetailsForm
