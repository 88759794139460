import { FC } from 'react'
import { DebtorLegal, DebtorLegalJudgmentDate } from '../../../types/debtor-legal.type'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import { getDebtorLegalByIdQueryKey } from '../../../utils/legal-query-key-factory.utils'
import Modal from '@components/modal'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import styles from './debtor-judgment-date-modal.module.scss'
import { CreateDebtorLegalJudgmentDateModalProps } from '../../../types/debtor-legal-judgment-date.type'
import CreateDebtorLegalJudgmentDateForm from '../../forms/debtor-legal-judgment-date-form/create-debtor-legal-judgment-date-form.component'
import ModalHeader from '@components/modal-header.component'

const CreateDebtorLegalJudgmentDateModal: FC<CreateDebtorLegalJudgmentDateModalProps> = ({
  debtorLegalId,
  ...modalProps
}) => {
  const queryClient = useQueryClient()
  const handleSuccessfulSubmit = (debtorLegalJudgmentDate: DebtorLegalJudgmentDate) => {
    queryClient.setQueryData<DebtorLegal>(
      getDebtorLegalByIdQueryKey(debtorLegalJudgmentDate.debtorLegalId),
      (prevData) =>
        prevData
          ? {
              ...prevData,
              judgmentDates: [...prevData.judgmentDates, debtorLegalJudgmentDate],
            }
          : undefined,
    )
    toast.success('Judgment date created successfully')
    modalProps.onClose()
  }

  return (
    <Modal className={styles.modalWrapper} {...modalProps} withBtns={false}>
      <ModalHeader onCloseClick={modalProps.onClose} />
      <Header mb={4}>Create Judgment Date</Header>
      <CreateDebtorLegalJudgmentDateForm
        onSuccessSubmit={handleSuccessfulSubmit}
        debtorLegalId={debtorLegalId}
      />
    </Modal>
  )
}

export default CreateDebtorLegalJudgmentDateModal
