import React from 'react';
import RecoveryDetailsCount from './recoveryDetailsCount';
export const radioStyle = {
  // background: 'cyan',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '5px 10px',
  borderRadius: '4px',
  gap: '7px',
  border: '2px solid #7badd3',
  background: '#ecf4ff',
  cursor: 'pointer',
};

const RecoveryDetails = ({ register, watch }) => {
  const vocated = watch('recovery_details.is_tenant_vacated');
  const isEvicted = watch('recovery_details.is_tenant_evicted');
  const didBreak = watch('recovery_details.is_break_fixed_term');
  const tribunalMagistrates = watch(
    'recovery_details.has_tribunal_or_magistrates_order',
  );

  return (
    <>
      <h3>Enter Recovery Details</h3>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '15px',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <div style={radioStyle}>
              <input
                type="radio"
                name="is-vacated"
                value={true}
                {...register('recovery_details.is_tenant_vacated')}
              />
              Yes
            </div>
            <div style={radioStyle}>
              <input
                type="radio"
                name="is-vacated"
                value={false}
                {...register('recovery_details.is_tenant_vacated')}
              />
              No
            </div>
            <p>Has the Tenant Vacated The Property?</p>
          </div>
          {vocated === 'true' && (
            <label>
              <span className="form__form-group-label">Date Vacated:</span>
              <input
                type="date"
                {...register(`recovery_details.date_vacated`)}
              />
            </label>
          )}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <div style={radioStyle}>
              <input
                type="radio"
                name="is-evicted"
                value={true}
                {...register('recovery_details.is_tenant_evicted')}
              />
              Yes
            </div>
            <div style={radioStyle}>
              <input
                type="radio"
                name="is-evicted"
                value={false}
                {...register('recovery_details.is_tenant_evicted')}
              />
              No
            </div>
            <p>Was the Tenant Evicted?</p>
          </div>
          {isEvicted === 'true' && (
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <span className="form__form-group-label">Evicted by</span>
              <div style={radioStyle}>
                <input
                  type="radio"
                  name="mis-other"
                  value="Barclay MIS"
                  {...register('recovery_details.evicted_by')}
                />
                Barclay MIS
              </div>
              <div style={radioStyle}>
                <input
                  type="radio"
                  name="mis-other"
                  value="Other"
                  {...register('recovery_details.evicted_by')}
                />
                Other
              </div>
            </div>
          )}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <div style={radioStyle}>
              <input
                type="radio"
                name="did-break"
                value={true}
                {...register('recovery_details.is_break_fixed_term')}
              />
              Yes
            </div>
            <div style={radioStyle}>
              <input
                type="radio"
                name="did-break"
                value={false}
                {...register('recovery_details.is_break_fixed_term')}
              />
              No
            </div>
            <p>Did the Tenant Break a Fixed Term?</p>
          </div>
          {didBreak === 'true' && (
            <label>
              <span className="form__form-group-label">Date Re-Leased:</span>
              <input
                type="date"
                {...register(`recovery_details.date_re_leased`)}
              />
            </label>
          )}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <div style={{ display: 'flex', gap: '10px' }}>
            <div style={radioStyle}>
              <input
                type="radio"
                name="tribunal"
                value={true}
                {...register(
                  'recovery_details.has_tribunal_or_magistrates_order',
                )}
              />
              Yes
            </div>
            <div style={radioStyle}>
              <input
                type="radio"
                name="tribunal"
                value={false}
                {...register(
                  'recovery_details.has_tribunal_or_magistrates_order',
                )}
              />
              No
            </div>
            <p>
              Has a Tribunal/Magistrates Court Order been obtained or applied
              for?
            </p>
          </div>
          {tribunalMagistrates === 'true' && (
            <>
              <label>
                <span className="form__form-group-label">Date Of Hearing:</span>
                <input
                  type="date"
                  {...register(
                    `recovery_details.tribunal_or_magistrates.hearing_date`,
                  )}
                />
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '5px',
                    padding: '0 7px',
                    background: '#ecf4ff',
                    border: '1px #7badd3 solid',
                    borderRadius: '5px',
                  }}
                >
                  <input
                    type="checkbox"
                    {...register(
                      'recovery_details.tribunal_or_magistrates.is_pending',
                    )}
                  />
                  Unknown/Pending
                </div>
              </label>
              <label>
                <span className="form__form-group-label">Plaintiff Name</span>
                <input
                  type="text"
                  placeholder="Plaintiff Name"
                  {...register(
                    `recovery_details.tribunal_or_magistrates.plaintiff_name`,
                  )}
                />
              </label>
              <label>
                <span className="form__form-group-label">
                  Plaintiff Address
                </span>
                <textarea
                  type="text"
                  placeholder="AliaPlaintiff Address"
                  {...register(
                    `recovery_details.tribunal_or_magistrates.plaintiff_address`,
                  )}
                />
              </label>
              <label>
                <span className="form__form-group-label">Defendant Name</span>
                <input
                  type="text"
                  placeholder="Defendant Name"
                  {...register(
                    `recovery_details.tribunal_or_magistrates.defendant_name`,
                  )}
                />
              </label>
              <label>
                <span className="form__form-group-label">
                  Defendant Address
                </span>
                <textarea
                  type="text"
                  placeholder="Defendant Address"
                  {...register(
                    `recovery_details.tribunal_or_magistrates.defendant_address`,
                  )}
                />
              </label>
              <label>
                <span className="form__form-group-label">
                  Tribunal Location
                </span>
                <input
                  type="text"
                  placeholder="Tribunal Location"
                  {...register(
                    `recovery_details.tribunal_or_magistrates.tribunal_location`,
                  )}
                />
              </label>
              <label>
                <span className="form__form-group-label">
                  Tribunal Claim No.
                </span>
                <input
                  type="text"
                  placeholder="Tribunal Claim No."
                  {...register(
                    `recovery_details.tribunal_or_magistrates.tribunal_claim_no`,
                  )}
                />
              </label>
              <label>
                <span className="form__form-group-label">
                  Claim/Plaintiff Number
                </span>
                <input
                  type="text"
                  placeholder="Claim/Plaintiff Number"
                  {...register(
                    `recovery_details.tribunal_or_magistrates.claim_plaintiff_number`,
                  )}
                />
              </label>
              <label>
                <span className="form__form-group-label">Court Location</span>
                <input
                  type="text"
                  placeholder="Court Location"
                  {...register(
                    `recovery_details.tribunal_or_magistrates.court_location`,
                  )}
                />
              </label>
            </>
          )}
        </div>
      </div>
      <RecoveryDetailsCount register={register} watch={watch} />
    </>
  );
};

export default RecoveryDetails;
