import { FC } from 'react'
import styles from './edit-client-property-page.module.scss'
import Section from '@components/section/section.component'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import EditPropertyAddressForm from '../../components/form/property-address-form/edit-property-address-from.component'
import EditPropertyCoverForm from '../../components/form/property-cover-form/edit-property-cover-form.component'
import { Property } from '@containers/Sales/Properties/types/property.type'
import { useQueryClient } from '@tanstack/react-query'
import { getPropertyByIdKey } from '../../utils/client-property.utils'
import { toast } from 'react-toastify'
import { GET_CLIENT_PROPERTIES_PARAMS } from '@containers/Sales/Client/const/client.const'

interface EditClientPropertyDetailsProps {
  property: Property
}

const EditClientPropertyDetails: FC<EditClientPropertyDetailsProps> = ({ property }) => {
  const queryClient = useQueryClient()

  const handleSuccessSubmit = (data: Property) => {
    queryClient.setQueryData<Property>(getPropertyByIdKey(property.id, GET_CLIENT_PROPERTIES_PARAMS), (prevData) =>
      prevData ? { ...prevData, ...data } : undefined,
    )
    toast.success('Property updated')
  }

  return (
    <div className={styles.sectionsWrapper}>
      <Section className={styles.fourthPart}>
        <Header mb={4}>Period of Cover</Header>
        <EditPropertyCoverForm entity={property} onSuccessSubmit={handleSuccessSubmit} />
      </Section>
      <Section>
        <Header mb={4}>Property Address</Header>
        <EditPropertyAddressForm entity={property} onSuccessSubmit={handleSuccessSubmit} />
      </Section>
    </div>
  )
}

export default EditClientPropertyDetails
