import { FC, useEffect } from 'react'
import { useGetWipTemplate } from '../../hooks/get-wip-template.hook'
import { CreateWipTemplateForm, EditWipTemplateModalProps } from '../../types/wip-templates.type'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { createWipTemplateSchema } from '../../schemas/create-wip-template.schema'
import Modal from '@components/modal'
import { toast } from 'react-toastify'
import LabelWrapper from '@components/label-wrapper'
import styles from './wip-template-modal.module.scss'
import { ThreeDots } from 'react-loader-spinner'
import { WipTemplatesService } from '../../services/wip-templates.service'
import { useQueryClient } from '@tanstack/react-query'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'

const wipTemplatesService = new WipTemplatesService()

export const EditWipTemplateModal: FC<EditWipTemplateModalProps> = ({
  isOpen,
  onClose,
  wipTemplateId,
}) => {
  const queryClient = useQueryClient()
  const { data, isLoading, isRefetching, isError } = useGetWipTemplate(wipTemplateId, isOpen)
  const { reset, control, handleSubmit } = useForm<CreateWipTemplateForm>({
    defaultValues: { name: '' },
    resolver: joiResolver(createWipTemplateSchema),
  })

  useEffect(() => {
    if (isError) {
      toast.error('Something Went Wrong')
      onClose()
    }
  }, [isError])

  useEffect(() => {
    if (data) {
      reset({ name: data.name })
    }
  }, [data])

  const onSubmit = async (formData: CreateWipTemplateForm) => {
    if (!data) {
      return
    }

    try {
      await wipTemplatesService.updateWipTemplate(formData, data.id)
      toast.success('Wip Template Updated')
      queryClient.refetchQueries([GLOBAL_QUERIES.WIP_TEMPLATE.GET])
      onClose()
    } catch {
      toast.error('Something Went Wrong')
    }
  }

  const isDataLoading = isLoading || isRefetching
  const isDataFound = data && !isDataLoading

  return (
    <Modal
      className={styles.modalWrapper}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
    >
      {isDataLoading && (
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#38bdf8"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      )}
      {isDataFound && (
        <>
          <h3>Edit Task Template</h3>
          <LabelWrapper className={styles.labelWrapper} control={control} name="name" label="Name">
            {({ onChange, value }) => {
              return (
                <input
                  type="text"
                  className="react-select"
                  placeholder="Example: Base Wip Template"
                  value={value as string}
                  onChange={onChange}
                />
              )
            }}
          </LabelWrapper>
        </>
      )}
    </Modal>
  )
}
