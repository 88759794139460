import { Params } from "@src/types/service.type";
import { CourtInterestRateService } from "../services/court-interest-rate.service";
import { useQuery } from "@tanstack/react-query";
import { GLOBAL_QUERIES } from "@src/lib/global-queries";

export const useGetCourtInterestRates = (params: Params = {}) => {

  const getCourtInterestRates = async (params?: Params) => {
    const courtInterestRateService = new CourtInterestRateService()
    return courtInterestRateService.getInterestRates(params)
  }

  const query = useQuery({
    queryKey: [GLOBAL_QUERIES.COURT_INTEREST_RATE.GET, params],
    queryFn: () => getCourtInterestRates(params),
    staleTime: 1000 * 60 * 60 * 24 // 24 hours
  })

  return query
}