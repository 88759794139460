import * as React from 'react'
const PhoneIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
    <path
      fill="#1F2A37"
      d="M14.926 21a7.294 7.294 0 0 1-1.573-.178 13.35 13.35 0 0 1-6.282-3.898 13.337 13.337 0 0 1-3.902-6.28 6.932 6.932 0 0 1 2.152-6.698 2.901 2.901 0 0 1 2.233-.945 3.027 3.027 0 0 1 1.986.945l1.4 1.4a2.984 2.984 0 0 1 0 4.216l-.7.7a.983.983 0 0 0 0 1.389l2.101 2.1a.987.987 0 0 0 1.391 0l.7-.7a2.988 2.988 0 0 1 4.22 0l1.401 1.4c.553.518.89 1.226.946 1.983a2.897 2.897 0 0 1-.946 2.232A7.146 7.146 0 0 1 14.926 21ZM7.422 5.003a1.02 1.02 0 0 0-.69.362 4.989 4.989 0 0 0-1.606 4.851 11.456 11.456 0 0 0 3.363 5.299 11.464 11.464 0 0 0 5.302 3.36 5.006 5.006 0 0 0 4.857-1.608.993.993 0 0 0 .362-.717 1.093 1.093 0 0 0-.362-.672l-1.4-1.4a.987.987 0 0 0-1.39 0l-.701.7a2.99 2.99 0 0 1-4.22 0l-2.101-2.1a2.987 2.987 0 0 1 0-4.217l.7-.7a.983.983 0 0 0 0-1.387l-1.4-1.4a1.1 1.1 0 0 0-.673-.362l-.04-.009Z"
    />
  </svg>
)
export default PhoneIcon
