import { FC } from 'react'
import { DebtorLegal, DebtorLegalClaimNumber } from '../../../types/debtor-legal.type'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import { getDebtorLegalByIdQueryKey } from '../../../utils/legal-query-key-factory.utils'
import Modal from '@components/modal'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import styles from './debtor-claim-number-modal.module.scss'
import { CreateDebtorLegalClaimNumberModalProps } from '../../../types/debtor-claim-number.type'
import CreateDebtorLegalClaimNumberForm from '../../forms/debtor-claim-number-form/create-debtor-claim-number-form.component'
import ModalHeader from '@components/modal-header.component'

const CreateDebtorLegalClaimNumberModal: FC<CreateDebtorLegalClaimNumberModalProps> = ({
  debtorLegalId,
  ...modalProps
}) => {
  const queryClient = useQueryClient()
  const handleSuccessfulSubmit = (debtorLegalClaimNumber: DebtorLegalClaimNumber) => {
    queryClient.setQueryData<DebtorLegal>(
      getDebtorLegalByIdQueryKey(debtorLegalClaimNumber.debtorLegalId),
      (prevData) =>
        prevData
          ? {
              ...prevData,
              claimNumbers: [...prevData.claimNumbers, debtorLegalClaimNumber],
            }
          : undefined,
    )
    toast.success('Claim number created successfully')
    modalProps.onClose()
  }

  return (
    <Modal className={styles.modalWrapper} {...modalProps} withBtns={false}>
      <ModalHeader onCloseClick={modalProps.onClose} />
      <Header mb={4}>Create Claim Number</Header>
      <CreateDebtorLegalClaimNumberForm
        onSuccessSubmit={handleSuccessfulSubmit}
        debtorLegalId={debtorLegalId}
      />
    </Modal>
  )
}

export default CreateDebtorLegalClaimNumberModal
