import GenericTable from '@components/generic-table/generic-table'
import { REACT_APP_API_URL } from '@src/lib/global-variables'
import axios from 'axios'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import { useTaskContext } from '@src/context/task-context'
import { Sms } from '@containers/Setting/components/Contacts/taskboard/types'
import SmsItem from './sms-item.component'
import { useModal } from '@src/hooks/useModal'
import { useState } from 'react'
import SmsModal from '@containers/Communication/components/modals/sms-modal/sms-modal.component'

const SmsTable = () => {
  const { selectedCompanyState, currentWip, isRecovery } = useTaskContext()
  const [selectedSmsId, setSelectedSmsId] = useState<number | null>(null)
  const [isSmsModalOpen, openSmsModal, closeSmsModal] = useModal(false)
  const fetchSms = async (pagination) => {
    const params = {
      join: ['staff_created', isRecovery ? 'wips' : 'companies'],
      s: JSON.stringify(
        isRecovery ? { 'wips.id': currentWip } : { 'companies.id': selectedCompanyState?.id },
      ),
      sort: 'time_created,DESC',
      ...pagination,
    }
    const response = await axios.get(`${REACT_APP_API_URL}/short-messages`, { params })

    return response.data as { total: number; data: Sms[] }
  }

  const handleOpenSmsModal = (id: number) => {
    setSelectedSmsId(id)
    openSmsModal()
  }

  return (
    <>
      <GenericTable
        queryKey={[GLOBAL_QUERIES.SMS.GET]}
        queryFn={fetchSms}
        limit={5}
        headerOptions={['Date', 'To', 'Body', 'Sender', 'Options']}
      >
        {({ data }) =>
          data.map((sms, i) => (
            <SmsItem openViewModal={handleOpenSmsModal} key={`${sms.id} ${i}`} sms={sms} />
          ))
        }
      </GenericTable>
      <SmsModal isOpen={isSmsModalOpen} id={selectedSmsId} onClose={closeSmsModal} />
    </>
  )
}

export default SmsTable
