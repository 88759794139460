import { FC } from 'react'
import { EmailHistoryItemProps } from '../../../../../../../Communication/types/email.type'
import { Button, ButtonGroup, TableRow } from '@material-ui/core'
import moment from 'moment'
import CustomTableCell from '@components/generic-table/custom-table-cell'

const EmailHistoryItem: FC<EmailHistoryItemProps> = ({ email, openViewModal }) => {
  const { sent_at, to, subject, from } = email
  return (
    <TableRow style={{ height: 70 }}>
      <CustomTableCell>{moment(sent_at).format('DD-MM-YYYY')}</CustomTableCell>
      <CustomTableCell>{moment(sent_at).format('HH:MM A')}</CustomTableCell>
      <CustomTableCell position="eclipsed">{to.emailAddress.address}</CustomTableCell>
      <CustomTableCell position="eclipsed">{subject}</CustomTableCell>
      <CustomTableCell position="eclipsed">{from.emailAddress.address}</CustomTableCell>

      <CustomTableCell>
        <ButtonGroup className="btn-group--icons" dir="ltr">
          <Button style={{ padding: '10px' }} onClick={() => openViewModal(email)}>
            <span className="lnr lnr-eye" />
          </Button>
        </ButtonGroup>
      </CustomTableCell>
    </TableRow>
  )
}

export default EmailHistoryItem
