import { Client } from '@containers/Sales/Client/types/client.type'
import { FC, useState } from 'react'
import SpeedDial from './speed-dial.component'
import { SPEED_DIAL_OPTIONS } from '@containers/Communication/const/speed-dial.const'
import {
  SpeedDialOption,
  SpeedDialSelectOption,
} from '@containers/Communication/types/speed-dial.type'
import ClientSendEmailModal from '@containers/Sales/Client/_modules/Communication/modals/client-send-email-modal/client-send-email-modal.component'
import ClientSendSmsModal from '@containers/Sales/Client/_modules/Communication/modals/client-send-sms-modal/client-send-sms-modal.component'

interface ClientSpeedDialProps {
  client: Client
}

const ClientSpeedDial: FC<ClientSpeedDialProps> = ({ client }) => {
  const [openModal, setOpenModal] = useState<SpeedDialSelectOption | null>(null)

  const handleSelect = (option: SpeedDialOption) => {
    setOpenModal(option.value as SpeedDialSelectOption)
  }

  const closeModal = () => setOpenModal(null)

  return (
    <>
      <SpeedDial options={SPEED_DIAL_OPTIONS} onSelect={handleSelect} />
      <ClientSendEmailModal
        client={client}
        onClose={closeModal}
        isOpen={openModal === SpeedDialSelectOption.SendEmail}
      />
      <ClientSendSmsModal
        client={client}
        onClose={closeModal}
        isOpen={openModal === SpeedDialSelectOption.SendSms}
      />
    </>
  )
}

export default ClientSpeedDial
