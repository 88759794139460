import { Params } from "@src/types/service.type";
import { DebtorContact } from "../types/debtor.type";
import { REACT_APP_API_URL } from "@src/lib/global-variables";
import axios from "axios";
import { DeepPartial } from "react-hook-form";

export class DebtorContactService {

  async updateDebtorContact(id: number, debtorContact: DeepPartial<DebtorContact>, params: Params = {}) {
    const res = await axios.patch<DebtorContact>(`${REACT_APP_API_URL}/debtor-contacts/${id}`, debtorContact, { params });
    return res.data;
  }
}