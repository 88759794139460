import AddIcon from '@src/icons/add'
import WipCostForm from './wip-cost-form.component'
import styles from './wip-cost-form.module.scss'
import {
  CreateWipCostFormProps,
  WipCostFormValues,
} from '@containers/Recovery/WipCost/types/wip-cost.type'
import { WipCostListItemService } from '@containers/Recovery/WipCost/services/wip-cost-list-item.service'
import { FC } from 'react'
import { parseStringToNumber } from '@containers/Recovery/Wip/utils/format-currency'
import { toast } from 'react-toastify'

const CreateWipCostForm: FC<CreateWipCostFormProps> = ({ wipCostListId, onSuccessSubmit }) => {
  const handleSubmit = async (data: WipCostFormValues) => {
    const wipCostListItemService = new WipCostListItemService()
    try {
      console.log('submit', data)
      const createdItem = await wipCostListItemService.createWipCostListItem({
        ...data,
        unenforceableCost: Math.round(parseStringToNumber(data.unenforceableCost)),
        recoverableCost: Math.round(parseStringToNumber(data.recoverableCost)),
        wipCostListId,
      })
      onSuccessSubmit(createdItem)
    } catch (e) {
      toast.error('Failed to create WIP cost item')
      console.error(e)
    }
  }

  return (
    <WipCostForm onSubmit={handleSubmit}>
      <button className={styles.actionButton} type="submit">
        <AddIcon />
      </button>
    </WipCostForm>
  )
}

export default CreateWipCostForm
