import { toast } from 'react-toastify'
import { FC } from 'react'
import { useCreateDebtorJudgmentDateMutation } from '../../../hooks/mutations/crud-debtor-legal-judgment-date.hook'
import {
  CreateDebtorLegalJudgmentDateFormProps,
  DebtorJudgmentDateFormValues,
} from '../../../types/debtor-legal-judgment-date.type'
import DebtorLegalJudgmentDateForm from './debtor-legal-judgment-date-form.component'

const CreateDebtorLegalJudgmentDateForm: FC<CreateDebtorLegalJudgmentDateFormProps> = ({
  debtorLegalId,
  onSuccessSubmit,
}) => {
  const { mutateAsync, isLoading } = useCreateDebtorJudgmentDateMutation()
  const handleSuccessSubmit = async (data: DebtorJudgmentDateFormValues) => {
    try {
      const createdData = await mutateAsync({
        data: {
          debtorLegalId,
          ...data,
        },
      })
      onSuccessSubmit(createdData)
    } catch (error) {
      toast.error('Failed to create judgment date')
      console.error(error)
    }
  }

  return (
    <DebtorLegalJudgmentDateForm
      onSuccessSubmit={handleSuccessSubmit}
      loading={isLoading}
      submitButtonTitle="Create Judgment Date"
    />
  )
}

export default CreateDebtorLegalJudgmentDateForm
