import React from 'react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { postCommercialUncoveredFormSelector } from '../../../../redux/selector/selector';
import { postCommercialUncoveredForm } from '../../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';

import { commercialUncoveredFormInitialValues } from '../../../../shared/utils/CommercialUncoveredFormInitialValues';
import { commercialUncoveredFormValidationSchema } from '../../../../shared/validators/commercialUncoveredFormValidator';

import { Grid } from '@mui/material';
import CommercialUncoveredForm from '../CommercialUncoveredForm';

import { commercialUncoveredStyles } from '../styles';

const AddCommercialUncoveredForm = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector(postCommercialUncoveredFormSelector);
  const history = useHistory();

  const { values, errors, touched, resetForm, submitForm, setFieldValue, handleChange } = useFormik(
    {
      initialValues: commercialUncoveredFormInitialValues,
      validationSchema: commercialUncoveredFormValidationSchema,
      validateOnChange: true,
      onSubmit: () => {
        dispatch(postCommercialUncoveredForm(values))
          .then(() => {
            toast.success('Submited Successfully');
            history.push('/recoveries/forms/commercial');
          })
          .catch(() => {
            toast.success('Submited Successfully');
          });
      },
    },
  );

  return (
    <Grid item className={'container page-body-wrapper position-relative pb-4'} md={12} lg={12}>
      <h3 className="page-title mb-5">Create Commercial Uncovered Form</h3>
      <Grid item sx={commercialUncoveredStyles?.card}>
        <CommercialUncoveredForm
          handleChange={handleChange}
          submitForm={submitForm}
          setFieldValue={setFieldValue}
          values={values}
          errors={errors}
          touched={touched}
          loading={loading}
        />
      </Grid>
    </Grid>
  );
};

export default AddCommercialUncoveredForm;
