import axios from "axios";
import { CombinedNote, GetCombinedNotesParams } from "../../types/combined-notes.type";
import { REACT_APP_API_URL } from "@src/lib/global-variables";
import { PaginationResponse } from "@src/types/service.type";

export class CombinedNotesService {
    async getCombinedNotes(params: GetCombinedNotesParams) {
        const response = await axios.get<PaginationResponse<CombinedNote[]>>(`${REACT_APP_API_URL}/combined-notes`, { params })
        return response.data;
    }
}