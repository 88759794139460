import React from 'react';
import { Button, ButtonGroup, Card, CardBody, Col, Container, Row } from 'reactstrap';
import Table from '@material-ui/core/Table';
import TablePagination from '@material-ui/core/TablePagination';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import WIPCostsUserHead from './WIPCostsUserHead';
import Services from '../../../../shared/Services.js';
import { withRouter } from 'react-router';
import ModalComponent from '../../../../shared/components/ModalCustom';
import StaffService from '../../../../services/StaffService';
import { TailSpin } from 'react-loader-spinner';
import { UserContext } from '../../../../context/user-context';

function getSorting(order, orderBy) {
  if (order === 'desc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
}

class WIPCostsUser extends React.Component {
  static contextType = UserContext
  
  constructor(props) {
    super(props);
    this.state = {
      WIPData: [],
      order: 'asc',
      orderBy: '',
      selected: new Map([]),
      page: 0,
      rowsPerPage: 5,
      accessErrorModal: false,
      stateId: '',
      loader: false,
    };

    this.staff = new StaffService();
    this.services = new Services();
    this.EditStaff = this.EditStaff.bind(this);
  }

  componentDidMount() {

    localStorage.setItem('editWIPCostId', '');
    const {user} = this.context;

    if (user && user.access_level === 'SUPER') {
      this.getStaff();
    } else {
      this.setState({ accessErrorModal: true });
    }
  }

  getStaff = async () => {
    this.setState({ loader: true });
    try {
      const params = {};
      const res = await this.staff.getStaff(params);
      this.setState({ WIPData: res.data });
      this.setState({ loader: false });
    } catch (e) {
      this.services.errorToast(e);
      this.setState({ loader: false });
      this.setState({ accessErrorModal: true });
      throw new Error(e);
    }
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      const { data } = this.state;
      const newSelected = new Map();
      data.map(n => newSelected.set(n.id, true));
      this.setState({ selected: newSelected });
      return;
    }
    this.setState({ selected: new Map([]) });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const newSelected = new Map(selected);
    const value = newSelected.get(id);
    let isActive = true;
    if (value) {
      isActive = false;
    }
    newSelected.set(id, isActive);
    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleDeleteSelected = () => {
    const { data } = this.state;
    let copyData = [...data];
    const { selected } = this.state;

    for (let i = 0; i < [...selected].filter(el => el[1]).length; i += 1) {
      copyData = copyData.filter(obj => obj.id !== selected[i]);
    }

    this.setState({ data: copyData, selected: new Map([]) });
  };

  isSelected = (id) => {
    const { selected } = this.state;
    return !!selected.get(id);
  };

  EditStaff(e, d) {
    localStorage.setItem('wipUserId', d.id);
    this.props.history.push('/wip-costs-add');
  }

  accessErrorModalClose = () => {
    this.setState({ accessErrorModal: false });
    this.props.history.push('/dashboard');
  };

  render() {
    const {
      WIPData, selected, order, orderBy, page, rowsPerPage, loader,
    } = this.state;

    return (
      (loader ? (
        <div className={'loader'}>
          <TailSpin
            height='100'
            width='100'
            color='#f69066'
            ariaLabel='loading'
          />
        </div>
      ) : <Container>
        <Row>
          <Col md={12}>
            <h3 className='page-title'>WIP Scale of Fees (Calculation)</h3>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <div style={{ paddingTop: '42px' }}>

                </div>
                <div className='material-table__wrap'>
                  <Table className='material-table'>
                    <WIPCostsUserHead
                      numSelected={[...selected].filter(el => el[1]).length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={this.handleSelectAllClick}
                      onRequestSort={this.handleRequestSort}
                      rowCount={WIPData.length}
                    />
                    <TableBody>
                      {WIPData
                        .sort(getSorting(order, orderBy))
                        .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
                        .map((d, i) => {
                          const isSelected = this.isSelected(d.id);

                          return (
                            <TableRow
                              className='material-table__row'
                              role='checkbox'
                              onClick={event => this.handleClick(event, d.id)}
                              aria-checked={isSelected}
                              tabIndex={-1}
                              key={i}
                              selected={isSelected}
                            >
                              <TableCell
                                className='material-table__cell material-table__cell-right'
                                component='th'
                                scope='row'
                                padding='none'
                              >
                                {d.id}
                              </TableCell>

                              <TableCell
                                className='material-table__cell material-table__cell-right'
                                component='th'
                                scope='row'
                                padding='none'
                              >{`${d.first_name} ${d.last_name}`}
                              </TableCell>
                              <TableCell
                                className='material-table__cell material-table__cell-right'
                                component='th'
                                scope='row'
                                padding='none'
                              >{d.comms_email}
                              </TableCell>


                              <TableCell className='material-table__cell material-table__cell-left'>
                                <ButtonGroup className='btn-group--icons' dir='ltr'>
                                  <Button className='btn btn-secondary' id='Edit' onClick={(e) => this.EditStaff(e, d)}>
                                    View WIP cost
                                    {/* <span className="lnr lnr93"></span> */}
                                  </Button>
                                </ButtonGroup>
                                {/* <UncontrolledTooltip  placement="bottom" target="Edit">
                                    View WIP const
                                  </UncontrolledTooltip> */}
                              </TableCell>
                            </TableRow>
                          );
                        })}

                    </TableBody>
                  </Table>
                </div>
                <TablePagination
                  component='div'
                  className='material-table__pagination'
                  count={WIPData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                  nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                  rowsPerPageOptions={[5, 10, 15]}
                  dir='ltr'
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        {this.state.accessErrorModal ?
          <ModalComponent modal={this.state.accessErrorModal} color='danger' isCancel={false}
                          message='You do not have access to this, please contact your manager for assistance'
                          onConfirm={(e) => this.accessErrorModalClose(e)}
                          onClose={(e) => this.accessErrorModalClose(e)} /> : null}

      </Container>)

    );
  }
}

export default withRouter(WIPCostsUser);