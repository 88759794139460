import { ShortMessage, ShortMessageFrom, SmsType } from '@containers/Communication/types/sms.type'
import { FC } from 'react'
import styles from './sms-modal.module.scss'
import cls from 'classnames'
import { Debtor } from '@containers/Setting/components/Contacts/taskboard/types'
import { Contact } from '@containers/Setting/components/Contacts/types/contact-list.type'
import { format } from 'date-fns'

interface SmsConversationItemProps {
  shortMessage: ShortMessage
  elementRef: ((node: HTMLDivElement) => void) | null
}

const SmsIncomingConversationItem: FC<SmsConversationItemProps> = ({
  shortMessage,
  elementRef,
}) => {
  const getDebtorName = (debtor: Debtor) => {
    if (!debtor) return 'Debtor not found'
    return `${shortMessage.debtor?.name_first} ${shortMessage.debtor?.name_last} | ${shortMessage.from}`
  }

  const getCompanyContactName = (companyContact: Contact) => {
    if (!companyContact) return 'Company Contact not found'
    return `${shortMessage.company_contact?.first_name} ${shortMessage.company_contact?.last_name} | ${shortMessage.from}`
  }

  const header =
    shortMessage.from_mode === ShortMessageFrom.Debtor
      ? getDebtorName(shortMessage.debtor)
      : getCompanyContactName(shortMessage.company_contact)

  return (
    <div
      className={cls(styles.smsConversationItemWrapper, styles[shortMessage.sms_type])}
      ref={elementRef}
    >
      <div className={cls(styles.smsConversationItem, styles[shortMessage.sms_type])}>
        <span className={styles.headerWrapper}>
          <span className={cls(styles.header, styles.incoming)}>{header}</span>
          <span className={cls(styles.date, styles[shortMessage.sms_type])}>
            {format(new Date(shortMessage.time_created), 'dd/MM/yyyy - HH:mm')}
          </span>
        </span>
        <span className={styles.body}>{shortMessage.body}</span>
        <span className={styles.status}>Delivered</span>
      </div>
    </div>
  )
}

const SmsOutgoingConversationItem: FC<SmsConversationItemProps> = ({
  shortMessage,
  elementRef,
}) => {
  const header =
    shortMessage.from_mode === ShortMessageFrom.Staff
      ? shortMessage.staff_created?.nickname || 'Staff not found'
      : 'System SMS'

  return (
    <div
      className={cls(styles.smsConversationItemWrapper, styles[shortMessage.sms_type])}
      ref={elementRef}
    >
      <div className={cls(styles.smsConversationItem, styles[shortMessage.sms_type])}>
        <span className={styles.headerWrapper}>
          <span className={styles.header}>{header}</span>
          <span className={cls(styles.date, styles[shortMessage.sms_type])}>
            {format(new Date(shortMessage.time_created), 'dd/MM/yyyy - HH:mm')}
          </span>
        </span>
        <span className={styles.body}>{shortMessage.body}</span>
        <span className={styles.status}>Delivered</span>
      </div>
    </div>
  )
}

const SmsConversationItem: FC<SmsConversationItemProps> = ({ shortMessage, elementRef }) => {
  return (
    <>
      {shortMessage.sms_type === SmsType.Incoming ? (
        <SmsIncomingConversationItem shortMessage={shortMessage} elementRef={elementRef} />
      ) : (
        <SmsOutgoingConversationItem shortMessage={shortMessage} elementRef={elementRef} />
      )}
    </>
  )
}

export default SmsConversationItem
