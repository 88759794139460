import { FC, useMemo } from 'react'
import LandlordContactForm, { LandlordContactFormValues } from './landlord-contact-form.component'
import { BaseEditFormProps } from '@src/types/component-types'
import { LandlordAddress, LandlordContact } from '@containers/Sales/Properties/types/property.type'
import { useUpdateLandlordAddressMutation } from '@containers/Sales/Landlord/hooks/crud-landlord-address.hook'
import { useUpdateLandlordContactMutation } from '@containers/Sales/Landlord/hooks/crud-landlord-contact.hook'
import { toast } from 'react-toastify'

interface EditLandlordContactFormProps extends BaseEditFormProps<LandlordContact> {
  onCancelClick?: () => void
}

const EditLandlordContactForm: FC<EditLandlordContactFormProps> = ({
  entity,
  onCancelClick,
  onSuccessSubmit,
}) => {
  const { mutateAsync, isLoading } = useUpdateLandlordContactMutation()

  const handleSuccessSubmit = async (data: LandlordContactFormValues) => {
    try {
      const res = await mutateAsync({
        landlordId: entity.landlordId,
        contactId: entity.id,
        data,
      })
      onSuccessSubmit && onSuccessSubmit(res)
    } catch (e) {
      console.log(e)
      toast.error('Failed to update landlord contact')
    }
  }

  const initialValues: LandlordContactFormValues = useMemo(
    () => ({
      position: entity.position,
      firstName: entity.firstName,
      lastName: entity.lastName,
      email: entity.email,
      mobilePhone: entity.mobilePhone,
      landlinePhone: entity.landlinePhone,
      faxNumber: entity.faxNumber,
      note: entity.note,
    }),
    [entity],
  )

  return (
    <LandlordContactForm
      onSuccessSubmit={handleSuccessSubmit}
      loading={isLoading}
      initialValues={initialValues}
      onCancelClick={onCancelClick}
      submitButtonTitle="Save"
    />
  )
}

export default EditLandlordContactForm
