import { Params } from "@src/types/service.type";
import { CreateDebtorLegalTribunalData } from "../types/debtor-legal-tribunal.type";
import axios from "axios";
import { REACT_APP_API_URL } from "@src/lib/global-variables";
import { DebtorLegalTribunal } from "../types/debtor-legal.type";
import { DeepPartial } from "react-hook-form";

export class DebtorLegalTribunalService {

  async createDebtorLegalTribunal(data: CreateDebtorLegalTribunalData, params?: Params) {
    const res = await axios.post<DebtorLegalTribunal>(`${REACT_APP_API_URL}/debtor-legals/tribunals`, data, { params });
    return res.data;
  }

  async updateDebtorLegalTribunal(debtorLegalTribunalId: number, data: DeepPartial<DebtorLegalTribunal>, params?: Params) {
    const res = await axios.patch<DebtorLegalTribunal>(`${REACT_APP_API_URL}/debtor-legals/tribunals/${debtorLegalTribunalId}`, data, { params });
    return res.data;
  }

  async deleteDebtorLegalTribunal(debtorLegalTribunalId: number, params?: Params) {
    const res = await axios.delete<DebtorLegalTribunal>(`${REACT_APP_API_URL}/debtor-legals/tribunals/${debtorLegalTribunalId}`, { params });
    return res.data;
  }

}