import React, { useEffect, useState } from 'react';
import Select from 'react-select';

export const PropertyDetails = (props) => {
  const { propertyDetails, setPropertyDetails, recoveryOnUncoveredPropertyOptions,errors } = props;

  useEffect(()=>{
    const defaultSelectedCover = recoveryOnUncoveredPropertyOptions.find((val) => val.value === propertyDetails.is_cover)
    setSelectedCover(defaultSelectedCover)
  },[recoveryOnUncoveredPropertyOptions,propertyDetails])

  const [selectedCover, setSelectedCover] = useState(null);

  const onInputChangeHandler = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setPropertyDetails((prev) => ({ ...prev, [name]: value }));
  };

  const setRecover = (name) => (value) => {
    setSelectedCover(value)
    setPropertyDetails((prev) => ({ ...prev, [name]: value.value }));
  };

  return (
    <>
      <div className="card__title">
        <h5 className="bold-text">Property Details</h5>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Address</span>
        <div className="form__form-group-field">
          <input
            name="address"
            type="text"
            placeholder="Address"
            onChange={onInputChangeHandler}
            value={propertyDetails.address}
            required={true}

          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">
          Recovery on Uncovered Property
        </span>
        <div className="form__form-group-field">
          <Select
            name="is_cover"
            value={selectedCover}
            onChange={setRecover('is_cover')}
            options={recoveryOnUncoveredPropertyOptions}
            classNamePrefix="react-select"
            className="react-select"
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Suburb</span>
        <div className="form__form-group-field">
          <input
            name="suburb"
            type="text"
            placeholder="Suburb"
            onChange={onInputChangeHandler}
            value={propertyDetails.suburb}
            required={true}
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Post Code</span>
        <div className="form__form-group-field">
          <input
            name="post_code"
            type="text"
            placeholder="Post Code"
            onChange={onInputChangeHandler}
            value={propertyDetails.post_code}
            required={true}
            // defaultValue={defaultData?.property_details?.post_code}
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">State</span>
        <div className="form__form-group-field">
          <input
            name="state"
            type="text"
            placeholder="State"
            onChange={onInputChangeHandler}
            value={propertyDetails.state}
            required={true}
            // defaultValue={defaultData?.property_details?.state}
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Landlord</span>
        <div className="form__form-group-field">
          <input
            name="landlord"
            type="text"
            placeholder="Landlord"
            onChange={onInputChangeHandler}
            value={propertyDetails.landlord}
            required={true}
            // defaultValue={defaultData?.property_details?.landlord}
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">First Name</span>
        <div className="form__form-group-field">
          <input
            name="first_name"
            type="text"
            placeholder="First Name"
            onChange={onInputChangeHandler}
            value={propertyDetails.first_name}
            required={true}
            // defaultValue={defaultData?.property_details?.first_name}
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Last Name</span>
        <div className="form__form-group-field">
          <input
            name="last_name"
            type="text"
            placeholder="Last Name"
            onChange={onInputChangeHandler}
            value={propertyDetails.last_name}
            required={true}
            // defaultValue={defaultData?.property_details?.last_name}
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">
          Owner Email<span style={{ color: '#ff0000' }}>*</span>
        </span>
        <div className="form__form-group-field d-flex flex-column">
          <input
            name="owner_email"
            type="email"
            required={true}
            placeholder="Owner Email"
            onChange={onInputChangeHandler}
            value={propertyDetails.owner_email}
          />
          {/*{errors.owner_email && (*/}
          {/*  <span className="text-danger">Owner Email is required</span>*/}
          {/*)}*/}
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">
          Owner Phone<span style={{ color: '#ff0000' }}>*</span>
        </span>
        <div className="form__form-group-field d-flex flex-column">
          <input
            name="owner_phone"
            type="text"
            placeholder="Owner Phone"
            onChange={onInputChangeHandler}
            value={propertyDetails.owner_phone}
            required={true}
          />
          {/* {errors.property_details?.owner_phone && (*/}
          {/*  <span className="text-danger">Owner Phone is required</span>*/}
          {/*)}*/}
        </div>
      </div>
    </>
  );
};
