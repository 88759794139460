import { Checkbox } from '@mui/material'
import { FC } from 'react'

const DataGridCheckbox: FC<{ checked: boolean; onChange: () => void }> = ({
  checked,
  onChange,
}) => {
  return (
    <Checkbox checked={checked} onChange={onChange} inputProps={{ 'aria-label': 'controlled' }} />
  )
}

export default DataGridCheckbox
