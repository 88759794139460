import { useEffect, useRef, useState } from "react"

export const useOptimizedDebounce = <T>(value: T, delay?: number): T => {
  const isComponentInitialized = useRef(false)
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(() => {
    if (!isComponentInitialized.current) {
      isComponentInitialized.current = true
      return
    }

    const timer = setTimeout(() => setDebouncedValue(value), delay || 500)

    return () => {
      clearTimeout(timer)
    }
  }, [value, delay])

  return debouncedValue
}