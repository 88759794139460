import {
  CreateDebtorReferenceFormProps,
  DebtorReferenceFormValues,
} from '@containers/Recovery/Wip/_modules/Debtors/types/debtor-associate-detail.type'
import { toast } from 'react-toastify'
import { FC } from 'react'
import DebtorReferenceForm from './debtor-reference-form.component'
import { useCreateDebtorReferenceMutation } from '@containers/Recovery/Wip/_modules/Debtors/hooks/mutations/crud-debtor-reference.hook'

const CreateDebtorReferenceForm: FC<CreateDebtorReferenceFormProps> = ({
  debtorId,
  onSuccessSubmit,
  onCancelClick,
}) => {
  const { mutateAsync, isLoading } = useCreateDebtorReferenceMutation()

  const handleSubmit = async (data: DebtorReferenceFormValues) => {
    try {
      const debtorRelation = await mutateAsync({
        ...data,
        debtor_id: debtorId,
        call_result: data.call_result.value,
      })
      onSuccessSubmit && onSuccessSubmit(debtorRelation)
      toast.success('Created')
    } catch (e) {
      toast.error('Something went wrong')
      console.log(e)
    }
  }

  return (
    <DebtorReferenceForm
      onSubmit={handleSubmit}
      onCancelClick={onCancelClick}
      loading={isLoading}
    />
  )
}

export default CreateDebtorReferenceForm
