import TrackPaymentFromSection
  from './components/sections/track-payment-from-section/track-payment-from-section.component'
import styles from './payment-tracking-page.module.scss'
import { FC, useState } from 'react'
import { Debtor } from '../../Debtors/types/debtor.type'
import { PaymentTracking, PaymentTrackingPageProps } from './types/payment-tracking.type'
import NotFoundPaymentTrackingSection
  from './components/sections/payment-tracking-section/not-found-payment-tracking-section.component'
import CreatePaymentTrackingSection
  from './components/sections/payment-tracking-section/create-payment-tracking-section.component'
import EditPaymentTrackingSection
  from './components/sections/payment-tracking-section/edit-payment-tracking-section.component'
import cls from 'classnames'
import PaymentTable
  from '@containers/Recovery/Wip/_modules/Financial/Payments/components/tables/payment-table/payment-table.component'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { useHistory } from 'react-router'

const PaymentTrackingPage: FC<PaymentTrackingPageProps> = ({ wip, alignment = 'horizontal' }) => {
  const [selectedDebtor, setSelectedDebtor] = useState<Debtor | null>(
    wip.debtors_added_to_pay_tracking[0] || null,
  )
  const history = useHistory()
  const redirectToPaymentLogs = () => {
    history.push(`/wip/${wip.id}/financial/payments`)
  }
  const foundPaymentTracking =
    wip.pay_trackings.find((paymentTracking) => paymentTracking.debtor_id === selectedDebtor?.id) ||
    null

  const renderPaymentTrackingSection = (
    selectedDebtor: Debtor | null,
    paymentTracking: PaymentTracking | null,
  ) => {
    if (!selectedDebtor) {
      return <NotFoundPaymentTrackingSection />
    }

    if (!paymentTracking) {
      return <CreatePaymentTrackingSection wipId={wip.id} selectedDebtor={selectedDebtor} />
    }

    return <EditPaymentTrackingSection paymentTracking={paymentTracking} wipId={wip.id} />
  }

  return (
    <div className={cls(styles.wrapper, { [styles.vertical]: alignment === 'vertical' })}>
      <TrackPaymentFromSection
        wipId={wip.id}
        debtors={wip.debtors}
        debtorsAddedToPayTracking={wip.debtors_added_to_pay_tracking}
        selectedDebtor={selectedDebtor}
        onSelectDebtor={setSelectedDebtor}
        full={alignment === 'vertical'}
      />
      {renderPaymentTrackingSection(selectedDebtor, foundPaymentTracking)}
      <PaymentTable wipId={wip.id} />
      <SubmitButton onClick={redirectToPaymentLogs}>Payment Logs</SubmitButton>
    </div>
  )
}

export default PaymentTrackingPage
