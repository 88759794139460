import React,{Component} from 'react';
import { Col, Row,  Card, CardBody } from 'reactstrap';
// import SettingTable from './components/SettingTable';
import { Link } from 'react-router-dom';
import ModalComponent from '../../../../shared/components/ModalCustom'
import Services from '../../../../shared/Services.js';
import TemplateService from "../../../../services/TempleatsService";

class Setting extends Component{
  constructor(props){
    super(props)
    this.state={
      title:"",
      accessErrorModal:false,
  }
  this.services = new Services();
    this.temp = new TemplateService()
}
  componentDidMount(){
    this.setState({title:"MANAGE STAFF"})
    this.getTemplateTypeList();
  }
  getTemplateTypeList=(i)=>{
    this.temp.getTempEmail(i,(res)=>{
      if(res.status===200){
        const item = [];
        res.data && res.data.data.forEach(element => {
          item.push({ value: element, label: element })
        });
        this.setState({
          typeList: item
        })
      }
      else if(res.statusCode === 401 && res.responseType === "UNAUTHORIZED"){
        this.setState({accessErrorModal:true})
       }
      else{
        this.services.errorToast(res.message);
      }
    })
  }

  accessErrorModalClose =(e)=>{
    this.setState({accessErrorModal:false});
    this.props.history.push("/dashboard")
  }
  render(){
    return(
      <div className="container">
      <Row>
        <Col md={12} lg={12}>
            <h3 className="page-title">Manage Templates</h3>
        </Col>
      </Row>
      <Row>
        {/* Here we will design setting screen */}
        <Col md={12} lg={12}>
          <Card>
            <CardBody className="settings-mangement">
              <div className="card__title">
                <h5 className="bold-text">Management Tools</h5>
              </div>
              <Row>
                <Col xl={3} lg={4} md={6} sm={12}>
                    <Link to="/edit-templates" className="mg_tools_box">
                      <div className="mg_icon"></div>
                      <p className="mg_title">Edit Templates</p>
                    </Link>
                </Col>
                {/* <Col lg={3} md={6} sm={12}>
                    <Link to="/templates" className="mg_tools_box">
                      <div className="mg_icon"></div>
                      <p className="mg_title">Send Mail</p>
                    </Link>
                </Col> */}
              </Row>
              <Col md={12} className="toolbox_divider">
                <hr />
              </Col>           
            </CardBody>
          </Card>
        </Col>
      </Row>
      {this.state.accessErrorModal ? <ModalComponent modal={this.state.accessErrorModal } color="danger" isCancel={false} message="You do not have access to this, please contact your manager for assistance" 
                onConfirm={(e)=>this.accessErrorModalClose(e)}  onClose={(e)=>this.accessErrorModalClose(e)} /> : null} 

    </div>
    )
  }
}
export default Setting;
