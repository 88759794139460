import React from 'react';

export const CourtDetails = ({ courtDetails, setCourtDetails }) => {
  const onInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setCourtDetails((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <>
      <div className="card__title">
        <h5 className="bold-text">Court Details</h5>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Plaintiff Name</span>
        <div className="form__form-group-field">
          <input
            name="plaintiff_name"
            type="text"
            placeholder="Plaintiff Name"
            onChange={onInputChange}
            value={courtDetails.plaintiff_name}
            // defaultValue={defaultData?.court_details?.plaintiff_name}
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Plaintiff Address</span>
        <div className="form__form-group-field">
          <input
            name="plaintiff_address"
            type="text"
            placeholder="Plaintiff Address"
            onChange={onInputChange}
            value={courtDetails.plaintiff_address}
            // defaultValue={defaultData?.court_details?.plaintiff_address}
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Defendant Name</span>
        <div className="form__form-group-field">
          <input
            name="defendant_name"
            type="text"
            placeholder="Defendant Name"
            onChange={onInputChange}
            value={courtDetails.defendant_name}
            // defaultValue={defaultData?.court_details?.defendant_name}
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Defendant Address</span>
        <div className="form__form-group-field">
          <input
            name="defendant_address"
            type="text"
            placeholder="Defendant Address"
            onChange={onInputChange}
            value={courtDetails.defendant_address}
            // defaultValue={defaultData?.court_details?.defendant_address}
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Tribunal Location</span>
        <div className="form__form-group-field">
          <input
            name="tribunal_location"
            type="text"
            placeholder="Tribunal Location"
            onChange={onInputChange}
            value={courtDetails.tribunal_location}
            // defaultValue={defaultData?.court_details?.tribunal_location}
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Tribunal Order Date</span>
        <div className="form__form-group-field">
          <input
            name="tribunal_order_date"
            type="date"
            placeholder="Date Of Hearing"
            onChange={onInputChange}
            value={courtDetails.tribunal_order_date}
            // defaultValue={defaultData?.court_details?.tribunal_order_date}
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Tribunal Claim No.</span>
        <div className="form__form-group-field">
          <input
            name="tribunal_claim"
            type="text"
            placeholder="Tribunal Claim No."
            onChange={onInputChange}
            value={courtDetails.tribunal_claim}
            // defaultValue={defaultData?.court_details?.tribunal_claim}
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Claim/Plaintiff Number</span>
        <div className="form__form-group-field">
          <input
            name="claim_plaintiff_number"
            type="text"
            placeholder="Claim/Plaintiff Number"
            onChange={onInputChange}
            value={courtDetails.claim_plaintiff_number}
            // defaultValue={defaultData?.court_details?.claim_plaintiff_number}
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Court Location</span>
        <div className="form__form-group-field">
          <input
            name="court_location"
            type="text"
            placeholder="Court Location"
            onChange={onInputChange}
            value={courtDetails.court_location}
            // defaultValue={defaultData?.court_details?.court_location}
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Enforcement Date</span>
        <div className="form__form-group-field">
          <input
            name="enforcement_date"
            type="date"
            placeholder="Date Of Hearing"
            onChange={onInputChange}
            value={courtDetails.enforcement_date}
            // defaultValue={defaultData?.court_details?.enforcement_date}
          />
        </div>
      </div>
    </>
  );
};
