import {
  StepConnector,
  stepConnectorClasses,
  Stepper,
  stepperClasses,
  StepIconProps,
  Step,
  StepLabel,
  styled,
} from '@mui/material'
import CheckIcon from '@src/icons/check'
import { FC } from 'react'

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#FF6B03',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#FF6B03',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : 'white',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}))

const HorizontalStepper = styled(Stepper)(() => ({
  [`&.${stepperClasses.root}`]: {
    backgroundColor: '#EDF2F8',
    height: '88px',
    padding: '0 24px',
  },
}))

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: '#784af4',
    }),
    '& .QontoStepIcon-completed': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 40,
      height: 40,
      borderRadius: '50%',
      fontSize: '16px',
      color: 'white',
      backgroundColor: '#FF6B03',
      '& svg': {
        width: 24,
        height: 24,
        '& path': {
          stroke: 'white',
        },
      },
    },
    '& .QontoStepIcon-active': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 40,
      height: 40,
      borderRadius: '50%',
      fontSize: '16px',
      color: 'black',
      border: '2px solid #FF6B03',
      backgroundColor: 'white',
    },
    '& .QontoStepIcon-circle': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 40,
      height: 40,
      borderRadius: '50%',
      fontSize: '16px',
      color: 'black',
      backgroundColor: 'white',
    },
  }),
)

function QontoStepIcon(props: StepIconProps) {
  const { icon, active, completed, className } = props

  let jsx: React.ReactNode

  if (completed) {
    jsx = (
      <div className="QontoStepIcon-completed">
        <CheckIcon />
      </div>
    )
  } else if (active) {
    jsx = <div className="QontoStepIcon-active">{icon}</div>
  } else {
    jsx = <div className="QontoStepIcon-circle">{icon}</div>
  }

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {jsx}
    </QontoStepIconRoot>
  )
}

const steps = ['Upload file', 'Select header row', 'Match Columns', 'Validate data', 'Merge']

interface UploadMultiplePropertiesStepperProps {
  activeStep: number
}

const UploadMultiplePropertiesStepper: FC<UploadMultiplePropertiesStepperProps> = ({
  activeStep,
}) => {
  return (
    <HorizontalStepper activeStep={activeStep} connector={<QontoConnector />}>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
        </Step>
      ))}
    </HorizontalStepper>
  )
}

export default UploadMultiplePropertiesStepper
