import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { Spinner } from 'react-bootstrap';
import Services from '../../../../shared/Services';
import UploadsService from '../../../../services/UploadsService';
import { Button } from 'reactstrap';
import ChipsArray from '../../../Setting/components/Contacts/WIPs/ChipArray';


export const AttachedFilesDetails = ({ wipDetails, setWipDetails }) => {

  const file = wipDetails.attached_file === null ? []:wipDetails.attached_file;
  const [fileLoader, setFileLoader] = useState(false);
  const [delays, setDelays]=useState(false)
  const services = new Services();
  const uploads = new UploadsService();

  const fileUpload = (img) => {
    setFileLoader(true);
    setDelays(true)
    uploads.uploadsFile(img[0]).then((res) => {
      if (res.status === 201) {
        setWipDetails((prev) => ({ ...prev, attached_file: prev.attached_file ? [...prev.attached_file, res.data] : [res.data] }));
        delay('File upload successfully')
      } else {
        services.errorToast(res.message);
        setFileLoader(false);
      }

    });
  };

  const removeFile = (file) => {
    setDelays(true)
    uploads.deleteFile(file).then((res) => {
      try {
        if (res.status === 200) {
          const remove = wipDetails.attached_file.filter((el)=> el.file_path !== file.file_path )
          setWipDetails((prev) => ({ ...prev, attached_file: remove}));
          delay('File delete successfully')
        } else {
          services.errorToast(res);
        }
      } catch (e) {
        console.log(e);
      }
    });
  };

  const handlerDownloadFile = (file) => {
    setDelays(true)
    uploads.downloadFile(file).then(()=> delay());
  };

  const delay = (text = 'Download successfully')=>{
    setTimeout(()=>{
      setDelays(false)
      setFileLoader(false);
      services.successToast(text);
    },4500)
  }


  return (
    <>
      <div className='card__title'>
        <h5 className='bold-text'>Uploaded files</h5>
      </div>
      <div className='form__form-group'>
        <span className='form__form-group-label'>Files</span>
        <div className='form__form-group-field'>
          <div className={`dropzone dropzone--single`}>
            {fileLoader && (
              <Spinner
                animation='border'
                variant='warning'
                className='dropzone__drop-here'
              />
            )}
            <Dropzone
              name={'file'}
              multiple={false}
              onDrop={(fileToUpload) => {
                fileUpload(fileToUpload);
              }}
              disabled={delays}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} className='dropzone__input'>
                  {wipDetails.attached_file === null && !fileLoader && (
                    <div className='dropzone__drop-here'>
                      <span className='lnr lnr-upload' /> Drop File Here To
                      Upload
                    </div>
                  )}
                  <input {...getInputProps()} disabled={fileLoader} />
                </div>
              )}
            </Dropzone>
          </div>
        </div>
      </div>
      <div className='form__form-group'>
        <span className='form__form-group-label'>Attached files</span>
        <div className='col-6'>
          <ChipsArray handlerDownloadFile={handlerDownloadFile}
                      removeFile={removeFile}
                      files={file}
                      delay={delays}
          />
        </div>
      </div>
    </>
  );
};
