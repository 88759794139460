import { getInvoiceTemplateByIdActionTypes } from '../actionTypes';
import { getInvoiceTemplateByIdService } from '../service';

export const getInvoiceTemplateById = (id) => async (dispatch) => {
  dispatch({ type: getInvoiceTemplateByIdActionTypes.GET_INVOICE_TEMPLATE_BY_ID_LOADING });
  try {
    const res = await getInvoiceTemplateByIdService(id);
    
    dispatch({
      type: getInvoiceTemplateByIdActionTypes.GET_INVOICE_TEMPLATE_BY_ID_SUCCESS,
      payload: res,
    });

    return res;
  } catch (error) {
    dispatch({
      type: getInvoiceTemplateByIdActionTypes.GET_INVOICE_TEMPLATE_BY_ID_LOADING,
      payload: error,
    });

    throw error;
  }
};
