import { AssignedStaff, Debtor, TaskProps } from "@containers/Setting/components/Contacts/taskboard/types";
import { Company, Contact } from "@containers/Setting/components/Contacts/types/contact-list.type";
import { BaseModalProps, SelectOption } from "@src/types/component-types";
import { PhoneContactOption } from "./communication.type";
import { Wip } from "@containers/Recovery/Wip/types/wip.type";
import { Client } from "@containers/Sales/Client/types/client.type";

export enum ShortMessageTo {
  Debtor = 'debtor',
  CompanyContact = 'company_contact',
  Other = 'other',
  System = 'system',
}

export enum ShortMessageFrom {
  Staff = 'staff',
  Debtor = 'debtor',
  CompanyContact = 'company_contact',
  System = 'system',
}

export enum ShortMessageStatus {
  Sent = 'sent',
  Scheduled = 'scheduled',
  Sending = 'sending',
  Failed = 'failed',
}

export enum SmsType {
  Incoming = 'incoming',
  Outgoing = 'outgoing',
}


export interface ShortMessage {
  id: number;
  messageSid: string;
  to: string;
  from: string;
  to_mode: ShortMessageTo;
  from_mode: ShortMessageFrom;
  status: ShortMessageStatus;
  body: string;
  sms_type: SmsType;

  // Assigned To: Company[] or Task or Wip[]

  companies: Company[];
  task: TaskProps;
  task_id: number;
  wips: Wip[];


  // sender

  staff_created: AssignedStaff
  staff_id_created: number;

  // recipients

  debtor: Debtor;
  debtor_id: number;

  company_contact: Contact;
  company_contact_id: number;

  // scheduled sending

  is_scheduled: boolean;
  scheduled_for: Date;

  // timestamps

  time_created: Date;
  time_modified: Date;
  time_deleted: Date;
}


export interface SendShortMessageData {
  to: string;
  to_mode: ShortMessageTo;
  body: string;
  debtor_id?: number;
  company_contact_id?: number;
  name_to?: string;
  company_id?: number;
  staff_id_created: number
  wip_id?: number;
  task_id?: number;
  scheduled_for?: Date;
}


export interface SmsTemplate {
  id: number;
  sms_template_archived: boolean;
  sms_template_body: string;
  sms_template_deleted: boolean;
  sms_template_title: string;
  sms_template_type: string | null;
  staff_id_created: number | null;
  staff_id_deleted: number | null;
  staff_id_modified: number | null;
  time_archived: string;
  time_created: string;
  time_deleted: string | null;
  time_modified: string;
}


export interface SendSmsFormSubmitValues {
  sendTo: SelectOption
  template: SelectOption | null
  contact: PhoneContactOption[] | string
  message: string
}

export interface SendSmsFormProps {
  sendToOptions: SelectOption[]
  templateOptions: SelectOption[]
  contactOptions: PhoneContactOption[]
  onSendToChange: (value: string) => void
  loading?: boolean
  onSubmit: (data: SendSmsFormSubmitValues) => void
  onCancelClick?: () => void
}

export interface SendSmsFormValues {
  sendTo: SelectOption | null
  template: SelectOption | null
  contact: SelectOption[] | string | null
  message: string
}

export interface WipSendSmsModalProps extends BaseModalProps {
  wip: Wip
}

export interface WipTaskSendSmsModalProps extends BaseModalProps {
  wip: Wip
  task: TaskProps
}

export interface ClientSendSmsModalProps extends BaseModalProps {
  client: Client
}