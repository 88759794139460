import React, { useEffect } from 'react'
import { Button, ButtonToolbar } from 'react-bootstrap'
import CustomPhoneInput from '@components/inputs/form-input/custom-phone-input/custom-phone-input.component'

import { radioStyle } from './recoveryDetails'
import { Controller } from 'react-hook-form'

const TenantsDetails = ({ control, register, fields, append, remove }) => {
  return (
    <>
      <h3>Enter details for all Tenants</h3>
      <p>
        Please enter the details for all Tenants for this recovery on this form. Please do not
        submit multiple recoveries for the same debt.
      </p>
      {fields.map((item, index) => {
        return (
          <div key={index}>
            <div className="d-flex align-items-center mb-2">
              <h3>Tenant {`${index + 1}`}</h3>
              <Button
                color="primary"
                className="mb-0 ml-4"
                type="button"
                onClick={() => remove(index)}
              >
                Remove
              </Button>
            </div>
            <label>
              <span className="form__form-group-label">
                First Name <span style={{ color: '#ff0000' }}>*</span>
              </span>
              <input
                type="text"
                placeholder="First Name"
                required={true}
                {...register(`tenants_details[${index}].first_name`)}
              />
            </label>
            <label>
              <span className="form__form-group-label">Middle Name</span>
              <input
                type="text"
                placeholder="Middle Name"
                {...register(`tenants_details[${index}].middle_name`)}
              />
            </label>
            <label>
              <span className="form__form-group-label">
                Last Name <span style={{ color: '#ff0000' }}>*</span>
              </span>
              <input
                type="text"
                placeholder="Last Name"
                required={true}
                {...register(`tenants_details[${index}].last_name`)}
              />
            </label>
            <label>
              <span className="form__form-group-label">Aliases</span>
              <textarea
                type="text"
                placeholder="Aliases"
                {...register(`tenants_details[${index}].alias`)}
              />
            </label>
            <label>
              <span className="form__form-group-label">
                Current Address<span style={{ color: '#ff0000' }}>*</span>
              </span>
              <textarea
                type="text"
                placeholder="Current Address"
                required={true}
                {...register(`tenants_details[${index}].current_address`)}
              />
            </label>
            <label>
              <span className="form__form-group-label">Date of Birth</span>
              <input type="date" {...register(`tenants_details[${index}].date_of_birth`)} />
            </label>
            <label>
              <span className="form__form-group-label">Email Address</span>
              <input
                type="email"
                placeholder="Email Address"
                {...register(`tenants_details[${index}].email`)}
              />
            </label>
            <Controller
              name={`tenants_details[${index}].phone`}
              control={control}
              render={({ field }) => (
                <CustomPhoneInput
                  label="Home Phone"
                  placeholder="Home Phone"
                  value={field.value || undefined}
                  onChange={(value) =>
                    field.onChange(value.originalValue === undefined ? null : value.formattedPhone)
                  }
                />
              )}
            />
            <Controller
              name={`tenants_details[${index}].work_phone`}
              control={control}
              render={({ field }) => (
                <CustomPhoneInput
                  label="Work Phone"
                  placeholder="Work Phone"
                  value={field.value || undefined}
                  onChange={(value) =>
                    field.onChange(value.originalValue === undefined ? null : value.formattedPhone)
                  }
                />
              )}
            />
            <Controller
              name={`tenants_details[${index}].mobile_phone`}
              control={control}
              render={({ field }) => (
                <CustomPhoneInput
                  label="Mobile Phone"
                  placeholder="Mobile Phone"
                  value={field.value || undefined}
                  onChange={(value) =>
                    field.onChange(value.originalValue === undefined ? null : value.formattedPhone)
                  }
                />
              )}
            />
            <label>
              <span className="form__form-group-label">Licence No.</span>
              <input
                type="text"
                placeholder="Licence No."
                {...register(`tenants_details[${index}].license_no`)}
              />
            </label>
            <label>
              <span className="form__form-group-label">Passport No.</span>
              <input
                type="text"
                placeholder="Passport No."
                {...register(`tenants_details[${index}].passport_no`)}
              />
            </label>
            <label>
              <span className="form__form-group-label">Other Contact Info</span>
              <textarea
                type="text"
                placeholder="Other Contact Info"
                {...register(`tenants_details[${index}].other_contact_info`)}
              />
            </label>
          </div>
        )
      })}
      <ButtonToolbar className="form__button-toolbar mb-3">
        <Button
          color="primary"
          className="mb-0"
          type="button"
          style={{ marginLeft: '122px' }}
          onClick={() => append({})}
        >
          {fields.length === 0 ? 'Add Tenant' : 'Add another Tenant'}
        </Button>
      </ButtonToolbar>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <div style={radioStyle}>
          <input
            required={false}
            type="radio"
            name="is-entered"
            value={true}
            {...register('is_entered_all_tenants')}
          />
          Yes
        </div>
        <div style={radioStyle}>
          <input
            required={false}
            type="radio"
            name="is-entered"
            value={false}
            onClick={() => append({})}
            {...register('is_entered_all_tenants')}
          />
          No
        </div>
        <p>Have all Tenants been entered?</p>
      </div>
    </>
  )
}

export default TenantsDetails
