import { FC } from 'react'
import DebtorTable from '../../components/tables/debtor-table/debtor-table.component'
import Section from '@components/section/section.component'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import styles from './debtors-page.module.scss'
import { DebtorsPageProps } from '../../types/debtor.type'

const DebtorsPage: FC<DebtorsPageProps> = ({ wip }) => {
  return (
    <>
      <Header mb={4}>Debtors</Header>
      <Section className={styles.debtorsSection}>
        <DebtorTable debtors={wip.debtors} wipId={wip.id} />
      </Section>
    </>
  )
}

export default DebtorsPage
