import { BaseEditModalProps } from '@src/types/component-types'
import { FC } from 'react'
import { DebtorLegalNote } from '../../../types/debtor-legal.type'
import Modal from '@components/modal'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import DebtorLegalNoteForm from '../../forms/debtor-legal-note-form/debtor-legal-note-form.component'
import styles from './debtor-legal-note-modal.module.scss'
import ModalHeader from '@components/modal-header.component'

const ViewDebtorLegalNoteModal: FC<BaseEditModalProps<DebtorLegalNote>> = ({
  entity,
  ...modalProps
}) => {
  return (
    <Modal className={styles.modalWrapper} {...modalProps} withBtns={false}>
      <ModalHeader onCloseClick={modalProps.onClose} />
      <Header mb={4}>Debtor Legal Note</Header>
      <DebtorLegalNoteForm
        initialValues={{ content: entity.content }}
        onSuccessSubmit={() => {}}
        disabled={true}
        withBtn={false}
      />
    </Modal>
  )
}

export default ViewDebtorLegalNoteModal
