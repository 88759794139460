export const formButtons = {
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'no-wrap',
    marginBottom: '30px',
  },

  title: {
    fontSize: '21px',
    lineHeight: '25.2px',
    fontFamily: 'var(--SourceSansPro) !important',
    fontWeight: '700',
  },

  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'no-wrap',
    gap: '10px',
  },
};
