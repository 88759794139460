import { postInvoiceEmailActionTypes } from '../actionTypes';
import { postInvoiceEmailService } from '../service';

export const postInvoiceEmail = (data) => async (dispatch) => {
  dispatch({ type: postInvoiceEmailActionTypes.POST_INVOICE_EMAIL_LOADING });
  try {
    const res = await postInvoiceEmailService(data);

    dispatch({
      type: postInvoiceEmailActionTypes.POST_INVOICE_EMAIL_SUCCESS,
      payload: res,
    });

    return res;
  } catch (error) {
    dispatch({
      type: postInvoiceEmailActionTypes.POST_INVOICE_EMAIL_ERROR,
      payload: error,
    });

    throw error;
  }
};
