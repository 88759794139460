import BaseService from './BaseService';
import axios from 'axios';

export default class TimeLogService extends BaseService {
  constructor() {
    super();
    this.apiUrl = this.apiUrl + '/wip-time-logs';
  }

  async getTimeLog(params = {}) {
    try {
      const response = await axios.get(this.apiUrl, { params });
      if (response.status !== 200) {
        throw new Error('Error occured fetching SMS');
      }

      return response.data;
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message);
      } else {
        if (error.response) {
          throw new Error(error.response.data);
        }
        if (!error.response) {
          throw new Error(error);
        }
      }
    }
  }
}
