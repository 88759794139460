import { useQuery } from "@tanstack/react-query"
import { CallLogService } from "../services/call-log.service"
import { GLOBAL_QUERIES } from "@src/lib/global-queries"
import { Params } from "@src/types/service.type"


export const useGetCallLogById = (id: number, params: Params = {}) => {

  const getCallLogById = async (id: number, params) => {
    const callLogService = new CallLogService()
    return callLogService.getCallLogById(id, params)
  }

  const query = useQuery({
    queryKey: [GLOBAL_QUERIES.CALL_LOG.GET_BY_ID, id],
    queryFn: () => getCallLogById(id, params),
    staleTime: 1000 * 60 * 60 * 24
  })

  return query;
}