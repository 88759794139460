import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import EditStaffForm from './EditStaffForm';

const EditStaff = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className='page-title'><b>EDIT STAFF</b></h3>
      </Col>
    </Row>
    <Row className='justify-content-center'>
      <EditStaffForm />
    </Row>
  </Container>
);

export default EditStaff;
