import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import { REACT_APP_API_URL } from '@src/lib/global-variables'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { getHours } from 'date-fns'
import { FC } from 'react'
import Column from '@containers/Setting/components/Contacts/taskboard/column'
import { DragDropContext } from 'react-beautiful-dnd'
import styles from './global-kanban-board.module.scss'
import { getParams } from '../../utils/global-kanban.utils'
import { DailyKanbanBoardProps } from '../../types/global-kanban-board.type'
import { generateDailyColumns } from '../../utils/generate-columns.utils'

const DailyKanbanBoard: FC<DailyKanbanBoardProps> = ({ values }) => {
  const fetchTasks = async () => {
    const {dateRange, assignedToMe} = values;
    const params = getParams({ dateRange, assignedToMe })

    const response = await axios.get(`${REACT_APP_API_URL}/tasks`, { params })

    const data = response.data.data
    const newColumns = [...generateDailyColumns(values.dateRange)]

    for (const task of data) {
      const columnIndex = getHours(task.time_created) - getHours(values.dateRange[0]) - 1
      if(columnIndex >= 0){ 
        newColumns[columnIndex].tasks.push(task)
      }
    }

    return newColumns
  }

  const {data: columns} = useQuery({
    queryKey: [GLOBAL_QUERIES.TASKSERVICE.GET_TODAY, values],
    queryFn: () => fetchTasks(),
    initialData: generateDailyColumns(values.dateRange)
  })

  return (
    <DragDropContext onDragEnd={() => {}}>
      <div className={styles.taskBoard}>
        {columns.map((column, i) => (
          <Column
            id={i}
            key={column.title}
            columnName={column.title}
            columnColor={'#06b6d4'}
            tasks={column.tasks}
            isAssigned={false}
            isArchived={false}
            isUnassigned={false}
            filterByStaff={[]}
            filterByType={[]}
            filterByContact={[]}
            step={i + 1}
            isAutomationColumn={false}
            automationAmount={0}
          />
        ))}
      </div>
    </DragDropContext>
  )
}

export default DailyKanbanBoard
