import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { RTLProps } from '../../../../../shared/prop-types/ReducerProps';
import { Checkbox } from '@mui/material';
import { checkboxStyles } from './styles';

const rows = [
  {
    id: 'invoiceNo',
    disablePadding: true,
    label: 'Invoice No',
  },
  {
    id: 'invoice_to',
    disablePadding: false,
    label: 'Invoice To',
  },
  {
    id: 'cover_to',
    disablePadding: false,
    label: 'Properties Due',
  },
  {
    id: 'cover_from',
    disablePadding: false,
    label: 'Invoice Date',
  },
  {
    id: 'amount',
    disablePadding: false,
    label: 'Amount',
  },
  {
    id: 'status',
    disablePadding: false,
    label: 'Status',
  },

  {
    id: 'option',
    disablePadding: false,
    label: 'Option',
  },
];

class SettingTableHead extends PureComponent {
  static propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    rtl: RTLProps.isRequired,
    handleSelectAll: PropTypes.func,
    allChecked: PropTypes.bool,
  };

  createSortHandler = (property) => (event) => {
    const { onRequestSort } = this.props;
    onRequestSort(event, property);
  };

  render() {
    const { order, orderBy, rtl, handleSelectAll, allChecked } = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell>
            <div style={checkboxStyles.checkboxWrapper}>
              <Checkbox
                style={checkboxStyles.checkbox}
                onChange={handleSelectAll}
                checked={allChecked}
              />
              <label style={checkboxStyles.label} className="material-table__sort-label">
                Select all
              </label>
            </div>
          </TableCell>
          {rows.map(
            (row) => (
              <TableCell
                className="material-table__cell material-table__cell--sort material-table__cell-right"
                key={row.id}
                align={rtl.direction === 'rtl' ? 'right' : 'left'}
                padding={row.disablePadding ? 'none' : 'normal'}
                sortDirection={orderBy === row.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === row.id}
                  direction={order}
                  onClick={this.createSortHandler(row.id)}
                  className="material-table__sort-label"
                  dir="ltr"
                >
                  {row.label}
                </TableSortLabel>
              </TableCell>
            ),
            this,
          )}
        </TableRow>
      </TableHead>
    );
  }
}

export default connect((state) => ({
  rtl: state.rtl,
}))(SettingTableHead);
