import { useMutation } from "@tanstack/react-query";
import { PaymentTrackingService } from "../../../services/payment-tracking.service";
import { CreatePaymentTrackingData, PaymentTracking } from "../../../types/payment-tracking.type";
import { Params } from "@src/types/service.type";
import { DeepPartial } from "react-hook-form";

export const useCreatePaymentTrackingMutation = () => {

  const createPaymentTracking = async (data: CreatePaymentTrackingData) => {
    const paymentTrackingService = new PaymentTrackingService()
    return paymentTrackingService.createPaymentTracking(data)
  }

  const mutation = useMutation(createPaymentTracking);
  return mutation;
}

export const useUpdatePaymentTrackingMutation = () => {

  const updatePaymentTracking = async (id: number, data: DeepPartial<PaymentTracking>, params?: Params) => {
    const paymentTrackingService = new PaymentTrackingService()
    return paymentTrackingService.updatePaymentTracking(id, data, params)
  }

  const mutation = useMutation({
    mutationFn: ({ id, data, params }: { id, data: DeepPartial<PaymentTracking>, params?: Params }) => updatePaymentTracking(id, data, params)
  })

  return mutation;
}