import FormCheckbox from '@components/inputs/form-checkbox/form-checkbox.component'
import FormFileSelect from '@components/inputs/form-file-select/form-file-select.component'
import FormInput from '@components/inputs/form-input/form-input.component'
import FormSelect from '@components/inputs/form-select/form-select.component'
import { Controller, useForm, useWatch } from 'react-hook-form'
import styles from './send-email-form.module.scss'
import cls from 'classnames'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import { TextEditor } from '@src/shared/components/TextEditor/TextEditor'
import { FC, useEffect } from 'react'
import { SEND_TO_DEFAULT_OPTIONS } from '@containers/Communication/const/communication.const'
import { CommunicationOptionType } from '@containers/Communication/types/communication.type'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { sendEmailSchema } from '@containers/Communication/schemas/communication.schemas'
import { joiResolver } from '@hookform/resolvers/joi'
import { SEND_EMAIL_FORM_DEFAULT_VALUES, SEND_EMAIL_FORM_EDITOR_SETTINGS } from './send-email-form-editor-setting'
import { SendEmailFormProps, SendEmailFormValues } from '@containers/Communication/types/email.type'

const SendEmailForm: FC<SendEmailFormProps> = ({
  sendToOptions,
  recipientOptions,
  templateOptions,
  loading = false,
  onSendToChange,
  onSubmit,
  onCancelClick,
}) => {
  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors },
  } = useForm<SendEmailFormValues>({
    defaultValues: SEND_EMAIL_FORM_DEFAULT_VALUES,
    resolver: joiResolver(sendEmailSchema),
  })

  const isCC = useWatch({ control, name: 'isCC' })
  const isBCC = useWatch({ control, name: 'isBCC' })

  const mainRecipients = useWatch({ control, name: 'mainRecipients' }) || []
  const ccRecipients = useWatch({ control, name: 'ccRecipients' }) || []
  const bccRecipients = useWatch({ control, name: 'bccRecipients' }) || []

  const template = useWatch({ control, name: 'template' })
  const sendTo = useWatch({ control, name: 'sendTo' })

  useEffect(() => {
    if (!template) return
    setValue('body', template.value)
  }, [template])

  useEffect(() => {
    if (!sendTo || !onSendToChange) return
    setValue('mainRecipients', [])
    setValue('ccRecipients', [])
    setValue('bccRecipients', [])
    setValue('inlineRecepient', null)
    if (sendTo.value === CommunicationOptionType.Other) {
      setValue('isCC', false)
      setValue('isBCC', false)
    }
    onSendToChange(sendTo)
  }, [sendTo])

  useEffect(() => {
    if (isCC) return
    setValue('ccRecipients', [])
  }, [isCC])

  useEffect(() => {
    if (isBCC) return
    setValue('bccRecipients', [])
  }, [isBCC])

  const innerSubmit = (data: SendEmailFormValues) => {
    onSubmit({
      mainRecipients:
        data.sendTo.value === CommunicationOptionType.Other
          ? [{ emailAddress: { address: data.inlineRecepient as string } }]
          : data.mainRecipients?.map((o) => ({
              emailAddress: { address: JSON.parse(o.value).email as string },
            })) || [],
      ccRecipients:
        data.ccRecipients?.map((o) => ({
          emailAddress: { address: JSON.parse(o.value).email as string },
        })) || [],
      bccRecipients:
        data.bccRecipients?.map((o) => ({
          emailAddress: { address: JSON.parse(o.value).email as string },
        })) || [],
      subject: data.subject,
      body: data.body,
      attachments: data.attachments || [],
      sendTo: data.sendTo,
    })
  }

  const transformedRecipientOptions = recipientOptions.map((o) => ({
    ...o,
    value: JSON.stringify(o.value),
  }))

  const mainRecipientsOptions = transformedRecipientOptions.filter(
    (o) => ![...ccRecipients, ...bccRecipients].find((r) => r.value === o.value),
  )
  const ccRecipientsOptions = transformedRecipientOptions.filter(
    (o) => ![...mainRecipients, ...bccRecipients].find((r) => r.value === o.value),
  )
  const bccRecipientsOptions = transformedRecipientOptions.filter(
    (o) => ![...mainRecipients, ...ccRecipients].find((r) => r.value === o.value),
  )

  return (
    <form className={styles.formWrapper} onSubmit={handleSubmit(innerSubmit)}>
      <Header>Send Email</Header>
      <div className={styles.row}>
        <FormSelect
          control={control}
          options={[...sendToOptions, ...SEND_TO_DEFAULT_OPTIONS]}
          name="sendTo"
          style="outlined"
          required
          label="Send To"
        />
        <FormSelect
          control={control}
          name="template"
          style="outlined"
          options={templateOptions}
          required
          label="Select Template"
        />
      </div>
      {sendTo?.value !== CommunicationOptionType.Other ? (
        <>
          <div className={cls(styles.row, styles.bottom)}>
            <FormSelect
              control={control}
              name="mainRecipients"
              style="outlined"
              required
              label="Select"
              isMulti
              options={mainRecipientsOptions}
            />
            <div className={styles.checkboxWrapper}>
              <FormCheckbox label="CC" {...register('isCC')} />
              <FormCheckbox label="BCC" {...register('isBCC')} />
            </div>
          </div>

          {isCC ? (
            <FormSelect
              control={control}
              name="ccRecipients"
              style="outlined"
              required
              label="CC"
              isMulti
              options={ccRecipientsOptions}
            />
          ) : null}
          {isBCC ? (
            <FormSelect
              control={control}
              name="bccRecipients"
              style="outlined"
              required
              label="BCC"
              isMulti
              options={bccRecipientsOptions}
            />
          ) : null}
        </>
      ) : null}

      {sendTo?.value === CommunicationOptionType.Other ? (
        <FormInput
          inputStyle="outlined"
          label="Recipient"
          required
          error={errors.inlineRecepient?.message}
          {...register('inlineRecepient')}
        />
      ) : null}
      <FormInput
        inputStyle="outlined"
        label="Subject"
        required
        error={errors.subject?.message}
        {...register('subject')}
      />
      <Controller
        control={control}
        name="body"
        render={({ field: { value, onChange } }) => (
          <div className={styles.editorWrapper}>
            <label className={styles.labelWrapper}>
              <label className={styles.label}>
                Your message <span className={styles.error}>*</span>
              </label>
              {errors.body?.message ? (
                <label className={styles.error}>{errors.body?.message}</label>
              ) : null}
            </label>
            <TextEditor
              value={value}
              onEditorChange={onChange}
              initOptions={SEND_EMAIL_FORM_EDITOR_SETTINGS}
            />
          </div>
        )}
      />
      <Controller
        control={control}
        name="attachments"
        render={({ field: { value, onChange } }) => (
          <FormFileSelect
            label="Attach File"
            required
            error={`(10MB Limit) ${errors.attachments?.message || ''}`}
            value={value}
            onChange={onChange}
          />
        )}
      />
      <div className={cls(styles.row, styles.end)}>
        <SubmitButton buttonType="button" buttonStyle="secondary" onClick={onCancelClick}>
          Cancel
        </SubmitButton>
        <SubmitButton buttonType="submit" loading={loading}>
          Send Email
        </SubmitButton>
      </div>
    </form>
  )
}

export default SendEmailForm
