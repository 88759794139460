import Section from '@components/section/section.component'
import { Header, SubHeader } from '@containers/Recovery/Wip/components/UI/text/text.component'
import styles from './wip-cost-list-page.module.scss'
import { useMainStyleContext } from '@containers/App/contexts/main-style.context'
import { useEffect } from 'react'
import { useGetWipCostList } from '../../hooks/get-wip-cost-list.hook'
import LoaderWrapper from '@components/loader/loader-wrapper.component'
import WipCostList from '../../components/lists/wip-cost-list/wip-cost-list.component'

const WipCostListPage = () => {
  const { data: wipCostList, isLoading } = useGetWipCostList({ join: 'list', sort: 'state,DESC' })
  const { setStyle } = useMainStyleContext()

  useEffect(() => {
    setStyle({ backgroundColor: '#f9fafb' })
    return () => {
      setStyle({ backgroundColor: 'white' })
    }
  }, [])

  return (
    <>
      <Header mb={4}>Wip Cost List</Header>
      <Section className={styles.sectionWrapper}>
        {isLoading || !wipCostList ? <LoaderWrapper /> : <WipCostList wipCostList={wipCostList.data} />}
      </Section>
    </>
  )
}

export default WipCostListPage
