import { CreateLandlordContact, LandlordContact } from '@containers/Sales/Properties/types/property.type'
import { REACT_APP_API_URL } from '@src/lib/global-variables'
import { PaginationResponse } from '@src/types/service.type'
import axios from 'axios'

export class LandlordContactService {

  async getLandlordContacts(landlordId: number): Promise<PaginationResponse<LandlordContact[]>> {
    const res = await axios.get<PaginationResponse<LandlordContact[]>>(`${REACT_APP_API_URL}/landlords/${landlordId}/contacts`)
    return res.data
  }

  async getLandlordContact(landlordId: number, contactId: number): Promise<LandlordContact> {
    const res = await axios.get<LandlordContact>(`${REACT_APP_API_URL}/landlords/${landlordId}/contacts/${contactId}`)
    return res.data
  }

  async createLandlordContact(landlordId: number, data: CreateLandlordContact): Promise<LandlordContact> {
    const res = await axios.post<LandlordContact>(`${REACT_APP_API_URL}/landlords/${landlordId}/contacts`, data)
    return res.data
  }

  async updateLandlordContact(landlordId: number, contactId: number, data: CreateLandlordContact): Promise<LandlordContact> {
    const res = await axios.patch<LandlordContact>(`${REACT_APP_API_URL}/landlords/${landlordId}/contacts/${contactId}`, data)
    return res.data
  }

  async deleteLandlordContact(landlordId: number, contactId: number): Promise<LandlordContact> {
    const res = await axios.delete(`${REACT_APP_API_URL}/landlords/${landlordId}/contacts/${contactId}`)
    return res.data
  }
}

const landlordContactServiceInstance = new LandlordContactService()
export default landlordContactServiceInstance