import FormSelect from '@components/inputs/form-select/form-select.component'
import { SelectOption } from '@src/types/component-types'
import { Controller, useForm, useWatch } from 'react-hook-form'
import styles from './send-report-form.module.scss'
import { Wip } from '@containers/Recovery/Wip/types/wip.type'
import { FC, useEffect, useMemo } from 'react'
import { validateEmail } from '@containers/Setting/components/Contacts/taskboard/utils'
import { TextEditor } from '@src/shared/components/TextEditor/TextEditor'
import { SEND_EMAIL_FORM_EDITOR_SETTINGS } from '../send-email-form/send-email-form-editor-setting'
import { ReportService } from '@containers/Communication/utils/report.service'
import { useUserContext } from '@src/context/user-context'
import { AssignedStaff } from '@containers/Setting/components/Contacts/taskboard/types'
import FormCheckbox from '@components/inputs/form-checkbox/form-checkbox.component'
import { useSendMessageMutation } from '@containers/Inbox/hooks/mutations/send-message.hook'
import { toast } from 'react-toastify'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { GraphApiMessage } from '@containers/Inbox/types/graph-api-mail.type'
import { ApiReportService } from '@containers/Communication/types/api-report.service'

interface SendReportFormProps {
  wip: Wip
  templateOptions: SelectOption[]
  onCancelClick?: () => void
  onSuccessfulSubmit?: (email: GraphApiMessage) => void
}

interface SendReportFormValues {
  template: SelectOption
  debtor: SelectOption<number> | null
  clientContact: SelectOption<number>
  body: string
  isTemplateShown: boolean
}

const SendReportForm: FC<SendReportFormProps> = ({
  wip,
  templateOptions,
  onSuccessfulSubmit,
  onCancelClick,
}) => {
  const { user } = useUserContext()
  const { mutateAsync, isLoading } = useSendMessageMutation()
  const {
    control,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SendReportFormValues>()

  const template = useWatch({ control, name: 'template' })
  const debtor = useWatch({ control, name: 'debtor' })
  const isTemplateShown = useWatch({ control, name: 'isTemplateShown' })

  const clientContactOptions: SelectOption<number>[] =
    useMemo(
      () =>
        wip.company.contacts
          ?.filter((contact) => validateEmail(contact.comm_email))
          .map((contact) => ({
            label: `${contact.first_name} ${contact.last_name} - ${contact.comm_email}`,
            value: contact.id,
          })),
      [wip],
    ) || []

  const debtorOptions: SelectOption<number>[] = useMemo(
    () =>
      wip.debtors.map((debtor) => ({
        label: `${debtor.name_first} ${debtor.name_last}`,
        value: debtor.id,
      })),
    [wip],
  )

  useEffect(() => {
    if (!template) return
    const reportService = new ReportService(wip, user as unknown as AssignedStaff)

    setValue(
      'body',
      isTemplateShown
        ? template.value
        : reportService.formatReport(
            template.value,
            wip.debtors.find((d) => d.id === debtor?.value),
          ),
    )
  }, [template, debtor, isTemplateShown])

  const onSubmit = async (data: SendReportFormValues) => {
    const reportService = new ReportService(wip, user as unknown as AssignedStaff)
    const apiReportService = new ApiReportService()
    const [message, err] = await mutateAsync({
      message: {
        toRecipients: [
          {
            emailAddress: {
              address:
                wip.company.contacts?.find((c) => c.id === data.clientContact.value)?.comm_email ||
                '',
            },
          },
        ],
        subject: 'Report',
        body: {
          contentType: 'html',
          content: reportService.formatReport(
            template.value,
            wip.debtors.find((d) => d.id === data.debtor?.value),
          ),
        },
      },
    })

    await apiReportService.createReport(wip.id, {
      title: 'Report',
      body: reportService.formatReport(
        template.value,
        wip.debtors.find((d) => d.id === data.debtor?.value),
      ),
    })

    if (err || !message) {
      toast.error('Something went wrong')
      console.error(err)
      return
    }

    onSuccessfulSubmit && onSuccessfulSubmit(message)
  }

  return (
    <form className={styles.formWrapper} onSubmit={handleSubmit(onSubmit)}>
      <FormSelect
        label="Report Template"
        control={control}
        name="template"
        style="outlined"
        options={templateOptions}
        required
      />
      <div className={styles.row}>
        <FormSelect
          label="Debtor"
          control={control}
          name="debtor"
          style="outlined"
          options={debtorOptions}
          required
        />
        <FormSelect
          label="Client Contact"
          control={control}
          name="clientContact"
          style="outlined"
          options={clientContactOptions}
          required
        />
      </div>
      <Controller
        control={control}
        name="body"
        render={({ field: { value, onChange } }) => (
          <div className={styles.editorWrapper}>
            <div className={styles.row}>
              <label className={styles.labelWrapper}>
                <label className={styles.label}>
                  Your message <span className={styles.error}>*</span>
                </label>
                {errors.body?.message ? (
                  <label className={styles.error}>{errors.body?.message}</label>
                ) : null}
              </label>
              <FormCheckbox label="Show Template" {...register('isTemplateShown')} />
            </div>
            <TextEditor
              value={value}
              onEditorChange={onChange}
              initOptions={{ ...SEND_EMAIL_FORM_EDITOR_SETTINGS, height: 320 }}
            />
          </div>
        )}
      />
      <div className={styles.actionWrapper}>
        <SubmitButton buttonStyle="secondary" buttonType="button" onClick={onCancelClick}>
          Cancel
        </SubmitButton>
        <SubmitButton buttonType="submit" loading={isLoading}>
          Send Report
        </SubmitButton>
      </div>
    </form>
  )
}

export default SendReportForm
