import { ChangeEvent, FC, useEffect, useRef, useState } from 'react'
import styles from './from-file-select.module.scss'
import { toast } from 'react-toastify'
import CloseIcon from '@src/icons/close'

interface FormFileSelectProps {
  fileLimitInMb?: number
  onChange?: (files: File[]) => void
  value?: File[]
  label?: string
  required?: boolean
  error?: string
  accept?: string
}

const FormFileSelect: FC<FormFileSelectProps> = ({
  fileLimitInMb = 10,
  onChange,
  label,
  required = false,
  error,
  value,
  accept,
}) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([])
  const ref = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (value === selectedFiles || !value) return
    setSelectedFiles(value)
  }, [value])

  const handleFileSelectClick = () => {
    if (!ref.current) return
    ref.current.click()
  }

  const handleFilesAdd = (oldFiles: File[], newFiles: File[]) => {
    const filteredOldFiles = oldFiles.filter((oldFile) => {
      return !newFiles.some(
        (newFile) => newFile.name === oldFile.name && newFile.size === oldFile.size,
      )
    })

    return [...filteredOldFiles, ...newFiles]
  }

  const handleFilterItems = (files: File[], index: number) => {
    const newFiles = files.filter((_, i) => i !== index)
    return newFiles
  }

  const handleFileSelect = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return

    const files = e.target.files

    for (const file of files) {
      if (file.size > fileLimitInMb * 1024 * 1024) {
        toast.warning(`File size should be less than ${fileLimitInMb}MB`)
        return
      }
    }

    setSelectedFiles((selectedFiles) => handleFilesAdd(selectedFiles, Array.from(files)))

    onChange && onChange(handleFilesAdd(selectedFiles, Array.from(files)))
  }

  const handleRemoveFile = (index: number) => {
    setSelectedFiles((selectedFiles) => handleFilterItems(selectedFiles, index))
    onChange && onChange(handleFilterItems(selectedFiles, index))
  }

  return (
    <div className={styles.wrapper}>
      <div>
        {label ? (
          <label className={styles.labelWrapper}>
            <label className={styles.label}>
              {label} {required ? <span className={styles.error}>*</span> : null}
            </label>
            {error ? <label className={styles.error}>{error}</label> : null}
          </label>
        ) : null}
        <div className={styles.fileInputWrapper}>
          <button type="button" className={styles.selectFileButton} onClick={handleFileSelectClick}>
            Choose File
          </button>
          <div className={styles.selectFileLabelWrapper}>
            <span className={styles.selectFileLabel}>
              {!selectedFiles.length ? 'No file chosen' : `${selectedFiles.length} file chosen`}
            </span>
          </div>
        </div>
      </div>
      <div className={styles.selectedFileList}>
        {selectedFiles.map((file, index) => (
          <div className={styles.selectedItemWrapper} key={index}>
            <span className={styles.selectedItemName}>{file.name}</span>
            <button className={styles.removeSelectedItemButton} type="button">
              <CloseIcon onClick={() => handleRemoveFile(index)} />
            </button>
          </div>
        ))}
      </div>
      <input
        className={styles.hiddenFileInput}
        onChange={handleFileSelect}
        accept={accept}
        ref={ref}
        type="file"
        multiple
      />
    </div>
  )
}

export default FormFileSelect
