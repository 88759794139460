import Section from '@components/section/section.component'
import SmsTableWipFilter from '@containers/Communication/components/tables/sms-table/sms-table-with-filter.component'
import { Client } from '@containers/Sales/Client/types/client.type'
import { FC } from 'react'

interface ClientCommunicationSmsPageProps {
  client: Client
}

const ClientCommunicationSmsPage: FC<ClientCommunicationSmsPageProps> = ({ client }) => {
  return (
    <Section>
      <SmsTableWipFilter params={{ 'companies.id': client.id }} />
    </Section>
  )
}

export default ClientCommunicationSmsPage
