import { FC } from 'react'
import { Link } from 'react-router-dom'
import styles from './nav-bar.module.scss'
import cls from 'classnames'

interface NavBarProps {
  options: {
    label: string
    url: string
  }[]
  activeUrl: string
  className?: string
}

const NavBar: FC<NavBarProps> = ({ options, activeUrl, className }) => {
  return (
    <nav className={cls(className, styles.navBarWrapper)}>
      <ul className={styles.navBarList}>
        {options.map((option) => (
          <li
            className={cls(styles.navBarItem, {
              [styles.active]: activeUrl.includes(option.url),
            })}
            key={option.url + option.label}
          >
            <Link className={styles.navBarLink} to={option.url}>
              {option.label}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default NavBar
