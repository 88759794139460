import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import TaskTable from './task-table.component'

const overdueTaskPrams = {
  sort: 'time_created,DESC',
  s: JSON.stringify({ due_date: { $lt: new Date() }, is_archived: { $eq: false } }),
}

const OverdueTaskTable = () => {
  return <TaskTable queryKey={GLOBAL_QUERIES.TASKSERVICE.GET_OVERDUE} params={overdueTaskPrams} />
}

export default OverdueTaskTable
