import { useForm } from 'react-hook-form'
import styles from './add-fee-form.module.scss'
import { FC, useMemo } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import { toast } from 'react-toastify'
import FormSelect from '@components/inputs/form-select/form-select.component'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { AddFeeFormProps, AddFeeFormValues } from '../../../types/claim.type'
import { useAddFeeMutation } from '../../../hooks/mutations/add-fee.hook'
import { getWipQueryKey } from '@containers/Recovery/Wip/utils/wip-query-factory'
import { Wip } from '@containers/Recovery/Wip/types/wip.type'
import { useGetWipCostList } from '@containers/Recovery/WipCost/hooks/get-wip-cost-list.hook'
import { IWipCostList } from '@containers/Recovery/WipCost/types/wip-cost.type'

const AddFeeForm: FC<AddFeeFormProps> = ({ stateOfOrigin = 'NT', wipId }) => {
  const { data: wipCostList } = useGetWipCostList({
    s: JSON.stringify({ short: stateOfOrigin }),
    join: 'list',
  })
  const { mutateAsync, isLoading } = useAddFeeMutation()
  const { control, handleSubmit } = useForm<AddFeeFormValues>({})

  const queryClient = useQueryClient()

  const onSubmit = async (data: AddFeeFormValues) => {
    try {
      const res = await mutateAsync({
        data: {
          stateOfOrigin,
          feeId: Number(data.fee?.value),
          wipId,
        },
      })

      const queryKey = getWipQueryKey(wipId)

      queryClient.setQueryData<Wip>(queryKey, (prevData) =>
        prevData
          ? {
              ...prevData,
              recoverable_costs_total: res.recoverable_costs_total,
              unenforceable_costs_total: res.unenforceable_costs_total,
            }
          : undefined,
      )

      queryClient.invalidateQueries({ queryKey: [GLOBAL_QUERIES.CLAIM_DETAILS_NOTES.GET] })
      queryClient.invalidateQueries({ queryKey: [GLOBAL_QUERIES.RECOVERABLE_COST.GET] })
      toast.success('Fee added')
    } catch {
      toast.error('Something went wrong')
    }
  }

  const transformWipCostListToOptions = (wipCostList?: IWipCostList) => {
    if (!wipCostList) {
      return []
    }
    const list = wipCostList.list
    return list.map((item) => ({
      label: item.name,
      value: item.id,
    }))
  }

  const options = useMemo(
    () => transformWipCostListToOptions(wipCostList?.data[0] || undefined),
    [wipCostList],
  )

  return (
    <form className={styles.wrapper} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.addFeeFieldWrapper}>
        <FormSelect label="List of Feeds" control={control} name="fee" options={options} />
      </div>
      <SubmitButton buttonType="submit" loading={isLoading}>
        Add Fee
      </SubmitButton>
    </form>
  )
}

export default AddFeeForm
