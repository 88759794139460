export const FORWARD_FORM_INITIAL_VALUES = {
  recipient: '',
  comment: ''
}

export const ATTRIBUTES_SUBJECT_DELIMITER = ' - #BMS'

export const ATTRIBUTES_START_KEYWORD = '['

export const ATTRIBUTES_END_KEYWORD = ']'

export const WIP_ATTRIBUTE_KEYWORD = 'RefW'

export const CLIENT_ATTRIBUTE_KEYWORD = 'RefC'
