import { Debtor } from "@containers/Recovery/Wip/_modules/Debtors/types/debtor.type"
import { Wip } from "@containers/Recovery/Wip/types/wip.type"
import { AssignedStaff, TaskProps } from "@containers/Setting/components/Contacts/taskboard/types"
import { Company, Contact } from "@containers/Setting/components/Contacts/types/contact-list.type"
import { BaseEditFetchModalProps, BaseModalProps } from "@src/types/component-types"


export enum CallMadeFrom {
  Wip = 'wip',
  Client = 'client',
}

export enum CallMadeTo {
  Debtor = 'debtor',
  ClientContact = 'clientContact',
}

export interface CallLog {
  id: number
  madeFrom: CallMadeFrom;
  madeTo: CallMadeTo;
  phoneTo: string;

  // relations

  debtor: Debtor;
  debtorId: number;

  clientContact: Contact;
  clientContactId: number;


  wip: Wip;
  wipId: number;

  client: Company;
  clientId: number;

  staffMadeCall: AssignedStaff;
  staffMadeCallId: number;

  // timestamps

  createdAt: Date
  updatedAt: Date
}

export interface CreateCallLogData {
  madeFrom: CallMadeFrom;
  madeTo: CallMadeTo;
  phoneTo: string;
  debtorId?: number;
  clientContactId?: number;
  wipId?: number;
  clientId?: number;
  staffMadeCallId: number;
}


// props


export interface PhoneNumberTableProps {
  debtors?: Debtor[]
  contacts?: Contact[]
  onPhoneNumberClick?: (data: IPhoneNumberTableItem) => void
}

export interface IPhoneNumberTableItem {
  id: number
  name: string
  phone: string
  phoneType: string
  entityType: 'Debtor' | 'Client Contact'
}

export interface PhoneNumberTableItemProps {
  data: IPhoneNumberTableItem
  onPhoneNumberClick?: (data: IPhoneNumberTableItem) => void
}

export interface WipTaskCallModalProps extends BaseModalProps {
  wip: Wip
  task: TaskProps
}


export interface WipMakeCallModalProps extends BaseModalProps {
  wip: Wip
}

export interface CallLogModalProps extends BaseEditFetchModalProps {}