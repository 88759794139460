import { GlobalKanbanBoardHeaderValues } from "../types/global-kanban-board.type"

const getRuleForDateRange = (dateRange: [Date, Date]) => {
  return { time_created: { $gt: dateRange[0], $lt: dateRange[1] } }
}

const getRuleForAssignedToMe = (id: number) => {
  // TODO: implement show assigned to me feature
  return {}
}

const PARAM_RULE = {
  dateRange: getRuleForDateRange,
  assignedToMe: getRuleForAssignedToMe
}

export const getParams = (
  values: Omit<GlobalKanbanBoardHeaderValues, 'mode'>,
) => {
  const paramRules: Record<string, unknown>[] = []

  for (const key in values) {
    if (!Object.prototype.hasOwnProperty.call(values, key)) {
      continue;
    }

    const value = values[key];

    if (value === null) {
      continue;
    }

    const paramRuleFunction = PARAM_RULE[key];
    paramRules.push(paramRuleFunction(value));
  }

  return { s: JSON.stringify(paramRules.reduce((acc, cv) => ({ ...acc, ...cv }), {})) }
}
