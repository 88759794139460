import React from 'react';
import moment from 'moment';

export const RecoveryDetails = ({ setRecoveryDetails, recoveryDetails }) => {
  const onInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setRecoveryDetails((prev) => ({ ...prev, [name]: value }));
  };

  const onDateInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setRecoveryDetails((prev) => ({ ...prev, [name]: new Date(value) }));
  };

  return (
    <>
      <div className="card__title">
        <h3 className="bold-text">Enter Recovery Details</h3>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Arrears From</span>
        <div className="form__form-group-field  d-flex flex-column">
          <input
            name="arrears_from"
            type="date"
            onChange={onDateInputChange}
            value={moment(recoveryDetails?.arrears_from).format('YYYY-MM-DD')}
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">Arrears To</span>
        <div className="form__form-group-field d-flex flex-column">
          <input
            name="arrears_to"
            type="date"
            onChange={onDateInputChange}
            value={moment(recoveryDetails?.arrears_to).format('YYYY-MM-DD')}
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">Arrears</span>
        <div className="form__form-group-field d-flex flex-column">
          <input
            name="arrears"
            type="text"
            placeholder="Arrears"
            onChange={onInputChange}
            value={recoveryDetails?.arrears}
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">Sub Total</span>
        <div className="form__form-group-field d-flex flex-column">
          <input
            name="sub_total"
            type="number"
            value={recoveryDetails?.sub_total}
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">Total Amount of Claim</span>
        <div className="form__form-group-field d-flex flex-column">
          <input
            name="total_amount"
            type="number"
            value={recoveryDetails?.total_amount}
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">Email</span>
        <div className="form__form-group-field d-flex flex-column">
          <input
            name="email"
            type="email"
            placeholder="Email"
            onChange={onInputChange}
            value={recoveryDetails?.email}
          />
        </div>
      </div>

      <div className="form__form-group">
        <span style={{ maxHeight: 'none' }} className="form__form-group-label">
          Please enter any other relevant information here:
        </span>
        <div className="form__form-group-field d-flex flex-column">
          <textarea
            name="information"
            placeholder="Type here"
            value={recoveryDetails?.information}
            onChange={onInputChange}
          />
        </div>
      </div>
    </>
  );
};
