import { WipTemplateMode } from "../types/wip-templates.type";

export const INITAL_PAGINATION_VALUE = {
    page: 0,
    limit: 10
}


export const GET_WIP_TEMPLATES_PARAMS = {
    s: JSON.stringify({ mode: WipTemplateMode.RECOVERY }),
    limit: INITAL_PAGINATION_VALUE.limit,
    page: INITAL_PAGINATION_VALUE.page + 1,
    sort: 'created_at,DESC'
}

export const WIP_TEMPLATE_TABLE_HEAD_OPTIONS = [{ id: 'name', label: 'Name' }, { id: 'options', label: 'Options' }]