import React from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Col,
  UncontrolledTooltip,
} from 'reactstrap';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import SettingTableHead from './SettingTableHead';
import Services from '../../../../../shared/Services.js';
import { withRouter } from 'react-router';
import ModalComponent from '../../../../../shared/components/ModalCustom';
import { Link } from 'react-router-dom';
import { UserContext } from '../../../../../context/user-context';
// const userImg = `${process.env.PUBLIC_URL}/image/user.jpg`;

function getSorting(order, orderBy) {
  if (order === 'desc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
}

class InvoiceList extends React.Component {
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.state = {
      client_id: '',
      order: 'asc',
      orderBy: '',
      selected: new Map([]),
      staffData: [],
      page: 0,
      rowsPerPage: 5,
      errorModal: false,
      errorMessage: '',
      errorType: '',
      st_id: '',
      accessErrorModal: false,
      coverNoteList: [],
    };

    this.services = new Services();
    this.EditStaff = this.EditStaff.bind(this);
    this.getStaff = this.getStaff.bind(this);
  }

  componentDidMount() {
    this.getStaff(localStorage.getItem('client_Id'));
    const {user} = this.context;
    if (user && user.role === 'SUPER') {
    } else {
      this.setState({ accessErrorModal: true });
    }
  }

  getStaff(id) {
    const data = {
      client_id: id,
    };
    this.services.getCoverNotes(data, (res) => {
      if (res.status === 200) {
        const getData = res.data.data.list;
        this.setState({
          coverNoteList: getData,
        });
      } else if (
        res.statusCode === 401 &&
        res.responseType === 'UNAUTHORIZED'
      ) {
        this.setState({ accessErrorModal: true });
      } else {
        this.services.errorToast(res.message);
      }
    });
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      const { data } = this.state;
      const newSelected = new Map();
      data.map((n) => newSelected.set(n.id, true));
      this.setState({ selected: newSelected });
      return;
    }
    this.setState({ selected: new Map([]) });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const newSelected = new Map(selected);
    const value = newSelected.get(id);
    let isActive = true;
    if (value) {
      isActive = false;
    }
    newSelected.set(id, isActive);
    this.setState({ selected: newSelected });

    localStorage.setItem('propertyId', id);
    localStorage.setItem('propertyEdit', 'no');
    localStorage.setItem('propertyAdd', 'no');

    this.props.history.push('/view-properties');
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleDeleteSelected = () => {
    const { data } = this.state;
    let copyData = [...data];
    const { selected } = this.state;

    for (let i = 0; i < [...selected].filter((el) => el[1])?.length; i += 1) {
      copyData = copyData.filter((obj) => obj.id !== selected[i]);
    }

    this.setState({ data: copyData, selected: new Map([]) });
  };

  isSelected = (id) => {
    const { selected } = this.state;
    return !!selected.get(id);
  };

  EditStaff(e, id, cid) {
    const coverNoteId = {
      coverNoteId: id,
      client_Id: cid,
    };
    this.props.history.push('/add-cover-note', coverNoteId);
  }

  DeleteStaff = () => {
    this.setState({
      errorMessage: '',
      errorModal: false,
      errorType: '',
    });
    const data = {
      Prop_ID: this.state.st_id,
      isdel: true,
    };
    this.services.updateSingleProperty(data, (res) => {
      if (res.status === 200) {
        this.getPropertyList();
        this.services.successToast('Property deleted successfully');
        // this.props.history.push("/property-list");
      } else if (
        res.statusCode === 401 &&
        res.responseType === 'UNAUTHORIZED'
      ) {
        this.setState({ accessErrorModal: true });
      } else {
        this.services.errorToast(res.message);
      }
    });
  };

  OpenModal = (e, d) => {
    this.setState({
      st_id: d._id,
      errorMessage: 'Are you sure you want to delete this record? ',
      errorModal: true,
      errorType: 'danger',
    });
  };
  handleChange0 = (selectedOption0, d) => {
    let url = 'Staff/editDebtor';

    const data = {
      st_id: d._id,
      status: [selectedOption0.value],
    };
    this.services.editStaffStatus(data, url, (res) => {
      if (res.status === 200) {
        this.services.successToast('Status update successfully');
        this.getStaff();
      }
    });
  };

  accessErrorModalClose = () => {
    this.setState({ accessErrorModal: false });
    this.props.history.push('/dashboard');
  };

  render() {
    // staffData
    const { order, orderBy, selected, rowsPerPage, page, coverNoteList } =
      this.state;
    // const emptyRows = rowsPerPage - Math.min(rowsPerPage, propertyData.length - (page * rowsPerPage));

    return (
      <div className="container" style={{ height: '100vh' }}>
        <div className="row">
          <Col md={12} lg={12}>
            <div>
              {this.state.errorModal ? (
                <ModalComponent
                  modal={this.state.errorModal}
                  color={this.state.errorType}
                  isCancel={true}
                  message={this.state.errorMessage}
                  onConfirm={(e) => this.DeleteStaff(e)}
                  onClose={() => this.setState({ errorModal: false })}
                />
              ) : null}
              {this.state.accessErrorModal ? (
                <ModalComponent
                  modal={this.state.accessErrorModal}
                  color="danger"
                  isCancel={false}
                  message="You do not have access to this, please contact your manager for assistance"
                  onConfirm={(e) => this.accessErrorModalClose(e)}
                  onClose={(e) => this.accessErrorModalClose(e)}
                />
              ) : null}

              <div>
                <Col md={12}>
                  <h3 className="page-title">Cover Notes</h3>
                </Col>
              </div>
              <Card>
                <CardBody>
                  <div className="card__title"></div>
                  <div
                    className="material-table__toolbar-wrap"
                    style={{ right: '11px' }}
                  >
                    <Link className="btn btn-secondary" to="/add-cover-note">
                      Add
                    </Link>
                  </div>
                  <div className="material-table__wrap">
                    <Table className="material-table">
                      <SettingTableHead
                        numSelected={
                          [...selected].filter((el) => el[1])?.length
                        }
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={this.handleSelectAllClick}
                        onRequestSort={this.handleRequestSort}
                        rowCount={coverNoteList?.length}
                      />
                      <TableBody>
                        {/* .sort(getSorting(order, orderBy))
                        .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage) */}

                        {coverNoteList
                          ?.sort(getSorting(order, orderBy))
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage,
                          )
                          .map((d, i) => {
                            const isSelected = this.isSelected(d.id);

                            return (
                              <TableRow
                                className="material-table__row"
                                role="checkbox"
                                aria-checked={isSelected}
                                tabIndex={-1}
                                key={i}
                                selected={isSelected}
                              >
                                <TableCell className="material-table__cell material-table__cell-right">
                                  {d.client_id}
                                </TableCell>

                                <TableCell className="material-table__cell material-table__cell-right">
                                  {d.agent_nm}
                                </TableCell>

                                {/* <TableCell className="material-table__cell material-table__cell-right"  >
                                  {d.agent_phone}
                                </TableCell> */}

                                {/* <TableCell className="material-table__cell material-table__cell-right"  >
                                  {d.fax ? d.fax : '-'}
                                </TableCell> */}

                                <TableCell className="material-table__cell material-table__cell-right">
                                  {d.cover_to}
                                </TableCell>

                                <TableCell className="material-table__cell material-table__cell-right">
                                  {d.prop_suburb}
                                </TableCell>

                                <TableCell className="material-table__cell material-table__cell-right">
                                  {d.prop_state}
                                </TableCell>

                                <TableCell className="material-table__cell material-table__cell-right">
                                  {d.prop_post_code}
                                </TableCell>

                                {/* <TableCell className="material-table__cell material-table__cell-right" >
                                  {d.cover_from}
                                </TableCell> */}

                                <TableCell className="material-table__cell material-table__cell-right">
                                  {/* <ButtonGroup className="btn-group--icons" dir="ltr">
                                    <Button outline id="View" onClick={event => this.handleClick(event, d._id)}><span className="lnr lnr-user"></span></Button>
                                    
                                  </ButtonGroup> */}

                                  <ButtonGroup
                                    className="btn-group--icons"
                                    dir="ltr"
                                  >
                                    <Button
                                      outline
                                      id="Edit"
                                      onClick={(e) =>
                                        this.EditStaff(e, d._id, d.client_id)
                                      }
                                    >
                                      <span className="lnr lnr-pencil" />
                                    </Button>
                                  </ButtonGroup>
                                  {/* <ButtonGroup className="btn-group--icons" dir="ltr">
                                      <Button outline id="Delete" onClick={(e)=> this.OpenModal(e,d)} ><span className="lnr lnr-trash"></span></Button>
                                    </ButtonGroup> */}
                                  {/* <UncontrolledTooltip  placement="bottom" target="View">
                                    View
                                  </UncontrolledTooltip> */}
                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target="Edit"
                                  >
                                    Edit
                                  </UncontrolledTooltip>
                                  {/* <UncontrolledTooltip  placement="bottom" target="Delete">
                                    Delete
                                  </UncontrolledTooltip> */}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </div>
                  <TablePagination
                    component="div"
                    className="material-table__pagination"
                    count={coverNoteList?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                    nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                    rowsPerPageOptions={[5, 10, 15]}
                    dir="ltr"
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true,
                    }}
                  />
                </CardBody>
              </Card>
            </div>
          </Col>
        </div>
      </div>
    );
  }
}

export default withRouter(InvoiceList);
