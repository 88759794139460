import React from 'react';
import { Button, ButtonGroup, Card, CardBody, UncontrolledTooltip } from 'reactstrap';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import SettingTableHead from './SettingTableHead';
import Services from '../../../../../shared/Services.js';
import { withRouter } from 'react-router';
import ModalComponent from '../../../../../shared/components/ModalCustom';
import CompaniesService from '../../../../../services/CompaniesService';
import PropertyService from '../../../../../services/PropertyService';
import { TailSpin } from 'react-loader-spinner';
import moment from 'moment';
import { UserContext } from '../../../../../context/user-context';

function getSorting(order, orderBy) {
  if (order === 'desc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
}

class PropertiesList extends React.Component {
  static contextType = UserContext
  constructor(props) {
    super(props);
    this.state = {
      client_id: JSON.parse(localStorage.getItem('client_Id')),
      order: 'asc',
      orderBy: '',
      selected: new Map([]),
      staffData: [],
      propertyData: [],
      page: 0,
      rowsPerPage: 10,
      errorModal: false,
      errorMessage: '',
      errorType: '',
      st_id: '',
      total: 0,
      accessErrorModal: false,
      loader: false,
    };

    this.comp = new CompaniesService();
    this.services = new Services();
    this.prop = new PropertyService();
    this.EditStaff = this.EditStaff.bind(this);
    this.getClient = this.getClient.bind(this);
    this.getPropertyList = this.getPropertyList.bind(this);
  }

  componentDidMount() {
    const {user} = this.context;
    if (user && user.access_level === 'SUPER') {
    } else {
      this.setState({ accessErrorModal: true });
    }
    this.getPropertyList();
  }

  getClient(id) {
    const params = {
      id: id,
      join: ['contacts'],
    };
    this.comp.getCompaniesId(params).then((res) => {
      if (res.status === 200) {
        const getData = res.data;
        this.setState({
          client_id: getData.id,
        });
        this.getPropertyList();
      } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
        this.setState({ accessErrorModal: true });
      } else {
        this.services.errorToast(res.message);
      }
    });
  }

  getPropertyList(page, rowsPerPage) {
    this.setState({ loader: true });
    const params = {
      page: page,
      limit: rowsPerPage,
      sort: 'time_modified,DESC',
      join: 'company',
      filter: `company.id||$eq||${+this.state.client_id}`,
    };
    this.prop.getProperty(params).then((res) => {
      if (res.status === 200) {
        this.setState({ propertyData: res.data.data, total: res.data.total });
        this.setState({ loader: false });
      } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
        this.setState({ accessErrorModal: true });
        this.setState({ loader: false });
      }
    });
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      const { data } = this.state;
      const newSelected = new Map();
      data.map((n) => newSelected.set(n.id, true));
      this.setState({ selected: newSelected });
      return;
    }
    this.setState({ selected: new Map([]) });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const newSelected = new Map(selected);
    const value = newSelected.get(id);
    let isActive = true;
    if (value) {
      isActive = false;
    }
    newSelected.set(id, isActive);
    this.setState({ selected: newSelected });

    localStorage.setItem('propertyId', id);
    localStorage.setItem('propertyEdit', 'no');
    localStorage.setItem('propertyAdd', 'no');

    this.props.history.push('/view-properties');
  };

  handleChangePage = (event, page) => {
    this.setState({ page: page });
    this.getPropertyList(page + 1);
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
    this.setState({ page: 0 });
    this.getPropertyList(0, event.target.value);
  };

  handleDeleteSelected = () => {
    const { data } = this.state;
    let copyData = [...data];
    const { selected } = this.state;

    for (let i = 0; i < [...selected].filter((el) => el[1]).length; i += 1) {
      copyData = copyData.filter((obj) => obj.id !== selected[i]);
    }

    this.setState({ data: copyData, selected: new Map([]) });
  };

  isSelected = (id) => {
    const { selected } = this.state;
    return !!selected.get(id);
  };

  EditStaff(e, id) {
    localStorage.setItem('propertyId', id);
    localStorage.setItem('propertyEdit', 'yes');
    localStorage.setItem('propertyAdd', 'no');
    this.props.history.push('/add-properties');
  }

  deleteProperty = () => {
    this.setState({
      errorMessage: '',
      errorModal: false,
      errorType: '',
    });
    const params = {
      id: this.state.st_id,
    };
    this.prop.deleteProperty(params).then((res) => {
      if (res.status === 200) {
        this.getPropertyList(this.state.page, this.state.rowsPerPage);
        this.services.successToast('Property deleted successfully');
      } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
        this.setState({ accessErrorModal: true });
      } else {
        this.services.errorToast(res.message);
      }
    });
  };

  OpenModal = (e, d) => {
    this.setState({
      st_id: d.id,
      errorMessage: 'Are you sure you want to delete this record? ',
      errorModal: true,
      errorType: 'danger',
    });
  };

  handleChange0 = (selectedOption0, d) => {
    let url = 'Staff/editDebtor';

    const data = {
      st_id: d._id,
      status: [selectedOption0.value],
    };
    this.services.editStaffStatus(data, url, (res) => {
      if (res.status === 200) {
        this.services.successToast('Status update successfully');
        this.getStaff();
      }
    });
  };

  accessErrorModalClose = () => {
    this.setState({ accessErrorModal: false });
    this.props.history.push('/dashboard');
  };

  render() {
    const { order, orderBy, selected, rowsPerPage, page, propertyData, loader } = this.state;
    return (
      <div>
        {this.state.errorModal ? (
          <ModalComponent
            modal={this.state.errorModal}
            color={this.state.errorType}
            isCancel={true}
            message={this.state.errorMessage}
            onConfirm={(e) => this.deleteProperty(e)}
            onClose={() => this.setState({ errorModal: false })}
          />
        ) : null}
        {this.state.accessErrorModal ? (
          <ModalComponent
            modal={this.state.accessErrorModal}
            color="danger"
            isCancel={false}
            message="You do not have access to this, please contact your manager for assistance"
            onConfirm={(e) => this.accessErrorModalClose(e)}
            onClose={(e) => this.accessErrorModalClose(e)}
          />
        ) : null}

        <div>
          <h3 className="page-title">Property List</h3>
        </div>
        {loader ? (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '350px' }}>
            <TailSpin height="75" width="75" color="#f69066" ariaLabel="loading" />
          </div>
        ) : (
          <Card>
            <CardBody>
              <div className="card__title"></div>
              <div className="material-table__wrap">
                <Table className="material-table">
                  <SettingTableHead
                    numSelected={[...selected].filter((el) => el[1]).length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={this.handleSelectAllClick}
                    onRequestSort={this.handleRequestSort}
                    rowCount={this.state.total}
                  />
                  <TableBody>
                    {propertyData.sort(getSorting(order, orderBy)).map((d, i) => {
                      const isSelected = this.isSelected(d.id);
                      const name = d.company?.name;
                      return (
                        <TableRow
                          className="material-table__row"
                          role="checkbox"
                          aria-checked={isSelected}
                          tabIndex={-1}
                          key={i}
                          selected={isSelected}
                        >
                          <TableCell className="material-table__cell material-table__cell-right">
                            {d.id}
                          </TableCell>

                          <TableCell
                            id={'name'}
                            className="material-table__cell material-table__cell-right"
                          >
                            {name}
                          </TableCell>

                          <TableCell className="material-table__cell material-table__cell-right">
                            {d.status}
                          </TableCell>

                          <TableCell className="material-table__cell material-table__cell-right">
                            {d.address_details?.suburb}
                          </TableCell>

                          <TableCell className="material-table__cell material-table__cell-right">
                            {d.address_details?.state}
                          </TableCell>

                          <TableCell className="material-table__cell material-table__cell-right">
                            {d.address_details?.postcode}
                          </TableCell>

                          <TableCell className="material-table__cell material-table__cell-right">
                            {moment(d.company?.renewal_date).format('DD-MM-YY')}
                          </TableCell>

                          <TableCell className="material-table__cell material-table__cell-right">
                            <ButtonGroup className="btn-group--icons" dir="ltr">
                              <Button
                                outline
                                id="View"
                                onClick={(event) => this.handleClick(event, d.id)}
                              >
                                <span className="lnr lnr-user" />
                              </Button>
                            </ButtonGroup>
                            <ButtonGroup className="btn-group--icons" dir="ltr">
                              <Button outline id="Edit" onClick={(e) => this.EditStaff(e, d.id)}>
                                <span className="lnr lnr-pencil" />
                              </Button>
                            </ButtonGroup>
                            <ButtonGroup className="btn-group--icons" dir="ltr">
                              <Button outline id="Delete" onClick={(e) => this.OpenModal(e, d)}>
                                <span className="lnr lnr-trash" />
                              </Button>
                            </ButtonGroup>

                            <UncontrolledTooltip placement="bottom" target="View">
                              View
                            </UncontrolledTooltip>
                            <UncontrolledTooltip placement="bottom" target="Edit">
                              Edit
                            </UncontrolledTooltip>
                            <UncontrolledTooltip placement="bottom" target="Delete">
                              Delete
                            </UncontrolledTooltip>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
              <TablePagination
                component="div"
                className="material-table__pagination"
                count={this.state.total}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
                rowsPerPageOptions={[10, 20, 30]}
                dir="ltr"
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
              />
            </CardBody>
          </Card>
        )}
      </div>
      //  </Col>
    );
  }
}

export default withRouter(PropertiesList);
