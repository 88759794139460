import FormInput from '@components/inputs/form-input/form-input.component'
import FormTextArea from '@components/inputs/form-textarea/form-textarea.component'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import styles from './debtor-legal-defendant-form.module.scss'
import { BaseFormProps } from '@src/types/component-types'
import { DefendantFormValues } from '../../../types/debtor-legal-defendant.type'
import { debtorLegalDefendantSchema } from '../../../schemas/legal.schemas'
import { joiResolver } from '@hookform/resolvers/joi'

const DebtorLegalDefendantForm: FC<BaseFormProps<DefendantFormValues>> = ({
  submitButtonTitle = 'Submit',
  onSuccessSubmit,
  initialValues,
  loading = false,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<DefendantFormValues>({
    defaultValues: initialValues,
    resolver: joiResolver(debtorLegalDefendantSchema),
  })

  useEffect(() => {
    if (!initialValues) return
    reset(initialValues)
  }, [initialValues])

  const onSubmit = (data: DefendantFormValues) => {
    onSuccessSubmit(data)
  }

  return (
    <form className={styles.formWrapper} onSubmit={handleSubmit(onSubmit)}>
      <FormInput
        label="Defendant Name"
        {...register('defendantName')}
        error={errors.defendantName?.message}
      />
      <FormTextArea
        className={styles.textarea}
        label="Defendant Address"
        error={errors.defendantAddress?.message}
        {...register('defendantAddress')}
      />
      <SubmitButton className={styles.submitButton} buttonType="submit" loading={loading}>
        {submitButtonTitle}
      </SubmitButton>
    </form>
  )
}

export default DebtorLegalDefendantForm
