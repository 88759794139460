import { FC } from 'react'
import WipDetailsForm from './wip-details-form.component'
import {
  EditWipDetailsFormProps,
  WipDetailsFormValues,
} from '../../../types/wip-overview-forms.type'
import { useUpdateWipByIdMutation } from '@containers/Recovery/Wip/hooks/mutations/update-wip-by-id.hook'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import { getWipQueryKey } from '@containers/Recovery/Wip/utils/wip-query-factory'
import { Wip } from '@containers/Recovery/Wip/types/wip.type'
import { DeepPartial } from 'react-hook-form'

const EditWipDetailsForm: FC<EditWipDetailsFormProps> = ({ wipId, initialValues }) => {
  const queryClient = useQueryClient()
  const { mutateAsync, isLoading } = useUpdateWipByIdMutation()

  const handleSubmit = async (data: WipDetailsFormValues) => {
    console.log(data)
    const updateData: DeepPartial<Wip> = {
      cover_as_date_log: data.cover_as_date_log,
      date_lodged: data.date_lodged,
      finalize_date: data.finalize_date,
      cover: data.cover_type?.value ? { id: data.cover_type?.value } : null,
      state_of_origin: data.state_of_origin?.value || null,
      // TODO: Implement general_filing
      // general_filing: data.general_filing?.value,
    }

    try {
      const updatedWip = await mutateAsync({
        id: wipId,
        data: updateData,
        params: { fields: Object.keys(updateData).join(','), join: 'cover' },
      })
      queryClient.setQueryData<Wip>(getWipQueryKey(wipId), (prevData) => ({
        ...(prevData as unknown as Wip),
        ...updatedWip,
      }))
      toast.success('Wip Details updated')
    } catch (e) {
      toast.error('Failed to update Wip Details')
      console.error(e)
    }
  }

  return (
    <WipDetailsForm onSubmit={handleSubmit} initialValues={initialValues} loading={isLoading} />
  )
}

export default EditWipDetailsForm
