import { getInvoicePDFActionEnum } from '../actionTypes';

export const getInvoicePDF = (pdfID) => async (dispatch) => {
  dispatch({
    type: getInvoicePDFActionEnum.IS_LOADING_GET_INVOICE_PDF,
  });
  try {
    const image = await fetch(`${process.env.REACT_APP_BMS5_END_POINT}/invoices/pdf?ids=${pdfID}`);
    const imageBlob = await image.blob();
    const imageURL = URL.createObjectURL(imageBlob);

    dispatch({
      type: getInvoicePDFActionEnum.GET_INVOICE_PDF_SUCCESS,
      payload: imageURL,
    });

    return imageURL;
  } catch (error) {
    dispatch({
      type: getInvoicePDFActionEnum.GET_INVOICE_PDF_ERROR,
      payload: error,
    });

    throw error;
  }
};
