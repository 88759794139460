import { Params } from "@src/types/service.type";
import { ApiReportService } from "../types/api-report.service";
import { useQuery } from "@tanstack/react-query";
import { GLOBAL_QUERIES } from "@src/lib/global-queries";

export const useGetReports = (params?: Params) => {

  const getReports = (params?: Params) => {
    const apiReportService = new ApiReportService();
    return apiReportService.getReports(params);
  }

  const query = useQuery({ queryKey: [GLOBAL_QUERIES.REPORTS.GET], queryFn: () => getReports(params) })

  return query;
}