import React, { useState } from 'react';

const RecoveryDetailsCount = ({ register, watch }) => {
  const [inputs, setInputs] = useState({
    rent: '',
    carpet_cleaning: '',
    pest_control: '',
    locks: '',
    general_cleaning: '',
    rubbish_removal: '',
    general_repairs: '',
    letting_adv_fee: '',
    legal_cost_sheriff_fee: '',
    replacement: '',
    watter_usage: '',
    insurance_excess: '',
    total: '',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
      total:
        name === 'insurance_excess'
          ? Number(value) +
            Number(prevInputs.rent) +
            Number(prevInputs.carpet_cleaning) +
            Number(prevInputs.pest_control) +
            Number(prevInputs.locks) +
            Number(prevInputs.general_cleaning) +
            Number(prevInputs.rubbish_removal) +
            Number(prevInputs.general_repairs) +
            Number(prevInputs.letting_adv_fee) +
            Number(prevInputs.replacement) +
            Number(prevInputs.watter_usage)
          : prevInputs.total,
    }));
  };
  return (
    <div style={{ marginTop: '50px' }}>
      <label>
        <span className="form__form-group-label">Rent From</span>
        <input type="date" {...register(`recovery_details.rent_from`)} />
      </label>
      <label>
        <span className="form__form-group-label">Rent To</span>
        <input type="date" {...register(`recovery_details.rent_to`)} />
      </label>
      <label>
        <span className="form__form-group-label">Rent</span>
        <input
          type="text"
          name="rent"
          placeholder={0}
          onChange={handleInputChange}
          {...register(`recovery_details.rent`)}
        />
      </label>
      <label>
        <span className="form__form-group-label">Carpet Cleaning</span>
        <input
          type="text"
          name="carpet_cleaning"
          placeholder={0}
          onChange={handleInputChange}
          {...register(`recovery_details.carpet_cleaning`)}
        />
      </label>
      <label>
        <span className="form__form-group-label">Pest Control</span>
        <input
          type="text"
          name="pest_control"
          placeholder={0}
          onChange={handleInputChange}
          {...register(`recovery_details.pest_control`)}
        />
      </label>
      <label>
        <span className="form__form-group-label">Locks</span>
        <input
          type="text"
          name="locks"
          placeholder={0}
          onChange={handleInputChange}
          {...register(`recovery_details.locks`)}
        />
      </label>
      <label>
        <span className="form__form-group-label">General Cleaning</span>
        <input
          type="text"
          name="general_cleaning"
          placeholder={0}
          onChange={handleInputChange}
          {...register(`recovery_details.general_cleaning`)}
        />
      </label>
      <label>
        <span className="form__form-group-label">Rubbish Removal</span>
        <input
          type="text"
          name="rubbish_removal"
          placeholder={0}
          onChange={handleInputChange}
          {...register(`recovery_details.rubbish_removal`)}
        />
      </label>
      <label>
        <span className="form__form-group-label">General Repairs</span>
        <input
          type="text"
          name="general_repairs"
          placeholder={0}
          onChange={handleInputChange}
          {...register(`recovery_details.general_repairs`)}
        />
      </label>
      <label>
        <span className="form__form-group-label">Letting Fee/Advertising</span>
        <input
          type="text"
          name="letting_adv_fee"
          placeholder={0}
          onChange={handleInputChange}
          {...register(`recovery_details.letting_adv_fee`)}
        />
      </label>
      <label>
        <span className="form__form-group-label">Legal Costs/Sheriff Fees</span>
        <input
          type="text"
          name="legal_cost_sheriff_fee"
          placeholder={0}
          onChange={handleInputChange}
          {...register(`recovery_details.legal_cost_sheriff_fee`)}
        />
      </label>
      <label>
        <span className="form__form-group-label">Replacements</span>
        <input
          type="text"
          name="replacement"
          placeholder={0}
          onChange={handleInputChange}
          {...register(`recovery_details.replacement`)}
        />
      </label>
      <label>
        <span className="form__form-group-label">Water Usage</span>
        <input
          type="text"
          name="watter_usage"
          placeholder={0}
          onChange={handleInputChange}
          {...register(`recovery_details.watter_usage`)}
        />
      </label>
      <label>
        <span className="form__form-group-label">Insurance Excess</span>
        <input
          type="text"
          name="insurance_excess"
          placeholder={0}
          onChange={handleInputChange}
          {...register(`recovery_details.insurance_excess`)}
        />
      </label>
    </div>
  );
};

export default RecoveryDetailsCount;
