import React from 'react';
import { Button, ButtonToolbar } from 'reactstrap';

export const OwnersCompany = ({ ownersCompany, setOwnersCompany }) => {
  const getOnInputChange = (index) => (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setOwnersCompany((prev) => {
      if (index === 0) {
        return [
          {
            ...prev[index],
            [name]: value,
          },
          ...prev.slice(index + 1),
        ];
      }

      return [
        ...prev.slice(0, index),
        {
          ...prev[index],
          [name]: value,
        },
        ...prev.slice(index + 1),
      ];
    });
  };

  const onTenantAdd = () => {
    setOwnersCompany((prev) => (prev ? [...prev, {}] : [{}]));
  };

  return (
    <>
      {ownersCompany.map((item, index) => (
        <React.Fragment key={index}>
          <div className="card__title">
            <h5 className="bold-text">Owners Company {index + 1}</h5>
          </div>

          <div className="form__form-group">
            <span className="form__form-group-label">Company Full Name</span>
            <div className="form__form-group-field">
              <input
                name="oc_full_name"
                type="text"
                placeholder="Company Full Name"
                onChange={getOnInputChange(index)}
                value={item?.oc_full_name}
              />
            </div>
          </div>

          <div className="form__form-group">
            <span className="form__form-group-label">
              Company Last Name <span style={{ color: '#ff0000' }}>*</span>
            </span>
            <div className="form__form-group-field">
              <input
                name="oc_last_name"
                type="text"
                placeholder="Company Full Name"
                required={true}
                onChange={getOnInputChange(index)}
                value={item?.oc_last_name}
              />
            </div>
          </div>

          <div className="form__form-group">
            <span className="form__form-group-label">Company ABN</span>
            <div className="form__form-group-field">
              <input
                name="oc_abn"
                type="text"
                placeholder="Company ABN"
                onChange={getOnInputChange(index)}
                value={item?.oc_abn}
              />
            </div>
          </div>
        </React.Fragment>
      ))}
      <div className="form__form-group">
        <ButtonToolbar className="form__button-toolbar mb-3">
          <Button
            color="primary"
            className="mb-0"
            type="button"
            onClick={onTenantAdd}
          >
            Add Owners Company
          </Button>
        </ButtonToolbar>
      </div>
    </>
  );
};
