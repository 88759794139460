import SlideModal from '@components/slide-modal'
import { FC } from 'react'
import PhoneNumberTable from '../../tables/phone-number-table/phone-number-table.component'
import styles from './make-call-modal.module.scss'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import {
  CallMadeFrom,
  CallMadeTo,
  IPhoneNumberTableItem,
  WipTaskCallModalProps,
} from '@containers/Communication/types/call.type'
import { CallLogService } from '@containers/Communication/services/call-log.service'
import { useUserContext } from '@src/context/user-context'

const WipTaskMakeCallModal: FC<WipTaskCallModalProps> = ({ wip, task, ...modalProps }) => {
  const { user } = useUserContext()

  const debtors = wip.debtors.filter(({ id }) =>
    task.assigned_debtors.includes(id as unknown as string),
  )

  const handlePhoneNumberClick = async (data: IPhoneNumberTableItem) => {
    const callLogService = new CallLogService()
    await callLogService.createCallLog({
      madeFrom: CallMadeFrom.Wip,
      phoneTo: data.phone,
      madeTo: data.entityType === 'Debtor' ? CallMadeTo.Debtor : CallMadeTo.ClientContact,
      debtorId: data.entityType === 'Debtor' ? data.id : undefined,
      clientContactId: data.entityType === 'Client Contact' ? data.id : undefined,
      wipId: wip.id,
      staffMadeCallId: user!.id,
    })
  }

  return (
    <SlideModal
      {...modalProps}
      layoutStyle={{ backgroundColor: 'black', zIndex: 1000 }}
      className={styles.slideModalWrapper}
    >
      <Header mb={4}>Phone Number List</Header>
      <PhoneNumberTable
        debtors={debtors}
        contacts={wip.company.contacts}
        onPhoneNumberClick={handlePhoneNumberClick}
      />
    </SlideModal>
  )
}

export default WipTaskMakeCallModal
