/* eslint-disable @typescript-eslint/no-unused-vars */
import styles from './task.module.scss'
import { ColumnTaskProps } from './types'
import { PRIORITY_OPTIONS, formatAssigneName } from './utils'
import cls from 'classnames'
import AddUser from 'src/icons/user_add'
import Assignee from 'src/icons/assignee'
import Comment from 'src/icons/comment'
import React, { useEffect } from 'react'
import AssignAvatar from '@components/assign-avatar'
import { useTaskContext } from '@src/context/task-context'
import { useUserContext } from '@src/context/user-context'
import AddAssignees from '@components/add-assignees'
import { useModal } from '@src/hooks/useModal'
import SubTaskIndicator, {
  SubTaskIndicatorStatus,
} from './SubTaskIndicator/sub-task-indicator.component'

// eslint-disable-next-line react/display-name
const Task = React.forwardRef<HTMLDivElement, ColumnTaskProps>(
  (
    {
      comments = 0,
      priority = 'MEDIUM',
      title = '',
      uncompleted_sub_tasks_count,
      sub_tasks_count,
      id,
      onClick = () => null,
      assign_to,
      ...rest
    },
    ref,
  ) => {
    const { staff } = useTaskContext()
    const { user } = useUserContext()

    const [isAssigneeModalOpen, handleOpenModal, handleCloseModal] = useModal(false)

    const handleOpenAssigneeModal = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      e.stopPropagation()
      handleOpenModal()
    }

    const getSubTasksIndicatorStatus = (
      uncompletedSubTasksCount: number,
      subTasksCount: number,
    ): SubTaskIndicatorStatus => {
      if (uncompletedSubTasksCount === subTasksCount) {
        return 'notCompleted'
      }
      if (uncompletedSubTasksCount === 0) {
        return 'fullyCompleted'
      }
      return 'partiallyCompleted'
    }

    const subTasksIndicatorStatus = getSubTasksIndicatorStatus(
      uncompleted_sub_tasks_count,
      sub_tasks_count,
    )

    return (
      <div className={styles.wrapper} {...rest} onClick={onClick} ref={ref}>
        <div className="d-flex justify-content-between">
          <span
            className={cls(styles.text, styles.low, {
              [styles.low]: priority === 'Normal',
              [styles.medium]: priority === 'Medium',
              [styles.high]: priority === 'High',
            })}
          >
            {PRIORITY_OPTIONS[priority] || PRIORITY_OPTIONS.Normal}
          </span>
          {sub_tasks_count > 0 ? <SubTaskIndicator status={subTasksIndicatorStatus} /> : null}
        </div>
        <span>{(title ||= '-')}</span>
        <div className={styles.footerSection}>
          <div>
            <Comment />
            <span>{comments || 0}</span>
          </div>
          <div>
            <span style={{ position: 'relative' }}>
              <AddUser onClick={handleOpenAssigneeModal} />
              <AddAssignees ticketId={id} isOpen={isAssigneeModalOpen} onClose={handleCloseModal} />
            </span>
            <span className={styles.assigneeContainer}>
              {staff
                .filter(({ id }) => assign_to.includes(id))
                .map(({ id, access_level, profile_picture, last_name, first_name }) => {
                  const assigneeName = formatAssigneName({
                    last_name: last_name,
                    first_name: first_name,
                    isAssigned: id === user?.id || false,
                  })

                  return (
                    <AssignAvatar
                      key={id}
                      name={assigneeName}
                      avatarUrl={profile_picture}
                      accessLevel={access_level}
                    />
                  )
                })}
              {staff.filter(({ id }) => assign_to.includes(id)).length === 0 && <Assignee />}
            </span>
          </div>
        </div>
      </div>
    )
  },
)

export default Task
