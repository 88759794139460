import React, { useRef, useState } from 'react'
import { format, startOfWeek, endOfWeek, eachDayOfInterval, add, sub, isSameDay } from 'date-fns'
import styles from './calendar.module.scss'
import ArrowIcon from '@src/icons/arrow'
import classNames from 'classnames'
import { dayMap, isTheSameDate } from './utils'
import { EventProps } from './types'
import Event from './event'

interface CalendarProps {
  /**
   * Avaliable time
   * @default  from: 6, to: 22
   * @description from: 6, to: 22 means that avaliable time is from 6:00 to 22:00
   */
  avaliableTime?: {
    from: number
    to: number
  }

  /**
   * Currently selected date
   * @default new Date()
   */
  selectedDate?: Date

  /**
   * Callback function that is fired when the user clicks on the left arrow
   */
  onLeftArrowClick?: (date: Date) => void

  /**
   * Callback function that is fired when the user clicks on the right arrow
   */
  onRightArrowClick?: (date: Date) => void

  /**
   * Events that are displayed on the calendar
   */
  events: EventProps[]
}

const Calendar = ({
  avaliableTime = {
    from: 6,
    to: 22,
  },
  selectedDate = new Date(),
  onLeftArrowClick = () => null,
  onRightArrowClick = () => null,
  events = [],
}: CalendarProps) => {
  const [currentlySelectedDate, setCurrentlySelectedDate] = useState(selectedDate)
  const [currentDate] = useState(new Date())
  const amountOfRows = avaliableTime.to - avaliableTime.from + 1

  const firstCellRef = useRef<HTMLDivElement | null>(null)

  const nextWeek = () => {
    const nextWeek = add(currentlySelectedDate, { weeks: 1 })
    setCurrentlySelectedDate(new Date(nextWeek))
    onRightArrowClick(nextWeek)
  }

  const prevWeek = () => {
    const prevWeek = sub(currentlySelectedDate, { weeks: 1 })
    setCurrentlySelectedDate(new Date(prevWeek))
    onLeftArrowClick(prevWeek)
  }

  const CalendarHeader = () => {
    const start = startOfWeek(currentlySelectedDate, { weekStartsOn: 1 })
    const end = endOfWeek(currentlySelectedDate, { weekStartsOn: 1 })
    const weekInterval = eachDayOfInterval({
      start,
      end,
    }).map((date) => ({ date, id: format(date, 'dd:mm:yyyy') }))

    return (
      <div className={styles.calendarHeader}>
        <div className={styles.arrow}>
          <ArrowIcon onClick={prevWeek} direction={'left'} />
        </div>
        {weekInterval.map(({ date, id }) => {
          const isToday = isTheSameDate(date, currentDate)

          return (
            <div className={classNames(styles.dateCell, { [styles.today]: isToday })} key={id}>
              <span>{format(date, 'iiiiii').toUpperCase()}</span>
              <span>{format(date, 'dd')}</span>
            </div>
          )
        })}
        <div className={styles.arrow}>
          <ArrowIcon onClick={nextWeek} direction={'right'} />
        </div>
      </div>
    )
  }
  const CalendarSideBar = () => {
    const timeRows = Array.from({ length: amountOfRows }, (_, i) => i + avaliableTime.from)
    return (
      <div
        className={styles.calendarSideBar}
        style={{ gridTemplateRows: `repeat(${amountOfRows}, 80px)` }}
      >
        {timeRows.map((time) => {
          const formattedTime = format(new Date().setHours(time), 'H a')
          return (
            <div className={styles.timeCell} key={time}>
              {formattedTime}
            </div>
          )
        })}
      </div>
    )
  }
  const CalendarBody = () => {
    const cells = amountOfRows * 8
    const eventCells = Array.from({ length: cells }, (_, i) => i + 1)

    return (
      <div
        className={styles.calendarBody}
        style={{ gridTemplateRows: `repeat(${amountOfRows}, 80px)` }}
      >
        {eventCells.map((cell) => {
          const isOdd = cell % 8 === 0
          const lastRowCell = cell > cells - 8

          return (
            <div
              ref={() => {
                if (cell === 1) {
                  firstCellRef.current = document.querySelector(`.${styles.cell}`)
                }
              }}
              className={classNames(styles.cell, {
                [styles.odd]: isOdd,
                [styles.lastRowCell]: lastRowCell,
              })}
              key={cell}
            ></div>
          )
        })}
      </div>
    )
  }

  const renderChildren = () => {
    if (!firstCellRef.current) return null

    return events.map((event, idx) => {
      if (!firstCellRef.current) return null
      const { startTime, endTime } = event

      // We need to understand how many days from the first cell the event starts

      const firstCell = firstCellRef.current.getBoundingClientRect()
      const startDay = format(startTime, 'iiiiii').toUpperCase()
      const leftOffSet = firstCell.left + firstCell.width

      const startHour = Number(format(startTime, 'H'))
      const topOffSet = firstCell.top + firstCell.height
      console.log({ leftOffSet }, firstCell.x, firstCell.y)

      const eventStyle = {
        position: 'absolute',
        left: `${leftOffSet}px`,
        top: `${topOffSet}px`,
        width: `${firstCell.width}px`,
        // Set the height of the event if necessary, for example, if the event is 1 hour long, it will be the same as the cell height
        height: `${firstCell.height}px`,
      } as React.CSSProperties

      return <Event key={idx} {...event} styles={{ ...eventStyle }} />
    })
  }
  return (
    <div className={styles.wrapper}>
      <CalendarHeader />
      <div className={styles.calendarUnite}>
        <CalendarSideBar />
        <CalendarBody />
      </div>
      {renderChildren()}
    </div>
  )
}

export default Calendar
