import { FC, useState } from 'react'
import PaginatedSelectOption from './paginated-select-option.component'
import styles from './paginated-select.module.scss'
import RoundedArrow from '@src/icons/rounded-arrow'
import SelectedOption from './selected-option.component'
import cls from 'classnames'
import { PaginatedSelectProps, SelectSubOptionWithRef } from './pagination-select.type'

const PaginatedSelect: FC<PaginatedSelectProps> = ({
  options,
  selectedSubOptions = [],
  onOptionChange,
  onSelectIsOpenChange,
  onSubOptionChange,
  onReachEnd,
  onSearchChange,
}) => {
  const [isPaginatedSelectOpen, setIsPaginatedSelectOpen] = useState(false)

  const handlePaginatedSelectClick = () => {
    onSelectIsOpenChange && onSelectIsOpenChange(!isPaginatedSelectOpen)
    setIsPaginatedSelectOpen((prev) => !prev)
  }

  const filterOptionsByRef = (options: SelectSubOptionWithRef[], ref: string) => {
    return options.filter((option) => option.ref === ref)
  }

  return (
    <div className={styles.paginatedSelectWrapper}>
      <div className={cls(styles.headerSelectWrapper, { [styles.open]: isPaginatedSelectOpen })}>
        <div className={styles.selectedOptionsWrapper}>
          {selectedSubOptions.length === 0 ? <span>No labels assigned</span> : null}
          {selectedSubOptions.map((selectedSubOption, index) => (
            <SelectedOption
              key={`${selectedSubOption.value}-${index}`}
              option={selectedSubOption}
              onOptionDelete={onSubOptionChange}
            />
          ))}
        </div>
        <div>
          <RoundedArrow
            className={cls(styles.openIcon, styles.pointer)}
            onClick={handlePaginatedSelectClick}
          />
        </div>
      </div>
      {isPaginatedSelectOpen ? (
        <div className={styles.optionsWrapper}>
          {options.map((option, index) => (
            <PaginatedSelectOption
              key={`option-${option.option.value}-${index}`}
              option={option.option}
              subOptions={option.subOptions}
              onSubOptionChange={onSubOptionChange}
              onReachEnd={onReachEnd}
              isLoading={option.loading}
              onSearchChange={onSearchChange}
              selectedSubOptions={filterOptionsByRef(selectedSubOptions, option.option.value)}
            />
          ))}
        </div>
      ) : null}
    </div>
  )
}

export default PaginatedSelect
