import { getInvoiceTemplatesListActionEnum } from '../actionTypes';
import { getInvoiceTemplatesListService } from '../service';

export const getInvoiceTemplatesList = (page) => async (dispatch) => {
  dispatch({
    type: getInvoiceTemplatesListActionEnum.IS_LOADING_GET_INVOICE_TEMPLATES_LIST,
  });
  try {
    const res = await getInvoiceTemplatesListService(page);
    dispatch({
      type: getInvoiceTemplatesListActionEnum.GET_INVOICE_TEMPLATES_LIST_SUCCESS,
      payload: res,
    });

    return res;
  } catch (error) {
    dispatch({
      type: getInvoiceTemplatesListActionEnum.GET_INVOICE_TEMPLATES_LIST_ERROR,
      payload: error,
    });

    throw error;
  }
};
