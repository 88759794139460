import { FC } from 'react'
import PaymentLogSection from './components/sections/payment-log-section/payment-log-section.component'
import PaymentOverviewSection from './components/sections/payment-overview-section/payment-overview-section.component'
import styles from './payment-page.module.scss'
import { PaymentPageProps } from './types/transaction.type'

const PaymentPage: FC<PaymentPageProps> = ({ wip }) => {
  return (
    <div className={styles.wrapper}>
      <PaymentLogSection wipId={wip.id} />
      <PaymentOverviewSection
        totalRecoverableCost={wip.recoverable_costs_total}
        totalClaims={wip.claim_details_total}
        totalPaidToAgent={0}
        totalPayments={0}
      />
    </div>
  )
}

export default PaymentPage
