import { FC } from 'react'
import { DebtorLegal, DebtorLegalTribunal } from '../../../types/debtor-legal.type'
import SubSection from '@containers/Recovery/Wip/components/UI/sub-section/sub-section.component'
import { SubHeader, Text } from '@containers/Recovery/Wip/components/UI/text/text.component'
import TrashIcon from '@src/icons/trash'
import styles from './debtor-tribunal-list.module.scss'
import EditIcon from '@src/icons/edit'
import { useQueryClient } from '@tanstack/react-query'
import { getDebtorLegalByIdQueryKey } from '../../../utils/legal-query-key-factory.utils'
import { toast } from 'react-toastify'
import { BaseListItemPropsWithEdit } from '@src/types/component-types'
import { format } from 'date-fns'
import { useDeleteDebtorLegalTribunalMutation } from '../../../hooks/mutations/crud-debtor-legal-tribunal.hook'

const DebtorLegalTribunalListItem: FC<BaseListItemPropsWithEdit<DebtorLegalTribunal>> = ({
  entity: debtorLegalTribunal,
  onEditClick,
}) => {
  const { mutateAsync } = useDeleteDebtorLegalTribunalMutation()
  const queryClient = useQueryClient()

  const handleDeleteClick = async () => {
    try {
      await mutateAsync({ debtorLegalTribunalId: debtorLegalTribunal.id })
      queryClient.setQueryData<DebtorLegal>(
        getDebtorLegalByIdQueryKey(debtorLegalTribunal.debtorLegalId),
        (prevData) =>
          prevData
            ? {
                ...prevData,
                tribunals: prevData.tribunals.filter((t) => t.id !== debtorLegalTribunal.id),
              }
            : undefined,
      )
    } catch (error) {
      toast.error('Failed to delete tribunal')
      console.error(error)
    }
  }

  return (
    <SubSection className={styles.listItemWrapper} p={4}>
      <div className={styles.listItemContent}>
        <div className={styles.row}>
          <SubHeader>Tribunal Location:</SubHeader>
          <Text>{debtorLegalTribunal.tribunalLocation}</Text>
        </div>
        <div className={styles.row}>
          <div className={styles.row}>
            <SubHeader>Tribunal Order Date:</SubHeader>
            <Text>{format(debtorLegalTribunal.tribunalOrderDate, 'MM/dd/yyyy')}</Text>
          </div>
          <div className={styles.row}>
            <SubHeader>Tribunal CN:</SubHeader>
            <Text>{debtorLegalTribunal.tribunalCaseNumber}</Text>
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <button className={styles.actionButton} onClick={() => onEditClick(debtorLegalTribunal)}>
          <EditIcon />
        </button>
        <button onClick={handleDeleteClick} className={styles.actionButton}>
          <TrashIcon />
        </button>
      </div>
    </SubSection>
  )
}

export default DebtorLegalTribunalListItem
