import { REACT_APP_API_URL, REACT_APP_XOR_ENCRYPTION_KEY } from "@src/lib/global-variables";
import { ATTRIBUTES_END_KEYWORD, ATTRIBUTES_START_KEYWORD, ATTRIBUTES_SUBJECT_DELIMITER, CLIENT_ATTRIBUTE_KEYWORD, WIP_ATTRIBUTE_KEYWORD } from "../../const/view-email-page.const";
import { decryptXOR } from "./xor-encryption.utils";
import axios from "axios";
import { Company } from "@containers/Setting/components/Contacts/types/contact-list.type";
import { AssignedAttributes } from "../../types/attributes.type";

const validateId = (id: string): number | null => {
  const numericId = Number(id)
  if (!Number.isNaN(numericId) && numericId > 0 && Number.isInteger(numericId)) {
    return numericId
  }
  return null
}

const fetchClientsByIds = async (ids: number[]) => {
  const clients = await Promise.allSettled(ids.map(async (id) => {
    const response = await axios.get<{ id: number, name: string }>(`${REACT_APP_API_URL}/companies/${id}`, { params: { select: 'name' } })
    return response.data
  }))
  const fulfilledClients = clients.filter((client) => client.status === 'fulfilled') as PromiseFulfilledResult<Company>[]
  return fulfilledClients.map((client) => client.value)
}

export const addAttributeToSubject = (subject: string, attribute: string) => {
  const parsedSubject = parseSubjectForAttribute(subject)
  const newAttributeString = createAttributeString([...parsedSubject.attributes, attribute])
  return [parsedSubject.subject, ATTRIBUTES_SUBJECT_DELIMITER.slice(1), newAttributeString].join(' ');
}

export const removeAttributeFromSubject = (subject: string, attribute: string) => {
  const parsedSubject = parseSubjectForAttribute(subject)
  const filteredAttributes = parsedSubject.attributes.filter((a) => a !== attribute)
  const newAttributeString = createAttributeString(filteredAttributes)
  return [parsedSubject.subject, ATTRIBUTES_SUBJECT_DELIMITER.slice(1), newAttributeString].join(' ');
}

export const parseSubjectForAttribute = (subject: string) => {
  const [subjectWithoutAttributes, dataAfterDelimiter] = subject.split(ATTRIBUTES_SUBJECT_DELIMITER)

  const stringAfterDelimiter = dataAfterDelimiter || '';

  const attributesStartIndex = stringAfterDelimiter.indexOf(ATTRIBUTES_START_KEYWORD)
  const attributesEndIndex = stringAfterDelimiter.indexOf(ATTRIBUTES_END_KEYWORD)

  const isAttributeArrayAssigned = attributesStartIndex !== -1 && attributesEndIndex !== -1 && attributesStartIndex < attributesEndIndex;

  if (!isAttributeArrayAssigned) {
    const attributes = stringAfterDelimiter.split(' ').filter((a) => a.includes(WIP_ATTRIBUTE_KEYWORD) || a.includes(CLIENT_ATTRIBUTE_KEYWORD))
    return {
      subject: subjectWithoutAttributes,
      attributes
    }
  }

  const stringAttributes = stringAfterDelimiter.slice(attributesStartIndex + 1, attributesEndIndex);

  const parsedAttributes = stringAttributes.split(', ');

  return {
    subject: subjectWithoutAttributes,
    attributes: parsedAttributes,
  }
}

export const parseAttributesByGroup = async (attributes: string[]): Promise<AssignedAttributes> => {
  const parsedAttributes = {
    wip: [] as number[],
    client: [] as number[],
  }

  for (const attribute of attributes) {
    if (attribute.includes(WIP_ATTRIBUTE_KEYWORD)) {
      const id = validateId(attribute.slice(WIP_ATTRIBUTE_KEYWORD.length + 1))

      if (id) {
        const encryptedId = decryptXOR(id, REACT_APP_XOR_ENCRYPTION_KEY)
        parsedAttributes.wip.push(encryptedId)
      }
    } else if (attribute.includes(CLIENT_ATTRIBUTE_KEYWORD)) {
      const id = validateId(attribute.slice(CLIENT_ATTRIBUTE_KEYWORD.length + 1))

      if (id) {
        const encryptedId = decryptXOR(id, REACT_APP_XOR_ENCRYPTION_KEY)
        parsedAttributes.client.push(encryptedId)
      }
    }
  }

  const clients = await fetchClientsByIds(parsedAttributes.client)

  return {
    wip: parsedAttributes.wip,
    client: clients,
  }
}

export const parseSubjectForAttributesByGroup = async (subject: string) => {
  const parsedSubject = parseSubjectForAttribute(subject)
  const attributes = await parseAttributesByGroup(parsedSubject.attributes)
  return {
    subject: parsedSubject.subject,
    attributes
  }
}

export const createAttributeString = (attributes: string[]) => {
  if (!attributes.length) return '';
  if (attributes.length === 1) return `${attributes[0]}`;
  return `${ATTRIBUTES_START_KEYWORD}${attributes.join(', ')}${ATTRIBUTES_END_KEYWORD}`
}
