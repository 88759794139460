import { REACT_APP_API_URL } from "@src/lib/global-variables"
import { PaginationResponse, Params } from "@src/types/service.type"
import axios from "axios"
import { EmailTemplate } from "../types/email.type"

export class EmailTemplateService {
  async getTemplates(params: Params = {}) {
    const res = await axios.get<PaginationResponse<EmailTemplate[]>>(`${REACT_APP_API_URL}/email-templates`, { params })
    return res.data
  }
}