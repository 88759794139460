import { editCommercialUncoveredFormEnum } from '../actionTypes';
import { editCommercialUncoveredFormService } from '../service';

export const editCommercialUncoveredForm = (id, data) => async (dispatch) => {
  dispatch({ type: editCommercialUncoveredFormEnum.IS_LOADING_EDIT_COMMERCIAL_UNCOVERED_FORM });
  try {
    const res = await editCommercialUncoveredFormService(id, data);

    dispatch({
      type: editCommercialUncoveredFormEnum.EDIT_COMMERCIAL_UNCOVERED_FORM_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: editCommercialUncoveredFormEnum.EDIT_COMMERCIAL_UNCOVERED_FORM_ERROR,
      payload: error,
    });

    throw error;
  }
};
