import React from 'react';

import {
  Grid,
  OutlinedInput,
  Typography,
  outlinedInputClasses,
  Select,
  MenuItem,
} from '@mui/material';

import { inputStyles, formInputStyles } from './styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const FormSelect = ({
  label,
  placeholder,
  name,
  changeHandler,
  required,
  value,
  error,
  touched,
  bold,
  width,
  column,
  disabled,
  options = [],
  geyByIndex = false,
}) => {
  return (
    <Grid item sx={width ? formInputStyles.container : formInputStyles.containerFull}>
      <Grid sx={column ? formInputStyles.containerColumn : formInputStyles.containerFullInner}>
        {label && (
          <Typography sx={bold ? formInputStyles.labelBold : formInputStyles.label}>
            {label} {required && <span style={formInputStyles.required}>*</span>}
          </Typography>
        )}

        <Grid sx={formInputStyles.inputContainer} style={{ maxWidth: width ? width : '' }}>
          <Select
            value={value}
            name={name}
            required={required}
            disabled={disabled}
            onChange={changeHandler}
            label={placeholder}
            input={<OutlinedInput sx={inputStyles(outlinedInputClasses)} />}
            MenuProps={MenuProps}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            {options?.map((item, index) => (
              <MenuItem
                key={`${item.name}-${index}`}
                value={geyByIndex ? index : item?.id || index}
              >
                {item?.name}
              </MenuItem>
            ))}
          </Select>

          {!!error && !!touched && <Grid sx={formInputStyles.errorMessage}>{error}</Grid>}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FormSelect;
