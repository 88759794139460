export const voidModalStyles = {
  container: {
    width: '60vw',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    boxShadow: 24,
    p: 4,
    minHeight: '250px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  closeButton: {
    position: 'absolute',
    top: '15px',
    right: '15px',
  },

  title: {
    fontFamily: 'Source Sans Pro',
    fontSize: '28px',
    color: '#112637',
    fontWeight: '700',
    marginBottom: '45px !important',
  },

  subtitleContainer: {
    width: '100%',
    maxWidth: '510px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '45px',
  },

  subtitle: {
    fontFamily: 'Source Sans Pro',
    fontSize: '18px',
    color: '#112637',
    marginBottom: '5px !important',
    textAlign: 'center',
  },

  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'no-wrap',
    gap: '25px',
  },
};
