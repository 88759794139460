import BaseService from './BaseService'
import axios from 'axios'

export default class BankRecordsService extends BaseService {
  constructor() {
    super()
    this.apiUrl = this.apiUrl + '/bank-file-records'
  }

  async getBankRecords(params = {}) {
    try {
      const response = await axios.get(this.apiUrl, { params })
      if (response.status !== 200) {
        throw new Error('Error occured fetching emails')
      }

      return response
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async createBankRecords(params = {}) {
    try {
      const response = await axios.post(this.apiUrl, params)
      if (response.status !== 201) {
        throw new Error('Error occured fetching emails')
      }

      return response
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async changeBankRecordsId(params) {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/bank-file-record-transactions/${params.id}`,
        params,
      )
      if (response.status !== 200) {
        throw new Error('Error occured fetching emails')
      }

      return response
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }
}
