import { format, setHours, setMinutes } from 'date-fns'
import { FC, useEffect, useState } from 'react'
import styles from './date-range-picker.module.scss'
import {
  add,
  sub,
  isToday,
  startOfDay,
  endOfDay,
  getHours,
  getMinutes,
  startOfMinute,
} from 'date-fns'
import cls from 'classnames'
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'
import { DailyRangePickerProps } from '../../types/global-kanban-board.type'

const DailyRangePicker: FC<DailyRangePickerProps> = ({ initialDate = new Date(), onChange }) => {
  const [currentDate, setCurrentDate] = useState(startOfMinute(initialDate))
  const [from, setFrom] = useState(startOfDay(new Date()))
  const [to, setTo] = useState(endOfDay(new Date()))

  const handleClickNext = () => {
    setCurrentDate((prevDate) => add(prevDate, { days: 1 }))
  }

  const handleClickBack = () => {
    setCurrentDate((prevDate) => sub(prevDate, { days: 1 }))
  }

  const getDateInterval = (): [Date, Date] => {
    const startHours = getHours(from)
    const startMinutes = getMinutes(from)
    const startDate = setMinutes(setHours(currentDate, startHours), startMinutes)

    const endHours = getHours(to)
    const endMinutes = getMinutes(to)
    const endDate = setMinutes(setHours(currentDate, endHours), endMinutes)
    return [startDate, endDate]
  }

  useEffect(() => {
    if (onChange) {
      onChange(getDateInterval())
    }
  }, [currentDate, from, to])

  return (
    <div className={styles.dateRangeWrapper}>
      <div className={styles.dateRangeWrapper}>
        <div className={styles.dateRangeAction} onClick={handleClickBack}>
          <span className="lnr lnr-chevron-left" />
        </div>
        <div className={cls(styles.dateRangeItem, { [styles.active]: isToday(currentDate) })}>
          <span>{format(currentDate, 'EEEEEE').toUpperCase()}</span>
          <span>{format(currentDate, 'dd')}</span>
        </div>
        <div className={styles.dateRangeAction} onClick={handleClickNext}>
          <span className="lnr lnr-chevron-right" />
        </div>
      </div>
      <div className={styles.hourFieldsWrapper}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MobileTimePicker
            className={styles.hourField}
            openTo="hours"
            defaultValue={dayjs(from)}
            onChange={(date) => setFrom((date as any)?.$d)}
          />
          <div>-</div>
          <MobileTimePicker
            className={styles.hourField}
            defaultValue={dayjs(to)}
            openTo="hours"
            onChange={(date) => setTo((date as any)?.$d)}
          />
        </LocalizationProvider>
      </div>
    </div>
  )
}

export default DailyRangePicker
