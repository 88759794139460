import { FC, useMemo, useState } from 'react'
import PaymentTable from '../payment-table/payment-table.component'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import Dropdown from '@components/dropdown/dropdown.component'
import { WIP_DROPDOWN_OPTIONS } from '@containers/Recovery/Wip/const/wip.const'
import CloackIcon from '@src/icons/cloack'
import { SelectOption } from '@components/pagination-select/pagination-select.type'
import { startOfToday, sub } from 'date-fns'
import FormInput from '@components/inputs/form-input/form-input.component'
import SearchIcon from '@src/icons/search'
import styles from './payment-table-with-fitler.module.scss'
import { PaymentTableWithFilterProps } from '../../../types/transaction.type'

const PaymentTableWithFilter: FC<PaymentTableWithFilterProps> = ({ wipId }) => {
  const [selected, setSelected] = useState(WIP_DROPDOWN_OPTIONS[2])

  const transformDropDownOptionToDate = (option: SelectOption): Date | undefined => {
    switch (option.value) {
      case 'today':
        return startOfToday()
      case 'week':
        return sub(new Date(), { days: 7 })
      case 'month':
        return sub(new Date(), { months: 1 })
      default:
        return undefined
    }
  }

  const handleDropDownChange = (option: SelectOption) => {
    setSelected(option)
  }

  const s = useMemo(
    () =>
      transformDropDownOptionToDate(selected)
        ? {
            date: { $gt: transformDropDownOptionToDate(selected) },
          }
        : undefined,
    [selected],
  )

  return (
    <>
      <div className={styles.filterWrapper}>
        <div className={styles.dropdownWrapper}>
          <Header>Payment Logs</Header>
          <Dropdown
            options={WIP_DROPDOWN_OPTIONS}
            selected={selected}
            icon={CloackIcon}
            onSelectedChange={handleDropDownChange}
          />
        </div>
        <div className={styles.searchFieldWrapper}>
          <FormInput size="small" placeholder="Search" icon={SearchIcon} />
        </div>
      </div>
      <PaymentTable wipId={wipId} s={s} />
    </>
  )
}

export default PaymentTableWithFilter
