import Section from '@components/section/section.component'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import DebtorLegalNoteList from '../../lists/debtor-legal-note-list/debtor-legal-note-list.component'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import CreateDebtorLegalNoteModal from '../../modals/debtor-legal-note-modal/create-debtor-legal-note-modal.component'
import { useModal } from '@src/hooks/useModal'
import { FC } from 'react'
import styles from './debtor-legal-note-section.module.scss'
import { DebtorLegalNoteSectionProps } from '../../../types/debtor-legal-note.type'

const DebtorLegalNoteSection: FC<DebtorLegalNoteSectionProps> = ({ debtorLegal }) => {
  const [isOpen, handleOpen, handleClose] = useModal(false)
  return (
    <Section className={styles.sectionWrapper}>
      <Header>Notes</Header>
      <DebtorLegalNoteList debtorLegalId={debtorLegal.id} />
      <SubmitButton onClick={handleOpen}>Add Note</SubmitButton>
      <CreateDebtorLegalNoteModal
        isOpen={isOpen}
        onClose={handleClose}
        debtorLegalId={debtorLegal.id}
      />
    </Section>
  )
}

export default DebtorLegalNoteSection
