import { STATE_OPTIONS } from "@src/lib/select-options.const";
import { DebtorDetailsNamesFormValues, DebtorDetailsOtherFormValues, DebtorPhones } from "../types/debtor-form.type";
import { Debtor } from "../types/debtor.type";

export const transformEmptyStringToNull = <T>(obj: T): T => {
  const newObj = { ...obj }
  for (const key in newObj) {
    if (newObj[key] === '') {
      newObj[key] = null as any;
    }
  }
  return newObj
}

export const transformPhoneEmptyStringToNull = <T>(obj: T): T => {
  const newObj = { ...obj }
  for (const key in newObj) {
    if (!newObj[key] || newObj[key] === '+610' || newObj[key] === '+640' || newObj[key] === '' || newObj[key] === '610' || newObj[key] === '640') {
      newObj[key] = null as any;
    }
  }
  return newObj
}

export const transformDebtorToDebtorDetailsNamesFormValues = (debtor: Debtor): DebtorDetailsNamesFormValues => {
  return {
    name_first: debtor.name_first,
    second_name_first: debtor.second_name_first,
    name_last: debtor.name_last,
    second_name_last: debtor.second_name_last,
    name_middle: debtor.name_middle,
    second_name_middle: debtor.second_name_middle,
  }
}

export const transformDebtorToDebtorDetailsPhoneFormValues = (debtor: Debtor): DebtorPhones => {
  return {
    comms_ph_mobile: debtor.contact.comms_ph_mobile || '+610',
    comms_ph_home: debtor.contact.comms_ph_home || '+610',
    comms_ph_work: debtor.contact.comms_ph_work || '+610',
    comms_ph_mobile_secondary: debtor.contact.comms_ph_mobile_secondary,
    comms_ph_direct: debtor.contact.comms_ph_direct,
    comms_ph_office: debtor.contact.comms_ph_office,
    comms_ph_fax: debtor.contact.comms_ph_fax,
    comms_ph_extension: debtor.contact.comms_ph_extension,
  }
}

export const transformDebtorToDebtorDetailsOtherFormValues = (debtor: Debtor): DebtorDetailsOtherFormValues => {
  return {
    time_created: debtor.time_created,
    date_of_entry: debtor.date_of_entry,
    drivers_licence: debtor.drivers_licence,
    state_drivers_licence: STATE_OPTIONS.find(option => option.value === debtor.state_drivers_licence) || null,
    vehicle_reg: debtor.vehicle_reg,
    state_vehicle_licence: STATE_OPTIONS.find(option => option.value === debtor.state_vehicle_licence) || null,
  }
}