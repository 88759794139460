import { CreateLandlordContact } from '@containers/Sales/Properties/types/property.type'
import landlordContactServiceInstance, { LandlordContactService } from '../services/landlord-contact.service'
import { useMutation, useQuery } from '@tanstack/react-query'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'


export const useCreateLandlordContactMutation = () => {

  const createLandlordContact = async (landlordId: number, data: CreateLandlordContact) => {
    const landlordContactService = new LandlordContactService()
    return landlordContactService.createLandlordContact(landlordId, data)
  }

  const mutation = useMutation({
    mutationFn: ({ landlordId, data }: {
      landlordId: number,
      data: CreateLandlordContact
    }) => createLandlordContact(landlordId, data),
  })

  return mutation
}

export const useGetLandlordContacts = (landlordId: number) => {
  const getGetLandlordContacts = async (landlordId: number) => {
    return landlordContactServiceInstance.getLandlordContacts(landlordId)
  }
  return useQuery({
    queryKey: [GLOBAL_QUERIES.CONTACTS.GET, landlordId],
    queryFn: () => getGetLandlordContacts(landlordId),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
}

export const useUpdateLandlordContactMutation = () => {

  const updateLandlordContact = async (landlordId: number, contactId: number, data: CreateLandlordContact) => {
    const landlordContactService = new LandlordContactService()
    return landlordContactService.updateLandlordContact(landlordId, contactId, data)
  }

  const mutation = useMutation({
    mutationFn: ({ landlordId, contactId, data }: {
      landlordId: number,
      contactId: number,
      data: CreateLandlordContact
    }) => updateLandlordContact(landlordId, contactId, data),
  })

  return mutation
}

export const useDeleteLandlordContactMutation = () => {

  const deleteLandlordContact = async (landlordId: number, contactId: number) => {
    const landlordContactService = new LandlordContactService()
    return landlordContactService.deleteLandlordContact(landlordId, contactId)
  }

  const mutation = useMutation({
    mutationFn: ({ landlordId, contactId }: {
      landlordId: number,
      contactId: number
    }) => deleteLandlordContact(landlordId, contactId),
  })

  return mutation
}

