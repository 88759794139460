import { SubHeader } from '@containers/Recovery/Wip/components/UI/text/text.component'
import { WipCostListProps } from '@containers/Recovery/WipCost/types/wip-cost.type'
import { FC } from 'react'
import EditWipCostItem from './edit-wip-cost-item.component'
import CreateWipCostItem from './create-wip-cost-item.component'
import styles from './wip-cost-list.module.scss'

const WipCostList: FC<WipCostListProps> = ({ wipCostList }) => {
  return (
    <div className={styles.wipCostListWrapper}>
      {wipCostList.map((wipCost) => (
        <div key={wipCost.id}>
          <SubHeader fontSize="large" mb={4}>
            {wipCost.state}
          </SubHeader>
          <div>
            <ul className={styles.list}>
              {wipCost.list.map((wipCostListItem) => (
                <EditWipCostItem key={wipCostListItem.id} wipCostListItem={wipCostListItem} />
              ))}
              <CreateWipCostItem wipCostListId={wipCost.id} />
            </ul>
          </div>
        </div>
      ))}
    </div>
  )
}

export default WipCostList
