export const PROTECT_COLLECT = 'PROTECT_COLLECT';

export function protectCollectAC(formData) {
  return {
    type: PROTECT_COLLECT,
    formData,
  };
}

export const SUBMIT_PROTECT_COLLECT_FORM = 'SUBMIT_PROTECT_COLLECT_FORM';
export const SUBMIT_PROTECT_COLLECT_FORM_SUCCESS =
  'SUBMIT_PROTECT_COLLECT_FORM_SUCCESS';
export const SUBMIT_PROTECT_COLLECT_FORM_FAILURE =
  'SUBMIT_PROTECT_COLLECT_FORM_FAILURE';

// action creators
export const submitProtectCollectForm = () => ({
  type: SUBMIT_PROTECT_COLLECT_FORM,
});

export const submitProtectCollectFormSuccess = (data) => ({
  type: SUBMIT_PROTECT_COLLECT_FORM_SUCCESS,
  payload: data,
});

export const submitProtectCollectFormFailure = (error) => ({
  type: SUBMIT_PROTECT_COLLECT_FORM_FAILURE,
  payload: error,
});
