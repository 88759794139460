import { Wip } from "@containers/Recovery/Wip/types/wip.type";
import { AssignedStaff, Debtor } from "@containers/Setting/components/Contacts/taskboard/types";
import { format } from "date-fns";


interface ReportAttributes {
  date_long: string
  client_name: string,
  client_address: string,
  file_number: number,
  wip_prop: string,
  comb_name_amp: string,
  staff_name: string,
  staff_title: string
}

export class ReportService {

  private readonly wip: Wip;
  private readonly staff: AssignedStaff;

  constructor(wip: Wip, staff: AssignedStaff) {
    this.wip = wip;
    this.staff = staff;
  }

  public formatReport(reportTemplate: string, debtor?: Debtor) {

    const reportAttributes: Partial<ReportAttributes> = {
      ...this.baseAttributes,
      date_long: format(new Date(), 'dd/MM/yyyy'),
      comb_name_amp: debtor && `${debtor.name_first} ${debtor.name_last}`
    }

    return this.fillTemplate(reportTemplate, reportAttributes);
  }

  fillTemplate(template: string, values: Partial<ReportAttributes>) {
    return template.replace(/%(\w+)%/g, (match, key) => values[key] || match);
  }

  get baseAttributes() {
    return {
      client_name: this.wip.company.name,
      client_address: this.wip.company.address?.[0]?.address_1 || 'Invalid Address',
      file_number: this.wip.id,
      wip_prop: this.wip.property?.fullAddress || 'Invalid Address',
      staff_name: this.staff.nickname,
      staff_title: this.staff.access_level
    }
  }
}