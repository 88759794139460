import React from 'react';
import { Button } from 'reactstrap';
import { reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import SimpleReactValidator from 'simple-react-validator';
import Geosuggest from 'react-geosuggest';
import DebtorService from '../../../../../../services/DebtorService';
import Services from '../../../../../../shared/Services';
import ModalComponent from '../../../../../../shared/components/Modal';

class Addresses extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      CorrDet: '',
      address_1: '',
      address_2: '',
      address_3: '',
      suburb: '',
      state: '',
      postcode: '',
      country: '',
      srv_address_1: '',
      srv_address_2: '',
      srv_address_3: '',
      srv_suburb: '',
      srv_state: '',
      srv_postcode: '',
      srv_country: '',
      id: localStorage.getItem('debtorId'),
      accessErrorModal: false,
      serviceId: null,
      currentId: null,
      contactId: null,
      wips: [],
      history_rank: '',
      is_service: false,
    };
    this.debtor = new DebtorService();
    this.services = new Services();
    this.editDebtor = this.editDebtor.bind(this);
    this.changeInput = this.changeInput.bind(this);
    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    this.setDebtor(this.props.dataDebtor);
  }


  setDebtor(data) {
    if (data) {
      const getData = data.data;
      const currentAdd = getData.addresses.find((add) => (add.is_current === true) && (add.is_service === true) || (add.is_service === false));
      this.setState({
        currentId: currentAdd?.id,
        address_1: currentAdd?.address_1 || '-',
        address_2: currentAdd?.address_2,
        address_3: currentAdd?.address_3,
        suburb: currentAdd?.suburb,
        state: currentAdd?.state,
        postcode: currentAdd?.postcode,
        country: currentAdd?.country,
        history_rank: currentAdd?.history_rank || 0,
        is_service: currentAdd?.is_service,
      });
      const serviceAdd = getData.addresses.find((add) => add.is_service === true && add.is_current === false );
      if (serviceAdd !== undefined) {
        this.setState({
          serviceId: serviceAdd?.id,
          srv_address_1: serviceAdd?.address_1 || '-',
          srv_address_2: serviceAdd?.address_2,
          srv_address_3: serviceAdd?.address_3,
          srv_suburb: serviceAdd?.suburb,
          srv_state: serviceAdd?.state,
          srv_postcode: serviceAdd?.postcode,
          srv_country: serviceAdd?.country,
        });
      }

      this.setState({
        contactId: getData?.contact?.id,
        old_ten_id: getData.id,
        name: getData.name,
        Client_Code: getData.client_code,
        CorrDet: '',
        staffData: getData,
        wips: getData.wips,
      });
      const assWip = getData.wips.map((e) => ({ id: e.id }));
      localStorage.setItem('wipDebId', JSON.stringify(assWip));
    }
  }

  accessErrorModalClose = () => {
    this.setState({ accessErrorModal: false });
    this.props.history.push('/dashboard');
  };

  editDebtor = () => {

    const dataCurrentAddress = {
      id: this.state.currentId || null,
      is_current: true,
      is_service: this.state.is_service,
      debtor: { id: +this.state.id },
      address_1: this.state.address_1 || null,
      address_2: this.state.address_2 || null,
      address_3: this.state.address_3 || null,
      suburb: this.state.suburb || null,
      state: this.state.state || null,
      postcode: this.state.postcode || null,
      country: this.state.country || null,
      is_international: true,
      history_start_date: new Date(),
      history_end_date: new Date(),
      history_rank: this.state.history_rank,
      city: 'Riga',
    };

    const dataServiceAddress = {
      id: this.state.serviceId || null,
      is_current: false,
      is_service: true,
      debtor: { id: +this.state.id },
      address_1: this.state.srv_address_1 || null,
      address_2: this.state.srv_address_2 || null,
      address_3: this.state.srv_address_3 || null,
      suburb: this.state.srv_suburb || null,
      state: this.state.srv_state || null,
      postcode: this.state.srv_postcode || null,
      country: this.state.srv_country || null,
      is_international: true,
      history_start_date: new Date(),
      history_end_date: new Date(),
      history_rank: 0,
      city: 'Riga',
    };


    if (this.validator.allValid()) {

      if (dataCurrentAddress.id === null && dataCurrentAddress.address_1) {
        this.debtor.addDebtorAddress([dataCurrentAddress]).then((res) => {
          if (res.status === 201) {
            this.services.successToast('Address update successfully');
            this.props.getDebtor(+this.state.id)
          } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
            this.setState({ accessErrorModal: true });
          } else {
            this.services.errorToast(res.message);
          }
        });
      }

      if (dataServiceAddress.id === null && dataServiceAddress.address_1) {
        this.debtor.addDebtorAddress([dataServiceAddress]).then((res) => {
          if (res.status === 201) {
            this.services.successToast('Address update successfully');
            this.props.getDebtor(+this.state.id)
          } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
            this.setState({ accessErrorModal: true });
          } else {
            this.services.errorToast(res.message);
          }
        });
      }
      if (dataCurrentAddress.id) {
        this.debtor.editDebtorAddress([dataCurrentAddress]).then((res) => {
          if (res[0].status === 200) {
            this.services.successToast('Current address update successfully');
            this.props.getDebtor(+this.state.id)
          } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
            this.setState({ accessErrorModal: true });
          } else {
            this.services.errorToast(res.message);
          }
        });
      }
      if (dataServiceAddress.id !== null) {
        this.debtor.editDebtorAddress([dataServiceAddress]).then((res) => {
          if (res[0].status === 200) {
            this.services.successToast('Service address update successfully');
            this.props.getDebtor(+this.state.id)
          } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
            this.setState({ accessErrorModal: true });
          } else {
            this.services.errorToast(res.message);
          }
        });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  changeInput(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSelectGeoSuggest = (place, id) => {
    if (!place) return null;

    const componentForm = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      administrative_area_level_1: 'long_name',
      country: 'long_name',
      postal_code: 'short_name',
    };
    for (let i = 0; i < place.gmaps.address_components.length; i++) {
      const addressType = place.gmaps.address_components[i].types[0];
      if (componentForm[addressType]) {
        const val = place.gmaps.address_components[i][componentForm[addressType]];
        if (val) componentForm[addressType] = val;
      }
    }

    if (id === 'Service_Address') {
      this.setState({
        service_address: place.label,
        srv_address_1: place.label || 'Service_Address',
        srv_address_2: componentForm.street_number !== 'short_name' ? componentForm.street_number : '',
        srv_address_3: componentForm.route !== 'long_name' ? componentForm.route : '',
        srv_suburb: componentForm.locality !== 'long_name' ? componentForm.locality : '',
        srv_state: componentForm.administrative_area_level_1 !== 'long_name' ? componentForm.administrative_area_level_1 : '',
        srv_postcode: componentForm.postal_code !== 'short_name' ? componentForm.postal_code : '',
        srv_country: componentForm.country !== 'long_name' ? componentForm.country : '',
      });
    }

    if (id === 'Current_Address') {
      this.setState({
        CorrDet: place.label,
        address_1: place.label || 'Current_Address',
        address_2: componentForm.street_number !== 'short_name' ? componentForm.street_number : '',
        address_3: componentForm.route !== 'long_name' ? componentForm.route : '',
        suburb: componentForm.locality !== 'long_name' ? componentForm.locality : '',
        state: componentForm.administrative_area_level_1 !== 'long_name' ? componentForm.administrative_area_level_1 : '',
        postcode: componentForm.postal_code !== 'short_name' ? componentForm.postal_code : '',
        country: componentForm.country !== 'long_name' ? componentForm.country : '',
      });
    }

  };

  changeAddressService(e) {
    this.setState({ [e.target.name]: e.target.checked });
  }

  render() {
    const {
      CorrDet,
      address_2,
      address_3,
      suburb,
      state,
      postcode,
      country,
      service_address,
      srv_address_2,
      srv_address_3,
      srv_suburb,
      srv_state,
      srv_postcode,
      srv_country,
      is_service,
    } = this.state;


    return (
      <>
        <div className='w-100'>
          <div id='contacts_accordion' className='form'>
            <div className='card mb-4'>
              <div id='collapseOne' className='d-flex justify-between ' data-parent='#contacts_accordion'>

                {/*--------------------Current address-----------------------*/}
                <div className={'col-6'}>
                  <div className='card-header mb-4 pl-0'>
                    <h4 className='card-link' data-toggle='collapse'>
                      Current address
                    </h4>
                  </div>
                  <div className='row'>
                    <div className='col-12'>
                      <div className='form-group'>
                        <label className='form__form-group-label'>Search Address Here</label>
                        <Geosuggest
                          ref={el => this._geoSuggest = el}
                          onSuggestSelect={(e) => this.handleSelectGeoSuggest(e, 'Current_Address')}
                          initialValue={CorrDet}
                          country={['AU', 'NZ']}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-12'>
                      <div className='form-group'>
                        <label className='form__form-group-label'>Address Line 2</label>
                        <input type='text' className='custom-input'
                               id='address_2' name='address_2'
                               value={address_2 ||''} onChange={this.changeInput} />
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-12'>
                      <div className='form-group'>
                        <label className='form__form-group-label'>Address Line 3</label>
                        <input type='text' className='custom-input'
                               id='address_3' name='address_3'
                               value={address_3 || ''} onChange={this.changeInput} />
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-12 col-md-6'>
                      <div className='form-group'>
                        <label className='form__form-group-label'>Suburb</label>
                        <input type='text' className='custom-input'
                               id='suburb' name='suburb'
                               value={suburb ?? ''} onChange={this.changeInput} />
                      </div>
                    </div>
                    <div className='col-12 col-md-6'>
                      <div className='form-group'>
                        <label className='form__form-group-label'>State</label>
                        <input type='text' className='custom-input'
                               id='state' name='state'
                               value={state || ''} onChange={this.changeInput} />
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-12 col-md-6'>
                      <div className='form-group'>
                        <label className='form__form-group-label'>Post Code</label>
                        <input type='text' className='custom-input'
                               id='postcode' name='postcode'
                               value={postcode ?? ''} onChange={this.changeInput} />
                      </div>
                    </div>
                    <div className='col-12 col-md-6'>
                      <div className='form-group'>
                        <label className='form__form-group-label'>Country</label>
                        <input type='text' className='custom-input'
                               id='country' name='country'
                               value={country ||''} onChange={this.changeInput} />
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-12 col-md-6 '>
                    </div>
                    <div className='col-12 col-md-6 '>
                      <div className='form-group text-right'>
                        <div className='custom-control custom-switch mt-4 pt-2'>
                          <input
                            type='checkbox'
                            className='custom-control-input'
                            id={`is_service`}
                            name='is_service'
                            checked={is_service || false}
                            onChange={(e) => this.changeAddressService(e)}
                          />
                          <label
                            className='custom-control-label'
                            htmlFor={`is_service`}
                          >
                            Same as service address
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/*--------------------Service Address-----------------------*/}
                <div className={'col-6'}>
                  <div className='card-header mb-4 pl-0'>
                    <h4 className='card-link' data-toggle='collapse'>
                      Service Address
                    </h4>
                  </div>
                  <div className='w-100' id='client_overview_tab'>
                    <div className='outer_block_div'>
                      <div className='row'>
                        <div className='col-12 '>
                          <div className='form-group'>
                            <label className='form__form-group-label'>Search Address Here</label>
                            <Geosuggest
                              ref={el => this._geoSuggest = el}
                              onSuggestSelect={(e) => this.handleSelectGeoSuggest(e, 'Service_Address')}
                              initialValue={service_address}
                              country={['AU', 'NZ']}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-12 '>
                          <div className='form-group'>
                            <label className='form__form-group-label'>Address Line 2</label>
                            <input type='text' className='custom-input'
                                   id='srv_address_2' name='srv_address_2'
                                   value={srv_address_2 ?? ''} onChange={this.changeInput} />
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-12'>
                          <div className='form-group'>
                            <label className='form__form-group-label'>Address Line 3</label>
                            <input type='text' className='custom-input'
                                   id='srv_address_3' name='srv_address_3'
                                   value={srv_address_3 ?? ''} onChange={this.changeInput} />
                          </div>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-12 col-md-6'>
                          <div className='form-group'>
                            <label className='form__form-group-label'>Suburb</label>
                            <input type='text' className='custom-input'
                                   id='srv_suburb' name='srv_suburb'
                                   value={srv_suburb ?? ''} onChange={this.changeInput} />
                          </div>
                        </div>
                        <div className='col-12 col-md-6'>
                          <div className='form-group'>
                            <label className='form__form-group-label'>State</label>
                            <input type='text' className='custom-input'
                                   id='srv_state' name='srv_state'
                                   value={srv_state} onChange={this.changeInput} />
                          </div>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-12 col-md-6'>
                          <div className='form-group'>
                            <label className='form__form-group-label'>Post Code</label>
                            <input type='text' className='custom-input'
                                   id='srv_post_code' name='srv_postcode'
                                   value={srv_postcode ?? ''} onChange={this.changeInput} />
                          </div>
                        </div>
                        <div className='col-12 col-md-6'>
                          <div className='form-group'>
                            <label className='form__form-group-label'>Country</label>
                            <input type='text' className='custom-input'
                                   id='srv_country' name='srv_country'
                                   value={srv_country} onChange={this.changeInput} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='w-100 d-flex justify-content-end pt-4 '>
                <Button className='btn-group--icons' onClick={this.editDebtor}>Save</Button>
                <button className='btn-gray m-0' onClick={() => this.props.history.push('/debtor')}>Cancel</button>
              </div>
            </div>
          </div>
          {this.state.errorModal ?
            <ModalComponent modal={this.state.errorModal} color={this.state.errorType} isCancel={true}
                            message={this.state.errorMessage} onConfirm={(e) => this.DeleteStaff(e)}
                            onClose={() => this.setState({ errorModal: false })} /> : null}
          {this.state.accessErrorModal ?
            <ModalComponent modal={this.state.accessErrorModal} color='danger' isCancel={false}
                            message='You do not have access to this, please contact your manager for assistance'
                            onConfirm={(e) => this.accessErrorModalClose(e)}
                            onClose={(e) => this.accessErrorModalClose(e)} /> : null}

        </div>
      </>
    );
  }
}

export default reduxForm({ form: 'add_staff_form_validation' })
(withRouter(withTranslation('common')(Addresses)));
