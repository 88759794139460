import RecoverableCostsSection from './components/sections/recoverable-costs-section/recoverable-costs-section.component'
import TotalClaimAmountSection from './components/sections/total-claim-amount-section/total-claim-amount-section.component'
import styles from './claim-page.module.scss'
import { FC } from 'react'
import { ClaimPageProps } from './types/claim.type'

const ClaimPage: FC<ClaimPageProps> = ({ wip }) => {
  return (
    <div className={styles.claimPageWrapper}>
      <TotalClaimAmountSection wip={wip} />
      <RecoverableCostsSection wipId={wip.id} stateOfOrigin={wip.state_of_origin || 'NT'} />
    </div>
  )
}

export default ClaimPage
