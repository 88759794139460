import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import CorporateRecoveryListRow from './corporateRecoveryListRow';
import Loader from '../../loading/loading';

import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import { Card, CardBody, Col } from 'reactstrap';
import {
  makeStyles,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
} from '@material-ui/core';

import { getURFList, removeURF } from './serviceCorporateRecoveryForm';
import { STRATA_LIST_TABLE_HEAD_ITEMS } from '../../../shared/utils/strataListTableHead.constants';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {STRATA_LIST_TABLE_HEAD_ITEMS.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const CorporateRecoveryList = () => {
  const [order, setOrder] = useState('desc');
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(1);
  const [orderBy, setOrderBy] = useState('time_created');
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const classes = useStyles();

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getListItems = async (page) => {
    setLoading(true);
    const params = {
      join: 'company||id',
    };

    try {
      const response = await getURFList(params, page);

      if (response?.data?.pageCount) {
        setCount(response?.data?.pageCount);
      }
      setRows(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error('Something went wrong!');
    }
  };

  const handleChange = (_, newPage) => {
    setPage(newPage);
    getListItems(newPage);
  };

  const tenantName = (tenants) => {
    const tenantsName = [];
    if (tenants?.length > 0) {
      tenants.forEach((item) => {
        tenantsName.push(`${item.tenant_name_first} ${item.tenant_name_last}`);
      });
    }
    return tenantsName.join(', ');
  };

  const getRemoveItem = (id) => () => {
    removeURF(id)
      .then(() => {
        toast.success('Recovery canceled successfully');
        getListItems(page);
      })
      .catch(() => {
        toast.success('Something went wrong!');
      });
  };

  useEffect(() => {
    getListItems(page);
  }, []);

  return (
    <>
      <Col
        className={'container page-body-wrapper position-relative pb-4'}
        md={12}
        lg={12}
      >
        <h3 className="page-title mb-5">Strata Body Corporate Recovery</h3>
        <Card>
          <CardBody>
            <Link
              to="/recoveries/add-recovery-form"
              className="btn btn-secondary"
            >
              <span>Add Strata Body Recovery Form</span>
            </Link>
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />

                <TableBody>
                  {loading ? (
                    <TableRow>
                      <Loader />
                    </TableRow>
                  ) : (
                    <>
                      {stableSort(rows, getComparator(order, orderBy)).map(
                        (row, index) => {
                          const labelId = `enhanced-table-checkbox-${index}`;
                          return (
                            <CorporateRecoveryListRow
                              row={row}
                              labelId={labelId}
                              index={index}
                              tenantName={tenantName}
                              getRemoveItem={getRemoveItem}
                              key={index}
                            />
                          );
                        },
                      )}
                    </>
                  )}
                </TableBody>

                <Stack spacing={2} style={{ marginTop: '30px' }}>
                  <Pagination
                    count={count}
                    page={page}
                    onChange={handleChange}
                  />
                </Stack>
              </Table>
            </TableContainer>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default CorporateRecoveryList;
