import { FC } from "react";
import Section from "@components/section/section.component";
import PaymentTableWithFilter from "../../tables/payment-table-with-filter/payment-table-with-filter.component";
import { PaymentLogSectionProps } from "../../../types/transaction.type";

const PaymentLogSection:FC<PaymentLogSectionProps> = ({ wipId }) => {
  return (
    <Section>
      <PaymentTableWithFilter wipId={wipId}/>
    </Section>
  )
}

export default PaymentLogSection;