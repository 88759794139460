import styles from './email-item-skeleton.module.scss'
import cls from 'classnames'

const EmailItemSkeleton = () => {
  return (
    <li className={styles.emailItemSkeletonWrapper}>
      <div className={cls(styles.line, styles.square)} />
      <div className={cls(styles.line, styles.square)} />
      <div className={cls(styles.line, styles.senderPhoto)} />
      <div className={cls(styles.line, styles.senderName)} />
      <div className={cls(styles.line, styles.subject)} />
      <div className={cls(styles.line, styles.creationTime)} />
    </li>
  )
}

export default EmailItemSkeleton
