import { useMutation } from "@tanstack/react-query"
import { AddFeeData } from "../../types/claim.type"
import { WipCostListsService } from "../../services/wip-cost-list.service"
import { useUserContext } from "@src/context/user-context"

export const useAddFeeMutation = () => {
  const { user } = useUserContext()

  const addFee = (data: Omit<AddFeeData, 'staffCreatedId'>) => {
    if (!user) {
      throw new Error('User not found')
    }

    const wipService = new WipCostListsService()
    return wipService.addFee({ ...data, staffCreatedId: user.id })
  }

  const mutation = useMutation(({ data }: { data: Omit<AddFeeData, 'staffCreatedId'> }) => addFee(data))

  return mutation
}