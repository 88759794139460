import Modal from '@components/modal'
import { FC, useMemo } from 'react'
import InterestRateCalculatorForm from '../../forms/interest-rate-calculator-form/interest-rate-calculator-form.component'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import styles from './interest-rate-calculator-modal.module.scss'
import ModalHeader from '@components/modal-header.component'
import {
  CourtInterestCalculator,
  GeneralInterestCalculator,
  InterestRateCalculatorModalProps,
} from '../../../types/interest-rate.type'
import { useQueryClient } from '@tanstack/react-query'
import { Wip } from '@containers/Recovery/Wip/types/wip.type'
import { getWipQueryKey } from '@containers/Recovery/Wip/utils/wip-query-factory'
import { useGetCourtInterestRates } from '../../../hooks/get-court-interest-rates.hook'
import LoaderWrapper from '@components/loader/loader-wrapper.component'

const InterestRateCalculatorModal: FC<InterestRateCalculatorModalProps> = ({
  wipId,
  courtInterestCalculator,
  generalInterestCalculator,
  state,
  ...modalProps
}) => {
  const { data, isLoading } = useGetCourtInterestRates()
  const queryClient = useQueryClient()

  const handleCourtInterestRateCalculatorSubmit = (
    courtInterestCalculator: CourtInterestCalculator,
  ) => {
    queryClient.setQueryData<Wip>(getWipQueryKey(wipId), (prevData) =>
      prevData ? { ...prevData, courtInterestCalculator } : undefined,
    )
    modalProps.onClose()
  }

  const handleGeneralInterestRateCalculatorSubmit = (
    generalInterestCalculator: GeneralInterestCalculator,
  ) => {
    queryClient.setQueryData<Wip>(getWipQueryKey(wipId), (prevData) =>
      prevData ? { ...prevData, generalInterestCalculator } : undefined,
    )
    modalProps.onClose()
  }

  const courtInterestRateOptions = useMemo(
    () => data?.data?.map((rate) => ({ value: `${rate.rate}-${rate.state}`, label: rate.state })),
    [data],
  )

  return (
    <Modal className={styles.modalWrapper} {...modalProps} withBtns={false}>
      <ModalHeader onCloseClick={modalProps.onClose} />
      <Header mb={4}>Interest Calculator</Header>
      {isLoading || !data ? (
        <LoaderWrapper />
      ) : (
        <InterestRateCalculatorForm
          wipId={wipId}
          state={state}
          courtInterestRatesOptions={courtInterestRateOptions}
          courtInterestCalculator={courtInterestCalculator}
          generalInterestCalculator={generalInterestCalculator}
          onCourtInterestRateCalculatorSubmit={handleCourtInterestRateCalculatorSubmit}
          onGeneralInterestRateCalculatorSubmit={handleGeneralInterestRateCalculatorSubmit}
          onCancelClick={modalProps.onClose}
        />
      )}
    </Modal>
  )
}

export default InterestRateCalculatorModal
