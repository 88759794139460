import BaseService from './BaseService';
import axios from 'axios';

export default class PayTrackingService extends BaseService {
  constructor() {
    super();
    this.apiUrl = this.apiUrl + '/pay-trackings';
  }

  async getPayTrack(params) {
    try {
      const response = await axios.get(this.apiUrl, { params } );
      if (response.status !== 200) {
        throw new Error('Error occured fetching emails');
      }

      return response;
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message);
      } else {
        if (error.response) {
          throw new Error(error.response.data);
        }
        if (!error.response) {
          throw new Error(error);
        }
      }
    }
  }

  async changePayTrack(params = {}) {
    try {
      const response = await axios.patch(`${this.apiUrl}/${params.id}`, params );
      if (response.status !== 200) {
        throw new Error('Error occured fetching emails');
      }

      return response;
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message);
      } else {
        if (error.response) {
          throw new Error(error.response.data);
        }
        if (!error.response) {
          throw new Error(error);
        }
      }
    }
  }

  async createPayTrack(params = {}) {
    try {
      const response = await axios.post(this.apiUrl,  params );
      if (response.status !== 201) {
        throw new Error('Error occured fetching emails');
      }

      return response;
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message);
      } else {
        if (error.response) {
          throw new Error(error.response.data);
        }
        if (!error.response) {
          throw new Error(error);
        }
      }
    }
  }
}
