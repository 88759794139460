import Table from '@components/table/table.component'
import { WipAttachment } from '@containers/Recovery/Wip/types/wip.type'
import { BaseListProps } from '@src/types/component-types'
import { FC } from 'react'
import { AttachmentTableItem } from './attachment-table-item.component'

const AttachmentList: FC<BaseListProps<WipAttachment>> = ({ entities }) => {
  return (
    <Table
      headers={['DATE', 'TIME', 'FILE', 'DOWNLOAD']}
      paginated={false}
      bodyHeight={entities.length * 54}
    >
      {entities.map((entity) => (
        <AttachmentTableItem key={entity.id} entity={entity} />
      ))}
    </Table>
  )
}

export default AttachmentList
