import TableCell from '@components/table/table-cell-component'
import TableRow from '@components/table/table-row.component'
import { Report } from '@containers/Communication/types/api-report.service'
import { BaseListItemProps } from '@src/types/component-types'
import { format } from 'date-fns'
import { FC } from 'react'

const ReportTableItem: FC<BaseListItemProps<Report>> = ({ entity }) => {
  return (
    <TableRow>
      {/* DATE */}
      <TableCell position="eclipsed" width={120}>
        {format(entity.createdAt, 'MMM dd yyyy')}
      </TableCell>
      {/* TIME */}
      <TableCell position="eclipsed" width={120}>
        {format(entity.createdAt, 'HH:mm aa')}
      </TableCell>
      <TableCell position="eclipsed">{entity.subject}</TableCell>
    </TableRow>
  )
}

export default ReportTableItem
