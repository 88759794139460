import Axios from 'axios';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const API_URL = process.env.REACT_APP_BMS5_END_POINT;

const request = (method, url, data, additionalHeaders = {}, file) =>
  new Promise(async (resolve, reject) => {
    const apiurl = `${API_URL}${url}`;

    try {
      const config = {
        method: method.toLowerCase(),
        url: apiurl,
        data,
        file,
        headers: {
          'Accept-Language': 'en',
          Authorization: '',
          ...additionalHeaders,
        },
      };

      const token = localStorage.getItem('token');

      if (token) config.headers.Authorization = `Bearer ${token}`;

      await Axios(config).then((response) => {
        resolve(response.data);
      });
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        if (error?.response) {
          if (error?.response.status === 401) {
            localStorage.removeItem('token');
            reject('401');
          } else {
            reject(error?.response || 'Error');
          }
        }
      } else {
        reject('Error');
      }
    }
  });

export default request;
