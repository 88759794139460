import { DebtorInfoListProps } from '../../types/task.type'
import DebtorInfoItem from './debtor-info-item.component'
import styles from './debtor-info-list.module.scss'

const DebtorInfoList = ({ debtors }: DebtorInfoListProps) => {
  return (
    <div className={styles.debtorListWrapper}>
      {debtors.map((debtor) => (
        <DebtorInfoItem key={`debtor-info-item-${debtor.id}`} debtor={debtor}/>
      ))}
    </div>
  )
}

export default DebtorInfoList
