import Section from '@components/section/section.component'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import styles from './payment-tracking-section.module.scss'
import CreatePaymentTrackingForm from '../../forms/payment-tracking-form/create-payment-tracking-form.component'
import { FC } from 'react'
import { PaymentTracking, PaymentTrackingSectionProps } from '../../../types/payment-tracking.type'
import { useQueryClient } from '@tanstack/react-query'
import { Wip } from '@containers/Recovery/Wip/types/wip.type'
import { getWipQueryKey } from '@containers/Recovery/Wip/utils/wip-query-factory'
import { toast } from 'react-toastify'

const CreatePaymentTrackingSection: FC<PaymentTrackingSectionProps> = ({
  wipId,
  selectedDebtor,
}) => {
  const queryClient = useQueryClient()
  const handleSuccessSubmit = (payTracking: PaymentTracking) => {
    queryClient.setQueryData<Wip>(getWipQueryKey(wipId), (prevData) =>
      prevData
        ? {
            ...prevData,
            pay_trackings: [...prevData.pay_trackings, payTracking],
          }
        : undefined,
    )
    toast.success('Payment tracking created')
  }

  return (
    <Section className={styles.sectionWrapper}>
      <Header mb={4}>Payment Tracking</Header>
      <CreatePaymentTrackingForm
        debtor={selectedDebtor}
        wipId={wipId}
        onSuccessSubmit={handleSuccessSubmit}
      />
    </Section>
  )
}

export default CreatePaymentTrackingSection
