import { useGetDebtorAddressHistoryList } from '@containers/Recovery/Wip/_modules/Debtors/hooks/get-debtor-address-history-list.hook'
import { FC, useEffect, useState } from 'react'
import AddressHistoryListItem from './address-history-list-item.component'
import styles from './address-history-list.module.scss'
import {
  DebtorAddress,
  DebtorAddressHistoryListProps,
} from '@containers/Recovery/Wip/_modules/Debtors/types/debtor-address.type'
import LoaderWrapper from '@components/loader/loader-wrapper.component'

const AddressHistoryList: FC<DebtorAddressHistoryListProps> = ({
  debtorId,
  onSelectedAddressChange,
  s = {},
}) => {
  const [selectedAddress, setSelectedAddress] = useState<DebtorAddress | null>(null)
  const { data: addressHistory, isLoading } = useGetDebtorAddressHistoryList({
    s: JSON.stringify({ 'debtor.id': debtorId, ...s }),
    limit: 999,
    join: 'debtor',
  })

  useEffect(() => {
    if (!addressHistory) return
    setSelectedAddress(addressHistory.data[0] || null)
  }, [addressHistory])

  useEffect(() => {
    if (!selectedAddress || !onSelectedAddressChange) return
    onSelectedAddressChange(selectedAddress)
  }, [selectedAddress])

  const debtorAddresses = addressHistory?.data || []

  return (
    <ul className={styles.addressHistoryList}>
      {debtorAddresses.map((debtorAddress) => (
        <AddressHistoryListItem
          selected={debtorAddress.id === selectedAddress?.id}
          key={debtorAddress.id}
          onClick={setSelectedAddress}
          debtorAddress={debtorAddress}
        />
      ))}
      {isLoading ? <LoaderWrapper /> : null}
    </ul>
  )
}

export default AddressHistoryList
