import React, { FC } from 'react'
import styles from './table.module.scss'
import cls from 'classnames'

interface TableCellProps {
  position?: 'center' | 'eclipsed'
  children?: React.ReactNode
  width?: string | number
  className?: string
}

const TableCell: FC<TableCellProps> = ({ position = 'center', children, width, className }) => {
  return (
    <th
      style={width ? { width } : undefined}
      className={cls(
        styles.tableBodyCell,
        {
          [styles.ellipsis]: position === 'eclipsed',
          [styles.center]: position === 'center',
        },
        className,
      )}
    >
      <span>{children}</span>
    </th>
  )
}

export default TableCell
