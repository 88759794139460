import { REACT_APP_API_URL } from "@src/lib/global-variables";
import { PaginationResponse, Params } from "@src/types/service.type";
import axios from "axios";
import { CreateDebtorLegalNoteData } from "../types/debtor-legal-note.type";
import { DebtorLegalNote } from "../types/debtor-legal.type";

export class DebtorLegalNoteService {

  async getDebtorLegalNotes(params: Params) {
    const res = await axios.get<PaginationResponse<DebtorLegalNote[]>>(`${REACT_APP_API_URL}/debtor-legals-notes`, { params })
    return res.data;
  }

  async createDebtorLegalNote(data: CreateDebtorLegalNoteData, params?: Params) {
    const res = await axios.post<DebtorLegalNote>(`${REACT_APP_API_URL}/debtor-legals-notes`, data, { params });
    return res.data;
  }
}