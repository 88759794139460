export const getClientNavBarOptions = (clientId: number): { label: string, url: string }[] => {
  return [
    { label: 'Overview', url: `/client/${clientId}/overview` },
    { label: 'Accounts', url: `/client/${clientId}/accounts` },
    { label: 'Task Board', url: `/client/${clientId}/task-board` },
    { label: 'Communication', url: `/client/${clientId}/communication` },
    { label: 'Automations', url: `/client/${clientId}/automations` },
    { label: 'Properties', url: `/client/${clientId}/properties` },
    { label: 'WIPS', url: `/client/${clientId}/wips` },
  ]
}