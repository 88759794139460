import { FC, useState } from 'react'
import secondaryTableStyles from '../../../tables/secondary-table/secondary-table.module.scss'
import SecondaryTable from '../../../tables/secondary-table/secondary-table.component'
import TableCell from '@components/table/table-cell-component'
import TableRow from '@components/table/table-row.component'
import styles from '../upload-multiple-properties-form.module.scss'
import cls from 'classnames'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { SelectHeaderRowStepProps } from '@containers/Sales/Properties/types/property.type'
import Radio from '@mui/material/Radio'

const SelectHeaderRowStep: FC<SelectHeaderRowStepProps> = ({
  jsonSheet,
  onSuccessfulSelectHeader,
}) => {
  const [checkedRowIndex, setCheckedRowIndex] = useState<number>(0)

  const getSheetHeader = (): string[] => {
    const headerFields = Object.keys(jsonSheet[0])
    return headerFields
  }

  const getSheetData = (): string[][] => {
    const sheetData = jsonSheet.map((row) => Object.values(row))
    return sheetData
  }

  const getRows = () => {
    const sheetHeader = getSheetHeader()
    const sheetData = getSheetData()
    return [sheetHeader, ...sheetData]
  }

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedRowIndex(Number(event.target.value))
  }

  const handleNextStep = () => {
    const rows = getRows()

    onSuccessfulSelectHeader({
      rows: rows,
      headerRowIndex: checkedRowIndex,
    })
  }

  const rows = getRows()

  return (
    <div className={cls(styles.stepWrapper, styles.withoutPadding)}>
      <div className={styles.selectHeaderTableWrapper}>
        <SecondaryTable>
          {rows.map((row, index) => (
            <TableRow key={`row-${index}`} className={secondaryTableStyles.secondaryTableRow}>
              <TableCell>
                <Radio
                  checked={index === checkedRowIndex}
                  value={index}
                  onChange={handleRadioChange}
                />
              </TableCell>
              {row.map((cell, cellIndex) => (
                <TableCell key={`cell-${cellIndex}`} position="eclipsed">
                  {cell}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </SecondaryTable>
      </div>
      <div className={styles.formFooter}>
        <SubmitButton
          className={styles.nextStepButton}
          buttonStyle="highlighted"
          size="large"
          onClick={handleNextStep}
        >
          Next Step
        </SubmitButton>
      </div>
    </div>
  )
}

export default SelectHeaderRowStep
