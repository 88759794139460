import { useEffect } from 'react'
import ReactDOM from 'react-dom'
import styles from './modal.module.scss'
import cls from 'classnames'
interface ModalProps {
  className?: string
  classNameLayout?: string
  style?: React.CSSProperties
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
  withBtns?: boolean
  isLayoutOverlap?: boolean
  saveBtnText?: string
  canecelBtnText?: string
  onSubmit?: () => void
}
const Modal = ({
  children,
  isOpen = false,
  onClose = () => null,
  onSubmit = () => null,
  withBtns = true,
  isLayoutOverlap = false,
  className,
  classNameLayout,
  style,
  saveBtnText = '',
  canecelBtnText = '',
}: ModalProps) => {
  useEffect(() => {
    if (typeof window === 'undefined') return

    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [isOpen])

  const handleLayoutClose = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    onClose()
  }

  useEffect(() => {
    if (typeof window === 'undefined') return

    const handleKeyDown = (e) => {
      if (e.key === 'Escape' && isOpen) {
        onClose()
      }
    }
  
    window.addEventListener('keydown', handleKeyDown)
  
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [isOpen])

  const renderModal = () => {
    return (
      <>
        <div className={cls({ [styles.layout]: !isLayoutOverlap }, classNameLayout)} onClick={handleLayoutClose} />
        <div
          style={style}
          className={cls(className, styles.wrapper)}
          onClick={(e) => e.stopPropagation()}
        >
          {children}

          {withBtns && (
            <div className={styles.btns}>
              <button onClick={onClose}>{!canecelBtnText ? 'Cancel' : canecelBtnText}</button>
              <button onClick={onSubmit}>{!saveBtnText ? 'Save' : saveBtnText}</button>
            </div>
          )}
        </div>
      </>
    )
  }

  return isOpen ? ReactDOM.createPortal(renderModal(), document.body) : null
}

export default Modal
