import React, { useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';

import AddProtectAndCollectForm from './protectAndCollectForm';
import { deepCopyFunction } from '../../../shared/helpers/deepCopy';

import '../../../scss/form/styles.scss';

const AddProtectCollectForm = () => {
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const history = useHistory();

  const {
    reset,
    handleSubmit,
    register,
    watch,
    control,
    formState: {},
  } = useForm({ mode: 'onSubmit' });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'tenants_details',
  });

  const postProtectCollectFormData = async (formData) => {
    setLoading(true); 
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/protect-and-collect-form`, formData);

      reset();
      setLoading(false);
      toast.success('Submited Successfully');
      history.push('/recoveries/forms/protect_&_collect');
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message?.[0]);
    }
  };

  const onSubmit = handleSubmit((formData) => {
    
    const dataClone = deepCopyFunction(
      deepCopyFunction({
        ...formData,
        // property: { id: propertyId },
        files: files,
      }),
    );

    postProtectCollectFormData(
      dataClone.recovery_details
        ? {
            ...dataClone,
            is_entered_all_tenants: dataClone.is_entered_all_tenants === 'true' ? true : false,
            recovery_details: Object.fromEntries(
              Object.entries(dataClone.recovery_details).map((item) => {
                if (item[1] === 'true') {
                  return [item[0], true];
                }
                if (item[1] === 'false') {
                  return [item[0], false];
                }
                return item;
              }),
            ),
          }
        : {
            ...dataClone,
            is_entered_all_tenants: dataClone.is_entered_all_tenants === 'true' ? true : false,
          },
    );
  });

  return (
    <AddProtectAndCollectForm
      onSubmit={onSubmit}
      register={register}
      fields={fields}
      control={control}
      append={append}
      remove={remove}
      watch={watch}
      setFiles={setFiles}
      files={files}
      loading={loading}
    />
  );
};

export default AddProtectCollectForm;
