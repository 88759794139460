import Section from '@components/section/section.component'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import styles from './debtor-other-section.module.scss'
import SubSection from '@containers/Recovery/Wip/components/UI/sub-section/sub-section.component'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import DebtorOtherTable from '../../tables/debtor-other-table/debtor-other-table.component'
import { FC, useState } from 'react'
import { useModal } from '@src/hooks/useModal'
import CreateDebtorOtherModal from '../../modals/debtor-other-modal/create-debtor-other-modal.component'
import {
  DebtorOther,
  DebtorOthersSectionProps,
} from '@containers/Recovery/Wip/_modules/Debtors/types/debtor-associate-detail.type'
import EditDebtorOtherModal from '../../modals/debtor-other-modal/edit-debtor-other-modal.component'

const DebtorOthersSection: FC<DebtorOthersSectionProps> = ({ others, debtorId }) => {
  const [isOpen, handleOpen, handleClose] = useModal(false)
  const [isEditModalOpen, handleEditModalOpen, handleEditModalClose] = useModal(false)
  const [selectedDebtorOther, setSelectedDebtorOther] = useState<DebtorOther | null>(null)

  const handleItemClick = (other: DebtorOther) => {
    setSelectedDebtorOther(other)
    handleEditModalOpen()
  }

  return (
    <Section>
      <SubSection className={styles.headerWrapper} mb={4} p={4}>
        <Header>List Other</Header>
        <SubmitButton buttonType="button" onClick={handleOpen}>
          Add Other
        </SubmitButton>
      </SubSection>
      <DebtorOtherTable others={others} debtorId={debtorId} onItemClick={handleItemClick} />
      <CreateDebtorOtherModal debtorId={debtorId} isOpen={isOpen} onClose={handleClose} />
      {selectedDebtorOther && (
        <EditDebtorOtherModal
          debtorId={debtorId}
          debtorOther={selectedDebtorOther}
          isOpen={isEditModalOpen}
          onClose={handleEditModalClose}
        />
      )}
    </Section>
  )
}

export default DebtorOthersSection
