import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';

import {
  editCommercialUncoveredFormSelector,
  commercialUncoveredFormByIdSelector,
} from '../../../../redux/selector/selector';
import {
  editCommercialUncoveredForm,
  getCommercialUncoveredFormById,
} from '../../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';

import { commercialUncoveredFormInitialValues } from '../../../../shared/utils/CommercialUncoveredFormInitialValues';
import { commercialUncoveredFormValidationSchema } from '../../../../shared/validators/commercialUncoveredFormValidator';

import { Grid } from '@mui/material';
import CommercialUncoveredForm from '../CommercialUncoveredForm';
import Loader from './../../../loading/loading';

import { commercialUncoveredStyles } from '../styles';

const EditCommercialUncoveredForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  const { loading } = useSelector(editCommercialUncoveredFormSelector);
  const itemById = useSelector(commercialUncoveredFormByIdSelector);

  const { values, errors, touched, submitForm, setFieldValue, handleChange, setValues } = useFormik(
    {
      initialValues: commercialUncoveredFormInitialValues,
      validationSchema: commercialUncoveredFormValidationSchema,
      validateOnChange: true,
      enableReinitialize: true,
      onSubmit: () => {
        dispatch(editCommercialUncoveredForm(id, values))
          .then(() => {
            toast.success('Edited Successfully');
            history.push('/recoveries/forms/commercial');
          })
          .catch(() => {
            toast.error('Something went wrong!');
          });
      },
    },
  );

  useEffect(() => {
    if (id) {
      dispatch(getCommercialUncoveredFormById(id))
        .then((res) => {
          setValues({ ...res });
        })
        .catch(() => {
          toast.error('Something went wrong!');
        });
    } else {
      toast.error('Something went wrong!');
    }
  }, [id]);

  return (
    <Grid item className={'container page-body-wrapper position-relative pb-4'} md={12} lg={12}>
      <h3 className="page-title mb-5">Edit Commercial Uncovered Form</h3>
      <Grid item sx={commercialUncoveredStyles?.card}>
        {itemById?.loading ? (
          <Loader />
        ) : (
          <CommercialUncoveredForm
            handleChange={handleChange}
            submitForm={submitForm}
            setFieldValue={setFieldValue}
            values={values}
            errors={errors}
            touched={touched}
            loading={loading}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default EditCommercialUncoveredForm;
