import PaymentTrackingForm from './payment-tracking-form.component'
import { FC, useState } from 'react'
import { useCreatePaymentTrackingMutation } from '../../hooks/mutations/crud-payment-tracking.hook'
import {
  CreatePaymentTrackingData,
  CreatePaymentTrackingFormProps,
  PaymentTrackingFormValues,
} from '../../../types/payment-tracking.type'
import { useUserContext } from '@src/context/user-context'
import { parseStringToNumber } from '@containers/Recovery/Wip/utils/format-currency'
import { toast } from 'react-toastify'
import { add } from 'date-fns'

const CreatePaymentTrackingForm: FC<CreatePaymentTrackingFormProps> = ({
  debtor,
  wipId,
  onSuccessSubmit,
}) => {
  const [initialValues] = useState<PaymentTrackingFormValues>({
    amount: '',
    trackDayPeriod: null,
    firstPaymentDue: add(new Date(), { days: 1 }),
    from: null,
  })
  const { user } = useUserContext()
  const { mutateAsync, isLoading } = useCreatePaymentTrackingMutation()
  const handleSubmit = async (data: PaymentTrackingFormValues) => {
    const createData: CreatePaymentTrackingData = {
      debtorId: debtor.id,
      wipId: wipId,
      amount: Math.round(parseStringToNumber(data.amount)),
      trackDayPeriod: data.trackDayPeriod!.value,
      firstPaymentDue: data.firstPaymentDue,
      from: data.from!.value,
      assigned_staff_tracking: user!.id,
    }
    try {
      const payTracking = await mutateAsync(createData)
      onSuccessSubmit && onSuccessSubmit(payTracking)
    } catch (e) {
      toast.error('Failed to create payment tracking')
      console.error(e)
    }
  }

  return (
    <PaymentTrackingForm
      onSubmit={handleSubmit}
      loading={isLoading}
      initialValues={initialValues}
    />
  )
}

export default CreatePaymentTrackingForm
