import Modal from '@components/modal'
import { FC } from 'react'
import AddClaimDetailNoteForm from '../../forms/add-claim-detail-note-form/add-claim-detail-note-form.component'
import { AddClaimDetailNoteModalProps } from '../../../types/claim-detail.type'

const AddClaimDetailNoteModal: FC<AddClaimDetailNoteModalProps> = ({ wipId, onClose, isOpen }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      withBtns={false}
      style={{ width: 400, height: 'fit-content' }}
    >
      <AddClaimDetailNoteForm wipId={wipId} onSuccess={onClose} />
    </Modal>
  )
}

export default AddClaimDetailNoteModal
