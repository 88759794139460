import { getCommercialUncoveredByIdActionEnum } from '../actionTypes';
import { getCommercialUncoveredFormByIdService } from '../service';

export const getCommercialUncoveredFormById = (id) => async (dispatch) => {
  dispatch({
    type: getCommercialUncoveredByIdActionEnum.IS_LOADING_GET_COMMERCIAL_UNCOVERED_BY_ID,
  });
  try {
    const res = await getCommercialUncoveredFormByIdService(id);

    dispatch({
      type: getCommercialUncoveredByIdActionEnum.GET_COMMERCIAL_UNCOVERED_BY_ID_SUCCESS,
      payload: res,
    });

    return res;
  } catch (error) {
    dispatch({
      type: getCommercialUncoveredByIdActionEnum.GET_COMMERCIAL_UNCOVERED_BY_ID_ERROR,
      payload: error,
    });

    throw error;
  }
};
