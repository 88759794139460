import { Params } from "@src/types/service.type"
import { DebtorLegalDefendantService } from "../../services/debtor-legal-defendant.service"
import { CreateDebtorLegalDefendantData } from "../../types/debtor-legal-defendant.type"
import { useMutation } from "@tanstack/react-query"
import { DeepPartial } from "react-hook-form"
import { DebtorLegalDefendant } from "../../types/debtor-legal.type"


export const useCreateDebtorLegalDefendantMutation = () => {
  const createDebtorLegalDefendant = async (data: CreateDebtorLegalDefendantData, params?: Params) => {
    const debtorLegalDefendantService = new DebtorLegalDefendantService()
    return debtorLegalDefendantService.createDebtorLegalDefendant(data, params)
  }

  const mutation = useMutation({
    mutationFn: ({ data, params }: { data: CreateDebtorLegalDefendantData, params?: Params }) => createDebtorLegalDefendant(data, params)
  })

  return mutation
}

export const useUpdateDebtorLegalDefendantMutation = () => {
  const updateDebtorLegalDefendant = async (debtorLegalDefendantId: number, data: DeepPartial<DebtorLegalDefendant>, params?: Params) => {
    const debtorLegalDefendantService = new DebtorLegalDefendantService()
    return debtorLegalDefendantService.updateDebtorLegalDefendant(debtorLegalDefendantId, data, params)
  }

  const mutation = useMutation({
    mutationFn: ({ debtorLegalDefendantId, data, params }: { debtorLegalDefendantId: number, data: DeepPartial<DebtorLegalDefendant>, params?: Params }) => updateDebtorLegalDefendant(debtorLegalDefendantId, data, params)
  })

  return mutation
}

export const useDeleteDebtorLegalDefendantMutation = () => {
  const deleteDebtorLegalDefendant = async (debtorLegalDefendantId: number, params?: Params) => {
    const debtorLegalDefendantService = new DebtorLegalDefendantService()
    return debtorLegalDefendantService.deleteDebtorLegalDefendant(debtorLegalDefendantId, params)
  }

  const mutation = useMutation({
    mutationFn: ({ debtorLegalDefendantId, params }: { debtorLegalDefendantId: number, params?: Params }) => deleteDebtorLegalDefendant(debtorLegalDefendantId, params)
  })

  return mutation
}