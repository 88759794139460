import { ColumnMatchField, PropertyStatus } from '../types/property.type'
import Joi from 'joi'
import { SelectOption } from '@src/types/component-types'
import { DataGridColumn } from '@components/data-grid/data-grid.type'
import { AustralianState } from '@src/lib/select-options.const'

export enum PropertyColumn {
  OWNER = 'owner',
  OWNER_FIRST_NAME = 'firstName',
  OWNER_LAST_NAME = 'lastName',
  OWNER_ENTITY_NAME = 'entityName',
  OWNER_MIDDLE_NAME = 'middleName',
  OWNER_EMAIL = 'ownerEmail',
  OWNER_PHONE = 'ownerPhone',
  PROPERTY = 'property',
  PROPERTY_ADDRESS_1 = 'address1',
  PROPERTY_ADDRESS_2 = 'address2',
  PROPERTY_ADDRESS_3 = 'address3',
  PROPERTY_SUBURB = 'suburb',
  PROPERTY_STATE = 'state',
  PROPERTY_POSTCODE = 'postcode',
  ADDITIONAL_LANDLORD = 'additionalLandlord',
}

export const COLUMN_TO_MATCH = [
  PropertyColumn.OWNER_FIRST_NAME,
  PropertyColumn.OWNER_LAST_NAME,
  PropertyColumn.OWNER_EMAIL,
  PropertyColumn.PROPERTY_ADDRESS_1,
  PropertyColumn.PROPERTY_ADDRESS_2,
  PropertyColumn.PROPERTY_ADDRESS_3,
  PropertyColumn.PROPERTY_SUBURB,
  PropertyColumn.PROPERTY_STATE,
]

export const PROPERTY_DATA_GRID_COLUMNS_MAP: Record<PropertyColumn, DataGridColumn> = {
  [PropertyColumn.OWNER_FIRST_NAME]: {
    label: 'First Name',
    name: PropertyColumn.OWNER_FIRST_NAME,
    type: 'input',
    validation: Joi.string(),
  },
  [PropertyColumn.OWNER_LAST_NAME]: {
    label: 'Last Name',
    name: PropertyColumn.OWNER_LAST_NAME,
    type: 'input',
    validation: Joi.string(),
  },
  [PropertyColumn.OWNER_MIDDLE_NAME]: {
    label: 'Middle Name',
    name: PropertyColumn.OWNER_MIDDLE_NAME,
    type: 'input',
    validation: Joi.string(),
  },
  [PropertyColumn.OWNER_ENTITY_NAME]: {
    label: 'Entity Name',
    name: PropertyColumn.OWNER_ENTITY_NAME,
    type: 'input',
    validation: Joi.string(),
  },
  [PropertyColumn.ADDITIONAL_LANDLORD]: {
    label: 'Additional Landlord ',
    name: PropertyColumn.ADDITIONAL_LANDLORD,
    type: 'input',
    validation: Joi.string(),
  },
  [PropertyColumn.OWNER_EMAIL]: {
    label: 'Email',
    name: PropertyColumn.OWNER_EMAIL,
    type: 'input',
    validation: Joi.string(),
  },
  [PropertyColumn.OWNER_PHONE]: {
    label: 'Phone',
    name: PropertyColumn.OWNER_PHONE,
    type: 'input',
    validation: Joi.string(),
  },
  [PropertyColumn.PROPERTY_ADDRESS_1]: {
    label: 'Address 1',
    name: PropertyColumn.PROPERTY_ADDRESS_1,
    type: 'input',
    validation: Joi.string(),
  },
  [PropertyColumn.PROPERTY_ADDRESS_2]: {
    label: 'Address 2',
    name: PropertyColumn.PROPERTY_ADDRESS_2,
    type: 'input',
    validation: Joi.string(),
  },
  [PropertyColumn.PROPERTY_ADDRESS_3]: {
    label: 'Address 3',
    name: PropertyColumn.PROPERTY_ADDRESS_3,
    type: 'input',
    validation: Joi.string().allow(''),
  },
  [PropertyColumn.PROPERTY_SUBURB]: {
    label: 'Suburb',
    name: PropertyColumn.PROPERTY_SUBURB,
    type: 'input',
    validation: Joi.string(),
  },
  [PropertyColumn.PROPERTY_STATE]: {
    label: 'State',
    name: PropertyColumn.PROPERTY_STATE,
    type: 'input',
    validation: Joi.string().valid(...Object.values(AustralianState)),
  },
  [PropertyColumn.PROPERTY_POSTCODE]: {
    label: 'Postcode',
    name: PropertyColumn.PROPERTY_POSTCODE,
    type: 'input',
    validation: Joi.number(),
  },
  [PropertyColumn.OWNER]: {
    label: 'Owner',
    name: PropertyColumn.OWNER,
    type: 'input',
    validation: Joi.string(),
  },
  [PropertyColumn.PROPERTY]: {
    label: 'Property',
    name: PropertyColumn.PROPERTY,
    type: 'input',
    validation: Joi.string(),
  },

}

export const COLUMN_TO_MATCH_OPTIONS = [
  {
    value: PropertyColumn.OWNER_FIRST_NAME,
    label: 'Owner First Name',
  },
  {
    value: PropertyColumn.OWNER_LAST_NAME,
    label: 'Owner Last Name',
  },
  {
    value: PropertyColumn.OWNER_MIDDLE_NAME,
    label: 'Owner Middle Name',
  },
  {
    value: PropertyColumn.OWNER_EMAIL,
    label: 'Owner Email',
  },
  {
    value: PropertyColumn.OWNER_PHONE,
    label: 'Owner Phone',
  },
  {
    value: PropertyColumn.PROPERTY_ADDRESS_1,
    label: 'Property Address 1',
  },
  {
    value: PropertyColumn.PROPERTY_ADDRESS_2,
    label: 'Property Address 2',
  },
  {
    value: PropertyColumn.PROPERTY_ADDRESS_3,
    label: 'Property Address 3',
  },
  {
    value: PropertyColumn.PROPERTY_SUBURB,
    label: 'Property Suburb',
  },
  {
    value: PropertyColumn.PROPERTY_STATE,
    label: 'Property State',
  },
  {
    value: PropertyColumn.PROPERTY_POSTCODE,
    label: 'Property Postcode',
  },
]

export const PROPERTY_MATCH_FIELDS: ColumnMatchField[] = [
  {
    fieldName: 'address1',
    fieldType: 'input',
  },
  {
    fieldName: 'address2',
    fieldType: 'input',
  },
  {
    fieldName: 'address3',
    fieldType: 'input',
  },
  {
    fieldName: 'suburb',
    fieldType: 'input',
  },
  {
    fieldName: 'state',
    fieldType: 'input',
  },
  {
    fieldName: 'postcode',
    fieldType: 'input',
  },
]

export const OWNER_MATCH_FIELDS: ColumnMatchField[] = [
  {
    fieldName: 'firstName',
    fieldType: 'input',
  },
  {
    fieldName: 'middleName',
    fieldType: 'input',
  },
  {
    fieldName: 'lastName',
    fieldType: 'input',
  },
  {
    fieldName: 'entityName',
    fieldType: 'input',
  },
  {
    fieldName: 'additionalLandlord',
    fieldType: 'input',
  },
]

export const PROPERTY_MATCH_FIELD_MAP = {
  [PropertyColumn.PROPERTY]: PROPERTY_MATCH_FIELDS,
  [PropertyColumn.OWNER]: OWNER_MATCH_FIELDS,
}


export const PROPERTY_STATUS_OPTIONS: SelectOption<PropertyStatus>[] = [
  { label: 'Current - Renewal', value: PropertyStatus.CurrentRenewal },
  { label: 'Current - Opt', value: PropertyStatus.CurrentOpt },
  { label: 'Current - Cover Note', value: PropertyStatus.CurrentCoverNote },
  { label: 'Declined - l\'lord', value: PropertyStatus.DeclinedLandLord },
  { label: 'Declined - agent', value: PropertyStatus.DeclinedAgent },
  { label: 'Cancelled - l\'lord', value: PropertyStatus.CancelledLandLord },
  { label: 'Cancelled - agent', value: PropertyStatus.CancelledAgent },
  { label: 'Refunded', value: PropertyStatus.Refunded },
  { label: 'Sold', value: PropertyStatus.Sold },
  { label: 'Nlmba', value: PropertyStatus.Nlmba },
]