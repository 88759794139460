import React from 'react';
import { reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import Services from '../../../../../shared/Services.js';
import { withRouter } from 'react-router';
import AddNotes from './AddNotes.jsx';
import ListNotes from './ListNotes.jsx';
import NotesService from '../../../../../services/NotesServices';

class Notes extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      id: localStorage.getItem('contactsId'),
      showAddNotes: false,
      allApp_type: [],
      editId: '',
      debtorId: localStorage.getItem('debtorId'),
      reloading: {},
      staffData:[],
    };
    this.note = new NotesService();
    this.services = new Services();
    this.editNodeList = this.editNodeList.bind(this);
    this.reloadList = this.reloadList.bind(this);
  }

  componentDidMount() {
    this.getNotesApplicationType();
    this.getDebtorNotes()
  }
  getDebtorNotes() {
    const params = {
      join: 'debtor',
      filter: `debtor.id||$eq||${this.state.debtorId}`,
      limit: 10000,
    };
    this.note.getDebtorNotes(params).then((res) => {
      if (res.status === 200) {
        res.data.data.forEach((val) => {
          if (val.is_important === true) {
            res.data.data.sort(function(x, y) {
              return x === val ? -1 : y === val ? 1 : 0;
            });
          }
        });
        this.setState({ staffData: res.data.data });
      } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
        this.setState({ accessErrorModal: true });
      }
    });
  }

  getNotesApplicationType() {
    this.note.getNotesAppType().then((res) => {
      if (res.status === 200) {
        const type = res.data.data;
        this.setState({
          allApp_type: type.map(item => {return { value: item.id, label: item.name }}),
        });
        this.forceUpdate();
      } else {
        this.services.errorToast(res.message);
      }
    });
  }

  addNodeList = () => {
    localStorage.setItem('note_Id', '');
    this.setState({
      showAddNotes: true,
    });
  };

  editNodeList = (id) => {
    const self = this;
    localStorage.setItem('note_Id', id);
    setTimeout(() => {
      self.setState({
          showAddNotes: false,
          editId: id,
        },
        function() {
          self.setState({
            showAddNotes: true,
            editId: id,
          });
        });
    }, 100);
  };

  cancle = () => {
    this.setState({
      showAddNotes: false,
    });
  };

  reloadList = (formData) => {
    this.props.history.push(`/contacts-notes/${this.state.id}`);
    this.setState({ reloading: formData })
  };

  render() {
    return (
      <>
        <h3 className='page-title'>
          Debtor file notes
        </h3>
        <div className='tabs_wrapper card-body mb-3'>
          <AddNotes cancle={this.cancle}
                    reloadList={this.reloadList}
                    allApp_type={this.state.allApp_type}
                    note_Id={this.state.editId}
                    staffData={ this.state.staffData}
          />
        </div>
        <div className='tabs_wrapper card-body'>
          <ListNotes addNodeList={this.addNodeList}
                     editNodeList={(id) => this.editNodeList(id)}
                     allApp_type={this.state.allApp_type}
                     reloading={this.state.reloading}
                     staffData={ this.state.staffData}
          />
        </div>
      </>
    );
  }
}

export default reduxForm({
  form: 'add_staff_form_validation', // a unique identifier for this form
})(withRouter(withTranslation('common')(Notes)));
