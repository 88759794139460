import React, { Component } from 'react';
import { Col, Modal, Row } from 'reactstrap';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import SimpleReactValidator from 'simple-react-validator';
//import ModalComponent from "../../shared/components/ModalCustom";
import moment from 'moment';
import Services from '../../../shared/Services';
import TasksService from '../../../services/TasksService';
import ModalComponent from '../../../shared/components/ModalCustom';
import Geosuggest from 'react-geosuggest';
import { withRouter } from 'react-router';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { Tab, Tabs } from '@material-ui/core';


const dayAppointmentList = [{ value: '8:00', label: '8:00' }, { value: '8:30', label: '8:30' },
  { value: '9:00', label: '9:00' }, { value: '9:30', label: '9:30' },
  { value: '10:00', label: '10:00' }, { value: '10:30', label: '10:30' },
  { value: '11:00', label: '11:00' }, { value: '11:30', label: '11:30' },
  { value: '12:00', label: '12:00' }, { value: '12:30', label: '12:30' },
  { value: '13:00', label: '13:00' }, { value: '13:30', label: '13:30' },
  { value: '14:00', label: '14:00' }, { value: '14:30', label: '14:30' },
  { value: '15:00', label: '15:00' }, { value: '15:30', label: '15:30' },
  { value: '16:00', label: '16:00' }, { value: '16:30', label: '16:30' },
  { value: '17:00', label: '17:00' }, { value: '17:30', label: '17:30' }];

const repeatTypeOption = [{ value: '0', label: 'None' }, { value: '1', label: 'Daily' },
  { value: '2', label: 'Weekly' }, { value: '3', label: 'Monthly (by Day)' }, {
    value: '4',
    label: 'Monthly (by Date)',
  },
  { value: '5', label: 'Yearly' }];


class AddTaskModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // id:localStorage.getItem("contactsId"),
      task_id: '',
      task_type: { value: 'Meeting', label: 'Meeting' },
      wip_no: '',
      title: '',
      internal: false,
      staff: [],
      client: '',
      sDate: this.props.newDate ? this.props.newDate : new Date(),
      sTimeH: '',
      sTimeM: '',
      timeTypeS: { value: 'AM', label: 'AM' },
      eDate: new Date(),
      eTimeH: '',
      eTimeM: '',
      timeTypeE: { value: 'AM', label: 'AM' },
      rType: { value: '0', label: 'None' },
      priority: '',
      eSubject: '',
      eDescription: '',
      location: '',
      guest: '',
      smaReminder: { value: '0', label: '0' },
      dayAppointment: { value: '8:00', label: '8:00' },
      useEnddate: false,
      allClient: [],
      allStaff: [],
      textSMS: '',
      toNumber: '',
      isEdit: false,
      mainType: this.props.mainType || 'RECOVERY',
      status: { value: 'To Do', label: 'To Do' },
      startTime: '',
      endTime: '',
      taskSubject: '',
      allDataStaff: '',
      calanderType: { value: 'Appointment', label: 'Appointment' },
      meet_with: '',

      errorModal: false,
      errorMessage: '',
      errorType: '',

      rpt_use_end: false,
      rpt_day: [{ name: 'Monday', checked: false }, { name: 'Tuesday', checked: false }, {
        name: 'Wednesday',
        checked: false,
      }, { name: 'Thursday', checked: false }, { name: 'Friday', checked: false }, {
        name: 'Saturday',
        checked: false,
      }, { name: 'Sunday', checked: false }],
      recur_enddate: new Date(),

      emailList: [],
      assignto: '',
      valueTab: 0,
    };
    this.addTask = new TasksService();
    this.services = new Services();
    this.changeInput = this.changeInput.bind(this);
    this.saveTask = this.saveTask.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.validator = new SimpleReactValidator();
    this.validatorEmail = new SimpleReactValidator();
    this.validatorSMS = new SimpleReactValidator();

    // this.handleSelectEmail = this.handleSelectEmail.bind(this)
  }

  componentDidMount() {
    this.getAllClient();
    this.getAllstaff();
  }

  addRecord() {
    let time;
    let str;
    let date;
    if (this.props.data) {
      const { data, isEdit } = this.props;
      let startDate;
      let endDate;
      if (data.start_date) {
        date = moment(data.start_date).format('DD-MM-YY');

        if (date === 'Invalid date') {
          startDate = new Date(data.start_date.slice(0, data.start_date.length - 2).replace(/\//g, '-'));
        } else {
          startDate = new Date(date);
        }
      } else {
        startDate = new Date();
      }
      if (startDate === 'Invalid Date' || date === 'Invalid date') {
        startDate = new Date();
      }
      if (data.end_date) {
        date = moment(data.end_date).format('DD-MM-YY');
        if (date === 'Invalid date') {
          endDate = new Date(data.end_date.slice(0, data.end_date.length - 2).replace(/\//g, '-'));
        } else {
          endDate = new Date(date);
        }
      } else {
        endDate = new Date();
      }
      if (endDate === 'Invalid Date' || date === 'Invalid date') {
        endDate = new Date();
      }
      let startTime = '';
      if (this.props.data.ev_start_time) {
        str = moment();
        time = this.props.data.ev_start_time.split(':');
        if (time && time.length > 1) {
          startTime = str.set({ h: time[0], m: time[1].split(':')[0], a: time[1].split('-')[1] });
        }
      }
      let endTime = '';
      if (this.props.data.ev_end_time) {
        str = moment();
        time = this.props.data.ev_end_time.split(':');
        if (time && time.length > 1) {
          endTime = str.set({ h: time[0], m: time[1].split(':')[0], a: time[1].split('-')[1] });
        }
      }
      let selectedStaff;
      selectedStaff = data.staff && data.staff.map(s => {
        return { label: s.staff_nm, value: s.staff_id };
      });
      let selectedClient = [];
      if (data.client_input && typeof data.client_input === 'object') {
        selectedClient = data.client_input && data.client_input.map(s => {
          return { label: s.client_input, value: s.client_input_id };
        });
      }
      if (data.rpt_day) {
        this.state.rpt_day.map(item => {
          data.rpt_day.map(sub => {
            if (sub === item.name) {
              item.checked = true;
            }
          });
        });
      }

      let recur_end;
      if (data.recur_enddate) {
        date = moment(data.recur_enddate).format('DD-MM-YY');
        if (date === 'Invalid date') {
          recur_end = new Date(data.recur_enddate.slice(0, data.recur_enddate.length - 2).replace(/\//g, '-'));
        } else {
          recur_end = new Date(date);
        }
      } else {
        recur_end = '';
      }
      if (recur_end === 'Invalid Date' || date === 'Invalid date') {
        recur_end = '';
      }
      // var selectedGuest = [];
      // if(data.guest && typeof data.guest === 'object' ){
      //     data.guest && data.guest.map(s => {
      //         var guest = this.state.allClient.find(_=>_.value === s)
      //         if(guest)
      //         selectedGuest.push(guest)
      //     })
      // }

      this.setState({
        task_id: data._id,
        task_type: { value: data.type, label: data.type },
        wip_no: data.wip,
        title: data.title,
        internal: data.internal,
        staff: selectedStaff,
        client: selectedClient,
        sDate: startDate,
        sTimeH: data.ev_start_time && data.ev_start_time.split(':')[0],
        sTimeM: data.ev_start_time && data.ev_start_time.split(':')[1].split('-')[0],
        timeTypeS: {
          value: data.ev_start_time && data.ev_start_time.split('-')[1],
          label: data.ev_start_time && data.ev_start_time.split('-')[1],
        },
        eDate: endDate,
        eTimeH: data.ev_end_time && data.ev_end_time.split(':')[0],
        eTimeM: data.ev_end_time && data.ev_end_time.split(':')[1].split('-')[0],
        timeTypeE: {
          value: data.ev_end_time && data.ev_end_time.split('-')[1],
          label: data.ev_end_time && data.ev_end_time.split('-')[1],
        },
        rType: repeatTypeOption.find(_ => _.value === data.recur_type) || this.state.rType,
        priority: { value: data.priority, label: data.priority },
        eSubject: data.email_subject,
        eDescription: data.email_description,
        location: data.email_location,
        // guest: selectedGuest,
        smaReminder: { value: data.csms_days, label: data.csms_days },
        dayAppointment: { value: data.csms_time, label: data.csms_time },
        useEnddate: data.rpt_use_end,
        textSMS: data.csms_msg,
        toNumber: data.csms_to,
        isEdit: isEdit,
        mainType: data.main_type,
        status: { value: data.status, label: data.status },
        startTime: startTime,
        endTime: endTime,
        taskSubject: data.subject,
        calanderType: { value: data.calender_type, label: data.calender_type },
        meet_with: data.meet_with,
        rpt_use_end: data.rpt_use_end,
        recur_enddate: recur_end || '',
        assignto: this.state.staff.find(_ => _.value === data.assignto),
      });
    }
  }

  getAllstaff() {
    let url = 'Staff/getDebtor';
    const data = {};
    this.services.getStaff(data, url, (res) => {
      if (res.status === 200) {
        const getData = res.data.data.list;
        let listStaff;
        listStaff = getData && getData.map((item) => {
          return { value: item.id, label: `${item.first_name}  ${item.last_name}` };
        });

        this.setState({ allStaff: listStaff, allDataStaff: getData });
        this.addRecord();
      } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
        this.setState({ accessErrorModal: true });
      }
    });
  }

  getAllClient() {
    this.services.getallClient((res) => {
      if (res.status === 200) {
        const getData = res.data.data;
        let listClient;
        listClient = getData && getData.map((item) => {
          return { value: item._id, label: item.name };
        });
        this.setState({
          allClient: listClient,
        });
        this.addRecord();

      } else if (
        res.statusCode === 401 &&
        res.responseType === 'UNAUTHORIZED'
      ) {
        this.setState({ accessErrorModal: true });
      } else {
        this.services.errorToast(res.message);
      }
    });
  }

  closeModal = () => {
    this.setState({ task_id: '' });
    this.props.closeModal();
    this.clear();
  };

  clear = () => {
    this.setState = {
      task_id: '',
      task_type: { value: 'Meeting', label: 'Meeting' },
      wip_no: '',
      title: '',
      internal: false,
      staff: '',
      client: '',
      sDate: this.props.newDate ? this.props.newDate : new Date(),
      sTimeH: '',
      sTimeM: '',
      timeTypeS: { value: 'AM', label: 'AM' },
      eDate: '',
      eTimeH: '',
      eTimeM: '',
      timeTypeE: { value: 'AM', label: 'AM' },
      rType: { value: 'None', label: 'None' },
      priority: '',
      eSubject: '',
      eDescription: '',
      location: '',
      guest: '',
      smaReminder: { value: '0', label: '0' },
      dayAppointment: { value: '8:00', label: '8:00' },
      useEnddate: false,
      allClient: [],
      allStaff: [],
      textSMS: '',
      toNumber: '',
      isEdit: false,
      mainType: this.props.mainType || 'RECOVERY',
      status: { value: 'To Do', label: 'To Do' },
      startTime: '',
      endTime: '',
      taskSubject: '',
      allDataStaff: '',
      calanderType: { value: 'Appointment', label: 'Appointment' },
      meet_with: '',
    };
  };

  handleSelectGeoSuggest = (place) => {
    if (!place) return null;
    this.setState({
      location: [place.label],
    });
  };

  handleChange = (selectedOption, name) => {
    if ((name === 'calanderType') && (selectedOption.value === 'Appointment' || selectedOption.value === 'Task')) {
      this.setState({
        eDate: new Date(),
      });
    } else if ((name === 'calanderType') && (selectedOption.value === 'Note')) {
      this.setState({
        eDate: '',
      });
    }
    this.setState({ [name]: selectedOption });

    if (name === 'staff') {
      this.setState({ assignto: '' });
    }

    if (name === 'guest') {
      const list = [];
      if (selectedOption) {

        selectedOption.map(item => {
          list.push(item.value);
        });
        const data = {
          client_id: JSON.stringify(list),
        };
        this.services.getcontactsfromClient(data, (res) => {
          if (res.status === 200) {
            const emailList = [];
            const resdata = res.data.data;
            if (resdata && resdata.length > 0) {
              resdata.map(item => {
                if (item && item.contact_det) {
                  item.contact_det.map(email => {
                    if (email.email)
                      emailList.push({
                        _id: item._id,
                        email: email.email,
                        name: item.name,
                        email_id: '_' + Math.random().toString(36).substr(2, 9),
                      });
                  });
                }
              });
            }
            this.setState({ emailList: emailList });

          } else if (
            res.statusCode === 401 &&
            res.responseType === 'UNAUTHORIZED'
          ) {
            this.setState({ accessErrorModal: true });
          } else {
            this.services.errorToast(res.message);
          }
        });
      }
    }
  };


  changeInput(e) {
    if (!e.target.validity.valid) {
      return null;
    }
    this.setState({ [e.target.name]: e.target.value });
  }

  handleChangeDate = (date, name) => {
    this.setState({
      [name]: date,
    });
    if (name === 'recur_enddate') {
      this.setState({ recur_enddate: date });
    }
  };
  handleOptionChange = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };
  onTimeChange = (e, name) => {
    this.setState({ [name]: e });
    // const data = '12:45-AM';
    //const str = moment();
    //const d = str.set({h: 12, m: 15, a: 'am'});
  };

  EmailInvite = () => {
    if (this.validatorEmail.allValid()) {
      const startDate = moment(this.state.sDate);
      const time = this.state.startTime.format('HH:mm:A');
      const splitTime = time.split(':');
      let finalTime;
      let endTemp = undefined;
      let temp;
      if (splitTime && splitTime.length > 1) {
        finalTime = startDate.set({ h: splitTime[0], m: splitTime[1], a: splitTime[2] });
      } else {
        finalTime = moment();
      }
      const dateS = new Date(finalTime._d);
      const dateNewS = new Date(dateS.getTime() + dateS.getTimezoneOffset() * 60 * 1000);
      const d = {
        y: dateNewS.getFullYear(),
        m: dateNewS.getMonth(),
        d: dateNewS.getDate(),
        h: dateNewS.getHours(),
        mi: dateNewS.getMinutes(),
      };
      temp = `${d.y}${d.m < 10 ? '0' + d.m : d.m}${d.d}T${d.h < 10 ? '0' + d.h : d.h}${d.mi < 10 ? '0' + d.mi : d.mi}00Z`;

      if (this.state.eDate && this.state.endTime) {
        const endDate = moment(this.state.eDate);

        const timeE = this.state.endTime.format('HH:mm:A');
        const splitTimeE = timeE.split(':');
        let finalTimeE;
        if (splitTimeE && splitTimeE.length > 1) {
          finalTimeE = endDate.set({ h: splitTimeE[0], m: splitTimeE[1], a: splitTimeE[2] });
        } else {
          finalTimeE = moment();
        }
        const dateE = new Date(finalTimeE._d);
        const dateNewE = new Date(dateE.getTime() + dateE.getTimezoneOffset() * 60 * 1000);

        const e = {
          y: dateNewE.getFullYear(),
          m: dateNewE.getMonth(),
          d: dateNewE.getDate(),
          h: dateNewE.getHours(),
          mi: dateNewE.getMinutes(),
        };
        endTemp = `${e.y}${e.m < 10 ? '0' + e.m : e.m}${e.d}T${e.h < 10 ? '0' + e.h : e.h}${e.mi < 10 ? '0' + e.mi : e.mi}00Z`;
      }
      const email = [];
      if (this.state.emailList) {
        this.state.emailList.map(item => {
          if (item && item.checked) {
            email.push({ _id: item._id, email: item.email });
          }
        });
      }

      const data = {
        eventnm: this.state.title,
        time: `${d.y}${d.m < 10 ? '0' + d.m : d.m}${d.d}T${d.h < 10 ? '0' + d.h : d.h}${d.mi < 10 ? '0' + d.mi : d.mi}00Z`,
        subject: this.state.eSubject,
        event_details: this.state.eDescription,
        // client_id:this.state.guest && this.state.guest.value,
        email: JSON.stringify(email),
        location: this.state.location,
        endtime: endTemp ? endTemp : temp,
      };
      this.services.sendInvitationfromTask(data, (res) => {
        if (res.status === 200) {
          this.services.successToast('Email Sent Successfully');
        } else if (
          res.statusCode === 401 &&
          res.responseType === 'UNAUTHORIZED'
        ) {
          this.setState({ accessErrorModal: true });
        } else {
          this.services.errorToast(res.message);
        }
      });

    } else {
      this.validatorEmail.showMessages();
      this.forceUpdate();
    }
  };

  SendSMS = () => {
    if (this.validatorSMS.allValid()) {

    } else {
      this.validatorSMS.showMessages();
      this.forceUpdate();
    }
  };
  // OpenDeleteModal = (e, d) => {
  //   this.setState({
  //     errorMessage: "Are you sure you want to delete this record?",
  //     errorModal: true,
  //     errorType: "danger"
  //   })
  // }
  deleteTask = () => {
    if (this.state.status && this.state.status.value === 'Completed') {
      const data = {
        isdel: true,
        task_id: this.state.task_id,
        status: 'Completed',
      };
      this.services.editDashBoardTask(data, (res) => {
        if (res.status === 200) {
          this.services.successToast('Task Deleted Successfully');
          this.setState({
            errorMessage: '',
            errorModal: false,
            errorType: '',
          });
          this.closeModal();
        } else if (
          res.statusCode === 401 &&
          res.responseType === 'UNAUTHORIZED'
        ) {
          this.setState({ accessErrorModal: true });
        } else {
          this.services.errorToast(res.message);
        }
      });
    }

  };

  saveTask(isCopy) {
    let self;
    const staffList = [];
    const clientList = [];
    //const guestList = [];
    const all = this.state.allDataStaff;
    if (this.state.staff) {
      this.state.staff.map(item => {
        const color = all.find(_ => _._id === item.value);
        const list = {
          staff_nm: item.label,
          staff_id: item.value,
          dis_color: color && color.dis_color,
        };
        staffList.push(list);
      });
    }
    if (this.state.client) {
      this.state.client.map(item => {
        const list = {
          client_input_id: item.value,
          client_input: item.label,
        };
        clientList.push(list);
      });
    }

    const dayList = [];
    this.state.rpt_day.map(item => {
      if (item.checked) {
        dayList.push(item.name);
      }
    });

    // if (this.state.guest) {
    //     this.state.guest.map(item => {
    //         if (item.value) {
    //             guestList.push(item.value)
    //         }
    //     })
    // }
    const data = {
      //client_id : this.state.id,
      type: this.state.task_type && this.state.task_type.value,
      // main_type:this.state.mainType,
      wip: this.state.wip_no,
      title: this.state.title,
      internal: this.state.internal,
      staff: JSON.stringify(staffList),
      //  client_input_id: this.state.client && this.state.client.value,
      client_input: JSON.stringify(clientList),
      start_date: this.state.sDate && moment(this.state.sDate).format('DD-MM-YY'),
      end_date: this.state.eDate && moment(this.state.eDate).format('DD-MM-YY'),
      ev_start_time: this.state.startTime ? moment(this.state.startTime).format('hh:mm:A') : '',
      ev_end_time: this.state.endTime ? moment(this.state.endTime).format('hh:mm:A') : '',
      recur_type: this.state.rType && this.state.rType.value,
      priority: this.state.priority && this.state.priority.value,
      // rpt_use_end: this.state.useEnddate,
      email_subject: this.state.eSubject,
      email_description: this.state.eSubject,
      email_location: this.state.location,
      // guest: JSON.stringify(guestList),
      csms_days: this.state.smaReminder && parseInt(this.state.smaReminder.value),
      csms_time: this.state.dayAppointment && this.state.dayAppointment.value,
      csms_to: this.state.toNumber,
      csms_msg: this.state.textSMS,
      // status: this.state.status && this.state.status.value,
      subject: this.state.taskSubject,
      calender_type: this.state.calanderType.value,
      meet_with: this.state.meet_with,
      recur_enddate: this.state.recur_enddate && moment(this.state.recur_enddate).format('DD-MM-YY'),
      rpt_use_end: this.state.rpt_use_end,
      rpt_day: JSON.stringify(dayList),
      assignto: this.state.assignto && this.state.assignto.value,
    };
    if (this.state.calanderType && this.state.calanderType.label === 'Task') {
      data.status = this.state.status && this.state.status.value || 'To Do';
    }

    if (this.state.calanderType && this.state.calanderType.label === 'Note') {
      if (this.validator && this.validator.fields) this.validator.fields['End Time'] = true;
      if (this.validator && this.validator.errorMessages) this.validator.errorMessages['End Time'] = null;
    }
    if (this.state.calanderType && this.state.calanderType.label === 'Task' && (this.state.task_type.value !== 'Presentation' ||
      this.state.task_type.value !== 'Catch Up' ||
      this.state.task_type.value !== 'Training' ||
      this.state.task_type.value !== 'Meeting')) {
      if (this.validator && this.validator.fields) this.validator.fields['Meeting With'] = true;
      if (this.validator && this.validator.errorMessages) this.validator.errorMessages['Meeting With'] = null;
    } else if (this.state.calanderType && this.state.calanderType.label !== 'Task') {
      if (this.validator && this.validator.fields) this.validator.fields['Meeting With'] = true;
      if (this.validator && this.validator.errorMessages) this.validator.errorMessages['Meeting With'] = null;
    }

    if (this.validator.allValid()) {
      if (this.state.isEdit && this.state.task_id && isCopy === 'no') {
        data.task_id = this.state.task_id;
        delete data.client_id;
        self = this;
        this.services.editDashBoardTask(data, (res) => {
          if (res.status === 200) {
            this.closeModal();
            this.props.reloadList();
            this.services.successToast('Task Updated Successfully');
          } else if (
            res.statusCode === 401 &&
            res.responseType === 'UNAUTHORIZED'
          ) {
            this.setState({ accessErrorModal: true });
          } else {
            this.services.errorToast(res.message);
          }
        });
      } else {
        self = this;
        this.addTask.addTask(data, (res) => {
          if (res.status === 200) {
            this.services.successToast('Task Added Successfully');
            this.closeModal();
            self.props.reloadList();
          } else if (
            res.statusCode === 401 &&
            res.responseType === 'UNAUTHORIZED'
          ) {
            this.setState({ accessErrorModal: true });
          } else {
            this.services.errorToast(res.message);
          }
        });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  handleSelectEmail = (e, item) => {
    if (item) {
      this.state.emailList.map(el => {
        if (el.email_id === item.email_id) {
          item.checked = e.target.checked;
        }
      });
    }
  };

  handleSelectDay(e) {
    e && this.state.rpt_day && this.state.rpt_day.map(el => {
      if (el.name === e.target.name) {
        el.checked = e.target.checked;
      }
    });
    this.forceUpdate();
  }

  handleUseEndDate = (e) => {
    if (e) {
      if (e.target.checked) {
        this.setState({ recur_enddate: this.state.eDate });
      } else {
        this.setState({ recur_enddate: new Date() });
      }
      this.setState({ rpt_use_end: e.target.checked });
    }
  };

  handleChangeTab = (event, newValue) => {
    this.setState({ valueTab: newValue });
  };

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.addTaskModal} size='lg' style={{ maxWidth: '1000px', width: '95%' }}
          className='theme-light addtaskModal'
        >
          <form className=''>
            <div className='modal__header pt-0' style={{ marginBottom: '25px' }}>
              <div className='flex_jusbt'>
                <h3 className='mb-2'> {this.props.isEdit ? 'Update Your Task' : 'Add Your Task'}</h3>
              </div>
            </div>
            <div className='modal__body'>
              <Tabs indicatorColor='orange'
                    textColor='inherit'
                    variant='fullWidth'
                    value={this.state.valueTab}
                    onChange={this.handleChangeTab} centeredа>
                <Tab className={this.state.valueTab === 0 ? 'btn btn-secondary-active' : 'btn btn-secondary-tab'}
                     label='Task Details' />
                <Tab className='btn btn-secondary-tab' label='Email Guests' />
                <Tab className='btn btn-secondary-tab' label='Send SMS' />
                <Tab className='btn btn-secondary-tab' label='Repeating Event Information' />
              </Tabs>
              <div style={{ marginTop: '5px' }}>
                {this.state.valueTab === 0 &&
                <div className='innerContent'>
                  <Row className='form'>
                    <Col md={6}>
                      <div className='form-group'>
                        <label className='form__form-group-label' htmlFor=''>Calendar
                          Type <span
                            style={{ color: '#ff0000' }} /></label>
                        <Select
                          name='calanderType'
                          type='text'
                          options={[{ value: 'Appointment', label: 'Appointment' }, {
                            value: 'Note',
                            label: 'Note',
                          }, { value: 'Task', label: 'Task' }]}
                          classNamePrefix='react-select'
                          className='react-select'
                          value={this.state.calanderType}
                          onChange={(e) => this.handleChange(e, 'calanderType')}
                        />
                        {/* <span className="form__form-group-error">{this.validator.message('', this.state.task_type, 'required')}</span> */}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className='form-group'>
                        <label className='form__form-group-label' htmlFor=''>WIP Number<span
                          style={{ color: '#ff0000' }} /></label>
                        <input
                          type='text'
                          className='modal_input'
                          name='wip_no'
                          value={this.state.wip_no}
                          onChange={this.changeInput}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className='align-items-center'>
                    <Col md={6}>
                      <div className='form-group'>
                        <label className='form__form-group-label' htmlFor=''>Title<span
                          style={{ color: '#ff0000' }}>*</span></label>
                        <input
                          type='text'
                          className='modal_input'
                          name='title'
                          value={this.state.title}
                          onChange={this.changeInput}
                        />
                        <span
                          className='form__form-group-error'>{this.validator.message('Title', this.state.title, 'required')}</span>
                        <span
                          className='form__form-group-error'>{this.validatorEmail.message('Title', this.state.title, 'required')}</span>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className='form-group'>
                        <label className='form__form-group-label' htmlFor=''>Meeting With<span
                          style={{ color: '#ff0000' }} /></label>
                        <input
                          type='text'
                          className='modal_input'
                          name='meet_with'
                          value={this.state.meet_with}
                          onChange={this.changeInput}
                        />
                        {(this.state.task_type.value === 'Presentation' ||
                          this.state.task_type.value === 'Catch Up' ||
                          this.state.task_type.value === 'Training' ||
                          this.state.task_type.value === 'Meeting') && this.state.calanderType.value === 'Task' &&
                        <span
                          className='form__form-group-error'>{this.validator.message('Meeting with', this.state.meet_with, 'required')}</span>}
                      </div>
                    </Col>
                  </Row>
                  <Row className='form'>
                    <Col md={6}>
                      <div className='form-group'>
                        <label className='form__form-group-label ' htmlFor=''>Staff<span
                          style={{ color: '#ff0000' }} /></label>
                        <Select
                          name='staff'
                          type='text'
                          options={this.state.allStaff}
                          classNamePrefix='react-select'
                          className='react-select height_auto'
                          value={this.state.staff}
                          isMulti
                          onChange={(e) => this.handleChange(e, 'staff')}
                        />
                        {/* <span className="form__form-group-error">{this.validator.message('', this.state.staff, 'required')}</span> */}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className='form-group'>
                        <label className='form__form-group-label ' htmlFor=''>Select
                          Client<span
                            style={{ color: '#ff0000' }} /></label>
                        <Select
                          name='client'
                          type='text'
                          options={this.state.allClient}
                          classNamePrefix='react-select'
                          className='react-select height_auto'
                          value={this.state.client}
                          isMulti
                          onChange={(e) => this.handleChange(e, 'client')}
                        />
                        {/* <span className="form__form-group-error">{this.validator.message('', this.state.client, 'required')}</span> */}
                      </div>
                    </Col>
                  </Row>
                  <Row className='form'>
                    <Col md={6}>
                      <div className='form-group'>
                        <label className='form__form-group-label' htmlFor=''>Start
                          Date</label>
                        <div className='date-picker date-picker--interval'>
                          <DatePicker
                            selected={this.state.sDate}
                            onChange={(e) => this.handleChangeDate(e, 'sDate')}
                            name='sDate'
                            dateFormat='dd/MM/yy'
                            dropDownMode='select'
                            popperPlacement='center'
                            minDate={new Date()}
                            placeholderText='Select Date'
                          />
                          <span className='date_icon'>
                                                    <img src={'./image/calendar.svg'} alt='date' />
                                                </span>
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <label className='form__form-group-label' htmlFor=''>Start Time<span
                        style={{ color: '#ff0000' }}>*</span></label>
                      <Row>
                        <Col>
                          <div className='form-group'>
                            <TimePicker showSecond={false}
                                        name={'startTime'}
                                        placeholder={'Time..'}
                                        use12Hours={true}
                                        onChange={(e) => this.onTimeChange(e, 'startTime')}
                                        value={this.state.startTime}
                                        defaultValue={null}
                            > </TimePicker>
                            <span
                              className='form__form-group-error'>{this.validator.message('Start Time', this.state.startTime, 'required')}</span>
                            <span
                              className='form__form-group-error'>{this.validatorEmail.message('Start Time', this.state.startTime, 'required')}</span>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {
                    this.state.calanderType && this.state.calanderType.value !== 'Note' &&
                    <Row className='form'>
                      <Col md={6}>
                        <div className='form-group'>
                          <label className='form__form-group-label ' htmlFor=''>End
                            Date</label>
                          <div className='date-picker date-picker--interval'>
                            <DatePicker
                              selected={this.state.eDate}
                              onChange={(e) => this.handleChangeDate(e, 'eDate')}
                              name='eDate'
                              dateFormat='dd/MM/yy'
                              dropDownMode='select'
                              popperPlacement='center'
                              minDate={this.state.sDate}
                              placeholderText='Select Date'
                            />
                            <span className='date_icon'>
                                                        <img src={'./image/calendar.svg'} alt='date' />
                                                    </span>
                            {this.state.calanderType !== 'Note' && <span
                              className='form__form-group-error'>{this.validatorEmail.message('End Date', this.state.eDate, 'required')}</span>}

                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <label className='form__form-group-label' htmlFor=''>End Time <span
                          style={{ color: '#ff0000' }}>*</span></label>
                        <Row>
                          <Col>
                            <TimePicker showSecond={false}
                                        name='endTime'
                                        placeholder='Time..'
                                        use12Hours={true}
                                        onChange={(e) => this.onTimeChange(e, 'endTime')}
                                        value={this.state.endTime}
                            > </TimePicker>
                            <span
                              className='form__form-group-error'>{this.validator.message('End Time', this.state.endTime, 'required')}</span>
                            {this.state.calanderType !== 'Note' && <span
                              className='form__form-group-error'>{this.validatorEmail.message('End Time', this.state.endTime, 'required')}</span>}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  }
                  <Row className='align-items-center'>
                    {
                      this.state.calanderType && this.state.calanderType.value === 'Task' && <>
                        <Col md={6}>
                          <div className='form-group'>
                            <label className='form__form-group-label' htmlFor=''>Type <span
                              style={{ color: '#ff0000' }}>*</span></label>
                            <Select
                              name='task_type'
                              type='text'
                              options={[{ value: 'Email', label: 'Email' }, {
                                value: 'Call',
                                label: 'Call',
                              }, { value: 'Meeting', label: 'Meeting' },
                                { value: 'Task', label: 'Task' }, {
                                  value: 'Presentation',
                                  label: 'Presentation',
                                }, { value: 'Catch Up', label: 'Catch Up' },
                                { value: 'Priority Task', label: 'Priority Task' }, {
                                  value: 'Follow Up',
                                  label: 'Follow Up',
                                }, {
                                  value: 'Priority Follow Up',
                                  label: 'Priority Follow Up',
                                },
                              ]}
                              classNamePrefix='react-select'
                              className='react-select'
                              value={this.state.task_type}
                              onChange={(e) => this.handleChange(e, 'task_type')}
                            />
                            <span
                              className='form__form-group-error'>{this.validator.message('Type', this.state.task_type, 'required')}</span>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className='form-group form'>
                            <label className='form__form-group-label ' htmlFor=''>Status<span
                              style={{ color: '#ff0000' }}>*</span></label>
                            <Select
                              name='status'
                              type='text'
                              options={[{ value: 'To Do', label: 'To Do' }, {
                                value: 'Completed',
                                label: 'Completed',
                              }]}
                              classNamePrefix='react-select'
                              className='react-select'
                              value={this.state.status}
                              onChange={(e) => this.handleChange(e, 'status')}
                            />
                          </div>
                          <span
                            className='form__form-group-error'>{this.validator.message('Status', this.state.status, 'required')}</span>
                        </Col>

                      </>
                    }

                  </Row>
                  <Row className='align-items-center'>
                    <Col sm={12}>
                      <div className='form-group'>
                        <label className='form__form-group-label pt-0' htmlFor=''>Subject<span
                          style={{ color: '#ff0000' }} /></label>
                        <input
                          type='text'
                          className='modal_input'
                          name='taskSubject'
                          value={this.state.taskSubject}
                          onChange={this.changeInput}
                        />
                        {/* <span className="form__form-group-error">{this.validator.message('', this.state.taskSubject, 'required')}</span> */}
                      </div>
                    </Col>
                  </Row>

                  <Row className='align-items-center form'>
                    {/*
                                 */}
                    <Col>
                      <Row className='align-items-center'>
                        <Col md={6}>
                          <div className='form-group'>
                            <label className='form__form-group-label'
                                   htmlFor=''>Priority<span
                              style={{ color: '#ff0000' }}>*</span></label>
                            <Select
                              name='priority'
                              type='text'
                              options={[{ value: 'High', label: 'High' }, {
                                value: 'Medium',
                                label: 'Medium',
                              }, { value: 'Normal', label: 'Normal' }]}
                              classNamePrefix='react-select'
                              className='react-select'
                              value={this.state.priority}
                              onChange={(e) => this.handleChange(e, 'priority')}
                            />
                            <span
                              className='form__form-group-error'>{this.validator.message('priority', this.state.priority, 'required')}</span>
                          </div>
                        </Col>

                        <Col md={6}>
                          <div className='form-group'>
                            <label className='form__form-group-label'
                                   htmlFor=''>&nbsp;</label>
                            <div className=''>
                              <div className='custom-control custom-switch'>
                                <input type='checkbox'
                                       className='custom-control-input'
                                       name='internal' id='hp'
                                       checked={!this.state.client} readOnly />
                                <label className='custom-control-label'
                                       htmlFor='hp'>Internal</label>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col>

                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className='align-items-center form'>
                    <Col md={6}>
                      <div className='form-group'>
                        <label className='form__form-group-label' htmlFor=''>Assign To<span
                          style={{ color: '#ff0000' }} /></label>
                        <Select
                          name='assignto'
                          type='text'
                          options={this.state.staff || []}
                          classNamePrefix='react-select'
                          className='react-select'
                          value={this.state.assignto}
                          onChange={(e) => this.handleChange(e, 'assignto')}
                        />
                      </div>
                    </Col>
                  </Row>

                </div>
                }
                {this.state.valueTab === 1 &&
                <div style={{ paddingTop: '15px' }}>
                  <h4 style={{ padding: '10px' }}>Email Guests</h4>
                  <div className='innerContent'>
                    <Row>
                      <Col sm={9}>
                        <div className='form-group'>
                          <label className='form__form-group-label' htmlFor=''>Subject<span
                            style={{ color: '#ff0000' }} /></label>
                          <input
                            type='text'
                            className='modal_input'
                            name='eSubject'
                            value={this.state.eSubject}
                            onChange={this.changeInput}
                          />
                          <span
                            className='form__form-group-error'>{this.validatorEmail.message('Subject', this.state.eSubject, 'required')}</span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className='form-group'>
                          <label htmlFor='exampleText'
                                 className='form__form-group-label'>Description</label>
                          <textarea className='modal_textarea'
                                    placeholder='Type the description htmlFor above here'
                                    onChange={this.changeInput}
                                    value={this.state.eDescription}
                                    maxLength={150} name='eDescription'
                                    id='exampleText'> </textarea>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className='form-group'>
                          <label className='form__form-group-label' htmlFor=''>Location<span
                            style={{ color: '#ff0000' }} /></label>
                          <Geosuggest
                            ref={el => this._geoSuggest = el}
                            onSuggestSelect={(e) => this.handleSelectGeoSuggest(e)}
                            initialValue={this.state.location}
                            placeholder='Start your search here'
                            className='modal_input'
                            country ={['AU', 'NZ']}
                          />
                          <span
                            className='form__form-group-error'>{this.validatorEmail.message('Location', this.state.location, 'required')}</span>
                        </div>
                      </Col>
                    </Row>
                    <Row className='align-items-center form'>
                      <Col sm={6}>
                        <>
                          <div className='form-group'>
                            <label className='form__form-group-label '
                                   htmlFor=''>Guest<span
                              style={{ color: '#ff0000' }}>*</span></label>
                            <Select
                              name='guest'
                              type='text'
                              options={this.state.allClient}
                              classNamePrefix='react-select'
                              className='react-select'
                              value={this.state.guest}
                              isMulti
                              onChange={(e) => this.handleChange(e, 'guest')}
                            />
                            <span
                              className='form__form-group-error'>{this.validatorEmail.message('Guest', this.state.guest, 'required')}</span>
                          </div>

                        </>
                      </Col>
                      <Col sm={6} className='text-right'>
                        <div className='form-group'>
                          <label className='form__form-group-label'
                                 htmlFor=''>&nbsp;</label>
                          {/* <button className="btn btn-secondary mr-3">Add Guest</button> */}
                          <button type='button' className='btn btn-secondary mb-0'
                                  onClick={this.EmailInvite}>Email Invite
                          </button>
                        </div>
                      </Col>

                      <Col sm={12} className='text-right mt-3'>
                        {
                          this.state.emailList.map(item => {
                            return (
                              <div className='form-group row'>
                                <div className='col-12  col-lg-4 m-0'>
                                  <div className='d-flex align-items-center'>
                                    <div className='round_checkbox'>
                                      <input type='checkbox'
                                             checked={item.checked}
                                             id={item.email_id}
                                             name={item.email_id}
                                             onChange={(e) => this.handleSelectEmail(e, item)} />
                                      <label className='mb-0'
                                             htmlFor={item.email_id}> </label>
                                    </div>
                                    <p
                                      className='m-0 ml-3 checkbox-label'>{item.email}</p>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        }

                      </Col>

                    </Row>
                  </div>

                </div>
                }
                {this.state.valueTab === 2 &&
                <div style={{ paddingTop: '15px' }}>
                  <h4 style={{ padding: '10px' }}>Send SMS</h4>
                  <div className='innerContent'>
                    <Row className='form'>
                      <Col md={6}>
                        <div className='form-group'>
                          <label className='form__form-group-label' htmlFor=''>SMS
                            Reminders</label>
                          <Select
                            name='smaReminder'
                            type='text'
                            options={[{ value: 0, label: 0 }, { value: 1, label: 1 }, {
                              value: 2,
                              label: 2,
                            }, {
                              value: 3,
                              label: 3,
                            }, { value: 7, label: 7 }]}
                            classNamePrefix='react-select'
                            className='react-select'
                            value={this.state.smaReminder}
                            onChange={(e) => this.handleChange(e, 'smaReminder')}
                          />
                          {/* <span className="form__form-group-error">{this.validator.message('', this.state.client_type, 'required')}</span> */}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className='form-group'>
                          <label className='form__form-group-label' htmlFor=''>Day Before
                            Appointment</label>
                          <Select
                            name='dayAppointment'
                            type='text'
                            options={dayAppointmentList}
                            classNamePrefix='react-select'
                            className='react-select'
                            value={this.state.dayAppointment}
                            onChange={(e) => this.handleChange(e, 'dayAppointment')}
                          />
                          {/* <span className="form__form-group-error">{this.validator.message('', this.state.client_type, 'required')}</span> */}
                        </div>
                      </Col>
                    </Row>
                    <Row className='align-items-center'>
                      <Col md={6}>
                        <div className='form-group'>
                          <label className='form__form-group-label' htmlFor=''>To (Mobile
                            Number)<span
                              style={{ color: '#ff0000' }} /></label>
                          <input
                            name='toNumber'
                            type='text'
                            value={this.state.toNumber}
                            onChange={this.changeInput}
                            className='modal_input'
                          />
                          <span
                            className='form__form-group-error'>{this.validatorSMS.message('To Mobile', this.state.toNumber, 'required')}</span>
                        </div>
                      </Col>
                      <Col md={6} className='text-right'>
                        <div className='form-group'>
                          <label className='form__form-group-label '
                                 htmlFor=''>&nbsp;</label>
                          {/* <button className="btn btn-secondary mr-3">Add No.</button> */}
                          <button className='btn btn-secondary mb-0' type='button'
                                  onClick={this.SendSMS}>Send SMS
                          </button>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className='form-group'>
                          <label htmlFor='exampleTextone'>Message (Limit 150
                            Character)</label>
                          <textarea className='modal_textarea'
                                    name='textSMS'
                                    value={this.state.textSMS}
                                    placeholder='Type your short message here'
                                    onChange={this.changeInput} maxLength={150}
                                    id='exampleTextone'> </textarea>
                        </div>
                        <span
                          className='form__form-group-error'>{this.validatorSMS.message('Message', this.state.textSMS, 'required')}</span>
                      </Col>
                    </Row>
                  </div>
                </div>
                }
                {this.state.valueTab === 3 &&
                <div style={{ paddingTop: '15px' }}>
                  {this.state.calanderType && this.state.calanderType.value !== 'Note' &&
                  <>
                    <h4 style={{ padding: '10px' }}>Repeating Event Information</h4>
                    <div className='innerContent'>
                      <p style={{ marginBottom: '15px' }}>To stop a Repeating Event, tick 'Use
                        End Date' and select today's date.</p>
                      <Row className='form'>
                        <Col md={6}>
                          <div className='form-group'>
                            <label className='form__form-group-label' htmlFor=''> Repeat
                              type:</label>
                            <Select
                              name='rType'
                              type='text'
                              options={repeatTypeOption}
                              classNamePrefix='react-select'
                              className='react-select'
                              value={this.state.rType}
                              onChange={(e) => this.handleChange(e, 'rType')}
                            />
                            {/* <span className="form__form-group-error">{this.validator.message('', this.state.client_type, 'required')}</span> */}
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={6}>
                          <div className='form-group'>
                            <label className='form__form-group-label ' htmlFor=''>Repeat
                              End date:</label>
                            <div className='col-12 p-0 mb-2 mt-3'>
                              <div className='d-flex align-items-center'>
                                <div className='round_checkbox'>
                                  <input type='checkbox' className=''
                                         id='customRadio' name='asPin'
                                         checked={this.state.rpt_use_end}
                                         onChange={(e) => this.handleUseEndDate(e)} />
                                  <label className='mb-0' htmlFor='customRadio' />
                                </div>
                                <p className='m-0 ml-3'> Use End date </p>
                              </div>
                            </div>


                            <div className='date-picker date-picker--interval'>
                              <DatePicker
                                selected={this.state.recur_enddate ? this.state.recur_enddate : ''}
                                onChange={(e) => this.handleChangeDate(e, 'recur_enddate')}
                                name='recur_enddate'
                                dateFormat='dd/MM/yy'
                                dropDownMode='select'
                                popperPlacement='center'
                                placeholderText='Select Date'
                                disabled={this.state.rpt_use_end}
                              />
                              <span className='date_icon'>
                                                            <img src={'./image/calendar.svg'} alt='date' />
                                                        </span>
                              {/* {this.state.calanderType !== 'Note' && <span className="form__form-group-error">{this.validatorEmail.message('Repeat Date', this.state.recur_enddate, 'required')}</span>} */}

                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row className='align-items-center pl-3 pr-3'>
                        {
                          this.state.rpt_day && this.state.rpt_day.map(item => {
                            return (
                              <div key={item.name}
                                   className='col-6 col-sm-3 col-md-3 p-0 mb-2 mt-3'>
                                <div className='d-flex align-items-center'>
                                  <div className='round_checkbox'>
                                    <input type='checkbox' id={item.name}
                                           name={item.name} checked={item.checked}
                                           onChange={(e) => this.handleSelectDay(e, item)} />
                                    <label className='mb-0' htmlFor={item.name} />
                                  </div>
                                  <p className='m-0 ml-3'>{item.name}</p>
                                </div>
                              </div>
                            );
                          })
                        }
                      </Row>
                    </div>

                  </>}
                </div>
                }
              </div>
              <hr />
              <div className='text-right'>
                <button className='btn-gray mr-3 mb-2' type='button' onClick={this.props.closeModal}>Cancel</button>
                {this.props.isEdit ? <button className='btn-gray mr-2 mb-2' type='button'
                                             onClick={() => this.saveTask('yes')}>Duplicate</button> : ''}
                {/* { this.props.isEdit && this.state.status && this.state.status.value === 'Completed' ? <button className="btn btn-secondary m-0 mb-1 mr-2" type="button" onClick={()=>this.OpenDeleteModal()}>Delete</button> : '' } */}
                <button className='btn btn-secondary m-0 mb-1' type='button' onClick={() => this.saveTask('no')}>Save
                </button>
              </div>
            </div>
          </form>
        </Modal>

        {this.state.errorModal ?
          <ModalComponent modal={this.state.errorModal} color={this.state.errorType} isCancel={true}
                          message={this.state.errorMessage}
                          onConfirm={(e) => this.deleteTask(e)}
                          onClose={() => this.setState({ errorModal: false })} /> : null}
      </div>
    );
  }
}

export default withRouter(AddTaskModal);