import React, { FC, InputHTMLAttributes, forwardRef } from 'react'
import styles from './form-input.module.scss'
import cls from 'classnames'

interface FormInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> {
  labelClassName?: string
  wrapperClassname?: string
  icon?: FC<{ className: string }>
  error?: string
  label?: string
  size?: 'small' | 'medium' | 'large'
  inputStyle?: 'filled' | 'outlined'
  children?: React.ReactNode
}

const FormInput = forwardRef<HTMLInputElement, FormInputProps>(
  (
    {
      labelClassName,
      wrapperClassname,
      icon: Icon,
      error = '',
      label = '',
      className,
      size = 'medium',
      children,
      required,
      inputStyle,
      ...props
    },
    ref,
  ) => {
    return (
      <div className={cls(styles.formInputWrapper, wrapperClassname)}>
        {label ? (
          <label className={cls(labelClassName, styles.labelWrapper)}>
            <label className={styles.label}>
              {label} {required ? <span className={styles.error}>*</span> : null}
            </label>
            {error ? <label className={styles.error}>{error}</label> : null}
          </label>
        ) : null}
        {Icon ? <Icon className={cls(styles.formInputIcon, styles[size])} /> : null}
        <input
          ref={ref}
          className={cls(className, styles.formInput, styles[size], {
            [styles.icon]: !!Icon,
            [styles.outlined]: inputStyle === 'outlined',
          })}
          {...props}
        />
        {children ? (
          <div className={cls(styles.inputChildrenWrapper, styles[size])}>{children}</div>
        ) : null}
      </div>
    )
  },
)

FormInput.displayName = 'FormInput'

export default FormInput
