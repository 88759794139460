import {  ClaimDetailsFormValues, CreateClaimDeatailNoteValues } from "../types/claim-details.type";
import { AddFeeFormValues } from "../types/recoverable-costs.type";

export const CLAIM_DETAILS_DEFAULT_VALUES: ClaimDetailsFormValues = {
  total: 0,
  currentTotal: 0,
}

export const CREATE_CLAIM_DETAIL_NOTE_DEFAULT_VALUES: CreateClaimDeatailNoteValues= {
  total_amount: 0,
  description: ''
}

export const ADD_FEE_DEFAULT_VALUES: AddFeeFormValues = { 
  fee: null
}