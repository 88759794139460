import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormControl, MenuItem, Select } from '@material-ui/core'
import axios from 'axios'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: '100%',
    width: '100%',
    marginLeft: '122px',
    border: '1px solid silver',
    borderRadius: '3px',
  },
}))

const Company = ({ setCompanyID, companyID }) => {
  const [companies, setCompanies] = useState([])
  const classes = useStyles()

  const changeCompanyHandler = (e) => {
    setCompanyID(e.target.value)
  }

  const getCompanies = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/companies`)
    setCompanies(response.data)
  }

  useEffect(() => {
    getCompanies()
  }, [])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        gap: '5px',
        marginBottom: '20px',
        width: '100%',
      }}
    >
      <h3>Select Company</h3>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '5px',
          width: '100%',
        }}
      >
        <div className="form__form-group-label" style={{ width: '150px' }}>
          Select Company <span style={{ color: '#ff0000' }}>*</span>
        </div>
        <FormControl
          className={classes.formControl}
          style={{
            margin: '0px',
            padding: '0px',
            width: '100%',
            marginTop: '25px',
          }}
        >
          <Select
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
            onChange={changeCompanyHandler}
            defaultValue={companyID?.id}
            style={{
              background: '#f5f9fc',
              borderRadius: '3px',
              width: '100%',
            }}
            required={true}
          >
            {companies.data?.map((company) => (
              <MenuItem key={company.id} value={company.id}>
                {company?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  )
}

export default Company
