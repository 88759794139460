import { GLOBAL_QUERIES } from "@src/lib/global-queries"
import { useQuery } from "@tanstack/react-query"
import { WipService } from "../services/wip.service"
import { StaffService } from "../services/staff.service"

export const useGetWipById = (id: number | undefined, params = {}) => {
  const getWipById = async () => {
    const wipService = new WipService()
    const staffService = new StaffService()
    const wip = await wipService.getWipById(id, params)

    try {
      wip.staff_assigned_entity = await staffService.getStaffById(wip?.staff_assigned)
    } catch (e) {
      console.error(e)
    }

    return wip
  }

  const query = useQuery({ queryKey: [GLOBAL_QUERIES.WIP.GET_BY_ID, id, params], queryFn: getWipById, enabled: !!id, staleTime: 1000 * 60 * 60, retry: 1 })

  return query
}