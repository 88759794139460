import axios from 'axios'
import Services from '../../../shared/Services'

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_BMS5_END_POINT,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

const services = new Services()

export const submitURF = async (formData) => {
  try {
    const result = await httpClient.post('strata-body-corporate-recovery-form', formData)
    return result
  } catch (error) {
    throw error
  }
}

export const getURFList = async (params = {}, page) => {
  try {
    const result = await httpClient.get(
      `strata-body-corporate-recovery-form?limit=10&page=${page}`,
      {
        params,
      },
    )
    return result
  } catch (error) {
    console.log(error)
  }
}

export const getURFInfo = async (id, params = {}) => {
  try {
    const result = await httpClient.get(`strata-body-corporate-recovery-form/${id}`, {
      params,
    })
    return result
  } catch (error) {
    console.log(error)
  }
}

export const getURFByID = async (id, params = {}) => {
  try {
    const result = await httpClient.get(`strata-body-corporate-recovery-form/${id}`, {
      params,
    })
    return result
  } catch (error) {
    console.log(error)
  }
}

export const updateURF = async (id, formData) => {
  try {
    const result = await httpClient.patch(`strata-body-corporate-recovery-form/${id}`, formData)
    return result
  } catch (error) {
    console.log(error)
    services.errorToast(error.message)
  }
}

export const removeURF = async (id) => {
  try {
    const result = await httpClient.delete(`strata-body-corporate-recovery-form/${id}`)
    return result
  } catch (error) {
    throw error
  }
}

export const createURFWip = async (wipData) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/strata-body-corporate-recovery-form/wip`,
      wipData,
    )

    if (response.status !== 201) {
      throw new Error('Error occured while WIP creation')
    }

    return response.data
  } catch (error) {
    if (
      error.response &&
      error.response.status === 401 &&
      error.response.data.responseType !== 'UNAUTHORIZED'
    ) {
      this.unAuthorizeUser(error.response.data.message)
    } else {
      if (error.response) {
        throw new Error(error.response.data)
      }
      if (!error.response) {
        throw new Error(error)
      } else {
        throw error
      }
    }
  }
}

export const sendSMS = async (formData) => {
  try {
    const requests = formData.map(async (data) => httpClient.post('short-messages', data))
    const result = await Promise.all(requests)
    return result
  } catch (error) {
    console.log(error)
  }
}

export const sendEmail = async (formData) => {
  try {
    const requests = formData.map(async (data) => httpClient.post('emails', data))
    const result = await Promise.all(requests)
    return result
  } catch (error) {
    console.log(error)
  }
}

export const sendReply = async (formData) => {
  try {
    const requests = await httpClient.post('emails/reply', formData)
    const result = await Promise.all(requests)
    return result
  } catch (error) {
    console.log(error)
  }
}

export const getSmsTypes = async () => {
  try {
    const result = await httpClient.get('short-message-templates')
    return result
  } catch (error) {
    console.log(error)
  }
}

export const getEmailTypes = async (params) => {
  try {
    const result = await httpClient.get('email-templates', { params })
    return result
  } catch (error) {
    console.log(error)
  }
}

export const createTimeLog = async (data) => {
  try {
    const result = await httpClient.post('wip-time-logs', data)
    return result
  } catch (error) {
    console.log(error)
  }
}

export const getShortMessage = async () => {
  try {
    const result = await httpClient.get('short-messages')
    return result
  } catch (error) {
    console.log(error)
  }
}

export const getEmail = async () => {
  try {
    const result = await httpClient.get('emails')
    return result
  } catch (error) {
    console.log(error)
  }
}
