import * as Yup from 'yup';

export const generateInvoiceFormValidationSchema = Yup.object().shape({
  bill_to: Yup.object().shape({
    client_id: Yup.number('Please fill number!').required('Please fill client ID!'),
    name: Yup.string().required('Please fill client name!'),
    email: Yup.string().email().required('Please fill client email!'),
    address_1: Yup.string().required('Please fill client address 1!'),
    suburb: Yup.string().required('Please fill client suburb!'),
    state: Yup.string().required('Please fill client state!'),
    post_code: Yup.string().required('Please fill client post code!'),
    country: Yup.string().required('Please fill client country!'),
  }),

  bill_from: Yup.object().shape({
    client_id: Yup.number('Please fill number!').required('Please fill client ID!'),
    name: Yup.string().required('Please fill client name!'),
    email: Yup.string().email().required('Please fill client email!'),
    address_1: Yup.string().required('Please fill client address 1!'),
    address_2: Yup.string().required('Please fill client address 2!'),
    address_3: Yup.string().required('Please fill client address 3!'),
    suburb: Yup.string().required('Please fill client suburb!'),
    state: Yup.string().required('Please fill client state!'),
    post_code: Yup.string().required('Please fill client post code!'),
    country: Yup.string().required('Please fill client country!'),
  }),

  payment_details: Yup.object().shape({
    name: Yup.string().required('Please select payment details!'),
    id: Yup.string().required('Please select payment details!'),
  }),

  item: Yup.array(
    Yup.object().shape({
      template_id: Yup.string().required('Please fill template name!'),
      description: Yup.string().required('Please fill template description!'),
      quantity: Yup.number('Please fill number!').required('Please fill template quantity!'),
      price: Yup.string().required('Please fill price!'),
    }),
  ),

  due_date: Yup.string().required('Please select due date!'),
  item_total: Yup.string().required('Please fill item total!'),
  special_notes: Yup.string().required('Please fill special notes!'),
  company_details: Yup.string().required('Please fill company details!'),
  invoice_no: Yup.number().required('Please fill invoice no!'),
});
