import { FC } from 'react'
import { TaskProps } from '@containers/Setting/components/Contacts/taskboard/types'
import { Button, ButtonGroup, TableCell, TableRow } from '@material-ui/core'
import moment from 'moment'
import styles from './task-item.module.scss'
import cls from 'classnames'

interface OverdueTaksItemProps {
  task: TaskProps
}

const TaskItem: FC<OverdueTaksItemProps> = ({ task }) => {
  const { due_date, wip_id, companies, description, time_created, title, is_done } = task
  return (
    <TableRow className={styles.overdueTaskRow}>
      <TableCell className={cls(styles.dueDateCell, styles.center)}>
        <span>{moment(due_date).format('DD-MM-YYYY')}</span>
      </TableCell>
      <TableCell
        className={cls(styles.clientCell, {
          [styles.center]: !companies[0]?.name,
          [styles.ellipsis]: !!companies[0]?.name,
        })}
      >
        <span>{companies[0]?.name || '-'}</span>
      </TableCell>
      <TableCell className={cls(styles.titleCell, styles.ellipsis)}>
        <span>{title}</span>
      </TableCell>
      <TableCell className={cls(styles.wipCell, styles.center)}>
        <span>{wip_id || '-'}</span>
      </TableCell>
      <TableCell className={cls(styles.descriptionCell, styles.ellipsis)}>
        <span>{description}</span>
      </TableCell>
      <TableCell className={cls(styles.createdOn, styles.center)}>
        <span>{moment(time_created).format('DD-MM-YYYY')}</span>
      </TableCell>
      <TableCell className={cls(styles.optionsCell, styles.center)}>
        <span>
          <ButtonGroup className={styles.optionButton}>
            <Button>
              <span className="lnr lnr-pencil" />
            </Button>
          </ButtonGroup>
        </span>
      </TableCell>
      <TableCell className={cls(styles.doneCell, styles.center)}>
        <span className={cls({ [styles.done]: is_done, [styles.inProgress]: !is_done })}>
          {is_done ? 'Done' : 'In Progress'}{' '}
        </span>
      </TableCell>
    </TableRow>
  )
}

export default TaskItem
