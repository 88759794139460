import { FC } from 'react'
import AddNoteSection from '../../components/sections/add-note-section/add-note-section.component'
import WipNotesSection from '../../components/sections/notes-section/wip-notes-section.component'
import styles from './wip-notes-page.module.scss'
import { WipNotesPageProps } from '../../types/wip-communication.type'

const WipNotesPage: FC<WipNotesPageProps> = ({ wipId }) => {
  return (
    <div className={styles.wrapper}>
      <AddNoteSection wipId={wipId} />
      <WipNotesSection wipId={wipId} />
    </div>
  )
}

export default WipNotesPage
