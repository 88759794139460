import { FC, useState, useEffect } from 'react'
import EditableDiv from './editable-div.component'
import { useOptimizedDebounce } from './optimized-debounce.hook'
import styles from './data-grid.module.scss'
import cls from 'classnames'
import { DataGridCellProps } from './data-grid.type'

const DataGridCell: FC<DataGridCellProps> = ({
  value,
  error,
  header,
  selected,
  editable,
  onChange,
  onSelectionChange,
  onErrorsChange,
}) => {
  const [innerValue, setInnerValue] = useState(value)
  const debouncedValue = useOptimizedDebounce(innerValue, 300)

  useEffect(() => {
    if (debouncedValue === value) {
      return
    }

    const validation = header.validation

    if (!validation) {
      onChange && onChange(debouncedValue)
      return
    }

    const result = validation.validate(debouncedValue)

    onErrorsChange && onErrorsChange(result.error?.details)
    onChange && onChange(debouncedValue)
  }, [debouncedValue])

  return (
    <div
      className={cls(styles.cell, {
        [styles.selected]: selected && !header.selected,
        [styles.columnSelected]: header.selected,
        [styles.error]: error,
      })}
      style={{
        width: header.width,
      }}
    >
      <EditableDiv
        isEditing={selected}
        value={value}
        editable={editable}
        onChange={setInnerValue}
        onSelect={() => onSelectionChange(true)}
        onUnselect={() => onSelectionChange(false)}
      />
    </div>
  )
}

export default DataGridCell
