import Modal from '@components/modal'
import { FC, useState } from 'react'
import SendSmsForm from '../../forms/send-sms-form/send-sms-form.component'
import styles from './send-sms-modal.module.scss'
import { useGetSmsTemplates } from '@containers/Communication/hooks/get-sms-templates.hook'
import {
  transformCompanyContactToPhoneContactOptions,
  transformDebtorToPhoneOptions,
  transformSmsTemplateToOption,
} from '@containers/Communication/utils/communcation-transfrom.utils'
import { WIP_SEND_TO_OPTIONS } from '@containers/Communication/const/communication.const'
import {
  CommunicationOptionType,
  PhoneContactOption,
} from '@containers/Communication/types/communication.type'
import { useSendShortMessageMutation } from '@containers/Communication/hooks/mutations/send-short-message.hook'
import { useSendShortMessageInBulkMutation } from '@containers/Communication/hooks/mutations/send-short-message-in-bulk.hook'
import { useUserContext } from '@src/context/user-context'
import { SendSmsFormSubmitValues, ShortMessageTo, WipSendSmsModalProps } from '@containers/Communication/types/sms.type'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'

const WipSendSmsModal: FC<WipSendSmsModalProps> = ({ isOpen, onClose, wip }) => {
  const [contactOptions, setContactOptions] = useState<PhoneContactOption[]>([])

  const queryClient = useQueryClient()
  const { mutateAsync: sendShortMessage, isLoading: isShortMessageSending } =
    useSendShortMessageMutation()
  const { mutateAsync: sendShortMessageInBulk, isLoading: isShortMessageInBulkSending } =
    useSendShortMessageInBulkMutation()
  const { user } = useUserContext()
  const { data } = useGetSmsTemplates()

  const smsTemplateOptions = data?.data?.map(transformSmsTemplateToOption) || []

  const handleSendToChange = (value: string) => {
    if (value === CommunicationOptionType.Debtor) {
      const debtors = wip.debtors
      setContactOptions(debtors.flatMap(transformDebtorToPhoneOptions))
    } else if (value === CommunicationOptionType.CompanyContact) {
      const companyContacts = wip.company.contacts
      setContactOptions(
        companyContacts?.flatMap(transformCompanyContactToPhoneContactOptions) || [],
      )
    } else setContactOptions([])
  }

  const handleSendSmsFormSubmit = async (data: SendSmsFormSubmitValues) => {
    try {
      if (data.sendTo.value === CommunicationOptionType.Other && typeof data.contact === 'string') {
        await sendShortMessage({
          to: data.contact,
          body: data.message,
          staff_id_created: user!.id,
          to_mode: ShortMessageTo.Other,
          wip_id: wip.id,
        })
      } else if (
        Array.isArray(data.contact) &&
        data.sendTo.value !== CommunicationOptionType.Other
      ) {
        const shortMessages = data.contact.map(({ value }) => ({
          to: value.phone,
          debtor_id: value.type === CommunicationOptionType.Debtor ? value.id : undefined,
          company_contact_id:
            value.type === CommunicationOptionType.CompanyContact ? value.id : undefined,
          wip_id: wip.id,
          body: data.message,
          to_mode:
            value.type === CommunicationOptionType.Debtor
              ? ShortMessageTo.Debtor
              : ShortMessageTo.CompanyContact,
          staff_id_created: user!.id,
        }))
        await sendShortMessageInBulk(shortMessages)
      }
      queryClient.invalidateQueries({ queryKey: [GLOBAL_QUERIES.COMBINED_NOTES.GET] })
      queryClient.invalidateQueries({ queryKey: [GLOBAL_QUERIES.SMS.GET] })
      toast.success('Short message sent successfully')
      onClose()
    } catch (e) {
      toast.error('Failed to send short message')
      console.log(e)
    }
  }

  const handleCancelClick = () => {
    onClose()
  }

  return (
    <Modal className={styles.modalWrapper} isOpen={isOpen} onClose={onClose} withBtns={false}>
      <SendSmsForm
        sendToOptions={WIP_SEND_TO_OPTIONS}
        templateOptions={smsTemplateOptions}
        contactOptions={contactOptions}
        onSendToChange={handleSendToChange}
        onSubmit={handleSendSmsFormSubmit}
        loading={isShortMessageSending || isShortMessageInBulkSending}
        onCancelClick={handleCancelClick}
      />
    </Modal>
  )
}

export default WipSendSmsModal
