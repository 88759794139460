import React from 'react';
import { toast } from 'react-toastify';

import {
  getInvoicePDFSelector,
  postInvoiceSelector,
} from '../../../../../../redux/selector/selector';
import { downloadImage } from '../../../../../../shared/request/useDownloadFile';
import { useSelector } from 'react-redux';

import { Grid, Modal } from '@mui/material';
import Loader from './../../../../../loading/loading';
import AppButton from '../../../../../../shared/components/AppButton/AppButton';

import { previewModalStyles } from './styles.js';

const PreviewModal = ({
  open,
  setOpen,
  setOpenVoidModal,
  setOpenEmailModal,
  setOpenSaveModal,
  place,
  handleSave,
}) => {
  const getInvoicePDFRes = useSelector(getInvoicePDFSelector);
  const postInvoiceRes = useSelector(postInvoiceSelector);

  const handleClose = () => {
    setOpen(false);
  };

  const savePDFHandler = () => {
    if (postInvoiceRes?.data?.id) {
      const handleOpenModal = () => setOpenSaveModal(true);
      downloadImage(postInvoiceRes?.data?.id, handleOpenModal);
    } else {
      toast.error('Something went wrong!');
    }
  };

  const setOpenVoidModalHandler = () => {
    setOpenVoidModal(true);
  };

  const openEmailModalHandler = () => {
    setOpenEmailModal(true);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid sx={previewModalStyles.container}>
        {getInvoicePDFRes?.loading || postInvoiceRes?.loading ? (
          <Loader />
        ) : (
          !!getInvoicePDFRes.data && (
            <Grid sx={previewModalStyles.pdfContainer}>
              <Grid sx={previewModalStyles.buttonsContainer}>
                <AppButton content="Cancel" buttonStyle="secondary" onClick={handleClose} />
                <AppButton
                  content="Save"
                  buttonStyle="success"
                  onClick={place === 'list' ? handleSave : savePDFHandler}
                />
                {place !== 'list' && (
                  <AppButton content="Void" buttonStyle="error" onClick={setOpenVoidModalHandler} />
                )}
                <AppButton content="Email" buttonStyle="primary" onClick={openEmailModalHandler} />
              </Grid>

              <iframe
                src={getInvoicePDFRes?.data}
                title="Invoice PDF"
                frameborder="0"
                style={previewModalStyles.iframe}
              ></iframe>
            </Grid>
          )
        )}
      </Grid>
    </Modal>
  );
};

export default PreviewModal;
