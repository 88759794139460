export const commercialUncoveredStyles = {
  card: {
    height: '100%',
    minHeight: '150px',
    borderRadius: '5px',
    boxShadow: '0 2px 10px 0 #ddd, 0 2px 20px 0 rgba(0, 0, 0, 0.1)',
    backgroundColor: 'white',
    padding: '30px',
  },
};

export const commercialUncoveredFormStyles = {
  collectionContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alighItems: 'flex-start',
    justifyContent: 'flex-start',
    marginBottom: '30px',
  },

  collectionTitle: {
    color: '#646777',
    fontFamily: 'Source Sans Pro',
    fontSize: '21px',
    lineHeight: '25.2px',
    fontWeight: '700',
    marginBottom: '20px !important',
  },
};
