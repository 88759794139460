import { useIsAuthenticated, useMsal } from "@azure/msal-react"
import { GLOBAL_QUERIES } from "@src/lib/global-queries"
import { useQuery } from "@tanstack/react-query"
import { GraphApiMailService } from "../services/graph-api-mail.service"
import { BaseGraphApiParams } from "../types/graph-api-mail.type"

export const useGetMessagesInsideFolder = (folderId?: string, params: BaseGraphApiParams = {}) => {
  const isAuthenticated = useIsAuthenticated()
  const { instance, accounts } = useMsal()

  const getMessagesInsideFolder = async (folderId: string | undefined) => {
    if(!folderId) return;

    const graphApiMailService = new GraphApiMailService();
    const account = accounts[0] || {};

    try {
      const token = await instance.acquireTokenSilent({ scopes: ['Mail.ReadBasic', 'Mail.Read', 'Mail.ReadWrite'], account: account })
      const messages =  graphApiMailService.getMessagesInsideFolder(token.accessToken, folderId, params)
      return messages
    }
    catch (e) {
      console.log(e)
    }
  }

  const query = useQuery({
    queryKey: [GLOBAL_QUERIES.GRAPH_API_MESSAGES.GET_BY_FOLDER_ID, folderId, params],
    queryFn: () => getMessagesInsideFolder(folderId),
    enabled: !!folderId && isAuthenticated,
    keepPreviousData: true,
    staleTime: 1000 * 60 * 5
  })

  return query;
}