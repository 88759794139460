import { FC, useState } from 'react'
import styles from './debtor-legal-note-list.module.scss'
import { format } from 'date-fns'
import { DebtorLegalNoteListItemProps } from '../../../types/debtor-legal-note.type'

const DebtorLegalNoteListItem: FC<DebtorLegalNoteListItemProps> = ({
  onEditClick,
  entity,
  lastElementRef,
}) => {
  const { createdAt, content } = entity
  const [isOpenButtonShown, setIsOpenButtonShown] = useState(false)

  const handleMouseEnter = () => {
    setIsOpenButtonShown(true)
  }

  const handleMouseLeave = () => {
    setIsOpenButtonShown(false)
  }

  return (
    <div
      className={styles.noteItem}
      ref={lastElementRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={styles.noteItemContent}>
        <div className={styles.avatarWrapper}>
          <span className="lnr lnr-user"></span>
        </div>
        <div>
          <h5 className={styles.noteHeader}>Note</h5>
          <p className={styles.noteTitle}>{content}</p>
        </div>
      </div>
      <div className={styles.noteDate}>
        <p className="m-0">{format(createdAt, 'dd-MM-yyyy')}</p>
        <p className="m-0">{format(createdAt, 'kk:mm:ss')}</p>
      </div>
      {isOpenButtonShown ? (
        <div className={styles.noteOpenButton} onClick={() => onEditClick(entity)}>
          <span className="lnr lnr-text-align-right"></span> OPEN
        </div>
      ) : null}
    </div>
  )
}

export default DebtorLegalNoteListItem
