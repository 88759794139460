const FilterIcon = () => {
  return (
    <svg width="17" height="17" fill="none" viewBox="0 0 17 17">
      <path
        fill="#000"
        d="M16.95.404A.653.653 0 0016.346 0H.654A.653.653 0 00.19 1.116l6.077 6.077c.131.131.27.467.27.653v5.885c0 .173.07.34.192.462l2.616 2.615a.653.653 0 001.116-.462v-8.5c0-.186.14-.522.27-.654l6.076-6.076a.653.653 0 00.142-.712z"
      ></path>
    </svg>
  )
}

export default FilterIcon
