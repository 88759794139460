import React, { Fragment, useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TransactionsService from '../../../../../services/TransactionsService';
import Services from '../../../../../shared/Services';
import TablePagination from '@material-ui/core/TablePagination';
import moment from 'moment';
import { TailSpin } from 'react-loader-spinner';


const useStyles = makeStyles(() => ({
  PageHeader: {
    fontSize: 25,
    fontWeight: 700,
    color: '#000',
  },
  PageSubtitle: {
    fontSize: 16,
    color: '#000',
    marginTop: 20,
  },
  Card: {
    padding: '10px 15px',
    marginTop: 20,
  },
  BackButton: {
    background: '#fff',
    borderRadius: 5,
    fontWeight: 700,
  },
  Button: {
    marginBottom: 0,
    borderRadius: 5,
  },
  TableTitle: {
    fontSize: 22,
    fontWeight: 700,
    color: '#000',

  },
  TableSubtitle: {
    fontSize: 16,
    color: '#000',
    marginTop: 20,
    fontWeight: 'bold',
  },
  TableHeader: {
    background: '#fff',
  },
  Footer: {
    background: '#ffff0014',
    border: '1px dashed #e5de0891',
    padding: '25px 15px',
    marginTop: 20,
  },
  textTable: {
    fontWeight: 'bold',
  },
}));

const Transactions = (props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [wipNumber, setWipNumber] = useState(0);
  const [transaction, setTransaction] = useState([]);
  const [totalClaims, setTotalClaims] = useState(0);
  const [totalRecoverableCost, setRecoverableCost] = useState(0);
  const [totalPayments, setTotalPayments] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);
  const [totalPaid, setTotalPaid] = useState(0);
  const classes = useStyles();
  const transactionService = new TransactionsService();
  const { errorToast } = new Services();

  useEffect(() => {
    getTransaction(props.allData.id, page, rowsPerPage);
    getTotalPaymentTransaction(props.allData.id);
    setWipNumber(props.allData.id);
  }, []);

  const getTransaction = async (id, page, rowsPerPage) => {
    setIsLoading(true);
    try {
      const params = {
        join: ['debtor', 'wip'],
        page: page,
        limit: rowsPerPage,
        filter: [`wip.id||$eq||${id}`],
        sort: 'id,DESC',
      };
      const res = await transactionService.getTransaction(params);
      setTransaction(res.data.data);
      const totalClaims = res.data.data.map((e) => e.wip.claim_details.total);
      setTotalClaims(totalClaims[0]);
      const totalCost = res.data.data.map((e) => e.wip.recoverable_costs_details.total);
      setRecoverableCost(totalCost[0]);
      setTotal(res.data.total);
      setIsLoading(false);
    } catch (e) {
      errorToast(e);
    } finally {
      setIsLoading(false);
    }
  };

  const getTotalPaymentTransaction = async (id) => {
    setIsLoading(true);
    try {
      const params = {
        join: ['debtor', 'wip'],
        limit: 1000,
        filter: [`wip.id||$eq||${id}`],
      };
      const res = await transactionService.getTransaction(params);
      const totalPayments = res.data.data.reduce((acc, item) => acc + +item.deposit, 0);
      setTotalPayments(totalPayments);
      setIsLoading(false);
    } catch (e) {
      errorToast(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const totalBalance = (totalClaims + totalRecoverableCost) - (totalPayments + totalPaid);
    setTotalBalance(totalBalance);
  }, [totalClaims, totalRecoverableCost, totalPayments, totalPaid]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getTransaction(wipNumber, newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getTransaction(wipNumber, 0, event.target.value);
  };

  return (
    <Fragment>
      <div className='card'>
        <div className='row mt-4'>
          <div className='col-12 mb-4'>
            {isLoading ? (
              <div className='loader'>
                <TailSpin
                  height='100'
                  width='100'
                  color='#f69066'
                  ariaLabel='loading'
                />
              </div>
            ) : <Accordion defaultExpanded={transaction && transaction.length !== 0}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1a-content'
                id='panel1a-header'
              >
                <div className='d-flex flex-column m-3'>
                  <div className={classes.TableTitle}>Transactions for WIP: {wipNumber}</div>
                  <div className={classes.TableSubtitle}>Quote 20% - {props.allData?.company?.name}</div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className='d-flex flex-column w-100'>
                  <table style={{ borderTop: 'none' }} className='table '>
                    <thead>
                    <tr className={classes.TableHeader}>
                      <th>TID</th>
                      <th>Date</th>
                      <th>Debtor</th>
                      <th>Deposit</th>
                      <th>Withdrawal</th>
                      <th>Type</th>
                    </tr>
                    </thead>
                    <tbody>
                    {transaction.map((item) => (
                      <tr className={classes.textTable} key={item.id}>
                        <td>{item.id}</td>
                        <td>{moment(item.date).format('DD-MM-YY')}</td>
                        <td>{`${item.debtor.name_first} ${item.debtor.name_last}`}</td>
                        <td>${item.deposit}</td>
                        <td>{item.withdrawal}</td>
                        <td>{item.type}</td>
                      </tr>
                    ))}
                    <tr style={{ borderTop: '2px solid #BFC5D2', borderBottom: '2px solid #BFC5D2' }}>
                      <td colSpan={3} />
                      <td>
                        Total: <strong>${totalPayments}</strong>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <div className='px-4 mb-3'>
                    <div className={`row ${classes.Footer}`}>
                      <div className='col-sm-4 col-xs-12'>
                        <div className='d-flex flex-column mt-2 mb-2'>
                          <p>(Total Claims + Total Recoverable Cost) -</p>
                          <p>(Total Payments + Total Paid To Agent)</p>
                          <p>--------------------------------------------------</p>
                          <p className={'mt-0 mb-3'}>Amount Outstanding Ignoring Interest</p>
                          {transaction.length === 0 ? <p /> : <p>
                            <strong>(${totalClaims} + ${totalRecoverableCost}) - (${totalPayments} + $0) =
                              ${totalBalance}</strong>
                          </p>}
                        </div>
                      </div>
                    </div>
                  </div>
                  <TablePagination style={{ marginTop: '25px' }}
                                   component='div'
                                   count={+total}
                                   page={page}
                                   onPageChange={handleChangePage}
                                   rowsPerPage={rowsPerPage}
                                   onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              </AccordionDetails>
            </Accordion>}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Transactions;
