export const getNavBarOptions = (wipId: number): { label: string, url: string }[] => {
  return [
    { label: 'Overview', url: `/wip/${wipId}/overview` },
    { label: 'Financial', url: `/wip/${wipId}/financial` },
    { label: 'Communication', url: `/wip/${wipId}/communication` },
    { label: 'Property', url: `/wip/${wipId}/property` },
    { label: 'Debtors', url: `/wip/${wipId}/debtors` },
    { label: 'Tasks', url: `/wip/${wipId}/tasks` },
    { label: 'Legal', url: `/wip/${wipId}/legal` },
    { label: 'Automations', url: `/wip/${wipId}/automations` },
  ]
}