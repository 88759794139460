import React from 'react';

import { Grid, Typography, outlinedInputClasses } from '@mui/material';
import Textarea from '@mui/joy/Textarea';

import { inputStyles, formInputStyles } from './styles';

const FormTextArea = ({
  label,
  placeholder,
  name,
  changeHandler,
  required,
  value,
  error,
  touched,
  bold,
  width,
  column,
  disabled,
  minRows,
}) => {
  return (
    <Grid item sx={width ? formInputStyles.container : formInputStyles.containerFull}>
      <Grid sx={column ? formInputStyles.containerColumn : formInputStyles.containerFull}>
        {label && (
          <Typography sx={bold ? formInputStyles.labelBold : formInputStyles.label}>
            {label} {required && <span style={formInputStyles.required}>*</span>}
          </Typography>
        )}

        <Grid sx={formInputStyles.inputContainer} style={{ maxWidth: width ? width : '' }}>
          <Textarea
            onChange={changeHandler}
            value={value}
            placeholder={placeholder}
            name={name}
            required={required}
            sx={inputStyles(outlinedInputClasses)}
            disabled={disabled}
            minRows={minRows}
          />
          <Grid sx={formInputStyles.errorMessage}>{error && touched && error}</Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FormTextArea;
