import React, { useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { Button, Col, Row } from 'reactstrap';
import Select from 'react-select';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { Editor } from '@tinymce/tinymce-react';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {
  createTimeLog,
  getEmailTypes,
  getURFInfo,
  sendEmail,
  sendReply,
} from '../service';
import Services from '../../../../shared/Services';
import { compileTemplate } from '../utils';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from 'mdi-react/CloseIcon';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import { useUserContext } from '../../../../context/user-context';

const SendReplyEmail = (props) => {
  const { refetchEmailsList } = props;
  const {user} = useUserContext()
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [emailBodyPlain, setEmailBodyPlain] = useState('');
  const [recoveryData, setRecoveryData] = useState(null);
  const [text, setText] = useState(null);
  const [valueText, setValueText] = useState('');
  const services = new Services();
  const id = 13;

  useEffect(() => {
    getFormInfo();
    const params = {
      limit: 10000
    }
    getEmailTypes(params)
      .then((res) => {
        console.log('res', res);
        setEmailTemplates(res.data.data);
      })
      .catch((e) => console.log(e));
  }, []);

  const getFormInfo = () => {
    getURFInfo(id)
      .then((res) => {
        setRecoveryData(res.data);
        console.log(res.data)
      })
      .catch((e) => console.error(e));
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      email_status_scheduled: { value: false, label: 'No' },
    },
  });
  const shouldBeScheduled = useWatch({
    control,
    name: 'email_status_scheduled',
    defaultValue: { value: false, label: 'No' },
  });

  const onSubmitHandler = async (formData) => {
    const data = {
      comm_id: props.commID,
      text: text,
    };

    const timeLogEmail = {
      wip_id: props.wipID,
      entry_time: moment(),
      entry_details: 'Send Email answer',
      entry_notes: 'Answer',
      staff_id: user.id,
    };

    await sendReply(data);
    await createTimeLog(timeLogEmail);
    await refetchEmailsList();
    services.successToast('Email Queued Successfully');
    props.closeModal();
  };

  const watchTemplate = (value, { name }) => {
    if (name === 'type') {
      const index = emailTemplates?.findIndex(
        (i) => i.id === value?.type.value,
      );
      if (index !== -1) {
        setValue(
          'email_header_subject',
          compileTemplate(
            emailTemplates[index].title,
            recoveryData,
          ),
        );

        // Set the editor state
        const blocksFromHtml = htmlToDraft(
          compileTemplate(
            emailTemplates[index].body,
            recoveryData,
          ),
        );
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap,
        );
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);

        // Set the plain text
        setEmailBodyPlain(
          draftToHtml(convertToRaw(editorState.getCurrentContent())),
        );
      }
    }
  };

  useEffect(() => {
    const subscription = watch(watchTemplate);
    return () => subscription.unsubscribe();
  }, [watch, emailTemplates]);

  return (
    <>
      <div style={{ position: 'relative', left: '375px', marginTop: '-25px' }}>
        <IconButton
          style={{ right: '24px', marginTop: '-16px' }}
          onClick={() => props.closeModal(false)}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div>
        <h3 style={{ color: 'black' }} className="form__form-group">
          Incoming message from {props.debtor}
        </h3>
      </div>
      <h4 className="form__form-group">{props.subject}</h4>
      <div
        className="form__form-group"
        dangerouslySetInnerHTML={{ __html: props.text }}
      />
      <div style={{ marginTop: '20px' }}>
        <h3 style={{ color: 'black' }} className="form__form-group">
          Send Email
        </h3>
      </div>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <div className="form__form-group">
          <span className="form__form-group-label">Template</span>
          <div className="form__form-group-field">
            <Controller
              control={control}
              name="type"
              render={({ field }) => (
                <Select
                  {...field}
                  options={emailTemplates.map((i) => ({
                    value: i.id,
                    label: i.title,
                  }))}
                  classNamePrefix="react-select"
                  className="react-select"
                />
              )}
            />
          </div>
          {errors.to && (
            <span className="form__form-group-error">Type is required</span>
          )}
        </div>

        <div className="form__form-group">
          <div className="form__form-group-field">
            <div className="text-editor" style={{ zIndex: '-5px' }}>
              <Editor
                apiKey="qwxo2mvltn5dflrlsa3iri4nw9z0uum2nl62s3f5rd4nbf0f"
                initialValue={emailBodyPlain}
                value={valueText}
                onInit={(evt, editor) => {
                  setText(editor.getContent({ format: 'text' }));
                }}
                onEditorChange={(newValue, editor) => {
                  setValueText(newValue);
                  setText(editor.getContent({ format: 'text' }));
                }}
                init={{
                  plugins:
                    'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
                  imagetools_cors_hosts: ['picsum.photos'],
                  menubar:
                    'file edit view insert format tools table help custom',
                  menu: {
                    custom: {
                      title: 'Variables',
                      items:
                        'title fullName firstNames lastName loginLink loginButton unsubscribe',
                    },
                  },
                  toolbar:
                    'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
                  toolbar_sticky: true,
                  autosave_ask_before_unload: true,
                  autosave_interval: '30s',
                  autosave_prefix: '{path}{query}-{id}-',
                  autosave_restore_when_empty: false,
                  autosave_retention: '2m',
                  image_advtab: true,
                  link_list: [
                    { title: 'My page 1', value: 'https://www.tiny.cloud' },
                    { title: 'My page 2', value: 'http://www.moxiecode.com' },
                  ],
                  image_list: [
                    { title: 'My page 1', value: 'https://www.tiny.cloud' },
                    { title: 'My page 2', value: 'http://www.moxiecode.com' },
                  ],
                  image_class_list: [
                    { title: 'None', value: '' },
                    { title: 'Some class', value: 'class-name' },
                  ],
                  importcss_append: true,
                  file_picker_callback: function (callback, value, meta) {
                    /* Provide file and text for the link dialog */
                    if (meta.filetype === 'file') {
                      callback('https://www.google.com/logos/google.jpg', {
                        text: 'My text',
                      });
                    }

                    /* Provide image and alt text for the image dialog */
                    if (meta.filetype === 'image') {
                      callback('https://www.google.com/logos/google.jpg', {
                        alt: 'My alt text',
                      });
                    }

                    /* Provide alternative source and posted for the media dialog */
                    if (meta.filetype === 'media') {
                      callback('movie.mp4', {
                        source2: 'alt.ogg',
                        poster: 'https://www.google.com/logos/google.jpg',
                      });
                    }
                  },
                  template_cdate_format:
                    '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
                  template_mdate_format:
                    '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
                  height: 600,
                  image_caption: true,
                  quickbars_selection_toolbar:
                    'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                  noneditable_noneditable_class: 'mceNonEditable',
                  toolbar_mode: 'sliding',
                  contextmenu: 'link image imagetools table',
                  skin: 'oxide',
                  content_css: 'default',
                  content_style:
                    'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                  setup: function (editor) {
                    const toggleState = false;

                    editor.ui.registry.addMenuItem('title', {
                      text: 'Title',
                      onAction: function () {
                        editor.insertContent('%recipient.title%');
                      },
                    });

                    editor.ui.registry.addMenuItem('fullName', {
                      text: 'Full Name',
                      onAction: function () {
                        editor.insertContent('%recipient.full_name%');
                      },
                    });

                    editor.ui.registry.addMenuItem('firstNames', {
                      text: 'First Names',
                      onAction: function () {
                        editor.insertContent('%recipient.first_names%');
                      },
                    });

                    editor.ui.registry.addMenuItem('lastName', {
                      text: 'Last Name',
                      onAction: function () {
                        editor.insertContent('%recipient.last_name%');
                      },
                    });
                    editor.ui.registry.addMenuItem('loginLink', {
                      text: 'Login Link',
                      onAction: function () {
                        editor.insertContent(
                          '<a href="https://www.ezpurim.com/login?uuid=%recipient.uuid%">login link</a>',
                        );
                      },
                    });
                    editor.ui.registry.addMenuItem('loginButton', {
                      text: 'Login Button',
                      onAction: function () {
                        editor.insertContent(
                          '<a href="https://www.ezpurim.com/login?uuid=%recipient.uuid%" class="btn-primary" style="font-family: Helvetica Neue,Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; color: #FFF; text-decoration: none; line-height: 2em; font-weight: bold; text-align: center; cursor: pointer; display: inline-block; border-radius: 5px; text-transform: capitalize; background-color: #348eda; margin: 0; border-color: #348eda; border-style: solid; border-width: 10px 20px;">Login Button</a>',
                        );
                      },
                    });

                    editor.ui.registry.addMenuItem('unsubscribe', {
                      text: 'Unsubscribe',
                      onAction: function () {
                        editor.insertContent(
                          '<a href="%mailing_list_unsubscribe_url%">Unsubscribe</a>',
                        );
                      },
                    });
                  },
                }}
              />
            </div>
          </div>
        </div>
        <Row style={{ marginTop: '50px' }}>
          <Col md={6}></Col>
          <Col md={6}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div>
                <button
                  className={'btn-group--icons btn-gray m-0'}
                  onClick={() => props.closeModal(false)}
                >
                  Cancel
                </button>
              </div>
              <div>
                <Button className={'ml-4'} type="submit">
                  Send
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default SendReplyEmail;
