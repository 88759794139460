import Joi from "joi";


const comment = Joi.string().required().messages({
  'string.empty': 'Comment is required',
  'string.required': 'Comment is required',
})


export const replyToEmailSchema = Joi.object({
  to: Joi.object({
    name: Joi.string().required(),
    address: Joi.string().email({ tlds: { allow: false } }).required()
  }).required().messages({
    'string.email': 'To must be a email',
    'string.empty': 'To is required',
    'string.required': 'To is required',
  }),
  comment
})

export const forwardEmailSchema = Joi.object({
  recipient: Joi.string().email({ tlds: { allow: false } }).required().messages({
    'string.email': 'Recipient must be a email',
    'string.empty': 'Recipient is required',
    'string.required': 'Recipient is required',
  }),
  comment
})
