import Modal from '@components/modal'
import styles from './create-task-modal.module.scss'
import Select from 'react-select'

import { useForm, useWatch } from 'react-hook-form'
import { PRIORITY_OPTIONS, TASK_TEMPLATE_OPTIONS } from './utils'
import Switch from '@components/switch'
import DatePicker from 'react-datepicker'

import LabelWrapper from '@components/label-wrapper'
import React, { useEffect, useState } from 'react'
import AssignAttributes from '@components/assign-attributes'
import newTaskService from '@src/services/task-service'
import { formatAssigneName } from '../utils'
import { useQueryClient } from '@tanstack/react-query'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import AssignAttributesIcon from '@src/icons/assign-attributes'
import { useTaskContext } from '@src/context/task-context'
import { useUserContext } from '@src/context/user-context'
import { RULES_TO_ASSIGN_TASK } from '@src/utils'
import { joiResolver } from '@hookform/resolvers/joi'
import { CreateTaskSchema } from './validations'
import cls from 'classnames'

interface IProps {
  isOpen: boolean
  onClose: () => void
  column_belong_id?: number
}

interface IOption {
  label: string
  value: number
}

interface ICreateFrom {
  priority: IOption | undefined
  type: IOption | undefined
  assign_to: IOption[] | undefined
  due_date: Date | undefined
  assign_attributes: number[]
  excluded_from_calendar: boolean
  task_template: IOption | undefined
  title: string
  description: string
  excluded_from_automation: boolean
}

const defaultValues = {
  priority: undefined,
  type: undefined,
  assign_to: undefined,
  due_date: new Date(),
  assign_attributes: [],
  excluded_from_calendar: false,
  task_template: undefined,
  title: '',
  description: '',
  excluded_from_automation: false,
}

const CreateTaskModal = ({ isOpen, onClose = () => null, column_belong_id = 1 }: IProps) => {
  const [isOpened, setIsOpened] = useState<boolean>(false)
  const {
    selectedCompanyState,
    companies,
    staff,
    taskMode,
    isSales,
    isRecovery,
    currentWip,
    currentFoundWip,
    fetchColumns,
  } = useTaskContext()
  const { user } = useUserContext()
  const handleIsOpenStateChange = () => {
    setIsOpened((prev) => !prev)
  }
  const { control, reset, handleSubmit, setValue } = useForm<ICreateFrom>({
    defaultValues,
    resolver: joiResolver(CreateTaskSchema),
    mode: 'all',
  })

  const handleIsCloseStateChange = () => setIsOpened(false)

  const [TASK_TYPE_OPTIONS, setTaskTypeOptions] = useState<any[]>([])

  const filteredStuffMemebers =
    staff?.map((member) => ({
      value: member.id,
      label: member.first_name.trim() + ' ' + member.last_name.trim(),
      access_level: member.access_level,
    })) || []

  const allowedToBeAssigned =
    RULES_TO_ASSIGN_TASK[user?.access_level as keyof typeof RULES_TO_ASSIGN_TASK]

  const filteredStuffMembersByRules = [...filteredStuffMemebers]?.filter(
    ({ access_level }) => allowedToBeAssigned?.includes(access_level),
  )
  const debtorOptions =
    currentFoundWip?.debtors?.map(({ name_first, name_last, id }) => ({
      label: formatAssigneName({ first_name: name_first, last_name: name_last, isAssigned: false }),
      value: id,
    })) || []

  const assignAttributesOptions = isSales
    ? [
        {
          innerOptions:
            companies?.map(({ id, name, contacts }: any) => ({
              label: name,
              value: id,
              options: contacts?.map(({ id, first_name, last_name }: any) => ({
                label: first_name + last_name,
                value: id,
              })),
            })) || [],
          isNested: true,
          name: 'Client',
          filter: false,
        },
      ]
    : [
        {
          innerOptions: [
            {
              label: `WIP ${currentWip}`,
              value: currentWip,
              options: debtorOptions,
            },
          ],
          isNested: true,
          name: 'WIP',
          filter: false,
        },
      ]

  const queryClient = useQueryClient()

  const { assign_attributes, excluded_from_automation } = useWatch({ control })
  const onSubmit = async (data: ICreateFrom) => {
    const {
      priority,
      type,
      task_template,
      assign_to,
      assign_attributes,
      excluded_from_automation,
      ...rest
    } = data

    const dataTOSend = {
      ...rest,
      priority: PRIORITY_OPTIONS.find(({ value }) => value === priority?.value)?.label,
      task_template: task_template?.value,
      type: excluded_from_automation ? null : type?.value,
      wip_id: isSales ? null : assign_attributes[0],
      staff_id_created: user?.id,
      column_belong_id,
      assign_to: assign_to?.map((el) => el?.value),
      company_id: isSales ? Number(selectedCompanyState.id) : null,
      assign_attributes: isRecovery ? [] : assign_attributes,
      assigned_debtors: isRecovery
        ? assign_attributes?.filter((n) => n !== Number(currentWip))
        : [],
      excluded_from_automation,
      automation_column_belong_id: excluded_from_automation ? null : data.type?.value,
    }
    await newTaskService
      .createTask(dataTOSend)
      .then((res) => {
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        onClose()
        queryClient.invalidateQueries({ queryKey: [GLOBAL_QUERIES.TASKSERVICE.GET] })
      })
  }

  useEffect(() => {
    if (!selectedCompanyState?.id || !isOpen) return

    fetchColumns(selectedCompanyState?.id || 0).then((res) => {
      const taskTypes =
        res?.map(({ id, title }) => ({
          value: id,
          label: title,
        })) || []
      setTaskTypeOptions(taskTypes)
    })
  }, [selectedCompanyState?.id, isOpen])

  useEffect(() => {
    if (!isOpen) {
      reset(defaultValues, { keepDirty: false, keepValues: false })
    }
  }, [isOpen])

  useEffect(() => {
    if (isRecovery && currentWip) {
      setValue('assign_attributes', [currentWip])
    }
  }, [isOpen])

  return (
    <Modal isOpen={isOpen} onClose={onClose} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.wrapper} onClick={(e) => e.stopPropagation()}>
        <span>Create Task</span>
        <div className={styles.select}>
          <LabelWrapper<ICreateFrom>
            className={styles.labelWrapper}
            control={control}
            name={'priority'}
            label="Priority"
          >
            {({ onChange, value }) => {
              return (
                <Select
                  type="text"
                  options={PRIORITY_OPTIONS}
                  className="react-select"
                  value={value}
                  onChange={onChange}
                />
              )
            }}
          </LabelWrapper>
          <LabelWrapper<ICreateFrom>
            className={styles.labelWrapper}
            control={control}
            name={'type'}
            label="Task Type"
          >
            {({ onChange, value }) => {
              return (
                <Select
                  type="text"
                  isDisabled={excluded_from_automation}
                  options={TASK_TYPE_OPTIONS}
                  className="react-select"
                  value={value}
                  onChange={onChange}
                />
              )
            }}
          </LabelWrapper>
          <LabelWrapper<ICreateFrom>
            className={styles.labelWrapper}
            control={control}
            name={'assign_to'}
            label="Assign To"
          >
            {({ onChange, value }) => {
              return (
                <Select
                  type="text"
                  isMulti
                  options={filteredStuffMembersByRules}
                  className="react-select"
                  value={value}
                  onChange={onChange}
                />
              )
            }}
          </LabelWrapper>
        </div>
        <div className={styles.select}>
          <LabelWrapper<ICreateFrom>
            className={styles.labelWrapper}
            control={control}
            name={'due_date'}
            label="Due Date"
          >
            {({ onChange, value }) => {
              return (
                <DatePicker
                  type="text"
                  dateFormat="dd/MM/yyyy"
                  className="react-select"
                  selected={value}
                  onChange={(e) => {
                    const date = e as Date
                    onChange(date)
                  }}
                />
              )
            }}
          </LabelWrapper>
          <LabelWrapper<ICreateFrom>
            className={styles.labelWrapper}
            control={control}
            name={'assign_attributes'}
            label="Assign Attributes"
          >
            {({ onChange, value }) => {
              return (
                <AssignAttributes
                  isOpen={isOpened}
                  handleDropdownChangeState={handleIsOpenStateChange}
                  handleCloseDropdown={handleIsCloseStateChange}
                  values={value as number[]}
                  onChange={(value) => onChange(value)}
                  options={assignAttributesOptions}
                >
                  <AssignAttributesIcon />
                  <span>Assign Attributes</span>
                </AssignAttributes>
              )
            }}
          </LabelWrapper>
        </div>
        <div className={cls(styles.select, 'mt-2')}>
          <LabelWrapper<ICreateFrom>
            control={control}
            name={'excluded_from_calendar'}
            label=""
          >
            {({ value, onChange }) => {
              return (
                <div className="d-flex">
                  <Switch value={value as boolean} onChange={onChange} />
                  <span className="ml-2">Exclude from Calender</span>
                </div>
              )
            }}
          </LabelWrapper>
          <LabelWrapper<ICreateFrom>
            control={control}
            name={'excluded_from_automation'}
            label=""
          >
            {({ value, onChange }) => {
              return (
                <div className="d-flex">
                  <Switch value={value as boolean} onChange={onChange} />
                  <span className="ml-2">Exclude from Automation</span>
                </div>
              )
            }}
          </LabelWrapper>
        </div>
        <div className={styles.select}>
          <div className={styles.assignedAttributes}>
            <label>Assigned Attributes</label>
            <div>
              {assign_attributes && assign_attributes?.length < 1 && (
                <div>No Attributes Assaigned</div>
              )}
              {assignAttributesOptions?.map(({ innerOptions }) => {
                const selectedOptions = innerOptions?.filter(({ value }) =>
                  (assign_attributes as number[]).includes(value),
                )
                return (
                  <div key={React.useId()}>
                    {selectedOptions?.map(({ label }) => <span key={label}>{label}</span>)}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className={styles.select}>
          <LabelWrapper<ICreateFrom>
            className={styles.labelWrapper}
            control={control}
            name={'task_template'}
            label="Select Task Template"
          >
            {({ onChange, value }) => {
              return (
                <Select
                  type="text"
                  options={TASK_TEMPLATE_OPTIONS}
                  className="react-select"
                  value={value}
                  onChange={onChange}
                />
              )
            }}
          </LabelWrapper>
        </div>
        <div className={styles.select}>
          <LabelWrapper<ICreateFrom>
            className={styles.labelWrapper}
            control={control}
            name={'title'}
            label="Task Title"
          >
            {({ onChange, value }) => {
              return (
                <input
                  type="text"
                  className="react-select"
                  placeholder="Task Template 1. Title Example"
                  value={value as string}
                  onChange={onChange}
                />
              )
            }}
          </LabelWrapper>
        </div>
        <div className={styles.select}>
          <LabelWrapper<ICreateFrom>
            className={styles.labelWrapper}
            control={control}
            name={'description'}
            label="Task Description"
          >
            {({ onChange, value }) => {
              return (
                <div>
                  {' '}
                  <textarea
                    rows={10}
                    cols={50}
                    placeholder={`Task Template one template desciption.
 * Think of this point
 * And this one too when doing task`}
                    value={value as string}
                    onChange={onChange}
                  />
                </div>
              )
            }}
          </LabelWrapper>
        </div>
      </div>
    </Modal>
  )
}

export default CreateTaskModal
