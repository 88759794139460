import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import FormInput from './form-input.component'
import { FC, ForwardedRef, InputHTMLAttributes } from 'react'
import { Control, Controller, FieldValues, Path, useController } from 'react-hook-form'

const defaultMaskOptions = {
  prefix: '$',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 7, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
}

interface CurrencyInputProps<T extends FieldValues>
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> {
  labelClassName?: string
  icon?: FC<{ className: string }>
  label?: string
  size?: 'small' | 'medium' | 'large'
  inputStyle?: 'filled' | 'outlined'
  name: Path<T>
  control: Control<T>
}

const CurrencyFormInput = <T extends FieldValues>({
  size,
  icon,
  label,
  name,
  control,
  inputStyle,
  labelClassName,
  ...props
}: CurrencyInputProps<T>) => {
  const {
    fieldState: { error },
  } = useController({
    name,
    control,
  })

  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
  })

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value } }) => (
        <MaskedInput
          mask={currencyMask}
          {...props}
          value={value}
          onChange={onChange}
          defaultValue={value}
          onBlur={onBlur}
          render={(maskedInputRef, maskedInputProps) => (
            <FormInput
              ref={maskedInputRef as ForwardedRef<HTMLInputElement>}
              {...maskedInputProps}
              size={size}
              icon={icon}
              labelClassName={labelClassName}
              inputStyle={inputStyle}
              error={error?.message}
              label={label}
            />
          )}
        />
      )}
    />
  )
}

export default CurrencyFormInput
