import BurgerIcon from '@src/icons/burger'
import styles from './top-bar.module.scss'
import { Link } from 'react-router-dom'

import { FC } from 'react'
import { TopBarProps } from '../types/top-bar.type'
import TopBarNotification from './top-bar-notification/tob-bar-notification.component'
import TopbarMail from './top-bar-mail/tob-bar-mail.component'
import TopbarProfile from './top-bar-profile/tob-bar-profile.component'
import NavigationButton from '@components/navigation-button/navigation-button'

const TopBar: FC<TopBarProps> = ({ displayInHeader, onSideBarIconClick }) => {
  const DisplayInHeader = displayInHeader
  return (
    <header className={styles.topBarWrapper}>
      <div>
        <NavigationButton icon={BurgerIcon} onClick={onSideBarIconClick}/>
      </div>
      <Link className="topbar__logo" to={'/dashboard'} />
      <div className={styles.displayInHeaderComponentWrapper}>
        {DisplayInHeader ? <DisplayInHeader /> : null}
      </div>
      <div className={styles.actions}>
        <TopBarNotification />
        <TopbarMail />
      </div>
      <TopbarProfile />
    </header>
  )
}

export default TopBar
