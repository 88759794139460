import FormInput from '@components/inputs/form-input/form-input.component'
import WipPropertiesList from './wip-properties-list.component'
import SearchIcon from '@src/icons/search'
import { FC, useEffect, useState } from 'react'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { WipPropertiesListWithSearchProps } from '../../types/properties.type'
import { toast } from 'react-toastify'
import { useUpdateWipByIdMutation } from '@containers/Recovery/Wip/hooks/mutations/update-wip-by-id.hook'
import { useQueryClient } from '@tanstack/react-query'
import { getWipQueryKey } from '@containers/Recovery/Wip/utils/wip-query-factory'
import { Wip } from '@containers/Recovery/Wip/types/wip.type'
import styles from './wip-properties-list.module.scss'
import { Property } from '@containers/Sales/Properties/types/property.type'

const WipPropertiesListWithSearch: FC<WipPropertiesListWithSearchProps> = ({
  wipId,
  clientId,
  initialSelectedProperty,
  selectedAssignProperty,
  onAssignPropertyClick,
}) => {
  const queryClient = useQueryClient()
  const { mutateAsync, isLoading } = useUpdateWipByIdMutation()
  const [selectedAssignProperties, setSelectedAssignProperties] = useState<Property[]>(
    initialSelectedProperty ? [initialSelectedProperty] : [],
  )

  useEffect(() => {
    setSelectedAssignProperties(initialSelectedProperty ? [initialSelectedProperty] : [])
  }, [initialSelectedProperty])

  const handleAssignPropertyChange = (checked: boolean, property: Property) => {
    if (checked) {
      setSelectedAssignProperties((prev) => [...prev, property])
    } else {
      setSelectedAssignProperties((prev) => prev.filter((p) => p.id !== property.id))
    }
  }

  const handleAssignProperty = async () => {
    if (selectedAssignProperties.length === 0) {
      toast.warning('Please select a property to assign')
      return
    }
    if (selectedAssignProperties.length > 1) {
      toast.warning('Please select only one property to assign')
      return
    }

    try {
      const updatedWip = await mutateAsync({
        id: wipId,
        data: {
          property: { id: selectedAssignProperties[0].id },
        },
        params: { join: ['property'], fields: 'property' },
      })
      queryClient.setQueryData<Wip>(getWipQueryKey(wipId), (prevData) =>
        prevData ? { ...prevData, property: updatedWip.property } : undefined,
      )
      toast.success('Property assigned successfully')
    } catch (e) {
      toast.error('Failed to assign property')
      console.log(e)
    }
  }

  return (
    <div className={styles.propertiesListWithSearch}>
      <FormInput size="small" icon={SearchIcon} placeholder="Start your search here..." />
      <WipPropertiesList
        selectedAssignProperty={selectedAssignProperty}
        wipId={wipId}
        clientId={clientId}
        selectedAssignProperties={selectedAssignProperties}
        onAssignPropertyChange={handleAssignPropertyChange}
        onAssignPropertyClick={onAssignPropertyClick}
      />
      <SubmitButton onClick={handleAssignProperty} buttonType="button" loading={isLoading}>
        Assign Property
      </SubmitButton>
    </div>
  )
}

export default WipPropertiesListWithSearch
