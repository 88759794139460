import {
  DebtorWorkPlaceFormValues,
  EditDebtorWorkPlaceFormProps,
} from '@containers/Recovery/Wip/_modules/Debtors/types/debtor-associate-detail.type'
import { FC } from 'react'
import { toast } from 'react-toastify'
import { useUpdateDebtorWorkPlaceMutation } from '@containers/Recovery/Wip/_modules/Debtors/hooks/mutations/crud-debtor-work-place.hook'
import DebtorWorkPlaceForm from './debtor-work-place-form.component'

const EditDebtorWorkPlaceForm: FC<EditDebtorWorkPlaceFormProps> = ({
  debtorWorkPlaceId,
  onSuccessSubmit,
  onCancelClick,
  initialValues,
}) => {
  const { mutateAsync, isLoading } = useUpdateDebtorWorkPlaceMutation()

  const handleSubmit = async (data: DebtorWorkPlaceFormValues) => {
    try {
      const updatedDebtorWorkPlace = await mutateAsync({
        id: debtorWorkPlaceId,
        data: {
          ...data,
          call_result: data.call_result.value,
        },
      })
      onSuccessSubmit(updatedDebtorWorkPlace)
      toast.success('Updated')
    } catch (e) {
      toast.error('Something went wrong')
      console.log(e)
    }
  }

  return (
    <DebtorWorkPlaceForm
      onSubmit={handleSubmit}
      onCancelClick={onCancelClick}
      loading={isLoading}
      initialValues={initialValues}
    />
  )
}

export default EditDebtorWorkPlaceForm
