import Table from '@components/table/table.component'
import { DebtorOtherTableProps } from '@containers/Recovery/Wip/_modules/Debtors/types/debtor-associate-detail.type'
import { FC } from 'react'
import DebtorOtherTableItem from './debtor-other-table-item.component'

const DebtorOtherTable: FC<DebtorOtherTableProps> = ({ others, debtorId, onItemClick }) => {
  return (
    <Table
      headers={['NAME', 'PHONE', 'ADDRESS', 'REMOVE']}
      paginated={false}
      bodyHeight={54 * others.length}
    >
      {others.map((other) => (
        <DebtorOtherTableItem
          key={other.id}
          other={other}
          debtorId={debtorId}
          onItemClick={onItemClick}
        />
      ))}
    </Table>
  )
}

export default DebtorOtherTable
