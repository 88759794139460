import EmailItem from './email-item.component'
import styles from './email-list.module.scss'
import EmailListSkeleton from '../email-list-skeleton/email-list-skeleton.component'
import { FC } from 'react'
import { EmailListProps } from '../../types/inbox.type'

const EmailList: FC<EmailListProps> = ({ emails = [], isLoading }) => {
  const isEmailListFound = !!emails.length && !isLoading
  const isEmailsListNotFound = !emails.length && !isLoading

  return (
    <ul className={styles.emailListWrapper}>
      {isLoading ? <EmailListSkeleton /> : null}
      {isEmailListFound ? (
        <>
          {emails.map((email) => (
            <EmailItem
              id={email.id}
              senderImage={
                'https://img.freepik.com/free-photo/freedom-concept-with-hiker-mountain_23-2148107064.jpg'
              }
              isRead={email.isRead}
              senderName={email.from.emailAddress.name}
              emailSubject={email.subject}
              sendAt={new Date(email.sentDateTime)}
              key={`email-item-${email.id}`}
            />
          ))}
        </>
      ) : null}
      {isEmailsListNotFound ? (
        <span className={styles.notFound}>
          <b>No Emails Found</b>
        </span>
      ) : null}
    </ul>
  )
}

export default EmailList
