import { postCommercialUncoveredFormEnum } from '../actionTypes';
import { postCommercialUncoveredFormService } from '../service';

export const postCommercialUncoveredForm = (data) => async (dispatch) => {
  dispatch({ type: postCommercialUncoveredFormEnum.IS_LOADING_POST_COMMERCIAL_UNCOVERED_FORM });
  try {
    const res = await postCommercialUncoveredFormService(data);

    dispatch({
      type: postCommercialUncoveredFormEnum.POST_COMMERCIAL_UNCOVERED_FORM_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: postCommercialUncoveredFormEnum.POST_COMMERCIAL_UNCOVERED_FORM_ERROR,
      payload: error,
    });

    throw error;
  }
};
