import { toast } from 'react-toastify'
import { FC, useMemo } from 'react'
import { BaseEditFormProps } from '@src/types/component-types'
import { DebtorLegalDefendant } from '../../../types/debtor-legal.type'
import { useUpdateDebtorLegalDefendantMutation } from '../../../hooks/mutations/crud-debtor-legal-defendant.hook'
import { DefendantFormValues } from '../../../types/debtor-legal-defendant.type'
import DebtorLegalDefendantForm from './debtor-legal-defendant-form.component'

const EditDebtorLegalDefendantForm: FC<BaseEditFormProps<DebtorLegalDefendant>> = ({
  entity: debtorLegalDefendant,
  onSuccessSubmit,
}) => {
  const { mutateAsync, isLoading } = useUpdateDebtorLegalDefendantMutation()
  const handleSuccessSubmit = async (data: DefendantFormValues) => {
    try {
      const updatedData = await mutateAsync({
        debtorLegalDefendantId: debtorLegalDefendant.id,
        data,
      })
      onSuccessSubmit(updatedData)
    } catch (error) {
      toast.error('Failed to update defendant')
      console.error(error)
    }
  }

  const initialValues = useMemo(
    () => ({
      defendantName: debtorLegalDefendant.defendantName,
      defendantAddress: debtorLegalDefendant.defendantAddress,
    }),
    [debtorLegalDefendant],
  )

  return (
    <DebtorLegalDefendantForm
      onSuccessSubmit={handleSuccessSubmit}
      initialValues={initialValues}
      loading={isLoading}
      submitButtonTitle="Update Defendant"
    />
  )
}

export default EditDebtorLegalDefendantForm
