import { FC, useEffect, useState } from 'react'
import GenericSendEmailModal from '../../../../components/GenericSendEmailModal/generic-send-email-modal'
import axios from 'axios'
import { REACT_APP_API_URL } from '@src/lib/global-variables'
import { useQueryClient } from '@tanstack/react-query'
import { NOTE_QUERY } from '@containers/Setting/components/Contacts/Notes/const/note-query.const'

interface SendEmailToDebtorModalProps {
  wipId?: number | null
  debtorId?: number | null
  isOpen: boolean
  onClose: () => void
}

const SendEmailToDebtorModal: FC<SendEmailToDebtorModalProps> = ({
  debtorId = null,
  isOpen,
  onClose,
  wipId = null,
}) => {
  const queryClient = useQueryClient()
  const [senderOptions, setSenderOptions] = useState<{ label: string; value: string }[] | null>(
    null,
  )

  const [emailTemplates, setEmailTemplates] = useState<{ label: string; value: string }[] | null>(
    null,
  )

  const additionalValuesInRequeset = { debtor_id: debtorId, wip_id: wipId, what_to: 'EMAIL' }

  const getSenderOptions = async () => {
    if (wipId) {
      return getSenderOptionsForWip(wipId)
    }
    if (debtorId) {
      return getSenderOptionsForDebtor(debtorId)
    }
  }

  const getSenderOptionsForDebtor = async (debtorId: number) => {
    const params = {
      join: 'contact',
    }
    const response = await axios.get(`${REACT_APP_API_URL}/debtors/${debtorId}`, { params })

    const data = response.data

    const phone = data.contact.comms_email[0]
    const label = `${data.name_first} ${data.name_last}`

    setSenderOptions(phone ? [{ label, value: phone }] : [])
  }

  const getSenderOptionsForWip = async (wipId: number) => {
    const params = {
      join: ['debtors', 'debtors.contact'],
    }

    const response = await axios.get(`${REACT_APP_API_URL}/wips/${wipId}`, { params })

    const data = response.data

    const senderOptions = data.debtors
      .map((debtor) => {
        const contact = debtor?.contact

        if (!contact) {
          return undefined
        }

        return {
          label: `${debtor.name_first} ${debtor.name_last}`,
          value: contact.comms_email[0],
        }
      })
      .filter((option) => !!option && !!option.value)

    setSenderOptions(senderOptions)
  }

  const fetchEmailTemplate = async () => {
    const response = await axios.get(`${REACT_APP_API_URL}/email-templates`)
    const data = response.data.data
    const formatedTemplates =
      data.map((template) => ({ label: template?.title, value: template?.body })) || []

    setEmailTemplates(formatedTemplates)
  }

  useEffect(() => {
    if ((debtorId || wipId) && isOpen) {
      getSenderOptions()
    }
  }, [debtorId, isOpen])

  useEffect(() => {
    fetchEmailTemplate()
  }, [])

  const isInnerOpen = (debtorId || wipId) && isOpen && senderOptions && emailTemplates

  return (
    <GenericSendEmailModal
      sendToOther
      senderOptions={senderOptions || []}
      isOpen={!!isInnerOpen}
      onClose={onClose}
      emailTemplates={emailTemplates || []}
      additionalValuesInRequeset={additionalValuesInRequeset}
      onEmailSend={() => queryClient.invalidateQueries([NOTE_QUERY.EMAIL.GET])}
    />
  )
}

export default SendEmailToDebtorModal
