import LabelWrapper from '@components/label-wrapper'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import { REACT_APP_API_URL } from '@src/lib/global-variables'
import { toast } from 'react-toastify'
import { useUserContext } from '@src/context/user-context'
import { useQueryClient } from '@tanstack/react-query'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import { FC } from 'react'
import styles from './combined-note.module.scss'
import { AddCombinedNoteFormProps } from '../../types/combined-notes.type'
import { formatAssigneName } from '../utils'
import { useTaskContext } from '@src/context/task-context'
import TasksService from '@services/TasksService.js'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'

const AddTaskNoteForm: FC<AddCombinedNoteFormProps> = ({
  taskId,
  isCompleted,
  onClose,
  onSubmit,
}) => {
  const { control, handleSubmit, reset } = useForm<{ note: string }>()
  const { isRecovery, isSales, currentWip, selectedCompanyState } = useTaskContext()
  const queryClient = useQueryClient()
  const { user } = useUserContext()

  const onCompletedClick = async () => {
    if (!taskId) return
    const taskService = new TasksService()
    try {
      await taskService.changeColumnBelongId(
        taskId,
        // done
        2,
        // TODO: change position depoending on the column
        1,
        false,
      )
    } catch (error) {
      console.log(error)
      toast.error('Something went wrong')
    } finally {
      onClose()
    }
  }

  const handleAddNote = async (data: { note: string }) => {
    const { note } = data
    if (!note) return toast.warning('Note is required')

    try {
      await axios.post(`${REACT_APP_API_URL}/task-note`, {
        note,
        task_id: taskId,
        wip_id: isRecovery ? currentWip : null,
        company_id: isSales ? selectedCompanyState?.id : null,
        createdBy: formatAssigneName({
          first_name: user?.first_name || '',
          last_name: user?.last_name || '',
          isAssigned: false,
        }),
        updatedBy: null,
      })
      reset({ note: '' })
      toast.success('Note added')
    } catch (error) {
      console.log({ error })
      toast.error('Something went wrong')
    }
    queryClient.invalidateQueries([GLOBAL_QUERIES.COMBINED_NOTES.GET])
  }

  return (
    <div>
      <h4>Add Note</h4>
      <LabelWrapper label="" control={control} name="note">
        {({ onChange, value }) => (
          <textarea
            className={styles.noteInput}
            value={value}
            onChange={onChange}
            placeholder="What happened during your task?"
          />
        )}
      </LabelWrapper>
      <div className="d-flex justify-content-between mt-2">
        <div className="d-flex align-items-center">
          {isRecovery && !isCompleted ? (
            <SubmitButton onClick={onCompletedClick}>Completed</SubmitButton>
          ) : null}
          {isSales ? <SubmitButton onClick={onSubmit}>Save Note</SubmitButton> : null}
        </div>
        <SubmitButton onClick={handleSubmit(handleAddNote)}>Add Note</SubmitButton>
      </div>
    </div>
  )
}

export default AddTaskNoteForm
