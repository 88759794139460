import FilterIcon from '@src/icons/filter'
import { useState } from 'react'
import styles from './task-filter.module.scss'
import AssignAttributes from '@components/assign-attributes'
import { IAssignAttributesProps } from '@src/types/component-types'
import cls from 'classnames'

interface IProps {
  onChange: (value: number[]) => void
  options: IAssignAttributesProps['options']
  values: number[]
  handleClearFilter: () => void
}

const TaskFilter = ({
  onChange = () => null,
  options = [],
  values = [],
  handleClearFilter = () => null,
}: IProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const isFilterActive = values.length > 0

  const handleIsOpenStateChange = () => {
    setIsOpen((prev) => !prev)
  }

  const handleCloseDropdown = () => setIsOpen(false)

  const handleResetFilter = () => {
    setIsOpen(false)
    handleClearFilter()
  }
  return (
    <div className={styles.wrapper}>
      {isFilterActive && (
        <div>
          <button onClick={handleResetFilter} className={styles.filterButton}>
            Clear Filters
          </button>
        </div>
      )}
      <div className={styles.filterContent}>
        <button className={styles.filterButton} onClick={handleIsOpenStateChange}>
          <FilterIcon /> Filter
        </button>
        {isOpen && (
          <AssignAttributes
            isOpen={isOpen}
            handleDropdownChangeState={handleIsOpenStateChange}
            handleCloseDropdown={handleCloseDropdown}
            values={values}
            onChange={onChange}
            options={options}
            className={cls(styles.filter, { [styles.isActive]: isFilterActive })}
          />
        )}
      </div>
    </div>
  )
}

export default TaskFilter
