import React from 'react'
import { Route, Switch } from 'react-router-dom'
import MainWrapper from '../MainWrapper'
import LogIn from '../../Account/LogIn/index'
import ResetPassword from '../../../shared/components/login/ResetPassword'
import WrappedRoutes from './WrappedRoutes'
import { ToastContainer } from 'react-toastify'
import { PublicRoute } from './guards/public-route.guard'
import LoginRedirectPage from '@containers/Account/LoginRedirect/login-redirect-page.component'

const Router = () => (
  <MainWrapper>
    <ToastContainer />
    <Switch>
      <Route path="/login/redirect" exact component={LoginRedirectPage} />
      <PublicRoute path="/login" exact component={LogIn} />
      <PublicRoute path="/reset" exact component={ResetPassword} />
      <Route path="/" component={WrappedRoutes} />
    </Switch>
  </MainWrapper>
)

export default Router
