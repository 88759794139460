import Section from '@components/section/section.component'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import DebtorWorkPlaceTable from '../../tables/debtor-work-place-table/debtor-work-place-table.component'
import { FC, useState } from 'react'
import SubSection from '@containers/Recovery/Wip/components/UI/sub-section/sub-section.component'
import styles from './debtor-work-places-section.module.scss'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { useModal } from '@src/hooks/useModal'
import CreateDebtorWorkPlaceModal from '../../modals/debtor-work-place-modal/create-debtor-work-place-modal.componen'
import {
  DebtorWorkPlace,
  DebtorWorkPlacesSectionProps,
} from '@containers/Recovery/Wip/_modules/Debtors/types/debtor-associate-detail.type'
import EditDebtorWorkPlaceModal from '../../modals/debtor-work-place-modal/edit-debtor-work-place-modal.component'

const DebtorWorkPlacesSection: FC<DebtorWorkPlacesSectionProps> = ({ workPlaces, debtorId }) => {
  const [isOpen, handleOpen, handleClose] = useModal(false)
  const [isEditModalOpen, handleEditModalOpen, handleEditModalClose] = useModal(false)

  const [selectedDebtorWorkPlace, setSelectedDebtorWorkPlace] = useState<DebtorWorkPlace | null>(
    null,
  )

  const handleItemClick = (workPlace: DebtorWorkPlace) => {
    setSelectedDebtorWorkPlace(workPlace)
    handleEditModalOpen()
  }
  return (
    <Section>
      <SubSection className={styles.headerWrapper} mb={4} p={4}>
        <Header>List Work Places</Header>
        <SubmitButton buttonType="button" onClick={handleOpen}>
          Add Work Place
        </SubmitButton>
      </SubSection>
      <DebtorWorkPlaceTable
        workPlaces={workPlaces}
        debtorId={debtorId}
        onItemClick={handleItemClick}
      />
      <CreateDebtorWorkPlaceModal debtorId={debtorId} isOpen={isOpen} onClose={handleClose} />
      {selectedDebtorWorkPlace && (
        <EditDebtorWorkPlaceModal
          debtorId={debtorId}
          debtorWorkPlace={selectedDebtorWorkPlace}
          isOpen={isEditModalOpen}
          onClose={handleEditModalClose}
        />
      )}
    </Section>
  )
}

export default DebtorWorkPlacesSection
