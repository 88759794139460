import Modal from '@components/modal'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import { FC } from 'react'
import {
  DebtorReference,
  EditDebtorReferenceModalProps,
} from '@containers/Recovery/Wip/_modules/Debtors/types/debtor-associate-detail.type'
import { DEBTOR_CALL_RESULT_OPTIONS } from '@containers/Recovery/Wip/_modules/Debtors/const/debtor-associate-details.const'
import { useQueryClient } from '@tanstack/react-query'
import { getDebtorQueryKey } from '@containers/Recovery/Wip/_modules/Debtors/utils/debtor-query-key-factory.utils'
import { Debtor } from '@containers/Recovery/Wip/_modules/Debtors/types/debtor.type'
import EditDebtorReferenceForm from '../../forms/debtor-reference-form/edit-debtor-reference-form.componen'
import styles from './debtor-reference-modal.module.scss'

const EditDebtorReferenceModal: FC<EditDebtorReferenceModalProps> = ({
  debtorReference,
  debtorId,
  ...modalProps
}) => {
  const queryClient = useQueryClient()
  const handleSuccessSubmit = (debtorReference: DebtorReference) => {
    queryClient.setQueryData<Debtor>(getDebtorQueryKey(debtorId), (prevData) =>
      prevData
        ? {
            ...prevData,
            references: prevData.references.map((relation) =>
              relation.id === debtorReference.id ? debtorReference : relation,
            ),
          }
        : undefined,
    )
    modalProps.onClose()
  }
  return (
    <Modal
      className={styles.modalWrapper}
      {...modalProps}
      withBtns={false}
      classNameLayout={styles.layout}
    >
      <Header mb={4}>Edit Reference</Header>
      <EditDebtorReferenceForm
        onCancelClick={modalProps.onClose}
        onSuccessSubmit={handleSuccessSubmit}
        debtorReferenceId={debtorReference.id}
        initialValues={{
          name: debtorReference.name,
          phone: debtorReference.phone,
          address: debtorReference.address,
          call_note: debtorReference.call_note,
          call_result:
            DEBTOR_CALL_RESULT_OPTIONS.find(
              (option) => option.value === debtorReference.call_result,
            ) || DEBTOR_CALL_RESULT_OPTIONS[0],
        }}
      />
    </Modal>
  )
}

export default EditDebtorReferenceModal
