import * as React from "react"
import { SVGProps } from "react"

const SquareAddIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 21 21" {...props}>
    <title>{"plus [#1512]"}</title>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M21 9v2h-9.45v9h-2.1v-9H0V9h9.45V0h2.1v9z"
    />
  </svg>
)
export default SquareAddIcon
