import BaseService from './BaseService';
import axios from 'axios';

export default class PropertyService extends BaseService {
  constructor() {
    super();
    this.apiUrl = `${process.env.REACT_APP_API_URL}` + '/properties';
  }

  async getProperty(params) {
    try {
      const response = await axios.get(this.apiUrl, { params } );

      if (response.status !== 200) {
        throw new Error('Failed to fetch staff');
      }

      return response;
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message);
      } else {
        if (error.response) {
          throw new Error(error.response.data);
        }
        if (!error.response) {
          throw new Error(error);
        }
      }
    }
  }

  async getPropertyId(params) {
    try {
      const response = await axios.get(`${this.apiUrl}/${params.id}`, { params });

      if (response.status !== 200) {
        throw new Error('Failed to fetch staff');
      }

      return response;
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message);
      } else {
        if (error.response) {
          throw new Error(error.response.data);
        }
        if (!error.response) {
          throw new Error(error);
        }
      }
    }
  }


  async addProperty(data) {
    try {
      const response = await axios.post(this.apiUrl, data);

      if (response.status !== 201) {
        throw new Error('Failed to add staff');
      }

      return response;
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message);
      } else {
        if (error.response) {
          throw new Error(error.response.data);
        }
        if (!error.response) {
          throw new Error(error);
        }
      }
    }
  }

  async deleteProperty(params) {
    try {

      const response = await axios.delete(`${this.apiUrl}/${params.id}`);

      return response;
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message);
      } else {
        if (error.response) {
          //throw new Error(error.response.data);
        }
        if (!error.response) {
          throw new Error(error);
        }
      }
    }
  }

  async editProperty(val) {
    try {

      const response = axios.patch(`${this.apiUrl}/${val.id}`, val);

      return response;
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message);
      } else {
        if (error.response) {
          throw new Error(error.response.data);
        }
        if (!error.response) {
          throw new Error(error);
        }
      }
    }
  }
}
