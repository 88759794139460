import { FC, useMemo } from 'react'
import {
  DebtorServiceFormValues,
  EditDebtorServiceFormProps,
} from '../../../types/debtor-legal-service.type'
import DebtorServiceForm from './debtor-service-form.component'
import { useUpdateDebtorLegalMutation } from '../../../hooks/mutations/crud-debtor-legal.hook'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import { getDebtorLegalByIdQueryKey } from '../../../utils/legal-query-key-factory.utils'
import { DebtorLegal } from '../../../types/debtor-legal.type'

const EditDebtorServiceForm: FC<EditDebtorServiceFormProps> = ({ debtorLegal }) => {
  const { mutateAsync, isLoading } = useUpdateDebtorLegalMutation()
  const queryClient = useQueryClient()
  const handleSubmit = async (data: DebtorServiceFormValues) => {
    try {
      const updatedDebtorLegal = await mutateAsync({
        debtorLegalId: debtorLegal.id,
        data: {
          servedDate: data.servedDate,
          enforcementDate: data.enforcementDate,
          spfSentDate: data.spfSentDate,
          courtLocation: data.courtLocation,
        },
        params: {
          fields: Object.keys(data).join(','),
        },
      })
      queryClient.setQueryData<DebtorLegal>(
        getDebtorLegalByIdQueryKey(debtorLegal.id),
        (prevData) => (prevData ? { ...prevData, ...updatedDebtorLegal } : updatedDebtorLegal),
      )
      toast.success('Debtor service updated successfully')
    } catch (error) {
      toast.error('Failed to update debtor service')
      console.error(error)
    }
  }

  const initialValues = useMemo(
    () => ({
      servedDate: debtorLegal.servedDate,
      enforcementDate: debtorLegal.enforcementDate,
      spfSentDate: debtorLegal.spfSentDate,
      courtLocation: debtorLegal.courtLocation,
    }),
    [debtorLegal],
  )

  return (
    <DebtorServiceForm onSubmit={handleSubmit} initialValues={initialValues} loading={isLoading} />
  )
}

export default EditDebtorServiceForm
