import Modal from '@components/modal'
import { BaseModalProps } from '@src/types/component-types'
import { FC } from 'react'
import styles from './upload-multiple-properties-modal.module.scss'
import UploadMultiplePropertiesForm
  from '../../forms/upload-multiple-properties-form/upload-multiple-properties-form.component'
import ConfirmationDialog from '@components/dialogs/confirmation-dialog/confirmation-dialog.component'
import { useModal } from '@src/hooks/useModal'
import { Text } from '@containers/Recovery/Wip/components/UI/text/text.component'
import { PropertyService } from '@containers/Sales/Properties/services/property.service'
import { toast } from 'react-toastify'
import { FormProvider, useForm } from 'react-hook-form'
import { CreatePropertyData } from '@containers/Sales/Properties/types/property.type'
import { useQueryClient } from '@tanstack/react-query'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import { useParams } from 'react-router'
import { transformFieldToArray } from '@containers/Sales/Properties/utils/property.utils'

interface UploadMultiplePropertiesModalProps extends BaseModalProps {
  companyId: number
}

export const UploadMultiplePropertiesModal: FC<UploadMultiplePropertiesModalProps> = ({
                                                                                        onClose,
                                                                                        companyId,
                                                                                        ...modalProps
                                                                                      }) => {
  const [isOpen, handleOpen, handleClose] = useModal(false)
  const { clientId } = useParams<{ clientId: string; propertyId: string }>()
  const handleModalClose = () => {
    handleOpen()
  }
  const queryClient = useQueryClient()


  const handleSubmit = async (data: Record<string, string>[]) => {
    const dto = data.map(value => {
      return transformFieldToArray(value as unknown as CreatePropertyData, 'additionalLandlord')
    })
    const propertyService = new PropertyService()
    try {
      await propertyService.createManyProperties(dto, companyId)
      toast.success('Properties uploaded successfully')
      queryClient.invalidateQueries([GLOBAL_QUERIES.PROPERTIES.GET, +clientId])
      methods.reset()
      onClose()
    } catch (error) {
      toast.error('Failed to upload properties')
      console.error(error)
    }
  }
  const methods = useForm()

  const handleConfirm = () => {
    methods.reset()
    onClose()
    handleClose()
  }

  return (
    <Modal
      className={styles.modalWrapper}
      onClose={handleModalClose}
      withBtns={false}
      {...modalProps}
    >
      <FormProvider {...methods}>
        <UploadMultiplePropertiesForm onSubmit={handleSubmit} />
      </FormProvider>

      <ConfirmationDialog
        isOpen={isOpen}
        onClose={handleClose}
        onConfirm={handleConfirm}
        title="Exit import flow"
        confirmButtonText="Exit"
        confirmationButtonStyle="alert"
      >
        <Text color="black" fontSize="large">
          Are you sure? Your current information will not be saved.
        </Text>
      </ConfirmationDialog>
    </Modal>
  )
}
