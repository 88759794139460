import { toast } from 'react-toastify'
import AddressForm, { AddressFormValues } from './address-form.component'
import { useUpdatePropertyMutation } from '@containers/Sales/Properties/hooks/crud-property.hook'
import { FC, useMemo } from 'react'
import { BaseEditFormProps } from '@src/types/component-types'
import { Property } from '@containers/Sales/Properties/types/property.type'
import { STATE_OPTIONS } from '@src/lib/select-options.const'
import { transformEmptyStringToNull } from '@containers/Recovery/Wip/_modules/Debtors/utils/transform-debtor-to-values.utils'

const EditPropertyAddressForm: FC<BaseEditFormProps<Property>> = ({ entity, onSuccessSubmit }) => {
  const { isLoading, mutateAsync } = useUpdatePropertyMutation()

  const handleSuccessSubmit = async (data: AddressFormValues) => {
    const transformedData = transformEmptyStringToNull(data)
    try {
      const updateRes = await mutateAsync({
        id: entity.id,
        data: { ...transformedData, state: transformedData.state?.value || null },
      })
      onSuccessSubmit(updateRes)
    } catch (e) {
      toast.error('Failed to update property address')
      console.error(e)
    }
  }

  const initialValues = useMemo(
    () => ({
      addressLine1: entity.addressLine1,
      addressLine2: entity.addressLine2,
      addressLine3: entity.addressLine3,
      postcode: entity.postcode,
      country: entity.country,
      suburb: entity.suburb,
      state: entity.state
        ? STATE_OPTIONS.find((option) => option.value === entity.state) || null
        : null,
    }),
    [entity],
  )

  return (
    <AddressForm
      loading={isLoading}
      initialValues={initialValues}
      onSuccessSubmit={handleSuccessSubmit}
    />
  )
}

export default EditPropertyAddressForm
