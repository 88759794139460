import React from 'react';

export const ManagementDetails = ({
  managementDetails,
  setManagementDetails,
}) => {
  const onInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setManagementDetails((prev) => ({ ...prev, [name]: value }));
  };

  const onNumberInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setManagementDetails((prev) => ({ ...prev, [name]: Number(value) }));
  };

  return (
    <>
      <div className="card__title">
        <h3 style={{ paddingBottom: '20px' }}>Enter Management Details</h3>
        <h5 className="bold-text">Self Managed Body Corporate - Option 1 </h5>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">
          Body Corporate Name <span style={{ color: '#ff0000' }}>*</span>
        </span>
        <div className="form__form-group-field  d-flex flex-column">
          <input
            name="sm_corporate_name"
            type="text"
            required={true}
            placeholder="Body Corporate Name"
            onChange={onInputChange}
            value={managementDetails?.sm_corporate_name}
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">
          Body Corporate ABN <span style={{ color: '#ff0000' }}>*</span>
        </span>
        <div className="form__form-group-field d-flex flex-column">
          <input
            name="sm_abn"
            type="number"
            placeholder="Body Corporate ABN"
            required={true}
            onChange={onNumberInputChange}
            value={managementDetails?.sm_abn}
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">
          Body Corporate Address Line 1
        </span>
        <div className="form__form-group-field  d-flex flex-column">
          <input
            name="sm_address1"
            type="text"
            placeholder="Address Line 1"
            onChange={onInputChange}
            value={managementDetails?.sm_address1}
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">
          Body Corporate Address Line 2
        </span>
        <div className="form__form-group-field d-flex flex-column">
          <input
            name="sm_address2"
            type="text"
            placeholder="Address Line 2"
            onChange={onInputChange}
            value={managementDetails?.sm_address2}
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">
          Body Corporate Address Line 3
        </span>
        <div className="form__form-group-field  d-flex flex-column">
          <input
            name="sm_address3"
            type="text"
            placeholder="Address Line 3"
            onChange={onInputChange}
            value={managementDetails?.sm_address3}
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Body Corporate Suburb</span>
        <div className="form__form-group-field d-flex flex-column">
          <input
            name="sm_suburb"
            type="text"
            placeholder="Suburb"
            onChange={onInputChange}
            value={managementDetails?.sm_suburb}
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Body Corporate Post Code</span>
        <div className="form__form-group-field d-flex flex-column">
          <input
            name="sm_post_code"
            type="number"
            placeholder="Post Code"
            onChange={onNumberInputChange}
            value={managementDetails?.sm_post_code}
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Body Corporate Country</span>
        <div className="form__form-group-field d-flex flex-column">
          <input
            name="sm_country"
            type="text"
            placeholder="Country"
            onChange={onInputChange}
            value={managementDetails?.sm_country}
          />
        </div>
      </div>

      <div className="form__form-group">
        <span className="form__form-group-label">Contact Person Details</span>
        <div className="form__form-group-field d-flex flex-column">
          <input
            name="sm_person_details"
            type="text"
            placeholder="Person Details"
            onChange={onInputChange}
            value={managementDetails?.sm_person_details}
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">
          Email Address for Reports<span style={{ color: '#ff0000' }}>*</span>
        </span>
        <div className="form__form-group-field d-flex flex-column">
          <input
            name="sm_email_reports"
            type="text"
            placeholder="Email"
            onChange={onInputChange}
            required={true}
            value={managementDetails?.sm_email_reports}
          />
        </div>
      </div>

      <div className="card__title">
        <h5 className="bold-text">Body Corporate Manager - Option 2 </h5>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">
          Body Corporate Name<span style={{ color: '#ff0000' }}>*</span>
        </span>
        <div className="form__form-group-field  d-flex flex-column">
          <input
            name="bc_name"
            type="text"
            required={true}
            placeholder="Name"
            onChange={onInputChange}
            value={managementDetails?.bc_name}
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">
          Body Corporate ABN<span style={{ color: '#ff0000' }}>*</span>
        </span>
        <div className="form__form-group-field d-flex flex-column">
          <input
            name="bc_abn"
            type="number"
            placeholder="ABN"
            required={true}
            onChange={onNumberInputChange}
            value={managementDetails?.bc_abn}
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">
          Body Corporate Manager Name
        </span>
        <div className="form__form-group-field  d-flex flex-column">
          <input
            name="bc_manager_name"
            type="text"
            placeholder="Manager Name"
            onChange={onInputChange}
            value={managementDetails?.bc_manager_name}
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">
          Body Corporate Contact Person
        </span>
        <div className="form__form-group-field d-flex flex-column">
          <input
            name="bc_contact_person"
            type="text"
            placeholder="Contact Person"
            onChange={onInputChange}
            value={managementDetails?.bc_contact_person}
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">
          Body Corporate Contact Details
        </span>
        <div className="form__form-group-field  d-flex flex-column">
          <input
            name="bc_contact_details"
            type="text"
            placeholder="Contact Details"
            onChange={onInputChange}
            value={managementDetails?.bc_contact_details}
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">
          Email Address for reports<span style={{ color: '#ff0000' }}>*</span>
        </span>
        <div className="form__form-group-field d-flex flex-column">
          <input
            name="bc_reports_email"
            type="text"
            placeholder="Email"
            required={true}
            onChange={onInputChange}
            value={managementDetails?.bc_reports_email}
          />
          {/* {errors.reports_email && (
            <span className="text-danger">Reports Email is required</span>
          )} */}
        </div>
      </div>
    </>
  );
};
