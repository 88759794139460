import SendEmailHeader from './send-email-header/send-email-header.component'
import SendEmailMain from './send-email-main/send-email-main.component'
import styles from './send-email-page.module.scss'

const SendEmailPage = () => {
  return (
    <div className={styles.sendEmailPageWrapper}>
      <SendEmailHeader />
      <SendEmailMain />
    </div>
  )
}

export default SendEmailPage
