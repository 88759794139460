import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { InvoiceTemplateLayout } from './InvoiceTemplateLayout';
import { InvoiceTemplateForm } from './InvoiceTemplateForm';
import { useFormik } from 'formik';
import { createInvoiceTemplateInitialValues } from '../../../../../shared/utils/createInvoiceTemplateInitialValues';
import { createInvoiceTemplateValidationSchema } from '../../../../../shared/validators/createInvoiceTemplateValidationSchema';
import { toast } from 'react-toastify';

import { editInvoiceTemplate, getInvoiceTemplateById } from '../../../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { editInvoiceTemplateLoading } from '../../../../../redux/selector/selector';
import { useUserContext } from '../../../../../context/user-context';

export const EditInvoiceTemplate = () => {
  const {user} = useUserContext()
  const userId = user.id;
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  // Setting editor value
  const [editorValue, setEditorValue] = useState('');
  const editingLoading = useSelector(editInvoiceTemplateLoading);

  // Setting form inputs except editor
  const { values, errors, touched, submitForm, handleChange, setFieldValue, isValid } = useFormik({
    initialValues: createInvoiceTemplateInitialValues,
    validationSchema: createInvoiceTemplateValidationSchema,
    validateOnChange: true,
    onSubmit: () => {
      if (isValid && editorValue.trim()) {
        const data = {
          ...values,
          is_deleted: false,
          is_archived: false,
          body: editorValue,
          staff_id_created: userId,
          staff_id_modified: userId,
        };

        dispatch(editInvoiceTemplate(id, data))
          .then(handleEditTemplateSuccess)
          .catch(handleEditTemplateError);
      } else {
        toast.error('Please fill again!');
      }
    },
  });

  useEffect(() => {
    dispatch(getInvoiceTemplateById(id))
      .then(handleGetTemplateByIdSuccess)
      .catch(handleGetTemplateByIdError);
  }, []);

  const handleEditorChange = (newValue) => {
    setEditorValue(newValue);
  };

  const handleClose = () => {
    history.push('/invoice-templates');
  };

  const handleGetTemplateByIdError = () => {
    toast.error('Id has not been found');
    return history.push('/invoice-templates');
  };

  const handleGetTemplateByIdSuccess = (res) => {
    setFieldValue('type', res.type || null);
    setFieldValue('title', res.title || null);
    setFieldValue('subject', res.subject || null);
    setEditorValue(res.body || '');
  };

  const handleEditTemplateSuccess = () => {
    toast.success('Successfully edited');
    return history.push('/invoice-templates');
  };

  const handleEditTemplateError = () => {
    toast.error('Something went wrong, please try again!');
  };

  if (!id) {
    return history.push('/invoice-templates');
  }

  return (
    <InvoiceTemplateLayout heading="Edit Invoice Template">
      <InvoiceTemplateForm
        values={values}
        errors={errors}
        touched={touched}
        editorValue={editorValue}
        handleChange={handleChange}
        handleSubmit={submitForm}
        handleEditorChange={handleEditorChange}
        handleClose={handleClose}
        loading={editingLoading}
      />
    </InvoiceTemplateLayout>
  );
};
