import axios from "axios";
import { CreateDebtorOtherData, DebtorOther } from "../types/debtor-associate-detail.type";
import { REACT_APP_API_URL } from "@src/lib/global-variables";
import { DeepPartial } from "react-hook-form";

export class DebtorOtherService {

  async createDebtorOther(data: CreateDebtorOtherData) {
    const res = await axios.post<DebtorOther>(`${REACT_APP_API_URL}/debtor-others`, data)
    return res.data
  }

  async deleteDebtorOther(id: number) {
    const res = await axios.delete<DebtorOther>(`${REACT_APP_API_URL}/debtor-others/${id}`)
    return res.data
  }

  async updateDebtorOther(id: number, data: DeepPartial<DebtorOther>) {
    const res = await axios.put<DebtorOther>(`${REACT_APP_API_URL}/debtor-others/${id}`, data)
    return res.data
  }
}