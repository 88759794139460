import Modal from '@components/modal'
import { BaseModalProps } from '@src/types/component-types'
import { FC } from 'react'
import CreateLandlordContactForm from '../../form/landlord-contact-form/create-landlord-contact-form.component'
import { LandlordContact } from '@containers/Sales/Properties/types/property.type'
import styles from './landlord-contact-modal.module.scss'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'

interface CreateLandlordContactModalProps extends BaseModalProps {
  landlordId: number
  onSuccessSubmit?: (landlordContact: LandlordContact) => void
}

const CreateLandlordContactModal: FC<CreateLandlordContactModalProps> = ({
  landlordId,
  onSuccessSubmit,
  ...modalProps
}) => {
  const handleSuccessSubmit = (landlordContact: LandlordContact) => {
    onSuccessSubmit && onSuccessSubmit(landlordContact)
    modalProps.onClose()
  }

  return (
    <Modal className={styles.modalWrapper} {...modalProps} withBtns={false}>
      <Header mb={4}>Add Owners / Landlord/s</Header>
      <CreateLandlordContactForm
        landlordId={landlordId}
        onSuccessSubmit={handleSuccessSubmit}
        onCancelClick={modalProps.onClose}
      />
    </Modal>
  )
}

export default CreateLandlordContactModal
