import Table from '@components/table/table.component'
import { Debtor } from '@containers/Recovery/Wip/_modules/Debtors/types/debtor.type'
import {
  CLIENT_CONTACT_PHONE_FIELD_TO_LABEL,
  DEBTOR_PHONE_FIELD_TO_LABEL,
} from '@containers/Setting/components/Contacts/taskboard/taskboard.const'
import { FC, useMemo } from 'react'
import { Contact } from '@containers/Setting/components/Contacts/types/contact-list.type'
import PhoneNumberTableItem from './phone-number-table-item.component'
import {
  PhoneNumberTableProps,
  IPhoneNumberTableItem,
} from '@containers/Communication/types/call.type'

const PhoneNumberTable: FC<PhoneNumberTableProps> = ({
  debtors = [],
  contacts = [],
  onPhoneNumberClick,
}) => {
  const getDebtorPhoneNumbers = (debtors: Debtor[]): IPhoneNumberTableItem[] => {
    return debtors
      .map((debtor) => {
        const contact = debtor.contact
        const tableItems: IPhoneNumberTableItem[] = []

        for (const key in contact) {
          console.log('contact', contact)
          if (!DEBTOR_PHONE_FIELD_TO_LABEL[key] || !contact[key]) continue

          tableItems.push({
            id: debtor.id,
            name: `${debtor.name_first} ${debtor.name_last}`,
            phone: contact[key],
            phoneType: DEBTOR_PHONE_FIELD_TO_LABEL[key],
            entityType: 'Debtor',
          })
        }

        return tableItems
      })
      .flat()
  }

  const getContactPhoneNumbers = (contacts: Contact[]): IPhoneNumberTableItem[] => {
    return contacts
      .map((contact) => {
        const tableItems: IPhoneNumberTableItem[] = []

        for (const key in contact) {
          if (!CLIENT_CONTACT_PHONE_FIELD_TO_LABEL[key] || !contact[key]) continue

          tableItems.push({
            id: contact.id,
            name: `${contact.first_name} ${contact.last_name}`,
            phone: contact[key],
            phoneType: CLIENT_CONTACT_PHONE_FIELD_TO_LABEL[key],
            entityType: 'Client Contact',
          })
        }

        return tableItems
      })
      .flat()
  }

  const debtorItems = useMemo(() => getDebtorPhoneNumbers(debtors), [debtors])
  const contactItems = useMemo(() => getContactPhoneNumbers(contacts), [contacts])
  const items = [...debtorItems, ...contactItems]

  return (
    <Table
      headers={['ID #', 'NAME', 'ENTITY TYPE', 'PHONE NUMBER', 'PHONE TYPE']}
      bodyHeight={items.length * 54}
      paginated={false}
    >
      {items.map((item) => (
        <PhoneNumberTableItem
          data={item}
          key={`${item.id}-${item.phoneType}-${item.entityType}`}
          onPhoneNumberClick={onPhoneNumberClick}
        />
      ))}
    </Table>
  )
}

export default PhoneNumberTable
