import { GLOBAL_QUERIES } from "@src/lib/global-queries"
import { REACT_APP_API_URL } from "@src/lib/global-variables"
import { useQuery } from "@tanstack/react-query"
import { AutomationColumn } from "../../taskboard/types"
import axios from "axios"

export const useGetAutomationByColumns = (columns?: AutomationColumn[]) => {
  const fetchAutomationsByColumns = async (columns: AutomationColumn[] | undefined) => {
    if (!columns) {
      return
    }
    const automations = await Promise.all(
      columns.map((column) =>
        axios.get(`${REACT_APP_API_URL}/automations`, {
          params: { s: JSON.stringify({ referrence_column_id: column.id }) },
        }),
      ),
    )
    return columns.map((column, index) => ({
      ...column,
      automations: automations[index].data.data,
    }))
  }

  const query = useQuery({
    queryKey: [GLOBAL_QUERIES.AUTOMATIONS.GET, columns],
    enabled: !!columns,
    queryFn: () => fetchAutomationsByColumns(columns),
  })

  return query;
}