import { Params } from "@src/types/service.type";
import { TaskService } from "../services/task.service";
import { useQuery } from "@tanstack/react-query";
import { GLOBAL_QUERIES } from "@src/lib/global-queries";

export const useGetTasks = (params?: Params) => {

  const getTasks = (params?: Params) => {
    const taskService = new TaskService()
    return taskService.getTasks(params)
  }

  const query = useQuery({ queryKey: [GLOBAL_QUERIES.TASKSERVICE.GET, params], queryFn: () => getTasks(params) })

  return query
}