import { Redirect, Switch } from 'react-router-dom'
import Layout from '../../../layout/layout.component'

import Setting from '../../../../containers/Setting/index'
import Tasks from '../../../Tasks/index'

import AddStaff from '../../../Setting/components/Staff/index'
import ListStaff from '../../../Setting/components/Staff/ListStaff.jsx'

import WIPCostsUser from '../../../Setting/components/WIPCost/WIPCostsUser.jsx'
import WIPCosts from '../../../Setting/components/WIPCost/WIPCosts.jsx'
import WIPCostsAdd from '../../../Setting/components/WIPCostsAdd/WIPCostsAdd.jsx'

import WIPCostsTable from '../../../Setting/components/WIPCostTable/WIPCostsTable.jsx'
import EditStaff from '../../../Setting/components/editStaff/index'
import WIPScaleOfFees from '../../../Setting/components/WIPScaleOfFee/index'
import ManagementTool from '../../../Setting/components/managementTool/index'
import Profile from '../../../Account/Profile/index'
import ContactList from '../../../Setting/components/Contacts/ContactList/contact-list'
import AutomationsPage from '../../../Setting/components/Contacts/automations-page'

import ContactAdd from '../../../Setting/components/Contacts/Add-Contacts'
import AddProperty from '../../../Setting/components/Tools/Properties/addProperty'
import ViewProperty from '../../../Setting/components/Tools/Properties/viewProperty'
import ConcatsAndNotes from '../../../Setting/components/Debtor/Index'
import ManagementTemplate from '../../../Setting/components/manageTemplates/index'
import Edittemplates from '../../../Setting/components/manageTemplates/editTemplate'
import Addtemplate from '../../../Setting/components/manageTemplates/addTemplate'
import AddCoverNote from '../../../Setting/components/Tools/CoverNotes/AddCoverNote'
import CoverNoteList from '../../../Setting/components/Tools/CoverNotes/CoverNoteList'
import WIPListRecovery from '../../../Recovery/WIPList'
import RecoveryAction from '../../../Recovery/index'
import WipDetails from '../../../Setting/components/Contacts/WIPs/index'
import AllTable from '../../../Setting/components/AllTable'
import list from '../../../Setting/components/reconciliation/list'
import ImportBankCSV from '../../../Setting/components/reconciliation/ImportBankCSV'
import TransactionsReport from '../../../Setting/components/Transactions'
import AddRecoveryForm from '../../../Form/RecoveryForm/index'
import RecoveryList from '../../../Form/RecoveryForm/list'
import EditRecoveryForm from '../../../Form/RecoveryForm/edit'
import TaskTableStaffID from '../../../../containers/Tasks/components/TaskTableStaffID'
import MonthReport from '../../../Setting/components/MonthReport/MonthReport'
import CorporateRecoveryList from '../../../Form/CorporateRecoveryForm/corporateRecoveryList'
import AddCorporateRecoveryForm from '../../../Form/CorporateRecoveryForm/addCorporateRecovery'
import EditCorporateRecoveryForm from '../../../Form/CorporateRecoveryForm/editCorporateRecoveryForm'
import listRecoveryTab from '../../../Form/FormRecoveryTools/listRecoveryTab'

import AddProtectCollectForm from '../../../Form/ProtectCollectForm/addProtectCollectForm'
import EditProtectAndCollectForm from '../../../Form/ProtectCollectForm/editProtectAndCollectForm'

import CommercialUncoveredFormList from '../../../Form/CommercialUncoveredForm/CommercialUncoveredFormList/CommercialUncoveredFormList'
import AddCommercialUncoveredForm from '../../../Form/CommercialUncoveredForm/AddCommercialUncoveredForm/AddCommercialUncoveredForm'
import EditCommercialUncoveredForm from '../../../Form/CommercialUncoveredForm/EditCommercialUncoveredForm/EditCommercialUncoveredForm'
import { AddInvoiceTemplate } from '../../../Setting/components/Tools/InvoiceTemplates/AddInvoiceTemplate'
import { EditInvoiceTemplate } from '../../../Setting/components/Tools/InvoiceTemplates/EditInvoiceTemplate'
import { InvoiceTemplates } from '../../../Setting/components/Tools/InvoiceTemplates/InvoiceTemplates'
import ContactsPage from '../../../Setting/components/Contacts/Index'
import WipTemplateForm from '../../../Form/WipTemplateForm/wip-template-form.component'
import WipTemplateBoard from '../../../Setting/components/Contacts/WipTemplateBoard/wip-template-board.component'
import DashBoardPage from '@containers/Dashboard'
import { PrivateRoute } from '../guards/private-route.guard'
import InboxPage from '@containers/Inbox/inbox-page/inbox-page.component'
import ViewEmailPage from '@containers/Inbox/view-email-page/view-email-page.component'
import SendEmailPage from '@containers/Inbox/send-email-page/send-email-page.component'
import SharedViewEmailPage from '@containers/Inbox/view-email-page/shared-view-email-page.component'
import { microsoftGuard } from '../guards/microsoft.guard'
import WipListPage from '@containers/Recovery/Wip/view/wip-list-page/wip-list-page.component'
import WipPage from '@containers/Recovery/Wip/view/wip-page/wip-page.component'
import WipCostListPage from '@containers/Recovery/WipCost/views/wip-cost-list-page/wip-cost-list-page.component'
import ProtectAndCollectListPage from '@containers/Recovery/RecoveryForms/ProtectAndCollect/views/protect-and-collect-list-page/protect-and-collect-list-page.component'
import PropertyListPage from '@containers/Sales/Properties/views/property-list-page/property-list-page.component'
import ClientPage from '@containers/Sales/Client/view/client-page/client-page.component'

const WrappedRoutes = () => {
  return (
    <>
      <Layout>
        <Switch>
          <PrivateRoute exact Route path="/dashboard" component={DashBoardPage} />

          <PrivateRoute exact Route path="/account/profile" component={Profile} />

          <PrivateRoute exact Route path="/management-tool" component={ManagementTool} />

          <PrivateRoute exact Route path="/setting" component={Setting} />

          <PrivateRoute exact Route path="/tasks" component={Tasks} />

          <PrivateRoute exact Route path="/staff-list" component={ListStaff} />

          <PrivateRoute exact Route path="/add-staff" component={AddStaff} />

          <PrivateRoute exact Route path="/edit-staff" component={EditStaff} />

          <PrivateRoute exact Route path="/table" component={AllTable} />

          <PrivateRoute exact Route path="/wip-costs-user" component={WIPCostsUser} />

          <PrivateRoute exact Route path="/wip-costs" component={WIPCosts} />

          <PrivateRoute exact Route path="/wip-costs-add" component={WIPCostsAdd} />

          <PrivateRoute exact Route path="/wip-costs-table" component={WIPCostsTable} />

          <PrivateRoute exact Route path="/wip-cost-list" component={WipCostListPage} />

          <PrivateRoute exact Route path="/wip-scale-of-fees" component={WIPScaleOfFees} />

          <PrivateRoute exact Route path="/recoveries" component={WipListPage} />

          <PrivateRoute exact Route path="/contact-list" component={ContactList} />

          <PrivateRoute exact Route path="/add-properties" component={AddProperty} />

          <PrivateRoute exact Route path="/view-properties" component={ViewProperty} />

          <PrivateRoute exact Route path="/properties" component={PropertyListPage} />

          <PrivateRoute exact Route path="/contacts-notes/:id" component={ConcatsAndNotes} />

          <PrivateRoute Route path="/contacts" component={ContactsPage} />

          <PrivateRoute Route path="/automations/:id" component={AutomationsPage} />

          <PrivateRoute exact Route path="/manage-templates" component={ManagementTemplate} />

          <PrivateRoute exact Route path="/edit-templates" component={Edittemplates} />

          <PrivateRoute exact Route path="/add-template" component={Addtemplate} />

          <PrivateRoute exact Route path="/add-cover-note" component={AddCoverNote} />

          <PrivateRoute exact Route path="/cover-note-list" component={CoverNoteList} />

          <PrivateRoute exact Route path="/invoice-templates" component={InvoiceTemplates} />

          <PrivateRoute exact Route path="/add-invoice-template" component={AddInvoiceTemplate} />

          <PrivateRoute
            exact
            Route
            path="/edit-invoice-template/:id"
            component={EditInvoiceTemplate}
          />

          <PrivateRoute exact Route path="/wip-list" component={WIPListRecovery} />

          <PrivateRoute exact Route path="/Add-Contact" component={ContactAdd} />

          <PrivateRoute exact Route path="/Recovery-Action" component={RecoveryAction} />

          <PrivateRoute exact Route path="/wip-details" component={WipDetails} />

          <PrivateRoute exact Route path="/wip-template/:id" component={WipTemplateBoard} />

          <PrivateRoute exact Route path="/recoveries/tools" component={list} />

          <PrivateRoute exact Route path="/import-bank-csv" component={ImportBankCSV} />

          <PrivateRoute
            exact
            Route
            path="/recoveries/transactions"
            component={TransactionsReport}
          />

          <PrivateRoute exact Route path="/recoveries/month-report" component={MonthReport} />

          <PrivateRoute
            exact
            Route
            path="/recoveries/forms/strata-body"
            component={CorporateRecoveryList}
          />

          <PrivateRoute
            exact
            Route
            path="/recoveries/forms/protect_&_collect"
            component={ProtectAndCollectListPage}
          />

          <PrivateRoute
            exact
            Route
            path="/recoveries/forms/protect_&_collect_form"
            component={AddProtectCollectForm}
          />

          <PrivateRoute
            exact
            Route
            path="/recoveries/forms/commercial"
            component={CommercialUncoveredFormList}
          />

          <PrivateRoute
            exact
            Route
            path="/recoveries/forms/create-commercial"
            component={AddCommercialUncoveredForm}
          />

          <PrivateRoute
            exact
            Route
            path="/recoveries/forms/edit-commercial/:id"
            component={EditCommercialUncoveredForm}
          />

          <PrivateRoute
            exact
            Route
            path="/recoveries/forms/wip-template"
            component={WipTemplateForm}
          />

          <PrivateRoute
            exact
            Route
            path="/recoveries/edit-protect_&_collect/:id"
            component={EditProtectAndCollectForm}
          />

          <PrivateRoute exact Route path="/recoveries/forms/" component={listRecoveryTab} />

          <PrivateRoute
            exact
            Route
            path="/recoveries/add-recovery-form"
            component={AddCorporateRecoveryForm}
          />

          <PrivateRoute
            exact
            Route
            path="/recoveries/edit-recovery-form/:id"
            component={EditCorporateRecoveryForm}
          />

          <PrivateRoute exact Route path="/recovery/add" component={AddRecoveryForm} />

          <PrivateRoute exact Route path="/recovery" component={RecoveryList} />

          <PrivateRoute exact Route path="/recovery/edit/:id" component={EditRecoveryForm} />

          <PrivateRoute exact Route path="/tasks-staff/:id" component={TaskTableStaffID} />

          <PrivateRoute Route path="/wip/:wipId" component={WipPage} />

          <PrivateRoute Route path="/client/:clientId" component={ClientPage} />

          <PrivateRoute exact Route path="/inbox" component={microsoftGuard(InboxPage)} />

          <PrivateRoute exact Route path="/email/:id" component={microsoftGuard(ViewEmailPage)} />

          <PrivateRoute
            exact
            Route
            path="/shared/:userId/inbox/email/:messageId"
            component={microsoftGuard(SharedViewEmailPage)}
          />

          <PrivateRoute
            exact
            Route
            path="/mail/compose"
            component={microsoftGuard(SendEmailPage)}
          />

          <PrivateRoute path="*" component={() => <Redirect to={'/dashboard'} />} />
        </Switch>
      </Layout>
    </>
  )
}

export default WrappedRoutes
