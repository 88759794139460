import Modal from '@components/modal'
import styles from './contact-info-modal.module.scss'
import { FC } from 'react'
import NavigationButton from '@components/navigation-button/navigation-button'
import { ContactInfoModalProps } from '../../types/task.type'

const ContactInfoModal: FC<ContactInfoModalProps> = ({ isOpen, onClose, options, linkType = 'tel' }) => {
  return (
    <Modal className={styles.modalWrapper} classNameLayout={styles.layout} isOpen={isOpen} onClose={onClose} withBtns={false}>
      <ul className={styles.listWrapper}>
        {options.map((option) => {
          return (
            <li key={option.label}>
              <b>{option.label}</b> : <a href={`${linkType}: ${option.value}`}>{option.value}</a>
            </li>
          )
        })}
        <NavigationButton
          className={styles.closeButton}
          onClick={onClose}
          icon={() => <span className="lnr lnr-cross"></span>}
        />
      </ul>
    </Modal>
  )
}

export default ContactInfoModal
