import {
  Control,
  Controller,
  ControllerRenderProps,
  FieldValues,
  Path,
  useController,
} from 'react-hook-form'
import cls from 'classnames'
import styles from './label-wrapper.module.scss'
import { ErrorMessage } from '@hookform/error-message'

interface LabelWrapperProps<T extends FieldValues> {
  children: (props: ControllerRenderProps<T, Path<T>>) => JSX.Element
  label: string
  name: Path<T>
  control: Control<T>
  className?: string
  isWrapperStylesEnabled?: boolean
}

const LabelWrapper = <T extends FieldValues>({
  children,
  label,
  name,
  control,
  className = '',
  isWrapperStylesEnabled = true,
}: LabelWrapperProps<T>) => {
  if (!children) return null
  const {
    formState: { errors },
  } = useController({
    name,
    control,
    rules: { required: true },
  })
  
  return (
    <div className={cls({ [styles.wrapper]: isWrapperStylesEnabled }, className)}>
      <span style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
        <span>{label}</span>
        <span>
          <ErrorMessage
            errors={errors}
            name={name as T[keyof T]}
            render={({ message }) => {
              return <p style={{ color: 'red' }}>{message}</p>
            }}
          />
        </span>
      </span>

      <Controller name={name} control={control} render={({ field }) => children({ ...field })} />
    </div>
  )
}

export default LabelWrapper
