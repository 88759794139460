import TableCell from '@components/table/table-cell-component'
import TableRow from '@components/table/table-row.component'
import { EmailTableItemProps } from '@containers/Communication/types/communication.type'
import { format } from 'date-fns'
import { FC } from 'react'

const EmailTableItem: FC<EmailTableItemProps> = ({ email, onEmailClick }) => {
  const { sent_at, to, subject, from } = email

  const handleEmailClick = () => { 
    onEmailClick && onEmailClick(email)
  }

  return (
    <TableRow onClick={handleEmailClick} pointer>
      {/* DATE */}
      <TableCell position="eclipsed" width={120}>
        {format(sent_at, 'MMM dd yyyy')}
      </TableCell>
      {/* TIME */}
      <TableCell position="eclipsed" width={120}>
        {format(sent_at, 'HH:mm aa')}
      </TableCell>
      {/* SUBJECT */}
      <TableCell position="eclipsed"> {subject}</TableCell>
      {/* TO */}
      <TableCell position="eclipsed"> {to.emailAddress.address}</TableCell>
      {/* FROM */}
      <TableCell position="eclipsed"> {from.emailAddress.address}</TableCell>
    </TableRow>
  )
}

export default EmailTableItem
