import Section from '@components/section/section.component'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import EditLandlordDetailsForm from '../../form/landlord-details-form/edit-landlord-details-form.component'
import { Landlord } from '@containers/Sales/Properties/types/property.type'
import { FC } from 'react'
import { toast } from 'react-toastify'

interface LandlordDetailsSectionProps {
  landlord: Landlord
}

const LandlordDetailsSection: FC<LandlordDetailsSectionProps> = ({ landlord }) => {
  return (
    <Section>
      <Header>Ownership Details</Header>
      <EditLandlordDetailsForm entity={landlord} onSuccessSubmit={() => toast.success('Landlord updated')} />
    </Section>
  )
}

export default LandlordDetailsSection
