import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import styles from './debtor-details-emails-form.module.scss'
import { useForm, useWatch } from 'react-hook-form'
import FormInput from '@components/inputs/form-input/form-input.component'
import CloseIcon from '@src/icons/close'
import { FC, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import { Debtor } from '@containers/Recovery/Wip/_modules/Debtors/types/debtor.type'
import { getDebtorQueryKey } from '@containers/Recovery/Wip/_modules/Debtors/utils/debtor-query-key-factory.utils'
import { useUpdateDebtorContactMutation } from '@containers/Recovery/Wip/_modules/Debtors/hooks/mutations/update-debtor-contact.hook'
import { joiResolver } from '@hookform/resolvers/joi'
import { debtorDetailsEmailsFormSchema } from '@containers/Recovery/Wip/_modules/Debtors/schemas/debtor.schemas'
import { DebtorDetailsEmailsFormProps, DebtorDetailsEmailsFormValues } from '@containers/Recovery/Wip/_modules/Debtors/types/debtor-form.type'

const DebtorDetailsEmailsForm: FC<DebtorDetailsEmailsFormProps> = ({
  debtorId,
  debtorContactId,
  initialValues,
}) => {
  const queryClient = useQueryClient()
  const { mutateAsync, isLoading } = useUpdateDebtorContactMutation()
  const {
    control,
    register,
    setValue,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<DebtorDetailsEmailsFormValues>({
    defaultValues: initialValues,
    resolver: joiResolver(debtorDetailsEmailsFormSchema)
  })

  const emails = useWatch({ control, name: 'emails' })

  const handleDeleteEmail = (index: number) => {
    setValue(
      'emails',
      emails.filter((_, i) => i !== index),
    )
  }

  const handleAddAnotherEmail = () => {
    setValue('emails', [...emails, ''])
  }

  const onSubmit = async (data: DebtorDetailsEmailsFormValues) => {
    try {
      const contact = await mutateAsync({
        id: debtorContactId,
        debtorContact: { comms_email: data.emails },
      })
      queryClient.setQueryData<Debtor>(getDebtorQueryKey(debtorId), (prevData) => ({
        ...(prevData as unknown as Debtor),
        contact,
      }))
      toast.success('Debtor updated successfully')
    } catch (e) {
      console.error(e)
      toast.error('Failed to update debtor')
    }
  }

  useEffect(() => {
    reset(initialValues)
  }, [initialValues])

  console.log('errors', errors)

  return (
    <form className={styles.formWrapper} onSubmit={handleSubmit(onSubmit)}>
      <Header>Emails</Header>
      <div className={styles.emailsWrapper}>
        {emails.map((email, index) => (
          <FormInput
            key={index}
            label={`Email ${index + 1}`}
            {...register(`emails.${index}`)}
            error={errors.emails?.[index]?.message}
          >
            <button
              className={styles.clearButton}
              type="button"
              onClick={() => handleDeleteEmail(index)}
            >
              <CloseIcon />
            </button>
          </FormInput>
        ))}
      </div>
      <div className={styles.actionWrapper}>
        <SubmitButton onClick={handleAddAnotherEmail} loading={isLoading}>
          Add Another Email
        </SubmitButton>
        <SubmitButton buttonType="submit">Save</SubmitButton>
      </div>
    </form>
  )
}

export default DebtorDetailsEmailsForm
