import { FC, useCallback, useRef } from 'react'
import styles from './wip-properties-list.module.scss'
import FormCheckbox from '@components/inputs/form-checkbox/form-checkbox.component'
import { SubHeader, Text } from '@containers/Recovery/Wip/components/UI/text/text.component'
import SubSection from '@containers/Recovery/Wip/components/UI/sub-section/sub-section.component'
import { WipPropertiesListProps } from '../../types/properties.type'
import cls from 'classnames'
import LoaderWrapper from '@components/loader/loader-wrapper.component'
import { useGetInfinityProperties } from '@containers/Sales/Properties/hooks/crud-property.hook'

const WipPropertiesList: FC<WipPropertiesListProps> = ({
  clientId,
  selectedAssignProperties,
  selectedAssignProperty,
  onAssignPropertyClick,
  onAssignPropertyChange,
}) => {
  const {
    data: properties,
    isLoading,
    hasNextPage,
    isFetching,
    fetchNextPage,
  } = useGetInfinityProperties(clientId, {
    limit: 5,
    sort: 'id,DESC',
  })

  const observer = useRef<IntersectionObserver>()

  const lastElementRef = useCallback(
    (node: HTMLLIElement) => {
      if (isLoading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage && !isFetching) {
          fetchNextPage()
        }
      })
      if (node) observer.current.observe(node)
    },
    [isLoading, isFetching, hasNextPage],
  )

  const propertiesWithoutSelected = properties.filter((property) =>
    selectedAssignProperties.every((selectedProperty) => selectedProperty.id !== property.id),
  )

  const transformedProperties = [...selectedAssignProperties, ...propertiesWithoutSelected]

  return (
    <ul className={styles.propertiesList}>
      {transformedProperties.map((property, index) => (
        <li
          className={cls(styles.propertyListItemWrapper, {
            [styles.selected]: selectedAssignProperty?.id === property.id,
          })}
          ref={transformedProperties.length === index + 1 ? lastElementRef : null}
          key={property.id}
          onClick={() => onAssignPropertyClick(property)}
        >
          <div className={styles.checkBoxWrapper}>
            <FormCheckbox
              checked={!!selectedAssignProperties.find((ap) => ap.id === property.id)}
              onChange={(e) => onAssignPropertyChange(e.target.checked, property)}
            />
          </div>
          <SubSection className={styles.subSection} p={2}>
            <SubHeader>{property.fullAddress}</SubHeader>
            <Text fontWeight="thin">Property ID: {property.id}</Text>
            <Text fontWeight="thin">Status: {'Unknown'}</Text>
          </SubSection>
        </li>
      ))}
      {isLoading || isFetching ? <LoaderWrapper /> : null}
    </ul>
  )
}

export default WipPropertiesList
