import { getInvoicePDFActionEnum } from '../actionTypes';

export const getInvoicePDFReducer = function (
  state = { data: '', loading: false, error: '' },
  action,
) {
  switch (action.type) {
    case getInvoicePDFActionEnum.GET_INVOICE_PDF_SUCCESS:
      return { loading: false, error: '', data: action.payload };
    case getInvoicePDFActionEnum.IS_LOADING_GET_INVOICE_PDF:
      return { loading: true, error: '', data: '' };
    case getInvoicePDFActionEnum.GET_INVOICE_PDF_ERROR:
      return { loading: false, error: action.payload, data: '' };
    default:
      return state;
  }
};
