import React from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { Card, CardBody } from 'reactstrap';
import Table from '@material-ui/core/Table';
import TablePagination from '@material-ui/core/TablePagination';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';

import { Button, ButtonGroup, UncontrolledTooltip, Col } from 'reactstrap';
import ModalComponent from '../../../../../shared/components/ModalCustom';
import SettingTableHead from './SettingTableHead';

import Services from '../../../../../shared/Services.js';
import { getInvoicePDF, getInvoicesList } from '../../../../../redux/actions';
import AppButton from '../../../../../shared/components/AppButton/AppButton';
import { Checkbox } from '@mui/material';
import PreviewModal from './PreviewModal/PreviewModal';
import { EmailModal } from './EmailModal/EmailModal';
import SaveModal from './SaveModal/SaveModal';
import { downloadImage } from '../../../../../shared/request/useDownloadFile';
import CustomSelect from './components/CustomSelect';
import { statuses } from './consts';
import { emptyListStyles } from './styles';

// const userImg = `${process.env.PUBLIC_URL}/image/user.jpg`;

function getSorting(order, orderBy) {
  if (order === 'desc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
}

class CoverNoteList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      client_id: localStorage.getItem('client_Id'),
      order: 'asc',
      orderBy: '',
      selectedItems: [],
      staffData: [],
      page: 0,
      rowsPerPage: 10,
      errorModal: false,
      errorMessage: '',
      errorType: '',
      st_id: '',
      accessErrorModal: false,
      coverNoteList: [],
      isOpenReviewAndSendModal: false,
      isOpenEmailModal: false,
      isOpenSaveModal: false,
      status: statuses[0],
    };

    this.services = new Services();
    this.editInvoice = this.editInvoice.bind(this);
    this.getStaff = this.getStaff.bind(this);
  }

  componentDidMount() {
    this.getStaff(localStorage.getItem('client_Id'));
  }

  getStaff(id) {
    const data = {
      client_id: id,
    };

    this.services.getPropertyInvoiceList(data, (res) => {
      if (res.status === 200) {
        const getData = res?.data?.data;

        this.setState({
          coverNoteList: getData,
        });
      } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
        this.setState({ accessErrorModal: true });
      } else {
        this.services.errorToast(res.message);
      }
    });
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAll = (e, checked) => {
    if (checked) {
      const data = this.props.getInvoicesListS.data.data;
      const ids = data.map((item) => item.id);
      this.setState({ selectedItems: ids });
    } else {
      this.setState({ selectedItems: [] });
    }
  };

  handleSelect = (id) => {
    const items = this.state.selectedItems;
    if (items.includes(id)) {
      this.setState({ selectedItems: items.filter((item) => item !== id) });
    } else {
      this.setState({ selectedItems: [...items, id] });
    }
  };

  // handleClick = (event, id) => {
  //   const { selected } = this.state;
  //   const newSelected = new Map(selected);
  //   const value = newSelected.get(id);
  //   let isActive = true;
  //   if (value) {
  //     isActive = false;
  //   }
  //   newSelected.set(id, isActive);
  //   this.setState({ selected: newSelected });

  //   localStorage.setItem('propertyId', id);
  //   this.props.history.push('/view-properties');
  // };

  handleChangePage = (event, page) => {
    this.setState({ page });
    this.props.getInvoceList(page + 1, this.state.status);
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  // handleDeleteSelected = () => {
  //   const { data } = this.state;
  //   let copyData = [...data];
  //   const { selected } = this.state;

  //   for (let i = 0; i < [...selected].filter((el) => el[1]).length; i += 1) {
  //     copyData = copyData.filter((obj) => obj.id !== selected[i]);
  //   }

  //   this.setState({ data: copyData, selected: new Map([]) });
  // };

  editInvoice(id) {
    this.props.history.push(`/contacts/edit-invoice/${id}`);
  }

  DeleteStaff = () => {
    this.setState({
      errorMessage: '',
      errorModal: false,
      errorType: '',
    });
    const data = {
      Prop_ID: this.state.st_id,
      isdel: true,
    };
    this.services.updateSingleProperty(data, (res) => {
      if (res.status === 200) {
        this.getPropertyList();
        this.services.successToast('Property deleted successfully');
        // this.props.history.push("/property-list");
      } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
        this.setState({ accessErrorModal: true });
      } else {
        this.services.errorToast(res.message);
      }
    });
  };

  OpenModal = (e, d) => {
    this.setState({
      st_id: d._id,
      errorMessage: 'Are you sure you want to delete this record? ',
      errorModal: true,
      errorType: 'danger',
    });
  };
  handleChange0 = (selectedOption0, d) => {
    let url = 'Staff/editDebtor';

    const data = {
      st_id: d._id,
      status: [selectedOption0.value],
    };
    this.services.editStaffStatus(data, url, (res) => {
      if (res.status === 200) {
        this.services.successToast('Status update successfully');
        this.getStaff();
      }
    });
  };

  accessErrorModalClose = () => {
    this.setState({ accessErrorModal: false });
    this.props.history.push('/dashboard');
  };

  backToFrom = () => {
    this.props.history.push(`/contacts/${this.state.client_id}`, { fromTools: true });
  };

  componentDidMount() {
    this.props.getInvoceList(1, this.state.status);
  }

  handleOpenReviewAndSendModal = async () => {
    await this.props.getInvoicePDFs(this.state.selectedItems);
    this.setState({ isOpenReviewAndSendModal: true });
  };

  handleOpenModal = (key) => {
    this.setState({ [key]: true });
  };

  handleCloseModal = (key) => {
    this.setState({ [key]: false, isOpenReviewAndSendModal: false, selectedItems: [] });
  };

  handleSave = () => {
    const promises = this.state.selectedItems.map((item) => downloadImage(item));
    Promise.all(promises).then(() => {
      this.setState({ isOpenSaveModal: true, selectedItems: [] });
    });
  };

  getTamplatesIds = () => {
    const items = this.props.getInvoicesListS?.data.data.filter((item) =>
      this.state.selectedItems.includes(item.id),
    );
    const ids = [];
    items.forEach((item) => {
      item.item.forEach(({ template_id }) => {
        if (template_id && !ids.includes(template_id)) {
          ids.push(template_id);
        }
      });
    });
    return ids;
  };

  handleSelectStatus = (e) => {
    const status = e.target.value;
    this.setState({ status });
    this.props.getInvoceList(1, status);
  };

  render() {
    // staffData
    const { order, orderBy, rowsPerPage, page } = this.state;
    const { getInvoicesListS } = this.props;

    return (
      <div style={{ height: '100vh' }}>
        <div className="row">
          <Col md={12} lg={12}>
            <div>
              {this.state.errorModal ? (
                <ModalComponent
                  modal={this.state.errorModal}
                  color={this.state.errorType}
                  isCancel={true}
                  message={this.state.errorMessage}
                  onConfirm={(e) => this.DeleteStaff(e)}
                  onClose={() => this.setState({ errorModal: false })}
                />
              ) : null}
              {this.state.accessErrorModal ? (
                <ModalComponent
                  modal={this.state.accessErrorModal}
                  color="danger"
                  isCancel={false}
                  message="You do not have access to this, please contact your manager for assistance"
                  onConfirm={(e) => this.accessErrorModalClose(e)}
                  onClose={(e) => this.accessErrorModalClose(e)}
                />
              ) : null}

              <div>
                <Col md={12}>
                  <h3 className="page-title">Invoices</h3>
                </Col>
              </div>
              <Card>
                <CardBody>
                  <div className="card__title"></div>
                  <div
                    className="material-table__toolbar-wrap"
                    style={{
                      padding: '0 30px',
                      display: 'flex',
                      width: '100%',
                    }}
                  >
                    <CustomSelect
                      value={this.state.status}
                      options={statuses}
                      handleSelect={this.handleSelectStatus}
                    />
                    <Link className="btn btn-secondary" to="/contacts/add-invoice">
                      Add Invoice
                    </Link>
                    <AppButton
                      content="Review and Send"
                      buttonStyle="btn btn-secondary success"
                      disabled={!this.state.selectedItems.length}
                      onClick={this.handleOpenReviewAndSendModal}
                    />
                  </div>
                  {!getInvoicesListS.loading &&
                    (getInvoicesListS?.data?.data?.length ? (
                      <>
                        <div className="material-table__wrap">
                          <Table className="material-table">
                            <SettingTableHead
                              order={order}
                              orderBy={orderBy}
                              onRequestSort={this.handleRequestSort}
                              rowCount={getInvoicesListS?.data?.count}
                              handleSelectAll={this.handleSelectAll}
                              allChecked={
                                this.state.selectedItems?.length ===
                                getInvoicesListS?.data?.data?.length
                              }
                            />
                            <TableBody>
                              {getInvoicesListS?.data?.data
                                .sort(getSorting(order, orderBy))
                                .map((d, i) => {
                                  return (
                                    <TableRow
                                      className="material-table__row"
                                      role="checkbox"
                                      aria-checked={this.state.selectedItems.includes(d.id)}
                                      tabIndex={-1}
                                      key={i}
                                      selected={this.state.selectedItems.includes(d.id)}
                                    >
                                      <TableCell>
                                        <Checkbox
                                          checked={this.state.selectedItems.includes(d.id)}
                                          onChange={() => this.handleSelect(d.id)}
                                        />
                                      </TableCell>
                                      <TableCell className="material-table__cell material-table__cell-right">
                                        {d?.invoice_no || '-'}
                                      </TableCell>

                                      <TableCell className="material-table__cell material-table__cell-right">
                                        {d?.bill_to?.name || '-'}
                                      </TableCell>

                                      <TableCell className="material-table__cell material-table__cell-right">
                                        {d?.due_date
                                          ? dayjs(d?.due_date).format('MM.DD.YYYY')
                                          : '-'}
                                      </TableCell>

                                      <TableCell className="material-table__cell material-table__cell-right">
                                        {d?.due_date
                                          ? dayjs(d?.due_date).format('MM.DD.YYYY')
                                          : '-'}
                                      </TableCell>

                                      <TableCell className="material-table__cell material-table__cell-right">
                                        {d?.item_total || '-'}
                                      </TableCell>

                                      <TableCell className="material-table__cell material-table__cell-right">
                                        {d?.status || ''}
                                      </TableCell>

                                      <TableCell className="material-table__cell material-table__cell-right">
                                        <ButtonGroup className="btn-group--icons" dir="ltr">
                                          <Button
                                            outline
                                            id="Edit"
                                            onClick={(e) => this.editInvoice(d.id)}
                                          >
                                            <span className="lnr lnr-pencil" />
                                          </Button>
                                        </ButtonGroup>

                                        <UncontrolledTooltip placement="bottom" target="Edit">
                                          Edit
                                        </UncontrolledTooltip>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </div>
                        <TablePagination
                          component="div"
                          className="material-table__pagination"
                          count={getInvoicesListS?.data?.total}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                          nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                          onPageChange={this.handleChangePage}
                          onRowsPerPageChange={this.handleChangeRowsPerPage}
                          rowsPerPageOptions={[10, 15, 20]}
                          dir="ltr"
                          SelectProps={{
                            inputProps: { 'aria-label': 'rows per page' },
                            native: true,
                          }}
                        />
                      </>
                    ) : (
                      <div style={emptyListStyles}>Empty list</div>
                    ))}
                </CardBody>
              </Card>
            </div>
          </Col>
        </div>
        {this.state.isOpenReviewAndSendModal && (
          <PreviewModal
            open={this.state.isOpenReviewAndSendModal}
            setOpen={() => this.setState({ isOpenReviewAndSendModal: false })}
            setOpenEmailModal={() => this.handleOpenModal('isOpenEmailModal')}
            setOpenSaveModal={() => this.handleOpenModal('isOpenSaveModal')}
            place="list"
            handleSave={this.handleSave}
          />
        )}
        {this.state.isOpenEmailModal && (
          <EmailModal
            open={this.state.isOpenEmailModal}
            setOpen={() => this.handleCloseModal('isOpenEmailModal')}
            selectedItemsIds={this.state.selectedItems}
            templateIds={this.getTamplatesIds()}
            place="list"
          />
        )}
        {this.state.isOpenSaveModal && (
          <SaveModal
            open={this.state.isOpenSaveModal}
            setOpen={() => {
              this.handleCloseModal('isOpenSaveModal');
            }}
          />
        )}
        <div className=" w-100 d-flex justify-content-end">
          <button className="btn-gray" onClick={this.backToFrom}>
            Cancel
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { getInvoicesListS } = state || {};
  return { getInvoicesListS };
};
const mapActiontoProps = (dispatch) => {
  return {
    getInvoceList: (page, status) => dispatch(getInvoicesList(page, status)),
    getInvoicePDFs: (ids) => dispatch(getInvoicePDF(ids)),
  };
};

export default connect(mapStateToProps, mapActiontoProps)(CoverNoteList);
