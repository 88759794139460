import Modal from '@components/modal'
import styles from './task-modal.module.scss'
import { AutomationColumn, TaskProps, Wip } from '../types'
import { PRIORITY_OPTIONS, formatAssigneName } from '../utils'
import cls from 'classnames'
import ElipsisButton from '@components/elipsis-button'
import Select from 'react-select'
import moment from 'moment'
import { useEffect, useState } from 'react'
import LabelWrapper from '@components/label-wrapper'
import { useForm } from 'react-hook-form'
import { TASK_STATUS_OPTIONS, TASK_STATUS_OPTIONS_RECOVERY, fetchTaskById } from './utils'
import TasksService from '@services/TasksService'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import newTaskService from '@src/services/task-service'
import EditTaskModal from './edit-task-modal'
import { useTaskContext } from '@src/context/task-context'
import AssignAvatar from '@components/assign-avatar'
import TaskHeader from '../task-header'
import { toast } from 'react-toastify'
import { joiResolver } from '@hookform/resolvers/joi'
import { TaskSchema } from '@containers/Setting/components/Contacts/taskboard/modals/validations'
import CombinedNotes from '../CombinedNote/combined-notes.component'

import TaskInfo from '../Task/components/task-info/task-info.component'
import { useUserContext } from '@src/context/user-context'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import SubSection from '@containers/Recovery/Wip/components/UI/sub-section/sub-section.component'
import { Text } from '@containers/Recovery/Wip/components/UI/text/text.component'
import WipTaskSpeedDial from '@containers/Communication/components/speed-dial/wip-task-speed-dial.component'
import WipTaskInfo from '../Task/components/task-info/wip-task-info.component'
import SubTaskList from '../SubTask/sub-task-list.component'
import { getWipQueryKey } from '@containers/Recovery/Wip/utils/wip-query-factory'

interface IProps {
  isOpen: boolean
  onClose: () => void
  id: number
}

interface IForm {
  status: number | undefined
  description: string
  note: string
}

const taskService = new TasksService()
const TaskModal = ({ isOpen, onClose = () => null, id }: IProps) => {
  const { user } = useUserContext()
  const [task, setTask] = useState<TaskProps | null>(null)
  const [column, setColumn] = useState<AutomationColumn | null>(null)

  const { isLoading, isFetched } = useQuery(
    [GLOBAL_QUERIES.TASKSERVICE.GET_BY_ID, id],
    () => {
      const params = {
        join: ['assigned_staff', 'companies', 'debtors', 'wip'],
      }
      return fetchTaskById(id, params)
    },
    {
      onSuccess: (data) => {
        setColumn(data.column)
        setTask(data.task)
      },
      onError: () => {
        toast.error('Something went wrong')
      },
      enabled: isOpen && !!id,
      retry: 0,
    },
  )

  const queryClient = useQueryClient()
  const [isEditModalOpened, setIsEditModalOpened] = useState(false)

  const { staff, isSales, currentFoundWip, currentWip } = useTaskContext()

  const { control, reset, handleSubmit } = useForm<IForm>({
    defaultValues: {
      status: 0,
      description: '',
      note: '',
    },
    resolver: joiResolver(TaskSchema),
  })

  const assignedBy = staff?.find(({ id }) => id === task?.staff_id_created)

  const assignerName = formatAssigneName({
    last_name: assignedBy?.last_name,
    first_name: assignedBy?.first_name,
    isAssigned: false,
  })

  const handleEditModalOpen = () => {
    setIsEditModalOpened(true)
  }

  const handleEditModalClose = () => {
    setIsEditModalOpened(false)
  }

  const dueDate = moment(task?.date_end).format('dddd DD MMMM') || 'No due date'
  const taskType = column?.title

  const onSubmit = async (data: IForm) => {
    if (!task) return

    const { sub_tasks_count, uncompleted_sub_tasks_count, ...rest } = task

    await newTaskService
      .updateTask({
        ...rest,
        staff_id_modified: user?.id,
        column_belong_id: data?.status || 1,
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        queryClient.invalidateQueries([GLOBAL_QUERIES.TASKSERVICE.GET])
        onClose()
      })
  }

  const handleDeleteTask = async (id?: TaskProps['id']) => {
    if (!id) return
    await taskService.deleteTask(id)
    queryClient.invalidateQueries({ queryKey: [GLOBAL_QUERIES.TASKSERVICE.GET] })

    onClose()
  }

  const handleModalClose = async () => {
    await handleSubmit(onSubmit)()
    onClose()
  }

  useEffect(() => {
    if (!isOpen) {
      reset({}, { keepDirty: false, keepDefaultValues: true, keepValues: false })
      setTask(null)
    }
  }, [isOpen])

  useEffect(() => {
    if (!task) return
    if (isOpen) {
      reset(
        { status: task.column_belong_id },
        { keepDirty: false, keepDefaultValues: true, keepValues: false },
      )
    } else {
      queryClient.invalidateQueries({ queryKey: [GLOBAL_QUERIES.TASKSERVICE.GET] })
    }
  }, [task, isOpen])

  if (isLoading || !task) return null
  return (
    <Modal
      onSubmit={handleSubmit(onSubmit)}
      isOpen={isOpen}
      onClose={handleModalClose}
      withBtns={false}
      className={cls(styles.modalWrapper, {
        [styles.smallWidth]: isEditModalOpened,
      })}
    >
      {isLoading && isFetched ? (
        <div>Loading...</div>
      ) : (
        <div className={styles.containerWrapper}>
          <div className={styles.wrapper}>
            <div className={styles.header}>
              <div
                className={cls(styles.text, styles.low, {
                  [styles.low]: task?.priority === 'Normal',
                  [styles.medium]: task?.priority === 'Medium',
                  [styles.high]: task?.priority === 'High',
                })}
              >
                <span>
                  {PRIORITY_OPTIONS[task?.priority || 'Normal'] || PRIORITY_OPTIONS.Normal}
                </span>
              </div>
              <span>
                <b>Task Type</b>: {taskType || 'No task type'}
              </span>
              <div className={styles.headerAction}>
                <span className={styles.elipsisContainer}>
                  <TaskHeader assign_to={task?.assign_to || []} />
                  <ElipsisButton
                    options={[
                      { value: 'Edit Task', callback: handleEditModalOpen },
                      { value: 'Delete', callback: () => handleDeleteTask(task?.id || 0) },
                    ]}
                  />
                </span>
              </div>
            </div>
            <span className={styles.title}>{task?.title || 'Name not found'}</span>
            <span className={styles.dueDate}>
              <span>
                <b>Due Date</b>: {dueDate || 'No due date'}
              </span>
              <span>
                Assigned By: {assignerName}
                <AssignAvatar
                  accessLevel={assignedBy?.access_level}
                  avatarUrl={assignedBy?.profile_picture}
                  name={assignerName}
                />
              </span>
            </span>

            {isSales ? (
              <TaskInfo task={task} />
            ) : (
              <WipTaskInfo wip={currentFoundWip} client={currentFoundWip?.company} task={task} />
            )}

            <div className={styles.actionWrapper}>
              <SubTaskList task={task} />
              {isSales ? null : (
                <WipTaskSpeedDial
                  wip={queryClient.getQueryData<Wip>(getWipQueryKey(currentWip)) || currentFoundWip}
                  task={task}
                />
              )}
            </div>

            <SubSection className={styles.description}>
              <Text color="black">{task?.description || 'No description found'}</Text>
            </SubSection>

            {isSales ? (
              <>
                <div>
                  <LabelWrapper<IForm> control={control} label="Current Status" name="status">
                    {({ onChange, value }) => {
                      return (
                        <Select
                          type="text"
                          options={isSales ? TASK_STATUS_OPTIONS : TASK_STATUS_OPTIONS_RECOVERY}
                          className="react-select"
                          value={TASK_STATUS_OPTIONS.find((option) => option.value === value)}
                          onChange={({ value }) => onChange(value)}
                        />
                      )
                    }}
                  </LabelWrapper>
                </div>
              </>
            ) : null}
            <div className={styles.buttonWrapper}>
              <SubmitButton buttonStyle="secondary" onClick={onClose}>
                Cancel
              </SubmitButton>
              <SubmitButton onClick={handleSubmit(onSubmit)}>Save</SubmitButton>
            </div>
          </div>
          <CombinedNotes task={task} onClose={onClose} onSubmit={handleSubmit(onSubmit)} />
        </div>
      )}

      <EditTaskModal
        isOpen={isEditModalOpened}
        onClose={handleEditModalClose}
        task={task || null}
      />
    </Modal>
  )
}

export default TaskModal
