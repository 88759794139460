import { FC, useState } from 'react'
import styles from './speed-dial.module.scss'
import { SpeedDialOption, SpeedDialProps } from '@containers/Communication/types/speed-dial.type'
import cls from 'classnames'
import AddIcon from '@src/icons/add'

const SpeedDial: FC<SpeedDialProps> = ({ options, onSelect, verticalDirection = 'bottom' }) => {
  const [isButtonHovered, setIsButtonHovered] = useState(false)
  const [isListHovered, setIsListHovered] = useState(false)

  const handleMouseEnter = (cb: (v: boolean) => void) => {
    return () => cb(true)
  }

  const handleMouseLeave = (cb: (v: boolean) => void) => {
    return () => cb(false)
  }

  const handleSelect = (option: SpeedDialOption) => {
    onSelect && onSelect(option)
    setIsButtonHovered(false)
    setIsListHovered(false)
  }

  const isOpen = isButtonHovered || isListHovered

  return (
    <div
      className={styles.speedDialWrapper}
      onMouseEnter={handleMouseEnter(setIsButtonHovered)}
      onMouseLeave={handleMouseLeave(setIsButtonHovered)}
    >
      <div className={cls(styles.speedDialOpenButton, { [styles.active]: isOpen })}>
        <AddIcon />
      </div>
      {isOpen ? (
        <div
          className={cls(styles.speedDialSelectList, styles[verticalDirection])}
          onMouseEnter={handleMouseEnter(setIsListHovered)}
          onMouseLeave={handleMouseLeave(setIsListHovered)}
        >
          {options.map((option) => (
            <div
              key={option.value}
              className={styles.speedDialSelect}
              onClick={() => handleSelect(option)}
            >
              <option.icon className={styles.speedDialSelectIcon} />
              <div className={styles.speedDialSelectLabelWrapper}>
                <span className={styles.speedDialSelectLabel}>{option.label}</span>
                <div className={styles.triangleWrapper}>
                  <div className={styles.triangle} />
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  )
}

export default SpeedDial
