import Section from '@components/section/section.component'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import styles from './property-list-page.module.scss'
import Table from '@components/table/table.component'
import { useGetProperties } from '../../hooks/crud-property.hook'
import { useEffect, useMemo, useState } from 'react'
import TableRow from '@components/table/table-row.component'
import TableCell from '@components/table/table-cell-component'
import { isDate, format } from 'date-fns'
import { useMainStyleContext } from '@containers/App/contexts/main-style.context'
import Dropdown from '@components/dropdown/dropdown.component'
import CloackIcon from '@src/icons/cloack'
import { SelectOption } from '@src/types/component-types'
import { transformDropdownDateOptionToDate } from '@components/table/table.util'
import FormInput from '@components/inputs/form-input/form-input.component'
import SearchIcon from '@src/icons/search'
import { useHistory } from 'react-router'

const options = [
  {
    label: 'Added Last 30 days',
    value: 'month',
  },
  {
    label: 'Added Last year',
    value: 'year',
  },
  {
    label: 'All',
    value: 'all',
  },
]

const PropertyListPage = () => {
  const [page, setPage] = useState(1)
  const history = useHistory()
  const [selectedFilter, setSelectedFilter] = useState<SelectOption>(options[2])
  const { setStyle } = useMainStyleContext()

  const params = useMemo(
    () => ({
      page,
      s:
        selectedFilter && transformDropdownDateOptionToDate(selectedFilter)
          ? JSON.stringify({
              time_created: transformDropdownDateOptionToDate(selectedFilter)
                ? { $gte: transformDropdownDateOptionToDate(selectedFilter) }
                : undefined,
            })
          : undefined,
      limit: 10,
      sort: 'createdAt,DESC',
    }),
    [selectedFilter, page],
  )

  const { data, isLoading, isRefetching } = useGetProperties(params)

  useEffect(() => {
    setStyle({ backgroundColor: '#f9fafb' })
    return () => {
      setStyle({ backgroundColor: 'white' })
    }
  }, [])

  const handleSelectedDateChange = (selectedDate: SelectOption) => {
    setSelectedFilter(selectedDate)
    setPage(1)
  }

  const handlePropertyClick = (propertyId: number) => {
    localStorage.setItem('propertyId', String(propertyId))
    localStorage.setItem('propertyIsView', 'true')
    history.push('/view-properties', {
      fromDetails: propertyId,
    })
  }

  const properties = data?.data || []

  console.log('properties', properties)

  const isPropertiesLoading = isLoading || isRefetching
  const isPropertiesEmpty = properties.length === 0 && !isPropertiesLoading

  return (
    <Section className={styles.sectionWrapper}>
      <div className={styles.filterWrapper}>
        <Header>Properties</Header>
        <div className={styles.row}>
          <Dropdown
            className={styles.dropdown}
            selected={selectedFilter}
            onSelectedChange={handleSelectedDateChange}
            icon={CloackIcon}
            options={options}
          />
          <div className={styles.inputWrapper}>
            <FormInput size="small" placeholder="Search" icon={SearchIcon} />
          </div>
        </div>
      </div>
      <Table
        limit={10}
        page={page}
        onPageChange={setPage}
        headers={[
          'PID #',
          'CLIENT',
          'LANDLORD',
          'PROPERTY ADDRESS',
          'STATE',
          'POSTCODE',
          'COVER',
          'COVER EXPIRES',
          'PROPERTIES DUE',
        ]}
        totalCount={data?.total || 0}
        loading={isPropertiesLoading}
        notFound={isPropertiesEmpty}
        bodyHeight={54 * 10}
      >
        {properties.map((property) => (
          <TableRow key={property.id} onClick={() => handlePropertyClick(property.id)} pointer>
            {/* PID # */}
            <TableCell position="eclipsed">{property.id}</TableCell>
            {/* CLIENT # */}
            <TableCell position="eclipsed" className={styles.clientCell}>
              {property.companies?.map((company) => company.name).join(', ') || 'Not Found'}
            </TableCell>
            {/* LANDLORD # */}
            <TableCell position="eclipsed" className={styles.landlordCell}>
              {property.landlord?.companyName || 'Not Found'}
            </TableCell>
            {/* PROPERTY ADDRESS */}
            <TableCell position="eclipsed">{property.fullAddress || 'Not Found'}</TableCell>
            {/* STATE */}
            <TableCell position="eclipsed">{property.state || 'Not Found'}</TableCell>
            {/* POSTCODE */}
            <TableCell position="eclipsed">{property.postcode || 'Not Found'}</TableCell>
            {/* COVER */}
            <TableCell position="eclipsed">{property.invoiceType || 'Not Found'}</TableCell>
            {/* COVER EXPIRES */}
            <TableCell position="eclipsed">
              {isDate(property.coveredTo)
                ? format(property.coveredTo, 'MM/dd/yyyy')
                : 'Invalid Date'}
            </TableCell>
            {/* PROPERTIES DUE */}
            <TableCell position="eclipsed">Invalid Date</TableCell>
          </TableRow>
        ))}
      </Table>
    </Section>
  )
}

export default PropertyListPage
