import React from 'react';
import { Button } from 'reactstrap';
import { reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import Services from '../../../../../shared/Services.js';
import { withRouter } from 'react-router';
import SimpleReactValidator from 'simple-react-validator';
import ModalComponent from '../../../../../shared/components/ModalCustom';
import moment from 'moment';
import PropertyService from '../../../../../services/PropertyService';
import { TailSpin } from 'react-loader-spinner';

const renderField = ({
                       input,
                       placeholder,
                       value,
                       type,
                       meta: { touched, error },
                     }) => (
  <div className='form__form-group-input-wrap'>
    <input {...input} placeholder={placeholder} type={type} value={value} />
    {touched && error && (
      <span className='form__form-group-error'>{error}</span>
    )}
  </div>
);

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
};

class ViewProperty extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isView: localStorage.getItem('propertyIsView') ,
      isAdd: localStorage.getItem('propertyAdd') === 'yes',
      client_id: '',
      propertyData: '',
      client_code: '',
      id: '',
      agent_nm: '',
      agent_phone: '',
      agent_address_1: '',
      agent_address_2: '',
      agent_address_3: '',
      agent_suburb: '',
      agent_state: '',
      agent_post_code: '',
      agent_country: '',
      cover_to: new Date(),
      cover_from: new Date(),
      prop_address_1: '',
      prop_address_2: '',
      prop_address_3: '',
      prop_suburb: '',
      prop_state: '',
      prop_post_code: '',
      prop_country: '',
      landlord_title: '',
      landlord_first: '',
      landlord_last: '',
      owner_email: '',
      owner_phone: '',
      landlord_address_1: '',
      landlord_address_2: '',
      landlord_address_3: '',
      landlord_suburb: '',
      landlord_state: '',
      landlord_post_code: '',
      landlord_country: '',
      landlord_invoice: 0,
      invoice_type: 0,
      price: 0,
      credit: 500,
      tax: 0,
      total: 0,
      status: 0,
      service: 0,
      tenancy_plus_3_months: 0,
      tenancy_completed_all: 0,
      bond_4_weeks: 0,
      rent_arrears: 0,
      aware_of_details: 0,
      crby: 'Kevin',
      awareerrorModal: false,
      errorMessage: '',
      logoImageHeader: '',
      loader: false,
    };
    this.prop = new PropertyService();
    this.services = new Services();
    this.saveProperty = this.saveProperty.bind(this);
    this.changeInput = this.changeInput.bind(this);
    this.changeAddress = this.changeAddress.bind(this);
    this.validator = new SimpleReactValidator();
  }


  componentDidMount() {
    this.toDataURL('./image/logo.png').then((dataUrl) => {
      this.setState({ logoImageHeader: dataUrl });
    });
    this.getStaff(localStorage.getItem('contactsId'));
  }

  getStaff() {
      this.editProperty(localStorage.getItem('propertyId'));
  }

  accessErrorModalClose = () => {
    this.setState({ accessErrorModal: false });
    this.props.history.push('/dashboard');
  };

  editProperty = (propertyId) => {
    this.setState({ loader: true });
    const params = {
      id: propertyId,
      join: ['company'],
    };

    this.prop.getPropertyId(params).then((res) => {

      if (res.status === 200) {
        const propertyData = res.data;
        const cover_to = propertyData?.cover_details?.to && propertyData.cover_details.to !== 'Invalid date' ? new Date(propertyData.cover_details.to) : new Date();
        const cover_from = propertyData?.cover_details?.from && propertyData.cover_details.from !== 'Invalid date' ? new Date(propertyData.cover_details.from) : new Date();

        this.setState({
          propertyData: propertyData,
          agent_address_1: propertyData.agent_details?.address_1,
          agent_address_2: propertyData.agent_details?.address_2,
          agent_address_3: propertyData.agent_details?.address_3,
          agent_country: propertyData.agent_details?.country,
          agent_nm: propertyData.agent_details?.name,
          agent_phone: propertyData.agent_details?.phone,
          agent_post_code: propertyData.agent_details?.post_code,
          agent_state: propertyData.agent_details?.state,
          agent_suburb: propertyData.agent_details?.suburb,
          bond_4_weeks: propertyData.bond_4_weeks,
          client_code: propertyData.client_code ? propertyData.client_code : this.state.client_code,
          client_id: propertyData?.company?.id,
          cover_to: cover_to,
          cover_from: cover_from,
          crDate: new Date(propertyData.time_created),
          crby: propertyData.crby,
          credit: propertyData.credit,
          invoice_type: propertyData?.cover_details?.type,
          isdel: propertyData.isdel,
          landlord_address_1: propertyData.landlord_details?.address_street_name,
          landlord_address_2: propertyData.landlord_details?.address_street_secondary,
          landlord_address_3: propertyData.landlord_details?.address_city,
          landlord_country: propertyData.landlord_details?.address_country,
          landlord_first: propertyData.landlord_details?.name_first,
          landlord_invoice: propertyData.landlord_details?.invoice,
          landlord_last: propertyData.landlord_details?.name_last,
          landlord_post_code: propertyData.landlord_details?.address_postcode,
          landlord_state: propertyData.landlord_details?.address_state,
          landlord_suburb: propertyData.landlord_details?.address_suburb,
          landlord_title: propertyData.landlord_details?.title,
          owner_email: propertyData.landlord_details?.comms_email,
          owner_phone: propertyData.landlord_details?.comms_ph_mobile,
          price: propertyData?.cover_details?.price,
          prop_address_1: propertyData.address_details?.street_name,
          prop_address_2: propertyData.address_details?.street_secondary,
          prop_address_3: propertyData.address_details?.city,
          prop_country: propertyData.address_details?.country,
          prop_post_code: propertyData.address_details?.postcode,
          prop_state: propertyData.address_details?.state,
          prop_suburb: propertyData.address_details?.suburb,
          rent_arrears: propertyData.rent_arrears,
          aware_of_details: propertyData.aware_of_details ? propertyData.aware_of_details : 0,
          status: propertyData.status,
          service: propertyData.service,
          tax: propertyData?.cover_details?.tax,
          tenancy_completed_all: propertyData.tenancy_completed_all,
          tenancy_plus_3_months: propertyData.tenancy_plus_3_months,
          total: propertyData?.cover_details?.total,
          id: propertyData.id,
        });
        this.setState({ loader: false });
      } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
        this.setState({ accessErrorModal: true });
        this.setState({ loader: false });
      }
    });
  };

  saveProperty = () => {
    const property = this.state;

    if (property.aware_of_details === 1 || property.rent_arrears === 1) {
      this.setState({
        errorMessage:
          'If there the tenant is currently defaulted, please contact the management prior to adding into system',
        awareerrorModal: true,
        errorType: 'danger',
      });
    } else {
      const data = {
        client_id: property.client_id,
        client_code: property.client_code,
        agent_nm: property.agent_nm,
        agent_phone: property.agent_phone,
        agent_address_1: property.agent_address_1,
        agent_address_2: property.agent_address_2,
        agent_address_3: property.agent_address_3,
        agent_suburb: property.agent_suburb,
        agent_state: property.agent_state,
        agent_post_code: property.agent_post_code,
        agent_country: property.agent_country,
        cover_to: moment(this.state.cover_to).format('YYYY-MM-DD'),
        cover_from: moment(this.state.cover_from).format('YYYY-MM-DD'),
        prop_address_1: property.prop_address_1,
        prop_address_2: property.prop_address_2,
        prop_address_3: property.prop_address_3,
        prop_suburb: property.prop_suburb,
        prop_state: property.prop_state,
        prop_post_code: property.prop_post_code,
        prop_country: property.prop_country,
        landlord_title: property.landlord_title,
        landlord_first: property.landlord_first,
        landlord_last: property.landlord_last,
        owner_email: property.owner_email,
        owner_phone: property.owner_phone,
        landlord_address_1: property.landlord_address_1,
        landlord_address_2: property.landlord_address_2,
        landlord_address_3: property.landlord_address_3,
        landlord_suburb: property.landlord_suburb,
        landlord_state: property.landlord_state,
        landlord_post_code: property.landlord_post_code,
        landlord_country: property.landlord_country,
        landlord_invoice: property.landlord_invoice,
        invoice_type:
          property.invoice_type && property.invoice_type.value
            ? property.invoice_type.value
            : property.invoice_type,
        price: property.price,
        // credit : property.credit,
        tax: property.tax,
        total: parseInt(property.price) + parseInt(property.tax),
        status:
          property.status && property.status.value
            ? property.status.value
            : property.status,
        service:
          property.service && property.service.value
            ? property.service.value
            : property.service,
        tenancy_plus_3_months: property.tenancy_plus_3_months,
        tenancy_completed_all: property.tenancy_completed_all,
        bond_4_weeks: property.bond_4_weeks,
        rent_arrears: property.rent_arrears,
        aware_of_details: property.aware_of_details,
        // crby : property.crby
      };

      if (this.validator.allValid()) {
        if (!this.state.isView && this.state.isAdd) {
          data['credit'] = property.credit;
          data['crby'] = property.crby;

          this.services.addProperty(data, (res) => {
            if (res.status === 200) {
              this.services.successToast('Property Added successfully');
              this.props.history.push('/contacts');
            } else if (
              res.statusCode === 401 &&
              res.responseType === 'UNAUTHORIZED'
            ) {
              this.setState({ accessErrorModal: true });
            } else {
              this.services.errorToast(res.message);
            }
          });
        } else {
          data['Prop_ID'] = localStorage.getItem('propertyId');
          this.services.updateSingleProperty(data, (res) => {
            if (res.status === 200) {
              this.services.successToast('Property update successfully');
              this.props.history.push('/contacts');
            } else if (
              res.statusCode === 401 &&
              res.responseType === 'UNAUTHORIZED'
            ) {
              this.setState({ accessErrorModal: true });
            } else {
              this.services.errorToast(res.message);
            }
          });
        }
      } else {
        this.validator.showMessages();
        this.forceUpdate();
      }
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  addaware_of_details = () => {
    this.setState({
      awareerrorModal: false,
    });
  };

  changeInput(e) {
    if (
      e.target.name === 'tenancy_plus_3_months' ||
      e.target.name === 'tenancy_completed_all' ||
      e.target.name === 'bond_4_weeks' ||
      e.target.name === 'rent_arrears' ||
      e.target.name === 'aware_of_details'
    ) {
      this.setState({
        [e.target.name]: JSON.parse(e.target.value),
      });
    } else if (e.target.name === 'landlord_invoice') {
      this.setState({
        [e.target.name]: this.state.landlord_invoice === 0 ? 1 : 0,
      });
    } else if (e.target.name === 'price' || e.target.name === 'tax') {
      this.setState({
        [e.target.name]: e.target.value ? e.target.value : 0,
      });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  uncheckAll = (id) => {
    this.state.primary_address.forEach((addr) => {
      if (addr.id !== id) {
        addr.postal = false;
      }
    });
  };

  uncheckAllPrimary = (id) => {
    this.state.primary_address.forEach((addr) => {
      if (addr.id !== id) {
        addr.primary = false;
      }
    });
  };

  uncheckAllInvoice = (id) => {
    this.state.Contact_details.forEach((addr) => {
      if (addr.id !== id) {
        addr.invoice = false;
      }
    });
  };

  uncheckAllPrimaryContact = (id) => {
    this.state.Contact_details.forEach((addr) => {
      if (addr.id !== id) {
        addr.primary = false;
      }
    });
  };

  changeAddress(e, id) {
    if (id) {
      const self = this;
      const data = this.state.primary_address.map(function(item) {
        if (item.id === id && e.target.name) {
          if (e.target.name === 'postal' || e.target.name === 'primary') {
            if (e.target.name === 'postal') {
              item.postal = e.target.checked;
              self.uncheckAll(id);
            }
            if (e.target.name === 'primary') {
              item.primary = e.target.checked;
              self.uncheckAllPrimary(id);
            }
          } else {
            item[e.target.name] = e.target.value;
          }
        }
        return item;
      });
      if (e && e.target && e.target.name && id)
        this.setState({ primary_address: data });
    }
  }

  changeContact(e, id) {
    if (id) {
      const self = this;
      const data = this.state.Contact_details.map(function(item) {
        if (item.id === id && e.label && e.value) {
          item.title = e.value;
        } else {
          if (item.id === id && e.target.name) {
            if (e.target.name === 'primary' || e.target.name === 'invoice') {
              if (e.target.name === 'invoice') {
                item.invoice = e.target.checked;
                self.uncheckAllInvoice(id);
              }
              if (e.target.name === 'primary') {
                item.primary = e.target.checked;
                self.uncheckAllPrimaryContact(id);
              }
            } else {
              item[e.target.name] = e.target.value;
            }
          }
        }
        return item;
      });
      if ((e && e.target && e.target.name && id) || (e.label && e.value))
        this.setState({ Contact_details: data });
    }
  }

  handleChange = (selectedOption, name) => {
    this.setState({ [name]: selectedOption });
  };

  handleChangeToDate = (date) => {
    this.setState({
      cover_to: new Date(date),
    });
  };

  handleChangeFromDate = (date) => {
    this.setState({
      cover_from: new Date(date),
    });
  };

  handleSelectGeoSuggest = (place, id) => {
    if (!place) return null;

    const componentForm = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      administrative_area_level_1: 'long_name',
      country: 'long_name',
      postal_code: 'short_name',
    };
    for (var i = 0; i < place.gmaps.address_components.length; i++) {
      var addressType = place.gmaps.address_components[i].types[0];
      if (componentForm[addressType]) {
        var val = place.gmaps.address_components[i][componentForm[addressType]];
        if (val) componentForm[addressType] = val;
      }
    }

    if (id === 'Agent') {
      this.setState({
        agent_address_1: place.label,
        agent_address_2:
          componentForm.street_number !== 'short_name'
            ? componentForm.street_number
            : '',
        agent_address_3:
          componentForm.route !== 'long_name' ? componentForm.route : '',
        agent_suburb:
          componentForm.locality !== 'long_name' ? componentForm.locality : '',
        agent_state:
          componentForm.administrative_area_level_1 !== 'long_name'
            ? componentForm.administrative_area_level_1
            : '',
        agent_country:
          componentForm.country !== 'long_name' ? componentForm.country : '',
        agent_post_code:
          componentForm.postal_code !== 'short_name'
            ? componentForm.postal_code
            : '',
      });
    }
    if (id === 'Props') {
      this.setState({
        prop_address_1: place.label,
        prop_address_2:
          componentForm.street_number !== 'short_name'
            ? componentForm.street_number
            : '',
        prop_address_3:
          componentForm.route !== 'long_name' ? componentForm.route : '',
        prop_suburb:
          componentForm.locality !== 'long_name' ? componentForm.locality : '',
        prop_state:
          componentForm.administrative_area_level_1 !== 'long_name'
            ? componentForm.administrative_area_level_1
            : '',
        prop_country:
          componentForm.country !== 'long_name' ? componentForm.country : '',
        prop_post_code:
          componentForm.postal_code !== 'short_name'
            ? componentForm.postal_code
            : '',
      });
    }
    if (id === 'Landlord') {
      this.setState({
        landlord_address_1: place.label,
        landlord_address_2:
          componentForm.street_number !== 'short_name'
            ? componentForm.street_number
            : '',
        landlord_address_3:
          componentForm.route !== 'long_name' ? componentForm.route : '',
        landlord_suburb:
          componentForm.locality !== 'long_name' ? componentForm.locality : '',
        landlord_state:
          componentForm.administrative_area_level_1 !== 'long_name'
            ? componentForm.administrative_area_level_1
            : '',
        landlord_country:
          componentForm.country !== 'long_name' ? componentForm.country : '',
        landlord_post_code:
          componentForm.postal_code !== 'short_name'
            ? componentForm.postal_code
            : '',
      });
    }
  };

  printProp = () => {
    const content = document.getElementById('contacts_accordion_pdf');
    const pri = document.getElementById('ifmcontentstoprint').contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  };

  toDataURL = (url) =>
    fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          }),
      );

  backToFrom = () => {
    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.wipID
    ) {
      this.props.history.push('/wip-details', { wipID: this.props.location.state.wipID });
    } else {
      this.props.history.push(`/contacts/${this.state.propertyData.company.id}`, { fromViewPro: 'property' });
    }
  };

  render() {
    return (
      <>
        {this.state.awareerrorModal ? (
          <ModalComponent
            modal={this.state.awareerrorModal}
            color={this.state.errorType}
            isCancel={true}
            message={this.state.errorMessage}
            onConfirm={(e) => this.addaware_of_details(e)}
            onClose={() => this.setState({ awareerrorModal: false })}
          />
        ) : null}
        {this.state.loader ? (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '350px' }}>
            <TailSpin
              height='75'
              width='75'
              color='#f69066'
              ariaLabel='loading'
            />
          </div>
        ) : <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className='w-100'>
                <div id='contacts_accordion' className='add_new_propery'>
                  <div className='card mb-4'>
                    <div className='d-flex justify-content-between align-items-center mb-3'>
                      <div className='col-6'>
                        <h3 className='page-title '>View Property</h3>
                      </div>
                      <div className='col-6 align-right '>
                        <button
                          className='btn-gray mr-2'
                          onClick={this.backToFrom}
                        >
                          Back
                        </button>
                        <button
                          className='btn btn-secondary mb-0 popup-btn-primary'
                          onClick={this.printProp}
                        >
                          Print
                        </button>
                      </div>
                    </div>
                    <div
                      id='collapseOne'
                      className='collapse show mb-4'
                      data-parent='#contacts_accordion'
                    >
                      <div className='card-body'>
                        <div className='row'>
                          <div className='col-12 col-md-3'>
                            <div className='form-group'>
                              <label
                                style={{ display: 'block', fontSize: '16px' }}
                                className='form__form-group-label'
                              >
                                First Name
                              </label>
                              <h4>
                                {this.state.agent_nm
                                  ? this.state.agent_nm
                                  : '-'}
                              </h4>
                            </div>
                          </div>

                          <div className='col-12 col-md-3'>
                            <div className='form-group'>
                              <label className='form__form-group-label'>
                                Phone
                              </label>
                              <h4>
                                {this.state.agent_phone
                                  ? this.state.agent_phone
                                  : '-'}
                              </h4>
                            </div>
                          </div>

                          <div className='col-12 col-md-3'>
                            <div className='form-group'>
                              <label className='form__form-group-label'>
                                Service
                              </label>
                              <h4>
                                {this.state.service ? this.state.service : '-'}
                              </h4>
                            </div>
                          </div>
                        </div>

                        <div className='row'>
                          <div className='col-12 col-md-9'>
                            <div className='form-group'>
                              <label className='form__form-group-label'>
                                Address
                              </label>
                              <h4>
                                {this.state.agent_address_1
                                  ? this.state.agent_address_1
                                  : '-'}
                              </h4>
                            </div>
                          </div>
                          <div className='col-12 col-md-9'>
                            <div className='form-group'>
                              <label className='form__form-group-label'>
                                Address Line 2
                                <span style={{ color: '#ff0000' }} />
                              </label>
                              <h4>
                                {this.state.agent_address_2
                                  ? this.state.agent_address_2
                                  : '-'}
                              </h4>
                            </div>
                          </div>

                          <div className='col-12 col-md-9'>
                            <div className='form-group'>
                              <label className='form__form-group-label'>
                                Address Line 3
                                <span style={{ color: '#ff0000' }} />
                              </label>
                              <h4>
                                {this.state.agent_address_3
                                  ? this.state.agent_address_3
                                  : '-'}
                              </h4>
                            </div>
                          </div>
                        </div>

                        <div className='row'>
                          <div className='col-12 col-md-3'>
                            <div className='form-group'>
                              <label className='form__form-group-label'>
                                Suburb
                              </label>
                              <h4>
                                {this.state.agent_suburb
                                  ? this.state.agent_suburb
                                  : '-'}
                              </h4>
                            </div>
                          </div>
                          <div className='col-12 col-md-3'>
                            <div className='form-group'>
                              <label className='form__form-group-label'>
                                State
                              </label>
                              <h4>
                                {this.state.agent_state
                                  ? this.state.agent_state
                                  : '-'}
                              </h4>
                            </div>
                          </div>
                          <div className='col-12 col-md-3'>
                            <div className='form-group'>
                              <label className='form__form-group-label'>
                                Post Code
                              </label>
                              <h4>
                                {this.state.agent_post_code
                                  ? this.state.agent_post_code
                                  : '-'}
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-12 col-md-3'>
                            <div className='form-group'>
                              <label className='form__form-group-label'>
                                Country
                              </label>
                              <h4>
                                {this.state.agent_country
                                  ? this.state.agent_country
                                  : '-'}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      id='collapseOne'
                      className='collapse show mb-4'
                      data-parent='#contacts_accordion'
                    >
                      <div className='card-body'>
                        <div className='mb-4 pl-0'>
                          <h4 style={{ color: 'black' }} className='card-link' data-toggle='collapse'>
                            Property Details
                          </h4>
                        </div>
                        <div className='row'>
                          <div className='col-12 col-md-4'>
                            <div className='form-group'>
                              <label className='form__form-group-label'>
                                Period of Cover From (As of 4PM)
                              </label>
                              <h4>
                                {this.state.cover_from &&
                                this.state.cover_from !== 'Invalid date'
                                  ? moment(
                                    new Date(this.state.cover_from),
                                  ).format('YYYY-MM-DD')
                                  : moment(new Date()).format('YYYY-MM-DD')}
                              </h4>
                            </div>
                          </div>
                          <div className='col-12 col-md-4'>
                            <div className='form-group'>
                              <label className='form__form-group-label'>
                                Period of Cover To (As of 4PM)
                              </label>
                              <h4>
                                {this.state.cover_to &&
                                this.state.cover_to !== 'Invalid date'
                                  ? moment(
                                    new Date(this.state.cover_to),
                                  ).format('YYYY-MM-DD')
                                  : moment(new Date()).format('YYYY-MM-DD')}
                              </h4>
                            </div>
                          </div>
                        </div>

                        <div className='mb-4 pl-0'>
                          <span className='card-link' data-toggle='collapse'>
                            Address of Property to be Covered
                          </span>
                        </div>

                        <div className='row'>
                          <div className='col-12 col-md-9'>
                            <div className='form-group'>
                              <label className='form__form-group-label'>
                                Address
                              </label>
                              <h4>
                                {this.state.prop_address_1
                                  ? this.state.prop_address_1
                                  : '-'}
                              </h4>
                            </div>
                          </div>
                          <div className='col-12 col-md-9'>
                            <div className='form-group'>
                              <label className='form__form-group-label'>
                                Address Line 2
                                <span style={{ color: '#ff0000' }} />
                              </label>
                              <h4>
                                {this.state.prop_address_2
                                  ? this.state.prop_address_2
                                  : '-'}
                              </h4>
                            </div>
                          </div>

                          <div className='col-12 col-md-9'>
                            <div className='form-group'>
                              <label className='form__form-group-label'>
                                Address Line 3
                                <span style={{ color: '#ff0000' }} />
                              </label>
                              <h4>
                                {this.state.prop_address_3
                                  ? this.state.prop_address_3
                                  : '-'}
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-12 col-md-3'>
                            <div className='form-group'>
                              <label className='form__form-group-label'>
                                Suburb
                              </label>
                              <h4>
                                {this.state.prop_suburb
                                  ? this.state.prop_suburb
                                  : '-'}
                              </h4>
                            </div>
                          </div>
                          <div className='col-12 col-md-3'>
                            <div className='form-group'>
                              <label className='form__form-group-label'>
                                State
                              </label>
                              <h4>
                                {this.state.prop_state
                                  ? this.state.prop_state
                                  : '-'}
                              </h4>
                            </div>
                          </div>
                          <div className='col-12 col-md-3'>
                            <div className='form-group'>
                              <label className='form__form-group-label'>
                                Post Code
                              </label>
                              <h4>
                                {this.state.prop_post_code
                                  ? this.state.prop_post_code
                                  : '-'}
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-12 col-md-3'>
                            <div className='form-group'>
                              <label className='form__form-group-label'>
                                Country
                              </label>
                              <h4>
                                {this.state.prop_country
                                  ? this.state.prop_country
                                  : '-'}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      id='collapseOne'
                      className='collapse show mb-4'
                      data-parent='#contacts_accordion'
                    >
                      <div className='card-body'>
                        <div className='mb-4 pl-0'>
                          <h4 style={{ color: 'black' }} className='card-link' data-toggle='collapse'>
                            LandLord
                          </h4>
                        </div>
                        <div className='row'>
                          <div className='col-12 col-md-3'>
                            <div className='form-group'>
                              <label className='form__form-group-label'>
                                Landlord Title
                              </label>
                              <h4>
                                {this.state.landlord_title
                                  ? this.state.landlord_title
                                  : '-'}
                              </h4>
                            </div>
                          </div>
                          <div className='col-12 col-md-3'>
                            <div className='form-group'>
                              <label className='form__form-group-label'>
                                First Name
                              </label>
                              <h4>
                                {this.state.landlord_first
                                  ? this.state.landlord_first
                                  : '-'}
                              </h4>
                            </div>
                          </div>
                          <div className='col-12 col-md-3'>
                            <div className='form-group'>
                              <label className='form__form-group-label'>
                                SurName
                              </label>
                              <h4>
                                {this.state.landlord_last
                                  ? this.state.landlord_last
                                  : '-'}
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-12 col-md-3'>
                            <div className='form-group'>
                              <label className='form__form-group-label'>
                                Owner Email
                              </label>
                              <h4>
                                {this.state.owner_email
                                  ? this.state.owner_email
                                  : '-'}
                              </h4>
                            </div>
                          </div>
                          <div className='col-12 col-md-3'>
                            <div className='form-group'>
                              <label className='form__form-group-label'>
                                Owner Phone
                              </label>
                              <h4>
                                {this.state.owner_phone
                                  ? this.state.owner_phone
                                  : '-'}
                              </h4>
                            </div>
                          </div>
                        </div>

                        <div className='row'>
                          <div className='col-12 col-md-9'>
                            <div className='form-group'>
                              <label className='form__form-group-label'>
                                {' '}
                                Address
                              </label>
                              <h4>
                                {this.state.landlord_address_1
                                  ? this.state.landlord_address_1
                                  : '-'}
                              </h4>
                            </div>
                          </div>
                          <div className='col-12 col-md-9'>
                            <div className='form-group'>
                              <label className='form__form-group-label'>
                                Address Line 2
                                <span style={{ color: '#ff0000' }} />
                              </label>
                              <h4>
                                {this.state.landlord_address_2
                                  ? this.state.landlord_address_2
                                  : '-'}
                              </h4>
                            </div>
                          </div>

                          <div className='col-12 col-md-9'>
                            <div className='form-group'>
                              <label className='form__form-group-label'>
                                Address Line 3
                                <span style={{ color: '#ff0000' }} />
                              </label>
                              <h4>
                                {this.state.landlord_address_3
                                  ? this.state.landlord_address_3
                                  : '-'}
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-12 col-md-3'>
                            <div className='form-group'>
                              <label className='form__form-group-label'>
                                Suburb
                              </label>
                              <h4>
                                {this.state.landlord_suburb
                                  ? this.state.landlord_suburb
                                  : '-'}
                              </h4>
                            </div>
                          </div>
                          <div className='col-12 col-md-3'>
                            <div className='form-group'>
                              <label className='form__form-group-label'>
                                State
                              </label>
                              <h4>{this.state.landlord_state}</h4>
                            </div>
                          </div>
                          <div className='col-12 col-md-3'>
                            <div className='form-group'>
                              <label className='form__form-group-label'>
                                Post Code
                              </label>
                              <h4>
                                {this.state.landlord_post_code
                                  ? this.state.landlord_post_code
                                  : '-'}
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-12 col-md-3'>
                            <div className='form-group'>
                              <label className='form__form-group-label'>
                                Country
                              </label>
                              <h4>
                                {this.state.landlord_country
                                  ? this.state.landlord_country
                                  : '-'}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      id='collapseOne'
                      className='collapse show mb-4'
                      data-parent='#contacts_accordion'
                    >
                      <div className='card-body'>
                        <div className='mb-4 pl-0'>
                          <h4 style={{ color: 'black' }} className='card-link' data-toggle='collapse'>
                            Financial
                          </h4>
                        </div>
                        <div className='row'>
                          <div className='col-12 col-md-3'>
                            <div className='form-group'>
                              <label className='form__form-group-label'>
                                Invoice Landlord
                              </label>
                              <h4>
                                {this.state.landlord_invoice === 1
                                  ? 'Yes'
                                  : 'No'}
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-12 col-md-3'>
                            <div className='form-group'>
                              <label className='form__form-group-label'>
                                Invoice Type
                              </label>
                              <h4>
                                {this.state.invoice_type
                                  ? this.state.invoice_type
                                  : '-'}
                              </h4>
                            </div>
                          </div>
                          <div className='col-12 col-md-3'>
                            <div className='form-group'>
                              <label className='form__form-group-label'>
                                Base Price(Before Accrual)$
                              </label>
                              <h4>
                                {this.state.price ? this.state.price : '-'}
                              </h4>
                            </div>
                          </div>
                          <div className='col-12 col-md-3'>
                            <div className='form-group'>
                              <label className='form__form-group-label'>
                                + Tax $
                              </label>
                              <h4>{this.state.tax ? this.state.tax : '-'}</h4>
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-12 col-md-3'>
                            <div className='form-group'>
                              <label className='form__form-group-label'>
                                Total $
                              </label>
                              <h4>
                                {this.state.total ? this.state.total : '-'}
                              </h4>
                            </div>
                          </div>
                          <div className='col-12 col-md-3'>
                            <div className='form-group'>
                              <label className='form__form-group-label'>
                                Status
                              </label>
                              <h4>
                                {this.state.status ? this.state.status : '-'}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.errorModal ? (
                  <ModalComponent
                    modal={this.state.errorModal}
                    color={this.state.errorType}
                    isCancel={true}
                    message={this.state.errorMessage}
                    onConfirm={(e) => this.DeleteStaff(e)}
                    onClose={() => this.setState({ errorModal: false })}
                  />
                ) : null}
                {this.state.accessErrorModal ? (
                  <ModalComponent
                    modal={this.state.accessErrorModal}
                    color='danger'
                    isCancel={false}
                    message='You do not have access to this, please contact your manager for assistance'
                    onConfirm={(e) => this.accessErrorModalClose(e)}
                    onClose={(e) => this.accessErrorModalClose(e)}
                  />
                ) : null}
              </div>
            </div>
          </div>

          {/*PDF*/}
          <div style={{ height: '100px', overflow: 'auto', opacity: '0',display: 'none' }}>
            <div
              id='contacts_accordion_pdf'
              style={{ width: '700px', margin: 'auto', background: '#fff' }}
            >
              <div
                style={{
                  width: '700px',
                  margin: '50px auto',
                  padding: '0 40px',
                }}
              >
                <table style={{ width: '100%', marginBottom: '10px' }}>
                  <tr>
                    <td style={{ padding: '5px' }}>
                      <img
                        src={
                          this.state.logoImageHeader
                            ? this.state.logoImageHeader
                            : 'null'
                        }
                        style={{ maxWidth: '200px' }}
                        alt='logo'
                      />
                    </td>
                  </tr>
                </table>
                <table style={{ width: '100%', marginBottom: '10px' }}>
                  <tr>
                    <td style={{ padding: '5px', width: '300px' }}>
                      <label style={{ display: 'block', fontSize: '16px' }}>
                        First Name
                      </label>
                      <strong>
                        {this.state.agent_nm ? this.state.agent_nm : '-'}
                      </strong>
                    </td>
                    <td style={{ padding: '5px', width: '150px' }}>
                      <label style={{ display: 'block', fontSize: '16px' }}>
                        Phone
                      </label>
                      <strong>
                        {this.state.agent_phone ? this.state.agent_phone : '-'}
                      </strong>
                    </td>
                    <td style={{ padding: '5px', width: '250px' }}>
                      <label style={{ display: 'block', fontSize: '16px' }}>
                        Service
                      </label>
                      <strong>
                        {this.state.service ? this.state.service : '-'}
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan='3' style={{ padding: '5px' }}>
                      <label style={{ display: 'block', fontSize: '16px' }}>
                        {' '}
                        Address{' '}
                      </label>
                      <strong>
                        {' '}
                        {this.state.agent_address_1
                          ? this.state.agent_address_1
                          : '-'}
                      </strong>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>

                  <tr>
                    <td style={{ padding: '5px' }}>
                      <label style={{ display: 'block', fontSize: '16px' }}>
                        Post Code
                      </label>
                      <strong>
                        {this.state.agent_post_code
                          ? this.state.agent_post_code
                          : '-'}
                      </strong>
                    </td>
                  </tr>
                </table>
                <span
                  style={{
                    display: 'block',
                    borderBottom: '1px solid #f5f5f5',
                    marginBottom: '10px',
                  }}
                />
                <table style={{ width: '100%', marginBottom: '10px' }}>
                  <tr>
                    <td style={{ padding: '5px' }}>Property Details</td>
                  </tr>
                  <tr>
                    <td style={{ padding: '5px', width: '50%' }}>
                      <label style={{ display: 'block', fontSize: '16px' }}>
                        Period of Cover From (As of 4PM)
                      </label>
                      <strong>
                        {this.state.cover_from &&
                        this.state.cover_from !== 'Invalid date'
                          ? moment(new Date(this.state.cover_from)).format(
                            'YYYY-MM-DD',
                          )
                          : moment(new Date()).format('YYYY-MM-DD')}
                      </strong>
                    </td>
                    <td style={{ padding: '5px', width: '50%' }}>
                      <label style={{ display: 'block', fontSize: '16px' }}>
                        Period of Cover To (As of 4PM)
                      </label>
                      <strong>
                        {this.state.cover_to &&
                        this.state.cover_to !== 'Invalid date'
                          ? moment(new Date(this.state.cover_to)).format(
                            'YYYY-MM-DD',
                          )
                          : moment(new Date()).format('YYYY-MM-DD')}
                      </strong>
                    </td>
                  </tr>
                  {/* <tr>
                        <td style={{padding:"5px"}}>Address of Property to be Covered</td>
                      </tr> */}
                  <tr>
                    <td colSpan='3' style={{ padding: '5px' }}>
                      <label style={{ display: 'block', fontSize: '16px' }}>
                        {' '}
                        Address
                      </label>
                      <strong>
                        {this.state.prop_address_1
                          ? this.state.prop_address_1
                          : '-'}
                      </strong>
                    </td>
                  </tr>

                  <tr>
                    <td style={{ padding: '5px' }}>
                      <label style={{ display: 'block', fontSize: '16px' }}>
                        Post Code
                      </label>
                      <strong>
                        {this.state.prop_post_code
                          ? this.state.prop_post_code
                          : '-'}
                      </strong>
                    </td>
                  </tr>
                </table>
                <span
                  style={{
                    display: 'block',
                    borderBottom: '1px solid #f5f5f5',
                    marginBottom: '10px',
                  }}
                />
                <table style={{ width: '100%', marginBottom: '10px' }}>
                  <tr>
                    <td>LandLord</td>
                  </tr>
                  <tr>
                    <td style={{ padding: '5px', width: '33%' }}>
                      <label style={{ display: 'block', fontSize: '16px' }}>
                        Landlord Title
                      </label>
                      <strong>
                        {this.state.landlord_title
                          ? this.state.landlord_title
                          : '-'}
                      </strong>
                    </td>
                    <td style={{ padding: '5px', width: '33%' }}>
                      <label style={{ display: 'block', fontSize: '16px' }}>
                        First Name
                      </label>
                      <strong>
                        {this.state.landlord_first
                          ? this.state.landlord_first
                          : '-'}
                      </strong>
                    </td>
                    <td style={{ padding: '5px', width: '33%' }}>
                      <label style={{ display: 'block', fontSize: '16px' }}>
                        SurName
                      </label>
                      <strong>
                        {this.state.landlord_last
                          ? this.state.landlord_last
                          : '-'}
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ padding: '5px' }}>
                      <label style={{ display: 'block', fontSize: '16px' }}>
                        Owner Email
                      </label>
                      <strong>
                        {this.state.owner_email ? this.state.owner_email : '-'}
                      </strong>
                    </td>
                    <td style={{ padding: '5px' }}>
                      <label style={{ display: 'block', fontSize: '16px' }}>
                        Owner Phone
                      </label>
                      <strong>
                        {this.state.owner_phone ? this.state.owner_phone : '-'}
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan='3' style={{ padding: '5px' }}>
                      <label style={{ display: 'block', fontSize: '16px' }}>
                        Address{' '}
                      </label>
                      <strong>
                        {this.state.landlord_address_1
                          ? this.state.landlord_address_1
                          : '-'}
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ padding: '5px' }}>
                      <label style={{ display: 'block', fontSize: '16px' }}>
                        Post Code
                      </label>
                      <strong>
                        {this.state.landlord_post_code
                          ? this.state.landlord_post_code
                          : '-'}
                      </strong>
                    </td>
                  </tr>
                </table>
                <span
                  style={{
                    display: 'block',
                    borderBottom: '1px solid #f5f5f5',
                    marginBottom: '10px',
                  }}
                />
                <table style={{ width: '100%', marginBottom: '10px' }}>
                  <tr>
                    <td style={{ padding: '5px' }}>Financial</td>
                  </tr>
                  <tr>
                    <td style={{ padding: '5px' }}>
                      <label style={{ display: 'block', fontSize: '16px' }}>
                        Invoice Landlord
                      </label>
                      <strong>
                        {this.state.landlord_invoice === 1 ? 'Yes' : 'No'}
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ padding: '5px' }}>
                      <label style={{ display: 'block', fontSize: '16px' }}>
                        Invoice Type
                      </label>
                      <strong>
                        {this.state.invoice_type
                          ? this.state.invoice_type
                          : '-'}
                      </strong>
                    </td>
                    <td style={{ padding: '5px' }}>
                      <label style={{ display: 'block', fontSize: '16px' }}>
                        Base Price(Before Accrual)$
                      </label>
                      <strong>
                        {this.state.price ? this.state.price : '-'}
                      </strong>
                    </td>
                    <td style={{ padding: '5px' }}>
                      <label style={{ display: 'block', fontSize: '16px' }}>
                        + Tax $
                      </label>
                      <strong>{this.state.tax ? this.state.tax : '-'}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ padding: '5px' }}>
                      <label style={{ display: 'block', fontSize: '16px' }}>
                        Total $
                      </label>
                      <strong>
                        {this.state.total ? this.state.total : '-'}
                      </strong>
                    </td>
                    <td style={{ padding: '5px' }}>
                      <label style={{ display: 'block', fontSize: '16px' }}>
                        Status
                      </label>
                      <strong>
                        {this.state.status ? this.state.status : '-'}
                      </strong>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <iframe
            id='ifmcontentstoprint'
            style={{ height: '0px', width: '0px', position: 'absolute' }}
          />
        </div>}
      </>
    );
  }
}

export default reduxForm({
  form: 'add_staff_form_validation',
})(withRouter(withTranslation('common')(ViewProperty)));
