import { REACT_APP_API_URL } from "@src/lib/global-variables"
import axios from "axios"
import { GetSyncedEmailsParams, UniformEmail } from "../types/email.type"
import { PaginationResponse } from "@src/types/service.type";

export class EmailService {

  async refresh(params: Omit<GetSyncedEmailsParams, 'page' | 'limit'>) {
    const response = await axios.get<void>(`${REACT_APP_API_URL}/emails/outlook-sync/refresh`, { params })
    return response.data;
  }

  async getLocalEmailsSyncedWithOutlookEmails(params: GetSyncedEmailsParams) {
    const response = await axios.get<PaginationResponse<UniformEmail[]> & {last_synced_at: Date}>(`${REACT_APP_API_URL}/emails/outlook-sync`, { params })
    return response.data;
  }


  async getLocalEmail(id: number) {
    const response = await axios.get<UniformEmail>(`${REACT_APP_API_URL}/emails/${id}`)
    return response.data;
  }

  async getOutlookEmail(userId: string, messageId: string) {
    const response = await axios.get<UniformEmail>(`${REACT_APP_API_URL}/emails/outlook/${userId}/message/${messageId}`)
    return response.data;
  }
}