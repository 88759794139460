import LabelWrapper from '@components/label-wrapper'
import { useForm } from 'react-hook-form'
import Select from 'react-select'
import styles from './add-fee-form.module.scss'
import { joiResolver } from '@hookform/resolvers/joi'
import { addFeeSchema } from '../../schemas/claim-details.schema'
import { ADD_FEE_DEFAULT_VALUES } from '../../const/claim-details.const'
import { FC } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import axios from 'axios'
import { REACT_APP_API_URL } from '@src/lib/global-variables'
import { toast } from 'react-toastify'
import { useUserContext } from '@src/context/user-context'
import cls from 'classnames'
import {
  AddFeeFormProps,
  AddFeeFormValues,
  AddFeeResponse,
} from '../../types/recoverable-costs.type'

const AddFeeForm: FC<AddFeeFormProps> = ({ stateOfOrigin, wipId, onFeeAdded }) => {
  const { control, handleSubmit } = useForm<AddFeeFormValues>({
    defaultValues: ADD_FEE_DEFAULT_VALUES,
    resolver: joiResolver(addFeeSchema),
  })
  const queryClient = useQueryClient()
  const { user } = useUserContext()

  const getListOfFees = async () => {
    const params = {
      s: JSON.stringify({ short: stateOfOrigin }),
    }
    const response = await axios.get(`${REACT_APP_API_URL}/wip-cost-lists`, { params })
    const data = response.data.data[0]
    if (data) {
      return data.list
        .filter((item) => item.id)
        .map((item) => ({ label: item.name, value: item.id }))
    }
    return []
  }

  const { data: listOfFees } = useQuery({
    queryKey: [GLOBAL_QUERIES.LIST_OF_FEES.GET],
    queryFn: getListOfFees,
    enabled: !!stateOfOrigin,
    initialData: [],
  })

  const onSubmit = async (data: AddFeeFormValues) => {
    try {
      const response = await axios.post<AddFeeResponse>(`${REACT_APP_API_URL}/wips/add-fee`, {
        stateOfOrigin,
        feeId: data.fee?.value,
        wipId,
        staffCreatedId: user?.id,
      })
      onFeeAdded(response.data)
      queryClient.invalidateQueries({ queryKey: [GLOBAL_QUERIES.CLAIM_DETAILS_NOTES.GET] })
      queryClient.invalidateQueries({ queryKey: [GLOBAL_QUERIES.RECOVERABLE_COST.GET] })
      toast.success('Fee added')
    } catch {
      toast.error('Something went wrong')
    }
  }
  return (
    <form className={styles.wrapper} onSubmit={handleSubmit(onSubmit)}>
      <LabelWrapper
        label="List Of Fees:"
        className={cls(styles.labelWrapper, styles.listOfFees)}
        control={control}
        name="fee"
      >
        {({ onChange, value }) => (
          <Select
            type="text"
            classNamePrefix="react-select"
            className={'react-select'}
            options={listOfFees}
            value={value}
            onChange={onChange}
          />
        )}
      </LabelWrapper>
      <button type="submit" className={cls('btn btn-secondary', styles.addFeeButton)}>
        Add Fee
      </button>
    </form>
  )
}

export default AddFeeForm
