import PaymentTrackingPage from '../../../payment-tracking-page.component'
import { FC } from 'react'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import SlideModal from '@components/slide-modal'
import { PaymentTrackingModalProps } from '../../../types/payment-tracking.type'

const PaymentTrackingModal: FC<PaymentTrackingModalProps> = ({ wip, ...modalProps }) => {
  return (
    <SlideModal
      {...modalProps}
      style={{ width: '620px' }}
      layoutStyle={{ zIndex: 1000, backgroundColor: 'black' }}
    >
      <Header mb={4}>Payment Tracking</Header>
      <PaymentTrackingPage wip={wip} alignment="vertical" />
    </SlideModal>
  )
}

export default PaymentTrackingModal
