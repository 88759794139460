import { deleteInvoiceTemplateActionTypes } from '../actionTypes';

export const deleteInvoiceTemplateReducer = function (
  state = { data: [], loading: false, error: '' },
  action,
) {
  switch (action.type) {
    case deleteInvoiceTemplateActionTypes.DELETE_INVOICE_TEMPLATE_SUCCESS:
      return { loading: false, error: '', data: action.payload };
    case deleteInvoiceTemplateActionTypes.DELETE_INVOICE_TEMPLATE_ERROR:
      return { loading: false, error: '', data: [] };
    case deleteInvoiceTemplateActionTypes.DELETE_INVOICE_TEMPLATE_LOADING:
      return { loading: true, error: action.payload, data: [] };
    default:
      return state;
  }
};
