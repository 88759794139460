import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { GraphApiMailService } from "@containers/Inbox/services/graph-api-mail.service";
import { GraphApiMessage, SendMessageBody } from "@containers/Inbox/types/graph-api-mail.type";
import { useGetMailFolderByDisplayNameOrCreate } from "../get-mail-folder-by-display-name-or-create.hook";
import { SHARED_FOLDER_NAME } from "@containers/Inbox/inbox-page/const/inbox.const";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { DateService } from "@containers/Inbox/services/date.service";

export const useSendMessageMutation = () => {
  const { mailFolder } = useGetMailFolderByDisplayNameOrCreate(SHARED_FOLDER_NAME);
  const userIsAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();

  const retrieveSentMessage = async (accessToken: string, emailSubject: string, emailSendingStartTime: string) => {
    const mailService = new GraphApiMailService();
    let attemptCount = 0;

    const DELAY_TIME = 1000
    const RETRY_COUNT = 10

    while (attemptCount < RETRY_COUNT) {
      ++attemptCount
      const requestStartDate = new Date();
      const sentEmails = await mailService.getMessagesInsideFolderByName(
        accessToken,
        'sentitems',
        {
          $filter: `sentDateTime ge ${emailSendingStartTime} and subject eq '${emailSubject}'`,
          $top: 1,
          $orderby: 'sentDateTime DESC',
        },
      );

      if (sentEmails.data.length) {
        return sentEmails.data[0];
      }

      const timePassedFromRequestStart = new Date().getTime() - requestStartDate.getTime()

      if (timePassedFromRequestStart < DELAY_TIME) {
        const delay = DELAY_TIME - timePassedFromRequestStart
        await new Promise((resolve) => {
          setTimeout(() => {
            resolve(true)
          }, delay)
        })
      }
    }

    return null;
  };

  const sendEmail = async (emailBody: SendMessageBody): Promise<[GraphApiMessage | null, Error | null]> => {
    if (!userIsAuthenticated) return [null, new Error('User is not authenticated')];
    if (!mailFolder) return [null, new Error('Mail folder not found')];
    const mailService = new GraphApiMailService();
    const dateService = new DateService();
    const userAccount = accounts[0] || {};

    try {
      const tokenResponse = await instance.acquireTokenSilent({ account: userAccount, scopes: ['Mail.Send', 'Mail.Read', 'Mail.ReadWrite'] });

      const emailSendingStartTime = await dateService.getUtcDate()

      await mailService.sendMessage(tokenResponse.accessToken, emailBody);

      const sentEmail = await retrieveSentMessage(tokenResponse.accessToken, emailBody.message.subject, emailSendingStartTime);

      if (!sentEmail) {
        return [null, new Error('Email was sent but not found in sent items folder')];
      }

      await mailService.moveMessageToFolder(
        tokenResponse.accessToken,
        sentEmail.id,
        mailFolder.id,
      );
      return [sentEmail, null];
    } catch (error) {
      console.error(error);
      if (axios.isAxiosError(error)) {
        return [null, new Error(error.response?.data?.message || 'Failed to send email')];
      }
      if (error instanceof Error) {
        return [null, error];
      }
      return [null, new Error('Failed to send email')];
    }
  };

  const sendMessageMutation = useMutation({ mutationFn: sendEmail });

  return sendMessageMutation;
};