import PaginatedSelect from '@components/pagination-select/paginated-select.component'
import {
  SelectSubOptionWithRef,
  SubOptionChangeEvent,
} from '@components/pagination-select/pagination-select.type'
import { encryptXOR } from '@containers/Inbox/view-email-page/components/utils/xor-encryption.utils'
import { useGetClientOptions } from '@containers/layout/hooks/get-clients-option.hook'
import { useGetWipsOptions } from '@containers/layout/hooks/get-wips-option.hook'
import { REACT_APP_XOR_ENCRYPTION_KEY } from '@src/lib/global-variables'
import { FC, useMemo, useState } from 'react'

interface WipClientPaginationSelectProps {
  selectedSubOptions?: SelectSubOptionWithRef[]
  onSubOptionChange?: (event: SubOptionChangeEvent) => void
}

const WipClientPaginationSelect: FC<WipClientPaginationSelectProps> = ({
  selectedSubOptions = [],
  onSubOptionChange,
}) => {
  const [clientSearch, setClientSearch] = useState<string>('')
  const [wipSearch, setWipSearch] = useState<string>('')

  const clientParams = useMemo(
    () => ({
      limit: 10,
      sort: 'time_created,DESC',
      s: clientSearch ? JSON.stringify({ name: { $contL: clientSearch } }) : undefined,
    }),
    [clientSearch],
  )

  const {
    data: clientOptions,
    hasNextPage: clientHasNextPage,
    isFetching: clientIsFetching,
    fetchNextPage: clientFetchNextPage,
    isLoading: clientIsLoading,
  } = useGetClientOptions(clientParams)

  const wipParams = useMemo(
    () => ({
      limit: 10,
      sort: 'created_at,DESC',
      s: wipSearch ? JSON.stringify({ id: wipSearch }) : undefined,
    }),
    [wipSearch],
  )

  const {
    data: wipOptions,
    hasNextPage,
    isFetching,
    fetchNextPage,
    isLoading,
  } = useGetWipsOptions(wipParams)


  const addEncryptedIdToLabel = (label: string, id: number) => {
    return `${label} | ${encryptXOR(id, REACT_APP_XOR_ENCRYPTION_KEY)}`
  }

  const addEncryptedIdToOption = (options: {label: string, value: number}[]) => {
    return options.map(option => ({label: addEncryptedIdToLabel(option.label, option.value), value: option.value}))
  }

  const options = useMemo(
    () => [
      {
        option: {
          label: 'Wip',
          value: 'wip',
        },
        loading: isLoading || isFetching,
        subOptions: addEncryptedIdToOption(wipOptions),
      },
      {
        option: {
          label: 'Client',
          value: 'client',
        },
        loading: clientIsLoading || clientIsFetching,
        subOptions: addEncryptedIdToOption(clientOptions),
      },
    ],
    [wipOptions, clientOptions, clientIsLoading, isLoading, clientIsFetching, isFetching],
  )

  const handleSubOptionChange = (event: SubOptionChangeEvent) => {
    onSubOptionChange && onSubOptionChange(event)
  }

  const handleReachEnd = (event: { ref: string }) => {
    if (event.ref === 'wip' && hasNextPage && !isFetching) {
      fetchNextPage()
      return
    }
    if (clientHasNextPage && !clientIsFetching) {
      clientFetchNextPage()
    }
  }

  const removeNonNumericChars = (str: string) => {
    return str.replace(/[^\d]/g, '')
  }

  const handleSearchChange = (event: { ref: string; value: string }) => {
    if (event.ref === 'wip') {
      const numericSearch = removeNonNumericChars(event.value)
      setWipSearch(numericSearch)
      return
    }
    setClientSearch(event.value)
  }

  const handleIsOpenChange = () => {
    setClientSearch('')
    setWipSearch('')
  }

  return (
    <PaginatedSelect
      options={options}
      selectedSubOptions={selectedSubOptions}
      onSelectIsOpenChange={handleIsOpenChange}
      onSubOptionChange={handleSubOptionChange}
      onSearchChange={handleSearchChange}
      onReachEnd={handleReachEnd}
    />
  )
}

export default WipClientPaginationSelect
