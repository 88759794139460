import GenericTable from '@components/generic-table/generic-table'
import { TaskProps } from '@containers/Setting/components/Contacts/taskboard/types'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import { REACT_APP_API_URL } from '@src/lib/global-variables'
import axios from 'axios'
import TaskItem from './task-item.component'
import styles from './task-table.module.scss'
import { FC } from 'react'

interface TaskTableProps {
  params?: NonNullable<unknown>
  queryKey?: string
}

const TaskTable: FC<TaskTableProps> = ({ params = {}, queryKey = GLOBAL_QUERIES.TASKSERVICE.GET}) => {
 
  const fetchOverdueTask = async (pagination) => {
    const requestParam = {
      ...pagination,
      ...params,
      join: 'companies',
    }

    const response = await axios.get(`${REACT_APP_API_URL}/tasks`, { params: requestParam })
    return response.data as {
      total: number
      data: TaskProps[]
    }
  }

  return (
    <GenericTable
      limit={5}
      queryFn={fetchOverdueTask}
      queryKey={[queryKey]}
      tableClassName={styles.overdueTable}
      rowHeight={85}
      headerOptions={[
        'Due Date',
        'Client',
        'Title',
        'Wip',
        'Description',
        'Created On',
        'Options',
        'Done',
      ]}
    >
      {({ data }) =>
        data.map((task, index) => (
          <TaskItem key={`${task.id}-overdue-task-item-${index}`} task={task} />
        ))
      }
    </GenericTable>
  )
}

export default TaskTable
