import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  select: {
    margin: '0 auto 0 0 !important',
    maxHeight: '34px',
    height: '34px',
    borderRadius: '5px',
    '& .MuiSelect-select': {
      border: '1px solid #646777',
    },
    '& .MuiFormLabel-root': {
      top: 0,
      bottom: 0,
      transform: 'translateY(50%)',
    },
    '& .MuiOutlinedInput-input': {
      color: '#646777',
      fontSize: '16px',
      padding: '5.5px 10px',
      minWidth: '200px',
      minHeight: '0 !important',
    },
    '& .MuiOutlinedInput-notchedOutline ': {
      border: '0',
    },
  },
});
