import * as React from 'react';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import { useEffect } from 'react';

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export default function ChipsArray({handlerDownloadFile,removeFile,files,delay}) {

  const [chipData, setChipData] = React.useState([]);

  useEffect(()=>{
    setChipData(files)
  },[files])


  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) => chips.filter((chip) => chip.file_path !== chipToDelete.file_path));
    removeFile(chipToDelete)
  };

  return (
    <Paper
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        listStyle: 'none',
        p: 0.5,
        m: 0,
        boxShadow: 'none',
        border: '1px solid #dadada',
        minHeight: '50px'
      }}
      component='ul'
    >
      {chipData.map((data,i) => {
        return (
          <ListItem key={i}>
            <Chip
              label={data?.file_name}
              onDelete={handleDelete(data)}
              onClick={()=>handlerDownloadFile(data)}
              disabled={delay}
            />
          </ListItem>
        );
      })}
    </Paper>
  );
}
