import { useMutation } from "@tanstack/react-query"
import { CreateDebtorOtherData, DebtorOther } from "../../types/debtor-associate-detail.type"
import { DebtorOtherService } from "../../services/debtor-other.service"
import { DeepPartial } from "react-hook-form"

export const useCreateDebtorOtherMutation = () => {

  const createDebtorOther = (data: CreateDebtorOtherData) => {
    const debtorOtherService = new DebtorOtherService()
    return debtorOtherService.createDebtorOther(data)
  }

  const mutation = useMutation(createDebtorOther)

  return mutation
}

export const useUpdateDebtorOtherMutation = () => {

  const updateDebtorOther = (id: number, data: DeepPartial<DebtorOther>) => {
    const debtorOtherService = new DebtorOtherService()
    return debtorOtherService.updateDebtorOther(id, data)
  }

  const mutation = useMutation({ mutationFn: ({ id, data }: { id: number, data: DeepPartial<DebtorOther> }) => updateDebtorOther(id, data) })

  return mutation;
}