import React, { useState } from 'react'
import { Collapse } from 'reactstrap'
import { Link } from 'react-router-dom'
import NavigationButton from '@components/navigation-button/navigation-button'
import NotificationIcon from '@src/icons/notification'

const mockData = [
  {
    ava: `${process.env.PUBLIC_URL}/img/topbar/ava.png`,
    name: 'Cristopher Changer',
    message: ' has started a new project',
    date: '09:02',
  },
  {
    ava: `${process.env.PUBLIC_URL}/img/topbar/ava2.png`,
    name: 'Sveta Narry',
    message: ' has closed a project',
    date: '09:00',
  },
  {
    ava: `${process.env.PUBLIC_URL}/img/topbar/ava3.png`,
    name: 'Lory McQueen',
    message: ' has started a new project as a Project Managert',
    date: '08:43',
  },
  {
    ava: `${process.env.PUBLIC_URL}/img/topbar/ava2.png`,
    name: 'Cristopher Changer',
    message: ' has closed a project',
    date: '08:43',
  },
]

const TopBarNotification = () => {
  const [collapse, setCollapse] = useState(false)
  const [notifications, setNotifications] = useState(mockData)

  const turn = () => {
    setCollapse((prevState) => !prevState)
  }

  return (
    <div className="topbar__collapse">
      <NavigationButton icon={NotificationIcon} onClick={turn} isActive={collapse}/>
      {collapse && <button className="topbar__back" type="button" onClick={turn} />}
      <Collapse isOpen={collapse} className="topbar__collapse-content">
        <div className="topbar__collapse-title-wrap">
          <p className="topbar__collapse-title">Notifications</p>
          <button className="topbar__collapse-button" type="button">
            Mark all as read
          </button>
        </div>
        {notifications.map((notification, index) => (
          <div className="topbar__collapse-item" key={index}>
            <div className="topbar__collapse-img-wrap">
              <img className="topbar__collapse-img" src={notification.ava} alt="" />
            </div>
            <p className="topbar__collapse-message">
              <span className="topbar__collapse-name">{notification.name}</span>
              {notification.message}
            </p>
            <p className="topbar__collapse-date">{notification.date}</p>
          </div>
        ))}
        <Link className="topbar__collapse-link" to="/dashboard" onClick={turn}>
          See all notifications
        </Link>
      </Collapse>
    </div>
  )
}

export default TopBarNotification
