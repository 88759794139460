import { AutomationColumn } from "@containers/Setting/components/Contacts/taskboard/types";
export enum WipTemplateMode {
    RECOVERY = 'RECOVERY',
    SALES = 'SALES'
}

export interface WipTemplate {
    id: number;
    name: string;
    mode: WipTemplateMode;
    columns: AutomationColumn[];
    created_at: number;
    updated_at: number;
}

export interface WipTemplateItemProps {
    wipTemplate: WipTemplate
    openEditTemplateModal: (event, data: WipTemplate) => void
}

export interface CreateWipTemplateModalProps {
    isOpen: boolean;
    onClose: () => void;
}

export interface EditWipTemplateModalProps {
    isOpen: boolean;
    onClose: () => void;
    wipTemplateId: number;
}

export interface CreateWipTemplateForm {
    name: string;
}