import FormDatePicker from '@components/inputs/form-date-picker/form-date-picker.component'
import FormInput from '@components/inputs/form-input/form-input.component'
import FormSelect from '@components/inputs/form-select/form-select.component'
import { useUpdateDebtorMutation } from '@containers/Recovery/Wip/_modules/Debtors/hooks/mutations/update-debtor.hook'
import {
  DebtorDetailsOtherFormProps,
  DebtorDetailsOtherFormValues,
} from '@containers/Recovery/Wip/_modules/Debtors/types/debtor-form.type'
import { getDebtorQueryKey } from '@containers/Recovery/Wip/_modules/Debtors/utils/debtor-query-key-factory.utils'
import { transformEmptyStringToNull } from '@containers/Recovery/Wip/_modules/Debtors/utils/transform-debtor-to-values.utils'
import SubSection from '@containers/Recovery/Wip/components/UI/sub-section/sub-section.component'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import { Debtor } from '@containers/Setting/components/Contacts/taskboard/types'
import { STATE_OPTIONS } from '@src/lib/select-options.const'
import { useQueryClient } from '@tanstack/react-query'
import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

const DebtorDetailsOtherForm: FC<DebtorDetailsOtherFormProps> = ({ initialValues, debtorId }) => {
  const queryClient = useQueryClient()
  const { mutateAsync, isLoading } = useUpdateDebtorMutation()
  const { register, control, reset, handleSubmit } = useForm<DebtorDetailsOtherFormValues>({
    defaultValues: initialValues,
  })

  useEffect(() => {
    reset(initialValues)
  }, [initialValues])

  const onSubmit = async (data: DebtorDetailsOtherFormValues) => {
    try {
      const updatedDebtor = await mutateAsync({
        id: debtorId,
        debtor: transformEmptyStringToNull({
          ...data,
          state_vehicle_licence: data.state_vehicle_licence?.value || null,
          state_drivers_licence: data.state_drivers_licence?.value || null,
        }),
        params: { fields: Object.keys(data).join(',') },
      })
      queryClient.setQueryData<Debtor>(getDebtorQueryKey(debtorId), (prevData) => ({
        ...(prevData as unknown as Debtor),
        ...updatedDebtor,
      }))
      toast.success('Debtor updated successfully')
    } catch (e) {
      console.error(e)
      toast.error('Something went wrong')
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Header>Other</Header>
      <SubSection flex p={4}>
        <FormDatePicker name="time_created" control={control} label="Created at" />
        <FormDatePicker name="date_of_entry" control={control} label="Date of Entry" />
      </SubSection>
      <SubSection flex p={4}>
        <FormInput label="Drivers License " {...register('drivers_licence')} />
        <FormSelect
          name="state_drivers_licence"
          control={control}
          label="State Issued"
          options={STATE_OPTIONS}
        />
      </SubSection>
      <SubSection flex p={4} mb={4}>
        <FormInput label="Vehicle Registration " {...register('vehicle_reg')} />
        <FormSelect
          name="state_vehicle_licence"
          control={control}
          label="State Issued"
          options={STATE_OPTIONS}
        />
      </SubSection>
      <SubmitButton buttonType="submit" loading={isLoading}>
        Save Details
      </SubmitButton>
    </form>
  )
}

export default DebtorDetailsOtherForm
