import CloseIcon from '@src/icons/close'
import RoundedArrow from '@src/icons/rounded-arrow'
import { FC, useEffect, useState } from 'react'
import styles from './custom-phone-input.module.scss'
import AustraliaFlagIcon from '../flags/autstralia-flag-icon'
import NewZealandFlagIcon from '../flags/new-zealand-flag-icon'
import cls from 'classnames'

enum PhoneType {
  LANDLINE = 'landline',
  MOBILE = 'mobile',
  COMBINED = 'combined',
}

interface PhoneFormatting {
  phoneStartsWith: number[]
  mask: string
  type: PhoneType
}

interface PhoneOnChangeEvent {
  countryCode: string
  countryShortName: string
  countryLongName: string
  phone: string
  phoneType: PhoneType
  originalValue: string | undefined
  formattedPhone: string
}

export interface PhoneOption {
  countryCode: string // +61
  shortCountryName: string // AU
  longCountryName: string // Australia
  phoneFormatting: PhoneFormatting[]
  flag: FC<{ className: string }>
}

const DEFAULT_PHONE_FORMATTING: PhoneFormatting = {
  phoneStartsWith: [],
  mask: '(#) ### ### ###',
  type: PhoneType.COMBINED,
}

const phoneOptions: PhoneOption[] = [
  {
    countryCode: '+61',
    shortCountryName: 'AU',
    longCountryName: 'Australia',
    phoneFormatting: [
      {
        phoneStartsWith: [1, 4, 5, 6, 9],
        mask: '(#) ### ### ###',
        type: PhoneType.MOBILE,
      },
      {
        phoneStartsWith: [2, 3, 7, 8],
        mask: '(##) #### ####',
        type: PhoneType.LANDLINE,
      },
    ],
    flag: AustraliaFlagIcon,
  },
  {
    countryCode: '+64',
    shortCountryName: 'NZ',
    longCountryName: 'New Zealand',
    phoneFormatting: [
      {
        phoneStartsWith: [24],
        mask: '(###) ### ####',
        type: PhoneType.LANDLINE,
      },
      {
        phoneStartsWith: [3, 4, 6, 7, 9],
        mask: '(##) ### ####',
        type: PhoneType.LANDLINE,
      },
      {
        phoneStartsWith: [1, 2, 5],
        mask: '(#) ### ### ###',
        type: PhoneType.MOBILE,
      },
    ],
    flag: NewZealandFlagIcon,
  },
]

interface CustomPhoneInputProps {
  value?: string
  label?: string
  clearType?: 'emptyString' | 'null'
  inputStyle?: 'outlined' | 'filled'
  error?: string
  onChange?: (changeEvent: PhoneOnChangeEvent) => void
  onPhoneOptionChange?: (phoneOption: PhoneOption) => void
}

const removeNonNumeric = (value: string) => {
  if (typeof value !== 'string') return ''
  return value.replace(/\D/g, '')
}

const format = (value: string, pattern: string) => {
  let i = 0
  const formattedString = pattern.replace(/#/g, (_) => value[i++] || '')
  return formattedString.trim()
}

const removeZeroFromStart = (value: string) => {
  if (value.startsWith('0')) {
    return value.slice(1)
  }
  return value
}

const isZeroInStart = (value: string) => {
  return value.startsWith('0')
}

const formatToChangeEvent = (
  value: string | undefined,
  phoneOption: PhoneOption,
  phoneType: PhoneType,
): PhoneOnChangeEvent => {
  return {
    countryCode: phoneOption.countryCode,
    countryShortName: phoneOption.shortCountryName,
    countryLongName: phoneOption.longCountryName,
    phone: removeNonNumeric(value || ''),
    originalValue: value,
    phoneType: phoneType,
    formattedPhone: phoneOption.countryCode + removeZeroFromStart(removeNonNumeric(value || '')),
  }
}

const CustomPhoneInput: FC<CustomPhoneInputProps> = ({
  label,
  error,
  clearType = 'emptyString',
  inputStyle = 'filled',
  onChange,
  value,
  onPhoneOptionChange,
}) => {
  const [innerValue, setInnerValue] = useState<string | undefined>('')
  const [lastChangeEvent, setLastChangeEvent] = useState<PhoneOnChangeEvent | null>(null)
  const [selectedPhoneOption, setSelectedPhoneOption] = useState<PhoneOption>(phoneOptions[0])
  const [isSelectOpen, setIsSelectOpen] = useState(false)

  const handleIconClick = () => {
    setIsSelectOpen((prev) => !prev)
  }

  const handleInputChange = (inputValue: string, phoneOption: PhoneOption) => {
    const isRemoveLastChar = new RegExp(/^\(\d{1,3}$/).test(inputValue)
    let inputValueWithoutNonNumeric = isRemoveLastChar
      ? removeNonNumeric(inputValue).slice(0, -1)
      : removeNonNumeric(inputValue)

    inputValueWithoutNonNumeric = isZeroInStart(inputValueWithoutNonNumeric)
      ? inputValueWithoutNonNumeric
      : '0' + inputValueWithoutNonNumeric

    const matchedPhoneFormatting =
      phoneOption.phoneFormatting.find((formatting) =>
        formatting.phoneStartsWith.some((start) =>
          inputValueWithoutNonNumeric.startsWith(start.toString(), 1),
        ),
      ) || DEFAULT_PHONE_FORMATTING

    const maxLength = matchedPhoneFormatting.mask.split('#').length - 1

    if (inputValueWithoutNonNumeric.length > maxLength) {
      return
    }

    const formattedValue = format(inputValueWithoutNonNumeric, matchedPhoneFormatting.mask)

    const newValue = formattedValue.length === 2 ? '' : formattedValue

    const changeEvent = formatToChangeEvent(newValue, phoneOption, matchedPhoneFormatting.type)

    onChange && onChange(changeEvent)

    setLastChangeEvent(changeEvent)
    setInnerValue(newValue)
  }

  const handleSelectItemClick = (option: PhoneOption) => {
    setSelectedPhoneOption(option)
    setIsSelectOpen(false)
    handleInputChange(innerValue || '', option)
    onPhoneOptionChange && onPhoneOptionChange(option)
  }

  const handleClearClick = () => {
    const clearValue = clearType === 'emptyString' ? '' : undefined
    const changeEvent = formatToChangeEvent(clearValue, selectedPhoneOption, PhoneType.COMBINED)

    onChange && onChange(changeEvent)
    setLastChangeEvent(changeEvent)
    setInnerValue(clearValue)
  }

  useEffect(() => {
    if (lastChangeEvent?.formattedPhone === value) {
      return
    }

    const regex = /^\+(61|64)\d{0,9}$/
    const isPhoneValid = regex.test(value || '')

    if (!isPhoneValid || value === undefined) {
      setInnerValue(undefined)
      return
    }

    const countryCode = value.slice(0, 3)
    const valueWithoutCountryCode = isZeroInStart(value.slice(3))
      ? value.slice(3)
      : '0' + value.slice(3)
    const phoneOption =
      phoneOptions.find((option) => option.countryCode === countryCode) || phoneOptions[0]

    if (phoneOption.countryCode !== selectedPhoneOption.countryCode) {
      setSelectedPhoneOption(phoneOption)
    }

    handleInputChange(valueWithoutCountryCode, phoneOption)
  }, [value, lastChangeEvent])

  const Icon = selectedPhoneOption.flag

  return (
    <div className={styles.wrapper}>
      {label ? (
        <span className={styles.labelWrapper}>
          <span className={styles.label}>{label}</span>
          {error ? <span className={styles.error}>{error}</span> : null}
        </span>
      ) : null}
      <div className={styles.phoneFieldWrapper}>
        <button
          type="button"
          className={cls(styles.iconWrapper, { [styles.open]: isSelectOpen })}
          onClick={handleIconClick}
        >
          <Icon className={styles.icon} />
          <RoundedArrow className={styles.arrow} />
        </button>
        {isSelectOpen ? (
          <div className={cls(styles.selectListWrapper, { [styles.open]: isSelectOpen })}>
            <ul className={styles.selectList}>
              {phoneOptions.map((option) => {
                const OptionIcon = option.flag
                return (
                  <li
                    key={option.shortCountryName}
                    className={styles.selectListItem}
                    onClick={() => handleSelectItemClick(option)}
                  >
                    <OptionIcon className={styles.icon} />
                    <span className={styles.label}>{option.shortCountryName}</span>
                  </li>
                )
              })}
            </ul>
          </div>
        ) : null}
        <input
          className={cls(styles.phoneInput, {
            [styles.open]: isSelectOpen,
            [styles.outlined]: inputStyle === 'outlined',
          })}
          value={innerValue}
          onChange={(e) => handleInputChange(e.target.value, selectedPhoneOption)}
        />
        <button className={styles.clearButton} type="button" onClick={handleClearClick}>
          <CloseIcon />
        </button>
      </div>
    </div>
  )
}

export default CustomPhoneInput
