import BaseService from './BaseService'
import axios from 'axios'

export default class TemplateService extends BaseService {
  constructor() {
    super()
    this.apiUrl = this.apiUrl + '/email-templates'
    this.apiUrlTemp = this.apiUrl + '/email-template-types'
  }

  async getTempEmail(params = {}) {
    try {
      const response = await axios.get(this.apiUrl, { params })
      if (response.status !== 200) {
        throw new Error('Failed to fetch staff')
      }

      return response
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }
  async getTypeTempEmail() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/email-template-types`)

      if (response.status !== 200) {
        throw new Error('Failed to fetch staff')
      }

      return response
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async editTempEmail(params) {
    try {
      const response = await axios.patch(`${this.apiUrl}/${params.id}`, params)
      if (response.status !== 200) {
        throw new Error('Failed to fetch staff')
      }

      return response
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async addTempEmail(params) {
    try {
      const response = await axios.post(this.apiUrl, params)
      if (response.status !== 201) {
        throw new Error('Failed to fetch staff')
      }

      return response
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }
}
