import React from 'react';

// import PropTypes from 'prop-types';
// import { Button } from 'reactstrap';
// import FirebaseIcon from 'mdi-react/FirebaseIcon';
// import withAuthFirebase from '../auth/withAuthFirebase';
import { withRouter } from 'react-router';
import LogInForm from './LogInForm';
import ForgotPassword from './ForgotPassword';
// import GoogleAuthBtn from '../../../containers/Account/AuthBtn/googleAuthBtn';
// import FacebookAuthBtn from '../../../containers/Account/AuthBtn/fbAuthBtn';

// const auth0Icon = `${process.env.PUBLIC_URL}/img/auth0.svg`;

const loginRightImg = `${process.env.PUBLIC_URL}/image/login-right-img.jpg`;

class LoginCard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      forgotpassword:false
    };

    this.forgotPassword=this.forgotPassword.bind(this);

    
  }
  forgotPassword=()=>{
    this.setState({
      forgotpassword:!this.state.forgotpassword
    })
  }

  render() {
    const {forgotpassword}=this.state;
    return (
    <div className="container-scroller container-fluid">
      <div className="login_wrapper ">
        <div className="login_wrapper_inner row">
          <div className="login_left">
            <div className="login_right_banner">
              <img src={loginRightImg} alt="login" />
            </div>
          </div>
          <div className="login_right">

            {!forgotpassword && 
              <LogInForm onSubmin form="login_form" forgotPassword={this.forgotPassword}/>
            }
            {forgotpassword && 
              <ForgotPassword onSubmin form="login_form" forgotPassword={this.forgotPassword}/>
            }
           
            <div className="login-footer">
              <div className="copyright">
                <p>Copyright ©2020 Barclay MIS. All rights reserved.</p>             
              </div>
              <div className="privacy-policy">
                <a href="http://13.211.158.213/BarclayMIS/?page=disclaimer">Disclaimer</a>
                <a href="http://13.211.158.213/BarclayMIS/?page=privacy">Privacy Policy</a>
              </div>
            </div>
            
          </div>
          
        </div>
      </div>
     </div>
  );
  }
};


export default withRouter(LoginCard)
// export default withAuthFirebase(LoginCard);
