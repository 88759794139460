import FormInput from '@components/inputs/form-input/form-input.component'
import FormSelect from '@components/inputs/form-select/form-select.component'
import FormTextArea from '@components/inputs/form-textarea/form-textarea.component'
import {
  DebtorOtherFormProps,
  DebtorOtherFormValues,
} from '@containers/Recovery/Wip/_modules/Debtors/types/debtor-associate-detail.type'
import { Controller, useForm } from 'react-hook-form'
import styles from './debtor-other-form.module.scss'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { DEBTOR_CALL_RESULT_OPTIONS } from '@containers/Recovery/Wip/_modules/Debtors/const/debtor-associate-details.const'
import { joiResolver } from '@hookform/resolvers/joi'
import { debtorOtherSchema } from '@containers/Recovery/Wip/_modules/Debtors/schemas/debtor.schemas'
import { FC, useEffect } from 'react'
import CustomPhoneInput from '@components/inputs/form-input/custom-phone-input/custom-phone-input.component'

const DebtorOtherForm: FC<DebtorOtherFormProps> = ({
  onSubmit,
  loading = false,
  onCancelClick,
  initialValues,
}) => {
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<DebtorOtherFormValues>({
    resolver: joiResolver(debtorOtherSchema),
    defaultValues: initialValues,
  })

  useEffect(() => {
    if(!initialValues) return;
    reset(initialValues)
  }, [initialValues])

  const innerSubmit = (data: DebtorOtherFormValues) => {
    onSubmit(data)
  }

  return (
    <form className={styles.formWrapper} onSubmit={handleSubmit(innerSubmit)}>
      <FormInput label="Name" {...register('name')} required error={errors.name?.message} />
      <Controller
        name="phone"
        control={control}
        render={({ field }) => (
          <CustomPhoneInput
            label="Phone"
            error={errors.phone?.message}
            value={field.value}
            onChange={(value) => field.onChange(value.formattedPhone)}
          />
        )}
      />
      <FormInput
        label="Address"
        required
        {...register('address')}
        error={errors.address?.message}
      />
      <FormSelect
        required
        control={control}
        name="call_result"
        label="Call Result"
        options={DEBTOR_CALL_RESULT_OPTIONS}
      />
      <FormTextArea
        label="Call Note"
        {...register('call_note')}
        error={errors.call_note?.message}
      />
      <div className={styles.buttonsWrapper}>
        <SubmitButton buttonStyle="secondary" buttonType="button" onClick={onCancelClick}>
          Cancel
        </SubmitButton>
        <SubmitButton buttonType="submit" loading={loading}>
          Submit
        </SubmitButton>
      </div>
    </form>
  )
}

export default DebtorOtherForm
