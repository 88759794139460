import { BaseModalProps } from "@src/types/component-types"
import { Debtor } from "./debtor.type"

export interface DebtorDetailsNamesFormValues {
  name_first: string
  second_name_first: string | null
  name_last: string
  second_name_last: string | null
  name_middle: string | null
  second_name_middle: string | null
}

export interface DebtorDetailsNamesForm {
  debtorId: number
  initialValues: DebtorDetailsNamesFormValues
}

export enum DebtorDetailsFormTab {
  Names = 'names',
  Emails = 'emails',
  Phone = 'phone',
  Other = 'other',
}

export interface DebtorDetailsFormProps {
  debtor: Debtor
  direction: 'vertical' | 'horizontal'
}

export interface DebtorPhones {
  comms_ph_mobile: string | null
  comms_ph_home: string | null
  comms_ph_work: string | null
  comms_ph_mobile_secondary: string | null
  comms_ph_direct: string | null
  comms_ph_extension: string | null
  comms_ph_fax: string | null
  comms_ph_office: string | null
}

export interface DebtorDetailsPhoneFormValues {
  selectedPhoneNumber: PhoneOption | null
  phones: DebtorPhones
}

export interface DebtorDetailsPhoneFormProps {
  debtorId: number
  debtorContactId: number
  initialValues: DebtorPhones
}

export type PhoneOption = {
  label: string
  value:
  | 'comms_ph_mobile'
  | 'comms_ph_home'
  | 'comms_ph_work'
  | 'comms_ph_mobile_secondary'
  | 'comms_ph_direct'
  | 'comms_ph_extension'
  | 'comms_ph_fax'
  | 'comms_ph_office'
}


export interface DebtorDetailsNamesFormValues {
  name_first: string
  second_name_first: string | null
  name_last: string
  second_name_last: string | null
  name_middle: string | null
  second_name_middle: string | null
}

export interface DebtorDetailsNamesFormProps {
  debtorId: number
  initialValues: DebtorDetailsNamesFormValues
}

export interface DebtorDetailsEmailsFormValues {
  emails: string[]
}

export interface DebtorDetailsEmailsFormProps {
  debtorId: number
  debtorContactId: number
  initialValues: DebtorDetailsEmailsFormValues
}

export interface EditDebtorFormProps {
  debtor: Debtor
  direction?: 'vertical' | 'horizontal'
}

export interface DebtorDetailsOtherFormValues {
  time_created: Date
  date_of_entry: Date | null
  drivers_licence: string | null
  state_drivers_licence: {
    label: string
    value: string
  } | null
  vehicle_reg: string | null
  state_vehicle_licence: {
    label: string
    value: string
  } | null;
}

export interface DebtorDetailsOtherFormProps {
  debtorId: number
  initialValues: DebtorDetailsOtherFormValues
}

export interface EditDebtorModalProps extends BaseModalProps {
  debtorId: number | null
  wipId: number
}