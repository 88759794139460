import { GLOBAL_QUERIES } from "@src/lib/global-queries"
import { useQuery } from "@tanstack/react-query"
import { DebtorAddressHistoryService } from "../services/debtor-address-history.service"
import { Params } from "@src/types/service.type"

export const useGetDebtorAddressHistoryList = (params: Params) => {
  const getDebtorAddressHistoryList = (params: Params = {}) => {
    const debtorAddressHistoryService = new DebtorAddressHistoryService()
    return debtorAddressHistoryService.getDebtorAddressHistory(params)
  }

  const query = useQuery({
    queryKey: [GLOBAL_QUERIES.DEBTOR_ADDRESS_HISTORY.GET, params], queryFn: () => getDebtorAddressHistoryList(params),
    keepPreviousData: true
  })

  return query
}