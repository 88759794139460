import { TaskProps } from '@containers/Setting/components/Contacts/taskboard/types'
import { SERVICES_API_ROUTES } from '@src/lib/global-variables'
import axios from 'axios'
import BaseService from './base-service'

class TaskService extends BaseService {
  private API_URL: string

  constructor() {
    super()
    this.API_URL = SERVICES_API_ROUTES.TASKSERVICE
  }

  async getTasks() {
    return this.withErrorHandling(async () => {
      const response = await axios.get<TaskProps[]>(`${this.API_URL}`, {})
      if (!(response as any).ok) {
        throw new Error(response.statusText)
      }

      return response.data
    })
  }

  async deleteTask(id: string) {
    return this.withErrorHandling(async () => {
      const response = await axios.delete<TaskProps>(`${this.API_URL}/${id}`)

      if (!(response as any).ok) {
        throw new Error(response.statusText)
      }

      return response.data
    })
  }

  async updateTask(task: Partial<TaskProps>) {
    return this.withErrorHandling(async () => {
      const response = await axios.put<TaskProps>(`${this.API_URL}/${task.id}`, task)

      if (!(response as any).ok) {
        throw new Error(response.statusText)
      }

      return response.data
    })
  }

  async createTask(task: any) {
    return this.withErrorHandling(async () => {
      const response = await axios.post<TaskProps>(`${this.API_URL}`, task)

      // if (!(response as any).ok) {
      //   throw new Error(response.statusText)
      // }

      return response.data
    })
  }
}

const newTaskService = new TaskService()
export default newTaskService
