import styles from '../interest-rate-calculator-form.module.scss'
import FormSelect from '@components/inputs/form-select/form-select.component'
import { useForm } from 'react-hook-form'
import FormInput from '@components/inputs/form-input/form-input.component'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { FC, useEffect } from 'react'
import {
  CourtInterestRateCalculatorFormProps,
  CourtInterestRateCalculatorFormValues,
} from '../../../../types/interest-rate.type'
import { CALCULATOR_TYPE_OPTIONS } from '../../../../const/interest-rate.const'
import { joiResolver } from '@hookform/resolvers/joi'
import { courtInterestRateCalculatorSchema } from '../../../../schemas/claim.schemas'

const CourtInterestRateCalculatorForm: FC<CourtInterestRateCalculatorFormProps> = ({
  buttonText = 'Save Rate',
  initialValues,
  loading,
  courtInterestRatesOptions,
  onCalculateTypeChange,
  onSubmit,
  onCancelClick,
}) => {
  const {
    control,
    reset,
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<CourtInterestRateCalculatorFormValues>({
    defaultValues: initialValues,
    resolver: joiResolver(courtInterestRateCalculatorSchema),
  })

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'calculateType' && type === 'change' && value?.calculateType?.value) {
        onCalculateTypeChange(value.calculateType.value)
      }
      if (name === 'state' && type === 'change' && value?.state?.value) {
        const rate = value.state.value.split('-')[0]
        setValue('interestRate', String(Number(rate) * 100))
      }
    })
    return () => subscription.unsubscribe()
  }, [watch])

  useEffect(() => {
    if (!initialValues) return
    reset(initialValues)
  }, [initialValues])

  return (
    <form className={styles.formWrapper} onSubmit={handleSubmit(onSubmit)}>
      <FormSelect
        control={control}
        name="calculateType"
        label="Calculate Type"
        style="outlined"
        options={CALCULATOR_TYPE_OPTIONS}
      />
      <FormSelect
          control={control}
          name="state"
          label="State"
          style="outlined"
          options={courtInterestRatesOptions}
        />
      <FormInput
        {...register('interestRate')}
        error={errors.interestRate?.message}
        label="Interest Rate %"
        placeholder="0.00"
        inputStyle="outlined"
        required
      />
      <div className={styles.actionWrapper}>
        <SubmitButton buttonStyle="secondary" buttonType="button" onClick={onCancelClick}>
          Cancel
        </SubmitButton>
        <SubmitButton loading={loading} buttonType="submit">
          {buttonText}
        </SubmitButton>
      </div>
    </form>
  )
}

export default CourtInterestRateCalculatorForm
