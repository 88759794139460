import { CreateDebtorWorkPlaceFormProps, DebtorWorkPlaceFormValues } from '@containers/Recovery/Wip/_modules/Debtors/types/debtor-associate-detail.type'
import { toast } from 'react-toastify'
import { FC } from 'react'
import DebtorWorkPlaceForm from './debtor-work-place-form.component'
import { useCreateDebtorWorkPlaceMutation } from '@containers/Recovery/Wip/_modules/Debtors/hooks/mutations/crud-debtor-work-place.hook'

const CreateDebtorWorkPlaceForm: FC<CreateDebtorWorkPlaceFormProps> = ({
  debtorId,
  onSuccessSubmit,
  onCancelClick,
}) => {
  const { mutateAsync, isLoading } = useCreateDebtorWorkPlaceMutation()

  const handleSubmit = async (data: DebtorWorkPlaceFormValues) => {
    try {
      const debtorWorkPlace = await mutateAsync({
        ...data,
        debtor_id: debtorId,
        call_result: data.call_result.value,
      })
      onSuccessSubmit && onSuccessSubmit(debtorWorkPlace)
      toast.success('Created')
    } catch (e) {
      toast.error('Something went wrong')
      console.log(e)
    }
  }

  return (
    <DebtorWorkPlaceForm
      onSubmit={handleSubmit}
      onCancelClick={onCancelClick}
      loading={isLoading}
    />
  )
}

export default CreateDebtorWorkPlaceForm
