import * as React from "react"

const EmailIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={200}
    height={200}
    viewBox="0 0 512 512"
    {...props}
  >
    <path
      d="M510.746 110.361c-2.128-10.754-6.926-20.918-13.926-29.463-1.422-1.794-2.909-3.39-4.535-5.009-12.454-12.52-29.778-19.701-47.531-19.701H67.244c-17.951 0-34.834 7-47.539 19.708-1.608 1.604-3.099 3.216-4.575 5.067-6.97 8.509-11.747 18.659-13.824 29.428A64.963 64.963 0 0 0 0 123.435v265.137c0 9.224 1.874 18.206 5.589 26.745 3.215 7.583 8.093 14.772 14.112 20.788a75.763 75.763 0 0 0 4.63 4.258c12.034 9.966 27.272 15.45 42.913 15.45h377.51c15.742 0 30.965-5.505 42.967-15.56a58.23 58.23 0 0 0 4.578-4.148c5.818-5.812 10.442-12.49 13.766-19.854l.438-1.05c3.646-8.377 5.497-17.33 5.497-26.628V123.435c0-4.375-.422-8.786-1.254-13.074zM34.823 99.104c.951-1.392 2.165-2.821 3.714-4.382 7.689-7.685 17.886-11.914 28.706-11.914h377.51c10.915 0 21.115 4.236 28.719 11.929a40.528 40.528 0 0 1 3.661 4.272l2.887 3.88-201.5 175.616c-6.212 5.446-14.21 8.443-22.523 8.443-8.231 0-16.222-2.99-22.508-8.436L32.19 102.939l2.633-3.835zm-8.068 291.809c-.109-.722-.134-1.524-.134-2.341V128.925l156.37 136.411L28.199 400.297l-1.444-9.384zm438.144 32.927c-6.052 3.492-13.022 5.344-20.145 5.344H67.244c-7.127 0-14.094-1.852-20.142-5.344l-6.328-3.668L200.71 280.793l17.528 15.246c10.514 9.128 23.922 14.16 37.761 14.16 13.89 0 27.32-5.032 37.827-14.16l17.521-15.253L471.228 420.18l-6.329 3.66zm20.473-35.268c0 .803-.015 1.597-.116 2.304l-1.386 9.472-154.858-134.939 156.36-136.418v259.581z"
      style={{
        fill: "#000",
      }}
    />
  </svg>
)
export default EmailIcon
