import styles from './slide-modal.module.scss'
import ReactDOM from 'react-dom'
import { useEffect, useState } from 'react'
import cls from 'classnames'

interface ModalProps {
  className?: string
  style?: React.CSSProperties
  layoutStyle?: React.CSSProperties
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
  isLayoutOverlap?: boolean
}

const SlideModal = ({
  children,
  isOpen = false,
  onClose = () => null,
  isLayoutOverlap = false,
  className,
  style,
  layoutStyle
}: ModalProps) => {
  const [innerIsOpen, setInnerIsOpen] = useState(isOpen)

  useEffect(() => {
    if (isOpen) {
      setInnerIsOpen(true)
    } else {
      close()
    }
  }, [isOpen])

  useEffect(() => {
    if (typeof window === 'undefined') return

    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      close()
      document.body.style.overflow = 'unset'
    }
  }, [innerIsOpen])
  const [modalClass, setModalClass] = useState(cls(styles.slideModal))

  const close = () => {
    setModalClass(cls(styles.slideModal, styles.closing))
    setTimeout(() => {
      onClose()
      setInnerIsOpen(false)
      setModalClass(cls(styles.slideModal))
    }, 275)
  }

  const handleLayoutClose = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    close()
  }

  useEffect(() => {
    if (typeof window === 'undefined') return

    addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        close()
      }
    })

    return () => {
      removeEventListener('keydown', (e) => {
        if (e.key === 'Escape') {
          close()
        }
      })
    }
  }, [])

  const renderModal = () => {
    return (
      <>
        <div className={cls({ [styles.layout]: !isLayoutOverlap })} style={layoutStyle} onClick={handleLayoutClose} />
        <div
          style={style}
          className={cls(modalClass, className)}
          onClick={(e) => e.stopPropagation()}
        >
          {children}
        </div>
      </>
    )
  }

  return innerIsOpen ? ReactDOM.createPortal(renderModal(), document.body) : null
}

export default SlideModal
