import { LandlordContact, Property } from '@containers/Sales/Properties/types/property.type'
import styles from './landlord-contact-list.module.scss'
import { FC, useState } from 'react'
import SubSection from '@containers/Recovery/Wip/components/UI/sub-section/sub-section.component'
import { SubHeader, Text } from '@containers/Recovery/Wip/components/UI/text/text.component'
import TrashIcon from '@src/icons/trash'
import EditIcon from '@src/icons/edit'
import { useDeleteLandlordContactMutation } from '@containers/Sales/Landlord/hooks/crud-landlord-contact.hook'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import { getPropertyByIdKey } from '../../../utils/client-property.utils'
import { GET_CLIENT_PROPERTIES_PARAMS } from '@containers/Sales/Client/const/client.const'
import { useModal } from '@src/hooks/useModal'
import EditLandlordContactModal from '../../modals/landlord-contact-modal/edit-landlord-contact-modal.component'

interface LandlordContactListProps {
  propertyId: number
  contacts: LandlordContact[]
}

interface LandlordContactItem {
  propertyId: number
  contact: LandlordContact
  onEditClick: (contact: LandlordContact) => void
}

const LandlordContactItem: FC<LandlordContactItem> = ({ contact, propertyId, onEditClick }) => {
  const { mutateAsync } = useDeleteLandlordContactMutation()
  const queryClient = useQueryClient()

  const handleDelete = () => {
    try {
      mutateAsync({
        contactId: contact.id,
        landlordId: contact.landlordId,
      })
      queryClient.setQueryData<Property>(
        getPropertyByIdKey(propertyId, GET_CLIENT_PROPERTIES_PARAMS),
        (prevData) =>
          prevData
            ? {
                ...prevData,
                landlord: prevData.landlord
                  ? {
                      ...prevData.landlord,
                      contacts: prevData.landlord.contacts.filter((c) => c.id !== contact.id),
                    }
                  : null,
              }
            : undefined,
      )
      toast.success('Owner deleted successfully')
    } catch (e) {
      toast.error('Failed to delete landlord contact')
      console.log(e)
    }
  }
  return (
    <SubSection className={styles.contactItemWrapper} p={4}>
      <div className={styles.contactInformation}>
        <div className={styles.row}>
          <div className={styles.row}>
            <SubHeader>Owner Name</SubHeader>
            <Text>{contact.fullName}</Text>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.row}>
            <SubHeader>Phone</SubHeader>
            <Text>
              {contact.mobilePhone || contact.landlinePhone || contact.faxNumber || 'Not Found'}
            </Text>
          </div>
          <div className={styles.row}>
            <SubHeader>Email</SubHeader>
            <Text>{contact.email || 'Not Found'}</Text>
          </div>
        </div>
      </div>
      <div className={styles.contactActions}>
        <button className={styles.actionButton} onClick={handleDelete}>
          <TrashIcon />
        </button>
        <button className={styles.actionButton} onClick={() => onEditClick(contact)}>
          <EditIcon />
        </button>
      </div>
    </SubSection>
  )
}

const LandlordContactList: FC<LandlordContactListProps> = ({ contacts, propertyId }) => {
  const queryClient = useQueryClient()
  const [isEditModalOpen, handleEditModalOpen, handleEditModalClose] = useModal(false)
  const [selectedContact, setSelectedContact] = useState<LandlordContact | null>(null)

  const handleEditClick = (contact: LandlordContact) => {
    setSelectedContact(contact)
    handleEditModalOpen()
  }

  const handleSuccessSubmit = (contact: LandlordContact) => {
    queryClient.setQueryData<Property>(
      getPropertyByIdKey(propertyId, GET_CLIENT_PROPERTIES_PARAMS),
      (prevData) =>
        prevData
          ? {
              ...prevData,
              landlord: prevData.landlord
                ? {
                    ...prevData.landlord,
                    contacts: prevData.landlord?.contacts.map((c) =>
                      c.id === contact.id ? contact : c,
                    ),
                  }
                : null,
            }
          : undefined,
    )
    toast.success('Owner updated successfully')
  }

  return (
    <div className={styles.listWrapper}>
      {contacts.map((contact) => (
        <LandlordContactItem
          key={`landlord-contact-${contact.id}`}
          onEditClick={handleEditClick}
          contact={contact}
          propertyId={propertyId}
        />
      ))}
      {selectedContact && (
        <EditLandlordContactModal
          entity={selectedContact}
          onSuccessSubmit={handleSuccessSubmit}
          isOpen={isEditModalOpen}
          onClose={handleEditModalClose}
        />
      )}
    </div>
  )
}

export default LandlordContactList
