import React, { useState } from 'react';
import Svg from '../../Contacts/WIPs/assets/Svg';
import DebtorOverview from './Overview/DebtorOverview';
import Addresses from './CSAddresses/Addresses'
import AssociateDetail from './AssociateDetail/AssociateDetail';
import AddressHistory from './AddressHistory/AddressHistory';
import SearchDatabases from './SearchDatabases/SearchDatabases';
import NotesDebtor from './Notes/NotesDebtor';


const DetailDebtor = ({dataDebtor,getDebtor,debtorNotes,total,page,rowsPerPage,handleChangePage,handleChangeRowsPerPage,addNote, getDebtorNotes}) => {

  const [notesDropDown, setNotesDropDown] = useState(true);
  const [overviewDropDown, setOverviewDropDown] = useState(true);
  const [associatedDropDown, setAssociatedDropDown] = useState(true);
  const [CSAddressesDropDown, setCSAddressesDropDown] = useState(true);
  const [addressHistoryDropDown, setAddressHistoryDropDown] = useState(true);
  const [searchDatabasesDropDown, setSearchDatabasesDropDown] = useState(true)

  return (
    <div className='w-100 pb-5'>
      <div id='contacts_accordion' className='form'>
        <div className='card pb-0'>

          {/*-------------------Notes-------------------------*/}

          <div id='collapseOne' className='collapse show' data-parent='#contacts_accordion'>
            <div className='slide_down_wrap mb-4 rounded'>
              <div className=' card-body bg-white pl-0 rounded'>
                <div
                  className={`slide_drop_down boxshadow-none cursor-pointer d-flex flex-wrap align-items-center justify-content-between rounded ${overviewDropDown ? 'active' : ''}`}>
                  <div onClick={() => setNotesDropDown(!notesDropDown)} className='d-flex align-items-center'>
                    <h3 className='page-title mb-0 mr-4' data-toggle='collapse'> Notes </h3>
                    <hr />
                  </div>
                  <Svg onClick={() => setNotesDropDown(!notesDropDown)} />
                </div>
                <div className={`bg-white hide_contact ${notesDropDown ? 'active pt-4' : ''}`}>
                  {debtorNotes && <NotesDebtor debtorNotes={debtorNotes}
                                               total={total}
                                               page={page}
                                               rowsPerPage={rowsPerPage}
                                               handleChangePage={handleChangePage}
                                               handleChangeRowsPerPage={handleChangeRowsPerPage}
                                               addNote={addNote}
                  />}
                </div>
              </div>
            </div>
          </div>

          {/*-------------------Overview-------------------------*/}

          <div id='collapseOne' className='collapse show' data-parent='#contacts_accordion'>
            <div className='slide_down_wrap mb-4 rounded'>
              <div className=' card-body bg-white pl-0 rounded'>
                <div
                  className={`slide_drop_down boxshadow-none cursor-pointer d-flex flex-wrap align-items-center justify-content-between rounded ${overviewDropDown ? 'active' : ''}`}>
                  <div onClick={() => setOverviewDropDown(!overviewDropDown)} className='d-flex align-items-center'>
                    <h3 className='page-title mb-0 mr-4' data-toggle='collapse'> Overview </h3>
                    <hr />
                  </div>
                  <Svg onClick={() => setOverviewDropDown(!overviewDropDown)} />
                </div>
                <div className={`bg-white hide_contact ${overviewDropDown ? 'active pt-4' : ''}`}>
                  <DebtorOverview getDebtor={getDebtor} dataDebtor={dataDebtor} />
                </div>
              </div>
            </div>
          </div>

          {/*-------------------Current & Service Addresses-------------------------*/}

          <div className='slide_down_wrap mb-4 rounded'>
            <div className=' card-body bg-white pl-0 rounded'>
              <div
                className={`slide_drop_down boxshadow-none cursor-pointer d-flex flex-wrap align-items-center justify-content-between rounded ${CSAddressesDropDown ? 'active' : ''}`}>
                <div onClick={() => setCSAddressesDropDown(!CSAddressesDropDown)} className='d-flex align-items-center'>
                  <h3 className='page-title mb-0 mr-4' data-toggle='collapse'> Current & Service Addresses </h3>
                </div>
                <Svg onClick={() => setCSAddressesDropDown(!CSAddressesDropDown)} />
              </div>
              <div className={`bg-white hide_contact ${CSAddressesDropDown ? 'active pt-4' : ''}`}>
                <Addresses getDebtor={getDebtor} dataDebtor={dataDebtor} />
              </div>
            </div>
          </div>

          {/*-------------------Search Databases-------------------------*/}

          <div className='slide_down_wrap mb-4 rounded'>
            <div className=' card-body bg-white pl-0 rounded'>
              <div
                className={`slide_drop_down boxshadow-none cursor-pointer d-flex flex-wrap align-items-center justify-content-between rounded ${searchDatabasesDropDown ? 'active' : ''}`}>
                <div onClick={() => setSearchDatabasesDropDown(!searchDatabasesDropDown)} className='d-flex align-items-center'>
                  <h3 className='page-title mb-0 mr-4' data-toggle='collapse'> Search Databases & Notes </h3>
                </div>
                <Svg onClick={() => setSearchDatabasesDropDown(!searchDatabasesDropDown)} />
              </div>
              <div className={`bg-white hide_contact ${searchDatabasesDropDown ? 'active pt-4' : ''}`}>
                <SearchDatabases getDebtor={getDebtor} dataDebtor={dataDebtor} />
              </div>
            </div>
          </div>

          {/*-------------------Associate Detail-------------------------*/}

          <div className='slide_down_wrap mb-4 rounded'>
            <div className=' card-body bg-white pl-0 rounded'>
              <div
                className={`slide_drop_down boxshadow-none cursor-pointer d-flex flex-wrap align-items-center justify-content-between rounded ${associatedDropDown ? 'active' : ''}`}>
                <div onClick={() => setAssociatedDropDown(!associatedDropDown)} className='d-flex align-items-center'>
                  <h3 className='page-title mb-0 mr-4' data-toggle='collapse'> Associate Details </h3>
                </div>
                <Svg onClick={() => setAssociatedDropDown(!associatedDropDown)} />
              </div>
              <div className={`bg-white hide_contact ${associatedDropDown ? 'active pt-4' : ''}`}>
                 <AssociateDetail getDebtor={getDebtor} dataDebtor={dataDebtor} getDebtorNotes={getDebtorNotes}/>
              </div>
            </div>
          </div>

          {/*-------------------Address History-------------------------*/}

          <div className='slide_down_wrap mb-4 rounded'>
            <div className=' card-body bg-white pl-0 rounded'>
              <div
                className={`slide_drop_down boxshadow-none cursor-pointer d-flex flex-wrap align-items-center justify-content-between rounded ${addressHistoryDropDown ? 'active' : ''}`}>
                <div onClick={() => setAddressHistoryDropDown(!addressHistoryDropDown)}
                     className='d-flex align-items-center'>
                  <h3 className='page-title mb-0 mr-4' data-toggle='collapse'> Address History </h3>
                </div>
                <Svg onClick={() => setAddressHistoryDropDown(!addressHistoryDropDown)} />
              </div>
              <div className={`bg-white hide_contact ${addressHistoryDropDown ? 'active pt-4' : ''}`}>
                 <AddressHistory getDebtor={getDebtor} dataDebtor={dataDebtor} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailDebtor;
