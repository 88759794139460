import React, { useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import Select from 'react-select';
import WIPService from '../../services/WIPService';
import { compileTemplate } from '../Form/RecoveryForm/utils';
import { getSmsTypes } from '../Form/RecoveryForm/service';

const repeatTypeOption = [
  { value: '0', label: 'None' },
  { value: '1', label: 'Daily' },
  { value: '2', label: 'Weekly' },
  { value: '3', label: 'Monthly (by Day)' },
  { value: '4', label: 'Monthly (by Date)' },
  { value: '5', label: 'Yearly' },
];

const statusOption = [
  { value: 'client', label: 'Client' },
  { value: 'debtors', label: 'Debtors' },
];

const SetSMSTaskManager = (props) => {

  const [smsTemplates, setSmsTemplates] = useState([]);
  const [recoveryData, setRecoveryData] = useState(null);
  const [recipientsOptionDebtor, setRecipientsOptionDebtor] = useState([]);
  const [recipientsOptionClient, setRecipientsOptionClient] = useState([]);
  const [valueTo, setValueTo] = useState('');
  const [companyId, setCompanyId] = useState({});
  const [debtorId, setDebtorId] = useState(null);
  const [repeatType, setRepeatType] = useState({});
  const [tempOption, setTempOption] = useState([])


  const wip = new WIPService();

  useEffect(() => {

    getWIPbyId(props.wipID || +localStorage.getItem('wipId'));
    getSmsTypes()
      .then((res) => {
        setSmsTemplates(res.data.data);
         const option = res.data.data.map((i) => ({
          value: i.id,
          label: i.sms_template_title,
        }))
        setTempOption(option)

      })
      .catch((e) => console.log(e));

    if (props?.setSMS) {
      if (props.setSMS.to === 'client' ) {
        setValueTo('client');
      } else{
        setValueTo('debtors');
      }
      const rep = repeatTypeOption.find(e => e.label === props.setSMS?.repeatSMS);
      setRepeatType(rep);
    }

  }, []);

  const getWIPbyId = async (id) => {
    try {
      const params = {
        wip_id: id,
        join: ['debtors', 'debtors.contact', 'company'],
      };
      const list = await wip.getWipsId(params);
      const getDataDebtor = list.data.debtors[0].contact;
      const getDataClient = list.data.owner_details;
      let client = [
        { value: getDataClient.phone, label: getDataClient.phone },
      ];
      let debtor = [
        { value: getDataDebtor.comms_ph_mobile, label: getDataDebtor.comms_ph_mobile },
        { value: getDataDebtor.comms_ph_mobile_secondary, label: getDataDebtor.comms_ph_mobile_secondary },
      ];
      setRecipientsOptionDebtor(debtor);
      setRecipientsOptionClient(client);
      setCompanyId(list.data.company.id);
      setDebtorId(list.data.debtors[0].id);
    } catch {
      console.log('Error');
    }

  };


  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      client: props.setSMS?.client,
      sms_status_scheduled: props?.setSMS?.sms_status_scheduled? { value: true, label: 'Yes' } : {
        value: false,
        label: 'No',
      },
      debtors: props.setSMS?.debtors,
      sms_time_scheduled: props.setSMS.sms_time_scheduled,
      sms_status_time_scheduled: props.setSMS?.sms_status_time_scheduled,
      to: statusOption.find((e) => e.value === props.setSMS?.status),
      short_message_body: props.setSMS?.short_message_body,
      type: props.tempSMS,
    },
  });

  const shouldBeScheduled = useWatch({
    control,
    name: 'sms_status_scheduled',
    defaultValue: props?.setSMS?.sms_status_scheduled ? { value: true, label: 'Yes' } : { value: false,label: 'No'},
  });

  const status = useWatch({
    control,
    name: 'to',
    defaultValue: props?.setSMS?.status === "client" ? { value: 'client', label: 'Client' } : props?.setSMS?.status === 'debtors'? { value: 'debtors', label: 'Debtor' } : "",
  });


  const saveSMS = (formData) => {

    const { to, ...rest } = formData;

    if (status.value === 'client') {
      const data = {
        to: formData.client.map((e) => e.value),
        text: rest.short_message_body,
        debtor_id: debtorId,
        company_id: companyId,
        wip_id: props.wipID || +localStorage.getItem('wipId'),
        urf_id: null,
        task_id: null,
        status: 'client',
        sms_status_scheduled: formData.sms_status_scheduled,
        short_message_body: formData.short_message_body,
        type: formData.type,
        client: formData.client,
      };
      if (rest.sms_status_scheduled?.value ) {
        data.repeatSMS = repeatType.label;
        data.sms_status_time_scheduled = formData.sms_status_time_scheduled;
      }

      props.handleChangeSetSMS(data, props.valueTab);

    } else if (status.value === 'debtors') {
      const data = {
        to: formData.debtors.map((e) => e.value),
        text: rest.short_message_body,
        debtor_id: debtorId,
        company_id: companyId,
        wip_id: props.wipID || +localStorage.getItem('wipId'),
        urf_id: null,
        task_id: null,
        status: 'debtors',
        short_message_body: formData.short_message_body,
        type: formData.type,
        debtors: formData.debtors,
        sms_status_scheduled: formData.sms_status_scheduled,
      };
      if (rest.sms_status_scheduled?.value) {
        data.repeatSMS = repeatType.label;
        data.sms_status_time_scheduled = formData.sms_status_time_scheduled;
      }

      props.handleChangeSetSMS(data, props.valueTab);

    } else {
      props.handleChangeSetSMS({}, props.valueTab);
    }
  };

  const watchTemplate = (value, { name }) => {
    if (name === 'type') {
      const index = smsTemplates?.findIndex((i) => i.id === value?.type.value);
      if (index !== -1) {
        setValue(
          'short_message_body',
          compileTemplate(smsTemplates[index].sms_template_body, recoveryData),
        );
      }
    }
  };

  useEffect(() => {
    const subscription = watch(watchTemplate);
    return () => subscription.unsubscribe();
  }, [watch, smsTemplates]);

  return (
    <>
      <div>
        <h3 style={{ color: 'black' }} className='form__form-group'>Set your SMS</h3>
      </div>

      <form onSubmit={handleSubmit(saveSMS)}>
        <Row className='align-items-center'>
          <Col md={valueTo ? 6 : 12}>
            <div className='form-group form text-left'>
              <label className='form__form-group-label' htmlFor=''>To <span
                style={{ color: '#ff0000' }}>*</span></label> {errors.to &&
            <span style={{ marginRight: '15px' }} className='form__form-group-error'>To is required</span>}
              <Controller
                control={control}
                name='to'
                render={({ field }) => (
                  <Select
                    {...field}
                    options={statusOption}
                    classNamePrefix='react-select'
                    className='react-select'
                  />
                )}
              />
            </div>
          </Col>
          <Col md={6}>
            {status.value === 'client' && <div className='form-group form text-left'>
              <label className='form__form-group-label' htmlFor=''>Recipients<span
                style={{ color: '#ff0000' }}>*</span></label>
              <Controller
                control={control}
                name='client'
                rules={{ required: !!valueTo }}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={recipientsOptionClient}
                    classNamePrefix='react-select'
                    className='react-select'
                    isMulti
                    placeholder={'Select recipients'}
                  />
                )}
              />
              {errors.client &&
              <span style={{ marginTop: '-2px' }} className='form__form-group-error'>To is required</span>}
            </div>}
            {status.value === 'debtors' && <div className='form-group form text-left'>
              <label className='form__form-group-label' htmlFor=''>Recipients<span style={{ color: '#ff0000' }}>*</span></label>
              <Controller
                control={control}
                name='debtors'
                rules={{ required: !!valueTo }}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={recipientsOptionDebtor}
                    classNamePrefix='react-select'
                    className='react-select'
                    isMulti
                    placeholder={'Select recipients'}
                  />
                )}
              />
              {errors.debtors &&
              <span style={{ marginTop: '-2px' }} className='form__form-group-error'>To is required</span>}
            </div>}
          </Col>
          <Col md={12}>
            <div className='form-group form'>
              <label className='form__form-group-label text-left' htmlFor=''>Template<span
                style={{ color: '#ff0000' }} /></label>
              {errors.to &&
              <span style={{ marginTop: '-2px' }} className='form__form-group-error'>Type is required</span>}
              <Controller
                control={control}
                name='type'
                render={({ field }) => (
                  <Select
                    {...field}
                    options={tempOption}
                    classNamePrefix='react-select'
                    className='react-select'
                  />
                )}
              />
            </div>
          </Col>
        </Row>

        <div className='row'>
          <div className='col-12 '>
            <div className='form-group form'>
              <span className='form__form-group-label'>Message<span style={{ color: '#ff0000' }}>*</span></span>
              {errors.short_message_body && (
                <span style={{ marginBottom: '2px' }} className='form__form-group-error'>Message is required</span>
              )}
              <textarea
                name='short_message_body'
                {...register('short_message_body', { required: !!status.value  })}
                className='custom-input'
              />
            </div>
          </div>
        </div>

        <Row>
          <Col md={12}>
            <div className='form__form-group'>
              <span className='form__form-group-label'>Schedule SMS</span>
              <div className='form__form-group-field'>
                <Controller
                  control={control}
                  name='sms_status_scheduled'
                  render={({ field }) => (
                    <Select
                      {...field}
                      defaultValue={props?.setSMS?.sms_status_scheduled ? { value: true, label: 'Yes' } : {
                        value: false,
                        label: 'No',
                      } }
                      options={[
                        { value: true, label: 'Yes' },
                        { value: false, label: 'No' },
                      ]}
                      classNamePrefix='react-select'
                      className='react-select'
                    />
                  )}
                />
              </div>
            </div>
          </Col>
        </Row>
        {shouldBeScheduled.value && (
          <Row>
            <Col md={6}>
              <div className='form__form-group'>
                <span className='form__form-group-label'>Schedule Date</span>
                <div className='form__form-group'>
                  <div className='form form--horizontal'>
                    <input
                      name='sms_status_time_scheduled'
                      placeholder='date'
                      type='datetime-local'
                      {...register('sms_status_time_scheduled')}
                    />
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className='form-group'>
                <label className='form__form-group-label' htmlFor=''> Repeat type </label>
                <Select
                  name='rType'
                  type='text'
                  options={repeatTypeOption}
                  classNamePrefix='react-select'
                  className='react-select'
                  value={repeatType}
                  onChange={(e) => setRepeatType(e)}
                />
              </div>
            </Col>
          </Row>
        )}
        <hr />
        <div className={'button_group_custom_email'}>
          <button className='btn-gray mr-3 mb-2' type='button' onClick={props.closeModal}>Cancel</button>
          <button type='submit' style={{ padding: '9px 30px 11px 30px' }} className='btn btn-secondary m-0 mb-0'
          >Next
          </button>
        </div>
      </form>
    </>
  );
};

export default SetSMSTaskManager;
