import Section from '@components/section/section.component'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import DebtorRelationTable from '../../tables/debtor-relation-table/debtor-relation-table.component'
import {
  DebtorRelation,
  DebtorRelationsSectionProps,
} from '@containers/Recovery/Wip/_modules/Debtors/types/debtor-associate-detail.type'
import SubSection from '@containers/Recovery/Wip/components/UI/sub-section/sub-section.component'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import styles from './debtor-relations-section.module.scss'
import CreateDebtorRelationModal from '../../modals/debtor-relation-modal/create-debtor-relation-modal.component'
import { useModal } from '@src/hooks/useModal'
import { FC, useState } from 'react'
import EditDebtorRelationModal from '../../modals/debtor-relation-modal/edit-debtor-relation-modal.component'

const DebtorRelationsSection: FC<DebtorRelationsSectionProps> = ({ relations, debtorId }) => {
  const [isOpen, handleOpen, handleClose] = useModal(false)
  const [isEditModalOpen, handleEditModalOpen, handleEditModalClose] = useModal(false)
  const [selectedDebtorRelation, setSelectedDebtorRelation] = useState<DebtorRelation | null>(null)

  const handleItemClick = (relation: DebtorRelation) => {
    setSelectedDebtorRelation(relation)
    handleEditModalOpen()
  }

  return (
    <Section>
      <SubSection className={styles.headerWrapper} p={4} mb={4}>
        <Header>List Relations</Header>
        <SubmitButton buttonType="button" onClick={handleOpen}>
          Add Relation
        </SubmitButton>
      </SubSection>
      <DebtorRelationTable
        debtorId={debtorId}
        relations={relations}
        onItemClick={handleItemClick}
      />
      <CreateDebtorRelationModal debtorId={debtorId} isOpen={isOpen} onClose={handleClose} />
      {selectedDebtorRelation && (
        <EditDebtorRelationModal
          debtorId={debtorId}
          debtorRelation={selectedDebtorRelation}
          isOpen={isEditModalOpen}
          onClose={handleEditModalClose}
        />
      )}
    </Section>
  )
}

export default DebtorRelationsSection
