import Modal from '@components/modal'
import { FC } from 'react'
import styles from './debtor-work-place-modal.module.scss'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import { useQueryClient } from '@tanstack/react-query'
import {
  CreateDebtorAssociateDetailModalProps,
  DebtorWorkPlace,
} from '@containers/Recovery/Wip/_modules/Debtors/types/debtor-associate-detail.type'
import { getDebtorQueryKey } from '@containers/Recovery/Wip/_modules/Debtors/utils/debtor-query-key-factory.utils'
import { Debtor } from '@containers/Recovery/Wip/_modules/Debtors/types/debtor.type'
import CreateDebtorWorkPlaceForm from '../../forms/debtor-work-place-form/create-debtor-work-place-form.component'

const CreateDebtorWorkPlaceModal: FC<CreateDebtorAssociateDetailModalProps> = ({
  debtorId,
  ...modalProps
}) => {
  const queryClient = useQueryClient()

  const handleSuccessSubmit = (workPlace: DebtorWorkPlace) => {
    queryClient.setQueryData<Debtor>(getDebtorQueryKey(debtorId), (prevData) =>
      prevData ? { ...prevData, work_places: [...prevData.work_places, workPlace] } : undefined,
    )
    modalProps.onClose()
  }

  return (
    <Modal className={styles.modalWrapper} {...modalProps} withBtns={false} classNameLayout={styles.layout}>
      <Header mb={4}>Add Work Place</Header>
      <CreateDebtorWorkPlaceForm
        debtorId={debtorId}
        onSuccessSubmit={handleSuccessSubmit}
        onCancelClick={modalProps.onClose}
      />
    </Modal>
  )
}

export default CreateDebtorWorkPlaceModal
