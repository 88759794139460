import Section from '@components/section/section.component'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import { Wip, WipAttachment } from '@containers/Recovery/Wip/types/wip.type'
import { FC, useState } from 'react'
import AttachmentList from '../../tables/attachment-table/attachment-table.component'
import CreateAttachmentForm from '../../forms/attachment-form/create-attachment-form.component'
import { useQueryClient } from '@tanstack/react-query'
import { getWipQueryKey } from '@containers/Recovery/Wip/utils/wip-query-factory'
import { toast } from 'react-toastify'
import { AttachmentsSectionProps } from '../../../types/sections-props.type'
import { AttachmentFormValues } from '../../../types/wip-overview-forms.type'

const AttachmentsSection: FC<AttachmentsSectionProps> = ({ attachments, wipId }) => {
  const [initialValues, setInitialValues] = useState<AttachmentFormValues | undefined>(undefined)
  const queryClient = useQueryClient()

  const handleSuccessSubmit = (data: WipAttachment[]) => {
    queryClient.setQueryData<Wip>(getWipQueryKey(wipId), (prevData) =>
      prevData
        ? {
            ...prevData,
            attachments: [...prevData.attachments, ...data],
          }
        : undefined,
    )
    setInitialValues({ files: [] })
    toast.success('Files uploaded successfully')
  }

  return (
    <Section>
      <Header mb={4}>Attachments</Header>
      <CreateAttachmentForm
        wipId={wipId}
        onSuccessSubmit={handleSuccessSubmit}
        initialValues={initialValues}
      >
        <AttachmentList entities={attachments} />
      </CreateAttachmentForm>
    </Section>
  )
}

export default AttachmentsSection
