import { Params } from "@src/types/service.type"
import { RecoverableCostService } from "../services/recoverable-cost.service"
import { GLOBAL_QUERIES } from "@src/lib/global-queries"
import { useQuery } from "@tanstack/react-query"

export const useGetRecoverableCostList = (params: Params = {}) => {
  const getRecoverableCostList = (params: Params = {}) => {
    const recoverableCostService = new RecoverableCostService()
    return recoverableCostService.getRecoveredCosts(params)
  }


  const query = useQuery({
    queryKey: [GLOBAL_QUERIES.RECOVERABLE_COST.GET, params],
    queryFn: () => getRecoverableCostList(params),
  })

  return query;
}