import * as React from "react"
const ReplyIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="currentColor"
    className="YIUegm7fh_CpJbivTu6B MnxxlQlR1H0xJuMEE8Yr"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M3 10h10a8 8 0 0 1 8 8v2M3 10l6 6m-6-6 6-6"
    />
  </svg>
)
export default ReplyIcon
