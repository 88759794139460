import { useGetWipTemplate } from '@containers/Form/WipTemplateForm/hooks/get-wip-template.hook'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { GET_WIP_TEMPLATES_PARAMS_BOARD } from './const/wip-template-board.const'
import { IOption } from '../taskboard/types'
import styles from './wip-template-board.module.scss'
import { DragDropContext } from 'react-beautiful-dnd'
import Column from '../taskboard/column'
import CreateColumnModal from '../taskboard/modals/create-column-modal'
import { WipTemplatesService } from '@containers/Form/WipTemplateForm/services/wip-templates.service'
import { RawCreateWipTemplateColumn } from '@containers/Form/WipTemplateForm/types/wip-templates-service.type'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import CreateAutomationModal from '../automations/modals/create-automation-modal'
import { useTaskContext } from '@src/context/task-context'
import { useGetAutomationByColumns } from './hooks/get-automations-buy-columns.hook'

const wipTemplatesService = new WipTemplatesService()

const WipTemplateBoard = () => {
  const location = useLocation()
  const history = useHistory()
  const [isCreateColumnModalOpen, setIsCreateColumnModalOpen] = useState<boolean>(false)
  const [isCreateAutomationModalOpen, setIsCreateAutomationModalOpen] = useState<boolean>(false)
  const [wipTemplateId, setWipTemplateId] = useState<number | null>(null)
  const queryClient = useQueryClient()
  const { data: wipTemplate } = useGetWipTemplate(wipTemplateId, !!wipTemplateId, GET_WIP_TEMPLATES_PARAMS_BOARD, () => {
    history.push('/recoveries/forms/wip-template')
  })
  const { setMode, setCanSelectContact, setExternalAutomationOptions } = useTaskContext()

  const {
    data: columnsWithAutomation,
    isLoading,
    isRefetching,
  } = useGetAutomationByColumns(wipTemplate?.columns)

  const checkIsIdValid = (): number | null => {
    const id = location.pathname.split('/').at(-1)
    const numericId = Number(id)
    if (Number.isNaN(numericId)) {
      history.push('/recoveries/forms/wip-template')
      return null
    }
    return numericId
  }

  useEffect(() => {
    setMode('RECOVERY')
    setCanSelectContact(false);
    setExternalAutomationOptions([])
    return () => {
      setCanSelectContact(true)
      setExternalAutomationOptions(null)
    }
  }, [])

  useEffect(() => {
    const id = checkIsIdValid()
    if (!id) {
      return
    }
    setWipTemplateId(id)
  }, [location])

  useEffect(() => {
    if(columnsWithAutomation){
      const automationOptions: IOption[] | undefined = columnsWithAutomation.map((column) => ({
        label: column.title,
        value: column.id,
      })) as unknown as IOption[]
      setExternalAutomationOptions(automationOptions)
    }
  }, [columnsWithAutomation])

  // modals
  const handleCreateColumnModalOpen = () => {
    setIsCreateColumnModalOpen(true)
  }

  const handleCreateColumnModalClose = () => {
    setIsCreateColumnModalOpen(false)
  }

  const handleCreateAutomationModalOpen = () => {
    setIsCreateAutomationModalOpen(true)
  }

  const handleCreateAutomationModalClose = () => {
    setIsCreateAutomationModalOpen(false)
  }

  const handleCreateColumnModalSubmit = async (
    createColumnModalData: RawCreateWipTemplateColumn,
  ) => {
    if (!wipTemplate) return
    try {
      await wipTemplatesService.createTemplateColumn({
        ...createColumnModalData,
        columnColor: createColumnModalData.columnColor.value,
        wip_template_id: wipTemplate.id,
        is_cron: createColumnModalData.amount_of_cron_days ? true : false,
      })
      handleCreateColumnModalClose()
      queryClient.refetchQueries([
        GLOBAL_QUERIES.WIP_TEMPLATE.GET_WIP_TEMPLATE_BY_ID,
        wipTemplateId,
      ])
      toast.success('Column Created')
    } catch {
      toast.error('Something Went Wrong')
    }
  }

  if(!wipTemplate) return null

  const isColumnsLoading = isLoading || isRefetching
  const isColumnsNotFound = !isColumnsLoading && !columnsWithAutomation
  return (
    <DragDropContext onDragEnd={() => {}}>
      <div className="container">
        <div className="row pl-3">
          <h2 className="text-dark">{wipTemplate?.name}</h2>
        </div>
        <div className="row d-flex justify-content-end align-items-center pr-3">
          <button className="btn btn-secondary" onClick={handleCreateColumnModalOpen}>
            Create Column
          </button>
          <button className="btn btn-secondary" onClick={handleCreateAutomationModalOpen}>
            Create Automation
          </button>
        </div>
        <div className={styles.taskBoard}>
          {isColumnsNotFound && <div> Not Found</div>}
          {columnsWithAutomation &&
            columnsWithAutomation.map((column) => (
              <Column
                id={column.id}
                key={`wip-template-column-${column.id}`}
                columnName={column.title}
                columnColor={column.columnColor || 'red'}
                tasks={[]}
                isArchived={undefined}
                isUnassigned={undefined}
                isAssigned={undefined}
                filterByType={[]}
                filterByStaff={[]}
                filterByContact={[]}
                step={column.step || 0}
                isAutomationColumn={true}
                automationAmount={column.automations.length}
                canCreateTask={false}
              />
            ))}
        </div>
      </div>
      <CreateColumnModal
        isOpen={isCreateColumnModalOpen}
        onClose={handleCreateColumnModalClose}
        externalOnSubmit={(data) =>
          handleCreateColumnModalSubmit(data as RawCreateWipTemplateColumn)
        }
      />
      <CreateAutomationModal
        isOpen={isCreateAutomationModalOpen}
        onClose={handleCreateAutomationModalClose}
      />
    </DragDropContext>
  )
}

export default WipTemplateBoard
