import axios from 'axios'
import { AutomationColumn, IOption, TaskProps } from '../types'
import { REACT_APP_API_URL } from '@src/lib/global-variables'

export const PRIORITY_OPTIONS = [
  {
    label: 'Normal',
    value: 0,
  },
  {
    label: 'Medium',
    value: 1,
  },
  {
    label: 'High',
    value: 2,
  },
]

export const TASK_TYPE_OPTIONS = [
  {
    label: 'Task',
    value: 0,
  },
  {
    label: 'Bug',
    value: 1,
  },
  {
    label: 'Story',
    value: 2,
  },
]

export const TASK_TEMPLATE_OPTIONS = [
  {
    label: 'Bug',
    value: 0,
  },
  {
    label: 'Story',
    value: 1,
  },
  {
    label: 'Task',
    value: 2,
  },
]

export const TASK_STATUS_OPTIONS = [
  {
    label: 'To do',
    value: 1,
  },
  {
    label: 'In Progress',
    value: 2,
  },
  {
    label: 'Done',
    value: 3,
  },
]

export const TASK_STATUS_OPTIONS_RECOVERY = [
  {
    label: 'To do',
    value: 1,
  },
  {
    label: 'Done',
    value: 3,
  },
]

export const COLUMN_COLORS = [
  { label: 'Red', value: '#FF0000' },
  { label: 'Skyline Blue', value: '#44a6f5' },
  { label: 'Coral Blush', value: '#FF9784' },
  { label: 'Mint Garden', value: '#82C786' },
] as IOption[]

export const AUTOMATION_COLUMN_CUSTOM_DATES = [1, 14, 28]


export const fetchTaskById = async (id: number, params = {}) => {
  console.log('params', params)
  const taskResponse = await axios.get<TaskProps>(`${REACT_APP_API_URL}/tasks/${id}`, {params})
  const task = taskResponse.data
  console.log(task)
  let columnResponse: AutomationColumn | null = null;
  
  if (task.automation_column_belong_id){
    const response = await axios.get<AutomationColumn>(`${REACT_APP_API_URL}/board-columns/${task.automation_column_belong_id}`);
    columnResponse = response.data
  }

  return {task, column: columnResponse};
}