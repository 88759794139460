import LabelWrapper from '@components/label-wrapper'
import { IOption } from '@containers/Setting/components/Contacts/taskboard/types'
import { formatAssigneName } from '@containers/Setting/components/Contacts/taskboard/utils'
import { joiResolver } from '@hookform/resolvers/joi'
import { useUserContext } from '@src/context/user-context'
import { REACT_APP_API_URL } from '@src/lib/global-variables'
import axios from 'axios'
import { FC, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import Select from 'react-select'
import { TextEditor } from '@src/shared/components/TextEditor/TextEditor'
import Modal from '@components/modal'
import { ErrorMessage } from '@hookform/error-message'
import moment from 'moment'
import styles from './generic-send-email.module.scss'
import { genericSendEmailSchema } from './validation-shema'
import Switch from '@components/switch'

interface SendEmailFormModalProps {
  senderOptions: { label: string; value: string }[]
  isOpen: boolean
  sendToOther?: boolean
  onClose: () => void
  emailTemplates: IOption[]
  additionalValuesInRequeset?: NonNullable<unknown>
  additionalValuesInReplacement?: NonNullable<unknown>
  onEmailSend?: () => void
}

interface IForm {
  to: IOption | undefined | string
  template: IOption | undefined
  subject: string
  message: string
}

const GenericSendEmailModal: FC<SendEmailFormModalProps> = ({
  senderOptions,
  isOpen,
  onClose,
  additionalValuesInRequeset = {},
  sendToOther = false,
  additionalValuesInReplacement = {},
  emailTemplates,
  onEmailSend
}) => {
  const [isAnotherEmail, setIsAnotherEmail] = useState(false)
  const { user } = useUserContext()

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<IForm>({
    defaultValues: {
      to: undefined,
      template: undefined,
      subject: '',
      message: '',
    },
    mode: 'all',
    resolver: joiResolver(genericSendEmailSchema),
  })

  const handleAnotherEmailChange = () => {
    setIsAnotherEmail((prevState) => !prevState)
  }

  const sendEmail = async (data) => {
    await axios.post(`${REACT_APP_API_URL}/emails`, data)
  }

  const replacePlaceholders = (template, values) => {
    let result = template
    Object.keys(values).forEach((key) => {
      const placeholder = new RegExp(`%${key}%`, 'g')
      result = result.replace(placeholder, values[key])
    })
    return result
  }

  const formatHtmlText = (template, values) => {
    const htmlText = replacePlaceholders(template, values)
    return htmlText
  }

  const onSumbit = async (data: IForm) => {
    const { subject, message, to } = data

    const replacements = {
      date_long: moment(new Date()).format('dddd DD MMMM YYYY'),
      staff_name: formatAssigneName({
        first_name: user?.first_name || '',
        last_name: user?.last_name || '',
        isAssigned: false,
      }),
      staff_title: user?.position,
      ...additionalValuesInReplacement,
    }

    const emailData = {
      to: typeof to === 'string' ? to : to?.value,
      subject,
      html: formatHtmlText(message, replacements),
      text: message,
      staff_id_created: user?.id,
      ...additionalValuesInRequeset,
    }

    try {
      await sendEmail(emailData)
      onEmailSend && onEmailSend()
      toast.success('Email sent successfully')
    } catch (e) {
      console.log(e)
      toast.error('Something went wrong')
    } finally {
      onClose()
    }
  }

  useEffect(() => {
    if (!isOpen) {
      setIsAnotherEmail(false)
      reset({}, { keepDirty: false, keepDefaultValues: true, keepValues: false })
    }
  }, [isOpen])

  useEffect(() => {
    if(isAnotherEmail){
      setValue('to', '')
      return
    }
    setValue('to', undefined)
  }, [isAnotherEmail])

  useEffect(() => {
    const subscription = watch(({ template }, { name }) => {
      if (template && name === 'template') {
        if (template?.value) {
          setValue('message', template?.value)
          return
        }
      }
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [watch])

  return (
    <Modal
      onSubmit={handleSubmit(onSumbit)}
      isOpen={isOpen}
      onClose={onClose}
      style={{ maxWidth: 600, width: '90vw' }}
      saveBtnText="Send"
      canecelBtnText="Back"
    >
      <div className={styles.wrapper}>
        <span className={styles.headerText}>Send Email</span>
        <div className={styles.form}>
          {sendToOther ? (
            <div className="d-flex justify-content-end">
              <Switch value={isAnotherEmail} onChange={handleAnotherEmailChange} />
              <span className="ml-2">Another Email</span>
            </div>
          ) : null}

          {!isAnotherEmail ? (
            <LabelWrapper<IForm> control={control} name="to" label="To">
              {({ onChange, value }) => {
                return (
                  <Select
                    className="react-select"
                    options={senderOptions}
                    value={value}
                    onChange={onChange}
                  />
                )
              }}
            </LabelWrapper>
          ) : null}

          {isAnotherEmail ? (
            <LabelWrapper<IForm> control={control} name="to" label="To">
              {({ onChange, value }) => {
                return (
                  <input
                    type="text"
                    className="react-select"
                    value={value as string}
                    onChange={onChange}
                  />
                )
              }}
            </LabelWrapper>
          ) : null}
          <LabelWrapper<IForm> control={control} name="template" label="Template">
            {({ onChange, value }) => {
              return (
                <Select
                  className="react-select"
                  options={emailTemplates}
                  value={value}
                  onChange={onChange}
                />
              )
            }}
          </LabelWrapper>

          <LabelWrapper<IForm> control={control} name="subject" label="Subject">
            {({ onChange, value }) => {
              return (
                <input
                  className="react-select"
                  placeholder="Subject"
                  value={value as any}
                  onChange={onChange}
                />
              )
            }}
          </LabelWrapper>
        </div>
        <div style={{ zIndex: 0 }}>
          <span>
            <ErrorMessage
              errors={errors}
              name={'message'}
              render={({ message }) => {
                return <p style={{ color: 'red' }}>{message}</p>
              }}
            />
          </span>
          <Controller
            control={control}
            name="message"
            render={({ field }) => (
              <TextEditor value={field.value} onEditorChange={field.onChange} />
            )}
          />
        </div>
      </div>
    </Modal>
  )
}

export default GenericSendEmailModal
