import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from 'mdi-react/CloseIcon';
import moment from 'moment';
import TransactionsService from '../../../../services/TransactionsService';
import Services from '../../../../shared/Services';
import { TailSpin } from 'react-loader-spinner';

const useStyles = makeStyles(() => ({
  PageHeader: {
    fontSize: 25,
    fontWeight: 700,
    color: '#000',
  },
  PageSubtitle: {
    fontSize: 16,
    color: '#000',

  },
  Card: {
    padding: '10px 15px',
    marginTop: 20,
  },
  BackButton: {
    background: '#fff',
    borderRadius: 5,
    fontWeight: 700,
  },
  Button: {
    marginBottom: 0,
    borderRadius: 5,
  },
  TableTitle: {
    fontSize: 18,
    fontWeight: 700,
    color: '#000',
    marginBottom: 20,
  },
  TableSubtitle: {
    fontSize: 16,
    color: '#000',
    marginTop: 20,
  },
  TableHeader: {
    background: '#fff',

  },
  Footer: {
    background: '#ffff0014',
    border: '1px dashed #e5de0891',
    padding: '25px 15px',
    marginTop: 20,
  },
}));

const TransactionModal = ({ closeModal, transaction }) => {

  const classes = useStyles();
  const [reversedDate, setReversedDate] = useState('');
  const [noteReason, setNoteReason] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const transactionService = new TransactionsService();
  const services = new Services();

  const reversTransaction = async () => {
    try {
      const params = {
        id: transaction.id,
        reversed_on: moment(reversedDate).format('YYYY-MM-DD'),
        reason_for: noteReason,
      };
      const res = await transactionService.reversTransaction(params);
      console.log(res);
    } catch (e) {
      services.errorToast(e);
    } finally {
      setIsLoading(false);

    }
  };

  function reversedTransaction() {
    reversTransaction().then(() => {
      closeModal(false);
      services.successToast('Reverse Transaction successfully');
    });

  }

  return (
    <div>
      <div style={{ position: 'relative', left: '375px', marginTop: '-25px' }}>
        <IconButton style={{ right: '24px', marginTop: '-16px' }} onClick={() => closeModal(false)}>
          < CloseIcon />
        </IconButton>
      </div>
      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '150px' }}>
          <TailSpin
            height='50'
            width='50'
            color='#f69066'
            ariaLabel='loading'
          />
        </div>
      ) : <div className={'d-flex justify-content-around mt-3'}>
        <div className={'text-left'}>
          <h3 className={'mb-3'}>Transaction {transaction.id}</h3>
          <p><strong>Deposit/Withdrawal Data:</strong> {moment(transaction.date).format('DD-MM-YY')}</p>
          <p><strong>Payee:</strong> {transaction.payee} </p>
          <p><strong>Payment Method:</strong> {transaction.type}</p>
          <p><strong>Depositor Name:</strong> {transaction.depositor_name} </p>
          <p><strong>Depositor Address:</strong> {transaction.depositor_address}</p>
          <p><strong>Deposit Amount:</strong> ${transaction.deposit}</p>
          <p><strong>Withdrawal:</strong> ${transaction.withdrawal}</p>
          <p><strong>Notes:</strong> - </p>
          <p><strong>Created:</strong> {moment(transaction.date).format('DD-MM-YY')}</p>
          <p><strong>WIP File Number: </strong> {transaction.wip.id}</p>
          <p><strong>Client:</strong> Real Estate Company</p>
          <p><strong>Debtor:</strong> {`${transaction.debtor.name_first} ${transaction.debtor.name_last}`}</p>
        </div>
        <div className={'text-left'}>
          <h4 className={'mb-3'}>Reverse Transaction</h4>
          <div className='form-group mb-3'>
            <label className='form__form-group-label text-left w-100 ' htmlFor=''> Reversed on: </label>
            <div className='date-picker date-picker--interval'>
              <DatePicker
                selected={reversedDate}
                onChange={(e) => setReversedDate(e)}
                name='address_hist_start'
                dateFormat='dd/MM/yy'
                dropDownMode='select'
                popperPlacement='center'
                placeholderText='Select Date'
              />
              <span className='date_icon'><img src={'/image/calendar.svg'} alt='date' /></span>
            </div>
            <div className='form-group mt-3'>
              <label className='form__form-group-label text-left w-100'>
                Reason For:
              </label>
              <textarea style={{ minHeight: '215px' }}
                        name='notes'
                        className='custom-input'
                        value={noteReason}
                        onChange={(e) => setNoteReason(e.target.value)}
              />
            </div>
            <div style={{ marginTop: '25px', textAlign: 'right' }}>
              <button onClick={reversedTransaction}
                      className={`btn btn-secondary text-right ${classes.Button}`}>Submit Reversal
              </button>
            </div>
          </div>
        </div>
      </div>}
    </div>
  );
};

export default TransactionModal;