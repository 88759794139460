import { FC } from 'react'
import { CombinedNoteModalProps } from '../../../Setting/components/Contacts/types/combined-notes.type'
import EmailModal from './email-modal.component'
import SmsModal from './sms-modal/sms-modal.component'
import GeneralNoteModal from './general-note-modal.component'
import TaskNoteModal from './task-note-modal.component'
import { CombinedNoteType } from '../../../Setting/components/Contacts/types/combined-notes.type'
import CallLogModal from './call-log-modal/call-log-modal.component'

const CombinedNoteModal: FC<CombinedNoteModalProps> = ({ type, userId, ...viewModalProps }) => {
  return (
    <>
      {type === CombinedNoteType.LOCAL_EMAIL && (
        <EmailModal emailType="Local" userId={userId} {...viewModalProps} />
      )}
      {type === CombinedNoteType.OUTLOOK_EMAIL && (
        <EmailModal emailType="Outlook" userId={userId} {...viewModalProps} />
      )}
      {type === CombinedNoteType.SMS && <SmsModal {...viewModalProps} />}
      {type === CombinedNoteType.GENERAL_NOTE && <GeneralNoteModal {...viewModalProps} />}
      {type === CombinedNoteType.TASK_NOTE && <TaskNoteModal {...viewModalProps} />}
      {type === CombinedNoteType.CALL && typeof viewModalProps.id === 'number' ? (
        <CallLogModal {...viewModalProps} id={viewModalProps.id} />
      ) : null}
    </>
  )
}

export default CombinedNoteModal
