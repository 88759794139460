import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import DatePicker from 'react-datepicker';
import { Button, Modal } from 'reactstrap';
import TransactionModal from './TransactionModal';
import TablePagination from '@material-ui/core/TablePagination';
import Services from '../../../../shared/Services';
import { TailSpin } from 'react-loader-spinner';
import moment from 'moment';
import TransactionsService from '../../../../services/TransactionsService';
import { useHistory } from 'react-router-dom';
import WIPService from '../../../../services/WIPService';

const useStyles = makeStyles(() => ({
  ButtonUpdate: {
    marginTop: 22,
    marginLeft: 101,
  },
  PageHeader: {
    fontSize: 25,
    fontWeight: 700,
    color: '#000',
  },
  PageSubtitle: {
    fontSize: 16,
    color: '#000',

  },
  Card: {
    padding: '10px 15px',
    marginTop: 20,
  },
  BackButton: {
    background: '#fff',
    borderRadius: 5,
    fontWeight: 700,
  },
  Button: {
    marginBottom: 0,
    borderRadius: 5,
  },
  TableTitle: {
    fontSize: 18,
    fontWeight: 700,
    color: '#000',
    marginBottom: 30,
  },
  TableSubtitle: {
    fontSize: 16,
    color: '#000',
    marginTop: 20,
  },
  TableHeader: {
    background: '#fff',

  },
  Footer: {
    background: '#ffff0014',
    border: '1px dashed #e5de0891',
    padding: '25px 15px',
    marginTop: 20,
  },
  textTable: {
    fontWeight: 'bold',
  },
  color: {
    color: '#BFC5D2!important',
  },
}));

const TransactionsReport = (props) => {

  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [dateStart, setDataStart] = useState(new Date(new Date().setMonth(new Date().getMonth() - 1)));
  const [dateEnd, setDataEnd] = useState(new Date(new Date().setDate(new Date().getDate() + 1)));
  const [wipNumber, setWipNumber] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [transaction, setTransaction] = useState([]);
  const [transactionDetail, setTransactionDetail] = useState({});
  const [totalClaims, setTotalClaims] = useState(null);
  const [totalRecoverableCost, setRecoverableCost] = useState(null);
  const [totalPayments, setTotalPayments] = useState(null);
  const [totalBalance, setTotalBalance] = useState(null);
  const [totalPaid, setTotalPaid] = useState(0);
  const [wipNumberArray, setWipNumberArray] = useState([]);
  const wipService = new WIPService();
  const transactionService = new TransactionsService();
  const { errorToast } = new Services();
  const history = useHistory();

  useEffect(() => {
    if (history.location?.state?.wipId) {
      setWipNumber(history.location.state.wipId);
      updateView();
    }
    getAllClientWIPS();
  }, []);

  const getAllClientWIPS = async () => {

    const params = {
      limit: 10000,
    };
    const list = await wipService.getWips(params);
    const option = list.data.map((val) => ({
      number: val.id,
    }));
    setWipNumberArray(option);
  };

  const getTransaction = async (id, page, rowsPerPage) => {
    setIsLoading(true);
    try {
      const params = {
        join: ['debtor', 'wip', 'wip.company'],
        page: page,
        limit: rowsPerPage,
        filter: [`wip.id||$eq||${id}`,
          `date||$lte||${moment(dateEnd).format('YYYY-MM-DD')}`, `date||$gte||${moment(dateStart).format('YYYY-MM-DD')}`],
        sort: 'date,DESC',
      };
      const res = await transactionService.getTransaction(params);
      setTransaction(res.data.data);
      const totalClaims = res.data.data.map((e) => e.wip.claim_details.total);
      setTotalClaims(totalClaims[0]);
      const totalCost = res.data.data.map((e) => e.wip.recoverable_costs_details.total);
      setRecoverableCost(totalCost[0]);
      setTotal(res.data.total);
      setIsLoading(false);
    } catch (e) {
      errorToast(e);
    } finally {
      setIsLoading(false);
    }
  };

  const getTotalPaymentTransaction = async (id) => {
    setIsLoading(true);
    try {
      const params = {
        join: ['debtor', 'wip'],
        limit: 1000,
        filter: [`wip.id||$eq||${id}`],
      };
      const res = await transactionService.getTransaction(params);
      const totalPayments = res.data.data.reduce((acc, item) => acc + +item.deposit, 0);
      setTotalPayments(totalPayments);
      setIsLoading(false);
    } catch (e) {
      errorToast(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const totalBalance = (totalClaims + totalRecoverableCost) - (totalPayments + totalPaid);
    setTotalBalance(totalBalance);
  }, [totalClaims, totalRecoverableCost, totalPayments, totalPaid]);

  const open = item => {
    setOpenModal(true);
    setTransactionDetail(item);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getTransaction(wipNumber, newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getTransaction(wipNumber, 0, event.target.value);
  };

  function handleChangeStartDate(e) {
    setDataStart(e);
  }

  function handleChangeEndDate(e) {
    setDataEnd(e);
  }

  function changeInput(e) {
    setWipNumber(e.target.value);
  }

  function updateView() {
    const wip = wipNumber || history.location.state.wipId;
    getTransaction(wip, page, rowsPerPage);
    getTotalPaymentTransaction(wip);
  }

  return (
    <>
      <div className='container w-100'>
        <div id='contacts_accordion' className='form'>
          <div className='card mb-4'>
            <div className='justify-content-between p-relative d-flex mb-4 align-content-center'>
              <h3 className='page-title mb-0'>Transaction Reports</h3>
            </div>
            <div
              id='collapseOne'
              className='collapse show mb-4'
              data-parent='#contacts_accordion'
              style={{ background: '#fff' }}
            >
              <div className='card-body'>
                <div className='row'>
                  <div className='col-12 col-md-6 col-lg-3'>
                    <div className='form-group'>
                      <label className='form__form-group-label' htmlFor=''>
                        Wip File Number
                      </label>
                      <input
                        type='number'
                        className='custom-input'
                        id='wip'
                        value={wipNumber || ''}
                        onChange={changeInput}
                        placeholder={'Enter WIP number'}
                        list='WIP'
                      />
                      <datalist id='WIP'>
                        {wipNumberArray.map((e, i) =>
                          <option key={i}>{e.number}</option>,
                        )}
                      </datalist>
                    </div>
                  </div>
                  <div className='col-12 col-md-6 col-lg-3'>
                    <div className='form-group'>
                      <label className='form__form-group-label text-left w-100' htmlFor=''> Start
                        Date </label>
                      <div className='date-picker date-picker--interval'>
                        <DatePicker
                          selected={dateStart ? dateStart : ''}
                          onChange={(e) => handleChangeStartDate(e)}
                          name='address_hist_start'
                          dateFormat='dd/MM/yy'
                          dropDownMode='select'
                          popperPlacement='center'
                          placeholderText='Select Date'
                        />
                        <span className='date_icon'>
                                      <img src={'/image/calendar.svg'} alt='date' />
                                    </span>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-md-6 col-lg-3'>
                    <div className='form-group'>
                      <label className='form__form-group-label text-left w-100' htmlFor=''> End
                        Date </label>
                      <div className='date-picker date-picker--interval'>
                        <DatePicker
                          selected={dateEnd ? dateEnd : new Date()}
                          onChange={(e) => handleChangeEndDate(e)}
                          name='address_hist_start'
                          dateFormat='dd/MM/yy'
                          dropDownMode='select'
                          popperPlacement='center'
                          placeholderText='Select Date'
                        />
                        <span className='date_icon'>
                                      <img src={'/image/calendar.svg'} alt='date' />
                                    </span>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-md-6 col-lg-3'>
                    <div className={classes.ButtonUpdate}>
                      <button disabled={wipNumber === null} onClick={updateView}
                              className={`btn btn-secondary ${classes.Button}`}>Update View
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '150px' }}>
          <TailSpin
            height='50'
            width='50'
            color='#f69066'
            ariaLabel='loading'
          />
        </div>
      ) : transaction.length !== 0 && (<div className='container w-100'>
        <div id='contacts_accordion' className='form'>
          <div className='card mb-4'>
            <div
              id='collapseOne'
              className='collapse show mb-4'
              data-parent='#contacts_accordion'
              style={{ background: '#fff' }}
            >
              <div className='card-body'>
                <div style={{ margin: ' 15px' }} className='row'>
                  <div className='d-flex flex-column'>
                    <div className={classes.TableTitle}>Transactions Processed
                      from {moment(dateStart).format('DD-MM-YY')} to {moment(dateEnd).format('DD-MM-YY')}</div>
                    <div><span style={{ right: '65px', position: 'absolute', top: '35px' }}>
                    <Button
                      className='bold-text'>Print PDF</Button> </span></div>
                  </div>

                  <div className='d-flex flex-column w-100'>
                    <table style={{ borderBottom: '1px solid #BFC5D2' }} className='table'>
                      <thead style={{ borderTop: 'none', borderBottom: '1px solid #BFC5D2' }}>
                      <tr className={classes.TableHeader}>
                        <th className={classes.color}>TID</th>
                        <th className={classes.color}>Date</th>
                        <th className={classes.color}>WIP</th>
                        <th className={classes.color}>Debtor</th>
                        <th className={classes.color}>Deposit</th>
                        <th className={classes.color}>Withdrawal</th>
                        <th className={classes.color}>Paid Out</th>
                      </tr>
                      </thead>
                      <tbody>
                      {transaction.map((item) => (
                        <tr className={classes.textTable} key={item.id}>
                          <td onClick={() => open(item)} style={{ cursor: 'pointer', color: '#3070e5' }}>{item.id}</td>
                          <td>{moment(item.date).format('DD-MM-YY')}</td>
                          <td>{item.wip?.id}</td>
                          <td>{`${item.debtor.name_first} ${item.debtor.name_last}`}</td>
                          <td>${item.deposit}</td>
                          <td>{item.withdrawal}</td>
                          <td>{item.type}</td>
                        </tr>
                      ))}
                      <tr>
                      </tr>
                      </tbody>
                    </table>
                    <div className='px-4 mb-3'>
                      <div className={`row ${classes.Footer}`}>
                        <div className='col-sm-4 col-xs-12'>
                          <div className='d-flex flex-column mt-2 mb-2'>
                            <p>(Total Claims + Total Recoverable Cost) -</p>
                            <p>(Total Payments + Total Paid To Agent)</p>
                            <p>--------------------------------------------------</p>
                            <p className={'mt-0 mb-3'}>Amount Outstanding Ignoring Interest</p>
                            <p>
                              <strong>(${totalClaims} + ${totalRecoverableCost}) - (${totalPayments} + $0) =
                                ${totalBalance}</strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <TablePagination className={'mt-3'}
                                     component='div'
                                     count={+total}
                                     page={page}
                                     onPageChange={handleChangePage}
                                     rowsPerPage={rowsPerPage}
                                     onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>)}

      <Modal
        isOpen={openModal}
        size='lg'
        style={{ maxWidth: '800px', width: '95%' }}
        className='theme-light'
        centered
      >
        <TransactionModal transaction={transactionDetail} closeModal={setOpenModal} />
      </Modal>
    </>
  );
};

export default TransactionsReport;
