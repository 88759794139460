import React, { useEffect, useState } from 'react'
import axios from 'axios'

import { makeStyles } from '@material-ui/core/styles'
import { FormControl, MenuItem, Select } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: '100%',
    width: '100%',
    marginLeft: '122px',
    border: '1px solid silver',
    borderRadius: '3px',
  },
}))

const CompanyList = ({ handleChange, values, name, errors }) => {
  const [companies, setCompanies] = useState([])
  const classes = useStyles()

  const getCompanies = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/companies`)
    setCompanies(response.data)
  }

  useEffect(() => {
    getCompanies()
  }, [])

  return (
    <>
      <h3>Select Company</h3>
      <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
        <span className="form__form-group-label" style={{ width: '195px' }}>
          Select Company <span style={{ color: '#ff0000' }}>*</span>
        </span>

        <FormControl className={classes.formControl} style={{ margin: '0px', padding: '0px' }}>
          <Select
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
            name="company.id"
            onChange={handleChange}
            style={{ background: '#f5f9fc', borderRadius: '3px' }}
            required={true}
            value={values?.company?.id}
          >
            {companies.data?.map((company) => (
              <MenuItem key={company.id} value={company.id}>
                {company?.name}
              </MenuItem>
            ))}
          </Select>

          {errors?.company?.id && (
            <div style={{ color: '#ff0000', marginTop: '10px', border: 'none' }}>
              {errors?.company?.id}
            </div>
          )}
        </FormControl>
      </div>
    </>
  )
}

export default CompanyList
