import { GLOBAL_QUERIES } from "@src/lib/global-queries"
import { ClientService } from "../services/client.service"
import { Params } from "@src/types/service.type"
import { useQuery } from "@tanstack/react-query"


export const useGetClientWips = (id: number, params?: Params) => {

  const getClientWips = async (id: number, params?: Params) => {
    const clientService = new ClientService()
    return clientService.getClientWips(id, params)
  }

  const query = useQuery({
    queryKey: [GLOBAL_QUERIES.COMPANIES.GET_WIPS, id, params],
    queryFn: () => getClientWips(id, params),
    keepPreviousData: true
  })

  return query;
}