import FormCheckbox from '@components/inputs/form-checkbox/form-checkbox.component'
import FormSelect from '@components/inputs/form-select/form-select.component'
import { SubHeader } from '@containers/Recovery/Wip/components/UI/text/text.component'
import { BaseFormProps, SelectOption } from '@src/types/component-types'
import { useForm, useWatch } from 'react-hook-form'
import styles from './property-cover-form.module.scss'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { FC, useEffect } from 'react'
import FormDatePicker from '@components/inputs/form-date-picker/form-date-picker.component'
import { PropertyStatus } from '@containers/Sales/Properties/types/property.type'
import { PROPERTY_STATUS_OPTIONS } from '@containers/Sales/Properties/const/properties.const'

export interface PropertyCoverFormValues {
  coveredFrom: Date | null
  coveredTo: Date | null
  invoiceType: SelectOption | null
  status: SelectOption<PropertyStatus> | null
  invoiceOwner: boolean
}

const propertyCoverDefaultValues: PropertyCoverFormValues = {
  coveredFrom: null,
  coveredTo: null,
  invoiceType: null,
  status: null,
  invoiceOwner: false,
}

const PropertyCoverForm: FC<BaseFormProps<PropertyCoverFormValues>> = ({
  initialValues,
  loading = false,
  submitButtonTitle = 'Save',
  onSuccessSubmit,
}) => {
  const { control, setValue, reset, handleSubmit } = useForm<PropertyCoverFormValues>({
    defaultValues: {
      ...propertyCoverDefaultValues,
      ...initialValues,
    },
  })

  useEffect(() => {
    if (!initialValues) {
      return
    }
    reset({
      ...propertyCoverDefaultValues,
      ...initialValues,
    })
  }, [initialValues])

  const invoiceOwner = useWatch({ control, name: 'invoiceOwner' })
  return (
    <form className={styles.formWrapper} onSubmit={handleSubmit(onSuccessSubmit)}>
      <FormDatePicker
        control={control}
        name="coveredFrom"
        inputStyle="outlined"
        label="Covered From"
      />
      <FormDatePicker control={control} name="coveredTo" inputStyle="outlined" label="Covered To" />
      <FormSelect
        control={control}
        style="outlined"
        options={PROPERTY_STATUS_OPTIONS}
        label="Status"
        name="status"
      />
      <FormSelect control={control} style="outlined" label="Invoice Type" name="invoiceType" />
      <SubHeader fontWeight="semibold">Invoice the Owner</SubHeader>
      <div className={styles.checkboxWrapper}>
        <FormCheckbox
          checked={invoiceOwner}
          onChange={() => setValue('invoiceOwner', true)}
          label="Yes"
        />
        <FormCheckbox
          checked={!invoiceOwner}
          onChange={() => setValue('invoiceOwner', false)}
          label="No"
        />
      </div>
      <div className={styles.submitButtonWrapper}>
        <SubmitButton buttonType="submit" loading={loading}>
          {submitButtonTitle}
        </SubmitButton>
      </div>
    </form>
  )
}

export default PropertyCoverForm
