import Modal from '@components/modal'
import { FC } from 'react'
import CreateDebtorLegalNoteForm from '../../forms/debtor-legal-note-form/create-debtor-legal-note-form.component'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import ModalHeader from '@components/modal-header.component'
import styles from './debtor-legal-note-modal.module.scss'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import { CreateDebtorLegalNoteModalProps } from '../../../types/debtor-legal-note.type'

const CreateDebtorLegalNoteModal: FC<CreateDebtorLegalNoteModalProps> = ({
  debtorLegalId,
  ...modalProps
}) => {
  const queryClient = useQueryClient()
  const handleSuccessfulSubmit = () => {
    toast.success('Debtor Legal Note created successfully')
    queryClient.invalidateQueries({
      queryKey: [GLOBAL_QUERIES.DEBTOR_LEGAL_NOTE.GET_BY_DEBTOR_LEGAL_ID],
    })
    modalProps.onClose()
  }
  return (
    <Modal className={styles.modalWrapper} {...modalProps} withBtns={false}>
      <ModalHeader onCloseClick={modalProps.onClose} />
      <Header mb={4}>Create Debtor Legal Note</Header>
      <CreateDebtorLegalNoteForm
        debtorLegalId={debtorLegalId}
        onSuccessSubmit={handleSuccessfulSubmit}
      />
    </Modal>
  )
}

export default CreateDebtorLegalNoteModal
