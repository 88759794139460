import SendEmailForm from '../forms/send-email-form/send-email-form.component'
import styles from './send-email-main.module.scss'

const SendEmailMain = () => {
  return (
    <div className={styles.sendEmailMainWrapper}>
      <SendEmailForm />
    </div>
  )
}

export default SendEmailMain
