import React from 'react';
import { Button, ButtonGroup, Card, CardBody, Col, UncontrolledTooltip } from 'reactstrap';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import SettingTableHead from './SettingTableHead';
import Services from '../../../shared/Services.js';
import { withRouter } from 'react-router';
import ModalComponent from '../../../shared/components/ModalCustom';
import StaffService from '../../../services/StaffService';

let counter = 0;

const userImg = `${process.env.PUBLIC_URL}/image/user.jpg`;

function createData(name, calories, fat, carbs, protein) {
  counter += 1;
  return {
    id: counter, name, calories, fat, carbs, protein,
  };
}

function getSorting(order, orderBy) {
  if (order === 'desc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
}

class SettingTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: 'asc',
      orderBy: '',
      selected: new Map([]),
      data: [
        createData('Account', '-', 'Account', 'Current'),
        createData('Account', '-', 'Account', 'Suspended'),
        createData('Account', '-', 'Account', 'Departed'),
        createData('Account', '-', 'Account', 'Current'),
        createData('Account', '-', 'Account', 'Suspended'),
        createData('Account', '-', 'Account', 'Departed'),
        createData('Account', '-', 'Account', 'Current'),
        createData('Account', '-', 'Account', 'Suspended'),
        createData('Account', '-', 'Account', 'Departed'),
        createData('Account', '-', 'Account', 'Current'),
        createData('Account', '-', 'Account', 'Suspended'),
        createData('Account', '-', 'Account', 'Departed'),
        createData('Account', '-', 'Account', 'Current'),
        createData('Account', '-', 'Account', 'Suspended'),
        createData('Account', '-', 'Account', 'Departed'),
      ],
      staffData: [],
      page: 0,
      rowsPerPage: 5,
      errorModal: false,
      errorMessage: '',
      errorType: '',
      st_id: '',
      accessErrorModal: false,
      total: '',
    };

    this.staff = new StaffService();
    this.services = new Services();
    this.EditStaff = this.EditStaff.bind(this);
  }

  componentDidMount() {
    this.getStaff();
  }

  getStaff = async (page, rowsPerPage) => {
    try {
      const params = {
        page: page,
        limit: rowsPerPage,
        sort: 'id,ASC',
      };
      const res = await this.staff.getStaff(params);
      this.setState({ total: res.total });
      this.setState({ staffData: res.data });
    } catch (e) {
      this.services.errorToast(e);
      throw new Error(e);
    }
  };


  handleRequestSort = (event, property) => {

    const orderBy = property;
    let order = 'desc';
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === 'desc') {
      order = 'asc';
    }
    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      const { data } = this.state;
      const newSelected = new Map();
      data.map(n => newSelected.set(n.id, true));
      this.setState({ selected: newSelected });
      return;
    }
    this.setState({ selected: new Map([]) });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const newSelected = new Map(selected);
    const value = newSelected.get(id);
    let isActive = true;
    if (value) {
      isActive = false;
    }
    newSelected.set(id, isActive);
    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
    this.getStaff(newPage);
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
    this.setState({ page: 0 });
    this.getStaff(0, event.target.value);
  };

  handleDeleteSelected = () => {
    const { data } = this.state;
    let copyData = [...data];
    const { selected } = this.state;

    for (let i = 0; i < [...selected].filter(el => el[1]).length; i += 1) {
      copyData = copyData.filter(obj => obj.id !== selected[i]);
    }

    this.setState({ data: copyData, selected: new Map([]) });
  };

  isSelected = (id) => {
    const { selected } = this.state;
    return !!selected.get(id);
  };

  EditStaff(e, d) {
    localStorage.setItem('editId', d.id);
    this.props.history.push('/edit-staff');
  }

  DeleteStaff = () => {
    this.setState({
      errorMessage: '',
      errorModal: false,
      errorType: '',
    });
    const data = this.state.st_id;
    this.staff.deleteStaff(data).then((res) => {
      if (res.status === 200) {
        this.services.successToast('Staff removed successfully');
        this.getStaff();
      }
    });
  };

  OpenModal = (e, d) => {
    this.setState({
      st_id: d.id,
      errorMessage: 'Are you sure you want to delete this record?',
      errorModal: true,
      errorType: 'danger',
    });
  };

  handleChange0 = (selectedOption0, d) => {

    const data = {
      st_id: d.id,
      status: [selectedOption0.value],
    };
    this.staff.editStaffStatus(data).then((res) => {
      if (res.status === 200) {
        this.services.successToast('Status update successfully');
        this.getStaff();
      }
    });
  };

  accessErrorModalClose = () => {
    this.setState({ accessErrorModal: false });
    this.props.history.push('/dashboard');
  };

  render() {
    const {
      order, orderBy, selected, rowsPerPage, page, staffData, total,
    } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, staffData.length - (page * rowsPerPage));

    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <div className='card__title'>
              {/* <h5 className="bold-text">MANAGE STAFF</h5> */}
            </div>

            <div className='material-table__toolbar-wrap' style={{ right: '11px' }}>
              <Link className='btn btn-secondary' to='/add-staff'>Add</Link>
            </div>

            {/* <SettingTableToolbar
              numSelected={[...selected].filter(el => el[1]).length}
              handleDeleteSelected={this.handleDeleteSelected}
              onRequestSort={this.handleRequestSort}
            /> */}
            <div className='material-table__wrap'>
              <Table className='material-table'>
                <SettingTableHead
                  numSelected={[...selected].filter(el => el[1]).length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={this.handleSelectAllClick}
                  onRequestSort={this.handleRequestSort}
                  rowCount={staffData.length}
                />
                <TableBody>
                  {/* .sort(getSorting(order, orderBy))
                   .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage) */}

                  {staffData
                    .sort(getSorting(order, orderBy))
                    .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
                    .map((d, i) => {
                      const isSelected = this.isSelected(d.id);

                      return (
                        <TableRow
                          className='material-table__row'
                          role='checkbox'
                          onClick={event => this.handleClick(event, d.id)}
                          aria-checked={isSelected}
                          tabIndex={-1}
                          key={i}
                          selected={isSelected}
                        >
                          <TableCell className='material-table__cell material-table__cell-right' component='th'
                                     scope='row' padding='none'>
                            <img className='topbar__avatar-img'
                                 src={d.profile_picture ? d.profile_picture : userImg}
                                 onError={userImg} alt='avatar' />
                          </TableCell>
                          <TableCell
                            className='material-table__cell material-table__cell-right'
                            component='th'
                            scope='row'
                            padding='none'
                          >
                            {d.first_name} {d.last_name}
                          </TableCell>

                          <TableCell
                            className='material-table__cell material-table__cell-right'
                          >{d.nickname}
                          </TableCell>
                          <TableCell
                            className='material-table__cell material-table__cell-right'
                          >{d.comms_email}
                          </TableCell>
                          <TableCell
                            className='material-table__cell material-table__cell-right'
                          >{d.access_level}
                          </TableCell>
                          <TableCell
                            className='material-table__cell material-table__cell-right'
                          >
                            <Select name='status' options={[
                              { value: 'CURRENT', label: 'CURRENT' },
                              { value: 'SUSPENDED', label: 'SUSPENDED' },
                              { value: 'DEPARTED', label: 'DEPARTED' },
                            ]}
                                    classNamePrefix='react-select'
                                    className='react-select'
                                    value={{ value: d.account_status, label: d.account_status }}
                                    onChange={(e) => this.handleChange0(e, d)}
                            />
                          </TableCell>

                          <TableCell className='material-table__cell material-table__cell-left'>
                            <ButtonGroup className='btn-group--icons' dir='ltr'>
                              <Button outline id='Edit' onClick={(e) => this.EditStaff(e, d)}><span
                                className='lnr lnr-pencil' /></Button>

                              {/* {d.isdel && (
                                <Button outline id="Delete" onClick={(e)=> this.OpenModal(e,d)} ><span className="lnr lnr-trash"></span></Button>
                              )} */}

                            </ButtonGroup>
                            {!d.isdel && (
                              <ButtonGroup className='btn-group--icons' dir='ltr'>
                                <Button outline id='Delete' onClick={(e) => this.OpenModal(e, d)}><span
                                  className='lnr lnr-trash' /></Button>
                              </ButtonGroup>
                            )}
                            <UncontrolledTooltip placement='bottom' target='Edit'>
                              Edit
                            </UncontrolledTooltip>
                            <UncontrolledTooltip placement='bottom' target='Delete'>
                              Delete
                            </UncontrolledTooltip>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 49 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
            <TablePagination
              component='div'
              className='material-table__pagination'
              count={total}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{ 'aria-label': 'Previous Page' }}
              nextIconButtonProps={{ 'aria-label': 'Next Page' }}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 15]}
              dir='ltr'
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
            />
          </CardBody>
        </Card>
        {this.state.errorModal ?
          <ModalComponent modal={this.state.errorModal} color={this.state.errorType} isCancel={true}
                          message={this.state.errorMessage} onConfirm={(e) => this.DeleteStaff(e)}
                          onClose={() => this.setState({ errorModal: false })} /> : null}
        {this.state.accessErrorModal ?
          <ModalComponent modal={this.state.accessErrorModal} color='danger' isCancel={false}
                          message='You do not have access to this, please contact your manager for assistance'
                          onConfirm={(e) => this.accessErrorModalClose(e)}
                          onClose={(e) => this.accessErrorModalClose(e)} /> : null}
      </Col>
    );
  }
}


export default withRouter(SettingTable);