import { useQuery } from "@tanstack/react-query";
import { BaseGraphApiParams } from "../types/graph-api-mail.type";
import { GLOBAL_QUERIES } from "@src/lib/global-queries";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { GraphApiMailService } from "../services/graph-api-mail.service";

export const useGetMessageById = (id: string, params: BaseGraphApiParams = {}) => {
  const isAuthenticated = useIsAuthenticated()
  const { instance, accounts } = useMsal()

  const getMessageById = async (id: string, params: BaseGraphApiParams) => {
    const graphApiMailService = new GraphApiMailService();
    const account = accounts[0] || {};

    const token = await instance.acquireTokenSilent({ scopes: ['Mail.ReadBasic', 'Mail.Read', 'Mail.ReadWrite'], account: account })
    const message = graphApiMailService.getMessageById(token.accessToken, id, params)
    return message
  }

  const query = useQuery({
    queryKey: [GLOBAL_QUERIES.GRAPH_API_MESSAGES.GET_BY_ID, id],
    queryFn: () => getMessageById(id, params),
    enabled: !!id && isAuthenticated,
    refetchOnWindowFocus: false,
    retry: 1
  })

  return query;
}