export const GLOBAL_QUERIES = {
  WIP: {
    GET: 'getWips',
    GET_BY_ID: 'getWipById',
  },
  WIP_COVER: {
    GET: 'getWipCovers',
  },
  SMS_CONVERSATION: {
    GET: 'getSmsConversation',
    FRAGMENT: {
      GET_BY_MESSAGE_ID: 'getSmsConversationFragmentByMessageId',
    },
  },
  TASKSERVICE: {
    GET: 'getTasks',
    GET_TODAY: 'getToday',
    GET_WEEKLY: 'getWeeklyTasks',
    GET_OVERDUE: 'getOverdue',
    GET_BY_ID: 'getTaskById',
    GET_TICKET_NOTES: 'getTicketNotes',
  },
  CALL_LOG: {
    GET_BY_ID: 'getCallLogById',
  },
  CLAIM_DETAILS_NOTES: {
    GET: 'getClaimDetailsNotes',
    GET_BY_ID: 'getClaimDetailsNoteById',
  },
  DEBTOR: {
    GET: 'getDebtors',
    GET_DEBTOR_BY_ID: 'getDebtorById',
  },
  DEBTOR_LEGAL: {
    GET_DEBTOR_LEGAL_BY_WIP_ID: 'getDebtorLegalListByWipId',
    GET_DEBTOR_LEGAL_BY_ID: 'getDebtorLegalById',
  },
  DEBTOR_LEGAL_NOTE: {
    GET_BY_DEBTOR_LEGAL_ID: 'getDebtorLegalNotesByDebtorLegalId',
  },
  DEBTOR_ADDRESS_HISTORY: {
    GET: 'getDebtorAddressHistory',
  },
  RECOVERABLE_COST: {
    GET: 'getRecoverableCosts',
  },
  LIST_OF_FEES: {
    GET: 'getListOfFees',
  },
  COURT_INTEREST_RATE: {
    GET: 'getCourtInterestRates',
  },
  PROTECT_AND_COLLECT: {
    GET: 'getProtectAndCollectForms',
  },
  COMBINED_NOTES: {
    GET: 'getCombinedNotes',
  },
  SUB_TASK: {
    GET: 'getSubTasks',
  },
  GENERAL_NOTES: {
    GET_GENERAL_NOTE_BY_ID: 'getGeneralNoteById',
  },
  TASK_NOTES: {
    GET_TASK_NOTE_BY_ID: 'getTaksNoteById',
  },
  AUTOMATIONS: {
    GET: 'getAutomations',
    GET_AUTOMATION_LIST: 'getAutomationList',
    GET_AUTOMATION_COLUMN_BY_ID: 'getAutomationColumnById',
    GET_AUTOMATION_BY_ID: 'getAutomationById',
  },
  TRANSACTIONS: {
    GET: 'getTransactions',
  },
  LANDLORDS: {
    GET: 'getLandlords',
  },
  CONTACTS:{
    GET:'getLendLordContacts',
  },
  CLIENT: {
    GET: 'getClientByProperty',
    GET_BY_ID: 'getClientByPropertyId',
  },
  PROPERTIES: {
    GET: 'getProperties',
    GET_BY_ID: 'getPropertyById',
  },
  COMPANIES: {
    GET: 'getCompanies',
    GET_BY_ID: 'getCompanyById',
    GET_WIPS: 'getCompanyWips',
  },
  REPORTS: {
    GET: 'getReports',
  },
  COLUMNS: {
    GET: 'getColumns',
    GET_COLUMN_BY_ID: 'getColumnById',
  },
  WIP_COST_LIST: {
    GET: 'getWipCostList',
  },
  WIP_TEMPLATE: {
    GET: 'getWipTemplates',
    GET_WIP_TEMPLATE_BY_ID: 'getWipTemplateById',
  },
  EMAIL: {
    GET_EMAIL_BY_ID: 'getEmailById',
  },
  EMAIL_TEMPLATE: {
    GET: 'getEmailTemplates',
  },
  SMS_TEMPLATE: {
    GET: 'getSmsTemplates',
  },
  MAIL_FOLDER: {
    GET: 'getEmailFolders',
    GET_MAIL_FOLDER_BY_NAME: 'getEmailFolderByName',
  },
  GRAPH_API_USER: {
    ME: 'getGraphApiUserMe',
  },
  UNIFORM_EMAILS: {
    GET: 'getEmails',
  },
  GRAPH_API_MESSAGES: {
    GET: 'getGraphApiMessages',
    GET_BY_FOLDER_ID: 'getGraphApiMessagesByFolderId',
    GET_BY_ID: 'getGraphApiMessageById',
  },
  MESSAGE_RULES: {
    GET_BY_DISPLAY_NAME: 'getGraphApiRuleByDisplayName',
  },
  LOCAL_GRAPH_API_MAIL: {
    GET_BY_ID: 'getLocalGraphApiMailById',
  },
  SMS: {
    GET: 'getSms',
    GET_SMS_BY_ID: 'getSmsById',
  },
}
