import { FC, useState } from 'react'
import styles from './side-bar.module.scss'
import { SideBarProps } from '../types/side-bar.type'
import cls from 'classnames'
import PieChartIcon from '@src/icons/pie-chart'
import TaskIcon from '@src/icons/task'
import NavigationButton from '@components/navigation-button/navigation-button'
import { useLocation } from 'react-router'
import ClientDropDown from './dropdowns/client-dropdown.component'
import UsersIcon from '@src/icons/users'
import BookIcon from '@src/icons/book'
import InboxButton from './inbox-button/inbox-button.component'
import BriefcaseIcon from '@src/icons/briefcase'

const SideBar: FC<SideBarProps> = ({ isOpen }) => {
  const location = useLocation()

  const [isHovered, setIsHovered] = useState(false)
  const isCloseWithoutHover = !isOpen && !isHovered

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const isActive = (pathname: string) => {
    return location.pathname === pathname
  }

  return (
    <nav
      className={cls(styles.sideBarWrapper, { [styles.close]: isCloseWithoutHover })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <ul>
        <li>
          <NavigationButton
            icon={PieChartIcon}
            label="Dashboard"
            isActive={isActive('/dashboard')}
            isLabelHidden={isCloseWithoutHover}
            isLink
            to="/dashboard"
          />
        </li>
        <li>
          <NavigationButton
            icon={TaskIcon}
            label="Tasks"
            isActive={isActive('/tasks')}
            isLabelHidden={isCloseWithoutHover}
            isLink
            to="/tasks"
          />
        </li>
        <li>
          <InboxButton isActive={isActive('/inbox')} isCloseWithoutHover={isCloseWithoutHover}/>
        </li>
        <li>
          <NavigationButton
            icon={UsersIcon}
            label="Clients"
            isActive={isActive('/contact-list')}
            isLabelHidden={isCloseWithoutHover}
            isLink
            to="/contact-list"
          />
        </li>
        <li>
          <ClientDropDown isCloseWithoutHover={isCloseWithoutHover} />
        </li>
        <li>
          <NavigationButton
            icon={BookIcon}
            label="Recoveries"
            isActive={isActive('/recoveries')}
            isLabelHidden={isCloseWithoutHover}
            isLink
            to="/recoveries"
          />
        </li>
        <li>
          <NavigationButton
            icon={BriefcaseIcon}
            label="Properties"
            isActive={isActive('/properties')}
            isLabelHidden={isCloseWithoutHover}
            isLink
            to="/properties"
          />
        </li>
      </ul>
    </nav>
  )
}

export default SideBar
