import { FC } from 'react'
import { Link } from 'react-router-dom'
import cls from 'classnames'
import styles from './navigation-button.module.scss'

interface NavigationButtonProps {
  className?: string
  isLink?: boolean
  label?: string
  icon?: React.FC<{ className: string }>
  to?: string
  isActive?: boolean
  isLabelHidden?: boolean
  onClick?: (e) => void
  children?: React.ReactNode
}

const NavigationButton: FC<NavigationButtonProps> = ({
  isLink = false,
  label,
  icon,
  to = '',
  onClick,
  isLabelHidden = false,
  children,
  isActive = false,
  className = '',
}) => {
  const Icon = icon
  return (
    <>
      {isLink ? (
        <Link
          to={to}
          className={`${cls(styles.navigationButton, { [styles.active]: isActive })} ${className}`}
          onClick={onClick}
        >
          <div className={styles.navigationButtonContent}>
            {Icon ? <Icon className={styles.navigationButtonWrapper} /> : null}
            {label ? (
              <span className={cls({ [styles.labelHidden]: isLabelHidden })}>{label}</span>
            ) : null}
          </div>
          {children}
        </Link>
      ) : (
        <button
          className={`${cls(styles.navigationButton, { [styles.active]: isActive })} ${className}`}
          onClick={onClick}
        >
          <div className={styles.navigationButtonContent}>
            {Icon ? <Icon className={styles.navigationButtonWrapper} /> : null}
            {label ? (
              <span className={cls({ [styles.labelHidden]: isLabelHidden })}>{label}</span>
            ) : null}
          </div>
          {children}
        </button>
      )}
    </>
  )
}

export default NavigationButton
