import { getCommercialUncoveredListActionEnum } from '../actionTypes';

export const getCommercialUncoveredListReducer = function (state = { data: [], loading: false, error: '' }, action) {
  switch (action.type) {
    case getCommercialUncoveredListActionEnum.GET_COMMERCIAL_UNCOVERED_LIST_SUCCESS:
      return { loading: false, error: '', data: action.payload };
    case getCommercialUncoveredListActionEnum.IS_LOADING_GET_COMMERCIAL_UNCOVERED_LIST:
      return { loading: true, error: '', data: [] };
    case getCommercialUncoveredListActionEnum.GET_COMMERCIAL_UNCOVERED_LIST_ERROR:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
