import React from 'react';
import { Button, ButtonToolbar } from 'reactstrap';

export const RecoverableCostsDetails = ({
                                          recoverableCosts,
                                          setRecoverableCosts,
                                        }) => {
  const getOnInputChange = (index) => (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === 'amount') {
      setRecoverableCosts((prev) => {
        if (index === 0) {
          return [
            {
              ...prev[index],
              [name]: +value,
            },
            ...prev.slice(index + 1),
          ];
        }

        return [
          ...prev.slice(0, index),
          {
            ...prev[index],
            [name]: +value,
          },
          ...prev.slice(index + 1),
        ];
      });
    } else {
      setRecoverableCosts((prev) => {
        if (index === 0) {
          return [
            {
              ...prev[index],
              [name]: value,
            },
            ...prev.slice(index + 1),
          ];
        }

        return [
          ...prev.slice(0, index),
          {
            ...prev[index],
            [name]: value,
          },
          ...prev.slice(index + 1),
        ];
      });
    }
  };

  const onRecoverAdd = () => {
    setRecoverableCosts((prev) => (prev ? [...prev, {}] : [{}]));
  };

  const onRecoverRemove = (index) => {
    const array = recoverableCosts.filter((cost,i)=> i !== index)
    setRecoverableCosts(array)
  };

  return (
    <>
      <div className='card__title'>
        <h5 className='bold-text'>Recoverable Costs</h5>
      </div>
      {recoverableCosts &&
      recoverableCosts?.map((item, index) => (
        <React.Fragment key={index}>
          <div className='form__form-group'>
            <span className='form__form-group-label'>Cost {index + 1}</span>
            <div className='col-12 col-md-4 col-lg-3'>
              <span className='form__form-group-label'>Description</span>
              <div className='form-group'>
                <input
                  name='description'
                  type='text'
                  onChange={getOnInputChange(index)}
                  value={item?.description}
                  // defaultValue={item.description}
                />
              </div>
            </div>
            <div className='col-12 col-md-4 col-lg-3'>
              <div className='form-group'>
                <span className='form__form-group-label'>Cost Amount</span>
                <input
                  name='amount'
                  type='text'
                  onChange={getOnInputChange(index)}
                  value={item?.amount}
                  // defaultValue={item.cost_amount}
                />
              </div>
            </div>
            <div className='col-12 col-md-2 col-lg-3'>
              <div className='form-group'>
                <span className='form__form-group-label'>Date</span>
                <input
                  name='date'
                  type='date'
                  onChange={getOnInputChange(index)}
                  value={item?.date}
                  // defaultValue={item.data ? item.data : new Date()}
                />
              </div>
            </div>
            <div style={{marginTop:'17px',marginLeft:'35px'}}>
            <Button style={{lineHeight: '13px', fontSize: '16.5px'}}
              color='primary'
              className='mb-0'
              type='button'
              onClick={()=>onRecoverRemove(index)}
            >
              Remove
            </Button>
            </div>
          </div>
        </React.Fragment>
      ))}
      <div className='form__form-group'>
        <ButtonToolbar className='form__button-toolbar mb-3'>
          <Button
            disabled={recoverableCosts && recoverableCosts.length === 3}
            color='primary'
            className='mb-0'
            type='button'
            onClick={onRecoverAdd}
          >
            Add Recoverable Costs
          </Button>
        </ButtonToolbar>
      </div>
    </>
  );
};
