import FormInput from '@components/inputs/form-input/form-input.component'
import styles from './wip-list-filter.module.scss'
import SearchIcon from '@src/icons/search'
import Dropdown from '@components/dropdown/dropdown.component'
import CloackIcon from '@src/icons/cloack'
import { FC, useEffect, useState } from 'react'
import { SelectOption } from '@components/pagination-select/pagination-select.type'
import { startOfToday, sub } from 'date-fns'
import { WipListFilterProps } from '../../types/wip.type'
import { WIP_DROPDOWN_OPTIONS } from '../../const/wip.const'

const WipListFilter: FC<WipListFilterProps> = ({ onFilterChange }) => {
  const [selected, setSelected] = useState(WIP_DROPDOWN_OPTIONS[1])

  const handleDropDownChange = (option: SelectOption) => {
    setSelected(option)
  }

  useEffect(() => {
    if (!onFilterChange) return
    const date = transformDropDownOptionToDate(selected)

    onFilterChange({ created_at: date ? { $gte: date } : undefined })
  }, [selected])

  const transformDropDownOptionToDate = (option: SelectOption): Date | null => {
    switch (option.value) {
      case 'today':
        return startOfToday()
      case 'week':
        return sub(new Date(), { days: 7 })
      case 'month':
        return sub(new Date(), { months: 1 })
      default:
        return null
    }
  }

  return (
    <div className={styles.wipListFilter}>
      <Dropdown
        options={WIP_DROPDOWN_OPTIONS}
        selected={selected}
        icon={CloackIcon}
        onSelectedChange={handleDropDownChange}
      />
      <div className={styles.search}>
        <FormInput size='small' placeholder="Search" icon={SearchIcon} />
      </div>
    </div>
  )
}

export default WipListFilter
