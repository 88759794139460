import axios from "axios";
import { CallLog, CreateCallLogData } from "../types/call.type";
import { REACT_APP_API_URL } from "@src/lib/global-variables";
import { Params } from "@src/types/service.type";

export class CallLogService {

  async createCallLog(data: CreateCallLogData): Promise<CallLog> {
    const res = await axios.post<CallLog>(`${REACT_APP_API_URL}/call-logs`, data)
    return res.data
  }

  async getCallLogById(id: number, params?: Params): Promise<CallLog> {
    const res = await axios.get<CallLog>(`${REACT_APP_API_URL}/call-logs/${id}`, { params })
    return res.data
  }

}