import { ProtectAndCollectForm } from '@containers/Recovery/RecoveryForms/ProtectAndCollect/types/protect-and-collect-form.type'
import { Wip } from '@containers/Recovery/Wip/types/wip.type'
import { AssignedStaff } from '@containers/Setting/components/Contacts/taskboard/types'
import { Company } from '@containers/Setting/components/Contacts/types/contact-list.type'
import { AustralianState } from '@src/lib/select-options.const'
import { SelectOption } from '@src/types/component-types'

export interface PropertyLandlord {
  landlord?: string
  owner_email?: string
  owner_phone?: string
  title?: string
  name_first?: string
  name_middle?: string
  name_last?: string
  comms_email?: string
  comms_ph_mobile?: string
  comms_ph_direct?: string
  comms_ph_office?: string
  comms_ph_extension?: string
  comms_ph_fax?: string
  address_type?: string
  address_street_number?: string
  address_street_name?: string
  address_street_secondary?: string
  address_suburb?: string
  address_city?: string
  address_state?: string
  address_postcode?: string
  address_country?: string
  address_international?: boolean
  invoice?: number
}

export interface PropertyCover {
  from: Date
  to: Date
  price: number
  tax: number
  credit: number
  total: number
  type: string
  invoiceLandlord: boolean
}

export interface PropertyAgent {
  name: string
  phone: string
  address_1: string
  address_2: string
  address_3: string
  suburb: string
  state: string
  post_code: string
  country: string
  service: string
}

export interface PropertyAddressDetails {
  type?: string
  street_number?: string
  street_name?: string
  street_secondary?: string
  suburb?: string
  city?: string
  state?: string
  postcode?: string
  country?: string
  is_international?: boolean
}

export interface LandlordContact {
  id: number
  position: string | null
  firstName: string
  lastName: string
  fullName: string
  email: string | null
  mobilePhone: string | null
  landlinePhone: string | null
  faxNumber: string | null
  note: string | null

  landlord: Landlord
  landlordId: number

  createdAt: Date
  updatedAt: Date
}

export type CreateLandlordContact = Omit<
  LandlordContact,
  'id' | 'createdAt' | 'updatedAt' | 'landlord' | 'landlordId' | 'fullName'
>

export interface LandlordAddress {
  id: number

  addressLine1: string
  addressLine2: string
  addressLine3: string
  suburb: string
  state: AustralianState | null
  postcode: string
  country: string

  landlord: Landlord
  landlordId: number

  createdAt: Date
  updatedAt: Date
}

export type CreateLandlordAddressData = Omit<
  LandlordAddress,
  'id' | 'landlord' | 'landlordId' | 'createdAt' | 'updatedAt'
>

export interface Landlord {
  id: number

  companyName: string
  fullName: string
  abn: string
  ownershipType: string
  companies?: Company[]
  contacts: LandlordContact[]
  address: LandlordAddress
  property: Property
  propertyId: number

  createdAt: Date
  updatedAt: Date
}

export enum PropertyStatus {
  CurrentRenewal = 'currentRenewal',
  CurrentOpt = 'currentOpt',
  CurrentCoverNote = 'currentCoverNote',
  DeclinedLandLord = 'declinedLandLord',
  DeclinedAgent = 'declinedAgent',
  CancelledLandLord = 'cancelledLandLord',
  CancelledAgent = 'cancelledAgent',
  Refunded = 'refunded',
  Sold = 'sold',
  Nlmba = 'nlmba',
}

export interface Property {
  id: number

  fullAddress: string | null
  addressLine1: string | null
  addressLine2: string | null
  addressLine3: string | null
  suburb: string | null
  state: AustralianState | null
  postcode: string | null
  country: string | null
  invoiceOwner: boolean
  coveredFrom: Date | null
  coveredTo: Date | null
  invoiceType: string | null
  landlordId:number
  status: PropertyStatus

  // relations

  companies: Company[]
  wips: Wip[]
  landlord: Landlord | null
  monthReports: unknown[]
  sbcRecoveryForms: unknown[]
  protectAndCollectForm: ProtectAndCollectForm[]
  lodgeAndEvictionForm: unknown[]
  staffCreated: AssignedStaff
  staffCreatedId: number
  isFullNameEquals: boolean
  isNumberEquals: boolean
  isEmailEquals: boolean
  isFullAddressEquals: boolean
  createdAt: Date
  updatedAt: Date
}

export interface ValidateProperty {
  address1: string
  address2: string
  address3?: string | null
  suburb: string
  country: string
  state: string
  postcode: string
  email: string
  phone: string
  firstName: string
  lastName: string
}

export interface ValidatePropertyData {
  properties: ValidateProperty[]
}

export interface MergePropertyRow {
  id: string
  property: Property | null
}

export interface CreatePropertyData {
  firstName: string
  lastName?: string
  middleName?: string
  email?: string
  phone?: string
  address1: string
  address2: string
  address3: string
  suburb: string
  state: AustralianState
  postcode: string
  action: string
  additionalLandlord?: string
}

export interface MergedProperty
  extends CreatePropertyData,
    Pick<
      Property,
      'isFullNameEquals' | 'isEmailEquals' | 'isFullAddressEquals' | 'isNumberEquals'
    > {}
export interface SelectHeaderStepResponse {
  rows: string[][]
  headerRowIndex: number
}

export interface Column {
  name: string
  match: MatchOption | null
}

export type MatchHeader = Column[]

export interface ColumnMatchField {
  fieldType: 'input' | 'select'
  fieldName: string
}

//props

export interface SecondaryTableProps {
  children?: React.ReactNode
}

export interface UploadFileStepProps {
  onSuccessfulUpload: (file: Record<string, string>[]) => void
}

export interface SelectHeaderRowStepProps {
  jsonSheet: Record<string, string>[]
  onSuccessfulSelectHeader: (response: SelectHeaderStepResponse) => void
}

export interface MatchOption extends SelectOption {
  matchDropdown?: {
    rowsChangeAction?: (rows: string[][], columnIndex: number) => string[][]
    jsonResolver: (cell: string) => Record<string, string>
    fields: ColumnMatchField[]
  }
}

export interface MatchOptionChangeEvent {
  rows: string[][]
  columnData: string[]
  column: Column
  columnIndex: number
  optionValue: string
  changeRows: (rows: string[][]) => void
}

export interface MatchColumnsStepProps {
  rows: string[][]
  header: string[]
  matchOptions: MatchOption[]
  onMatchOptionChange?: (event: MatchOptionChangeEvent) => void
  onNextStepClick: (data: Record<string, string | Record<string, string>>) => void
}

export interface PropertyMatchColumnsStepProps {
  rows: string[][]
  header: string[]
  onNextStepClick: (data: Record<string, string[]>) => void
}

export interface ColumnMatchProps {
  columnName: string
  columnData: string[]
  fields: ColumnMatchField[]
}
