import FormDatePicker from '@components/inputs/form-date-picker/form-date-picker.component'
import FormInput from '@components/inputs/form-input/form-input.component'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { useForm } from 'react-hook-form'
import styles from './debtor-service-form.module.scss'
import { FC, useEffect } from 'react'
import {
  DebtorServiceFormProps,
  DebtorServiceFormValues,
} from '../../../types/debtor-legal-service.type'

const DebtorServiceForm: FC<DebtorServiceFormProps> = ({
  initialValues,
  onSubmit,
  loading = false,
}) => {
  const { control, register, reset, handleSubmit } = useForm<DebtorServiceFormValues>({
    defaultValues: initialValues,
  })

  useEffect(() => {
    if (!initialValues) return
    reset(initialValues)
  }, [initialValues])

  const innerOnSubmit = (data: DebtorServiceFormValues) => {
    onSubmit(data)
  }

  return (
    <form className={styles.formWrapper} onSubmit={handleSubmit(innerOnSubmit)}>
      <FormDatePicker control={control} label="Served Date" name="servedDate" />
      <FormDatePicker control={control} label="Enforcement Date" name="enforcementDate" />
      <FormDatePicker control={control} label="SPF Sent" name="spfSentDate" />
      <FormInput label="Court Location" {...register('courtLocation')} />
      <SubmitButton buttonType="submit" loading={loading}>
        Save
      </SubmitButton>
    </form>
  )
}

export default DebtorServiceForm
