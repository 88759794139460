import React from 'react';

import { Grid, Typography } from '@mui/material';

import FormInput from '../../../../../shared/components/FormInput/FormInput';
import FormCheckbox from '../../../../../shared/components/FormCheckbox/FormCheckbox';
import FormDatePicker from '../../../../../shared/components/FormDatePicker/FormDatePicker';

import { commercialUncoveredFormStyles } from '../../styles';

const RecoveryDetails = ({ handleChange, values, errors, touched }) => {
  return (
    <Grid item sx={commercialUncoveredFormStyles?.collectionContainer}>
      <Typography sx={commercialUncoveredFormStyles?.collectionTitle}>Recovery Details</Typography>

      <FormCheckbox
        changeHandler={handleChange}
        label="Is Tenant Vacated"
        name="recovery_details.is_tenant_vacated"
        type="text"
        value={values?.recovery_details?.is_tenant_vacated}
      />

      <FormDatePicker
        label="Date Vacated"
        placeholder="Date Vacated"
        name="recovery_details.date_vacated"
        changeHandler={handleChange}
        value={values?.recovery_details?.date_vacated || undefined}
        required={true}
        error={errors?.recovery_details?.date_vacated}
        touched={touched?.recovery_details?.date_vacated}
      />

      <FormCheckbox
        changeHandler={handleChange}
        label="Is Tenant Locked Out"
        name="recovery_details.is_tenant_locked_out"
        type="text"
        value={values?.recovery_details?.is_tenant_locked_out}
      />

      <FormInput
        changeHandler={handleChange}
        label="Locked Out By"
        name="recovery_details.locked_out_by"
        placeholder="Locked Out By"
        type="text"
        value={values?.recovery_details?.locked_out_by}
        required={true}
        error={errors?.recovery_details?.locked_out_by}
        touched={touched?.recovery_details?.locked_out_by}
      />

      <FormCheckbox
        changeHandler={handleChange}
        label="Is Break Fixed Term"
        name="recovery_details.is_break_fixed_term"
        type="text"
        value={values?.recovery_details?.is_break_fixed_term}
      />

      <FormDatePicker
        label="Date Re Leased"
        placeholder="Date Re Leased"
        name="recovery_details.date_re_leased"
        changeHandler={handleChange}
        value={values?.recovery_details?.date_re_leased || undefined}
        required={true}
        error={errors?.recovery_details?.date_re_leased}
        touched={touched?.recovery_details?.date_re_leased}
      />

      <FormDatePicker
        label="Rent From"
        placeholder="Rent From"
        name="recovery_details.rent_from"
        changeHandler={handleChange}
        value={values?.recovery_details?.rent_from || undefined}
      />

      <FormDatePicker
        label="Rent To"
        placeholder="Rent To"
        name="recovery_details.rent_to"
        changeHandler={handleChange}
        value={values?.recovery_details?.rent_to || undefined}
      />

      <FormInput
        changeHandler={handleChange}
        label="Rent"
        name="recovery_details.rent"
        placeholder="Rent"
        type="text"
        value={values?.recovery_details?.rent}
      />

      <FormInput
        changeHandler={handleChange}
        label="Other"
        name="recovery_details.other"
        placeholder="Other"
        type="text"
        value={values?.recovery_details?.other}
      />

      <FormInput
        changeHandler={handleChange}
        label="Sub Total"
        name="recovery_details.sub_total"
        placeholder="Sub Total"
        type="text"
        value={values?.recovery_details?.sub_total}
      />

      <FormInput
        changeHandler={handleChange}
        label="Less Held In Trust"
        name="recovery_details.less_held_in_trust"
        placeholder="Less Held In Trust"
        type="text"
        value={values?.recovery_details?.less_held_in_trust}
      />

      <FormInput
        changeHandler={handleChange}
        label="Total Amount Of Claim"
        name="recovery_details.total_amount_of_claim"
        placeholder="Total Amount Of Claim"
        type="text"
        value={values?.recovery_details?.total_amount_of_claim}
      />

      <FormInput
        changeHandler={handleChange}
        label="Relevant Info"
        name="recovery_details.relevant_info"
        placeholder="Relevant Info"
        type="text"
        value={values?.recovery_details?.relevant_info}
      />
    </Grid>
  );
};

export default RecoveryDetails;
