import { useIsAuthenticated, useMsal } from "@azure/msal-react"
import { GraphApiRuleService } from "@containers/Inbox/services/graph-api-rule.service"
import { CreateGraphApiRule, GraphApiRule } from "@containers/Inbox/types/graph-api-mail.type"
import { GRAPH_API_REQUEST } from "@src/config/azure-auth.config"
import { useMutation } from "@tanstack/react-query"

export const useCreateMessageRuleMutation = () => {
  const { accounts, instance } = useMsal()
  const isAuthenticated = useIsAuthenticated()

  const createMessageRule = async (rule: CreateGraphApiRule): Promise<GraphApiRule | void> => {
    if (!isAuthenticated) return
    const graphApiRuleService = new GraphApiRuleService()

    const account = accounts[0] || {}
    const token = await instance.acquireTokenSilent({ scopes: GRAPH_API_REQUEST.MESSAGE_RULES.CREATE, account: account })
    const createdRule = await graphApiRuleService.createRule(token.accessToken, rule)
    return createdRule
  }

  const mutation = useMutation({ mutationFn: createMessageRule })
  return mutation
}