import { FC, useEffect, useState } from 'react'
import { EmailModalProps } from '../../../Setting/components/Contacts/types/combined-notes.type'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import SlideModal from '@components/slide-modal'
import cls from 'classnames'
import styles from './note-modal.module.scss'
import { Oval } from 'react-loader-spinner'
import moment from 'moment'
import { FrameWrapper } from '@components/iframe-wrapper.component'
import { useIsAuthenticated } from '@azure/msal-react'
import ReplyToEmailForm from '@containers/Inbox/view-email-page/components/forms/reply-to-email-form/reply-to-email-form.component'
import NavigationButton from '@components/navigation-button/navigation-button'
import ReplyIcon from '@src/icons/reply'
import { EmailService } from '../../services/email.service'
import { UniformEmail } from '../../types/email.type'
import { useGetMe } from '@containers/Inbox/hooks/get-me.hook'
import { useRefreshEmails } from '../../../Setting/components/Contacts/Notes/hooks/refresh-emails.hook'
import { NOTE_QUERY } from '../../../Setting/components/Contacts/Notes/const/note-query.const'
import { useTaskContext } from '@src/context/task-context'
import { Header, SubHeader, Text } from '@containers/Recovery/Wip/components/UI/text/text.component'
import SubSection from '@containers/Recovery/Wip/components/UI/sub-section/sub-section.component'
import { format } from 'date-fns'

const EmailModal: FC<EmailModalProps> = ({
  isOpen,
  onClose,
  id,
  userId = null,
  emailType = 'Local',
}) => {
  const { isRecovery, isSales, currentFoundWip, currentClient } = useTaskContext()
  const queryClient = useQueryClient()
  const [isReplying, setIsReplying] = useState(false)
  const { mutateAsync } = useRefreshEmails()
  const isAuthenticated = useIsAuthenticated()
  const { data: me, isLoading: isMeLoading, isRefetching: isMeRefetching } = useGetMe()
  const fetchUniformEmail = async (): Promise<UniformEmail | null> => {
    const emailService = new EmailService()
    if (emailType === 'Local' && typeof id === 'number') {
      return emailService.getLocalEmail(id)
    }

    if (emailType === 'Outlook' && typeof id === 'string' && typeof userId === 'string') {
      return emailService.getOutlookEmail(userId, id)
    }

    return null
  }

  const { data, isLoading, isRefetching, isError } = useQuery({
    queryKey: [GLOBAL_QUERIES.EMAIL.GET_EMAIL_BY_ID, id],
    queryFn: async () => fetchUniformEmail(),
    enabled: !!id && isOpen,
    staleTime: 1000 * 60 * 5,
  })

  useEffect(() => {
    if (isError) {
      toast.error('Something went wrong')
    }
  }, [isError])

  if (isError) return null

  const handleReplyClick = () => {
    setIsReplying((prev) => !prev)
  }

  const handleSuccessReply = async () => {
    if (!data) return
    try {
      await mutateAsync({
        wip_id: isRecovery ? currentFoundWip?.id : undefined,
        client_id: isSales ? currentClient?.id : undefined,
      })
    } catch (e) {
      console.error(e)
    }

    Promise.all([
      queryClient.invalidateQueries([GLOBAL_QUERIES.MAIL_FOLDER.GET]),
      queryClient.invalidateQueries([GLOBAL_QUERIES.GRAPH_API_MESSAGES.GET_BY_FOLDER_ID]),
      queryClient.invalidateQueries([NOTE_QUERY.EMAIL.GET]),
      queryClient.invalidateQueries([GLOBAL_QUERIES.COMBINED_NOTES.GET]),
    ])
  }

  const isTaskNoteLoading = isLoading || isRefetching

  const isGraphUserLoading = isMeLoading || isMeRefetching
  const isMeFound = me && !isGraphUserLoading

  const isMyOutlookEmail = data?.email_type === 'Outlook' && data?.user_id === me?.id

  return (
    <SlideModal
      isOpen={isOpen}
      onClose={onClose}
      style={{ width: '675px' }}
      layoutStyle={{ zIndex: 1000, backgroundColor: 'black' }}
    >
      <div className={styles.taskNoteModal}>
        <span onClick={onClose} className={cls('lnr lnr-indent-decrease', styles.closeIcon)}></span>
        {isTaskNoteLoading ? (
          <Oval
            visible={true}
            height="60"
            width="60"
            color="blue"
            secondaryColor="blue"
            ariaLabel="oval-loading"
            wrapperStyle={{
              position: 'absolute',
              top: '50%',
              right: '50%',
              transform: 'translate(50%,-50%)',
            }}
          />
        ) : null}

        {!isTaskNoteLoading && data ? (
          <div>
            <SubSection>
              <Header mb={4}>Email</Header>
            </SubSection>
            <SubSection>
              <SubHeader>Sent By:</SubHeader>
              <Text>{data.from.emailAddress.address}</Text>
            </SubSection>
            <SubSection>
              <SubHeader>Sent To: </SubHeader>
              <Text>{data.to.emailAddress.address}</Text>
            </SubSection>
            <SubSection>
              <SubHeader>Sent On: </SubHeader>
              <Text>{format(data.sent_at, 'dd/MM/yyyy')}</Text>
            </SubSection>
            <SubSection>
              <SubHeader>Subject: </SubHeader>
              <Text>{data.subject}</Text>
            </SubSection>
            <div>
              <SubHeader>Body: </SubHeader>
              <FrameWrapper html={data.body.content} />
            </div>
            {isGraphUserLoading && isAuthenticated ? (
              <div className="d-flex justify-content-center">
                <Oval
                  visible={true}
                  height="40"
                  width="40"
                  color="blue"
                  secondaryColor="blue"
                  ariaLabel="oval-loading"
                />
              </div>
            ) : null}
            {isMeFound && isMyOutlookEmail ? (
              <NavigationButton
                className={styles.replyButton}
                onClick={handleReplyClick}
                icon={ReplyIcon}
                label="Reply"
              />
            ) : null}
            {isReplying && isMyOutlookEmail ? (
              <ReplyToEmailForm
                subject={data.subject}
                messageId={data.id as string}
                onReplySuccess={handleSuccessReply}
                onCloseClick={handleReplyClick}
                defaultValues={{ to: data.from.emailAddress, comment: '' }}
              />
            ) : null}
          </div>
        ) : null}
      </div>
    </SlideModal>
  )
}

export default EmailModal
