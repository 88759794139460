import React from 'react';
import { radioStyle } from './recoveryDetails';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import StaffService from '../../../services/StaffService';
import { useGetWipTemplates } from '../WipTemplateForm/hooks/get-wip-templates.hook';
import { WipTemplateMode } from '../WipTemplateForm/types/wip-templates.type';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: '100%',
    width: '100%',
    marginLeft: '122px',
    border: '1px solid silver',
    borderRadius: '3px',
  },
}));

const PriorityOption = [
  { value: 'A', label: 'A' },
  { value: 'B', label: 'B' },
  { value: 'C', label: 'C' },
];

const GET_WIP_TEMPLATES_PARAMS = {
  s: JSON.stringify({ mode: WipTemplateMode.RECOVERY }),
  limit: 1000,
}

const WipDetails = ({ register, watch }) => {
  const [staff, setStaff] = React.useState([]);
  const classes = useStyles();
  const staffService = new StaffService();

  React.useEffect(() => {
    staffService.getStaff({}).then((res) => {
      if (res.data) {
        const staff = res.data.map((item) => ({
          value: item.id,
          label: `${item.first_name}  ${item.last_name}`,
        }))
        setStaff(staff)
      }
    })
  }, [])

  const { data: wipTemplates } = useGetWipTemplates(GET_WIP_TEMPLATES_PARAMS)

  return (
    <div
      style={{
        padding: '10px 0',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <h3>Enter Wip Details</h3>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
        {/*  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>*/}
        {/*    <div style={radioStyle}>*/}
        {/*      <input*/}
        {/*        type="radio"*/}
        {/*        required={true}*/}
        {/*        name="wip-landlord"*/}
        {/*        value={true}*/}
        {/*          {...register('recovery_details.is_tenant_vacated', {*/}
        {/*            setValueAs: (value) => (value === 'true' ? true : false),*/}
        {/*          })}*/}
        {/*        {...register(*/}
        {/*          'wip_details.is_property_covered_by_landlord_insurance',*/}
        {/*        )}*/}
        {/*      />*/}
        {/*      Yes*/}
        {/*    </div>*/}
        {/*    <div style={radioStyle}>*/}
        {/*      <input*/}
        {/*        type="radio"*/}
        {/*        required={true}*/}
        {/*        name="wip-landlord"*/}
        {/*        value={false}*/}
        {/*        {...register(*/}
        {/*          'wip_details.is_property_covered_by_landlord_insurance',*/}
        {/*        )}*/}
        {/*      />*/}
        {/*      No*/}
        {/*    </div>*/}
        {/*    <p>Is property covered by landlord insurance?</p>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>*/}
        {/*  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>*/}
        {/*    <div style={radioStyle}>*/}
        {/*      <input*/}
        {/*        type="radio"*/}
        {/*        required={true}*/}
        {/*        name="wip-payed"*/}
        {/*        value={true}*/}
        {/*        {...register('wip_details.is_payed_in_whole')}*/}
        {/*      />*/}
        {/*      Yes*/}
        {/*    </div>*/}
        {/*    <div style={radioStyle}>*/}
        {/*      <input*/}
        {/*        type="radio"*/}
        {/*        required={true}*/}
        {/*        name="wip-payed"*/}
        {/*        value={false}*/}
        {/*        {...register('wip_details.is_payed_in_whole')}*/}
        {/*      />*/}
        {/*      No*/}
        {/*    </div>*/}
        {/*    <p>Is payed in whole?</p>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<label>*/}
        {/*  <span className="form__form-group-label">*/}
        {/*    Insured by <span style={{ color: '#ff0000' }}>*</span>*/}
        {/*  </span>*/}
        {/*  <input*/}
        {/*    type="text"*/}
        {/*    required={true}*/}
        {/*    placeholder={'Insured by'}*/}
        {/*    {...register(`wip_details.insured_by`)}*/}
        {/*  />*/}
        {/*</label>*/}
        {/*<label>*/}
        {/*  <span className="form__form-group-label">*/}
        {/*    Amount Covered <span style={{ color: '#ff0000' }}>*</span>*/}
        {/*  </span>*/}
        {/*  <input*/}
        {/*    type="number"*/}
        {/*    required={true}*/}
        {/*    placeholder={'Amount Covered'}*/}
        {/*    {...register(`wip_details.amount_covered`, { valueAsNumber: true })}*/}
        {/*  />*/}
        {/*</label>*/}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            gap: '5px',
            marginBottom: '20px',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '5px',
              width: '100%',
              marginTop: '25px',
            }}
          >
            <div className="form__form-group-label" style={{ width: '123px' }}>
              WIP Assigned to:
            </div>
            <FormControl
              className={classes.formControl}
              style={{ margin: '0px', padding: '0px', width: '100%' }}
            >
              <Select
                {...register('assign_staff')}
                style={{
                  background: '#f5f9fc',
                  borderRadius: '3px',
                  width: '100%',
                  paddingLeft: '10px',
                }}
                defaultValue={watch('assign_staff')}
                required={true}
              >
                {staff.map(({ value, label }) => {
                  return (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </div>{' '}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            gap: '5px',
            marginBottom: '20px',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '5px',
              width: '100%',
            }}
          >
            <div className="form__form-group-label" style={{ width: '123px' }}>
              Priority:
            </div>
            <FormControl
              className={classes.formControl}
              style={{ margin: '0px', padding: '0px', width: '100%' }}
            >
              <Select
                {...register('priority')}
                style={{
                  background: '#f5f9fc',
                  borderRadius: '3px',
                  width: '100%',
                  paddingLeft: '10px',
                }}
                defaultValue={watch('priority')}
                required={true}
              >
                {PriorityOption.map(({ label, value }) => {
                  return (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
            width: '100%',
          }}
        >
          <div className="form__form-group-label" style={{ width: '123px' }}>
            WIP Templates:
          </div>
          <FormControl
            className={classes.formControl}
            style={{ margin: '0px', padding: '0px', width: '100%' }}
          >
            <Select
              {...register('wip_template')}
              style={{
                background: '#f5f9fc',
                borderRadius: '3px',
                width: '100%',
                paddingLeft: '10px',
              }}
              defaultValue={watch('wip_template')}
            >
              {wipTemplates
                ? wipTemplates.data.map(({ id, name }) => {
                    return (
                      <MenuItem key={`wip-template-option-${id}`} value={id}>
                        {name}
                      </MenuItem>
                    )
                  })
                : null}
            </Select>
          </FormControl>
        </div>
      </div>
    </div>
  );
};

export default WipDetails;
