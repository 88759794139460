import { GLOBAL_QUERIES } from "@src/lib/global-queries";
import { REACT_APP_API_URL } from "@src/lib/global-variables";
import { PaginationResponse } from "@src/types/service.type";
import { useInfiniteQuery } from "@tanstack/react-query";
import axios from "axios";
import { useMemo } from "react";
import { ClaimDetailNote } from "../types/claim-details.type";

export const useGetClaimDetails = (noteParams) => {
  const { data, ...restQuery } = useInfiniteQuery({
    queryKey: [GLOBAL_QUERIES.CLAIM_DETAILS_NOTES.GET, noteParams],
    queryFn: async ({ pageParam }) => {
      const page = pageParam ? pageParam : 1;
      const limit = noteParams.limit;
      const response = await axios.get<PaginationResponse<ClaimDetailNote>>(`${REACT_APP_API_URL}/claim-detail-notes`, { params: { ...noteParams, limit, page } })
      const data = response.data
      return {
        results: data.data,
        page: page < data.pageCount ? page + 1 : undefined
      }
    },
    cacheTime: 0,
    getNextPageParam: (lastPage) => lastPage.page
  });

  const flattenedData = useMemo(
    () => (data ? data?.pages.flatMap(item => item.results) : []),
    [data]
  );

  return { data: flattenedData, ...restQuery }
}