import { FC, useState } from 'react'
import { AutomationItemProps, OpenAutomationModalProps } from '../../types'
import styles from './automation-item.module.scss'
import TaskIcon from '@src/icons/task'
import TrashIcon from '@src/icons/trash'
import NavigationButton from '@components/navigation-button/navigation-button'
import EmailIcon from '@src/icons/email'
import SmsIcon from '@src/icons/sms'
import cls from 'classnames'
import RoundedArrow from '@src/icons/rounded-arrow'
import RoundedLeftLongArrow from '@src/icons/rounded-left-long-arrow'

const AutomationItem: FC<AutomationItemProps> = ({ automation, onAutomationClick, onDeleteAutomationClick }) => {
  const [isDropDownOpen, setIsDropDownOpen] = useState(false)

  const handleItemClick = () => {}

  const isEmailAutomationSet = !!(automation.email_subject && automation.email_template)
  const isSmsAutomationSet = !!automation.sms_template
  const isTaskAutomationSet = !!automation.task_template
  const isAutomationAttached = isEmailAutomationSet || isSmsAutomationSet || isTaskAutomationSet

  const handleDropwownClick = () => {
    if(!isAutomationAttached) return
    setIsDropDownOpen((prev) => !prev)
  }

  const handleDeleteClick = (e) => {
    e.stopPropagation()
    onDeleteAutomationClick(automation.id)
  }

  const handleOpenClick = (e, openAutomationModalProps: OpenAutomationModalProps) => {
    e.stopPropagation()
    onAutomationClick(openAutomationModalProps)
  }

  return (
    <div onClick={handleItemClick}>
      <div className={styles.row} onClick={handleDropwownClick}>
        <div>
          <span className={styles.rowTitleWrapper}>
            {isAutomationAttached ? <RoundedArrow className={styles.dropDownIcon} /> : null}
            <span> {automation.title}</span>
          </span>
        </div>
        <div className={styles.actionWrapper}>
          <NavigationButton
            className={styles.button}
            icon={TrashIcon}
            onClick={handleDeleteClick}
          />
          <NavigationButton
            className={styles.button}
            icon={() => <RoundedLeftLongArrow style={{ transform: 'rotate(180deg)' }} />}
            onClick={(e) => handleOpenClick(e, { id: automation.id })}
          />
        </div>
      </div>
      {isDropDownOpen && isAutomationAttached ? (
        <div className={styles.subRowWraper}>
          {isEmailAutomationSet ? (
            <div
              className={cls(styles.row, styles.sub)}
              onClick={(e) => handleOpenClick(e, { id: automation.id, isEmailOpen: true })}
            >
              <EmailIcon className={styles.subRowIcon} /> Email
            </div>
          ) : null}
          {isSmsAutomationSet ? (
            <div
              className={cls(styles.row, styles.sub)}
              onClick={(e) => handleOpenClick(e, { id: automation.id, isSmsOpen: true })}
            >
              <SmsIcon className={styles.subRowIcon} /> Sms
            </div>
          ) : null}
          {isTaskAutomationSet ? (
            <div
              className={cls(styles.row, styles.sub)}
              onClick={(e) => handleOpenClick(e, { id: automation.id, isTaskOpen: true })}
            >
              <TaskIcon className={styles.subRowIcon} />
              Task
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  )
}

export default AutomationItem
