const requireEnv = (name: string, env: any, defaultValue?: string) => {
  if (!env && !defaultValue) {
    throw new Error(`Missing required environment variable ${name}`)
  }

  return env || defaultValue
}

export const REACT_APP_API_URL = requireEnv('REACT_APP_API_URL', process.env.REACT_APP_API_URL)

export const REACT_APP_APPLICATION_CLIENT_ID = requireEnv('REACT_APP_APPLICATION_CLIENT_ID', process.env.REACT_APP_APPLICATION_CLIENT_ID)

export const REACT_APP_DIRECTORY_TENANT_ID = requireEnv('REACT_APP_DIRECTORY_TENANT_ID', process.env.REACT_APP_DIRECTORY_TENANT_ID)

export const REACT_APP_CLIENT_URL = requireEnv('REACT_APP_CLIENT_URL', process.env.REACT_APP_CLIENT_URL)

export const REACT_APP_XOR_ENCRYPTION_KEY = requireEnv('REACT_APP_XOR_ENCRYPTION_KEY', process.env.REACT_APP_XOR_ENCRYPTION_KEY)

export const SERVICES_API_ROUTES = {
  TASKSERVICE: `${REACT_APP_API_URL}/tasks`,
  GRAPH_API: {
    MAIL: {
      GET_MAIL_FOLDERS: 'https://graph.microsoft.com/v1.0/me/mailFolders'
    }
  }
}
