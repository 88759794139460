import { SelectOption } from "@src/types/component-types";

export enum AustralianState {
  ACT = 'ACT',
  NSW = 'NSW',
  NT = 'NT',
  QLD = 'QLD',
  SA = 'SA',
  TAS = 'TAS',
  VIC = 'VIC',
  WA = 'WA',
}

export const STATE_OPTIONS: SelectOption<AustralianState>[] = [
  { value: AustralianState.ACT, label: 'ACT' },
  { value: AustralianState.NSW, label: 'NSW' },
  { value: AustralianState.NT, label: 'NT' },
  { value: AustralianState.QLD, label: 'QLD' },
  { value: AustralianState.SA, label: 'SA' },
  { value: AustralianState.TAS, label: 'TAS' },
  { value: AustralianState.VIC, label: 'VIC' },
  { value: AustralianState.WA, label: 'WA' },
]

export const FULL_STATE_OPTIONS = [
  { value: 'Australian Capital Territory', label: 'ACT' },
  { value: 'New South Wales', label: 'NSW' },
  { value: 'Northern Territory', label: 'NT' },
  { value: 'Queensland', label: 'QLD' },
  { value: 'South Australia', label: 'SA' },
  { value: 'Tasmania', label: 'TAS' },
  { value: 'Victoria', label: 'VIC' },
  { value: 'Western Australia', label: 'WA' },
];

export const STATE_INTEREST_RATE = {
  [AustralianState.ACT]: 0.1035,
  [AustralianState.NSW]: 0.1035,
  [AustralianState.NT]: 0.0835,
  [AustralianState.QLD]: 0.1035,
  [AustralianState.SA]: 0.0835,
  [AustralianState.TAS]: 0.1035,
  [AustralianState.VIC]: 0.1,
  [AustralianState.WA]: 0.0835,
}


export const STATE_INTEREST_RATE_OPTIONS = [
  { value: `${STATE_INTEREST_RATE[AustralianState.ACT]}-${AustralianState.ACT}`, label: 'ACT' },
  { value: `${STATE_INTEREST_RATE[AustralianState.NSW]}-${AustralianState.NSW}`, label: 'NSW' },
  { value: `${STATE_INTEREST_RATE[AustralianState.NT]}-${AustralianState.NT}`, label: 'NT' },
  { value: `${STATE_INTEREST_RATE[AustralianState.QLD]}-${AustralianState.QLD}`, label: 'QLD' },
  { value: `${STATE_INTEREST_RATE[AustralianState.SA]}-${AustralianState.SA}`, label: 'SA' },
  { value: `${STATE_INTEREST_RATE[AustralianState.TAS]}-${AustralianState.TAS}`, label: 'TAS' },
  { value: `${STATE_INTEREST_RATE[AustralianState.VIC]}-${AustralianState.VIC}`, label: 'VIC' },
  { value: `${STATE_INTEREST_RATE[AustralianState.WA]}-${AustralianState.WA}`, label: 'WA' },

]