import FormInput from '@components/inputs/form-input/form-input.component'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import styles from './reply-to-short-message-form.module.scss'
import { SubHeader } from '@containers/Recovery/Wip/components/UI/text/text.component'
import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { useSendShortMessageMutation } from '@containers/Communication/hooks/mutations/send-short-message.hook'
import { toast } from 'react-toastify'
import { joiResolver } from '@hookform/resolvers/joi'
import { replyToSmsSchema } from '@containers/Communication/schemas/communication.schemas'
import {
  ReplyToFromValues,
  ReplyToShortMessageFormProps,
} from '@containers/Communication/types/communication.type'
import { useQueryClient } from '@tanstack/react-query'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import { ShortMessageTo, SmsType } from '@containers/Communication/types/sms.type'
import { useUserContext } from '@src/context/user-context'
import { useTaskContext } from '@src/context/task-context'

const ReplyToShortMessageForm: FC<ReplyToShortMessageFormProps> = ({ shortMessage }) => {
  const {user} = useUserContext()
  const {isRecovery, isSales, currentWip, selectedCompanyState} = useTaskContext()
  const to = shortMessage.sms_type === SmsType.Incoming ? shortMessage.from : shortMessage.to
  const queryClient = useQueryClient()
  const { mutateAsync, isLoading } = useSendShortMessageMutation()
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ReplyToFromValues>({
    resolver: joiResolver(replyToSmsSchema),
    defaultValues: { body: '' },
  })

  const onSubmit = async (data: ReplyToFromValues) => {
    try {
      await mutateAsync({
        body: data.body,
        to,
        debtor_id: shortMessage.debtor_id || undefined,
        company_contact_id: shortMessage.company_contact_id || undefined,
        to_mode: shortMessage.debtor_id ? ShortMessageTo.Debtor : ShortMessageTo.CompanyContact,
        staff_id_created: user!.id,
        wip_id: isRecovery ? currentWip : undefined,
        company_id: isSales ? selectedCompanyState?.id : undefined,
        task_id: shortMessage.task_id || undefined,
      })
      reset({ body: '' })
      queryClient.resetQueries({ queryKey: [GLOBAL_QUERIES.SMS_CONVERSATION.GET] })
      queryClient.invalidateQueries({ queryKey: [GLOBAL_QUERIES.SMS.GET] })
      queryClient.invalidateQueries({ queryKey: [GLOBAL_QUERIES.COMBINED_NOTES.GET] })
      toast.success('SMS sent')
    } catch (e) {
      toast.error('Something went wrong')
      console.error(e)
    }
  }

  return (
    <form className={styles.formWrapper} onSubmit={handleSubmit(onSubmit)}>
      <FormInput label="To" disabled value={to} />
      <div className={styles.labelWrapper}>
        <SubHeader fontWeight="semibold">Body</SubHeader>
        {errors.body ? <span className={styles.error}>{errors.body.message}</span> : null}
      </div>
      <div className={styles.textSectionWrapper}>
        <textarea
          className={styles.textarea}
          placeholder="Write text here ..."
          {...register('body')}
        />
        <div className={styles.textSectionActionWrapper}>
          <SubmitButton buttonType="submit" loading={isLoading}>
            Reply
          </SubmitButton>
        </div>
      </div>
    </form>
  )
}

export default ReplyToShortMessageForm
