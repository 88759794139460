import { useModal } from '@src/hooks/useModal'
import SendSmsToStaffModal from './send-sms-to-staff-modal.component'
import SmsTable from './sms-table.component'
import { useTaskContext } from '@src/context/task-context'
import SendSmsModalToDebtor from '@containers/Form/RecoveryForm/notifications/send-sms-to-debtor-modal'

const SmsTab = () => {
  const { currentClient, isRecovery, currentWip } = useTaskContext()
  const [isSendSmsModalOpen, openSmsModal, closeSmsModal] = useModal(false)
  return (
    <div
      className="tabs_wrapper card-body"
      style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
    >
      <div className="d-flex justify-content-between mb-3">
        <h3>Sms History</h3>
        <button className="btn btn-secondary" onClick={openSmsModal}>
          Send Sms
        </button>
      </div>
      <SmsTable />
      {isRecovery ? (
        <SendSmsModalToDebtor
          isOpen={isSendSmsModalOpen}
          onClose={closeSmsModal}
          wipId={currentWip}
        />
      ) : (
        <SendSmsToStaffModal
          isOpen={isSendSmsModalOpen}
          onClose={closeSmsModal}
          clientId={currentClient.id}
        />
      )}
    </div>
  )
}

export default SmsTab
