import React, {Component} from 'react'
import {Button, ButtonToolbar, Card, CardBody, Col, Modal, Row} from 'reactstrap';
import Select from 'react-select'
import {ContentState, EditorState} from 'draft-js';
import {Editor} from '@tinymce/tinymce-react';
import {withRouter} from 'react-router';
import Services from '../../../../shared/Services.js';
import htmlToDraft from 'html-to-draftjs';
import SimpleReactValidator from 'simple-react-validator';
import ModalComponent from '../../../../shared/components/ModalCustom'
import TempleatsService from '../../../../services/TempleatsService';
import { UserContext } from '../../../../context/user-context';

class EditTemplate extends Component {
  static contextType = UserContext

  constructor(props) {
    super(props)
    this.state = {
      editorState: EditorState.createEmpty(),
      title: "",
      subject: "",
      cc: '',
      bodyString: "",
      catagory: "",
      subCategory: {value: "New templateEmail", label: "New templateEmail"},
      typeList: [],
      subTypeList: [],
      subType: [],
      currentId: "",
      accessErrorModal: false,
      isPreview: false,
      logoImageHeader: "",
      text: null,
      valueText: "",
    };
    this.services = new Services();
    this.validator = new SimpleReactValidator();
    this.temp = new TempleatsService();
  }

  componentDidMount() {
    this.getTemplateTypeList();
    this.toDataURL('./image/logo.png')
      .then(dataUrl => {
        this.setState({logoImageHeader: dataUrl})
      })
  }

  getTemplateTypeList = () => {
    this.temp.getTypeTempEmail().then((res) => {
      if (res.status === 200) {
        const getData = res.data.data;
        let item = getData && getData.map((item) => {
            return {
              value: item.id,
              label: item.name,
            };
          });
        this.setState({
          typeList: item
        })
      } else if (res.statusCode === 401 && res.responseType === "UNAUTHORIZED") {
        this.setState({accessErrorModal: true})
      } else {
        this.services.errorToast(res.message);
      }
    })
  }
  accessErrorModalClose = () => {
    this.setState({accessErrorModal: false});
    this.props.history.push("/dashboard")
  }

  handleChange = option => {

    this.setState({catagory: {value: option.value, label: option.label}});
    this.setState({
      editorState: EditorState.createEmpty(),
      title: "",
      subject: '',
      cc: '',
      bodyString: "",
      subCategory: {value: "New templateEmail", label: "New templateEmail"}
    })
    const params = {
      limit: 100
    };
    this.temp.getTempEmail(params).then( (res) => {

      if (res.status === 200) {
        const item = [{value: "New templateEmail", label: "New templateEmail"}];
        res.data && res.data.data.map(element => {
          item.push({value: element.id, label: element.title})
        });
        this.setState({
          subTypeList: item,
          subType: res.data && res.data.data
        })
      } else {
        this.services.errorToast(res.message);
      }
    })
  };
  handleChangeSub = option => {
    this.setState({subCategory: {value: option.value, label: option.label}});
    if (option.value !== "New templateEmail") {
      const selectedTemplate = this.state.subType.find(_ => _.id === option.value);
      const blocksFromHtml = htmlToDraft(selectedTemplate.body);
      const {contentBlocks, entityMap} = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      const editorState = EditorState.createWithContent(contentState);
      this.setState({
        currentId: selectedTemplate.id,
        title: selectedTemplate.title,
        bodyString: selectedTemplate.body,
        editorState: editorState
      })
    } else {
      this.setState({editorState: EditorState.createEmpty(), title: "", currentId: ""})
    }
  };


  changeInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  cancle = () => {
    this.props.history.push('/manage-templates')
  }
  handleSubmit = (e) => {
    e.preventDefault()
    if (this.validator.allValid()) {
      if (this.state.currentId === "" && this.state.subCategory.value === "New templateEmail") {
        let data;
        if (this.state.catagory.value === 'General - AGENT Email' || this.state.catagory.value === 'EMAIL Debtor' || this.state.catagory.value === 'EMAIL Agent') {
          data = {
            title: this.state.title,
            body: this.state.valueText,
            subject: this.state.subject,
            cc: this.state.cc,
            crby: this.state.user._id,
            type: this.state.catagory.value
          }
        } else {
          const {user} = this.context;
          data = {
            title: this.state.title,
            body: this.state.valueText,
            staff_id_created: user.id,
            subject: this.state.title,
          }
        }

        this.temp.addTempEmail(data).then( (res) => {
          if (res.status === 201) {
            this.services.successToast('templateEmail Added Successfully');
            this.props.history.push('/manage-templates')
          } else {
            this.services.errorToast(res.message);
          }
        })
      } else {
        let datas;
        if (this.state.catagory.value === 'General - AGENT Email' || this.state.catagory.value === 'EMAIL Debtor' || this.state.catagory.value === 'EMAIL Agent') {
          datas = {
            em_Id: this.state.currentId,
            title: this.state.title,
            body: this.state.valueText,
            subject: this.state.subject,
            cc: this.state.cc,
            // crby: this.state.user._id,
            //type: this.state.catagory.value
          }
        } else {
          const {user} = this.context;
          const params = {
            id: this.state.currentId,
            title: this.state.title,
            body: this.state.valueText,
            staff_id_modified: user.id,
          }
          this.temp.editTempEmail(params).then( (res) => {
            if (res.status === 200) {
              this.services.successToast('templateEmail Updated Successfully');
              this.props.history.push('/manage-templates')
            } else {
              this.services.errorToast(res.message);
            }
          })
        }
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  printProp = () => {
    const content = document.getElementById("print_templateview");
    const pri = document.getElementById("ifmcontentstoprint").contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  }

  toDataURL = url => fetch(url)
    .then(response => response.blob())
    .then(blob => new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.onerror = reject
      reader.readAsDataURL(blob)
    }))

  render() {

    return (
      <div className="container page-body-wrapper position-relative pb-5">
        <div>
          <div>
            <div >
              <h3 className="page-title">Edit Template</h3>
            </div>
          </div>
          <Card className="h-auto">
            <CardBody>
              <form className="form form--horizontal">
                <div className="form__form-group">
                  <span className="form__form-group-label mb-1" style={{width: "100%"}}>Select Template Type<span
                    style={{color: "#ff0000"}}>*</span></span>
                  <Select
                    name="role"
                    type="text"
                    //  value={this.state.role}
                    options={this.state.typeList}
                    onChange={this.handleChange}
                    classNamePrefix="react-select"
                    className="react-select"
                  />
                  <span
                    className="form__form-group-error">{this.validator.message('templateEmail Type', this.state.catagory, 'required')}</span>
                  {/* <Link className="btn btn-secondary mt-4" to="/add-template">Add templateEmail</Link> */}
                </div>

                {this.state.catagory &&
                  <>
                    <div className="form__form-group">
                      <span className="form__form-group-label mb-1"> Select Template</span>

                      {/* <span className="form__form-group-label"> Title<span style={{color:"#ff0000"}}>*</span></span> */}
                      <Select
                        name="role"
                        type="text"
                        value={this.state.subCategory}
                        options={this.state.subTypeList}
                        onChange={this.handleChangeSub}
                        classNamePrefix="react-select"
                        className="react-select"
                      />
                    </div>


                    {
                      this.state.catagory.value === 'General - AGENT Email' || this.state.catagory.value === 'EMAIL Debtor' || this.state.catagory.value === 'EMAIL Agent' ?

                        <>
                          <div className="form__form-group">
                          <span className="form__form-group-label mb-1"> Email Subject<span
                            style={{color: "#ff0000"}}>*</span></span>
                            <input
                              name="subject"
                              type="text"
                              placeholder="Email Subject"
                              value={this.state.subject}
                              onChange={this.changeInput}
                            />
                            <span
                              className="form__form-group-error">{this.validator.message('Subject', this.state.subject, 'required')}</span>
                          </div>


                          <div className="form__form-group">
                          <span className="form__form-group-label mb-1"> Auto BCC<span
                            style={{color: "#ff0000"}}>*</span></span>
                            <input
                              name="cc"
                              type="text"
                              placeholder="Auto BCC"
                              value={this.state.cc}
                              onChange={this.changeInput}
                            />
                            {/* <Multiselect
                          // options={this.stt}
                          // selectedValues={selectedDivisions}
                          onSelect={this.onSelect}
                          onRemove={this.onRemove}
                          displayValue="name"
                          className="dropdown_icon"
                        /> */}
                            <span
                              className="form__form-group-error">{this.validator.message('CC', this.state.cc, 'required')}</span>
                          </div>
                        </> : ''

                    }

                    <div className="form__form-group">
                      <span className="form__form-group-label mb-1"> Title<span
                        style={{color: "#ff0000"}}>*</span></span>
                      <input
                        name="title"
                        type="text"
                        placeholder="Template Name"
                        value={this.state.title}
                        onChange={this.changeInput}
                      />
                      <span
                        className="form__form-group-error">{this.validator.message('Title', this.state.title, 'required')}</span>

                    </div>
                    <div className="text-editor" style={{zIndex: "-5px"}}>

                      <Editor apiKey="qwxo2mvltn5dflrlsa3iri4nw9z0uum2nl62s3f5rd4nbf0f"
                              initialValue={this.state.bodyString}
                              value={this.state.valueText}
                              onEditorChange={(newValue) => {
                                this.setState({
                                  valueText: newValue
                                })
                              }}
                              init={{
                                plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
                                imagetools_cors_hosts: ['picsum.photos'],
                                menubar: 'file edit view insert format tools table help custom',
                                menu: {
                                  custom: {
                                    title: 'Variables',
                                    items: 'title fullName firstNames lastName loginLink loginButton unsubscribe'
                                  }
                                },
                                toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
                                toolbar_sticky: true,
                                autosave_ask_before_unload: true,
                                autosave_interval: '30s',
                                autosave_prefix: '{path}{query}-{id}-',
                                autosave_restore_when_empty: false,
                                autosave_retention: '2m',
                                image_advtab: true,
                                link_list: [
                                  {title: 'My page 1', value: 'https://www.tiny.cloud'},
                                  {title: 'My page 2', value: 'http://www.moxiecode.com'}
                                ],
                                image_list: [
                                  {title: 'My page 1', value: 'https://www.tiny.cloud'},
                                  {title: 'My page 2', value: 'http://www.moxiecode.com'}
                                ],
                                image_class_list: [
                                  {title: 'None', value: ''},
                                  {title: 'Some class', value: 'class-name'}
                                ],
                                importcss_append: true,
                                file_picker_callback: function (callback, value, meta) {
                                  /* Provide file and text for the link dialog */
                                  if (meta.filetype === 'file') {
                                    callback('https://www.google.com/logos/google.jpg', {text: 'My text'});
                                  }

                                  /* Provide image and alt text for the image dialog */
                                  if (meta.filetype === 'image') {
                                    callback('https://www.google.com/logos/google.jpg', {alt: 'My alt text'});
                                  }

                                  /* Provide alternative source and posted for the media dialog */
                                  if (meta.filetype === 'media') {
                                    callback('movie.mp4', {
                                      source2: 'alt.ogg',
                                      poster: 'https://www.google.com/logos/google.jpg'
                                    });
                                  }
                                },
                                template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
                                template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
                                height: 600,
                                image_caption: true,
                                quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                                noneditable_noneditable_class: 'mceNonEditable',
                                toolbar_mode: 'sliding',
                                contextmenu: 'link image imagetools table',
                                skin: 'oxide',
                                content_css: 'default',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                setup: function (editor) {
                                  const toggleState = false;

                                  editor.ui.registry.addMenuItem('title', {
                                    text: 'Title',
                                    onAction: function () {
                                      editor.insertContent('%recipient.title%');
                                    }
                                  });

                                  editor.ui.registry.addMenuItem('fullName', {
                                    text: 'Full Name',
                                    onAction: function () {
                                      editor.insertContent('%recipient.full_name%');
                                    }
                                  });

                                  editor.ui.registry.addMenuItem('firstNames', {
                                    text: 'First Names',
                                    onAction: function () {
                                      editor.insertContent('%recipient.first_names%');
                                    }
                                  });

                                  editor.ui.registry.addMenuItem('lastName', {
                                    text: 'Last Name',
                                    onAction: function () {
                                      editor.insertContent('%recipient.last_name%');
                                    }
                                  });
                                  editor.ui.registry.addMenuItem('loginLink', {
                                    text: 'Login Link',
                                    onAction: function () {
                                      editor.insertContent('<a href="https://www.ezpurim.com/login?uuid=%recipient.uuid%">login link</a>');
                                    }
                                  });
                                  editor.ui.registry.addMenuItem('loginButton', {
                                    text: 'Login Button',
                                    onAction: function () {
                                      editor.insertContent('<a href="https://www.ezpurim.com/login?uuid=%recipient.uuid%" class="btn-primary" style="font-family: Helvetica Neue,Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; color: #FFF; text-decoration: none; line-height: 2em; font-weight: bold; text-align: center; cursor: pointer; display: inline-block; border-radius: 5px; text-transform: capitalize; background-color: #348eda; margin: 0; border-color: #348eda; border-style: solid; border-width: 10px 20px;">Login Button</a>');
                                    }
                                  });

                                  editor.ui.registry.addMenuItem('unsubscribe', {
                                    text: 'Unsubscribe',
                                    onAction: function () {
                                      editor.insertContent('<a href="%mailing_list_unsubscribe_url%">Unsubscribe</a>');
                                    }
                                  });
                                },
                              }}
                      />
                    </div>
                    <ButtonToolbar>
                      <Button color="primary" onClick={this.handleSubmit.bind(this)}>Save</Button>
                      <Button type="button" onClick={this.cancle}>
                        Cancel
                      </Button>
                      {this.state.catagory && this.state.bodyString.length > 8 &&
                        <Button type="button" onClick={() => this.setState({isPreview: true})}>
                          Preview
                        </Button>
                      }
                    </ButtonToolbar>
                  </>
                }

              </form>
            </CardBody>
          </Card>
          {this.state.accessErrorModal ?
            <ModalComponent modal={this.state.accessErrorModal} color="danger" isCancel={false}
                            message="You do not have access to this, please contact your manager for assistance"
                            onConfirm={(e) => this.accessErrorModalClose(e)}
                            onClose={(e) => this.accessErrorModalClose(e)}/> : null}

          <Modal
            isOpen={this.state.isPreview} size="lg" style={{maxWidth: '800px', width: '95%'}}
            className="theme-light"
          >
            <div className="modal__header pt-0">
              <div className="text-right">
                <button className="btn btn-secondary mr-2" onClick={this.printProp}>Print</button>
                <button className="btn btn-secondary" type="button"
                        onClick={() => this.setState({isPreview: false})}>Close
                </button>
              </div>
              <h5 className="text-modal  modal__title pt-0">{"Preview"}</h5>
            </div>

            <div className="modal__body pt-5">
              <p
                dangerouslySetInnerHTML={{
                  __html: this.state.bodyString
                }}/>
            </div>
          </Modal>
          <div style={{height: "100px", overflow: "auto", opacity: "0"}}>
            <div id="print_templateview">
              <div style={{width: "700px", margin: "50px auto", padding: "0 40px"}}>
                <div className="preview_logo text-left">
                  <img src={this.state.logoImageHeader} alt="logo" style={{maxWidth: "200px"}}/>
                </div>

                <p
                  dangerouslySetInnerHTML={{
                    __html: this.state.bodyString
                  }}/>
              </div>
            </div>
          </div>
          <iframe id="ifmcontentstoprint" style={{height: '0px', width: '0px', position: 'absolute'}}/>
        </div>
      </div>
    )
  }
}

export default (withRouter((EditTemplate)));
