import React from 'react';
import {Card, CardBody, Col, Container, Row, ButtonGroup, UncontrolledTooltip, Button} from 'reactstrap';
import {Link} from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TablePagination from '@material-ui/core/TablePagination';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import WIPCostHead from './WIPCostHead';
import Services from '../../../../shared/Services.js';
import {withRouter} from 'react-router';

// import classNames from 'classnames';

function getSorting(order, orderBy) {
  if (order === 'desc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
}

class WIPCosts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      WIPData: [],
      order: 'asc',
      orderBy: '',
      selected: new Map([]),
      page: 0,
      rowsPerPage: 5,
      wipUserId: localStorage.getItem("wipUserId"),
      accessErrorModal: false,
      stateId: "",
    };

    this.services = new Services();
    this.EditStaff = this.EditStaff.bind(this);
  }

  componentDidMount() {
    localStorage.setItem('editWIPCostId', '');
    this.getWIP();
  }


  getWIP() {
    const data = {
      user_Id: this.state.wipUserId
      // limit:this.state.rowsPerPage,
      // skip:this.state.page
    };
    this.services.getWPIReCost(data, (res) => {
      if (res.status === 200) {
        this.setState({WIPData: res.data.data.list})
      } else if (res.statusCode === 401 && res.responseType === "UNAUTHORIZED") {
        this.setState({accessErrorModal: true})
      }
    })
  }

  // pagination and Sorting
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    const {orderBy: stateOrderBy, order: stateOrder} = this.state;

    if (stateOrderBy === property && stateOrder === 'desc') {
      order = 'asc';
    }

    this.setState({order, orderBy});
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      const {data} = this.state;
      const newSelected = new Map();
      data.map(n => newSelected.set(n.id, true));
      this.setState({selected: newSelected});
      return;
    }
    this.setState({selected: new Map([])});
  };

  handleClick = (event, id) => {
    const {selected} = this.state;
    const newSelected = new Map(selected);
    const value = newSelected.get(id);
    let isActive = true;
    if (value) {
      isActive = false;
    }
    newSelected.set(id, isActive);
    this.setState({selected: newSelected});
  };

  handleChangePage = (event, page) => {
    this.setState({page});
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({rowsPerPage: event.target.value});
  };

  handleDeleteSelected = () => {
    const {data} = this.state;
    let copyData = [...data];
    const {selected} = this.state;

    for (let i = 0; i < [...selected].filter(el => el[1]).length; i += 1) {
      copyData = copyData.filter(obj => obj.id !== selected[i]);
    }

    this.setState({data: copyData, selected: new Map([])});
  };

  isSelected = (id) => {
    const {selected} = this.state;
    return !!selected.get(id);
  };

  EditStaff(e, d) {
    localStorage.setItem('editWIPCostId', d._id);
    this.props.history.push('/wip-costs-add');
  }

  render() {
    const {
      WIPData, selected, order, orderBy, page, rowsPerPage
    } = this.state;

    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">WIP Costs</h3>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <div style={{paddingTop: "42px"}}>

                </div>
                <div className="material-table__toolbar-wrap" style={{right: "11px"}}>
                  <Link className="btn btn-secondary" to="/wip-costs-add">Add</Link>
                </div>
                <div className="material-table__wrap">
                  <Table className="material-table">
                    <WIPCostHead
                      numSelected={[...selected].filter(el => el[1]).length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={this.handleSelectAllClick}
                      onRequestSort={this.handleRequestSort}
                      rowCount={WIPData.length}
                    />
                    <TableBody>
                      {WIPData
                        .sort(getSorting(order, orderBy))
                        .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
                        .map((d, i) => {
                          const isSelected = this.isSelected(d.id);

                          return (
                            <TableRow
                              className="material-table__row"
                              role="checkbox"
                              onClick={event => this.handleClick(event, d.id)}
                              aria-checked={isSelected}
                              tabIndex={-1}
                              key={i}
                              selected={isSelected}
                            >
                              <TableCell
                                className="material-table__cell material-table__cell-right"
                                component="th"
                                scope="row"
                                padding="none"
                              >
                                {d.state}
                              </TableCell>

                              <TableCell
                                className="material-table__cell material-table__cell-right"
                              >{d.offeramount}
                              </TableCell>
                              <TableCell
                                className="material-table__cell material-table__cell-right"
                              >{d.total}
                              </TableCell>

                              <TableCell className="material-table__cell material-table__cell-right">
                                <ButtonGroup className="btn-group--icons" dir="ltr">
                                  <Button outline id="Edit" onClick={(e) => this.EditStaff(e, d)}><span
                                    className="lnr lnr-pencil"/></Button>
                                </ButtonGroup>
                                <UncontrolledTooltip placement="bottom" target="Edit">
                                  Edit
                                </UncontrolledTooltip>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {/* {WIPData.length <= 0 && (
                             <TableRow style={{ height: 49 }}>
                              <TableCell colSpan={4}>
                                There is no data please click here to <Link className="btn btn-secondary" to="/wip-costs-add">Add</Link>  
                              </TableCell>
                             </TableRow>
                           )} */}
                    </TableBody>
                  </Table>
                </div>
                <TablePagination
                  component="div"
                  className="material-table__pagination"
                  count={WIPData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{'aria-label': 'Previous Page'}}
                  nextIconButtonProps={{'aria-label': 'Next Page'}}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                  rowsPerPageOptions={[5, 10, 15]}
                  dir="ltr"
                  SelectProps={{
                    inputProps: {'aria-label': 'rows per page'},
                    native: true,
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>


      </Container>

    );
  }
}


export default withRouter(WIPCosts)