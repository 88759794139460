import { format } from 'date-fns'
import { FC } from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import Column from '@containers/Setting/components/Contacts/taskboard/column'
import styles from './global-kanban-board.module.scss'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import { useQuery } from '@tanstack/react-query'
import { REACT_APP_API_URL } from '@src/lib/global-variables'
import axios from 'axios'
import { getParams } from '../../utils/global-kanban.utils'
import { WeeklyKanbanBoardProps } from '../../types/global-kanban-board.type'
import { generateWeeklyColumns } from '../../utils/generate-columns.utils'

const WeeklyKanbanBoard: FC<WeeklyKanbanBoardProps> = ({ values }) => {
  const fetchTasks = async () => {
    const {dateRange, assignedToMe} = values
    const params = getParams({ dateRange, assignedToMe })

    const response = await axios.get(`${REACT_APP_API_URL}/tasks`, { params })

    const data = response.data.data
    const newColumns = [...generateWeeklyColumns(values.dateRange)]

    for (const task of data) {
      const columnIndex = Number(format(task.time_created, 'i')) - 1
      newColumns[columnIndex].tasks.push(task)
    }

    return newColumns
  }

  const {data: columns} = useQuery({
    queryKey: [GLOBAL_QUERIES.TASKSERVICE.GET_WEEKLY, values],
    queryFn: () => fetchTasks(),
    initialData: generateWeeklyColumns(values.dateRange) 
  })

  return (
    <DragDropContext onDragEnd={() => {}}>
      <div className={styles.taskBoard}>
        {columns.map((column, i) => (
          <Column
            id={i}
            key={`${column.title}-${i}`}
            columnName={column.title}
            columnColor={'#06b6d4'}
            tasks={column.tasks}
            isAssigned={false}
            isArchived={false}
            isUnassigned={false}
            filterByStaff={[]}
            filterByType={[]}
            filterByContact={[]}
            step={i + 1}
            isAutomationColumn={false}
            automationAmount={0}
          />
        ))}
      </div>
    </DragDropContext>
  )
}

export default WeeklyKanbanBoard
