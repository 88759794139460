import { DataGridService } from '@components/data-grid/data-grid.service'
import { DataGridColumn } from '@components/data-grid/data-grid.type'
import { MergePropertyRow } from '@containers/Sales/Properties/types/property.type'
import React, { useState } from 'react'

type ParsedRow = {
  address1: string
  address2: string
  address3?: string
  firstName: string
  lastName: string
  ownerEmail: string
  ownerPhone: string
  postcode: string
  state: string
  suburb: string
}

interface PropertiesContextType {
  header: DataGridColumn[] | null
  parsedRows: ParsedRow[] | null
  mergePropertyRows: MergePropertyRow[]
  isErrorsResolved: boolean
  resolvedRowsIds: number[]
  setIsErrorsResolved: (val: boolean) => void
  setHeader: (header: DataGridColumn[]) => void
  setRows: (rows: any[]) => void,
  setMergePropertiesRows: (rows: MergePropertyRow[]) => void
  setResolvedRowId: (id: number) => void,
  setResolvedRowsIds:(ids:number[])=>void
}

const _noop = () => {
  throw new Error('usePropertiesContext() must be used within a UserProvider')
}

export const PropertiesContext = React.createContext<PropertiesContextType>({
  header: null,
  parsedRows: null,
  mergePropertyRows: [],
  isErrorsResolved: false,
  resolvedRowsIds: [],
  setResolvedRowId: _noop,
  setResolvedRowsIds:_noop,
  setIsErrorsResolved: _noop,
  setHeader: _noop,
  setRows: _noop,
  setMergePropertiesRows: _noop,
})

type PropertiesProps = React.PropsWithChildren

export const PropertiesProvider = ({ children }: PropertiesProps) => {
  const [isErrorsResolved, setIsErrorsResolved] = useState(false)
  const [resolvedRowsIds, setResolvedRowsIds] = useState<number[]>([])
  const [header, setHeader] = useState<PropertiesContextType['header']>(null)
  const [rows, setRows] = useState<any[]>([])
  const [parsedRows, setParsedRows] = useState<PropertiesContextType['parsedRows']>([])
  const [mergePropertyRows, setMergePropertiesRows] = useState<
    PropertiesContextType['mergePropertyRows']
  >([])

  const setResolvedRowId = (id: number) => {
    if (resolvedRowsIds.includes(id)) {
      const filteredIds = resolvedRowsIds.filter((v) => v === id)
      setResolvedRowsIds(filteredIds)
      return
    }
    setResolvedRowsIds((prev) => [...prev, id])
  }

  React.useEffect(() => {
    if (rows && header) {
      setParsedRows(DataGridService.transformDataGridRowsToData(rows, header) as ParsedRow[])
    }
  }, [rows, header])

  const value: PropertiesContextType = React.useMemo(
    () => ({
      header,
      parsedRows,
      mergePropertyRows,
      isErrorsResolved,
      resolvedRowsIds,
      setResolvedRowId,
      setResolvedRowsIds,
      setIsErrorsResolved,
      setHeader,
      setRows,
      setMergePropertiesRows,
    }),
    [header, parsedRows, mergePropertyRows, isErrorsResolved, resolvedRowsIds],
  )

  return <PropertiesContext.Provider value={value}>{children}</PropertiesContext.Provider>
}

export const usePropertiesContext = (): PropertiesContextType => {
  const context = React.useContext(PropertiesContext)
  if (!context) {
    throw new Error('usePropertiesContext must be used within a PropertiesProvider')
  }
  return context
}
