import { useMutation } from "@tanstack/react-query"
import { DebtorAddressHistoryService } from "../../services/debtor-address-history.service"
import { CreateDebtorAddress } from "../../types/debtor-address.type"

export const useCreateDebtorAddressMutation = () => {

  const createDebtorAddress = (address: CreateDebtorAddress) => {
    const debtorAddressHistoryService = new DebtorAddressHistoryService()
    return debtorAddressHistoryService.createDebtorAddress(address)
  }

  const mutation = useMutation(createDebtorAddress)

  return mutation
}