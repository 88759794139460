import moment from 'moment'
import TaskTable from './task-table.component'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'

const TodayTaskTable = () => {
  const getTodayTaskParams = () => {
    const initDate = moment(new Date())
    const startDate = initDate.clone().startOf('day')
    const endDate = startDate.clone().add(1, 'days')

    return {
      sort: 'time_created,DESC',
      s: JSON.stringify({ time_created: { $gt: startDate, $lt: endDate } }),
    }
  }

  const todayTaskParams = getTodayTaskParams()

  return <TaskTable queryKey={GLOBAL_QUERIES.TASKSERVICE.GET_TODAY} params={todayTaskParams} />
}

export default TodayTaskTable
