import { Params } from "@src/types/service.type";
import { DebtorLegalTribunalService } from "../../services/debtor-legal-tribunal.service";
import { CreateDebtorLegalTribunalData } from "../../types/debtor-legal-tribunal.type";
import { useMutation } from "@tanstack/react-query";
import { DeepPartial } from "react-hook-form";
import { DebtorLegalTribunal } from "../../types/debtor-legal.type";


export const useCreateDebtorLegalTribunalMutation = () => {

  const createDebtorLegalTribunal = async (data: CreateDebtorLegalTribunalData, params?: Params) => {
    const debtorLegalTribunalService = new DebtorLegalTribunalService();
    return debtorLegalTribunalService.createDebtorLegalTribunal(data, params);
  }

  const mutation = useMutation({ mutationFn: ({ data, params }: { data: CreateDebtorLegalTribunalData, params?: Params }) => createDebtorLegalTribunal(data, params) });

  return mutation;
}

export const useUpdateDebtorLegalTribunalMutation = () => {

  const updateDebtorLegalTribunal = async (debtorLegalTribunalId: number, data: DeepPartial<DebtorLegalTribunal>, params?: Params) => {
    const debtorLegalTribunalService = new DebtorLegalTribunalService();
    return debtorLegalTribunalService.updateDebtorLegalTribunal(debtorLegalTribunalId, data, params);
  }

  const mutation = useMutation({ mutationFn: ({ debtorLegalTribunalId, data, params }: { debtorLegalTribunalId: number, data: DeepPartial<DebtorLegalTribunal>, params?: Params }) => updateDebtorLegalTribunal(debtorLegalTribunalId, data, params) });

  return mutation;
}

export const useDeleteDebtorLegalTribunalMutation = () => {

  const deleteDebtorLegalTribunal = async (debtorLegalTribunalId: number, params?: Params) => {
    const debtorLegalTribunalService = new DebtorLegalTribunalService();
    return debtorLegalTribunalService.deleteDebtorLegalTribunal(debtorLegalTribunalId, params);
  }

  const mutation = useMutation({ mutationFn: ({ debtorLegalTribunalId, params }: { debtorLegalTribunalId: number, params?: Params }) => deleteDebtorLegalTribunal(debtorLegalTribunalId, params) });

  return mutation;
}