import { FC } from 'react'
import { DebtorLegal, DebtorLegalDefendant } from '../../../types/debtor-legal.type'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import { getDebtorLegalByIdQueryKey } from '../../../utils/legal-query-key-factory.utils'
import Modal from '@components/modal'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import styles from './debtor-defendant-modal.module.scss'
import { BaseEditModalProps } from '@src/types/component-types'
import EditDebtorLegalDefendantForm from '../../forms/debtor-legal-defendant-form/edit-debtor-legal-defendant-form.component'
import ModalHeader from '@components/modal-header.component'

const EditDebtorLegalDefendantModal: FC<BaseEditModalProps<DebtorLegalDefendant>> = ({
  entity: debtorLegalDefendant,
  ...modalProps
}) => {
  const queryClient = useQueryClient()
  const handleSuccessfulSubmit = (debtorLegalDefendant: DebtorLegalDefendant) => {
    queryClient.setQueryData<DebtorLegal>(
      getDebtorLegalByIdQueryKey(debtorLegalDefendant.debtorLegalId),
      (prevData) =>
        prevData
          ? {
              ...prevData,
              defendants: prevData.defendants.map((d) =>
                d.id === debtorLegalDefendant.id ? debtorLegalDefendant : d,
              ),
            }
          : undefined,
    )
    toast.success('Defendant updated successfully')
    modalProps.onClose()
  }

  return (
    <Modal className={styles.modalWrapper} {...modalProps} withBtns={false}>
      <ModalHeader onCloseClick={modalProps.onClose} />
      <Header mb={4}>Edit Defendant Details</Header>
      <EditDebtorLegalDefendantForm
        onSuccessSubmit={handleSuccessfulSubmit}
        entity={debtorLegalDefendant}
      />
    </Modal>
  )
}

export default EditDebtorLegalDefendantModal
