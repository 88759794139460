export enum CombinedNoteType {
  LOCAL_EMAIL = 'localEmail',
  OUTLOOK_EMAIL = 'outlookEmail',
  GENERAL_NOTE = 'generalNote',
  TASK_NOTE = 'taskNote',
  SMS = 'sms',
  CALL = 'call'
}

export enum CombinedNotesMode {
  TASK = 'task',
  WIP = 'wip',
  COMPANY = 'company'
}

export interface CombinedNote {
  id: number;
  important: boolean;
  title: string;
  type: CombinedNoteType;
  created_user_id: string | number | null;
  createdAt: Date;
}

export interface GetCombinedNotesParams {
  task_id?: number;
  company_id?: number;
  wip_id?: number;
  mode: CombinedNotesMode;
  page: number;
  limit: number;
  type?: `${CombinedNoteType}`[]
}