import * as React from "react"
const ArchiveIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    className="YIUegm7fh_CpJbivTu6B MnxxlQlR1H0xJuMEE8Yr"
    viewBox="0 0 20 20"
    {...props}
  >
    <path d="M4 3a2 2 0 1 0 0 4h12a2 2 0 1 0 0-4H4z" />
    <path
      fillRule="evenodd"
      d="M3 8h14v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8zm5 3a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1z"
      clipRule="evenodd"
    />
  </svg>
)
export default ArchiveIcon
