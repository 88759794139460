import BaseService from './BaseService'
import axios from 'axios'

export default class UploadsService extends BaseService {
  constructor() {
    super()
    this.apiUrl = this.apiUrl + '/uploads/file-recovery'
  }

  async uploadsFile(params) {
    try {
      const formData = new FormData()
      formData.append('file', params)
      const response = await axios.post(this.apiUrl, formData)
      if (response.status !== 201) {
        throw new Error('Error occured fetching emails')
      }

      return response
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async downloadFile(file) {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/uploads/file-recovery/${file.file_path}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('user.accessToken')}`,
        },
      },
    )
    if (response.status === 200) {
      const blob = await response.blob()
      const downloadUrl = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = downloadUrl
      link.download = file.file_name
      document.body.appendChild(link)
      link.click()
      link.remove()
    }
  }

  async deleteFile(file) {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/uploads/file-recovery/${file.file_path}`,
      )

      return response
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          //throw new Error(error.response.data);
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async uploadsFileWip(params) {
    try {
      const formData = new FormData()
      formData.append('file', params)
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/uploads/file-wip`,
        formData,
      )
      if (response.status !== 201) {
        throw new Error('Error occured fetching emails')
      }

      return response
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          throw new Error(error.response.data)
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }

  async downloadFileWip(file) {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/uploads/file-wip/${file.file_path}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('user.accessToken')}`,
        },
      },
    )
    if (response.status === 200) {
      const blob = await response.blob()
      const downloadUrl = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = downloadUrl
      link.download = file.file_name
      document.body.appendChild(link)
      link.click()
      link.remove()
    }
  }

  async deleteFileWip(file) {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/uploads/file-wip/${file.file_path}`,
      )

      return response
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message)
      } else {
        if (error.response) {
          //throw new Error(error.response.data);
        }
        if (!error.response) {
          throw new Error(error)
        }
      }
    }
  }
}
