import { GLOBAL_QUERIES } from "@src/lib/global-queries";
import { Params } from "@src/types/service.type";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { ClaimDetailNoteService } from "../services/claim-detail-note.service";

export const useGetClaimDetails = (noteParams: Params = {}) => {
  const { data, ...restQuery } = useInfiniteQuery({
    queryKey: [GLOBAL_QUERIES.CLAIM_DETAILS_NOTES.GET, noteParams],
    queryFn: async ({ pageParam }) => {
      const claimDetailNoteService = new ClaimDetailNoteService()
      const page = pageParam ? pageParam : 1;
      const limit = noteParams.limit;
      const data = await claimDetailNoteService.getClaimDetailNotes({ ...noteParams, limit, page })
      return {
        results: data.data,
        page: page < data.pageCount ? page + 1 : undefined
      }
    },
    cacheTime: 0,
    getNextPageParam: (lastPage) => lastPage.page
  });

  const flattenedData = useMemo(
    () => (data ? data?.pages.flatMap(item => item.results) : []),
    [data]
  );

  return { data: flattenedData, ...restQuery }
}