import { FC } from 'react'
import ClaimDetailsForm from './claim-details-form/claim-details-form.component'
import ClaimDetailsNoteList from './claim-details-note-list/claim-details-note-list'
import { ClaimDetailsProps } from '../types/claim-details.type'

const ClaimDetails: FC<ClaimDetailsProps> = ({ wipId, claimDetailsInitialValues }) => {
  return (
    <div>
      <ClaimDetailsForm wipId={wipId} claimDetailsInitialValues={claimDetailsInitialValues} />
      <ClaimDetailsNoteList wipId={wipId} />
    </div>
  )
}

export default ClaimDetails
