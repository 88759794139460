import { useMutation } from "@tanstack/react-query"
import { CreateDebtorLegalData, DebtorLegal } from "../../types/debtor-legal.type"
import { DebtorLegalService } from "../../services/debtor-legal.service";
import { Params } from "@src/types/service.type";
import { DeepPartial } from "react-hook-form";


export const useCreateDebtorLegalMutation = () => {

  const createDebtorLegal = (data: CreateDebtorLegalData, params?: Params) => {
    const debtorLegalService = new DebtorLegalService();
    return debtorLegalService.createDebtorLegal(data, params);
  }

  const mutation = useMutation({
    mutationFn: ({ data, params }: { data: CreateDebtorLegalData, params?: Params }) => createDebtorLegal(data, params)
  })

  return mutation
}

export const useUpdateDebtorLegalMutation = () => {

  const updateDebtorLegal = (debtorLegalId: number, data: DeepPartial<DebtorLegal>, params?: Params) => {
    const debtorLegalService = new DebtorLegalService();
    return debtorLegalService.updateDebtorLegal(debtorLegalId, data, params);
  }

  const mutation = useMutation({
    mutationFn: ({ debtorLegalId, data, params }: { debtorLegalId: number, data: DeepPartial<DebtorLegal>, params?: Params }) => updateDebtorLegal(debtorLegalId, data, params)
  })

  return mutation
}