import { FC, useEffect, useState } from 'react'
import styles from './star-button.module.scss'
import OutlinedStarIcon from '@src/icons/outlined-star'
import cls from 'classnames'

interface StarButtonProps {
  isActive?: boolean
  onClick?: () => void
  onStarValueChange?: (value: boolean) => void
}

const StarButton:FC<StarButtonProps> = ({isActive = false, onClick, onStarValueChange}) => {
  const [isInnerActive, setIsInnerActive] = useState(false);

  const handleClick = () => {
    setIsInnerActive((prevState) => !prevState)
    if(onClick){ 
      onClick()
    }
  }


  useEffect(() => {
    if(isActive !== undefined) {
      setIsInnerActive(isActive)
    }
  }, [isActive])

  useEffect(() => {
    if(onStarValueChange) {
      onStarValueChange(isInnerActive)
    }
  }, [isInnerActive])

  return <OutlinedStarIcon className={cls(styles.star, {[styles.active]: isInnerActive})} onClick={handleClick} />
}

export default StarButton
