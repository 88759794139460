import { useIsAuthenticated, useMsal } from "@azure/msal-react"
import { GraphApiMailFoldersService } from "@containers/Inbox/services/graph-api-mail-folders.service"
import { useMutation } from "@tanstack/react-query"

export const useCreateMailFolderMutation = () => {
  const { instance, accounts } = useMsal()
  const isAuthenticated = useIsAuthenticated()

  const createMailFolder = async (folderName: string) => {
    if (!isAuthenticated) return null 

    const mailFolderService = new GraphApiMailFoldersService()
    const account = accounts[0] || {}
    const accessToken = await instance.acquireTokenSilent({ scopes: ['Mail.ReadBasic', 'Mail.Read', 'Mail.ReadWrite'], account: account })
    const mailFolder = await mailFolderService.createMailFolder(accessToken.accessToken, folderName)
    return mailFolder
  }

  const mutation = useMutation({ mutationFn: createMailFolder })

  return mutation
}