import { FC } from 'react'
import { DebtorLegal, DebtorLegalJudgmentDate } from '../../../types/debtor-legal.type'
import SubSection from '@containers/Recovery/Wip/components/UI/sub-section/sub-section.component'
import { SubHeader, Text } from '@containers/Recovery/Wip/components/UI/text/text.component'
import TrashIcon from '@src/icons/trash'
import styles from './debtor-legal-judgment-date-list.module.scss'
import EditIcon from '@src/icons/edit'
import { useQueryClient } from '@tanstack/react-query'
import { getDebtorLegalByIdQueryKey } from '../../../utils/legal-query-key-factory.utils'
import { toast } from 'react-toastify'
import { BaseListItemPropsWithEdit } from '@src/types/component-types'
import { useDeleteDebtorJudgmentDateMutation } from '../../../hooks/mutations/crud-debtor-legal-judgment-date.hook'
import { format } from 'date-fns'

const DebtorLegalJudgmentDateListItem: FC<BaseListItemPropsWithEdit<DebtorLegalJudgmentDate>> = ({
  entity: debtorJudgmentDate,
  onEditClick,
}) => {
  const { mutateAsync } = useDeleteDebtorJudgmentDateMutation()
  const queryClient = useQueryClient()

  const handleDeleteClick = async () => {
    try {
      await mutateAsync({ debtorJudgmentDateId: debtorJudgmentDate.id })
      queryClient.setQueryData<DebtorLegal>(
        getDebtorLegalByIdQueryKey(debtorJudgmentDate.debtorLegalId),
        (prevData) =>
          prevData
            ? {
                ...prevData,
                judgmentDates: prevData.judgmentDates.filter(
                  (jd) => jd.id !== debtorJudgmentDate.id,
                ),
              }
            : undefined,
      )
    } catch (error) {
      toast.error('Failed to delete judgment date')
      console.error(error)
    }
  }

  return (
    <SubSection className={styles.listItemWrapper} p={4}>
      <div className={styles.listItemContent}>
        <SubHeader>Judgment Date:</SubHeader>
        <Text>{format(debtorJudgmentDate.judgmentDate, 'MM/dd/yyyy')}</Text>
      </div>
      <div className={styles.row}>
        <button className={styles.actionButton} onClick={() => onEditClick(debtorJudgmentDate)}>
          <EditIcon />
        </button>
        <button onClick={handleDeleteClick} className={styles.actionButton}>
          <TrashIcon />
        </button>
      </div>
    </SubSection>
  )
}

export default DebtorLegalJudgmentDateListItem
