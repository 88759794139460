import axios from "axios";
import { GRAPH_API_ENDPOINT } from "../const/graph-api.const";
import { BaseGraphApiParams, GraphApiResponse, MailFolder, MailFolderName, SingleItemGraphApiResponse } from "../types/graph-api-mail.type";


export class GraphApiMailFoldersService {

  async createMailFolder(accessToken: string, displayName: string): Promise<MailFolder> {
    const headers = this.getAuthorizationHeaders(accessToken);
    const res = await axios.post<MailFolder>(`${GRAPH_API_ENDPOINT.MAIL_FOLDERS}`, { displayName }, { headers })
    return res.data
  }

  async getMailFolders(accessToken: string, params: BaseGraphApiParams = {}): Promise<MailFolder[]> {
    const headers = this.getAuthorizationHeaders(accessToken);
    const res = await axios.get<GraphApiResponse<MailFolder[]>>(GRAPH_API_ENDPOINT.MAIL_FOLDERS, { headers, params })
    return res.data.value
  }

  async getMailFolder(accessToken: string, folderName: MailFolderName): Promise<MailFolder> {
    const headers = this.getAuthorizationHeaders(accessToken);
    const res = await axios.get<SingleItemGraphApiResponse<MailFolder>>(`${GRAPH_API_ENDPOINT.MAIL_FOLDERS}/${folderName}`, { headers })
    const { '@odata.context': _, ...mailFolder } = res.data
    return mailFolder;
  }

  private getAuthorizationHeaders(accessToken: string) {
    return ({ Authorization: `Bearer ${accessToken}` })
  }
}