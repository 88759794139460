import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { FC } from 'react'
import { WipTemplateItemProps } from './types/wip-templates.type'
import { WipTemplatesService } from './services/wip-templates.service'
import { useQueryClient } from '@tanstack/react-query'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import { toast } from 'react-toastify'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { useHistory } from 'react-router'

const wipTemplatesService = new WipTemplatesService()

export const WipTemplateItem: FC<WipTemplateItemProps> = ({
  wipTemplate,
  openEditTemplateModal,
}) => {
  const queryClient = useQueryClient()
  const history = useHistory()
  const { name } = wipTemplate

  const deleteWipTemplate = async (event) => {
    event.stopPropagation()
    try {
      await wipTemplatesService.deleteTemplate(wipTemplate.id)
      queryClient.refetchQueries([GLOBAL_QUERIES.WIP_TEMPLATE.GET])
      toast.success('Wip Template Deleted')
    } catch {
      toast.error('Something Went Wrong')
    }
  }

  const handleItemClick = () => {
    history.push(`/wip-template/${wipTemplate.id}`)
  }

  return (
    <TableRow onClick={handleItemClick}>
      <TableCell sx={{ textAlign: 'center', width: '50%' }}>{name}</TableCell>
      <TableCell className="d-flex justify-content-center align-items-center" sx={{ gap: '10px' }}>
        <IconButton onClick={deleteWipTemplate} aria-label="delete">
          <DeleteIcon />
        </IconButton>
        <IconButton
          onClick={(event) => openEditTemplateModal(event, wipTemplate)}
          aria-label="delete"
        >
          <EditIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}
