import React, { createContext, useContext, useState, ReactNode, useMemo } from 'react'

interface ClientPageActionsContextType {
  actions: ReactNode
  setActions: (actions: ReactNode) => void
}

const ClientPageActionsContext = createContext<ClientPageActionsContextType | null>(null)

export const ClientPageActionsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [actions, setActions] = useState<ReactNode>()

  const value = useMemo(() => ({ actions, setActions }), [actions])
  return (
    <ClientPageActionsContext.Provider value={value}>{children}</ClientPageActionsContext.Provider>
  )
}

export const useClientPageActions = () => {
  const context = useContext(ClientPageActionsContext)
  if (!context) {
    throw new Error('useClientPageActions must be used within a ClientPageActionsProvider')
  }
  return context
}
