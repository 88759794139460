import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col } from 'reactstrap';
import { useNotificationContext } from '../../App/contexts/NotificationContext';
import {
  makeStyles,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
} from '@material-ui/core';
import { getURFList, removeURF, getURFInfo } from './service';
import moment from 'moment';
import Services from '../../../shared/Services';
import SharedServices from '../../../shared/Services';
import WIPService from '../../../services/WIPService';
import { TailSpin } from 'react-loader-spinner';
import { useUserContext } from '../../../context/user-context';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'time_created',
    numeric: false,
    disablePadding: true,
    label: 'Submitted',
    sortable: true,
  },
  {
    id: 'recovery_type',
    numeric: true,
    disablePadding: false,
    label: 'Recovery Type',
    sortable: false,
  },
  {
    id: 'tenant',
    numeric: true,
    disablePadding: false,
    label: 'Tenants',
    sortable: false,
  },
  {
    id: 'urf_property_type',
    numeric: true,
    disablePadding: false,
    label: 'Property',
    sortable: false,
  },
  {
    id: 'owner_name',
    numeric: true,
    disablePadding: false,
    label: 'Agent',
    sortable: false,
  },
  {
    id: '',
    numeric: true,
    disablePadding: false,
    label: 'Status',
    sortable: false,
  },
  {
    id: 'protein',
    numeric: true,
    disablePadding: false,
    label: '',
    sortable: false,
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const RecoveryList = () => {
  const service = new Services();
  const classes = useStyles();
  const { setNotificationsHandler } = useNotificationContext();
  const [order, setOrder] = React.useState('desc');
  const [rows, setRows] = React.useState([]);
  const [orderBy, setOrderBy] = React.useState('time_created');
  const sharedService = new SharedServices();
  const wipService = new WIPService();
  const {user} = useUserContext()
  const[loader,setLoader]=useState(false)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getListItems = async () => {
    const params = {
      join: 'company||id',
    };
    const response = await getURFList(params);
    if (response?.status === 200) setRows(response.data.data);
  };

  const tenantName = (tenants) => {
    const tenantsName = [];
    if (tenants?.length > 0) {
      tenants.forEach((item) => {
        tenantsName.push(`${item.tenant_name_first} ${item.tenant_name_last}`);
      });
    }
    return tenantsName.join(', ');
  };

  const getRemoveItem = (id) => () => {
    removeURF(id).then(() => {
      service.successToast('Recovery canceled successfully');
    });
  };


  const getCreateWIP = (id) => async () => {
    setLoader(true)
    try {
      const params = {
        join: 'company||id',
      };
      const { data: urf } = await getURFInfo(id, params);
      const createWIPDto = {
        urf_id: urf.id,
        staff_created_id: user?.id,
      };

      const wip = await wipService.createWip(createWIPDto);
      setLoader(false)
      service.successToast(`WIP File #${wip.id} Created Successfully`);
      const createNotification = {
        _id: wip.id,
        ava: `${process.env.PUBLIC_URL}/img/topbar/ava.png`,
        file_number: wip.id,
        client: wip.company?.name,
        date: wip.crDate && moment(wip.crDate).format('HH:MM'),
      };
      setNotificationsHandler(createNotification);
    } catch (error) {
      setLoader(false)
      console.log(error);
      service.errorToast(error);
    }
  };

  useEffect(() => {
    getListItems();
  }, []);

  return (
    <>
      <Col
        className={'container page-body-wrapper position-relative pb-4'}
        md={12}
        lg={12}
      >
        <h3 className="page-title mb-5">Recovery form</h3>
        <Card>
          <CardBody>
            <Link to="/recovery/add" className="btn btn-secondary">
              <span>Add Recovery Form</span>
            </Link>
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy)).map(
                    (row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                        >
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {moment(row.time_created).format(
                              'MM-DD-YYYY HH:mm',
                            )}
                          </TableCell>
                          <TableCell align="right" />
                          <TableCell align="right">
                            {tenantName(row.tenants)}
                          </TableCell>
                          <TableCell align="right">
                            {row.property_type}
                          </TableCell>
                          <TableCell align="right">{row.owner_name}</TableCell>
                          <TableCell align="right">Submitted</TableCell>
                          <TableCell align="right">
                            <button
                              onClick={getCreateWIP(row.id)}
                              className="btn btn-success py-1 px-2 m-0 mr-2"
                            >
                              {loader ? "loading..." : "Create WIP" }
                            </button>
                            <Link
                              to={`/recovery/edit/${row.id}`}
                              className="btn btn-info py-1 px-2 m-0"
                            >
                              Edit
                            </Link>
                            <button
                              onClick={getRemoveItem(row.id)}
                              className="btn btn-danger py-1 px-2 m-0 ml-2"
                            >
                              Cancel
                            </button>
                          </TableCell>
                        </TableRow>
                      );
                    },
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default RecoveryList;
