import React, { Component, Fragment } from 'react'
import { connect, Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import i18n from 'i18next'
import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'bootstrap/dist/css/bootstrap.css'
import { ThemeProvider } from '@material-ui/core/styles'
import '../../scss/app.scss'
import PropTypes from 'prop-types'
import Router from './Router'
import store from '../../redux/store/store'
import ScrollToTop from './ScrollToTop'
import { config as i18nextConfig } from '../../translations'
import firebaseConfig from '../../config/firebase'
import NotificationContextProvider from './contexts/NotificationContext'
import { initTheme } from '../../scss/mui-theme'
// import Auth0Provider from '../../shared/components/auth/withAuth0';
// import auth0Config from '../../config/auth0';
import { TaskProvider } from '../../context/task-context'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { UserProvider } from '@src/context/user-context'
import { ModalsProvider } from '@src/context/modals-context'
import AuthProvider from '@src/context/auth-provider'
import { MainStyleProvider } from './contexts/main-style.context'
import { PropertiesProvider } from '@src/context/properties.context'

i18n.init(i18nextConfig)
const queryClient = new QueryClient()
const ThemeComponent = ({ children }: { children: React.ReactNode; themeName: string }) => {
  return <ThemeProvider theme={initTheme}>{children}</ThemeProvider>
}

ThemeComponent.propTypes = {
  themeName: PropTypes.string.isRequired,
}

const ConnectedThemeComponent = connect((state: any) => ({
  themeName: state.theme.className,
}))(ThemeComponent)

class App extends Component {
  constructor() {
    super({})
    this.state = {
      loading: true,
      loaded: false,
    }
  }

  componentDidMount() {
    window.addEventListener('load', () => {
      this.setState({ loading: false })
      setTimeout(() => this.setState({ loaded: true }), 50)
    })
    firebase.initializeApp(firebaseConfig)
  }

  onRedirectCallbackAuth0 = (appState: any) => {
    window.history.replaceState(
      {},
      document.title,
      appState && appState.targetUrl ? appState.targetUrl : window.location.pathname,
    )
  }

  render() {
    return (
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <PropertiesProvider>
            <UserProvider>
              <AuthProvider>
                <ModalsProvider>
                  <TaskProvider>
                    <NotificationContextProvider>
                      <BrowserRouter basename="/">
                        <I18nextProvider i18n={i18n}>
                          <ScrollToTop>
                            <Fragment>
                              <ConnectedThemeComponent>
                                <MainStyleProvider>
                                  <Router />
                                </MainStyleProvider>
                              </ConnectedThemeComponent>
                            </Fragment>
                          </ScrollToTop>
                        </I18nextProvider>
                      </BrowserRouter>
                    </NotificationContextProvider>
                  </TaskProvider>
                </ModalsProvider>
              </AuthProvider>
            </UserProvider>
          </PropertiesProvider>
        </Provider>
      </QueryClientProvider>
    )
  }
}

export default App
