import React from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import moment from 'moment';

export const TenantsDetails = ({ tenantsDetails, setTenantsDetails }) => {
  const getOnInputChange = (index) => (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setTenantsDetails((prev) => {
      if (index === 0) {
        return [
          {
            ...prev[index],
            [name]: value,
          },
          ...prev.slice(index + 1),
        ];
      }

      return [
        ...prev.slice(0, index),
        {
          ...prev[index],
          [name]: value,
        },
        ...prev.slice(index + 1),
      ];
    });
  };

  const onTenantAdd = () => {
    setTenantsDetails((prev) => (prev ? [...prev, {}] : [{}]));
  };

  function tenantRemove(index) {
    tenantsDetails.splice(index, 1);
    setTenantsDetails([...tenantsDetails]);
  }

  return (
    <>
      {tenantsDetails.map((item, index) => (
        <React.Fragment key={index}>
          <div className='card__title'>
            <h5 className='bold-text'>Tenant {index + 1} Details</h5>
          </div>
          <div className='form__form-group'>
            <span className='form__form-group-label'>First Name</span>
            <div className='form__form-group-field'>
              <input
                name='first_name'
                type='text'
                placeholder='First Name'
                onChange={getOnInputChange(index)}
                value={item?.first_name}
                required={true}
              />
            </div>
          </div>
          <div className='form__form-group'>
            <span className='form__form-group-label'>Middle Name</span>
            <div className='form__form-group-field'>
              <input
                name='middle_name'
                type='text'
                placeholder='Middle Name'
                onChange={getOnInputChange(index)}
                value={item?.middle_name}
                required={true}
              />
            </div>
          </div>
          <div className='form__form-group'>
            <span className='form__form-group-label'>Last Name</span>
            <div className='form__form-group-field'>
              <input
                name={`last_name`}
                type='text'
                placeholder='Last Name'
                onChange={getOnInputChange(index)}
                value={item?.last_name}
                required={true}
              />
            </div>
          </div>
          <div className='form__form-group'>
            <span className='form__form-group-label'>Alias</span>
            <div className='form__form-group-field'>
              <input
                name='alias'
                type='text'
                placeholder='Alias'
                onChange={getOnInputChange(index)}
                value={item?.alias}
                required={true}
              />
            </div>
          </div>
          <div className='form__form-group'>
            <span className='form__form-group-label'>Current Address</span>
            <div className='form__form-group-field'>
              <input
                name='current_address'
                // defaultValue={item.current_address}
                type='text'
                placeholder='Current Address'
                onChange={getOnInputChange(index)}
                value={item?.current_address}
                required={true}
              />
            </div>
          </div>
          <div className='form__form-group'>
            <span className='form__form-group-label'>Current Agent</span>
            <div className='form__form-group-field'>
              <input
                name='current_agent'
                // defaultValue={item.current_agent}
                type='text'
                placeholder='Current Agent'
                onChange={getOnInputChange(index)}
                value={item?.current_agent}
                required={true}
              />
            </div>
          </div>
          <div className='form__form-group'>
            <span className='form__form-group-label'>Date of Birth</span>
            <div className='form__form-group-field'>
              <input
                name='date_of_birth'
                type='date'
                placeholder='Date of Birth'
                onChange={getOnInputChange(index)}
                value={item?.date_of_birth ? moment(item?.date_of_birth).format('YYYY-MM-DD') : item?.date_of_birth}
                required={true}
              />
            </div>
          </div>
          <div className='form__form-group'>
            <span className='form__form-group-label'>Email</span>
            <div className='form__form-group-field'>
              <input
                name='email'
                // defaultValue={item.email}
                type='email'
                placeholder='Email'
                onChange={getOnInputChange(index)}
                value={item?.email}
                required={true}
              />
            </div>
          </div>
          <div className='form__form-group'>
            <span className='form__form-group-label'>Home Phone</span>
            <div className='form__form-group-field'>
              <input
                name='home_phone'
                type='text'
                placeholder='Home Phone'
                onChange={getOnInputChange(index)}
                value={item?.home_phone}
                required={true}
              />
            </div>
          </div>
          <div className='form__form-group'>
            <span className='form__form-group-label'>Work Phone</span>
            <div className='form__form-group-field'>
              <input
                name='work_phone'
                type='text'
                placeholder='Work Phone'
                onChange={getOnInputChange(index)}
                value={item?.work_phone}
                required={true}
              />
            </div>
          </div>
          <div className='form__form-group'>
            <span className='form__form-group-label'>Mobile Phone</span>
            <div className='form__form-group-field'>
              <input
                name='mobile_phone'
                type='text'
                placeholder='Mobile Phone'
                onChange={getOnInputChange(index)}
                value={item?.mobile_phone}
                required={true}
              />
            </div>
          </div>
          <div className='form__form-group'>
            <div className='form__form-group-field'>
              {index > 0 &&
              <Button
                color='primary'
                className='mb-0'
                type='button'
                onClick={() => tenantRemove(index)}
              >
                Remove Tenant
              </Button>}
            </div>
          </div>

        </React.Fragment>
      ))}
      <div className='form__form-group'>
        <ButtonToolbar className='form__button-toolbar mb-3'>
          <Button
            color='primary'
            className='mb-0'
            type='button'
            onClick={onTenantAdd}
          >
            Add another Tenant
          </Button>
        </ButtonToolbar>
      </div>
    </>
  );
};
