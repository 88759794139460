import TableCell from '@components/table/table-cell-component'
import { FC } from 'react'
import styles from './payment-table.module.scss'

const PaymentTableItemEmpty: FC = () => {
  return (
    <tr className={styles.tableRow}>
      <TableCell position="eclipsed" />
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell />
    </tr>
  )
}

export default PaymentTableItemEmpty
