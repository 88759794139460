import {
  EditGeneralInterestRateCalculatorProps,
  GeneralInterestRateCalculatorFormValues,
} from '../../../../types/interest-rate.type'
import { FC } from 'react'
import GeneralInterestRateCalculatorForm from './general-interest-rate-calculator-form.component'
import {
  CALCULATED_ON_OPTIONS,
  CALCULATOR_TYPE_OPTIONS,
} from '../../../../const/interest-rate.const'
import { useUpdateGeneralInterestCalculator } from '../../../../hooks/crud-general-interest-calculator.hook'
import { toast } from 'react-toastify'
import { parseStringToNumber } from '@containers/Recovery/Wip/utils/format-currency'

const EditGeneralInterestRateCalculatorForm: FC<EditGeneralInterestRateCalculatorProps> = ({
  entity,
  onCalculateTypeChange,
  onSuccessSubmit,
  onCancelClick,
}) => {
  const { mutateAsync, isLoading } = useUpdateGeneralInterestCalculator()
  const handleSubmit = async (data: GeneralInterestRateCalculatorFormValues) => {
    try {
      const res = await mutateAsync({
        id: entity.id,
        data: {
          rate: Number(data.interestRate) / 100,
          period: data.calculatedOn.value,
          startClaimAmount: data.onClaimAmount
            ? Math.floor(parseStringToNumber(data.onClaimAmount))
            : null,
          dateStart: data.startDate,
          dateEnd: data.endDate,
        },
      })
      toast.success('General interest calculator updated successfully')
      onSuccessSubmit(res)
    } catch (e) {
      toast.error('Failed to update court interest calculator')
      console.log(e)
    }
  }
  return (
    <GeneralInterestRateCalculatorForm
      loading={isLoading}
      onCalculateTypeChange={onCalculateTypeChange}
      onSubmit={handleSubmit}
      onCancelClick={onCancelClick}
      initialValues={{
        calculateType: CALCULATOR_TYPE_OPTIONS[1],
        calculatedOn:
          CALCULATED_ON_OPTIONS.find((option) => option.value === entity.period) ||
          CALCULATED_ON_OPTIONS[0],
        interestRate: String(Number(entity.rate) * 100),
        onClaimAmount: entity.startClaimAmount ? String(entity.startClaimAmount) : '',
        startDate: entity.dateStart,
        endDate: entity.dateEnd,
      }}
    />
  )
}

export default EditGeneralInterestRateCalculatorForm
