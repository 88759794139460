import { FC } from 'react'
import styles from './nav-bar.module.scss'
import cls from 'classnames'

interface VerticalNavBarProps {
  options: {
    label: string
    value: string
  }[]
  activeValue: string
  onChange?: (url: string) => void
  position?: 'vertical' | 'horizontal'
}

const VerticalNavBar: FC<VerticalNavBarProps> = ({
  options,
  activeValue,
  onChange,
  position = 'vertical',
}) => {
  return (
    <div className={cls(styles.verticalNavBarWrapper, styles[position])}>
      <ul className={cls(styles.verticalNavBarList, styles[position])}>
        {options.map((option) => (
          <li
            className={cls(styles.verticalNavBarItem, styles[position], {
              [styles.active]: option.value === activeValue,
            })}
            onClick={() => onChange && onChange(option.value)}
            key={option.value + option.label}
          >
            <span>{option.label}</span>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default VerticalNavBar
