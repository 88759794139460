import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { InvoiceTemplateLayout } from './InvoiceTemplateLayout';
import { createInvoiceTemplateInitialValues } from '../../../../../shared/utils/createInvoiceTemplateInitialValues';
import { createInvoiceTemplateValidationSchema } from '../../../../../shared/validators/createInvoiceTemplateValidationSchema';
import { InvoiceTemplateForm } from './InvoiceTemplateForm';
import { toast } from 'react-toastify';

import { useDispatch, useSelector } from 'react-redux';
import { createInvoiceTemplate } from '../../../../../redux/actions';
import { createInvoiceTemplateLoading } from '../../../../../redux/selector/selector';
import { useUserContext } from '../../../../../context/user-context';

export const AddInvoiceTemplate = () => {
  const {user} = useUserContext()
  const userId = user.id;
  const history = useHistory();
  const dispatch = useDispatch();
  // Being loaded on creation
  const creationLoading = useSelector(createInvoiceTemplateLoading);
  // Setting editor value
  const [editorValue, setEditorValue] = useState('');

  // Setting form inputs except editor
  const { values, errors, touched, submitForm, handleChange, isValid } = useFormik({
    initialValues: createInvoiceTemplateInitialValues,
    validationSchema: createInvoiceTemplateValidationSchema,
    validateOnChange: true,
    onSubmit: () => {
      if (isValid && editorValue.trim()) {
        const data = {
          ...values,
          is_deleted: false,
          is_archived: false,
          body: editorValue,
          staff_id_created: userId,
        };

        dispatch(createInvoiceTemplate(data)).then(handleSuccess).catch(handleError);
      } else {
        toast.error('Please fill again!');
      }
    },
  });

  const handleEditorChange = (newValue) => {
    setEditorValue(newValue);
  };

  const handleClose = () => {
    history.push('/invoice-templates');
  };

  const handleSuccess = () => {
    toast.success('Successfully added');
    return history.push('/invoice-templates');
  };

  const handleError = () => {
    toast.error('Something went wrong, please try again!');
  };

  return (
    <InvoiceTemplateLayout heading="Add New Invoice Template">
      <InvoiceTemplateForm
        values={values}
        errors={errors}
        touched={touched}
        editorValue={editorValue}
        handleChange={handleChange}
        handleSubmit={submitForm}
        handleEditorChange={handleEditorChange}
        handleClose={handleClose}
        loading={creationLoading}
      />
    </InvoiceTemplateLayout>
  );
};
