import VerticalNavBar from '@components/nav-bar/vertical-nav-bar.component'
import { SelectOption } from '@src/types/component-types'
import { FC, useState } from 'react'
import styles from './edit-client-property-page.module.scss'
import LandlordContactsSection from '../../components/client-landlord-sections/landlord-contacts-section/landlord-contacts-section.component'
import LandlordAddressSection from '../../components/client-landlord-sections/landlord-address-section/landlord-address-section.component'
import LandlordDetailsSection from '../../components/client-landlord-sections/landlord-details-section/landlord-details-section.component'
import { Property } from '@containers/Sales/Properties/types/property.type'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'

interface EditClientPropertyOwnerProps {
  property: Property
}

enum OwnerNavOption {
  DETAILS = 'DETAILS',
  ADDRESS = 'ADDRESS',
  CONTACTS = 'CONTACTS',
}

const ownerNavBarOptions: SelectOption<OwnerNavOption>[] = [
  {
    label: 'Ownership Details',
    value: OwnerNavOption.DETAILS,
  },
  {
    label: 'Owners Address',
    value: OwnerNavOption.ADDRESS,
  },
  {
    label: 'Owner Contacts',
    value: OwnerNavOption.CONTACTS,
  },
]

const EditClientPropertyOwners: FC<EditClientPropertyOwnerProps> = ({ property }) => {
  const [activeOption, setActiveOption] = useState<OwnerNavOption>(OwnerNavOption.DETAILS)

  if (!property.landlord)
    return (
      <div className={styles.landlordAbsent}>
        <Header>There are no landlords attached</Header>
      </div>
    )
  return (
    <div className={styles.sectionsWrapper}>
      <VerticalNavBar
        options={ownerNavBarOptions}
        activeValue={activeOption}
        onChange={(v) => setActiveOption(v as OwnerNavOption)}
      />
      {activeOption === OwnerNavOption.DETAILS ? (
        <LandlordDetailsSection landlord={property.landlord} />
      ) : null}
      {activeOption === OwnerNavOption.ADDRESS ? (
        <LandlordAddressSection landlord={property.landlord} />
      ) : null}
      {activeOption === OwnerNavOption.CONTACTS ? (
        <LandlordContactsSection
          landlordId={property.landlord.id}
          propertyId={property.id}
          contacts={property.landlord.contacts}
        />
      ) : null}
    </div>
  )
}

export default EditClientPropertyOwners
