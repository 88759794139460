import { add, endOfWeek, format, isToday, startOfWeek, sub } from 'date-fns'
import { FC, useEffect, useState } from 'react'
import styles from './date-range-picker.module.scss'
import cls from 'classnames'
import { WeeklyRangePickerProps } from '../../types/global-kanban-board.type'

const WeeklyRangePicker: FC<WeeklyRangePickerProps> = ({
  onChange,
  initialDate = new Date(),
  onDayClick,
}) => {
  const [currentDate, setCurrentDate] = useState(initialDate)

  const getDateRange = () => {
    const dateRange: Date[] = []
    const start = startOfWeek(currentDate, { weekStartsOn: 1 })
    for (let i = 0; i < 7; i++) {
      dateRange.push(add(start, { days: i }))
    }
    return dateRange
  }

  const getWeekDateInterval = (date: Date): [Date, Date] => {
    return [startOfWeek(date, { weekStartsOn: 1 }), endOfWeek(date, { weekStartsOn: 1 })]
  }

  const handleClickBack = () => {
    setCurrentDate((prevDate) => sub(prevDate, { weeks: 1 }))
  }

  const handleClickNext = () => {
    setCurrentDate((prevDate) => add(prevDate, { weeks: 1 }))
  }

  useEffect(() => {
    if (onChange) {
      onChange(getWeekDateInterval(currentDate))
    }
  }, [currentDate])

  const dateRange = getDateRange()

  return (
    <div className={styles.dateRangeWrapper}>
      <div className={styles.dateRangeAction} onClick={handleClickBack}>
        <span className="lnr lnr-chevron-left" />
      </div>
      {dateRange.map((date, i) => (
        <div
          className={cls(styles.dateRangeItem, styles.clickable, {
            [styles.active]: isToday(date),
          })}
          onClick={onDayClick ? () => onDayClick(date) : undefined}
          key={`${date}-${i}`}
        >
          <span>{format(date, 'EEEEEE').toUpperCase()}</span>
          <span>{format(date, 'dd')}</span>
        </div>
      ))}
      <div className={styles.dateRangeAction} onClick={handleClickNext}>
        <span className="lnr lnr-chevron-right" />
      </div>
    </div>
  )
}

export default WeeklyRangePicker
