import { PaginationResponse, Params } from "@src/types/service.type";
import { ClaimDetailNote, CreateClaimDetailsNoteValues } from "../types/claim-detail.type";
import { REACT_APP_API_URL } from "@src/lib/global-variables";
import axios from "axios";

export class ClaimDetailNoteService {

  async getClaimDetailNotes(params: Params = {}) {
    const response = await axios.get<PaginationResponse<ClaimDetailNote>>(`${REACT_APP_API_URL}/claim-detail-notes`, { params })
    return response.data
  }

  async getClaimDetailNoteById(id: number | undefined, params: Params = {}) {
    const response = await axios.get<ClaimDetailNote>(`${REACT_APP_API_URL}/claim-detail-notes/${id}`, { params })
    return response.data
  }

  async createClaimDetailNote(data: CreateClaimDetailsNoteValues) {
    const response = await axios.post<ClaimDetailNote>(`${REACT_APP_API_URL}/claim-detail-notes`, data)
    return response.data
  }
}