/* eslint-disable react/no-children-prop */
import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

const renderTextField = ({
  input, label, meta: { touched, error }, children,type
}) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    children={children}
    value={input.value}
    type={type}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  />
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  children: PropTypes.arrayOf(PropTypes.element),
};

renderTextField.defaultProps = {
  meta: null,
  label: '',
  children: [],
};

class ProfileSettings extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
  };

  render() {
    const { handleSubmit ,backHome} = this.props;
    return (
      <form className="material-form" onSubmit={handleSubmit}>
        <div>
          <span className="material-form__label">First Name</span>
          <Field
            name="first_name"
            component={renderTextField}
            placeholder="First name"
          />
        </div>
        <div>
          <span className="material-form__label">Last Name</span>
          <Field
            name="last_name"
            component={renderTextField}
            placeholder="Last name"
          />
        </div>
        <div>
          <span className="material-form__label">Email</span>
          <Field
            name="comms_email"
            disabled
            component={renderTextField}
            placeholder="example@mail.com"
            type="email"
          />
        </div>
        <div>
          <span className="material-form__label">User Name</span>
          <Field
            name="nickname"
            disabled
            component={renderTextField}
            placeholder="Username"
            type="text"
          />
        </div>
        <div>
          <span className="material-form__label">Password</span>
          <Field
            name="password"
            component={renderTextField}
            type="password"
          />
        </div>
        <div>
          <span className="material-form__label">Phone</span>
          <Field
            name="comms_ph_home"
            component={renderTextField}
            type="text"
          />
        </div>
        <ButtonToolbar className="form__button-toolbar">
          <Button color="primary" type="submit">Update Profile</Button>
          <Button type="button" onClick={backHome}>
            Cancel
          </Button>
        </ButtonToolbar>
      </form>
    );
  }
}

export default reduxForm({
  form: 'profile_settings_form', // a unique identifier for this form
})(ProfileSettings);
