import { FC } from 'react'
import { DebtorLegal, DebtorLegalTribunal } from '../../../types/debtor-legal.type'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import { getDebtorLegalByIdQueryKey } from '../../../utils/legal-query-key-factory.utils'
import Modal from '@components/modal'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import styles from './debtor-legal-tribunal-modal.module.scss'
import CreateDebtorLegalTribunalForm from '../../forms/debtor-legal-tribunal-form/create-debtor-legal-tribunal-form.component'
import { CreateDebtorLegalTribunalModalProps } from '../../../types/debtor-legal-tribunal.type'
import ModalHeader from '@components/modal-header.component'

const CreateDebtorLegalTribunalModal: FC<CreateDebtorLegalTribunalModalProps> = ({
  debtorLegalId,
  ...modalProps
}) => {
  const queryClient = useQueryClient()
  const handleSuccessfulSubmit = (debtorLegalTribunal: DebtorLegalTribunal) => {
    queryClient.setQueryData<DebtorLegal>(
      getDebtorLegalByIdQueryKey(debtorLegalTribunal.debtorLegalId),
      (prevData) =>
        prevData
          ? {
              ...prevData,
              tribunals: [...prevData.tribunals, debtorLegalTribunal],
            }
          : undefined,
    )
    toast.success('Tribunal created successfully')
    modalProps.onClose()
  }

  return (
    <Modal className={styles.modalWrapper} {...modalProps} withBtns={false}>
      <ModalHeader onCloseClick={modalProps.onClose} />
      <Header mb={4}>Create Tribunal Details</Header>
      <CreateDebtorLegalTribunalForm
        onSuccessSubmit={handleSuccessfulSubmit}
        debtorLegalId={debtorLegalId}
      />
    </Modal>
  )
}

export default CreateDebtorLegalTribunalModal
