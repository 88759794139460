import SubSection from '@containers/Recovery/Wip/components/UI/sub-section/sub-section.component'
import CreateWipCostForm from '../../forms/wip-cost-form/create-wip-cost-form.component'
import { FC } from 'react'
import { CreateWipCostItemProps, IWipCostList, WipCostListItem } from '@containers/Recovery/WipCost/types/wip-cost.type'
import { useQueryClient } from '@tanstack/react-query'
import { PaginationResponse } from '@src/types/service.type'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import { toast } from 'react-toastify'

const CreateWipCostItem: FC<CreateWipCostItemProps> = ({ wipCostListId }) => {
  const queryClient = useQueryClient()
  const handleSubmit = (item: WipCostListItem) => {
    queryClient.setQueryData<PaginationResponse<IWipCostList[]>>(
      [GLOBAL_QUERIES.WIP_COST_LIST.GET],
      (prev) =>
        prev
          ? {
              ...prev,
              data: prev.data.map((wipCostList) => {
                if (wipCostList.id === wipCostListId) {
                  return {
                    ...wipCostList,
                    list: [...wipCostList.list, item],
                  }
                }
                return wipCostList
              }),
            }
          : undefined,
    )
    toast.success('WIP cost item created')
  }

  return (
    <li>
      <SubSection p={4}>
        <CreateWipCostForm onSuccessSubmit={handleSubmit} wipCostListId={wipCostListId} />
      </SubSection>
    </li>
  )
}

export default CreateWipCostItem
