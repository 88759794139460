import { PaginationResponse, Params } from "@src/types/service.type"
import { RecoverableCost } from "../types/claim.type"
import axios from "axios"
import { REACT_APP_API_URL } from "@src/lib/global-variables"

export class RecoverableCostService {

  async getRecoveredCosts(params: Params = {}) {
    const response = await axios.get<PaginationResponse<RecoverableCost[]>>(
      `${REACT_APP_API_URL}/recoverable-costs`,
      { params },
    )

    return response.data
  }
}