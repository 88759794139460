import { PHONE_REGEX } from "@src/lib/regexp.const";
import Joi from "joi";

const recipientsEmail = Joi.alternatives().try(
  Joi.array().items(
    Joi.object({
      label: Joi.string().required(),
      value: Joi.object({
        id: Joi.number().required(),
        value: Joi.string().email({ tlds: { allow: false } }).required()
      })
    })
  ).min(1).required(),
  Joi.string().email({ tlds: { allow: false } }).required().messages({
    'string.email': 'To must be a email',
  })
).required().messages({
  'alternatives.types': 'Recepients is required',
  'any.required': 'Recepients is required',
  'string.empty': 'Recepients is required',
  'array.min': 'Recepients is required',
})


const recipientsPhone  = Joi.alternatives().try(
  Joi.array().items(
    Joi.object({
      label: Joi.string().required(),
      value: Joi.object({
        id: Joi.alternatives().try(Joi.string(), Joi.number()).required(),
        value: Joi.string().regex(PHONE_REGEX).required()
      })
    })
  ).min(1).required(),
  Joi.string().regex(PHONE_REGEX).required()
).required().messages({
  'alternatives.types': 'Recepients is required',
  "string.pattern.base": 'To must be a phone',
  'any.required': 'Recepients is required',
  'string.empty': 'Recepients is required',
  'string.regex': 'To must be a phone',
  'array.min': 'Recepients is required',
})

const to = Joi.object({
  label: Joi.string().required(),
  value: Joi.string().required()
}).required().messages({
  'any.required': 'To is required',
  'object.base': 'To is required',
})

const template = Joi.object({
  label: Joi.string().required(),
  value: Joi.string().required(),
}).optional().allow(null)

export const emailSendingModalWithRecipientsSchema = Joi.object({
  to,
  recipients: recipientsEmail,
  template,

  bcc: Joi.boolean().required(),

  subject: Joi.string().required().messages({
    'string.empty': 'Subject is required',
  }),

  body: Joi.string().required().messages({
    'string.empty': 'Body is required',
  }),
})

export const smsSendingModalWithRecipientsSchema = Joi.object({
  to,
  recipients: recipientsPhone,
  template,

  message: Joi.string().required().messages({
    'string.empty': 'Message is required',
  })
})