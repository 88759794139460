import { useForm } from 'react-hook-form'
import styles from '../interest-rate-calculator-form.module.scss'
import { FC, useEffect } from 'react'
import FormInput from '@components/inputs/form-input/form-input.component'
import FormSelect from '@components/inputs/form-select/form-select.component'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import CurrencyFormInput from '@components/inputs/form-input/currency-form-input.component'
import FormDatePicker from '@components/inputs/form-date-picker/form-date-picker.component'

import {
  GeneralInterestRateCalculatorFormProps,
  GeneralInterestRateCalculatorFormValues,
} from '../../../../types/interest-rate.type'
import {
  CALCULATED_ON_OPTIONS,
  CALCULATOR_TYPE_OPTIONS,
} from '../../../../const/interest-rate.const'
import { joiResolver } from '@hookform/resolvers/joi'
import { generalInterestRateCalculatorSchema } from '../../../../schemas/claim.schemas'

const GeneralInterestRateCalculatorForm: FC<GeneralInterestRateCalculatorFormProps> = ({
  onCalculateTypeChange,
  onSubmit,
  loading,
  onCancelClick,
  initialValues,
  buttonText = 'Save Rate',
}) => {
  const {
    control,
    register,
    reset,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<GeneralInterestRateCalculatorFormValues>({
    defaultValues: initialValues,
    resolver: joiResolver(generalInterestRateCalculatorSchema),
  })

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'calculateType' && type === 'change' && value?.calculateType?.value) {
        onCalculateTypeChange(value.calculateType.value)
      }
    })
    return () => subscription.unsubscribe()
  }, [watch])

  useEffect(() => {
    if (!initialValues) return
    reset(initialValues)
  }, [initialValues])

  return (
    <form className={styles.formWrapper} onSubmit={handleSubmit(onSubmit)}>
      <FormSelect
        control={control}
        name="calculateType"
        label="Calculate Type"
        style="outlined"
        options={CALCULATOR_TYPE_OPTIONS}
      />
      <FormSelect
        control={control}
        name="calculatedOn"
        label="Calculated On"
        style="outlined"
        options={CALCULATED_ON_OPTIONS}
      />
      <FormInput
        {...register('interestRate')}
        error={errors.interestRate?.message}
        label="Interest Rate %"
        placeholder="0.00"
        inputStyle="outlined"
        required
      />
      <CurrencyFormInput
        control={control}
        name="onClaimAmount"
        label="On Claim Amount"
        placeholder="$00.00"
        inputStyle="outlined"
      />
      <div className={styles.row}>
        <FormDatePicker
          control={control}
          name="startDate"
          label="Starting From"
          inputStyle="outlined"
        />
        <FormDatePicker control={control} name="endDate" label="Ending At" inputStyle="outlined" />
      </div>
      <div className={styles.actionWrapper}>
        <SubmitButton buttonStyle="secondary" buttonType="button" onClick={onCancelClick}>
          Cancel
        </SubmitButton>
        <SubmitButton loading={loading} buttonType="submit">
          {buttonText}
        </SubmitButton>
      </div>
    </form>
  )
}

export default GeneralInterestRateCalculatorForm
