export const IncoveredStyles = {
  card: {
    height: '100%',
    minHeight: '150px',
    borderRadius: '5px',
    boxShadow: '0 2px 10px 0 #ddd, 0 2px 20px 0 rgba(0, 0, 0, 0.1)',
    backgroundColor: 'white',
    padding: '30px',
  },
};
