import FormInput from '@components/inputs/form-input/form-input.component'
import { SubHeader } from '@containers/Recovery/Wip/components/UI/text/text.component'
import { FC, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import Select from 'react-select'
import styles from './column-match.module.scss'
import RoundedArrow from '@src/icons/rounded-arrow'
import cls from 'classnames'
import { capitalizeWords } from '../../utils/property.utils'
import { ColumnMatchProps } from '../../types/property.type'

const ColumnMatch: FC<ColumnMatchProps> = ({ columnName, columnData, fields }) => {
  const [isOpen, setIsOpen] = useState(false)

  const { register } = useFormContext()

  const handleOpen = () => {
    setIsOpen((prev) => !prev)
  }
  return (
    <div>
      <button className={styles.dropdownToggleWrapper} onClick={handleOpen}>
        <RoundedArrow
          className={cls(styles.dropdownToggleIcon, {
            [styles.open]: isOpen,
          })}
        />
        <p className={styles.dropdownToggleText}>Match {columnName}</p>
      </button>
      {isOpen ? (
        <div>
          {columnData.map((cell, cellIndex) => (
            <div key={`match-cell-${cellIndex}`}>
              <div>
                <SubHeader>{cell}</SubHeader>
              </div>
              <div>
                {fields.map((field, inputIndex) =>
                  field.fieldType === 'input' ? (
                    <FormInput
                      inputStyle="outlined"
                      size="small"
                      label={capitalizeWords(field.fieldName)}
                      key={`match-input-${field}-${inputIndex}`}
                      {...register(`${columnName}.${cellIndex}.${field.fieldName}`)}
                    />
                  ) : (
                    // wrap in controller
                    <Select key={`match-select-${field}-${inputIndex}`} />
                  ),
                )}
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  )
}

export default ColumnMatch
