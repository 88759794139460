/* eslint-disable react/no-array-index-key */
import React, {useEffect, useState} from 'react';
import {Button, Card, CardBody, Col, Container, Row} from 'reactstrap';
import {withTranslation} from 'react-i18next';
import Modal from "react-bootstrap/Modal";
import {Link, useHistory} from "react-router-dom";
import ReactFileReader from "react-file-reader";


const data = [
  {name: 'Page A', pv: 255},
  {name: 'Page B', pv: 452},
  {name: 'Page C', pv: 154},
  {name: 'Page D', pv: 85},
  {name: 'Page E', pv: 545},
  {name: 'Page F', pv: 438},
  {name: 'Page G', pv: 523},
  {name: 'Page H', pv: 226},
  {name: 'Page I', pv: 112},
  {name: 'Page J', pv: 101},
];

const NewUsers = () => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [user, setUser] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const [showUploadPopup, setShowUploadPopup] = useState(false)
  const history = useHistory()

  useEffect(() => {
    setUser(JSON.parse(window.localStorage.getItem("user")));
  }, []);


  const handleClick = (item) => {
    const index = data.indexOf(item.payload);
    setActiveIndex(index);
  };

  const showAddProperty = (e) => {
    localStorage.setItem('propertyId', "false");
    localStorage.setItem("propertyId", "false");
    localStorage.setItem('propertyAdd', 'yes');
    localStorage.setItem('propertyEdit', 'yes');
    setOpenModal(false)

    history.push('/add-properties', {fromTools: true});
  }

  const uploadFile=()=>{
    setShowUploadPopup(true);
  }
  const searchDebtor=()=>{
    setShowUploadPopup(true);
  }


  return (
    <>
      <Col md={12} xl={3} lg={6} xs={12}>
        <Card>
          <CardBody className="dashboard__card-widget mb-3">
            <h4 onClick={() => setOpenModal(true)} style={{cursor: "pointer", color: "#0052ff"}}
                className="bold-text text-center"> {(user && user.role === 'RECOVERY_ADMIN') || (user && user.role === 'SUPER_ADMIN') ? 'ADD ITEM TO COVER' : 'ADD NEW CLIENT'}</h4>
          </CardBody>
          <CardBody className="dashboard__card-widget">
            <h4
              className="bold-text text-center"> {(user && user.role === 'RECOVERY_ADMIN') || (user && user.role === 'SUPER_ADMIN') ?  <Link to="/debtor">SEARCH DEBTORS</Link>: 'ADVANCED CLIENT SEARCH'}</h4>
          </CardBody>
        </Card>
      </Col>
      {openModal && <Modal
        show={openModal}
        onHide={() => setOpenModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="tool_modal small_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Properties
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0 py-4">

          <Container className="p-0 tool_popup">
            <Row>
              <Col xs={12} md={6}>
                <Button onClick={(e) => {
                  showAddProperty(e)
                }} className="btn-group--icons mb-0">
                  Add Single Property
                </Button>
              </Col>
              <Col xs={12} md={6}>
                <Button onClick={()=>{uploadFile()}} className="btn-group--icons mb-0">
                  Upload Properties
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>}
      {showUploadPopup && <Modal
        show={showUploadPopup}
        onHide={() => {
          setShowUploadPopup(false)
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="tool_modal small_modal"

      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Upload Properties
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Container>
            <Row>
              <label className="mx-auto">Please upload your CSV here.</label>
            </Row>
            <Row>
              <div className="position-relative file_wrap">
                <ReactFileReader handleFiles={()=>uploadFile()} fileTypes={'.csv'}>
                  <button className='btn-group--icons mb-0'>Upload Properties</button>
                </ReactFileReader>
              </div>
            </Row>
            <Row>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>}
    </>
  );

}

export default withTranslation('common')(NewUsers);

