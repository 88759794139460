import { FC } from 'react'
import { Link } from 'react-router-dom'
import styles from './nav-bar.module.scss'
import cls from 'classnames'

interface SubNavBarProps {
  options: {
    label: string
    url: string
  }[]
  activeUrl: string
  className?: string
  isLink?: boolean
  onChange?: (url: string) => void
}

const SubNavBar: FC<SubNavBarProps> = ({
  options,
  activeUrl,
  className,
  isLink = true,
  onChange,
}) => {
  const handleLabelClick = (url: string) => {
    onChange && onChange(url)
  }

  return (
    <nav className={cls(className, styles.subNavBarWrapper)}>
      <ul className={styles.subNavBarList}>
        {options.map((option) => (
          <li
            className={cls(styles.subNavBarItem, {
              [styles.active]: activeUrl.includes(option.url),
            })}
            key={option.url + option.label}
          >
            {isLink ? (
              <Link className={styles.subNavBarLink} to={option.url}>
                {option.label}
              </Link>
            ) : (
              <span className={styles.subNavBarLink} onClick={() => handleLabelClick(option.url)}>
                {option.label}
              </span>
            )}
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default SubNavBar
