import axios from 'axios'
import Services from '../../../shared/Services'
import { REACT_APP_API_URL } from '../../../lib/global-variables'

const httpClient = axios.create({
  baseURL: REACT_APP_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

const services = new Services()

export const submitURF = async (formData) => {
  try {
    const result = await httpClient.post('unprocessed-recovery-form', formData)
    return result
  } catch (error) {
    console.log(error.message)
  }
}

export const getURFList = async (params = {}) => {
  try {
    const result = await httpClient.get('unprocessed-recovery-form', {
      params,
    })
    return result
  } catch (error) {
    console.log(error)
  }
}

export const getURFInfo = async (id, params = {}) => {
  try {
    const result = await httpClient.get(`unprocessed-recovery-form/${id}`, {
      params,
    })
    return result
  } catch (error) {
    console.log(error)
  }
}

export const updateURF = async (id, formData) => {
  try {
    const result = await httpClient.patch(`unprocessed-recovery-form/${id}`, formData)
    return result
  } catch (error) {
    console.log(error)
    services.errorToast(error.message)
  }
}

export const removeURF = async (id) => {
  try {
    const result = await httpClient.delete(`unprocessed-recovery-form/${id}`)
    return result
  } catch (error) {
    console.log(error)
  }
}

export const sendSMS = async (formData) => {
  try {
    const requests = formData.map(async (data) => httpClient.post('short-messages', data))
    const result = await Promise.all(requests)
    return result
  } catch (error) {
    console.log(error)
  }
}

export const sendEmail = async (formData) => {
  try {
    const requests = formData.map(async (data) => httpClient.post('emails', data))
    const result = await Promise.all(requests)
    return result
  } catch (error) {
    console.log(error)
  }
}

export const sendReply = async (formData) => {
  try {
    const requests = await httpClient.post('emails/reply', formData)
    const result = await Promise.all(requests)
    return result
  } catch (error) {
    console.log(error)
  }
}

export const getSmsTypes = async () => {
  try {
    const result = await fetch(`${REACT_APP_API_URL}/short-message-templates`, { method: 'GET' })
    return result
  } catch (error) {
    console.log(error)
  }
}

export const getEmailTypes = async (params) => {
  try {
    const result = await fetch(`${REACT_APP_API_URL}/email-templates`, { method: 'GET', params })
    return result
  } catch (error) {
    console.log(error)
  }
}

export const createTimeLog = async (data) => {
  try {
    const result = await httpClient.post('wip-time-logs', data)
    return result
  } catch (error) {
    console.log(error)
  }
}

export const getShortMessage = async () => {
  try {
    const result = await httpClient.get('short-messages')
    return result
  } catch (error) {
    console.log(error)
  }
}

export const getEmail = async () => {
  try {
    const result = await httpClient.get('emails')
    return result
  } catch (error) {
    console.log(error)
  }
}
