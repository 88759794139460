import { REACT_APP_API_URL } from "@src/lib/global-variables";
import axios from "axios";
import { Wip } from "../types/wip.type";
import { PaginationResponse, Params } from "@src/types/service.type";
import { DeepPartial } from "react-hook-form";

export class WipService {
  async getWipList(params: Params) {
    const res = await axios.get<PaginationResponse<Wip>>(`${REACT_APP_API_URL}/wips`, { params })
    return res.data
  }

  async getWipById(id: number | undefined, params: Params = {}) {
    const res = await axios.get<Wip>(`${REACT_APP_API_URL}/wips/${id}`, { params })
    return res.data
  }

  async updateWip(id: number, data: DeepPartial<Wip>, params: Params = {}) {
    const res = await axios.patch<Wip>(`${REACT_APP_API_URL}/wips/${id}`, data, { params })
    return res.data;
  }
}