import { Params } from "@src/types/service.type"
import { DebtorLegalNoteService } from "../../services/debtor-legal-note.service"
import { useInfiniteQuery, useMutation } from "@tanstack/react-query"
import { GLOBAL_QUERIES } from "@src/lib/global-queries"
import { CreateDebtorLegalNoteData } from "../../types/debtor-legal-note.type"
import { useMemo } from "react"


export const useGetDebtorLegalNotes = (params: Params) => {
  const { data, ...restQuery } = useInfiniteQuery({
    queryKey: [GLOBAL_QUERIES.DEBTOR_LEGAL_NOTE.GET_BY_DEBTOR_LEGAL_ID, params],
    queryFn: async ({ pageParam }) => {
      const debtorLegalNoteService = new DebtorLegalNoteService()
      const page = pageParam ? pageParam : 1;
      const limit = params.limit;
      const data = await debtorLegalNoteService.getDebtorLegalNotes({ ...params, limit, page })
      return {
        results: data.data,
        page: page < data.pageCount ? page + 1 : undefined
      }
    },
    cacheTime: 0,
    getNextPageParam: (lastPage) => lastPage.page
  });

  const flattenedData = useMemo(
    () => (data ? data?.pages.flatMap(item => item.results) : []),
    [data]
  );

  return { data: flattenedData, ...restQuery }
}

export const useCreateDebtorLegalNoteMutation = () => {

  const createDebtorLegalNote = async (data: CreateDebtorLegalNoteData, params?: Params) => {
    const debtorLegalNoteService = new DebtorLegalNoteService()
    return debtorLegalNoteService.createDebtorLegalNote(data, params)
  }

  const mutation = useMutation({ mutationFn: ({ data, params }: { data: CreateDebtorLegalNoteData, params?: Params }) => createDebtorLegalNote(data, params) })

  return mutation;
}