import Joi from "joi";

const phoneRegexp = /^\(?\+\d{1,3}\)? ?-?\d{1,3} ?-?\d{3,5} ?-?\d{4}( ?-?\d{3})? ?(\w{1,10}\s?\d{1,6})?$/

export const genericSendSmsSchema = Joi.object({
    to:
        Joi.alternatives().try(
            Joi.object({
                label: Joi.string().required(), value: Joi.string().regex(phoneRegexp).required()
            }).required(),
            Joi.string().regex(phoneRegexp).required().messages({
                'string.pattern.base': 'To must be a phone',
            })
        ).required().messages({
            'any.required': 'To is required',
            'string.empty': 'To is required',
            'string.pattern.base': 'To must be a phone',
        }),
    message: Joi.string().required().messages({
        'string.empty': 'Message is required',
    }),
    template: Joi.object({ label: Joi.string().required(), value: Joi.string().required() })
        .allow('', null)
        .optional(),
})