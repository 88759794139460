export const inputStyles = (outlinedInputClasses) => {
  return {
    pointerEvents: 'auto',
    [`&.${outlinedInputClasses.root}`]: {
      height: '32px',
      width: '100%',
      backgroundColor: '#f5f9fc',
      borderRadius: '5px',
      transition: 'border 0.3s',

      '&:before': {
        border: 'unset !important',
      },
      '&:after': {
        border: 'unset !important',
      },

      '&.Mui-focused fieldset': {
        border: '1px solid #fd7e14',
      },
    },
    [`& .${outlinedInputClasses.input}`]: {
      fontSize: '16px',
      lineHeight: '16.8px',
      fontWeight: 400,
      color: '#535F77',
      padding: '5px 10px',
      outline: 'none',
    },
    [`& .${outlinedInputClasses.notchedOutline}`]: {
      border: '1px solid silver',
      borderRadius: '8px',
      '&.Mui-error': {
        border: '1px solid red',
      },
    },
    '& .MuiSelect-icon': {
      top: 'calc(50% - 11.5px)',
      right: '14px',
      transition: 'all 0.3s ease-in-out',
    },
    '& input::placeholder': {
      fontSize: '16px',
      lineHeight: '24px',
      color: '#98A2B3',
      opacity: 1,
    },
  };
};

export const formInputStyles = {
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: '5px',
  },

  label: {
    width: '200px',
    color: '#979ca7',
    marginBottom: '4px',
    display: 'inline-block',
    fontSize: '16px',
    lineHeight: '21px',
    fontWeight: '400',
  },

  labelBold: {
    paddingRight: '10px',
    color: '#979ca7',
    marginBottom: '4px',
    display: 'inline-block',
    fontSize: '16px',
    lineHeight: '21px',
    fontWeight: '600',
    whiteSpace: 'nowrap',
  },

  inputContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },

  input: {
    fontSize: '16px',
    lineHeight: '16.8px',
    fontWeight: 400,
    color: '#535F77',
    padding: '5px 10px',
    outline: 'none',
    border: '1px solid silver',
    borderRadius: '8px',

    ':focus': {
      border: '1px solid #fd7e14',
    },
  },

  required: { color: '#ff0000' },

  errorMessage: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    color: '#ff0000',
    marginTop: '5px',
    height: '20px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#ff0000 !important',
  },
};
