import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withRouter } from 'react-router';
import ProfileMain from './components/ProfileMain';
import Services from '../../../shared/Services.js';

// import ProfileCalendar from './components/ProfileCalendar';
// import ProfileTasks from './components/ProfileTasks';
import ProfileTabs from './components/ProfileTabs';
import StaffService from '../../../services/StaffService';
import { UserContext } from '../../../context/user-context';

class Calendar extends React.Component {
  static contextType = UserContext
  constructor(props) {
    super(props);
    this.state = {
      userData:""
    };

    this.staff = new StaffService();
    this.services = new Services();
    // this.validator = new SimpleReactValidator();
    this.getStaff=this.getStaff.bind(this);
  }
  
  componentDidMount(){
    const {user} = this.context;
    this.getStaff(user.id)
  }


  getStaff = async (id) => {
    try {
      const params = {
        id: id,
        join: 'Staff||password',
      };
      const res = await this.staff.getStaffId(params);
      this.setState({
        userData:res
      })
    } catch (e) {
      this.services.errorToast(e);
      throw new Error(e);
    }
  };

  render() {
    const {userData}=this.state
    return (
      <Container>
        <div className="profile">
          <Row>
            <Col md={12} lg={12} xl={4}>
              <Row>
                <ProfileMain user={userData}/>
                {/* <ProfileCalendar />
                <ProfileTasks /> */}
              </Row>
            </Col>
            <ProfileTabs user={userData}/>
          </Row>
        </div>
      </Container>
    );
  }
}

export default withRouter(Calendar);
