import BaseService from './BaseService';
import axios from 'axios';

export default class StaffService extends BaseService {
  constructor() {
    super();
    this.apiUrl = this.apiUrl + '/staff';
  }

  async getStaff(params = {}) {
    try {
      const response = await axios.get(this.apiUrl, { params });

      if (response.status !== 200) {
        throw new Error('Failed to fetch staff');
      }

      return response.data;
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message);
      } else {
        if (error.response) {
          throw new Error(error.response.data);
        }
        if (!error.response) {
          throw new Error(error);
        }
      }
    }
  }

  async getStaffId(params) {
    try {
      const response = await axios.get(`${this.apiUrl}/${params.id}`,{ params });

      if (response.status !== 200) {
        throw new Error('Failed to fetch staff');
      }

      return response.data;
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message);
      } else {
        if (error.response) {
          throw new Error(error.response.data);
        }
        if (!error.response) {
          throw new Error(error);
        }
      }
    }
  }


  async addStaff(val) {
    try {
      const data = {
        email: val.email,
        password: val.password,
        first_name: val.fname,
        last_name: val.lname,
        comms_ph_home: val.phone,
        comms_ph_mobile_pri: val.mobile,
        comms_ph_mobile_sec: val.mobile2,
        position: val.position,
        ass_states: val.Ass_States,
        access_type: val.access_type,
        access_level: val.role,
        account_status: val.status,
        nickname: val.name,
        display_color: val.display_color,
        email_letter: val.email_letter,
        profile_picture: val.profilePic || 'https://pic.onlinewebfonts.com/svg/img_325788.png',
        comms_email: val.email,
      };
      const response = await axios.post(this.apiUrl, data);

      if (response.status !== 201) {
        throw new Error('Failed to add staff');
      }

      return response;
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message);
      } else {
        if (error.response) {
          throw new Error(error.response.data);
        }
        if (!error.response) {
          throw new Error(error);
        }
      }
    }
  }

  async deleteStaff(staffId) {
    try {
      const data = {
        st_id: staffId,
        isdel: true,
      };
      const response = await axios.delete(`${this.apiUrl}/${staffId}`, data);

      return response;
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message);
      } else {
        if (error.response) {
          throw new Error(error.response.data);
        }
        if (!error.response) {
          throw new Error(error);
        }
      }
    }
  }

  async editStaff(val) {
    try {
      const data = {
        first_name: val.fname,
        last_name: val.lname,
        comms_ph_home: val.phone,
        comms_ph_mobile_pri: val.mobile,
        comms_ph_mobile_sec: val.mobile2,
        position: val.position,
        ass_states: val.Ass_States,
        access_type: val.access_type,
        access_level: val.role,
        account_status: val.status,
        nickname: val.name,
        display_colour: val.display_colour,
        email_letter: val.email_letter,
        profile_picture: 'https://pic.onlinewebfonts.com/svg/img_325788.png',
        comms_email: val.email,

      };

      const response = axios.put(`${this.apiUrl}/${val.st_id}`, data);

      return response;
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message);
      } else {
        if (error.response) {
          throw new Error(error.response.data);
        }
        if (!error.response) {
          throw new Error(error);
        }
      }
    }
  }

  async editStaffStatus(val) {
    try {
      let data = {
        id: val.st_id,
        account_status: val.status,
      };

      const response = await axios.patch(`${this.apiUrl}/${val.st_id}`, data);

      return response;
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.responseType !== 'UNAUTHORIZED'
      ) {
        this.unAuthorizeUser(error.response.data.message);
      } else {
        if (error.response) {
          throw new Error(error.response.data);
        }
        if (!error.response) {
          throw new Error(error);
        }
      }
    }
  }
}
