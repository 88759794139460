import { SelectOption } from "@src/types/component-types";
import { SmsTemplate } from "../types/sms.type";
import { CommunicationOptionType, EmailRecipientOption, PhoneContactOption } from "../types/communication.type";
import { Debtor } from "@containers/Setting/components/Contacts/taskboard/types";
import { Contact } from "@containers/Setting/components/Contacts/types/contact-list.type";
import { EMAIL_REGEX, PHONE_REGEX } from "@src/lib/regexp.const";
import { EmailTemplate } from "../types/email.type";

export const transformSmsTemplateToOption = (smsTemplate: SmsTemplate): SelectOption => {
  return {
    label: smsTemplate.sms_template_title,
    value: smsTemplate.sms_template_body,
  }
}

export const transformEmailTemplateToOption = (emailTemplate: EmailTemplate): SelectOption => {
  return {
    label: emailTemplate.title,
    value: emailTemplate.body,
  }
}

export const transformDebtorToPhoneOptions = (debtor: Debtor): PhoneContactOption[] => {
  const contact = debtor.contact;

  const phones: Set<string> = new Set();

  for (const key in contact) {
    if (PHONE_REGEX.test(contact[key])) {
      phones.add(contact[key]);
    }
  }

  return Array.from(phones).map((phone) => ({
    label: `${debtor.name_first} ${debtor.name_last} - ${phone}`,
    value: {
      id: debtor.id,
      phone: phone,
      type: CommunicationOptionType.Debtor,
    },
  }));


}

export const transformCompanyContactToPhoneContactOptions = (companyContact: Contact): PhoneContactOption[] => {
  const phones: Set<string> = new Set();

  for (const key in companyContact) {
    if (PHONE_REGEX.test(companyContact[key])) {
      phones.add(companyContact[key]);
    }
  }

  return Array.from(phones).map((phone) => ({
    label: `${companyContact.first_name} ${companyContact.last_name} - ${phone}`,
    value: {
      id: companyContact.id,
      phone: phone,
      type: CommunicationOptionType.CompanyContact,
    },
  }));
}

export const transformDebtorToEmailOptions = (debtor: Debtor): EmailRecipientOption[] => {
  const emails = debtor.contact.comms_email;

  const filteredEmails = new Set(emails.filter((email) => EMAIL_REGEX.test(email)));

  return Array.from(filteredEmails).map((email) => ({
    label: `${debtor.name_first} ${debtor.name_last} - ${email}`,
    value: {
      id: debtor.id,
      email: email,
      type: CommunicationOptionType.Debtor,
    },
  }));
}

export const transformCompanyContactToEmailOptions = (companyContact: Contact): EmailRecipientOption | null => {
  const isValidEmail = EMAIL_REGEX.test(companyContact.comm_email);
  return isValidEmail ? ({
    label: `${companyContact.first_name} ${companyContact.last_name} - ${companyContact.comm_email}`,
    value: {
      id: companyContact.id,
      email: companyContact.comm_email,
      type: CommunicationOptionType.CompanyContact,
    },
  }) : null;
}

export const transformFileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result as string);
    }
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}