
const recoveryDetails = {
  rent: "Rent",
  carpet_cleaning: "Carpet Cleaning",
  pest_control: "Pest Control",
  locks: "Locks",
  general_cleaning: "General Cleaning",
  rubbish_removal: "Rubbish Removal",
  general_repairs: "General Repairs",
  letting_adv_fee: "Letting Adv Fee",
  legal_cost_sheriff_fee: "Legal Cost Sheriff Fee",
  replacement: "Replacement",
  watter_usage: "Water Usage",
  insurance_excess: "Insurance Excess"
};

/**
 * Map recovery details
 * @param inputObject
 * @param keyMapping
 * @return {{}}
 */

const mapRecoveryDetails = (keyMapping, inputObject = recoveryDetails,) => {
  const mappedObject = {};

  for (const oldKey in keyMapping) {
    if (keyMapping.hasOwnProperty(oldKey) && inputObject.hasOwnProperty(oldKey)) {
      const newKey = keyMapping[oldKey];
      mappedObject[newKey] = inputObject[oldKey];
    }
  }

  return mappedObject;
}

export default mapRecoveryDetails;