import { SubHeader } from '@containers/Recovery/Wip/components/UI/text/text.component'
import { WipTaskInfoProps } from '@containers/Recovery/Wip/types/wip.type'
import { FC, useState } from 'react'
import styles from './task-info.module.scss'
import cls from 'classnames'
import { useModal } from '@src/hooks/useModal'
import EditDebtorModal from '@containers/Recovery/Wip/_modules/Debtors/components/modals/edit-debtor-modal/edit-debtor-modal.component'

const WipTaskInfo: FC<WipTaskInfoProps> = ({ client, wip, task }) => {
  const [isEditDebtorModalOpen, handleOpenEditDebtorModal, handleCloseEditDebtorModal] =
    useModal(false)
  const [selectedDebtorId, setSelectedDebtorId] = useState<number | null>(null)

  const handleEditDebtor = (debtorId: number) => {
    setSelectedDebtorId(debtorId)
    handleOpenEditDebtorModal()
  }

  const assignedDebtors = wip.debtors.filter(({ id }) =>
    task.assigned_debtors.includes(id as unknown as string),
  )

  return (
    <div className={styles.taskInfoWrapper}>
      <div className={styles.textWrapper}>
        <div className={styles.textWrapper}>
          <SubHeader>Client:</SubHeader>
          <p className={styles.highlightedText}>{client.name}</p>
        </div>
        <div className={styles.textWrapper}>
          <SubHeader>WIP:</SubHeader>
          <p className={styles.highlightedText}>{wip.id}</p>
        </div>
      </div>
      <div className={styles.textWrapper}>
        <SubHeader>Address:</SubHeader>
        <p className={styles.text}>{client?.address?.address_1}</p>
      </div>
      <div>
        {assignedDebtors.length === 0 ? (
          <>
            <SubHeader>Debtors:</SubHeader>
            <p className={styles.text}>No debtors assigned</p>
          </>
        ) : (
          <p className={styles.debtorsWrapper}>
            <SubHeader>Debtors:</SubHeader>
            {assignedDebtors.map(({ id, name_last, name_first }, idx) => {
              const str = `${name_first?.trim()} ${name_last?.trim()} `
              return (
                <span key={idx}>
                  {str}
                  <span
                    className={cls(styles.highlightedText, styles.underline)}
                    onClick={() => handleEditDebtor(id)}
                  >
                    Edit
                  </span>
                </span>
              )
            })}
          </p>
        )}
      </div>
      <div className={styles.textWrapper}>
        <SubHeader>Payment:</SubHeader>
        <p className={styles.overdueText}> OVERDUE - 7 Days</p>
      </div>
      <EditDebtorModal
        debtorId={selectedDebtorId}
        wipId={wip.id}
        isOpen={isEditDebtorModalOpen}
        onClose={handleCloseEditDebtorModal}
      />
    </div>
  )
}

export default WipTaskInfo
