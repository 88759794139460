const Comment = () => {
  return (
    <svg style={{ cursor: 'pointer' }} width="15" height="17" fill="none" viewBox="0 0 15 17">
      <path
        fill="#CCCCD7"
        fillRule="evenodd"
        d="M9.22 6.047V4.65H3.073v1.397H9.22zm0 2.078V6.728H3.073v1.397H9.22zm0 2.078V8.806H3.073v1.397H9.22zm2.04-6.95v12.47l-2.04-2.76H2.04c-.278 0-.516-.139-.712-.416a1.65 1.65 0 01-.295-.981V3.254c0-.377.098-.699.295-.965.196-.266.434-.399.713-.399h8.211c.279 0 .516.133.713.4.197.265.295.587.295.964z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default Comment
