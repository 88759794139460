import React, { Fragment } from 'react'
import { Button } from 'reactstrap'
import { reduxForm } from 'redux-form'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router'
import SimpleReactValidator from 'simple-react-validator'
import DebtorService from '../../../../../../services/DebtorService'
import Services from '../../../../../../shared/Services'
import ModalComponent from '../../../../../../shared/components/Modal'
import DatePicker from 'react-datepicker'
import calendar from '../../../../../../assets/image/calendar.svg'
import moment from 'moment'
import Select from 'react-select'

const australianState = [
  {
    label: 'New South Wales',
    value: 'NSW',
  },
  {
    label: 'Victoria',
    value: 'VIC',
  },
  {
    label: 'Queensland',
    value: 'QLD',
  },
  {
    label: 'Tasmania',
    value: 'TAS',
  },
  {
    label: 'South Australia',
    value: 'SA',
  },
  {
    label: 'Western Australia',
    value: 'WA',
  },
  {
    label: 'Northern Territory',
    value: 'NT',
  },
  {
    label: 'Australian Capital Territory',
    value: 'ACT',
  },
]

const sexOption = [
  {
    label: 'Male',
    value: 'Male',
  },
  {
    label: 'Female',
    value: 'Female',
  },
]

const requiredPhoneOption = [
  { value: 'mobile_phone', label: 'Mobile Phone' },
  { value: 'home_phone', label: 'Home Phone' },
  { value: 'work_phone', label: 'Work Phone' },
]

const phoneSelectOption = [
  { value: 'mobile_secondary_phone', label: 'Mobile Secondary Phone' },
  { value: 'direct_phone', label: 'Direct Phone' },
  { value: 'extension_phone', label: 'Extension Phone' },
  { value: 'fax_phone', label: 'Fax Phone' },
  { value: 'office_phone', label: 'Office Phone' },
]

const phoneOption = [...requiredPhoneOption, ...phoneSelectOption]

class DebtorOverview extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      contactId: '',
      old_ten_id: '',
      contact_1_first: '',
      contact_1_last: '',
      contact_1_middle: '',
      contact_2_first: '',
      contact_2_last: '',
      contact_2_middle: '',
      email: [''],
      aliasName: [],
      otherMobile: [],
      otherEmail: [],
      id: localStorage.getItem('debtorId'),
      alias: false,
      accessErrorModal: false,
      drivers_licence: '',
      vehicle_reg: '',
      date_of_birth: '',
      state_drivers_licence: '',
      state_vehicle_licence: '',
      sex: '',
      //phone
      status_phone: null,
      mobile_phone: '',
      home_phone: '',
      work_phone: '',
      mobile_secondary_phone: null,
      direct_phone: null,
      extension_phone: null,
      fax_phone: null,
      office_phone: null,
    }
    this.debtor = new DebtorService()
    this.services = new Services()
    this.editDebtor = this.editDebtor.bind(this)
    this.changeInput = this.changeInput.bind(this)
    this.validator = new SimpleReactValidator()
  }

  componentDidMount() {
    this.setDebtor(this.props.dataDebtor)
  }

  handleChangePhone = (selectedOption) => {
    this.setState({ status_phone: selectedOption })
  }

  isPhoneSelected(selectedOption) {
    if (!selectedOption) return false
    return this.state[selectedOption.value] !== null
  }

  addPhone = (selectedOption) => {
    if (this.state[selectedOption.value] !== null) {
      this.setState({ [selectedOption.value]: null })
      return
    }
    this.setState({ [selectedOption.value]: '' })
    this.forceUpdate()
  }

  setDebtor(data) {
    if (data) {
      const getData = data.data
      if (getData.contact?.comms_ph_mobile_secondary) {
        this.setState({ addNewMobile: true })
      }
      if (getData.second_name_first) {
        this.setState({ alias: true })
      }

      this.setState({
        contactId: getData?.contact?.id,
        old_ten_id: getData.id,
        name: getData.name,
        Client_Code: getData.client_code,
        contact_1_first: getData.name_first,
        contact_1_last: getData.name_last,
        contact_1_middle: getData.name_middle,
        contact_2_first: getData.second_name_first,
        contact_2_last: getData.second_name_last,
        contact_2_middle: getData.second_name_middle,
        mobile_phone: getData?.contact?.comms_ph_mobile || '',
        home_phone: getData?.contact?.comms_ph_home || '',
        work_phone: getData?.contact?.comms_ph_work || '',
        mobile_secondary_phone: getData?.contact?.comms_ph_mobile_secondary,
        direct_phone: getData?.contact?.comms_ph_direct,
        extension_phone: getData?.contact?.comms_ph_extension,
        fax_phone: getData?.contact?.comms_ph_fax,
        office_phone: getData?.contact?.comms_ph_office,
        email: getData?.contact?.comms_email.length
          ? getData.contact?.comms_email
          : this.state.email,
        otherEmail: getData.email,
        staffData: getData,
        wips: getData.wips,
        date_of_birth: getData.date_of_birth,
        drivers_licence: getData.drivers_licence,
        vehicle_reg: getData.vehicle_reg,
        state_drivers_licence: australianState.find(
          (el) => el.value === getData.state_drivers_licence,
        ),
        state_vehicle_licence: australianState.find(
          (el) => el.value === getData.state_vehicle_licence,
        ),
        sex: sexOption.find((el) => el.value === getData.sex),
      })
      const assWip = getData.wips.map((e) => ({ id: e.id }))
      localStorage.setItem('wipDebId', JSON.stringify(assWip))
    }
  }

  accessErrorModalClose = () => {
    this.setState({ accessErrorModal: false })
    this.props.history.push('/dashboard')
  }

  editDebtor = () => {
    const dataDebtor = {
      id: +this.state.id,
      name_first: this.state.contact_1_first || null,
      name_last: this.state.contact_1_last || null,
      name_middle: this.state.contact_1_middle || null,
      second_name_first: this.state.alias ? this.state.contact_2_first : null,
      second_name_last: this.state.alias ? this.state.contact_2_last : null,
      second_name_middle: this.state.contact_2_middle,
      date_of_birth: this.state.date_of_birth,
      drivers_licence: this.state.drivers_licence,
      vehicle_reg: this.state.vehicle_reg,
      state_vehicle_licence: this.state.state_vehicle_licence?.value || null,
      state_drivers_licence: this.state.state_drivers_licence?.value || null,
      sex: this.state.sex?.value || null,
    }

    const filteredEmails = this.state.email.filter((email) => email !== '' && email)

    const dataContact = {
      debtor: { id: +this.state.id },
      id: this.state.contactId || null,
      comms_email: filteredEmails || null,
      comms_ph_home: this.state.home_phone || null,
      comms_ph_mobile: this.state.mobile_phone || null,
      comms_ph_direct: this.state.direct_phone || null,
      comms_ph_mobile_secondary: this.state.mobile_secondary_phone || null,
      comms_ph_extension: this.state.extension_phone || null,
      comms_ph_fax: this.state.fax_phone || null,
      comms_ph_work: this.state.work_phone || null,
      comms_ph_office: this.state.office_phone || null,
    }

    if (this.validator.allValid()) {
      this.debtor.editDebtor(dataDebtor).then((res) => {
        if (res.status === 200) {
          this.services.successToast('Contact update successfully')
          this.props.getDebtor(dataDebtor.id)
        } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
          this.setState({ accessErrorModal: true })
        } else {
          this.services.errorToast(res.message)
        }
      })
      if (dataContact.id === null) {
        this.debtor.addDebtorContact(dataContact).then((res) => {
          if (res.status === 201) {
            this.services.successToast('Contact update successfully')
            this.props.getDebtor(dataDebtor.id)
          } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
            this.setState({ accessErrorModal: true })
          } else {
            this.services.errorToast(res.message)
          }
        })
      }
      if (dataContact.id) {
        this.debtor.editDebtorContact(dataContact).then((res) => {
          if (res.status === 201) {
            this.services.successToast('Address update successfully')
            this.props.getDebtor(dataDebtor.id)
          } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
            this.setState({ accessErrorModal: true })
          } else {
            this.services.errorToast(res.message)
          }
        })
      }
    } else {
      this.validator.showMessages()
      this.forceUpdate()
    }
  }

  changeInput(e) {
    console.log(e.target.name, e.target.value)
    if (e.target.name === 'email') {
      this.setState({ emailChanges: true })
    }
    if (e.target.name === 'name') {
      this.setState({ nameChanges: true })
    }
    this.setState({ [e.target.name]: e.target.value })
  }

  addAlias = () => {
    this.setState({
      alias: !this.state.alias,
    })
  }

  addMobile = () => {
    this.setState({
      addNewMobile: !this.state.addNewMobile,
    })
  }

  addEmail = () => {
    const email = [...this.state.email, '']
    this.setState({ email: email })
  }

  removeEmail = (e, i) => {
    const data = this.state.email
    data.splice(i, 1)
    this.setState({ email: data })
  }

  changeEmail = (e, data, i) => {
    const newData = this.state.email
    newData.forEach((element, j) => {
      if (i === j) {
        newData[j] = e.target.value
      }
    })
    this.setState({ email: newData })
  }

  dateFormat(dateNew) {
    let fDate
    if (dateNew && dateNew !== '0000-00-00') {
      if (dateNew) {
        const date = moment(dateNew, 'YYYY-MM-DDTHH:mm:ss.sssZ').format('YYYY-MM-DDTHH:mm:ss.sssZ')
        fDate = ''
        if (date === 'Invalid date') {
          fDate = new Date(dateNew.slice(0, dateNew.length - 2).replace(/\//g, '-'))
        } else {
          fDate = new Date(date)
        }
      } else {
        return ''
      }
      if (fDate === 'Invalid Date' || fDate === 'Invalid date') {
        return ''
      } else {
        return fDate
      }
    }
  }

  handleChangeDate = (date, name) => {
    this.setState({
      [name]: date,
    })
  }

  handleChangeSelect = (name, value) => {
    this.setState({
      [name]: value,
    })
  }

  render() {
    const {
      contact_1_first,
      contact_1_last,
      contact_1_middle,
      contact_2_first,
      contact_2_last,
      contact_2_middle,
      email,
      alias,
      drivers_licence,
      vehicle_reg,
      date_of_birth,
      state_drivers_licence,
      state_vehicle_licence,
      sex,
    } = this.state

    return (
      <div className="w-100">
        <div id="contacts_accordion" className="form">
          <div className="card">
            <div id="collapseOne" className="collapse show" data-parent="#contacts_accordion">
              <div className="row">
                <div className="col-12 col-md-3">
                  <div className="form-group">
                    <label className="form__form-group-label">First Name <span style={{ color: '#ff0000' }}>*</span></label>
                    <input
                      type="text"
                      className="custom-input"
                      id="contact_1_first"
                      value={contact_1_first}
                      name="contact_1_first"
                      onChange={this.changeInput}
                    />
                    <span className="form__form-group-error">
                      {this.validator.message('First Name', contact_1_first, 'required')}
                    </span>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className="form-group">
                    <label className="form__form-group-label">Middle Name</label>
                    <input
                      type="text"
                      className="custom-input"
                      id="contact_1_middle"
                      value={contact_1_middle}
                      name="contact_1_middle"
                      onChange={this.changeInput}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className="form-group">
                    <label className="form__form-group-label">Last Name <span style={{ color: '#ff0000' }}>*</span></label>
                    <input
                      type="text"
                      className="custom-input"
                      id="contact_1_last"
                      value={contact_1_last}
                      name="contact_1_last"
                      onChange={this.changeInput}
                    />
                    <span className="form__form-group-error">
                      {this.validator.message('Last Name', contact_1_last, 'required')}
                    </span>
                  </div>
                </div>
                {!alias && (
                  <div className="col-12 col-md-3">
                    <div className="form-group top-24">
                      <button className="btn btn-primary" onClick={this.addAlias}>
                        Add Other Name
                      </button>
                    </div>
                  </div>
                )}
              </div>
              {alias && (
                <div className="row">
                  <div className="col-12 col-md-3">
                    <div className="form-group">
                      <label className="form__form-group-label">First Name</label>
                      <input
                        type="text"
                        className="custom-input"
                        id="contact_2_first"
                        value={contact_2_first || ''}
                        name="contact_2_first"
                        onChange={this.changeInput}
                      />
                    </div>
                  </div>

                  <div className="col-12 col-md-3">
                    <div className="form-group">
                      <label className="form__form-group-label">Middle Name</label>
                      <input
                        type="text"
                        className="custom-input"
                        id="contact_2_middle"
                        value={contact_2_middle || ''}
                        name="contact_2_middle"
                        onChange={this.changeInput}
                      />
                    </div>
                  </div>

                  <div className="col-12 col-md-3">
                    <div className="form-group">
                      <label className="form__form-group-label">Last Name</label>
                      <input
                        type="text"
                        className="custom-input"
                        id="contact_2_last"
                        value={contact_2_last || ''}
                        name="contact_2_last"
                        onChange={this.changeInput}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-3 custom-contact-btn">
                    <div className="form-group mb-0">
                      <button className="btn btn-secondary" onClick={this.addAlias}>
                        {' '}
                        Remove Other Names
                      </button>
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                {email.map((e, i) => {
                  return (
                    <Fragment key={i}>
                      <div className="col-12 col-md-3">
                        <div className="form-group">
                          <label className="form__form-group-label">Email</label>
                          <input
                            className="custom-input"
                            id="name"
                            value={e}
                            name="email"
                            onChange={(ele) => this.changeEmail(ele, e, i)}
                          />
                          <span className="form__form-group-error">
                            {this.validator.message('Email', e, 'email')}
                          </span>
                        </div>
                      </div>
                      {i === 0 && (
                        <div className="col-12 col-md-2">
                          <div className="form-group top-24">
                            <button className="btn btn-primary" onClick={this.addEmail}>
                              Add Email
                            </button>
                          </div>
                        </div>
                      )}
                      {i !== 0 && (
                        <div className="col-12 col-md-2">
                          <div className="form-group top-24">
                            <button
                              className="btn btn-primary"
                              onClick={(e) => this.removeEmail(e, i)}
                            >
                              Remove Email
                            </button>
                          </div>
                        </div>
                      )}
                    </Fragment>
                  )
                })}
              </div>
              <div className="row">
                <div className="col-12 col-md-6 col-lg-6">
                  <div className="form-group">
                    <label className="form__form-group-label text-left w-100">
                      Add Status Phone
                    </label>
                    <Select
                      classNamePrefix="react-select"
                      className="react-select"
                      options={phoneSelectOption}
                      value={this.state.status_phone}
                      onChange={(e) => this.handleChangePhone(e)}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-4">
                  <Button
                    disabled={!this.state.status_phone}
                    onClick={() => this.addPhone(this.state.status_phone)}
                    style={{ marginTop: '23px' }}
                  >
                    {this.isPhoneSelected(this.state.status_phone) ? 'Remove Phone' : 'Add Phone'}
                  </Button>
                </div>
              </div>
              <div className="row">
                {phoneOption.map((option) =>
                  this.state[option.value] !== null ? (
                    <div key={option.value} className="col-12 col-md-4 col-lg-4">
                      <div className="form-group">
                        <label className="form__form-group-label text-left w-100">
                          {option.label}
                        </label>
                        <input
                          type="text"
                          className="custom-input"
                          name={option.value}
                          onChange={this.changeInput}
                          value={this.state[option.value] || ''}
                          pattern="[0-9]*"
                        />
                        <span className="form__form-group-error">
                          {this.validator.message(
                            option.label,
                            this.state[option.value],
                            'numeric',
                          )}
                        </span>
                      </div>
                    </div>
                  ) : null,
                )}
              </div>
              <div className="row mt-3">
                <div className="col-12 col-md-3">
                  <div className="form-group">
                    <label className="form__form-group-label">Drivers Licence</label>
                    <input
                      type="text"
                      className="custom-input"
                      id="drivers_licence"
                      value={drivers_licence || ''}
                      name="drivers_licence"
                      onChange={(e) => this.changeInput(e)}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className="form-group">
                    <label className="form__form-group-label">Vehicle Registration</label>
                    <input
                      type="text"
                      className="custom-input"
                      id="vehicle_reg"
                      value={vehicle_reg || ''}
                      name="vehicle_reg"
                      onChange={(e) => this.changeInput(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-3">
                  <div className="form__form-group">
                    <span className="form__form-group-label">State Issued</span>
                    <div className="form__form-group-field ">
                      <Select
                        name="state_drivers_licence"
                        options={australianState}
                        onChange={(e) => this.handleChangeSelect('state_drivers_licence', e)}
                        value={state_drivers_licence}
                        classNamePrefix="react-select"
                        className="react-select"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className="form__form-group">
                    <span className="form__form-group-label">State Issued</span>
                    <div className="form__form-group-field ">
                      <Select
                        name="state_vehicle_licence"
                        options={australianState}
                        onChange={(e) => this.handleChangeSelect('state_vehicle_licence', e)}
                        value={state_vehicle_licence}
                        classNamePrefix="react-select"
                        className="react-select"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-3">
                  <div className="form-group">
                    <label className="form__form-group-label text-left w-100">Date Of Birth</label>
                    <div className="date-picker date-picker--interval">
                      <DatePicker
                        selected={date_of_birth ? this.dateFormat(date_of_birth) : ''}
                        onChange={(e) => this.handleChangeDate(e, 'date_of_birth')}
                        name="date_of_birth"
                        dateFormat="dd/MM/yy"
                        dropDownMode="select"
                        popperPlacement="center"
                        placeholderText="Select Date"
                      />
                      <span className="date_icon">
                        <img src={calendar} alt="date" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-3">
                  <div className="form__form-group">
                    <span className="form__form-group-label">Sex</span>
                    <div className="form__form-group-field ">
                      <Select
                        name="sex"
                        options={sexOption}
                        onChange={(e) => this.handleChangeSelect('sex', e)}
                        value={sex}
                        classNamePrefix="react-select"
                        className="react-select"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-100 d-flex justify-content-end pt-4 ">
              <Button className="btn-group--icons" onClick={this.editDebtor}>
                Save{' '}
              </Button>
              <button className="btn-gray m-0" onClick={() => this.props.history.push('/debtor')}>
                Cancel
              </button>
            </div>
          </div>
        </div>
        {this.state.errorModal ? (
          <ModalComponent
            modal={this.state.errorModal}
            color={this.state.errorType}
            isCancel={true}
            message={this.state.errorMessage}
            onConfirm={(e) => this.DeleteStaff(e)}
            onClose={() => this.setState({ errorModal: false })}
          />
        ) : null}
        {this.state.accessErrorModal ? (
          <ModalComponent
            modal={this.state.accessErrorModal}
            color="danger"
            isCancel={false}
            message="You do not have access to this, please contact your manager for assistance"
            onConfirm={(e) => this.accessErrorModalClose(e)}
            onClose={(e) => this.accessErrorModalClose(e)}
          />
        ) : null}
      </div>
    )
  }
}

export default reduxForm({ form: 'add_staff_form_validation' })(
  withRouter(withTranslation('common')(DebtorOverview)),
)
