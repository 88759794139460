import FormTextArea from '@components/inputs/form-textarea/form-textarea.component'
import styles from './debtor-legal-note-form.module.scss'
import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import {
  DebtorLegalNoteFormProps,
  DebtorLegalNoteFormValues,
} from '../../../types/debtor-legal-note.type'
import { joiResolver } from '@hookform/resolvers/joi'
import { debtorLegalNoteSchema } from '../../../schemas/legal.schemas'

const DebtorLegalNoteForm: FC<DebtorLegalNoteFormProps> = ({
  submitButtonTitle = 'Submit',
  initialValues,
  onSuccessSubmit,
  loading,
  withBtn = true,
  disabled = false,
}) => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<DebtorLegalNoteFormValues>({
    defaultValues: initialValues,
    resolver: joiResolver(debtorLegalNoteSchema),
  })

  useEffect(() => {
    if (!initialValues) return
    reset(initialValues)
  }, [initialValues])

  return (
    <form className={styles.formWrapper} onSubmit={handleSubmit(onSuccessSubmit)}>
      <FormTextArea
        label="Note"
        {...register('content')}
        error={errors.content?.message}
        disabled={disabled}
      />
      {withBtn || !disabled ? (
        <SubmitButton className={styles.submitButton} loading={loading} buttonType="submit">
          {submitButtonTitle}
        </SubmitButton>
      ) : null}
    </form>
  )
}

export default DebtorLegalNoteForm
