import React from 'react';
import { Modal } from 'reactstrap';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import SimpleReactValidator from 'simple-react-validator';
import ModalComponent from '../../../../../shared/components/ModalCustom';
import moment from 'moment';
import Services from '../../../../../shared/Services';
import { withRouter } from 'react-router';
import 'rc-time-picker/assets/index.css';
import DebtorService from '../../../../../services/DebtorService';
import DebtorListWIP from '../../Debtor/DebtorListWip/DebtorsListWIP';
import AddDebtor from './AddDebtor';
import WIPService from '../../../../../services/WIPService';
import { TailSpin } from 'react-loader-spinner';
import { UserContext } from '../../../../../context/user-context';

const partPaymentOption = [
  { value: '0', label: 'Weekly' },
  { value: '1', label: 'Fortnightly' },
  { value: '2', label: 'Monthly' },
  { value: '3', label: 'Other' },
];

const paymentByOption = [
  { value: '0', label: 'Deft' },
  { value: '1', label: 'Cheque' },
  { value: '2', label: 'Money Order' },
  { value: '3', label: 'CentreLink' },
  { value: '4', label: 'DirectLink' },
];

const paymentTrackingOption = [{ value: 'Any', label: 'Any' }];

const fromOption = [
  { value: '0', label: 'Other' },
  { value: '0', label: 'BOQ' },
  { value: '0', label: 'CentreLink' },
  { value: '0', label: 'Bank est' },
];

const withPirodOption = [
  { value: '0', label: 'Weekly' },
  { value: '1', label: 'Fortnightly' },
  { value: '2', label: 'Monthly' },
  { value: '3', label: 'Tri Weekly' },
  { value: '4', label: 'Bi Monthly' },
];


class Debtors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      sDate: null,
      eDate: null,
      adddebtor: false,
      onClosed: true,
      AdddebtorDialog: false,
      judgement_date: '',
      served_date: '',
      debt_cleared_by_tenant: false,
      debt_cleared_by_bankruptcy: false,
      debt_cleared_by_insurance: false,
      debt_cleared_by_other: false,
      payment_arranged: false,
      amount_received: '',
      part_payment_period: '',
      payment_by: '',
      last_payment: '',
      special_payment: false,
      paymentracking: '',
      pay_plan_debtor_id: '',
      pay_plan_days: '',
      pay_plan_amount: '',
      pay_plan_source: '',
      pay_plan_first_due: '',
      debtors: [],
      contact_1_first: '',
      contact_1_last: '',
      contact_2_first: '',
      contact_2_last: '',
      email: '',
      phone_1: '',
      mobile: '',
      phone_2: '',
      date_of_birth: '',
      date_of_entry: '',
      wipID: '',
      editID: '',
      startdate: '',
      enddate: '',
      deb_Id: '',
      file_number: '',
      total_claim: 0,
      total_received: 0,
      total_recov_cost: 0,
      paid_to_agent: 0,
      allDebtor: [],
      subject: [],
      AdddebtordropDown: true,
      PaymentdropDown: true,
      errorModal: false,
      errorMessage: '',
      errorType: '',
      isAlias: false,
      allData: null,
      loader: false,
    };

    this.wipService = new WIPService();
    this.debtor = new DebtorService();
    this.services = new Services();
    this.getAllDebtorsWIPS = this.getAllDebtorsWIPS.bind(this);
    this.changeInput = this.changeInput.bind(this);
    this.validatorDE = new SimpleReactValidator();
    this.validatorAddr = new SimpleReactValidator();
    this.validatorRelation = new SimpleReactValidator();
    this.validatorReference = new SimpleReactValidator();
    this.validatorOther = new SimpleReactValidator();
    this.validatorWorkPlace = new SimpleReactValidator();
  }

  componentDidMount() {
    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.wipID
    ) {
      this.setState({ wipID: this.props.location.state.wipID });
    }
    this.getAllDebtorsWIPS(this.props.location.state.wipID);
  }

  getAllDebtorsWIPS = async id => {
    this.setState({
      loader: true,
    });
    const params = {
      wip_id: id,
      join: ['debtors', 'debtors.contact'],
      sort: 'id,DESC',
    };
    const list = await this.wipService.getWipsId(params);
    const data = list.data;
    this.setState({ allData: data });
    this.setState({
      wip_ID: this.state.wipID,
      judgement_date: this.dateFormate(data.judgement_date),
      served_date: this.dateFormate(data.served_date),
      debt_cleared_by_tenant: data.is_debt_cleared_by_tenant,
      debt_cleared_by_bankruptcy: data.is_debt_cleared_by_bankruptcy,
      debt_cleared_by_insurance: data.is_debt_cleared_by_insurance,
      debt_cleared_by_other: data.is_debt_cleared_by_other,

      payment_arranged: data.is_payment_arranged,
      amount_received: data.amount_received,
      part_payment_period:
        data.part_payment_period &&
        partPaymentOption.find((_) => _.value === data.part_payment_period),
      payment_by:
        data.payment_by &&
        paymentByOption.find((_) => _.value === data.payment_by),
      last_payment: this.dateFormate(data.last_payment_date),
      special_payment: data.special_payment,
      pay_plan_debtor_id:
        data.pay_plan_debtor_id &&
        paymentTrackingOption.find(
          (_) => _.value === data.pay_plan_debtor_id,
        ),
      pay_plan_days:
        data.pay_plan_days &&
        withPirodOption.find((_) => _.value === data.pay_plan_days),
      pay_plan_amount: data.pay_plan_amount,
      pay_plan_source:
        data.pay_plan_source &&
        fromOption.find((_) => _.value === data.pay_plan_source),
      pay_plan_first_due: this.dateFormate(data.pay_plan_first_due),

      file_number: data.file_number,
      total_claim: data.total_claim ? parseInt(data.total_claim) : 0,
      total_received: data.total_received ? parseInt(data.total_received) : 0,
      total_recov_cost: data.total_recov_cost
        ? parseInt(data.total_recov_cost)
        : 0,
      paid_to_agent: data.paid_to_agent ? parseInt(data.paid_to_agent) : 0,
      current_interest: data.current_interest
        ? parseInt(data.current_interest)
        : 0,
      subject: data.subject,
      debtors: data.debtors,
    });
    this.setState({
      loader: false,
    });
  };

  dateFormate(dateNew) {
    let fDate;
    if (dateNew && dateNew !== '0000-00-00') {
      if (dateNew) {
        const date = moment(dateNew, 'YYYY-MM-DDTHH:mm:ss.sssZ').format('YYYY-MM-DDTHH:mm:ss.sssZ');
        fDate = '';
        if (date === 'Invalid date') {
          fDate = new Date(
            dateNew.slice(0, dateNew.length - 2).replace(/\//g, '-'),
          );
        } else {
          fDate = new Date(date);
        }
      } else {
        return '';
      }
      if (fDate === 'Invalid Date' || fDate === 'Invalid date') {
        return '';
      } else {
        return fDate;
      }
    }
  }

  addDebtor = () => {

    this.setState({
      AdddebtorDialog: true,
      activeTab: 1,
    });
  };

  handleOptionChange = (e, name) => {
    this.setState({ [name]: e.target.checked });
  };

  handleChange = (selectedOption, name) => {
    this.setState({ [name]: selectedOption });
  };

  onClosed = () => {
    if (!this.state.editID) {
      this.setState({
        address_hist: [
          {
            id: '_' + Math.random().toString(36).substr(2, 9),
            address_1: '',
            address_2: '',
            address_3: '',
            suburb: '',
            state: '',
            post_code: '',
            country: '',
            address_hist_start: '',
            address_hist_end: '',
            address_hist_rank: '',
          },
        ],

        relations: [
          {
            id: '_' + Math.random().toString(36).substr(2, 9),
            name: '',
            relationship: '',
            phone: '',
            address: '',
            call_result: '',
            call_note: '',
          },
        ],

        references: [
          {
            id: '_' + Math.random().toString(36).substr(2, 9),
            name: '',
            phone: '',
            address: '',
            call_result: '',
            call_note: '',
          },
        ],

        work_place: [
          {
            id: '_' + Math.random().toString(36).substr(2, 9),
            name: '',
            phone: '',
            contact: '',
            address: '',
            call_result: '',
            call_note: '',
          },
        ],

        other: [
          {
            id: '_' + Math.random().toString(36).substr(2, 9),
            name: '',
            phone: '',
            address: '',
            call_result: '',
            call_note: '',
          },
        ],
      });
    }
    this.setState({
      contact_1_first: '',
      contact_1_last: '',
      email: '',
      phone_1: '',
      mobile: '',
      phone_2: '',
      date_of_birth: '',
      date_of_entry: '',
      AdddebtorDialog: false,
      editID: '',
      deb_Id: '',
      activeTab: 1,
    });

    this.validatorDE.hideMessages();
    this.validatorAddr.hideMessages();
    this.validatorRelation.hideMessages();
    this.validatorReference.hideMessages();
    this.validatorOther.hideMessages();
    this.validatorWorkPlace.hideMessages();
  };

  handleChangeDate = (date, name) => {
    this.setState({
      [name]: date,
    });
  };

  cancel = () => {
    const client = JSON.parse(localStorage.getItem('contactsId'));
    this.props.history.push(`/contacts/${client}`, { fromWIP: true });
  };

  editDebtor = (item) => {
    if (item) {
      this.setState(
        { deb_Id: item.id },
        () => {
          this.setState({ AdddebtorDialog: true });
        },
      );
    }
  };

  changeInput(e) {
    if (!e.target.validity.valid) {
      return null;
    }
    this.setState({ [e.target.name]: e.target.value });
  }

  deleteDebtor = (item) => {
    const list = ([] =
      this.state.debtors && this.state.debtors.filter((_) => _.id !== item.id));
    if (list) {
      this.setState({ debtors: list });
    }
    this.debtor.deleteDebtor(item.id).then((res) => {
      try {
        if (res.status === 200) {
          this.services.successToast('Debtor delete successfully');
          this.getAllDebtorsWIPS(this.state.wipID);
        } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
          this.setState({ accessErrorModal: true });
        } else {
          this.services.errorToast(res.message);
        }
      } catch (e) {
        console.log(e);
        this.services.errorToast('500 Internal Server Error');
      }
    });
  };

  saveDebtor = () => {
    const formData = {
      id: this.state.wipID,
      is_debt_cleared_by_bankruptcy: this.state.debt_cleared_by_bankruptcy,
      is_debt_cleared_by_insurance: this.state.debt_cleared_by_insurance,
      is_debt_cleared_by_other: this.state.debt_cleared_by_other,
      is_debt_cleared_by_tenant: this.state.debt_cleared_by_tenant,
      is_payment_arranged: this.state.payment_arranged,
      judgement_date: this.state.judgement_date ? this.state.judgement_date : '',
      last_payment_date: this.state.last_payment ? this.state.last_payment : '',
      served_date: this.state.served_date ? this.state.served_date : '',
      special_payment: this.state.special_payment,
      payment_by: this.state.payment_by && this.state.payment_by.value,
      amount_received: this.state.amount_received,
      total_received: +this.state.total_received,
      part_payment_period: this.state.part_payment_period && this.state.part_payment_period.value,
    };

    this.wipService.editWipsId(formData).then((res) => {
      if (res.status === 200) {
        this.services.successToast('Debtor Updated Successfully');
        this.cancel();
      } else if (
        res.statusCode === 401 &&
        res.responseType === 'UNAUTHORIZED'
      ) {
        this.setState({ accessErrorModal: true });
      } else {

        this.services.errorToast(res.message);
      }
    });
  };

  render() {
    const { loader } = this.state;
    return (
      (loader ? (
        <div className='loader'>
          <TailSpin
            height='100'
            width='100'
            color='#f69066'
            ariaLabel='loading'
          />
        </div>
      ) : (<div className='w-100'>
        <div id='contacts_accordion' className='form'>
          <div className='card pb-0'>
            <div className='slide_down_wrap mb-4 rounded'>
              <div className=' card-body bg-white pl-0 rounded'>

                <div
                  className={`slide_drop_down boxshadow-none cursor-pointer d-flex flex-wrap align-items-center justify-content-between rounded ${this.state.PaymentdropDown ? 'active' : ''}`}
                >
                  <h3 className='page-title mb-0' data-toggle='collapse'> Debtors list </h3>
                  <div>
                    <button
                      className='btn-gray mr-5'
                      onClick={() => this.addDebtor()}
                    >
                      Add Debtors
                    </button>
                    <span className='show_dropdown'
                          onClick={() => this.setState({ PaymentdropDown: !this.state.PaymentdropDown })}
                    > <svg className='ml-2' version='1.1' id='Capa_1' x='0px' y='0px'
                           width='14px' height='14px' viewBox='0 0 284.929 284.929'>
                                        <g>
                                            <path d='M282.082,76.511l-14.274-14.273c-1.902-1.906-4.093-2.856-6.57-2.856c-2.471,0-4.661,0.95-6.563,2.856L142.466,174.441
                                                L30.262,62.241c-1.903-1.906-4.093-2.856-6.567-2.856c-2.475,0-4.665,0.95-6.567,2.856L2.856,76.515C0.95,78.417,0,80.607,0,83.082
                                                c0,2.473,0.953,4.663,2.856,6.565l133.043,133.046c1.902,1.903,4.093,2.854,6.567,2.854s4.661-0.951,6.562-2.854L282.082,89.647
                                                c1.902-1.903,2.847-4.093,2.847-6.565C284.929,80.607,283.984,78.417,282.082,76.511z' />
                                        </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </svg>
                                    </span>
                  </div>
                </div>
                <div className={`bg-white hide_contact ${this.state.PaymentdropDown ? 'active pt-4' : ''}`}>
                  <DebtorListWIP editDebtor={this.editDebtor} deleteDebtor={this.deleteDebtor}
                                 debtors={this.state.debtors} />
                </div>
              </div>
            </div>
            <div className='slide_down_wrap mb-4 rounded'>
              <div className=' card-body bg-white pl-0 rounded'>
                <div className={`slide_drop_down boxshadow-none cursor-pointer d-flex flex-wrap align-items-center justify-content-between rounded ${
                    this.state.AdddebtordropDown ? 'active' : ''
                  }`}>
                  <div className='d-flex align-items-center'>
                    <h3 className='page-title mb-0 mr-4' data-toggle='collapse'>
                      Details
                    </h3>
                  </div>
                  <span
                    className='show_dropdown'
                    onClick={() => this.setState({AdddebtordropDown: !this.state.AdddebtordropDown,})}
                  >
                    <svg
                      className='ml-2'
                      version='1.1'
                      id='Capa_1'
                      x='0px'
                      y='0px'
                      width='14px'
                      height='14px'
                      viewBox='0 0 284.929 284.929'
                    >
                      <g>
                        <path
                          d='M282.082,76.511l-14.274-14.273c-1.902-1.906-4.093-2.856-6.57-2.856c-2.471,0-4.661,0.95-6.563,2.856L142.466,174.441
                                            L30.262,62.241c-1.903-1.906-4.093-2.856-6.567-2.856c-2.475,0-4.665,0.95-6.567,2.856L2.856,76.515C0.95,78.417,0,80.607,0,83.082
                                            c0,2.473,0.953,4.663,2.856,6.565l133.043,133.046c1.902,1.903,4.093,2.854,6.567,2.854s4.661-0.951,6.562-2.854L282.082,89.647
                                            c1.902-1.903,2.847-4.093,2.847-6.565C284.929,80.607,283.984,78.417,282.082,76.511z'
                        />
                      </g>
                      {' '}
                      <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g>
                      {' '}
                      <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g>
                      {' '}
                      <g> </g> <g> </g> <g> </g>
                      {' '}
                    </svg>
                  </span>
                </div>
                <div className={`bg-white hide_contact ${this.state.AdddebtordropDown ? 'active pt-4' : ''}`}>
                  <div className='row pt-3'>
                    <div className='col-12 col-md-6 col-lg-3'>
                      <div className='form-group'>
                        <label className='form__form-group-label'>
                          {' '}
                          Judgment Date{' '}
                        </label>

                        <div className='date-picker date-picker--interval'>
                          <DatePicker
                            selected={
                              this.state.judgement_date
                                ? this.dateFormate(this.state.judgement_date)
                                : ''
                            }
                            onChange={(e) =>
                              this.handleChangeDate(e, 'judgement_date')
                            }
                            name='judgement_date'
                            dateFormat='dd/MM/yy'
                            dropDownMode='select'
                            popperPlacement='center'
                            placeholderText='Select Date'
                          />
                          <span className='date_icon'>
                            <img src={'./image/calendar.svg'} alt='date' />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className='col-12 col-md-6 col-lg-3'>
                      <div className='form-group'>
                        <label className='form__form-group-label'>
                          {' '}
                          Served Date{' '}
                        </label>
                        <div className='date-picker date-picker--interval'>
                          <DatePicker
                            selected={
                              this.state.served_date
                                ? this.dateFormate(this.state.served_date)
                                : ''
                            }
                            onChange={(e) =>
                              this.handleChangeDate(e, 'served_date')
                            }
                            name='served_date'
                            dateFormat='dd/MM/yy'
                            dropDownMode='select'
                            popperPlacement='center'
                            placeholderText='Select Date'
                          />
                          <span className='date_icon'>
                            <img src={'./image/calendar.svg'} alt={'date'} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-12 col-md-6 col-lg-3 mt-2'>
                      <div className='form__form-group'>
                        <div className='form__form-group-field'>
                          <label className={`colored-click radio-btn--colored`}>
                            <input
                              className='radio-btn__radio'
                              type='radio'
                              name='optradio'
                              checked={this.state.debt_cleared_by_tenant}
                              onChange={(e) =>
                                this.handleOptionChange(
                                  e,
                                  'debt_cleared_by_tenant',
                                )
                              }
                            />
                            <span className='radio-btn__radio-custom' />
                            <span className='radio-btn__label mt-0'>
                              Debt Cleared by Tenant
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className='col-12 col-md-6 col-lg-3 mt-2'>
                      <div className='form__form-group'>
                        <div className='form__form-group-field'>
                          <label className={`colored-click radio-btn--colored`}>
                            <input
                              className='radio-btn__radio'
                              type='radio'
                              name='optradio'
                              checked={this.state.debt_cleared_by_bankruptcy}
                              onChange={(e) =>
                                this.handleOptionChange(
                                  e,
                                  'debt_cleared_by_bankruptcy',
                                )
                              }
                            />
                            <span className='radio-btn__radio-custom' />
                            <span className='radio-btn__label mt-0'>
                              Debt Cleared by Bankruptcy
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className='col-12 col-md-6 col-lg-3 mt-2'>
                      <div className='form__form-group'>
                        <div className='form__form-group-field'>
                          <label className={`colored-click radio-btn--colored`}>
                            <input
                              className='radio-btn__radio'
                              type='radio'
                              name='optradio'
                              checked={this.state.debt_cleared_by_insurance}
                              onChange={(e) =>
                                this.handleOptionChange(
                                  e,
                                  'debt_cleared_by_insurance',
                                )
                              }
                            />
                            <span className='radio-btn__radio-custom' />
                            <span className='radio-btn__label mt-0'>
                              Debt Cleared by Insurance
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className='col-12 col-md-6 col-lg-3 mt-2'>
                      <div className='form__form-group'>
                        <div className='form__form-group-field'>
                          <label className={`colored-click radio-btn--colored`}>
                            <input
                              className='radio-btn__radio'
                              type='radio'
                              name='optradio'
                              checked={this.state.debt_cleared_by_other}
                              onChange={(e) =>
                                this.handleOptionChange(
                                  e,
                                  'debt_cleared_by_other',
                                )
                              }
                            />
                            <span className='radio-btn__radio-custom' />
                            <span className='radio-btn__label mt-0'>
                              Debt Cleared by Other
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-12 col-md-6 col-lg-3'>
                      <div className='form-group'>
                        <label className='form__form-group-label'>
                          Amount Received{' '}
                        </label>
                        <input
                          type='text'
                          className='custom-input'
                          name='amount_received'
                          onChange={this.changeInput}
                          value={this.state.amount_received || ''}
                          pattern='[0-9]*'
                        />
                      </div>
                    </div>
                    <div className='col-12 col-md-6 col-lg-3'>
                      <div className='d-flex align-items-center h-100 ml-2'>
                        <div className='round_checkbox'>
                          <input
                            type='checkbox'
                            className=''
                            name='payment_arranged'
                            id='Arranged'
                            checked={this.state.payment_arranged || ''}
                            onChange={() =>
                              this.setState({
                                payment_arranged: !this.state.payment_arranged,
                              })
                            }
                          />
                          <label className='mb-0' htmlFor='Arranged' />
                        </div>
                        <p className='m-0 ml-3'> Payment Arranged </p>
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-12 col-md-6 col-lg-3'>
                      <div className='form-group'>
                        <label className='form__form-group-label'>
                          {' '}
                          Part Payment Period
                        </label>
                        <Select
                          classNamePrefix='react-select'
                          className='react-select'
                          options={partPaymentOption}
                          value={this.state.part_payment_period}
                          name='part_payment_period'
                          onChange={(e) =>
                            this.handleChange(e, 'part_payment_period')
                          }
                        />
                      </div>
                    </div>
                    <div className='col-12 col-md-6 col-lg-3'>
                      <div className='form-group'>
                        <label className='form__form-group-label'>
                          Total Received{' '}
                        </label>
                        <input
                          type='text'
                          className='custom-input'
                          value={this.state.total_received}
                          name='total_received'
                          onChange={this.changeInput}
                          pattern='[0-9]*'
                        />
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-12 col-md-6 col-lg-3'>
                      <div className='form-group'>
                        <label className='form__form-group-label'>
                          {' '}
                          Payment By
                        </label>
                        <Select
                          classNamePrefix='react-select'
                          className='react-select'
                          options={paymentByOption}
                          value={this.state.payment_by}
                          name='payment_by'
                          onChange={(e) => this.handleChange(e, 'payment_by')}
                        />
                      </div>
                    </div>
                    <div className='col-12 col-md-6 col-lg-3'>
                      <div className='form-group'>
                        <label className='form__form-group-label'>
                          {' '}
                          Last Payment Date{' '}
                        </label>
                        <div className='date-picker date-picker--interval'>
                          <DatePicker
                            selected={
                              this.state.last_payment
                                ? this.dateFormate(this.state.last_payment)
                                : ''
                            }
                            onChange={(e) =>
                              this.handleChangeDate(e, 'last_payment')
                            }
                            name='last_payment'
                            dateFormat='dd/MM/yy'
                            dropDownMode='select'
                            popperPlacement='center'
                            placeholderText='Select Date'
                          />
                          <span className='date_icon'>
                            <img src={'./image/calendar.svg'} alt='date' />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-12 col-md-6 col-lg-3'>
                      <div className='d-flex align-items-center h-100 ml-2'>
                        <div className='round_checkbox'>
                          <input
                            type='checkbox'
                            className=''
                            name='special_payment'
                            id='SpecialPayment'
                            checked={this.state.special_payment || false}
                            onChange={() =>
                              this.setState({
                                special_payment: !this.state.special_payment,
                              })
                            }
                          />
                          <label className='mb-0' htmlFor='SpecialPayment' />
                        </div>
                        <p className='m-0 ml-3'>
                          {' '}
                          Special Payment Arrangement OK{' '}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='w-100 d-flex justify-content-end'>
          <button
            type='button'
            className='btn-group--icons btn btn-secondary'
            onClick={() => this.saveDebtor()}
          >
            Save Changes
          </button>
          <button
            type='button'
            className='btn-group--icons btn-gray m-0'
            onClick={() => this.cancel()}
          >
            Cancel
          </button>
        </div>

        <Modal
          isOpen={this.state.AdddebtorDialog}
          size='lg'
          style={{ maxWidth: '1100px', width: '95%' }}
          className='theme-light'
          centered
        >
          <AddDebtor debtorId={this.state.deb_Id} getAllDebtorsWIPS={this.getAllDebtorsWIPS} onClosed={this.onClosed} />
        </Modal>

        {this.state.errorModal ? (
          <ModalComponent
            modal={this.state.errorModal}
            color={this.state.errorType}
            isCancel={true}
            message={this.state.errorMessage}
            onConfirm={(e) => this.deleteDebtor(e)}
            onClose={() => this.setState({ errorModal: false })}
          />
        ) : null}
      </div>))
    );
  }
}

export default withRouter(Debtors);
