import { FC } from 'react'
import { DebtorLegal, DebtorLegalClaimNumber } from '../../../types/debtor-legal.type'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import { getDebtorLegalByIdQueryKey } from '../../../utils/legal-query-key-factory.utils'
import Modal from '@components/modal'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import styles from './debtor-claim-number-modal.module.scss'
import { BaseEditModalProps } from '@src/types/component-types'
import EditDebtorLegalClaimNumberForm from '../../forms/debtor-claim-number-form/edit-debtor-claim-number-form.component'
import ModalHeader from '@components/modal-header.component'

const EditDebtorLegalClaimNumberModal: FC<BaseEditModalProps<DebtorLegalClaimNumber>> = ({
  entity: debtorLegalClaimNumber,
  ...modalProps
}) => {
  const queryClient = useQueryClient()
  const handleSuccessfulSubmit = (debtorLegalClaimNumber: DebtorLegalClaimNumber) => {
    queryClient.setQueryData<DebtorLegal>(
      getDebtorLegalByIdQueryKey(debtorLegalClaimNumber.debtorLegalId),
      (prevData) =>
        prevData
          ? {
              ...prevData,
              claimNumbers: prevData.claimNumbers.map((cn) =>
                cn.id === debtorLegalClaimNumber.id ? debtorLegalClaimNumber : cn,
              ),
            }
          : undefined,
    )
    toast.success('Claim Number updated successfully')
    modalProps.onClose()
  }

  return (
    <Modal className={styles.modalWrapper} {...modalProps} withBtns={false}>
      <ModalHeader onCloseClick={modalProps.onClose} />
      <Header mb={4}>Edit Claim Number</Header>
      <EditDebtorLegalClaimNumberForm
        onSuccessSubmit={handleSuccessfulSubmit}
        entity={debtorLegalClaimNumber}
      />
    </Modal>
  )
}

export default EditDebtorLegalClaimNumberModal
