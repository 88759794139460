import FormSelect from '@components/inputs/form-select/form-select.component'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { useUpdateWipByIdMutation } from '@containers/Recovery/Wip/hooks/mutations/update-wip-by-id.hook'
import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import styles from './add-debtors-to-pay-tracking-form.module.scss'
import { joiResolver } from '@hookform/resolvers/joi'
import { addDebtorsToPayTrackingSchema } from '../../../schemas/tracking.schemas'
import { AddDebtorsToPayTrackingFormProps, AddDebtorsToPayTrackingFormValues } from '../../../types/payment-tracking.type'

const AddDebtorsToPayTrackingForm: FC<AddDebtorsToPayTrackingFormProps> = ({
  debtorsOptions,
  debtorsAddedToPayTracking,
  wipId,
  onSuccessSubmit,
}) => {
  const { mutateAsync, isLoading } = useUpdateWipByIdMutation()
  const { control, handleSubmit } = useForm<AddDebtorsToPayTrackingFormValues>({
    resolver: joiResolver(addDebtorsToPayTrackingSchema),
  })

  const onSubmit = async (data: AddDebtorsToPayTrackingFormValues) => {
    try {
      const res = await mutateAsync({
        id: wipId,
        data: {
          debtors_added_to_pay_tracking: [
            ...debtorsAddedToPayTracking.map((debtor) => ({ id: debtor.id })),
            ...data.debtors.map((debtor) => ({ id: debtor.value })),
          ],
        },
        params: {
          join: 'debtors_added_to_pay_tracking',
          fields: 'debtors_added_to_pay_tracking',
        },
      })

      onSuccessSubmit && onSuccessSubmit(res.debtors_added_to_pay_tracking)
    } catch (e) {
      toast.error('Failed to add debtors to pay tracking')
      console.error(e)
    }
  }

  return (
    <form className={styles.formWrapper} onSubmit={handleSubmit(onSubmit)}>
      <FormSelect
        isMulti
        control={control}
        name="debtors"
        label="Debtors"
        options={debtorsOptions}
      />
      <SubmitButton className={styles.submitButton} buttonType="submit" loading={isLoading}>
        Add To Pay Tracking
      </SubmitButton>
    </form>
  )
}

export default AddDebtorsToPayTrackingForm
