import Modal from '@components/modal'
import { FC } from 'react'
import styles from './debtor-other-modal.module.scss'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import { useQueryClient } from '@tanstack/react-query'
import {
  CreateDebtorAssociateDetailModalProps,
  DebtorOther,
} from '@containers/Recovery/Wip/_modules/Debtors/types/debtor-associate-detail.type'
import { getDebtorQueryKey } from '@containers/Recovery/Wip/_modules/Debtors/utils/debtor-query-key-factory.utils'
import { Debtor } from '@containers/Recovery/Wip/_modules/Debtors/types/debtor.type'
import CreateDebtorOtherForm from '../../forms/debtor-other-form/create-debtor-other-form.component'

const CreateDebtorOtherModal: FC<CreateDebtorAssociateDetailModalProps> = ({
  debtorId,
  ...modalProps
}) => {
  const queryClient = useQueryClient()

  const handleSuccessSubmit = (debtorOther: DebtorOther) => {
    queryClient.setQueryData<Debtor>(getDebtorQueryKey(debtorId), (prevData) =>
      prevData ? { ...prevData, other: [...prevData.other, debtorOther] } : undefined,
    )
    modalProps.onClose()
  }

  return (
    <Modal className={styles.modalWrapper} {...modalProps} withBtns={false} classNameLayout={styles.layout}>
      <Header mb={4}>Add Other</Header>
      <CreateDebtorOtherForm
        debtorId={debtorId}
        onSuccessSubmit={handleSuccessSubmit}
        onCancelClick={modalProps.onClose}
      />
    </Modal>
  )
}

export default CreateDebtorOtherModal
