import React from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';

const EmailCheckbox = ({ name, value, handleSelect, checked }) => {
  const label = (
    <>
      <b>{`${name}: `}</b>
      {value}
    </>
  );
  return (
    <FormControlLabel
      sx={{ margin: 0 }}
      control={
        <Checkbox
          value={value}
          checked={checked}
          onChange={() => handleSelect(value)}
          sx={{ padding: 0, marginRight: '10px' }}
        />
      }
      label={label}
    />
  );
};

export default EmailCheckbox;
