import NavigationButton from '@components/navigation-button/navigation-button'
import styles from './send-email-header.module.scss'
import RoundedLeftLongArrow from '@src/icons/rounded-left-long-arrow'

const SendEmailHeader = () => {
  return (
    <div className={styles.sendEmailHeaderWrapper}>
      <div>
        <NavigationButton icon={RoundedLeftLongArrow} isLink to='/inbox' />
      </div>
      <div className={styles.separator}/>
      <div>
        <span className={styles.title}>New Message</span>
      </div>
    </div>
  )
}

export default SendEmailHeader
