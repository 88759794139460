import Modal from '@components/modal'
import { SelectOption } from '@src/types/component-types'
import { FC, useState } from 'react'
import { CLIENT_SEND_TO_OPTIONS } from '@containers/Communication/const/communication.const'
import { useGetEmailTemplates } from '@containers/Communication/hooks/get-email-templates.hook'
import {
  transformCompanyContactToEmailOptions,
  transformEmailTemplateToOption,
  transformFileToBase64,
} from '@containers/Communication/utils/communcation-transfrom.utils'
import {
  CommunicationOptionType,
  EmailRecipientOption,
} from '@containers/Communication/types/communication.type'
import { SendMessageBody } from '@containers/Inbox/types/graph-api-mail.type'
import { toast } from 'react-toastify'
import { useSendMessageMutation } from '@containers/Inbox/hooks/mutations/send-message.hook'
import {
  ATTRIBUTES_SUBJECT_DELIMITER,
  CLIENT_ATTRIBUTE_KEYWORD,
} from '@containers/Inbox/view-email-page/const/view-email-page.const'
import { encryptXOR } from '@containers/Inbox/view-email-page/components/utils/xor-encryption.utils'
import { REACT_APP_XOR_ENCRYPTION_KEY } from '@src/lib/global-variables'
import { useQueryClient } from '@tanstack/react-query'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import { useRefreshEmails } from '@containers/Setting/components/Contacts/Notes/hooks/refresh-emails.hook'
import {
  ClientSendEmailModalProps,
  SendEmailSubmitFormValues,
} from '@containers/Communication/types/email.type'
import SendEmailForm from '@containers/Communication/components/forms/send-email-form/send-email-form.component'

const ClientSendEmailModal: FC<ClientSendEmailModalProps> = ({ client, isOpen, onClose }) => {
  const queryClient = useQueryClient()
  const { mutateAsync: sendEmail, isLoading: isEmailSending } = useSendMessageMutation()
  const { mutateAsync: refreshEmails, isLoading: isEmailsRefreshing } = useRefreshEmails()
  const [recipientOptions, setRecipientOptions] = useState<EmailRecipientOption[]>([])
  const { data } = useGetEmailTemplates({ limit: 999 })

  const templateOptions = data?.data?.map(transformEmailTemplateToOption) || []

  const handleSendToChange = (option: SelectOption) => {
    const value = option.value
    if (value === CommunicationOptionType.CompanyContact) {
      const contacts = client.contacts || []
      setRecipientOptions(
        contacts
          .flatMap(transformCompanyContactToEmailOptions)
          .filter((o) => !!o) as EmailRecipientOption[],
      )
    } else {
      setRecipientOptions([])
    }
  }

  const handleSubmit = async (data: SendEmailSubmitFormValues) => {
    let filesInBase64: string[] = []
    try {
      filesInBase64 = await Promise.all(data.attachments.map(transformFileToBase64))
    } catch (e) {
      toast.error('Failed to parse files')
      console.error(e)
      return
    }

    const tag = `${ATTRIBUTES_SUBJECT_DELIMITER} ${CLIENT_ATTRIBUTE_KEYWORD}:${encryptXOR(
      client.id,
      REACT_APP_XOR_ENCRYPTION_KEY,
    )}`

    const dataToSend: SendMessageBody = {
      message: {
        toRecipients: data.mainRecipients,
        ccRecipients: data.ccRecipients,
        bccRecipients: data.bccRecipients,
        subject: data.subject + tag,
        body: {
          contentType: 'html',
          content: data.body,
        },
        attachments: data.attachments.map((file, index) => ({
          '@odata.type': '#microsoft.graph.fileAttachment',
          name: file.name,
          contentBytes: filesInBase64[index].split(',')[1],
          contentType: file.type,
        })),
      },
    }

    const [, err] = await sendEmail(dataToSend)

    if (err) {
      toast.error('Something went wrong')
      console.error(err)
      return
    }

    await refreshEmails({ client_id: client.id })
    await Promise.all([
      queryClient.invalidateQueries([GLOBAL_QUERIES.MAIL_FOLDER.GET]),
      queryClient.invalidateQueries([GLOBAL_QUERIES.GRAPH_API_MESSAGES.GET_BY_FOLDER_ID]),
      queryClient.invalidateQueries([GLOBAL_QUERIES.COMBINED_NOTES.GET]),
      queryClient.invalidateQueries([GLOBAL_QUERIES.UNIFORM_EMAILS.GET]),
    ])
    toast.success('Email sent')
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} withBtns={false}>
      <SendEmailForm
        onSubmit={handleSubmit}
        sendToOptions={CLIENT_SEND_TO_OPTIONS}
        recipientOptions={recipientOptions}
        templateOptions={templateOptions}
        loading={isEmailSending || isEmailsRefreshing}
        onSendToChange={handleSendToChange}
        onCancelClick={onClose}
      />
    </Modal>
  )
}

export default ClientSendEmailModal
