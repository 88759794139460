import { Typography } from '@material-ui/core'
import styles from './taskboard.module.scss'
import Column from './column'
import { Controller, useForm, useWatch } from 'react-hook-form'

import { DragDropContext } from 'react-beautiful-dnd'
import { useMemo, useState } from 'react'
import Switch from '@components/switch'
import TasksService from '@services/TasksService.js'
import { useQueryClient } from '@tanstack/react-query'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import TaskFilter from './task-filter'
import { TASK_TYPE_OPTIONS } from './modals/utils'
import LabelWrapper from '@components/label-wrapper'
import { ColumnProps } from './types'
import { useTaskContext } from '@src/context/task-context'
import { formatAssigneName, initialColumns } from './utils'
import { useIsAdmin, useUserContext } from '@src/context/user-context'
import { useQuery } from '@tanstack/react-query'
import { useGetAutomations } from '../automations/utils'
import ModalComponent from '../../../../../shared/components/ModalCustom'
import { ErrorModal } from '../types/contact-list.type'
import { toast } from 'react-toastify'

interface IForm {
  isArchived: boolean
  isAssigned: boolean
  isUnassigned: boolean
  filterByType: number[]
  isAutomationBoardShown: boolean
}

const taskService = new TasksService()
const TaskBoard = () => {
  const [tasks, setTasks] = useState<ColumnProps[]>([])
  const [warningModal, setWarningModal] = useState<ErrorModal | null>(null)

  const queryClient = useQueryClient()
  const {
    filterTaskListDependingOnType,
    staff,
    companies,
    selectedCompanyState,
    currentClient,
    taskMode,
    isSales,
    isRecovery,
    currentWip,
    setCurrentColumns,
    fetchColumns,
  } = useTaskContext()
  const { user } = useUserContext()
  const task = new TasksService()

  const clientId = Number(currentClient?.id) || Number(selectedCompanyState?.id) || 0
  const { automations } = useGetAutomations(
    isSales ? { clientId: clientId } : { wip_id: currentWip },
  )

  const isAdmin = useIsAdmin()
  const currentCompanyContacts = companies?.find(({ id }) => id === clientId)?.contacts || []

  const { control, setValue } = useForm<IForm>({
    defaultValues: {
      isArchived: false,
      isUnassigned: false,
      isAssigned: false,
      filterByType: [],
      isAutomationBoardShown: false,
    },
  })

  const { isArchived, isUnassigned, filterByType, isAssigned, isAutomationBoardShown } = useWatch({
    control,
  })

  useQuery({
    queryKey: [GLOBAL_QUERIES.TASKSERVICE.GET, taskMode, isAutomationBoardShown],
    queryFn: getTasks,
    onSuccess: (data) => {
      const { list, columns } = data
      setCurrentColumns(columns)
      if (isAutomationBoardShown) {
        const automatedColumns = list.reduce(
          (acc, task) => {
            // Find the column in the accumulator that has the same step as the task's column_belong_id
            const column = acc.find((c) => c.id === task.automation_column_belong_id)
            if (column) {
              // If the column is found, push the task into the tasks array of the found column
              column.tasks.push(task)
            }
            // Always return the accumulator for the next iteration
            return acc
          },
          // Initial accumulator value is an array of columns with tasks array initialized
          columns
            ?.map((column: any) => ({
              id: column.id,
              columnColor: column.columnColor,
              columnName: column.title,
              step: column.step,
              tasks: [],
            }))
            .sort((a, b) => a.step - b.step),
        )
        setTasks(automatedColumns)
        return
      }

      const usualColumns = list.reduce((acc, task) => {
        if (isSales) {
          if (task.column_belong_id === 1) {
            acc[0].tasks.push(task)
          } else if (task.column_belong_id === 2) {
            acc[1].tasks.push(task)
          } else if (task.column_belong_id === 3) {
            acc[2].tasks.push(task)
          }
          // Always return the accumulator for the next iteration
          return acc
        } else {
          if (task.column_belong_id === 1) {
            acc[0].tasks.push(task)
          } else {
            acc[1].tasks.push(task)
          }
          // Always return the accumulator for the next iteration
          return acc
        }
      }, initialColumns(taskMode))
      setTasks(usualColumns)
    },
    refetchOnWindowFocus: false,
    enabled: !!taskMode && (!!currentWip || !!clientId),
  })

  async function getTasks() {
    const params = {
      join: ['assigned_staff', 'companies', 'debtors', 'wip'],
      filter: isSales ? `company_id||$eq||${clientId}` : `wip_id||$eq||${currentWip}`,
      sort: 'time_created,DESC',
      limit: 999,
    }
    const list = await task.getTasks(params)
    const columns = await fetchColumns(selectedCompanyState.id)

    return { list, columns }
  }

  const changeColumnBelongId = async (taskId: string, columnStep) => {
    try {
      await taskService.changeColumnBelongId(
        Number(taskId),
        Number(columnStep?.step),
        (columnStep?.tasks?.length || 0) + 1 || null,
        isAutomationBoardShown ? true : false,
      )
    } catch {
      toast.warn("You can't change the column until the subtasks are completed")
    }

    queryClient.invalidateQueries([GLOBAL_QUERIES.TASKSERVICE.GET])
  }

  const handleWarningModalClose = () => {
    setWarningModal(null)
  }

  const handleWaringModalConfirm = async (taskId: string, columnStep) => {
    await changeColumnBelongId(taskId, columnStep)
    setWarningModal(null)
  }

  async function handleOnDragEnd(result) {
    if (!result.destination) return

    const {
      destination: { droppableId: columnToId },
      draggableId: taskId,
    } = result

    const columnStep = tasks.find(({ id }) => id === Number(columnToId))

    const isWillMoveToDone = isRecovery ? columnStep?.step === 2 : columnStep?.step === 3

    if (isWillMoveToDone) {
      setWarningModal({
        errorMessage: 'Automations will be started. Are you sure this task is done?',
        errorType: 'warning',
        errorModal: true,
        onConfirm: () => handleWaringModalConfirm(taskId, columnStep),
        onClose: handleWarningModalClose,
      })
      return
    }
    changeColumnBelongId(taskId, columnStep)
  }
  const findAutomationAmount = (columnId: number) => {
    return automations?.filter(({ referrence_column_id }) => referrence_column_id === columnId)
      .length
  }
  const handleClearFilters = () => {
    setValue('filterByType', [])
  }

  const filtersForStaff =
    staff?.map(({ first_name, last_name, id }) => ({
      label: formatAssigneName({
        first_name,
        last_name,
        isAssigned: user?.id === id,
      }),
      value: id,
    })) || []

  const stuffIds = filtersForStaff.map(({ value }) => value) || []
  const filterByStaff = useMemo(
    () => filterByType?.filter((n) => stuffIds.includes(n)) || [],
    [filterByType],
  )
  const taskTypesIds = TASK_TYPE_OPTIONS.map(({ value }) => value) || []
  const filterByTaskType =
    useMemo(() => filterByType?.filter((n) => taskTypesIds.includes(n)), [filterByType]) || []

  const contactOptions = currentCompanyContacts.map(({ first_name, last_name, id }) => ({
    label: formatAssigneName({
      first_name,
      last_name,
      isAssigned: user?.id === id,
    }),
    value: id,
  }))
  const contactIds: number[] = contactOptions.map(({ value }) => value) || []
  const filterByContact = useMemo(
    () => filterByType?.filter((n) => contactIds?.includes(n)),
    [filterByType],
  )

  const filterOptions = [
    {
      isNested: false,
      name: 'Client',
      innerOptions: [...(contactOptions || [])],
      filter: false,
    },
    {
      isNested: false,
      name: 'Task Type',
      innerOptions: [...TASK_TYPE_OPTIONS],
      filter: false,
    },
    {
      name: 'Staff',
      isNested: false,
      innerOptions: filtersForStaff,
      filter: false,
    },
  ]

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <div className={styles.wrapper}>
        <div className={styles.filterSection}>
          {isAdmin && (
            <span>
              <Controller
                control={control}
                name="isAutomationBoardShown"
                render={({ field }) => <Switch value={field.value} onChange={field.onChange} />}
              />
              <Typography>Show Automation Board</Typography>
            </span>
          )}
          <span>
            <Controller
              control={control}
              name="isArchived"
              render={({ field }) => <Switch value={field.value} onChange={field.onChange} />}
            />
            <Typography>Show Archived</Typography>
          </span>
          <span>
            <Controller
              control={control}
              name="isUnassigned"
              render={({ field }) => <Switch value={field.value} onChange={field.onChange} />}
            />
            <Typography>Show Unassinged</Typography>
          </span>
          <span>
            <Controller
              control={control}
              name="isAssigned"
              render={({ field }) => <Switch value={field.value} onChange={field.onChange} />}
            />
            <Typography>Show assigned to me</Typography>
          </span>

          <LabelWrapper<IForm>
            isWrapperStylesEnabled={false}
            name="filterByType"
            control={control}
            label=""
          >
            {({ onChange, value }) => {
              return (
                <TaskFilter
                  handleClearFilter={handleClearFilters}
                  options={filterOptions}
                  onChange={onChange}
                  values={value as number[]}
                />
              )
            }}
          </LabelWrapper>
        </div>
        <div className={styles.taskBoard}>
          {tasks.map(({ columnColor, columnName, tasks, id, step }) => (
            <Column
              key={`${id}-${columnName}`}
              id={id}
              step={step}
              columnColor={columnColor}
              columnName={columnName}
              tasks={filterTaskListDependingOnType({ mode: taskMode, taskList: tasks || [] })}
              isArchived={isArchived}
              isUnassigned={isUnassigned}
              isAssigned={isAssigned}
              filterByType={filterByTaskType || []}
              filterByStaff={filterByStaff || []}
              filterByContact={filterByContact || []}
              isAutomationColumn={isAutomationBoardShown as boolean}
              automationAmount={findAutomationAmount(id)}
            />
          ))}
          {tasks?.length === 0 && (
            <div className={styles.empty}>
              <Typography>No tasks and columns yet</Typography>
            </div>
          )}
        </div>
      </div>
      {warningModal && (
        <ModalComponent
          modal={warningModal}
          color={warningModal.errorType}
          isCancel={true}
          message={warningModal.errorMessage}
          onConfirm={warningModal.onConfirm}
          onClose={handleWarningModalClose}
        />
      )}
    </DragDropContext>
  )
}

export default TaskBoard
