import FormFileSelect from '@components/inputs/form-file-select/form-file-select.component'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { Text } from '@containers/Recovery/Wip/components/UI/text/text.component'
import { FC, useEffect } from 'react'
import styles from './attachment-form.module.scss'
import { Controller, useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { attachmentSchema } from '../../../schemas/attachment.schemas'
import { AttachmentFormProps, AttachmentFormValues } from '../../../types/wip-overview-forms.type'

const AttachmentForm: FC<AttachmentFormProps> = ({
  children,
  onSubmit,
  loading,
  initialValues,
}) => {
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<AttachmentFormValues>({
    resolver: joiResolver(attachmentSchema),
  })

  useEffect(() => {
    if (!initialValues) return
    reset(initialValues)
  }, [initialValues])

  return (
    <form className={styles.formWrapper} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.formFileSelectWrapper}>
        <Controller
          name="files"
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormFileSelect
              label="Upload File"
              accept={['.jpg', '.png', '.pdf', '.xls', '.gif'].join(',')}
              onChange={onChange}
              error={errors.files?.message}
              value={value}
            />
          )}
        />
        <Text color="grey" fontSize="small">
          Maximum 10mb. Files applicable: jpg, png, pdf, xls, gif.
        </Text>
      </div>
      {children}
      <SubmitButton buttonType="submit" loading={loading}>
        Save
      </SubmitButton>
    </form>
  )
}

export default AttachmentForm
