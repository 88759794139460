import { REACT_APP_API_URL } from "@src/lib/global-variables"
import axios from "axios"
import { CreateGeneralInterestCalculatorData, GeneralInterestCalculator, UpdateGeneralInterestCalculatorData } from "../types/interest-rate.type"

export class GeneralInterestCalculatorService {
  async createGeneralInterestCalculator(data: CreateGeneralInterestCalculatorData) {
    const res = await axios.post<GeneralInterestCalculator>(`${REACT_APP_API_URL}/general-interest-calculators`, data)
    return res.data
  }

  async updateGeneralInterestCalculator(id: number, data: UpdateGeneralInterestCalculatorData) {
    const res = await axios.patch<GeneralInterestCalculator>(`${REACT_APP_API_URL}/general-interest-calculators/${id}`, data)
    return res.data
  }
}