import React, { Fragment, useEffect, useState } from 'react';
import { reduxForm } from 'redux-form';
import { Button, ButtonToolbar, Card, CardBody, Col, Spinner } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import WIPService from '../../../../services/WIPService';
import StaffService from '../../../../services/StaffService';
import AccountService from '../../../../services/AccountService';
import CompaniesService from '../../../../services/CompaniesService';

import { InvoiceDetails } from './InvoiceDetails';
import { PropertyDetails } from './PropertyDetails';
import { TenantsDetails } from './TenantsDetails';
import { RecoveryDetails } from './RecoveryDetails';
import { ClaimAmountDetails } from './ClaimAmountDetails';
import { ClaimsDetails } from './ClaimsDetails';
import { RecoverableCostsDetails } from './RecoverableCostsDetails';
import { ManagementDetails } from './ManagementDetails';
import { OwnerDetails } from './OwnerDetails';
import { CourtDetails } from './CourtDetails';
import { WipDetails } from './WipDetails';
import { LandlordInsuranceDetails } from './LandlordInsuranceDetails';
import { AttachedFilesDetails } from './AttachedFilesDetails';
import { UrfDetails } from './UrfDetails';
import { ClientDetails } from './ClientDetails';
import { useUserContext } from '../../../../context/user-context';

const invoiceStatusOptions = [
  { value: 1, label: 'Paid' },
  { value: 2, label: 'Unpaid' },
  { value: 3, label: 'DISPUTED' },
  { value: 4, label: 'OTHER' },
];

const recoveryOnUncoveredPropertyOptions = [
  { value: true, label: 'Cover' },
  { value: false, label: 'No Cover' },
];

const australianState = [
  {
    label: 'New South Wales',
    value: 'NSW',
  },
  {
    label: 'Victoria',
    value: 'VIC',
  },
  {
    label: 'Queensland',
    value: 'QLD',
  },
  {
    label: 'Tasmania',
    value: 'TAS',
  },
  {
    label: 'South Australia',
    value: 'SA',
  },
  {
    label: 'Western Australia',
    value: 'WA',
  },
  {
    label: 'Northern Territory',
    value: 'NT',
  },
  {
    label: 'Australian Capital Territory',
    value: 'ACT',
  },
];

const hearingOptions = [
  { label: 'Hearing', value: 'HEARING' },
  { label: 'Received Order', value: 'RECEIVED_ORDER' },
];

const priorityOptions = [
  { label: 'A', value: 'A' },
  { label: 'B', value: 'B' },
  { label: 'C', value: 'C' },
];

const descriptionOptions = [
  { label: 'Admin Fee', value: 'Admin Fee' },
  { label: 'Received Fee', value: 'Received Fee' },
];

const RecoveryForm = ({ onSubmit, defaultData = null, isEdit = false }) => {
  const {user} = useUserContext()
  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);

  const [invoiceDetails, setInvoiceDetails] = useState({
    total_claim: null,
    list_fee: null,
    listFee: null,
    retain: null,
    status: null,
  });

  const [clientDetails, setClientDetails] = useState({
    id: null,
    name: null,
    title_note: null,
    location: null,
    client_status: null,
    login_type: null,
  });

  const [propertyDetails, setPropertyDetails] = useState({
    address: null,
    is_cover: null,
    suburb: null,
    post_code: null,
    state: null,
    landlord: null,
    first_name: null,
    last_name: null,
    owner_email: null,
    owner_phone: null,
  });

  const [tenantsDetails, setTenantsDetails] = useState([{}]);

  const [managementDetails, setManagementDetails] = useState({
    property_manager: null,
    reports_email: null,
  });

  const [ownerDetails, setOwnerDetails] = useState({
    name: null,
    address: null,
    phone: null,
    email: null,
  });

  const [courtDetails, setCourtDetails] = useState({
    claim_plaintiff_number: null,
    court_location: null,
    defendant_name: null,
    defendant_address: null,
    enforcment_date: null,
    plaintiff_name: null,
    plaintiff_address: null,
    tribunal_location: null,
    tribunal_claim: null,
    tribunal_order_date: null,
  });

  const [wipDetails, setWipDetails] = useState({
    file_number: null,
    date_lodged: null,
    wip_cover_id: null,
    state_of_origin: null,
    file_location: null,
    is_property_covered_by_landlord_insurance: false,
    is_insurance_didnt_payout: false,
    insured_by: null,
    total_origin_claim: null,
    amount_covered: null,
    attached_file: [],
  });

  const [recoveryDetails, setRecoveryDetails] = useState({
    applied_for: null,
    break_fixed_term: null,
    evicted_by: null,
    insurance_cover: null,
    insurance_payout: null,
    insurance_company: null,
    insurance_covered: null,
    insurance_payout_reduction: null,
    is_tenant_vacated: false,
    date_vacated: null,
    is_tenant_evicted: false,
    date_evicted: null,
    date_re_leased: null,
    date_of_hearing: null,
    policy_no: null,
  });

  const [urfDetails, setUrfDetails] = useState({
    assign_staff: null,
    priority: null,
    add_recuring_calendar: false,
    recuring_date: null,
    note_wip: null,
  });

  const [claims, setClaims] = useState([]);
  const [recoverableCosts, setRecoverableCosts] = useState([]);

  const [clientsOptions, setClientsOptions] = useState([]);
  const [listFee, setListFee] = useState([]);
  const [staffOptions, setStaffOptions] = useState([]);
  const [wipCoverOptions, setWipCoverOptions] = useState([]);

  const [claimAmountDetails, setClaimAmountDetails] = useState({});
  const [totalClaim, setTotalClaim] = useState(null);
  const [clientPhone, setClientPhone] = useState(null);
  const [clientContact, setClientContact] = useState([]);

  const wipService = new WIPService();
  const staffService = new StaffService();
  const accountService = new AccountService();
  const companiesService = new CompaniesService();

  // if isEdit = true
  useEffect(() => {
    // fetch from DB
    const clientsClaimAmounts = {
      rent: { date_from: '25-01-2022', date_to: '10-10-2022', amount: 10000 },
      claims: [
        {
          current_amount: 150,
          description: 'Pest Control',
        },
        {
          current_amount: 150,
          description: 'General Cleaning',
        },
      ],
    };

    setClaimAmountDetails(clientsClaimAmounts);

    const currentAmount =
      clientsClaimAmounts.claims.reduce(
        (acc, item) => acc + item.current_amount,
        0,
      ) + clientsClaimAmounts?.rent?.amount || 0;
    const orderAmountClaim = {
      description: 'Order Amount',
      original_amount: currentAmount,
      current_amount: currentAmount,
    };
    setTotalClaim(currentAmount);
    setClaims([orderAmountClaim]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // calculating total on changing
  useEffect(() => {
    if (totalClaim) {
      if (claimAmountDetails && claimAmountDetails.claims) {
        const total =
          claimAmountDetails.claims.reduce(
            (acc, item) => acc + +item.current_amount,
            0,
          ) + (+claimAmountDetails?.rent?.amount || 0);
        setTotalClaim(total);
        return;
      }
      setTotalClaim(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimAmountDetails]);

  const getWipCoverOptions = async () => {
    const response = await wipService.getWipsCover();
    if (response) {
      const option = response.data.map((e) => ({
        value: e.id,
        label: e.title,
      }));
      setWipCoverOptions(option);
    }
  };

  const getStaffOptions = async () => {
    const response = await staffService.getStaff();
    if (response) {
      const option = response.data.map((ele) => ({
        value: ele.id,
        label: `${ele.first_name} ${ele.last_name}`,
      }));
      setStaffOptions(option);
    }
  };

  const getListFeeOptions = async () => {
    const response = await wipService.getListFeeOption();
    if (response) {
      const options = response.data.data.map((item) => ({
        value: item.id,
        label: item.list_fee,
      }));
      setListFee(options);
    }
  };

  const getClientsOptions = async () => {
    const params = {
      join: ['login_type', 'contacts'],
      limit: 10000,
    };
    const response = await companiesService.getCompanies(params);
    if (response.data.data) {
      setClientContact(response.data.data);
      const options = response.data.data.map((item) => ({
        value: item,
        label: item.name,
      }));
      setClientsOptions(options);
    }
  };

  const fetchDatatoCreate = async () => {
    try {
      setIsLoading(true);
      await getListFeeOptions();
      await getClientsOptions();
      await getStaffOptions();
      await getWipCoverOptions();
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {

    fetchDatatoCreate();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmitHandler = () => {
    invoiceDetails.total_claim = totalClaim;
    onSubmit({
      //claim_amount_details:claimAmountDetails,
      tenants_details: tenantsDetails,
      invoice_details: invoiceDetails,
      company: { id: clientDetails.id },
      property_details: propertyDetails,
      managment_details: managementDetails,
      owner_details: ownerDetails,
      recovery_details: recoveryDetails,
      court_details: courtDetails,
      wip_details: wipDetails,
      claims,
      recoverable_costs: recoverableCosts,
      ...urfDetails,
    });
    updateCompany();
    updateCompanyContact();
  };

  const updateCompany = async () => {
    clientDetails.client_phone = clientPhone;
    try {
      const res = await companiesService.editCompanies(clientDetails);
      if (res.status === 200) {
        console.log('Client updated successfully');
      } else {
        throw new Error('Error save change in client');
      }
    } catch (error) {
      console.log('Error save change in client');
    }
  };

  const updateCompanyContact = async () => {

    const clientCont = clientContact.find((id) => id.id === clientDetails.id);
    const editContact = clientCont.contacts.find((val) => val.is_invoice === true);
    console.log(editContact);


    if (managementDetails.reports_email  && managementDetails.property_manager) {

      if (editContact === undefined || editContact?.is_international === false ) {
        let addContact = [];
        const contact = [{
          id: 'string',
          is_international: true,
          companies: [{ id: clientDetails.id }],
          role: 'Property Manager',
          title: 'Property Manager',
          is_primary: false,
          first_name: managementDetails.property_manager,
          last_name: '-',
          comm_email: managementDetails.reports_email,
          comm_ph_mobile: '-',
          comm_ph_direct: '-',
          comm_ph_fax: '-',
          is_invoice: true,
          middle_name: '-',
          comm_ph_office: '-',
          comm_ph_extension: '-',
        }];

        addContact = contact.filter(val => typeof val.id === 'string').map(val => ({
          ...val,
          staff_id_created: user.id,
          id: null,
        }));

        if (addContact.length > 0) {
          try {
            const res = await companiesService.addCompaniesContacts(addContact);
            if (res.status === 200) {
              console.log('Contacts added successfully');
            } else if (!!res.status) {
              throw new Error('Error add contacts in client');
            }

          } catch (error) {
            console.log('Error add contacts in client');
          }
        }
      }

      if (editContact?.is_invoice && editContact?.is_international ) {
        console.log(editContact);
        const sendEdit = [editContact].map(val => ({
          ...val,
          companies: [{ id: clientDetails.id }],
          role: 'Property Manager',
          is_primary: false,
          first_name: managementDetails.property_manager,
          comm_email: managementDetails.reports_email,
          staff_id_modified: user.id,
        }));
        if (sendEdit.length > 0) {
          console.log(sendEdit)
          try {
            const res = await companiesService.editCompaniesContacts(sendEdit);
            if (res.status === 200) {
              console.log('Contacts updated successfully');
            }
          } catch (error) {
            console.log('Error save change contacts in client');
          }
        }
      }
    }
  };

  useEffect(() => {
    if (defaultData && isEdit) {
      setInvoiceDetails(defaultData?.invoice_details || null);
      setPropertyDetails(defaultData?.property_details || null);
      setRecoveryDetails(defaultData?.recovery_details || null);
      setCourtDetails(defaultData?.court_details || null);
      setManagementDetails(defaultData?.managment_details || null);
      setTenantsDetails(defaultData?.tenants_details || null);
      setWipDetails(defaultData?.wip_details || null);
      setOwnerDetails(defaultData?.owner_details || null);
      setClaimAmountDetails(defaultData?.claim_amount_details || null);
      setRecoverableCosts(defaultData?.recoverable_costs || null);
      setClaims(defaultData?.claims || null);
      setClientDetails(defaultData?.company || null);
      setUrfDetails((prev) => ({
        ...prev,
        assign_staff: defaultData?.assign_staff || null,
        priority: defaultData?.priority || null,
        add_recuring_calendar: defaultData?.add_recuring_calendar || null,
        recuring_date: defaultData?.recuring_date || null,
        note_wip: defaultData?.note_wip || null,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Fragment>
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <form
              className='form form--horizontal'
              onSubmit={handleSubmit(onSubmitHandler)}
            >
              <InvoiceDetails
                invoiceDetails={invoiceDetails}
                setInvoiceDetails={setInvoiceDetails}
                totalClaim={totalClaim}
                listFeeOptions={listFee}
                statusOptions={invoiceStatusOptions}
                errors={errors}
              />
              <ClientDetails
                setClientDetails={setClientDetails}
                clientDetails={clientDetails}
                clients={clientsOptions}
                isEdit={isEdit}
                errors={errors}
                clientPhone={clientPhone}
                setClientPhone={setClientPhone}
              />
              <PropertyDetails
                propertyDetails={propertyDetails}
                setPropertyDetails={setPropertyDetails}
                recoveryOnUncoveredPropertyOptions={recoveryOnUncoveredPropertyOptions}
                errors={errors}
              />
              <TenantsDetails
                tenantsDetails={tenantsDetails}
                setTenantsDetails={setTenantsDetails}
                errors={errors}
              />
              <RecoveryDetails
                setRecoveryDetails={setRecoveryDetails}
                recoveryDetails={recoveryDetails}
                clientLoginType=''
                errors={errors}
              />
              <ClaimAmountDetails
                claimAmountDetails={claimAmountDetails}
                totalClaim={totalClaim}
                errors={errors}
              />
              <ClaimsDetails
                claims={claims}
                setClaims={setClaims}
                setClaimAmountDetails={setClaimAmountDetails}
                claimAmountDetails={claimAmountDetails}
                errors={errors}
              />
              <RecoverableCostsDetails
                recoverableCosts={recoverableCosts}
                setRecoverableCosts={setRecoverableCosts}
                errors={errors}
              />
              <ManagementDetails
                managementDetails={managementDetails}
                setManagementDetails={setManagementDetails}
                errors={errors}
              />
              <OwnerDetails
                ownerDetails={ownerDetails}
                setOwnerDetails={setOwnerDetails}
                errors={errors}
              />
              <CourtDetails
                courtDetails={courtDetails}
                setCourtDetails={setCourtDetails}
                errors={errors}
              />
              <WipDetails
                setWipDetails={setWipDetails}
                wipDetails={wipDetails}
                wipCoverOptions={wipCoverOptions}
                errors={errors}
              />
              <LandlordInsuranceDetails
                setWipDetails={setWipDetails}
                wipDetails={wipDetails}
                errors={errors}
              />

              <AttachedFilesDetails
                setWipDetails={setWipDetails}
                wipDetails={wipDetails}
              />

              <UrfDetails
                urfDetails={urfDetails}
                setUrfDetails={setUrfDetails}
                staffOptions={staffOptions}
                priorityOptions={priorityOptions}
                wipDetails={wipDetails}
                errors={errors}
              />

              <ButtonToolbar className='form__button-toolbar mb-3'>
                <Button color='primary' className='mb-0' type='submit'>
                  Save
                </Button>
              </ButtonToolbar>
            </form>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};

export default reduxForm({
  form: 'add_staff_form_validation', // a unique identifier for this form
})(withRouter(withTranslation('common')(RecoveryForm)));
