import React from 'react';
import { Button, ButtonToolbar } from 'reactstrap';

export const OwnersIndividual = ({ ownersIndividual, setOwnersIndividual }) => {
  const getOnInputChange = (index) => (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setOwnersIndividual((prev) => {
      if (index === 0) {
        return [
          {
            ...prev[index],
            [name]: value,
          },
          ...prev.slice(index + 1),
        ];
      }

      return [
        ...prev.slice(0, index),
        {
          ...prev[index],
          [name]: value,
        },
        ...prev.slice(index + 1),
      ];
    });
  };

  const onTenantAdd = () => {
    setOwnersIndividual((prev) => (prev ? [...prev, {}] : [{}]));
  };

  return (
    <>
      {ownersIndividual.map((item, index) => (
        <React.Fragment key={index}>
          <div className="card__title">
            {index === 0 && (
              <h3 style={{ paddingBottom: '20px' }}>
                Enter details for all Owners
              </h3>
            )}
            <h5 className="bold-text">Owners Individual {index + 1}</h5>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">
              First Name <span style={{ color: '#ff0000' }}>*</span>
            </span>
            <div className="form__form-group-field">
              <input
                name="first_name"
                type="text"
                placeholder="First Name"
                onChange={getOnInputChange(index)}
                value={item?.first_name}
                required={true}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Middle Name</span>

            <div className="form__form-group-field">
              <input
                name="middle_name"
                type="text"
                placeholder="Middle Name"
                onChange={getOnInputChange(index)}
                value={item?.middle_name}
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">
              Last Name<span style={{ color: '#ff0000' }}>*</span>
            </span>
            <div className="form__form-group-field">
              <input
                name={`last_name`}
                type="text"
                placeholder="Last Name"
                onChange={getOnInputChange(index)}
                value={item?.last_name}
                required={true}
              />
            </div>
          </div>

          <div className="form__form-group">
            <span className="form__form-group-label">
              Post Code<span style={{ color: '#ff0000' }}>*</span>
            </span>
            <div className="form__form-group-field">
              <input
                name="post_code"
                required={true}
                type="number"
                placeholder="Post Code"
                onChange={getOnInputChange(index)}
                value={item?.post_code}
              />
            </div>
          </div>

          <div className="card__title">
            <h5 className="bold-text">Current Address</h5>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">
              Address Line 1<span style={{ color: '#ff0000' }}>*</span>
            </span>
            <div className="form__form-group-field">
              <input
                name="oi_address1"
                required={true}
                type="text"
                placeholder="Address Line 1"
                onChange={getOnInputChange(index)}
                value={item?.oi_address1}
              />
            </div>
          </div>

          <div className="form__form-group">
            <span className="form__form-group-label">
              Address Line 2<span style={{ color: '#ff0000' }}>*</span>
            </span>
            <div className="form__form-group-field">
              <input
                name="oi_address2"
                required={true}
                type="text"
                placeholder="Address Line 2"
                onChange={getOnInputChange(index)}
                value={item?.oi_address2}
              />
            </div>
          </div>

          <div className="form__form-group">
            <span className="form__form-group-label">
              Address Line 3<span style={{ color: '#ff0000' }}>*</span>
            </span>
            <div className="form__form-group-field">
              <input
                name="oi_address3"
                required={true}
                type="text"
                placeholder="Address Line 3"
                onChange={getOnInputChange(index)}
                value={item?.oi_address3}
              />
            </div>
          </div>

          <div className="form__form-group">
            <span className="form__form-group-label">
              Suburb<span style={{ color: '#ff0000' }}>*</span>
            </span>
            <div className="form__form-group-field">
              <input
                name="oi_suburb"
                type="text"
                placeholder="Suburb"
                required={true}
                onChange={getOnInputChange(index)}
                value={item?.oi_suburb}
              />
            </div>
          </div>

          <div className="form__form-group">
            <span className="form__form-group-label">
              Post Code<span style={{ color: '#ff0000' }}>*</span>
            </span>
            <div className="form__form-group-field">
              <input
                name="oi_post_code"
                type="number"
                required={true}
                placeholder="Post Code"
                onChange={getOnInputChange(index)}
                value={item?.oi_post_code}
              />
            </div>
          </div>

          <div className="form__form-group">
            <span className="form__form-group-label">
              Country<span style={{ color: '#ff0000' }}>*</span>
            </span>
            <div className="form__form-group-field">
              <input
                name="oi_country"
                type="text"
                required={true}
                placeholder="Country"
                onChange={getOnInputChange(index)}
                value={item?.oi_country}
              />
            </div>
          </div>

          <div className="form__form-group">
            <span className="form__form-group-label">Email Address</span>
            <div className="form__form-group-field">
              <input
                name="oi_email"
                type="email"
                placeholder="Email"
                onChange={getOnInputChange(index)}
                value={item?.oi_email}
              />
            </div>
          </div>

          <div className="form__form-group">
            <span className="form__form-group-label">
              Mobile Phone<span style={{ color: '#ff0000' }}>*</span>
            </span>
            <div className="form__form-group-field">
              <input
                name="mobile_phone"
                type="tel"
                placeholder="Mobile Phone"
                onChange={getOnInputChange(index)}
                value={item?.mobile_phone}
                required={true}
              />
            </div>
          </div>
        </React.Fragment>
      ))}

      <div className="form__form-group">
        <ButtonToolbar className="form__button-toolbar mb-3">
          <Button
            color="primary"
            className="mb-0"
            type="button"
            onClick={onTenantAdd}
          >
            Add Owners Individual
          </Button>
        </ButtonToolbar>
      </div>
    </>
  );
};
