import React from 'react';

export const ManagementDetails = ({
  managementDetails,
  setManagementDetails,
}) => {
  const onInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setManagementDetails((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <>
      <div className="card__title">
        <h5 className="bold-text">Management Details</h5>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">
          Property Manager<span style={{ color: '#ff0000' }}>*</span>
        </span>
        <div className="form__form-group-field  d-flex flex-column">
          <input
            name="property_manager"
            type="text"
            placeholder="Property Manager"
            onChange={onInputChange}
            value={managementDetails.property_manager}
            required={true}
          />

        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">
          Reports Email<span style={{ color: '#ff0000' }}>*</span>
        </span>
        <div className="form__form-group-field d-flex flex-column">
          <input
            name="reports_email"
            type="text"
            placeholder="Reports Email"
            required={true}
            onChange={onInputChange}
            value={managementDetails.reports_email}
          />
          {/* {errors.reports_email && (
            <span className="text-danger">Reports Email is required</span>
          )} */}
        </div>
      </div>
    </>
  );
};
