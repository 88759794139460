import Section from '@components/section/section.component'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import AddFeeForm from '../../forms/add-fee-form/add-fee-form.component'
import { FC } from 'react'
import { RecoverableCostsSectionProps } from '../../../types/claim.type'
import RecoverableCostList from '../../recoverable-cost-list/recoverable-cost-list.component'

const RecoverableCostsSection: FC<RecoverableCostsSectionProps> = ({ wipId, stateOfOrigin }) => {
  return (
    <Section takeFullHeight>
      <Header mb={4}>Recoverable Costs</Header>
      <AddFeeForm wipId={wipId} stateOfOrigin={stateOfOrigin} />
      <RecoverableCostList wipId={wipId} />
    </Section>
  )
}

export default RecoverableCostsSection
