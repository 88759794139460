import EmailItemSkeleton from './email-item-skeleton.component'
import styles from './email-list-skeleton.module.scss'

const EmailListSkeleton = () => {
  return (
    <ul className={styles.emailListWrapper}>
      {[...Array(25)].map((_, index) => (
        <EmailItemSkeleton key={`email-item-skeleton-${index}`} />
      ))}
    </ul>
  )
}

export default EmailListSkeleton
