import * as React from 'react'

const AddIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none" {...props}>
    <path fill="#fff" d="M16 16H6.667 16Z" />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M16 6.667V16m0 0v9.333M16 16h9.333M16 16H6.667"
    />
  </svg>
)
export default AddIcon
