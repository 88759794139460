import Section from '@components/section/section.component'
import SubSection from '@containers/Recovery/Wip/components/UI/sub-section/sub-section.component'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import { useModal } from '@src/hooks/useModal'
import { FC } from 'react'
import DebtorLegalClaimNumberList from '../../lists/debtor-claim-number-list/debtor-claim-number-list.component'
import { ClaimNumberSectionProps } from '../../../types/debtor-claim-number.type'
import CreateDebtorLegalClaimNumberModal from '../../modals/debtor-claim-number-modal/create-debtor-claim-number-modal.component'
import styles from './claim-number-section.module.scss'

const ClaimNumberSection: FC<ClaimNumberSectionProps> = ({ debtorLegal }) => {
  const [isOpen, handleOpen, handleClose] = useModal(false)
  return (
    <Section>
      <SubSection>
        <Header mb={4}>Claim Number/s</Header>
      </SubSection>

      <DebtorLegalClaimNumberList entities={debtorLegal.claimNumbers} />

      <div className={styles.buttonWrapper}>
        <SubmitButton onClick={handleOpen}>Add Claim Number</SubmitButton>
      </div>

      <CreateDebtorLegalClaimNumberModal
        isOpen={isOpen}
        onClose={handleClose}
        debtorLegalId={debtorLegal.id}
      />
    </Section>
  )
}

export default ClaimNumberSection
