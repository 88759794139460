import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import { Params } from '@src/types/service.type'
import { useQuery } from '@tanstack/react-query'
import { TransactionService } from '../services/transaction.service'

export const useGetTransactionList = (params: Params = {}) => {
  const getTransactionList = (params: Params = {}) => {
    const transactionService = new TransactionService()
    return transactionService.getTransactions(params)
  }

  const query = useQuery({
    queryKey: [GLOBAL_QUERIES.TRANSACTIONS.GET, params],
    queryFn: () => getTransactionList(params),
    keepPreviousData: true,
  })

  return query
}
