import { Wip } from "@containers/Recovery/Wip/types/wip.type"
import { DebtorOther, DebtorReference, DebtorRelation, DebtorWorkPlace } from "./debtor-associate-detail.type"

export interface DebtorContact {
  id: number
  comms_email: string[]
  comms_ph_mobile: string | null,
  comms_ph_mobile_secondary: string | null,
  comms_ph_mobile_secondary_current: string | null,
  comms_ph_direct: string | null,
  comms_ph_office: string | null,
  comms_ph_extension: string | null,
  comms_ph_home: string | null,
  comms_ph_fax: string | null,
  comms_ph_work: string | null,
}

export interface Debtor {
  id: number
  // first name
  name_first: string
  name_last: string
  name_middle: string | null
  // second name
  second_name_first: string | null
  second_name_last: string | null
  second_name_middle: string | null

  date_of_entry: Date | null

  drivers_licence: string | null
  state_drivers_licence: string | null
  vehicle_reg: string | null
  state_vehicle_licence: string | null

  contact: DebtorContact

  time_created: Date;

  relations: DebtorRelation[]
  references: DebtorReference[]
  other: DebtorOther[]
  work_places: DebtorWorkPlace[]
}




export interface DebtorsPageProps {
  wip: Wip
}

export interface DebtorTableProps {
  debtors: Debtor[]
  wipId: number
}

export interface DebtorTableItemProps {
  debtor: Debtor
  wipId: number
}

export enum EditDebtorFormTab {
  Details = 'details',
  AddressHistory = 'addressHistory',
  AssociateDetails = 'associateDetails',
}

export enum DebtorAssociateDetailsTab {
  Relation = 'relation',
  References = 'references',
  WorkPlace = 'workPlace',
  Other = 'other',
}