import WipCostForm from './wip-cost-form.component'
import styles from './wip-cost-form.module.scss'
import { WipCostListItemService } from '@containers/Recovery/WipCost/services/wip-cost-list-item.service'
import { FC } from 'react'
import { formatCurrency, parseStringToNumber } from '@containers/Recovery/Wip/utils/format-currency'
import { toast } from 'react-toastify'
import TrashIcon from '@src/icons/trash'
import EditIcon from '@src/icons/edit'
import {
  EditWipCostItemFormProps,
  WipCostFormValues,
} from '@containers/Recovery/WipCost/types/wip-cost.type'

const EditWipCostItemForm: FC<EditWipCostItemFormProps> = ({
  onDelete,
  entity,
  onSuccessSubmit,
}) => {
  const handleSubmit = async (data: WipCostFormValues) => {
    const wipCostListItemService = new WipCostListItemService()
    try {
      const createdItem = await wipCostListItemService.updateWipCostListItem(entity.id, {
        ...data,
        unenforceableCost: Math.round(parseStringToNumber(data.unenforceableCost)),
        recoverableCost: Math.round(parseStringToNumber(data.recoverableCost)),
      })
      onSuccessSubmit(createdItem)
    } catch (e) {
      toast.error('Failed to update WIP cost item')
      console.error(e)
    }
  }

  const handleDelete = async () => {
    const wipCostListItemService = new WipCostListItemService()
    try {
      await wipCostListItemService.deleteWipCostList(entity.id)
      onDelete && onDelete()
    } catch (e) {
      toast.error('Failed to delete WIP cost item')
      console.error(e)
    }
  }

  return (
    <WipCostForm
      onSubmit={handleSubmit}
      initialValues={{
        name: entity.name,
        recoverableCost: formatCurrency(entity.recoverableCost),
        unenforceableCost: formatCurrency(entity.unenforceableCost),
      }}
    >
      <button className={styles.actionButtonSmall} type="submit">
        <EditIcon />
      </button>
      <button className={styles.actionButtonSmall} type="button" onClick={handleDelete}>
        <TrashIcon />
      </button>
    </WipCostForm>
  )
}

export default EditWipCostItemForm
