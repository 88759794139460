import Joi from "joi";


export const courtInterestRateCalculatorSchema = Joi.object({
  calculateType: Joi.object({
    label: Joi.string().required(),
    value: Joi.string().required()
  }).required(),
  state: Joi.object({
    label: Joi.string().required(),
    value: Joi.string().required()
  }).allow(null).optional(),
  interestRate: Joi.number().min(1).max(100).required()
}).required().messages({
  'any.required': 'Required',
  'number.base': 'Must be a number',
  'number.min': 'Min 1',
  'number.max': 'Max 100'
})

export const generalInterestRateCalculatorSchema = Joi.object({
  calculateType: Joi.object({
    label: Joi.string().required(),
    value: Joi.string().required()
  }).required(),
  calculatedOn: Joi.object({
    label: Joi.string().required(),
    value: Joi.number().required()
  }).required(),
  interestRate: Joi.number().min(1).max(100).required(),
  onClaimAmount: Joi.string().min(2).optional().allow(''),
  startDate: Joi.date().optional().allow(null),
  endDate: Joi.date().optional().allow(null)
}).required().messages({
  'any.required': 'Required',
  'number.base': 'Must be a number',
  'string.min': 'Min 1',
  'number.max': 'Max 100'
})