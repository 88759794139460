import { FC, useState } from 'react'
import ReplyToEmailForm from '../forms/reply-to-email-form/reply-to-email-form.component'
import styles from './email-footer.module.scss'
import NavigationButton from '@components/navigation-button/navigation-button'
import ReplyIcon from '@src/icons/reply'
import LongArrowRightIcon from '@src/icons/long-arrow-right'
import { EmailFooterProps, ReplyFormValues } from '../../types/view-email-page.type'
import ForwardToEmailForm from '../forms/forward-email-form/forward-email-form.component'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import { useQueryClient } from '@tanstack/react-query'

const EmailFooter: FC<EmailFooterProps> = ({ email }) => {
  const queryClient = useQueryClient()
  const [isReplying, setIsReplying] = useState(false)
  const [isForwarding, setIsForwarding] = useState(false)

  const handleReplyButtonClick = () => {
    setIsReplying((prevState) => !prevState)
  }

  const handleForwardButtonClick = () => {
    setIsForwarding((prevState) => !prevState)
  }

  const replyFormDefaultValues: ReplyFormValues = {
    to: email.from.emailAddress,
    comment: '',
  }

  const handleSuccessReply = async () => {
    await Promise.all([
      queryClient.invalidateQueries([GLOBAL_QUERIES.MAIL_FOLDER.GET]),
      queryClient.invalidateQueries([GLOBAL_QUERIES.GRAPH_API_MESSAGES.GET_BY_FOLDER_ID]),
    ])
  }

  return (
    <div className={styles.emailFooterWrapper}>
      {!isReplying && !isForwarding ? (
        <div className={styles.actionWrapper}>
          <NavigationButton
            className={styles.replyButton}
            onClick={handleReplyButtonClick}
            icon={ReplyIcon}
            label="Reply"
          />
          <NavigationButton
            className={styles.forwardButton}
            onClick={handleForwardButtonClick}
            icon={LongArrowRightIcon}
            label="Forward"
          />
        </div>
      ) : null}
      {isReplying ? (
        <ReplyToEmailForm
          messageId={email.id}
          subject={email.subject}
          defaultValues={replyFormDefaultValues}
          onReplySuccess={handleSuccessReply}
          onCloseClick={handleReplyButtonClick}
        />
      ) : null}
      {isForwarding ? <ForwardToEmailForm messageId={email.id} onCloseClick={handleForwardButtonClick} /> : null}
    </div>
  )
}

export default EmailFooter
