import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button, Modal } from 'reactstrap';
import moment from 'moment';
import SMSService from '../../../../../services/SMSService';
import SendReplySMS from '../../../../Form/RecoveryForm/notifications/SendReplySMS';
import ViweModal from '../../../../Form/RecoveryForm/notifications/ViweModal';
import TablePagination from '@material-ui/core/TablePagination';
import { TailSpin } from 'react-loader-spinner';
import Services from '../../../../../shared/Services';
import { useHistory } from 'react-router-dom';

const StyledTableCell = withStyles((theme) => ({
  head: {
    background: '#FCCFB0E2',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#f5f9fc',
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    textAlign: 'center',
  },
  cell: {
    maxWidth: '450px',
    minWidth: '350px',
  },
  ellipsis: {
    maxWidth: 200, // percentage also works
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  link: {
    color: 'coral',
    textDecoration: 'underline',
    fontWeight: '600',
    '&:hover': {
      color: '#000',
    },
  },
});

function ListSMSDebtor(props) {
  const classes = useStyles();
  const [isOpenViewModal, setIsOpenViewModal] = useState(false);
  const [isOpenReplyModal, setIsOpenReplyModal] = useState(false);
  const [listSMS, setListSMS] = useState([]);
  const [text, setText] = useState('');
  const [debtorFullName, setDebtorFullName] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLodaing] = useState(false);
  const [replyToNumber, setReplyToNumber] = useState(null);
  const [debtorId, setDebtorId] = useState(null);
  const [wipId, setWipId] = useState([]);
  const smsService = new SMSService();
  const { errorToast } = new Services();
  const id = localStorage.getItem('debtorId');
  const history = useHistory();

  const getSMSList = async (id, page, rowsPerPage) => {
    setIsLodaing(true);
    try {
      const params = {
        join: ['debtor'],
        filter: `debtor_id||$eq||${id}`,
        page: page,
        limit: rowsPerPage,
        sort: 'id,DESC',
      };
      const data = await smsService.getSMS(params);
      setTotal(data.total);
      setListSMS(data.data);
      setWipId(data.data.map((e) => {
        const wip = e.wip_id;
        return wip;
      }));

    } catch (e) {
      errorToast(e);
    } finally {
      setIsLodaing(false);
    }
  };

  const refetchSMSList = async () => await getSMSList(id);

  useEffect(() => {
    getSMSList(id);
    localStorage.setItem('wipId', wipId[0]);
  }, []);

  useEffect(() => {
    localStorage.setItem('wipId', wipId[0]);
  }, [wipId]);

  const openViewModal = () => setIsOpenViewModal(true);
  const closeViewModal = () => setIsOpenViewModal(false);

  const openReplyModal = () => setIsOpenReplyModal(true);
  const closeReplyModal = () => setIsOpenReplyModal(false);

  const getOnReplyClick =
    ({ text, debtorFullName, replyTo, debtorId }) =>
      () => {
        openReplyModal();
        setText(text);
        setDebtorFullName(debtorFullName);
        setReplyToNumber(replyTo);
        setDebtorId(debtorId);
      };

  const getOnViewClick =
    ({ text, debtorFullName }) =>
      () => {
        setText(text);
        setDebtorFullName(debtorFullName);
        openViewModal();
      };

  const editWIP = (item) => {
    history.push('/wip-details', { wipID: item.wip_id });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getSMSList(id, newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getSMSList(id, 0, event.target.value);
  };

  return (
    <>
      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <TailSpin
            height='50'
            width='50'
            color='#f69066'
            ariaLabel='loading'
          />
        </div>
      ) : (
        <TableContainer component={Paper}>
          <Table aria-label='customized table'>
            <TableHead>
              <TableRow>
                <StyledTableCell>Sent / Recieved</StyledTableCell>
                <StyledTableCell>Message</StyledTableCell>
                <StyledTableCell style={{ textAlign: 'center' }}>WIP FN</StyledTableCell>
                <StyledTableCell>Sent By</StyledTableCell>
                <StyledTableCell>Sent To</StyledTableCell>
                <StyledTableCell>When</StyledTableCell>
                <StyledTableCell style={{ textAlign: 'center' }}>
                  Action
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listSMS.map((row) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell className={classes.ellipsis}>
                    {row.status}
                  </StyledTableCell>
                  <StyledTableCell className={classes.cell}>
                    {row.body}
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: 'center' }} className={classes.ellipsis}>
                    <div
                      style={{ cursor: 'pointer' }}
                      className={classes.link}
                      onClick={() => {
                        editWIP(row);
                      }}>
                      {row.wip_id}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell className={classes.ellipsis}>
                    {row.is_from_staff ? (
                      'Barclay'
                    ) : (
                      <div>
                        {row.debtor_id
                          ? `${row.debtor?.name_first} ${row.debtor?.name_last}`
                          : row.from}
                      </div>
                    )}
                  </StyledTableCell>
                  <StyledTableCell className={classes.ellipsis}>
                    {row.is_from_staff ? (
                      <div>
                        {row.debtor_id
                          ? `${row.debtor?.name_first} ${row.debtor?.name_last}`
                          : row.to}
                      </div>
                    ) : (
                      'Barclay'
                    )}
                  </StyledTableCell>
                  <StyledTableCell className={classes.ellipsis}>
                    {moment(row.time_created).format('DD-MM-YYYY, h:mm')}
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: 'center' }}>
                    {row.is_from_staff !== true ? (
                      <Button
                        onClick={getOnReplyClick({
                          text: row.body,
                          debtorFullName: `${row.debtor?.name_first || ''} ${
                            row.debtor?.name_last || ''
                          }`,
                          replyTo: row.from,
                          debtorId: row.debtor_id,
                        })}
                        style={{ width: '6rem' }}
                      >
                        Reply
                      </Button>
                    ) : (
                      <Button
                        onClick={getOnViewClick({
                          text: row.body,
                          debtorFullName: `${row.debtor?.name_first || ''} ${
                            row.debtor?.name_last || ''
                          }`,
                        })}
                        style={{
                          background: 'grey',
                          border: 'grey',
                          width: '6rem',
                        }}
                      >
                        View
                      </Button>
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <TablePagination
        style={{ marginTop: '25px' }}
        component='div'
        count={total}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {isOpenViewModal && (
        <Modal
          isOpen={isOpenViewModal}
          size='lg'
          style={{ maxWidth: '800px', width: '95%' }}
          className='theme-light'
        >
          <ViweModal
            wipID={props.numberWIP}
            closeModal={closeViewModal}
            text={text}
            debtor={debtorFullName}
          />
        </Modal>
      )}
      {isOpenReplyModal && (
        <Modal
          isOpen={isOpenReplyModal}
          size='lg'
          style={{ maxWidth: '800px', width: '95%' }}
          className='theme-light'
        >
          <SendReplySMS
            clientID={''}
            wipID={props.numberWIP}
            closeModal={closeReplyModal}
            text={text}
            replyToNumber={replyToNumber}
            refetchSMSList={refetchSMSList}
            debtorId={debtorId}
            debtor={debtorFullName}
          />
        </Modal>
      )}
    </>
  );
}

export default ListSMSDebtor;
