import Section from '@components/section/section.component'
import { Header, SubHeader } from '@containers/Recovery/Wip/components/UI/text/text.component'
import styles from './total-claim-amount-section.module.scss'
import { FC } from 'react'
import { TotalClaimAmountSectionProps } from '../../../types/claim.type'
import EditTotalClaimAmountForm from '../../forms/total-claim-amount-form/edit-total-claim-amount-form.component'
import ClaimDetailsNoteList from '../../claim-details-note-list/calim-details-note-list.component'
import AddDetailNoteButton from '../../add-claim-detail-note-button/add-claim-detail-note-button.component'
import { useModal } from '@src/hooks/useModal'
import InterestRateCalculatorModal from '../../modals/interest-rate-calculator-modal/interest-rate-calculator-modal.component'

const TotalClaimAmountSection: FC<TotalClaimAmountSectionProps> = ({ wip }) => {
  const [isOpen, handleOpen, handleClose] = useModal(false)
  return (
    <Section className={styles.totalClaimAmountSection} takeFullHeight>
      <Header>Total Claim Amount</Header>
      <EditTotalClaimAmountForm
        wipId={wip.id}
        initialValues={{
          claimTotalAmount: wip.claim_details_total,
          recoverableTotalAmount: wip.recoverable_costs_total,
          unenforceableTotalAmount: wip.unenforceable_costs_total,
          interestRate: wip.total_interest_calculated,
          totalAmount:
            Number(wip.claim_details_total) +
            wip.unenforceable_costs_total +
            Number(wip.total_interest_calculated),
          totalAmountRecoverable:
            Number(wip.claim_details_total) +
            wip.recoverable_costs_total +
            Number(wip.total_interest_calculated),
        }}
        onEditInterestRateClick={handleOpen}
      />
      <SubHeader>Notes</SubHeader>
      <ClaimDetailsNoteList wipId={wip.id} />
      <AddDetailNoteButton wipId={wip.id} />
      <InterestRateCalculatorModal
        wipId={wip.id}
        state={wip.state_of_origin || ''}
        courtInterestCalculator={wip.courtInterestCalculator}
        generalInterestCalculator={wip.generalInterestCalculator}
        isOpen={isOpen}
        onClose={handleClose}
      />
    </Section>
  )
}
export default TotalClaimAmountSection
