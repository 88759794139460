import Table from '@components/table/table.component'
import { useGetCombinedNotes } from '@containers/Communication/hooks/get-combined-notes.hook'
import {
  CombinedNote,
  CombinedNoteType,
  CombinedNotesMode,
} from '@containers/Communication/types/combined-notes.type'
import CombinedNotesTableItem from './combined-notes-table-item.component'
import { FC, useEffect, useState } from 'react'
import { CombinedNotesTableProps } from '@containers/Communication/types/communication.type'
import CombinedNoteModal from '../../modals/combined-note-modal.component'
import { useModal } from '@src/hooks/useModal'

const CombinedNotesTable: FC<CombinedNotesTableProps> = ({ params, currentPage, onPageChange }) => {
  const [isTaskNoteModalOpen, openTaskNoteModal, handleTaskModalClose] = useModal(false)
  const [selectedNote, setSelectedNote] = useState<CombinedNote | null>(null)

  const [page, setPage] = useState(1)
  const { data, isLoading, isRefetching } = useGetCombinedNotes({
    ...params,
    mode: params.company_id ? CombinedNotesMode.COMPANY : CombinedNotesMode.WIP,
    page,
    limit: 5,
  })

  useEffect(() => {
    if (!currentPage) return
    setPage(currentPage)
  }, [currentPage])

  const handleItemClick = (combinedNote: CombinedNote) => {
    setSelectedNote(combinedNote)
    openTaskNoteModal()
  }

  const combinedNotes = data?.data || []
  const isNotesLoading = isLoading || isRefetching
  const isNotesNotFound = combinedNotes.length === 0 && !isNotesLoading

  return (
    <>
      <Table
        headers={['STAFF', 'DATE', 'TIME', 'NOTE', 'NOTE TYPE']}
        bodyHeight={54 * 5}
        totalCount={data?.count}
        limit={5}
        page={page}
        loading={isNotesLoading}
        notFound={isNotesNotFound}
        onPageChange={onPageChange}
      >
        {combinedNotes.map((note, i) => (
          <CombinedNotesTableItem
            key={`note-${note.id}-${i}`}
            note={note}
            onNoteClick={handleItemClick}
          />
        ))}
      </Table>
      <CombinedNoteModal
        isOpen={isTaskNoteModalOpen}
        onClose={handleTaskModalClose}
        id={selectedNote?.id || null}
        userId={selectedNote?.created_user_id || null}
        type={selectedNote?.type || CombinedNoteType.TASK_NOTE}
      />
    </>
  )
}

export default CombinedNotesTable
