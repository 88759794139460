import { useMutation } from "@tanstack/react-query";
import { DebtorLegalJudgmentDateService } from "../../services/debtor-legal-judgment-date.service";
import { CreateDebtorLegalJudgmentDateData } from "../../types/debtor-legal-judgment-date.type";
import { Params } from "@src/types/service.type";
import { DeepPartial } from "react-hook-form";
import { DebtorLegalJudgmentDate } from "../../types/debtor-legal.type";

export const useCreateDebtorJudgmentDateMutation = () => {
  const createDebtorJudgmentDate = async (data: CreateDebtorLegalJudgmentDateData, params?: Params) => {
    const debtorLegalJudgmentDateService = new DebtorLegalJudgmentDateService();
    return debtorLegalJudgmentDateService.createDebtorLegalJudgmentDate(data, params);
  }

  const mutation = useMutation({ mutationFn: ({ data, params }: { data: CreateDebtorLegalJudgmentDateData, params?: Params }) => createDebtorJudgmentDate(data, params) });

  return mutation;
}

export const useUpdateDebtorJudgmentDateMutation = () => {
  const updateDebtorJudgmentDate = async (debtorJudgmentDateId: number, data: DeepPartial<DebtorLegalJudgmentDate>, params?: Params) => {
    const debtorLegalJudgmentDateService = new DebtorLegalJudgmentDateService();
    return debtorLegalJudgmentDateService.updateDebtorLegalJudgmentDate(debtorJudgmentDateId, data, params);
  }

  const mutation = useMutation({ mutationFn: ({ debtorJudgmentDateId, data, params }: { debtorJudgmentDateId: number, data: DeepPartial<DebtorLegalJudgmentDate>, params?: Params }) => updateDebtorJudgmentDate(debtorJudgmentDateId, data, params) });

  return mutation;
}

export const useDeleteDebtorJudgmentDateMutation = () => {
  const deleteDebtorJudgmentDate = async (debtorJudgmentDateId: number, params?: Params) => {
    const debtorLegalJudgmentDateService = new DebtorLegalJudgmentDateService();
    return debtorLegalJudgmentDateService.deleteDebtorLegalJudgmentDate(debtorJudgmentDateId, params);
  }

  const mutation = useMutation({ mutationFn: ({ debtorJudgmentDateId, params }: { debtorJudgmentDateId: number, params?: Params }) => deleteDebtorJudgmentDate(debtorJudgmentDateId, params) });

  return mutation;
}