import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Services from '../../../../../shared/Services';
import moment from 'moment';
import { withRouter } from 'react-router';
import TablePagination from '@material-ui/core/TablePagination';
import DatePicker from 'react-datepicker';
import { UncontrolledTooltip } from 'reactstrap';
import TimeLogService from '../../../../../services/TimeLog';
import { TailSpin } from 'react-loader-spinner';

let timeupdate = '';
let color = '#fff';
const codes = [];
let logDate = '';

class Timeline extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      timeLogList: [],
      page: 0,
      skip: 0,
      limit: 30,
      wipID: '',
      total: 0,
      rowsPerPage: 30,
      startDate: '',
      endDate: '',
      timeLogListFilter: [],
      endCopy: '',
      fileNumber: '',
      loader: false,
    };

    this.services = new Services();
    this.timeLog = new TimeLogService();
    this.getWIPTimeLogs = this.getWIPTimeLogs.bind(this);
    let i;
    for (i = 0; i <= 30; i++) {
      const clr = this.getRandomColor();
      codes.push(clr);
    }
  }

  componentDidMount() {
    this.setState({ wipID: this.props.location.state.wipID });
    this.getWIPTimeLogs(this.props.location.state.wipID);
  }

  getWIPTimeLogs = async (id) => {
    this.setState({loader: true})
    try {
      const params = {
        wip_id: id,
        join: ['wip','staff||first_name,last_name'],
        filter: `wip.id||$eq||${id}`,
        sort: 'id,DESC'
      };
      const res = await this.timeLog.getTimeLog(params);
      this.setState({
        timeLogList: res.data,
        timeLogListFilter: res.data,
        total: res.data.length,
      });
      this.setState({loader: false})

    } catch (e) {
      this.services.errorToast(e);
      throw new Error(e);
    }
  };

  handleChangePage = (event, page) => {
    this.setState({ page: page });
  };

  cancel = () => {
    this.props.history.push('/contacts', { fromWIP: true });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  getRandomColor = () => {
    const letters = 'BCDEF'.split('');
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * letters.length)];
    }
    return color;
  };

  handleChangeDate = (date, name) => {
    if (date === 'clear') {
      this.setState({ startDate: '', endDate: '', skip: 0, page: 0 });
    }
    if (date && name) {
      this.setState({
        [name]: date,
        skip: 0,
        page: 0,
      });
    }
    setTimeout(() => {
      //this.getWIPTimeLogsBMS5(this.state.wipID);
    }, 100);
  };

  render() {
    timeupdate = '';
    logDate = '';
    const { loader } = this.state;
    return (
      (loader ? (
          <div className='loader'>
            <TailSpin
              height='100'
              width='100'
              color='#f69066'
              ariaLabel='loading'
            />
          </div>
        ) : (
      <div className='w-100'>
        <div className='form'>
          <div className='card'>
            <div>
              <div className='card p-0' id='contacts_accordion'>
                <div className='row'>
                  <h3 className='page-title col-12 col-md-4 '>
                    {' '}
                    Table For Wip: {this.state.wipID}{' '}
                  </h3>
                  <div className=' col-12 col-md-8  '>
                    <div className='row m-0 justify-content-end'>
                      <div className='col-12 col-md-5 col-lg-4'>
                        <div className='form-group'>
                          <label className='form__form-group-label' htmlFor=''>
                            From
                          </label>
                          <div className='date-picker date-picker--interval'>
                            <DatePicker
                              selected={
                                this.state.startDate ? this.state.startDate : ''
                              }
                              onChange={(e) =>
                                this.handleChangeDate(e, 'startDate')
                              }
                              name='startDate'
                              dateFormat='dd/MM/yy'
                              dropDownMode='select'
                              placeholderText='Select Date'
                              popperPlacement='center'
                            />
                            <span className='date_icon'>
                              <img src={'./image/calendar.svg'} alt='date' />
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className='col-12 col-md-5 col-lg-4'>
                        <div className='form-group'>
                          <label className='form__form-group-label' htmlFor=''>
                            To
                          </label>
                          <div className='date-picker date-picker--interval'>
                            <DatePicker
                              selected={
                                this.state.endDate ? this.state.endDate : ''
                              }
                              onChange={(e) =>
                                this.handleChangeDate(e, 'endDate')
                              }
                              name='endDate'
                              dateFormat='dd/MM/yy'
                              dropDownMode='select'
                              popperPlacement='center'
                              placeholderText='Select Date'
                              minDate={
                                this.state.startDate ? this.state.startDate : ''
                              }
                              //isClearable={true}
                            />
                            <span className='date_icon'>
                              <img src={'./image/calendar.svg'} alt='date' />
                            </span>
                          </div>
                        </div>
                      </div>

                      {(this.state.endDate || this.state.startDate) && (
                        <div className='col-12 col-md-1 col-lg-1 pt-1 d-flex justify-center align-items-center '>
                          <span
                            className='lnr lnr-cross d-flex justify-center align-items-center '
                            id='clean'
                            onClick={() => this.handleChangeDate('clear')}
                          >
                            {' '}
                          </span>
                          <UncontrolledTooltip
                            placement='bottom'
                            target='clean'
                          >
                            Clear Filter
                          </UncontrolledTooltip>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className=' m-0 p-1'>
                  <Table className='material-table details-timeline table_wrap m-0 p-1 '>
                    <thead>
                    <tr>
                      <th> Date</th>
                      <th> Log Details</th>
                    </tr>
                    </thead>

                    <TableBody>
                      {this.state.timeLogList.map((item, i) => {
                        let isdateSame;
                        if (
                          timeupdate ===
                          new Date(item.updated_at).toLocaleDateString() ||
                          logDate ===
                          new Date(item.created_at)
                            .toISOString()
                            .split('T')[0]
                        ) {
                          isdateSame = true;
                        } else {
                          timeupdate = new Date(
                            item.updated_at,
                          ).toLocaleDateString();
                          isdateSame = false;
                          color = codes[i];
                          logDate = new Date(item.created_at)
                            .toISOString()
                            .split('T')[0];
                        }
                        const d = new Date(item.created_at)
                          .toISOString()
                          .split('T')[0];
                        return (
                          <TableRow key={i} className='material-table__row boxshadow_tbl timeline-date mb-2'>
                            {isdateSame === false ? (
                              <TableCell
                                className='material-table__cell  material-table__cell-right bg-white timeline-date-info pb-0 pt-1 pr-0'
                                style={{ backgroundColor: color }}
                              >
                                <span
                                  style={{ backgroundColor: color }}
                                  className='font-weight-bold'
                                >
                                  {' '}
                                  {moment(d).format('DD-MM-YYYY')}
                                </span>
                              </TableCell>
                            ) : (
                              <TableCell
                                className='material-table__cell  material-table__cell-right bg-white timeline-date-info pb-0 pr-0 pt-0'
                                style={{ backgroundColor: color }}
                              >
                                <span style={{ backgroundColor: color }} />
                              </TableCell>
                            )}

                            <TableCell
                              className='material-table__cell  material-table__cell-right timeline-info-section pb-0 pt-1 bg-white '>
                              <TableRow className='timeline-info p-3 '>
                                <TableCell className='material-table__cell  material-table__cell-right p-0  '>
                                  <span className='font-weight-bold'>
                                    {item.entry_details}{' '}
                                  </span>
                                  <span>
                                    {' '}
                                    {moment(item.created_at).format(
                                      'hh:mm:ss',
                                    )}{' '}
                                  </span>
                                  {item.notes && (
                                    <span> Note: {item.entry_notes} </span>
                                  )}
                                  <span>
                                    Staff: {item.staff.first_name}{' '}
                                    {item.staff.last_name}
                                  </span>
                                </TableCell>
                              </TableRow>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </div>
                <TablePagination
                  component='div'
                  className='material-table__pagination'
                  count={this.state.total}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                  nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                  rowsPerPageOptions={[5, 10, 15, 30, 50]}
                  dir='ltr'
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                />
              </div>
              <div className='w-100 d-flex justify-content-end'>
                <button
                  type='button'
                  className='btn-group--icons btn-gray m-0'
                  onClick={() => this.cancel()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>))
    );
  }
}

export default withRouter(Timeline);
