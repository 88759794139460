import React from 'react';
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Col,
  Modal,
  UncontrolledTooltip,
} from 'reactstrap';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import WIPTableHead from './WIPTableHead';
import Services from '../../../../shared/Services.js';
import { withRouter } from 'react-router';
import ModalComponent from '../../../../shared/components/ModalCustom';
import AddWIP from './AddWIP';
import WIPService from '../../../../services/WIPService';
import TablePagination from '@material-ui/core/TablePagination';
import { TailSpin } from 'react-loader-spinner';
import moment from 'moment';

function getSorting(order, orderBy) {
  if (order === 'desc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
}

const fileLoacationOption = [
  { value: '0', label: 'General filing' },
  { value: '1', label: 'General filing – No Order' },
  { value: '2', label: 'Paying' },
  { value: '3', label: 'Paying Agent Direct' },
  { value: '4', label: 'Eviction' },
  { value: '5', label: 'Paid in full' },
  { value: '6', label: 'Purged – Bankrupt' },
  { value: '7', label: 'Purged – Overseas' },
  { value: '8', label: 'Purged – No TAF' },
  { value: '9', label: 'Purged – No Order' },
  { value: '10', label: 'Paying Agent' },
  { value: '11', label: 'Purged – jail' },
  { value: '12', label: 'Purged – Deceased' },
  { value: '13', label: 'Purged - By Agen' },
  { value: '14', label: 'Purged - No Cover' },
  { value: '15', label: 'Purged - Insurance' },
  { value: '16', label: 'QLD General Filing' },
  { value: '17', label: 'QLD PIF Filing' },
  { value: '18', label: 'QLD Purge Filing' },
  { value: '19', label: 'QLD Audit Box 1' },
  { value: '20', label: 'QLD Audit Box 2' },
  { value: '21', label: 'QLD Audit Box 3' },
  { value: '22', label: 'QLD Audit Box 4' },
  { value: '23', label: 'QLD Other' },
  { value: '24', label: 'NSW General Filing' },
  { value: '25', label: 'NSW PIF Filing' },
  { value: '26', label: 'NSW Purge Filing' },
  { value: '27', label: 'NSW Other' },
  { value: '28', label: 'VIC General Filing' },
  { value: '29', label: 'VIC PIF Filing' },
  { value: '30', label: 'VIC Purge Filing' },
  { value: '31', label: 'VIC Othe' },
];

class WIPList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientId: localStorage.getItem("client_Id"),
      order: 'asc',
      orderBy: '',
      selected: new Map([]),
      staffData: [],
      staffDataFiltered: [],
      clientWips: [],
      page: 0,
      rowsPerPage: 10,
      errorModal: false,
      errorMessage: '',
      errorType: '',
      st_id: '',
      accessErrorModal: false,
      app_type: '',
      filter_app_type: [],
      isPreview: false,
      emailbody: '',
      isAddWIp: false,
      allDebtor: [],
      cName: '',
      total: '',
      loader: false,
    };

    this.services = new Services();
    this.wipService = new WIPService();
    this.EditWIP = this.EditWIP.bind(this);
  }

  componentDidMount() {
    if (this.props.cName) {
      this.setState({ cName: this.props.cName });
    }
    // this.getDebtorList();
    this.getAllClientWIPS();
    if (this.props.cName) {
      this.setState({ cName: this.props.cName });
    }
  }

  getDebtorList() {
    this.services.getDebtorListDropDown((res) => {
      if (res.status === 200) {
        const getData = res.data.data;
        let list;
        list =
          getData &&
          getData.map((item) => {
            return {
              value: item._id,
              label: item.contact_1_first + ' ' + item.contact_1_last,
            };
          });
        this.setState({
          allDebtor: list,
        });
      } else if (
        res.statusCode === 401 &&
        res.responseType === 'UNAUTHORIZED'
      ) {
        this.setState({ accessErrorModal: true });
      } else {
        this.services.errorToast(res.message);
      }
    });
  }

  async getAllClientWIPS(page, rowsPerPage) {
    this.setState({
      loader: true,
    });
    const params = {
      page: page,
      limit: rowsPerPage,
      sort: 'id,DESC',
      join: ['debtors', 'property', 'company'],
      filter: `company.id||$eq||${+this.state.clientId}`,
    };
    const list = await this.wipService.getWips(params);
    this.setState({
      clientWips: list.data,
    });
    this.setState({
      total: list.total,
    });
    this.setState({
      loader: false,
    });
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  EditWIP(item) {
    this.props.history.push(`/wip/${item.id}/overview`);
  }

  accessErrorModalClose = () => {
    this.setState({ accessErrorModal: false });
    this.props.history.push('/dashboard');
  };
  print = () => {};
  addWIP = () => {
    this.setState({ isAddWIp: true });
  };
  closeCreate = () => {
    this.setState({ isAddWIp: false });
    this.getAllClientWIPS();
  };
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
    this.getAllClientWIPS(newPage + 1);
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
    this.setState({ page: 0 });
    this.getAllClientWIPS(0, event.target.value);
  };

  render() {
    const {
      order,
      orderBy,
      selected,
      clientWips,
      isAddWIp,
      page,
      total,
      rowsPerPage,
      loader,
    } = this.state;
    return (
      <div className="w-100">
        {isAddWIp === true ? (
          <AddWIP close={this.closeCrete} />
        ) : (
          <>
            <div className="card">
              <div className="card-body">
                <div style={{ background: '#fff' }}>
                  <Col md={12} lg={12} className="px-3 pt-3 pb-0 bg-white">
                    <div className="justify-content-between d-flex">
                      <label className="form__form-group-label" htmlFor="">
                        Work In Progress File
                      </label>
                      <ButtonToolbar className="form__button-toolbar m-0">
                        <Button
                          color="primary"
                          type="button"
                          onClick={this.addWIP}
                        >
                          Create WIP
                        </Button>
                      </ButtonToolbar>
                    </div>
                  </Col>
                  <Col md={12} lg={12} className="px-3 pt-0 pt-0 bg-white">
                    <div className="justify-content-between d-flex">
                      <h3>
                        <label className="" htmlFor="">
                          Total Number of WIP's: {this.state.total}
                        </label>
                      </h3>
                    </div>
                  </Col>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <Col md={12} lg={12} className="bg-white">
                  {loader ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '50px',
                      }}
                    >
                      <TailSpin
                        height="50"
                        width="50"
                        color="#f69066"
                        ariaLabel="loading"
                      />
                    </div>
                  ) : (
                    <div className="material-table__wrap">
                      <Table
                        className="material-table"
                        id="notesTable"
                        ref={(el) => (this.componentRef = el)}
                      >
                        <WIPTableHead
                          numSelected={
                            [...selected].filter((el) => el[1]).length
                          }
                          order={order}
                          orderBy={orderBy}
                          rowCount={clientWips.length}
                          onRequestSort={this.handleRequestSort}
                        />
                        <TableBody>
                          {clientWips
                            .sort(getSorting(order, orderBy))
                            .map((wip, i) => {
                              return (
                                <TableRow key={i}>
                                  <TableCell
                                    className="material-table__cell material-table__cell-right"
                                    component="th"
                                    scope="row"
                                    padding="none"
                                  >
                                    {wip.id}
                                  </TableCell>
                                  <TableCell className="material-table__cell material-table__cell-right">
                                    {wip.debtors?.map(
                                      (e) => `${e.name_first} ${e.name_last}  `,
                                    )}
                                  </TableCell>
                                  <TableCell className="material-table__cell material-table__cell-right">
                                    {moment(wip.created_at).format(
                                      'HH:mm DD-MM-YYYY ',
                                    )}
                                  </TableCell>
                                  <TableCell className="material-table__cell material-table__cell-right">
                                    {
                                      wip?.property?.address_details
                                        ?.street_name
                                    }
                                  </TableCell>
                                  <TableCell className="material-table__cell material-table__cell-right">
                                    Active
                                  </TableCell>
                                  <TableCell className="material-table__cell material-table__cell-right">
                                    {wip?.wip_cover?.title || 'None'}
                                  </TableCell>
                                  <TableCell className="material-table__cell material-table__cell-left">
                                    <ButtonGroup
                                      className="btn-group--icons"
                                      dir="ltr"
                                    >
                                      <Button
                                        outline
                                        id="Edit"
                                        onClick={() => this.EditWIP(wip)}
                                      >
                                        View
                                      </Button>
                                    </ButtonGroup>
                                    <UncontrolledTooltip
                                      placement="bottom"
                                      target="Edit"
                                    >
                                      View WIP
                                    </UncontrolledTooltip>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          {clientWips.length === 0 && (
                            <TableRow
                              className="material-table__row"
                              style={{ height: 60 }}
                            >
                              <TableCell
                                colSpan={8}
                                className="text-center bg-white material-table__cell  material-table__cell-right"
                              >
                                <span>{'None To Display'}</span>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </div>
                  )}
                  <TablePagination
                    style={{ marginTop: '25px' }}
                    component="div"
                    count={+total}
                    page={page}
                    onPageChange={this.handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                  />
                  {
                    <Modal
                      isOpen={this.state.isPreview}
                      size="lg"
                      style={{ maxWidth: '800px', width: '95%' }}
                      className="theme-light"
                    >
                      <div className="modal__header pt-0">
                        <div className="text-right">
                          <button
                            className="btn btn-secondary"
                            type="button"
                            onClick={() =>
                              this.setState({ isPreview: false, emailbody: '' })
                            }
                          >
                            Close
                          </button>
                        </div>
                        <div className="preview_logo text-left">
                          <img
                            src={'./image/logo.png'}
                            alt="logo"
                            style={{ maxWidth: '200px' }}
                          />
                        </div>
                      </div>
                      <div className="modal__body pt-5 float-left">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: this.state.emailbody,
                          }}
                        />
                      </div>
                    </Modal>
                  }
                  {this.state.errorModal ? (
                    <ModalComponent
                      modal={this.state.errorModal}
                      color={this.state.errorType}
                      isCancel={true}
                      message={this.state.errorMessage}
                      onConfirm={(e) => this.DeleteStaff(e)}
                      onClose={() => this.setState({ errorModal: false })}
                    />
                  ) : null}
                  {this.state.accessErrorModal ? (
                    <ModalComponent
                      modal={this.state.accessErrorModal}
                      color="danger"
                      isCancel={false}
                      message="You do not have access to this, please contact your manager for assistance"
                      onConfirm={(e) => this.accessErrorModalClose(e)}
                      onClose={(e) => this.accessErrorModalClose(e)}
                    />
                  ) : null}
                </Col>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default withRouter(WIPList);
