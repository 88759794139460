import React from 'react';

import { Grid, Typography } from '@mui/material';

import { formInputStyles } from './styles';

const FormDatePicker = ({
  label,
  placeholder,
  name,
  changeHandler,
  value,
  required,
  error,
  touched,
  bold,
  max,
}) => {
  return (
    <Grid item sx={formInputStyles.container}>
      <Typography sx={bold ? formInputStyles.labelBold : formInputStyles.label}>
        {label} {required && <span style={formInputStyles.required}>*</span>}
      </Typography>

      <Grid sx={formInputStyles.inputContainer}>
        <input
          type="date"
          placeholder={placeholder}
          name={name}
          onChange={changeHandler}
          value={value}
          max={max}
        />

        {error && touched && <Grid sx={formInputStyles.errorMessage}>{error}</Grid>}
      </Grid>
    </Grid>
  );
};

export default FormDatePicker;
