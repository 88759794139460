import { useModal } from '@src/hooks/useModal'
import EditColumnModal from '../taskboard/modals/edit-column-modal'
import styles from './automation-board.module.scss'
import { useGetAutomations } from './utils'
import { useLocation } from 'react-router'
import { useEffect, useState } from 'react'
import { useTaskContext } from '@src/context/task-context'
import AutomationItem from './components/AutomationItem/automation-item.component'
import EditAutomationModal from './modals/edit-automation'
import { OpenAutomationModalProps } from './types'
import axios from 'axios'
import { REACT_APP_API_URL } from '@src/lib/global-variables'
import { toast } from 'react-toastify'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import { useQueryClient } from '@tanstack/react-query'

export const AutomationBoard = () => {
  const queryClient = useQueryClient()
  const [selectedAutomation, setSelectedAutomation] = useState<OpenAutomationModalProps | null>(
    null,
  )
  const [column_id, setColumnId] = useState<number | null>(null)

  const [isEditColumnModalOpened, handleEditColumnModalOpen, handleEditColumnModalClose] =
    useModal(false)
  const [
    isEditAutomationModalOpened,
    handleEditAutomationModalOpen,
    handleEditAutomationModalClose,
  ] = useModal(false)

  const location = useLocation()
  const locationId = Number(location?.pathname?.split('/')[2])
  const { currentClient, isSales, currentWip } = useTaskContext()

  const clientId = locationId ? locationId : Number(currentClient?.id)

  const { automations, columns } = useGetAutomations(
    isSales ? { clientId: clientId } : { wip_id: currentWip },
  )

  const handleColumnClick = (column_id: number) => {
    setColumnId(column_id)
    handleEditColumnModalOpen()
  }

  const handleAutomationClick = (openAutomationModalProps: OpenAutomationModalProps) => {
    handleEditAutomationModalOpen()
    setSelectedAutomation(openAutomationModalProps)
  }

  const handleDeleteAutomationClick = async (id: number) => {
    try {
      await axios.delete(`${REACT_APP_API_URL}/automations/${id}`)
      queryClient.invalidateQueries([GLOBAL_QUERIES.AUTOMATIONS.GET])

      toast.success('Automation deleted')
    } catch (error) {
      console.log({ error })
      toast.error('Something went wrong')
    }
  }

  const handleOnSuccessUpdate = () => {
    queryClient.invalidateQueries([GLOBAL_QUERIES.AUTOMATIONS.GET])
  }

  const resetModals = () => {
    setSelectedAutomation((prev) => (prev ? { id: prev.id } : null))
  }

  return (
    <div className={styles.wrapper}>
      {columns
        ?.sort((a, b) => {
          if (a?.step && b?.step) {
            return a.step - b.step
          }
          return 0
        })
        ?.map(({ id, title }) => {
          return (
            <div className={styles.columnAutomation} key={id}>
              <span>
                {title} - <span onClick={() => handleColumnClick(id)}>Edit</span>
              </span>

              <div>
                {automations
                  .filter(({ referrence_column_id }) => referrence_column_id === id)
                  ?.sort((a, b) => (new Date(a.createdDate) > new Date(b.createdDate) ? -1 : 1))
                  .map((automation) => (
                    <AutomationItem
                      key={`${automation.title}-${automation.id}`}
                      automation={automation}
                      onAutomationClick={handleAutomationClick}
                      onDeleteAutomationClick={handleDeleteAutomationClick}
                    />
                  ))}
                {automations?.filter(({ referrence_column_id }) => referrence_column_id === id)
                  .length === 0 && <span className={styles.empty}>No automations set.</span>}
              </div>
            </div>
          )
        })}
      <EditColumnModal
        isOpen={isEditColumnModalOpened}
        onClose={handleEditColumnModalClose}
        column_id={column_id}
      />

      <EditAutomationModal
        automation={selectedAutomation?.id || null}
        isSmsOpen={selectedAutomation?.isSmsOpen}
        isEmailOpen={selectedAutomation?.isEmailOpen}
        isTaskOpen={selectedAutomation?.isTaskOpen}
        isOpen={isEditAutomationModalOpened}
        onClose={handleEditAutomationModalClose}
        resetModals={resetModals}
        onSuccessUpdate={handleOnSuccessUpdate}
      />
    </div>
  )
}

export default AutomationBoard
