import { FC, useEffect, useState } from 'react'
import { NOTE_TAB } from './types/notes.type'
import NotesNavigation from './notes-navaigation.component'
import { NOTE_TAB_COMPONENTS } from './const/note.const'
import { useTaskContext } from '@src/context/task-context'

const Notes: FC = () => {
  const {setSelectedCompanyState} = useTaskContext();
  const [tab, setTab] = useState<NOTE_TAB>(NOTE_TAB.NOTE)

  const handleTabChange = (newTab: NOTE_TAB) => {
    setTab(newTab)
  }

  useEffect(() => {
    setSelectedCompanyState({id: localStorage.getItem('contactsId')})
  }, [])

  const Tab: FC = NOTE_TAB_COMPONENTS[tab]

  return (
    <div>
      <NotesNavigation onTabClick={handleTabChange} activeTab={tab} />
      <Tab />
    </div>
  )
}

export default Notes
