import SlideModal from '@components/slide-modal'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import { REACT_APP_API_URL } from '@src/lib/global-variables'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { FC, useEffect } from 'react'
import { Oval } from 'react-loader-spinner'
import { toast } from 'react-toastify'
import cls from 'classnames'
import moment from 'moment'
import { ClaimDetailNote, ViewClaimDetailsNoteModalProps } from '../../types/claim-details.type'
import styles from './view-claim-details-note-modal.module.scss'

const ViewClaimDetailsNoteModal: FC<ViewClaimDetailsNoteModalProps> = ({
  claimDetailsNoteId,
  isOpen,
  onClose,
}) => {
  const { data, isLoading, isRefetching, isError } = useQuery({
    queryKey: [GLOBAL_QUERIES.CLAIM_DETAILS_NOTES.GET_BY_ID, claimDetailsNoteId],
    queryFn: async () => {
      const response = await axios.get(`${REACT_APP_API_URL}/claim-detail-notes/${claimDetailsNoteId}`, {
        params: { join: 'staff_created' },
      })
      return response.data as ClaimDetailNote
    },
    enabled: !!claimDetailsNoteId && isOpen,
  })

  useEffect(() => {
    if (isError) {
      toast.error('Something went wrong')
    }
  }, [isError])

  if (isError) return null

  const isTaskNoteLoading = isLoading || isRefetching

  const staffFullName = `${data?.staff_created?.first_name || ''} ${data?.staff_created?.last_name || ''}`;

  return (
    <SlideModal isOpen={isOpen} onClose={onClose} style={{ width: '675px' }} layoutStyle={{zIndex: 1000, backgroundColor: 'black'}}>
      <div className={styles.wrapper}>
        <span onClick={onClose} className={cls('lnr lnr-indent-decrease', styles.closeIcon)}></span>
        {isTaskNoteLoading ? (
          <Oval
            visible={true}
            height="60"
            width="60"
            color="blue"
            secondaryColor="blue"
            ariaLabel="oval-loading"
            wrapperStyle={{
              position: 'absolute',
              top: '50%',
              right: '50%',
              transform: 'translate(50%,-50%)',
            }}
          />
        ) : null}

        {!isTaskNoteLoading && data ? (
          <div className={styles.noteWraper}>
            <h3 className="w-100 text-center">Claim Details Note</h3>
            <div className="d-flex align-items center">
              <h4>Created By: </h4>
              <p className="m-0 ml-1">{staffFullName}</p>
            </div>
            <div className="d-flex align-items center">
              <h4>Created On:</h4>
              <p className="m-0 ml-1">{moment(data.created_at).format('DD/MM/YYYY')}</p>
            </div>
            <div className="d-flex align-items center">
              <h4>Total:</h4>
              <p className="m-0 ml-1">{data.total_amount}</p>
            </div>
            <div>
              <h4 className="w-100 mb-1">Description:</h4>
              <p className="m-0 overflow-auto">{data.description}</p>
            </div>
          </div>
        ) : null}
      </div>
    </SlideModal>
  )
}

export default ViewClaimDetailsNoteModal
