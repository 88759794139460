import { TopBarMenuLinkProps } from '@containers/layout/types/top-bar.type'
import { FC } from 'react'
import { Link } from 'react-router-dom'

const TopbarMenuLink: FC<TopBarMenuLinkProps> = ({ title, icon, path, onClick }) => (
  <Link className="topbar__link" to={path} onClick={onClick}>
    <span className={`topbar__link-icon lnr lnr-${icon}`} />
    <p className="topbar__link-title">{title}</p>
  </Link>
)

export default TopbarMenuLink
