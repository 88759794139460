import React, { useMemo } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import { Button, ButtonGroup, Col, Row, UncontrolledTooltip } from 'reactstrap';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import Services from './../../../shared/Services';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@material-ui/core/IconButton';
import ModalComponent from '../../../shared/components/ModalCustom';
import Popover from '@material-ui/core/Popover';
import { withRouter } from 'react-router';
import { useLocation } from 'react-router-dom';
import TablePagination from '@material-ui/core/TablePagination';
import AddTaskModal from './AddTaskModal';
import TasksService from '../../../services/TasksService';
import { TailSpin } from 'react-loader-spinner';
import StaffService from '../../../services/StaffService';
import { UserContext } from '../../../context/user-context';

const withReloadOnParamChanges = (Component) => {
  return (props) => {
    const location = useLocation();
    return useMemo(() => {
      return (
        <Component
          key={location.state.id}
          staffID={location.state.id}
          lname={location.state.last_name}
          fname={location.state.first_name}
        />
      );
    }, [location]);
  };
};

class TaskTableStaffID extends React.Component {
  static contextType = UserContext

  constructor(props) {
    super(props);
    this.state = {
      openArrow: false,
      addTaskModal: false,
      notesDialog: false,
      id: localStorage.getItem('contactsId'),
      order: 'asc',
      orderBy: '',
      selected: new Map([]),
      taskList: [],
      overDue: [],
      toDay: [],
      later: [],
      pageLater: 0,
      rowsPerPageLater: 5,
      errorModal: false,
      errorMessage: '',
      errorType: '',
      st_id: '',
      accessErrorModal: false,
      app_type: '',
      filter_app_type: [],
      salesList: [],
      recoveryList: [],
      addAppointment: false,
      typeDropdown: false,
      staffDropdown: false,
      allStaff: [],
      taskFilter: 'All',
      staffFilter: 'All',
      anchorEl: null,
      anchorE2: null,
      isArchive: false,
      total: 0,
      pageOverDue: 0,
      rowsPerPageOverDue: 5,
      pageToDay: 0,
      rowsPerPageToDay: 5,
      // staffID: this.props?.location?.state?.staffId
      staffID: props.staffID,
      fname: props.fname,
      lname: props.lname,
      newDate: new Date(),
      isEdit: false,
      selectedTask: '',
      loader: false,
    };
    this.services = new Services();
    this.task = new TasksService();
    this.staff = new StaffService();
  }

  componentDidMount() {
    this.getTaskListStaffId();
    this.getAllstaff();
  }

  getAllstaff() {
    const data = {};
    this.staff.getStaff(data).then((res) => {
      if (res.data) {
        const getData = res.data.filter(e => e.id === this.props.staffID);
        let listStaff;
        listStaff =
          getData &&
          getData.map((item) => {
            return {
              value: item.id,
              label: `${item.first_name}  ${item.last_name}`,
            };
          });
        this.setState({ allStaff: listStaff });
      } else if (
        res.statusCode === 401 &&
        res.responseType === 'UNAUTHORIZED'
      ) {
        this.setState({ accessErrorModal: true });
      }
    });
  }


  async getTaskListStaffId() {
    this.setState({ loader: true });
    try {
      const params = {
        id: this.props.staffID,
        join: ['tasks'],
      };
      const list = await this.staff.getStaffId(params);
      const taskList = list.tasks.filter(e => e.is_archived === false);
      this.setState({
        taskList: taskList,
        total: taskList.length,
      });
      this.filterData(taskList);
      this.setState({ loader: false });
    } catch (e) {
      this.services.errorToast(e);
    }
  }

  filterData(dataStaffID) {
    const overDue = [];
    const toDay = [];
    const later = [];
    dataStaffID.forEach((data) => {
      if (
        new Date(data.date_start).getMonth() > new Date().getMonth() ||
        (new Date(data.date_start).getDate() > new Date().getDate() &&
          new Date(data.date_start).getMonth() === new Date().getMonth())
      )
        later.push(data);
      else if (
        new Date(data.time_created).getMonth() < new Date().getMonth() ||
        (new Date(data.time_created).getDate() < new Date().getDate() &&
          new Date(data.time_created).getMonth() === new Date().getMonth())
      )
        overDue.push(data);
      else toDay.push(data);
    });

    if (this.props.completed === true || this.props.unassigned === true) {
      this.handleFilter(overDue, toDay, later);
    } else {
      this.setState({
        overDue: overDue,
        toDay: toDay,
        later: later,
      });
      this.setState({ errorModal: true });
      this.setState({ errorModal: false });
    }
  }

  handleFilter = (overDue, toDay, later) => {
    let filteredOverDue;
    let filteredToday;
    let filteredLater;
    if (this.props.completed === true && this.props.unassigned === false) {
      filteredOverDue = overDue.filter((_) => _.is_done === true);
      filteredToday = toDay.filter((_) => _.is_done === true);
      filteredLater = later.filter((_) => _.is_done === true);
    } else if (
      this.props.unassigned === true &&
      this.props.completed === false
    ) {
      filteredOverDue = overDue.filter((_) => _.assigned_staff.length === 0);
      filteredToday = toDay.filter((_) => _.assigned_staff.length === 0);
      filteredLater = later.filter((_) => _.assigned_staff.length === 0);
    } else {
      filteredOverDue = overDue.filter(
        (_) => _.assigned_staff.length === 0 && _.is_done === true,
      );
      filteredToday = toDay.filter(
        (_) => _.assigned_staff.length === 0 && _.is_done === true,
      );
      filteredLater = later.filter(
        (_) => _.assigned_staff.length === 0 && _.is_done === true,
      );
    }
    this.setState({
      overDue: filteredOverDue,
      toDay: filteredToday,
      later: filteredLater,
    });
  };

  editTask = (task) => {
    this.setState({
      selectedTask: task,
      isEdit: true,
      addAppointment: true,
      addTaskModal: true,
    });
  };

  TypeClick = (event) => {
    this.setState({
      anchorE2: event.currentTarget,
    });
  };
  StaffClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };
  handleClose = () => {
    this.setState({
      anchorEl: null,
      anchorE2: null,
    });
  };

  closeModal = () => {
    this.setState({
      addTaskModal: false,
      selectedTask: '',
      isEdit: false,
      viewModal: false,
    });
  };

  filterDataByType = (item) => {
    if (item) {
      this.setState({ taskFilter: item });
      let list = [];
      if (item === 'All') {
        this.setState({ taskListCopy: this.state.taskList });
        this.filterData(this.state.taskList);
      } else {
        list = this.state.taskList.filter((_) => _.type === item);
        if (list) {
          this.setState({ taskListCopy: list });
          this.filterData(list);
        }
      }
    }
  };
  filterDataByStaff = (item) => {
    if (item) {
      this.setState({ staffFilter: item });
      let list = [];
      if (item === 'All') {
        this.setState({ taskListCopy: this.state.taskList });
        this.filterData(this.state.taskList);
      } else {
        list = this.state.taskList.filter((task) => {
          const tList = task.assigned_staff.find((_) => _.id === item);
          if (tList) {
            return task;
          }
        });
        if (list) {
          this.setState({ taskListCopy: list });
          this.filterData(list);
        }
      }
    }
  };

  statusChange(item) {
    try {
      const {user} = this.context;
      const data = {
        is_done: !item.is_done,
        staff_id_modified: user.id,
      };
      this.task.editTask(data, item.id).then(() => {
        this.services.successToast('Status changed Successfully');
        this.getTaskListStaffId();
      });
    } catch (e) {
      this.services.errorToast(e);
    }
  }

  OpenModalDelete = (id) => {
    this.setState({
      deleteId: id,
      errorMessage: 'Are you sure you want to delete this record? ',
      errorModal: true,
      errorType: 'danger',
    });
  };
  OpenModalArchive = (id) => {
    this.setState({
      deleteId: id,
      isArchive: true,
      errorMessage: 'Are you sure you want to archive this record? ',
      errorModal: true,
      errorType: 'danger',
    });
  };

  DeleteTask = () => {
    const {user} = this.context;
    const data = {
      id: this.state.deleteId,
      is_archived: this.state.isArchive,
      is_done: true,
      staff_id_modified: user.id,
    };
    this.task.editTask(data, data.id).then((res) => {
      if (res.status === 200) {
        if (this.state.isArchive)
          this.services.successToast('Task Archived Successfully');
        else
          this.services.successToast('Task Deleted Successfully');
        this.getTaskList();
        this.setState({
          errorMessage: '',
          errorModal: false,
          errorType: 'danger',
          isArchive: false,
          deleteId: '',
        });
      } else if (
        res.statusCode === 401 &&
        res.responseType === 'UNAUTHORIZED'
      ) {
        this.setState({ accessErrorModal: true });
      } else {
        this.services.errorToast(res.message);
      }
    });
  };

  ViewTask = (task) => {
    this.props.ViewTask(task);
  };
  clientClick = (item) => {
    localStorage.setItem('contactsId', item.client_input_id);
    this.props.history.push('/contacts');
  };
  setClientName = (item) => {
    let list;
    const len = (item && item.length) || 0;
    list =
      item &&
      item.map((c, i) => {
        return (
          <div key={i}>
            <b
              onClick={() => this.clientClick(c)}
              className='link_text cursor-pointer'
            >
              {c.client_input} {i !== len - 1 ? ', ' : ''}
            </b>
          </div>
        );
      });
    return list;
  };

  reloadList = () => {
    this.refs.TaskTable && this.refs.TaskTable.getTaskList();
    this.refs.Calander && this.refs.Calander.getTaskList();
    this.setState({
      currentView: '',
    });
    this.setState({
      currentView: this.state.currentViewCopy || 'List',
    });
  };

  handleChangePageOverDue = (event, page) => {
    this.setState({ pageOverDue: +page });
  };

  handleChangeRowsPerPageOverDue = (event) => {
    this.setState({ rowsPerPageOverDue: +event.target.value });
  };

  handleChangePageToDay = (event, page) => {
    this.setState({ pageToDay: +page });
  };

  handleChangeRowsPerPageToDay = (event) => {
    this.setState({ rowsPerPageToDay: +event.target.value });
  };

  handleChangePageLater = (event, page) => {
    this.setState({ pageLater: +page });
  };

  handleChangeRowsPerPageLater = (event) => {
    this.setState({ rowsPerPageLater: +event.target.value });
  };

  render() {
    const open = Boolean(this.state.anchorEl);
    const openType = Boolean(this.state.anchorE2);
    const id = open ? 'simple-popover' : undefined;
    const idopenType = openType ? 'simple-popover' : undefined;
    const {user} = this.context;

    const {
      overDue,
      toDay,
      later,
      rowsPerPageOverDue,
      pageOverDue,
      rowsPerPageToDay,
      pageToDay,
      pageLater,
      rowsPerPageLater,
    } = this.state;
    let isAccess = true;
    if (
      (user && user.access_level === 'SALES') ||
      user.role === 'RECOVERY'
    ) {
      isAccess = false;
    }
    if (
      document.getElementById('archive') &&
      this.state.taskList &&
      this.state.taskList.length > 0
    ) {
    }
    return (
      <div className='container page-body-wrapper position-relative pb-5'>
        {this.state.loader ? (
          <div className='loader'>
            <TailSpin
              height='150'
              width='150'
              color='#f69066'
              ariaLabel='loading'
            />
          </div>
        ) : (
          <>
            <Row className='align-items-center '>
              <Col md={6} className='pb-4'>
                <h3 className='page-title mb-5'>
                  Tasks assign for {this.state.fname + ' ' + this.state.lname}
                </h3>
              </Col>
            </Row>
            <h3 className='overdue_title'>OVERDUE</h3>
            <div className='table_wrap table_radius'>
              <Table
                className='material-table '
                id='notesTable'
                ref={(el) => (this.componentRef = el)}
              >
                <thead>
                <tr>
                  <th>Due Date</th>
                  <th>
                    Type
                    <IconButton
                      style={{ height: '10px', width: '10px' }}
                      className='ml-3'
                      aria-label='expand row'
                      size='small'
                      onClick={this.TypeClick}
                    >
                      {this.state.typeDropdown ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                    <Popover
                      id={idopenType}
                      open={openType}
                      anchorEl={this.state.anchorE2}
                      onClose={this.handleClose}
                      className='dropdown_height theme-light ltr-support'
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      <li className='table_head_dropdown'>
                        <div className='form__form-group'>
                          <div className='form__form-group-field'>
                            <label
                              className={`colored-click radio-btn--colored`}
                            >
                              <input
                                className='radio-btn__radio'
                                type='radio'
                                name='Appointment'
                                id={'Appointment'}
                                checked={
                                  this.state.taskFilter === 'Appointment'
                                }
                                onChange={() =>
                                  this.filterDataByType('Appointment')
                                }
                              />
                              <span className='radio-btn__radio-custom' />
                              <span className='radio-btn__label mt-0'>
                                  {'Appointment'}
                                </span>
                            </label>
                          </div>
                        </div>
                      </li>
                      <li className='table_head_dropdown'>
                        <div className='form__form-group'>
                          <div className='form__form-group-field'>
                            <label
                              className={`colored-click radio-btn--colored`}
                            >
                              <input
                                className='radio-btn__radio'
                                type='radio'
                                name='Note'
                                id={'Note'}
                                checked={this.state.taskFilter === 'Note'}
                                onChange={() => this.filterDataByType('Note')}
                              />
                              <span className='radio-btn__radio-custom' />
                              <span className='radio-btn__label mt-0'>
                                  {'Note'}
                                </span>
                            </label>
                          </div>
                        </div>
                      </li>
                      <li className='table_head_dropdown'>
                        <div className='form__form-group'>
                          <div className='form__form-group-field'>
                            <label
                              className={`colored-click radio-btn--colored`}
                            >
                              <input
                                className='radio-btn__radio'
                                type='radio'
                                name='Task'
                                id={'Task'}
                                checked={this.state.taskFilter === 'Task'}
                                onChange={() => this.filterDataByType('Task')}
                              />
                              <span className='radio-btn__radio-custom' />
                              <span className='radio-btn__label mt-0'>
                                  {'Task'}
                                </span>
                            </label>
                          </div>
                        </div>
                      </li>
                      <li className='table_head_dropdown'>
                        <div className='form__form-group'>
                          <div className='form__form-group-field'>
                            <label
                              className={`colored-click radio-btn--colored`}
                            >
                              <input
                                className='radio-btn__radio'
                                type='radio'
                                name='All'
                                id={'All'}
                                checked={this.state.taskFilter === 'All'}
                                onChange={() => this.filterDataByType('All')}
                              />
                              <span className='radio-btn__radio-custom' />
                              <span className='radio-btn__label mt-0'>
                                  {'All'}
                                </span>
                            </label>
                          </div>
                        </div>
                      </li>
                    </Popover>
                  </th>
                  <th>Client</th>
                  <th>Title</th>
                  <th>Wip #</th>
                  <th>Subject</th>
                  <th className='text-center'>Created On</th>
                  <th className='text-center'>Completed On</th>
                  <th>
                    Staff
                    <IconButton
                      style={{ height: '10px', width: '10px' }}
                      className='ml-3'
                      aria-label='expand row'
                      size='small'
                      aria-describedby={id}
                      onClick={this.StaffClick}
                    >
                      {this.state.staffDropdown ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                    {/* <ul className={`table_head_dropdown ${this.state.staffDropdown ? 'active' : null}`}> */}
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={this.state.anchorEl}
                      onClose={this.handleClose}
                      className='dropdown_height theme-light ltr-support'
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      <li className='table_head_dropdown'>
                        <div className='form__form-group'>
                          <div className='form__form-group-field'>
                            <label
                              className={`colored-click radio-btn--colored`}
                            >
                              <input
                                className='radio-btn__radio'
                                type='radio'
                                name='All'
                                id={'All'}
                                checked={this.state.staffFilter === 'All'}
                                onChange={() => this.filterDataByStaff('All')}
                              />
                              <span className='radio-btn__radio-custom' />
                              <span className='radio-btn__label mt-0'>
                                  {'All'}
                                </span>
                            </label>
                          </div>
                        </div>
                      </li>
                      {this.state.allStaff.map((item, i) => {
                        return (
                          <li key={i} className='table_head_dropdown'>
                            <div className='form__form-group'>
                              <div className='form__form-group-field'>
                                <label
                                  className={`colored-click radio-btn--colored`}
                                >
                                  <input
                                    className='radio-btn__radio'
                                    type='radio'
                                    name={item.value}
                                    id={item.value}
                                    checked={
                                      this.state.staffFilter === item.value
                                    }
                                    onChange={() =>
                                      this.filterDataByStaff(item.value)
                                    }
                                  />
                                  <span className='radio-btn__radio-custom' />
                                  <span className='radio-btn__label mt-0'>
                                      {item.label}
                                    </span>
                                </label>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </Popover>
                    {/* </ul> */}
                  </th>

                  <th className='text-center'>Done</th>
                </tr>
                </thead>
                <TableBody>
                  {this.state.overDue.length === 0 && (
                    <TableRow
                      className='material-table__row boxshadow_tbl'
                      style={{ height: 80 }}
                    >
                      <TableCell
                        colSpan={10}
                        className='text-center bg-white material-table__cell  material-table__cell-right rounded-left'
                      >
                        <span>{'None To Display'}</span>
                      </TableCell>
                    </TableRow>
                  )}

                  {overDue
                    .slice(
                      pageOverDue * rowsPerPageOverDue,
                      pageOverDue * rowsPerPageOverDue + rowsPerPageOverDue,
                    )
                    .map((item, i) => {
                      const setClient = this.setClientName(item.client_input);
                      return (
                        <>
                          {/* {i === 0 ?
                      <TableRow className="material-table__row" role="checkbox" tabIndex={-1} >
                        <TableCell
                            className="material-table__cell material-table__cell-right"
                            scope="row"
                        >OVERDUE </TableCell>
                      </TableRow> : ''} */}
                          <TableRow
                            style={{ height: '100px' }}
                            key={i}
                            className='material-table__row boxshadow_tbl material-table__row bg-white '
                            role='checkbox'
                            tabIndex={-1}
                          >
                            <TableCell
                              width='5%'
                              className='material-table__cell  material-table__cell-right overdue  rounded-left'
                              scope='row'
                            >
                              <span className='overdue_date'>
                                {moment(item.date_start).format('DD-MM-YY')}
                              </span>
                            </TableCell>

                            <TableCell
                              width='5%'
                              className='material-table__cell  material-table__cell-right '
                            >
                              {item.type}
                            </TableCell>
                            <TableCell
                              width='10%'
                              className='material-table__cell  material-table__cell-right '
                            >
                              {/* <b onClick={()=>this.clientClick(item)} className="link_text">{item.client_input}</b> */}
                              {item.companies?.map(e => e.name)}
                            </TableCell>
                            <TableCell
                              width='10%'
                              className='material-table__cell  material-table__cell-right '
                            >
                              {item.title}
                            </TableCell>
                            <TableCell
                              width='10%'
                              className='material-table__cell  material-table__cell-right '
                            >
                              {item.wip_id}
                            </TableCell>
                            <TableCell
                              width='10%'
                              className='material-table__cell  material-table__cell-right '
                            >
                              {item.subject}
                            </TableCell>
                            <TableCell className='material-table__cell material-table__cell-right'>
                              {item.time_created ? moment(item.time_created).format('DD-MM-YY hh:mm:A') : ''}
                            </TableCell>
                            <TableCell className='material-table__cell material-table__cell-right'>
                              {item.is_done
                                ? moment(item.time_modified).format(
                                  'DD-MM-YY hh:mm:A',
                                )
                                : ' _ '}
                            </TableCell>
                            <TableCell
                              width='30%'
                              className='material-table__cell  material-table__cell-left'
                            >
                              <div className='d-flex flex-wrap align-items-center justify-content-between'>
                                <div className='d-flex flex-wrap round_three align-items-center'>
                                  {item.assigned_staff && item.assigned_staff.map((s, i) => {
                                    let name;
                                    let staff_nm = `${s.first_name} ${s.last_name}`;
                                    if (staff_nm && staff_nm.split(' ') && staff_nm.split(' ').length > 2) {
                                      const n = staff_nm.split(' ');
                                      name = n[0][0] + n[2][0];
                                    } else {
                                      name = staff_nm && staff_nm.substring(0, 2);
                                    }
                                    return (
                                      <span key={i} className='rounded-circle uppercase staff_name mb-1 mr-2'
                                            style={{
                                              border: `2px solid ${s.display_color}`,
                                              color: `${s.display_color}`,
                                            }}>
                                    {name}
                                  </span>
                                    );
                                  })}
                                </div>
                                <div className='d-flex align-items-center'>
                                  <ButtonGroup
                                    className='btn-group--icons mb-0'
                                    dir='ltr'
                                  >
                                    {isAccess ? (
                                      <>
                                        {' '}
                                        <Button
                                          outline
                                          id='Edit'
                                          className='mb-0 d-flex align-items-center mr-2'
                                          onClick={() => this.editTask(item)}
                                        >
                                          Edit
                                        </Button>
                                        <Button
                                          outline
                                          id='Delete'
                                          className='mb-0 d-flex align-items-center mr-2'
                                          onClick={() =>
                                            this.OpenModalDelete(item.id)
                                          }
                                        >
                                          <span className='lnr lnr-trash' />
                                        </Button>
                                        {item.is_done && (
                                          <Button
                                            outline
                                            id='archive'
                                            className='mb-0 d-flex align-items-center'
                                            onClick={() =>
                                              this.OpenModalArchive(item.id)
                                            }
                                          >
                                            <span className='lnr lnr-enter-down' />
                                          </Button>
                                        )}
                                      </>
                                    ) : (
                                      <Button
                                        outline
                                        id='View'
                                        className='mb-0 d-flex align-items-center mr-2'
                                        onClick={() => this.ViewTask(item)}
                                      >
                                        View
                                      </Button>
                                    )}

                                    {/* <IconButton className="ml-3" aria-label="expand row" size="small" onClick={this.setOpen}>
                                        {this.state.openArrow ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                      </IconButton> */}
                                  </ButtonGroup>
                                  {isAccess ? (
                                    <>
                                      {' '}
                                      <UncontrolledTooltip
                                        placement='bottom'
                                        target='Edit'
                                      >
                                        Edit Task
                                      </UncontrolledTooltip>{' '}
                                      <UncontrolledTooltip
                                        placement='bottom'
                                        target='Delete'
                                      >
                                        Delete Task
                                      </UncontrolledTooltip>
                                      {document.getElementById('archive') && (
                                        <UncontrolledTooltip
                                          placement='bottom'
                                          target='archive'
                                        >
                                          Archive Task
                                        </UncontrolledTooltip>
                                      )}
                                    </>
                                  ) : (
                                    <UncontrolledTooltip
                                      placement='bottom'
                                      target='View'
                                    >
                                      View Task
                                    </UncontrolledTooltip>
                                  )}
                                </div>
                              </div>
                            </TableCell>

                            <TableCell
                              width='2%'
                              className='material-table__cell  material-table__cell-right  btn-light rounded-right'
                            >
                              <div className='round_radio'>
                                <input
                                  type='checkbox'
                                  id={item.id}
                                  checked={item.is_done}
                                  onChange={() => this.statusChange(item)}
                                />
                                <label htmlFor={item.id} />
                              </div>
                            </TableCell>
                          </TableRow>
                          <TableRow className='material-table__row   '>
                            <TableCell colSpan='8' className='p-0 border-0'>
                              <div className='mt-2' />
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                  <div style={{ position: 'absolute', right: '50px' }}>
                    <TablePagination
                      component='div'
                      className='material-table__pagination'
                      count={this.state.overDue.length}
                      rowsPerPage={this.state.rowsPerPageOverDue}
                      page={this.state.pageOverDue}
                      backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                      nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                      onPageChange={this.handleChangePageOverDue}
                      onRowsPerPageChange={this.handleChangeRowsPerPageOverDue}
                      rowsPerPageOptions={[5, 10]}
                      dir='ltr'
                      SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' },
                        native: true,
                      }}
                    />
                  </div>

                  <TableRow>
                    <TableCell
                      style={{ paddingTop: '60px' }}
                      className='border-0 pl-0'
                    >
                      <h3 className={'page-title'}>TODAY</h3>
                    </TableCell>
                  </TableRow>
                  {this.state.toDay.length === 0 && (
                    <TableRow
                      className='material-table__row boxshadow_tbl bg-white'
                      style={{ height: 60 }}
                    >
                      <TableCell
                        colSpan={10}
                        className='text-center  material-table__cell  material-table__cell-right rounded-left'
                      >
                        <span>{'None To Display'}</span>
                      </TableCell>
                    </TableRow>
                  )}

                  {toDay.length > 0 &&
                  toDay
                    .slice(
                      pageToDay * rowsPerPageToDay,
                      pageToDay * rowsPerPageToDay + rowsPerPageToDay,
                    )
                    .map((item, i) => {
                      return (
                        <>
                          {/* {i === 0 ? <TableRow className="material-table__row" role="checkbox" tabIndex={-1} >
                          <TableCell
                              className="material-table__cell material-table__cell-right"
                              scope="row"
                          >TODAY </TableCell>
                          </TableRow> : ''} */}
                          <TableRow
                            style={{ height: '100px' }}
                            key={i}
                            className='material-table__row bg-white boxshadow_tbl'
                            role='checkbox'
                            tabIndex={-1}
                          >
                            <TableCell
                              width='5%'
                              className='material-table__cell material-table__cell-right rounded-left'
                              scope='row'
                            >
                              {moment(item.date_start).format('DD-MM-YY')}
                            </TableCell>

                            <TableCell
                              width='5%'
                              className='material-table__cell material-table__cell-right'
                            >
                              {item.type}
                            </TableCell>
                            <TableCell
                              width='10%'
                              className='material-table__cell material-table__cell-right'
                            >
                              {/* <b onClick={()=>this.clientClick(item)} className="link_text">{item.client_input}
                            </b> */}
                            </TableCell>
                            <TableCell
                              width='10%'
                              className='material-table__cell material-table__cell-right'
                            >
                              {item.title}
                            </TableCell>
                            <TableCell
                              width='10%'
                              className='material-table__cell material-table__cell-right'
                            >
                              {item.wip_id}
                            </TableCell>
                            <TableCell
                              width='10%'
                              className='material-table__cell material-table__cell-right'
                            >
                              {item.subject}
                            </TableCell>
                            <TableCell className='material-table__cell material-table__cell-right'>
                              {item.time_created ? moment(item.time_created).format('DD-MM-YY hh:mm:A') : ''}
                            </TableCell>
                            <TableCell className='material-table__cell material-table__cell-right'>
                              {item.is_done
                                ? moment(item.time_modified).format(
                                  'DD-MM-YY hh:mm:A',
                                )
                                : ' _ '}
                            </TableCell>
                            <TableCell
                              width='30%'
                              className='material-table__cell material-table__cell-left'
                            >
                              <div className='d-flex flex-wrap align-items-center justify-content-between'>
                                <div className='d-flex round_three flex-wrap align-items-center'>
                                  {item.assigned_staff && item.assigned_staff.map((s, i) => {
                                    let name;
                                    let staff_nm = `${s.first_name} ${s.last_name}`;
                                    if (staff_nm && staff_nm.split(' ') && staff_nm.split(' ').length > 2) {
                                      const n = staff_nm.split(' ');
                                      name = n[0][0] + n[2][0];
                                    } else {
                                      name = staff_nm && staff_nm.substring(0, 2);
                                    }
                                    return (
                                      <span key={i} className='rounded-circle uppercase staff_name mb-1 mr-2'
                                            style={{
                                              border: `2px solid ${s.display_color}`,
                                              color: `${s.display_color}`,
                                            }}>
                                    {name}
                                  </span>
                                    );
                                  })}
                                </div>
                                <div className='d-flex align-items-center'>
                                  <ButtonGroup
                                    className='btn-group--icons mb-0'
                                    dir='ltr'
                                  >
                                    {isAccess ? (
                                      <>
                                        {' '}
                                        <Button
                                          outline
                                          id='Edit'
                                          className='mb-0 d-flex align-items-center mr-2'
                                          onClick={() => this.editTask(item)}
                                        >
                                          Edit
                                        </Button>
                                        <Button
                                          outline
                                          id='Delete'
                                          className='mb-0 d-flex align-items-center mr-2'
                                          onClick={() =>
                                            this.OpenModalDelete(item.id)
                                          }
                                        >
                                          <span className='lnr lnr-trash' />
                                        </Button>
                                        {item.is_done && (
                                          <Button
                                            outline
                                            id='archive'
                                            className='mb-0 d-flex align-items-center'
                                            onClick={() =>
                                              this.OpenModalArchive(item.id)
                                            }
                                          >
                                            <span className='lnr lnr-enter-down' />
                                          </Button>
                                        )}
                                      </>
                                    ) : (
                                      <Button
                                        outline
                                        id='View'
                                        className='mb-0 d-flex align-items-center mr-2'
                                        onClick={() => this.ViewTask(item)}
                                      >
                                        View
                                      </Button>
                                    )}

                                    {/* <IconButton className="ml-3" aria-label="expand row" size="small" onClick={this.setOpen}>
                                      {this.state.openArrow ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    </IconButton> */}
                                  </ButtonGroup>
                                  {isAccess ? (
                                    <>
                                      {' '}
                                      <UncontrolledTooltip
                                        placement='bottom'
                                        target='Edit'
                                      >
                                        Edit Task
                                      </UncontrolledTooltip>
                                      <UncontrolledTooltip
                                        placement='bottom'
                                        target='Delete'
                                      >
                                        Delete Task
                                      </UncontrolledTooltip>
                                      {document.getElementById('archive') && (
                                        <UncontrolledTooltip
                                          placement='bottom'
                                          target='archive'
                                        >
                                          Archive Task
                                        </UncontrolledTooltip>
                                      )}
                                    </>
                                  ) : (
                                    <UncontrolledTooltip
                                      placement='bottom'
                                      target='View'
                                    >
                                      View Task
                                    </UncontrolledTooltip>
                                  )}
                                </div>
                              </div>
                            </TableCell>

                            <TableCell
                              width='2%'
                              className='material-table__cell material-table__cell-right btn-light rounded-right'
                            >
                              <div className='round_radio'>
                                <input
                                  type='checkbox'
                                  id={item.id}
                                  checked={item.is_done}
                                  onChange={() => this.statusChange(item)}
                                />
                                <label for={item.id} />
                              </div>
                            </TableCell>
                          </TableRow>
                          <TableRow className='material-table__row   '>
                            <TableCell colSpan='8' className='p-0 border-0'>
                              <div className='mt-2' />
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                  <div style={{ position: 'absolute', right: '50px' }}>
                    <TablePagination
                      component='div'
                      className='material-table__pagination'
                      count={this.state.toDay.length}
                      rowsPerPage={this.state.rowsPerPageToDay}
                      page={this.state.pageToDay}
                      backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                      nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                      onPageChange={this.handleChangePageToDay}
                      onRowsPerPageChange={this.handleChangeRowsPerPageToDay}
                      rowsPerPageOptions={[5, 10]}
                      dir='ltr'
                      SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' },
                        native: true,
                      }}
                    />
                  </div>

                  <TableRow>
                    <TableCell
                      style={{ paddingTop: '60px' }}
                      className='border-0 pl-0'
                    >
                      <h3 className={'page-title'}>LATER</h3>
                    </TableCell>
                  </TableRow>

                  {this.state.later.length === 0 && (
                    <TableRow
                      className='material-table__row boxshadow_tbl'
                      style={{ height: 60 }}
                    >
                      <TableCell
                        colSpan={10}
                        className='text-center bg-white material-table__cell  material-table__cell-right rounded-left'
                      >
                        <span>{'None To Display'}</span>
                      </TableCell>
                    </TableRow>
                  )}

                  {later.length > 0 &&
                  later
                    .slice(
                      pageLater * rowsPerPageLater,
                      pageLater * rowsPerPageLater + rowsPerPageLater,
                    )
                    .map((item) => {
                      return (
                        <>
                          <TableRow
                            style={{ height: '100px' }}
                            key={item.wip}
                            className='material-table__row bg-white boxshadow_tbl'
                            role='checkbox'
                            tabIndex={-1}
                          >
                            <TableCell
                              className='material-table__cell material-table__cell-right rounded-left'
                              scope='row'
                            >
                              {moment(item.date_start).format('DD-MM-YY')}
                            </TableCell>

                            <TableCell className='material-table__cell material-table__cell-right'>
                              {item.type}
                            </TableCell>
                            <TableCell className='material-table__cell material-table__cell-right'>
                              {/* <b  onClick={()=>this.clientClick(item)} className="link_text">{item.client_input}</b> */}
                            </TableCell>
                            <TableCell className='material-table__cell material-table__cell-right'>
                              {item.title}
                            </TableCell>
                            <TableCell className='material-table__cell material-table__cell-right'>
                              {item.wip_id}
                            </TableCell>
                            <TableCell className='material-table__cell material-table__cell-right'>
                              {item.subject}
                            </TableCell>
                            <TableCell className='material-table__cell material-table__cell-right'>
                              {item.time_created ? moment(item.time_created).format('DD-MM-YY hh:mm:A') : ''}
                            </TableCell>
                            <TableCell className='material-table__cell material-table__cell-right'>
                              {item.is_done
                                ? moment(item.time_modified).format(
                                  'DD-MM-YY hh:mm:A',
                                )
                                : ' _ '}
                            </TableCell>
                            <TableCell className='material-table__cell material-table__cell-left'>
                              <div className='d-flex flex-wrap align-items-center justify-content-between'>
                                <div className='d-flex round_three flex-wrap align-items-center'>
                                  {item.assigned_staff && item.assigned_staff.map((s, i) => {
                                    let name;
                                    let staff_nm = `${s.first_name} ${s.last_name}`;
                                    if (staff_nm && staff_nm.split(' ') && staff_nm.split(' ').length > 2) {
                                      const n = staff_nm.split(' ');
                                      name = n[0][0] + n[2][0];
                                    } else {
                                      name = staff_nm && staff_nm.substring(0, 2);
                                    }
                                    return (
                                      <span key={i} className='rounded-circle uppercase staff_name mb-1 mr-2'
                                            style={{
                                              border: `2px solid ${s.display_color}`,
                                              color: `${s.display_color}`,
                                            }}>
                                    {name}
                                  </span>
                                    );
                                  })}
                                </div>
                                <div className='d-flex align-items-center'>
                                  <ButtonGroup
                                    className='btn-group--icons mb-0'
                                    dir='ltr'
                                  >
                                    {isAccess ? (
                                      <>
                                        {' '}
                                        <Button
                                          outline
                                          id='Edit'
                                          className='mb-0 d-flex align-items-center mr-2'
                                          onClick={() => this.editTask(item)}
                                        >
                                          Edit
                                        </Button>
                                        <Button
                                          outline
                                          id='Delete'
                                          className='mb-0 d-flex align-items-center'
                                          onClick={() =>
                                            this.OpenModalDelete(item.id)
                                          }
                                        >
                                          <span className='lnr lnr-trash' />
                                        </Button>
                                        {item.is_done && (
                                          <Button
                                            outline
                                            id='archive'
                                            className='mb-0 d-flex align-items-center'
                                            onClick={() =>
                                              this.OpenModalArchive(item._id)
                                            }
                                          >
                                            <span className='lnr lnr-enter-down' />
                                          </Button>
                                        )}
                                      </>
                                    ) : (
                                      <Button
                                        outline
                                        id='View'
                                        className='mb-0 d-flex align-items-center mr-2'
                                        onClick={() => this.ViewTask(item)}
                                      >
                                        View
                                      </Button>
                                    )}

                                    {/* <IconButton className="ml-3" aria-label="expand row" size="small" onClick={this.setOpen}>
                                          {this.state.openArrow ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        </IconButton> */}
                                  </ButtonGroup>
                                  {isAccess ? (
                                    <>
                                      {' '}
                                      <UncontrolledTooltip
                                        placement='bottom'
                                        target='Edit'
                                      >
                                        Edit Task
                                      </UncontrolledTooltip>
                                      <UncontrolledTooltip
                                        placement='bottom'
                                        target='Delete'
                                      >
                                        Delete Task
                                      </UncontrolledTooltip>
                                      {document.getElementById('archive') && (
                                        <UncontrolledTooltip
                                          placement='bottom'
                                          target='archive'
                                        >
                                          Archive Task
                                        </UncontrolledTooltip>
                                      )}
                                    </>
                                  ) : (
                                    <UncontrolledTooltip
                                      placement='bottom'
                                      target='View'
                                    >
                                      View Task
                                    </UncontrolledTooltip>
                                  )}
                                </div>
                              </div>
                            </TableCell>

                            <TableCell
                              className='material-table__cell material-table__cell-right btn-light rounded-right'>
                              <div className='round_radio'>
                                <input
                                  type='checkbox'
                                  id={item.id}
                                  checked={item.is_done}
                                  onChange={() => this.statusChange(item)}
                                />
                                <label for={item.id} />
                              </div>
                            </TableCell>
                          </TableRow>
                          <TableRow className='material-table__row   '>
                            <TableCell colSpan='8' className='p-0 border-0'>
                              <div className='mt-2' />
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                </TableBody>
              </Table>
              <div
                style={{
                  position: 'absolute',
                  right: '50px',
                  paddingBottom: '50px',
                }}
              >
                <TablePagination
                  component='div'
                  className='material-table__pagination'
                  count={this.state.later.length}
                  rowsPerPage={this.state.rowsPerPageLater}
                  page={this.state.pageLater}
                  backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                  nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                  onPageChange={this.handleChangePageLater}
                  onRowsPerPageChange={this.handleChangeRowsPerPageLater}
                  rowsPerPageOptions={[5, 10]}
                  dir='ltr'
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                />
              </div>
            </div>
          </>
        )}
        {this.state.addTaskModal ? (
          <AddTaskModal
            newDate={this.state.newDate}
            addTaskModal={this.state.addTaskModal}
            reloadList={this.reloadList}
            isEdit={this.state.isEdit}
            data={this.state.selectedTask}
            closeModal={this.closeModal}
          />
        ) : (
          ''
        )}

        {this.state.errorModal ? (
          <ModalComponent
            modal={this.state.errorModal}
            color={this.state.errorType}
            isCancel={true}
            message={this.state.errorMessage}
            onConfirm={() => this.DeleteTask()}
            onClose={() =>
              this.setState({
                errorModal: false,
                deleteId: '',
                isArchive: false,
              })
            }
          />
        ) : null}
      </div>
    );
  }
}

export default withRouter(withReloadOnParamChanges(TaskTableStaffID));
