import AddressForm, { AddressFormValues } from '../property-address-form/address-form.component'
import { FC, useMemo } from 'react'
import { BaseEditFormProps } from '@src/types/component-types'
import { LandlordAddress } from '@containers/Sales/Properties/types/property.type'
import { useUpdateLandlordAddressMutation } from '@containers/Sales/Landlord/hooks/crud-landlord-address.hook'
import { DeepPartial } from 'react-hook-form'
import { toast } from 'react-toastify'
import { STATE_OPTIONS } from '@src/lib/select-options.const'

const EditLandlordAddressForm: FC<BaseEditFormProps<LandlordAddress>> = ({
  entity,
  onSuccessSubmit,
}) => {
  const { mutateAsync, isLoading } = useUpdateLandlordAddressMutation()

  const handleSuccessSubmit = async (data: AddressFormValues) => {
    try {
      const res = await mutateAsync({
        landlordAddressId: entity.id,
        landlordId: entity.landlordId,
        data: {
          ...data,
          state: data.state?.value || null,
        } as DeepPartial<LandlordAddress>,
      })

      onSuccessSubmit(res)
    } catch (e) {
      toast.error('Failed to update landlord address')
      console.error(e)
    }
  }

  const initialValues = useMemo(
    () => ({
      addressLine1: entity.addressLine1,
      addressLine2: entity.addressLine2,
      addressLine3: entity.addressLine3,
      postcode: entity.postcode,
      country: entity.country,
      suburb: entity.suburb,
      state: STATE_OPTIONS.find((option) => option.value === entity.state) || null,
    }),
    [entity],
  )

  return (
    <AddressForm
      onSuccessSubmit={handleSuccessSubmit}
      loading={isLoading}
      initialValues={initialValues}
      submitButtonTitle="Save"
    />
  )
}

export default EditLandlordAddressForm
