import { AddressHistoryListItemProps } from '@containers/Recovery/Wip/_modules/Debtors/types/debtor-address.type'
import styles from './address-history-list.module.scss'
import { FC } from 'react'
import CloseIcon from '@src/icons/close'
import cls from 'classnames'
import { DebtorAddressHistoryService } from '@containers/Recovery/Wip/_modules/Debtors/services/debtor-address-history.service'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'

const AddressHistoryListItem: FC<AddressHistoryListItemProps> = ({
  debtorAddress,
  selected = false,
  onClick,
}) => {
  const queryClient = useQueryClient()
  const handleClick = () => {
    onClick && onClick(debtorAddress)
  }

  const handleDeleteClick = async () => {
    const debtorAddressService = new DebtorAddressHistoryService()
    try {
      await debtorAddressService.deleteDebtorAddress(debtorAddress.id)
      queryClient.invalidateQueries({ queryKey: [GLOBAL_QUERIES.DEBTOR_ADDRESS_HISTORY.GET] })
      toast.success('Address deleted')
    } catch (e) {
      toast.error('Something went wrong')
      console.log(e)
    }
  }

  return (
    <li className={cls(styles.addressHistoryItem, { [styles.selected]: selected })}>
      <div className={styles.addressHistoryItemTextWrapper} onClick={handleClick}>
        <span>{debtorAddress.address_1}</span>
      </div>
      <button onClick={handleDeleteClick} className={styles.closeButton}>
        <CloseIcon />
      </button>
    </li>
  )
}

export default AddressHistoryListItem
