import Section from '@components/section/section.component'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import styles from './payment-tracking-section.module.scss'
import { FC } from 'react'
import EditPaymentTrackingForm from '../../forms/payment-tracking-form/edit-payment-tracking-form.component'
import { EditPaymentTrackingSectionProps, PaymentTracking } from '../../../types/payment-tracking.type'
import { useQueryClient } from '@tanstack/react-query'
import { Wip } from '@containers/Recovery/Wip/types/wip.type'
import { getWipQueryKey } from '@containers/Recovery/Wip/utils/wip-query-factory'
import { toast } from 'react-toastify'

const EditPaymentTrackingSection: FC<EditPaymentTrackingSectionProps> = ({
  paymentTracking,
  wipId,
}) => {
  const queryClient = useQueryClient()

  const handleSuccessSubmit = (payTracking: PaymentTracking) => {
    queryClient.setQueryData<Wip>(getWipQueryKey(wipId), (prevData) =>
      prevData
        ? {
            ...prevData,
            pay_trackings: prevData.pay_trackings.map((pt) =>
              pt.id !== payTracking.id ? pt : payTracking,
            ),
          }
        : prevData,
    )
    toast.success('Payment tracking updated successfully')
  }
  return (
    <Section className={styles.sectionWrapper}>
      <Header mb={4}>Payment Tracking</Header>
      <EditPaymentTrackingForm entity={paymentTracking} onSuccessSubmit={handleSuccessSubmit} />
    </Section>
  )
}

export default EditPaymentTrackingSection
