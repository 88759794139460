import Table from '@components/table/table.component'
import { FC, useState } from 'react'
import { useGetTransactionList } from '../../../hooks/get-transaction-list.component'
import PaymentTableItem from './payment-table-item.component'
import PaymentTableTotalItem from './payment-table-total-item.component'
import PaymentTableItemEmpty from './payment-table-item-empty.component'
import { PaymentTableProps } from '../../../types/transaction.type'

const PaymentTable: FC<PaymentTableProps> = ({ wipId, s = {} }) => {
  const [page, setPage] = useState(1)
  const { data } = useGetTransactionList({
    s: JSON.stringify({ 'wip.id': wipId, ...s }),
    join: ['wip', 'debtor'],
    page: page,
    limit: 5,
  })
  const transactions = data?.data || []
  return (
    <Table
      headers={['TID #', 'LOGGED', 'DEBTOR', 'DEPOSIT AMOUNT', 'WITHDRAWAL', 'TYPE']}
      totalCount={data?.total || 0}
      limit={5}
      page={page}
      onPageChange={setPage}
      bodyHeight={54 * 6}
    >
      {transactions.map((transaction, index) => (
        <PaymentTableItem payment={transaction} key={`transaction-${transaction.id}-${index}`} />
      ))}
      {Array.from({ length: 5 - transactions.length }).map((_, index) => (
        <PaymentTableItemEmpty key={`table-row-empty-${index}`} />
      ))}
      {transactions.length > 0 ? <PaymentTableTotalItem totalSum={data?.total_payments || 0} /> : null}
    </Table>
  )
}

export default PaymentTable
