import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import { REACT_APP_API_URL } from '@src/lib/global-variables'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { FC } from 'react'
import { RecoverableCost, RecoverableCostListProps } from '../../types/recoverable-costs.type'
import { PaginationResponse } from '@src/types/service.type'
import RecoverableCostItem from './recoverable-cost-item.compoment'

const RecoverableCostList: FC<RecoverableCostListProps> = ({ wipId }) => {
  const fetchRecoverableCosts = async () => {
    const params = {
      s: JSON.stringify({ wip_id: wipId }),
      sort: 'created_at,DESC',
    }
    const response = await axios.get<PaginationResponse<RecoverableCost[]>>(
      `${REACT_APP_API_URL}/recoverable-costs`,
      { params },
    )
    return response.data.data
  }

  const { data } = useQuery({
    queryKey: [GLOBAL_QUERIES.RECOVERABLE_COST.GET],
    queryFn: fetchRecoverableCosts,
  })

  return (
    <div>
      {(data || []).map((recoverableCost, i) => (
        <RecoverableCostItem
          recoverableCost={recoverableCost}
          key={`recoverable-cost-item-${recoverableCost.id}-${i}`}
        />
      ))}
    </div>
  )
}

export default RecoverableCostList
