import VerticalNavBar from '@components/nav-bar/vertical-nav-bar.component'
import { FC, useState } from 'react'
import { Debtor, DebtorAssociateDetailsTab } from '../../../types/debtor.type'
import { DEBTOR_ASSOCIATE_DETAILS_FORM_NAVIGATION_TABS } from '../../../const/debtor.const'
import DebtorRelationsSection from './components/sections/debtor-relations-section/debtor-relations-section.component'
import DebtorReferencesSection from './components/sections/debtor-references-section/debtor-references-section.component'
import DebtorWorkPlacesSection from './components/sections/debtor-work-places-section/debtor-work-places-section.component'
import DebtorOthersSection from './components/sections/debtor-others-section/debtor-others-section.component'
import styles from './debtor-associate-details.module.scss'
import cls from 'classnames'

interface DebtorAssociateDetailsFormProps {
  debtor: Debtor
  direction: 'horizontal' | 'vertical'
}

const DebtorAssociateDetailsForm: FC<DebtorAssociateDetailsFormProps> = ({ debtor, direction }) => {
  const [activeValue, setActiveValue] = useState(DebtorAssociateDetailsTab.Relation)

  const handleVerticalNavBarChange = (value: string) => {
    setActiveValue(value as DebtorAssociateDetailsTab)
  }

  const getActiveTab = (value: DebtorAssociateDetailsTab) => {
    switch (value) {
      case DebtorAssociateDetailsTab.Relation:
        return <DebtorRelationsSection debtorId={debtor.id} relations={debtor.relations} />
      case DebtorAssociateDetailsTab.References:
        return <DebtorReferencesSection debtorId={debtor.id} references={debtor.references} />
      case DebtorAssociateDetailsTab.WorkPlace:
        return <DebtorWorkPlacesSection debtorId={debtor.id} workPlaces={debtor.work_places} />
      case DebtorAssociateDetailsTab.Other:
        return <DebtorOthersSection debtorId={debtor.id} others={debtor.other} />
      default:
        return null
    }
  }

  return (
    <div className={cls(styles.wrapper, { [styles.vertical]: direction === 'vertical' })}>
      <VerticalNavBar
        options={DEBTOR_ASSOCIATE_DETAILS_FORM_NAVIGATION_TABS}
        activeValue={activeValue}
        onChange={handleVerticalNavBarChange}
        position={direction === 'horizontal' ? 'vertical' : 'horizontal'}
      />
      {getActiveTab(activeValue)}
    </div>
  )
}

export default DebtorAssociateDetailsForm
