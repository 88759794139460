import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import SettingTableHead from './SettingTableHead';
import Services from '../../../shared/Services';
import { withRouter } from 'react-router';
import { UserContext } from '../../../context/user-context';

function getSorting(order, orderBy) {
  if (order === 'desc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
}

const clientStatusOption = [
  { value: '0', label: 'Prospect' },
  { value: '1', label: 'Current' },
  { value: '2', label: 'Inactive' },
  { value: '3', label: 'No Longer Operating' },
];

class ContactList extends React.Component {
  static contextType = UserContext

  constructor(props) {
    super(props);
    const {user} = this.context;

    this.state = {
      order: 'asc',
      orderBy: '',
      selected: new Map([]),
      staffData: [],
      allStaff: [],
      page: 0,
      rowsPerPage: 5,
      errorModal: false,
      errorMessage: '',
      errorType: '',
      st_id: '',
      user,
      accessErrorModal: false,
      groupTypeOption: [],
      searchData: '',
    };

    this.services = new Services();
  }

  componentDidMount() {
    this.getGroupTypes();
    // if(this.state.user && this.state.user.role === "SUPER_ADMIN")
    // {
    this.getStaff();
    this.getAllstaff();
    // }
    // else{
    //   this.setState({accessErrorModal:true})
    // }
  }

  getAllstaff() {
    let url = 'Staff/getDebtor';
    const data = {};
    this.services.getStaff(data, url, (res) => {
      if (res.status === 200) {
        const getData = res.data.data.list;
        let listStaff;
        listStaff =
          getData &&
          getData.map((item) => {
            return { value: item.id, label: `${item.first_name}  ${item.last_name}` };
          });
        this.setState({ allStaff: listStaff });
      } else if (
        res.statusCode === 401 &&
        res.responseType === 'UNAUTHORIZED'
      ) {
        this.setState({ accessErrorModal: true });
      }
    });
  }

  getStaff() {
    const data = {};
    this.services.getContactList(data, (res) => {
      if (res.status === 200) {
        this.setState({ staffData: res.data.data.list });
      } else if (
        res.statusCode === 401 &&
        res.responseType === 'UNAUTHORIZED'
      ) {
        this.setState({ accessErrorModal: true });
      }
    });
  }

  getGroupTypes() {
    this.services.getGroupTypes((res) => {
      if (res.status === 200) {
        const gType = [];
        res.data.data.forEach((ele) => {
          gType.push({ value: ele, label: ele });
        });
        this.setState({ groupTypeOption: gType });
      } else if (
        res.statusCode === 401 &&
        res.responseType === 'UNAUTHORIZED'
      ) {
        this.setState({ accessErrorModal: true });
      } else {
        this.services.errorToast(res.message);
      }
    });
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      const { data } = this.state;
      const newSelected = new Map();
      data.map((n) => newSelected.set(n.id, true));
      this.setState({ selected: newSelected });
      return;
    }
    this.setState({ selected: new Map([]) });
  };

  handleClick = (event, id, cid) => {
    const { selected } = this.state;
    const newSelected = new Map(selected);
    const value = newSelected.get(id);
    let isActive = true;
    if (value) {
      isActive = false;
    }
    newSelected.set(id, isActive);
    this.setState({ selected: newSelected });
    localStorage.setItem('contactsId', id);
    localStorage.setItem('client_Id', cid);
    this.props.history.push('/contacts');
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleDeleteSelected = () => {
    const { data } = this.state;
    let copyData = [...data];
    const { selected } = this.state;

    for (let i = 0; i < [...selected].filter((el) => el[1]).length; i += 1) {
      copyData = copyData.filter((obj) => obj.id !== selected[i]);
    }

    this.setState({ data: copyData, selected: new Map([]) });
  };

  isSelected = (id) => {
    const { selected } = this.state;
    return !!selected.get(id);
  };

  EditStaff(e, d) {
    localStorage.setItem('editId', d._id);
    this.props.history.push('/edit-staff');
  }

  DeleteStaff = () => {
    this.setState({
      errorMessage: '',
      errorModal: false,
      errorType: '',
    });
    const data = {
      client_ID: this.state.st_id,
      client_code: this.state.client_code,
      isdel: true,
    };
    this.services.editContact(data, (res) => {
      if (res.status === 200) {
        this.services.successToast('Client Deleted successfully');
        this.getStaff();
      } else if (
        res.statusCode === 401 &&
        res.responseType === 'UNAUTHORIZED'
      ) {
        this.setState({ accessErrorModal: true });
      } else {
        this.services.errorToast(res.message);
      }
    });
  };

  OpenModal = (e, d) => {
    this.setState({
      st_id: d._id,
      client_code: d.client_code,
      errorMessage: 'Are you sure you want to delete this record?',
      errorModal: true,
      errorType: 'danger',
    });
  };
  handleChange0 = (selectedOption0, d) => {
    let url = 'Staff/editDebtor';

    const data = {
      st_id: d._id,
      status: [selectedOption0.value],
    };
    this.services.editStaffStatus(data, url, (res) => {
      if (res.status === 200) {
        this.services.successToast('Status update successfully');
        this.getStaff();
      }
    });
  };

  accessErrorModalClose = () => {
    this.setState({ accessErrorModal: false });
    this.props.history.push('/dashboard');
  };

  render() {
    const { order, orderBy, selected, rowsPerPage, page } = this.state;
    const xchange = this.props.location.staffData
      ? this.props.location.staffData
      : this.state.staffData;

    return (
      <div className='container'>
        <Row>
          <Col md={12} lg={12}>
            <Row>
              <Col md={12}>
                <h3 className='page-title'>Clients</h3>
              </Col>
            </Row>
            <Card>
              <CardBody>
                <div className='material-table__wrap'>
                  <Table className='material-table'>
                    <SettingTableHead
                      numSelected={[...selected].filter((el) => el[1]).length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={this.handleSelectAllClick}
                      onRequestSort={this.handleRequestSort}
                      rowCount={xchange.length}
                    />
                    <TableBody>
                      {xchange
                        .sort(getSorting(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage,
                        )
                        .map((d, i) => {
                          const isSelected = this.isSelected(d.id);
                          const status =
                            clientStatusOption.find(
                              (_) => _.value === d.client_status,
                            ) || 'Prospect';
                          const cName = this.state.groupTypeOption.find(
                            (_) => _.value === d.real_group,
                          );
                          return (
                            <TableRow
                              className='material-table__row'
                              role='checkbox'
                              aria-checked={isSelected}
                              tabIndex={-1}
                              key={i}
                              selected={isSelected}
                            >
                              <TableCell className='material-table__cell material-table__cell-right'>
                                {d.client_code}
                              </TableCell>
                              <TableCell className='material-table__cell material-table__cell-right'>
                                {d.name}
                              </TableCell>
                              <TableCell className='material-table__cell material-table__cell-right'>
                                {d.contact_det && d.contact_det[0].email}
                              </TableCell>
                              <TableCell className='material-table__cell material-table__cell-right'>
                                {cName && cName.label ? cName.label : ''}
                              </TableCell>
                              <TableCell className='material-table__cell material-table__cell-right'>
                                {status.label ? status.label : 'Prospect'}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </div>
                <TablePagination
                  component='div'
                  className='material-table__pagination'
                  count={xchange.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                  nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                  rowsPerPageOptions={[5, 10, 15]}
                  dir='ltr'
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(ContactList);
