import * as React from "react"
const SendIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    className="E9GV5sZJIbfO_GEQ_moc _o2IXcpM0qnG3JPReKus t1I6QaLqvCmfaYJcpWU_ uq6K_W9aRWoQeVKPE1pl J3Prop_5XYo9wwjXuecz VC3CWpWtbPnKEGp4NKOW"
    viewBox="0 0 20 20"
    {...props}
  >
    <path d="M10.894 2.553a1 1 0 0 0-1.788 0l-7 14a1 1 0 0 0 1.169 1.409l5-1.429A1 1 0 0 0 9 15.571V11a1 1 0 1 1 2 0v4.571a1 1 0 0 0 .725.962l5 1.428a1 1 0 0 0 1.17-1.408l-7-14z" />
  </svg>
)
export default SendIcon
