import { useIsAuthenticated, useMsal } from "@azure/msal-react"
import { GraphApiUserService } from "../services/graph-api-user.service"
import { useQuery } from "@tanstack/react-query"
import { GLOBAL_QUERIES } from "@src/lib/global-queries"

export const useGetMe = () => {
  const isAuthenticated = useIsAuthenticated()
  const { accounts, instance } = useMsal()

  const getMe = async () => {
    const graphApiUserService = new GraphApiUserService();
    const account = accounts[0] || {};
    try {
      const authResult = await instance.acquireTokenSilent({ scopes: ['User.Read'], account: account })
      const user = await graphApiUserService.getMe(authResult.accessToken)
      return user
    } catch (e) {
      console.log(e)
    }
  }

  const query = useQuery({ queryKey: [GLOBAL_QUERIES.GRAPH_API_USER.ME], queryFn: getMe, enabled: isAuthenticated, staleTime: 1000 * 60 * 60 })

  return query;
}