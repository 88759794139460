import FormCheckbox from '@components/inputs/form-checkbox/form-checkbox.component'
import FormDatePicker from '@components/inputs/form-date-picker/form-date-picker.component'
import FormInput from '@components/inputs/form-input/form-input.component'
import FormSelect from '@components/inputs/form-select/form-select.component'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { useForm } from 'react-hook-form'
import styles from './wip-details-form.module.scss'
import { useGetWipCover } from '@containers/Recovery/Wip/hooks/get-wip-cover.hook'
import { WipCover } from '@containers/Recovery/Wip/types/wip.type'
import { FC, useEffect, useMemo } from 'react'
import {
  WipDetailsFormProps,
  WipDetailsFormValues,
  WipDetailsInitialValues,
} from '../../../types/wip-overview-forms.type'
import { WIP_DETAILS_FORM_DEFAULT_VALUES } from '@containers/Recovery/Wip/_modules/Financial/Claim/const/claim.const'
import { STATE_OPTIONS } from '@src/lib/select-options.const'

const WipDetailsForm: FC<WipDetailsFormProps> = ({ onSubmit, initialValues, loading = false }) => {
  const { data: wipCovers } = useGetWipCover()

  const transformWipCoverToOptions = (wipCovers?: WipCover[]) => {
    if (!wipCovers) {
      return []
    }
    return wipCovers.map((wipCover) => ({
      label: wipCover.title,
      value: wipCover.id,
    }))
  }

  const wipCoverOptions = useMemo(() => transformWipCoverToOptions(wipCovers?.data), [wipCovers])

  const transformInitialValues = (
    initialValues: WipDetailsInitialValues,
    wipCoverOptions: {
      label: string
      value: number
    }[],
  ): WipDetailsFormValues => {
    return {
      ...initialValues,
      cover_type:
        wipCoverOptions.find((option) => option.value === initialValues.cover_type) || null,
      state_of_origin:
        STATE_OPTIONS.find((option) => option.value === initialValues.state_of_origin) || null,
      general_filing:
        STATE_OPTIONS.find((option) => option.value === initialValues.general_filing) || null,
    }
  }

  const { control, register, handleSubmit, reset } = useForm<WipDetailsFormValues>({
    defaultValues: initialValues
      ? transformInitialValues(initialValues, wipCoverOptions)
      : WIP_DETAILS_FORM_DEFAULT_VALUES,
  })

  useEffect(() => {
    if (!initialValues || !wipCovers) {
      return
    }

    reset(transformInitialValues(initialValues, wipCoverOptions))
  }, [initialValues, wipCoverOptions])

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.formRow}>
        <FormDatePicker
          label="Date lodged"
          placeholder="Select Date"
          control={control}
          name="date_lodged"
        />
        <FormSelect
          label="Cover Type"
          control={control}
          options={wipCoverOptions}
          name="cover_type"
        />
      </div>
      <div className={styles.formRow}>
        <FormDatePicker
          label="Finalisation date"
          placeholder="Select Date"
          control={control}
          name="finalize_date"
        />
        <FormInput label="Cover as at date log" {...register('cover_as_date_log')} />
      </div>
      <div className={styles.formRow}>
        <FormSelect
          label="State of Origin"
          control={control}
          options={STATE_OPTIONS}
          name="state_of_origin"
        />
        <FormSelect
          label="General Filing"
          control={control}
          options={STATE_OPTIONS}
          name="general_filing"
        />
      </div>
      <div className={styles.distributionSection}>
        <span className={styles.distribution}>Distribution</span>
        <div className={styles.formRow}>
          <FormCheckbox label="Pay Owner Direct" />
          <FormCheckbox label="Send Reports to Owner" />
        </div>
      </div>
      <SubmitButton loading={loading} buttonType="submit">
        Save
      </SubmitButton>
    </form>
  )
}

export default WipDetailsForm
