import { REACT_APP_API_URL } from "@src/lib/global-variables";
import { PaginationResponse, Params } from "@src/types/service.type";
import axios from "axios";
import { SendShortMessageData, ShortMessage } from "../types/sms.type";

export class ShortMessageService {

  async sendShortMessage(data: SendShortMessageData) {
    const res = await axios.post<ShortMessage>(`${REACT_APP_API_URL}/short-messages`, data);
    return res.data;
  }

  async sendShortMessages(data: SendShortMessageData[]) {
    const res = await axios.post<ShortMessage[]>(`${REACT_APP_API_URL}/short-messages/bulk`, { shortMessages: data });
    return res.data;
  }

  async getMessages(params: Params = {}): Promise<PaginationResponse<ShortMessage[]>> {
    const res = await axios.get<PaginationResponse<ShortMessage[]>>(`${REACT_APP_API_URL}/short-messages`, { params })
    return res.data;
  }
}