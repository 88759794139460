import { Control, Controller, FieldValues, Path, useController } from 'react-hook-form'
import ReactDatePicker from 'react-datepicker'
import { ErrorMessage } from '@hookform/error-message'
import styles from './form-date-picker.module.scss'
import CalendarIcon from '@src/icons/calendar'
import cls from 'classnames'

interface FormDatePickerProps<T extends FieldValues> {
  label: string
  control: Control<T>
  name: Path<T>
  placeholder?: string
  size?: 'small' | 'medium' | 'large'
  inputStyle?: 'filled' | 'outlined'
  disabled?: boolean
}

const FormDatePicker = <T extends FieldValues>({
  label,
  control,
  name,
  placeholder,
  size = 'medium',
  disabled = false,
  inputStyle = 'filled',
}: FormDatePickerProps<T>) => {
  const {
    formState: { errors },
  } = useController({
    name,
    control,
  })

  return (
    <div className={styles.datePickerWrapper}>
      {label ? (
        <span className={styles.labelWrapper}>
          <span className={styles.label}>{label}</span>
          <ErrorMessage
            name={name as T[keyof T]}
            errors={errors}
            render={({ message }) => <span className={styles.error}>{message}</span>}
          />
        </span>
      ) : null}
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, onBlur, value } }) => (
          <ReactDatePicker
            showIcon
            placeholderText={placeholder}
            wrapperClassName={cls(styles.datePicker, styles[inputStyle], styles[size])}
            onChange={onChange}
            onBlur={onBlur}
            selected={value}
            disabled={disabled}
            icon={<CalendarIcon className={cls(styles.calendarIcon, styles[size])} />}
          />
        )}
      />
    </div>
  )
}

export default FormDatePicker
