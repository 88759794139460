import { FC } from 'react'
import { useCreateDebtorLegalNoteMutation } from '../../../hooks/mutations/crud-debtor-legal-note.hook'
import {
  CreateDebtorLegalNoteFormProps,
  DebtorLegalNoteFormValues,
} from '../../../types/debtor-legal-note.type'
import DebtorLegalNoteForm from './debtor-legal-note-form.component'
import { toast } from 'react-toastify'
import { useUserContext } from '@src/context/user-context'

const CreateDebtorLegalNoteForm: FC<CreateDebtorLegalNoteFormProps> = ({
  debtorLegalId,
  onSuccessSubmit,
}) => {
  const { mutateAsync, isLoading } = useCreateDebtorLegalNoteMutation()
  const { user } = useUserContext()
  const handleSubmit = async (data: DebtorLegalNoteFormValues) => {
    try {
      const res = await mutateAsync({
        data: { content: data.content, debtorLegalId, staffCreatedId: user!.id },
      })
      onSuccessSubmit && onSuccessSubmit(res)
    } catch (e) {
      toast.error('Failed to create debtor legal note')
      console.error(e)
    }
  }

  return (
    <DebtorLegalNoteForm
      loading={isLoading}
      onSuccessSubmit={handleSubmit}
      submitButtonTitle="Create Legal Note"
    />
  )
}

export default CreateDebtorLegalNoteForm
