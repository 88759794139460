export const createTimeLogSMSDamp = (obj) => {

  const timeLogSMS = JSON.stringify(obj);
  localStorage.setItem(`${obj.file_number}SMS`, timeLogSMS);
};

export const createTimeLogEmailDamp = (obj) => {

  const timeLogEmail = JSON.stringify(obj);
  localStorage.setItem(`${obj.file_number}Email`, timeLogEmail);
};

export const getTimeLogSMSDamp = (data) => {
  return  JSON.parse(localStorage.getItem(`${data}SMS`))
};

export const getTimeLogEmailDamp = (data) => {
  return  JSON.parse(localStorage.getItem(`${data}Email`))
};

export const setSendSMSDamp = (data) => {
  const SMS = JSON.parse(localStorage.getItem("sendSMS") || "[]");
  SMS.push(...data);
  const sendSMS = JSON.stringify(SMS);
  localStorage.setItem("sendSMS", sendSMS);
};

export const getSentSMSDamp =  () => {
  return  JSON.parse(localStorage.getItem("sendSMS") || "[]")
};

export const setSendEmailDamp =  (data) => {

  const Email = JSON.parse(localStorage.getItem("sendEmail") || "[]");
  Email.push(...data);
  const sendEmail = JSON.stringify(Email);
  localStorage.setItem("sendEmail", sendEmail);
};

export const getSentEmailDamp =  () => {
  return  JSON.parse(localStorage.getItem("sendEmail") || "[]")
};