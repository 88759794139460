import React from 'react';

import { Grid, Typography } from '@mui/material';
import FormInput from '../../../../../../../../../shared/components/FormInput/FormInput';

import { invoiceFormStyles } from '../../../../styles';

const BillFrom = ({ handleChange, values, errors, touched }) => {
  return (
    <Grid sx={invoiceFormStyles.billFormContainer}>
      <Typography sx={invoiceFormStyles.title}>Bill From:</Typography>

      <FormInput
        label="Name"
        placeholder="name"
        name="bill_from.name"
        changeHandler={handleChange}
        value={values?.bill_from?.name || undefined}
        error={errors?.bill_from?.name}
        touched={touched?.bill_from?.name}
        column
        disabled
      />

      <FormInput
        label="Email Address:"
        placeholder="email"
        name="bill_from.email"
        changeHandler={handleChange}
        value={values?.bill_from?.email || undefined}
        error={errors?.bill_from?.email}
        touched={touched?.bill_from?.email}
        column
        disabled
      />

      <FormInput
        label="Billing Address:"
        placeholder="Address line 1"
        name="bill_from.address_1"
        changeHandler={handleChange}
        value={values?.bill_from?.address_1 || undefined}
        error={errors?.bill_from?.address_1}
        touched={touched?.bill_from?.address_1}
        column
        disabled
      />

      <FormInput
        placeholder="Address line 2"
        name="bill_from.address_2"
        changeHandler={handleChange}
        value={values?.bill_from?.address_2 || undefined}
        error={errors?.bill_from?.address_2}
        touched={touched?.bill_from?.address_2}
        disabled
        column
      />

      <FormInput
        placeholder="Address line 3"
        name="bill_from.address_3"
        changeHandler={handleChange}
        value={values?.bill_from?.address_3 || undefined}
        error={errors?.bill_from?.address_3}
        touched={touched?.bill_from?.address_3}
        disabled
        column
      />

      <Grid sx={invoiceFormStyles.billHalfContainer}>
        <Grid sx={invoiceFormStyles.billHalf}>
          <FormInput
            label="Suburb:"
            placeholder="Suburb"
            name="bill_from.suburb"
            changeHandler={handleChange}
            value={values?.bill_from?.suburb || undefined}
            error={errors?.bill_from?.suburb}
            touched={touched?.bill_from?.suburb}
            column
            disabled
          />

          <FormInput
            label="Postcode:"
            placeholder="Postcode"
            name="bill_from.post_code"
            changeHandler={handleChange}
            value={values?.bill_from?.post_code || undefined}
            error={errors?.bill_from?.post_code}
            touched={touched?.bill_from?.post_code}
            column
            disabled
          />
        </Grid>
        <Grid sx={invoiceFormStyles.billHalf}>
          <FormInput
            label="State:"
            placeholder="State"
            name="bill_from.state"
            changeHandler={handleChange}
            value={values?.bill_from?.state || undefined}
            error={errors?.bill_from?.state}
            touched={touched?.bill_from?.state}
            column
            disabled
          />

          <FormInput
            label="Country:"
            placeholder="Country"
            name="bill_from.country"
            changeHandler={handleChange}
            value={values?.bill_from?.country || undefined}
            error={errors?.bill_from?.country}
            touched={touched?.bill_from?.country}
            column
            disabled
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BillFrom;
