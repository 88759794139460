import { Link } from 'react-router-dom'
import styles from './email-item.module.scss'
import { Checkbox } from '@mui/material'
import { format } from 'date-fns'
import StarButton from '@components/star-button/star-button.component'
import { FC } from 'react'
import { EmailItemProps } from '../../types/inbox.type'
import cls from 'classnames'

const EmailItem: FC<EmailItemProps> = ({
  id,
  senderImage,
  senderName,
  emailSubject,
  sendAt,
  isRead,
}) => {
  return (
    <li className={cls(styles.emailItemWrapper, { [styles.unRead]: !isRead })}>
      <div className={styles.actions}>
        <Checkbox />
        <StarButton />
      </div>
      <Link to={`/email/${id}`} className={styles.mailInformation}>
        <div className={styles.senderInformation}>
          <img className={styles.senderImage} src={senderImage} />
          <div className={styles.senderName}>
            <span>{senderName}</span>
          </div>
        </div>
        <div className={styles.emailSubject}>
          <span>{emailSubject}</span>
        </div>
        <div className={styles.emailDate}>
          <span>{`${format(sendAt, 'd MMMM')} at ${format(sendAt, 'HH:mm aa')}`}</span>
        </div>
      </Link>
    </li>
  )
}

export default EmailItem
