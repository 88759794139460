import { getPaymentDetailsActionEnum } from '../actionTypes';
import { getPaymentDetailsService } from '../service';

export const getPaymentDetails = () => async (dispatch) => {
  dispatch({
    type: getPaymentDetailsActionEnum.IS_LOADING_GET_PAYMENT_DETAILS,
  });
  try {
    const res = await getPaymentDetailsService();
    dispatch({
      type: getPaymentDetailsActionEnum.GET_PAYMENT_DETAILS_SUCCESS,
      payload: res,
    });

    return res;
  } catch (error) {
    dispatch({
      type: getPaymentDetailsActionEnum.GET_PAYMENT_DETAILS_ERROR,
      payload: error,
    });

    throw error;
  }
};
