import React, { useState } from 'react'
import DownIcon from 'mdi-react/ChevronDownIcon'
import { Collapse } from 'reactstrap'
import TopbarMenuLink from '../top-bar-menu-link/top-bar-menu-link.component'
import { REACT_APP_CLIENT_URL } from '@src/lib/global-variables'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { useUserContext } from '@src/context/user-context'
import NavigationButton from '@components/navigation-button/navigation-button'
import TaskIcon from '@src/icons/task'
import ArrowIcon from '@src/icons/arrow'

const Ava = `${process.env.PUBLIC_URL}/image/user.jpg`;

const TopbarProfile = () => {
  const [collapse, setCollapse] = useState(false)
  const { user, logout } = useUserContext()
  const isAuthenticated = useIsAuthenticated()
  const { instance } = useMsal()

  const handleLogout = async () => {
    if (!user) return

    if (isAuthenticated && user.azure_ad_id) {
      instance.logoutPopup({
        postLogoutRedirectUri: `${REACT_APP_CLIENT_URL}/login`,
      })
    } else {
      await logout()
    }
  }

  const toggle = () => {
    setCollapse(!collapse)
  }

  return (
    <div className="topbar__profile m-0">
      <NavigationButton
        icon={() => <img className="topbar__avatar-img" src={Ava} alt="avatar" />}
        label={`${user?.first_name} ${user?.last_name}`}
        onClick={toggle}
        isActive={collapse}
      >
        <ArrowIcon direction="bottom" />
      </NavigationButton>
      {collapse && <button className="topbar__back" type="button" onClick={toggle} />}
      <Collapse isOpen={collapse} className="topbar__menu-wrap">
        <div className="topbar__menu">
          <TopbarMenuLink title="My Profile" icon="user" path="/account/profile" onClick={toggle} />
          <TopbarMenuLink title="Manage Staff" icon="exit" path="/staff-list" onClick={toggle} />
          <div className="topbar__menu-divider" />
          <TopbarMenuLink
            title="Account Settings"
            icon="cog"
            path="/account/profile"
            onClick={toggle}
          />
          <TopbarMenuLink title="Log Out" icon="exit" path="/login" onClick={handleLogout} />
        </div>
      </Collapse>
    </div>
  )
}

export default TopbarProfile
