import styles from './send-email-form.module.scss'
import SendIcon from '@src/icons/send'
import { Controller, useForm } from 'react-hook-form'
import { SendEmailFormValues } from '../../types/send-email-page.type'
import { SEND_EMAIL_FORM_INITIAL_VALUES } from '../../const/send-email-page.const'
import { TextEditor } from '@src/shared/components/TextEditor/TextEditor'
import NavigationButton from '@components/navigation-button/navigation-button'
import { SendMessageBody } from '@containers/Inbox/types/graph-api-mail.type'
import { GraphApiMailService } from '@containers/Inbox/services/graph-api-mail.service'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { toast } from 'react-toastify'
import { joiResolver } from '@hookform/resolvers/joi'
import { sendEmailSchema } from '@containers/Inbox/schemas/send-email.schema'
import LabelWrapper from '@components/label-wrapper'
import { ErrorMessage } from '@hookform/error-message'
import { useEffect } from 'react'

const SendEmailForm = () => {
  const isAuthenticated = useIsAuthenticated()
  const { accounts, instance } = useMsal()
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<SendEmailFormValues>({
    defaultValues: SEND_EMAIL_FORM_INITIAL_VALUES,
    resolver: joiResolver(sendEmailSchema),
  })
  const onSubmit = async (data: SendEmailFormValues) => {
    if (!isAuthenticated) return

    const graphApiMailService = new GraphApiMailService()
    const account = accounts[0] || {}
    const dataToSend: SendMessageBody = {
      message: {
        toRecipients: [{ emailAddress: { address: data.to } }],
        subject: data.subject,
        body: {
          contentType: 'html',
          content: data.body,
        },
      },
    }
    console.log(dataToSend)

    try {
      const authResult = await instance.acquireTokenSilent({ account, scopes: ['Mail.Send'] })
      await graphApiMailService.sendMessage(authResult.accessToken, dataToSend)
      reset(SEND_EMAIL_FORM_INITIAL_VALUES)
      toast.success('Email sent')
    } catch (e) {
      toast.error('Something went wrong')
      console.log(e)
    }
  }

  useEffect(() => {
    console.log(accounts[0] || {})
  }, [accounts])

  return (
    <form className={styles.sendEmailForm}>
      <div className={styles.fieldWrapper}>
        <LabelWrapper label="To" control={control} name="to">
          {({ onChange, value }) => (
            <input
              className={styles.field}
              type="text"
              placeholder="To"
              onChange={onChange}
              value={value}
            />
          )}
        </LabelWrapper>
        <LabelWrapper label="Subject" control={control} name="subject">
          {({ onChange, value }) => (
            <input
              className={styles.field}
              type="text"
              placeholder="Subject"
              onChange={onChange}
              value={value}
            />
          )}
        </LabelWrapper>

        <div className={styles.bodyFiledWrapper}>
          <ErrorMessage
            errors={errors}
            name={'body'}
            render={({ message }) => {
              return <p className={styles.error}>{message}</p>
            }}
          />
          <Controller
            control={control}
            name="body"
            render={({ field }) => (
              <TextEditor value={field.value} onEditorChange={field.onChange} />
            )}
          />
        </div>
      </div>
      <NavigationButton className={styles.sendButton} label="Send" onClick={handleSubmit(onSubmit)}>
        <SendIcon className={styles.right} />
      </NavigationButton>
    </form>
  )
}

export default SendEmailForm
