import { useMutation } from "@tanstack/react-query"
import { ClaimDetailNoteService } from "../../services/claim-detail-note.service"
import { CreateClaimDetailsNoteValues } from "../../types/claim-detail.type"

export const useCreateClaimDetailNoteMutation = () => {
  const createClaimDetailNote = (data: CreateClaimDetailsNoteValues) => {
    const claimDetailNoteService = new ClaimDetailNoteService()
    return claimDetailNoteService.createClaimDetailNote(data)
  }

  const mutation = useMutation(({ data }: { data: CreateClaimDetailsNoteValues }) => createClaimDetailNote(data))

  return mutation
}