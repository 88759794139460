import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, MenuItem, Select } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: '100%',
    width: '100%',
    marginLeft: '122px',
    border: '1px solid silver',
    borderRadius: '3px',
  },
}));

const Company = ({ register, watch, companies }) => {
  const classes = useStyles();
  
  return (
    <>
      <h3>Select Company</h3>
      <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
        <span className="form__form-group-label">
          Select Company <span style={{ color: '#ff0000' }}>*</span>
        </span>
        <FormControl className={classes.formControl} style={{ margin: '0px', padding: '0px' }}>
          <Select
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
            {...register('company.id')}
            style={{ background: '#f5f9fc', borderRadius: '3px' }}
            required={true}
            defaultValue={watch('company')?.id}
          >
            {companies.data?.map((company) => (
              <MenuItem key={company.id} value={company.id}>
                {company?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </>
  );
};

export default Company;
