import { FC } from 'react'
import TableRow from '@components/table/table-row.component'
import TableCell from '@components/table/table-cell-component'
import styles from './phone-number-table.module.scss'
import {
  IPhoneNumberTableItem,
  PhoneNumberTableItemProps,
} from '@containers/Communication/types/call.type'
import { parseStringToNumber } from '@containers/Recovery/Wip/utils/format-currency'

const PhoneNumberTableItem: FC<PhoneNumberTableItemProps> = ({ data, onPhoneNumberClick }) => {
  const handlePhoneNumberClick = (data: IPhoneNumberTableItem) => {
    onPhoneNumberClick && onPhoneNumberClick(data)
  }

  const getPhoneWithoutCountryCode = (phone: string) => {
    const phoneWithoutPlus = parseStringToNumber(phone)
    return `0${phoneWithoutPlus.toString().slice(2)}`
  }

  const getPhoneLink = (phone: string) => {
    return `tel:${getPhoneWithoutCountryCode(phone)}`
  }

  return (
    <TableRow>
      <TableCell className={styles.idCell} position="eclipsed" width={50}>
        {data.id}
      </TableCell>
      <TableCell className={styles.nameCell} position="eclipsed">
        {data.name}
      </TableCell>
      <TableCell position="eclipsed" width={120}>
        {data.entityType}
      </TableCell>
      <TableCell className={styles.phoneNumberCell} position="eclipsed" width={140}>
        <a href={getPhoneLink(data.phone)} onClick={() => handlePhoneNumberClick(data)}>
          {getPhoneWithoutCountryCode(data.phone)}
        </a>
      </TableCell>
      <TableCell position="eclipsed">{data.phoneType}</TableCell>
    </TableRow>
  )
}

export default PhoneNumberTableItem
