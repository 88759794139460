import React from 'react';

const ManagementDetails = ({ register }) => {
  return (
    <>
      <h3>Enter Management Details</h3>
      <label>
        <span className="form__form-group-label">
          Property Manager <span style={{ color: '#ff0000' }}>*</span>
        </span>
        <input
          type="text"
          placeholder="Property Manager"
          required={true}
          {...register(`management_details.property_manager`)}
        />
      </label>
      <label>
        <span className="form__form-group-label">
          Email Address for Reports <span style={{ color: '#ff0000' }}>*</span>
        </span>
        <input
          type="email"
          placeholder="Email Address for Reports"
          required={true}
          {...register(`management_details.reports_email`)}
        />
      </label>
    </>
  );
};

export default ManagementDetails;
