import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import LandlordSearchSelect from '@containers/Sales/Landlord/components/landlord-search-select.component'
import { FC, useState } from 'react'
import styles from './merge-properties-form.module.scss'

interface MergePropertiesFormProps {
  onSubmit: (landlordId: number) => void
}

const MergePropertiesForm: FC<MergePropertiesFormProps> = ({ onSubmit }) => {
  const [landlordId, setLandlordId] = useState<number>()
  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (!landlordId) return
    onSubmit(landlordId)
  }
  return (
    <form className={styles.wrapper} onSubmit={handleSubmit}>
      <LandlordSearchSelect onSelect={setLandlordId} />
      <div className={styles.actionsWrapper}>
        <SubmitButton buttonType="submit">Merge Properties</SubmitButton>
      </div>
    </form>
  )
}

export default MergePropertiesForm
