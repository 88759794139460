import React from 'react'
import { Button, ButtonToolbar, Card, CardBody, Col, Spinner } from 'reactstrap'
import { Field, reduxForm } from 'redux-form'
import EyeIcon from 'mdi-react/EyeIcon'
import { withTranslation } from 'react-i18next'
import renderSelectField from '../../../../shared/components/form/Select'
import renderMultiSelectField from '../../../../shared/components/form/MultiSelect'
import Services from '../../../../shared/Services.js'
import { withRouter } from 'react-router'
import Select from 'react-select'
import qs from 'querystring'
import SimpleReactValidator from 'simple-react-validator'
import Dropzone from 'react-dropzone'
import StaffService from '../../../../services/StaffService'
import { GLOBAL_USER_ACCESS, GLOBAL_USER_POSITION } from '../../../../utils'

const renderField = ({ input, placeholder, type, meta: { touched, error } }) => (
  <div className="form__form-group-input-wrap">
    <input {...input} placeholder={placeholder} type={type} />
    {/* {touched && error && <span className="form__form-group-error">{error}</span>} */}
  </div>
)

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
}

class AddStaffForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showPassword: false,
      email: '',
      password: '',
      name: '',
      fname: '',
      lname: '',
      phone: '',
      mobile: '',
      mobile2: '',
      position: '',
      Ass_StatesData: [],
      access_type: [],
      role: [],
      profilePic: '',
      profilePhotoView: '',
      email_letter: '',
      emailPhotoView: '',
      imgOption1: '',
      email_letter_name: '',
      profileLoder: false,
      emailPhotoLoder: false,
      isSelectAllClicked: false,
    }

    this.staff = new StaffService()
    this.services = new Services()
    this.addStaff = this.addStaff.bind(this)
    this.changeInput = this.changeInput.bind(this)
    let params = qs.parse(props.location)
    this.validator = new SimpleReactValidator()
  }

  showPassword = (e) => {
    e.preventDefault()
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }))
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }))
  }

  addStaff = () => {
    let AssData = []
    this.state.Ass_StatesData.forEach((element) => {
      AssData.push(element.value.toString())
    })
    if (this.validator.allValid()) {
      const sendData = {
        email: this.state.email,
        password: this.state.password,
        name: this.state.name,
        fname: this.state.fname,
        lname: this.state.lname,
        phone: this.state.phone,
        mobile: this.state.mobile,
        mobile2: this.state.mobile2,
        position: this.state.position,
        Ass_States: JSON.stringify(AssData),
        access_type: this.state.access_type,
        role: this.state.role.value,
        display_color: 'red',
      }
      if (this.state.profilePhotoView) {
        sendData['profilePic'] = JSON.stringify(this.state.profilePic)
      }
      if (this.state.emailPhotoView) {
        sendData['email_letter'] = JSON.stringify(this.state.email_letter)
      }
      this.staff.addStaff(sendData).then((res) => {
        if (res.status === 201) {
          this.services.successToast('Staff added successfully')
          this.props.history.push('/staff-list')
        } else {
          this.services.errorToast(res.message)
        }
      })
    } else {
      this.validator.showMessages()
      this.forceUpdate()
    }
  }

  cancle = () => {
    this.props.history.push('/staff-list')
  }

  changeInput(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  profilePictureUpload = (imgOption) => {
    this.setState({ profileLoder: true })
    this.services.uploadImage(imgOption[0], (res) => {
      if (res.status === 200) {
        this.services.successToast('Profile photo upload successfully')
        this.setState({
          profilePhotoView: URL.createObjectURL(imgOption[0]),
          profilePic: res.data.data.profile,
        })
      } else {
        this.services.errorToast(res.message)
      }
      this.setState({ profileLoder: false })
    })
  }
  email_letterUpload = (imgOption1) => {
    this.setState({ emailPhotoLoder: true })
    this.services.uploadImage(imgOption1[0], (res) => {
      if (res.status === 200) {
        this.services.successToast('email letter upload successfully')
        this.setState({
          emailPhotoView: URL.createObjectURL(imgOption1[0]),
          imgOption1: imgOption1,
          email_letter: res.data.data.profile,
        })
      } else {
        this.services.errorToast(res.message)
      }
      this.setState({ emailPhotoLoder: false })
    })
  }

  handleChange = (selectedOption) => {
    this.setState({ selectedOption, access_type: [selectedOption.value] })
  }
  handleChange1 = (selectedOption1) => {
    this.setState({ role: selectedOption1 })
  }
  handleChange2 = (selectedOption2) => {
    if (selectedOption2 !== null && selectedOption2.length > 0) {
      if (
        (selectedOption2[selectedOption2.length - 1] &&
          selectedOption2[selectedOption2.length - 1].value === 'selectAll') ||
        (selectedOption2[selectedOption2.length - 1] &&
          selectedOption2[selectedOption2.length - 1].value === 'deSelectAll')
      ) {
        if (selectedOption2[selectedOption2.length - 1].value === 'selectAll') {
          this.selectAllStates()
        }

        if (selectedOption2[selectedOption2.length - 1].value === 'deSelectAll') {
          this.deSelectAllStates()
        }
      } else {
        this.setState({ Ass_StatesData: selectedOption2 })
      }
    } else {
      this.setState({ Ass_StatesData: selectedOption2 })
    }
  }

  selectAllStates = () => {
    const allStates = [
      { value: 'ACT', label: 'ACT' },
      { value: 'NSW', label: 'NSW' },
      { value: 'NT', label: 'NT' },
      { value: 'QLD', label: 'QLD' },
      { value: 'SA', label: 'SA' },
      { value: 'TAS', label: 'TAS' },
      { value: 'VIC', label: 'VIC' },
      { value: 'WA', label: 'WA' },
    ]
    this.setState({ Ass_StatesData: allStates, isSelectAllClicked: true })
  }

  deSelectAllStates = () => {
    this.setState({ Ass_StatesData: [], isSelectAllClicked: false })
  }

  removeEmailLatter = (e) => {
    e.preventDefault()
    this.setState({ email_letter: '', email_letter_name: '' })
  }
  removeProfile = (e) => {
    e.preventDefault()
    this.setState({ profilePic: '' })
  }

  render() {
    const { showPassword } = this.state

    return (
      <Col md={12} lg={12} xl={10}>
        <Card>
          <CardBody>
            <div className="card__title"></div>
            <form className="form form--horizontal" onSubmit={this.handleSubmit}>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  First Name <span style={{ color: '#ff0000' }}>*</span>
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="fname"
                    component={renderField}
                    type="text"
                    placeholder="First Name"
                    onChange={this.changeInput}
                  />
                </div>
                <span className="form__form-group-error">
                  {this.validator.message('First Name', this.state.fname, 'required')}
                </span>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  Last Name <span style={{ color: '#ff0000' }}>*</span>
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="lname"
                    component={renderField}
                    type="text"
                    placeholder="Last Name"
                    onChange={this.changeInput}
                  />
                </div>
                <span className="form__form-group-error">
                  {this.validator.message('Last Name', this.state.lname, 'required')}
                </span>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  Email Address <span style={{ color: '#ff0000' }}>*</span>
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="email"
                    component={renderField}
                    type="text"
                    placeholder="Email address"
                    onChange={this.changeInput}
                  />
                </div>
                <span className="form__form-group-error">
                  {this.validator.message('Email', this.state.email, 'required|email')}
                </span>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  User Name <span style={{ color: '#ff0000' }}>*</span>
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="name"
                    component={renderField}
                    type="text"
                    placeholder="User Name"
                    onChange={this.changeInput}
                  />
                </div>
                <span className="form__form-group-error">
                  {this.validator.message('User Name', this.state.name, 'required')}
                </span>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  Password <span style={{ color: '#ff0000' }}>*</span>
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="password"
                    component={renderField}
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Password"
                    onChange={this.changeInput}
                  />
                  <button
                    type="button"
                    className={`form__form-group-button${showPassword ? ' active' : ''}`}
                    tabIndex="-1"
                    onClick={(e) => this.showPassword(e)}
                  >
                    <EyeIcon />
                  </button>
                </div>
                <span className="form__form-group-error">
                  {this.validator.message('Password', this.state.password, 'required')}
                </span>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  Access Type <span style={{ color: '#ff0000' }}>*</span>
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="access_type"
                    component={renderSelectField}
                    type="text"
                    options={GLOBAL_USER_ACCESS}
                    onChange={this.handleChange}
                  />
                </div>
                <span className="form__form-group-error">
                  {this.validator.message('Access Type', this.state.access_type, 'required')}
                </span>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  Access Information <span style={{ color: '#ff0000' }}>*</span>
                </span>
                <div className="form__form-group-field">
                  <Select
                    name="role"
                    //  component={renderSelectField}
                    type="text"
                    value={this.state.role}
                    options={GLOBAL_USER_POSITION}
                    onChange={this.handleChange1}
                    classNamePrefix="react-select"
                    className="react-select"
                  />
                </div>
                <span className="form__form-group-error">
                  {this.validator.message('Access Information', this.state.role, 'required')}
                </span>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  Associated States <span style={{ color: '#ff0000' }}>*</span>
                </span>
                <div className="form__form-group-field">
                  <Select
                    name="Ass_States"
                    component={renderMultiSelectField}
                    type="text"
                    value={this.state.Ass_StatesData}
                    isMulti="true"
                    options={[
                      this.state.isSelectAllClicked === false ||
                      this.state.Ass_StatesData === null ||
                      (this.state.Ass_StatesData && this.state.Ass_StatesData.length === 0)
                        ? {
                            value: 'selectAll',
                            label: 'Select All',
                          }
                        : { value: 'deSelectAll', label: 'Deselect All' },
                      { value: 'ACT', label: 'ACT' },
                      { value: 'NSW', label: 'NSW' },
                      { value: 'NT', label: 'NT' },
                      { value: 'QLD', label: 'QLD' },
                      { value: 'SA', label: 'SA' },
                      { value: 'TAS', label: 'TAS' },
                      { value: 'VIC', label: 'VIC' },
                      { value: 'WA', label: 'WA' },
                    ]}
                    onChange={this.handleChange2}
                    classNamePrefix="react-select"
                    className="react-select"
                  />
                </div>
                <span className="form__form-group-error">
                  {this.validator.message(
                    'Associated States',
                    this.state.Ass_StatesData,
                    'required',
                  )}
                </span>
              </div>
              <div className="card__title">
                <h5 className="bold-text">Personal Information</h5>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  Phone <span style={{ color: '#ff0000' }}>*</span>
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="phone"
                    component={renderField}
                    type="number"
                    placeholder="Phone Number"
                    onChange={this.changeInput}
                  />
                </div>
                <span className="form__form-group-error">
                  {this.validator.message('Phone', this.state.phone, 'required')}
                </span>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  Mobile1 <span style={{ color: '#ff0000' }}>*</span>
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="mobile"
                    component={renderField}
                    type="number"
                    placeholder="Mobile Number"
                    onChange={this.changeInput}
                  />
                </div>
                <span className="form__form-group-error">
                  {this.validator.message('Mobile', this.state.mobile, 'required')}
                </span>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  Mobile2 <span style={{ color: '#ff0000' }}>*</span>
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="mobile2"
                    component={renderField}
                    type="number"
                    placeholder="Mobile Number"
                    onChange={this.changeInput}
                  />
                </div>
                <span className="form__form-group-error">
                  {this.validator.message('Mobile2', this.state.mobile2, 'required')}
                </span>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  Position <span style={{ color: '#ff0000' }}>*</span>
                </span>
                <div className="form__form-group-field">
                  <Field
                    name="position"
                    component={renderField}
                    type="text"
                    placeholder="Position"
                    onChange={this.changeInput}
                  />
                </div>
                <span className="form__form-group-error">
                  {this.validator.message('position', this.state.position, 'required')}
                </span>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Email Letterhead</span>
                <div className="form__form-group-field">
                  <div className={`dropzone dropzone--single`}>
                    {this.state.emailPhotoLoder && (
                      <Spinner
                        animation="border"
                        variant="warning"
                        className="dropzone__drop-here"
                      />
                    )}
                    <Dropzone
                      accept="image/jpeg, image/png"
                      name={'email_letter'}
                      multiple={false}
                      onDrop={(fileToUpload) => {
                        this.email_letterUpload(fileToUpload)
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()} className="dropzone__input">
                          {this.state.email_letter === '' && !this.state.emailPhotoLoder && (
                            <div className="dropzone__drop-here">
                              <span className="lnr lnr-upload" /> Drop File Here To Upload
                            </div>
                          )}
                          <input {...getInputProps()} disabled={this.state.emailPhotoLoder} />
                        </div>
                      )}
                    </Dropzone>
                    {this.state.email_letter && (
                      <aside className="dropzone__img">
                        <img src={this.state.emailPhotoView} alt="drop-img" />
                        {/* <p className="dropzone__img-name">{files[0].name}</p> */}
                        <button
                          className="dropzone__img-delete"
                          type="button"
                          onClick={(e) => this.removeEmailLatter(e)}
                        >
                          Remove
                        </button>
                      </aside>
                    )}
                  </div>
                </div>
                {/* <span className="form__form-group-error">{this.validator.message('', this.state.email_letter, 'required')}</span> */}
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Profile Photo</span>

                <div className="form__form-group-field">
                  <div className={`dropzone dropzone--single`}>
                    {this.state.profileLoder && (
                      <Spinner
                        animation="border"
                        variant="warning"
                        className="dropzone__drop-here"
                      />
                    )}
                    <Dropzone
                      accept="image/jpeg, image/png"
                      name={'profilePic'}
                      multiple={false}
                      onDrop={(fileToUpload) => {
                        this.profilePictureUpload(fileToUpload)
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()} className="dropzone__input">
                          {this.state.profilePic === '' && !this.state.profileLoder && (
                            <div className="dropzone__drop-here">
                              <span className="lnr lnr-upload" /> Drop File Here To Upload
                            </div>
                          )}
                          <input {...getInputProps()} disabled={this.state.profileLoder} />
                        </div>
                      )}
                    </Dropzone>
                    {this.state.profilePic && (
                      <aside className="dropzone__img">
                        <img src={this.state.profilePhotoView} alt="drop-img" />
                        <button
                          className="dropzone__img-delete"
                          type="button"
                          onClick={(e) => this.removeProfile(e)}
                        >
                          Remove
                        </button>
                      </aside>
                    )}
                  </div>
                </div>

                {/* <span className="form__form-group-error">{this.validator.message('', this.state.profilePic, 'required')}</span> */}
              </div>
              <ButtonToolbar className="form__button-toolbar mb-3">
                <Button color="primary" className="mb-0" type="submit" onClick={this.addStaff}>
                  Save
                </Button>
                <button type="button" className="btn-gray m-0" onClick={this.cancle}>
                  Cancel
                </button>
              </ButtonToolbar>
            </form>
          </CardBody>
        </Card>
      </Col>
    )
  }
}

export default reduxForm({
  form: 'add_staff_form_validation', // a unique identifier for this form
})(withRouter(withTranslation('common')(AddStaffForm)))
