import FormInput from '@components/inputs/form-input/form-input.component'
import styles from './property-form.module.scss'
import FormSelect from '@components/inputs/form-select/form-select.component'
import { useForm } from 'react-hook-form'
import { FC, useEffect } from 'react'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { FULL_STATE_OPTIONS } from '@src/lib/select-options.const'
import { PropertyFormProps, PropertyFormValues } from '../../../types/properties.type'

const PropertyForm: FC<PropertyFormProps> = ({
  withSubmitButton = true,
  isViewOnly = false,
  onSubmit,
  initialValues,
}) => {
  const { control, handleSubmit, register, reset } = useForm<PropertyFormValues>({
    defaultValues: initialValues,
  })

  useEffect(() => {
    if (!initialValues) return
    reset(initialValues)
  }, [initialValues])

  const innerSubmit = (data: PropertyFormValues) => {
    onSubmit && onSubmit(data)
  }

  return (
    <form className={styles.formWrapper} onSubmit={handleSubmit(innerSubmit)}>
      <FormInput label="Address Line 1" {...register('addressLine1')} disabled={isViewOnly} />
      <FormInput label="Address Line 2" {...register('addressLine2')} disabled={isViewOnly} />
      <FormInput label="Address Line 3" {...register('addressLine3')} disabled={isViewOnly} />
      <div className={styles.row}>
        <FormInput label="Suburb" {...register('suburb')} disabled={isViewOnly} />
        <FormSelect
          label="State"
          options={FULL_STATE_OPTIONS}
          control={control}
          name="state"
          disabled={isViewOnly}
        />
      </div>
      <div>
        <FormInput label="Postcode" {...register('postcode')} disabled={isViewOnly} />
        <FormInput label="Country" {...register('country')} disabled={isViewOnly} />
      </div>
      {withSubmitButton ? <SubmitButton buttonType="submit">Submit</SubmitButton> : null}
    </form>
  )
}

export default PropertyForm
