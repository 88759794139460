import { GeneralNoteService } from "@containers/Communication/services/general-note.service";
import { CreateGeneralNoteData } from "@containers/Communication/types/note.type";
import { useMutation } from "@tanstack/react-query";

export const useCreateGeneralNoteMutation = () => {
  const createGeneralNote = (data: CreateGeneralNoteData) => {
    const generalNoteService = new GeneralNoteService()
    return generalNoteService.createGeneralNote(data)
  }

  return useMutation({ mutationFn: ({ data }: { data: CreateGeneralNoteData }) => createGeneralNote(data) })
}