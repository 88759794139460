import AssignAvatar from '@components/assign-avatar'
import Assignee from '@src/icons/assignee'
import styles from './task-header.module.scss'
import { useTaskContext } from '@src/context/task-context'
import { formatAssigneName } from './utils'
import { useUserContext } from '@src/context/user-context'
import { TaskProps } from './types'

interface IProps {
  assign_to: TaskProps['assign_to']
}

const TaskHeader = ({ assign_to }: IProps) => {
  const { staff } = useTaskContext()
  const { user } = useUserContext()

  return (
    <div className={styles.wrapper}>
      {staff
        .filter(({ id }) => assign_to.includes(id))
        .map(({ id, access_level, profile_picture, last_name, first_name }) => {
          const assigneeName = formatAssigneName({
            last_name: last_name,
            first_name: first_name,
            isAssigned: id === user?.id || false,
          })

          return (
            <AssignAvatar
              key={id}
              name={assigneeName}
              avatarUrl={profile_picture}
              accessLevel={access_level}
            />
          )
        })}
      {staff.filter(({ id }) => assign_to.includes(id)).length === 0 && <Assignee />}
    </div>
  )
}

export default TaskHeader
