import TableCell from '@components/table/table-cell-component'
import { formatCurrency } from '@containers/Recovery/Wip/utils/format-currency'
import styles from './payment-table.module.scss'
import { PaymentTableTotalItemProps } from '@containers/Recovery/Wip/_modules/Financial/Payments/types/transaction.type'

const PaymentTableTotalItem = ({ totalSum }: PaymentTableTotalItemProps) => {
  return (
    <tr className={styles.tableRow}>
      <TableCell className={styles.totalPaymentCell}>Total Payments</TableCell>
      <TableCell className={styles.totalSumCell}>{formatCurrency(totalSum)}</TableCell>
      <TableCell />
    </tr>
  )
}

export default PaymentTableTotalItem
