import React from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { getCompaniesByIdData } from '../../../../../redux/selector/selector'
import CreateTaskModal from '../taskboard/modals/create-task-modal'
import CreateColumnModal from '../taskboard/modals/create-column-modal'
import { useIsAdmin } from '@src/context/user-context'
import CreateAutomationModal from '../automations/modals/create-automation-modal'

export const TabWrapper = () => {
  const [isTaskBoardActive, setIsTaskBoardActive] = React.useState(false)
  const [isCreateTaskModalOpen, setIsCreateTaskModalOpen] = React.useState(false)
  const [isCreateColumnModalOpened, setIsCreateColumnModalOpened] = React.useState(false)
  const [isCreateAutomationModalOpened, setIsCreateAutomationModalOpened] = React.useState(false)

  const isAdmin = useIsAdmin()

  const contactsId = localStorage.getItem('contactsId')
  const { name = '' } = useSelector(getCompaniesByIdData) || ({ name: '' } as any)

  const handleCreateTaskModalClose = () => {
    setIsCreateTaskModalOpen(false)
  }

  const handleCreateTaskModalOpen = () => {
    setIsCreateTaskModalOpen(true)
  }

  const handleCreateColumnModalClose = () => {
    setIsCreateColumnModalOpened(false)
  }

  const handleCreateColumnModalOpen = () => {
    setIsCreateColumnModalOpened(true)
  }

  const handleCreateAutomationModalClose = () => {
    setIsCreateAutomationModalOpened(false)
  }

  const handleCreateAutomationModalOpen = () => {
    setIsCreateAutomationModalOpened(true)
  }

  return (
    <>
      <div className="row">
        <div className="col-12">
          <h1 className="mb-3 contact_username">
            <span>{name}</span>

            {isTaskBoardActive && (
              <div className="btns">
                {isAdmin && (
                  <button onClick={handleCreateAutomationModalOpen}>Create Automation</button>
                )}
                {isAdmin && <button onClick={handleCreateColumnModalOpen}>Create Column</button>}
                <button onClick={handleCreateTaskModalOpen}>Create Task</button>
                <button>Go Back</button>
              </div>
            )}
          </h1>
        </div>
        <div className="col-12">
          <div className="tabs_nav bg-white d-flex flex-wrap justify-content-between">
            <ul className="nav nav-tabs" role="tablist">
              <li className="nav-item">
                <NavLink
                  className={(isActive) => (isActive ? 'nav-link active' : 'nav-link')}
                  to={`/contacts/${contactsId}`}
                >
                  Contacts
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={(isActive) => (isActive ? 'nav-link active' : 'nav-link')}
                  to="/contacts/account"
                >
                  Accounts
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={(isActive) => {
                    if (isActive) {
                      setIsTaskBoardActive(true)
                    } else {
                      setIsTaskBoardActive(false)
                    }
                    return isActive ? 'nav-link active' : 'nav-link'
                  }}
                  to="/contacts/task-list"
                >
                  Task Board
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={(isActive) => (isActive ? 'nav-link active' : 'nav-link')}
                  to="/contacts/notes"
                >
                  Notes
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={(isActive) => (isActive ? 'nav-link active' : 'nav-link')}
                  to="/contacts/tools"
                >
                  Tools
                </NavLink>
              </li>
              {isAdmin && (
                <li className="nav-item">
                  <NavLink
                    className={(isActive) => (isActive ? 'nav-link active' : 'nav-link')}
                    to={`/automations/${contactsId}`}
                  >
                    Automations
                  </NavLink>
                </li>
              )}
            </ul>
            <ul className="nav nav-tabs tab-right" role="tablist">
              <li className="nav-item">
                <NavLink
                  className={(isActive) => (isActive ? 'nav-link active' : 'nav-link')}
                  to="/contacts/properties-list"
                >
                  Properties
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={(isActive) => (isActive ? 'nav-link active' : 'nav-link')}
                  to="/contacts/wip-list"
                >
                  WIPs
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <CreateColumnModal
          isOpen={isCreateColumnModalOpened}
          onClose={handleCreateColumnModalClose}
        />
        <CreateAutomationModal
          isOpen={isCreateAutomationModalOpened}
          onClose={handleCreateAutomationModalClose}
        />
        <CreateTaskModal isOpen={isCreateTaskModalOpen} onClose={handleCreateTaskModalClose} />
      </div>
    </>
  )
}
