import { SelectOption } from "@src/types/component-types";
import { InterestRateCalculatorType } from "../types/interest-rate.type";

export const CALCULATOR_TYPE_OPTIONS: SelectOption<InterestRateCalculatorType>[] = [
  { label: 'Court Interest', value: InterestRateCalculatorType.CourtInterest },
  { label: 'General Interest', value: InterestRateCalculatorType.GeneralInterest },
]

export const CALCULATED_ON_OPTIONS: SelectOption<number>[] = [
  { label: 'Monthly', value: 30 },
  { label: 'Yearly', value: 365 },
]