import Table from '@components/table/table.component'
import { useGetShortMessageList } from '@containers/Communication/hooks/get-short-message-list.hook'
import { FC, useEffect, useState } from 'react'
import SmsTableItem from './sms-table-item.component'
import { SmsTableProps } from '@containers/Communication/types/communication.type'
import SmsModal from '../../modals/sms-modal/sms-modal.component'
import { useModal } from '@src/hooks/useModal'
import { ShortMessage } from '@containers/Communication/types/sms.type'

const SmsTable: FC<SmsTableProps> = ({ s, currentPage, onPageChange }) => {
  const [page, setPage] = useState(1)
  const [selectedSmsId, setSelectedSmsId] = useState<number | null>(null)
  const [isSmsModalOpen, openSmsModal, closeSmsModal] = useModal(false)
  const { data, isLoading, isRefetching } = useGetShortMessageList({
    s: s,
    page,
    limit: 5,
    join: ['staff_created', 'wips', 'companies'],
    sort: 'time_created,DESC',
  })

  useEffect(() => {
    if (!currentPage) return

    setPage(currentPage)
  }, [currentPage])

  const shortMessages = data?.data || []

  const handleItemClick = (shortMessage: ShortMessage) => {
    setSelectedSmsId(shortMessage.id)
    openSmsModal()
  }

  const isSmsLoading = isLoading || isRefetching
  const isSmsNotFound = shortMessages.length === 0 && !isSmsLoading

  return (
    <>
      <Table
        headers={['DATE', 'TIME', 'TO', 'BODY', 'SENDER']}
        totalCount={data?.total}
        limit={5}
        page={page}
        onPageChange={onPageChange}
        bodyHeight={54 * 5}
        loading={isSmsLoading}
        notFound={isSmsNotFound}
      >
        {shortMessages.map((shortMessage, index) => (
          <SmsTableItem
            shortMessage={shortMessage}
            key={`short-message-${shortMessage.id}-${index}`}
            onSmsClick={handleItemClick}
          />
        ))}
      </Table>
      <SmsModal isOpen={isSmsModalOpen} id={selectedSmsId} onClose={closeSmsModal} />
    </>
  )
}

export default SmsTable
