import React, { Component } from "react";
import { Col, Container, Row, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
class list extends Component {
  render() {
    return (
      <Container style={{ height: "100vh" }}>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Recoveries</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody className="settings-mangement">
                <div className="card__title">
                  <h5 className="bold-text">Recoveries tools</h5>
                </div>
                <Row>
                  <Col xl={3} lg={4} md={6} sm={12}>
                    <Link
                      to="/import-bank-csv"
                      className="mg_tools_box"
                    >
                       <div className='mg_icon'/>
                      <p className="mg_title">Import Bank CSV</p>
                    </Link>
                  </Col>
                  <Col xl={3} lg={4} md={6} sm={12}>
                    <Link
                      to="/recoveries/transactions"
                      className="mg_tools_box"
                    >
                      <div className='mg_icon'/>
                      <p className="mg_title">Transaction Reports</p>
                    </Link>
                  </Col>
                  <Col xl={3} lg={4} md={6} sm={12}>
                    <Link
                      to="/recoveries/month-report"
                      className="mg_tools_box"
                    >
                      <div className='mg_icon'/>
                      <p className="mg_title">Month Reports</p>
                    </Link>
                  </Col>
                  <Col xl={3} lg={4} md={6} sm={12}>
                    <Link
                      to="/recoveries/forms"
                      className="mg_tools_box"
                    >
                      <div className='mg_icon'/>
                      <p className="mg_title">Forms</p>
                    </Link>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default list;
