/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react'
import styles from './column.module.scss'
import TaskModal from './modals/task-modal'
import Task from './task'
import { ColumnProps } from './types'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import CreateTaskModal from './modals/create-task-modal'
import { TASK_TYPE_OPTIONS } from './modals/utils'
import SettingsIcon from '@src/icons/settings'
import AutomationsListModal from '../automations/modals/automations-list-modal'
import { useModal } from '@src/hooks/useModal'
import EditColumnModal from './modals/edit-column-modal'
import { useUserContext } from '@src/context/user-context'
import cls from 'classnames'

const Column = ({
  columnName = '',
  columnColor = '#000',
  tasks = [],
  id,
  isArchived,
  isUnassigned,
  filterByType = [],
  isAssigned,
  filterByStaff = [],
  filterByContact = [],
  isAutomationColumn = false,
  automationAmount = 0,
  canCreateTask = true,
}: ColumnProps) => {
  const [isTaskOpened, setIsTaskOpened] = useState(false)
  const [selectedTask, setSelectedTask] = useState<any>(null)
  const [
    isAutomationModalListOpened,
    handleAutomationListModalOpen,
    handleAutomationListModalClose,
  ] = useModal(false)

  const { user } = useUserContext()

  const [isEditColumnModalOpened, handleIsEditColumnOpen, handleIsEditColumnClose] = useModal(false)
  const [isCreateTaskModalOpened, setIsCreateTaskModalOpened] = useState(false)
  const [selectedColumnId] = useState<number>(id)

  const handleCreateTaskModalOpen = () => {
    setIsCreateTaskModalOpened(true)
  }

  const handleCreateTaskModalClose = () => {
    setIsCreateTaskModalOpened(false)
  }

  const handleTaskOpen = () => {
    setIsTaskOpened(true)
  }

  const handleTaskClose = () => {
    document.body.style.overflow = 'unset'
    setSelectedTask(null)
    setIsTaskOpened(false)
  }

  return (
    <Droppable droppableId={id.toString()}>
      {(provided) => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div className={styles.wrapper}>
            <div style={{ background: columnColor, minHeight: 4 }} />
            <div className={styles.columnName}>
              <span>{(columnName ||= '-')}</span>
              {isAutomationColumn && <span onClick={handleIsEditColumnOpen}>Edit</span>}
            </div>

            <div className={styles.tasks} {...provided.droppableProps} ref={provided.innerRef}>
              {tasks
                .filter(({ assign_attributes }) =>
                  filterByContact.length > 0
                    ? assign_attributes.some((attr) => filterByContact.includes(Number(attr)))
                    : true,
                )
                .filter(({ assign_to }) => {
                  if (filterByStaff.length === 0) {
                    return true
                  }
                  return assign_to.some((id) => filterByStaff.includes(id))
                })
                .filter(({ type }) =>
                  filterByType.length > 0
                    ? filterByType.includes(
                        Number(TASK_TYPE_OPTIONS.find((task) => task.label === type)?.value),
                      )
                    : true,
                )
                .filter(({ is_archived }) => isArchived == is_archived)
                .filter(({ assign_to }) => (isAssigned ? assign_to.includes(user?.id || 0) : true))
                .filter(({ assign_to }) =>
                  isUnassigned ? !assign_to.includes(user?.id || 0) : true,
                )
                .sort(({ position: a }, { position: b }) => a - b)
                .map((task, i) => (
                  <Draggable key={task.id} draggableId={task.id.toString()} index={i}>
                    {(p) => (
                      <>
                        <Task
                          ref={p.innerRef}
                          {...p.draggableProps}
                          {...p.dragHandleProps}
                          {...task}
                          onClick={() => {
                            setSelectedTask(task)
                            handleTaskOpen()
                          }}
                        />
                      </>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>

            <div
              onClick={canCreateTask ? handleCreateTaskModalOpen : undefined}
              className={cls(styles.columnFooterSection, {[styles.cursorAuto]: !canCreateTask})}
            >
              {canCreateTask && (
                <>
                  <span>Add task</span>
                  <AddCircleOutlineRoundedIcon sx={{ height: 14, width: 14, opacity: 0.7 }} />
                </>
              )}
            </div>
            {canCreateTask && (
              <CreateTaskModal
              column_belong_id={selectedColumnId}
              isOpen={isCreateTaskModalOpened}
              onClose={handleCreateTaskModalClose}
            />
            )}
            <TaskModal isOpen={isTaskOpened} onClose={handleTaskClose} id={selectedTask?.id || 0} />
          </div>
          {isAutomationColumn && (
            <div onClick={handleAutomationListModalOpen} className={styles.automation}>
              <SettingsIcon />
              <span>Automations ({automationAmount})</span>
            </div>
          )}
          <AutomationsListModal
            automation_column_id={id}
            isOpen={isAutomationModalListOpened}
            onClose={handleAutomationListModalClose}
          />
          <EditColumnModal
            isOpen={isEditColumnModalOpened}
            onClose={handleIsEditColumnClose}
            column_id={id}
          />
        </div>
      )}
    </Droppable>
  )
}

export default Column
