import FormSelect from '@components/inputs/form-select/form-select.component'
import { FC, useEffect } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import styles from './send-sms-form.module.scss'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import FormTextArea from '@components/inputs/form-textarea/form-textarea.component'
import cls from 'classnames'
import FormInput from '@components/inputs/form-input/form-input.component'
import { CommunicationOptionType } from '@containers/Communication/types/communication.type'
import { joiResolver } from '@hookform/resolvers/joi'
import { sendSmsSchema } from '@containers/Communication/schemas/communication.schemas'
import { SEND_TO_DEFAULT_OPTIONS } from '@containers/Communication/const/communication.const'
import {
  SendSmsFormProps,
  SendSmsFormSubmitValues,
  SendSmsFormValues,
} from '@containers/Communication/types/sms.type'

const SendSmsForm: FC<SendSmsFormProps> = ({
  templateOptions,
  sendToOptions,
  contactOptions,
  onSendToChange,
  loading = false,
  onSubmit,
  onCancelClick
}) => {
  const {
    control,
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm<SendSmsFormValues>({
    resolver: joiResolver(sendSmsSchema),
  })

  const innerSubmit = (data: SendSmsFormValues) => {
    const contact =
      typeof data.contact === 'string'
        ? data.contact
        : data.contact?.map((o) => ({
            ...o,
            value: JSON.parse(o.value),
          }))
    onSubmit({
      ...data,
      contact,
    } as SendSmsFormSubmitValues)
  }

  const sendTo = useWatch({ control, name: 'sendTo' })
  const template = useWatch({ control, name: 'template' })

  useEffect(() => {
    if (!template) return
    setValue('message', template.value)
  }, [template])

  useEffect(() => {
    if (!sendTo) return
    onSendToChange(sendTo.value)
    setValue('contact', null)
  }, [sendTo])

  return (
    <form className={styles.formWrapper} onSubmit={handleSubmit(innerSubmit)}>
      <Header>Send SMS</Header>
      <div className={styles.row}>
        <FormSelect
          control={control}
          name={'sendTo'}
          label="Send To"
          style="outlined"
          required
          options={[...sendToOptions, ...SEND_TO_DEFAULT_OPTIONS]}
        />
        <FormSelect
          control={control}
          name={'template'}
          label="Select Template"
          style="outlined"
          required
          options={templateOptions}
        />
      </div>
      {sendTo?.value !== CommunicationOptionType.Other ? (
        <FormSelect
          control={control}
          name={'contact'}
          label="Select Contact"
          style="outlined"
          isMulti
          required
          options={contactOptions.map((o) => ({ label: o.label, value: JSON.stringify(o.value) }))}
        />
      ) : null}

      {sendTo?.value === CommunicationOptionType.Other ? (
        <FormInput
          label="Phone Number"
          placeholder="Enter phone number"
          inputStyle="outlined"
          required
          error={errors.contact?.message}
          {...register('contact')}
        />
      ) : null}

      <FormTextArea
        label="Your message"
        placeholder="Write your message here"
        inputStyle="outlined"
        error={errors.message?.message}
        {...register('message')}
      />
      <div className={cls(styles.row, styles.end)}>
        <SubmitButton buttonStyle="secondary" buttonType="button" onClick={onCancelClick}>
          Cancel
        </SubmitButton>
        <SubmitButton buttonType="submit" loading={loading}>
          Send SMS
        </SubmitButton>
      </div>
    </form>
  )
}

export default SendSmsForm
