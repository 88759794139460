import { BaseModalProps, SelectOption } from "@src/types/component-types";
import { Debtor } from "./debtor.type";


export enum CallResult {
  SW = 'SW',
  'S/OFF' = 'S/OFF',
  'NHOP/P' = 'NHOP/P',
  CNBC = 'CNBC',
  DoNotRing = 'Do Not Ring',
  Disconnected = 'Disconnected',
}
export enum Relationships {
  Ar = 'Ar',
  BL = 'BL',
  Bo = 'Bo',
  Brother = 'Brother',
  Cousin = 'Cousin',
  Daughter = 'Daughter',
  DL = 'DL',
  EH = 'EH',
  EW = 'EW',
}


export interface DebtorOther {
  id: number
  name: string;
  phone: string;
  address: string;
  call_result: CallResult;
  call_note: string;
  debtor: Debtor
  debtor_id: number
  created_at: Date;
  updated_at: Date;
}

export interface DebtorWorkPlace {
  id: number
  name: string;
  contact: string;
  phone: string;
  address: string;
  call_result: CallResult;
  call_note: string;
  debtor: Debtor
  debtor_id: number
  created_at: Date;
  updated_at: Date;
}


export interface DebtorRelation {
  id: number
  name: string;
  relationship: Relationships;
  phone: string;
  address: string;
  call_result: CallResult;
  call_note: string;
  debtor: Debtor
  debtor_id: number
  created_at: Date
  updated_at: Date
}

export interface DebtorReference {
  id: number
  name: string;
  phone: string;
  address: string;
  call_result: CallResult;
  call_note: string;
  debtor: Debtor
  debtor_id: number
  created_at: Date;
  updated_at: Date;
}

export type CreateDebtorOtherData = Omit<DebtorOther, 'id' | 'created_at' | 'updated_at' | 'debtor'>
export type DebtorOtherFormValues = Omit<CreateDebtorOtherData, 'debtor_id' | 'call_result'> & { call_result: SelectOption<CallResult> }

export type CreateDebtorRelationData = Omit<DebtorRelation, 'id' | 'created_at' | 'updated_at' | 'debtor'>
export type DebtorRelationFormValues = Omit<CreateDebtorRelationData, 'debtor_id' | 'relationship' | 'call_result'> & { relationship: SelectOption<Relationships>, call_result: SelectOption<CallResult> }

export type CreateDebtorReferenceData = Omit<DebtorReference, 'id' | 'created_at' | 'updated_at' | 'debtor'>
export type DebtorReferenceFormValues = Omit<CreateDebtorReferenceData, 'debtor_id' | 'call_result'> & { call_result: SelectOption<CallResult> }

export type CreateDebtorWorkPlaceData = Omit<DebtorWorkPlace, 'id' | 'created_at' | 'updated_at' | 'debtor'>
export type DebtorWorkPlaceFormValues = Omit<CreateDebtorWorkPlaceData, 'debtor_id' | 'call_result'> & { call_result: SelectOption<CallResult> }

// props

export interface DebtorRelationTableItemProps {
  debtorId: number
  relation: DebtorRelation
  onItemClick: (debtorRelation: DebtorRelation) => void
}

export interface DebtorRelationTableProps {
  debtorId: number
  relations: DebtorRelation[]
  onItemClick: (debtorRelation: DebtorRelation) => void
}

export interface DebtorRelationsSectionProps {
  debtorId: number
  relations: DebtorRelation[]
}

export interface CreateDebtorAssociateDetailModalProps extends BaseModalProps {
  debtorId: number
}

export interface DebtorRelationFormProps {
  onSubmit: (data: DebtorRelationFormValues) => void
  loading?: boolean
  onCancelClick?: () => void
  initialValues?: DebtorRelationFormValues
}

export interface DebtorReferenceFormProps {
  onSubmit: (data: DebtorReferenceFormValues) => void
  loading?: boolean
  onCancelClick?: () => void
  initialValues?: DebtorReferenceFormValues
}

export interface DebtorWorkPlaceFormProps {
  onSubmit: (data: DebtorWorkPlaceFormValues) => void
  loading?: boolean
  onCancelClick?: () => void
  initialValues?: DebtorWorkPlaceFormValues
}

export interface DebtorOtherFormProps {
  onSubmit: (data: DebtorOtherFormValues) => void
  loading?: boolean
  onCancelClick?: () => void
  initialValues?: DebtorOtherFormValues
}

export interface DebtorWorkPlaceTableProps {
  workPlaces: DebtorWorkPlace[]
  debtorId: number
  onItemClick: (debtorWorkPlace: DebtorWorkPlace) => void
}

export interface DebtorWorkPlaceTableItemProps {
  workPlace: DebtorWorkPlace
  debtorId: number
  onItemClick: (debtorWorkPlace: DebtorWorkPlace) => void
}

export interface DebtorReferenceTableProps {
  references: DebtorReference[]
  debtorId: number
  onItemClick: (debtorReference: DebtorReference) => void
}

export interface DebtorReferenceTableItemProps {
  reference: DebtorReference
  debtorId: number
  onItemClick: (debtorReference: DebtorReference) => void
}


export interface DebtorOtherTableProps {
  others: DebtorOther[]
  debtorId: number
  onItemClick: (debtorOther: DebtorOther) => void
}

export interface DebtorOtherTableItemProps {
  other: DebtorOther
  debtorId: number
  onItemClick: (debtorOther: DebtorOther) => void
}

export interface DebtorWorkPlacesSectionProps {
  workPlaces: DebtorWorkPlace[]
  debtorId: number
}

export interface DebtorOthersSectionProps {
  others: DebtorOther[]
  debtorId: number
}

export interface DebtorReferencesSectionProps {
  references: DebtorReference[]
  debtorId: number
}

export interface CreateDebtorWorkPlaceFormProps {
  debtorId: number
  onSuccessSubmit?: (debtorWorkPlace: DebtorWorkPlace) => void
  onCancelClick?: () => void
}

export interface CreateDebtorReferenceFormProps {
  debtorId: number
  onSuccessSubmit?: (debtorReference: DebtorReference) => void
  onCancelClick?: () => void
}

export interface CreateDebtorOtherFormProps {
  debtorId: number
  onSuccessSubmit?: (debtorOther: DebtorOther) => void
  onCancelClick?: () => void
}

export interface EditDebtorWorkPlaceModalProps extends BaseModalProps {
  debtorWorkPlace: DebtorWorkPlace
  debtorId: number
}

export interface EditDebtorRelationModalProps extends BaseModalProps {
  debtorRelation: DebtorRelation 
  debtorId: number
}

export interface EditDebtorReferenceModalProps extends BaseModalProps {
  debtorReference: DebtorReference
  debtorId: number
}

export interface EditDebtorOtherModalProps extends BaseModalProps {
  debtorOther: DebtorOther
  debtorId: number
}

export interface EditDebtorWorkPlaceFormProps {
  debtorWorkPlaceId: number
  onSuccessSubmit: (debtorWorkPlace: DebtorWorkPlace) => void
  onCancelClick: () => void
  initialValues: DebtorWorkPlaceFormValues
}

export interface CreateDebtorRelationFormProps {
  debtorId: number
  onSuccessSubmit?: (debtorRelation: DebtorRelation) => void
  onCancelClick?: () => void
}

export interface EditDebtorReferenceFormProps {
  debtorReferenceId: number
  onSuccessSubmit: (debtorRelation: DebtorReference) => void
  onCancelClick: () => void
  initialValues: DebtorReferenceFormValues
}

export interface EditDebtorOtherFormProps {
  debtorOtherId: number
  onSuccessSubmit: (debtorOther: DebtorOther) => void
  onCancelClick: () => void
  initialValues: DebtorOtherFormValues
}