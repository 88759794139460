import { createWIPCommercialUncoveredFormEnum } from '../actionTypes';

export const createWIPCommercialUncoveredFormReducer = function (
  state = { data: [], loading: false, error: '' },
  action,
) {
  switch (action.type) {
    case createWIPCommercialUncoveredFormEnum.CREATE_WIP_COMMERCIAL_UNCOVERED_SUCCESS:
      return { loading: false, error: '', data: action.payload };
    case createWIPCommercialUncoveredFormEnum.IS_LOADING_CREATE_WIP_COMMERCIAL_UNCOVERED:
      return { loading: true, error: '', data: [] };
    case createWIPCommercialUncoveredFormEnum.CREATE_WIP_COMMERCIAL_UNCOVERED_ERROR:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
