import * as React from "react"

const RoundedArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    className="RWlOLn85L9w5_1l9GIaX bLH_DeiQ7Cp4iBqeW3kq"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M12.707 5.293a1 1 0 0 1 0 1.414L9.414 10l3.293 3.293a1 1 0 0 1-1.414 1.414l-4-4a1 1 0 0 1 0-1.414l4-4a1 1 0 0 1 1.414 0z"
      clipRule="evenodd"
    />
  </svg>
)
export default RoundedArrow
