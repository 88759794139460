import { FC, useEffect } from 'react'
import { RecoverableCostItemProps } from '../../types/claim.type'
import SubSection from '@containers/Recovery/Wip/components/UI/sub-section/sub-section.component'
import FormInput from '@components/inputs/form-input/form-input.component'
import { format } from 'date-fns'
import CalendarIcon from '@src/icons/calendar'
import TrashIcon from '@src/icons/trash'
import styles from './recoverable-cost-list.module.scss'
import { WipCostListsService } from '../../services/wip-cost-list.service'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import { Wip } from '@containers/Recovery/Wip/types/wip.type'
import { getWipQueryKey } from '@containers/Recovery/Wip/utils/wip-query-factory'
import { formatCurrency, parseStringToNumber } from '@containers/Recovery/Wip/utils/format-currency'
import { useForm } from 'react-hook-form'
import CurrencyFormInput from '@components/inputs/form-input/currency-form-input.component'
import cls from 'classnames'
import EditIcon from '@src/icons/edit'

interface RecoverableCostFormValues {
  description: string
  unenforceableCostAmount: string
  recoverableCostAmount: string
}

const RecoverableCostListItem: FC<RecoverableCostItemProps> = ({ recoverableCost }) => {
  const queryClient = useQueryClient()
  const { register, control, reset, handleSubmit } = useForm<RecoverableCostFormValues>({
    defaultValues: {
      description: recoverableCost.description,
      unenforceableCostAmount: formatCurrency(recoverableCost.unenforceableCostAmount),
      recoverableCostAmount: formatCurrency(recoverableCost.recoverableCostAmount),
    },
  })

  useEffect(() => {
    reset({
      description: recoverableCost.description,
      unenforceableCostAmount: formatCurrency(recoverableCost.unenforceableCostAmount),
      recoverableCostAmount: formatCurrency(recoverableCost.recoverableCostAmount),
    })
  }, [recoverableCost])

  const handleRemoveClick = async () => {
    const wipCostListsService = new WipCostListsService()
    try {
      const res = await wipCostListsService.removeFee(recoverableCost.id)
      queryClient.invalidateQueries({ queryKey: [GLOBAL_QUERIES.RECOVERABLE_COST.GET] })
      queryClient.setQueryData<Wip>(getWipQueryKey(recoverableCost.wip_id), (prevData) =>
        prevData
          ? {
              ...prevData,
              recoverable_costs_total: res.recoverable_costs_total,
              unenforceable_costs_total: res.unenforceable_costs_total,
            }
          : prevData,
      )
      toast.success('Recoverable cost removed')
    } catch (error) {
      console.error(error)
      toast.error('Failed to remove recoverable cost')
    }
  }

  const onSubmit = async (data: RecoverableCostFormValues) => {
    const wipCostListsService = new WipCostListsService()
    try {
      const res = await wipCostListsService.updateFee(recoverableCost.id, {
        recoverableCost: parseStringToNumber(data.recoverableCostAmount),
        unenforceableCost: parseStringToNumber(data.unenforceableCostAmount),
      })
      queryClient.invalidateQueries({ queryKey: [GLOBAL_QUERIES.RECOVERABLE_COST.GET] })
      queryClient.setQueryData<Wip>(getWipQueryKey(recoverableCost.wip_id), (prevData) =>
        prevData
          ? {
              ...prevData,
              recoverable_costs_total: res.recoverable_costs_total,
              unenforceable_costs_total: res.unenforceable_costs_total,
            }
          : prevData,
      )
      toast.success('Recoverable cost updated')
    } catch (error) {
      console.error(error)
      toast.error('Failed to update recoverable cost')
    }
  }

  return (
    <SubSection flex p={4}>
      <form className={styles.formWrapper} onSubmit={handleSubmit(onSubmit)}>
        <FormInput label="Description" disabled {...register('description')} />
        <div className={styles.inputWrapper}>
          <label className={styles.labelWrapper}>
            <label className={styles.label}>Amount</label>
          </label>
          <div className={styles.formInput}>
            <CurrencyFormInput
              className={cls(styles.customCurrencyInput, styles.unenforceableCost)}
              control={control}
              name="unenforceableCostAmount"
            />
            {' | '}
            <CurrencyFormInput
              className={cls(styles.customCurrencyInput, styles.recoverableCost)}
              control={control}
              name="recoverableCostAmount"
            />
          </div>
        </div>

        <FormInput
          icon={CalendarIcon}
          label="Created at"
          disabled
          value={format(recoverableCost.created_at, 'dd/MM/yyyy')}
        />
        <button className={styles.removeButton} type="submit">
          <EditIcon />
        </button>
        <button className={styles.removeButton} onClick={handleRemoveClick} type="button">
          <TrashIcon />
        </button>
      </form>
    </SubSection>
  )
}

export default RecoverableCostListItem
