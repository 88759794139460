import TableCell from '@components/table/table-cell-component'
import TableRow from '@components/table/table-row.component'
import { WipAttachment } from '@containers/Recovery/Wip/types/wip.type'
import DownloadIcon from '@src/icons/download'
import { REACT_APP_API_URL } from '@src/lib/global-variables'
import { BaseListItemProps } from '@src/types/component-types'
import { format } from 'date-fns'
import { FC } from 'react'
import styles from './attachment-table.module.scss'

export const AttachmentTableItem: FC<BaseListItemProps<WipAttachment>> = ({ entity }) => {
  const { id, originalName, wipId, createdAt } = entity

  return (
    <TableRow>
      <TableCell position="eclipsed" width={120}>
        {format(createdAt, 'MMM dd yyyy')}
      </TableCell>
      <TableCell position="eclipsed" width={100}>
        {format(createdAt, 'HH:mm aa')}
      </TableCell>
      <TableCell position="eclipsed">{originalName}</TableCell>
      <TableCell position="center" width={80}>
        <a href={`${REACT_APP_API_URL}/wips/${wipId}/attachments/download/${id}`} download>
          <DownloadIcon className={styles.downloadIcon} />
        </a>
      </TableCell>
    </TableRow>
  )
}
