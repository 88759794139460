import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { GLOBAL_QUERIES } from '@src/lib/global-queries';
import { GraphApiMailFoldersService } from '../services/graph-api-mail-folders.service';
import { useQuery } from '@tanstack/react-query';
import { BaseGraphApiParams } from '../types/graph-api-mail.type';

export const useGetMailFolders = (params: BaseGraphApiParams = {}) => {
  const isAuthenticated = useIsAuthenticated()
  const { instance, accounts } = useMsal()

  const getMailFolders = async () => {
    const graphApiMailFoldersService = new GraphApiMailFoldersService();
    const account = accounts[0] || {};
    try {
      const token = await instance.acquireTokenSilent({ scopes: ['Mail.ReadBasic', 'Mail.Read', 'Mail.ReadWrite'], account: account })
      const emailFolders = await graphApiMailFoldersService.getMailFolders(token.accessToken, params)
      return emailFolders
    }
    catch (e) {
      console.log(e)
      throw e
    }
  }

  const query = useQuery({ queryKey: [GLOBAL_QUERIES.MAIL_FOLDER.GET, params], queryFn: getMailFolders, enabled: isAuthenticated, staleTime: 1000 * 60 * 5, retry: 2 })

  return query;
}