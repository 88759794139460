import FormSelect from '@components/inputs/form-select/form-select.component'
import SubSection from '@containers/Recovery/Wip/components/UI/sub-section/sub-section.component'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import { Controller, useForm, useWatch } from 'react-hook-form'
import styles from './debtor-details-phone-form.module.scss'
import { FC, useEffect } from 'react'
import { useUpdateDebtorContactMutation } from '@containers/Recovery/Wip/_modules/Debtors/hooks/mutations/update-debtor-contact.hook'
import { toast } from 'react-toastify'
import { transformPhoneEmptyStringToNull } from '@containers/Recovery/Wip/_modules/Debtors/utils/transform-debtor-to-values.utils'
import { useQueryClient } from '@tanstack/react-query'
import { Debtor } from '@containers/Recovery/Wip/_modules/Debtors/types/debtor.type'
import { getDebtorQueryKey } from '@containers/Recovery/Wip/_modules/Debtors/utils/debtor-query-key-factory.utils'
import {
  DebtorDetailsPhoneFormProps,
  DebtorDetailsPhoneFormValues,
  DebtorPhones,
} from '@containers/Recovery/Wip/_modules/Debtors/types/debtor-form.type'
import {
  PHONE_OPTIONS,
  PHONE_SELECT_OPTIONS,
  REQUIRED_PHONE_OPTIONS,
} from '@containers/Recovery/Wip/_modules/Debtors/const/debtor.const'
import CustomPhoneInput from '@components/inputs/form-input/custom-phone-input/custom-phone-input.component'
import { joiResolver } from '@hookform/resolvers/joi'
import { debtorDetailsPhoneSchema } from '@containers/Recovery/Wip/_modules/Debtors/schemas/debtor.schemas'

const DebtorDetailsPhoneForm: FC<DebtorDetailsPhoneFormProps> = ({
  debtorId,
  debtorContactId,
  initialValues,
}) => {
  const queryClient = useQueryClient()
  const { mutateAsync, isLoading } = useUpdateDebtorContactMutation()
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<DebtorDetailsPhoneFormValues>({
    resolver: joiResolver(debtorDetailsPhoneSchema),
    defaultValues: {
      selectedPhoneNumber: null,
      phones: initialValues,
    },
  })

  const handleAddNewPhone = () => {
    if (!selectedPhoneNumber) return
    setValue(`phones.${selectedPhoneNumber.value}`, '+610')
    setValue('selectedPhoneNumber', null)
  }

  const onSubmit = async (data: DebtorDetailsPhoneFormValues) => {
    const phones = data.phones

    try {
      const validPhones = transformPhoneEmptyStringToNull(phones)
      const transformedPhones: DebtorPhones = { ...validPhones }
      const contact = await mutateAsync({
        id: debtorContactId,
        debtorContact: transformedPhones,
      })
      queryClient.setQueryData<Debtor>(getDebtorQueryKey(debtorId), (prevData) => ({
        ...(prevData as unknown as Debtor),
        contact,
      }))
      toast.success('Debtor updated successfully')
    } catch (e) {
      console.log(e)
      toast.error('Failed to update debtor')
    }
  }

  const selectedPhoneNumber = useWatch({ control, name: 'selectedPhoneNumber' })
  const phones = useWatch({ control, name: 'phones' })

  useEffect(() => {
    setValue('phones', initialValues)
  }, [initialValues])

  return (
    <form className={styles.formWrapper} onSubmit={handleSubmit(onSubmit)}>
      <Header>Phone</Header>
      <SubSection className={styles.subSectionWrapper}>
        <div className={styles.phoneSelectWrapper}>
          <FormSelect
            control={control}
            name="selectedPhoneNumber"
            label="Add Phone Number Type"
            options={PHONE_SELECT_OPTIONS.filter((option) => phones[option.value] === null)}
          />
        </div>
        <SubmitButton onClick={handleAddNewPhone}>Add Phone Number</SubmitButton>
      </SubSection>

      <div className={styles.phoneInputsWrapper}>
        {PHONE_OPTIONS.map((option) =>
          phones[option.value] !== null ? (
            <Controller
              name={`phones.${option.value}`}
              key={option.value}
              control={control}
              render={({ field }) => (
                <CustomPhoneInput
                  clearType={
                    REQUIRED_PHONE_OPTIONS.find((required) => required.value === option.value)
                      ? 'emptyString'
                      : 'null'
                  }
                  error={errors?.phones?.[option.value]?.message}
                  value={field.value || undefined}
                  onChange={(value) =>
                    field.onChange(value.originalValue === undefined ? null : value.formattedPhone)
                  }
                  label={option.label}
                />
              )}
            />
          ) : null,
        )}
      </div>
      <div className={styles.submitButtonWrapper}>
        <SubmitButton buttonType="submit" loading={isLoading}>
          Save
        </SubmitButton>
      </div>
    </form>
  )
}

export default DebtorDetailsPhoneForm
