import { useDropzone } from 'react-dropzone'
import styles from '../upload-multiple-properties-form.module.scss'
import { Header, SubHeader, Text } from '@containers/Recovery/Wip/components/UI/text/text.component'
import { FC, useMemo } from 'react'
import SecondaryTable from '../../../tables/secondary-table/secondary-table.component'
import TableRow from '@components/table/table-row.component'
import TableCell from '@components/table/table-cell-component'
import secondaryTableStyles from '../../../tables/secondary-table/secondary-table.module.scss'
import cls from 'classnames'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { UploadFileStepProps } from '@containers/Sales/Properties/types/property.type'
import { toast } from 'react-toastify'
import * as XLSX from 'xlsx'

const baseStyle: React.CSSProperties = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 8,
  borderColor: '#CCCCD7',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  height: '100%',
  color: '#2D3748',
  outline: 'none',
  transition: 'border .24s ease-in-out',
}

const focusedStyle = {
  borderColor: '#2196f3',
}

const acceptStyle = {
  borderColor: '#00e676',
}

const rejectStyle = {
  borderColor: '#ff1744',
}

const ExampleDataTable = () => {
  return (
    <div className={styles.exampleTableWrapper}>
      <SecondaryTable>
        <TableRow
          className={cls(secondaryTableStyles.secondaryTableRow, secondaryTableStyles.header)}
        >
          <TableCell position="eclipsed">OWNER NAME</TableCell>
          <TableCell position="eclipsed">OWNER EMAIL</TableCell>
          <TableCell position="eclipsed">PROPERTY</TableCell>
          <TableCell position="eclipsed">STATE</TableCell>
          <TableCell position="eclipsed">POST CODE</TableCell>
          <TableCell position="eclipsed">COVER DATE</TableCell>
        </TableRow>
        <TableRow className={secondaryTableStyles.secondaryTableRow}>
          <TableCell position="eclipsed">Segun Adebayo</TableCell>
          <TableCell position="eclipsed">email@email.com</TableCell>
          <TableCell position="eclipsed">6 Fimister Lane, HANNANS</TableCell>
          <TableCell position="eclipsed">WA</TableCell>
          <TableCell position="eclipsed">4055</TableCell>
          <TableCell position="eclipsed">25-04-23</TableCell>
        </TableRow>
      </SecondaryTable>
    </div>
  )
}

const UploadFileStep: FC<UploadFileStepProps> = ({ onSuccessfulUpload }) => {

  const handleSuccessfulUpload = async (file: File) => {
    const reader = new FileReader()
    reader.readAsArrayBuffer(file)

    reader.onload = (e) => {
      const result = e.target?.result

      if (!result) {
        toast.error('Error reading file')
        return
      }

      try {
        const workbook = XLSX.read(result, {
          type: 'buffer',
        })

        const jsonSheet = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {
          defval: '',
        }) as Record<string, string>[]

        if (jsonSheet.length === 0) {
          toast.error('No data found in file')
          return
        }

        onSuccessfulUpload(jsonSheet)
      } catch (e) {
        toast.error('Error reading file')
      }
    }

    reader.onerror = () => {
      toast.error('Error reading file')
    }
  }


  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: ['.xlsx', '.xls', '.csv'],
    onDropAccepted: (acceptedFiles) => {
      handleSuccessfulUpload(acceptedFiles[0])
    },
  })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  )

  return (
    <div className={styles.stepWrapper}>
      <Header mb={4}>Upload File</Header>
      <div>
        <SubHeader fontSize="large">Data that we expect:</SubHeader>
        <Text fontSize="large" mb={4}>
          (You will have a chance to rename or remove columns in next steps)
        </Text>
      </div>

      <ExampleDataTable />

      <div className={styles.dropzoneWrapper}>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <SubHeader>Upload .xlsx, .xls or .csv file</SubHeader>
          <SubmitButton buttonStyle="highlighted" size="large">
            Select File
          </SubmitButton>
        </div>
      </div>
    </div>
  )
}

export default UploadFileStep
