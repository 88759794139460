import { FC } from 'react'
import { DebtorLegal, DebtorLegalClaimNumber } from '../../../types/debtor-legal.type'
import SubSection from '@containers/Recovery/Wip/components/UI/sub-section/sub-section.component'
import { SubHeader, Text } from '@containers/Recovery/Wip/components/UI/text/text.component'
import TrashIcon from '@src/icons/trash'
import styles from './debtor-claim-number-list.module.scss'
import EditIcon from '@src/icons/edit'
import { useQueryClient } from '@tanstack/react-query'
import { getDebtorLegalByIdQueryKey } from '../../../utils/legal-query-key-factory.utils'
import { toast } from 'react-toastify'
import { BaseListItemPropsWithEdit } from '@src/types/component-types'
import { useDeleteDebtorLegalClaimNumberMutation } from '../../../hooks/mutations/crud-debtor-legal-claim-number.hook'

const DebtorLegalClaimNumberListItem: FC<BaseListItemPropsWithEdit<DebtorLegalClaimNumber>> = ({
  entity: debtorClaimNumber,
  onEditClick,
}) => {
  const { mutateAsync } = useDeleteDebtorLegalClaimNumberMutation()
  const queryClient = useQueryClient()

  const handleDeleteClick = async () => {
    try {
      await mutateAsync({ debtorClaimNumberId: debtorClaimNumber.id })
      queryClient.setQueryData<DebtorLegal>(
        getDebtorLegalByIdQueryKey(debtorClaimNumber.debtorLegalId),
        (prevData) =>
          prevData
            ? {
                ...prevData,
                claimNumbers: prevData.claimNumbers.filter((cn) => cn.id !== debtorClaimNumber.id),
              }
            : undefined,
      )
    } catch (error) {
      toast.error('Failed to delete claim number')
      console.error(error)
    }
  }

  return (
    <SubSection className={styles.listItemWrapper} p={4}>
      <div className={styles.listItemContent}>
        <SubHeader>Claim Number:</SubHeader>
        <Text>{debtorClaimNumber.claimNumber}</Text>
      </div>
      <div className={styles.row}>
        <button className={styles.actionButton} onClick={() => onEditClick(debtorClaimNumber)}>
          <EditIcon />
        </button>
        <button onClick={handleDeleteClick} className={styles.actionButton}>
          <TrashIcon />
        </button>
      </div>
    </SubSection>
  )
}

export default DebtorLegalClaimNumberListItem
