import Modal from '@components/modal'
import styles from './edit-column-modal.module.scss'

import { useForm, useWatch } from 'react-hook-form'

import LabelWrapper from '@components/label-wrapper'

import { useTaskContext } from '@src/context/task-context'
import { REACT_APP_API_URL } from '@src/lib/global-variables'
import { toast } from 'react-toastify'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import Select from 'react-select'
import { IOption } from '../types'
import cls from 'classnames'
import { useEffect } from 'react'
import axios from 'axios'
import { AUTOMATION_COLUMN_CUSTOM_DATES, COLUMN_COLORS } from './utils'
import { useLocation } from 'react-router'

interface IProps {
  isOpen: boolean
  onClose: () => void
  column_id: number | null
}

interface ICreateFrom {
  title: string
  description: string
  columnColor: IOption | null
  clientId: number | null
  mode: string
  order: number | null
  step: number | null
  wip_id: number | null
  is_cron: boolean
  cron_date: Date | null
  amount_of_cron_days: number | null
}

const defaultValues: ICreateFrom = {
  description: '',
  title: '',
  columnColor: null,
  clientId: null,
  mode: '',
  order: null,
  step: null,
  wip_id: null,
  cron_date: null,
  is_cron: false,
  amount_of_cron_days: null,
}

const EditColumnModal = ({ isOpen, onClose = () => null, column_id }: IProps) => {
  if (!column_id) return null
  const { taskMode, selectedCompanyState,isRecovery, currentWip } = useTaskContext()
  const queryClient = useQueryClient()
  const location = useLocation()
  const clientId = Number(location?.pathname?.split('/')[2])

  useQuery(
    [GLOBAL_QUERIES.COLUMNS.GET_COLUMN_BY_ID, column_id],
    () => axios.get(`${REACT_APP_API_URL}/board-columns/${column_id}`),
    {
      enabled: !!column_id && isOpen,
      select: (data) => data.data,
      onSuccess: (data) => {
        reset({
          ...data,
          columnColor: COLUMN_COLORS.find(({ value }) => value === data.columnColor),
        })
      },
    },
  )
  const { control, handleSubmit, reset, setValue } = useForm<ICreateFrom>({
    defaultValues,
    mode: 'all',
  })
  const { columnColor, amount_of_cron_days } = useWatch({ control })
  const setCustomTime = (time: number) => {
    setValue('amount_of_cron_days', time)
  }

  const onSubmit = async (data: ICreateFrom) => {
    try {
      await axios.patch(`${REACT_APP_API_URL}/board-columns/${column_id}`, {
        ...data,
        columnColor: data.columnColor?.value,
        clientId: Number(selectedCompanyState?.id) || clientId,
        mode: taskMode,
        wip_id: currentWip ? Number(currentWip) : null,
        is_cron: isRecovery ? true : false,
        amount_of_cron_days: isRecovery ? Number(amount_of_cron_days) : null,
      })
      toast.success('Column updated successfully')
    } catch (error) {
      console.log(error)
      toast.error('Something went wrong')
    } finally {
      onClose()
    }
    queryClient.invalidateQueries([GLOBAL_QUERIES.TASKSERVICE.GET, taskMode])
    queryClient.invalidateQueries([GLOBAL_QUERIES.COLUMNS.GET])
  }

  const handleDeleteColumn = async () => {
    try {
      await axios.delete(`${REACT_APP_API_URL}/board-columns/${column_id}`)
      toast.success('Column deleted successfully')
      queryClient.invalidateQueries([GLOBAL_QUERIES.TASKSERVICE.GET, taskMode])
      onClose()
    } catch (error) {
      console.log(error)
      toast.error('Something went wrong')
    }
  }

  useEffect(() => {
    if (!isOpen) {
      reset(defaultValues)
    }
  }, [isOpen])

  return (
    <Modal isOpen={isOpen} onClose={onClose} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.wrapper} onClick={(e) => e.stopPropagation()}>
        <div>
          <span>Edit Column</span>
          <span onClick={handleDeleteColumn}>Delete Colunn</span>
        </div>
        <div className={styles.select}>
          <LabelWrapper<ICreateFrom>
            className={styles.labelWrapper}
            control={control}
            name={'title'}
            label="Column Title"
          >
            {({ onChange, value }) => {
              return (
                <input
                  type="text"
                  className="react-select"
                  placeholder="Task Template 1. Title Example"
                  value={value as string}
                  onChange={onChange}
                />
              )
            }}
          </LabelWrapper>
        </div>
        <div className={styles.select}>
          <LabelWrapper<ICreateFrom>
            className={styles.labelWrapper}
            control={control}
            name={'description'}
            label="Column Description"
          >
            {({ onChange, value }) => {
              return (
                <textarea
                  className="react-select"
                  placeholder="Description Example"
                  value={value as string}
                  onChange={onChange}
                />
              )
            }}
          </LabelWrapper>
        </div>
        {isRecovery && (
          <div className={styles.dayIndicator}>
            <span>Selected cron time job interval: {amount_of_cron_days}</span>
          </div>
        )}
        {isRecovery && (
          <>
            <div className={cls(styles.selectTime)}>
              <div>
                {' '}
                {AUTOMATION_COLUMN_CUSTOM_DATES.map((dayInterval) => {
                  return (
                    <div
                      onClick={() => setCustomTime(dayInterval)}
                      className={cls(styles.customDate, {
                        [styles.activeDate]: dayInterval === amount_of_cron_days,
                      })}
                      key={dayInterval}
                    >
                      {dayInterval} {dayInterval > 1 ? 'Days' : 'Day'}
                    </div>
                  )
                })}
              </div>
              <LabelWrapper<ICreateFrom>
                className={cls(styles.labelWrapper, styles.addCustomTime)}
                control={control}
                name={'amount_of_cron_days'}
                label="Custom amount"
              >
                {({ onChange, value }) => {
                  return (
                    <input
                      type="number"
                      className="react-select"
                      value={value as number}
                      onChange={onChange}
                    />
                  )
                }}
              </LabelWrapper>
            </div>
          </>
        )}
        <div className={styles.select}>
          <LabelWrapper<ICreateFrom>
            className={styles.labelWrapper}
            control={control}
            name={'columnColor'}
            label="Column Color"
          >
            {({ onChange, value }) => {
              return (
                <Select
                  className="react-select"
                  placeholder="Column Color"
                  value={value as string}
                  onChange={onChange}
                  options={
                    [
                      { label: 'Red', value: '#FF0000' },
                      { label: 'Skyline Blue', value: '#44a6f5' },
                      { label: 'Coral Blush', value: '#FF9784' },
                      { label: 'Mint Garden', value: '#82C786' },
                    ] as IOption[]
                  }
                />
              )
            }}
          </LabelWrapper>
        </div>
        {columnColor && (
          <div className={styles.colorContainer}>
            <span>
              {' '}
              Selected color:{' '}
              <span
                className={cls(styles.text, {
                  [styles[
                    columnColor.label?.split(' ').join('').toLowerCase() as keyof typeof styles
                  ]]: columnColor,
                })}
              >
                {' '}
                {columnColor.label}
              </span>
            </span>
            <span
              className={cls(styles.border, {
                [styles[
                  columnColor.label?.split(' ').join('').toLowerCase() as keyof typeof styles
                ]]: columnColor,
              })}
            ></span>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default EditColumnModal
