import React, { Component } from 'react';
import { Col, Row, Modal, Form } from 'reactstrap';
import Select from "react-select";
import DatePicker from "react-datepicker";
import SimpleReactValidator from "simple-react-validator";
//import ModalComponent from "../../shared/components/ModalCustom";
import moment from "moment";
import Services from "../../../shared/Services";
import Geosuggest from 'react-geosuggest';
import { withRouter } from 'react-router';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';


var dayAppointmentList = [{ value: '8:00', label: "8:00" }, { value: '8:30', label: "8:30" },
{ value: '9:00', label: "9:00" }, { value: '9:30', label: "9:30" },
{ value: '10:00', label: "10:00" }, { value: '10:30', label: "10:30" },
{ value: '11:00', label: "11:00" }, { value: '11:30', label: "11:30" },
{ value: '12:00', label: "12:00" }, { value: '12:30', label: "12:30" },
{ value: '13:00', label: "13:00" }, { value: '13:30', label: "13:30" },
{ value: '14:00', label: "14:00" }, { value: '14:30', label: "14:30" },
{ value: '15:00', label: "15:00" }, { value: '15:30', label: "15:30" },
{ value: '16:00', label: "16:00" }, { value: '16:30', label: "16:30" },
{ value: '17:00', label: "17:00" }, { value: '17:30', label: "17:30" }]


class ViewTaskModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // id:localStorage.getItem("contactsId"),
            task_id: '',
            task_type: { value: 'Meeting', label: 'Meeting' },
            wip_no: '',
            title: '',
            internal: false,
            staff: '',
            client: '',
            sDate: this.props.newDate ? this.props.newDate : new Date(),
            sTimeH: '',
            sTimeM: '',
            timeTypeS: { value: 'AM', label: 'AM' },
            eDate: '',
            eTimeH: '',
            eTimeM: '',
            timeTypeE: { value: 'AM', label: 'AM' },
            rType: { value: 'None', label: 'None' },
            priority: '',
            eSubject: '',
            eDescription: '',
            location: '',
            guest: '',
            smaReminder: { value: '0', label: '0' },
            dayAppointment: { value: '8:00', label: "8:00" },
            useEnddate: false,
            allClient: [],
            allStaff: [],
            textSMS: '',
            toNumber: '',
            isEdit: false,
            mainType: this.props.mainType || "RECOVERY",
            status: { value: 'To Do', label: 'To Do' },
            startTime: '',
            endTime: '',
            taskSubject: '',
            allDataStaff: '',
            calanderType: { value: 'Appointment', label: 'Appointment' },
            meet_with: '',
        }
        this.services = new Services();
        this.changeInput = this.changeInput.bind(this);
        this.saveTask = this.saveTask.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.validator = new SimpleReactValidator();
        this.validatorEmail = new SimpleReactValidator();
        this.validatorSMS = new SimpleReactValidator();
    }
    componentDidMount() {
        if(this.props.isView){
           this.addRecord() 
        }else{
            this.getAllClient();
            this.getAllstaff();
        }
       
    }
    addRecord() {
        if (this.props.data) {
            const { data, isEdit } = this.props
            var startDate = new Date();
            var endDate = new Date();
            if (data.start_date) {
                var date = moment(data.start_date).format("MM-DD-YY")

                if (date === 'Invalid date') {
                    startDate = new Date(data.start_date.slice(0, data.start_date.length - 2).replace(/\//g, "-"));
                } else {
                    startDate = new Date(date)
                }
            } else {
                startDate = new Date();
            }
            if (startDate === 'Invalid Date' || date === 'Invalid date') {
                startDate = new Date();
            }
            if (data.end_date) {
                var date = moment(data.end_date).format("MM-DD-YY")
                if (date === 'Invalid date') {
                    endDate = new Date(data.end_date.slice(0, data.end_date.length - 2).replace(/\//g, "-"));
                } else {
                    endDate = new Date(date)
                }
            } else {
                endDate = new Date();
            }
            if (endDate === 'Invalid Date' || date === 'Invalid date') {
                endDate = new Date();
            }
            var startTime = ""
            if (this.props.data.ev_start_time) {
                var str = moment()
                var time = this.props.data.ev_start_time.split(":")
                if (time && time.length > 1) {
                    startTime = str.set({ h: time[0], m: time[1].split(':')[0], a: time[1].split('-')[1] });
                }
            }
            var endTime = ""
            if (this.props.data.ev_end_time) {
                var str = moment()
                var time = this.props.data.ev_end_time.split(":")
                if (time && time.length > 1) {
                    endTime = str.set({ h: time[0], m: time[1].split(':')[0], a: time[1].split('-')[1] });
                }
            }
            var selectedStaff = [];
            selectedStaff = data.staff && data.staff.map(s => {
                return { label: s.staff_nm, value: s.staff_id }
            })

            this.setState({
                task_id: data._id,
                task_type: { value: data.type, label: data.type },
                wip_no: data.wip,
                title: data.title,
                internal: data.internal,
                staff: selectedStaff,
                client: this.state.allClient.find(_ => _.value === data.client_input_id),
                sDate: startDate,
                sTimeH: data.ev_start_time && data.ev_start_time.split(":")[0],
                sTimeM: data.ev_start_time && data.ev_start_time.split(":")[1].split("-")[0],
                timeTypeS: { value: data.ev_start_time && data.ev_start_time.split("-")[1], label: data.ev_start_time && data.ev_start_time.split("-")[1] },
                eDate: endDate,
                eTimeH: data.ev_end_time && data.ev_end_time.split(":")[0],
                eTimeM: data.ev_end_time && data.ev_end_time.split(":")[1].split("-")[0],
                timeTypeE: { value: data.ev_end_time && data.ev_end_time.split("-")[1], label: data.ev_end_time && data.ev_end_time.split("-")[1] },
                rType: { value: data.recur_type, label: data.recur_type },
                priority: { value: data.priority, label: data.priority },
                eSubject: data.email_subject,
                eDescription: data.email_description,
                location: data.email_location,
                guest: this.state.allClient.find(_ => _.value === data.guest),
                smaReminder: { value: data.csms_days, label: data.csms_days },
                dayAppointment: { value: data.csms_time, label: data.csms_time },
                useEnddate: data.rpt_use_end,
                textSMS: data.csms_msg,
                toNumber: data.csms_to,
                isEdit: isEdit,
                mainType: data.main_type,
                status: { value: data.status, label: data.status },
                startTime: startTime,
                endTime: endTime,
                taskSubject: data.subject,
                calanderType: { value: data.calender_type, label: data.calender_type },
                meet_with: data.meet_with
            })

        }
    }

    getAllstaff() {
        let url = "Staff/getDebtor";
        var data = {}
        this.services.getStaff(data, url, (res) => {
            if (res.status === 200) {
                var getData = res.data.data.list
                var listStaff = []
                listStaff = getData && getData.map((item) => {
                  return {value: item.id, label: `${item.first_name}  ${item.last_name}`}
                })

                this.setState({ allStaff: listStaff, allDataStaff: getData })
                this.addRecord()
            }
            else if (res.statusCode === 401 && res.responseType === "UNAUTHORIZED") {
                this.setState({ accessErrorModal: true })
            }
        })
    }

    getAllClient() {
        this.services.getallClient((res) => {
            if (res.status === 200) {
                var getData = res.data.data
                var listClient = []
                listClient = getData && getData.map((item) => {
                    return { value: item._id, label: item.name }
                })
                this.setState({
                    allClient: listClient
                });
                this.addRecord()

            } else if (
                res.statusCode === 401 &&
                res.responseType === "UNAUTHORIZED"
            ) {
                this.setState({ accessErrorModal: true });
            } else {
                this.services.errorToast(res.message);
            }
        });
    }

    closeModal = () => {
        this.setState({ task_id: '' })
        this.props.closeModal()
        this.clear();
    }

    clear = () => {
        this.setState = {
            task_id: '',
            task_type: { value: 'Meeting', label: 'Meeting' },
            wip_no: '',
            title: '',
            internal: false,
            staff: '',
            client: '',
            sDate: this.props.newDate ? this.props.newDate : new Date(),
            sTimeH: '',
            sTimeM: '',
            timeTypeS: { value: 'AM', label: 'AM' },
            eDate: '',
            eTimeH: '',
            eTimeM: '',
            timeTypeE: { value: 'AM', label: 'AM' },
            rType: { value: 'None', label: 'None' },
            priority: '',
            eSubject: '',
            eDescription: '',
            location: '',
            guest: '',
            smaReminder: { value: '0', label: '0' },
            dayAppointment: { value: '8:00', label: "8:00" },
            useEnddate: false,
            allClient: [],
            allStaff: [],
            textSMS: '',
            toNumber: '',
            isEdit: false,
            mainType: this.props.mainType || "RECOVERY",
            status: { value: 'To Do', label: 'To Do' },
            startTime: '',
            endTime: '',
            taskSubject: '',
            allDataStaff: '',
            calanderType: { value: 'Appointment', label: 'Appointment' },
            meet_with: '',
        }
    }

    handleSelectGeoSuggest = (place) => {
        if (!place) return null;
        this.setState({
            location: [place.label]
        })
    };

    handleChange = (selectedOption, name) => {
        this.setState({ [name]: selectedOption });
    };

    changeInput(e) {
        if (!e.target.validity.valid) {
            return null
        }
        this.setState({ [e.target.name]: e.target.value });
    }
    handleChangeDate = (date, name) => {
        this.setState({
            [name]: date,
        });
    };
    handleOptionChange = (e) => {
        this.setState({ [e.target.name]: e.target.checked });
    }
    onTimeChange = (e, name) => {
        this.setState({ [name]: e });
        var data = '12:45-AM'
        var str = moment()
        var d = str.set({ h: 12, m: 15, a: 'am' });
    }

    EmailInvite = () => {
        if (this.validatorEmail.allValid()) {

        }else{
            this.validatorEmail.showMessages();
            this.forceUpdate();
        }
    }

    SendSMS = () =>{
        if (this.validatorSMS.allValid()) {

        }else{
            this.validatorSMS.showMessages();
            this.forceUpdate();
        }
    }

    saveTask(isCopy) {
        var staffList = []
        var all = this.state.allDataStaff
        if(this.state.staff){
            this.state.staff.map(item => {
                var color = all.find(_ => _._id === item.value)
                var list = {
                    staff_nm: item.label,
                    staff_id: item.value,
                    dis_color: color && color.dis_color
                }
                staffList.push(list)
            })
        }
       
        var data = {
            //client_id : this.state.id,
            type: this.state.task_type && this.state.task_type.value,
            // main_type:this.state.mainType,
            wip: this.state.wip_no,
            title: this.state.title,
            internal: this.state.internal,
            staff: JSON.stringify(staffList),
            client_input_id: this.state.client && this.state.client.value,
            client_input: this.state.client && this.state.client.label,
            start_date: this.state.sDate && moment(this.state.sDate).format("MM-DD-YY"),
            end_date: this.state.eDate && moment(this.state.eDate).format("MM-DD-YY"),
            ev_start_time: this.state.startTime ? moment(this.state.startTime).format('HH:MM:A') : '',
            ev_end_time: this.state.endTime ? moment(this.state.endTime).format('HH:MM:A') : '',
            recur_type: this.state.rType && this.state.rType.value,
            priority: this.state.priority && this.state.priority.value,
            rpt_use_end: this.state.useEnddate,
            email_subject: this.state.eSubject,
            email_description: this.state.eSubject,
            email_location: this.state.location,
            guest: this.state.guest && this.state.guest.value,
            csms_days: this.state.smaReminder && parseInt(this.state.smaReminder.value),
            csms_time: this.state.dayAppointment && this.state.dayAppointment.value,
            csms_to: this.state.toNumber,
            csms_msg: this.state.textSMS,
            status: this.state.status && this.state.status.value,
            subject: this.state.taskSubject,
            calender_type: this.state.calanderType.value,
            meet_with: this.state.meet_with
        }
        if (this.validator.allValid()) {
            if (this.state.isEdit && this.state.task_id && isCopy === 'no') {
                data.task_id = this.state.task_id
                delete data.client_id
                var self = this
                this.services.editDashBoardTask(data, (res) => {
                    if (res.status === 200) {
                        this.closeModal();
                        this.props.reloadList()
                        this.services.successToast("Task Updated Successfully");
                    } else if (
                        res.statusCode === 401 &&
                        res.responseType === "UNAUTHORIZED"
                    ) {
                        this.setState({ accessErrorModal: true });
                    } else {
                        this.services.errorToast(res.message);
                    }
                });
            } else {
                var self = this
                this.services.createTask(data, (res) => {
                    if (res.status === 200) {
                        this.services.successToast("Task Added Successfully");
                        this.closeModal();
                        self.props.reloadList()
                    } else if (
                        res.statusCode === 401 &&
                        res.responseType === "UNAUTHORIZED"
                    ) {
                        this.setState({ accessErrorModal: true });
                    } else {
                        this.services.errorToast(res.message);
                    }
                });
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    render() {
        return (
            <div>
                <Modal
                    isOpen={this.props.viewModal} size="lg" style={{ maxWidth: '800px', width: '95%' }}
                    className="theme-light addtaskModal"
                >
                    <form className="">
                        <div className="modal__header pt-0">
                            <div className="flex_jusbt">
                                <h3> {this.props.isEdit ? 'Update Your Task' : 'View Your Task'}</h3>
                                <div className="text-right">
                                    <button className="btn btn-secondary mr-2" type="button" onClick={this.closeModal}>Close</button>
                                </div>
                            </div>
                        </div>
                        <div className="modal__body">
                            <h4 className="">Task Details</h4>
                            <div className="innerContent">
                                <Row className="form">
                                    <Col  >
                                        <div className="form-group">
                                            <label className="form__form-group-label" for="">Calendar Type  :</label>
                                            <p className="mt-0">{this.state.calanderType && this.state.calanderType.value}</p>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="form-group">
                                            <label className="form__form-group-label" for="">WIP Number :</label>
                                            <p className="mt-0">{this.state.wip_no ? this.state.wip_no : ' - ' }</p>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="align-items-center">
                                    <Col sm={6}>
                                        <div className="form-group">
                                            <label className="form__form-group-label" for="">Title :</label>
                                            <p className="mt-0">{this.state.title ? this.state.title : ' - '}</p>
                                        </div>
                                    </Col>
                                    <Col sm={6}>
                                        <div className="form-group">
                                            <label className="form__form-group-label" for="">Meeting With :</label>
                                           <p className="mt-0">{this.state.meet_with ? this.state.meet_with : ' - '}</p>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="form">
                                    <Col  >
                                        <div className="form-group">
                                            <label className="form__form-group-label " for="">Staff :</label>
                                            <p className="mt-0">{this.state.staff && this.state.staff.length > 0 ? this.state.staff.map((staff) => {
                                                return( staff.label )
                                            }).join(', ') : ' - '}
                                            </p>
                                        </div>
                                    </Col>
                                    <Col  >
                                        <div className="form-group">
                                            <label className="form__form-group-label " for="">Client :</label>
                                             <p className="mt-0">{this.state.client && this.state.client.label? this.state.client.label: '-'}</p>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="form">
                                    <Col>
                                        <div className="form-group">
                                            <label className="form__form-group-label" for="">Start Date :</label>
                                            <p className="mt-0">{this.state.sDate && this.state.sDate ?  moment(this.state.sDate).format('MM/DD/YY'): ' - '}</p>
                                        </div>
                                    </Col>
                                    <Col >
                                        <label className="form__form-group-label" for="">Start Time :</label>
                                        <Row>
                                            <Col>
                                                <p className="mt-0">{this.state.startTime && this.state.startTime ?  moment(this.state.startTime).format('H:mm:A') : ' - '}</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                {
                                    this.state.calanderType && this.state.calanderType.value === 'Task' && <Row className="form">
                                        <Col>
                                            <div className="form-group">
                                                <label className="form__form-group-label " for="">End Date :</label>
                                                <p className="mt-0">{this.state.eDate && this.state.eDate ? moment(this.state.eDate).format('MM/DD/YY'): ' - '}</p>
                                            </div>
                                        </Col>
                                        <Col>
                                            <label className="form__form-group-label" for="">End Time  :</label>
                                            <Row>
                                                <Col >
                                                    <p className="mt-0">{this.state.endTime && this.state.endTime ? moment(this.state.endTime).format('H:mm:A') : ' - '}</p>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                }
                                 <Row className="align-items-center">
                                        {
                                            this.state.calanderType && this.state.calanderType.value === 'Task' && <>
                                                <Col  sm={6}>
                                                    <div className="form-group">
                                                        <label className="form__form-group-label" for="">Type  :</label>
                                                        <p className="mt-0">{this.state.task_type && this.state.task_type ?  this.state.task_type.label : ' - '}</p>
                                                    </div>
                                                </Col>
                                                <Col sm={6} >
                                                    <div className="form-group form">
                                                        <label className="form__form-group-label " for="">Status :</label>
                                                        <p className="mt-0">{this.state.status && this.state.status ? this.state.status.label : ' - '}</p>
                                                    </div>
                                                </Col>

                                            </>
                                        }
                                    </Row>
                                  <Row className="align-items-center">
                                    <Col>
                                        <div className="form-group">
                                            <label className="form__form-group-label pt-0" for="">Subject :</label>
                                            <p className="mt-0">{this.state.taskSubject ? this.state.taskSubject : ' - '}</p>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="d-inline">
                                            <div className="">
                                                <div className="custom-control custom-switch mt-3">
                                                    <input type="checkbox" class="custom-control-input" name="internal" id="hp" checked={!this.state.client ? true : false} />
                                                    <label className="custom-control-label" for='hp'>Internal </label>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="align-items-center form">
                                    <Col sm={6}>
                                        <div className="form-group">
                                            <label className="form__form-group-label" for="">Priority :</label>
                                            <p className="mt-0">{this.state.priority && this.state.priority ?  this.state.priority.label : ' - '}</p>
                                        </div>
                                    </Col>
                                    
                                </Row>
                            </div>
                        </div>
                    </form>
                </Modal>
            </div>
        );
    }
}

export default withRouter(ViewTaskModal)