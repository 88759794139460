import { FC, useState } from 'react'
import { CombinedNotesMode, CombinedNotesModeToggleProps } from '../../types/combined-notes.type'
import Switch from '@components/switch'
import { Typography } from '@material-ui/core'
import { useTaskContext } from '@src/context/task-context'

const CombinedNotesModeToggle: FC<CombinedNotesModeToggleProps> = ({ onChange, taskId }) => {
  const { isRecovery, currentClient, currentWip } = useTaskContext()
  const [mode, setMode] = useState(CombinedNotesMode.TASK)

  const isSwitchActive = mode !== CombinedNotesMode.TASK

  const getCombinedNotesParams = (mode: CombinedNotesMode) => {
    const baseParams = {
      limit: 10,
      mode,
    }


    switch (mode) {
      case CombinedNotesMode.TASK:
        return {
          ...baseParams,
          task_id: taskId,
        }
      case CombinedNotesMode.COMPANY:
        return {
          ...baseParams,
          company_id: currentClient.id,
        }
      case CombinedNotesMode.WIP:
        return {
          ...baseParams,
          wip_id: currentWip,
        }
      default:
        return baseParams
    }
  }

  const determineMode = (isActive: boolean) => {
    if (!isActive) {
      return CombinedNotesMode.TASK
    }
    return isRecovery ? CombinedNotesMode.WIP : CombinedNotesMode.COMPANY
  }

  const handleChange = (isActive: boolean) => {
    const mode = determineMode(isActive)
    const params = getCombinedNotesParams(mode)
    onChange(params)
    setMode(mode)
  }

  return (
    <div className='d-flex align-items-center'>
      <Switch value={isSwitchActive} onChange={handleChange} />
      <Typography className='ml-2'>Global notes</Typography>
    </div>
  )
}

export default CombinedNotesModeToggle
