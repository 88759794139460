import React, { Component } from 'react';
import { Col, Container, Row, Card, CardBody, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Services from '../../../../shared/Services.js';
// import AddProperty from './Properties/addProperty'
import { withRouter } from 'react-router';
import ReactFileReader from 'react-file-reader';
import SettingTableHead from './SettingTableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
// import CheckIcon from 'mdi-react/CheckIcon';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
// import jsPDF from 'jspdf'
// import autoTable from 'jspdf-autotable'
import moment from 'moment';
import { ReactSpreadsheetImport } from 'file-validator-component';
const csv = require('csvtojson');

const fields = [
  {
    // Visible in table header and when matching columns.
    label: 'First Name',
    // This is the key used for this field when we call onSubmit.
    key: 'first_name',
    // Allows for better automatic column matching. Optional.
    alternateMatches: ['first name', 'first', 'firstname'],
    // Used when editing and validating information.
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: 'input',
    },
    // Used in the first step to provide an example of what data is expected in this field. Optional.
    example: 'Stephanie',
    // Can have multiple validations that are visible in Validation Step table.
  },
  {
    label: 'Last Name',
    description: 'This is the surname',
    key: 'last_name',
    alternateMatches: ['last name', 'last', 'lastname'],
    fieldType: {
      type: 'input',
    },
    example: 'McDonald',
  },
  {
    label: 'Email',
    key: 'email',
    alternateMatches: ['email'],
    fieldType: {
      type: 'input',
    },
    example: 'stephanie.mcdonald@xyz.com',
    // Can have multiple validations that are visible in Validation Step table.
    validations: [
      {
        // Can be "required" / "unique" / "regex"
        rule: 'required',
        errorMessage: 'Email is required',
        // There can be "info" / "warning" / "error" levels. Optional. Default "error".
        level: 'error',
      },
    ],
  },
  {
    label: 'Primary Address 1',
    key: 'address_1',
    alternateMatches: ['address'],
    fieldType: {
      type: 'input',
    },
    example: '123 Main Street',
  }, 
  {
    label: 'Primary Address 2',
    key: 'address_2',
    alternateMatches: ['address'],
    fieldType: {
      type: 'input',
    },
    example: '123 Main Street',
  },
  {
    label: 'Primary Address 3',
    key: 'address_3',
    alternateMatches: ['address'],
    fieldType: {
      type: 'input',
    },
    example: '123 Main Street',
  },
  {
    label: 'Primary Town/Suburb',
    key: 'town',
    alternateMatches: ['town', 'suburb'],
    fieldType: {
      type: 'input',
    },
    example: 'Sydney, New South Wales',
    validations: [
      {
        // Can be "required" / "unique" / "regex"
        rule: 'required',
        errorMessage: 'Primary Town/Suburb is required',
        // There can be "info" / "warning" / "error" levels. Optional. Default "error".
        level: 'error',
      },
    ],
  },
  {
    label: 'Primary Post Code',
    key: 'postcode',
    alternateMatches: ['postcode', 'code'],
    fieldType: {
      type: 'input',
    },
    example: '3000',
    validations: [
      {
        // Can be "required" / "unique" / "regex"
        rule: 'required',
        errorMessage: 'Primary Post Code is required',
        // There can be "info" / "warning" / "error" levels. Optional. Default "error".
        level: 'error',
      },
    ],
  },
];

function getSorting(order, orderBy) {
  if (order === 'desc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
}

class Tools extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactsId: '',
      title: '',
      showPropertiesPopup: false,
      showPropertiesList: false,
      invoiceData: false,
      showUploadPopup: false,
      isShowAddProperty: false,
      showPropertieList: false,
      toJson: [],
      coverNoteList: [],
      order: 'asc',
      orderBy: '',
      selected: new Map([]),
      staffData: [],
      page: 0,
      rowsPerPage: 5,
      errorModal: false,
      errorMessage: '',
      errorType: '',
      st_id: '',
      accessErrorModal: false,
      errorElement: [],
      showPropertieListError: false,
    };
    this.showAddProperty = this.showAddProperty.bind(this);
    this.getStaff = this.getStaff.bind(this);
    this.services = new Services();
  }

  componentDidMount() {
    this.setState({ title: 'MANAGE STAFF' });
    this.getStaff(localStorage.getItem('client_Id'));
  }

  openPopup = () => {
    this.setState({
      showPropertiesPopup: true,
    });
  };
  openInVoice = () => {
    this.props.history.push('/contacts/invoice-list', { fromTools: true });

  };

  showPropertyList = () => {
    this.setState({
      showPropertiesList: true,
    });
  };

  closePopup = () => {
    this.setState({
      showPropertiesPopup: false,
      showUploadPopup: false,
      showPropertieList: false,
      showPropertiesList: false,
      invoiceData: false,
      showPropertieListError: false,
    });
  };

  isSelected = (id) => {
    const { selected } = this.state;
    return !!selected.get(id);
  };

  showAddProperty() {
    localStorage.setItem('propertyId', 'false');
    localStorage.setItem('propertyId', 'false');
    localStorage.setItem('propertyAdd', 'yes');
    localStorage.setItem('propertyEdit', 'yes');

    this.setState(
      {
        showPropertiesPopup: false,
        // isShowAddProperty: true
      },
      () => {
        this.props.history.push('/add-properties', { fromTools: true });
      },
    );
  }

  showUploadPopup = () => {
    this.setState({
      showPropertiesPopup: false,
      showUploadPopup: true,
      showPropertieList: false,
    });
  };

  getStaff(id) {
    const data = {
      client_id: id,
    };

    this.services.getCoverNotes(data, (res) => {
      if (res.status === 200) {
        const getData = res?.data?.data;
        this.setState({
          coverNoteList: getData,
        });
      } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
        this.setState({ accessErrorModal: true });
      } else {
        this.services.errorToast(res.message);
      }
    });
  }

  addProp = () => {
    const csvdata = [];
    this.state.toJson.forEach((ele) => {
      if (ele.selected) {
        csvdata.push({
          agent_nm: ele.agent_nm,
          cover_from: moment(new Date()).format('YYYY-MM-DD'),
          cover_to: ele.cover_to,
          email: ele.email,
          invoice_type: ele.invoice_type,
          prop_address: ele.prop_address,
          prop_post_code: ele.prop_post_code,
          prop_suburb: ele.prop_suburb,
        });
      }
    });
    const data = {
      client_id: localStorage.getItem('client_Id'),
      client_ID: localStorage.getItem('contactsId'),
      csvdata: csvdata,
    };
    this.services.uploadCSVforProperty(data, (res) => {
      if (res.status === 200) {
        this.setState({
          showPropertiesPopup: false,
          showUploadPopup: false,
          showPropertieList: false,
        });
        this.services.successToast('Property Added successfully');
      } else if (res.statusCode === 401 && res.responseType === 'UNAUTHORIZED') {
        this.setState({ accessErrorModal: true });
      } else {
        this.services.errorToast(res.message);
      }
    });
  };

  handleFiles = (files) => {
    console.log(files);
  };

  handleClick = (e) => {
    const { toJson } = this.state;
    const { id } = e.target;
    toJson[id].selected = !this.state.toJson[id].selected;
    this.setState({ toJson });
  };

  changePropertyList = (e) => {
    const { coverNoteList } = this.state;
    const { id } = e.target;
    coverNoteList[id].selected = !coverNoteList[id].selected;
    this.setState({ coverNoteList });
  };
  onChange = (files) => {
    this.setState({ file: files[0] });

    const csvfile = files[0];
    const reader = new FileReader();
    reader.readAsText(csvfile);
    let self = this;
    reader.onload = function () {
      csv({
        noheader: true,
        output: 'json',
      })
        .fromString(reader.result)
        .then((csvRows) => {
          const toJson = [];
          csvRows.forEach((aCsvRow, i) => {
            if (i !== 0) {
              const builtObject = {};

              Object.keys(aCsvRow).forEach((aKey) => {
                const valueToAddInBuiltObject = aCsvRow[aKey];
                const keyToAddInBuiltObject = csvRows[0][aKey];
                if (
                  keyToAddInBuiltObject === 'First Name' ||
                  keyToAddInBuiltObject === 'Last Name' ||
                  keyToAddInBuiltObject === 'Email' ||
                  keyToAddInBuiltObject === 'suburb' ||
                  keyToAddInBuiltObject === 'post code'
                ) {
                  builtObject[keyToAddInBuiltObject] = valueToAddInBuiltObject;
                }
                builtObject['selected'] = true;
              });

              toJson.push(builtObject);
            }
          });
          const dataTosend = [];
          const errorElements = [];
          toJson.forEach((element, i) => {
            if (
              element['First Name'] &&
              element['First Name'] !== 'Error' &&
              element['Last Name'] &&
              element['Last Name'] !== 'Error' &&
              element['Email'] &&
              element['Email'] !== 'Error' &&
              element['suburb'] &&
              element['suburb'] !== 'Error' &&
              element['post code'] !== 'Error' &&
              element['post code']
            ) {
              dataTosend.push({
                'First Name': element['First Name'],
                'Last Name': element['Last Name'],
                agent_nm: element['First Name'] + ' ' + element['Last Name'],
                invoice_type: 0,
                email: element.Email,
                cover_to: '',
                prop_address: element.Address ? element.Address : '',
                prop_suburb: element.suburb ? element.suburb : '',
                prop_post_code: element['post code'] ? element['post code'] : '',
                selected: true,
              });
            } else {
              element.RowNumber = i;
              if (
                element['First Name'] === '' ||
                element['First Name'] === undefined ||
                element['First Name'] === 'Error'
              ) {
                element['First Name'] = 'Error';
              }
              if (
                element['Last Name'] === '' ||
                element['Last Name'] === undefined ||
                element['Last Name'] === 'Error'
              ) {
                element['Last Name'] = 'Error';
              }
              if (
                element['Email'] === '' ||
                element['Email'] === undefined ||
                element['Email'] === 'Error'
              ) {
                element['Email'] = 'Error';
              }
              if (
                element['suburb'] === '' ||
                element['suburb'] === undefined ||
                element['suburb'] === 'Error'
              ) {
                element['suburb'] = 'Error';
              }
              if (
                element['post code'] === '' ||
                element['post code'] === undefined ||
                element['post code'] === 'Error'
              ) {
                element['post code'] = 'Error';
              }
              errorElements.push(element);
            }
          });

          if (errorElements.length > 0) {
            self.setState({
              showPropertieList: false,
              showPropertieListError: true,
              errorElement: errorElements,
              showUploadPopup: false,
            });
            //self.services.errorToast("Spredsheet is not valid.");
          } else {
            self.setState({
              toJson: dataTosend,
              showPropertiesPopup: false,
              showUploadPopup: false,
              showPropertieList: true,
            });
          }
        });
    };
  };

  downloadFile = () => {};

  genrateInvoice = () => {
    const { coverNoteList } = this.state;
    const data = [];

    coverNoteList.forEach((element) => {
      if (element.selected) {
        data.push(element);
      }
    });
    if (!data.length) {
      this.services.errorToast('Please select property');
      return;
    }
    this.closePopup();
    this.setState({ invoiceData: true });
  };
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  DownloadErrorCSV = () => {
    let rows = this.state.errorElement
      .map((element) => {
        return `${[
          element['RowNumber'],
          element['First Name'],
          element['Last Name'],
          element['Email'],
          element['suburb'],
          element['post code'],
        ]}`;
      })
      .join('\n');

    rows = 'Row Number, First Name, Last Name, Email, Suburb, Post Code' + '\n' + rows;
    let csvContent = 'data:text/csv;charset=utf-8,' + rows;

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'ReviewProperiesError.csv');
    document.body.appendChild(link);
    link.click();
  };

  reUploadProp = () => {
    this.setState(
      {
        showPropertiesPopup: false,
        showPropertieList: false,
        showPropertiesList: false,
        invoiceData: false,
        showPropertieListError: false,
      },
      () => {
        this.setState({
          showUploadPopup: true,
        });
      },
    );
  };
  

  render() {
    const { order, orderBy, selected, rowsPerPage, page, coverNoteList } = this.state;

    return (
      <>
        {/* {this.state.isShowAddProperty ? <AddProperty /> : */}
        <div>
          {/*  <Row>
         <Col md={12} lg={12}> */}

          <Card>
            <h3 className="page-title"> Managing Tools</h3>
            <CardBody className="settings-mangement">
              <Row>
                <Col lg={3} md={6} sm={12}>
                  <div onClick={this.openPopup} className="mg_tools_box">
                    <div className="mg_icon" />
                    <p className="mg_title">Add Property</p>
                  </div>
                </Col>
                <Col lg={3} md={6} sm={12}>
                  <div onClick={this.openInVoice} className="mg_tools_box">
                    <div className="mg_icon" />
                    <p className="mg_title">Invoice</p>
                  </div>
                </Col>
                <Col lg={3} md={6} sm={12}>
                  <Link to="/cover-note-list" className="mg_tools_box">
                    <div className="mg_icon" />
                    <p className="mg_title">Cover Notes</p>
                  </Link>
                </Col>
                <Col lg={3} md={6} sm={12}>
                  <Link to={`/invoice-templates`} className="mg_tools_box">
                    <div className="mg_icon" />
                    <p className="mg_title">Invoice Templates</p>
                  </Link>
                </Col>
              </Row>
            </CardBody>
          </Card>
          {/* </Col>
       </Row> */}

          <Modal
            show={this.state.showPropertiesList}
            onHide={this.closePopup}
            // size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="tool_modal"
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">Properties list</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0 py-4">
              <Container className="p-0 tool_popup">
                <div className="material-table__wrap">
                  <Table className="material-table">
                    <SettingTableHead
                      numSelected={[...selected].filter((el) => el[1]).length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={this.handleSelectAllClick}
                      onRequestSort={this.handleRequestSort}
                      rowCount={coverNoteList.length}
                    />
                    <TableBody>
                      {/* .sort(getSorting(order, orderBy))
                   .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage) */}

                      {coverNoteList
                        .sort(getSorting(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((d, i) => {
                          const isSelected = this.isSelected(d.id);

                          return (
                            <TableRow
                              className="material-table__row"
                              role="checkbox"
                              aria-checked={isSelected}
                              tabIndex={-1}
                              key={i}
                              selected={isSelected}
                            >
                              <TableCell className="material-table__cell material-table__cell-right">
                                <input
                                  type="checkbox"
                                  checked={d.selected}
                                  id={i}
                                  onClick={this.changePropertyList}
                                />
                              </TableCell>
                              <TableCell className="material-table__cell material-table__cell-right">
                                {d.client_id}
                              </TableCell>

                              <TableCell className="material-table__cell material-table__cell-right">
                                {d.agent_nm}
                              </TableCell>

                              {/* <TableCell className="material-table__cell material-table__cell-right"  >
                            {d.agent_phone}
                          </TableCell> */}

                              {/* <TableCell className="material-table__cell material-table__cell-right"  >
                            {d.fax ? d.fax : '-'}
                          </TableCell> */}

                              <TableCell className="material-table__cell material-table__cell-right">
                                {d.cover_to}
                              </TableCell>

                              <TableCell className="material-table__cell material-table__cell-right">
                                {d.prop_suburb}
                              </TableCell>

                              <TableCell className="material-table__cell material-table__cell-right">
                                {d.prop_state}
                              </TableCell>

                              <TableCell className="material-table__cell material-table__cell-right">
                                {d.prop_post_code}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </div>
              </Container>
              <Row>
                <Col xs={12} md={12}>
                  <span onClick={this.genrateInvoice} className="mg_tools_box">
                    <button>Genrate Invoice</button>
                  </span>
                </Col>
              </Row>
            </Modal.Body>
          </Modal>

          <Modal
            show={this.state.invoiceData}
            onHide={this.closePopup}
            // size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="tool_modal"
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">Properties list</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0 py-4">
              <Container className="p-0 tool_popup">
                <div className="material-table__wrap"></div>
              </Container>
            </Modal.Body>
          </Modal>

          <Modal
            show={this.state.showPropertiesPopup}
            onHide={this.closePopup}
            // size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="tool_modal small_modal"
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">Add Properties</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0 py-4">
              <Container className="p-0 tool_popup">
                <Row>
                  <Col xs={12} md={6}>
                    <Button
                      onClick={(e) => this.showAddProperty(e)}
                      className="btn-group--icons mb-0"
                    >
                      Add Single Property
                    </Button>
                  </Col>
                  <Col xs={12} md={6}>
                    <Button onClick={this.showUploadPopup} className="btn-group--icons mb-0">
                      Upload Properties
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Modal.Body>
          </Modal>
          <ReactSpreadsheetImport isOpen={this.state.showUploadPopup} onClose={this.closePopup} fields={fields} onSubmit={(data)=> console.log(data)} />
        </div>
        {/* } */}
      </>
    );
  }
}
export default withRouter(Tools);
