import { createWIPCommercialUncoveredFormEnum } from '../actionTypes';
import { createWIPCommercialUncoveredService } from '../service';

export const createWIPCommercialUncoveredForm = (data) => async (dispatch) => {
  dispatch({
    type: createWIPCommercialUncoveredFormEnum.IS_LOADING_CREATE_WIP_COMMERCIAL_UNCOVERED,
  });
  try {
    const res = await createWIPCommercialUncoveredService(data);

    dispatch({
      type: createWIPCommercialUncoveredFormEnum.CREATE_WIP_COMMERCIAL_UNCOVERED_SUCCESS,
      payload: res,
    });

    return res;
  } catch (error) {
    dispatch({
      type: createWIPCommercialUncoveredFormEnum.CREATE_WIP_COMMERCIAL_UNCOVERED_ERROR,
      payload: error,
    });

    throw error;
  }
};
