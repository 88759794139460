import { useQuery } from "@tanstack/react-query"
import { WipCoverService } from "../services/wip-cover.service"
import { GLOBAL_QUERIES } from "@src/lib/global-queries"

export const useGetWipCover = () => {

  const getWipCover = async () => {
    const wipCoverService = new WipCoverService()
    return wipCoverService.getWipCovers()
  }


  const query = useQuery({
    queryKey: [GLOBAL_QUERIES.WIP_COVER.GET],
    queryFn: getWipCover,
    staleTime: 1000 * 60 * 60,
  })

  return query
}