const AssignAttributesIcon = () => {
  return (
    <svg width="18" height="18" fill="none" viewBox="0 0 18 18">
      <path
        fill="#0C2638"
        d="M0 2a2 2 0 012-2h2a2 2 0 012 2v.25h4.5v1.5H6V4a1.986 1.986 0 01-.309 1.058l4.822 5.786a3.498 3.498 0 00-1.184.922L4.472 5.938c-.154.04-.313.06-.472.062H2a2 2 0 01-2-2V2zm18 0a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V2zm-8 14a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2h-2a2 2 0 00-2 2v2z"
      ></path>
    </svg>
  )
}

export default AssignAttributesIcon
