import { GLOBAL_QUERIES } from "@src/lib/global-queries"
import { REACT_APP_API_URL } from "@src/lib/global-variables"
import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import { ShortMessage } from "../types/sms.type"

export const useGetSms = (id?: number | null, enabled = true) => {
  const query = useQuery({
    queryKey: [GLOBAL_QUERIES.SMS.GET_SMS_BY_ID, id],
    queryFn: async () => {
      const response = await axios.get<ShortMessage>(`${REACT_APP_API_URL}/short-messages/${id}`, {
        params: { join: ['staff_created', 'debtor', 'company_contact'] },
      })
      return response.data
    },
    staleTime: 1000 * 60 * 5,
    enabled: enabled
  })

  return query
}