import { GLOBAL_QUERIES } from "@src/lib/global-queries"
import { useQuery } from "@tanstack/react-query"
import { WipTemplatesService } from "../services/wip-templates.service"

const wipTemplatesService = new WipTemplatesService();

export const useGetWipTemplate = (id: number | null, enabled: boolean, params?, onError?) => {
    const query = useQuery({
        queryKey: [GLOBAL_QUERIES.WIP_TEMPLATE.GET_WIP_TEMPLATE_BY_ID, id],
        queryFn: () => wipTemplatesService.getTemplate(id || 0, params),
        enabled, 
        onError,
        retry: 1
    })

    return query;
}