import { deleteCommercialUncoveredByIdEnum } from '../actionTypes';
import { removeCommercialUncoveredById } from '../service';

export const deleteCommercialUncoveredById = (id) => async (dispatch) => {
  dispatch({
    type: deleteCommercialUncoveredByIdEnum.IS_LOADING_DELETE_COMMERCIAL_UNCOVERED_BY_ID,
  });
  try {
    const res = await removeCommercialUncoveredById(id);

    dispatch({
      type: deleteCommercialUncoveredByIdEnum.DELETE_COMMERCIAL_UNCOVERED_BY_ID_SUCCESS,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: deleteCommercialUncoveredByIdEnum.DELETE_COMMERCIAL_UNCOVERED_BY_ID_ERROR,
      payload: error,
    });
  }
};
