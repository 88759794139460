import styles from './inbox-email-header.module.scss'
import Checkbox from '@mui/material/Checkbox'
import NavigationButton from '@components/navigation-button/navigation-button'
import UsersIcon from '@src/icons/users'
import TrashIcon from '@src/icons/trash'
import ArchiveIcon from '@src/icons/archive'
import PaginationArrows from '@components/pagination-arrows/pagination-arrows.component'
import { FC, useEffect, useRef, useState } from 'react'
import { EmailHeaderProps } from '../../types/inbox.type'
import { useDebounce } from '@src/hooks/useDebounce'
import RefreshButton from '@containers/Inbox/components/refresh-button/refresh-button.component'
import { useQueryClient } from '@tanstack/react-query'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'

const InboxEmailHeader: FC<EmailHeaderProps> = ({
  total,
  onParamsChange,
  defaultLimit = 5,
  defaultPage = 1,
}) => {
  const queryClient = useQueryClient()
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)
  const isInitialize = useRef(false)
  const [paginationParams, setPaginationParams] = useState({
    page: defaultPage,
    limit: defaultLimit,
    skip: (defaultPage - 1) * defaultLimit,
  })
  const { page, limit } = paginationParams

  useEffect(() => {
    if (!isInitialize.current) {
      isInitialize.current = true
      return
    }
    const searchOperator = debouncedSearch.length > 4 ? 'contains' : 'startswith'

    const params = {
      $skip: paginationParams.skip,
      $top: paginationParams.limit,
      $filter: debouncedSearch
        ? `createdDateTime ge 1900-01-01T00:00:00Z and ${searchOperator}(subject, '${debouncedSearch}') or ${searchOperator}(body/content,'${debouncedSearch}')`
        : undefined,
    }
    onParamsChange && onParamsChange(params)
  }, [paginationParams, debouncedSearch])

  const handlePaginationChange = (event: { page: number; limit: number; skip: number }) => {
    setPaginationParams(event)
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value)
  }

  const handleRefresh = async () => {
    setPaginationParams({
      page: defaultPage,
      limit: defaultLimit,
      skip: (defaultPage - 1) * defaultLimit,
    })

    await Promise.all([
      queryClient.resetQueries({
        queryKey: [GLOBAL_QUERIES.MAIL_FOLDER.GET],
      }),
      queryClient.resetQueries({
        queryKey: [GLOBAL_QUERIES.GRAPH_API_MESSAGES.GET_BY_FOLDER_ID],
      }),
    ])
  }

  return (
    <div className={styles.inboxEmailHeaderWrapper}>
      <div className={styles.leftSideHeaderWrapper}>
        <Checkbox className={styles.selectAll} name="antoine" />
        <div className={styles.separator} />
        <div className={styles.actionsHeaderWrapper}>
          <NavigationButton className={styles.smallSquareButton} icon={TrashIcon} />
          <NavigationButton className={styles.smallSquareButton} icon={ArchiveIcon} />
        </div>
        <div className={styles.separator} />
        <NavigationButton
          className={styles.composeButton}
          icon={UsersIcon}
          label="Compose"
          isLink
          to={'mail/compose'}
        />
      </div>
      <input
        className={styles.search}
        value={search}
        onChange={handleSearchChange}
        type="text"
        placeholder="Search"
      />
      <PaginationArrows
        total={total}
        limit={limit}
        page={page}
        onChangePage={handlePaginationChange}
      />
      <RefreshButton onClick={handleRefresh} />
    </div>
  )
}

export default InboxEmailHeader
