import Table from '@components/table/table.component'
import { DebtorRelationTableProps } from '@containers/Recovery/Wip/_modules/Debtors/types/debtor-associate-detail.type'
import { FC } from 'react'
import DebtorRelationTableItem from './debtor-relation-table-item.component'

const DebtorRelationTable: FC<DebtorRelationTableProps> = ({
  debtorId,
  relations,
  onItemClick,
}) => {
  return (
    <Table
      headers={['NAME', 'RELATIONSHIP', 'PHONE', 'ADDRESS', 'REMOVE']}
      paginated={false}
      bodyHeight={relations.length * 54}
    >
      {relations.map((relation) => (
        <DebtorRelationTableItem
          debtorId={debtorId}
          key={`debtor-relation-${relation.id}`}
          relation={relation}
          onItemClick={onItemClick}
        />
      ))}
    </Table>
  )
}

export default DebtorRelationTable
