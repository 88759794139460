import { DeepPartial } from "react-hook-form"
import { DebtorContact } from "../../types/debtor.type"
import { Params } from "@src/types/service.type"
import { useMutation } from "@tanstack/react-query"
import { DebtorContactService } from "../../services/debtor-contact.service"

export const useUpdateDebtorContactMutation = () => {

  const updateDebtorContact = async (id: number, debtorContact: DeepPartial<DebtorContact>, params: Params = {}) => {
    const debtorService = new DebtorContactService()
    return debtorService.updateDebtorContact(id, debtorContact, params)
  }

  const mutation = useMutation({
    mutationFn: ({ id, debtorContact, params }: { id: number, debtorContact: DeepPartial<DebtorContact>, params?: Params }) => updateDebtorContact(id, debtorContact, params)
  })

  return mutation
}