import CreateTaskModal from '@containers/Setting/components/Contacts/taskboard/modals/create-task-modal'
import SubmitButton from '../../components/UI/submit-button/submit-button.component'
import styles from './wip-page.module.scss'
import CreateColumnModal from '@containers/Setting/components/Contacts/taskboard/modals/create-column-modal'
import CreateAutomationModal from '@containers/Setting/components/Contacts/automations/modals/create-automation-modal'
import { useModal } from '@src/hooks/useModal'

const WipPageTaskBoardModals = () => {
  const [isCreateTaskModalOpen, handleCreateTaskModalOpen, handleCreateTaskModalClose] =
    useModal(false)
  const [isCreateColumnModalOpened, handleCreateColumnModalOpen, handleCreateColumnModalClose] =
    useModal(false)
  const [
    isCreateAutomationModalOpened,
    handleCreateAutomationModalOpen,
    handleCreateAutomationModalClose,
  ] = useModal(false)

  return (
    <>
      <div className={styles.buttonsWrapper}>
        <SubmitButton buttonType="button" onClick={handleCreateAutomationModalOpen}>
          Create Automation
        </SubmitButton>
        <SubmitButton buttonType="button" onClick={handleCreateColumnModalOpen}>
          Create Column
        </SubmitButton>
        <SubmitButton buttonType="button" onClick={handleCreateTaskModalOpen}>
          Create Task
        </SubmitButton>
      </div>

      <CreateTaskModal isOpen={isCreateTaskModalOpen} onClose={handleCreateTaskModalClose} />
      <CreateColumnModal
        isOpen={isCreateColumnModalOpened}
        onClose={handleCreateColumnModalClose}
      />

      <CreateAutomationModal
        isOpen={isCreateAutomationModalOpened}
        onClose={handleCreateAutomationModalClose}
      />
    </>
  )
}

export default WipPageTaskBoardModals
