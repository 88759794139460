import Joi from "joi";

export const claimDetailsSchema = Joi.object({
  currentTotal: Joi.number().required().messages({ 'number.required': 'Current Total is required' }),
  total: Joi.number().required().messages({ 'number.required': 'Total is required' })
}).required()

export const createClaimDetailsNoteSchema = Joi.object({
  total_amount: Joi.number().required().messages({ 'number.required': 'Total Amount is required' }),
  description: Joi.string().required().messages({ 'string.required': 'Description is required', 'string.empty': 'Description is required' })
}).required()

export const addFeeSchema = Joi.object({
  fee: Joi.object({ label: Joi.string(), value: Joi.number() }).required().messages({
    'any.required': 'Required', 
    'object.base': 'Required'
  })
}).required()