import React, {Component} from 'react'
import {Col, Row, Card, CardBody, ButtonToolbar, Button} from 'reactstrap';
import {EditorState, convertToRaw} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import {withRouter} from 'react-router';
import Services from '../../../../shared/Services.js';
import { UserContext } from '../../../../context/user-context';

const ToolbarOptions = {
  options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'emoji', 'image', 'history'],
  inline: {
    options: ['bold', 'italic', 'underline'],
  },
};

class addTemplate extends Component {
  static contextType = UserContext

  constructor(props) {
    super(props)
    this.state = {
      editorState: EditorState.createEmpty(),
      bodyString: "",
      titile: "",
    };
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
    this.services = new Services();
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });

    this.setState({
      bodyString: draftToHtml(convertToRaw(editorState.getCurrentContent()))
    })
  };
  changeInput = (e) => {
    this.setState({
      title: e.target.value
    })
  }
  handleSubmit = (e) => {
    const {user} = this.context;

    e.preventDefault()
    const data = {
      title: this.state.title,
      body: this.state.bodyString,
      crby: user.id,
      type: "WIP - Tenant Letter"
    };
    this.services.addTemplate(data, (res) => {
      if (res.status === 200) {
        this.services.successToast('templateEmail Added Successfully');
        this.props.history.push('/edit-templates')
      } else {
        this.services.errorToast(res.message);
      }
    })
  }

  cancle = () => {
    this.props.history.push('/edit-templates')
  }

  render() {
    return (
      <Col md={12} lg={12} xl={10}>
        <Row>
          <Col md={12}>
            <h3 className="page-title"> Add Template</h3>
          </Col>
        </Row>
        <Card>
          <CardBody>
            <form className="form form--horizontal">
              <div className="form__form-group">
                <span className="form__form-group-label"> Title<span style={{color: "#ff0000"}}>*</span></span>
                <input
                  name="title"
                  type="text"
                  placeholder="Template Name"
                  //  value={this.state.lname}
                  onChange={this.changeInput}
                />
              </div>
              <div className="text-editor">
                <Editor
                  editorState={this.state.editorState}
                  wrapperClassName="demo-wrapper"
                  editorClassName="demo-editor"
                  onEditorStateChange={this.onEditorStateChange}
                  toolbar={ToolbarOptions}
                />
              </div>
              <ButtonToolbar>
                <Button color="primary" type="bytton" onClick={this.handleSubmit.bind(this)}>Save</Button>
                <Button type="button" onClick={this.cancle}>
                  Cancel
                </Button>
              </ButtonToolbar>
            </form>
          </CardBody>
        </Card>
      </Col>
    )
  }
}

export default (withRouter((addTemplate)));
