import { GLOBAL_QUERIES } from "@src/lib/global-queries";
import { useQuery } from "@tanstack/react-query";
import { WipTemplatesService } from "../services/wip-templates.service";

const wipTemplatesService = new WipTemplatesService();

export const useGetWipTemplates = (params?) => {
    const fetchWipTemplates = () => {
        return wipTemplatesService.getTemplates(params)
    }

    const query = useQuery({ queryKey: [GLOBAL_QUERIES.WIP_TEMPLATE.GET, params], queryFn: fetchWipTemplates });

    return query;
};