import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { useModal } from '@src/hooks/useModal'
import AddClaimDetailNoteModal from '../modals/add-claim-detail-note-modal/add-claim-detail-note-modal.component'
import { FC } from 'react'
import { AddDetailNoteButtonProps } from '../../types/claim-detail.type'

const AddDetailNoteButton: FC<AddDetailNoteButtonProps> = ({ wipId }) => {
  const [isOpen, handleOpen, handleClose] = useModal(false)

  return (
    <>
      <SubmitButton buttonType="button" onClick={handleOpen}>
        Add Line Item
      </SubmitButton>
      {isOpen ? (
        <AddClaimDetailNoteModal isOpen={isOpen} onClose={handleClose} wipId={wipId} />
      ) : null}
    </>
  )
}

export default AddDetailNoteButton
