import { DeepPartial } from "react-hook-form";
import { LandlordService } from "../services/landlord.service";
import { Landlord } from "@containers/Sales/Properties/types/property.type";
import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import { Params } from "@src/types/service.type";
import { GLOBAL_QUERIES } from "@src/lib/global-queries";
import { useMemo } from "react";


export const useGetInfinityLandlords = (params: Params = {}) => {

  const getLandlords = (params: Params) => {
    const landlordService = new LandlordService();
    return landlordService.getLandlords(params);
  }

  const { data, ...restQuery } = useInfiniteQuery({
    queryKey: [GLOBAL_QUERIES.LANDLORDS.GET, params],
    queryFn: async ({ pageParam }) => {
      const page = pageParam ? pageParam : 1;
      const limit = params.limit;

      const res = await getLandlords({ ...params, limit, page })

      return {
        results: res.data,
        page: page < res.pageCount ? page + 1 : undefined
      }
    },
    cacheTime: 0,
    getNextPageParam: (lastPage) => lastPage.page,
    refetchOnWindowFocus: false,
  });

  const flattenedData = useMemo(
    () => (data ? data?.pages.flatMap(item => item.results) : []),
    [data]
  );

  return { data: flattenedData, ...restQuery }
}

export const useUpdateLandlordMutation = () => {
  const updateLandlord = (landlordId: number, data: DeepPartial<Landlord>) => {
    const landlordService = new LandlordService();
    return landlordService.updateLandlord(landlordId, data);
  }

  const mutation = useMutation({
    mutationFn: ({ landlordId, data }: {
      landlordId: number,
      data: DeepPartial<Landlord>
    }) => updateLandlord(landlordId, data)
  })

  return mutation
}