import React from 'react';

import { Grid, Typography, Button } from '@mui/material';

import FormInput from '../../../../../shared/components/FormInput/FormInput';
import FormDatePicker from '../../../../../shared/components/FormDatePicker/FormDatePicker';

import { tenant } from '../../../../../shared/utils/CommercialUncoveredFormInitialValues';
import { commercialUncoveredFormStyles } from '../../styles';

const TenantsDetails = ({ handleChange, values, setFieldValue, errors, touched }) => {
  const addTenatHandler = () => {
    setFieldValue('tenants_details', [...values.tenants_details, tenant]);
  };

  return (
    <Grid>
      {values?.tenants_details?.map((_, index) => (
        <Grid item sx={commercialUncoveredFormStyles?.collectionContainer} key={index}>
          <Typography sx={commercialUncoveredFormStyles?.collectionTitle}>
            Tenants Details {index + 1}
          </Typography>

          <FormInput
            changeHandler={handleChange}
            label="First Name"
            name={`tenants_details.[${index}].first_name`}
            placeholder="First Name"
            type="text"
            value={values?.tenants_details?.[index]?.first_name}
            required={true}
            error={errors?.tenants_details?.[index]?.first_name}
            touched={touched?.tenants_details?.[index]?.first_name}
          />

          <FormInput
            changeHandler={handleChange}
            label="Middle Name"
            name={`tenants_details.[${index}].middle_name`}
            placeholder="Middle Name"
            type="text"
            value={values?.tenants_details?.[index]?.middle_name}
          />

          <FormInput
            changeHandler={handleChange}
            label="Last Name"
            name={`tenants_details.[${index}].last_name`}
            placeholder="Last Name"
            type="text"
            value={values?.tenants_details?.[index]?.last_name}
            required={true}
            error={errors?.tenants_details?.[index]?.last_name}
            touched={touched?.tenants_details?.[index]?.last_name}
          />

          <FormInput
            changeHandler={handleChange}
            label="Alias"
            name={`tenants_details.[${index}].alias`}
            placeholder="Alias"
            type="text"
            value={values?.tenants_details?.[index]?.alias}
          />

          <FormInput
            changeHandler={handleChange}
            label="Current Address"
            name={`tenants_details.[${index}].current_address`}
            placeholder="Current Address"
            type="text"
            value={values?.tenants_details?.[index]?.current_address || undefined}
            required={true}
            error={errors?.tenants_details?.[index]?.current_address}
            touched={touched?.tenants_details?.[index]?.current_address}
          />

          <FormDatePicker
            label="Date of Birth"
            placeholder="Date of Birth"
            name={`tenants_details.[${index}].date_of_birth`}
            changeHandler={handleChange}
            value={values?.tenants_details?.[index]?.date_of_birth || undefined}
          />

          <FormInput
            changeHandler={handleChange}
            label="Email"
            name={`tenants_details.[${index}].email`}
            placeholder="Email"
            type="email"
            value={values?.tenants_details?.[index]?.email}
          />

          <FormInput
            changeHandler={handleChange}
            label="Home Phone"
            name={`tenants_details.[${index}].home_phone`}
            placeholder="Home Phone"
            type="phone"
            value={values?.tenants_details?.[index]?.home_phone}
            error={errors?.tenants_details?.[index]?.home_phone}
            touched={touched?.tenants_details?.[index]?.home_phone}
          />

          <FormInput
            changeHandler={handleChange}
            label="Work Phone"
            name={`tenants_details.[${index}].work_phone`}
            placeholder="Work Phone"
            type="phone"
            value={values?.tenants_details?.[index]?.work_phone}
            error={errors?.tenants_details?.[index]?.work_phone}
            touched={touched?.tenants_details?.[index]?.work_phone}
          />

          <FormInput
            changeHandler={handleChange}
            label="Mobile Phone"
            name={`tenants_details.[${index}].mobile_phone`}
            placeholder="Mobile Phone"
            type="phone"
            value={values?.tenants_details?.[index]?.mobile_phone}
            error={errors?.tenants_details?.[index]?.mobile_phone}
            touched={touched?.tenants_details?.[index]?.mobile_phone}
          />

          <FormInput
            changeHandler={handleChange}
            label="License No"
            name={`tenants_details.[${index}].license_no`}
            placeholder="License No"
            type="text"
            value={values?.tenants_details?.[index]?.license_no}
          />

          <FormInput
            changeHandler={handleChange}
            label="Passport No"
            name={`tenants_details.[${index}].passport_no`}
            placeholder="Passport No"
            type="text"
            value={values?.tenants_details?.[index]?.passport_no}
          />

          <FormInput
            changeHandler={handleChange}
            label="Other Contact Info"
            name={`tenants_details.[${index}].other_contact_info`}
            placeholder="Other Contact Info"
            type="text"
            value={values?.tenants_details?.[index]?.other_contact_info}
          />
        </Grid>
      ))}

      <Button onClick={addTenatHandler}>Add New Tenant</Button>
    </Grid>
  );
};

export default TenantsDetails;
