export const applicationEndpoints = {
  commercialUncoveredForm: '/commercial-uncovered-form',
  property: '/properties',
  paymentDetails: '/payment-details',
  invoiceTemplates: '/invoice-templates',
  invoice: '/invoices',
  invoiceEmail: '/invoices/send-email',
  companies: '/companies',
  emailTemplates: '/email-templates',
};
