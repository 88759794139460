import Joi from 'joi'
import { ActionRow, DataGridColumn, Row } from './data-grid.type'


export class DataGridService {
  static findLongestArray(obj: Record<string, string[]>) {
    let longestArray: string[] = []
    let maxLength = 0

    for (const key in obj) {
      if (obj[key].length > maxLength) {
        maxLength = obj[key].length
        longestArray = obj[key]
      }
    }

    return longestArray
  }

  static getValidationSchema(header: DataGridColumn[]) {
    const schema = {}

    header.forEach(({ name, validation }) => {
      if (validation) {
        schema[name] = validation
      }
    })

    return Joi.array().items(Joi.object(schema))
  }

  static transformDataGridRowsToData(
    rows: Row[],
    header: DataGridColumn[],
  ): Record<string, string>[] {
    return rows.map((row) => {
      const data: Record<string, string> = {}
      header.forEach(({ name }, index) => {
        data[name] = row.data[index].value
      })
      data.action = JSON.stringify(row.action)
      return data
    })
  }

  static transformRowsToColumnArrayValue(
    header: DataGridColumn[],
    rows: Record<string, string>[],
  ): Record<string, string[]> {
    const columnArrayValue: Record<string, string[]> = {}

    for (const column of header) {
      columnArrayValue[column.name] = []
    }

    for (const row of rows) {
      for (const column of header) {
        const value = row[column.name]
        columnArrayValue[column.name].push(value || '')
      }
    }
    return columnArrayValue
  }

  static validateRows(rows: Row[], header: DataGridColumn[]): Joi.ValidationResult {
    const dataToValidate = this.transformDataGridRowsToData(rows, header)
    const validationSchema = this.getValidationSchema(header)
    return validationSchema.validate(dataToValidate, { abortEarly: false })
  }

  static initializeRows(header: DataGridColumn[], data: Record<string, string[]>): Row[] {
    const rowCount = this.findLongestArray(data).length
    const rows: Row[] = Array.from({ length: rowCount }, (_, index) => {
      return {
        id: index.toString(),
        data: header.map(({ name }) => {
          return {
            value: data?.[name]?.[index] || '',
            selected: false,
            error: undefined,
          }
        }),
        selected: false,
        action: {} as ActionRow,
      }
    })

    const errors = this.validateRows(rows, header)

    const errorDetails = errors.error?.details

    if (!errorDetails) {
      return rows
    }

    const headerNameToIndex = header.reduce((acc, { name }, index) => {
      acc[name] = index
      return acc
    }, {})

    for (const errorDetail of errorDetails) {
      const path = errorDetail.path
      const rowIndex = path[0]
      const columnIndex = headerNameToIndex[path[1]]

      if (typeof rowIndex !== 'number' || typeof columnIndex !== 'number') {
        continue
      }

      rows[rowIndex].data[columnIndex].error = errorDetail
    }

    return rows
  }

  static getDataGridRowsWithErrors = (rows: Row[]): Row[] => {
    return rows.filter((row) => row.data.some((cell) => cell.error))
  }
}