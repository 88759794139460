import axios from 'axios'
import { REACT_APP_API_URL } from '../lib/global-variables'

export default class BaseService {
  constructor() {
    if (!localStorage.getItem('token')) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
    }

    this.apiUrl = REACT_APP_API_URL
  }
}
