import { FC } from 'react'
import { MainProps } from '../types/layout.type'
import styles from './main.module.scss'
import cls from 'classnames'
import { useMainStyleContext } from '@containers/App/contexts/main-style.context'

const Main: FC<MainProps> = ({ children, withPadding }) => {
  const { style } = useMainStyleContext()
  return (
    <main className={cls(styles.mainWrapper, { [styles.withPadding]: withPadding })} style={style}>
      {children}
    </main>
  )
}

export default Main
