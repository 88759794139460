import Joi from "joi";

export const addDebtorLegalSchema = Joi.object({
  debtor: Joi.object(
    {
      label: Joi.string().required(),
      value: Joi.number().required()
    }
  ).required(),
}).messages({
  "object.base": "Required",
  "any.required": "Required",
  "string.base": "Required",
  'string.empty': 'Required',
  "number.base": "Required",
})

export const debtorClaimNumberSchema = Joi.object({
  claimNumber: Joi.string().required().messages({ 'string.base': 'Required', 'any.required': 'Required', 'string.empty': 'Required' })
})

export const debtorLegalDefendantSchema = Joi.object({
  defendantName: Joi.string().required(),
  defendantAddress: Joi.string().required()
}).messages({ 'string.base': 'Required', 'any.required': 'Required', 'string.empty': 'Required' })

export const debtorLegalJudgmentDateSchema = Joi.object({
  judgmentDate: Joi.date().required()
}).messages({ 'string.base': 'Required', 'any.required': 'Required', 'string.empty': 'Required' })

export const debtorLegalPlaintiffSchema = Joi.object({
  plaintiffName: Joi.string().required(),
  plaintiffAddress: Joi.string().required()
}).messages({ 'string.base': 'Required', 'any.required': 'Required', 'string.empty': 'Required' })

export const debtorLegalTribunalSchema = Joi.object({
  tribunalCaseNumber: Joi.string().required(),
  tribunalLocation: Joi.string().required(),
  tribunalOrderDate: Joi.date().required()
}).messages({ 'string.base': 'Required', 'any.required': 'Required', 'date.base': 'Required', 'string.empty': 'Required' })

export const debtorLegalNoteSchema = Joi.object({
  content: Joi.string().required()
}).messages({ 'string.base': 'Required', 'any.required': 'Required', 'string.empty': 'Required' })
