import React from 'react';
import { Col, Row } from 'reactstrap';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Services from '../../../../../shared/Services.js';
import { withRouter } from 'react-router';
import ModalComponent from '../../../../../shared/components/ModalCustom';
import DebtorService from '../../../../../services/DebtorService';
import WIPService from '../../../../../services/WIPService';
import SettingTableHeadWIP from './SettingTableHeadWIP';

const userImg = `${process.env.PUBLIC_URL}/image/user.jpg`;

function getSorting(order, orderBy) {
  if (order === 'desc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
}

class DebtorListWIP extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: 'asc',
      orderBy: '',
      selected: new Map([]),
      debtors: [],
      page: 0,
      rowsPerPage: 10,
      errorModal: false,
      errorMessage: '',
      errorType: '',
      st_id: '',
      accessErrorModal: false,
      total: 0,
      wipID: null,
    };
    this.wipService = new WIPService();
    this.debtor = new DebtorService();
    this.services = new Services();
    this.EditStaff = this.EditStaff.bind(this);
  }

  componentDidMount() {
    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.wipID
    ) {
      this.setState({ wipID: this.props.location.state.wipID });
    }
    if (this.props.debtors) {
      this.setState({
        debtors: this.props.debtors,
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.debtors !== this.props.debtors) {
      this.setState({ debtors: this.props.debtors });
    }
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    const { orderBy: stateOrderBy, order: stateOrder } = this.state;

    if (stateOrderBy === property && stateOrder === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      const { data } = this.state;
      const newSelected = new Map();
      data.map((n) => newSelected.set(n.id, true));
      this.setState({ selected: newSelected });
      return;
    }
    this.setState({ selected: new Map([]) });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const newSelected = new Map(selected);
    const value = newSelected.get(id);
    let isActive = true;
    if (value) {
      isActive = false;
    }
    newSelected.set(id, isActive);
    this.setState({ selected: newSelected });
    localStorage.setItem('debtorId', id);
    this.props.history.push(`/contacts-notes/${id}`, { debtor: id, wipID: id });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
    this.getDebtors(newPage + 1);
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
    this.setState({ page: 0 });
    this.getDebtors(0, event.target.value);
  };

  handleDeleteSelected = () => {
    const { data } = this.state;
    let copyData = [...data];
    const { selected } = this.state;

    for (let i = 0; i < [...selected].filter((el) => el[1]).length; i += 1) {
      copyData = copyData.filter((obj) => obj.id !== selected[i]);
    }

    this.setState({ data: copyData, selected: new Map([]) });
  };

  isSelected = (id) => {
    const { selected } = this.state;
    return !!selected.get(id);
  };

  EditStaff(e, d) {
    localStorage.setItem('editId', d.id);
    this.props.history.push('/edit-staff');
  }

  OpenModal = (e, d) => {
    this.setState({
      st_id: d.id,
      errorMessage: 'Are you sure you want to delete this record? ',
      errorModal: true,
      errorType: 'danger',
    });
  };

  accessErrorModalClose = () => {
    this.setState({ accessErrorModal: false });
    this.props.history.push('/dashboard');
  };


  render() {
    const { order, orderBy, selected, debtors, } =
      this.state;
    const { editDebtor, deleteDebtor } =
      this.props;
    return (
      <div className='container'>
        <Row>
          <Col md={12} lg={12}>
            {this.state.errorModal ? (
              <ModalComponent
                modal={this.state.errorModal}
                color={this.state.errorType}
                isCancel={true}
                message={this.state.errorMessage}
                onConfirm={(e) => this.DeleteStaff(e)}
                onClose={() => this.setState({ errorModal: false })}
              />
            ) : null}
            {this.state.accessErrorModal ? (
              <ModalComponent
                modal={this.state.accessErrorModal}
                color='danger'
                isCancel={false}
                message='You do not have access to this, please contact your manager for assistance'
                onConfirm={(e) => this.accessErrorModalClose(e)}
                onClose={(e) => this.accessErrorModalClose(e)}
              />
            ) : null}

            <div className='material-table__wrap'>
              <Table className='material-table'>
                <SettingTableHeadWIP
                  numSelected={[...selected].filter((el) => el[1]).length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={this.handleSelectAllClick}
                  onRequestSort={this.handleRequestSort}
                  rowCount={debtors.length}
                />
                <TableBody>
                  {debtors
                    .sort(getSorting(order, orderBy))
                    .map((debtor) => {
                      const isSelected = this.isSelected(debtor.id);

                      return (
                        <TableRow
                          className='material-table__row'
                          role='checkbox'
                          aria-checked={isSelected}
                          tabIndex={-1}
                          key={debtor.id}
                          selected={isSelected}
                        >
                          <TableCell width={'25%'}
                                     className='material-table__cell material-table__cell-right'
                                     component='th'
                                     scope='row'
                                     padding='none'
                          >
                            {debtor?.id}
                          </TableCell>

                          <TableCell width={'25%'} className='material-table__cell material-table__cell-right'>
                            {`${debtor.name_first} ${debtor.name_last}`}
                          </TableCell>

                          <TableCell width={'25%'} className='material-table__cell material-table__cell-right'>
                            {debtor?.contact?.comms_email[0]}
                          </TableCell>

                          <TableCell width={'25%'} className='material-table__cell material-table__cell-right'>
                            <button
                              className='btn-gray mr-3'
                              onClick={(event) =>
                                this.handleClick(event, debtor.id)
                              }
                            >
                              View
                            </button>
                            <button
                              className='btn-gray mr-3'
                              onClick={() => {
                                editDebtor(debtor);
                              }}
                            >
                              Edit Details
                            </button>
                            <button
                              className='btn-gray'
                              onClick={() => {
                                deleteDebtor(debtor);
                              }}
                            >
                              Remove
                            </button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(DebtorListWIP);
