import html2pdf from 'html2pdf.js'

function convertHtmlToPdf(html, fileName, onAfterSave) {
  const element = document.createElement('div')
  element.innerHTML = html
  html2pdf()
    .from(element)
    .save(fileName + '.pdf')
    .then(() => {
      if (onAfterSave) {
        onAfterSave()
      }
    })
}

export const downloadImage = async (pdfID, handler) => {
  const image = await fetch(
    `${process.env.REACT_APP_BMS5_END_POINT}/invoices/pdf?ids=${String(pdfID)}`,
  )
  const textHtml = await image.text()

  convertHtmlToPdf(textHtml, pdfID, handler)
}
