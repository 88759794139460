import Section from '@components/section/section.component'
import SubSection from '@containers/Recovery/Wip/components/UI/sub-section/sub-section.component'
import SubmitButton from '@containers/Recovery/Wip/components/UI/submit-button/submit-button.component'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import styles from './plaintiff-section.module.scss'
import { useModal } from '@src/hooks/useModal'
import CreateDebtorLegalPlaintiffModal from '../../modals/debtor-legal-plaintiff-modal/create-debtor-legal-plaintiff-modal.component'
import { FC } from 'react'
import DebtorLegalPlaintiffList from '../../lists/debtor-legal-plaintiff-list/debtor-legal-plaintiff-list.component'
import { PlaintiffSectionProps } from '../../../types/debtor-plaintiff.type'

const PlaintiffSection: FC<PlaintiffSectionProps> = ({ debtorLegal }) => {
  const [isOpen, handleOpen, handleClose] = useModal(false)
  return (
    <Section>
      <SubSection className={styles.headerWrapper}>
        <Header mb={4}>Plaintiff/s</Header>
        <SubmitButton onClick={handleOpen}>Add Plaintiff</SubmitButton>
      </SubSection>

      <DebtorLegalPlaintiffList debtorLegalPlaintiffs={debtorLegal.plaintiffs} />

      <CreateDebtorLegalPlaintiffModal
        isOpen={isOpen}
        onClose={handleClose}
        debtorLegalId={debtorLegal.id}
      />
    </Section>
  )
}

export default PlaintiffSection
