import Dropdown from '@components/dropdown/dropdown.component'
import { TABLE_DROPDOWN_DATE_OPTIONS } from '@components/table/table.const'
import { FC, useState } from 'react'
import FormInput from '@components/inputs/form-input/form-input.component'
import SearchIcon from '@src/icons/search'
import styles from './sms-table.module.scss'
import SmsTable from './sms-table.component'
import CloackIcon from '@src/icons/cloack'
import { transformDropdownDateOptionToDate } from '@components/table/table.util'
import { SmsTableWipFilterProps } from '@containers/Communication/types/communication.type'
import { SelectOption } from '@src/types/component-types'

const SmsTableWipFilter: FC<SmsTableWipFilterProps> = ({ params }) => {
  const [page, setPage] = useState(1)
  const [selectedDate, setSelectedDate] = useState(TABLE_DROPDOWN_DATE_OPTIONS[3])

  const handleSelectedDateChange = (selectedDate: SelectOption) => {
    setSelectedDate(selectedDate)
    setPage(1)
  }

  return (
    <>
      <div className={styles.emailTableFilter}>
        <Dropdown
          options={TABLE_DROPDOWN_DATE_OPTIONS}
          selected={selectedDate}
          onSelectedChange={handleSelectedDateChange}
          icon={CloackIcon}
        />
        <div className={styles.searchFieldWrapper}>
          <FormInput size="small" placeholder="Search" icon={SearchIcon} />
        </div>
      </div>
      <SmsTable
        currentPage={page}
        onPageChange={setPage}
        s={JSON.stringify({
          ...params,
          time_created: transformDropdownDateOptionToDate(selectedDate)
            ? { $gte: transformDropdownDateOptionToDate(selectedDate) }
            : undefined,
        })}
      />
    </>
  )
}

export default SmsTableWipFilter
