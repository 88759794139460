import { FC } from 'react'
import DataGridCell from './data-grid-cell.component'
import DataGridCheckbox from './data-grid-checkbox.component'
import styles from './data-grid.module.scss'
import cls from 'classnames'
import { DataGridRowProps, DataGridRowRendererProps } from './data-grid.type'

export const DataGridRow: FC<DataGridRowProps> = ({
  row,
  style,
  header,
  editable,
  onRowClick,
  onRowSelect,
  onCellSelectionChange,
  onCellChange,
  onErrorsChange,
  isRowValid,
  isRowInvalid,
}) => {
  const isInvalid = isRowInvalid ? isRowInvalid(row, header) : false
  const isValid = (isRowValid ? isRowValid(row, header) : undefined) && !isInvalid
  return (
    <div
      className={cls(styles.row, {
        [styles.valid]: !!isValid,
        // [styles.invalid]: isInvalid,
      })}
      style={style}
      onClick={onRowClick ? () => onRowClick(row) : undefined}
    >
      <div className={cls(styles.cell, styles.checkboxWrapper)}>
        <DataGridCheckbox checked={row.selected} onChange={() => onRowSelect(row.id)} />
      </div>
      {row.data.map((cell, cellIndex) => {
        return (
          <DataGridCell
            value={cell.value}
            selected={cell.selected}
            editable={editable}
            key={`row-${row.id}-cell-${cellIndex}`}
            onSelectionChange={(selected) => onCellSelectionChange(row.id, cellIndex, selected)}
            header={header[cellIndex]}
            error={cell.error}
            onChange={(value) => onCellChange(row.id, cellIndex, value)}
            onErrorsChange={(errors) => onErrorsChange(row.id, cellIndex, errors)}
          />
        )
      })}
    </div>
  )
}

export const DataGridRowRenderer: FC<DataGridRowRendererProps> = ({ index, data, style }) => {
  const row = data.rows[index]

  return (
    <DataGridRow
      style={style}
      key={`row-${row.id}`}
      row={row}
      header={data.header}
      editable={data.editable}
      onRowClick={data.onRowClick}
      onCellSelectionChange={data.onCellSelectionChange}
      onRowSelect={data.onRowSelect}
      onCellChange={data.onCellChange}
      onErrorsChange={data.onErrorsChange}
      isRowValid={data.isRowValid}
      isRowInvalid={data.isRowInvalid}
    />
  )
}
