import Modal from '@components/modal'
import { FC } from 'react'
import AddDebtorsToPayTrackingForm from '../../forms/add-debtors-to-pay-tracking-form/add-debtors-to-pay-tracking-form.component'
import { Debtor } from '@containers/Recovery/Wip/_modules/Debtors/types/debtor.type'
import { useQueryClient } from '@tanstack/react-query'
import { getWipQueryKey } from '@containers/Recovery/Wip/utils/wip-query-factory'
import { Wip } from '@containers/Recovery/Wip/types/wip.type'
import { toast } from 'react-toastify'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import styles from './add-debtors-to-pay-tracking-modal.module.scss'
import { AddDebtorsToPayTrackingModalProps } from '../../../types/payment-tracking.type'

const AddDebtorsToPayTrackingModal: FC<AddDebtorsToPayTrackingModalProps> = ({
  debtorsAddedToPayTracking,
  wipId,
  debtors,
  ...modalProps
}) => {
  const queryClient = useQueryClient()
  const handleSuccessSubmit = (debtors: Debtor[]) => {
    queryClient.setQueryData<Wip>(getWipQueryKey(wipId), (prevData) =>
      prevData
        ? {
            ...prevData,
            debtors_added_to_pay_tracking: debtors,
          }
        : undefined,
    )
    toast.success('Debtors added to pay tracking')
    modalProps.onClose()
  }

  return (
    <Modal {...modalProps} className={styles.modalWrapper} withBtns={false}>
      <Header>Add Debtors To Pay Tracking</Header>
      <AddDebtorsToPayTrackingForm
        wipId={wipId}
        onSuccessSubmit={handleSuccessSubmit}
        debtorsOptions={debtors
          .filter((debtor) => !debtorsAddedToPayTracking.some((d) => d.id === debtor.id))
          .map((debtor) => ({
            label: `${debtor.name_first} ${debtor.name_last}`,
            value: debtor.id,
          }))}
        debtorsAddedToPayTracking={debtorsAddedToPayTracking}
      />
    </Modal>
  )
}

export default AddDebtorsToPayTrackingModal
