import { ChangeEvent, FC, useState } from 'react'
import { AssignedStaff } from '../types'
import { Checkbox } from '@mui/material'
import { toast } from 'react-toastify'
import axios from 'axios'
import { REACT_APP_API_URL } from '@src/lib/global-variables'
import { SubTaskItemProps } from '../../types/sub-task.type'
import styles from './sub-task.module.scss'
import { SubHeader, Text } from '@containers/Recovery/Wip/components/UI/text/text.component'

const SubTaskItem: FC<SubTaskItemProps> = ({ subTask }) => {
  const { id, title, is_done, assigned_to } = subTask
  const [isBlocked, setIsBlocked] = useState(false)
  const [checked, setChecked] = useState(is_done)

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (isBlocked) {
      return
    }

    const newValue = e.target.checked

    setIsBlocked(true)
    // TODO: rewrite api logic to service
    try {
      await axios.put(`${REACT_APP_API_URL}/sub-tasks/${id}`, {
        is_done: newValue,
      })
      setIsBlocked(false)
      setChecked(newValue)
    } catch {
      toast.error('Something went wrong')
      setIsBlocked(false)
    }
  }

  const getTransformedAssignTo = (assignedTo: AssignedStaff[]) => {
    return assignedTo
      .map((assignTo) => `${assignTo.first_name || ''} ${assignTo.last_name || ''}`)
      .join(', ')
  }

  const transformedAssignTo = getTransformedAssignTo(assigned_to)

  return (
    <li className={styles.subTaskItem}>
      <Checkbox onChange={handleChange} checked={checked} color="success" />
      <SubHeader>{title}</SubHeader>
      <Text>Assign to: {transformedAssignTo}</Text>
    </li>
  )
}

export default SubTaskItem
