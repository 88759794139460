import { FC } from 'react'
import {
  CreateGeneralInterestRateCalculatorFormProps,
  GeneralInterestRateCalculatorFormValues,
} from '../../../../types/interest-rate.type'
import GeneralInterestRateCalculatorForm from './general-interest-rate-calculator-form.component'
import {
  CALCULATED_ON_OPTIONS,
  CALCULATOR_TYPE_OPTIONS,
} from '../../../../const/interest-rate.const'
import { toast } from 'react-toastify'
import { useCreateGeneralInterestCalculator } from '../../../../hooks/crud-general-interest-calculator.hook'
import { parseStringToNumber } from '@containers/Recovery/Wip/utils/format-currency'

const CreateGeneralInterestRateCalculatorForm: FC<CreateGeneralInterestRateCalculatorFormProps> = ({
  wipId,
  onCalculateTypeChange,
  onSuccessSubmit,
  onCancelClick,
}) => {
  const { mutateAsync, isLoading } = useCreateGeneralInterestCalculator()
  const handleSubmit = async (data: GeneralInterestRateCalculatorFormValues) => {
    try {
      const res = await mutateAsync({
        rate: Number(data.interestRate) / 100,
        period: data.calculatedOn.value,
        startClaimAmount: data.onClaimAmount
          ? Math.floor(parseStringToNumber(data.onClaimAmount))
          : null,
        dateStart: data.startDate,
        dateEnd: data.endDate,
        wipId,
      })
      toast.success('General interest calculator created successfully')
      onSuccessSubmit(res)
    } catch (e) {
      toast.error('Failed to create court interest calculator')
      console.log(e)
    }
  }
  return (
    <GeneralInterestRateCalculatorForm
      loading={isLoading}
      onCalculateTypeChange={onCalculateTypeChange}
      onSubmit={handleSubmit}
      onCancelClick={onCancelClick}
      initialValues={{
        calculateType: CALCULATOR_TYPE_OPTIONS[1],
        calculatedOn: CALCULATED_ON_OPTIONS[0],
        interestRate: '',
        onClaimAmount: '',
        startDate: null,
        endDate: null,
      }}
      buttonText="Create Rate"
    />
  )
}

export default CreateGeneralInterestRateCalculatorForm
