import React from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import SimpleReactValidator from "simple-react-validator";
import moment from "moment";
import Services from "../../../../shared/Services.js";
import {withRouter} from "react-router";
import "rc-time-picker/assets/index.css";
import { optionsState } from '../../../Form/RecoveryForm/components/WipDetails';
import CompaniesService from '../../../../services/CompaniesService';
import WIPService from '../../../../services/WIPService';

const clientStatusOption = [
  {value: "0", label: "Prospect"},
  {value: "1", label: "Current"},
  {value: "2", label: "Inactive"},
  {value: "3", label: "No Longer Operating"},
];

const PriorityOption = [
  {value: "0", label: "Cover Note"},
  {value: "1", label: "Primary"},
  {value: "2", label: "Secondory"},
];

const CategoryOption = [
  {value: "0", label: "10%"},
  {value: "1", label: "15%"},
  {value: "2", label: "30%"},
  {value: "3", label: "50/50"},
  {value: "4", label: "Eviction"},
];

const fileLoacationOption = [
  {value: "0", label: "General filing"},
  {value: "1", label: "General filing – No Order"},
  {value: "2", label: "Paying"},
  {value: "3", label: "Paying Agent Direct"},
  {value: "4", label: "Eviction"},
  {value: "5", label: "Paid in full"},
  {value: "6", label: "Purged – Bankrupt"},
  {value: "7", label: "Purged – Overseas"},
  {value: "8", label: "Purged – No TAF"},
  {value: "9", label: "Purged – No Order"},
  {value: "10", label: "Paying Agent"},
  {value: "11", label: "Purged – jail"},
  {value: "12", label: "Purged – Deceased"},
  {value: "13", label: "Purged - By Agen"},
  {value: "14", label: "Purged - No Cover"},
  {value: "15", label: "Purged - Insurance"},
  {value: "16", label: "QLD General Filing"},
  {value: "17", label: "QLD PIF Filing"},
  {value: "18", label: "QLD Purge Filing"},
  {value: "19", label: "QLD Audit Box 1"},
  {value: "20", label: "QLD Audit Box 2"},
  {value: "21", label: "QLD Audit Box 3"},
  {value: "22", label: "QLD Audit Box 4"},
  {value: "23", label: "QLD Other"},
  {value: "24", label: "NSW General Filing"},
  {value: "25", label: "NSW PIF Filing"},
  {value: "26", label: "NSW Purge Filing"},
  {value: "27", label: "NSW Other"},
  {value: "28", label: "VIC General Filing"},
  {value: "29", label: "VIC PIF Filing"},
  {value: "30", label: "VIC Purge Filing"},
  {value: "31", label: "VIC Othe"},
];

const paidOutOption = [
  {value: "0", label: "None"},
  {value: "1", label: "Partly"},
  {value: "2", label: "Fully"},
  {value: "3", label: "Unknown"},
];

const urgencyOption = [
  {value: "0", label: "1. Priority Action"},
  {value: "1", label: "2. Action Required"},
  {value: "2", label: "3. Client Complain"},
  {value: "3", label: "4. Client for Renewal"},
  {value: "4", label: "5. Report Sent"},
];

class AddWIP extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: localStorage.getItem("contactsId"),
      allDataStaff: [],
      allClient: [],
      selectedClient: "",
      clientStatus: "",
      clientEmail: "",
      clientContact: "",
      clientContactName: "",
      clientFname: "",
      clientLname: "",
      wipPriority: {value: "0", label: "Cover Note"},
      checkOwner: false,
      checkSend: false,
      category: "",
      FileNumber: "",
      DateLodged: "",
      asDateLog: "",
      coverOption: "",
      allOrigin: [],
      allDebtor: "",
      origin: "",
      finalizeDate: "",
      fileLocation: "",
      urgency: "",
      paidOut: "",
      paidOutDate: "",
      ClientrefNo: "",
      RBClaim: "",
      pAmount: "",
      checkPayOwner: false,
      checkSendReport: false,
      fileLocationCost: {value: "0", label: "Add Cost Item"},
      paidInDate: "",
      clam_details: [
        {
          id: "_" + Math.random().toString(36).substr(2, 9),
          claim_desc: "",
          claim_cur_amt: "",
          claim_added: "",
        },
      ],

      cost_details: [
        {
          id: "_" + Math.random().toString(36).substr(2, 9),
          recov_desc: "",
          recov_cur_amt: "",
          recov_added: "",
        },
      ],
      claimtotal: "",
      claimInterest: "",
      errorClaimOne: "",
      errorClaimTwo: "",
      errorClaimThree: "",
      errorCostOne: "",
      errorCostTwo: "",
      errorCostThree: "",
      landlordInsurance: false,
      landlordPayOut: false,
      insuranceBy: "",
      totalOrignalClaim: "",
      amtCoverInsurance: "",
      subjects: [
        {
          id: "_" + Math.random().toString(36).substr(2, 9),
          selected: "",
        },
      ],
      errorSubject: "",
      currentClaim: "",
      PaidDirectToAgent: "",
      TotalReceived: "",
      paidAsAtAgent: "",
      interestStartFrom: "",
      interestStartTo: "",
      totalClaimAmount: 0,
      claimInterestTotal: 0,
      totalCostAmount: 0,
      costOfferAmount: 0,
      ownerName: "",
      ownerAddress: "",
      ownerContact: "",
      ownerEmail: "",
      ownerBank: "",
      OverviewdropDown: true,
      WIPDetailsdropDown: true,
      ClaimAmountdropDown: true,
      ClientdropDown: true,
      LandlorddropDown: true,
      OwnerdropDown: true,
      SubjectsdropDown: true,
    };
    this.services = new Services();
    this.changeInput = this.changeInput.bind(this);
    this.validatorOne = new SimpleReactValidator();
    this.comp = new CompaniesService();
    this.wip = new WIPService();
  }

  // SlideDownContact = () => {
  //   this.setState({
  //     overViewDropDown: !this.state.overViewDropDown
  //   })
  // }


  componentDidMount() {
    this.getAllClient();
    this.getOrigins();
    this.getDebtorList();
    this.setState({ loader: true });
    // state of origin defined in getClient function
    this.getClient(localStorage.getItem('contactsId'));     
  }

   getClient(id) {
    this.setState({ loader: true });
    const params = {
      id: id,
      join: ['contacts', 'properties'],
    };
    const self = this;
    this.comp.getCompaniesId(params).then((res) => {
      if (res.status === 200) {
        const getData = res.data;

        this.setState({
          primary_address: getData.address.length > 0 ? getData.address : this.state.primary_address,
        });
        
        this.setState({ loader: false });
        
        // get state of origin
        this.getStateofOrigin();
      } else if (
        res.statusCode === 401 &&
        res.responseType === 'UNAUTHORIZED'
      ) {
        this.setState({ accessErrorModal: true });
        this.setState({ loader: false });
      } else {
        this.services.errorToast(res.message);
        this.setState({ loader: false });
      }
    });
  }
  
  getStateofOrigin() {
    this.state.primary_address.forEach((item) => {
      if (item.primary === true) {
        this.setState({origin: {value: item.state, label: item.state}});
        this.getState({value: item.state, label: item.state});
      }
    });
  }

  getState = (d) => {
    this.setState({ Ass_States: d });
    this.setState({ stateOfOrigin: d.value });
    const params = {
      filter: `short||$eq||${d.value}`,
    };
    this.wip.getWIPCost(params).then((res) => {
      if (res.status === 200) {
        if (res.data?.data.length) {
          const allData = res.data?.data[0]?.list;
          const costItem = [];
          allData.forEach((element) => {
            costItem.push({ value: element.id, label: element.name });
          });
          this.setState({
            WIPData: allData,
            costItem: costItem,
            stateName: res.data?.data[0]?.state,
          });
          
          // Prefilling cost item list with Administration fee
          this.addCostToList({label: "Administration Fee", value: undefined});
          
        }
      } else {
        this.services.errorToast(res.message);
      }
    });
  };

  addCostToList = (d) => {
    this.setState({
      costItemSelected: d.value,
      indexOfCost: this.state.indexOfCost + 1,
    });
    const data = this.state.WIPData.find((ele) => ele.id === d.value);
    const dataTodave = {
      id: data.id,
      description: data.name,
      amount: +data.amount,
      date: data.date ? data.date : new Date(),
    };
    const CostData = [...this.state.cost_details, dataTodave];

    let costTotal = 0;
    CostData.map((item) => {
      costTotal += parseFloat(item.amount);
    });
    this.setState({ cost_details: CostData, totalCostAmount: +costTotal });
  };
  

  getOrigins() {
    this.services.getOrigins((res) => {
      if (res.status === 200) {
        const getData = res.data.data;
        let list;
        list =
          getData &&
          getData.map((item) => {
            return {value: item, label: item};
          });
        this.setState({
          allOrigin: list,
        });
      } else if (
        res.statusCode === 401 &&
        res.responseType === "UNAUTHORIZED"
      ) {
        this.setState({accessErrorModal: true});
      } else {
        this.services.errorToast(res.message);
      }
    });
  }

  getDebtorList() {
    this.services.getDebtorListDropDown((res) => {
      if (res.status === 200) {
        const getData = res.data.data;
        let list;
        list =
          getData &&
          getData.map((item) => {
            return {
              value: item._id,
              label: item.contact_1_first + " " + item.contact_1_last,
            };
          });
        this.setState({
          allDebtor: list,
        });
      } else if (
        res.statusCode === 401 &&
        res.responseType === "UNAUTHORIZED"
      ) {
        this.setState({accessErrorModal: true});
      } else {
        this.services.errorToast(res.message);
      }
    });
  }

  getAllClient() {
    this.services.getallClient((res) => {
      if (res.status === 200) {
        const getData = res.data.data;
        let listClient;
        listClient =
          getData &&
          getData.map((item) => {
            return {value: item._id, label: item.name};
          });
        this.setState({
          allClient: listClient,
        });
      } else if (
        res.statusCode === 401 &&
        res.responseType === "UNAUTHORIZED"
      ) {
        this.setState({accessErrorModal: true});
      } else {
        this.services.errorToast(res.message);
      }
    });
  }

  

  handleChange = (selectedOption, name) => {
    this.setState({[name]: selectedOption});
    if (name === "selectedClient" && selectedOption && selectedOption.value) {
      const data = {
        client_ID: selectedOption.value,
      };
      // const self = this;
      this.services.getContactList(data, (res) => {
        if (res.status === 200) {
          const getData = res.data.data.list[0];
          this.setState({
            clientStatus:
              clientStatusOption.find((_) => _.value === getData.status) &&
              clientStatusOption.find((_) => _.value === getData.status).label,
            clientEmail:
              getData.contact_det && getData.contact_det.length > 0
                ? getData.contact_det[0].email
                : "",
            clientContact:
              getData.contact_det && getData.contact_det.length > 0
                ? getData.contact_det[0].mobile
                : "",
            clientContactName:
              getData.contact_det && getData.contact_det.length > 0
                ? getData.contact_det[0].first +
                " " +
                getData.contact_det[0].last
                : "",
            clientFname:
              getData.contact_det && getData.contact_det.length > 0
                ? getData.contact_det[0].first
                : "",
            clientLname:
              getData.contact_det && getData.contact_det.length > 0
                ? getData.contact_det[0].last
                : "",
          });
        } else if (
          res.statusCode === 401 &&
          res.responseType === "UNAUTHORIZED"
        ) {
          this.setState({accessErrorModal: true});
        } else {
          this.services.errorToast(res.message);
        }
      });
    } else if (name === 'origin' && selectedOption && selectedOption.value) {
      this.getState(selectedOption);
    }
  };

  changeInput(e) {
    if (!e.target.validity.valid) {
      return null;
    }
    this.setState({[e.target.name]: e.target.value});
  }

  handleChangeDate = (date, name) => {
    this.setState({
      [name]: date,
    });
  };
  handleOptionChange = (e) => {
    this.setState({[e.target.name]: e.target.checked});
  };

  changeClaimDetails = (e, id) => {
    if (!e.target.validity.valid) {
      return null;
    }
    if (e.target.name === "claim_desc" && e.target.value) {
      this.setState({errorClaimOne: ""});
    } else if (e.target.name === "claim_added" && e.target.value) {
      this.setState({errorClaimTwo: ""});
    } else if (e.target.name === "claim_cur_amt" && e.target.value) {
      this.setState({errorClaimThree: ""});
    }
    const data = this.state.clam_details.map(function (item) {
      if (item.id === id && e.target) {
        item[e.target.name] = e.target.value;
      }
      return item;
    });
    let claimTotal = 0;
    this.state.clam_details.map((item) => {
      claimTotal += parseFloat(item.claim_cur_amt);
    });
    this.setState({clam_details: data, totalClaimAmount: claimTotal});
  };

  addClaimDetails = () => {
    const lastData = this.state.clam_details.slice(-1)[0];
    if (lastData) {
      if (
        lastData.claim_desc === "" ||
        lastData.claim_cur_amt === "" ||
        lastData.claim_added === ""
      ) {
        if (lastData.claim_desc === "") {
          this.setState({errorClaimOne: "This Filed is Required"});
        } else if (lastData.claim_added === "") {
          this.setState({errorClaimTwo: "This Filed is Required"});
        } else if (lastData.claim_cur_amt === "") {
          this.setState({errorClaimThree: "This Filed is Required"});
        }
      } else {
        const data = {
          id: "_" + Math.random().toString(36).substr(2, 9),
          claim_desc: "",
          claim_cur_amt: "",
          claim_added: "",
        };
        const details = this.state.clam_details;
        details.push(data);
        this.setState({
          clam_details: details,
        });
      }
    }
  };

  changeCostDetails = (e, id) => {
    if (!e.target.validity.valid) {
      return null;
    }
    if (e.target.name === "recov_desc" && e.target.value) {
      this.setState({errorCostOne: ""});
    } else if (e.target.name === "recov_cur_amt" && e.target.value) {
      this.setState({errorCostTwo: ""});
    }

    const data = this.state.cost_details.map(function (item) {
      if (item.id === id && e.target) {
        item[e.target.name] = e.target.value;
      }
      return item;
    });
    let costTotal = 0;
    this.state.cost_details.map((item) => {
      costTotal += parseFloat(item.recov_cur_amt);
    });

    this.setState({cost_details: data, totalCostAmount: costTotal});
  };
  changeCostDate = (date, id) => {
    const self = this;
    const data = this.state.cost_details.map(function (item) {
      if (item.id === id && date) {
        self.setState({errorCostThree: ""});
        item.recov_added = date;
      }
      return item;
    });
    this.setState({cost_details: data});
  };

  addSubject = () => {
    const lastData = this.state.subjects.slice(-1)[0];
    if (lastData && lastData.selected === "") {
      this.setState({errorSubject: "This Filed is Required."});
    } else {
      const data = {
        id: "_" + Math.random().toString(36).substr(2, 9),
        selected: "",
      };
      const details = this.state.subjects;
      details.push(data);
      this.setState({
        subjects: details,
      });
    }
  };

  addCostDetails = () => {
    const lastData = this.state.cost_details.slice(-1)[0];
    if (lastData) {
      if (
        lastData.recov_desc === "" ||
        lastData.recov_added === "" ||
        lastData.recov_cur_amt === ""
      ) {
        if (lastData.recov_desc === "") {
          this.setState({errorCostOne: "This Filed is Required"});
        } else if (lastData.recov_cur_amt === "") {
          this.setState({errorCostTwo: "This Filed is Required"});
        } else if (lastData.recov_added === "") {
          this.setState({errorCostThree: "This Filed is Required"});
        }
      } else {
        const data = {
          id: "_" + Math.random().toString(36).substr(2, 9),
          recov_desc: "",
          recov_cur_amt: "",
          recov_added: "",
        };
        const details = this.state.cost_details;
        details.push(data);
        this.setState({
          cost_details: details,
        });
      }
    }
  };

  handleChangeSubject = (selectedOption, id) => {
    const self = this;
    const data = this.state.subjects.map(function (item) {
      if (item.id === id && selectedOption) {
        self.setState({errorSubject: ""});
        item.selected = selectedOption;
      }
      return item;
    });
    this.setState({subjects: data});
  };

  saveWIP = () => {
    const subject = [];
    this.state.subjects.map((item) => {
      if (item.selected && item.selected.value) {
        subject.push(item.selected.value);
      }
    });

    const claim_amount = [];
    this.state.clam_details.map((item) => {
      if (
        item.claim_desc !== "" ||
        item.claim_cur_amt !== "" ||
        item.claim_added !== ""
      ) {
        delete item.id;
        claim_amount.push(item);
      }
    });
    const recoverable_costs = [];
    this.state.cost_details.map((item) => {
      if (
        item.recov_desc !== "" ||
        item.recov_added !== "" ||
        item.recov_cur_amt !== ""
      ) {
        delete item.id;
        item.recov_added = item.recov_added
          ? moment(item.recov_added).format("MM-DD-YY")
          : "";
        recoverable_costs.push(item);
      }
    });
    const data = {
      file_number: this.state.FileNumber,
      client_id: this.state.selectedClient && this.state.selectedClient.value,
      lodged: this.state.DateLodged
        ? moment(this.state.DateLodged).format("MM-DD-YY")
        : "",
      category: this.state.category && this.state.category.value,
      finalize: this.state.finalizeDate
        ? moment(this.state.finalizeDate).format("MM-DD-YY")
        : "",
      origin_state: this.state.origin && this.state.origin.value,
      file_location: this.state.filelocation && this.state.filelocation.value,
      urgency: this.state.urgency && this.state.urgency.value,
      send_owner_reports: this.state.checkSendReport,
      cover_as_at_date_log: this.state.asDateLog,
      cover: this.state.coverOption && this.state.coverOption.value,
      paid_out: this.state.paidOut && this.state.paidOut.value,
      paid_out_date: this.state.paidOutDate
        ? moment(this.state.paidOutDate).format("MM-DD-YY")
        : "",
      // purchased_amount: this.state.pAmount,
      residual_basic_claim: this.state.RBClaim,
      pay_owner_direct: this.state.checkPayOwner,
      priority: this.state.wipPriority,
      client_ref: this.state.ClientrefNo,
      paid_in_full_date: this.state.paidInDate
        ? moment(this.state.finalizeDate).format("MM-DD-YY")
        : "",
      current_interest: this.state.claimInterestTotal,
      total_claim: this.state.totalClaimAmount,
      total_recov_cost: this.state.totalCostAmount,
      insure_nill_payout: this.state.landlordPayOut,
      insure_had: this.state.landlordInsurance,
      owner_name: this.state.ownerName,
      owner_address: this.state.ownerAddress,
      owner_contact: this.state.ownerContact,
      owner_email: this.state.ownerEmail,
      owner_bank: this.state.ownerBank,

      subject: JSON.stringify(subject),
      claim_amount: JSON.stringify(claim_amount),
      recoverable_costs: JSON.stringify(recoverable_costs),

      insure_by: this.state.insuranceBy,
      insure_total_claim: this.state.totalOrignalClaim,
      insure_amount: this.state.amtCoverInsurance,

      paid_to_agent: this.state.PaidDirectToAgent,
      paid_to_agent_date: this.state.paidAsAtAgent,

      requested_taf: this.state.checkSend,
      requested_order: this.state.checkOwner,
      amount_received: this.state.TotalReceived,
    };
    if (
      this.validatorOne.allValid()) {
      this.services.CreateWIP(data, (res) => {
        if (res.status === 200) {
          this.services.successToast("WIP Added Successfully");
          this.props.close();
        } else if (
          res.statusCode === 401 &&
          res.responseType === "UNAUTHORIZED"
        ) {
          this.setState({accessErrorModal: true});
        } else {
          this.services.errorToast(res.message);
        }
      });
    } else {
      this.validatorOne.showMessages();
      this.forceUpdate();
    }
  };
  clientClick = () => {
    localStorage.setItem("contactsId", this.state.selectedClient.value);
    this.props.history.push("/setting");
    setTimeout(() => {
      this.props.history.push("/contacts");
    }, 100);
  };

  dateFormate(dateNew) {
    let fDate;
    if (dateNew && dateNew !== '0000-00-00') {
      if (dateNew) {
        const date = moment(dateNew).format("MM-DD-YY");
        fDate = "";
        if (date === "Invalid date") {
          fDate = new Date(
            dateNew.slice(0, dateNew.length - 2).replace(/\//g, "-")
          );
        } else {
          fDate = new Date(date);
        }
      } else {
        return "";
      }
      if (fDate === "Invalid Date" || fDate === "Invalid date") {
        return "";
      } else {
        return fDate;
      }
    }
  }

  removeClaimCost = (item) => {
    const list = [] = this.state.clam_details.filter(_ => _.id !== item.id);
    if (list) {
      let claimTotal = 0;
      this.setState({clam_details: list}, () => {
        this.state.clam_details.map((item) => {
          claimTotal += parseFloat(item.claim_cur_amt);
        });
        this.setState({totalClaimAmount: claimTotal});
      })
    }
  }
  removeRecovery = (item) => {
    const list = [] = this.state.cost_details.filter(_ => _.id !== item.id);
    if (list) {
      let costTotal = 0;
      this.setState({cost_details: list}, () => {
        this.state.cost_details.map((item) => {
          costTotal += parseFloat(item.recov_cur_amt);
        });
        this.setState({totalCostAmount: costTotal});
      })
    }

  }

  render() {
    let cClaim = 0;
    if (this.state.totalClaimAmount) {
      cClaim += parseFloat(this.state.totalClaimAmount);
    }
    if (this.state.claimInterestTotal) {
      cClaim += parseFloat(this.state.claimInterestTotal);
    }
    if (this.state.totalCostAmount) {
      cClaim += parseFloat(this.state.totalCostAmount);
    }
    return (
      <div className="w-100">
        <div id="contacts_accordion" className="form">
          <div className="card pb-0">

            <div className="slide_down_wrap mb-4 rounded">
              <div className=" card-body bg-white pl-0 rounded">
                {/* <hr /> */}
                <div
                  className={`slide_drop_down boxshadow-none cursor-pointer d-flex flex-wrap align-items-center justify-content-between rounded ${this.state.OverviewdropDown ? "active" : ""}`}
                  onClick={() => this.setState({OverviewdropDown: !this.state.OverviewdropDown})}>

                  <h3 className="page-title mb-0" data-toggle="collapse"> {" "}</h3>
                  <span className="show_dropdown"> <svg className="ml-2" version="1.1" id="Capa_1" x="0px" y="0px"
                                                        width="14px" height="14px" viewBox="0 0 284.929 284.929"
                  >
                    <g>
                      <path d="M282.082,76.511l-14.274-14.273c-1.902-1.906-4.093-2.856-6.57-2.856c-2.471,0-4.661,0.95-6.563,2.856L142.466,174.441
		L30.262,62.241c-1.903-1.906-4.093-2.856-6.567-2.856c-2.475,0-4.665,0.95-6.567,2.856L2.856,76.515C0.95,78.417,0,80.607,0,83.082
		c0,2.473,0.953,4.663,2.856,6.565l133.043,133.046c1.902,1.903,4.093,2.854,6.567,2.854s4.661-0.951,6.562-2.854L282.082,89.647
		c1.902-1.903,2.847-4.093,2.847-6.565C284.929,80.607,283.984,78.417,282.082,76.511z"/>
                    </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </svg>
                  </span>
                </div>
                <div className={`bg-white hide_contact ${this.state.OverviewdropDown ? "active pt-4" : ""}`}>
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label" htmlFor="">
                          {" "}
                          WIP Assigned to:{" "}
                        </label>
                        <input
                          type="text"
                          className="custom-input"
                          disabled
                          placeholder="Staff Name"
                        />
                        <span className="form__form-group-error">
                        </span>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label" htmlFor="">
                          {" "}
                          Diaried to:{" "}
                        </label>
                        <input
                          type="text"
                          className="custom-input"
                          disabled
                          placeholder="Diaried Name"
                        />
                        <span className="form__form-group-error">
                        </span>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label" htmlFor="">
                          {" "}
                          Last Report Sent:{" "}
                        </label>
                        <input
                          type="text"
                          className="custom-input"
                          defaultValue={"Never"}

                        />
                        <span className="form__form-group-error">
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label" htmlFor="">
                          Priority:
                        </label>
                        <Select
                          type="text"
                          classNamePrefix="react-select"
                          className="react-select"
                          options={PriorityOption}
                          value={this.state.wipPriority}
                          name="wipPriority"
                          onChange={(e) => this.handleChange(e, "wipPriority")}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label" htmlFor="">
                          {" "}
                          Payment:{" "}
                        </label>
                        <input
                          type="text"
                          className="custom-input"
                          disabled
                          placeholder="Payment Type"
                        />
                        <span className="form__form-group-error">
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="d-flex align-items-center ml-2">
                        <div className="round_checkbox">
                          <input
                            type="checkbox"
                            className=""
                            name="asPin"
                            id="send"
                            onChange={(e) => {
                              this.setState({checkSend: e.target.checked});
                            }}
                            checked={this.state.checkSend}
                          />
                          <label className="mb-0" htmlFor="send"/>
                        </div>
                        <p className="m-0 ml-3">REQUESTED TAF</p>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="d-flex align-items-center ml-2">
                        <div className="round_checkbox">
                          <input
                            type="checkbox"
                            className=""
                            name="asPin"
                            id="owner"
                            onChange={(e) => {
                              this.setState({checkOwner: e.target.checked});
                            }}
                            checked={this.state.checkOwner}
                          />
                          <label className="mb-0" htmlFor="owner"/>
                        </div>
                        <p className="m-0 ml-3"> REQUESTED ORDER </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="slide_down_wrap mb-4 rounded">
              <div className=" card-body bg-white pl-0 rounded">
                {/* <hr /> */}
                <div
                  className={`slide_drop_down boxshadow-none cursor-pointer d-flex flex-wrap align-items-center justify-content-between rounded ${this.state.WIPDetailsdropDown ? "active" : ""}`}
                  onClick={() => this.setState({WIPDetailsdropDown: !this.state.WIPDetailsdropDown})}>

                  <h3 className="page-title mb-0" data-toggle="collapse"> WIP Details </h3>
                  <span className="show_dropdown"> <svg className="ml-2" version="1.1" id="Capa_1" x="0px" y="0px"
                                                        width="14px" height="14px" viewBox="0 0 284.929 284.929">
                    <g>
                      <path d="M282.082,76.511l-14.274-14.273c-1.902-1.906-4.093-2.856-6.57-2.856c-2.471,0-4.661,0.95-6.563,2.856L142.466,174.441
		L30.262,62.241c-1.903-1.906-4.093-2.856-6.567-2.856c-2.475,0-4.665,0.95-6.567,2.856L2.856,76.515C0.95,78.417,0,80.607,0,83.082
		c0,2.473,0.953,4.663,2.856,6.565l133.043,133.046c1.902,1.903,4.093,2.854,6.567,2.854s4.661-0.951,6.562-2.854L282.082,89.647
		c1.902-1.903,2.847-4.093,2.847-6.565C284.929,80.607,283.984,78.417,282.082,76.511z"/>
                    </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </svg>
                  </span>
                </div>
                <div className={`bg-white hide_contact ${this.state.WIPDetailsdropDown ? "active pt-4" : ""}`}>
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label" htmlFor="">
                          File Number <span style={{color: "#ff0000"}}>*</span>
                        </label>
                        <input
                          type="text"
                          className="custom-input"
                          name="FileNumber"
                          onChange={this.changeInput}
                          value={this.state.FileNumber}
                        />
                        <span className="form__form-group-error">
                          {this.validatorOne.message(
                            'FileNumber',
                            this.state.FileNumber,
                            "required"
                          )}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label" htmlFor="">
                          Date Lodged
                        </label>
                        <div className="date-picker date-picker--interval">
                          <DatePicker
                            selected={
                              this.state.DateLodged ? this.dateFormate(this.state.DateLodged) : ""
                            }
                            onChange={(e) =>
                              this.handleChangeDate(e, "DateLodged")
                            }
                            name="DateLodged"
                            dateFormat="dd/MM/yy"
                            dropDownMode="select"
                            popperPlacement="center"
                            placeholderText="Select Date"
                          />
                          <span className="date_icon">
                            <img src={"./image/calendar.svg"} alt="date"/>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label" htmlFor="">
                          Cover as at Date Log
                        </label>
                        <input
                          type="text"
                          className="custom-input"
                          name="asDateLog"
                          onChange={this.changeInput}
                          value={this.state.asDateLog}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label" htmlFor="">
                          {" "}
                          Category 
                          {/*<span style={{color: "#ff0000"}}>*</span>*/}
                        </label>
                        <Select
                          type="text"
                          classNamePrefix="react-select"
                          className="react-select"
                          options={CategoryOption}
                          name="category"
                          onChange={(e) => this.handleChange(e, "category")}
                        />
                        {/*<span className="form__form-group-error">*/}
                        {/*  {this.validatorOne.message(*/}
                        {/*    "category",*/}
                        {/*    this.state.category,*/}
                        {/*    "required"*/}
                        {/*  )}*/}
                        {/*</span>*/}
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label" htmlFor="">
                          {" "}
                          Cover 
                          {/*<span style={{color: "#ff0000"}}>*</span>*/}
                        </label>
                        <Select
                          type="text"
                          classNamePrefix="react-select"
                          className="react-select"
                          name="coverOption"
                          options={[
                            {value: "0", label: "Protect & Collect"},
                            {value: "1", label: "None"},
                          ]}
                          onChange={(e) => this.handleChange(e, "coverOption")}
                          value={this.state.coverOption}
                        />
                        {/*<span className="form__form-group-error">*/}
                        {/*  {this.validatorOne.message(*/}
                        {/*    "Cover",*/}
                        {/*    this.state.coverOption,*/}
                        {/*    "required"*/}
                        {/*  )}*/}
                        {/*</span>*/}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label" htmlFor="">
                          {" "}
                          State of Origin{" "}
                        </label>
                        <Select
                          type="text"
                          classNamePrefix="react-select"
                          className="react-select"
                          options={optionsState}
                          name="origin"
                          value={this.state.origin}
                          onChange={(e) => this.handleChange(e, "origin")}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label" htmlFor="">
                          Finalize Date{" "}
                          {/*<span style={{color: "#ff0000"}}>*</span>*/}
                        </label>
                        <div className="date-picker date-picker--interval">
                          <DatePicker
                            selected={
                              this.state.finalizeDate
                                ? this.dateFormate(this.state.finalizeDate)
                                : ""
                            }
                            onChange={(e) =>
                              this.handleChangeDate(e, "finalizeDate")
                            }
                            name="finalizeDate"
                            dateFormat="dd/MM/yy"
                            dropDownMode="select"
                            popperPlacement="center"
                            placeholderText="Select Date"
                          />
                          <span className="date_icon">
                            <img src={"./image/calendar.svg"} alt="date"/>
                          </span>
                        </div>
                        {/*<span className="form__form-group-error">*/}
                        {/*  {this.validatorOne.message(*/}
                        {/*    "finalize Date",*/}
                        {/*    this.state.finalizeDate,*/}
                        {/*    "required"*/}
                        {/*  )}*/}
                        {/*</span>*/}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label" htmlFor="">
                          {" "}
                          File Location{" "}
                          {/*<span style={{color: "#ff0000"}}>*</span>*/}
                        </label>
                        <Select
                          type="text"
                          classNamePrefix="react-select"
                          className="react-select"
                          options={fileLoacationOption}
                          name="filelocation"
                          onChange={(e) => this.handleChange(e, "filelocation")}
                          value={this.state.filelocation}
                        />
                        {/*<span className="form__form-group-error">*/}
                        {/*  {this.validatorOne.message(*/}
                        {/*    "file location",*/}
                        {/*    this.state.filelocation,*/}
                        {/*    "required"*/}
                        {/*  )}*/}
                        {/*</span>*/}
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label" htmlFor="">
                          {" "}
                          Paid Out{" "}
                        </label>
                        <Select
                          type="text"
                          classNamePrefix="react-select"
                          className="react-select"
                          options={paidOutOption}
                          onChange={(e) => this.handleChange(e, "paidOut")}
                          value={this.state.paidOut}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label" htmlFor="">
                          {" "}
                          Urgency{" "}
                        </label>
                        <Select
                          type="text"
                          classNamePrefix="react-select"
                          className="react-select"
                          options={urgencyOption}
                          onChange={(e) => this.handleChange(e, "urgency")}
                          value={this.state.urgency}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label" htmlFor="">
                          {" "}
                          Paid Out Date{" "}
                        </label>
                        <div className="date-picker date-picker--interval">
                          <DatePicker
                            selected={
                              this.state.paidOutDate ? this.dateFormate(this.state.paidOutDate) : ""
                            }
                            onChange={(e) =>
                              this.handleChangeDate(e, "paidOutDate")
                            }
                            name="paidOutDate"
                            dateFormat="dd/MM/yy"
                            dropDownMode="select"
                            popperPlacement="center"
                            placeholderText="Select Date"
                          />
                          <span className="date_icon">
                            <img src={"./image/calendar.svg"} alt="date"/>
                          </span>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label" htmlFor="">
                          {" "}
                          Paid In Full Date{" "}
                        </label>
                        <div className="date-picker date-picker--interval">
                          <DatePicker
                            selected={
                              this.state.paidInDate ? this.dateFormate(this.state.paidInDate) : ""
                            }
                            onChange={(e) =>
                              this.handleChangeDate(e, "paidInDate")
                            }
                            name="paidInDate"
                            dateFormat="dd/MM/yy"
                            dropDownMode="select"
                            popperPlacement="center"
                            placeholderText="Select Date"
                          />
                          <span className="date_icon">
                            <img src={"./image/calendar.svg"} alt="date"/>
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label" htmlFor"">
                          Purchased Amount{" "}
                        </label>
                        <input
                          type="text"
                          className="custom-input"
                          onChange={this.changeInput}
                          name="pAmount"
                          pattern="^([0-9]+(\.?[0-9]?[0-9]?)?)"
                          value={this.state.pAmount}
                        />
                      </div>
                    </div> */}
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label" htmlFor="">
                          {" "}
                          Residual Basic Claim{" "}
                        </label>
                        <input
                          type="text"
                          className="custom-input"
                          name="RBClaim"
                          pattern="^([0-9]+(\.?[0-9]?[0-9]?)?)"
                          onChange={this.changeInput}
                          value={this.state.RBClaim}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label" htmlFor="">
                          {" "}
                          Client ref No.{" "}
                        </label>
                        <input
                          type="text"
                          className="custom-input"
                          name="ClientrefNo"
                          value={this.state.ClientrefNo}
                          onChange={this.changeInput}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="d-flex align-items-center ml-2">
                        <div className="round_checkbox">
                          <input
                            type="checkbox"
                            className=""
                            id="sendReport"
                            onChange={(e) => {
                              this.setState({
                                checkSendReport: e.target.checked,
                              });
                            }}
                            checked={this.state.checkSendReport}
                          />
                          <label className="mb-0" htmlFor="sendReport"/>
                        </div>
                        <p className="m-0 ml-3">Send Reports to Owner </p>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="d-flex align-items-center ml-2">
                        <div className="round_checkbox">
                          <input
                            type="checkbox"
                            className=""
                            id="payowner"
                            onChange={(e) => {
                              this.setState({checkPayOwner: e.target.checked});
                            }}
                            checked={this.state.checkPayOwner}
                          />
                          <label className="mb-0" htmlFor="payowner"/>
                        </div>
                        <p className="m-0 ml-3">Pay Owner Direct</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="slide_down_wrap mb-4 rounded">
              <div className=" card-body bg-white pl-0 rounded">
                {/* <hr /> */}
                <div
                  className={`slide_drop_down boxshadow-none cursor-pointer d-flex flex-wrap align-items-center justify-content-between rounded ${this.state.ClaimAmountdropDown ? "active" : ""}`}
                  onClick={() => this.setState({ClaimAmountdropDown: !this.state.ClaimAmountdropDown})}>

                  <h3 className="page-title mb-0" data-toggle="collapse"> Claim Amount Details </h3>
                  <span className="show_dropdown"> <svg className="ml-2" version="1.1" id="Capa_1" x="0px" y="0px"
                                                        width="14px" height="14px" viewBox="0 0 284.929 284.929">
                    <g>
                      <path d="M282.082,76.511l-14.274-14.273c-1.902-1.906-4.093-2.856-6.57-2.856c-2.471,0-4.661,0.95-6.563,2.856L142.466,174.441
                        L30.262,62.241c-1.903-1.906-4.093-2.856-6.567-2.856c-2.475,0-4.665,0.95-6.567,2.856L2.856,76.515C0.95,78.417,0,80.607,0,83.082
                        c0,2.473,0.953,4.663,2.856,6.565l133.043,133.046c1.902,1.903,4.093,2.854,6.567,2.854s4.661-0.951,6.562-2.854L282.082,89.647
                        c1.902-1.903,2.847-4.093,2.847-6.565C284.929,80.607,283.984,78.417,282.082,76.511z"/>
                    </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </svg>
                  </span>
                </div>
                <div className={`bg-white hide_contact ${this.state.ClaimAmountdropDown ? "active pt-4" : ""}`}>
                  {this.state.clam_details &&
                    this.state.clam_details.map((c, i) => {
                      return (
                        <div className="row" key={i}>
                          <div className="col-12 col-md-6 col-lg-3">
                            <div className="form-group">
                              <label className="form__form-group-label" htmlFor="">
                                {" "}
                                Description{" "}
                              </label>
                              <input
                                type="text"
                                className="custom-input"
                                value={c.claim_desc}
                                name="claim_desc"
                                onChange={(e) => this.changeClaimDetails(e, c.id)}
                              />
                              {i === this.state.clam_details.length - 1 && (
                                <span className="form__form-group-error">
                                  {this.state.errorClaimOne}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-lg-3">
                            <div className="form-group">
                              <label className="form__form-group-label" htmlFor="">
                                {" "}
                                Original Amount{" "}
                              </label>
                              <input
                                type="text"
                                className="custom-input"
                                value={c.claim_added}
                                name="claim_added"
                                pattern="^([0-9]+(\.?[0-9]?[0-9]?)?)"
                                onChange={(e) => this.changeClaimDetails(e, c.id)}
                              />
                              {i === this.state.clam_details.length - 1 && (
                                <span className="form__form-group-error">
                                  {this.state.errorClaimTwo}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-lg-3">
                            <div className="form-group">
                              <label className="form__form-group-label" htmlFor="">
                                {" "}
                                Current Amount{" "}
                              </label>
                              <input
                                type="text"
                                className="custom-input"
                                value={c.claim_cur_amt}
                                name="claim_cur_amt"
                                pattern="^([0-9]+(\.?[0-9]?[0-9]?)?)"
                                onChange={(e) => this.changeClaimDetails(e, c.id)}
                              />
                              {i === this.state.clam_details.length - 1 && (
                                <span className="form__form-group-error">
                                  {this.state.errorClaimThree}
                                </span>
                              )}
                            </div>
                          </div>
                          {i === this.state.clam_details.length - 1 ? (
                            <div className="col-12 col-md-4 col-lg-3">
                              <div className="form-group">
                                <div className="form-group top-20">
                                  {/* <button
                                    onClick={() => this.removeClaimCost(c)}
                                    className="btn-gray mr-3"
                                  >
                                    Remove
                                </button> */}
                                  <button
                                    onClick={() => this.addClaimDetails()}
                                    className="btn-gray"
                                  >
                                    Add Details
                                  </button>
                                </div>
                              </div>

                            </div>
                          ) : (
                            <div className="col-12 col-md-4 col-lg-2">
                              <div className="form-group">
                                <div className="form-group top-20">
                                  <button
                                    onClick={() => this.removeClaimCost(c)}
                                    className="btn-gray"
                                  >
                                    Remove
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}

                  <div className="card-header pl-0">
                    <span className="card-link form__form-group-label">TOTALS</span>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label" htmlFor="">
                          {" "}
                          Total{" "}
                        </label>
                        <input
                          type="text"
                          className="custom-input"
                          onChange={this.changeInput}
                          name="totalClaimAmount"
                          value={this.state.totalClaimAmount}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label" htmlFor="">
                          {" "}
                          Interest{" "}
                        </label>
                        <input
                          type="text"
                          className="custom-input"
                          onChange={this.changeInput}
                          name="claimInterestTotal"
                          value={this.state.claimInterestTotal}
                        />
                      </div>
                    </div>
                  </div>

                  <hr/>
                  <h3 className="page-title"> Recoverable Costs</h3>

                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label">
                          Add Cost Item
                        </label>
                        <Select
                          type="text"
                          classNamePrefix="react-select"
                          name="fileLocationCost"
                          className="react-select"
                          placeholder={'Select cost item'}
                          options={this.state.costItem || ''}
                          onChange={this.addCostToList}
                        />
                      </div>
                    </div>
                  </div>
                  {this.state.cost_details &&
                    this.state.cost_details.map((c, i) => {
                      return (
                        <div className="row" key={i}>
                          <div className="col-12 col-md-6 col-lg-3">
                            <div className="form-group">
                              <label className="form__form-group-label" htmlFor="">
                                {" "}
                                Description{" "}
                              </label>
                              <input
                                type="text"
                                className="custom-input"
                                value={c.description}
                                name="recov_desc"
                                onChange={(e) => this.changeCostDetails(e, c.id)}
                              />
                              {i === this.state.cost_details.length - 1 && (
                                <span className="form__form-group-error">
                                  {this.state.errorCostOne}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-lg-3">
                            <div className="form-group">
                              <label className="form__form-group-label" htmlFor="">
                                {" "}
                                Original Amount{" "}
                              </label>
                              <input
                                type="text"
                                className="custom-input"
                                value={c.amount}
                                name="recov_cur_amt"
                                pattern="^([0-9]+(\.?[0-9]?[0-9]?)?)"
                                onChange={(e) => this.changeCostDetails(e, c.id)}
                              />
                              {i === this.state.cost_details.length - 1 && (
                                <span className="form__form-group-error">
                                  {this.state.errorCostTwo}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-lg-3">
                            <div className="form-group">
                              <label className="form__form-group-label" htmlFor="">
                                {" "}
                                Date{" "}
                              </label>
                              <div className="date-picker date-picker--interval">
                                <DatePicker
                                  selected={c.date ? this.dateFormate(c.date) : ''}
                                  onChange={(e) => this.changeCostDate(e, c.id)}
                                  name="recov_added"
                                  dateFormat="dd/MM/yy"
                                  dropDownMode="select"
                                  popperPlacement="center"
                                  placeholderText="Select Date"
                                />

                                <span className="date_icon">
                                  <img src={"./image/calendar.svg"} alt="date"/>
                                </span>
                              </div>
                              {i === this.state.cost_details.length - 1 && (
                                <span className="form__form-group-error">
                                  {this.state.errorCostThree}
                                </span>
                              )}
                            </div>
                          </div>
                          {i === this.state.cost_details.length - 1 ? (
                            <div className="col-12 col-md-4 col-lg-3">
                              <div className="form-group">
                                <div className="form-group top-20">
                                  {/* <button
                                    onClick={() => this.removeRecovery(c)}
                                    className="btn-gray mr-3"
                                  >
                                    Remove
                                </button> */}
                                  <button
                                    onClick={() => this.addCostDetails()}
                                    className="btn-gray"
                                  >
                                    Add Details
                                  </button>
                                </div>
                              </div>
                            </div>
                          ) : (<div className="col-12 col-md-4 col-lg-3">
                            <div className="form-group">
                              <div className="form-group top-20">
                                <button
                                  onClick={() => this.removeRecovery(c)}
                                  className="btn-gray"
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          </div>)}
                        </div>
                      );
                    })}

                  <div className="card-header pl-0">
                    <span className="card-link form__form-group-label">TOTALS</span>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label" htmlFor="">
                          {" "}
                          Total{" "}
                        </label>
                        <input
                          type="text"
                          className="custom-input"
                          name="totalCostAmount"
                          pattern="^([0-9]+(\.?[0-9]?[0-9]?)?)"
                          onChange={this.changeInput}
                          value={this.state.totalCostAmount}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label" htmlFor="">
                          Offered Amount{" "}
                        </label>
                        <input
                          type="text"
                          className="custom-input"
                          name="costOfferAmount"
                          pattern="^([0-9]+(\.?[0-9]?[0-9]?)?)"
                          onChange={this.changeInput}
                          value={this.state.costOfferAmount}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="card-header mb-4 pl-0">
                    <hr/>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group  ml-0 mr-0 ">
                        <label className="form__form-group-label pl-0" htmlFor="">
                          {" "}
                          Current Claim{" "}
                        </label>
                        <input
                          type="text"
                          className="custom-inputs"
                          name="currentClaim"
                          onChange={this.changeInput}
                          disabled
                          value={cClaim}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group  ml-0 mr-0 ">
                        <label className="form__form-group-label  pl-0" htmlFor="">
                          {" "}
                          Total Received{" "}
                        </label>
                        <input
                          type="text"
                          className="custom-input"
                          name="TotalReceived"
                          disabled
                          onChange={this.changeInput}
                          value={this.state.TotalReceived}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group  ml-0 mr-0">
                        <label className="form__form-group-label pl-0" htmlFor="">
                          {" "}
                          Total Paid Direct To Agent{" "}
                        </label>
                        <input
                          type="text"
                          className="custom-input "
                          name="PaidDirectToAgent"
                          pattern="^([0-9]+(\.?[0-9]?[0-9]?)?)"
                          onChange={this.changeInput}
                          value={this.state.PaidDirectToAgent}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group  ml-0 mr-0 ">
                        <label className="form__form-group-label  pl-0" htmlFor="">
                          {" "}
                          AS AT{" "}
                        </label>
                        <div className="date-picker date-picker--interval">
                          <DatePicker
                            name="paidAsAtAgent"
                            dateFormat="dd/MM/yy"
                            dropDownMode="select"
                            popperPlacement="center"
                            placeholderText="Select Date"
                            onChange={(e) =>
                              this.handleChangeDate(e, "paidAsAtAgent")
                            }
                            selected={
                              this.state.paidAsAtAgent
                                ? this.dateFormate(this.state.paidAsAtAgent)
                                : ""
                            }
                          />
                          <span className="date_icon">
                            <img src={"./image/calendar.svg"} alt="date"/>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="slide_down_wrap mb-4 rounded">
              <div className=" card-body bg-white pl-0 rounded">
                {/* <hr /> */}
                <div
                  className={`slide_drop_down boxshadow-none cursor-pointer d-flex flex-wrap align-items-center justify-content-between rounded ${this.state.ClientdropDown ? "active" : ""}`}
                  onClick={() => this.setState({ClientdropDown: !this.state.ClientdropDown})}>

                  <h3 className="page-title mb-0" data-toggle="collapse"> Client </h3>
                  <span className="show_dropdown"> <svg className="ml-2" version="1.1" id="Capa_1" x="0px" y="0px"
                                                        width="14px" height="14px" viewBox="0 0 284.929 284.929">
                    <g>
                      <path d="M282.082,76.511l-14.274-14.273c-1.902-1.906-4.093-2.856-6.57-2.856c-2.471,0-4.661,0.95-6.563,2.856L142.466,174.441
		L30.262,62.241c-1.903-1.906-4.093-2.856-6.567-2.856c-2.475,0-4.665,0.95-6.567,2.856L2.856,76.515C0.95,78.417,0,80.607,0,83.082
		c0,2.473,0.953,4.663,2.856,6.565l133.043,133.046c1.902,1.903,4.093,2.854,6.567,2.854s4.661-0.951,6.562-2.854L282.082,89.647
		c1.902-1.903,2.847-4.093,2.847-6.565C284.929,80.607,283.984,78.417,282.082,76.511z"/>
                    </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </svg>
                  </span>
                </div>
                <div className={`bg-white hide_contact ${this.state.ClientdropDown ? "active pt-4" : ""}`}>
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label" htmlFor="">
                          {" "}
                          Client <span style={{color: "#ff0000"}}>*</span>
                        </label>
                        <Select
                          type="text"
                          classNamePrefix="react-select"
                          className="react-select"
                          options={this.state.allClient}
                          value={this.state.client}
                          onChange={(e) => this.handleChange(e, "selectedClient")}
                        />
                        <span className="form__form-group-error">
                          {this.validatorOne.message(
                            "client",
                            this.state.selectedClient,
                            "required"
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label" htmlFor="">
                          {" "}
                          Client Status:{" "}
                        </label>
                        <input
                          type="text"
                          className="custom-input"
                          value={this.state.clientStatus}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label" htmlFor="">
                          {" "}
                          Client Email Address:{" "}
                        </label>
                        <input
                          type="text"
                          className="custom-input"
                          value={this.state.clientEmail}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label" htmlFor="">
                          {" "}
                          Client First Name:{" "}
                        </label>
                        <input
                          type="text"
                          className="custom-input"
                          value={this.state.clientFname}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label" htmlFor="">
                          {" "}
                          Client Last Name:{" "}
                        </label>
                        <input
                          type="text"
                          className="custom-input"
                          value={this.state.clientLname}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label" htmlFor="">
                          {" "}
                          Client Phone Number:{" "}
                        </label>
                        <input
                          type="text"
                          className="custom-input"
                          value={this.state.clientContact}
                          disabled
                        />
                      </div>
                    </div>
                    {this.state.selectedClient && (
                      <div className="col-12 col-md-4 col-lg-2">
                        <div className="form-group">
                          <label className="form__form-group-label" htmlFor="">
                            {" "}
                            &nbsp;{" "}
                          </label>
                          <button
                            className="btn btn-primary mt-4"
                            onClick={this.clientClick}
                          >
                            {" "}
                            View Client{" "}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="row"/>
                </div>
              </div>
            </div>

            <div className="slide_down_wrap mb-4 rounded">
              <div className=" card-body bg-white pl-0 rounded">
                {/* <hr /> */}
                <div
                  className={`slide_drop_down boxshadow-none cursor-pointer d-flex flex-wrap align-items-center justify-content-between rounded ${this.state.LandlorddropDown ? "active" : ""}`}
                  onClick={() => this.setState({LandlorddropDown: !this.state.LandlorddropDown})}>

                  <h3 className="page-title mb-0" data-toggle="collapse"> Landlord Insurance </h3>
                  <span className="show_dropdown"> <svg className="ml-2" version="1.1" id="Capa_1" x="0px" y="0px"
                                                        width="14px" height="14px" viewBox="0 0 284.929 284.929">
                    <g>
                      <path d="M282.082,76.511l-14.274-14.273c-1.902-1.906-4.093-2.856-6.57-2.856c-2.471,0-4.661,0.95-6.563,2.856L142.466,174.441
		L30.262,62.241c-1.903-1.906-4.093-2.856-6.567-2.856c-2.475,0-4.665,0.95-6.567,2.856L2.856,76.515C0.95,78.417,0,80.607,0,83.082
		c0,2.473,0.953,4.663,2.856,6.565l133.043,133.046c1.902,1.903,4.093,2.854,6.567,2.854s4.661-0.951,6.562-2.854L282.082,89.647
		c1.902-1.903,2.847-4.093,2.847-6.565C284.929,80.607,283.984,78.417,282.082,76.511z"/>
                    </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </svg>
                  </span>
                </div>
                <div className={`bg-white hide_contact ${this.state.LandlorddropDown ? "active pt-4" : ""}`}>
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="d-flex align-items-center ml-2 mb-3">
                        <div className="round_checkbox">
                          <input
                            type="checkbox"
                            className=""
                            name="landlordInsurance"
                            id="Landlord"
                            checked={this.state.landlordInsurance}
                            onChange={(e) => {
                              this.setState({
                                landlordInsurance: e.target.checked,
                              });
                            }}
                          />
                          <label className="mb-0" htmlFor="Landlord"/>
                        </div>
                        <p className="m-0 ml-3">
                          Was Property Covered by Landlord Insurance?
                        </p>
                      </div>
                    </div>

                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="d-flex align-items-center ml-2 mb-3">
                        <div className="round_checkbox">
                          <input
                            type="checkbox"
                            className=""
                            name="landlordPayOut"
                            id="Insurance"
                            checked={this.state.landlordPayOut}
                            onChange={(e) => {
                              this.setState({landlordPayOut: e.target.checked});
                            }}
                          />
                          <label className="mb-0" htmlFor="Insurance"/>
                        </div>
                        <p className="m-0 ml-3">Insurance did not pay out? </p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label" htmlFor="">
                          {" "}
                          Insured By{" "}
                        </label>
                        <input
                          type="text"
                          className="custom-input"
                          name="insuranceBy"
                          onChange={this.changeInput}
                          value={this.state.insuranceBy}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label" htmlFor="">
                          {" "}
                          Total Original Claim{" "}
                        </label>
                        <input
                          type="text"
                          className="Total Original Claim"
                          name="totalOrignalClaim"
                          pattern="^([0-9]+(\.?[0-9]?[0-9]?)?)"
                          onChange={this.changeInput}
                          value={this.state.totalOrignalClaim}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label" htmlFor="">
                          {" "}
                          Amount Covered{" "}
                        </label>
                        <input
                          type="text"
                          className="custom-input"
                          name="amtCoverInsurance"
                          pattern="^([0-9]+(\.?[0-9]?[0-9]?)?)"
                          value={this.state.amtCoverInsurance}
                          onChange={this.changeInput}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="slide_down_wrap mb-4 rounded">
              <div className=" card-body bg-white pl-0 rounded">
                {/* <hr /> */}
                <div
                  className={`slide_drop_down boxshadow-none cursor-pointer d-flex flex-wrap align-items-center justify-content-between rounded ${this.state.OwnerdropDown ? "active" : ""}`}
                  onClick={() => this.setState({OwnerdropDown: !this.state.OwnerdropDown})}>

                  <h3 className="page-title mb-0" data-toggle="collapse"> Owner </h3>
                  <span className="show_dropdown"> <svg className="ml-2" version="1.1" id="Capa_1" x="0px" y="0px"
                                                        width="14px" height="14px" viewBox="0 0 284.929 284.929">
                    <g>
                      <path d="M282.082,76.511l-14.274-14.273c-1.902-1.906-4.093-2.856-6.57-2.856c-2.471,0-4.661,0.95-6.563,2.856L142.466,174.441
		L30.262,62.241c-1.903-1.906-4.093-2.856-6.567-2.856c-2.475,0-4.665,0.95-6.567,2.856L2.856,76.515C0.95,78.417,0,80.607,0,83.082
		c0,2.473,0.953,4.663,2.856,6.565l133.043,133.046c1.902,1.903,4.093,2.854,6.567,2.854s4.661-0.951,6.562-2.854L282.082,89.647
		c1.902-1.903,2.847-4.093,2.847-6.565C284.929,80.607,283.984,78.417,282.082,76.511z"/>
                    </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </svg>
                  </span>
                </div>
                <div className={`bg-white hide_contact ${this.state.OwnerdropDown ? "active pt-4" : ""}`}>
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label" htmlFor="">
                          Name:
                        </label>
                        <input
                          type="text"
                          className="custom-input"
                          name="ownerName"
                          onChange={this.changeInput}
                          value={this.state.ownerName}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-9">
                      <div className="form-group">
                        <label className="form__form-group-label" htmlFor="">
                          {" "}
                          Address:{" "}
                        </label>
                        <input
                          type="text"
                          className="custom-input"
                          name="ownerAddress"
                          onChange={this.changeInput}
                          value={this.state.ownerAddress}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label" htmlFor="">
                          {" "}
                          Contact Number:{" "}
                        </label>
                        <input
                          type="number"
                          className="custom-input"
                          name="ownerContact"
                          pattern="[0-9]*"
                          onChange={this.changeInput}
                          value={this.state.ownerContact}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                      <div className="form-group">
                        <label className="form__form-group-label" htmlFor="">
                          Email:
                        </label>
                        <input
                          type="text"
                          className="custom-input"
                          name="ownerEmail"
                          onChange={this.changeInput}
                          value={this.state.ownerEmail}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-9 col-lg-9">
                      <div className="form-group">
                        <label className="form__form-group-label" htmlFor="">
                          Bank Account:
                        </label>
                        <textarea
                          onChange={this.changeInput}
                          value={this.state.ownerBank}
                          className="custom-input"
                          name="ownerBank"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="slide_down_wrap mb-4 rounded">
              <div className=" card-body bg-white pl-0 rounded">
                {/* <hr /> */}
                <div
                  className={`slide_drop_down boxshadow-none cursor-pointer d-flex flex-wrap align-items-center justify-content-between rounded ${this.state.SubjectsdropDown ? "active" : ""}`}
                  onClick={() => this.setState({SubjectsdropDown: !this.state.SubjectsdropDown})}>

                  <h3 className="page-title mb-0" data-toggle="collapse"> Subjects </h3>
                  <span className="show_dropdown"> <svg className="ml-2" version="1.1" id="Capa_1" x="0px" y="0px"
                                                        width="14px" height="14px" viewBox="0 0 284.929 284.929">
                    <g>
                      <path d="M282.082,76.511l-14.274-14.273c-1.902-1.906-4.093-2.856-6.57-2.856c-2.471,0-4.661,0.95-6.563,2.856L142.466,174.441
		L30.262,62.241c-1.903-1.906-4.093-2.856-6.567-2.856c-2.475,0-4.665,0.95-6.567,2.856L2.856,76.515C0.95,78.417,0,80.607,0,83.082
		c0,2.473,0.953,4.663,2.856,6.565l133.043,133.046c1.902,1.903,4.093,2.854,6.567,2.854s4.661-0.951,6.562-2.854L282.082,89.647
		c1.902-1.903,2.847-4.093,2.847-6.565C284.929,80.607,283.984,78.417,282.082,76.511z"/>
                    </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </svg>
                  </span>
                </div>
                <div className={`bg-white hide_contact ${this.state.SubjectsdropDown ? "active pt-4" : ""}`}>
                  {this.state.subjects &&
                    this.state.subjects.map((c, i) => {
                      return (
                        <div className="row" key={i}>
                          <div className="col-12 col-md-6 col-lg-3">
                            <div className="form-group">
                              <label className="form__form-group-label" htmlFor="">
                                {" "}
                                Client{" "}
                              </label>
                              <Select
                                classNamePrefix="react-select"
                                className="react-select"
                                options={this.state.allDebtor}
                                value={c.selected}
                                onChange={(e) =>
                                  this.handleChangeSubject(e, c.id)
                                }
                              />
                              {i === this.state.subjects.length - 1 && (
                                <span className="form__form-group-error">
                                  {this.state.errorSubject}
                                </span>
                              )}
                              <span className="form__form-group-error">
                                {this.validatorOne.message(
                                  "",
                                  this.state.subjects,
                                  "required"
                                )}
                              </span>
                            </div>
                          </div>
                          {i === this.state.subjects.length - 1 && (
                            <div className="col-12 col-md-4 col-lg-2">
                              <div className="form-group">
                                <div className="form-group top-20">
                                  <button
                                    className="btn-gray"
                                    onClick={() => this.addSubject()}
                                  >
                                    Add Subject
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>

          </div>

          <div className="w-100 d-flex justify-content-end pb-4">
            <button
              type="button"
              className="btn-group--icons btn btn-secondary"
              onClick={() => this.saveWIP()}
            >
              Save
            </button>
            <button
              type="button"
              className="btn-gray m-0"
              onClick={() => this.props.close()}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AddWIP);
