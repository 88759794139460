import { format } from "date-fns"

export const isTheSameDate = (date, dateToCompare) => {
    const firstDate = format(new Date(date), 'yyyy-MM-dd')
    const secondDate = format(new Date(dateToCompare), 'yyyy-MM-dd')
    return firstDate === secondDate
}

export const dayMap = {
    MO: 1,
    TU: 2,
    WE: 3,
    TH: 4,
    FR: 5,
    SA: 6,
    SU: 7
}as const