import {
  EditDebtorAddressFormProps,
  EditDebtorAddressFormValues,
} from '@containers/Recovery/Wip/_modules/Debtors/types/debtor-address.type'
import DebtorAddressForm from './debtor-address-form.component'
import { FC, useEffect } from 'react'
import { transformDebtorAddressToDebtorAddressFormValues } from '@containers/Recovery/Wip/_modules/Debtors/utils/transform-debtor-address-to-values.utils'
import { Address } from '@components/inputs/form-input/geo-suggest.type'
import { useUpdateDebtorAddressMutation } from '@containers/Recovery/Wip/_modules/Debtors/hooks/mutations/update-debtor-address.hook'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import SubSection from '@containers/Recovery/Wip/components/UI/sub-section/sub-section.component'
import FormDatePicker from '@components/inputs/form-date-picker/form-date-picker.component'
import { useForm } from 'react-hook-form'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import FormCheckbox from '@components/inputs/form-checkbox/form-checkbox.component'
import styles from './debtor-address-form.module.scss'

const EditDebtorAddressForm: FC<EditDebtorAddressFormProps> = ({ debtorAddress }) => {
  const { control, reset } = useForm<EditDebtorAddressFormValues>()
  const queryClient = useQueryClient()
  const { mutateAsync, isLoading } = useUpdateDebtorAddressMutation()

  useEffect(() => {
    reset({ created_at: debtorAddress.time_created, date_of_entry: debtorAddress.time_created })
  }, [debtorAddress])

  const handleSubmit = async (data: Address) => {
    try {
      await mutateAsync({
        debtorAddressId: debtorAddress.id,
        updateData: data,
      })
      queryClient.invalidateQueries({ queryKey: [GLOBAL_QUERIES.DEBTOR_ADDRESS_HISTORY.GET] })
      toast.success('Debtor Address Updated')
    } catch (e) {
      toast.error('Something went wrong')
      console.error(e)
    }
  }

  return (
    <DebtorAddressForm
      initialValues={transformDebtorAddressToDebtorAddressFormValues(debtorAddress)}
      onSuccessSubmit={handleSubmit}
      loading={isLoading}
    >
      <div className={styles.headerWrapper}>
        <Header>Address Details</Header>
        <FormCheckbox label="Current Address" />
      </div>

      <SubSection flex p={4}>
        <FormDatePicker name="created_at" disabled control={control} label="Created At" />
        <FormDatePicker name="date_of_entry" disabled control={control} label="Date of Entry" />
      </SubSection>
    </DebtorAddressForm>
  )
}

export default EditDebtorAddressForm
