import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { Spinner } from 'react-bootstrap';
import Services from '../../../../../shared/Services';
import UploadsService from '../../../../../services/UploadsService';
import ChipsArray from './ChipArray';


export const AttachedFilesWip = ({ wipDetails, setWipDetails }) => {

  const [fileLoader, setFileLoader] = useState(false);
  const [delays, setDelays] = useState(false);
  const services = new Services();
  const uploads = new UploadsService();

  const fileUpload = (img) => {
    setDelays(true);
    setFileLoader(true);
    uploads.uploadsFileWip(img[0]).then((res) => {
      if (res.status === 201) {
        setWipDetails([...wipDetails, res.data]);
        delay('File upload successfully');
      } else {
        services.errorToast(res);
        setFileLoader(false);
      }
    });
  };

  const removeFile = (file) => {

    if (file?.folder === 'file-wip') {
      uploads.deleteFileWip(file).then((res) => {
        try {
          if (res.status === 200) {
            setDelays(true);
            setWipDetails(wipDetails.filter((el) => el.file_path !== file.file_path));
            delay('File delete successfully');
          } else {
            services.errorToast(res);
          }
        } catch (e) {
          console.log(e);
        }
      });
    } else if (file?.folder === 'file-recovery') {
      setWipDetails(wipDetails.filter((el) => el.file_path !== file.file_path));
    }
  };

  const handlerDownloadFile = (file) => {
    setDelays(true);
    if (file?.folder === 'file-recovery') {
      uploads.downloadFile(file).then(() => delay());
    } else if (file?.folder === 'file-wip') {
      uploads.downloadFileWip(file).then(() => delay());
    }
  };

  const delay = (text = 'Download successfully') => {
    setTimeout(() => {
      setDelays(false);
      setFileLoader(false);
      services.successToast(text);
    }, 4000);
  };

  return (
    <>
      <div className='card__title'>
        <h5 className='bold-text'>Uploaded files</h5>
      </div>
      <div className='form__form-group'>
        <span className='form__form-group-label'>Files</span>
        <div className='form__form-group-field'>
          <div className={`dropzone dropzone--single`}>
            {fileLoader && (
              <Spinner
                animation='border'
                variant='warning'
                className='dropzone__drop-here'
              />
            )}
            <Dropzone
              name={'file'}
              multiple={true}
              onDrop={(fileToUpload) => {
                fileUpload(fileToUpload);
              }}
              disabled={delays}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} className='dropzone__input'>
                  {!fileLoader && (
                    <div className='dropzone__drop-here'>
                      <span className='lnr lnr-upload' /> Drop File Here To
                      Upload
                    </div>
                  )}
                  <input {...getInputProps()} disabled={fileLoader} />
                </div>
              )}
            </Dropzone>
          </div>
        </div>
      </div>
      <div className='row' style={{ alignItems: 'center' }}>
        <span className='form__form-group-label'>Attached files</span>
        <div className='col-6'>
          <ChipsArray handlerDownloadFile={handlerDownloadFile}
                      removeFile={removeFile}
                      files={wipDetails}
                      delay={delays}
          />
        </div>
      </div>
    </>
  );
};
