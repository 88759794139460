import AddressHistoryList from './address-history-list.component'
import { ChangeEvent, FC, useState } from 'react'
import FormInput from '@components/inputs/form-input/form-input.component'
import SearchIcon from '@src/icons/search'
import { useDebounce } from '@src/hooks/useDebounce'
import styles from './address-history-list.module.scss'
import { AddressHistoryListWithSearchProps } from '@containers/Recovery/Wip/_modules/Debtors/types/debtor-address.type'

const AddressHistoryListWithSearch: FC<AddressHistoryListWithSearchProps> = ({
  debtorId,
  onSelectedAddressChange,
}) => {
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }

  return (
    <div className={styles.addressHistoryWithSearchWrapper}>
      <FormInput
        icon={SearchIcon}
        placeholder="Search Address Here"
        onChange={handleSearchChange}
      />
      <AddressHistoryList
        s={debouncedSearch ? { address_1: { $contL: debouncedSearch } } : undefined}
        debtorId={debtorId}
        onSelectedAddressChange={onSelectedAddressChange}
      />
    </div>
  )
}

export default AddressHistoryListWithSearch
