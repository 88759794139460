import { GLOBAL_QUERIES } from '@src/lib/global-queries'
import { Params } from '@src/types/service.type'
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { PropertyService } from '../services/property.service'
import { DeepPartial } from 'react-hook-form'
import { Property, ValidatePropertyData } from '../types/property.type'
import { getPropertyByIdKey } from '@containers/Sales/Client/_modules/Properties/utils/client-property.utils'

export const useGetInfinityProperties = (clientId: number, params: Params = {}) => {
  const getProperties = async (clientId: number, params?: Params) => {
    const propertyService = new PropertyService()
    return propertyService.getClientProperties(clientId, params)
  }

  const { data, ...restQuery } = useInfiniteQuery({
    queryKey: [GLOBAL_QUERIES.PROPERTIES.GET, params],
    queryFn: async ({ pageParam }) => {
      const page = pageParam ? pageParam : 1
      const limit = params.limit

      const res = await getProperties(clientId, { ...params, limit, page })

      return {
        results: res.data,
        page: page < res.pageCount ? page + 1 : undefined,
      }
    },
    cacheTime: 0,
    getNextPageParam: (lastPage) => lastPage.page,
    refetchOnWindowFocus: false,
  })

  const flattenedData = useMemo(
    () => (data ? data?.pages.flatMap((item) => item.results) : []),
    [data],
  )

  return { data: flattenedData, ...restQuery }
}

export const useGetProperties = (params: Params = {}) => {
  const getProperties = async (params?: Params) => {
    const propertyService = new PropertyService()
    return propertyService.getProperties(params)
  }

  const query = useQuery({
    queryKey: [GLOBAL_QUERIES.PROPERTIES.GET, params],
    queryFn: () => getProperties(params),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  })

  return query
}

export const useGetClientByPropertyId = (
  propertyId: number,
  params: Params = { filter: `propertyId||eq||${40}` },
) => {
  const getClientByPropertyId = async (params: Params = {}) => {
    const propertyService = new PropertyService()
    return propertyService.getClientByPropertyId(params)
  }

  const query = useQuery({
    queryKey: [GLOBAL_QUERIES.CLIENT.GET_BY_ID, propertyId, params],
    queryFn: () => getClientByPropertyId(params),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  })
  return query
}

export const useGetClientProperties = (clientId: number, params: Params = {}) => {
  const getClientProperties = async (clientId: number, params?: Params) => {
    const propertyService = new PropertyService()
    return propertyService.getClientProperties(clientId, params)
  }

  const query = useQuery({
    queryKey: [GLOBAL_QUERIES.PROPERTIES.GET, clientId, params],
    queryFn: () => getClientProperties(clientId, params),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  })

  return query
}

export const usePropertyById = (propertyId: number, params: Params = {}) => {
  const getPropertyById = async (propertyId: number, params?: Params) => {
    const propertyService = new PropertyService()
    return propertyService.getPropertyById(propertyId, params)
  }

  const query = useQuery({
    queryKey: getPropertyByIdKey(propertyId, params),
    queryFn: () => getPropertyById(propertyId, params),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  })

  return query
}

// mutations

export const useUpdatePropertyMutation = () => {
  const updateProperty = async (id: number, data: DeepPartial<Property>) => {
    const propertyService = new PropertyService()
    return propertyService.updateProperty(id, data)
  }

  const mutation = useMutation({
    mutationFn: ({ id, data }: { id: number; data: DeepPartial<Property> }) =>
      updateProperty(id, data),
  })

  return mutation
}

export const useValidatePropertiesMutation = () => {
  const validateProperties = (data: ValidatePropertyData) => {
    const propertyService = new PropertyService()
    return propertyService.validate(data)
  }

  const mutation = useMutation(validateProperties)

  return mutation
}
