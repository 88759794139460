import moment from 'moment'
import { FC, useState } from 'react'
import styles from './combined-note.module.scss'
import { CombinedNoteProps } from '../../types/combined-notes.type'
import { COMBINED_NOTE_HEADER_TEXT } from './const/combined-note.const'
import cls from 'classnames'
import { CombinedNoteType } from '../../types/combined-notes.type'

const CombinedNote: FC<CombinedNoteProps> = ({ taskNote, lastElementRef, openTaskNoteModal }) => {
  const { id, title, createdAt, type, important, created_user_id } = taskNote

  const [isOpenButtonShown, setIsOpenButtonShown] = useState(false)

  const handleMouseEnter = () => {
    setIsOpenButtonShown(true)
  }

  const handleMouseLeave = () => {
    setIsOpenButtonShown(false)
  }

  const header = important ? 'Important Note' : COMBINED_NOTE_HEADER_TEXT[type]

  return (
    <div
      key={id}
      className={cls(styles.noteItem, {
        [styles.sms]: type === CombinedNoteType.SMS,
        [styles.email]: type === CombinedNoteType.LOCAL_EMAIL || type === CombinedNoteType.OUTLOOK_EMAIL,
        [styles.call]: type === CombinedNoteType.CALL,
        [styles.important]: important,
      })}
      ref={lastElementRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={styles.noteItemContent}>
        <div className={styles.avatarWrapper}>
          <span className="lnr lnr-user"></span>
        </div>
        <div>
          <h5 className={styles.noteHeader}>{header}</h5>
          <p className={styles.noteTitle}>{title}</p>
        </div>
      </div>
      <div className={styles.noteDate}>
        <p className="m-0">{moment(createdAt).format('DD-MM-YYYY')}</p>
        <p className="m-0">{moment(createdAt).format('kk:mm:ss')}</p>
      </div>
      {isOpenButtonShown ? (
        <div className={styles.noteOpenButton} onClick={() => openTaskNoteModal({ id, type, userId: created_user_id })}>
          <span className="lnr lnr-text-align-right"></span> OPEN
        </div>
      ) : null}
    </div>
  )
}

export default CombinedNote
