import Table from '@components/table/table.component'
import { useGetUniformEmailList } from '@containers/Communication/hooks/get-uniform-email-list.hook'
import EmailTableItem from './email-table-item.component'
import { FC, useEffect, useState } from 'react'
import { EmailTableProps } from '@containers/Communication/types/communication.type'
import EmailModal from '../../modals/email-modal.component'
import { UniformEmail } from '@containers/Communication/types/email.type'
import { useModal } from '@src/hooks/useModal'

const EmailTable: FC<EmailTableProps> = ({
  params,
  onEmailListChange,
  currentPage,
  onPageChange,
}) => {
  const [selectedEmail, setSelectedEmail] = useState<UniformEmail | null>(null)
  const [isViewEmailModalOpen, openViewModal, handleViewEmailClose] = useModal(false)
  const [page, setPage] = useState(1)
  const { data, isLoading, isRefetching } = useGetUniformEmailList({ ...params, page, limit: 5 })

  const emails = data?.data || []

  useEffect(() => {
    if (!(data && onEmailListChange)) return

    onEmailListChange(data)
  }, [data])

  useEffect(() => {
    if (!currentPage) return

    setPage(currentPage)
  }, [currentPage])

  const handleItemClick = (email: UniformEmail) => {
    setSelectedEmail(email)
    openViewModal()
  }

  const isEmailsLoading = isLoading || isRefetching
  const isEmailsNotFound = emails.length === 0 && !isEmailsLoading

  return (
    <>
      <Table
        headers={['DATE', 'TIME', 'SUBJECT', 'TO', 'FROM']}
        page={page}
        onPageChange={onPageChange}
        limit={5}
        totalCount={data?.total || 0}
        bodyHeight={54 * 5}
        loading={isEmailsLoading}
        notFound={isEmailsNotFound}
      >
        {emails.map((email, index) => (
          <EmailTableItem
            email={email}
            key={`email-${email.id}-${index}`}
            onEmailClick={handleItemClick}
          />
        ))}
      </Table>

      <EmailModal
        id={selectedEmail?.id || null}
        emailType={selectedEmail?.email_type || 'Local'}
        userId={selectedEmail?.user_id || null}
        onClose={handleViewEmailClose}
        isOpen={isViewEmailModalOpen}
      />
    </>
  )
}

export default EmailTable
