import { FC } from 'react'
import { DebtorTableItemProps } from '../../../types/debtor.type'
import TableCell from '@components/table/table-cell-component'
import TableRow from '@components/table/table-row.component'
import { format } from 'date-fns'
import styles from './debtor-table.module.scss'
import FormCheckbox from '@components/inputs/form-checkbox/form-checkbox.component'
import TrashIcon from '@src/icons/trash'
import { useHistory } from 'react-router'
import { DebtorService } from '../../../services/debtor.service'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import { getWipQueryKey } from '@containers/Recovery/Wip/utils/wip-query-factory'
import { Wip } from '@containers/Recovery/Wip/types/wip.type'

const DebtorTableItem: FC<DebtorTableItemProps> = ({ debtor, wipId }) => {
  const queryClient = useQueryClient()
  const history = useHistory()

  const handleRemoveClick = async (e: MouseEvent) => {
    e.stopPropagation()
    const debtorService = new DebtorService()
    try {
      await debtorService.deleteDebtor(debtor.id)
      queryClient.setQueryData<Wip>(getWipQueryKey(wipId), (prevData) =>
        prevData
          ? { ...prevData, debtors: prevData.debtors.filter((d) => d.id !== debtor.id) }
          : undefined,
      )
      toast.success('Debtor removed')
    } catch (e) {
      toast.error('Failed to remove debtor')
      console.error(e)
    }
  }

  const handleRowClick = () => {
    history.push(`/wip/${wipId}/debtors/${debtor.id}`)
  }

  return (
    <TableRow onClick={handleRowClick} pointer>
      {/* Checkbox # */}
      <TableCell width={48} position="eclipsed">
        <FormCheckbox />
      </TableCell>
      {/* ID # */}
      <TableCell width={70} position="eclipsed">
        {debtor.id}
      </TableCell>
      {/* LOGGED */}
      <TableCell className={styles.dateCell} width={160} position="eclipsed">
        {format(debtor.time_created, 'kk:mm | dd-MM-yyyy')}
      </TableCell>
      {/* DEBTORS */}
      <TableCell className={styles.debtorCell} position="eclipsed">
        {`${debtor.name_first || ''} ${debtor.name_last || ''}`}
      </TableCell>
      {/* EMAIL */}
      <TableCell position="eclipsed">
        {debtor.contact.comms_email[0] || 'Email not provided'}
      </TableCell>
      {/* PHONE */}
      <TableCell position="eclipsed">
        {debtor.contact.comms_ph_mobile ||
          debtor.contact.comms_ph_mobile_secondary_current ||
          debtor.contact.comms_ph_direct ||
          debtor.contact.comms_ph_office ||
          debtor.contact.comms_ph_home ||
          debtor.contact.comms_ph_work ||
          'Phone not provided'}
      </TableCell>
      <TableCell width={48} position="eclipsed">
        {<TrashIcon onClick={handleRemoveClick} className={styles.trashIcon} />}
      </TableCell>
    </TableRow>
  )
}

export default DebtorTableItem
