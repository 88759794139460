import { useMainStyleContext } from '@containers/App/contexts/main-style.context'
import { useEffect, useMemo } from 'react'
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router'
import { useGetWipById } from '../../hooks/get-wip-by-id.hook'
import NavBar from '@components/nav-bar/nav-bar.component'
import { getNavBarOptions } from '../../utils/get-nav-bar-options'
import styles from './wip-page.module.scss'
import WipOverview from '../../_modules/Overview/wip-overview-page.component'
import { Header, SubHeader } from '../../components/UI/text/text.component'
import WipFinancialPage from '../../_modules/Financial/wip-financal-page.component'
import { useGetWipCover } from '../../hooks/get-wip-cover.hook'
import { FETCH_WIP_PARAMS } from '../../const/wip.const'
import WipCommunicationPage from '../../_modules/Communication/wip-communication-page.component'
import DebtorsPage from '../../_modules/Debtors/view/debtors-page/debtors-page.component'
import EditDebtorPage from '../../_modules/Debtors/view/edit-debtor-page/edit-debtor-page.component'
import { useTaskContext } from '@src/context/task-context'
import WipSpeedDial from '@containers/Communication/components/speed-dial/wip-speed-dial.component'
import TaskBoard from '@containers/Setting/components/Contacts/taskboard'
import AutomationBoard from '@containers/Setting/components/Contacts/automations'
import WipPageTaskBoardModals from './wip-page-taskborad-modals.component'
import WipProperties from '../../_modules/Property/wip-properties.component'
import WipLegalPage from '../../_modules/Legal/views/wip-legal-page/wip-legal-page.component'
import DebtorLegalPage from '../../_modules/Legal/views/debtor-legal-page/debtor-legal-page.component'
import LoaderWrapper from '@components/loader/loader-wrapper.component'
import { Wip } from '../../types/wip.type'
import { format, getDay } from 'date-fns'
import { formatCurrency } from '../../utils/format-currency'

const WipPage = () => {
  const history = useHistory()
  const location = useLocation()
  const { setCurrentWip, setMode } = useTaskContext()
  const { path } = useRouteMatch()
  const { wipId } = useParams<{ wipId: string }>()
  const { setStyle } = useMainStyleContext()
  const { data: wip, isLoading, isError } = useGetWipById(Number(wipId), FETCH_WIP_PARAMS)
  useGetWipCover()

  useEffect(() => {
    setStyle({ backgroundColor: '#f9fafb' })
    setCurrentWip(Number(wipId))
    setMode('RECOVERY')
    return () => {
      setStyle({ backgroundColor: 'white' })
    }
  }, [])

  useEffect(() => {
    if (!isError) return
    history.push('/recoveries')
  }, [isError])

  const options = useMemo(() => getNavBarOptions(Number(wipId)), [wipId])

  const getPaymentOverdueDays = (wip: Wip) => {
    const payTrackings = wip.pay_trackings
    console.log('payTrackings', payTrackings)
    const overduePayTrackings = payTrackings
      .filter((payTracking) => payTracking.isOverdue && payTracking.firstPaymentDue)
      .sort((a, b) => new Date(b.firstPaymentDue).getTime() - new Date(a.firstPaymentDue).getTime())

    if (overduePayTrackings.length === 0) {
      return null
    }
    const overdueAmount = overduePayTrackings.reduce(
      (acc, payTracking) => acc + payTracking.amount,
      0,
    )
    const overdueDays = Number(format(overduePayTrackings[0].firstPaymentDue, 'd'))

    return { overdueAmount, overdueDays }
  }

  const result = wip ? getPaymentOverdueDays(wip) : null

  return (
    <>
      {isLoading || !wip ? (
        <LoaderWrapper />
      ) : (
        <>
          <div className={styles.wipInfo}>
            <div>
              <Header>WIP Number: {wipId}</Header>
              <SubHeader>Client: {wip?.company?.name || ''}</SubHeader>
            </div>
            {location.pathname === `/wip/${wipId}/tasks` ? <WipPageTaskBoardModals /> : null}
          </div>
          <div className={styles.navbarWrapper}>
            <NavBar options={options} activeUrl={location.pathname} />
            {result ? (
              <div className={styles.overdueWrapper}>
                Overdue:{' '}
                {formatCurrency(result.overdueAmount).slice(
                  1,
                  formatCurrency(result.overdueAmount).length - 3,
                )}
                $ - {result.overdueDays} {result.overdueDays === 1 ? 'day' : 'days'}
              </div>
            ) : null}
            <WipSpeedDial wip={wip} />
          </div>
          <Switch>
            <Route exact path={`${path}/overview`} render={() => <WipOverview wip={wip} />} />
            <Route path={`${path}/financial`} render={() => <WipFinancialPage wip={wip} />} />
            <Route
              path={`${path}/communication`}
              render={() => <WipCommunicationPage wip={wip} />}
            />
            <Route exact path={`${path}/property`} render={() => <WipProperties wip={wip} />} />
            <Route exact path={`${path}/debtors`} render={() => <DebtorsPage wip={wip} />} />
            <Route
              exact
              path={`${path}/debtors/:debtorId/legal/:debtorLegalId`}
              render={() => <DebtorLegalPage wip={wip} />}
            />
            <Route exact path={`${path}/debtors/:debtorId`} render={() => <EditDebtorPage />} />
            <Route exact path={`${path}/tasks`} render={() => <TaskBoard />} />
            <Route exact path={`${path}/legal`} render={() => <WipLegalPage wip={wip} />} />
            <Route exact path={`${path}/automations`} render={() => <AutomationBoard />} />
            <Route path={'*'} render={() => <Redirect to={`/wip/${wipId}/overview`} />} />
          </Switch>
        </>
      )}
    </>
  )
}

export default WipPage
