import { FC } from 'react'
import { DebtorLegal, DebtorLegalPlaintiff } from '../../../types/debtor-legal.type'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import { getDebtorLegalByIdQueryKey } from '../../../utils/legal-query-key-factory.utils'
import Modal from '@components/modal'
import { Header } from '@containers/Recovery/Wip/components/UI/text/text.component'
import CreateDebtorLegalPlaintiffForm from '../../forms/debtor-legal-plaintiff-form/create-debtor-legal-plaintiff-form.component'
import styles from './debtor-legal-plaintiff-modal.module.scss'
import { CreateDebtorLegalPlaintiffModalProps } from '../../../types/debtor-plaintiff.type'
import ModalHeader from '@components/modal-header.component'

const CreateDebtorLegalPlaintiffModal: FC<CreateDebtorLegalPlaintiffModalProps> = ({
  debtorLegalId,
  ...modalProps
}) => {
  const queryClient = useQueryClient()
  const handleSuccessfulSubmit = (debtorLegalPlaintiff: DebtorLegalPlaintiff) => {
    queryClient.setQueryData<DebtorLegal>(
      getDebtorLegalByIdQueryKey(debtorLegalPlaintiff.debtorLegalId),
      (prevData) =>
        prevData
          ? {
              ...prevData,
              plaintiffs: [...prevData.plaintiffs, debtorLegalPlaintiff],
            }
          : undefined,
    )
    toast.success('Plaintiff created successfully')
    modalProps.onClose()
  }

  return (
    <Modal className={styles.modalWrapper} {...modalProps} withBtns={false}>
      <ModalHeader onCloseClick={modalProps.onClose} />
      <Header mb={4}>Create Plaintiff Details</Header>
      <CreateDebtorLegalPlaintiffForm
        onSuccessfulSubmit={handleSuccessfulSubmit}
        debtorLegalId={debtorLegalId}
      />
    </Modal>
  )
}

export default CreateDebtorLegalPlaintiffModal
